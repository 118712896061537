export type MetaMaskEthereumProviderBasic = {
  isMetaMask?: boolean
  isConnected(): boolean
}

/**
 * https://docs.metamask.io/guide/ethereum-provider.html#errors
 */
export interface ProviderRpcError extends Error {
  message: string
  code: number
  data?: unknown
}
export function isMayBeProviderRpcError(err: unknown): err is ProviderRpcError {
  if (!(err instanceof Error)) return false

  const _err: any = err

  if (!("code" in _err)) return false

  if (typeof _err.code !== "number") return false

  return true
}
