import { gql } from "@urql/core"
import { Observable, map } from "rxjs"
import { ORDER_BOOK_REFRESH_INTERVAL } from "../../../../config"
import {
  FetchAllMarketsQuery,
  FetchAllMarketsQueryVariables,
  FetchMarketTotalAmountQuery,
  FetchMarketTotalAmountQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { sendPublicRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { isNotNull } from "../../../../utils/utils"
import { intervalFetch } from "../../../../utils/wonkaHelpers/intervalFetch"
import { OrderbookAsset } from "../OrderbookStore.service/OrderbookStore.service"
import { OrderbookMarket, OrderbookMarketId } from "./StxDxMarket.service"

export type MarketSummary = {
  market: OrderbookMarketId
  highestPrice: number
  lastPrice: number
  lowestPrice: number
  price24hChangePercent: number
  price24hChange: number
  volume24h: number
  amount24h: number
  price: number
  bid: number
  bidSize: number
  ask: number
  askSize: number
}

export function fetchAllMarketSummary(): Observable<MarketSummary[]> {
  return intervalFetch(ORDER_BOOK_REFRESH_INTERVAL, () =>
    sendPublicRequest("OrderController_getAllMarketsOverview", {}).then(resp =>
      resp.data.map(
        ({
          highest_price,
          last_price,
          lowest_price,
          price_24h_change_percent,
          price_24h_change,
          volume_24h,
          amount_24h,
          price,
          ticker: { bid, bid_size, ask, ask_size },
          market,
        }) => ({
          market: market as any,
          highestPrice: Number(highest_price),
          lastPrice: Number(last_price),
          lowestPrice: Number(lowest_price),
          price24hChangePercent: Number(price_24h_change_percent),
          price24hChange: Number(price_24h_change),
          volume24h: Number(volume_24h),
          amount24h: Number(amount_24h),
          price: Number(price),
          ask: Number(ask),
          askSize: Number(ask_size),
          bid: Number(bid),
          bidSize: Number(bid_size),
        }),
      ),
    ),
  )
}

export function fetchMarketSummary(
  market: OrderbookMarketId,
): Observable<MarketSummary> {
  return intervalFetch(ORDER_BOOK_REFRESH_INTERVAL, () =>
    sendPublicRequest("OrderController_getTradeOverview", {
      path: { market: market },
    }),
  ).pipe(
    map(resp => {
      const {
        data: {
          highest_price,
          last_price,
          lowest_price,
          price_24h_change_percent,
          price_24h_change,
          volume_24h,
          amount_24h,
          price,
          ticker: { bid, bid_size, ask, ask_size },
        },
      } = resp
      return {
        market,
        highestPrice: Number(highest_price),
        lastPrice: Number(last_price),
        lowestPrice: Number(lowest_price),
        price24hChangePercent: Number(price_24h_change_percent),
        price24hChange: Number(price_24h_change),
        volume24h: Number(volume_24h),
        amount24h: Number(amount_24h),
        price: Number(price),
        ask: Number(ask),
        askSize: Number(ask_size),
        bid: Number(bid),
        bidSize: Number(bid_size),
      }
    }),
  )
}

export interface MarketRawData extends OrderbookMarket {
  pegFlowTokenSelectorOrder?: number
}

export function fetchAllMarkets(): Observable<{
  orderbookMarkets: MarketRawData[]
}> {
  return fromUrqlSource(
    gqlQuery<FetchAllMarketsQuery, FetchAllMarketsQueryVariables>(
      gql`
        query FetchAllMarkets {
          orderbookMarketsCollection {
            items {
              marketId
              pricePrecision
              priceToken {
                id
              }
              tradeToken {
                id
              }
              brc20MarketInfo {
                baseTokenSymbol
                pegFlowTokenSelectorOrder
              }
            }
          }
        }
      `,
      {},
    ),
    result => {
      return {
        orderbookMarkets: result.data
          .orderbookMarketsCollection!.items.filter(isNotNull)
          .map(
            (i): MarketRawData => ({
              marketId: i.marketId! as OrderbookMarketId,
              pricePrecision: i.pricePrecision!,
              priceToken: i.priceToken!.id as OrderbookAsset,
              tradeToken: i.tradeToken!.id as OrderbookAsset,
              isBrc20Token: i.brc20MarketInfo != null,
              pegFlowTokenSelectorOrder:
                i.brc20MarketInfo?.pegFlowTokenSelectorOrder == null
                  ? undefined
                  : new Date(
                      i.brc20MarketInfo.pegFlowTokenSelectorOrder,
                    ).getTime(),
            }),
          ),
      }
    },
  )
}

export type MarketTotalVolume = {
  totalTradeVolumeInUsd: number
}

export function fetchMarketTotalVolume(
  market: OrderbookMarketId,
): Observable<MarketTotalVolume> {
  return fromUrqlSource(
    gqlQuery<FetchMarketTotalAmountQuery, FetchMarketTotalAmountQueryVariables>(
      gql`
        query FetchMarketTotalAmount($market: String!) {
          public_dbt_dim_total_volume_by_market(
            where: { market: { _eq: $market } }
          ) {
            volume_in_usd
          }
        }
      `,
      { market },
    ),
    res => ({
      totalTradeVolumeInUsd:
        res.data.public_dbt_dim_total_volume_by_market?.[0] == null
          ? 0
          : Number(
              res.data.public_dbt_dim_total_volume_by_market[0].volume_in_usd,
            ),
    }),
  )
}
