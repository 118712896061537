import * as btc from "@scure/btc-signer"
import { checkNever } from "./types"

export type BitcoinNetwork = "mainnet" | "testnet"

export type AddressTypeKnown = "p2pkh" | "p2sh" | "p2wpkh" | "p2wsh" | "p2tr"
export type AddressType = AddressTypeKnown | "unknown"
export function getAddressType(
  network: BitcoinNetwork,
  address: string,
): AddressType {
  if (isP2PKHAddress(network, address)) {
    return "p2pkh"
  } else if (isP2SHAddress(network, address)) {
    return "p2sh"
  } else if (isP2WPKHAddress(network, address)) {
    return "p2wpkh"
  } else if (isP2WSHAddress(network, address)) {
    return "p2wsh"
  } else if (isP2TRAddress(network, address)) {
    return "p2tr"
  } else {
    return "unknown"
  }
}

export function isP2PKHAddress(
  network: BitcoinNetwork,
  address: string,
): boolean {
  if (network === "mainnet") {
    return address.startsWith("1")
  } else if (network === "testnet") {
    return address.startsWith("m") || address.startsWith("n")
  } else {
    checkNever(network)
    return false
  }
}

export function isP2SHAddress(
  network: BitcoinNetwork,
  address: string,
): boolean {
  if (network === "mainnet") {
    return address.startsWith("3")
  } else if (network === "testnet") {
    return address.startsWith("2")
  } else {
    checkNever(network)
    return false
  }
}

export function isP2WPKHAddress(
  network: BitcoinNetwork,
  address: string,
): boolean {
  if (network === "mainnet") {
    return address.startsWith("bc1q")
  } else if (network === "testnet") {
    return address.startsWith("tb1q")
  } else {
    checkNever(network)
    return false
  }
}

export function isP2TRAddress(
  network: BitcoinNetwork,
  address: string,
): boolean {
  if (network === "mainnet") {
    return address.startsWith("bc1p")
  } else if (network === "testnet") {
    return address.startsWith("tb1p")
  } else {
    checkNever(network)
    return false
  }
}

export function isP2WSHAddress(
  network: BitcoinNetwork,
  address: string,
): boolean {
  if (isP2TRAddress(network, address)) {
    return false
  }
  if (isP2WPKHAddress(network, address)) {
    return false
  }

  if (network === "mainnet") {
    return address.startsWith("bc1")
  } else if (network === "testnet") {
    return address.startsWith("tb1")
  } else {
    checkNever(network)
    return false
  }
}

export function getP2TRInternalPublicKey(
  network: BitcoinNetwork,
  publicKey: Uint8Array,
): Uint8Array {
  // function ecdsaPublicKeyToSchnorr(pubKey: Uint8Array): Uint8Array {
  const ecdsaPublicKeyLength = 33

  if (publicKey.byteLength !== ecdsaPublicKeyLength) {
    throw new Error("Invalid public key length")
  }

  return publicKey.slice(1)
  // }
}

export function getTapInternalKeyOfP2TRPublicKey(
  network: BitcoinNetwork,
  publicKey: Uint8Array,
): Uint8Array {
  return btc.p2tr(
    getP2TRInternalPublicKey(network, publicKey),
    undefined,
    getBtcNetwork(network),
  ).tapInternalKey
}

function getBtcNetwork(network: BitcoinNetwork): typeof btc.NETWORK {
  return network === "mainnet" ? btc.NETWORK : btc.TEST_NETWORK
}
