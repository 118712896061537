import copy from "copy-to-clipboard"
import { FC, useState } from "react"
import { useIntl } from "react-intl"
import { copied$t } from "../../../commonIntlMessages"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { SelectTokenModalContent } from "../../../components/SelectTokenModalContent/SelectTokenModalContent"
import { Spensor } from "../../../components/Spensor"
import { useMessage } from "../../../components/message/MessageProvider"
import { usePathGenerator } from "../../../routes/routes"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { BRC20Currency } from "../../../utils/alexjs/Currency"
import { sleep } from "../../../utils/promiseHelpers"
import { useEffectWithRef } from "../../../utils/reactHelpers/useEffectWithRef"
import { waitFor } from "../../../utils/waitFor"
import { DepositBrc20GuideModalContent } from "../components/BRC20/DepositBrc20GuideModalContent/DepositBrc20GuideModalContent"
import { DepositBrc20SubmitTransactionIdModalContent } from "../components/BRC20/DepositBrc20SubmitTransactionIdModalContent/DepositBrc20SubmitTransactionIdModalContent"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredPegInBrc20Modal: FC = () => {
  const { $t } = useIntl()
  const currencyStore = useCurrencyStore()
  const store = useOrderbookStore()
  const [addingTx, setAddingTx] = useState(false)
  const brc20Pegging = store.brc20Pegging
  const pegInForm = brc20Pegging.pegInForm
  const msg = useMessage()
  const g = usePathGenerator()

  const visible = store.depositFlow.pegInBrc20TokensModalVisible
  useEffectWithRef<boolean>(
    memoRef => {
      if (memoRef.current != null && !visible && memoRef.current !== visible) {
        pegInForm.reset()
      }
      memoRef.current = visible
    },
    [pegInForm, visible],
  )

  return (
    <>
      <Modal
        visible={visible}
        onClose={() => store.depositFlow.onFinishedDepositBrc20Tokens()}
      >
        <Spensor>
          {() => (
            <DepositBrc20GuideModalContent
              recentPegInRequests={suspenseResource(
                () => brc20Pegging.recentPegInRequests$,
              )}
              onSubmitNewTxRecord={() => {
                setAddingTx(true)
              }}
              queuingPegRequestCount={suspenseResource(
                () => brc20Pegging.queuedTransactionCount$,
              )}
              brc20ToBtcAddress={suspenseResource(
                () => brc20Pegging.pegInAlexOfficialBtcAddress$,
              )}
              targetStxAddress={suspenseResource(
                () => brc20Pegging.pegInUserStxAddress$,
              )}
              onCopyBrc20ToBtcAddress={async () => {
                if (
                  copy(
                    await waitFor(
                      () => brc20Pegging.pegInAlexOfficialBtcAddress$,
                    ),
                  )
                ) {
                  msg.show({ message: $t(copied$t) })
                }
              }}
              onClose={() => store.depositFlow.onFinishedDepositBrc20Tokens()}
            />
          )}
        </Spensor>
      </Modal>
      <Modal visible={addingTx} onClose={() => setAddingTx(false)}>
        <Spensor>
          {() => (
            <DepositBrc20SubmitTransactionIdModalContent
              amount={pegInForm.amount.get() ?? null}
              onChangeAmount={a => pegInForm.amount.set(a ?? undefined)}
              onChangeSelectedToken={() => pegInForm.onStartSelectToken()}
              inscriptionId={pegInForm.inscriptionNumber.get() ?? ""}
              onChangeInscriptionId={a =>
                pegInForm.inscriptionNumber.set(a ?? undefined)
              }
              selectedToken={suspenseResource(
                () => pegInForm.selectedTokenInfo$,
              )}
              pegInFeeToken={suspenseResource(() => pegInForm.pegInFeeToken$)}
              pegInFeeRate={suspenseResource(() => pegInForm.pegInFeeRate$)}
              pegInFeeAmount={suspenseResource(() => pegInForm.pegInFeeAmount$)}
              onSubmit={async () => {
                const a = Result.maybeValue(pegInForm.formData$)
                if (!a) return
                await store.brc20Pegging.pegInToken(a).then(async () => {
                  store.brc20Pegging.pegInForm.reset()
                  setAddingTx(false)
                  await sleep(1000)
                  store.brc20Pegging.refreshForMempool.set(Math.random())
                })
              }}
              onClose={() => setAddingTx(false)}
              error={suspenseResource(() =>
                Result.maybeError(pegInForm.formData$),
              )}
            />
          )}
        </Spensor>
      </Modal>
      <Modal
        visible={pegInForm.isSelectingToken}
        onClose={() => pegInForm.onCancelledSelectToken()}
      >
        <Spensor fallback={<LoadingIndicator className="m-auto" />}>
          {() => (
            <SelectTokenModalContent
              commonBasesTokens={pegInForm.availablePegInCurrencies$
                .slice(0, 6)
                .map(c => ({
                  tokenInfo: currencyStore.getTokenInfo$(c),
                }))}
              allTokens={pegInForm.availablePegInCurrencies$.map(c => ({
                tokenInfo: currencyStore.getTokenInfo$(c),
              }))}
              tokenListLink={g.b20TokenList()}
              onSelect={currency => {
                pegInForm.onSelectedToken(
                  TokenInfoPresets.toCurrency(currency) as BRC20Currency,
                )
              }}
              onClose={() => pegInForm.onCancelledSelectToken()}
            />
          )}
        </Spensor>
      </Modal>
    </>
  )
}
