import clsx from "clsx"
import { FC, useState } from "react"
import { SimplerPagination } from "../../../../components/SimplerPagination"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { LotteryTicket } from "../types"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"

export const LotteryTicketCardGrid: FC<{
  className?: string
  prizeToken: TokenInfo
  lotteryTickets: LotteryTicket[]
}> = props => {
  const recordCountPerPage = 9

  const [currPage, setCurrPage] = useState(0)

  return (
    <>
      <ul
        className={clsx(
          "flex flex-wrap justify-center gap-2.5",
          props.className,
        )}
      >
        {props.lotteryTickets
          .slice(
            currPage * recordCountPerPage,
            (currPage + 1) * recordCountPerPage,
          )
          .map((t, idx) => (
            <li key={idx}>
              <LotteryTicketCard
                prizeToken={props.prizeToken}
                lotteryTicket={t}
              />
            </li>
          ))}
      </ul>

      {props.lotteryTickets.length > recordCountPerPage && (
        <SimplerPagination
          className={"w-full"}
          currentPage={currPage}
          recordCountPerPage={recordCountPerPage}
          recordCountTotal={props.lotteryTickets.length}
          onChange={({ page }) => setCurrPage(page)}
        />
      )}
    </>
  )
}
