import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { Card } from "../../components/Card"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { IS_MAIN_NET } from "../../config"
import { suspenseResource } from "../../utils/SuspenseResource"
import { LotteryRecordsTable } from "./component/LotteryRecordsTable"
import {
  LaunchPadProvider,
  useLaunchPadContractStore,
} from "./store/useLaunchPadStores"

const WiredLotteryListPageContent: FC = () => {
  const store = useLaunchPadContractStore()

  const { lotteryListViewModule } = store

  return (
    <Card>
      <LotteryRecordsTable
        hideResultColumn={IS_MAIN_NET}
        currentPage={lotteryListViewModule.currentPage}
        recordCountPerPage={lotteryListViewModule.recordCountPerPage}
        recordCountTotal={suspenseResource(
          () => lotteryListViewModule.recordCountTotal,
        )}
        records={suspenseResource(() => lotteryListViewModule.records)}
        onChangePage={info => lotteryListViewModule.onChangePage(info)}
      />
    </Card>
  )
}

export const LaunchpadLotteryListPage: FC = () => {
  const urlSlug = useParams<"urlSlug">().urlSlug!
  const { $t } = useIntl()

  return (
    <LaunchPadProvider urlSlug={urlSlug}>
      <PageStackPage
        title={
          <PrimaryPageTitle>
            {$t(
              defineMessage({
                defaultMessage: "Lottery List",
                description: "/Launchpad/LotteryList/Primary title",
              }),
            )}
          </PrimaryPageTitle>
        }
      >
        <WiredLotteryListPageContent />
      </PageStackPage>
    </LaunchPadProvider>
  )
}
