import { action } from "mobx"
import { FC } from "react"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../components/Modal"
import { Spensor } from "../../components/Spensor"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { suspenseResource } from "../../utils/SuspenseResource"
import { BorrowRolloverModalContent } from "./components/borrowPage/BorrowRolloverModalContent/BorrowRolloverModalContent"
import { CollateralRebalanceDetailModalContent } from "./components/borrowPage/CollateralRebalanceDetailModalContent/CollateralRebalanceDetailModalContent"
import { ConfirmClaimModalContent } from "./components/borrowPage/ConfirmClaimModalContent/ConfirmClaimModalContent"
import {
  BorrowRecord,
  MyBorrowsPanel,
} from "./components/borrowPage/MyBorrowsPanel/MyBorrowsPanel"
import { useLendStore } from "./store/useLendStore"

export const WiredMyBorrowsPanel: FC = () => {
  const store = useLendStore()
  const chain = useChainStore()
  const currency = useCurrencyStore()
  const uTokenInfo = currency.getTokenInfo$(store.underlyingToken)
  const collateralInfo = currency.getTokenInfo$(store.collateralToken)
  const myBorrow = store.myBorrow
  return (
    <>
      <MyBorrowsPanel
        defaultCollapsed={store.keyTokenBalance$ === 0}
        borrowRecords={store.keyTokenAmount$
          .slice()
          .sort((a, b) => b.expiry - a.expiry)
          .map((e): BorrowRecord => {
            const common: BorrowRecord.Common = {
              onClickHourlyStat: action(
                () =>
                  (myBorrow.viewCollateralRebalanceHourlyStats = {
                    expiry: e.expiry,
                    page: 0,
                  }),
              ),
              collateralIntrinsicValue: {
                token: uTokenInfo,
                count: suspenseResource(() => {
                  const { collateralPerShare, uTokenPerShare } =
                    myBorrow.collateralBreakdown$(e.expiry)
                  return (
                    collateralPerShare *
                      e.amount *
                      myBorrow.collateralPricedInUnderlying$ +
                    uTokenPerShare * e.amount
                  )
                }),
              },
              borrowed: [{ token: uTokenInfo, count: e.amount }],
              collateral: [
                {
                  token: collateralInfo,
                  count:
                    myBorrow.collateralBreakdown$(e.expiry).collateralPerShare *
                    e.amount,
                },
                {
                  token: uTokenInfo,
                  count:
                    myBorrow.collateralBreakdown$(e.expiry).uTokenPerShare *
                    e.amount,
                },
              ],
            }
            if (e.expiry >= store.currentExpiry$) {
              return {
                ...common,
                status: "active",
                expiredAt: chain.estimatedDateForBlock$(store.currentExpiry$),
              }
            }
            return {
              ...common,
              status: "expired",
              estimateClaimable: [
                {
                  token: collateralInfo,
                  count:
                    myBorrow.collateralBreakdown$(e.expiry)
                      .estCollateralPerKeyToken * e.amount,
                },
                {
                  token: uTokenInfo,
                  count:
                    myBorrow.collateralBreakdown$(e.expiry)
                      .estUTokenPerKeyToken * e.amount,
                },
              ],
              estimateClaimableIntrinsicValue: {
                token: uTokenInfo,
                count: suspenseResource(() => {
                  const { estCollateralPerKeyToken, estUTokenPerKeyToken } =
                    myBorrow.collateralBreakdown$(e.expiry)
                  return (
                    estCollateralPerKeyToken *
                      e.amount *
                      myBorrow.collateralPricedInUnderlying$ +
                    estUTokenPerKeyToken * e.amount
                  )
                }),
              },
              onClaim: action(() => {
                myBorrow.expiryToClaim = e.expiry
              }),
              onRollover: action(() => {
                myBorrow.expiryToRoll = e.expiry
              }),
            }
          })}
      />
      <WiredTransactionStateDialog store={myBorrow.txStore} />
      <Modal
        visible={myBorrow.expiryToClaim != null}
        onClose={action(() => (myBorrow.expiryToClaim = undefined))}
      >
        <Spensor>
          {() => {
            if (myBorrow.expiryToClaim == null) {
              return null
            }
            const amount = myBorrow.keyTokenBalance$(myBorrow.expiryToClaim)
            const { estUTokenPerKeyToken, estCollateralPerKeyToken } =
              myBorrow.collateralBreakdown$(myBorrow.expiryToClaim)
            return (
              <ConfirmClaimModalContent
                claimAssets={[
                  {
                    token: uTokenInfo,
                    count: estUTokenPerKeyToken * amount,
                  },
                  {
                    token: collateralInfo,
                    count: estCollateralPerKeyToken * amount,
                  },
                ]}
                borrowedAssets={{
                  token: uTokenInfo,
                  count: amount,
                }}
                onClose={action(() => (myBorrow.expiryToClaim = undefined))}
                onConfirm={() => myBorrow.claim()}
              />
            )
          }}
        </Spensor>
      </Modal>
      <Modal
        visible={myBorrow.viewCollateralRebalanceHourlyStats != null}
        onClose={action(
          () => (myBorrow.viewCollateralRebalanceHourlyStats = undefined),
        )}
      >
        <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
          {() => {
            const hourlyStats = myBorrow.viewCollateralRebalanceHourlyStats
            if (hourlyStats == null) {
              return null
            }
            return (
              <CollateralRebalanceDetailModalContent
                records={suspenseResource(() => {
                  const keyBalance = myBorrow.keyTokenBalance$(
                    hourlyStats.expiry,
                  )
                  return myBorrow
                    .hourlyStatRecord(hourlyStats.expiry, hourlyStats.page)
                    .value$.map(row => ({
                      time: new Date(row.hour * 1000),
                      estimateValue: {
                        token: uTokenInfo,
                        count: row.valuePerShare * keyBalance,
                      },
                      myCollateral: [
                        {
                          token: collateralInfo,
                          count: row.collateralPerShare * keyBalance,
                        },
                        {
                          token: uTokenInfo,
                          count: row.underlyingPerShare * keyBalance,
                        },
                      ],
                    }))
                })}
                paginationInfo={{
                  currentPage: hourlyStats.page,
                  recordCountPerPage: 20,
                  recordCountTotal: suspenseResource(
                    () =>
                      myBorrow.hourlyStatTotalRecord(hourlyStats.expiry).value$,
                  ),
                }}
                onClose={action(
                  () =>
                    (myBorrow.viewCollateralRebalanceHourlyStats = undefined),
                )}
                onPageNumChange={action(page => (hourlyStats.page = page))}
              />
            )
          }}
        </Spensor>
      </Modal>
      <WiredBorrowRolloverModalContent />
    </>
  )
}

const WiredBorrowRolloverModalContent: FC = () => {
  const store = useLendStore()
  const chain = useChainStore()
  return (
    <>
      <Modal
        visible={store.myBorrow.expiryToRoll != null}
        onClose={action(() => (store.myBorrow.expiryToRoll = undefined))}
      >
        <Spensor>
          {() => {
            return (
              <BorrowRolloverModalContent
                flashLoanFee={suspenseResource(
                  () => store.myBorrow.rollOverFlashLoadFee$,
                )}
                expirationDate={suspenseResource(() =>
                  chain.estimatedDateForBlock$(store.currentExpiry$),
                )}
                expirationBlockHeight={suspenseResource(
                  () => store.currentExpiry$,
                )}
                estimateAbsoluteInterest={suspenseResource(
                  () => store.myBorrow.rollOverEstAbsoluteInterestFee$,
                )}
                interestToken={store.underlyingTokenInfo$}
                liquidityProviderFee={suspenseResource(
                  () => store.myBorrow.rollOverLiquidityProviderFee$,
                )}
                crpInfo={[
                  {
                    token: store.underlyingTokenInfo$,
                    percentage: suspenseResource(
                      () => store.addBorrow.collateralBreakdown$.underlying,
                    ),
                  },
                  {
                    token: store.collateralTokenInfo$,
                    percentage: suspenseResource(
                      () => store.addBorrow.collateralBreakdown$.collateral,
                    ),
                  },
                ]}
                // slippage={
                //   store.myBorrow.expiryToRollSlippage.slippagePercentage
                // }
                // onEditSlippage={() =>
                //   store.myBorrow.expiryToRollSlippage.showSlippagePopModal()
                // }
                onClose={action(
                  () => (store.myBorrow.expiryToRoll = undefined),
                )}
                onConfirm={() => store.myBorrow.rollOver()}
              />
            )
          }}
        </Spensor>
      </Modal>
      {/*<WiredSlippageModal*/}
      {/*  slippagePercent={store.myBorrow.expiryToRollSlippage}*/}
      {/*/>*/}
      <WiredTransactionStateDialog store={store.myBorrow.rollOverTxStore} />
    </>
  )
}
