import { GetAddressesResponse } from "@btckit/types"
import { hexToBytes } from "@stacks/common"
import {
  createFungiblePostCondition,
  FungibleConditionCode,
} from "@stacks/transactions"
import BigNumber from "bignumber.js"
import { unwrapResponse } from "clarity-codegen"
import { clamp, range } from "lodash"
import { CORS_PROXY_PREFIX } from "../../../../config"
import { ContractCallSender } from "../../../../generated/smartContractHelpers/asSender"
import { BRC20Currency, Currency } from "../../../../utils/alexjs/Currency"
import {
  currencyScale,
  getAssetIdentifierByCurrency,
  percentageScale,
} from "../../../../utils/alexjs/currencyHelpers"
import { transfer } from "../../../../utils/alexjs/postConditions"
import { hasAny, last } from "../../../../utils/arrayHelpers"
import pMemoize from "../../../../utils/pMemorize"
import { isNotNull } from "../../../../utils/utils"
import {
  PegInRequest,
  PegInRequestStatus,
} from "../../components/BRC20/DepositBrc20GuideModalContent/DepositBrc20GuideModalContent"
import { OrderbookAsset } from "../OrderbookStore.service/OrderbookStore.service"
import {
  getAuthSignatureFromWallet,
  TemporarilyStoredSignInfoForInitialDeposit,
} from "../stxdx_shared/StxDxMyInfoModule.service"

export const initialSkipPegInAdditionalChecking =
  window.location.search.includes("__5K1P_P3G_1N_CH3CK1NG__")

export function isOrdinalWalletAddress(addr: string): boolean {
  return addr.startsWith("bc1p") || addr.startsWith("tb1p")
}

export const getQueuedPegRequestCount = async (
  sender: ContractCallSender,
): Promise<number> => {
  return sender
    .contract("b20-bridge-endpoint")
    .func("get-pending-requests")
    .call({})
    .then(a => a)
}

export const getPegInBtcAddress = async (
  sender: ContractCallSender,
  stxAddress: string,
): Promise<undefined | string> => {
  const res = await sender
    .contract("b20-bridge-endpoint")
    .func("get-user-peg-in-address-or-fail")
    .call({
      user: stxAddress,
    })

  if (res.type === "error") return

  return unwrapResponse(res)
}

export const getCandidateBtcAddresses = async (): Promise<string[]> => {
  if (window.btc == null) return []

  const res: GetAddressesResponse = await window.btc.request("getAddresses")
  if ("error" in res) return []

  return res.result.addresses
    .filter((addr: any) => isOrdinalWalletAddress(addr.address))
    .map((a: any) => a.address)
}

export const checkInscriptionTransferable = pMemoize(
  async (
    inscriptionNumber: number,
    receivingAddress: undefined | string,
    receivingAmount: number,
  ): Promise<boolean> => {
    try {
      const inscriptionReqRes = await fetch(
        `${CORS_PROXY_PREFIX}https://ordapi.bestinslot.xyz/v1/get_inscription_with_number/${inscriptionNumber}`,
        { mode: "cors" },
      )
      const inscriptionDataArray: {
        wallet_addr: string
        content_type: string
        image_url: string
        transfers: {
          from: null | string
          to: string
          // ...
        }[]
        // ...
      }[] = await inscriptionReqRes.json()
      if (!hasAny(inscriptionDataArray)) return false
      const [inscriptionData] = inscriptionDataArray
      if (
        inscriptionData.content_type !== "text/plain" &&
        !inscriptionData.content_type.startsWith("text/plain;")
      ) {
        return false
      }

      const contentReqRes = await fetch(inscriptionData.image_url)
      const contentData = await contentReqRes.json()
      if (
        contentData.p == null ||
        contentData.op == null ||
        contentData.amt == null
      ) {
        return false
      }
      if (contentData.p !== "brc-20") return false
      if (contentData.op !== "transfer") return false
      if (contentData.amt !== String(receivingAmount)) return false

      // if the transfer inscription not successfully minted, the inscription id
      // may change after the tx confirmed, so it should be rejected
      if (!hasAny(inscriptionData.transfers)) return false

      // https://domo-2.gitbook.io/brc-20-experiment/#transferring-a-balance
      const isInscriptionLapsed = inscriptionData.transfers.length > 1
      if (isInscriptionLapsed) return false

      /**
       * * https://t.me/c/1599543687/31251
       * * https://t.me/c/1599543687/31258
       */
      const isInscriptionTransferable =
        last(inscriptionData.transfers).to === inscriptionData.wallet_addr
      return isInscriptionTransferable
    } catch {
      return false
    }
  },
  { skipCaching: true },
)

export const getTokenFees = async (
  sender: ContractCallSender,
  token: BRC20Currency,
): Promise<{
  pegInFeeRate: number
  pegOutFeeRate: number
  pegOutGasFee: number
}> => {
  return sender
    .contract("b20-bridge-endpoint")
    .func("get-token-details-or-fail")
    .call({ token })
    .then(unwrapResponse)
    .then(a => ({
      pegInFeeRate: a["peg-in-fee"] / percentageScale,
      pegOutFeeRate: a["peg-out-fee"] / percentageScale,
      pegOutGasFee: a["peg-out-gas-fee"] / currencyScale(Currency.sUSDT),
    }))
}

export const getRecentPegInRecords = async (
  sender: ContractCallSender,
): Promise<
  (Omit<PegInRequest, "token" | "pegInAddress"> & {
    currency: OrderbookAsset
  })[]
> => {
  const processProgress = await sender
    .contract("b20-bridge-endpoint")
    .func("get-requests-processing-progress")
    .call({})

  // get at least n requests from the queue tail, and should include all unprocessed requests
  const atLeastRequestCount = 500
  const requestIds = range(
    clamp(
      processProgress["requests-applied"] - atLeastRequestCount,
      1,
      processProgress["requests-processed"],
    ),
    processProgress["requests-applied"] + 1,
  ).reverse()

  return sender
    .contract("b20-bridge-endpoint")
    .func("get-request-by-tx-sender-many")
    .call({ "requests-id": requestIds })
    .then(async resp => {
      return resp
        .map((r, idx): undefined | (typeof r & { id: string }) =>
          r == null ? r : { id: String(requestIds[idx]!), ...r },
        )
        .filter(isNotNull)
        .filter(a => a["peg-in"])
        .map(req => ({
          id: req.id,
          currency: req.token as OrderbookAsset,
          pegInTransactionId: req.memo,
          amount: req.amount / currencyScale(req.token as OrderbookAsset),
          status: req.expired
            ? PegInRequestStatus.Rejected
            : req.sent
            ? PegInRequestStatus.Processed
            : PegInRequestStatus.Pending,
        }))
    })
}

export const generatePegInBtcAddress = async (
  sender: ContractCallSender,
  publicKey?: string,
): Promise<{ txId: string }> => {
  if (publicKey != null) {
    return sender
      .contract("b20-bridge-endpoint")
      .func("register-stxdx-user-by-tx-sender")
      .call(
        {
          "pub-key": hexToBytes(publicKey),
        },
        [],
      )
  }
  return sender
    .contract("b20-bridge-endpoint")
    .func("register-user-by-tx-sender")
    .call({}, [])
}

export const requestPegIn = async (
  sender: ContractCallSender,
  stacksAddress: string,
  hasRegisteredForStxDx: boolean,
  hasRegisteredForB20PegIn: boolean,
  reqInfo: {
    inscriptionNumber: string
    currency: BRC20Currency
    amount: number
  },
): Promise<{ txId: string }> => {
  let res: { txId: string }
  if (!hasRegisteredForStxDx) {
    const result = await getAuthSignatureFromWallet(
      stacksAddress,
      Math.floor(60 * 60 * 5 + Date.now() / 1000),
    )
    TemporarilyStoredSignInfoForInitialDeposit.saveSig(
      result.payload,
      result.signature,
    )
    res = await sender
      .contract("b20-bridge-endpoint-helper")
      .func("register-stxdx-and-request-peg-in")
      .call(
        {
          "pub-key": hexToBytes(result.publicKey),
          token: reqInfo.currency,
          amount: reqInfo.amount * currencyScale(reqInfo.currency),
          memo: reqInfo.inscriptionNumber,
        },
        [],
        {
          sponsored: true,
        },
      )
  } else if (!hasRegisteredForB20PegIn) {
    res = await sender
      .contract("b20-bridge-endpoint-helper")
      .func("register-and-request-peg-in")
      .call(
        {
          token: reqInfo.currency,
          amount: reqInfo.amount * currencyScale(reqInfo.currency),
          memo: reqInfo.inscriptionNumber,
        },
        [],
        {
          sponsored: true,
        },
      )
  } else {
    res = await sender
      .contract("b20-bridge-endpoint")
      .func("request-peg-in")
      .call(
        {
          token: reqInfo.currency,
          amount: reqInfo.amount * currencyScale(reqInfo.currency),
          memo: reqInfo.inscriptionNumber,
        },
        [],
        {
          sponsored: true,
        },
      )
  }
  return {
    txId: res.txId,
  }
}

export const requestPegOut = async (
  sender: ContractCallSender,
  senderStxAddr: string,
  currency: BRC20Currency,
  amount: number,
  toBtcAddress: string,
  gasFeeCurrency: Currency,
  gasFeeAmount: number,
  hasRegisteredForB20PegIn: boolean,
): Promise<{ txId: string }> => {
  if (hasRegisteredForB20PegIn) {
    return await sender
      .contract("b20-bridge-endpoint")
      .func("request-peg-out")
      .call(
        {
          "token-trait": currency,
          amount: new BigNumber(amount)
            .multipliedBy(currencyScale(currency))
            .decimalPlaces(0, BigNumber.ROUND_DOWN)
            .toString() as any,
          "peg-out-address": toBtcAddress,
        },
        [
          // https://t.me/c/1599543687/34122
          (currency as Currency) === Currency.ORDINALS_BLUEWHEEL
            ? createFungiblePostCondition(
                senderStxAddr,
                FungibleConditionCode.Equal,
                amount.toString(),
                getAssetIdentifierByCurrency(currency),
              )
            : transfer(senderStxAddr, currency, amount),
          transfer(senderStxAddr, gasFeeCurrency, gasFeeAmount),
        ],
      )
  }
  return await sender
    .contract("b20-bridge-endpoint-helper")
    .func("register-and-request-peg-out")
    .call(
      {
        "token-trait": currency,
        amount: new BigNumber(amount)
          .multipliedBy(currencyScale(currency))
          .decimalPlaces(0, BigNumber.ROUND_DOWN)
          .toString() as any,
        "peg-out-address": toBtcAddress,
      },
      [
        // https://t.me/c/1599543687/34122
        (currency as Currency) === Currency.ORDINALS_BLUEWHEEL
          ? createFungiblePostCondition(
              senderStxAddr,
              FungibleConditionCode.Equal,
              amount.toString(),
              getAssetIdentifierByCurrency(currency),
            )
          : transfer(senderStxAddr, currency, amount),
        transfer(senderStxAddr, gasFeeCurrency, gasFeeAmount),
      ],
    )
}
