import clsx from "clsx"
import { defineMessage, useIntl } from "react-intl"
import { PercentNumber } from "../../../../components/PercentNumber"
import { SegmentControl } from "../../../../components/SegmentControl"
import { Spensor } from "../../../../components/Spensor"
import { TabBar } from "../../../../components/TabBar"
import { ExclamationOutlinedIcon } from "../../../../components/TipIcon/ExclamationOutlinedIcon"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import { FCC } from "../../../../utils/reactHelpers/types"
import { orderDirection$t, orderType$t } from "../_/commonIntlMessages"
import { OrderDirection, StxDxOrderType } from "../types"

export interface CreateOrderSwitchersProps {
  className?: string
  gapClassName?: string

  orderDirection?: OrderDirection
  onChangeOrderDirection?: null | ((newMode: OrderDirection) => void)

  orderType: StxDxOrderType
  onChangeOrderType: (newType: StxDxOrderType) => void

  feeRates: SuspenseResource<{
    maker: number
    taker: number
  }>
}

export const CreateOrderSwitchers: FCC<CreateOrderSwitchersProps> = props => {
  const intl = useIntl()
  const { $t } = intl

  return (
    <div
      className={clsx(
        "flex flex-col",
        props.gapClassName ?? "gap-y-4",
        props.className,
      )}
    >
      {props.onChangeOrderDirection != null && (
        <SegmentControl
          className={"w-full"}
          boxClassName={"p-1"}
          segmentBoxClassName={"flex-1 min-h-[32px]"}
          segmentTextClassName={"text-sm leading-5 font-medium capitalize"}
          controls={[
            {
              title: $t(orderDirection$t.buy),
              isActive: props.orderDirection === "buy",
              onClick: () => props.onChangeOrderDirection?.("buy"),
            },
            {
              title: $t(orderDirection$t.sell),
              isActive: props.orderDirection === "sell",
              onClick: () => props.onChangeOrderDirection?.("sell"),
            },
          ]}
        />
      )}

      <TabBar
        tabClassName={"px-4 py-2.5 text-sm leading-5 font-semibold"}
        tabs={[
          { label: $t(orderType$t.limit), value: StxDxOrderType.Limit },
          { label: $t(orderType$t.market), value: StxDxOrderType.Market },
          // { label: $t(orderType$t.stopLimit), value: StxDxOrderType.StopLimit },
        ]}
        selectedTab={{
          tabValue: props.orderType,
          onChange: tab => props.onChangeOrderType?.(tab.value),
        }}
        rightSide={
          <TipIcon
            className={"ml-auto self-center"}
            Icon={ExclamationOutlinedIcon}
            tip={
              <span
                className={"text-xs leading-4 font-medium text-gray-200/70"}
              >
                <Spensor fallback={"..."}>
                  {() =>
                    $t(
                      defineMessage({
                        defaultMessage:
                          "Maker {makerFeeRate} / Taker {makerFeeRate}",
                        description:
                          "Orderbook/Create Order Panel/fee rate tooltip",
                      }),
                      {
                        makerFeeRate: (
                          <span className={"font-semibold text-gray-200"}>
                            <PercentNumber
                              number={readResource(props.feeRates).maker}
                              precision={3}
                            />
                          </span>
                        ),
                        takerFeeRate: (
                          <span className={"font-semibold text-gray-200"}>
                            <PercentNumber
                              number={readResource(props.feeRates).taker}
                              precision={3}
                            />
                          </span>
                        ),
                      },
                    )
                  }
                </Spensor>
              </span>
            }
          />
        }
      />
    </div>
  )
}
