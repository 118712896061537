import clsx from "clsx"
import { ComponentType, ReactNode } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { PlainIconButton } from "../button/variants/PlainIconButton"
import { BlueGradientAlert } from "./BlueGradientAlert"
import { AlertCommonProps } from "./types"

export const AlertLayoutAsBanner: FCC<{
  className?: string

  /**
   * @default BlueGradientAlert
   */
  Alert?: ComponentType<AlertCommonProps>

  icon?: ReactNode

  onClose?: null | (() => void)
}> = props => {
  const { Alert = BlueGradientAlert } = props

  return (
    <Alert className={clsx("flex items-center gap-2.5", props.className)}>
      {props.icon}

      <div className={"flex-1"}>{props.children}</div>

      {props.onClose && (
        <PlainIconButton
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#C4C4C4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.87306 7.75405L14.792 2.7973C15.019 2.57027 15.019 2.22973 14.792 2.0027L14.0352 1.20811C13.8082 0.981081 13.4677 0.981081 13.2406 1.20811L8.28387 6.16486C8.13252 6.31622 7.9055 6.31622 7.75415 6.16486L2.79739 1.17027C2.57036 0.943243 2.22982 0.943243 2.00279 1.17027L1.2082 1.96486C0.981172 2.19189 0.981172 2.53243 1.2082 2.75946L6.16496 7.71622C6.31631 7.86757 6.31631 8.09459 6.16496 8.24594L1.17036 13.2405C0.943335 13.4676 0.943335 13.8081 1.17036 14.0351L1.96496 14.8297C2.19198 15.0568 2.53252 15.0568 2.75955 14.8297L7.71631 9.87297C7.86766 9.72162 8.09469 9.72162 8.24604 9.87297L13.2028 14.8297C13.4298 15.0568 13.7704 15.0568 13.9974 14.8297L14.792 14.0351C15.019 13.8081 15.019 13.4676 14.792 13.2405L9.87306 8.28378C9.72171 8.13243 9.72171 7.9054 9.87306 7.75405Z" />
            </svg>
          }
          onClick={() => props.onClose?.()}
        />
      )}
    </Alert>
  )
}
