import { FC } from "react"
import { NavLink } from "../NavLink"
import { ButtonProps } from "./Button"
import { ButtonVariantProps } from "./ButtonVariant"
import { WhiteFilledButton } from "./variants/WhiteFilledButton"

/**
 * It's recommended to use `LinkedButton` instead of simply wrapping `Button`
 * with `NavLink` if there might not exist `link` in some cases.
 *
 * See more details in the below comments
 */
export const LinkedButton: FC<ButtonProps & { link?: string }> = props => {
  const { Variant = WhiteFilledButton, link, className, ...restProps } = props

  const variantProps: ButtonVariantProps = restProps

  if (link) {
    return (
      <NavLink className={className} to={link}>
        <Variant {...variantProps}>{props.children}</Variant>
      </NavLink>
    )
  } else {
    /**
     * The `to` prop of the `NavLink` is required, and it's bad to pass an empty
     * string, because the `a` tag in HTML `<a href=""><button /></a>` is still
     * clickable in browsers
     *
     * So we have to remove the `NavLink` component.
     *
     * The `span` tag is trying to prevent the flex width calculation issue with
     * padding: https://stackoverflow.com/q/33145242/1226532
     *
     *     the button variant has padding, so when we add `flex: 1` to the link
     *     tag, the button width will become larger than other `flex: 1`
     *     elements.
     */
    return (
      <span className={className}>
        <Variant {...variantProps} disabled={true}>
          {props.children}
        </Variant>
      </span>
    )
  }
}
