import { FC } from "react"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../../utils/types"
import { TriggerCondition } from "../../types"

export const TriggerConditionText: FC<{
  priceToken: TokenInfo
  triggerCondition: TriggerCondition
}> = props => (
  <>
    {
      // prettier-ignore
      props.triggerCondition.type === 'gte' ? '≥' :
      props.triggerCondition.type === 'lte' ? '≤' :
      assertNever(props.triggerCondition.type)
    }{" "}
    <TextTokenCount
      token={props.priceToken}
      count={props.triggerCondition.priceTokenCountPerTradeToken}
    />
  </>
)
