import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetDescription,
  CardInsetTitle,
} from "../../../../../components/Card"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { RedeemOrdinalsNotice } from "../../RedeemOrdinalsNotice"
import { HowLotteryWorkLink } from "./HowLotteryWorkLink"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"
import { LotteryTicket } from "./LotteryTicketCard/types"

export const ActionSectionContent$ClaimForRegistered: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTickets: LotteryTicket[]
  distributedAt?: Date
  onRedeemOrdinals?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      {props.onRedeemOrdinals != null && (
        <RedeemOrdinalsNotice onRedeem={props.onRedeemOrdinals} />
      )}

      <CardInset className={"flex flex-col items-center gap-6"}>
        <div className={"flex flex-col items-center gap-2.5"}>
          <CardInsetTitle>
            {$t(
              defineMessage({
                defaultMessage: "Your Lottery Ticket",
                description:
                  "/Launchpad/ActionSectionContent/ClaimForRegistered/Title",
              }),
            )}
          </CardInsetTitle>
          <CardInsetDescription className={"text-center"}>
            {props.distributedAt == null
              ? $t(
                  defineMessage({
                    defaultMessage:
                      "The lottery drawing is currently in progress! Thank you for your patience.",
                    description:
                      "/Launchpad/ActionSectionContent/ClaimForRegistered/Description",
                  }),
                )
              : $t(
                  defineMessage({
                    defaultMessage:
                      "The allocation of tokens is scheduled for {datetime, date, ::MMM d}.",
                    description:
                      "/Launchpad/ActionSectionContent/ClaimForRegistered/Description",
                  }),
                  { datetime: props.distributedAt },
                )}
          </CardInsetDescription>
        </div>

        <ul className={"flex flex-wrap justify-center gap-2.5"}>
          {props.lotteryTickets.map((t, idx) => (
            <li key={idx}>
              <LotteryTicketCard
                idoToken={props.idoToken}
                priceToken={props.priceToken}
                lotteryTicket={t}
              />
            </li>
          ))}
        </ul>
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "The {priceToken} submitting with your lottery ticket will be refunded if you draw a losing ticket, and will be swapped for LaunchPad tokens if you win. The {aPower} submitted is burnt either win or lose.",
              description:
                "/Launchpad/ActionSectionContent/ClaimForRegistered/Note paragraph",
            }),
            {
              priceToken: <TokenName token={props.priceToken} />,
              aPower: <TokenName token={TokenInfoPresets.APower} />,
            },
          )}
        </NoteParagraph>
        <HowLotteryWorkLink />
      </CardInset>
    </>
  )
}
