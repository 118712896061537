// export type TokenInfo = TokenInfo.SingleTokenInfo | TokenInfo.CombinedTokenInfo

export interface TokenInfo {
  id: string // in most cases, when found it will be Currency, when not found it will be Contract Address
  displayName: string
  description?: string
  precision?: number
  scale?: number
  icons: [string] | [string, string]
  propertyTags: TokenInfo.PropertyTag[]
  breakDowns?: [TokenInfo, TokenInfo]
}

export namespace TokenInfo {
  export type PropertyTag = "brc-20" | "ordinals-voucher"

  export const getPrecision = (tokenInfo?: TokenInfo): number => {
    return tokenInfo?.precision ?? 0
  }

  export const isIdentical = (
    token1: TokenInfo,
    token2: TokenInfo,
  ): boolean => {
    return token1.id === token2.id
  }
}
