import { FC, useEffect } from "react"
import { useLoading } from "../../../components/LoadingBoundary/useLoading"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { FCC } from "../../../utils/reactHelpers/types"
import { MyTradesOrderListContent } from "../components/OrderbookList/MyTradesOrderListContent"
import { OrderbookListContent } from "../components/OrderbookList/OrderbookListContent"
import { OrderbookListFrame } from "../components/OrderbookList/OrderbookListFrame"
import { TradesOrderListContent } from "../components/OrderbookList/TradesOrderListContent"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredOrderbookListContent: FC = () => {
  const store = useOrderbookStore()
  const loading = useLoading()

  useEffect(() => {
    if (
      store.orderbook.orderbookSellOrders.isRefreshing ||
      store.orderbook.orderbookBuyOrders.isRefreshing
    ) {
      loading.show()
    }
    return () => {
      loading.hide()
    }
  }, [
    loading,
    store.orderbook.orderbookBuyOrders.isRefreshing,
    store.orderbook.orderbookSellOrders.isRefreshing,
  ])

  return (
    <OrderbookListContent
      tradeToken={store.orderbook.tradeTokenInfo$}
      priceToken={store.orderbook.priceTokenInfo$}
      lastPriceTokenCountPerTradeToken={
        store.orderbook.lastPriceTokenCountPerTradeToken$
      }
      lastPriceTokenCountPerTradeTokenDelta={
        store.orderbook.lastPriceTokenCountPerTradeTokenDelta$
      }
      sellOrders={suspenseResource(
        () => store.orderbook.orderbookSellOrders.steadyValue$,
      )}
      buyOrders={suspenseResource(
        () => store.orderbook.orderbookBuyOrders.steadyValue$,
      )}
      onClickOrder={store.orderbook.onClickOrder}
    />
  )
}

export const WiredTradesOrderListContent: FC = () => {
  const store = useOrderbookStore()
  const loading = useLoading()

  useEffect(() => {
    if (store.orderbook.tradeOrders.isRefreshing) {
      loading.show()
    }
    return () => {
      loading.hide()
    }
  }, [loading, store.orderbook.tradeOrders.isRefreshing])

  return (
    <TradesOrderListContent
      tradeToken={store.orderbook.tradeTokenInfo$}
      priceToken={store.orderbook.priceTokenInfo$}
      orders={suspenseResource(() => store.orderbook.tradeOrders.steadyValue$)}
      onClickOrder={store.orderbook.onClickOrder}
    />
  )
}

export const WiredMyTradesOrderListContent: FC = () => {
  const store = useOrderbookStore()
  const loading = useLoading()

  useEffect(() => {
    if (store.orderbook.myTradeOrders.isRefreshing) {
      loading.show()
    }
    return () => {
      loading.hide()
    }
  }, [loading, store.orderbook.myTradeOrders.isRefreshing])

  return (
    <MyTradesOrderListContent
      tradeToken={store.orderbook.tradeTokenInfo$}
      priceToken={store.orderbook.priceTokenInfo$}
      orders={suspenseResource(
        () => store.orderbook.myTradeOrders.steadyValue$,
      )}
      onClickOrder={store.orderbook.onClickOrder}
    />
  )
}

export const WiredOrderbookListFrame: FCC<{ className?: string }> = props => {
  const store = useOrderbookStore()

  return (
    <OrderbookListFrame
      className={props.className}
      orderType={store.orderbook.showingOrderType}
      onChangeOrderType={store.orderbook.onChangeOrderType}
    >
      {props.children}
    </OrderbookListFrame>
  )
}
