import { computed, makeObservable } from "mobx"
import { Observable, startWith } from "rxjs"
import { ALLOW_CONTRACT_ARGUMENTATION } from "../../../../config"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { sharedLazyValue } from "../../../../stores/LazyValue/lazyValueHelpers"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { ConfirmTransactionStoreForGeneral } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStoreForGeneral"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { oneOf } from "../../../../utils/arrayHelpers"
import { combineLatestProps } from "../../../../utils/rxjsHelpers/combineLatestProps"
import { waitFor } from "../../../../utils/waitFor"
import { BitcoinClient } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClient"
import { BitcoinNetwork } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClientBRC20Wallet"
import { HiroWalletAdapter } from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/HiroWalletAdapter"
import { MockAddressWalletAdapter } from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/MockAddressWalletAdapter"
import { OkxWalletAdapter } from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/OkxWalletAdapter"
import { UnisatWalletAdapter } from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/UnisatWalletAdapter"
import type {
  WalletAdapter,
  WalletAdapterAddresses,
} from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/WalletAdapters.types"
import { XverseWalletAdapter } from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/XverseWalletAdapter"
import type { AvailableWalletType } from "../components/SelectOridinalsWalletModalContent/SelectOridinalsWalletModalContent"
import { availableWalletTypes } from "../components/SelectOridinalsWalletModalContent/SelectOridinalsWalletModalContent"
import { PegInFormModule } from "./PegInFormModule"
import { RecentTransferableModule } from "./RecentTransferableModule"

const btcNetowrk: BitcoinNetwork = "mainnet"

const previousConnectWalletLocalStorageKey =
  "alex:OrderbookPegInStore:previousConnectWallet"

export class BulkTransferInscriptionPageStore {
  constructor(
    private authStore: AuthStore,
    private chainStore: ChainStore,
    private accountStore: AccountStore,
    private currencyStore: CurrencyStore,
  ) {
    makeObservable(this)
  }

  txStore = new ConfirmTransactionStore()
  generalTxStore = new ConfirmTransactionStoreForGeneral()

  private btc = new BitcoinClient(btcNetowrk)

  availableBtcWalletAdapters = new LazyValue(
    () => [],
    () =>
      combineLatestProps<
        Partial<
          Record<AvailableWalletType, Observable<undefined | WalletAdapter>>
        >
      >({
        hiro: HiroWalletAdapter.getAdapter(btcNetowrk).pipe(
          startWith(undefined),
        ),
        xverse: XverseWalletAdapter.getAdapter(btcNetowrk).pipe(
          startWith(undefined),
        ),
        unisat: UnisatWalletAdapter.getAdapter(btcNetowrk).pipe(
          startWith(undefined),
        ),
        okx: OkxWalletAdapter.getAdapter(btcNetowrk).pipe(startWith(undefined)),
      }),
  )

  private btcWalletAdapterInfos = new SuspenseObservable<{
    adapter: WalletAdapter
    addresses: WalletAdapterAddresses
    brc20Wallet: BitcoinClientBRC20Wallet
  }>()

  @computed
  get bitcoinWalletAddresses$(): WalletAdapterAddresses {
    return this.btcWalletAdapterInfos.read$.addresses
  }

  @computed
  private get brc20Wallet$(): BitcoinClientBRC20Wallet {
    return this.btcWalletAdapterInfos.read$.brc20Wallet
  }

  async disconnectBtcWallet(): Promise<void> {
    const { adapter } = await waitFor(() => this.btcWalletAdapterInfos.read$)
    await adapter.disconnect()
    localStorage.removeItem(previousConnectWalletLocalStorageKey)
    this.btcWalletAdapterInfos.set(undefined)
  }

  get previousConnectedWallet(): AvailableWalletType | undefined {
    const type =
      localStorage.getItem(previousConnectWalletLocalStorageKey) || undefined

    if (oneOf(...availableWalletTypes)(type)) {
      return type
    }
  }

  get isConnected(): boolean {
    return this.btcWalletAdapterInfos.get() != null
  }

  async connectBtcWallet(
    adapterType: AvailableWalletType,
    adapter: WalletAdapter,
  ): Promise<void> {
    localStorage.setItem(previousConnectWalletLocalStorageKey, adapterType)

    const asOrdinalAddress = ALLOW_CONTRACT_ARGUMENTATION
      ? new URLSearchParams(window.location.search).get("asOrdinalAddress")
      : undefined

    const asBtcAddress = ALLOW_CONTRACT_ARGUMENTATION
      ? new URLSearchParams(window.location.search).get("asBtcAddress")
      : undefined

    const mockAdapter =
      asBtcAddress != null || asOrdinalAddress != null
        ? new MockAddressWalletAdapter(adapter, {
            bitcoin: asBtcAddress ?? undefined,
            ordinals: asOrdinalAddress ?? undefined,
          })
        : undefined

    const finalAdapter = mockAdapter ?? adapter
    const addresses = await finalAdapter.getAddresses()
    this.btcWalletAdapterInfos.set({
      adapter: finalAdapter,
      addresses,
      brc20Wallet: await this.btc.createBRC20Wallet(finalAdapter),
    })
  }

  pegInForm = new PegInFormModule(
    this.currencyStore,
    sharedLazyValue(() => this.btc),
    sharedLazyValue(() => this.btcWalletAdapterInfos.read$.adapter),
    sharedLazyValue(() => this.btcWalletAdapterInfos.read$.brc20Wallet),
  )

  transferable = new RecentTransferableModule(
    this.authStore,
    this.chainStore,
    this.accountStore,
    this.currencyStore,
    sharedLazyValue(() => this.btc),
    sharedLazyValue(() => this.btcWalletAdapterInfos.read$.brc20Wallet),
    sharedLazyValue(() => ""),
    this.txStore,
    this.generalTxStore,
  )
}
