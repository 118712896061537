import { FC } from "react"
import { DownOutlinedIcon } from "../../../components/DownOutlinedIcon"
import { Dropdown, DropdownItem } from "../../../components/Dropdown"
import { Segment } from "../../../components/SegmentControl"
import { Leverage } from "./types"

export const LeverageSelected: FC<{
  className?: string
  segmentBoxClassName?: string
  segmentTextClassName?: string
  leverage: Leverage
  leverages: Leverage[]
  onLeverageChange?: (ratio: Leverage) => void
}> = props => {
  return (
    <Dropdown
      triggerContainerClassName="flex-1 flex"
      dismissMethod={["click-outside", "click"]}
      triggerMethod="click"
      trigger={
        <Segment
          isActive
          className={"flex items-center justify-center"}
          boxClassName="flex-1 min-h-[32px]"
          textClassName="text-sm leading-5 font-medium capitalize"
          title={
            <p className="flex items-center">
              <p style={{ marginTop: 2, transform: "scale(.7)" }}>x</p>
              {props.leverage}
              <DownOutlinedIcon clasName={"ml-1"} />
            </p>
          }
        />
      }
    >
      {props.leverages.map(l => (
        <DropdownItem key={l} onClick={() => props.onLeverageChange?.(l)}>
          <span>{l}</span>
          <span className="ml-1">x</span>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
