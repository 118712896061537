import { computed, makeObservable } from "mobx"
import { map } from "rxjs"
import { EXPLORER_TX_URL } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import {
  LazyValue,
  SharedLazyValue,
} from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { ConfirmTransactionStoreForGeneral } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStoreForGeneral"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { BigNumber } from "../../../../utils/BigNumber"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { BRC20Currency } from "../../../../utils/alexjs/Currency"
import { DisplayableError } from "../../../../utils/error"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { sleep } from "../../../../utils/promiseHelpers"
import { assertExclude, checkNever } from "../../../../utils/types"
import { waitFor } from "../../../../utils/waitFor"
import { requestPegIn } from "../../store/modules/Brc20PeggingModule.service"
import { BRC20InscriptionStatus } from "../components/BRC20Card/BRC20InscriptionStatus.types"
import type { RecentInscriptionRecord } from "../components/InProgressPanel"
import { AvailableTokenInfo } from "../components/TransferPanel"
import { BitcoinClient } from "./BitcoinClient/BitcoinClient"
import { BRC20WalletRecentTransferableInscription } from "./BitcoinClient/BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "./BitcoinClient/BitcoinClientBRC20Wallet"
import { getTokenInfoFromBRC20Symbol$ } from "./OrderbookPegInStore.services"
import { RecentInscribeOrderStatus } from "./RecentInscribeOrderModule"
import {
  PegInRequestStatus,
  getRecentPegInRecords,
  getRecentPegInRecordsByInscriptionNumbers,
  getRecentPegInRecordsFromMempool,
} from "./RecentTransferableModule.services"

export class RecentTransferableModule {
  constructor(
    private authStore: AuthStore,
    private chainStore: ChainStore,
    private accountStore: AccountStore,
    private currencyStore: CurrencyStore,
    private btcClient: SharedLazyValue<BitcoinClient>,
    private brc20Wallet: SharedLazyValue<BitcoinClientBRC20Wallet>,
    private pegInBtcAddress: SharedLazyValue<undefined | string>,
    private recentInscribeOrderStatus: SharedLazyValue<
      RecentInscribeOrderStatus[]
    >,
    private notifyRecentInscribeOrderCompleted: (orderId: string) => void,
    private stacksTxStore: ConfirmTransactionStore,
    private generalTxStore: ConfirmTransactionStoreForGeneral,
  ) {
    makeObservable(this)
  }

  recommendedBitcoinNetworkFeeRates = new LazyValue(
    () => this.btcClient.value$,
    btcClient => btcClient.networkInfo.pipe(map(info => info.fees)),
  )

  private brc20WalletBalance = new LazyValue(
    () => ({ wallet: this.brc20Wallet.value$ }),
    ({ wallet }) => wallet.walletBalance,
  )

  @computed
  get brc20WalletBalance$(): (AvailableTokenInfo & {
    brc20Currency: BRC20Currency
    symbol: string
  })[] {
    return this.brc20WalletBalance.value$.tokens.flatMap(token => {
      const tokenInfo = getTokenInfoFromBRC20Symbol$(
        this.currencyStore,
        token.symbol,
      )

      if (tokenInfo == null) return []

      return [
        {
          brc20Currency: TokenInfoPresets.toCurrency(
            tokenInfo,
          ) as BRC20Currency,
          token: tokenInfo,
          symbol: token.symbol,
          totalBalance: BigNumber.from(token.balance),
          transferableBalance: BigNumber.from(token.transferableBalance),
          availableBalance: BigNumber.from(token.restBalance),
          peggingCount: suspenseResource(
            () =>
              this.recentAllTransferInscriptions$.filter(
                i =>
                  i.token.id === tokenInfo.id &&
                  BRC20InscriptionStatus.isInProgressStatus(i.status),
              ).length,
          ),
        },
      ]
    })
  }

  viewingBRC20Currency = new SuspenseObservable<BRC20Currency>()

  @computed get viewingBRC20Token$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.viewingBRC20Currency.read$)
  }

  private orderbookUserId = new LazyValue(
    () =>
      [this.authStore.stxAddress$, this.chainStore.currentBlockHash$] as const,
    ([stxAddress]) =>
      asSender(stxAddress)
        .contract("stxdx-registry")
        .func("get-user-id")
        .call({ user: stxAddress }),
  )

  private btcNetworkInfo = new LazyValue(
    () => this.btcClient.value$,
    btcClient => btcClient.networkInfo,
  )

  private recentTransferInscriptions = new LazyValue(
    () => ({ wallet: this.brc20Wallet.value$ }),
    ({ wallet }) => wallet.recentTransferBrc20Inscriptions,
  )

  private recentTransferInscriptionPegInRequests = new LazyValue(
    () => ({
      _block: this.btcNetworkInfo.value$.block.height,
      pegInAddress: this.pegInBtcAddress.value$,
      stxAddr: this.authStore.stxAddress$,
      inscriptions: this.recentTransferInscriptions.value$,
    }),
    ({ pegInAddress, stxAddr, inscriptions }) =>
      getRecentPegInRecordsByInscriptionNumbers(
        asSender(stxAddr),
        inscriptions.transferableInscriptions.flatMap(i =>
          i.toAddress === pegInAddress ? [i.inscriptionNumber] : [],
        ),
      ),
  )

  private refreshSignalForInscriptionTransferMempool = new SuspenseObservable(
    Math.random(),
  )
  private inscriptionTransferMempool = new LazyValue(
    () => ({
      _refresh: this.refreshSignalForInscriptionTransferMempool.read$,
      _block: this.btcNetworkInfo.value$.block.height,
      brc20Wallet: this.brc20Wallet.value$,
    }),
    ({ brc20Wallet }) => brc20Wallet.getInscriptionTransferMempool(),
  )

  private recentPegInRequests = new LazyValue(
    () => ({
      _block: this.chainStore.currentBlockHeight$,
      stxAddr: this.authStore.stxAddress$,
    }),
    ({ stxAddr }) => getRecentPegInRecords(asSender(stxAddr)),
  )

  private refreshSignalForStacksMempool = new SuspenseObservable(Math.random())
  private mempoolPegInRequests = new LazyValue(
    () => ({
      _refresh: this.refreshSignalForStacksMempool.read$,
      _block: this.chainStore.currentBlockHeight$,
      mempool: this.accountStore.transactions$,
    }),
    ({ mempool }) => getRecentPegInRecordsFromMempool(mempool),
  )

  private inscriptionStatus$(
    currency: BRC20Currency,
    inscription: BRC20WalletRecentTransferableInscription,
  ): undefined | BRC20InscriptionStatus {
    const recentPegInReq =
      this.recentPegInRequests.value$.find(
        r => r.inscriptionNumber === inscription.inscriptionNumber,
      ) ??
      this.mempoolPegInRequests.value$.find(
        r => r.inscriptionNumber === inscription.inscriptionNumber,
      ) ??
      this.recentTransferInscriptionPegInRequests.value$.find(
        r => r?.inscriptionNumber === inscription.inscriptionNumber,
      )

    const restStatusType = assertExclude.i<BRC20InscriptionStatus["type"]>()

    // already done with `this.recentInscribeOrderStatus`
    assertExclude(restStatusType, "inscribing")

    if (inscription.transferResult === "failed") {
      return {
        type: "send-to-alex-failed",
        explorerUrl: this.brc20Wallet.value$.inscriptionExplorerLink(
          inscription.inscriptionId,
        ),
      }
    }
    assertExclude(restStatusType, "send-to-alex-failed")

    if (recentPegInReq == null) {
      return {
        type: "transferable",
        explorerUrl: this.brc20Wallet.value$.inscriptionExplorerLink(
          inscription.inscriptionId,
        ),
        onSendPegInRequest: () =>
          this.requestPegIn({
            inscriptionNumber: String(inscription.inscriptionNumber),
            currency: currency,
            amount: inscription.amount,
          }).then(async () => {
            this.refreshSignalForStacksMempool.set(Math.random())
            await sleep(1000)
            this.refreshSignalForStacksMempool.set(Math.random())
            await sleep(4000)
            this.refreshSignalForStacksMempool.set(Math.random())
            await sleep(5000)
            this.refreshSignalForStacksMempool.set(Math.random())
          }),
      }
    }
    assertExclude(restStatusType, "transferable")

    if (recentPegInReq.status === "mempool") {
      return {
        type: "sending-peg-in-request",
        explorerUrl: EXPLORER_TX_URL(recentPegInReq.txId),
      }
    }
    assertExclude(restStatusType, "sending-peg-in-request")
    // TODO: implement it
    assertExclude(restStatusType, "send-peg-in-request-failed")
    if (recentPegInReq.status === PegInRequestStatus.Processed) {
      return { type: "pegged-in" }
    }
    assertExclude(restStatusType, "pegged-in")
    if (recentPegInReq.status === PegInRequestStatus.Rejected) {
      return { type: "peg-in-failed" }
    }
    assertExclude(restStatusType, "peg-in-failed")

    // the rest code is dealing the "pending" status peg-in request
    assertExclude(recentPegInReq.status, PegInRequestStatus.Pending)

    if (inscription.transferResult === "success") {
      return {
        type: "sent-to-alex",
        explorerUrl: this.brc20Wallet.value$.inscriptionExplorerLink(
          inscription.inscriptionId,
        ),
      }
    }
    assertExclude(restStatusType, "sent-to-alex")

    /**
     * Must be written before `sent-peg-in-request` detection, cuz otherwise:
     *
     * 1. `sending-to-alex` will never be visible, cuz `sent-peg-in-request`
     *    can always be true in rest cases
     *
     * 2. mobx may can not collect this dependency and will not trigger update
     *    when `inscriptionTransferMempool` need to be updated
     */
    if (this.pegInBtcAddress.value$ != null) {
      const inscriptionSendingToAlexTx =
        this.inscriptionTransferMempool.value$.find(
          {
            txId: inscription.txId,
            index: inscription.index,
          },
          this.pegInBtcAddress.value$,
        )

      if (inscriptionSendingToAlexTx != null) {
        return {
          type: "sending-to-alex",
          explorerUrl: this.btcClient.value$.explorerLink(
            inscriptionSendingToAlexTx.txId,
          ),
        }
      }
    }
    assertExclude(restStatusType, "sending-to-alex")

    if (inscription.transferResult == null) {
      return {
        type: "sent-peg-in-request",
        onSendToAlex: () =>
          this.sendInscriptionToAlex(inscription.inscriptionId).then(
            async () => {
              this.refreshSignalForInscriptionTransferMempool.set(Math.random())
              await sleep(1000)
              this.refreshSignalForInscriptionTransferMempool.set(Math.random())
              await sleep(4000)
              this.refreshSignalForInscriptionTransferMempool.set(Math.random())
              await sleep(5000)
              this.refreshSignalForInscriptionTransferMempool.set(Math.random())
            },
          ),
      }
    }
    assertExclude(restStatusType, "sent-peg-in-request")

    checkNever(recentPegInReq)
    checkNever(restStatusType)
    checkNever(inscription.transferResult)
    return
  }

  @computed
  get recentAllTransferInscriptions$(): RecentInscriptionRecord[] {
    const { transferableInscriptions } = this.recentTransferInscriptions.value$

    const inscribingInscriptions =
      this.recentInscribeOrderStatus.value$.flatMap(
        (order): RecentInscriptionRecord[] => {
          const token = this.currencyStore.getTokenInfo$(order.currency)

          if (token == null) return []

          if (order.status === "sent") {
            const isReceived =
              transferableInscriptions.find(i => i.txId === order.txId) != null

            if (isReceived) {
              this.notifyRecentInscribeOrderCompleted(order.id)
              return []
            }
          }

          return [
            {
              token,
              tokenAmount: order.amount,
              status: {
                type: "inscribing",
                explorerUrl: order.orderLink,
              },
            },
          ]
        },
      )

    const restInscriptions = transferableInscriptions.flatMap(record => {
      const isInscriptionSent = record.transferResult != null
      const isInscriptionSentToAlex =
        this.pegInBtcAddress.value$ == null && record.toAddress != null
          ? false
          : record.toAddress === this.pegInBtcAddress.value$

      if (isInscriptionSent && !isInscriptionSentToAlex) {
        return []
      }

      const token = getTokenInfoFromBRC20Symbol$(
        this.currencyStore,
        record.symbol,
      )
      if (token == null) return []

      const status = this.inscriptionStatus$(
        TokenInfoPresets.toCurrency(token) as BRC20Currency,
        record,
      )
      if (status == null) return []

      return [
        {
          status,
          token,
          tokenAmount: record.amount,
          inscriptionNumber: Number(record.inscriptionNumber),
        },
      ]
    })

    return [...inscribingInscriptions, ...restInscriptions]
  }

  @computed
  get recentTransferInscriptions$(): RecentInscriptionRecord[] {
    return this.recentAllTransferInscriptions$.filter(
      record => record.token.id === this.viewingBRC20Token$.id,
    )
  }

  async requestPegIn(recordInfo: {
    inscriptionNumber: string
    currency: BRC20Currency
    amount: BigNumber
  }): Promise<void> {
    const stacksAddr = await waitFor(() => this.authStore.stxAddress$)
    await this.stacksTxStore.run(async () =>
      requestPegIn(
        asSender(stacksAddr),
        stacksAddr,
        await waitFor(() => this.orderbookUserId.value$ != null),
        await waitFor(() => this.pegInBtcAddress.value$ != null),
        {
          inscriptionNumber: recordInfo.inscriptionNumber,
          currency: recordInfo.currency,
          amount: BigNumber.toNumber(recordInfo.amount),
        },
      ),
    )
  }

  async sendInscriptionToAlex(
    inscriptionId: string,
    feeRate?: BigNumber,
  ): Promise<void> {
    const pegInBtcAddress = await waitFor(() => this.pegInBtcAddress.value$)

    if (pegInBtcAddress == null) {
      throw new DisplayableError("Peg-in ALEX Bitcoin address not found")
    }

    await this.generalTxStore.run(async () => {
      const resp =
        await this.brc20Wallet.value$.unsafelySendInscriptionTransactionHex(
          [{ receiverAddress: pegInBtcAddress, inscriptionId }],
          { feeRate },
        )
      return {
        explorerLink: await this.btcClient.value$.explorerLink(resp.txId),
      }
    })
  }
}
