import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../../components/button/Button"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  CardInset,
  CardInsetSubTitle,
  CardTitle,
} from "../../../../../components/Card"
import { IconTokenCount } from "../../../../../components/RichTokenCount"
import { TokenIcon, TokenIconProps } from "../../../../../components/TokenIcon"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ReactComponent as LotteryForNotRegisteredIcon } from "./_/lotteryForNotRegisteredImage.svg"

export const ActionPanel$DrawForNotJoined: FC<{
  prizeToken: TokenInfo
  prizeTokenCount: number
  onConnectWallet?: null | (() => void)
}> = props => {
  const { $t } = useIntl()

  return (
    <>
      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Project in Draw",
              description: "/Lottery/ActionPanel$DrawForNotJoined/Card title",
            }),
          )}
        </CardTitle>
        <p
          className={
            "w-full text-2xl leading-8 font-normal text-gray-200 flex flex-wrap gap-5 items-center justify-center"
          }
        >
          {$t(
            defineMessage({
              defaultMessage: "Current prize pot: {tokenCount}",
              description: "/Lottery/ActionPanel$DrawForNotJoined/Content",
            }),
            {
              tokenCount: (
                <IconTokenCount
                  iconSize={32}
                  className={"text-2xl text-yellow-200"}
                  token={props.prizeToken}
                  count={props.prizeTokenCount}
                />
              ),
            },
          )}
        </p>
        <div className={"flex items-center justify-center relative w-full"}>
          <LotteryForNotRegisteredIcon />
          <TokenIcon
            className={
              "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            }
            token={props.prizeToken}
            size={TokenIconProps.sizes.large}
          />
        </div>
        <CardInsetSubTitle>
          {$t(
            defineMessage({
              defaultMessage: "You did not join this round.",
              description: "/Lottery/ActionPanel$DrawForNotJoined/Card title",
            }),
          )}
        </CardInsetSubTitle>
      </CardInset>
      {props.onConnectWallet != null && (
        <Button Variant={GradientFilledButton} onClick={props.onConnectWallet}>
          {$t(
            defineMessage({
              defaultMessage: "Connect Wallet",
              description: "/Lottery/ActionPanel$DrawForNotJoined/Button text",
            }),
          )}
        </Button>
      )}
    </>
  )
}
