import clsx from "clsx"
import { ReactNode } from "react"
import { FCC } from "../../../utils/reactHelpers/types"

export const CoFarmSection: FCC<{
  className?: string
  gapClassName?: string
  title: ReactNode
}> = props => (
  <section
    className={clsx(
      "flex flex-col",
      props.className,
      props.gapClassName ?? "gap-[24px]",
    )}
  >
    <header className={"text-xl text-white"}>{props.title}</header>

    {props.children}
  </section>
)
