import clsx from "clsx"
import { motion, MotionProps } from "framer-motion"
import { ReactNode, useState } from "react"
import { AnimatePresence } from "../../../../utils/animateHelpers/AnimatePresence"
import { FCC } from "../../../../utils/reactHelpers/types"
import { ReactComponent as StakingDetailArrowIcon } from "./stakingDetailArrow.svg"

export const DetailPlate: FCC<{
  className?: string
  gapClassName?: string
  title?: ReactNode
  collapseAnimationProps?: MotionProps
}> = props => {
  const [isDetailVisible, setIsDetailVisible] = useState(false)

  return (
    <div className={props.className}>
      <div
        className={
          "text-sm leading-5 font-normal text-gray-100 flex items-center justify-center gap-2.5 cursor-pointer"
        }
        onClick={() => {
          setIsDetailVisible(v => !v)
        }}
      >
        <StakingDetailArrowIcon
          className={clsx(!isDetailVisible && "rotate-180")}
          fill={"#C4C4C4"}
        />
        {props.title ?? "Details"}
      </div>

      <AnimatePresence initial={false}>
        {isDetailVisible && (
          <motion.div
            className={clsx("flex flex-col", props.gapClassName)}
            {...props.collapseAnimationProps}
          >
            <div />
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
