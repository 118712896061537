import { RemoveLiquidityRowData } from "../../store/transformerGroups/pool/removeLiquidity"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const removeLiquidityExporter: Exporter<RemoveLiquidityRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.lpCurrencyAmount],
    toCurrencyAmounts: [rowData.currencyAmountX, rowData.currencyAmountY],
    allTokenInfos,
  })
  const tradeInfo = "Remove Liquidity"
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
