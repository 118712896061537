import { FC } from "react"
import { VictoryLabel, VictoryLabelProps } from "victory"

export const CalcPositionLabel: FC<
  VictoryLabelProps & {
    calcX?: (props: VictoryLabelProps) => number
    calcY?: (props: VictoryLabelProps) => number
  }
> = props => {
  const x = props.calcX == null ? props.x : props.calcX(props)
  const y = props.calcY == null ? props.y : props.calcY(props)

  return <VictoryLabel {...props} x={x} y={y} />
}

export type VictoryDatum<PointData> = PointData & {
  childName: string
  continuous: boolean
}
