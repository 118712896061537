export type BRC20InscriptionStatus =
  | { type: "inscribing"; explorerUrl: string }
  | {
      type: "transferable"
      explorerUrl: string
      onSendPegInRequest: () => void | Promise<void>
    }
  | { type: "sending-peg-in-request"; explorerUrl: string }
  | { type: "send-peg-in-request-failed"; explorerUrl: string }
  | {
      type: "sent-peg-in-request"
      onSendToAlex: () => void | Promise<void>
    }
  | { type: "sending-to-alex"; explorerUrl: string }
  | { type: "send-to-alex-failed"; explorerUrl: string; reason?: string }
  | { type: "sent-to-alex"; explorerUrl: string }
  | { type: "pegged-in" }
  | { type: "peg-in-failed" }

export namespace BRC20InscriptionStatus {
  export function isInProgressStatus(status: BRC20InscriptionStatus): boolean {
    return (
      status.type !== "inscribing" &&
      status.type !== "transferable" &&
      status.type !== "pegged-in" &&
      status.type !== "peg-in-failed"
    )
  }
}
