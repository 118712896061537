import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../../../components/NavLink"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"

export const HowLotteryWorkLink: FC = () => {
  const { $t } = useIntl()
  return (
    <NoteParagraph icon={<TipIcon size={16} tip={null} />}>
      <NavLink
        className={"text-blue-600"}
        to={
          "https://medium.com/alexgobtc/alex-launchpad-v1-1-how-does-the-hybrid-lottery-system-work-5406f437f896"
        }
      >
        {$t(
          defineMessage({
            defaultMessage: "How does the transparent lottery process work?",
            description: "/Launchpad/How Lottery work link",
          }),
        )}
      </NavLink>
    </NoteParagraph>
  )
}
