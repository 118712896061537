import { FC, useMemo } from "react"
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory"
import { formatPercentNumber } from "../../../../../../components/PercentNumber"
import { formatTokenCount } from "../../../../../../components/TokenCount"
import { formatTokenName } from "../../../../../../components/TokenName"
import { last } from "../../../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { VictoryDatum } from "../../../../../Stake/components/EarningsPreviewChartFrame"

export const lineStyles = {
  totalDepositCurve: {
    width: 4,
    color: "#059669",
  },
  aprCurve: {
    width: 4,
    color: "#EA580C",
  },
} as const

type ChartPoint<T> = { date: Date } & T

export interface DepositChartProps {
  depositToken: TokenInfo
  totalDepositCurve: ChartPoint<{ tokenCount: number }>[]
  aprCurve: ChartPoint<{ apr: number }>[]
}

const valueTransformer = (
  domain: [number, number],
  curve: number[],
): ((value: number) => number) => {
  const min = Math.min(...curve)
  const max = Math.max(...curve)
  const [domainMin, domainMax] = domain

  const portionValue = (max - min) / (domainMax - domainMin)
  return value => (value - min) / portionValue + domainMin
}

export const DepositChart: FC<DepositChartProps> = props => {
  const depositValueTransformer = useMemo(
    () =>
      valueTransformer(
        [20, 80],
        props.totalDepositCurve.map(p => p.tokenCount),
      ),
    [props.totalDepositCurve],
  )

  const aprValueTransformer = useMemo(
    () =>
      valueTransformer(
        [20, 80],
        props.aprCurve.map(p => p.apr),
      ),
    [props.aprCurve],
  )

  return (
    <div style={{ margin: "-40px -50px" }}>
      <VictoryChart
        padding={{ top: 40, right: 40, bottom: 40, left: 40 }}
        domain={{ y: [0, 100] }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={data => {
              const point: VictoryDatum<ChartPoint<unknown>> = data.datum

              const depositPoint = props.totalDepositCurve.find(
                p => p.date === point.date,
              )
              const aprPoint = props.aprCurve.find(p => p.date === point.date)

              return [
                aprPoint && `APR: ${formatPercentNumber(aprPoint.apr)}`,
                depositPoint &&
                  `Total Deposit: ${formatTokenCount(
                    props.depositToken,
                    depositPoint.tokenCount,
                  )} ${formatTokenName(props.depositToken)}`,
              ]
                .filter(Boolean)
                .join("\n")
            }}
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
          />
        }
      >
        {/* date axis */}
        <VictoryAxis
          label=""
          tickFormat={() => ""}
          tickCount={props.totalDepositCurve.length}
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
            grid: {
              stroke: "#E5E7EB",
              strokeWidth: 0.5,
              opacity: 0.25,
            },
          }}
        />
        {/* total deposit, left side x axis */}
        <VictoryAxis
          dependentAxis
          label="Total Deposit"
          axisLabelComponent={<VictoryLabel dy={18} />}
          tickFormat={() => ""}
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
            axisLabel: {
              fill: "#7E8597",
            },
          }}
        />
        {/* apy, right side y-axis */}
        <VictoryAxis
          dependentAxis
          axisValue={last(props.totalDepositCurve)?.date}
          label="APR"
          axisLabelComponent={<VictoryLabel angle={90} dy={-32} />}
          tickFormat={() => ""}
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
            axisLabel: {
              fill: "#7E8597",
            },
          }}
        />

        {/* Deposit line */}
        <VictoryLine
          data={props.totalDepositCurve}
          x={"date"}
          y={(d: DepositChartProps["totalDepositCurve"][number]) =>
            depositValueTransformer(d.tokenCount)
          }
          style={{
            data: {
              strokeWidth: lineStyles.totalDepositCurve.width,
              stroke: lineStyles.totalDepositCurve.color,
            },
          }}
        />

        {/* APR line */}
        <VictoryLine
          data={props.aprCurve}
          x={"date"}
          y={(d: DepositChartProps["aprCurve"][number]) =>
            aprValueTransformer(d.apr)
          }
          style={{
            data: {
              strokeWidth: lineStyles.aprCurve.width,
              stroke: lineStyles.aprCurve.color,
            },
          }}
        />
      </VictoryChart>
    </div>
  )
}
