import { CopyOutlined, DisconnectOutlined } from "@ant-design/icons"
import { ComponentType, FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { trunc } from "../../../utils/addressHelpers"
import { FCC } from "../../../utils/reactHelpers/types"
import { Dropdown, DropdownItem } from "../../Dropdown"
import { NavLink } from "../../NavLink"
import { Button } from "../../button/Button"
import { ButtonVariantProps } from "../../button/ButtonVariant"
import { GradientBorderButton } from "../../button/variants/GradientBorderButton/GradientBorderButton"
import { navbarButtonPresetStyle } from "../navbarButtonPresetStyle"
import { ReactComponent as ExplorerIcon } from "./explorer.svg"

export interface TrivialButtonProps$Disconnected {
  showReminder?: boolean
  ButtonVariant?: ComponentType<ButtonVariantProps>
  onConnect?: () => void
}

export const TrivialButton$Disconnected: FCC<
  TrivialButtonProps$Disconnected
> = props => {
  const { $t } = useIntl()
  return (
    <span className="relative">
      <MainButton ButtonVariant={props.ButtonVariant} onClick={props.onConnect}>
        {$t(
          defineMessage({
            defaultMessage: "CONNECT WALLET",
            description: "/Components/TopNavBar/Connect wallet button text",
          }),
        )}
      </MainButton>
      {props.showReminder && (
        <span
          className="absolute right-0 top-0 bg-red-500 w-3 h-3 rounded-full"
          style={{ border: "1.3px solid #2d2e31" }}
        />
      )}
    </span>
  )
}

export interface TrivialButtonProps$Connected {
  stxAddress: string
  addressExplorerLink: string
  ButtonVariant?: ComponentType<ButtonVariantProps>
  onCopyAddress: () => void
  onSignOut: () => void
}
export const TrivialButton$Connected: FC<
  TrivialButtonProps$Connected
> = props => {
  return (
    <Dropdown
      placement={"start"}
      trigger={
        <MainButton ButtonVariant={props.ButtonVariant}>
          {trunc(props.stxAddress, { tail: 4 })}
        </MainButton>
      }
    >
      <DropdownItem icon={<CopyOutlined />} onClick={props.onCopyAddress}>
        <span>Copy address</span>
      </DropdownItem>
      <NavLink to={props.addressExplorerLink}>
        <DropdownItem icon={<ExplorerIcon className={"fill-current"} />}>
          <span>View on Explorer</span>
        </DropdownItem>
      </NavLink>
      <DropdownItem icon={<DisconnectOutlined />} onClick={props.onSignOut}>
        <span>Disconnect</span>
      </DropdownItem>
    </Dropdown>
  )
}

const MainButton: FCC<{
  ButtonVariant?: ComponentType<ButtonVariantProps>
  onClick?: () => void
}> = props => (
  <Button
    Variant={props.ButtonVariant ?? GradientBorderButton}
    {...navbarButtonPresetStyle}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
)
