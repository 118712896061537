import { DialogVisibleManagerFactory } from "../types"

type ComponentId = Record<any, any>

const compIdSymbol = Symbol("compId")

export const createDialogVisibleStackManagerFactory =
  (): DialogVisibleManagerFactory => {
    const callbacks = new Set<() => void>()

    let visibleStack: readonly ComponentId[] = []

    const notifyManagerStateChanged = (): void => {
      callbacks.forEach(cb => cb())
    }

    const doOpen = (compId: ComponentId): void => {
      visibleStack = [compId].concat(visibleStack)
      notifyManagerStateChanged()
    }

    const doClose = (compId: ComponentId): void => {
      visibleStack = visibleStack.filter(id => id !== compId)
      notifyManagerStateChanged()
    }

    return {
      create(compId) {
        return {
          get [compIdSymbol as any]() {
            return compId
          },

          get visible() {
            return visibleStack.includes(compId)
          },

          get isFocusing() {
            return visibleStack[0] === compId
          },

          requestChangeVisible: visible => {
            if (!visible) {
              doClose(compId)
            } else if (visibleStack.includes(compId)) {
              // do nothing
            } else {
              doOpen(compId)
            }
          },

          destroy: () => {
            doClose(compId)
          },

          subscribe: callback => {
            const wrapperCallbackToEnsureCallbackUnique = (): void => {
              callback()
            }

            callbacks.add(wrapperCallbackToEnsureCallbackUnique)
            return () => {
              callbacks.delete(wrapperCallbackToEnsureCallbackUnique)
            }
          },
        }
      },
    }
  }
