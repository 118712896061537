import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { LendIndexStore } from "./LendIndexStore"
import LendStore from "./LendStore"
import { YieldTokenPool } from "./LendStore.service"

const LendIndexStoreContext = createStore<LendIndexStore>("LendIndexStore")

const LendStoreContext = createStore<LendStore>("LendStore")

export const LendIndexStoreProvider: FCC = props => {
  const authStore = useAuthStore()
  const chainStore = useChainStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(() => {
    return new LendIndexStore(
      chainStore,
      authStore,
      accountStore,
      currencyStore,
    )
  }, [authStore, chainStore, accountStore, currencyStore])

  return (
    <LendIndexStoreContext.Provider store={store}>
      {props.children}
    </LendIndexStoreContext.Provider>
  )
}

export const useLendIndexStore = LendIndexStoreContext.useStore.bind(null)

export const LendStoreProvider: FCC<{ pool: YieldTokenPool }> = props => {
  const indexStore = useLendIndexStore()
  const store = useCreation(
    () => indexStore.poolStore(props.pool),
    [indexStore, props.pool],
  )
  return (
    <LendStoreContext.Provider store={store}>
      {props.children}
    </LendStoreContext.Provider>
  )
}

export const useLendStore = LendStoreContext.useStore.bind(null)
