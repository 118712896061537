import clsx from "clsx"
import { FC, ReactNode, useEffect } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { usePathGenerator } from "../../routes/routes"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../utils/SuspenseResource"
import { FCC } from "../../utils/reactHelpers/types"
import { Dropdown, DropdownDivider, DropdownItem } from "../Dropdown"
import { NavLink, useCheckIsActive } from "../NavLink"
import { Spensor } from "../Spensor"
import { MenuButton } from "./MenuButton/MenuButton"
import {
  NavBarButtonItem,
  NavBarDropdownItem,
  NavBarItemData,
} from "./NavBarItem"
import { useTopNavBarLogoExtra } from "./TopNavBarLogoExtraProvider"
import { BalanceBar, BalanceBarProps } from "./_/BalanceBar"
import { BalanceButton, BalanceButtonProps } from "./_/BalanceButton"
import { SecondaryNavBar, SecondaryNavBarHeight } from "./_/SecondaryNavBar"
import { ReactComponent as AboutIcon } from "./about.svg"
import { ReactComponent as AlexLogo } from "./alexLogo.svg"
import { ReactComponent as AlexLogoText } from "./alexLogoText.svg"
import { ReactComponent as AnalyticsIcon } from "./analytics.svg"
import { ReactComponent as BugIcon } from "./bug.svg"
import { ReactComponent as DiscordIcon } from "./discord.svg"
import { ReactComponent as LanguageIcon } from "./language.svg"
import { ReactComponent as TokenIcon } from "./token.svg"

const AutoHideBalanceComps: FCC = props => {
  return <div className={"hidden md:block"}>{props.children}</div>
}

export const _BalanceButton: FC<BalanceButtonProps> = props => {
  return (
    <AutoHideBalanceComps>
      <BalanceButton
        {...props}
        className={`cursor-pointer ${props.className}`}
      />
    </AutoHideBalanceComps>
  )
}

export const _BalanceBar: FC<BalanceBarProps> = props => {
  return (
    <AutoHideBalanceComps>
      <BalanceBar {...props} />
    </AutoHideBalanceComps>
  )
}

export const TopNavBarHeight = 80

export interface TopNavBarProps {
  className?: string
  buttons: SuspenseResource<NavBarItemData[]>
  rightSide: ReactNode
  onShowLanguageModal?: () => void
  onHeightChanged?: (height: number) => void
}

export const TopNavBar: FC<TopNavBarProps> = props => {
  const gen = usePathGenerator()
  const { $t } = useIntl()

  const logoExtra = useTopNavBarLogoExtra()

  const checkIsActive = useCheckIsActive()

  const activeButton = (safeReadResource(props.buttons) ?? []).find(b =>
    b.type === "dropdown"
      ? b.buttons.some(b => checkIsActive(b.link(gen)))
      : checkIsActive(b.link(gen)),
  )

  const expectedHeight =
    activeButton == null || activeButton.type !== "dropdown"
      ? TopNavBarHeight
      : TopNavBarHeight + SecondaryNavBarHeight
  const { onHeightChanged: propsOnHeightChanged } = props
  useEffect(() => {
    propsOnHeightChanged?.(expectedHeight)
  }, [expectedHeight, propsOnHeightChanged])

  return (
    <div className={clsx("flex flex-col max-w-[100vw]", props.className)}>
      <div
        className={clsx(
          "flex items-center max-w-full min-h-[5rem] py-5 px-4 sm:px-8 backdrop-blur-lg bg-gray-900/50 space-between gap-1",
        )}
      >
        <Link
          className={"flex items-center cursor-pointer gap-1.5"}
          to={gen.index()}
        >
          <AlexLogo width={32} height={32} />
          <AlexLogoText className="hidden md:block" width={106} height={28} />
          {logoExtra == null ? null : <div className={"ml-2"}>{logoExtra}</div>}
        </Link>

        <div
          className={"flex-1 self-stretch flex justify-start overflow-hidden"}
        >
          <div
            className={
              "mx-[38px] justify-start hidden lg:flex overflow-x-scroll scrollbar-hidden"
            }
          >
            <Spensor>
              {() => (
                <>
                  {readResource(props.buttons).map((b, idx) =>
                    // prettier-ignore
                    b.type === 'dropdown' ? <NavBarDropdownItem key={idx} item={b}/> :
                      <NavBarButtonItem key={idx} item={b}/>,
                  )}
                </>
              )}
            </Spensor>
          </div>
        </div>

        <div className={"flex gap-2 sm:gap-6 items-center"}>
          {props.rightSide && (
            <div className="flex items-center gap-2">{props.rightSide}</div>
          )}

          <Dropdown
            placement={"end"}
            trigger={({ isOpen }) => <MenuButton isOpen={isOpen} />}
            dismissMethod={["hover-outside", "click"]}
          >
            <Spensor>
              {() => (
                <>
                  {readResource(props.buttons)
                    .map(b => (b.type === "dropdown" ? b.buttons : b))
                    .flat()
                    .map(b => (
                      <NavLink
                        key={b.link(gen)}
                        className={"lg:hidden"}
                        to={b.link(gen)}
                      >
                        <DropdownItem>{b.name}</DropdownItem>
                      </NavLink>
                    ))}
                </>
              )}
            </Spensor>
            <DropdownDivider className={"lg:hidden"} />
            <NavLink to="https://alexgo.io/">
              <DropdownItem icon={<AboutIcon className={"fill-current"} />}>
                {$t(
                  defineMessage({
                    defaultMessage: "About",
                    description: "/Components/TopNavBar/Dropdown Menu Item",
                  }),
                )}
              </DropdownItem>
            </NavLink>
            <NavLink to="https://discord.gg/alexlab">
              <DropdownItem icon={<DiscordIcon className={"fill-current"} />}>
                {$t(
                  defineMessage({
                    defaultMessage: "Discord",
                    description: "/Components/TopNavBar/Dropdown Menu Item",
                  }),
                )}
              </DropdownItem>
            </NavLink>
            <NavLink to={gen.analytics()} target="_blank">
              <DropdownItem icon={<AnalyticsIcon className={"fill-current"} />}>
                {$t(
                  defineMessage({
                    defaultMessage: "Analytics",
                    description: "/Components/TopNavBar/Dropdown Menu Item",
                  }),
                )}
              </DropdownItem>
            </NavLink>
            <NavLink to="https://immunefi.com/bounty/alex/" target="_blank">
              <DropdownItem icon={<BugIcon className={"fill-current"} />}>
                {$t(
                  defineMessage({
                    defaultMessage: "Bug Bounty",
                    description: "/Components/TopNavBar/Dropdown Menu Item",
                  }),
                )}
              </DropdownItem>
            </NavLink>
            <NavLink to={gen.tokenList()}>
              <DropdownItem icon={<TokenIcon className={"fill-current"} />}>
                {$t(
                  defineMessage({
                    defaultMessage: "Token List",
                    description: "/Components/TopNavBar/Dropdown Menu Item",
                  }),
                )}
              </DropdownItem>
            </NavLink>
            {props.onShowLanguageModal && (
              <DropdownItem
                icon={<LanguageIcon className={"fill-current"} />}
                onClick={props.onShowLanguageModal}
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Language",
                    description: "/Components/TopNavBar/Dropdown Menu Item",
                  }),
                )}
              </DropdownItem>
            )}
          </Dropdown>
        </div>
      </div>

      {activeButton?.type === "dropdown" && (
        <SecondaryNavBar navItems={activeButton.buttons} />
      )}
    </div>
  )
}
