import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import { Truncatable } from "../Truncatable"
import { OpacityButton } from "../button/variants/OpacityButton"
import { BlockBottomLine } from "./Block"

export const BalanceBottomArea: FC<{
  className?: string
  boxClassName?: string
  token?: SuspenseResource<undefined | TokenInfo>
  balanceLabel?: string
  balance?: SuspenseResource<number>
  rightSide?: ReactNode
  onPressMax?: SuspenseResource<undefined | (() => void)>
}> = props => {
  return (
    <BlockBottomLine
      className={clsx("flex", props.className)}
      boxClassName={props.boxClassName}
    >
      {props.balance != null && (
        <BalanceCount
          balanceLabel={props.balanceLabel}
          token={safeReadResource(props.token)}
          balance={props.balance}
        />
      )}

      <Spensor>
        {() => {
          const onPressMax = props.onPressMax && readResource(props.onPressMax)
          return (
            onPressMax && (
              <MaxButton
                className={"mx-1 sm:mx-2.5"}
                onClick={readResource(onPressMax)}
              />
            )
          )
        }}
      </Spensor>

      {props.rightSide != null && (
        <div
          className={clsx(
            "ml-auto overflow-ellipsis overflow-hidden",
            bottomLineTextClassName,
          )}
        >
          {props.rightSide}
        </div>
      )}
    </BlockBottomLine>
  )
}

const bottomLineTextClassName = "text-xs sm:text-base text-gray-500"

export const BalanceCount: FC<{
  balanceLabel?: string
  token?: SuspenseResource<TokenInfo>
  balance: SuspenseResource<number>
}> = props => {
  const { $t } = useIntl()
  return (
    <span className={clsx("flex items-center", bottomLineTextClassName)}>
      {props.balanceLabel ??
        $t(
          defineMessage({
            defaultMessage: "Balance:",
            description:
              "/Components/TokenInput/BalanceBottomArea/Balance label",
          }),
        )}
      &nbsp;
      <Spensor fallback={"-"}>
        {() =>
          props.token === undefined ? (
            $t(
              defineMessage({
                defaultMessage: "Select a token",
                description:
                  "/Components/TokenInput/BalanceBottomArea/Placeholder",
              }),
            )
          ) : (
            <TokenCount
              token={readResource(props.token)}
              count={readResource(props.balance)}
            />
          )
        }
      </Spensor>
    </span>
  )
}

export const MaxButton: FC<{
  className?: string
  boxClassName?: string
  textClassName?: string
  onClick: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <OpacityButton
      className={clsx("flex items-center", props.className)}
      boxClassName={props.boxClassName ?? "px-1 sm:px-2 sm:py-0.5"}
      textClassName={
        props.textClassName ?? "text-blue-600 text-xs sm:text-sm font-medium"
      }
      onClick={props.onClick}
    >
      {$t(
        defineMessage({
          defaultMessage: "Max",
          description: "/Components/TokenInput/BalanceBottomArea/Max button",
        }),
      )}
    </OpacityButton>
  )
}

export const EstimatedUSD: FC<{
  className?: string
  usdCount: SuspenseResource<number>
  error?: boolean
}> = props => (
  <Truncatable
    className={clsx(
      bottomLineTextClassName,
      props.error && "text-red-500",
      props.className,
    )}
  >
    ≈${" "}
    <Spensor fallback={"-"}>
      {() => (
        <TokenCount
          token={TokenInfoPresets.USD}
          count={readResource(props.usdCount)}
        />
      )}
    </Spensor>
  </Truncatable>
)
