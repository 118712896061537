import clsx from "clsx"
import { Children } from "react"
import { FCC } from "../../../utils/reactHelpers/types"

export const ListContainer: FCC<{ className?: string }> = props => (
  <ul className={clsx("flex flex-col gap-4", props.className)}>
    {Children.toArray(props.children).map((child, idx) => (
      <li key={idx}>{child}</li>
    ))}
  </ul>
)

export interface ListItemContainerProps {
  className?: string
}

export const getListItemContainerClassName: (
  props: ListItemContainerProps,
) => string = () => "flex flex-row items-center gap-4"

export const ListItemContainer: FCC<ListItemContainerProps> = props => {
  return (
    <div
      className={clsx(getListItemContainerClassName(props), props.className)}
    >
      {props.children}
    </div>
  )
}

export interface ListSectionProps {
  className?: string
  type?: "head" | "tail" | "body"
}

export const getListSectionClassName: (props: ListSectionProps) => string = ({
  type = "body",
}) =>
  clsx(
    type === "head"
      ? "w-[257px]"
      : type === "body"
      ? "flex-[3] text-right justify-end"
      : "flex-1 text-right justify-end",
    "min-w-0 truncate",
  )

export const ListSection: FCC<ListSectionProps> = props => {
  return (
    <div className={clsx(props.className, getListSectionClassName(props))}>
      {props.children}
    </div>
  )
}
