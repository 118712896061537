import { unwrapResponse } from "clarity-codegen"
import { chunk, range } from "lodash"
import {
  FC,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  asSender,
  currentContractName,
} from "../../../generated/smartContractHelpers/asSender"
import AccountTransactions from "../../../stores/accountStore/AccountTransactions"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { isNotNull } from "../../../utils/utils"
import { getAllWonTicketIds } from "../../Launchpad/store/IDOLCGAlgorithm"
import {
  CreateIdoOptions,
  LaunchPadDetail,
  idoWalkResolution,
} from "../../Launchpad/store/LaunchPadContractStore.service"

const Input = withClassName("flex p-2 text-lg", "input")

export const ProcessLaunchPad = forwardRef<
  {
    setIdoId: (id: number) => void
  },
  {
    store: ConfirmTransactionStore
  }
>(({ store }, ref) => {
  const authStore = useAuthStore()
  const transactions = useCreation(
    () =>
      new AccountTransactions(
        `${CONTRACT_DEPLOYER}.${currentContractName("alex-launchpad-v1-3")}`,
      ),
    [],
  )
  const [idoId, setIdoId] = useState(0)
  useImperativeHandle(
    ref,
    () => ({
      setIdoId,
    }),
    [setIdoId],
  )

  const [detail, setDetail] =
    useState<Extract<LaunchPadDetail, { version: "alex-launchpad-v1-3" }>>()
  const [offset, setOffset] = useState(0)
  const [pageSize, setPageSize] = useState(200)

  const getCodedTxs = useCallback(async () => {
    await transactions.sync()
    const txs = await transactions.decodedContractCallTransactions(
      "alex-launchpad-v1-3",
      "register",
    )
    const codedTxs = txs
      .map(t => ({
        blockHeight: t.raw.block_height,
        blockTime: t.raw.burn_block_time_iso,
        ido: t.args["launch-id"],
        address: t.raw.sender_address,
        range: unwrapResponse(t.result),
      }))
      .filter(t => t.ido === idoId)
      .sort((a, b) => a.range.start - b.range.start)
    // verify range completeness
    let start = 0
    for (const codedTx of codedTxs) {
      if (codedTx.range.start !== start) {
        throw new Error("Invalid range, data corrupted")
      }
      start = codedTx.range.end
    }
    return codedTxs
  }, [idoId, transactions])

  return (
    <div className="flex flex-col col-span-8 gap-3">
      <Input
        type={"number"}
        value={idoId}
        onChange={e => setIdoId(Number(e.target.value))}
      />
      <GradientFilledButton
        onClick={async () => {
          const result = await asSender(authStore.stxAddress$)
            .contract("alex-launchpad-v1-3")
            .func("get-launch")
            .call({
              "launch-id": idoId,
            })
          setDetail({
            version: "alex-launchpad-v1-3",
            detail: unwrapResponse(result)!,
          })
          alert(JSON.stringify(result, null, 2))
        }}
      >
        Fetch IDO
      </GradientFilledButton>
      <Input
        type={"number"}
        value={offset}
        onChange={e => setOffset(Number(e.target.value))}
        placeholder="Offset"
      />
      <Input
        type={"number"}
        value={pageSize}
        onChange={e => setPageSize(Number(e.target.value))}
        placeholder="PageSize"
      />
      <GradientFilledButton
        disabled={detail == null}
        onClick={async () => {
          const codedTxs = await getCodedTxs()
          const result = await asSender(authStore.stxAddress$)
            .contract("alex-launchpad-v1-3")
            .func("get-offering-walk-parameters")
            .call({
              "launch-id": idoId,
            })
            .then(unwrapResponse)
          const wonTickets = getAllWonTicketIds(result)
          const toCSV = codedTxs.map(tx => {
            const tickets = range(
              tx.range.start / idoWalkResolution,
              tx.range.end / idoWalkResolution,
            )
            return {
              block: tx.blockHeight,
              blockTime: tx.blockTime,
              address: tx.address,
              tickets: tickets.length,
              wonTickets: tickets.filter(t => wonTickets.includes(t)).length,
            }
          })
          // download file
          const csv = [
            ["address", "block", "block time", "tickets", "won tickets"].join(
              ",",
            ),
            ...toCSV.map(
              tx =>
                `${tx.address},${tx.block},${tx.blockTime},${tx.tickets},${tx.wonTickets}`,
            ),
          ].join("\n")
          const blob = new Blob([csv], { type: "text/csv" })
          const url = URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = url
          a.download = `ido-${idoId}-walk.csv`
          a.click()
        }}
      >
        Download Result
      </GradientFilledButton>
      <GradientFilledButton
        disabled={detail == null}
        onClick={async () => {
          const codedTxs = await getCodedTxs()
          const result = await asSender(authStore.stxAddress$)
            .contract("alex-launchpad-v1-3")
            .func("get-offering-walk-parameters")
            .call({
              "launch-id": idoId,
            })
            .then(unwrapResponse)
          const wonTickets = getAllWonTicketIds(result)
          const winnerAddresses = wonTickets
            .map(t => t * idoWalkResolution)
            .map(index => {
              const tx = codedTxs.find(
                tx => tx.range.start <= index && tx.range.end > index,
              )
              if (tx == null) {
                // throw new Error(`index ${index} not found`)
                return null // out of range on ticket not sold
              }
              return tx.address
            })
            .filter(isNotNull)
          console.log(winnerAddresses)
          for (const addresses of chunk(
            winnerAddresses.slice(offset),
            pageSize,
          )) {
            const { txId } = await asSender(authStore.stxAddress$)
              .contract("alex-launchpad-v1-3")
              .func("claim")
              .call({
                "launch-id": idoId,
                "launch-token-trait": detail!.detail["launch-token"],
                "payment-token-trait": detail!.detail["payment-token"],
                input: addresses,
              })
            store.successRunning(txId)
          }
        }}
      >
        Process Winners
      </GradientFilledButton>
      <GradientFilledButton
        disabled={detail == null}
        onClick={async () => {
          const codedTxs = await getCodedTxs()
          const result = await asSender(authStore.stxAddress$)
            .contract("alex-launchpad-v1-3")
            .func("get-offering-walk-parameters")
            .call({
              "launch-id": idoId,
            })
            .then(unwrapResponse)
          const wonTickets = getAllWonTicketIds(result)
          const projectFailed =
            codedTxs[codedTxs.length - 1]!.range.end / idoWalkResolution <
            detail!.detail["activation-threshold"]
          const refundAddresses = codedTxs
            .map(t => {
              const amount =
                range(
                  t.range.start / idoWalkResolution,
                  t.range.end / idoWalkResolution,
                ).filter(i => projectFailed || !wonTickets.includes(i)).length *
                detail!.detail["price-per-ticket-in-fixed"]
              return { amount, recipient: t.address }
            })
            .filter(t => t.amount > 0)
          console.log(refundAddresses)
          for (const records of chunk(
            refundAddresses.slice(offset),
            pageSize,
          )) {
            const { txId } = await asSender(authStore.stxAddress$)
              .contract("alex-launchpad-v1-3")
              .func("refund")
              .call({
                "launch-id": idoId,
                "payment-token-trait": detail!.detail["payment-token"],
                input: records,
              })
            store.successRunning(txId)
          }
        }}
      >
        Process Refunds
      </GradientFilledButton>
      <GradientFilledButton
        className="mt-4"
        onClick={async () => {
          const address = window.prompt("Enter operator address")
          if (address == null) {
            return
          }
          const result = await asSender(authStore.stxAddress$)
            .contract("alex-launchpad-v1-3")
            .func("add-approved-operator")
            .call({
              "new-approved-operator": address,
            })
          store.successRunning(result.txId)
        }}
      >
        Add Operator
      </GradientFilledButton>
    </div>
  )
})

export const CreateNewIdo: FC<{
  store: ConfirmTransactionStore
  onAdded: (idoId: number) => void
}> = ({ store, onAdded }) => {
  const authStore = useAuthStore()
  const chainStore = useChainStore()
  const block = chainStore.currentBlockHeight$
  const options: CreateIdoOptions = {
    "fee-per-ticket-in-fixed": 0.05 * 1e8,
    "launch-owner": authStore.stxAddress$,
    "launch-tokens-per-ticket": 50,
    "price-per-ticket-in-fixed": 50 * 1e8,
    "activation-threshold": 18,
    "registration-start-height": block + 5,
    "registration-end-height": block + 20,
    "claim-end-height": block + 1000,
    "registration-max-tickets": 10000000,
    "apower-per-ticket-in-fixed": [
      {
        "apower-per-ticket-in-fixed": 5e8,
        "tier-threshold": 50,
      },
      {
        "apower-per-ticket-in-fixed": 10e8,
        "tier-threshold": 50,
      },
      {
        "apower-per-ticket-in-fixed": 15e8,
        "tier-threshold": 9999900,
      },
    ],
  }
  const [optionText, setOptionText] = useState(() =>
    JSON.stringify(options, null, 2),
  )
  const [amount, setAmount] = useState(48)

  return (
    <div className="flex flex-col col-span-8 gap-3">
      <textarea
        spellCheck={false}
        className="w-full"
        rows={11}
        value={optionText}
        onChange={e => setOptionText(e.target.value)}
      />
      <input
        type="number"
        value={amount}
        onChange={e => setAmount(Number(e.target.value))}
      />
      <GradientFilledButton
        onClick={async () => {
          try {
            const idoNonce = await asSender(authStore.stxAddress$)
              .contract("alex-launchpad-v1-3")
              .func("get-launch-id-nonce")
              .call([])
              .then(unwrapResponse)
            const { txId } = await asSender(authStore.stxAddress$)
              .contract("alex-launchpad-v1-3")
              .func("create-pool")
              .call({
                "launch-token-trait": Currency.ORDINALS_BLUEWHEEL,
                "payment-token-trait": Currency.sUSDT,
                offering: JSON.parse(optionText) as CreateIdoOptions,
              })
            onAdded(idoNonce)
            await asSender(authStore.stxAddress$)
              .contract("alex-launchpad-v1-3")
              .func("add-to-position")
              .call({
                "launch-token-trait": Currency.ORDINALS_BLUEWHEEL,
                "launch-id": idoNonce,
                tickets: amount,
              })
            store.successRunning(txId)
          } catch (e) {
            store.errorRunning(e as Error)
          }
        }}
      >
        Create IDO and add to position
      </GradientFilledButton>
    </div>
  )
}
