import clsx from "clsx"
import RcPagination from "rc-pagination"
import { FC, ReactNode, SVGProps } from "react"
import { defineMessage, useIntl } from "react-intl"
import { SuspenseResource, safeReadResource } from "../utils/SuspenseResource"
import { dontWrapObserver } from "../utils/mobxHelpers"
import { FCC } from "../utils/reactHelpers/types"
import { withClassName } from "../utils/reactHelpers/withClassName"
import styles from "./Pagination.module.scss"
import { OpacityButton } from "./button/variants/OpacityButton"

export interface PaginationInfo {
  /**
   * zero based page number
   */
  currentPage: number

  recordCountPerPage: number

  recordCountTotal: SuspenseResource<number>
}

export interface PaginationProps extends PaginationInfo {
  className?: string
  onChange?: (info: { page: number }) => void
}

export const Pagination: FC<PaginationProps> = props => {
  const { $t } = useIntl()

  const paginationLocale = {
    // Options.jsx
    items_per_page: $t(
      defineMessage({
        defaultMessage: "/ page",
        description: "/Components/Pagination/Items PerPage text",
      }),
    ),
    jump_to: $t(
      defineMessage({
        defaultMessage: "Go to",
        description: "/Components/Pagination/Jump To text",
      }),
    ),
    jump_to_confirm: $t(
      defineMessage({
        defaultMessage: "confirm",
        description: "/Components/Pagination/Jump To Confirm text",
      }),
    ),
    page: $t(
      defineMessage({
        defaultMessage: "Page",
        description: "/Components/Pagination/Page text",
      }),
    ),
    // Pagination.jsx
    prev_page: $t(
      defineMessage({
        defaultMessage: "Previous Page",
        description: "/Components/Pagination/Previous Page text",
      }),
    ),
    next_page: $t(
      defineMessage({
        defaultMessage: "Next Page",
        description: "/Components/Pagination/Next page text",
      }),
    ),
    prev_5: $t(
      defineMessage({
        defaultMessage: "Previous {page} Pages",
        description: "/Components/Pagination/Previous page text",
      }),
      { page: 5 },
    ),
    next_5: $t(
      defineMessage({
        defaultMessage: "Next {page} Pages",
        description: "/Components/Pagination/Next page text",
      }),
      {
        page: 4,
      },
    ),
    prev_3: $t(
      defineMessage({
        defaultMessage: "Previous {page} Pages",
        description: "/Components/Pagination/Previous page text",
      }),
      {
        page: 3,
      },
    ),
    next_3: $t(
      defineMessage({
        defaultMessage: "Next {page} Pages",
        description: "/Components/Pagination/Next page text",
      }),
      { page: 3 },
    ),
    page_size: $t(
      defineMessage({
        defaultMessage: "Page Size",
        description: "/Components/Pagination/Page Size text",
      }),
    ),
  }

  return (
    <div
      className={clsx(
        "flex flex-row flex-wrap justify-between align-center gap-4",
        styles.container,
        props.className,
      )}
    >
      {(safeReadResource(props.recordCountTotal) ?? 0) <= 0 ? (
        <p />
      ) : (
        <p
          className={
            "inline-block self-center text-sm leading-5 font-normal text-white"
          }
        >
          {$t<ReactNode>(
            defineMessage({
              defaultMessage:
                "Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{total}</strong> results",
              description: "/Components/Pagination/Showing results text",
            }),
            {
              from: props.currentPage * props.recordCountPerPage + 1,
              to: (props.currentPage + 1) * props.recordCountPerPage,
              total: safeReadResource(props.recordCountTotal) ?? "--",
              strong: dontWrapObserver(children => (
                <PaginateNumber>{children}</PaginateNumber>
              )),
            },
          )}
        </p>
      )}
      <RcPagination
        className={"max-w-full flex flex-row gap-[2px]"}
        locale={paginationLocale}
        current={props.currentPage + 1}
        total={
          safeReadResource(props.recordCountTotal) ??
          // ensure we will render at least 1 page
          props.recordCountPerPage
        }
        pageSize={props.recordCountPerPage}
        itemRender={paginationItemRender(props.currentPage + 1)}
        showLessItems={true}
        onChange={page => props.onChange?.({ page: page - 1 })}
      />
    </div>
  )
}

const paginationItemRender =
  (currentPage: number) =>
  (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
  ): ReactNode => {
    if (type === "page") {
      return (
        <PaginateButton state={currentPage === page ? "active" : "idle"}>
          {page}
        </PaginateButton>
      )
    }

    if (type === "jump-next" || type === "jump-prev") {
      return <PaginateButton state={"disabled"}>...</PaginateButton>
    }

    if (type === "next") {
      return (
        <PaginateButton
          className={"rounded-r-lg h-full flex items-center"}
          state={"idle"}
        >
          <ArrowIcon className={"rotate-180"} />
        </PaginateButton>
      )
    }

    if (type === "prev") {
      return (
        <PaginateButton
          className={"rounded-l-lg h-full flex items-center"}
          state={"idle"}
        >
          <ArrowIcon />
        </PaginateButton>
      )
    }

    return null
  }

const PaginateButton: FCC<{
  className?: string
  state: "idle" | "active" | "disabled"
}> = props => {
  return (
    <OpacityButton
      className={clsx(
        props.className,
        props.state === "disabled" && "pointer-events-none",
        props.state === "active" && "pointer-events-none",
      )}
      boxClassName={"px-3.5 py-2.5"}
      textClassName={
        "text-sm leading-5 font-normal text-gray-400 hover:text-white active:text-white"
      }
      bgClassName={clsx(
        "bg-white hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-15 disabled:bg-black disabled:bg-opacity-15",
        props.state === "active" ? "bg-opacity-20" : "bg-opacity-5",
      )}
      roundedClassName={""}
    >
      {props.children}
    </OpacityButton>
  )
}

const ArrowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#9CA3AF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.91502 7.97589L10.6876 13.7915L11.6304 12.8487L6.7572 7.97551L11.6296 3.10309L10.6868 2.16028L4.91502 7.97589Z" />
  </svg>
)

const PaginateNumber = withClassName("font-bold", "span")
