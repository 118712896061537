export function isNotNull<T>(input: T | undefined | null): input is T {
  return input != null
}

export function isNotFalse<T>(input: T | false): input is T {
  return input !== false
}

export function optionally<T, U>(
  input: T | undefined | null,
  map: (input: T) => U,
): U | undefined {
  if (isNotNull(input)) {
    return map(input)
  }
}
