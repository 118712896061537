import clsx from "clsx"
import { FC } from "react"

export interface CheckboxProps {
  className?: string
  value?: boolean
  onChange?: (info: { checked: boolean }) => void
}

export const Checkbox: FC<CheckboxProps> = props => (
  <input
    className={clsx("accent-gray-400", props.className)}
    type={"checkbox"}
    checked={props.value}
    onChange={e => props.onChange?.({ checked: e.target.checked })}
  />
)
