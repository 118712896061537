import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardInset, CardTitle } from "../../../../../components/Card"
import { GradientText } from "../../../../../components/GradientText"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { IconTokenCount } from "../../../../../components/RichTokenCount"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export const CoFarmPoolCard: FC<{
  className?: string
  coFarmPoolToken: TokenInfo
  coFarmPoolTokenCount: number
  coFarmPoolTokenAPR: number
  endCycle: number
}> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx("flex flex-col gap-3", props.className)}>
      <CardTitle>
        <GradientText
          gradientStyle={
            "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {$t(
            defineMessage({
              defaultMessage: "Co-Farm pool",
              description: "/Farm/Co-Farm pool card",
            }),
          )}
        </GradientText>
      </CardTitle>
      <CardInset>
        <InfoList
          direction={"row"}
          listItemDirection={"column"}
          listItemClassName={"flex-1 items-center gap-2.5"}
        >
          <InfoListItem>
            <InfoListItemDetail>
              <IconTokenCount
                inline={false}
                iconSize={32}
                className={"text-2xl font-medium"}
                tokenNameClassName={"sr-only"}
                token={props.coFarmPoolToken}
                count={props.coFarmPoolTokenCount}
              />
            </InfoListItemDetail>
            <InfoListItemTitle className={"text-xl font-medium"}>
              <GradientText
                gradientStyle={
                  "linear-gradient(90deg, #FFFFFF 0%, #FFE242 100%)"
                }
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Co-Farm LP",
                    description: "/Farm/Co-Farm pool card",
                  }),
                )}
              </GradientText>
            </InfoListItemTitle>
          </InfoListItem>
        </InfoList>
      </CardInset>
      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Est. APR",
                description: "/Farm/Co-Farm pool card",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.coFarmPoolTokenAPR} />
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Farming ends {icon}",
                description: "/Farm/Co-Farm pool card",
              }),
              {
                icon: (
                  <TipIcon
                    className="ml-1"
                    inline
                    tip={$t(
                      defineMessage({
                        defaultMessage:
                          "Co-Farming will concludes at cycle #{endCycle}",
                        description: "/Farm/Co-Farm pool card",
                      }),
                      {
                        endCycle: props.endCycle,
                      },
                    )}
                  />
                ),
              },
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            {$t(
              defineMessage({
                defaultMessage: "Cycle #{endCycle}",
                description: "/Farm/Co-Farm pool card",
              }),
              {
                endCycle: props.endCycle,
              },
            )}
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </Card>
  )
}
