import { FC } from "react"
import FinishedIconUrl from "./_/finishedImage.svg"

export const LotteryFinishedIcon: FC<{ size?: number }> = props => {
  return (
    <img
      alt="Lottery finished icon"
      width={props.size ?? 96}
      height={props.size ?? 96}
      src={FinishedIconUrl}
    />
  )
}
