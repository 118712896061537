import { optionalResponse } from "clarity-codegen"
import { sum } from "lodash"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["fwp-wstx-alex-tranched-64"] as const
const functionName = "claim-and-add-to-position-many" as const

export interface CoFarmRowData extends BaseRowData {
  type: NotifyTransactionType.CoFarm
  coFarmFromCurrencyAmounts: CurrencyAmount[]
  coFarmToCurrencyAmounts: CurrencyAmount[]
  entitledFromCurrencyAmounts: CurrencyAmount[]
  entitledToCurrencyAmounts: CurrencyAmount[]
}

export const coFarmTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  CoFarmRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.CoFarm,
  /**
   * entitled amount + co-farm amount = total transfer amount
   * get entitled amount from result, then calculate co-farm amount
   */
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const result = helpers.getResult()
    const transfers = helpers.getTransfers()

    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const entitledAlexAmount = getAmountFromContract(
      sum(
        (optionalResponse(result) ?? []).map(
          r => optionalResponse(r)?.["entitled-token"] ?? 0,
        ),
      ),
    )
    // find entitled token position first
    const entitledAlexIndex = transfers.findIndex(
      t => t.amount === entitledAlexAmount,
    )
    // entitled atAlex is one position before
    const entitledAtAlexAmount = transfers[entitledAlexIndex - 1]?.amount ?? 0
    const transferAtAlexAmount = transferAmounts[Currency.ATALEX]
    const coFarmAtAlexAmount = transferAtAlexAmount
      ? transferAtAlexAmount.in - entitledAtAlexAmount
      : 0

    const coFarmFromAmount =
      transferAmounts[Currency.FWP_STX_ALEX_50_50_V1_01]?.in ?? 0

    return {
      coFarmFromCurrencyAmounts: [
        {
          currency: Currency.FWP_STX_ALEX_50_50_V1_01,
          amount: coFarmFromAmount,
        },
      ],
      coFarmToCurrencyAmounts: [
        {
          currency: Currency.FWP_STX_ALEX_TRANCHED,
          amount: coFarmFromAmount,
        },
        {
          currency: Currency.ATALEX,
          amount: coFarmAtAlexAmount,
        },
      ],
      entitledFromCurrencyAmounts: [
        {
          currency: Currency.ALEX,
          amount: entitledAlexAmount,
        },
      ],
      entitledToCurrencyAmounts: [
        {
          currency: Currency.ATALEX,
          amount: entitledAtAlexAmount,
        },
      ],
    }
  },
  pendingOrFailedTransformer: () => {
    return {
      coFarmFromCurrencyAmounts: [
        {
          currency: Currency.FWP_STX_ALEX_50_50_V1_01,
        },
      ],
      coFarmToCurrencyAmounts: [
        {
          currency: Currency.FWP_STX_ALEX_TRANCHED,
        },
        {
          currency: Currency.ATALEX,
        },
      ],
      entitledFromCurrencyAmounts: [
        {
          currency: Currency.ALEX,
        },
      ],
      entitledToCurrencyAmounts: [
        {
          currency: Currency.ATALEX,
        },
      ],
    }
  },
}
