import type { OperationResult } from "@urql/core"
import { map, Observable } from "rxjs"
import { toSubscribable } from "../wonkaHelpers/toSubscribable"
import { Source } from "../wonkaHelpers/types"
import { fromSubscribable } from "./fromSubscribable"

export function fromUrqlSource<Data>(
  source: Source<OperationResult<Data>>,
): Observable<Data>
export function fromUrqlSource<Data, TOutput>(
  source: Source<OperationResult<Data>>,
  getData: (result: { data: Data }) => TOutput,
): Observable<TOutput>
export function fromUrqlSource<Data, TOutput>(
  source: Source<OperationResult<Data>>,
  getData?: (result: { data: Data }) => TOutput,
): Observable<TOutput | Data> {
  const _getData = getData ?? (result => result.data as Data)

  return fromSubscribable(toSubscribable(source)).pipe(
    map(data => {
      if (data.error) {
        throw data.error
      }
      if (data.data == null) {
        throw new Error("No data")
      }
      return _getData({ data: data.data })
    }),
  )
}
