import { gql } from "@urql/core"
import {
  TokenInfoListQuery,
  TokenInfoListQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import {
  parseTokenFragment,
  TokenFragment,
} from "../../../stores/currencyStore/CurrencyStore.service"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { isNotNull } from "../../../utils/utils"
import { TokenInfoListItem } from "../components/types"

class TokenListStore {
  tokenList = new LazyValue(
    () => null,
    () =>
      fromUrqlSource(
        gqlQuery<TokenInfoListQuery, TokenInfoListQueryVariables>(gql`
          ${TokenFragment}
          query TokenInfoList {
            tokenListItemCollection(order: [sortedBy_DESC]) {
              items {
                token {
                  ...TokenFragment
                }
                twitterLink
                mediumLink
                discordLink
                telegramLink
                website
                whitePaperLink
                ddReportLink
                status
                launchTime
              }
            }
          }
        `),
        result =>
          result.data.tokenListItemCollection!.items.filter(isNotNull).map(
            (item): TokenInfoListItem => ({
              token: parseTokenFragment(item.token!),
              status: item.status! as any,
              launchTime: item.launchTime!,
              telegramLink: item.telegramLink ?? undefined,
              discordLink: item.discordLink ?? undefined,
              mediumLink: item.mediumLink ?? undefined,
              twitterLink: item.twitterLink ?? undefined,
              ddReportLink: item.ddReportLink ?? undefined,
              website: item.website ?? undefined,
              whitePaper: item.whitePaperLink ?? undefined,
            }),
          ),
      ),
  )
}

export default TokenListStore
