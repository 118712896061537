import { FC, Suspense } from "react"
import { ChartContainer } from "../../components/ChartContainer/ChartContainer"
import { usePreventGlobalWiredDisclaimerModal } from "../../components/DisclaimerModalContent/WiredDisclaimerModal"
import { RootLayoutContainer } from "../../components/LayoutContainer/RootLayoutContainer"
import { suspenseResource } from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { assertNever } from "../../utils/types"
import { ResolutionString } from "../../vendors/charting_library/charting_library.esm"
import { OrderListOrderType } from "../Orderbook/components/OrderbookList/types"
import { MarginRatio } from "./components/MarginRatio"
import { PerpetualAccountContent } from "./components/PerpetualAccount/PerpetualAccountContent"
import { PerpetualAccountFrame } from "./components/PerpetualAccount/PerpetualAccountFrame"
import { OrderDirection } from "./components/types"
import { usePerpetualStore } from "./store/usePerpetualStore"
import { WiredCreateOrder } from "./wiredComponents/WiredCreateOrder"
import { WiredOrderHistory } from "./wiredComponents/WiredOrderHistory"
import {
  WiredMyTradesOrderListContent,
  WiredOrderbookListContent,
  WiredOrderbookListFrame,
  WiredTradesOrderListContent,
} from "./wiredComponents/WiredOrderbookList"
import { WiredSummaryBar } from "./wiredComponents/WiredSummaryBar"

const PerpetualPageContent: FC = () => {
  const store = usePerpetualStore()
  const perpetualMock = {
    gapClassName: "gap-2.5",

    totalBalance: 856521.12,
    totalBalancePnlInTokenCount: 521.12,
    totalBalancePnlInPercentage: 0.126,

    quotaToken: TokenInfoPresets.MockXUSD,
    quotaTokenAvailableCount: 456521.12,
    unrealizedPnl: 100,
  }

  const mockMarginRadio = {
    selectedMarginPool: {
      id: "1",
      tradeToken: TokenInfoPresets.MockXBTC,
      priceToken: TokenInfoPresets.MockXUSD,
      orderDirection: OrderDirection.Long,
    },
    availableMarginPools: [
      {
        id: "1",
        tradeToken: TokenInfoPresets.MockXBTC,
        priceToken: TokenInfoPresets.MockXUSD,
        orderDirection: OrderDirection.Long,
      },
      {
        id: "2",
        tradeToken: TokenInfoPresets.MockXBTC,
        priceToken: TokenInfoPresets.MockXUSD,
        orderDirection: OrderDirection.Short,
      },
    ],
    healthPercentage: 0.8,
    marginPriceTokenCount: 10.122321312,
    maintenanceMarginPriceTokenCount: 3.23123123,
  }

  return (
    <div
      className={"grid grid-cols-8 m-2.5"}
      style={{
        gridTemplateColumns: "1fr 320px",
      }}
    >
      <div className={"flex flex-col col-span-2 pr-2.5"}>
        <WiredSummaryBar />
        <div className="flex flex-1 mt-2.5">
          <ChartContainer
            key={store.marketId$}
            className="flex-1"
            datafeed={store.chartDatafeed}
            interval={"15" as ResolutionString}
            symbol={store.marketId$}
          />
          <WiredOrderbookListFrame className="ml-2.5">
            {(() => {
              if (
                store.orderbook.showingOrderType ===
                OrderListOrderType.OrderbookOrders
              ) {
                return <WiredOrderbookListContent />
              } else if (
                store.orderbook.showingOrderType ===
                OrderListOrderType.TradeOrders
              ) {
                return <WiredTradesOrderListContent />
              } else if (
                store.orderbook.showingOrderType ===
                OrderListOrderType.MyTradeOrders
              ) {
                return <WiredMyTradesOrderListContent />
              } else {
                assertNever(store.orderbook.showingOrderType)
              }
            })()}
          </WiredOrderbookListFrame>
        </div>
      </div>
      <div className={"col-start-3 col-span-1 row-start-1 row-span-1"}>
        <WiredCreateOrder />
        <MarginRatio
          className="col-start-3 col-span-1 row-start-3 row-span-1 gap-4 my-2.5"
          selectedMarginPool={mockMarginRadio.selectedMarginPool}
          availableMarginPools={mockMarginRadio.availableMarginPools}
          marginPriceTokenCount={mockMarginRadio.marginPriceTokenCount}
          healthPercentage={mockMarginRadio.healthPercentage}
          maintenanceMarginPriceTokenCount={
            mockMarginRadio.maintenanceMarginPriceTokenCount
          }
          onSelectedMarginPool={s => {
            console.log("onSelectedMarginPool")
          }}
        />
        <PerpetualAccountFrame
          className={"col-start-3 col-span-1 row-start-4 row-span-1 gap-4"}
        >
          <PerpetualAccountContent
            quotaToken={perpetualMock.quotaToken}
            totalBalance={perpetualMock.totalBalance}
            quotaTokenAvailableCount={suspenseResource(() => 1)}
            unrealizedPnl={suspenseResource(() => 1)}
            onWithdraw={suspenseResource(() => null)}
            onDeposit={() => {
              console.log("deposit")
            }}
          />
        </PerpetualAccountFrame>
      </div>
      <WiredOrderHistory
        className={
          "flex flex-col gap-5 col-start-1 col-span-4 row-start-4 row-span-1"
        }
      />
    </div>
  )
}

export const PerpetualPage: FC = () => {
  usePreventGlobalWiredDisclaimerModal()
  return (
    <RootLayoutContainer fullWidth={true} topExtraPadding={10}>
      <Suspense>
        <PerpetualPageContent />
      </Suspense>
    </RootLayoutContainer>
  )
}
