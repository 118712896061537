import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { Card } from "../../../../components/Card"
import { NavLink } from "../../../../components/NavLink"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { LaunchingStatus } from "../../store/LaunchPadContractStore"
import { IDOProgress } from "../IDOProgress/IDOProgress"
import { TokenIDOSummary } from "../TokenIDOSummary"
import { TokenNameCard } from "../TokenNameCard"
import {
  TokenIDOPrices,
  TokenIDORaiseNumbers,
  TokenIDOStatus,
  TokenMetaInfo,
} from "../types"

export interface TokenProfileSectionProps {
  className?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  idoTokenMeta: TokenMetaInfo
  idoStatus: TokenIDOStatus
  idoRaiseNumbers: TokenIDORaiseNumbers
  idoPrices: TokenIDOPrices
  tokenIntroPageLink: string
}

export const TokenProfileSection: FC<TokenProfileSectionProps> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx("flex flex-col gap-6", props.className)}>
      <TokenNameCard idoStatus={props.idoStatus} meta={props.idoTokenMeta} />
      <IDOProgress
        priceToken={props.priceToken}
        idoNumbers={props.idoRaiseNumbers}
        isFinished={props.idoStatus.type === LaunchingStatus.Finished}
      />
      <TokenIDOSummary
        idoToken={props.idoToken}
        priceToken={props.priceToken}
        idoPrices={props.idoPrices}
        idoNumbers={props.idoRaiseNumbers}
      />
      <div>
        <NavLink to={props.tokenIntroPageLink}>
          <OpacityButton
            className={"flex items-center justify-center !font-medium"}
          >
            {$t(
              defineMessage({
                defaultMessage: "Token introduction",
                description: "/Launchpad/TokenProfileSection/Nav link",
              }),
            )}
          </OpacityButton>
        </NavLink>
      </div>
    </Card>
  )
}
