import clsx from "clsx"
import { FC, ReactNode, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Column, useTable } from "react-table"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../components/table/StyledTable"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { customReactTableRender } from "../../../utils/customReactTableRender"
import { dontWrapObserver } from "../../../utils/mobxHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { getPrecisionFloor } from "../../../utils/numberHelpers"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ReactComponent as WarningIcon } from "./warning.svg"

export interface DataComparisonRowData {
  platform: string
  maxLTV: number
  loanableTokenLimit: number
  liquidationPrice?: number
  liquidated: boolean
  remainingValues: [number, number]
}

export interface DataComparisonProps {
  containerClassName?: string
  onClose?: () => void
  remainingValueDates: [Date, Date]
  dateRange: [Date, Date]
  initialPrice: number
  data: DataComparisonRowData[]
}

const tokenX = TokenInfoPresets.MockXBTC
const tokenY = TokenInfoPresets.MockXUSD

export const DataComparison: FC<DataComparisonProps> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      title={
        <div className="text-xl leading-7 font-medium text-gray-200 pb-1">
          {$t(
            defineMessage({
              defaultMessage: "Data Comparison",
              description: "/CRPSimulator/DataComparison/Title",
            }),
          )}
        </div>
      }
      onClose={props.onClose}
      className={clsx(
        props.containerClassName,
        "flex flex-col max-h-[80vh] max-w-[80vw]",
      )}
    >
      <p className="text-sm leading-5 font-normal text-gray-500">
        {$t(
          defineMessage({
            defaultMessage:
              "This market data is taken from {dateFrom, time, ::LLL dd yyyy} to {dateTo, time, ::LLL dd yyyy}, and assumes 1 {tokenX} @ ${initialPrice} is submitted as collateral and {tokenY} borrowed against it.",
            description: "/CRPSimulator/DataComparison/Description",
          }),
          {
            dateFrom: props.dateRange[0],
            dateTo: props.dateRange[1],
            tokenX: <TokenName token={tokenX} />,
            tokenY: <TokenName token={tokenY} />,
            initialPrice: (
              <TokenCount token={tokenX} count={props.initialPrice} />
            ),
          },
        )}
      </p>
      <DataTable
        data={props.data}
        remainingValueDates={props.remainingValueDates}
      />
      <div className="flex flex-row gap-x-2.5">
        <WarningIcon />
        <div className="text-sm leading-5 font-normal text-gray-500">
          {$t<ReactNode>(
            defineMessage({
              defaultMessage: `<title>DISCLAIMER:</title>
                 <content>
                    <listItem>Remaining Value is the sum, in USD, of {tokenX} and {tokenY} after dynamic rebalancing</listItem>
                    <listItem>Remaining Value is equal to the current xBTC price net of Liquidation Fee</listItem>
                    <listItem>We don't consider execution slippage in above simulations</listItem>
                 </content>`,
              description: "/CRPSimulator/DataComparison/Disclaimer",
            }),
            {
              title: dontWrapObserver(children => <p>{children}</p>),
              content: dontWrapObserver(children => <ul>{children}</ul>),
              listItem: dontWrapObserver(children => <li>{children}</li>),
              tokenX: <TokenName token={tokenX} />,
              tokenY: <TokenName token={tokenY} />,
            },
          )}
        </div>
      </div>
    </CardModalContent>
  )
}

const DataTable: FC<{
  data: DataComparisonRowData[]
  remainingValueDates: [Date, Date]
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({
      remainingValueDates: props.remainingValueDates,
    }),
    data: props.data,
  })
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } =
    tableInstance

  return (
    <div className="w-full overflow-auto">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps()}
                  className="text-xs leading-4 font-bold text-white bg-gray-800 border border-gray-600"
                >
                  {customReactTableRender(column as any, "Header")}
                </Th>
              ))}
            </TableHeadRow>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableBodyRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td
                    {...cell.getCellProps()}
                    className="text-xs leading-4 font-normal text-white bg-gray-800 border border-gray-600"
                  >
                    {cell.render("Cell")}
                  </Td>
                ))}
              </TableBodyRow>
            )
          })}
        </Tbody>
      </Table>
    </div>
  )
}

const useTableSchema = (context: {
  remainingValueDates: [Date, Date]
}): Column<DataComparisonRowData>[] => {
  const { $t } = useIntl()
  return useMemo(
    () => [
      {
        accessor: "platform",
        Header: $t(
          defineMessage({
            defaultMessage: "Platform",
            description: "/CRPSimulator/DataComparison/TableSchema/Header",
          }),
        ),
      },
      {
        accessor: data =>
          `$${formatTokenCount(tokenY, data.loanableTokenLimit)} (${
            data.maxLTV
          }%LTV)`,
        Header: $t(
          defineMessage({
            defaultMessage: "Loanable Token Limit (Max LTV)",
            description: "/CRPSimulator/DataComparison/TableSchema/Header",
          }),
        ),
      },
      {
        accessor: "liquidationPrice",
        Header: $t(
          defineMessage({
            defaultMessage: "Liquidation Price (xBTC)",
            description: "/CRPSimulator/DataComparison/TableSchema/Header",
          }),
        ),
        Cell: props => (
          <>
            {props.value ? (
              <>
                $<TokenCount token={tokenX} count={props.value} />
              </>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        accessor: data =>
          `$${formatTokenCount(tokenY, data.remainingValues[0])}${
            data.liquidated ? ` (liquidated)` : ""
          }`,
        Header: $t(
          defineMessage({
            defaultMessage: "Remaining Value {date, time, ::LLL d}",
            description: "/CRPSimulator/DataComparison/TableSchema/Header",
          }),
          { date: context.remainingValueDates[0] },
        ),
      },
      {
        accessor: data =>
          data.liquidated
            ? "-"
            : `$${formatTokenCount(tokenY, data.remainingValues[1])}`,
        Header: $t(
          defineMessage({
            defaultMessage: "Remaining Value {date, time, ::LLL d}",
            description: "/CRPSimulator/DataComparison/TableSchema/Header",
          }),
          {
            date: context.remainingValueDates[1],
          },
        ),
      },
    ],
    [$t, context.remainingValueDates],
  )
}

function formatTokenCount(token: TokenInfo, count: number): string {
  const precision = TokenInfo.getPrecision(token)
  const precisionFloor = getPrecisionFloor(precision)

  if (count === 0) return "0"
  if (count < precisionFloor) return `<${precisionFloor}`

  // according to @fiftyeightandeight
  // we need to always round up when display number
  const scale = Math.pow(10, precision)
  const roundedUp = Math.ceil(count * scale) / scale
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: precision,
  }).format(roundedUp)
}
