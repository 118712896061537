import { createStore } from "../../utils/createStore"
import { FCC } from "../../utils/reactHelpers/types"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useAppEnvStore } from "../appEnvStore/useAppEnvStore"
import { useAuthStore } from "../authStore/useAuthStore"
import { useCurrencyStore } from "../currencyStore/useCurrencyStore"
import { TestnetRewardsClaimStore } from "./TestnetRewardsClaimStore"

const Store = createStore<TestnetRewardsClaimStore>("TestnetRewardsClaimStore")

export const TestnetRewardsClaimStoreProvider: FCC = props => {
  const authStore = useAuthStore()
  const currencyStore = useCurrencyStore()
  const appEnv = useAppEnvStore()
  const store = useCreation(
    () => new TestnetRewardsClaimStore(authStore, currencyStore, appEnv),
    [authStore, currencyStore, appEnv],
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useTestnetRewardsClaimStore = Store.useStore.bind(null)
