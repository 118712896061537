import { FC } from "react"
import { AllTokenInfos, CurrencyAmount } from "../../types"
import { OneLineTokenCount } from "./OneLineTokenCount"
import { OneLineTokenName } from "./OneLineTokenName"

export interface OneLineTokenAmountProps {
  className?: string
  currencyAmount: CurrencyAmount
  allTokenInfos: AllTokenInfos
  prefix?: string
  suffix?: string
}

export const OneLineTokenAmount: FC<OneLineTokenAmountProps> = props => {
  const prefix = props.prefix ? props.prefix + " " : ""
  const suffix = props.suffix ? " " + props.suffix : ""
  if (
    CurrencyAmount.isMinimal(props.currencyAmount) ||
    CurrencyAmount.isMaximum(props.currencyAmount)
  ) {
    return (
      <span className="whitespace-nowrap">
        {prefix}
        <OneLineTokenName
          currency={props.currencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
        {CurrencyAmount.isMinimal(props.currencyAmount) ? "(>" : "(<"}
        <OneLineTokenCount
          count={
            CurrencyAmount.isMinimal(props.currencyAmount)
              ? props.currencyAmount.minAmount
              : props.currencyAmount.maxAmount
          }
          currency={props.currencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
        {")"}
        {suffix}
      </span>
    )
  }
  if (CurrencyAmount.isConfirmed(props.currencyAmount)) {
    return (
      <span className="whitespace-nowrap">
        {prefix}
        <OneLineTokenCount
          count={props.currencyAmount.amount}
          currency={props.currencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
        &nbsp;
        <OneLineTokenName
          currency={props.currencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
        {suffix}
      </span>
    )
  }
  return (
    <span className="whitespace-nowrap">
      {prefix}
      <OneLineTokenName
        currency={props.currencyAmount.currency}
        allTokenInfos={props.allTokenInfos}
      />
      {suffix}
    </span>
  )
}
