import { FC } from "react"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../../components/TokenIcon"
import { TokenName } from "../../../../../../components/TokenName"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"

export const TokenIconAndNumbersSection: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  maxRaiseIdoTokenCount: number
  maxRaiseIdoTokenPrice?: number
}> = props => (
  <div className={"flex gap-4 items-center"}>
    <TokenIcon size={48} token={props.idoToken} />

    <div>
      <p className={"text-2xl leading-8 font-semibold text-gray-200"}>
        <TokenCount
          token={props.idoToken}
          count={props.maxRaiseIdoTokenCount}
        />{" "}
        <TokenName token={props.idoToken} />
      </p>

      {props.maxRaiseIdoTokenPrice != null && (
        <p className={"text-sm leading-5 font-normal text-gray-400"}>
          ≈{" "}
          <TokenCount
            token={props.priceToken}
            count={props.maxRaiseIdoTokenPrice}
          />{" "}
          <TokenName token={props.priceToken} />
        </p>
      )}
    </div>
  </div>
)
