import { FC } from "react"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { useLaunchPadContractStore } from "../store/useLaunchPadStores"
import { RedeemOrdinalsModalContent } from "./RedeemOrdinalsModalContent/RedeemOrdinalsModalContent"

export const WiredRedeemOrdinalsModalContent: FC = () => {
  const launchpadStore = useLaunchPadContractStore()
  const store = launchpadStore.redeem.orderbookStore
  const onClose = (): void => launchpadStore.redeem.hideRedeemModal()
  return (
    <>
      <Modal visible={store != null} onClose={onClose}>
        <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
          {() => {
            if (store == null) {
              return null
            }
            const { pegOutForm } = store.brc20Pegging
            return (
              <RedeemOrdinalsModalContent
                formErrorMessage={suspenseResource(() =>
                  Result.maybeError(store.brc20Pegging.pegOutForm.formData$),
                )}
                queuingPegRequestCount={suspenseResource(
                  () => store.brc20Pegging.queuedTransactionCount$,
                )}
                token={launchpadStore.tokenInfo$}
                tokenAmount={suspenseResource(() => pegOutForm.amount.read$)}
                targetBtcAddress={pegOutForm.toBtcAddr.get() ?? undefined}
                onTargetBtcAddressChange={v =>
                  pegOutForm.toBtcAddr.set(v ?? undefined)
                }
                candidateTargetBtcAddress={suspenseResource(
                  () => pegOutForm.candidateBtcAddress$ ?? undefined,
                )}
                onFillCandidateTargetBtcAddress={suspenseResource(() => {
                  const addr = pegOutForm.candidateBtcAddress$
                  if (addr == null) return undefined
                  return () => pegOutForm.toBtcAddr.set(addr)
                })}
                redeemFee={suspenseResource(
                  () => store.brc20Pegging.pegOutForm.pegOutFee$,
                )}
                redeemFeeRate={suspenseResource(
                  () => store.brc20Pegging.pegOutForm.pegOutFeeRate$,
                )}
                redeemFeeToken={suspenseResource(
                  () => store.brc20Pegging.pegOutForm.pegOutFeeToken$,
                )}
                redeemGasFee={suspenseResource(
                  () => store.brc20Pegging.pegOutForm.pegOutGasFee$,
                )}
                redeemGasFeeToken={suspenseResource(
                  () => store.brc20Pegging.pegOutForm.pegOutGasFeeToken$,
                )}
                onSubmit={suspenseResource(() => {
                  const formData = store.brc20Pegging.pegOutForm.formData$
                  return formData.type === "error"
                    ? undefined
                    : async () => {
                        onClose()
                        await launchpadStore.redeem.txStore.run(() =>
                          store.brc20Pegging.pegOutToken(formData.payload),
                        )
                      }
                })}
                onClose={onClose}
              />
            )
          }}
        </Spensor>
      </Modal>
      <WiredTransactionStateDialog store={launchpadStore.redeem.txStore} />
    </>
  )
}
