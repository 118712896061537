import { unwrapResponse } from "clarity-codegen"
import { range } from "lodash"
import { computed, makeObservable } from "mobx"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import LaunchPadContractStore from "./LaunchPadContractStore"
import {
  fetchUserSubscription,
  idoWalkResolution,
} from "./LaunchPadContractStore.service"
import { determineApower } from "./determineApower"

class UserIDOStatusViewModule {
  constructor(readonly store: LaunchPadContractStore) {
    makeObservable(this)
  }

  private registered = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.idoId,
        this.store.launchPadVersion,
      ] as const,
    async ([stxAddress, idoId, version]) => {
      return await fetchUserSubscription(stxAddress, idoId, version)
    },
    {
      decorator: pMemoizeDecorator({
        persistKey: "launchpad-user-registered",
      }),
    },
  )

  private registeredTx = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.idoId,
        this.store.launchPadVersion,
        this.store.accountStore.transactions$,
        this.store.authStore.currentBlockHeight$,
      ] as const,
    async ([_stxAddress, idoId, version, transaction]) => {
      await transaction.sync()
      const txs = await transaction.decodedContractCallTransactions(
        version,
        "register",
      )
      const registerTx = txs.find(
        tx =>
          ("ido-id" in tx.args ? tx.args["ido-id"] : tx.args["launch-id"]) ===
          idoId,
      )
      if (registerTx == null) {
        return null
      }
      const { start, end } = unwrapResponse(registerTx.result)
      return {
        start: start / idoWalkResolution,
        end: end / idoWalkResolution,
      }
    },
    {
      decorator: pMemoizeDecorator({
        persistKey: "launchpad-user-subscription",
      }),
    },
  )

  @computed get registeredTicketIds(): number[] {
    // in the case of refunded tickets, readonly call will return undefined
    const r = this.registered.value$ ?? this.registeredTx.value$
    if (r == null) {
      return []
    }
    return range(r.start, r.end)
  }

  @computed get registeredTicketCount(): number {
    return this.registeredTicketIds.length
  }

  @computed get remainingRegisteredTicketCount(): number {
    return this.registeredTicketCount
  }

  @computed get hasValidated(): boolean {
    return this.registeredTicketCount > 0
  }

  @computed get lockedAPower$(): number {
    const detail = this.store.detail$.detail
    return (
      determineApower(
        this.registeredTicketCount,
        detail["apower-per-ticket-in-fixed"],
      ) / 1e8
    )
  }

  @computed get lockedPriceToken$(): number {
    return (
      (this.remainingRegisteredTicketCount *
        this.store.detail$.detail["price-per-ticket-in-fixed"]) /
      1e8
    )
  }

  @computed get allocatedTokenCount$(): number {
    return this.store.idoTokenCountPerTicket$ * this.lotteryWon$
  }

  @computed get lotteryWon$(): number {
    const allWonTickets = this.store.claimViewModule.wonTicketIds$
    return this.store.userIDOViewModule.registeredTicketIds.filter(id =>
      allWonTickets.includes(id),
    ).length
  }

  @computed get lotteryLost$(): number {
    return this.store.userIDOViewModule.registeredTicketCount - this.lotteryWon$
  }

  @computed get returnedPriceTokenCount$(): number {
    return this.lotteryLost$ * this.store.pricePerTicket$
  }
}

export default UserIDOStatusViewModule
