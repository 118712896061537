import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useColors } from "../../../../../components/Themed/color"
import { BasicCardTab } from "./basicCardTab"

export const StacksProjectCardTab: FC<{ className?: string }> = props => {
  const colors = useColors()
  const { $t } = useIntl()

  return (
    <BasicCardTab className={props.className} tintColor={colors("blue-600")}>
      {$t(
        defineMessage({
          defaultMessage: "Stacks Project",
          description: "/Launchpad/IDO List Large Card/Card Tab/Stacks Project",
        }),
      )}
    </BasicCardTab>
  )
}

export const Brc20ProjectCardTab: FC<{ className?: string }> = props => {
  const colors = useColors()
  const { $t } = useIntl()

  return (
    <BasicCardTab className={props.className} tintColor={colors("yellow-600")}>
      {$t(
        defineMessage({
          defaultMessage: "BRC-20 Project",
          description: "/Launchpad/IDO List Large Card/Card Tab/Brc20 Project",
        }),
      )}
    </BasicCardTab>
  )
}

export const OrdinalsProjectCardTab: FC<{ className?: string }> = props => {
  const colors = useColors()
  const { $t } = useIntl()

  return (
    <BasicCardTab className={props.className} tintColor={colors("orange-600")}>
      {$t(
        defineMessage({
          defaultMessage: "Ordinals Project",
          description:
            "/Launchpad/IDO List Large Card/Card Tab/Ordinals Project",
        }),
      )}
    </BasicCardTab>
  )
}
