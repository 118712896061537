import clsx from "clsx"
import { withSlot } from "../utils/reactHelpers/withSlot"

export namespace PinnacleLayoutMode {
  export const Container = withSlot<{ className?: string }>("div", props => (
    <props.Comp
      className={clsx(
        "grid grid-cols-24 gap-y-8 lg:gap-8 mb-8",
        props.className,
      )}
    >
      {props.children}
    </props.Comp>
  ))

  export const Top = withSlot<{ className?: string }>("div", props => (
    <props.Comp className={clsx("col-span-full", props.className)}>
      {props.children}
    </props.Comp>
  ))

  export const BottomLeft = withSlot<{ className?: string }>("div", props => (
    <props.Comp
      className={clsx("col-span-full lg:col-span-11", props.className)}
    >
      {props.children}
    </props.Comp>
  ))

  export const BottomRight = withSlot<{ className?: string }>("div", props => (
    <props.Comp
      className={clsx("col-span-full lg:col-span-13", props.className)}
    >
      {props.children}
    </props.Comp>
  ))
}
