import {
  booleanT,
  bufferT,
  defineContract,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const age003EmergencyExecute = defineContract({
  "age003-emergency-execute": {
    callback: {
      input: [
        { name: "sender", type: principalT },
        { name: "memo", type: bufferT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "executive-action": {
      input: [{ name: "proposal", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "is-dao-or-extension": {
      input: [],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-executive-team-member": {
      input: [
        { name: "who", type: principalT },
        { name: "member", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-executive-team-sunset-height": {
      input: [{ name: "height", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-signals-required": {
      input: [{ name: "new-requirement", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-signals": {
      input: [{ name: "proposal", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "get-signals-required": { input: [], output: numberT, mode: "readonly" },
    "has-signalled": {
      input: [
        { name: "proposal", type: principalT },
        { name: "who", type: principalT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "is-executive-team-member": {
      input: [{ name: "who", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "executive-action-signal-count": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "executive-action-signals": {
      input: tupleT({ proposal: principalT, "team-member": principalT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "executive-team": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "executive-signals-required": {
      input: noneT,
      output: numberT,
      mode: "variable",
    },
    "executive-team-sunset-height": {
      input: noneT,
      output: numberT,
      mode: "variable",
    },
  },
} as const)
