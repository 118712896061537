import { FC, ReactNode, useDebugValue, useEffect, useState } from "react"

export interface LanguageSet {
  /**
   * IETF language tag, e.g. "en-US".
   */
  languageTag: string

  /**
   * The language's display name, e.g. "English".
   */
  displayName: string

  /**
   * The language tag's regex, e.g. /en-\W+/.
   */
  regex: RegExp

  /**
   * The language's translation messages.
   */
  content: () => Promise<Record<string, string>>
}

export const ImportLanguage: FC<{
  languageTag: string | Promise<string>
  availableLanguages: Readonly<LanguageSet[]>
  children: (
    renderProps:
      | undefined
      | {
          language: LanguageSet
          messages: Record<string, string>
        },
  ) => ReactNode
}> = props => {
  const [loadState, setLoadState] = useState<
    | { state: "loading" }
    | {
        state: "success"
        value: {
          language: LanguageSet
          messages: Record<string, string>
        }
      }
    | { state: "error"; error: Error }
  >({ state: "loading" })

  useDebugValue(loadState)

  useEffect(() => {
    const abortCtrl = new AbortController()

    void (async () => {
      const languageTag = await props.languageTag
      console.log("languageTag", languageTag)
      const languageSet = props.availableLanguages.find(a =>
        a.regex.test(languageTag),
      )
      if (!languageSet) {
        setLoadState({ state: "error", error: new Error("Not found language") })
      } else {
        const value = await languageSet.content()
        if (abortCtrl.signal.aborted) return
        setLoadState({
          state: "success",
          value: { language: languageSet, messages: value },
        })
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [props.availableLanguages, props.languageTag])

  if (loadState.state === "loading" || loadState.state === "error") {
    return <>{props.children(undefined)}</>
  } else {
    return <>{props.children(loadState.value)}</>
  }
}
