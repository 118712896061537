import clsx from "clsx"
import { FC, ReactNode, useEffect, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  createInfoListItemDetail,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { Spensor } from "../../../components/Spensor"
import { subDuration } from "../../../components/TimeCountdown/helpers"
import { FCC } from "../../../utils/reactHelpers/types"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"

const PageClockCountdownImpl: FC<{
  className?: string
  time: Date
}> = props => {
  const { $t } = useIntl()
  const [duration, updateDuration] = useState(() =>
    subDuration(new Date(), props.time),
  )

  useEffect(() => {
    const timer = setInterval(() => {
      updateDuration(subDuration(new Date(), props.time))
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [props.time])

  if (duration == null) {
    return (
      <PageClockCountdownMainText className={props.className}>
        {$t(
          defineMessage({
            defaultMessage: "Less than 1 Minute",
            description: "/Lottery/Clock count down",
          }),
        )}
      </PageClockCountdownMainText>
    )
  }

  return (
    <InfoList
      className={props.className}
      gapClassName={"gap-1"}
      direction={"row"}
      listItemDirection={"column"}
      listItemClassName={"items-center"}
      InfoListItemDetail={createInfoListItemDetail({
        className: clsx(
          durationNumberTextClassName,
          durationNumberVerticalPaddingClassName,
          "text-center",
          "bg-purple-600 min-w-[64px] px-[5px] rounded-lg",
        ),
      })}
    >
      <InfoListItem>
        <InfoListItemDetail>
          {formatDurationNumber(duration.days)}
        </InfoListItemDetail>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Days",
              description: "/Lottery/ClockCountdown/Info list item title",
            }),
          )}
        </InfoListItemTitle>
      </InfoListItem>
      <DurationNumberDivider>:</DurationNumberDivider>
      <InfoListItem>
        <InfoListItemDetail>
          {formatDurationNumber(duration.hours)}
        </InfoListItemDetail>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Hours",
              description: "/Lottery/ClockCountdown/Info list item title",
            }),
          )}
        </InfoListItemTitle>
      </InfoListItem>
      <DurationNumberDivider>:</DurationNumberDivider>
      <InfoListItem>
        <InfoListItemDetail>
          {formatDurationNumber(duration.minutes)}
        </InfoListItemDetail>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Minutes",
              description: "/Lottery/ClockCountdown/Info list item title",
            }),
          )}
        </InfoListItemTitle>
      </InfoListItem>
    </InfoList>
  )
}
const durationNumberVerticalPaddingClassName = "py-[10px]"
const durationNumberTextClassName = "text-2xl font-semibold text-white"
const DurationNumberDivider = withClassName(
  clsx(
    durationNumberTextClassName,
    durationNumberVerticalPaddingClassName,
    "text-white/30",
  ),
  "span",
)
const formatDurationNumber = (n: undefined | number): string => {
  if (n == null) return "00"
  if (n < 10) return `0${n}`
  if (n > 99) return "99"
  return String(n)
}

const PageClockCountdownMainText = withClassName(
  "text-3xl leading-9 font-medium text-gray-200",
  "div",
)

export const PageClockCountdownTitleFrame: FCC<{
  className?: string
  title?: ReactNode
}> = props => (
  <div className={clsx("flex flex-col items-center gap-2", props.className)}>
    <div className={clsx("text-base leading-6 font-medium text-center")}>
      {props.title}
    </div>
    {props.children}
  </div>
)

export const PageClockCountdown: FC<{
  className?: string
  title?: ReactNode
  targetBlock: number
  currentBlock: SuspenseResource<number>
  time: SuspenseResource<Date>
}> = props => {
  const { $t } = useIntl()

  return (
    <Spensor>
      {() => {
        const targetBlock = props.targetBlock
        const currentBlock = readResource(props.currentBlock)
        const time = readResource(props.time)

        if (targetBlock - currentBlock > 1) {
          return (
            <PageClockCountdownImpl className={props.className} time={time} />
          )
        }

        return (
          <PageClockCountdownMainText className={props.className}>
            {$t(
              defineMessage({
                defaultMessage: "Less than 1 Block",
                description: "/Lottery/Clock count down text",
              }),
            )}
          </PageClockCountdownMainText>
        )
      }}
    </Spensor>
  )
}
