import {
  DependencyList,
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

export type AsyncState<T> =
  | AsyncState.Idle
  | AsyncState.Loading<T>
  | AsyncState.Success<T>
  | AsyncState.Failed<T>

export namespace AsyncState {
  export type Settled<T> = Success<T> | Failed<T>

  export interface Idle {
    readonly promise?: undefined
    readonly loading: false
    readonly error?: undefined
    readonly value?: undefined
  }

  export interface Loading<T> {
    readonly promise: Promise<T>
    readonly loading: true
    readonly error?: undefined
    readonly value?: undefined
  }

  export interface Failed<T> {
    readonly promise: Promise<T>
    readonly loading: false
    readonly error: unknown
    readonly value?: undefined
  }

  export interface Success<T> {
    readonly promise: Promise<T>
    readonly loading: false
    readonly error?: undefined
    readonly value: T
  }
}

export function useAsync<Result = any, Args extends any[] = any[]>(
  fn: (...args: Args | []) => Promise<Result>,
  deps: DependencyList = [],
): useAsync.Controller<Result, Args | []> {
  const isFirstTimeRenderRef = useRef(true)
  const isFirstTimeMountRef = useRef(true)

  const res = useAsyncFnFactory(
    () => fn,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
    isFirstTimeRenderRef.current ? { loading: true, promise: fn() } : undefined,
  ) as useAsync.Controller<Result, Args | []>
  if (isFirstTimeRenderRef.current) isFirstTimeRenderRef.current = false

  const [, reRun] = res
  useEffect(
    () => {
      // Skip `reRun` when component first time mounted, because we have already
      // executed `fn` before
      if (isFirstTimeMountRef.current) {
        isFirstTimeMountRef.current = false
      } else {
        void reRun()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, reRun],
  )

  useDebugValue(res)

  return res
}
export namespace useAsync {
  export type State<T> =
    | AsyncState.Loading<T>
    | AsyncState.Success<T>
    | AsyncState.Failed<T>

  export type AsyncFn<
    Result = any,
    Args extends any[] = any[],
  > = useAsyncFnFactory.AsyncFn<Result, Args>

  export type Controller<Result = any, Args extends any[] = any[]> = [
    State<Result>,
    AsyncFn<AsyncState.Settled<Result>, Args>,
  ]
}

export function useAsyncFnFactory<Result = any, Args extends any[] = any[]>(
  fnFactory: () => (...args: Args) => Promise<Result>,
  deps: DependencyList = [],
  initialState: useAsyncFnFactory.State<Result> = { loading: false },
): useAsyncFnFactory.Controller<Result, Args> {
  const stateRef = useRef(initialState)
  const [, rerenderComponent] = useState(Date.now())

  const lastWaitingPromiseRef = useRef<null | Promise<Result>>(null)
  const inflightPromiseToState = useCallback(
    async (promise: Promise<Result>): Promise<AsyncState.Settled<Result>> => {
      lastWaitingPromiseRef.current = promise
      let newState: useAsyncFnFactory.State<Result>

      try {
        const value = await promise
        newState = { value, loading: false, promise }
      } catch (error) {
        newState = { error, loading: false, promise }
      }

      const isInflightPromiseChanged = promise !== lastWaitingPromiseRef.current

      if (isInflightPromiseChanged) {
        // state should never be `Idle` in this case
        const latestState = stateRef.current as Exclude<
          useAsyncFnFactory.State<Result>,
          AsyncState.Idle
        >
        return inflightPromiseToState(latestState.promise)
      }

      return newState
    },
    [],
  )

  const handlePromiseCallId = useRef(0)
  const handlePromise = useCallback(
    async (promise: Promise<Result>): Promise<AsyncState.Settled<Result>> => {
      const callId = (handlePromiseCallId.current += 1)

      stateRef.current = await inflightPromiseToState(promise)
      const isStillTheLastBeCall = callId === handlePromiseCallId.current
      if (isStillTheLastBeCall) rerenderComponent(Date.now())

      return stateRef.current
    },
    [inflightPromiseToState],
  )

  const fn = useMemo(
    () => fnFactory(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  )

  const reRun = useCallback(
    async (...args: Args) => {
      const promise = fn(...args)

      stateRef.current = { loading: true, promise }
      rerenderComponent(Date.now())

      return handlePromise(promise)
    },
    [fn, handlePromise],
  )

  const isFirstTimeRef = useRef(true)
  if (isFirstTimeRef.current) {
    isFirstTimeRef.current = false
    if (stateRef.current.loading && stateRef.current.promise) {
      void handlePromise(stateRef.current.promise)
    }
  }

  const res: useAsyncFnFactory.Controller<Result, Args> = [
    stateRef.current,
    reRun,
  ]

  useDebugValue(res)

  return res
}
export namespace useAsyncFnFactory {
  export type State<T> =
    | AsyncState.Idle
    | AsyncState.Loading<T>
    | AsyncState.Success<T>
    | AsyncState.Failed<T>

  export type AsyncFn<Result = any, Args extends any[] = any[]> = (
    ...args: Args
  ) => Promise<Result>

  export type Controller<Result = any, Args extends any[] = any[]> = [
    State<Result>,
    AsyncFn<AsyncState.Settled<Result>, Args>,
  ]
}
