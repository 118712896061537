import { createContext } from "react"
import { RenderFn } from "../../../utils/reactHelpers/childrenHelpers"
import { InfoListItemProps } from "../InfoListItem"
import { InfoListItemDetailProps } from "../InfoListItemDetail"
import { InfoListItemTitleProps } from "../InfoListItemTitle"

export interface InfoListRenderersContextValue {
  renderInfoListItem?: undefined | RenderFn<InfoListItemProps>
  renderInfoListItemTitle?: undefined | RenderFn<InfoListItemTitleProps>
  renderInfoListItemDetail?: undefined | RenderFn<InfoListItemDetailProps>
}

export const InfoListRenderersContext =
  createContext<InfoListRenderersContextValue>({})
