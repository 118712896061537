import { useIsRTL } from "../reactHelpers/useIsRTL"
import { cleanupNullishFields } from "./styleHelpers"

export interface PaddingStyle {
  padding?: number
  paddingBottom?: number
  paddingEnd?: number
  paddingHorizontal?: number
  paddingLeft?: number
  paddingRight?: number
  paddingStart?: number
  paddingTop?: number
  paddingVertical?: number
}

export interface NormalizedPaddingStyle {
  paddingTop?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
}

export const useNormalizePaddingStyle = (
  padding: undefined | number | PaddingStyle,
): NormalizedPaddingStyle => {
  const isRTL = useIsRTL()

  if (padding == null) return {}

  if (typeof padding === "number") {
    return {
      paddingTop: padding,
      paddingBottom: padding,
      paddingLeft: padding,
      paddingRight: padding,
    }
  }

  const res: NormalizedPaddingStyle = cleanupNullishFields({
    paddingTop:
      padding.paddingTop ?? padding.paddingVertical ?? padding.padding,
    paddingBottom:
      padding.paddingBottom ?? padding.paddingVertical ?? padding.padding,
    paddingLeft:
      padding.paddingLeft ??
      (isRTL ? padding.paddingEnd : padding.paddingStart) ??
      padding.paddingHorizontal ??
      padding.padding,
    paddingRight:
      padding.paddingRight ??
      (isRTL ? padding.paddingStart : padding.paddingEnd) ??
      padding.paddingHorizontal ??
      padding.padding,
  })

  return res
}
