import { TypedDataDomain, TypedDataField } from "@ethersproject/abstract-signer"
import BigNumber from "bignumber.js"
import { BigNumber as EtherBigNumber, Signer, providers } from "ethers"
import { memoize, noop } from "lodash"
import { Observable, switchMap } from "rxjs"
import { ERC20, ERC20__factory } from "../../generated/evmContract"
import { ETHChain } from "../appEnvStore/ETHChain"
import { ETHCurrency } from "../appEnvStore/ETHCurrency"
import { MetaMaskEthereumProvider } from "./MetaMaskEthereumProvider/MetaMaskEthereumProvider"
import { ConnectInfo } from "./MetaMaskEthereumProvider/MetaMaskEthereumProviderNativeEvents"
import { AddEthereumChainParameter } from "./MetaMaskEthereumProvider/MetaMaskEthereumProviderRequests"
import { ethSubscribe } from "./MetaMaskEthereumProvider/ethSubscribe"
import { chainIdToETHChain } from "./_/ETHChainInfo"

export const connectedChain = (
  provider: MetaMaskEthereumProvider,
): Observable<{
  chain: ETHChain
  chainId: string
}> => {
  return new Observable<{ chain: ETHChain; chainId: string }>(ob => {
    const handleConnected = (info: ConnectInfo): void => {
      ob.next({
        chain: chainIdToETHChain(info.chainId),
        chainId: info.chainId,
      })
    }
    provider.on("connect", handleConnected)

    const handleChainId = (chainId: string): void => {
      ob.next({
        chain: chainIdToETHChain(chainId),
        chainId: chainId,
      })
    }
    provider.on("chainChanged", handleChainId)

    provider
      .request({ method: "eth_chainId" })
      .then(handleChainId, err => ob.error(err))

    return () => {
      provider.removeListener("connect", handleConnected)
      provider.removeListener("chainChanged", handleChainId)
    }
  })
}

export const switchToChainSimply = async (
  provider: MetaMaskEthereumProvider,
  chainId: string,
): Promise<void> => {
  await provider.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId }],
  })
}
export const switchToChain = async (
  provider: MetaMaskEthereumProvider,
  chainInfo: AddEthereumChainParameter,
): Promise<void> => {
  try {
    await switchToChainSimply(provider, chainInfo.chainId)
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as any).code === 4902) {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [chainInfo],
      })
    }
    throw switchError
  }
}

export const connectedWalletAddress = (
  provider: MetaMaskEthereumProvider,
): Observable<undefined | string> => {
  return new Observable<string | undefined>(ob => {
    const handleAccounts = (accounts: string[]): void => {
      ob.next(accounts[0])
    }
    provider.on("accountsChanged", handleAccounts)

    const handleDisconnect = (): void => {
      ob.next(undefined)
    }
    provider.on("disconnect", handleDisconnect)

    provider
      .request({ method: "eth_accounts" })
      .then(handleAccounts, err => ob.error(err))

    return () => {
      provider.removeListener("accountsChanged", handleAccounts)
      provider.removeListener("disconnect", handleDisconnect)
    }
  })
}

export const currentBlockNumber = (
  provider: MetaMaskEthereumProvider,
): Observable<string> => {
  return new Observable(ob => {
    let receivedValueFromEvents = false

    const sub = ethSubscribe(provider, "newHeads", result => {
      receivedValueFromEvents = true
      ob.next(result.number)
    })

    sub.promise.catch(ob.error)

    provider.request({ method: "eth_blockNumber" }).then(
      num => {
        if (receivedValueFromEvents) return
        ob.next(num)
      },
      err => ob.error(err),
    )

    return sub.unsubscribe
  })
}

export function getERC20TokenBalance(
  web3Provider: MetaMaskEthereumProvider,
  contract: ERC20,
  walletAddress: string,
): Observable<number> {
  return currentBlockNumber(web3Provider).pipe(
    switchMap(async () =>
      erc20AmountFromContractToNative(
        contract,
        await contract.balanceOf(walletAddress),
      ),
    ),
  )
}

export function getERC20TokenAllowance(
  web3Provider: MetaMaskEthereumProvider,
  contract: ERC20,
  walletAddress: string,
  contractAddress: string,
): Observable<number> {
  return currentBlockNumber(web3Provider).pipe(
    switchMap(async () =>
      erc20AmountFromContractToNative(
        contract,
        await contract.allowance(walletAddress, contractAddress),
      ),
    ),
  )
}

/**
 * @example
 * ```typescript
 *   const typedData = {
 *     types: {
 *       Message: [
 *         { name: "txId", type: "string" },
 *         { name: "toAddress", type: "string" },
 *       ],
 *     },
 *     domain: {
 *       name: "Stacks Bridge",
 *       version: "1",
 *     },
 *     message: {
 *       txId: transactionHash,
 *       toAddress: toSTXAddress,
 *     },
 *   }
 *
 *   return await signTypedData(
 *     typedData.domain,
 *     typedData.types,
 *     typedData.message,
 *   )
 * ```
 */
export type SignTypedDataFn = (
  domain: Omit<TypedDataDomain, "chainId">,
  types: Record<string, Array<TypedDataField>>,
  value: Record<string, any>,
) => Promise<string>
export async function getSignTypedDataFn(
  provider: MetaMaskEthereumProvider,
  chainId: string,
): Promise<SignTypedDataFn> {
  const web3Provider = new providers.Web3Provider(provider as any)
  const signer = web3Provider.getSigner()

  return async (domain, types, value) => {
    return await signer._signTypedData(
      { chainId: String(parseInt(chainId, 16)), ...domain },
      types,
      value,
    )
  }
}

export interface TransferResponse {
  hash: string
  waitConfirmations: () => Promise<void>
}
export async function transferERC20Token(
  signer: Signer,
  tokenContractAddr: string,
  chain: ETHChain,
  fromWalletAddress: string,
  toWalletAddress: string,
  amount: number,
): Promise<TransferResponse> {
  const tokenContract = ERC20__factory.connect(tokenContractAddr, signer)
  const resp = await tokenContract.transfer(
    toWalletAddress,
    erc20AmountFromNativeToContract(tokenContract, amount),
  )
  return {
    hash: resp.hash,
    waitConfirmations: () => resp.wait().then(noop),
  }
}

export const MAX_AMOUNT_TO_APPROVE = EtherBigNumber.from(2).pow(256).sub(1)
export async function isERC20TokenNeedToResetAllowanceFirst(
  chain: ETHChain,
  currency: ETHCurrency,
  tokenContract: ERC20,
  contractAddress: string,
  fromWalletAddress: string,
  amount: number,
): Promise<boolean> {
  if (chain !== ETHChain.Ethereum || currency !== ETHCurrency.USDT) {
    return false
  }

  const allowance = await tokenContract
    .allowance(fromWalletAddress, contractAddress)
    .then(n => erc20AmountFromContractToNative(tokenContract, n))
  if (allowance === 0) {
    return false
  }

  return allowance < amount
}
export async function approveERC20Token(
  provider: MetaMaskEthereumProvider,
  chain: ETHChain,
  tokenContract: ERC20,
  contractAddress: string,
  fromWalletAddress: string,
  amount: number,
  isAllowanceIneligible?: (allowance: number) => boolean,
): Promise<void> {
  const _isAllowanceIneligible =
    isAllowanceIneligible ?? (allowance => allowance < amount)

  let allowance = await tokenContract
    .allowance(fromWalletAddress, contractAddress)
    .then(n => erc20AmountFromContractToNative(tokenContract, n))
  while (_isAllowanceIneligible(allowance)) {
    const estimatedGas = await tokenContract.estimateGas
      .approve(contractAddress, MAX_AMOUNT_TO_APPROVE)
      .catch(() => EtherBigNumber.from(50000)) // add a fallback in case estimate failed
    const tx = await tokenContract.approve(
      contractAddress,
      MAX_AMOUNT_TO_APPROVE,
      {
        /**
         * 1.5x estimated gas limit, cuz sometimes `eth_estimateGas` returned
         * number is not enough for USDT's approve method.
         */
        gasLimit: estimatedGas.mul(15).div(10),
      },
    )
    await tx.wait()
    allowance = await tokenContract
      .allowance(fromWalletAddress, contractAddress)
      .then(n => erc20AmountFromContractToNative(tokenContract, n))
  }
}
export async function resetAndApproveERC20Token(
  provider: MetaMaskEthereumProvider,
  chain: ETHChain,
  tokenContract: ERC20,
  contractAddress: string,
  fromWalletAddress: string,
  amount: number,
): Promise<void> {
  // reset to 0
  await approveERC20Token(
    provider,
    chain,
    tokenContract,
    contractAddress,
    fromWalletAddress,
    0,
    allowance => allowance !== 0,
  )

  // set the new amount
  await approveERC20Token(
    provider,
    chain,
    tokenContract,
    contractAddress,
    fromWalletAddress,
    amount,
  )
}

export const getErc20Decimals = memoize(async (contract: ERC20) => {
  return await contract.decimals()
})

export async function erc20AmountFromContractToNative(
  contract: ERC20,
  contractValue: EtherBigNumber,
): Promise<number> {
  const decimals = await getErc20Decimals(contract)
  return new BigNumber(contractValue.toString()).div(10 ** decimals).toNumber()
}

export async function erc20AmountFromNativeToContract(
  contract: ERC20,
  nativeValue: number,
): Promise<EtherBigNumber> {
  const decimals = await getErc20Decimals(contract)
  return EtherBigNumber.from(
    new BigNumber(nativeValue)
      .multipliedBy(10 ** decimals)
      .toFixed(0, BigNumber.ROUND_DOWN),
  )
}
