import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { GrayBadge, GreenBadge } from "../../../components/Badge"
import { PageStackPage } from "../../../components/PageStack/PageStackPage"
import { SecondaryPageTitle } from "../../../components/PageStack/SecondaryPageTitle"
import { usePathGenerator } from "../../../routes/routes"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { AMMSwapPool } from "../../../utils/alexjs/AMMSwapPool"
import { Currency, isCurrency } from "../../../utils/alexjs/Currency"
import { poolMigration } from "../../../utils/alexjs/PoolMigrations"
import {
  LiquidityPoolToken,
  isFWPToken,
} from "../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { CopywritingProvider } from "../../Stake/components/CopywritingProvider"
import { WiredCoFarmStakePageContent } from "../../Stake/components/WiredCoFarmStakePageContent"
import { WiredStakePageContent } from "../../Stake/components/WiredStakePageContent"
import { ManualStakeStoreProvider } from "../../Stake/store/manualStaking/useManualStakeStore"
import { FarmingCopywriting } from "../_/FarmingCopywriting"
import PoolTokenName from "../components/PoolTokenName"
import { CoFarmStoreProvider } from "../stores/useCoFarmStore"

const FarmDetailPageImpl: FC<{ token: TokenInfo }> = props => {
  const gen = usePathGenerator()
  const appEnv = useAppEnvStore()
  const { $t } = useIntl()

  return (
    <PageStackPage
      title={
        <div className="flex items-center gap-x-2">
          <SecondaryPageTitle returnFallbackURL={gen.farmList()}>
            <span>
              {$t(
                defineMessage({
                  defaultMessage: "Farm LP:",
                  description: "/Farm/Farm detail page title",
                }),
              )}
            </span>
            <PoolTokenName token={props.token} />
          </SecondaryPageTitle>
          {props.token.id in poolMigration && <GrayBadge>Migrating</GrayBadge>}
          {Object.values(poolMigration).includes(
            props.token.id as LiquidityPoolToken,
          ) && <GreenBadge>Main</GreenBadge>}
        </div>
      }
    >
      {props.token.id === Currency.FWP_STX_ALEX_50_50_V1_01 &&
      appEnv.config$.enableCoFarmFeature ? (
        <CoFarmStoreProvider token={Currency.FWP_STX_ALEX_50_50_V1_01}>
          <WiredCoFarmStakePageContent />
        </CoFarmStoreProvider>
      ) : (
        <WiredStakePageContent />
      )}
    </PageStackPage>
  )
}

export const FarmDetailPage: FC = () => {
  const currency = useParams().token

  if (
    !isCurrency(currency) ||
    (!isFWPToken(currency) &&
      currency !== Currency.ALEX &&
      !AMMSwapPool.isPoolToken(currency))
  ) {
    throw new Error(`Invalid token ${currency}`)
  }

  const intl = useIntl()
  const token = useCurrencyStore().getTokenInfo$(currency)

  return (
    <ManualStakeStoreProvider token={currency}>
      <CopywritingProvider value={new FarmingCopywriting(intl)}>
        <FarmDetailPageImpl token={token} />
      </CopywritingProvider>
    </ManualStakeStoreProvider>
  )
}
