import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { AlertDialogModalContent } from "../../../components/dialog/AlertDialogModalContent"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"

const WiredWhiteListCheck: FC = () => {
  const store = useWrapBridgeStore()
  const { $t } = useIntl()
  return (
    <Spensor>
      {() => {
        if (store.wrapForm.isWalletInWhitelist.value$) {
          return null
        }
        return (
          <Modal visible={true}>
            <AlertDialogModalContent
              titleText={$t(
                defineMessage({
                  defaultMessage: "Your address is not whitelisted",
                  description: "/Bridge/WhiteListCheck/Modal title",
                }),
              )}
              message={
                <span>
                  {$t(
                    defineMessage({
                      defaultMessage:
                        "ALEX Bridge is currently in a closed beta. You will be notified if you are whitelisted.",
                      description: "/Bridge/WhiteListCheck/Modal message",
                    }),
                  )}
                </span>
              }
              confirmText={$t(
                defineMessage({
                  defaultMessage: "Okay",
                  description: "/Bridge/WhiteListCheck/Modal confirm",
                }),
              )}
              onConfirm={() => {
                window.location.href = "/"
              }}
            />
          </Modal>
        )
      }}
    </Spensor>
  )
}

export default WiredWhiteListCheck
