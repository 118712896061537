import clsx from "clsx"
import { ReactNode } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { ReactComponent as InfoIcon } from "./_/info.svg"

export { ReactComponent as InfoIcon } from "./_/info.svg"
export { ReactComponent as SuccessIcon } from "./_/success.svg"
export { ReactComponent as WarnIcon } from "./_/warn.svg"

export const NoteParagraph: FCC<{
  className?: string
  icon?: ReactNode
  textSizingClassName?: string
  textColorClassName?: string
}> = props => (
  <div
    className={clsx(
      "flex",
      props.className,
      props.textColorClassName ?? "text-gray-500",
      props.textSizingClassName ?? "text-sm sm:text-base",
    )}
  >
    <div className={"mr-2.5"}>
      <div className={"inline-flex items-center"}>
        &#8203; {/* zero-width space char */}
        {/*
        Above char is used to hold up this box, make it as height as one line
        text, then we can make the icon middle align with first line
        */}
        {props.icon ?? <InfoIcon fill={"currentColor"} />}
      </div>
    </div>

    <div className={"flex-1 text-left"}>{props.children}</div>
  </div>
)
