import { ReactNode } from "react"
import { defineMessage, IntlFormatters } from "react-intl"
import { assertNever } from "../../../../utils/types"
import { OrderDirection, OrderType } from "../types"

export const perpetualText$t = defineMessage({
  defaultMessage: "Perpetual",
  description: "Perpetual/Common",
})

export namespace orderDirection$t {
  export const long = defineMessage({
    defaultMessage: "Long",
    description: 'Perpetual/Common/order direction "long"',
  })

  export const short = defineMessage({
    defaultMessage: "Short",
    description: 'Perpetual/Common/order direction "short"',
  })

  export const fromOrderDirection = (
    { $t }: IntlFormatters<ReactNode>,
    direction: OrderDirection,
  ): ReactNode => {
    switch (direction) {
      case OrderDirection.Long:
        return $t(long)
      case OrderDirection.Short:
        return $t(short)
      default:
        assertNever(direction)
    }
  }
}

export namespace orderType$t {
  export const limit = defineMessage({
    defaultMessage: "Limit",
    description: 'Perpetual/Common/order type "limit"',
  })

  export const market = defineMessage({
    defaultMessage: "Market",
    description: 'Perpetual/Common/order type "market"',
  })

  export const stopLimit = defineMessage({
    defaultMessage: "Stop Limit",
    description: 'Perpetual/Common/order type "stop limit"',
  })

  export const stopDashLimit = defineMessage({
    defaultMessage: "Stop-Limit",
    description: 'Perpetual/Common/order type "stop-limit"',
  })

  export const fromOrderType = (
    { $t }: IntlFormatters<ReactNode>,
    type: OrderType,
  ): ReactNode => {
    switch (type) {
      case OrderType.Limit:
        return $t(limit)
      case OrderType.Market:
        return $t(market)
      case OrderType.StopLimit:
        return $t(stopLimit)
      default:
        assertNever(type)
    }
  }
}
