import { reaction } from "mobx"
import { FC, useEffect, useState } from "react"
import { Modal } from "../../../components/Modal"
import { AlertDialogModalContent } from "../../../components/dialog/AlertDialogModalContent"
import { useDialog } from "../../../components/dialog/useDialog"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { defer } from "../../../utils/promiseHelpers"
import {
  AuthJWTToken,
  TemporarilyStoredSignInfoForInitialDeposit,
  argumentSignature,
  getAuthSignatureFromWallet,
  signInAndCacheBySignature,
  signInByArgumentSignature,
} from "../store/stxdx_shared/StxDxMyInfoModule.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

const WiredLoginModal: FC = () => {
  const dialog = useDialog()
  const [confirm, setConfirm] = useState<null | defer.Deferred<void>>(null)
  const authStore = useAuthStore()
  const store = useOrderbookStore()
  useEffect(() => {
    let dispose: (() => void) | undefined
    void (async () => {
      if (!store.myInfo.hasRegistered$) {
        return
      }

      const restoredSig =
        await TemporarilyStoredSignInfoForInitialDeposit.restoreSig()

      let token: null | string = AuthJWTToken.get()
      if (argumentSignature) {
        token = await signInByArgumentSignature(
          authStore.stxAddress$,
          argumentSignature,
        ).catch(() => {
          alert("Argumented signature is invalid")
          return null
        })
      } else if (restoredSig) {
        token = await signInAndCacheBySignature(
          restoredSig.payload,
          restoredSig.signature,
        ).catch(
          () =>
            /* treat the case as token expired */
            null,
        )
      }
      if (token != null) {
        store.myInfo.saveAuthJWT(token)
      }

      dispose = reaction(
        () => store.myInfo.jwtTokenExpired,
        async expired => {
          if (!expired) {
            return
          }
          const deferred = defer()
          setConfirm(deferred)
          await deferred.promise
          setConfirm(null)
          try {
            const { payload, signature } = await getAuthSignatureFromWallet(
              authStore.stxAddress$,
              Math.floor(60 * 10 + Date.now() / 1000),
            )
            const token = await signInAndCacheBySignature(payload, signature)
            store.myInfo.saveAuthJWT(token)
          } catch (e) {
            alert((e as Error).message)
          }
        },
        {
          fireImmediately: true,
        },
      )
    })()
    return () => {
      dispose?.()
    }
  }, [authStore.stxAddress$, dialog, store.myInfo.hasRegistered$, store])
  if (confirm == null) {
    return null
  }
  return (
    <Modal visible={true}>
      <AlertDialogModalContent
        message="Please sign with your wallet to verify your ownership"
        onConfirm={() => confirm?.resolve()}
        confirmText="Sign with Wallet"
        titleText="Sign in required"
      />
    </Modal>
  )
}

export default WiredLoginModal
