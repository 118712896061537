import { computed, makeObservable } from "mobx"
import { ESTIMATED_BLOCK_DURATION } from "../../../../config"
import { AutoStakeCycle, StakeCycleStatus } from "../../types"
import AutoStakeStore from "./AutoStakeStore"
import { AutoStakeInfo } from "./AutoStakeStore.service"

export class AutoStakeCycleViewModule implements AutoStakeCycle {
  constructor(
    private readonly store: AutoStakeStore,
    readonly cycleNumber: number,
  ) {
    makeObservable(this)
  }

  private get stats$(): AutoStakeInfo {
    return this.store.statsInfo.value$
  }

  @computed get cycleStatus(): StakeCycleStatus {
    const current = this.store.currentCycle$
    if (this.cycleNumber > current) {
      return StakeCycleStatus.Upcoming
    }
    if (this.cycleNumber < current) {
      return StakeCycleStatus.Finished
    }
    return StakeCycleStatus.InProgress
  }

  @computed get apy(): number {
    return this.stats$.apys[this.cycleNumber - this.stats$.currentCycle]!
  }

  @computed get poolVolume(): number {
    return this.stats$.poolFutureVolumeStats[
      this.cycleNumber - this.stats$.currentCycle
    ]!
  }

  @computed get atAlexStakedVolume(): number {
    return this.stats$.poolTotalStakedStats[
      this.cycleNumber - this.stats$.currentCycle
    ]!
  }

  @computed get myStaked(): number {
    return this.store.atAlexBalance$ * this.intrinsicAlexValue
  }

  @computed get intrinsicAlexValue(): number {
    const grow = this.poolVolume / this.stats$.poolFutureVolumeStats[0]!
    return this.store.priceInAlex$ * grow
  }

  @computed get currentBlock(): number {
    return this.store.chainStore.currentBlockHeight$
  }

  @computed get fromBlock(): number {
    return (
      this.store.shared.nextCycleBlock$ +
      (this.cycleNumber - this.store.shared.currentCycle$ - 1) *
        this.store.shared.blocksPerCycle$
    )
  }

  @computed get targetBlock(): number {
    return (
      this.store.shared.nextCycleBlock$ +
      (this.cycleNumber - this.store.shared.currentCycle$) *
        this.store.shared.blocksPerCycle$
    )
  }

  @computed get targetDate(): Date {
    return new Date(
      new Date().getTime() +
        (this.targetBlock - this.currentBlock) * ESTIMATED_BLOCK_DURATION,
    )
  }
}
