import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "./BaseRoundedFilledButton"

export const OpacityButton: FC<
  RoundedButtonVariantProps & { bgClassName?: string }
> = props => {
  return (
    <BaseRoundedFilledButton
      {...props}
      className={clsx(`text-gray-200 disabled:opacity-30`, props.className)}
      bgClassName={
        props.bgClassName ??
        "bg-white bg-opacity-5 hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-15 disabled:bg-black disabled:bg-opacity-15"
      }
    >
      {props.children}
    </BaseRoundedFilledButton>
  )
}
