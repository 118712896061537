import { Bar } from "../../../../vendors/charting_library/charting_library.esm"

export function fillEmptyBarsInByTimeRange(
  from: number /* in milliseconds */,
  to: number /* in milliseconds */,
  resolution: number /* in minutes */,
  bars: Bar[],
): Bar[] {
  if (bars.length === 0) {
    throw new Error("bars is empty")
  }
  const result: Bar[] = []
  const timeSpan = resolution * 60 * 1000
  // fill first bar to first available bar
  const firstBarInTime = bars[0]!
  for (let i = from; i < firstBarInTime.time; i += timeSpan) {
    result.push({
      time: i,
      open: firstBarInTime.open,
      high: firstBarInTime.open,
      low: firstBarInTime.open,
      close: firstBarInTime.open,
      volume: 0,
    })
  }
  // fill remaining bars
  for (let i = firstBarInTime.time; i <= to; i += timeSpan) {
    const bar = bars.find(b => b.time === i)
    if (bar) {
      result.push(bar)
    } else {
      const lastBar = result[result.length - 1]!
      result.push({
        time: i,
        open: lastBar.close,
        high: lastBar.close,
        low: lastBar.close,
        close: lastBar.close,
        volume: 0,
      })
    }
  }
  return result
}

export function fillEmptyBarsByCount(
  count: number,
  to: number /* in milliseconds */,
  resolution: number /* in minutes */,
  bars: Bar[],
): Bar[] {
  if (bars.length === 0) {
    throw new Error("bars is empty")
  }
  const result: Bar[] = []
  const timeSpan = resolution * 60 * 1000 // in milliseconds
  const alignedTo = to - (to % timeSpan)
  const firstTime = alignedTo - timeSpan * (count - 1)
  const firstBarInTime = bars[0]!
  // fill first bar to first available bar
  for (let i = firstTime; i < firstBarInTime.time; i += timeSpan) {
    result.push({
      time: i,
      open: firstBarInTime.open,
      high: firstBarInTime.open,
      low: firstBarInTime.open,
      close: firstBarInTime.open,
      volume: 0,
    })
  }
  // fill remaining bars
  for (let i = firstBarInTime.time; i <= to; i += timeSpan) {
    const bar = bars.find(b => b.time === i)
    if (bar) {
      result.push(bar)
    } else {
      const lastBar = result[result.length - 1]!
      result.push({
        time: i,
        open: lastBar.close,
        high: lastBar.close,
        low: lastBar.close,
        close: lastBar.close,
        volume: 0,
      })
    }
  }
  return result
}
