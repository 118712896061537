import * as LabelPrimitive from "@radix-ui/react-label"
import * as SwitchPrimitive from "@radix-ui/react-switch"
import clsx from "clsx"
import { noop } from "lodash"
import { FC, ReactNode, useId } from "react"
import { FCC } from "../utils/reactHelpers/types"
import { LabelForProvider, useLabelFor } from "./LabelForProvider"
import { useSpacing } from "./Themed/spacing"

export interface ThumbSwitchProps {
  className?: string
  containerClassName?: string
  thumbClassName?: string

  inline?: boolean

  /**
   * the height of the total control
   *
   * @default spacing(5)
   */
  size?: number

  checked: boolean
  onCheckedChange?: (checked: boolean) => void

  left?: ReactNode
  right?: ReactNode
}

export const ThumbSwitch: FC<ThumbSwitchProps> = props => {
  const controlId = `Switch-${useId()}`

  const spacing = useSpacing()

  const { size = spacing(5) } = props
  const boundaryPaddingSize = spacing(0.5)
  const thumbSize = size - boundaryPaddingSize * 2

  return (
    <LabelForProvider controlId={controlId}>
      <div
        className={clsx(
          "items-center gap-2",
          props.inline ? "inline-flex" : "flex",
          props.className,
        )}
      >
        {props.left}

        <SwitchPrimitive.Root
          id={controlId}
          checked={props.checked}
          onCheckedChange={props.onCheckedChange ?? noop}
          className={clsx(
            props.containerClassName ?? "bg-gray-900 checked:bg-green-600",
            `relative inline-flex items-center rounded-full transition-colors focus:ring-2`,
          )}
          style={{
            width: thumbSize * 2 + boundaryPaddingSize * 2,
            height: size,
          }}
        >
          <SwitchPrimitive.Thumb
            className={clsx(
              props.thumbClassName ?? "bg-gray-600 checked:bg-gray-200",
              `inline-block transform bg-white rounded-full transition-transform`,
            )}
            style={{
              width: thumbSize,
              height: thumbSize,
              ...{
                "--tw-translate-x": `${
                  props.checked
                    ? thumbSize + boundaryPaddingSize
                    : boundaryPaddingSize
                }px`,
              },
            }}
          />
        </SwitchPrimitive.Root>

        {props.right}
      </div>
    </LabelForProvider>
  )
}

export const SwitchLabel: FCC<{
  className?: string
  textClassName?: string
}> = props => {
  const forId = useLabelFor()

  return (
    <LabelPrimitive.Root
      className={clsx(
        props.className,
        props.textClassName ?? "text-sm text-gray-400",
      )}
      htmlFor={forId}
    >
      {props.children}
    </LabelPrimitive.Root>
  )
}
