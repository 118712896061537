import { FC } from "react"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../components/Spensor"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { WrapBridgeRoute } from "./WrapBridgePanel/WrapBridgeRoute/WrapBridgeRoute"

export const WiredWrapBridgeRoute: FC<{ withBackground?: boolean }> = props => {
  const store = useWrapBridgeStore()

  return (
    <Spensor
      fallback={
        <div className={"w-full h-[256px]"}>
          <LoadingIndicator className={"m-auto"} />
        </div>
      }
    >
      {() => (
        <WrapBridgeRoute
          withBackground={props.withBackground}
          fromNetwork={store.wrapFormViewModule.fromNetwork}
          toNetwork={store.wrapFormViewModule.toNetwork}
          fromToken={store.wrapFormViewModule.fromToken$}
          toToken={store.wrapFormViewModule.toToken$}
        />
      )}
    </Spensor>
  )
}
