import { Slot } from "@radix-ui/react-slot"
import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { FCC } from "../../../utils/reactHelpers/types"
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator"
import { TokenCount } from "../../TokenCount"
import { TokenName } from "../../TokenName"

export interface BalanceBarProps {
  className?: string
  balanceInfos: {
    token: TokenInfo
    balance: number
  }[]
}

export const BalanceBar: FC<BalanceBarProps> = props => {
  return (
    <BalanceBarFrame
      asChild
      className={clsx("overflow-x-auto", props.className)}
    >
      <div>
        <dl className={"flex gap-8 mx-auto w-fit-content"}>
          {props.balanceInfos.map((info, idx) => (
            <div key={idx} className={"flex"}>
              <dd>
                <TokenCount token={info.token} count={info.balance} />
              </dd>
              <dt className={"ml-2 opacity-50 whitespace-nowrap"}>
                <TokenName token={info.token} />
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </BalanceBarFrame>
  )
}

export const BalanceBar$Loading: FCC<{ className?: string }> = props => {
  return (
    <BalanceBarFrame
      className={clsx("flex items-center justify-center", props.className)}
    >
      <LoadingIndicator size={20} />
    </BalanceBarFrame>
  )
}

export const BalanceBarFrame: FCC<{
  className?: string
  asChild?: boolean
}> = props => {
  const Comp = props.asChild ? Slot : "div"

  return (
    <Comp
      className={clsx(
        `p-2 backdrop-blur-lg border-b`,
        "text-sm leading-5 font-medium text-gray-200",
        props.className,
      )}
      style={{
        backgroundColor: "rgba(63, 63, 70, 0.2)",
        borderColor: "rgba(255, 255, 255, 0.05)",
      }}
    >
      {props.children}
    </Comp>
  )
}
