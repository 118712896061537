import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  numberT,
  principalT,
  responseSimpleT,
} from "../smartContractHelpers/codegenImport"

export const registerUserHelperV103 = defineContract({
  "register-user-helper-v1-03": {
    "register-and-deposit": {
      input: [
        { name: "pub-key", type: bufferT },
        { name: "amounts", type: listT(numberT) },
        { name: "asset-ids", type: listT(numberT) },
        { name: "asset-traits", type: listT(principalT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "transfer-in-many": {
      input: [
        { name: "amounts", type: listT(numberT) },
        { name: "asset-ids", type: listT(numberT) },
        { name: "asset-traits", type: listT(principalT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
  },
} as const)
