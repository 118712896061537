import clsx from "clsx"
import { FC, useState } from "react"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator"
import { Button, ButtonProps } from "./Button"

export interface LoadableButtonProps extends ButtonProps {
  loading?: boolean
  loadingText?: string
  contentContainerClassName?: string
}

export const LoadableButton: FC<LoadableButtonProps> = props => {
  const { loading = false, ...restProps } = props
  return (
    <Button
      {...restProps}
      className={clsx(
        props.className,
        "grid grid-rows-1 grid-cols-1 items-center justify-center",
      )}
      disabled={loading || props.disabled}
    >
      <div
        className={clsx(
          "col-start-1 row-start-1 self-center justify-self-center",
          loading && "invisible",
          props.contentContainerClassName,
        )}
      >
        {props.children}
      </div>

      {loading && (
        <div
          className={
            "col-start-1 row-start-1 self-center justify-self-center flex flex-row items-center"
          }
        >
          <LoadingIndicator size={"1em"} />
          {props.loadingText != null && (
            <>
              &nbsp;
              {props.loadingText}
            </>
          )}
        </div>
      )}
    </Button>
  )
}

export const SmartLoadableButton: FC<LoadableButtonProps> = props => {
  const [loading, setLoading] = useState(false)

  const onClick = usePersistFn(async () => {
    try {
      setLoading(true)
      await props.onClick?.()
    } finally {
      setLoading(false)
    }
  })

  return (
    <LoadableButton
      {...props}
      loading={loading || props.loading}
      onClick={props.onClick == null ? undefined : onClick}
    />
  )
}
