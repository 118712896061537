import { format } from "date-fns"
import { FC } from "react"
import { BaseCellContainer } from "./BaseCellContainer"

const DEFAULT_FORMAT = "MMM dd, yyyy HH:mm"

export const TimeCell: FC<{ time: Date }> = props => (
  <BaseCellContainer className="text-right min-w-[6rem]">
    <span className="text-gray-500">{format(props.time, DEFAULT_FORMAT)}</span>
  </BaseCellContainer>
)
