export function hasAny<T>(ary: T[]): ary is [T, ...T[]]
export function hasAny<T>(ary: readonly T[]): ary is readonly [T, ...T[]]
export function hasAny<T>(ary: readonly T[]): ary is readonly [T, ...T[]] {
  return ary.length > 0
}

export function oneOf<T extends string[]>(
  ...coll: T
): (input: unknown) => input is T[number]
export function oneOf<T extends any[]>(
  ...coll: T
): (input: unknown) => input is T[number]
export function oneOf<T extends any[]>(
  ...coll: T
): (input: unknown) => input is T[number] {
  return ((input: unknown) => coll.includes(input)) as any
}

export function first<T>(ary: [T, ...T[]]): T
export function first<T>(ary: [...T[], T]): T
export function first<T>(ary: T[]): undefined | T
export function first<T>(ary: T[]): undefined | T {
  return ary[0]
}

export function last<T>(ary: [T, ...T[]]): T
export function last<T>(ary: [...T[], T]): T
export function last<T>(ary: T[]): undefined | T
export function last<T>(ary: T[]): undefined | T {
  return ary[ary.length - 1]
}

export function arrayJoin<T>(
  separator: (info: { index: number }) => T,
  inputArray: T[],
): T[] {
  return inputArray.reduce((outputArray: T[], child, index) => {
    outputArray.push(child)

    if (index < inputArray.length - 1) {
      outputArray.push(separator({ index }))
    }

    return outputArray
  }, [])
}

export function median(apr: number[]): number {
  return apr[Math.floor(apr.length / 2)]!
}

export type NotEmptyArray<T> = [T, ...T[]]

export function hasLength<T>(input: readonly T[], length: 0): input is []
export function hasLength<T>(input: readonly T[], length: 1): input is [T]
export function hasLength<T>(input: readonly T[], length: 2): input is [T, T]
export function hasLength<T>(input: readonly T[], length: 3): input is [T, T, T]
export function hasLength<T>(
  input: readonly T[],
  length: 4,
): input is [T, T, T, T]
export function hasLength<T>(
  input: readonly T[],
  length: 5,
): input is [T, T, T, T, T]
export function hasLength<T>(
  input: readonly T[],
  length: number,
): input is NotEmptyArray<T> {
  return input.length === length
}
