import { openDB } from "idb"
import { PERSIST_KEY_PREFIX } from "../config"

const persistCacheKey = "persist-cache"

const dbPromise = openDB(`${PERSIST_KEY_PREFIX}_persistCache`, 1, {
  upgrade(db) {
    db.createObjectStore(persistCacheKey)
  },
})

let prefix = ""

export function setCachePrefix(p: string): void {
  prefix = p
}

export async function fetchPersistCache(key: string): Promise<any | undefined> {
  return await (await dbPromise).get(persistCacheKey, prefix + key)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function savePersistCache(key: string, value: any): Promise<void> {
  await (await dbPromise).put(persistCacheKey, value ?? null, prefix + key)
}

export async function clearPersistCache(): Promise<void> {
  await (await dbPromise).clear(persistCacheKey)
}
