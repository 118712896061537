import { TokenInfo } from "../../utils/models/TokenInfo"
import { SuspenseResource } from "../../utils/SuspenseResource"

export enum TokenListStatus {
  Listed = "Listed",
  Candidate = "Candidate",
}

export interface B20TokenListInfo {
  tradeToken: TokenInfo
  priceToken: TokenInfo
  status: TokenListStatus
  minLiquidity: number
  liquidity: SuspenseResource<number>
  raisingLiquidityPercentage: SuspenseResource<number>
  inscription: string
  inscriptionLink: string
  totalSupply: number
  minted: number
  mintedPercent: number
  limitPerMint: number
  decimal: number
  pegInLink: string
}
