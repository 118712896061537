import { computed, makeObservable } from "mobx"
import type { TokenIDOStatus } from "../component/types"
import {
  TokenIDOPrices,
  TokenIDORaiseNumbers,
  TokenIDOSuccessInfo,
} from "../component/types"
import LaunchPadContractStore, {
  LaunchingStatus,
} from "./LaunchPadContractStore"

export class TokenProfileViewModule
  implements TokenIDORaiseNumbers, TokenIDOPrices, TokenIDOSuccessInfo
{
  constructor(private readonly store: LaunchPadContractStore) {
    makeObservable(this)
  }

  get aPowerCountPerTicket(): number | [number, number] {
    const array = this.store.detail$.detail["apower-per-ticket-in-fixed"].map(
      a => a["apower-per-ticket-in-fixed"],
    )
    if (array.length === 1) {
      return array[0]! / 1e8
    }
    return [Math.min(...array) / 1e8, Math.max(...array) / 1e8]
  }

  @computed get status(): TokenIDOStatus {
    if (this.store.steps.currentStatus$ === LaunchingStatus.Finished) {
      return {
        type: LaunchingStatus.Finished,
        success: this.store.activationProgress$ >= 1,
      }
    }
    return {
      type: this.store.steps.currentStatus$,
    }
  }

  @computed get minRaiseTicketCount(): number {
    return this.store.detail$.detail["activation-threshold"]
  }

  @computed get maxRaiseTicketCount(): number {
    return this.store.detail$.detail["total-tickets"]
  }

  @computed get currentRaiseTicketCount(): number {
    return this.currentActivation
  }

  @computed get minRaiseIdoTokenCount(): number {
    return this.minRaiseTicketCount * this.store.idoTokenCountPerTicket$
  }

  @computed get maxRaiseIdoTokenCount(): number {
    return this.maxRaiseTicketCount * this.store.idoTokenCountPerTicket$
  }

  @computed get currentRaiseIdoTokenCount(): number {
    return this.currentRaiseTicketCount * this.store.idoTokenCountPerTicket$
  }

  @computed get minRaisePriceTokenCount(): number {
    return this.minRaiseTicketCount * this.store.pricePerTicket$
  }

  @computed get maxRaisePriceTokenCount(): number {
    return this.maxRaiseTicketCount * this.store.pricePerTicket$
  }

  @computed get currentRaisePriceTokenCount(): number {
    return this.currentRaiseTicketCount * this.store.pricePerTicket$
  }

  @computed get currentActivation(): number {
    return this.store.currentActivation.value$
  }

  @computed get fillRate(): number {
    return this.store.currentActivation.value$ / this.store.totalTicketCount$
  }

  @computed get totalAllocation(): number {
    return this.totalTicketWon * this.store.idoTokenCountPerTicket$
  }

  @computed get totalTicketWon(): number {
    return this.store.totalTicketWon$
  }

  @computed get idoTokenCountPerTicket(): number {
    return this.store.idoTokenCountPerTicket$
  }

  @computed get pricePerToken(): number {
    return this.store.pricePerTicket$ / this.store.idoTokenCountPerTicket$
  }

  @computed get pricePerTicket(): number {
    return this.store.pricePerTicket$
  }

  @computed get allTicketAllocated(): boolean {
    return this.totalTicketWon >= this.store.totalTicketCount$
  }
}
