import { FC, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../../components/NavLink"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { Spensor } from "../../../../../components/Spensor"
import { useResponsiveValue } from "../../../../../components/Themed/breakpoints"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import {
  SuspenseResource,
  readResource,
} from "../../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { NormalizedPaddingStyle } from "../../../../../utils/styleHelpers/PaddingStyle"
import { ReactComponent as WarningIcon } from "../../_/warning.svg"
import { RectButton } from "../../wrappedCommonComponents/RectButton"
import HiroIconSrc from "../_/HiroWallet.png"
import { QueuedSection } from "../_/QueuedSection"
import UniSetIconSrc from "../_/UniSatIcon.png"
import { WarningSection } from "../_/WarningSection"
import XverseIconSrc from "../_/XverseIcon.png"
import { AddressRow } from "./_/AddressRow"
import { PegInRecord } from "./_/PegInRecord"
import { StepPanel, StepPanelSection, useStepPanelPadding } from "./_/StepPanel"
import { ReactComponent as StacksIcon } from "./_/stacks.svg"

export enum PegInRequestStatus {
  Mempool = "Mempool",
  Pending = "Pending",
  Processed = "Processed",
  Rejected = "Rejected",
}

export interface PegInRequest {
  id: string
  token: TokenInfo
  amount: number
  pegInTransactionId: string
  pegInAddress: string
  status: PegInRequestStatus
}

export const DepositBrc20GuideModalContent: FC<{
  queuingPegRequestCount: SuspenseResource<number>
  brc20ToBtcAddress: SuspenseResource<string>
  targetStxAddress: SuspenseResource<string>
  recentPegInRequests: SuspenseResource<PegInRequest[]>
  onSubmitNewTxRecord: () => void
  onCopyBrc20ToBtcAddress: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  const stepPanelPadding = useStepPanelPadding()
  const stepPanelHorizontalPadding: NormalizedPaddingStyle = useMemo(
    () => ({
      paddingLeft: stepPanelPadding.paddingLeft,
      paddingRight: stepPanelPadding.paddingRight,
    }),
    [stepPanelPadding.paddingLeft, stepPanelPadding.paddingRight],
  )

  const truncate =
    useResponsiveValue({
      md: false,
    }) ?? true

  return (
    <CardModalContent
      className="flex flex-col gap-5"
      bgClassName="bg-gray-800"
      width={700}
      title={$t(
        defineMessage({
          defaultMessage: "Peg in your BRC-20",
          description: "/OrderBook/BRC20/DepositModal/Title text",
        }),
      )}
      onClose={props.onClose}
    >
      <QueuedSection
        queuingPegRequestCount={props.queuingPegRequestCount}
        warningText={$t(
          defineMessage({
            defaultMessage:
              "ALEX Orderbook could only proceed 100 peg in/out request every 24hrs.",
            description:
              "/OrderBook/BRC20 Peg Flow/queued number panel/warning text",
          }),
        )}
      />

      <StepPanel
        stepNumber={1}
        title={$t(
          defineMessage({
            defaultMessage: "Prepare your “transfer inscription ID”",
            description: "/OrderBook/BRC20/DepositProgress/Description text",
          }),
        )}
      >
        <StepPanelSection>
          <div className={"flex flex-row flex-wrap gap-2.5"}>
            <NavLink
              className={"flex-1"}
              to="https://perfect-bulb-261.notion.site/Inscribe-Transfer-Inscription-eeb3ac045bfe4eb591046e4a66aac862"
            >
              <RectButton
                className={"flex flex-row justify-center items-center gap-x-1"}
                boxClassName={"p-1"}
                Variant={WhiteFilledButton}
              >
                {$t(
                  defineMessage({
                    defaultMessage: `{walletIcon} Unisat {externalLinkIcon}`,
                    description:
                      "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                  }),
                  {
                    walletIcon: (
                      <img src={UniSetIconSrc} className={"w-8 h-8"} />
                    ),
                    externalLinkIcon: (
                      <svg
                        className={"shrink-0"}
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.82422 2.99922C1.82422 2.07414 2.57414 1.32422 3.49922 1.32422H5.49922C5.87201 1.32422 6.17422 1.62643 6.17422 1.99922C6.17422 2.37201 5.87201 2.67422 5.49922 2.67422H3.49922C3.31973 2.67422 3.17422 2.81973 3.17422 2.99922V8.99922C3.17422 9.17872 3.31972 9.32422 3.49922 9.32422H9.49922C9.67873 9.32422 9.82422 9.17873 9.82422 8.99922V6.99922C9.82422 6.62643 10.1264 6.32422 10.4992 6.32422C10.872 6.32422 11.1742 6.62643 11.1742 6.99922V8.99922C11.1742 9.92431 10.4243 10.6742 9.49922 10.6742H3.49922C2.57415 10.6742 1.82422 9.92432 1.82422 8.99922V2.99922ZM7.32422 1.99922C7.32422 1.62643 7.62643 1.32422 7.99922 1.32422H10.4992C10.872 1.32422 11.1742 1.62643 11.1742 1.99922V4.49922C11.1742 4.87201 10.872 5.17422 10.4992 5.17422C10.1264 5.17422 9.82422 4.87201 9.82422 4.49922V3.62881L6.47652 6.97652C6.21291 7.24012 5.78553 7.24012 5.52192 6.97652C5.25832 6.71291 5.25832 6.28553 5.52192 6.02192L8.86962 2.67422H7.99922C7.62643 2.67422 7.32422 2.37201 7.32422 1.99922Z"
                          fill="#111827"
                        />
                      </svg>
                    ),
                  },
                )}
              </RectButton>
            </NavLink>

            <NavLink
              className={"flex-1"}
              to="https://perfect-bulb-261.notion.site/Guide-to-Creating-a-Transfer-Inscription-with-Xverse-Wallet-72436a00f8f042c593149b1440956e15"
            >
              <RectButton
                className={"flex flex-row justify-center items-center gap-x-1"}
                boxClassName={"p-1"}
                Variant={WhiteFilledButton}
              >
                {$t(
                  defineMessage({
                    defaultMessage: `{walletIcon} Xverse {externalLinkIcon}`,
                    description:
                      "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                  }),
                  {
                    walletIcon: (
                      <img src={XverseIconSrc} className={"w-8 h-8"} />
                    ),
                    externalLinkIcon: (
                      <svg
                        className={"shrink-0"}
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.82422 2.99922C1.82422 2.07414 2.57414 1.32422 3.49922 1.32422H5.49922C5.87201 1.32422 6.17422 1.62643 6.17422 1.99922C6.17422 2.37201 5.87201 2.67422 5.49922 2.67422H3.49922C3.31973 2.67422 3.17422 2.81973 3.17422 2.99922V8.99922C3.17422 9.17872 3.31972 9.32422 3.49922 9.32422H9.49922C9.67873 9.32422 9.82422 9.17873 9.82422 8.99922V6.99922C9.82422 6.62643 10.1264 6.32422 10.4992 6.32422C10.872 6.32422 11.1742 6.62643 11.1742 6.99922V8.99922C11.1742 9.92431 10.4243 10.6742 9.49922 10.6742H3.49922C2.57415 10.6742 1.82422 9.92432 1.82422 8.99922V2.99922ZM7.32422 1.99922C7.32422 1.62643 7.62643 1.32422 7.99922 1.32422H10.4992C10.872 1.32422 11.1742 1.62643 11.1742 1.99922V4.49922C11.1742 4.87201 10.872 5.17422 10.4992 5.17422C10.1264 5.17422 9.82422 4.87201 9.82422 4.49922V3.62881L6.47652 6.97652C6.21291 7.24012 5.78553 7.24012 5.52192 6.97652C5.25832 6.71291 5.25832 6.28553 5.52192 6.02192L8.86962 2.67422H7.99922C7.62643 2.67422 7.32422 2.37201 7.32422 1.99922Z"
                          fill="#111827"
                        />
                      </svg>
                    ),
                  },
                )}
              </RectButton>
            </NavLink>
            <NavLink
              className={"flex-1"}
              to="https://hirowallet.gitbook.io/guides/bitcoin/fast-and-easy-brc-20-inscription-guide-with-hiro-wallet#heres-how-to-make-a-transfer-inscription-available-on-hiro-wallet-v4.28-onwards"
            >
              <RectButton
                className={"flex flex-row justify-center items-center gap-x-1"}
                boxClassName={"p-1"}
                Variant={WhiteFilledButton}
              >
                {$t(
                  defineMessage({
                    defaultMessage: `{walletIcon} Hiro Wallet {externalLinkIcon}`,
                    description:
                      "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                  }),
                  {
                    walletIcon: <img src={HiroIconSrc} className={"w-8 h-8"} />,
                    externalLinkIcon: (
                      <svg
                        className={"shrink-0"}
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.82422 2.99922C1.82422 2.07414 2.57414 1.32422 3.49922 1.32422H5.49922C5.87201 1.32422 6.17422 1.62643 6.17422 1.99922C6.17422 2.37201 5.87201 2.67422 5.49922 2.67422H3.49922C3.31973 2.67422 3.17422 2.81973 3.17422 2.99922V8.99922C3.17422 9.17872 3.31972 9.32422 3.49922 9.32422H9.49922C9.67873 9.32422 9.82422 9.17873 9.82422 8.99922V6.99922C9.82422 6.62643 10.1264 6.32422 10.4992 6.32422C10.872 6.32422 11.1742 6.62643 11.1742 6.99922V8.99922C11.1742 9.92431 10.4243 10.6742 9.49922 10.6742H3.49922C2.57415 10.6742 1.82422 9.92432 1.82422 8.99922V2.99922ZM7.32422 1.99922C7.32422 1.62643 7.62643 1.32422 7.99922 1.32422H10.4992C10.872 1.32422 11.1742 1.62643 11.1742 1.99922V4.49922C11.1742 4.87201 10.872 5.17422 10.4992 5.17422C10.1264 5.17422 9.82422 4.87201 9.82422 4.49922V3.62881L6.47652 6.97652C6.21291 7.24012 5.78553 7.24012 5.52192 6.97652C5.25832 6.71291 5.25832 6.28553 5.52192 6.02192L8.86962 2.67422H7.99922C7.62643 2.67422 7.32422 2.37201 7.32422 1.99922Z"
                          fill="#111827"
                        />
                      </svg>
                    ),
                  },
                )}
              </RectButton>
            </NavLink>
          </div>
        </StepPanelSection>
      </StepPanel>

      <StepPanel
        stepNumber={2}
        title={$t(
          defineMessage({
            defaultMessage: "Submit your “transfer inscription ID”",
            description: "/OrderBook/BRC20/DepositProgress/Description text",
          }),
        )}
      >
        <StepPanelSection>
          <p className="text-sm text-white">
            {$t(
              defineMessage({
                defaultMessage:
                  "The status of the submitted “transfer inscription ID” is displayed in “Step 3”.",
                description:
                  "/OrderBook/BRC20 Peg Flow/DepositProgress/Description text",
              }),
            )}
          </p>
          <NoteParagraph
            textSizingClassName={"text-sm font-semibold"}
            icon={<WarningIcon />}
            textColorClassName="text-yellow-400"
          >
            {$t(
              defineMessage({
                defaultMessage:
                  "Important: Please avoid submitting new request with the same inscription ID, unless the previous attempt was unsuccessful.",
                description:
                  "/OrderBook/BRC20 Peg Flow/DepositProgress/Note text",
              }),
            )}
          </NoteParagraph>
          <RectButton
            className="text-xs flex justify-center items-center"
            Variant={GradientFilledButton}
            onClick={props.onSubmitNewTxRecord}
          >
            {$t(
              defineMessage({
                defaultMessage: `{plusIcon} Submit “transfer inscription ID” request`,
                description:
                  "/OrderBook/BRC20 Peg Flow/Add new transaction text",
              }),
              {
                plusIcon: (
                  <svg
                    className={"mx-1 shrink-0"}
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11 5.25H7.25V1.5H5.75V5.25H2V6.75H5.75V10.5H7.25V6.75H11V5.25Z" />
                  </svg>
                ),
              },
            )}
          </RectButton>
        </StepPanelSection>
      </StepPanel>

      <StepPanel
        stepNumber={3}
        title={$t(
          defineMessage({
            defaultMessage: "Send your BRC-20 to Peg-in address",
            description:
              "/OrderBook/BRC20 Peg Flow/DepositProgress/Description text",
          }),
        )}
        padding={{
          paddingTop: stepPanelPadding.paddingTop,
          paddingBottom: stepPanelPadding.paddingBottom,
          paddingHorizontal: 0,
        }}
        hairlineStyle={stepPanelHorizontalPadding}
      >
        <StepPanelSection
          className={"text-sm text-white"}
          style={stepPanelHorizontalPadding}
        >
          {$t(
            defineMessage({
              defaultMessage:
                'Once we received(confirmed) your “transfer inscription ID”, transfer the BRC-20 you have specified from your wallet to the "Peg-in Address" we provided.',
              description:
                "/OrderBook/BRC20 Peg Flow/DepositProgress/Description text",
            }),
          )}
        </StepPanelSection>

        <StepPanelSection>
          <p
            className={"text-xs font-bold text-white mt-2.5 mb-1"}
            style={stepPanelHorizontalPadding}
          >
            {$t(
              defineMessage({
                defaultMessage: `Recent History:`,
                description: "/OrderBook/BRC20 Peg Flow/Recent history title",
              }),
            )}
          </p>

          <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
            {() => (
              <div className="flex flex-col gap-y-2.5">
                {readResource(props.recentPegInRequests).map((record, idx) => (
                  <div
                    key={idx}
                    className={"overflow-x-auto"}
                    style={stepPanelHorizontalPadding}
                  >
                    <PegInRecord
                      className={"w-full min-w-[fit-content]"}
                      record={record}
                      onCopyBrc20ToBtcAddress={props.onCopyBrc20ToBtcAddress}
                    />
                  </div>
                ))}
              </div>
            )}
          </Spensor>
        </StepPanelSection>
      </StepPanel>

      <StepPanel
        stepNumber={4}
        title={$t(
          defineMessage({
            defaultMessage:
              "ALEX will send your Pegged BRC-20 into your Orderbook Trading Account:",
            description: "/OrderBook/BRC20/DepositProgress/Description text",
          }),
        )}
      >
        <AddressRow
          address={props.targetStxAddress}
          truncate={truncate}
          backgroundClassName="bg-blue-900"
          icon={<StacksIcon />}
        />

        <WarningSection textClassName="font-semibold" inContainer={false}>
          {$t(
            defineMessage({
              defaultMessage: `<line>Once you complete the Peg-in steps, ALEX protocol will automatically send your pegged-in BRC-20 token to your Orderbook Trading Account.</line>`,
              description: "/BRC20/WarningSection/Description text",
            }),
            {
              line: dontWrapObserver(children => (
                <p className="text-yellow-500 text-xs">{children}</p>
              )),
              link: dontWrapObserver(children => (
                <NavLink
                  className="text-blue-500"
                  to={"https://discord.gg/alexlab"}
                >
                  {children}
                </NavLink>
              )),
            },
          )}
        </WarningSection>
      </StepPanel>
    </CardModalContent>
  )
}
