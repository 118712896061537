import {
  ContractCallTransaction,
  MempoolContractCallTransaction,
} from "@stacks/stacks-blockchain-api-types"
import { CONTRACT_DEPLOYER } from "../../../config"
import { AlexContracts } from "../../../generated/smartContract/contracts_Alex"
import { isContractCallTransaction } from "../../../utils/contractHelpers"
import { FLOAT_RATIO } from "../constants"
import {
  ContractCallsTuple,
  NotifyTransactionFilter,
  TransformerGroup,
} from "../types"

export function getAmountFromContract(before: number): number
export function getAmountFromContract(before: undefined): undefined
export function getAmountFromContract(before?: number): number | undefined
export function getAmountFromContract(before?: number): number | undefined {
  return before ? before / FLOAT_RATIO : undefined
}

export function filterBuilder<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof (typeof AlexContracts)[ContractNames[number]],
>(
  contractCallsTuple: ContractCallsTuple<ContractNames, FunctionName>,
): NotifyTransactionFilter {
  return function (tx) {
    if (!isContractCallTransaction(tx)) {
      return false
    }
    const [contractCalls, functionName] = contractCallsTuple
    return (
      contractCalls.some(
        contractName =>
          tx.contract_call.contract_id ===
          `${CONTRACT_DEPLOYER}.${contractName}`,
      ) && tx.contract_call.function_name === functionName
    )
  }
}
export function getFilterFromGroup<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof (typeof AlexContracts)[ContractNames[number]],
>(
  group: TransformerGroup<ContractNames, FunctionName>,
): NotifyTransactionFilter {
  if (group.filterFn) {
    return group.filterFn
  }
  return filterBuilder([group.contracts, group.functionName])
}

export function getContractCallTupleFromTx<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): [ContractName, FunctionName] {
  return [
    getContractName<ContractName>(tx),
    getContractCallFunctionName<ContractName, FunctionName>(tx),
  ]
}

function getContractName<ContractName extends keyof typeof AlexContracts>(
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): ContractName {
  return tx.contract_call.contract_id.replace(
    CONTRACT_DEPLOYER + ".",
    "",
  ) as any
}

function getContractCallFunctionName<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(tx: ContractCallTransaction | MempoolContractCallTransaction): FunctionName {
  return tx.contract_call.function_name as any
}
