import {
  sendPublicRequest,
  sendRequest,
} from "../../../../generated/stxdxHelpers/stxdxApi"

export interface Setting {
  username?: string
  email?: string
  enableEmailSubscription: boolean
  emailVerified: boolean
}

export interface SettingFormData extends Omit<Setting, "emailVerified"> {
  auth: string
  uid: number
}

export async function fetchAccountSetting(
  principal: string,
  auth: string,
): Promise<Setting> {
  const { data: response } = await sendRequest(auth)(
    "AccountController_getByPrincipal",
    { body: { principal } },
  )
  return {
    email: response.email,
    username: response.username,
    enableEmailSubscription: response.email_subscription_enabled,
    emailVerified: response.email_verified,
  }
}

export async function saveAccountSetting(data: SettingFormData): Promise<void> {
  await sendRequest(data.auth)("AccountController_updateAccountSettings", {
    path: {
      uid: data.uid,
    },
    body: {
      username: data.username ?? "",
      email: data.email!,
      email_subscription_enabled: data.enableEmailSubscription,
    },
  })
}

export async function sendVerificationEmail(
  uid: number,
  auth: string,
  email: string,
): Promise<void> {
  await sendRequest(auth)("AccountController_sendVerificationEmail", {
    path: {
      uid,
    },
    body: {
      email,
    },
  })
}

export async function verifyEmail(uid: number, token: string): Promise<void> {
  await sendPublicRequest("AccountController_verifyEmail", {
    path: {
      uid,
    },
    body: {
      token,
    },
  })
}
