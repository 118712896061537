import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export const DetailSection: FC<{
  redeemFeeToken: SuspenseResource<TokenInfo>
  redeemFee: SuspenseResource<number>
  redeemFeeRate: SuspenseResource<number>
  redeemGasFeeToken: SuspenseResource<TokenInfo>
  redeemGasFee: SuspenseResource<number>
}> = props => {
  const { $t } = useIntl()
  return (
    <div className="bg-white/5 rounded-lg p-4">
      <InfoList>
        <InfoListItem>
          <InfoListItemTitle className="flex items-center">
            <span className="underline">
              {$t(
                defineMessage({
                  defaultMessage: "Fees",
                  description:
                    "/Launchpad/RedeemOrdinalsModalContent/DetailSection/label",
                }),
              )}
            </span>
            &nbsp;
            {(safeReadResource(props.redeemFeeRate) ?? 0) !== 0 && (
              <TipIcon
                tip={$t(
                  defineMessage({
                    defaultMessage: "Redeem fees: {feeRate}",
                    description:
                      '/Launchpad/RedeemOrdinalsModalContent/DetailSection/"Fees" label tip text',
                  }),
                  {
                    feeRate: <PercentNumber number={props.redeemFeeRate} />,
                  },
                )}
              />
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.redeemFeeToken}
              count={props.redeemFee}
            />
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle className="flex items-center">
            <span className="underline">
              {$t(
                defineMessage({
                  defaultMessage: "Redeem gas fees",
                  description:
                    "/Launchpad/RedeemOrdinalsModalContent/DetailSection/label",
                }),
              )}
            </span>
            &nbsp;
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "Redeem gas fees cover the Bitcoin transaction fee.",
                  description:
                    '/Launchpad/RedeemOrdinalsModalContent/DetailSection/"Fees" label tip text',
                }),
              )}
            />
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.redeemGasFeeToken}
              count={props.redeemGasFee}
            />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </div>
  )
}
