import { FC } from "react"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { waitForNever$ } from "../../../utils/waitFor"
import { SummaryBarForBrc20 } from "../components/SummaryBarForBrc20"
import { useOrderbookStore } from "../store/useOrderbookStore"
import { WiredMarketSelector } from "./WiredMarketSelector"

export const WiredSummaryBar: FC<{ className?: string }> = props => {
  const store = useOrderbookStore()

  return (
    <>
      <SummaryBarForBrc20
        className={props.className}
        tradeToken={store.currency.getTokenInfo$(
          store.buyFormModule.tradeToken$,
        )}
        priceToken={store.currency.getTokenInfo$(
          store.buyFormModule.priceToken$,
        )}
        currentPrice={suspenseResource(() => store.lastPrice$)}
        currentPriceInUSD={/* TODO */ suspenseResource(() => store.lastPrice$)}
        currentPriceDeltaPercentage={suspenseResource(
          () => store.currentPriceDeltaPercentage$,
        )}
        tokenPairSelector={<WiredMarketSelector />}
        priceChangeIn24Hours={suspenseResource(() => ({
          priceBefore24Hours: store.currentMarketSummary$.price24hChange,
          priceDeltaPercentage:
            store.currentMarketSummary$.price24hChangePercent,
        }))}
        priceHighestIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.highestPrice,
        )}
        priceLowestIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.lowestPrice,
        )}
        priceTokenVolumeIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.amount24h,
        )}
        tradeTokenVolumeIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.volume24h,
        )}
        totalTradeVolume={suspenseResource(
          () => store.currentMarketSummary$.totalTradeVolumeInUsd ?? 0,
        )}
        marketInfo={suspenseResource(
          () => store.brc20MarketInfo.value$ ?? waitForNever$(),
        )}
      />
    </>
  )
}
