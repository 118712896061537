import {
  booleanT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const cofarmApowerHelper = defineContract({
  "cofarm-apower-helper": {
    "add-approved-contract": {
      input: [{ name: "new-approved-contract", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    distribute: {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
        { name: "recipients", type: listT(principalT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "distribute-apower-only": {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
        { name: "recipients", type: listT(principalT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-approved-contract": {
      input: [
        { name: "owner", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-distributed-per-cycle-or-default": {
      input: [{ name: "cycle", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-user-distributed-per-cycle-or-default": {
      input: [
        { name: "user", type: principalT },
        { name: "cycle", type: numberT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "is-cycle-batch-processed": {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "approved-contracts": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "distributed-per-cycle": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "processed-batches": {
      input: tupleT({ batch: numberT, cycle: numberT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "user-distributed-per-cycle": {
      input: tupleT({ cycle: numberT, user: principalT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
  },
} as const)
