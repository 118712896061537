import { ReactNode } from "react"
import { defineMessage, IntlFormatters } from "react-intl"
import { assertNever } from "../../../../utils/types"
import { OrderDirection, StxDxOrderType } from "../types"

export namespace orderDirection$t {
  export const buy = defineMessage({
    defaultMessage: "Buy",
    description: 'Orderbook/Common/order direction "buy"',
  })

  export const sell = defineMessage({
    defaultMessage: "Sell",
    description: 'Orderbook/Common/order direction "sell"',
  })

  export const fromOrderDirection = (
    { $t }: IntlFormatters<ReactNode>,
    direction: OrderDirection,
  ): ReactNode => {
    switch (direction) {
      case "buy":
        return $t(buy)
      case "sell":
        return $t(sell)
      default:
        assertNever(direction)
    }
  }
}

export namespace orderType$t {
  export const limit = defineMessage({
    defaultMessage: "Limit",
    description: 'Orderbook/Common/order type "limit"',
  })

  export const market = defineMessage({
    defaultMessage: "Market",
    description: 'Orderbook/Common/order type "market"',
  })

  export const stopLimit = defineMessage({
    defaultMessage: "Stop Limit",
    description: 'Orderbook/Common/order type "stop limit"',
  })

  export const stopDashLimit = defineMessage({
    defaultMessage: "Stop-Limit",
    description: 'Orderbook/Common/order type "stop-limit"',
  })

  export const fromStxDxOrderType = (
    { $t }: IntlFormatters<ReactNode>,
    type: StxDxOrderType,
  ): ReactNode => {
    switch (type) {
      case StxDxOrderType.Limit:
        return $t(limit)
      case StxDxOrderType.Market:
        return $t(market)
      case StxDxOrderType.StopLimit:
        return $t(stopLimit)
      default:
        assertNever(type)
    }
  }
}
