import { mapValues } from "lodash"
import { FC, Ref, useEffect, useMemo, useState } from "react"
import { useConnect } from "../../../../components/ConnectWallet/ConnectProvider"
import { Modal } from "../../../../components/Modal"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { waitFor, waitUntilExist$ } from "../../../../utils/waitFor"
import { ConnectedWalletsPanel } from "../components/ConnectedWalletsPanel"
import {
  AvailableWalletType,
  SelectOridinalsWalletModalContent,
} from "../components/SelectOridinalsWalletModalContent/SelectOridinalsWalletModalContent"
import { useBulkTransferInscriptionPageStore } from "../stores/useBulkTransferInscriptionPageStore"

export const WiredConnectedWalletsPanel: FC<{
  className?: string
  containerRef?: Ref<HTMLElement>
}> = props => {
  const authStore = useAuthStore()
  const pegInStore = useBulkTransferInscriptionPageStore()
  const [connectStacksWallet] = useConnect()

  const [walletSelectModalVisible, setWalletSelectModalVisible] =
    useState(false)

  const availableWallets = useMemo(
    () =>
      suspenseResource(() =>
        mapValues(
          pegInStore.availableBtcWalletAdapters.value$,
          (adapter, type: AvailableWalletType) =>
            adapter == null
              ? undefined
              : async () => {
                  await pegInStore.connectBtcWallet(type, adapter)
                  setWalletSelectModalVisible(false)
                },
        ),
      ),
    [pegInStore],
  )

  useEffect(() => {
    const abortCtrl = new AbortController()

    const previousType = pegInStore.previousConnectedWallet
    if (previousType != null) {
      void waitFor(() =>
        waitUntilExist$(
          () => pegInStore.availableBtcWalletAdapters.value$[previousType],
        ),
      ).then(adapter => {
        if (pegInStore.isConnected) return
        if (abortCtrl.signal.aborted) return
        return pegInStore.connectBtcWallet(previousType, adapter)
      })
    }

    return () => {
      abortCtrl.abort()
    }
  }, [pegInStore])

  return (
    <>
      <ConnectedWalletsPanel
        className={props.className}
        containerRef={props.containerRef}
        ordinalsWallet={suspenseResource(() => ({
          walletAddress: pegInStore.bitcoinWalletAddresses$.ordinals[0].address,
          onDisconnect: () => pegInStore.disconnectBtcWallet(),
        }))}
        onConnectOrdinalsWallet={() => {
          setWalletSelectModalVisible(true)
        }}
        stacksWallet={suspenseResource(() => ({
          walletAddress: authStore.stxAddress$,
          onDisconnect: () => authStore.signOut(),
        }))}
        onConnectStacksWallet={connectStacksWallet}
      />

      <Modal
        visible={walletSelectModalVisible}
        onClose={() => setWalletSelectModalVisible(false)}
      >
        <SelectOridinalsWalletModalContent
          availableWallets={availableWallets}
          onClose={() => setWalletSelectModalVisible(false)}
        />
      </Modal>
    </>
  )
}
