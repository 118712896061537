import {
  ObservableInput,
  OperatorFunction,
  from,
  identity,
  scan,
  startWith,
  switchMap,
} from "rxjs"

export function scanWithObservableSeed<T, R>(
  accumulator: (acc: R, value: T, index: number) => R,
  seed: ObservableInput<R>,
  options?: { emitSeed?: boolean },
): OperatorFunction<T, R> {
  const { emitSeed = false } = options ?? {}

  return source =>
    from(seed).pipe(
      switchMap(seed =>
        source.pipe(
          scan(accumulator, seed),
          emitSeed ? startWith(seed) : identity,
        ),
      ),
    )
}
