import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Spensor } from "../../../../components/Spensor"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { ReactComponent as CycleCountQuestionIcon } from "./cycleCount.svg"

export interface EstimateCycleDaysProps {
  cycleCount: number
  cycleTip: string
  estimateDayCount: SuspenseResource<number>
}

export const EstimateCycleDays: FC<EstimateCycleDaysProps> = props => {
  const { $t } = useIntl()
  return (
    <div className={"flex flex-wrap items-center justify-between"}>
      <div className={"flex items-center gap-2.5"}>
        <span className={"text-sm leading-5 font-normal text-gray-400"}>
          {$t(
            defineMessage({
              defaultMessage: "Staking for:",
              description: "/Stake/ManualStake/Estimate cycle days",
            }),
          )}
        </span>
        <span className={"text-lg leading-7 font-medium text-gray-200"}>
          {$t(
            defineMessage({
              defaultMessage: `{cycleCount} {cycleCount, plural,
                one {Cycle}
                other {Cycles}
              }`,
              description: "/Stake/ManualStake/Estimate cycle days",
            }),
            {
              cycleCount: props.cycleCount,
            },
          )}
        </span>
        <Tooltip title={props.cycleTip}>
          <CycleCountQuestionIcon className={"opacity-40"} fill={"#eaeaea"} />
        </Tooltip>
      </div>
      <p className={"text-sm leading-5 font-normal text-gray-500"}>
        {$t(
          defineMessage({
            defaultMessage: "Estimated {dayCount} days",
            description: "/Stake/ManualStake/Estimate cycle days",
          }),
          {
            dayCount: (
              <Spensor fallback={"..."}>
                {() => {
                  const day = readResource(props.estimateDayCount)
                  return day === 0 ? "<1" : day
                }}
              </Spensor>
            ),
          },
        )}
      </p>
    </div>
  )
}
