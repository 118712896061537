import clsx from "clsx"
import { ComponentType, ReactNode } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { BlueGradientAlert } from "./BlueGradientAlert"
import { AlertCommonProps } from "./types"

export const AlertLayoutAsEntry: FCC<{
  className?: string

  /**
   * @default BlueGradientAlert
   */
  Alert?: ComponentType<AlertCommonProps>

  icon?: ReactNode
}> = props => {
  const { Alert = BlueGradientAlert } = props

  return (
    <Alert className={clsx("flex items-center gap-2.5", props.className)}>
      {props.icon}

      <div className={"flex-1"}>{props.children}</div>

      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="#C4C4C4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.6305 7.97577L5.85792 13.7914L4.9151 12.8486L9.78827 7.97539L4.91585 3.10297L5.85865 2.16016L11.6305 7.97577Z" />
      </svg>
    </Alert>
  )
}
