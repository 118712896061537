import clsx from "clsx"
import { FC } from "react"
import { TokenName, TokenNameBRC20Badge } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { TruncateTokenName } from "./TruncateTokenName"

const PoolTokenName: FC<{ token: TokenInfo; className?: string }> = ({
  className,
  token,
}) => {
  if (token.breakDowns == null) {
    return <TruncateTokenName token={token} className={className} />
  }
  return (
    <span className={clsx("inline-flex items-center gap-x-1", className)}>
      <TokenName token={token.breakDowns[0]} />
      <span>-</span>
      <TokenName token={token.breakDowns[1]} />
      {token.breakDowns.some(a => a.propertyTags.includes("brc-20")) && (
        <TokenNameBRC20Badge className="inline-block" />
      )}
    </span>
  )
}

export default PoolTokenName
