import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../../components/Card"
import { GradientText } from "../../../../components/GradientText"
import { NavLink } from "../../../../components/NavLink"
import {
  SegmentControl,
  SegmentControlSegment,
} from "../../../../components/SegmentControl"
import { SlippageSettingsButton } from "../../../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../../../components/Spensor"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../components/TokenInput/BalanceBottomArea"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../components/TokenInput/BlockGroup"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { TokenNameSelectTrigger } from "../../../../components/TokenInput/TokenNameSelect"
import { DetailCollapseButton } from "../../../../components/button/DetailCollapseButton"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { LoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { MaxSTXWarning } from "../../../Pool/components/ChangeLiquiditySection/ChangeLiquiditySection"
import { SwapFormErrorType } from "../../types"
import { SpotInfo, SpotInfoProps } from "../SpotInfo"
import { UnitPriceDescribe } from "./UnitPriceDescribe"
import { ReactComponent as LightBubbleIcon } from "./light.svg"

interface SpotFormProps extends SpotInfoProps {
  onSlippage?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
  onConfirm?: () => void

  isMaxSTX: SuspenseResource<boolean>

  formError: SuspenseResource<SwapFormErrorType | undefined>

  fromToken: SuspenseResource<TokenInfo>
  fromAmount: number
  fromOnChange?: (newValue: number | null) => void
  fromBalance: SuspenseResource<number>
  fromUSD: SuspenseResource<number>
  onPressMax: SuspenseResource<undefined | (() => void)>
  fromShowSelectTokenModal?: () => void

  toToken: SuspenseResource<TokenInfo>
  toAmount: SuspenseResource<number | null>
  toBalance: SuspenseResource<number>
  toUSD: SuspenseResource<number>

  fromToExchangeRate: SuspenseResource<number>
  fromUnitUSD: SuspenseResource<number>
  toFromExchangeRate: SuspenseResource<number>
  toUnitUSD: SuspenseResource<number>

  toShowSelectTokenModal?: () => void

  exchangeToken?: () => void

  availablePoolTypes: SuspenseResource<SegmentControlSegment[]>

  isTradingBrc20Token: SuspenseResource<boolean>
}

export const SpotForm: FC<SpotFormProps> = props => {
  const [showSpotInfo, setShowSpotInfo] = useState(false)
  const { $t } = useIntl()
  return (
    <Card className={"md:w-[30rem] mx-auto flex flex-col gap-3"}>
      <div className="flex flex-row items-center gap-2">
        <CardTitle className="mr-auto">
          {$t(
            defineMessage({
              defaultMessage: "Swap",
              description: "/Spot/SpotForm/Card title",
            }),
          )}
        </CardTitle>
        {props.onClear && (
          <HeadlessButton className={"text-blue-600"} onClick={props.onClear}>
            {$t(
              defineMessage({
                defaultMessage: "Clear",
                description: "/Spot/SpotForm/Button text",
              }),
            )}
          </HeadlessButton>
        )}
        <SlippageSettingsButton onClick={props.onSlippage} />
      </div>
      <BlockGroup
        icon={<BlockGroupDownArrowIcon onClick={props.exchangeToken} />}
        firstBlock={
          <TokenInput
            tokenNameArea={
              <TokenNameSelectTrigger
                token={safeReadResource(props.fromToken)}
                onClick={props.fromShowSelectTokenModal}
              />
            }
            token={props.fromToken}
            value={props.fromAmount}
            onChange={props.fromOnChange}
            error={
              safeReadResource(props.formError) ===
              SwapFormErrorType.InsufficientTokenBalance
            }
            bottomArea={
              <BalanceBottomArea
                token={props.fromToken}
                balance={props.fromBalance}
                rightSide={<EstimatedUSD usdCount={props.fromUSD} />}
                onPressMax={props.onPressMax}
              />
            }
          />
        }
        secondBlock={
          <TokenInput
            readonly={true}
            tokenNameArea={
              <TokenNameSelectTrigger
                token={safeReadResource(props.toToken)}
                onClick={props.toShowSelectTokenModal}
              />
            }
            token={props.toToken}
            value={props.toAmount}
            bottomArea={
              safeReadResource(props.toToken) == null ? null : (
                <BalanceBottomArea
                  token={safeReadResource(props.toToken)!}
                  balance={props.toBalance}
                  rightSide={<EstimatedUSD usdCount={props.toUSD} />}
                />
              )
            }
          />
        }
      />
      <Spensor>
        {() => {
          if (!readResource(props.isTradingBrc20Token)) {
            return null
          }
          return (
            <div className="flex items-center gap-x-1 p-1">
              <LightBubbleIcon />
              <GradientText
                gradientStyle={
                  "linear-gradient(90deg, #FFFFFF 0%, #EA580C 100%)"
                }
                className="text-[14px]"
              >
                {$t(
                  defineMessage({
                    defaultMessage: "You are trading BRC-20 assets now.",
                    description: "Spot/SpotForm/Trading BRC-20 assets",
                  }),
                )}
              </GradientText>
              <NavLink
                to="https://www.google.com"
                className="text-[14px] text-blue-600"
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Learn more >",
                    description: "Spot/SpotForm/Trading BRC-20 assets/Link",
                  }),
                )}
              </NavLink>
            </div>
          )
        }}
      </Spensor>
      <Spensor>
        {() => {
          const controls = readResource(props.availablePoolTypes)
          if (controls.length === 0) {
            return null
          }
          return (
            <SegmentControl
              className={"w-full"}
              boxClassName={"p-1"}
              segmentBoxClassName={"flex-1 min-h-[32px]"}
              segmentTextClassName={"text-sm leading-5 font-medium capitalize"}
              controls={controls}
            />
          )
        }}
      </Spensor>
      <Spensor>
        {() => (
          <div className="flex items-center">
            <span className="flex items-center">
              <DetailCollapseButton
                show={showSpotInfo}
                onClick={() => setShowSpotInfo(s => !s)}
              />
              &nbsp;
              <span className={"text-sm text-gray-100"}>
                {$t(
                  defineMessage({
                    defaultMessage: "Details",
                    description: "/Spot/SpotForm/Details text",
                  }),
                )}
              </span>
            </span>
            <UnitPriceDescribe
              className={"ml-auto"}
              fromToken={props.fromToken}
              toToken={props.toToken}
              fromToExchangeRate={props.fromToExchangeRate}
              fromUnitUSD={props.fromUnitUSD}
              toFromExchangeRate={props.toFromExchangeRate}
              toUnitUSD={props.toUnitUSD}
            />
          </div>
        )}
      </Spensor>
      {showSpotInfo && (
        <SpotInfo
          slippage={props.slippage}
          liquidityProviderFee={props.liquidityProviderFee}
          fromToken={props.fromToken}
          routes={props.routes}
          toToken={props.toToken}
          minimumReceived={props.minimumReceived}
        />
      )}
      {safeReadResource(props.isMaxSTX) && <MaxSTXWarning />}
      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(
              defineMessage({
                defaultMessage: "Loading...",
                description: "/Spot/SpotForm/Button text",
              }),
            )}
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.formError)
          if (error === SwapFormErrorType.TokenNotSelected) {
            return (
              <GradientFilledButton disabled={true}>
                {$t(
                  defineMessage({
                    defaultMessage: "Select Token",
                    description: "/Spot/SpotForm/Button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.TokenRouteNotSupported) {
            return (
              <GradientFilledButton disabled={true}>
                {$t(
                  defineMessage({
                    defaultMessage: "Not Supported",
                    description: "/Spot/SpotForm/Button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.WalletNotConnected) {
            return (
              <GradientFilledButton onClick={props.onConnectWallet}>
                {$t(
                  defineMessage({
                    defaultMessage: "Connect Wallet",
                    description: "/Spot/SpotForm/Button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.AmountIsEmpty) {
            return (
              <GradientFilledButton disabled={true}>
                {$t(
                  defineMessage({
                    defaultMessage: "Enter Amount",
                    description: "/Spot/SpotForm/Button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error === SwapFormErrorType.InsufficientTokenBalance) {
            return (
              <RedFilledButton disabled={true}>
                {$t(
                  defineMessage({
                    defaultMessage: "Insufficient Balance",
                    description: "/Spot/SpotForm/Button text",
                  }),
                )}
              </RedFilledButton>
            )
          }
          if (
            error === SwapFormErrorType.ErrorMaxInRatio ||
            error === SwapFormErrorType.ErrorMaxOutRatio
          ) {
            return (
              <RedFilledButton disabled={true}>
                {$t(
                  defineMessage({
                    defaultMessage: "Exceeds Liquidity Limit",
                    description: "/Spot/SpotForm/Button text",
                  }),
                )}
              </RedFilledButton>
            )
          }
          return (
            <GradientFilledButton onClick={props.onConfirm}>
              {$t(
                defineMessage({
                  defaultMessage: "Swap",
                  description: "/Spot/SpotForm/Button text",
                }),
              )}
            </GradientFilledButton>
          )
        }}
      </Spensor>
    </Card>
  )
}
