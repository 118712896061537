import clsx from "clsx"
import { ComponentType, FC, ReactNode, useState } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { RoundedFlatButton } from "../button/RoundedFlatButton"
import { Card, CardTitle } from "../Card"
import { ReactComponent as CollapseArrowIcon } from "./collapseArrow.svg"

const contentVerticalPaddingClass = "px-6"

export type ContentContainerProps = {
  className?: string
  gapClassName?: string
  children?: ReactNode
}

export const CollapsableCard: FCC<{
  className?: string
  gapClassName?: string
  defaultCollapsed?: boolean
  title: ReactNode
  ContentContainer?: ComponentType<ContentContainerProps>
}> = props => {
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed ?? false)

  const { gapClassName = "gap-4", ContentContainer = DefaultContentContainer } =
    props

  return (
    <Card
      className={clsx("flex flex-col", gapClassName, props.className)}
      boxClassName="px-0 py-6"
    >
      <div
        className={clsx(
          "flex items-center justify-between cursor-pointer",
          contentVerticalPaddingClass,
        )}
        onClick={() => setCollapsed(collapsed => !collapsed)}
      >
        <CardTitle>{props.title}</CardTitle>
        <RoundedFlatButton
          Svg={CollapseArrowIcon}
          svgProps={{
            className: clsx(
              collapsed && "rotate-180",
              "transition ease-in-out duration-200",
            ),
            fill: "#C4C4C4",
          }}
        />
      </div>

      {!collapsed && (
        <ContentContainer gapClassName={gapClassName}>
          {props.children}
        </ContentContainer>
      )}
    </Card>
  )
}

export const BasicContentContainer: FC<
  ContentContainerProps & { boxClassName?: string }
> = props => (
  <div
    className={clsx(
      props.boxClassName ?? contentVerticalPaddingClass,
      props.gapClassName,
      props.className,
    )}
  >
    {props.children}
  </div>
)

export const DefaultContentContainer: FC<
  ContentContainerProps & {
    boxClassName?: string
  }
> = props => (
  <BasicContentContainer
    className={clsx("flex flex-col", props.className)}
    boxClassName={props.boxClassName}
    gapClassName={props.gapClassName}
  >
    {props.children}
  </BasicContentContainer>
)

export const CentralizedContentContainer: FC<
  ContentContainerProps & {
    boxClassName?: string
  }
> = props => (
  <BasicContentContainer
    className={clsx("flex items-center justify-center", props.className)}
    boxClassName={props.boxClassName}
    gapClassName={props.gapClassName}
  >
    {props.children}
  </BasicContentContainer>
)

export const IdentityContentContainer: FC<ContentContainerProps> = props => (
  <>{props.children}</>
)
