import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import {
  PlainVerticalInfoListContainer,
  PlainVerticalInfoListItem,
} from "./InfoList"
import { TokenIDOPrices, TokenIDORaiseNumbers } from "./types"

export const TokenIDOSummary: FC<{
  className?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  idoPrices: TokenIDOPrices
  idoNumbers: TokenIDORaiseNumbers
}> = props => {
  const { idoToken, priceToken, idoPrices } = props
  const { $t } = useIntl()

  return (
    <PlainVerticalInfoListContainer className={props.className}>
      <PlainVerticalInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "Total Raise",
            description: "/Launchpad/Token ido summary",
          }),
        )}
        detail={
          <>
            <TokenIcon className={"mr-2"} token={priceToken} size={24} />
            <TokenCount
              token={priceToken}
              count={props.idoNumbers.maxRaisePriceTokenCount}
            />
            &nbsp;
            <TokenName token={priceToken} />
          </>
        }
      />
      <PlainVerticalInfoListItem
        title={$t(
          props.idoToken.propertyTags.includes("ordinals-voucher")
            ? defineMessage({
                defaultMessage: "Ordinal Per Ticket",
                description: "/Launchpad/Token ido summary",
              })
            : defineMessage({
                defaultMessage: "Token Per Ticket",
                description: "/Launchpad/Token ido summary",
              }),
        )}
        detail={
          <>
            <TokenIcon className={"mr-2"} token={idoToken} size={24} />
            <TokenCount
              token={idoToken}
              count={idoPrices.idoTokenCountPerTicket}
            />
            &nbsp;
            <TokenName token={idoToken} />
          </>
        }
      />
      <PlainVerticalInfoListItem
        title={
          <>
            {props.idoToken.propertyTags.includes("ordinals-voucher")
              ? $t(
                  defineMessage({
                    defaultMessage: "Price Per Ordinal",
                    description: "/Launchpad/Token ido summary",
                  }),
                )
              : $t(
                  defineMessage({
                    defaultMessage: "Price Per {tokenName}",
                    description: "/Launchpad/Token ido summary",
                  }),
                  { tokenName: <TokenName token={idoToken} /> },
                )}
          </>
        }
        detail={
          <>
            <TokenIcon className={"mr-2"} token={priceToken} size={24} />
            &nbsp;
            <TokenCount token={priceToken} count={idoPrices.pricePerToken} />
            &nbsp;
            <TokenName token={priceToken} />
          </>
        }
      />
    </PlainVerticalInfoListContainer>
  )
}
