import { FC } from "react"
import { ConfirmTransactionStore } from "../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Modal } from "../Modal"
import { TransactionStateDialogContentForStacks } from "./TransactionStateDialog"

export const WiredTransactionStateDialog: FC<{
  store: ConfirmTransactionStore
  onClose?: () => void
}> = props => {
  const state = props.store.running

  const onClose = (): void => {
    props.store.closeRunning()
    props.onClose?.()
  }

  if (!state) return null

  return (
    <Modal visible={true} onClose={onClose}>
      <TransactionStateDialogContentForStacks
        transactionState={state}
        onClose={onClose}
      />
    </Modal>
  )
}
