import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { dontWrapObserver } from "../../../utils/mobxHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { CoFarmSection } from "./CoFarmSection"

export const CoFarmDisclaimer: FC<{
  className?: string
  lpLeftToken: TokenInfo
  lpLeftTokenCount: number
  rewardToken: TokenInfo
}> = props => {
  const { $t } = useIntl()

  return (
    <CoFarmSection className={props.className} title={"Disclaimer"}>
      {$t<ReactNode>(
        defineMessage({
          defaultMessage: `<content>
            <line>You committing {tokenCount}.</line>
            <line>The ALEX Lab Foundation will provide the other half and co-farm.</line>
            <line>At the end of farming, you will be able to claim your {tokenCount} subject to the pool value exceeding the amount.</line>
            <line>{rewardToken} rewards will be automatically sent to your wallet address after each cycle.</line>
          </content>`,
          description: "/Farm/Co-farm disclaimer",
        }),
        {
          tokenCount: (
            <>
              <TokenCount
                token={props.lpLeftToken}
                count={props.lpLeftTokenCount}
              />
              &nbsp;
              <TokenName token={props.lpLeftToken} />
            </>
          ),
          rewardToken: <TokenName token={props.rewardToken} />,
          content: dontWrapObserver(children => (
            <ul className={"list-disc list-inside text-sm text-gray-400"}>
              {children}
            </ul>
          )),
          line: dontWrapObserver(children => <li>{children}</li>),
        },
      )}
    </CoFarmSection>
  )
}
