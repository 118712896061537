import React, { Suspense } from "react"
import { GradientBorderButton } from "../../../../components/button/variants/GradientBorderButton/GradientBorderButton"
import { ConnectProvider } from "../../../../components/ConnectWallet/ConnectProvider"
import {
  WiredTrivialButton$Connected,
  WiredTrivialButton$Disconnected,
} from "../../../../components/TopNavBar/TrivialButton/WiredTrivialButton"
import { FCC } from "../../../../utils/reactHelpers/types"
import { withProps } from "../../../../utils/reactHelpers/withProps"
import { NewBrandWrapBridgePageLayout } from "./NewBrandWrapBridgePageLayout"
import styles from "./WiredNewBrandWrapBridgePageLayout.module.scss"

const NewStyleMainButton = withProps(GradientBorderButton, {
  borderClassName: styles.buttonBorder,
})

export const WiredNewBrandWrapBridgePageLayout: FCC = props => {
  return (
    <ConnectProvider>
      <NewBrandWrapBridgePageLayout
        rightSide={
          <Suspense
            fallback={
              <WiredTrivialButton$Disconnected
                ButtonVariant={NewStyleMainButton}
              />
            }
          >
            <WiredTrivialButton$Connected ButtonVariant={NewStyleMainButton} />
          </Suspense>
        }
      >
        {props.children}
      </NewBrandWrapBridgePageLayout>
    </ConnectProvider>
  )
}
