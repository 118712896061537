import clsx from "clsx"
import { ReactNode, Suspense } from "react"
import { UnboxSuspenseResourceCollection } from "../../utils/SuspenseResource"
import { dontWrapObserver } from "../../utils/mobxHelpers"
import { RenderFn } from "../../utils/reactHelpers/childrenHelpers"
import { Spensor, SpensorProps, SpensorR, SpensorRReadType } from "../Spensor"
import { LoadingControllerFactoryProvider } from "./useLoading"
import { useLoadingControllerFactory } from "./useLoadingControllerFactory"

export interface OverlayFnContextInfo {
  isSuspense: boolean
}
export type OverlayFn = (contextInfo: OverlayFnContextInfo) => boolean

export const defaultOverlayFn: OverlayFn = ({ isSuspense }) => !isSuspense

export interface ScopedLoadingBoundaryProps {
  className?: string
  contentContainerClassName?: string
  spensorTag?: string
  inline?: boolean
  overlay?: boolean | OverlayFn
  isLoading?: boolean
  loadingIndicator: ReactNode
  placeholder?: ReactNode
  renderSpensor?: RenderFn<SpensorProps>
  children: ReactNode | (() => ReactNode)
}

export function ScopedLoadingBoundary(
  props: ScopedLoadingBoundaryProps,
): JSX.Element {
  const [isSubtreeLoading, ctrlFactory] = useLoadingControllerFactory()

  const renderSpensor =
    props.renderSpensor ?? dontWrapObserver(p => <Spensor {...p} />)

  const propsOverlay = props.overlay
  const overlay =
    propsOverlay == null
      ? defaultOverlayFn
      : typeof propsOverlay === "boolean"
      ? () => propsOverlay
      : propsOverlay

  const isLoading = isSubtreeLoading || props.isLoading

  const wrapChildren = (
    isLoading: boolean,
    children: ReactNode,
  ): JSX.Element => (
    <div
      className={clsx(
        "col-start-1 row-start-1",
        props.contentContainerClassName,
        isLoading && "opacity-50",
      )}
    >
      {children}
    </div>
  )

  const loadingJsx = (
    contextInfo: OverlayFnContextInfo,
    extraInfo: { placeholder?: ReactNode } = {},
  ): JSX.Element => (
    <>
      {extraInfo?.placeholder != null
        ? wrapChildren(true, extraInfo.placeholder)
        : null}
      <div
        className={clsx(
          "col-start-1 row-start-1 grid items-center justify-center",
        )}
        style={{
          backgroundColor: overlay(contextInfo)
            ? "rgba(0, 0, 0, .3)"
            : "transparent",
        }}
      >
        {props.loadingIndicator}
      </div>
    </>
  )

  return (
    <div
      className={clsx(props.className, props.inline ? "inline-grid" : "grid")}
    >
      <LoadingControllerFactoryProvider factory={ctrlFactory}>
        {typeof props.children === "function" ? (
          renderSpensor({
            spensorTag: props.spensorTag,
            fallback: loadingJsx(
              { isSuspense: true },
              { placeholder: props.placeholder },
            ),
            children: () =>
              wrapChildren(!!isLoading, (props.children as any)()),
          })
        ) : (
          <Suspense
            fallback={loadingJsx(
              { isSuspense: true },
              { placeholder: props.placeholder },
            )}
          >
            {wrapChildren(!!isLoading, props.children)}
          </Suspense>
        )}
      </LoadingControllerFactoryProvider>

      {isLoading && loadingJsx({ isSuspense: false })}
    </div>
  )
}

export function MaskedScopedLoadingBoundary<Read extends SpensorRReadType>(
  props: Omit<ScopedLoadingBoundaryProps, "children" | "placeholder"> & {
    inline?: boolean
    read: Read
    defaultRead: UnboxSuspenseResourceCollection<Read>
    children: (read: UnboxSuspenseResourceCollection<Read>) => ReactNode
  },
): JSX.Element {
  const placeholder = props.children(props.defaultRead)

  return (
    <ScopedLoadingBoundary
      contentContainerClassName={clsx(
        props.inline ? "inline-grid" : "grid",
        "items-center justify-center",
      )}
      overlay={false}
      {...props}
      placeholder={placeholder}
      renderSpensor={dontWrapObserver(p => (
        <SpensorR {...p} read={props.read}>
          {props.children}
        </SpensorR>
      ))}
    >
      {() => <></>}
    </ScopedLoadingBoundary>
  )
}
