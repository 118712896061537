import clsx from "clsx"
import { defineMessage, useIntl } from "react-intl"
import { TabBar } from "../../../components/TabBar"

import { FCC } from "../../../utils/reactHelpers/types"
import { TokenListStatus } from "../types"

export const TokenListTabBar: FCC<{
  className?: string
  filterStatus: null | TokenListStatus
  onFilterStatusChange: (status: null | TokenListStatus) => void
}> = props => {
  const { $t } = useIntl()
  return (
    <TabBar
      className={clsx(props.className)}
      tabClassName={"px-4 py-3 text-sm leading-5 font-semibold"}
      tabs={[
        {
          label: $t(
            defineMessage({
              defaultMessage: "All",
              description: "/B20TokenListPage/TokenListFrame/tab name",
            }),
          ),
          value: null,
        },
        {
          label: $t(
            defineMessage({
              defaultMessage: "Trading",
              description: "/B20TokenListPage/TokenListFrame/tab name",
            }),
          ),
          value: TokenListStatus.Listed,
        },
        {
          label: $t(
            defineMessage({
              defaultMessage: "Candidates",
              description: "/B20TokenListPage/TokenListFrame/tab name",
            }),
          ),
          value: TokenListStatus.Candidate,
        },
      ]}
      selectedTab={{
        tabValue: props.filterStatus,
        onChange: item => props.onFilterStatusChange(item.value),
      }}
    />
  )
}
