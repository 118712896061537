import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { TokenCount } from "../../../../../components/TokenCount"
import {
  BlockTopLine,
  BlockTopTitleLine,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"

export interface ConfirmSellDepositModalContentProps {
  yieldAssets: TokenCountPairPlain
  depositAssets: TokenCountPairPlain
  liquidityProviderFee: TokenCountPairPlain
  slippage: number

  yieldTokenPrice: {
    toCrypto: TokenCountPairPlain
    toUSD: number
  }

  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmSellDepositModalContent: FC<
  ConfirmSellDepositModalContentProps
> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Sell",
          description: "/Lend/DepositPage/ConfirmSellDepositModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            topArea={
              <BlockTopLine>
                {$t(
                  defineMessage({
                    defaultMessage: "From",
                    description:
                      "/Lend/DepositPage/ConfirmSellDepositModalContent/Block text",
                  }),
                )}
              </BlockTopLine>
            }
            tokens={[props.yieldAssets]}
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopTitleLine>
                {$t(
                  defineMessage({
                    defaultMessage: "To",
                    description:
                      "/Lend/DepositPage/ConfirmSellDepositModalContent/Second block text",
                  }),
                )}
              </BlockTopTitleLine>
            }
            tokens={[props.depositAssets]}
          />
        }
      />

      <InfoList>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Price",
                description:
                  "/Lend/DepositPage/ConfirmSellDepositModalContent/Info list item",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.yieldAssets.token}
              count={props.yieldAssets.count}
            />
            &nbsp;
            <span className={"text-gray-500"}>
              (≈$ {props.yieldTokenPrice.toUSD.toFixed(2)})
            </span>
            &nbsp; = &nbsp;
            <TextTokenCount
              token={props.yieldTokenPrice.toCrypto.token}
              count={props.yieldTokenPrice.toCrypto.count}
            />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
      <CardPlate>
        <InfoList listItemDirection={"row-responsive"}>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Liquidity Provider Fee",
                  description:
                    "/Lend/DepositPage/ConfirmSellDepositModalContent/Info list item",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount
                token={props.liquidityProviderFee.token}
                count={props.liquidityProviderFee.count}
              />
              &nbsp;
              <TokenName token={props.liquidityProviderFee.token} />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Slippage Tolerance",
                  description:
                    "/Lend/DepositPage/ConfirmSellDepositModalContent/Info list item",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <PercentNumber number={props.slippage} />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardPlate>
      <div className={"flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description:
                "/Lend/DepositPage/ConfirmSellDepositModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description:
                "/Lend/DepositPage/ConfirmSellDepositModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
