import { FC } from "react"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import {
  SelectTokenModalContent,
  TokenCandidate,
} from "../../../components/SelectTokenModalContent/SelectTokenModalContent"
import { Spensor } from "../../../components/Spensor"
import { usePathGenerator } from "../../../routes/routes"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { useEffectWithRef } from "../../../utils/reactHelpers/useEffectWithRef"
import { PegOutBrc20ModalContent } from "../components/BRC20/PegOutBrc20ModalContent/PegOutBrc20ModalContent"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredPegOutBrc20Modal: FC = () => {
  const store = useOrderbookStore()
  const { pegOutForm } = store.brc20Pegging

  const visible = store.withdrawFlow.pegOutBrc20TokensModalVisible
  useEffectWithRef<boolean>(
    memoRef => {
      if (memoRef.current != null && !visible && memoRef.current !== visible) {
        pegOutForm.reset()
      }
      memoRef.current = visible
    },
    [pegOutForm, visible],
  )

  return (
    <>
      <Modal
        visible={visible}
        onClose={() => store.withdrawFlow.onCanceledPegOutBrc20Tokens()}
      >
        <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
          {() => (
            <PegOutBrc20ModalContent
              formErrorMessage={suspenseResource(() =>
                Result.maybeError(store.brc20Pegging.pegOutForm.formData$),
              )}
              queuingPegRequestCount={suspenseResource(
                () => store.brc20Pegging.queuedTransactionCount$,
              )}
              brc20TokenBalance={suspenseResource(() =>
                store.account.getBalance$(pegOutForm.currency.read$),
              )}
              brc20Token={suspenseResource(() =>
                pegOutForm.currency.get() == null
                  ? undefined
                  : store.currency.getTokenInfo$(pegOutForm.currency.read$),
              )}
              pegOutAmount={pegOutForm.amount.get() ?? null}
              onPegOutAmountChange={v => pegOutForm.amount.set(v ?? undefined)}
              onPressMax={suspenseResource(() => {
                const balance = store.account.getBalance$(
                  pegOutForm.currency.read$,
                )
                return () => pegOutForm.amount.set(balance)
              })}
              targetBtcAddress={pegOutForm.toBtcAddr.get() ?? undefined}
              onTargetBtcAddressChange={v =>
                pegOutForm.toBtcAddr.set(v ?? undefined)
              }
              candidateTargetBtcAddress={suspenseResource(
                () => pegOutForm.candidateBtcAddress$ ?? undefined,
              )}
              onFillCandidateTargetBtcAddress={suspenseResource(() => {
                const addr = pegOutForm.candidateBtcAddress$
                if (addr == null) return undefined
                return () => pegOutForm.toBtcAddr.set(addr)
              })}
              pegOutFee={suspenseResource(
                () => store.brc20Pegging.pegOutForm.pegOutFee$,
              )}
              pegOutFeeRate={suspenseResource(
                () => store.brc20Pegging.pegOutForm.pegOutFeeRate$,
              )}
              pegOutFeeToken={suspenseResource(
                () => store.brc20Pegging.pegOutForm.pegOutFeeToken$,
              )}
              pegOutGasFee={suspenseResource(
                () => store.brc20Pegging.pegOutForm.pegOutGasFee$,
              )}
              pegOutGasFeeToken={suspenseResource(
                () => store.brc20Pegging.pegOutForm.pegOutGasFeeToken$,
              )}
              onSelectBrc20Token={() =>
                store.brc20Pegging.pegOutForm.onStartSelectToken()
              }
              onWithdrawBrc20={suspenseResource(
                () => store.withdrawFlow.onStartWithdrawStxTokens$.run,
              )}
              onSubmit={suspenseResource(() => {
                const formData = store.brc20Pegging.pegOutForm.formData$
                return formData.type === "error"
                  ? undefined
                  : async () => {
                      await store.txStore.run(() =>
                        store.brc20Pegging.pegOutToken(formData.payload),
                      )
                    }
              })}
              onClose={() => {
                store.withdrawFlow.onCanceledPegOutBrc20Tokens()
              }}
            />
          )}
        </Spensor>
      </Modal>
      <Spensor
        fallback={
          <Modal visible={true}>
            <LoadingIndicator className="m-auto" />
          </Modal>
        }
      >
        {() => <WiredPegOutSelectTokenModal />}
      </Spensor>
    </>
  )
}
const WiredPegOutSelectTokenModal: FC = () => {
  const g = usePathGenerator()
  const currency = useCurrencyStore()
  const account = useAccountStore()
  const store = useOrderbookStore()

  const selected = store.brc20Pegging.pegOutForm.currency.get()

  const allStxDxAssets =
    store.brc20Pegging.pegOutForm.availablePegOutCurrencies$

  const allTokens = allStxDxAssets.map((c): TokenCandidate => {
    return {
      disabled: selected === c,
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => account.getBalance$(c)),
    }
  })

  const commonTokens = allTokens.slice(0, 6)

  return (
    <Modal
      visible={store.brc20Pegging.pegOutForm.isSelectingToken}
      onClose={() => store.brc20Pegging.pegOutForm.onCancelledSelectToken()}
    >
      <SelectTokenModalContent
        commonBasesTokens={commonTokens}
        allTokens={allTokens}
        tokenListLink={g.b20TokenList()}
        onSelect={currency => {
          store.brc20Pegging.pegOutForm.onSelectedToken(currency.id as any)
        }}
        onClose={() => {
          store.brc20Pegging.pegOutForm.onCancelledSelectToken()
        }}
      />
    </Modal>
  )
}
