import { unwrapResponse } from "clarity-codegen"
import { fromPairs, memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { computedFn, createTransformer } from "mobx-utils"
import { CONTRACT_DEPLOYER } from "../../config"
import { asSender } from "../../generated/smartContractHelpers/asSender"
import {
  StakableCurrency,
  StakingToken,
} from "../../pages/Stake/store/manualStaking/ManualStakeStore.service"
import { assetIdentifierEquals } from "../../utils/addressHelpers"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import {
  BRC20Currency,
  Currency,
  CurrencyAndBRC20s,
  WrappedCurrency,
  isBRC20Token,
  isCurrencyOrB20,
} from "../../utils/alexjs/Currency"
import {
  FungibleToken,
  LiquidityPoolToken,
  getAssetIdentifierByCurrency,
  isFWPToken,
  isYTPToken,
  liquidityTokenPairs,
} from "../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { LazyValue } from "../LazyValue/LazyValue"
import { pMemoizeDecorator } from "../LazyValue/pMemoizeDecorator"
import AuthStore from "../authStore/AuthStore"
import {
  fetchBrc20Price,
  fetchLatestPrices,
  getDxDyOnFixedWeightPool,
  getTokensFromCMS,
  parseTokenPropertyTags,
} from "./CurrencyStore.service"

class CurrencyStore {
  constructor(
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
  ) {
    makeObservable(this)
  }

  private _prices = new LazyValue(
    () => null,
    () => fetchLatestPrices(),
  )

  private prices$ = createTransformer((currency: Currency): number => {
    if (!(currency in this._prices.value$)) {
      console.error(new Error(`Currency ${currency} not found in prices`))
      return 1
    }
    return this._prices.value$[currency]!
  })
  private brc20Prices$ = memoize(
    (currency: BRC20Currency) => new LazyValue(() => currency, fetchBrc20Price),
  )

  private _fwpPriceBreakdown = memoize(
    (currency: LiquidityPoolToken) =>
      new LazyValue(
        () => [currency, this.authStore.currentBlockHeight$] as const,
        async ([currency]) => {
          for (const lpUnit of [1000, 100000, 10000000]) {
            const result = await getDxDyOnFixedWeightPool(currency, lpUnit)
            if (result.dx !== 0 && result.dy !== 0) {
              return result
            }
          }
          throw new Error(`Failed to get price breakdown for ${currency}`)
        },
        { decorator: pMemoizeDecorator({ persistKey: `pool-dx-dya` }) },
      ),
  )

  fetchPoolBreakdown$ = createTransformer((currency: LiquidityPoolToken) => {
    return this._fwpPriceBreakdown(currency).value$
  })

  getPrice$ = createTransformer((currency: FungibleToken): number => {
    if (isBRC20Token(currency)) {
      return this.brc20Prices$(currency).value$
    }
    if (
      isFWPToken(currency) ||
      isYTPToken(currency) ||
      AMMSwapPool.isPoolToken(currency)
    ) {
      const { y, x, dx, dy } = this.fetchPoolBreakdown$(currency)
      return this.prices$(x) * dx + this.prices$(y) * dy
    }
    return this.prices$(currency)
  })

  private allTokenInfoFromCMS = new LazyValue(() => null, getTokensFromCMS)

  @computed
  private get allAssetIdentifiers$(): string[] {
    return Object.keys(this.allAssetIdentifiersMap$)
  }

  @computed
  get allAssetIdentifiersMap$(): Record<string, CurrencyAndBRC20s> {
    return fromPairs(
      this.allTokenInfoFromCMS.value$.flatMap(t => {
        const currency = t.id

        if (!isCurrencyOrB20(currency)) return []

        const upstreamId = getAssetIdentifierByCurrency(currency)
        const downstreamId = getAssetIdentifierByCurrency(
          WrappedCurrency.safeUnwrap(currency),
        )

        return [
          [upstreamId, currency],
          [downstreamId, currency],
        ]
      }),
    )
  }

  getCurrencyForAsset$ = createTransformer(
    (
      assetIdentifierOrAssetContractAddress: string,
    ): undefined | CurrencyAndBRC20s => {
      const assetIdentifier = this.allAssetIdentifiers$.find(id =>
        assetIdentifierEquals(id, assetIdentifierOrAssetContractAddress),
      )
      if (!assetIdentifier) return

      return this.allAssetIdentifiersMap$[assetIdentifier]
    },
    { keepAlive: true },
  )

  getTokenInfoForAsset$ = createTransformer(
    (
      assetIdentifierOrAssetContractAddress: string,
    ): Omit<TokenInfo, "id"> & { id?: string } => {
      const seekingCurrency = this.getCurrencyForAsset$(
        assetIdentifierOrAssetContractAddress,
      )
      const foundToken = this.allTokenInfoFromCMS.value$.find(
        t => t.id === seekingCurrency,
      )
      if (foundToken == null) {
        throw new Error(
          `Unable to find token for asset ${assetIdentifierOrAssetContractAddress}`,
        )
      }
      return foundToken
    },
    { keepAlive: true },
  )

  getAssetIdentifiers$(currency: CurrencyAndBRC20s): undefined | string {
    const id = getAssetIdentifierByCurrency(currency)
    if (id == null) return
    return id
  }

  getTokenInfo$ = createTransformer(
    (currency: CurrencyAndBRC20s): TokenInfo => {
      const assetIdentifier = this.getAssetIdentifiers$(currency)

      if (assetIdentifier == null) {
        throw new Error(`Unable to find token for currency ${currency}`)
      }

      return {
        ...this.getTokenInfoForAsset$(assetIdentifier),
        id: currency,
        propertyTags: parseTokenPropertyTags(currency),
        breakDowns:
          isFWPToken(currency) ||
          isYTPToken(currency) ||
          AMMSwapPool.isPoolToken(currency)
            ? (liquidityTokenPairs(currency).map(this.getTokenInfo$) as [
                TokenInfo,
                TokenInfo,
              ])
            : undefined,
      }
    },
    { keepAlive: true },
  )

  @computed get allTokenInfos$(): { [key in Currency]?: TokenInfo } {
    return Object.keys(Currency)
      .map(key => this.getTokenInfo$((Currency as any)[key]))
      .reduce<{ [key in Currency]?: TokenInfo }>((acc, curr) => {
        if (curr.id) {
          return {
            ...acc,
            [curr.id]: curr,
          }
        }
        return acc
      }, {})
  }

  ammV1PoolId = computedFn(
    (
      tokenX: AMMSwapPool.SwapTokens,
      tokenY: AMMSwapPool.SwapTokens,
      factor: number,
    ) =>
      new LazyValue(
        () => [tokenX, tokenY, factor] as const,
        async ([tokenX, tokenY, factor]) =>
          asSender(CONTRACT_DEPLOYER)
            .contract("amm-swap-pool")
            .func("get-pool-details")
            .call({
              factor,
              "token-x": tokenX,
              "token-y": tokenY,
            })
            .then(unwrapResponse)
            .then(a => a["pool-id"]),
        {
          decorator: pMemoizeDecorator({
            persistKey: "amm-swap-pool::get-pool-id",
          }),
        },
      ),
  )

  ammV1_1PoolId = computedFn(
    (
      tokenX: AMMSwapPool.SwapTokens,
      tokenY: AMMSwapPool.SwapTokens,
      factor: number,
    ) =>
      new LazyValue(
        () => [tokenX, tokenY, factor] as const,
        async ([tokenX, tokenY, factor]) =>
          asSender(CONTRACT_DEPLOYER)
            .contract("amm-swap-pool-v1-1")
            .func("get-pool-details")
            .call({
              factor,
              "token-x": tokenX,
              "token-y": tokenY,
            })
            .then(unwrapResponse)
            .then(a => a["pool-id"]),
        {
          decorator: pMemoizeDecorator({
            persistKey: "amm-swap-pool_v1-1::get-pool-id",
          }),
        },
      ),
  )

  ammPoolIdFromCurrency$ = createTransformer(
    (currency: AMMSwapPool.PoolTokens) => {
      const [tokenX, tokenY] = liquidityTokenPairs(currency)
      if (AMMSwapPool.isV1PoolToken(currency)) {
        return this.ammV1PoolId(
          tokenX as AMMSwapPool.SwapTokens,
          tokenY as AMMSwapPool.SwapTokens,
          AMMSwapPool.getFactor(currency),
        ).value$
      }
      return this.ammV1_1PoolId(
        tokenX as AMMSwapPool.SwapTokens,
        tokenY as AMMSwapPool.SwapTokens,
        AMMSwapPool.getFactor(currency),
      ).value$
    },
    { keepAlive: true },
  )

  stakingCurrencyToStakingToken$ = createTransformer(
    (stakableToken: StakableCurrency): StakingToken => {
      if (!AMMSwapPool.isPoolToken(stakableToken)) {
        return stakableToken
      }
      const poolId = this.ammPoolIdFromCurrency$(stakableToken)
      return {
        currency: AMMSwapPool.isV1_1PoolToken(stakableToken)
          ? Currency.AMM_SWAP_POOL_V1_1
          : Currency.AMM_SWAP_POOL,
        tokenId: poolId,
        representingCurrency: stakableToken,
      }
    },
    { keepAlive: true },
  )
}

export default CurrencyStore
