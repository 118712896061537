import produce from "immer"
import { throttle } from "lodash"
import { useCallback, useLayoutEffect, useMemo, useState } from "react"

const THROTTLE_INTERVAL = 40

interface CanScroll {
  left: boolean
  right: boolean
  offset: number
}

export function useCanScrollMask(): [
  (instance: HTMLElement | null) => void,
  CanScroll,
] {
  const [element, setElement] = useState<HTMLElement | null>(null)
  const [canScroll, setCanScroll] = useState<CanScroll>({
    left: false,
    right: false,
    offset: 0,
  })

  const ref = useCallback(
    (instance: HTMLElement | null) => setElement(instance),
    [],
  )

  const throttledHandleScroll = useMemo(() => {
    const handleScroll = (): void => {
      setCanScroll(
        produce(draft => {
          // scrollLeft !== 0 -> can scroll to left
          // scrollLeft + clientWidth !== scrollWidth -> can scroll to right
          if (!element) {
            draft.left = false
            draft.right = false
            draft.offset = 0
          } else {
            draft.left = element.scrollLeft !== 0
            draft.right =
              element.scrollLeft + element.clientWidth !== element.scrollWidth
            draft.offset = element.scrollLeft
          }
        }),
      )
    }
    return throttle(handleScroll, THROTTLE_INTERVAL)
  }, [element])

  useLayoutEffect(() => {
    throttledHandleScroll()
    element?.addEventListener("scroll", throttledHandleScroll)
    return () => {
      element?.removeEventListener("scroll", throttledHandleScroll)
    }
  }, [element, throttledHandleScroll])
  return [ref, canScroll]
}
