import { noop } from "lodash"
import { action, computed, makeObservable, observable, reaction } from "mobx"
import { of } from "rxjs"
import {
  BridgeEndpoint__factory,
  ERC20__factory,
} from "../../../generated/evmContract"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import AccountStore from "../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../stores/appEnvStore/AppEnvStore"
import { ETHChain } from "../../../stores/appEnvStore/ETHChain"
import {
  ETHCurrency,
  isETHCurrency,
} from "../../../stores/appEnvStore/ETHCurrency"
import AuthStore from "../../../stores/authStore/AuthStore"
import {
  approveERC20Token,
  getERC20TokenAllowance,
  resetAndApproveERC20Token,
} from "../../../stores/authStore/MetaMaskModule.service"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { ConfirmTransactionStoreForGeneral } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStoreForGeneral"
import { Currency } from "../../../utils/alexjs/Currency"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { DisplayableError } from "../../../utils/error"
import { never } from "../../../utils/promiseHelpers"
import { OneOrMore, assertNever } from "../../../utils/types"
import type { OppositeBridgeChain } from "../types/BridgeChain"
import {
  BridgeChain,
  SupportedOppositeBridgeChain,
  isEthBridgeChain,
  isOppositeBridgeChain,
  isSupportedOppositeBridgeChain,
  supportedOppositeBridgeChains,
} from "../types/BridgeChain"
import {
  contractAssignedChainIdFromBridgeChain,
  getOppositeChainExplorerTxLink,
  mapBridgeChainToEthChain,
  mapEthChainToBridgeChain,
} from "../types/BridgeChainHelpers"
import {
  TransferProphet,
  createUnwrappableTransferProphet,
  createWrappableTransferProphet,
  isWalletAddressInWhitelist,
  unwrapStacksToken,
  wrapAsStacksToken,
} from "./WrapFormModule.service"
import type { BridgeCurrency } from "./utils/BridgeCurrency"
import {
  UnwrappableBridgeCurrency,
  getCorrespondingBridgeCurrency,
  isUnwrappableBridgeCurrency,
  isWrappableBridgeCurrency,
  unwrappableBridgeCurrencies,
  wrappableBridgeCurrencies,
  type WrappableBridgeCurrency,
} from "./utils/BridgeCurrency"

export type FormData = FormData.WrappingFormData | FormData.UnwrappingFormData
export namespace FormData {
  interface Common {
    fromAddress: string
    fromAmount: number

    toAddress: string

    fee: number
  }

  export interface WrappingFormData extends Common {
    fromChain: SupportedOppositeBridgeChain
    fromCurrency: WrappableBridgeCurrency

    toChain: BridgeChain.Stacks
    toCurrency: UnwrappableBridgeCurrency
  }

  export interface UnwrappingFormData extends Common {
    fromChain: BridgeChain.Stacks
    fromCurrency: UnwrappableBridgeCurrency

    toChain: SupportedOppositeBridgeChain
    toCurrency: WrappableBridgeCurrency
  }
}

export class WrapFormModule {
  private disposableBag: (() => void)[] = []

  constructor(
    private readonly accountStore: Pick<AccountStore, "getBalance$">,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,
    private readonly appEnvStore: AppEnvStore,
  ) {
    makeObservable(this)

    this.disposableBag.push(
      reaction(
        () => this.authStore.metaMaskModule.connectedChain.value$,
        chain => {
          this.onETHChainUpdated(chain.chain)
        },
        { fireImmediately: true, onError: noop },
      ),
    )
  }

  destroy(): void {
    this.disposableBag.forEach(f => f())
  }

  isWalletInWhitelist = new LazyValue(
    () => this.authStore.stxAddress$,
    address => isWalletAddressInWhitelist(address),
  )

  transferProphet = new LazyValue(
    () => [this.fromChain, this.toChain, this.fromChainCurrency] as const,
    ([fromChain, toChain, fromChainCurrency]) =>
      this.createTransferProphet(fromChain, toChain, fromChainCurrency).then(
        v => v ?? never(),
      ),
  )

  @observable direction: "wrap" | "unwrap" = "wrap"
  @action swapWrapDirection(): void {
    this.direction = this.direction === "wrap" ? "unwrap" : "wrap"
  }

  @observable private ethChain: OppositeBridgeChain | BridgeChain.Unknown =
    supportedOppositeBridgeChains[0]
  @action onETHChainUpdated(chain: ETHChain): void {
    const result = mapEthChainToBridgeChain(chain)
    if (result && isOppositeBridgeChain(result)) {
      this.ethChain = result
    } else {
      this.ethChain = BridgeChain.Unknown
    }
  }
  @asyncAction async switchToETHChain(
    newChain: OppositeBridgeChain,
    run = runAsyncAction,
  ): Promise<void> {
    await run(
      this.authStore.metaMaskModule.switchToChain$(
        mapBridgeChainToEthChain(newChain),
      ),
    )
  }

  @computed private get chains(): [from: BridgeChain, to: BridgeChain] {
    return this.direction === "wrap"
      ? [this.ethChain, BridgeChain.Stacks]
      : [BridgeChain.Stacks, this.ethChain]
  }
  @computed get fromChain(): BridgeChain {
    return this.chains[0]
  }
  @computed get toChain(): BridgeChain {
    return this.chains[1]
  }

  @observable private ethChainCurrency: WrappableBridgeCurrency =
    ETHCurrency.USDT
  @computed get fromChainCurrencyCandidates(): BridgeCurrency[] {
    if (isOppositeBridgeChain(this.fromChain)) {
      return wrappableBridgeCurrencies as OneOrMore<BridgeCurrency>
    }

    if (this.fromChain === BridgeChain.Stacks) {
      return unwrappableBridgeCurrencies as OneOrMore<BridgeCurrency>
    }

    if (this.fromChain === BridgeChain.Unknown) {
      return []
    }

    assertNever(this.fromChain)
  }
  @computed private get chainCurrencies(): [
    from: BridgeCurrency,
    to: BridgeCurrency,
  ] {
    const stxCurrency = getCorrespondingBridgeCurrency(this.ethChainCurrency)
    return this.direction === "wrap"
      ? [this.ethChainCurrency, stxCurrency]
      : [stxCurrency, this.ethChainCurrency]
  }
  @computed get fromChainCurrency(): BridgeCurrency {
    return this.chainCurrencies[0]
  }
  @computed get toChainCurrency(): BridgeCurrency {
    return this.chainCurrencies[1]
  }
  @action setFromChainCurrency(newCurrency: BridgeCurrency): void {
    if (this.direction === "wrap") {
      if (isETHCurrency(newCurrency)) {
        this.ethChainCurrency = newCurrency
      }
    } else {
      const ethChainCurrency = getCorrespondingBridgeCurrency(newCurrency)
      if (isETHCurrency(ethChainCurrency)) {
        this.ethChainCurrency = ethChainCurrency
      }
    }
  }
  @computed get fromChainCurrencyBalance$(): number {
    return this.bridgeCurrencyBalance$(this.fromChainCurrency)
  }

  fromChainCurrencyAllowance = new LazyValue(
    () =>
      [
        this.authStore.metaMaskModule.metaMaskEthereumProvider.read$,
        this.authStore.metaMaskModule.provider$,
        this.authStore.metaMaskModule.connectedWalletAddress$,
        !isETHCurrency(this.fromChainCurrency) ||
        !isEthBridgeChain(this.fromChain)
          ? undefined
          : this.authStore.metaMaskModule.getErc20ContractAddr$(
              mapBridgeChainToEthChain(this.fromChain),
              this.fromChainCurrency,
            ),
        !isEthBridgeChain(this.fromChain)
          ? undefined
          : this.authStore.metaMaskModule.getEndpointContractAddr$(
              mapBridgeChainToEthChain(this.fromChain),
            ),
        this.authStore.metaMaskModule.currentBlockNumber,
      ] as const,
    ([
      metamaskProvider,
      ethProvider,
      walletAddr,
      tokenContractAddr,
      endpointAddr,
    ]) =>
      tokenContractAddr == null || endpointAddr == null
        ? of(Infinity)
        : getERC20TokenAllowance(
            metamaskProvider,
            ERC20__factory.connect(tokenContractAddr, ethProvider),
            walletAddr,
            endpointAddr,
          ),
  )

  fromTokenCount = new SuspenseObservable<number>()
  @action setFromTokenCount(newCount: null | undefined | number): void {
    this.fromTokenCount.set(newCount ?? undefined)
  }
  @action setMaxFromTokenCount(): void {
    this.setFromTokenCount(this.fromChainCurrencyBalance$)
  }
  @computed get toTokenCount$(): number {
    return Math.max(this.fromTokenCount.read$ - this.wrapFeeTokenCount$, 0)
  }

  @computed get wrapFeeCurrency$(): BridgeCurrency {
    return this.transferProphet.value$.feeToken
  }

  @computed get wrapFeeTokenCount$(): number {
    return Math.max(
      this.transferProphet.value$.feeRate * this.fromTokenCount.read$,
      this.transferProphet.value$.minFeeAmount,
    )
  }

  @computed get wrapCostedMilliseconds$(): number {
    return this.transferProphet.value$.costedMilliseconds
  }

  metamaskTransactionStore = new ConfirmTransactionStoreForGeneral()
  stacksTransactionStore = new ConfirmTransactionStore()

  private async createTransferProphet(
    fromChain: BridgeChain,
    toChain: BridgeChain,
    fromCurrency: BridgeCurrency,
  ): Promise<undefined | TransferProphet> {
    if (
      fromChain === BridgeChain.Stacks &&
      isSupportedOppositeBridgeChain(toChain) &&
      isUnwrappableBridgeCurrency(fromCurrency)
    ) {
      return createUnwrappableTransferProphet(
        asSender(this.authStore.stxAddress$),
        toChain,
        fromCurrency,
      )
    }

    if (
      isSupportedOppositeBridgeChain(fromChain) &&
      toChain === BridgeChain.Stacks &&
      isWrappableBridgeCurrency(fromCurrency)
    ) {
      return createWrappableTransferProphet(
        BridgeEndpoint__factory.connect(
          this.authStore.metaMaskModule.getEndpointContractAddr$(
            mapBridgeChainToEthChain(fromChain),
          ),
          this.authStore.metaMaskModule.provider$,
        ),
        ERC20__factory.connect(
          this.authStore.metaMaskModule.getErc20ContractAddr$(
            mapBridgeChainToEthChain(fromChain),
            fromCurrency,
          ),
          this.authStore.metaMaskModule.provider$,
        ),
        fromChain,
        fromCurrency,
      )
    }

    console.error(
      `Unsupported wrap/unwrap request: ${fromCurrency} ${fromChain}->${toChain}`,
    )
    return
  }

  async approveFromCurrency(): Promise<void> {
    await this.metamaskTransactionStore.run(async () => {
      if (!isEthBridgeChain(this.fromChain)) return
      if (!isETHCurrency(this.fromChainCurrency)) return

      const ethChain = mapBridgeChainToEthChain(this.fromChain)

      await approveERC20Token(
        this.authStore.metaMaskModule.metaMaskEthereumProvider.read$,
        ethChain,
        ERC20__factory.connect(
          this.authStore.metaMaskModule.getErc20ContractAddr$(
            ethChain,
            this.fromChainCurrency,
          ),
          this.authStore.metaMaskModule.signer$,
        ),
        this.authStore.metaMaskModule.getEndpointContractAddr$(ethChain),
        this.authStore.metaMaskModule.connectedWalletAddress$,
        this.fromTokenCount.read$,
      )
    })
  }

  async resetThenApproveFromCurrency(): Promise<void> {
    await this.metamaskTransactionStore.run(async () => {
      if (!isEthBridgeChain(this.fromChain)) return
      if (!isETHCurrency(this.fromChainCurrency)) return

      const ethChain = mapBridgeChainToEthChain(this.fromChain)

      await resetAndApproveERC20Token(
        this.authStore.metaMaskModule.metaMaskEthereumProvider.read$,
        ethChain,
        ERC20__factory.connect(
          this.authStore.metaMaskModule.getErc20ContractAddr$(
            ethChain,
            this.fromChainCurrency,
          ),
          this.authStore.metaMaskModule.signer$,
        ),
        this.authStore.metaMaskModule.getEndpointContractAddr$(ethChain),
        this.authStore.metaMaskModule.connectedWalletAddress$,
        this.fromTokenCount.read$,
      )
    })
  }

  @asyncAction async execute(
    formData: FormData,
    run = runAsyncAction,
  ): Promise<void> {
    if (
      formData.fromChain === BridgeChain.Stacks &&
      isSupportedOppositeBridgeChain(formData.toChain) &&
      isUnwrappableBridgeCurrency(formData.fromCurrency)
    ) {
      await run(
        this.stacksTransactionStore.run(async () => {
          const txId = await unwrapStacksToken(
            asSender(formData.fromAddress),
            contractAssignedChainIdFromBridgeChain(formData.toChain),
            formData.fromCurrency,
            formData.fromAddress,
            formData.toAddress,
            formData.fromAmount,
          )
          return { txId }
        }),
      )
      return
    }

    if (
      isSupportedOppositeBridgeChain(formData.fromChain) &&
      formData.toChain === BridgeChain.Stacks &&
      isWrappableBridgeCurrency(formData.fromCurrency)
    ) {
      const { metaMaskModule } = this.authStore
      const erc20 = ERC20__factory.connect(
        metaMaskModule.getErc20ContractAddr$(
          mapBridgeChainToEthChain(formData.fromChain),
          formData.fromCurrency,
        ),
        metaMaskModule.signer$,
      )
      const endpointContract = BridgeEndpoint__factory.connect(
        metaMaskModule.getEndpointContractAddr$(
          mapBridgeChainToEthChain(formData.fromChain),
        ),
        metaMaskModule.signer$,
      )

      await run(
        this.metamaskTransactionStore.run(async () => {
          const txHash = await wrapAsStacksToken(
            endpointContract,
            erc20,
            formData.fromCurrency,
            formData.fromAddress,
            formData.toAddress,
            formData.fromAmount,
          )

          return {
            explorerLink: getOppositeChainExplorerTxLink(
              formData.fromChain,
              txHash,
            ),
          }
        }),
      )

      return
    }

    throw new DisplayableError(
      `Unsupported wrap/unwrap request: ${formData.fromCurrency} ${formData.fromChain}->${formData.toChain}`,
    )
  }

  private bridgeCurrencyBalance$(currency: BridgeCurrency): number {
    switch (currency) {
      case ETHCurrency.USDT:
        return this.authStore.metaMaskModule.usdtBalance.value$
      case ETHCurrency.LUNR:
        return this.authStore.metaMaskModule.lunrBalance.value$
      case Currency.sUSDT:
      case Currency.sLUNR:
        return this.accountStore.getBalance$(currency)
      default:
        assertNever(currency)
    }
  }
}
