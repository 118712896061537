import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { suspenseResource } from "../../utils/SuspenseResource"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import { suspenseMap$ } from "../../utils/waitFor"
import { FarmStoreProvider, useFarmStore } from "../Farm/stores/useFarmStore"
import { AllPoolListInfo, AllPoolsSection } from "./components/AllPoolsSection"
import { MyPoolsSection } from "./components/MyPoolsSection"
import {
  PoolListStoreProvider,
  usePoolListStore,
} from "./store/list/usePoolListStore"

const WiredAllPools: FC = () => {
  const store = usePoolListStore()
  const farmPool = useFarmStore()
  return (
    <AllPoolsSection
      pools={suspenseResource(() =>
        suspenseMap$(
          store.allPoolStores$,
          (p): AllPoolListInfo => ({
            isStableSwap:
              AMMSwapPool.isPoolToken(p.poolToken) &&
              AMMSwapPool.getFactor(p.poolToken) <= 0.1e8,
            mainPool: {
              ...p.info$,
              poolTokenInfo: p.poolTokenInfo,
              farmYield: suspenseResource(
                () => farmPool.aprForPool$(p.poolToken as any) ?? null,
              ),
            },
            legacyPool: store.oldPoolStores$(p.poolToken).map(pp => ({
              ...pp.info$,
              poolTokenInfo: pp.poolTokenInfo,
              farmYield: suspenseResource(
                () => farmPool.aprForPool$(pp.poolToken as any) ?? null,
              ),
            })),
          }),
        ),
      )}
    />
  )
}

const WiredMyPools: FC = () => {
  const store = usePoolListStore()
  return (
    <MyPoolsSection
      pools={suspenseResource(() =>
        suspenseMap$(store.myPools$, p => ({
          ...p.info$,
          poolTokenInfo: p.poolTokenInfo,
          myBalance: p.pooledAmount$,
        })),
      )}
    />
  )
}

const PageContent: FC = () => {
  return (
    <div className={"flex flex-col gap-5"}>
      <WiredMyPools />
      <WiredAllPools />
    </div>
  )
}

const PoolListPage: FC = () => {
  const { $t } = useIntl()

  return (
    <FarmStoreProvider>
      <PoolListStoreProvider>
        <PageStackPage
          title={
            <PrimaryPageTitle
              subtitle={"Provide liquidity to earn trading fees"}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Pool",
                  description: "/Pool/PoolDetail/Page title",
                }),
              )}
            </PrimaryPageTitle>
          }
        >
          <PageContent />
        </PageStackPage>
      </PoolListStoreProvider>
    </FarmStoreProvider>
  )
}

export default PoolListPage
