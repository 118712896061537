import clsx from "clsx"
import { FC, useState } from "react"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import { Spensor, SuspenseProps } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ReactComponent as ExchangeIcon } from "./Exchange.svg"

export interface UnitPriceDescription {
  fromUnitUSD: number
  fromToExchangeRate: number
  toUnitUSD: number
  toFromExchangeRate: number
}

export interface UnitPriceDescribeProps
  extends SuspenseProps<UnitPriceDescription> {
  className?: string
  fromToken: SuspenseResource<TokenInfo>
  toToken: SuspenseResource<TokenInfo>
}

export const UnitPriceDescribe: FC<UnitPriceDescribeProps> = props => {
  const [info, setInfo] = useState({
    oneUnitToken: props.fromToken,
    oneUnitUSD: props.fromUnitUSD,
    exchangeToken: props.toToken,
    exchangeRate: props.fromToExchangeRate,
  })

  const onSwap = (): void => {
    setInfo(info => {
      if (info.oneUnitToken === props.fromToken) {
        return {
          oneUnitToken: props.toToken,
          oneUnitUSD: props.toUnitUSD,
          exchangeToken: props.fromToken,
          exchangeRate: props.toFromExchangeRate,
        }
      } else {
        return {
          oneUnitToken: props.fromToken,
          oneUnitUSD: props.fromUnitUSD,
          exchangeToken: props.toToken,
          exchangeRate: props.fromToExchangeRate,
        }
      }
    })
  }

  return (
    <span
      className={clsx(
        "text-xs sm:text-sm text-gray-200 font-medium",
        props.className,
      )}
    >
      <TokenCount token={readResource(info.oneUnitToken)} count={1} />{" "}
      <TokenName token={readResource(info.oneUnitToken)} />{" "}
      <span className="text-gray-500">
        (≈${" "}
        <Spensor fallback={"-"}>
          {() => (
            <TokenCount
              token={TokenInfoPresets.USD}
              count={readResource(info.oneUnitUSD)}
            />
          )}
        </Spensor>
        )
      </span>
      <PlainIconButton
        className={"inline-block align-text-bottom px-[5px]"}
        inline
        icon={<ExchangeIcon width={16 + 5 * 2} height={16} fill={"#E5E7EB"} />}
        onClick={onSwap}
      />
      <Spensor fallback={"-"}>
        {() => (
          <TokenCount
            token={readResource(info.exchangeToken)}
            count={readResource(info.exchangeRate)}
          />
        )}
      </Spensor>{" "}
      <TokenName token={readResource(info.exchangeToken)} />{" "}
    </span>
  )
}
