import clsx from "clsx"
import { FC, ReactElement } from "react"
import { ProgressBar } from "../../../../../components/ProgressBar"
import { AbsolutePositionPointContainer } from "./AbsolutePositionPointContainer"
import {
  arrivedTextColorClassName,
  idleTextColorClassName,
  pointHeight,
} from "./consts"
import { CursorPointProps } from "./CursorPoint"

const trackHeight = 6

export const Track: FC<{
  className?: string
  percentage: number
  cursor?: ReactElement<CursorPointProps>
}> = props => {
  return (
    <div
      className={clsx("flex flex-col", props.className)}
      style={{ marginTop: pointHeight, marginBottom: pointHeight }}
    >
      <div
        style={{
          height: trackHeight,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <ProgressBar
          className={"w-full h-full"}
          bgColorTextClassName={idleTextColorClassName}
          fgColorTextClassName={arrivedTextColorClassName}
          progress={props.percentage}
        />
      </div>

      {props.cursor && (
        <div
          className={"w-full relative flex items-center transform"}
          style={
            {
              "--tw-translate-y": `${0 - trackHeight / 2}px`,
            } as any
          }
        >
          <AbsolutePositionPointContainer
            position={props.percentage}
            point={props.cursor}
          />
        </div>
      )}
    </div>
  )
}
