/**
 * This file defined all the ETH currencies that this App supported in any environment.
 */

import { values } from "lodash"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { assertExclude, assertNever, checkNever } from "../../utils/types"

export enum ETHCurrency {
  USDT = "usdt",
  LUNR = "lunr",
  USDC = "usdc",
  WBTC = "wbtc",
}

export function isETHCurrency(input: string): input is ETHCurrency {
  return values(ETHCurrency).includes(input as any)
}

export function tokenInfoFromETHCurrency(currency: ETHCurrency): TokenInfo {
  switch (currency) {
    case ETHCurrency.USDT:
      return TokenInfoPresets.USDT
    case ETHCurrency.LUNR:
      return TokenInfoPresets.LUNR
    case ETHCurrency.USDC:
      return TokenInfoPresets.USDC
    case ETHCurrency.WBTC:
      return TokenInfoPresets.WBTC
    default:
      assertNever(currency)
  }
}

export function tokenInfoToETHCurrency(
  info: TokenInfo,
): undefined | ETHCurrency {
  const restETHCurrency = assertExclude.i<ETHCurrency>()

  if (info.id === TokenInfoPresets.USDT.id) {
    return ETHCurrency.USDT
  }
  assertExclude(restETHCurrency, assertExclude.i<ETHCurrency.USDT>())

  if (info.id === TokenInfoPresets.LUNR.id) {
    return ETHCurrency.LUNR
  }
  assertExclude(restETHCurrency, assertExclude.i<ETHCurrency.LUNR>())

  if (info.id === TokenInfoPresets.USDC.id) {
    return ETHCurrency.USDC
  }
  assertExclude(restETHCurrency, assertExclude.i<ETHCurrency.USDC>())

  if (info.id === TokenInfoPresets.WBTC.id) {
    return ETHCurrency.WBTC
  }
  assertExclude(restETHCurrency, assertExclude.i<ETHCurrency.WBTC>())

  checkNever(restETHCurrency)
}
