import { action, computed, makeObservable, observable } from "mobx"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { OrderbookStore } from "../../Orderbook/store/OrderbookStore"
import { StxDxStore } from "../../Orderbook/store/stxdx_shared/StxDxStore"
import LaunchPadContractStore from "./LaunchPadContractStore"

export class LaunchPadRedeemModule {
  constructor(readonly store: LaunchPadContractStore) {
    makeObservable(this)
  }

  @computed get eligibleForRedeem$(): boolean {
    if (!this.store.tokenInfo$.propertyTags.includes("ordinals-voucher")) {
      return false
    }
    return this.balance$ > 0
  }

  @computed get balance$(): number {
    return this.store.accountStore.getBalance$(this.store.token$)
  }

  @observable.ref orderbookStore?: OrderbookStore

  @action showRedeemModal(): void {
    const stxdxStore = new StxDxStore(
      this.store.chainStore.appEnv,
      this.store.chainStore,
      this.store.authStore,
      this.store.currencyStore,
      this.store.accountStore,
    )
    const store = new OrderbookStore(
      this.store.chainStore,
      this.store.authStore,
      this.store.currencyStore,
      this.store.accountStore,
      stxdxStore,
    )
    store.brc20Pegging.pegOutForm.currency.set(this.store.token$ as any)
    // https://t.me/c/1599543687/34122
    store.brc20Pegging.pegOutForm.amount.set(
      this.store.token$ === Currency.ORDINALS_BLUEWHEEL
        ? Math.round(this.balance$ * 1e8)
        : this.balance$,
    )
    this.orderbookStore = store
  }

  @action hideRedeemModal(): void {
    this.orderbookStore = undefined
  }

  txStore = new ConfirmTransactionStore()
}
