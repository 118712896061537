import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { Spensor } from "../../../../../components/Spensor"
import { formatTimeCountdownText } from "../../../../../components/TimeCountdown/TimeCountdownText"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { readResource } from "../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { WrapBridgeNetwork } from "../../../types/types"
import { FeeTooltip } from "../../FeeTooltip"

export interface WrapBridgeConfirmationData {
  fromNetwork: WrapBridgeNetwork
  fromToken: TokenInfo
  fromAmount: number
  fromAddress: string

  toNetwork: WrapBridgeNetwork
  toToken: TokenInfo
  toAmount: number
  toAddress: string

  direction: "wrap" | "unwrap"
  toTokenAmountPerFromToken: number
  minFeeAmount: number
  feeRate: number
  fee: number
  feeToken: TokenInfo
  waitingBlocks: number
}

export interface WrapBridgeConfirmationProps
  extends WrapBridgeConfirmationData {
  onClose: () => void
  onConfirm: () => void
}

const AddressAndNetwork: FC<{
  title: string
  address: string
  network: WrapBridgeNetwork
}> = props => (
  <div className="flex flex-col gap-2 mb-2">
    <div className="text-gray-200 leading-6">{props.title}</div>
    <div className="bg-white bg-opacity-5 rounded text-xs leading-4 p-2.5">
      <div className="text-gray-200">{props.address}</div>
      <div className="text-gray-400">{props.network.name}</div>
    </div>
  </div>
)

export const WrapBridgeConfirmationFrame: FCC<{
  className?: string
  onClose: () => void
}> = props => {
  return (
    <CardModalContent
      className={props.className}
      title="Confirm"
      width={480}
      onClose={props.onClose}
    >
      {props.children}
    </CardModalContent>
  )
}

export const WrapBridgeConfirmation: FC<
  WrapBridgeConfirmationProps
> = props => {
  const { $t } = useIntl()
  return (
    <WrapBridgeConfirmationFrame onClose={props.onClose}>
      <BlockGroup
        icon={<BlockGroupDownArrowIcon />}
        firstBlock={
          <TokenBlock
            topArea={
              <AddressAndNetwork
                title={$t(
                  defineMessage({
                    defaultMessage: "From",
                    description: "/Bridge/WrapBridgeConfirmation/Adress title",
                  }),
                )}
                address={props.fromAddress}
                network={props.fromNetwork}
              />
            }
            tokens={[{ token: props.fromToken, count: props.fromAmount }]}
          />
        }
        secondBlock={
          <TokenBlock
            topArea={
              <AddressAndNetwork
                title={$t(
                  defineMessage({
                    defaultMessage: "To",
                    description: "/Bridge/WrapBridgeConfirmation/Adress title",
                  }),
                )}
                address={props.toAddress}
                network={props.toNetwork}
              />
            }
            tokens={[{ token: props.toToken, count: props.toAmount }]}
          />
        }
      />
      <CardPlate>
        <InfoList direction={"column"} listItemDirection={"row-responsive"}>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Price",
                  description:
                    "/Bridge/WrapBridgeConfirmation/Info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              1 <TokenName token={props.fromToken} /> ≈{" "}
              <TokenCount
                token={props.toToken}
                count={props.toTokenAmountPerFromToken}
              />{" "}
              <TokenName token={props.toToken} />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Fee {tip}",
                  description:
                    "/Bridge/WrapBridgeConfirmation/Info list item title",
                }),
                {
                  tip: (
                    <FeeTooltip
                      direction={props.direction}
                      sourceToken={props.fromToken}
                      targetToken={props.toToken}
                      feeToken={props.feeToken}
                      feeRate={props.feeRate}
                      minFeeAmount={props.minFeeAmount}
                    />
                  ),
                },
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <>
                <TokenCount token={props.feeToken} count={props.fee} />{" "}
                <TokenName token={props.feeToken} />
              </>
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Time to {direction, select, wrap {wrap} other {unwrap}} {tip}",
                  description:
                    "/Bridge/WrapBridgeConfirmation/Info list item title",
                }),
                {
                  direction: props.direction,
                  tip: (
                    <TipIcon
                      className="ml-1"
                      inline
                      tip={$t(
                        defineMessage({
                          defaultMessage:
                            "Estimated time required for {direction, select, wrap {wrap} other {unwrap}}",
                          description:
                            "/Bridge/WrapBridgeConfirmation/Info list item tooltip",
                        }),
                        { direction: props.direction },
                      )}
                    />
                  ),
                },
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor>
                {() => (
                  <>
                    {formatTimeCountdownText(
                      new Date(Date.now() + readResource(props.waitingBlocks)),
                    )}
                  </>
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardPlate>
      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Bridge/WrapBridgeConfirmation/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Bridge/WrapBridgeConfirmation/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </WrapBridgeConfirmationFrame>
  )
}
