import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card } from "../../../../../components/Card"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../components/Spensor"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { ReactComponent as Step1Icon } from "./step-1.svg"
import { ReactComponent as Step2Icon } from "./step-2.svg"
import { ReactComponent as Step3Icon } from "./step-3.svg"

export const HowToPlay: FC<{
  priceToken: SuspenseResource<TokenInfo>
  priceTokenCountPerTicket: SuspenseResource<number>
}> = props => {
  const { $t } = useIntl()
  return (
    <section className="w-full flex flex-col gap-y-2.5">
      <h1 className="text-xl leading-7 font-medium text-white">
        {$t(
          defineMessage({
            defaultMessage: "How to Play",
            description: "/Lottery/Rules/How to Play title",
          }),
        )}
      </h1>
      <ul className="grid grid-cols-3 gap-6 items-stretch">
        <StepCard className={"col-span-3 lg:col-span-1"}>
          <StepIndex>
            {$t(
              defineMessage({
                defaultMessage: "Step 1",
                description: "/Lottery/Rules/HowToPlay/Step text",
              }),
            )}
          </StepIndex>
          <Step1Icon />
          <StepContent>
            <StepTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Buy Tickets",
                  description: "/Lottery/Rules/HowToPlay/Step title",
                }),
              )}
            </StepTitle>
            <StepDescription>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Prices for tickets are fixed at {tokenCount} per ticket.",
                  description: "/Lottery/Rules/HowToPlay/Step description",
                }),
                {
                  tokenCount: (
                    <Spensor fallback={"-"}>
                      {() => (
                        <TextTokenCount
                          token={readResource(props.priceToken)}
                          count={readResource(props.priceTokenCountPerTicket)}
                        />
                      )}
                    </Spensor>
                  ),
                },
              )}
            </StepDescription>
          </StepContent>
        </StepCard>

        <StepCard className={"col-span-3 lg:col-span-1"}>
          <StepIndex>
            {$t(
              defineMessage({
                defaultMessage: "Step 2",
                description: "/Lottery/Rules/HowToPlay/Step text",
              }),
            )}
          </StepIndex>
          <Step2Icon />
          <StepContent>
            <StepTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Wait for the Drawing",
                  description: "/Lottery/Rules/HowToPlay/Step text",
                }),
              )}
            </StepTitle>
            <StepDescription>
              {$t(
                defineMessage({
                  defaultMessage:
                    "There is one drawing every cycle, the drawing will be done based upon a random block at the end of the cycle approaches",
                  description: "/Lottery/Rules/HowToPlay/Step text",
                }),
              )}
            </StepDescription>
          </StepContent>
        </StepCard>

        <StepCard className={"col-span-3 lg:col-span-1"}>
          <StepIndex>
            {$t(
              defineMessage({
                defaultMessage: "Step 3",
                description: "/Lottery/Rules/HowToPlay/Step text",
              }),
            )}
          </StepIndex>
          <Step3Icon />
          <StepContent>
            <StepTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Check for Prizes",
                  description: "/Lottery/Rules/HowToPlay/Step text",
                }),
              )}
            </StepTitle>
            <StepDescription>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Once the round is over, review the drawing page to check if you’ve won.",
                  description: "/Lottery/Rules/HowToPlay/Step text",
                }),
              )}
            </StepDescription>
          </StepContent>
        </StepCard>
      </ul>
    </section>
  )
}

const StepCard: FCC<{ className?: string }> = ({ className, children }) => (
  <li className={className}>
    <Card
      backgroundImage=""
      className="bg-gray-800 flex flex-col items-center justify-start h-full"
      roundClassName="rounded-2xl"
    >
      {children}
    </Card>
  </li>
)

const StepIndex = withClassName(
  "text-gray-400 text-base leading-6 font-normal self-start",
  "span",
)

const StepContent = withClassName(
  "flex flex-col gap-y-2.5 items-center pb-6",
  "div",
)

const StepTitle = withClassName(
  "text-base leading-6 font-semibold text-white",
  "h2",
)

const StepDescription = withClassName(
  "text-sm leading-5 font-normal text-gray-400 text-center",
  "p",
)
