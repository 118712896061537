import QRCodeModal from "@walletconnect/qrcode-modal"
import Client from "@walletconnect/sign-client"
import { SessionTypes } from "@walletconnect/types/dist/types/sign-client/session"
import { IS_MAIN_NET } from "../../config"
import AuthStore from "./AuthStore"

const LOCAL_STORAGE_KEY = "walletconnect_auto_session"

// @ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString()
}

export class WalletConnectAuthSession {
  static current?: WalletConnectAuthSession

  constructor(readonly store: AuthStore) {
    const cache = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    if (cache) {
      this.session = JSON.parse(cache)
    }
    WalletConnectAuthSession.current = this
  }

  provider = "wallet-connect" as const
  session?: SessionTypes.Struct

  get currentWalletAddress(): string | undefined {
    if (this.session) {
      return this.session.namespaces.stacks?.accounts[0]?.split(":")[2]
    }
    return undefined
  }

  get connectedToChain(): string | undefined {
    if (this.session) {
      return this.session.namespaces.stacks?.accounts[0]
        ?.split(":")
        .slice(0, 2)
        .join(":")
    }
    return undefined
  }

  signUserOut(): void {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY)
    this.session = undefined
    this.store.refreshAddress()
  }

  chain = IS_MAIN_NET ? "stacks:1" : "stacks:2147483648"

  #client?: Client
  async client(): Promise<Client> {
    if (this.#client) {
      return this.#client
    }
    const client = await Client.init({
      logger: "debug",
      relayUrl: "wss://relay.walletconnect.com",
      projectId: "7b6768711c236b88d283cf0184dd91a7",
      metadata: {
        name: "ALEX",
        description: "The complete #BitFi via @Stacks.",
        url: "https://app.alexlab.co",
        icons: ["https://cdn.alexlab.co/logos/ALEX_Token.png"],
      },
    })
    this.#client = client
    return client
  }

  async connect(): Promise<void> {
    const client = await this.client()
    const { uri, approval } = await client.connect({
      pairingTopic: undefined,
      requiredNamespaces: {
        stacks: {
          methods: [
            "stacks_signMessage",
            "stacks_stxTransfer",
            "stacks_contractCall",
            "stacks_contractDeploy",
          ],
          chains: [this.chain],
          events: [],
        },
      },
    })
    if (uri) {
      QRCodeModal.open(uri, () => {
        console.log("QR Code Modal closed")
      })
    }
    const session = await approval()
    QRCodeModal.close()
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session))
    this.session = session
    this.store.refreshAddress()
  }
}
