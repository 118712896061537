import { createContext, useContext } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"

export interface ThemeSpacing {
  spacing(spacing: never): void
}

export type AnySpacingDefinition = Readonly<Record<number | string, number>>

const SpacingContext = createContext<null | AnySpacingDefinition>(null)

export const SpacingProvider: FCC<{
  spacings: AnySpacingDefinition
}> = props => {
  return (
    <SpacingContext.Provider value={props.spacings}>
      {props.children}
    </SpacingContext.Provider>
  )
}

export type SpacingGetter = (
  spacing: Parameters<ThemeSpacing["spacing"]>[0],
) => number

export const useSpacing = (): SpacingGetter => {
  const spacingMap = useContext(SpacingContext)

  if (spacingMap == null) {
    throw new Error("SpacingProvider not found")
  }

  return usePersistFn(spacing => {
    if (spacingMap[spacing] == null) {
      throw new Error(`Spacing "${spacing}" not defined`)
    }

    return spacingMap[spacing]!
  })
}
