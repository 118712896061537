import { memoize, sum } from "lodash"
import { computed, makeObservable } from "mobx"
import { computedFn } from "mobx-utils"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { Currency } from "../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { AddBorrowModule } from "./AddBorrowModule"
import { AddDepositModule } from "./AddDepositModule"
import type { TokenWithIdBalance } from "./LendStore.service"
import {
  CRPPoolDetail,
  crpPoolDetail,
  currentLTV,
  expiryCorrespondingPoolExist,
  fetchBorrowChartData,
  fetchDepositChartData,
  fetchYieldTokenDetails,
  getExpiry,
  TokenWithId,
  YieldTokenPool,
} from "./LendStore.service"
import MyBorrowModule from "./MyBorrowModule"
import { MyDepositModule } from "./MyDepositModule"

class LendStore {
  constructor(
    readonly poolToken: YieldTokenPool,
    readonly chainStore: Pick<ChainStore, "currentBlockHash$">,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "isWalletConnected">,
    readonly accountStore: Pick<AccountStore, "getBalance$">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$" | "getTokenInfo$">,
  ) {
    makeObservable(this)
  }

  underlyingToken = Currency.ALEX as const
  collateralToken = Currency.ATALEX as const
  yieldToken = Currency.YIELD_ALEX as const
  keyToken = Currency.KEY_ALEX_AUTOALEX as const

  addDeposit = new AddDepositModule(this)
  myDeposit = new MyDepositModule(this)
  addBorrow = new AddBorrowModule(this)
  myBorrow = new MyBorrowModule(this)

  poolDetail = new LazyValue(
    () => this.poolToken,
    poolToken => crpPoolDetail(poolToken),
  )

  @computed get underlyingTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.underlyingToken)
  }

  @computed get collateralTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.collateralToken)
  }

  @computed get poolTotalBorrowed$(): number {
    return this.poolDetail.value$.totalBorrow
  }

  @computed get poolTotalDeposit$(): number {
    return this.poolDetail.value$.totalDeposit
  }

  @computed get depositAPR$(): number {
    return this.poolDetail.value$.depositApr
  }

  @computed get borrowAPR$(): number {
    return this.poolDetail.value$.borrowApr
  }

  borrowChartData = new LazyValue(
    () =>
      [
        this.poolToken,
        this.collateralTokenInfo$,
        this.underlyingTokenInfo$,
      ] as const,
    fetchBorrowChartData,
  )

  depositChartData = new LazyValue(
    () => [this.poolToken] as const,
    fetchDepositChartData,
  )

  @computed get currentExpiryDetail$(): CRPPoolDetail | undefined {
    const details = this.poolDetail.value$.details[this.currentExpiry$]
    if (details == null) {
      return undefined
    }
    return details
  }

  private ltv = new LazyValue(
    () => [this.currentExpiry$, this.poolToken] as const,
    currentLTV,
  )

  @computed get ltv$(): number {
    if (!this.currentExpiryExist$) {
      return 0
    }
    return this.ltv.value$
  }

  private balanceAmount = memoize(
    (tokenWithId: TokenWithId) =>
      new LazyValue(
        () =>
          [
            tokenWithId,
            this.authStore.stxAddress$,
            this.chainStore.currentBlockHash$,
          ] as const,
        ([token, address]) => fetchYieldTokenDetails(address, token),
        {
          decorator: pMemoizeDecorator({ persistKey: "crp-balance-breakdown" }),
        },
      ),
  )

  private expire = new LazyValue(
    () => [this.poolToken, this.chainStore.currentBlockHash$] as const,
    ([poolToken]) => getExpiry(poolToken),
    {
      decorator: pMemoizeDecorator({
        persistKey: "crp-current-expiry",
      }),
    },
  )

  @computed get currentExpiry$(): number {
    return this.expire.value$
  }

  private _currentExpiryExist = new LazyValue(
    () =>
      [
        this.currentExpiry$,
        this.underlyingToken,
        this.collateralToken,
      ] as const,
    ([e, t, c]) => expiryCorrespondingPoolExist(e, t, c),
  )

  @computed get currentExpiryExist$(): boolean {
    return this._currentExpiryExist.value$ && this.currentExpiryDetail$ != null
  }

  @computed get yieldTokenAmount$(): TokenWithIdBalance {
    return this.balanceAmount(this.yieldToken).value$
  }

  @computed get claimableYieldToken$(): number {
    return sum(
      this.yieldTokenAmount$
        .filter(s => s.expiry < this.currentExpiry$)
        .map(s => s.amount),
    )
  }

  @computed get yieldTokenBalance$(): number {
    return sum(this.yieldTokenAmount$.map(t => t.amount))
  }

  @computed get keyTokenAmount$(): TokenWithIdBalance {
    return this.balanceAmount(this.keyToken).value$
  }

  @computed get keyTokenBalance$(): number {
    return sum(this.keyTokenAmount$.map(t => t.amount))
  }

  ytpLiquidityFeeCalc = computedFn((from: "yield" | "underlying", amount) => {
    if (this.currentExpiryDetail$ == null) {
      return 0
    }
    const { minFee, yieldPerToken, feeRateYieldToken, feeRateToken } =
      this.currentExpiryDetail$
    return Math.max(
      minFee,
      amount *
        yieldPerToken *
        (from === "yield" ? feeRateYieldToken : feeRateToken),
    )
  })
}

export default LendStore
