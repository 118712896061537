import {
  CSSProperties,
  FC,
  ReactNode,
  Ref,
  useLayoutEffect,
  useRef,
} from "react"
import { IntlShape, defineMessage } from "react-intl"
import { getErrorMessage } from "../utils/error"
import { usePersistFn } from "../utils/reactHelpers/usePersistFn"
import {
  ErrorBoundary,
  ErrorBoundaryFallbackRenderProps,
  ErrorBoundaryProps,
} from "./ErrorBoundary"
import { ColorGetter } from "./Themed/color"
import { TipIcon } from "./TipIcon/TipIcon"
import { Button } from "./button/Button"
import { LinkStyleButton } from "./button/variants/LinkStyleButton"

export interface MaskErrorBoundaryChildrenRenderProps {
  containerDomRef: Ref<HTMLElement>
}

export interface MaskErrorBoundaryFallbackContextInfo {
  hasSnapshot: boolean
}

export type MaskErrorBoundaryFallbackRender = (
  renderProps: ErrorBoundaryFallbackRenderProps,
  contextInfo: MaskErrorBoundaryFallbackContextInfo,
) => ReactNode

export interface MaskErrorBoundaryProps
  extends Omit<ErrorBoundaryProps, "fallback" | "children"> {
  fallbackContainerStyle?: CSSProperties
  fallback: MaskErrorBoundaryFallbackRender
  children: (renderProps: MaskErrorBoundaryChildrenRenderProps) => ReactNode
}

export const MaskErrorBoundary: FC<MaskErrorBoundaryProps> = props => {
  const containerRef = useRef<null | HTMLElement>(null)
  const previousSnapshottedHTMLRef = useRef<null | string>(null)

  const fallback = usePersistFn(
    (renderProps: ErrorBoundaryFallbackRenderProps): ReactNode => {
      if (previousSnapshottedHTMLRef.current == null) {
        return (
          <div style={props.fallbackContainerStyle}>
            {props.fallback(renderProps, { hasSnapshot: false })}
          </div>
        )
      }

      return (
        <div className={"relative"}>
          <div
            className={"opacity-50"}
            dangerouslySetInnerHTML={{
              __html: previousSnapshottedHTMLRef.current,
            }}
          />

          <div
            className={"absolute inset-0"}
            style={props.fallbackContainerStyle}
          >
            {props.fallback(renderProps, { hasSnapshot: true })}
          </div>
        </div>
      )
    },
  )

  useLayoutEffect(() => {
    if (containerRef.current) {
      previousSnapshottedHTMLRef.current = containerRef.current.outerHTML
    } else {
      previousSnapshottedHTMLRef.current = null
    }
  })

  return (
    <ErrorBoundary {...props} fallback={fallback}>
      {props.children({ containerDomRef: containerRef })}
    </ErrorBoundary>
  )
}

export const defaultMaskErrorBoundaryFallbackFactory: (context: {
  intl: IntlShape
  colors: ColorGetter
  retryPolicy?: "reloadComponent" | "reloadPage"
}) => MaskErrorBoundaryFallbackRender =
  ({ intl: { $t }, colors, retryPolicy }) =>
  p =>
    (
      <div className="w-full h-full flex items-center justify-center text-red-500">
        <TipIcon
          className={"max-w-full max-h-full"}
          color={colors("red-500")}
          tip={
            <div>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Error{errorMessage, select, empty {} other {: {errorMessage}}}, <reloadButton>reload</reloadButton>",
                  description: "/Bridge/Detail/error message",
                }),
                {
                  errorMessage: getErrorMessage(p.error) ?? "empty",
                  reloadButton: (content: ReactNode) => (
                    <Button
                      Variant={LinkStyleButton}
                      onClick={
                        retryPolicy === "reloadPage"
                          ? () => window.location.reload()
                          : p.retry
                      }
                    >
                      {content}
                    </Button>
                  ),
                },
              )}
            </div>
          }
        />
      </div>
    )
