import copy from "copy-to-clipboard"
import { FC, ReactNode } from "react"
import { IntlShape, defineMessage, useIntl } from "react-intl"
import { copied$t } from "../../commonIntlMessages"
import { dontWrapObserver } from "../../utils/mobxHelpers"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { assertNever } from "../../utils/types"
import { CardInset, CardPlate, CardSubTitle } from "../Card"
import { CardModalContent, CardModalContentProps } from "../CardModal/CardModal"
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator"
import { NavLink } from "../NavLink"
import { NoteParagraph } from "../NoteParagraph/NoteParagraph"
import { Button } from "../button/Button"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { OpacityButton } from "../button/variants/OpacityButton"
import { useMessage } from "../message/MessageProvider"
import { ReactComponent as BroadcastedIcon } from "./broadcasted.svg"
import { ReactComponent as ConfirmIcon } from "./confirm.svg"
import { ReactComponent as ExplorerIcon } from "./explorer.svg"
import { ReactComponent as FailIcon } from "./fail.svg"
import { ReactComponent as SubscribeIcon } from "./subscribe.svg"
import { ReactComponent as TickIcon } from "./tick.svg"

export type TransactionStateMessageRenderer = (ctx: {
  intl: IntlShape
}) => string

export type TransactionStateForGeneral =
  | { type: "running"; msg?: TransactionStateMessageRenderer }
  | {
      type: "success"
      explorerLink?: string
      msg?: TransactionStateMessageRenderer
    }
  | {
      type: "error"
      error: TransactionStateMessageRenderer
      explorerLink?: string
    }

export const TransactionStateDialogContentForGeneral: FC<{
  transactionState: TransactionStateForGeneral
  onClose: () => void
}> = props => {
  const intl = useIntl()
  const state = props.transactionState
  switch (state.type) {
    case "running":
      return (
        <TransactionStateDialog$ConfirmTransaction
          onClose={props.onClose}
          msg={state.msg?.({ intl })}
        />
      )
    case "success":
      return (
        <TransactionStateDialog$BroadcastedForGeneral
          explorerLink={state.explorerLink}
          infoText={state.msg?.({ intl })}
          onClose={props.onClose}
        />
      )
    case "error":
      return (
        <TransactionStateDialog$Failed
          details={state.error?.({ intl })}
          explorerLink={state.explorerLink}
          onClose={props.onClose}
        />
      )
    default:
      assertNever(state)
  }
}

export type TransactionStateForStacks =
  | { type: "running"; msg?: TransactionStateMessageRenderer }
  | { type: "success"; explorerLink: string; telegramSubscribeLink?: string }
  | {
      type: "error"
      error: TransactionStateMessageRenderer
      explorerLink?: string
    }
export const TransactionStateDialogContentForStacks: FC<{
  transactionState: TransactionStateForStacks
  onClose: () => void
}> = props => {
  const intl = useIntl()
  const state = props.transactionState
  switch (state.type) {
    case "running":
      return (
        <TransactionStateDialog$ConfirmTransaction
          onClose={props.onClose}
          msg={state.msg?.({ intl })}
        />
      )
    case "success":
      return (
        <TransactionStateDialog$BroadcastedForStacks
          explorerLink={state.explorerLink}
          telegramSubscribeLink={state.telegramSubscribeLink}
          onClose={props.onClose}
        />
      )
    case "error":
      return (
        <TransactionStateDialog$Failed
          message={state.error?.({ intl })}
          explorerLink={state.explorerLink}
          onClose={props.onClose}
        />
      )
    default:
      assertNever(state)
  }
}

const ModalContentContainer = withClassName(
  "items-center",
  (props: CardModalContentProps) => <CardModalContent {...props} width={480} />,
)

export const TransactionStateDialog$ConfirmTransaction: FC<{
  msg?: string
  onClose?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <ModalContentContainer
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Transaction",
          description:
            "/Components/TransactionStateDialog/ConfirmTransaction/Title",
        }),
      )}
      onClose={props.onClose}
    >
      <div className={"flex p-6"}>
        <LoadingIndicator />
      </div>
      <CardSubTitle className={"text-center"}>
        {props.msg ?? (
          <>
            {$t<ReactNode>(
              defineMessage({
                defaultMessage:
                  "<line>Please confirm the transaction</line><line>in your wallet app...</line>",
                description:
                  "/Components/TransactionStateDialog/ConfirmTransaction/SubTitle",
              }),
              {
                line: dontWrapObserver(children => <p>{children}</p>),
              },
            )}
          </>
        )}
      </CardSubTitle>
    </ModalContentContainer>
  )
}

export const TransactionStateDialog$BroadcastedForStacks: FC<{
  explorerLink: string
  telegramSubscribeLink?: string
  onClose: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <ModalContentContainer
      onClose={props.onClose}
      title={
        <div className="flex flex-row items-center justify-center gap-x-2.5">
          <TickIcon />
          <span>
            {$t(
              defineMessage({
                defaultMessage: "Transaction broadcasted.",
                description:
                  "/Components/TransactionStateDialog/Broadcasted/Title",
              }),
            )}
          </span>
        </div>
      }
    >
      <div className="flex flex-col items-center gap-y-4">
        <p className="flex flex-row items-center justify-center gap-x-2.5">
          <ConfirmIcon />
          <span className="text-3xl leading-9 font-light text-gray-200">
            {$t(
              defineMessage({
                defaultMessage: "10-30mins",
                description:
                  "/Components/TransactionStateDialog/Broadcasted/Time",
              }),
            )}
          </span>
        </p>
        <p className="text-sm leading-5 font-normal text-gray-400 text-center">
          {$t(
            defineMessage({
              defaultMessage:
                "This Transaction takes at least <span>1 Stacks block</span> to confirm, that is approximately around <span>10-30mins</span>.",
              description:
                "/Components/TransactionStateDialog/Broadcasted/Desc",
            }),
            {
              span: dontWrapObserver(children => (
                <em className="text-green-500 font-bold not-italic">
                  {children}
                </em>
              )),
            },
          )}
        </p>
        <p className={"text-sm leading-5 font-normal text-center"}>
          <ViewInExplorerLink explorerLink={props.explorerLink} />
        </p>
        {props.telegramSubscribeLink != null && (
          <p className={"text-sm leading-5 font-normal text-center"}>
            <TelegramSubscribeLink
              subscribeLink={props.telegramSubscribeLink}
            />
          </p>
        )}
      </div>

      <GradientFilledButton
        className={"block w-full mt-4"}
        onClick={props.onClose}
      >
        {$t(
          defineMessage({
            defaultMessage: "OK",
            description:
              "/Components/TransactionStateDialog/Broadcasted/Button Text",
          }),
        )}
      </GradientFilledButton>
    </ModalContentContainer>
  )
}

export const TransactionStateDialog$BroadcastedForGeneral: FC<{
  explorerLink?: string
  infoText?: ReactNode
  onClose: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <ModalContentContainer onClose={props.onClose}>
      <BroadcastedIcon />

      <div>
        <CardSubTitle>
          {$t(
            defineMessage({
              defaultMessage: "Transaction broadcasted.",
              description:
                "/Components/TransactionStateDialog/BroadcastedForGeneral/Subtitle",
            }),
          )}
        </CardSubTitle>
        {props.explorerLink != null && (
          <p className={"mt-2.5 text-sm leading-5 font-normal text-center"}>
            <ViewInExplorerLink explorerLink={props.explorerLink} />
          </p>
        )}
      </div>

      {props.infoText != null && (
        <CardInset boxClassName={"px-2.5 py-5"}>
          <NoteParagraph>{props.infoText}</NoteParagraph>
        </CardInset>
      )}

      <GradientFilledButton
        className={"block w-full mt-[30px]"}
        onClick={props.onClose}
      >
        {$t(
          defineMessage({
            defaultMessage: "OK",
            description:
              "/Components/TransactionStateDialog/BroadcastedForGeneral/Button Text",
          }),
        )}
      </GradientFilledButton>
    </ModalContentContainer>
  )
}

export const TransactionStateDialog$Failed: FC<{
  message?: string
  details?: string
  explorerLink?: string
  onClose?: () => void
}> = props => {
  const { $t } = useIntl()

  const message = useMessage()

  return (
    <ModalContentContainer className={"min-h-[328px]"} onClose={props.onClose}>
      <FailIcon />
      <div>
        <CardSubTitle className={"text-center"}>
          {props.message ??
            $t(
              defineMessage({
                defaultMessage: "Transaction failed.",
                description:
                  "/Components/TransactionStateDialog/Failed/Subtitle",
              }),
            )}
        </CardSubTitle>
        {!!props.details && (
          <CardPlate
            className={"mt-2.5 max-h-[100px] overflow-y-auto break-all"}
            boxClassName={"px-3 py-1"}
          >
            {props.details}
          </CardPlate>
        )}
        {props.explorerLink != null && (
          <p
            className={
              "mt-2.5 text-sm leading-5 font-normal text-gray-100 text-center"
            }
          >
            <ViewInExplorerLink explorerLink={props.explorerLink} />
          </p>
        )}
      </div>
      <div className={"flex flex-col items-stretch w-full mt-auto gap-y-2"}>
        <GradientFilledButton onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "OK",
              description:
                "/Components/TransactionStateDialog/Failed/Button Text",
            }),
          )}
        </GradientFilledButton>

        {!!props.details && (
          <Button
            Variant={OpacityButton}
            onClick={() => {
              if (copy(props.details!)) {
                message.show({ message: $t(copied$t) })
              }
            }}
          >
            {$t(
              defineMessage({
                defaultMessage: "Copy Details",
                description:
                  "/Components/TransactionStateDialog/Failed/Button Text",
              }),
            )}
          </Button>
        )}
      </div>
    </ModalContentContainer>
  )
}

const ViewInExplorerLink: FC<{ explorerLink: string }> = props => {
  const { $t } = useIntl()
  return (
    <NavLink
      className={"text-blue-600"}
      target={"_blank"}
      to={props.explorerLink}
    >
      <ExplorerIcon className={"inline mr-2"} />
      {$t(
        defineMessage({
          defaultMessage: "View in explorer",
          description:
            "/Components/TransactionStateDialog/ViewInExplorerLink/Nav Text",
        }),
      )}
    </NavLink>
  )
}

const TelegramSubscribeLink: FC<{ subscribeLink: string }> = props => {
  const { $t } = useIntl()
  return (
    <NavLink
      className={"text-blue-600"}
      target={"_blank"}
      to={props.subscribeLink}
    >
      <SubscribeIcon className={"inline mr-2"} />
      {$t(
        defineMessage({
          defaultMessage: "Turn on TG Notification",
          description:
            "/Components/TransactionStateDialog/TelegramSubscribeLink/Nav Text",
        }),
      )}
    </NavLink>
  )
}
