export enum DepositBrc20SubmitTransactionIdModalFormErrorType {
  AmountIsRequired = "AmountIsRequired",
  TokenIsRequired = "TokenIsRequired",
  InscriptionNumberIsRequired = "InscriptionNumberIsRequired",
  InscriptionNotTransferable = "InscriptionNotTransferable",
}

export interface DepositBrc20SubmitTransactionIdModalFormError {
  type: DepositBrc20SubmitTransactionIdModalFormErrorType
}
