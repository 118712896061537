import { FC } from "react"
import { Divider } from "../../../../components/Divider"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { SuspenseResource } from "../../../../utils/SuspenseResource"
import { HowToPlay } from "./HowToPlay/HowToPlay"
import { PrizeFunds } from "./PrizeFunds/PrizeFunds"
import { WinningCriteria } from "./WinningCriteria/WinningCriteria"

export const RulesTabContent: FC<{
  priceToken: SuspenseResource<TokenInfo>
  priceTokenCountPerTicket: SuspenseResource<number>
}> = props => (
  <div className="w-full flex flex-col gap-y-10">
    <HowToPlay
      priceToken={props.priceToken}
      priceTokenCountPerTicket={props.priceTokenCountPerTicket}
    />

    <Divider className="border-gray-500/30" />

    <WinningCriteria />

    <Divider className="border-gray-500/30" />

    <PrizeFunds />
  </div>
)
