import { hc } from "hono/client"
import { isPlainObject } from "lodash-es"
import { defer, Observable, switchMap } from "rxjs"
import type { ApiType } from "../../../../../../functions/_api/[[api]]"
import { CORS_PROXY_PREFIX, FRONTEND_API_HOST } from "../../../../../config"
import { DisplayableError } from "../../../../../utils/error"
import { BitcoinNetwork } from "./BitcoinClient.types"

export class OklinkRequestError extends Error {
  constructor(public code: string, public msg: string) {
    super(msg)

    this.message = msg
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const honoClient = () => hc<ApiType>(FRONTEND_API_HOST)

export function fetchOklink<T>(options: {
  path: string
  network: BitcoinNetwork
  query?: Record<string, string | number>
}): Observable<{
  code: "0" | string
  msg: string
  data: T
}> {
  const queryPairs = Object.entries(options.query ?? {})
  const querystring = new URLSearchParams(queryPairs as string[][]).toString()

  return defer(() =>
    honoClient()._api.oklink.$get({
      query: {
        path: options.path.replace(/^\//, ""),
        querystring,
      },
    }),
  ).pipe(
    switchMap(resp => resp.json()),
    switchMap(async res => {
      const _res = res as { code: "0" | string; msg: string; data: T }

      if (_res.code !== "0") {
        throw new OklinkRequestError(_res.code, _res.msg)
      }

      return _res
    }),
  )
}

export const fetchHiroOrdinalsAPI = <T>(options: {
  path: string
  network: BitcoinNetwork
  query?: Record<string, undefined | string | number>
}): Observable<T> => {
  const queryPairs = Object.entries(options.query ?? {}).filter(
    p => p[1] !== undefined,
  )
  const querystring = new URLSearchParams(queryPairs as string[][]).toString()

  return defer(() =>
    fetch(
      [
        "https://api.hiro.so/ordinals/",
        options.path.replace(/^\//, ""),
        querystring ? "?" + querystring : "",
      ].join(""),
    ),
  ).pipe(switchMap(resp => resp.json()))
}

export const fetchBestInSlotLegacy = <T>(options: {
  path: string
  network: BitcoinNetwork
  query?: Record<string, string | number>
}): Observable<T> => {
  const queryPairs = Object.entries(options.query ?? {})
  const querystring = new URLSearchParams(queryPairs as string[][]).toString()

  const prefix = `${CORS_PROXY_PREFIX}https://ordapi.bestinslot.xyz/`

  return defer(() =>
    fetch(
      [
        prefix,
        options.path.replace(/^\//, ""),
        querystring ? "?" + querystring : "",
      ].join(""),
    ),
  ).pipe(switchMap(resp => resp.json()))
}

export const fetchBestInSlot = <T>(options: {
  path: string
  network: BitcoinNetwork
  query?: Record<string, string | number>
}): Observable<{
  data: T
  block_height: number
}> => {
  const queryPairs = Object.entries(options.query ?? {})
  const querystring = new URLSearchParams(queryPairs as string[][]).toString()

  return defer(() =>
    honoClient()._api.bestinslot.$get({
      query: {
        path: options.path,
        querystring,
      },
    }),
  ).pipe(switchMap(resp => resp.json() as Promise<any>))
}
export const getMempoolAPIPrefix = (network: BitcoinNetwork): string => {
  return `mempool.space${network === "mainnet" ? "" : `/${network}`}/api/`
}

export const mempoolFetch = <T>(options: {
  network: BitcoinNetwork
  method?: "get" | "post"
  path: string
  headers?: Record<string, string | number>
  query?: Record<string, string | number>
  body?: any
  corsProxy?: boolean
  parseResponse?: (resp: Response) => Promise<T>
}): Observable<T> => {
  const {
    method: opMethod = "get",
    query: opQuery = {},
    body: opBody,
    corsProxy = false,
    parseResponse: opParseResponse = resp => resp.json(),
  } = options

  const queryPairs = Object.entries(opQuery ?? {})
  const querystring = new URLSearchParams(queryPairs as string[][]).toString()

  const headers = new Headers(options.headers as Record<string, string>)
  if (
    opMethod === "post" &&
    isPlainObject(opBody) &&
    !headers.has("content-type")
  ) {
    headers.set("content-type", "application/json")
  }

  const requestUrl =
    "https://" +
    getMempoolAPIPrefix(options.network) +
    options.path.replace(/^\//, "") +
    querystring

  return defer(() =>
    fetch(corsProxy ? `${CORS_PROXY_PREFIX}${requestUrl}` : requestUrl, {
      method: opMethod,
      headers: headers,
      body:
        headers.get("content-type")?.toLowerCase() === "application/json"
          ? JSON.stringify(opBody)
          : opBody,
    }),
  ).pipe(
    switchMap(async resp => {
      if (resp.status < 200 || resp.status >= 300) {
        throw new MempoolRequestError(await resp.text())
      }
      return resp
    }),
    switchMap(opParseResponse),
  )
}

export class MempoolRequestError extends DisplayableError {
  constructor(data: any) {
    super("Request mempool.space failed: " + JSON.stringify(data), {
      cause: data,
    })
  }
}
