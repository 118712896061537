import clsx from "clsx"
import { ComponentType, SVGProps } from "react"
import { HeadlessButton } from "./HeadlessButton"
import styles from "./RoundedFlatButton.module.scss"

export interface RoundedFlatButtonProps<T> {
  className?: string
  Svg: ComponentType<Pick<SVGProps<T>, "width" | "height">>
  svgProps?: SVGProps<T>
  /**
   * tailwind size, but >= 3 is valid
   *
   * @default 6
   */
  size?: number
  activated?: boolean
  disabled?: boolean
  onClick?: () => void
}

/**
 * This component take an svg icon and return a button.
 *
 * The icon size will be exactly 2/3 of the button size by design.
 */
export function RoundedFlatButton<T>(
  props: RoundedFlatButtonProps<T>,
): JSX.Element {
  const realSize = props.size && props.size >= 3 ? props.size : 6
  const pixelSize = realSize * 4
  const iconPixelSize = (pixelSize * 2) / 3

  return (
    <HeadlessButton
      className={clsx(
        "inline-flex items-center justify-center rounded-full bg-opacity-5",
        props.activated ? "bg-black" : "bg-white",
        props.activated && styles.activated,
        "hover:bg-black hover:bg-opacity-10",
        "active:bg-black active:bg-opacity-20",
        props.className,
      )}
      style={{
        // tailwind cannot use dynamic classname
        width: pixelSize,
        height: pixelSize,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <props.Svg
        width={iconPixelSize}
        height={iconPixelSize}
        {...(props.svgProps ?? {})}
      />
    </HeadlessButton>
  )
}
