import { DepositClaimRowData } from "../../store/transformerGroups/lend/depositClaim"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const depositClaimExporter: Exporter<DepositClaimRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = rowData.claimCurrencyAmounts
    .map(c =>
      parseAmount({
        fromCurrencyAmounts: [c.from],
        toCurrencyAmounts: [c.to],
        allTokenInfos,
      }),
    )
    .join("\n")
  const tradeInfo = rowData.expiries.map(e => `#${e}`).join("\n")
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
