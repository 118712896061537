import { computed, makeObservable } from "mobx"
import {
  asSender,
  contractAddr,
} from "../../generated/smartContractHelpers/asSender"
import { Currency } from "../../utils/alexjs/Currency"
import { transfer } from "../../utils/alexjs/postConditions"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { props } from "../../utils/promiseHelpers"
import { waitUntilExist$ } from "../../utils/waitFor"
import { LazyValue } from "../LazyValue/LazyValue"
import { AppEnvStore } from "../appEnvStore/AppEnvStore"
import AuthStore from "../authStore/AuthStore"
import { ConfirmTransactionStore } from "../confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../currencyStore/CurrencyStore"

export class TestnetRewardsClaimStore {
  constructor(
    readonly authStore: AuthStore,
    readonly currencyStore: CurrencyStore,
    readonly appEnv: AppEnvStore,
  ) {
    makeObservable(this)
  }

  @computed get eventId$(): number {
    return waitUntilExist$(() => this.appEnv.config$.testnetRewardBanner)
  }

  #info = new LazyValue(
    () => ({ stxAddr: this.authStore.stxAddress$, eventId: this.eventId$ }),
    ({ eventId, stxAddr }) =>
      props({
        claimable: asSender(stxAddr)
          .contract("event-claim-helper-v1-01")
          .func("get-claim-or-default")
          .call({ claimer: stxAddr, "event-id": eventId })
          .then(a => a / 1e8),
        claimed: asSender(stxAddr)
          .contract("event-claim-helper-v1-01")
          .func("get-claimed-or-default")
          .call({ claimer: stxAddr, "event-id": eventId }),
        eventDetail: asSender(stxAddr)
          .contract("event-claim-helper-v1-01")
          .func("get-event-details-or-fail")
          .call({ "event-id": eventId }),
      }),
  )

  @computed get enabled$(): boolean {
    if (this.appEnv.config$.testnetRewardBanner == null) {
      return false
    }
    const { claimed, eventDetail, claimable } = this.#info.value$
    if (claimed) {
      return false
    }
    if (eventDetail.type === "error") {
      return false
    }
    const startTime = eventDetail.value["start-timestamp"]
    const endTime = eventDetail.value["end-timestamp"]
    const now = Date.now() / 1000
    if (now < startTime || now > endTime) {
      return false
    }
    return claimable > 0
  }

  @computed get claimable$(): number {
    return this.#info.value$.claimable
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @computed get detail$() {
    return waitUntilExist$(() => {
      const e = this.#info.value$.eventDetail
      if (e.type === "error") {
        return null
      }
      return e.value
    })
  }

  @computed get endTime$(): number {
    return this.detail$["end-timestamp"]
  }

  @computed get token$(): Currency {
    return this.detail$.token as Currency
  }

  @computed get tokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.token$)
  }

  claimRunning = new ConfirmTransactionStore()

  async claim(): Promise<void> {
    await this.claimRunning.run(() => {
      const stxAddr = this.authStore.stxAddress$
      return asSender(stxAddr)
        .contract("event-claim-helper-v1-01")
        .func("claim-for-claimer")
        .call(
          {
            claimer: stxAddr,
            "event-id": this.eventId$,
            "token-trait": this.token$,
          },
          [
            transfer(
              contractAddr("event-claim-helper-v1-01"),
              this.token$,
              this.claimable$,
            ),
          ],
        )
    })
  }
}
