import { createContext, useContext } from "react"
import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { LiquidityPoolToken } from "../../../../utils/alexjs/currencyHelpers"
import { FCC } from "../../../../utils/reactHelpers/types"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import PoolDetailStore from "./PoolDetailStore"

const PoolDetailStoreContext = createContext<PoolDetailStore | null>(null)

export const PoolDetailStoreProvider: FCC<{
  token: LiquidityPoolToken
}> = props => {
  const appEnvStore = useAppEnvStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(
    () =>
      new PoolDetailStore(
        props.token,
        appEnvStore,
        authStore,
        accountStore,
        currencyStore,
      ),
    [props.token, appEnvStore, authStore, accountStore, currencyStore],
  )

  return (
    <PoolDetailStoreContext.Provider value={store}>
      {props.children}
    </PoolDetailStoreContext.Provider>
  )
}

export const usePoolDetailStore = (): PoolDetailStore => {
  const store = useContext(PoolDetailStoreContext)
  if (store == null) {
    throw new Error(
      `usePoolDetailStore must be used within a PoolDetailStoreContext`,
    )
  }
  return store
}
