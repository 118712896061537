import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { accept$t, cancel$t } from "../../../../commonIntlMessages"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardInset } from "../../../../components/Card"
import {
  ActionRow,
  CardModalContent,
} from "../../../../components/CardModal/CardModal"
import { Checkbox } from "../../../../components/Checkbox"
import { ScopedLoadingBoundary } from "../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { MarkdownContent } from "../../../../components/MarkdownContent/MarkdownContent"
import { RenderChildrenFn } from "../../../../components/Spensor"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import styles from "./AcknowledgeTermsModalContent.module.scss"

export interface AcknowledgeTermsModalContentProps {
  contentMarkdown: SuspenseResource<string>
  onAccept?: () => void
  onCancel?: () => void
  onClose?: () => void
}

export const AcknowledgeTermsModalContent: FC<
  AcknowledgeTermsModalContentProps
> = props => {
  const { $t } = useIntl()

  const [isRead, setIsRead] = useState(false)

  return (
    <CardModalContent
      layoutClassName={"flex flex-col"}
      gapClassName={"gap-6"}
      title={"Terms and Conditions"}
      width={640}
      height={530}
    >
      <CardInset
        className={
          "min-h-[300px] flex-1 text-xs leading-4 font-normal text-gray-400 overflow-auto"
        }
        boxClassName={""}
      >
        <ScopedLoadingBoundary
          className={"min-w-full min-h-full p-4"}
          loadingIndicator={<LoadingIndicator />}
        >
          <RenderChildrenFn>
            {() => (
              <MarkdownContent
                className={styles.term}
                markdown={readResource(props.contentMarkdown)}
              />
            )}
          </RenderChildrenFn>
        </ScopedLoadingBoundary>
      </CardInset>

      <p>
        <label
          className={
            "flex flex-row items-center text-sm leading-5 font-normal text-gray-200"
          }
        >
          <Checkbox
            className={"mr-3"}
            onChange={({ checked }) => setIsRead(checked)}
          />
          {$t(
            defineMessage({
              defaultMessage: "I read and accept the Terms and Conditions.",
              description: "Orderbook/terms and conditions checkbox label",
            }),
          )}
        </label>
      </p>

      <ActionRow>
        <RectButton
          className={"flex-1"}
          Variant={WhiteFilledButton}
          onClick={props.onCancel}
        >
          {$t(cancel$t)}
        </RectButton>

        <RectButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          disabled={!isRead}
          onClick={props.onAccept}
        >
          {$t(accept$t)}
        </RectButton>
      </ActionRow>
    </CardModalContent>
  )
}
