import { computed, makeObservable } from "mobx"
import type { LiquidityToken } from "../../../../utils/alexjs/currencyHelpers"
import { liquidityTokenPairs } from "../../../../utils/alexjs/currencyHelpers"
import { waitUntilExist$ } from "../../../../utils/waitFor"
import PoolDetailStore from "./PoolDetailStore"

export class MyLiquidityModule {
  constructor(readonly store: PoolDetailStore) {
    makeObservable(this)
  }

  @computed get pooledAmount$(): number {
    return this.store.pooledAmount$
  }

  @computed get share$(): number {
    const totalSupply = waitUntilExist$(() => this.store.info$.totalSupply)
    return this.pooledAmount$ / totalSupply
  }

  pooledTokenAmount$ = (token: LiquidityToken): number => {
    const { x, y, dx, dy } = this.store.currencyStore.fetchPoolBreakdown$(
      this.store.poolToken,
    )
    return (x === token ? dx : y === token ? dy : 0) * this.pooledAmount$
  }

  @computed get tokenA(): LiquidityToken {
    return liquidityTokenPairs(this.store.poolToken)[0]
  }

  @computed get tokenB(): LiquidityToken {
    return liquidityTokenPairs(this.store.poolToken)[1]
  }
}
