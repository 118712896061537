import { action } from "mobx"
import { FC, Suspense } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { Modal } from "../../components/Modal"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { SegmentControlSegment } from "../../components/SegmentControl"
import {
  SelectTokenModalContent,
  TokenCandidate,
} from "../../components/SelectTokenModalContent/SelectTokenModalContent"
import { WiredSlippageModal } from "../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../components/Spensor"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { usePathGenerator } from "../../routes/routes"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Result } from "../../utils/Result"
import { suspenseResource } from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { Currency } from "../../utils/alexjs/Currency"
import { SwappableCurrency } from "../../utils/alexjs/currencyHelpers"
import SpotConfirmation from "./components/SpotConfirmation"
import { SpotForm } from "./components/SpotForm/SpotForm"
import { SpotStoreProvider, useSpotStore } from "./store/useSpotStore"

const WiredSpotForm: FC = () => {
  const store = useSpotStore()
  const amount = store.inputtedFromAmount.get() ?? 0
  const [connect] = useConnect()
  const currency = useCurrencyStore()
  const { $t } = useIntl()
  return (
    <SpotForm
      isMaxSTX={suspenseResource(() => store.isMaxSTX$)}
      onClear={amount > 0 ? () => store.setFromAmount(undefined) : undefined}
      onSlippage={() => store.slippagePercent.showSlippagePopModal()}
      fromToken={suspenseResource(() =>
        currency.getTokenInfo$(store.fromCurrency.read$),
      )}
      fromAmount={amount}
      fromOnChange={count => store.setFromAmount(count ?? undefined)}
      formError={suspenseResource(() => Result.maybeError(store.spotFormData$))}
      fromBalance={suspenseResource(() =>
        store.accountStore.getBalance$(store.fromCurrency.read$),
      )}
      fromUSD={suspenseResource(() => store.fromAmountEstimatedUSD$)}
      onPressMax={suspenseResource(() => {
        const currency = store.fromCurrency.read$
        const balance = store.accountStore.getBalance$(currency)
        const tokenCount = store.inputtedFromAmount.get()
        if (tokenCount === balance) return undefined
        if (!balance) return
        if (currency === Currency.W_STX) {
          return () => store.setFromAmount(balance * 0.99)
        }
        return () => store.setFromAmount(balance)
      })}
      fromShowSelectTokenModal={action(() => (store.onSelectType = "from"))}
      toToken={suspenseResource(() =>
        currency.getTokenInfo$(store.toCurrency.read$),
      )}
      toAmount={suspenseResource(() => store.toAmount$)}
      toBalance={suspenseResource(() =>
        store.accountStore.getBalance$(store.toCurrency.read$),
      )}
      toUSD={suspenseResource(() => store.toAmountEstimatedUSD$)}
      toShowSelectTokenModal={action(() => (store.onSelectType = "to"))}
      exchangeToken={() => store.swapFromAndTo()}
      fromUnitUSD={suspenseResource(() => store.fromCurrencyUnitPrice$)}
      fromToExchangeRate={suspenseResource(() => store.fromToExchangeRate$)}
      toUnitUSD={suspenseResource(() => store.toCurrencyUnitPrice$)}
      toFromExchangeRate={suspenseResource(() => store.toFromExchangeRate$)}
      slippage={store.slippagePercent.slippagePercentage}
      liquidityProviderFee={suspenseResource(
        () => store.liquidityProviderFee.fee$,
      )}
      minimumReceived={suspenseResource(() => store.minimumReceived$)}
      routes={suspenseResource(() => store.routes$.map(currency.getTokenInfo$))}
      onConnectWallet={() => connect()}
      onConfirm={() => store.showConfirmationModal()}
      availablePoolTypes={suspenseResource((): SegmentControlSegment[] => {
        if (!store.currentPairAvailableInAmmV1_1$) {
          return []
        }
        if (!store.currentPairAvailableInClassicSwap$) {
          return []
        }
        return [
          {
            title: $t(
              defineMessage({
                defaultMessage: "AMM v1.1",
                description: "SpotPage/SpotForm",
              }),
            ),
            isActive: store.selectPoolTypes === "AMMV1_1",
            onClick: action(() => (store.selectPoolTypes = "AMMV1_1")),
            tooltip: $t(
              defineMessage({
                defaultMessage:
                  "AMM v1.1 uses the latest AMM pool contract, which is more efficient than previous versions.",
                description: "SpotPage/SpotForm",
              }),
            ),
          },
          {
            title: $t(
              defineMessage({
                defaultMessage: "Classic",
                description: "SpotPage/SpotForm",
              }),
            ),
            isActive: store.selectPoolTypes === "classPools",
            onClick: action(() => (store.selectPoolTypes = "classPools")),
            tooltip: $t(
              defineMessage({
                defaultMessage:
                  "Classic pools use the original AMM pool contracts.",
                description: "SpotPage/SpotForm",
              }),
            ),
          },
        ]
      })}
      isTradingBrc20Token={suspenseResource(() => store.isTradingBRC20Tokens$)}
    />
  )
}

const PageContent: FC = () => {
  const store = useSpotStore()
  return (
    <Suspense>
      <WiredSpotForm />
      <WiredSelectTokenModal />
      <WiredSlippageModal slippagePercent={store.slippagePercent} />
      <WiredConfirmationModal />
      <WiredTransactionStateDialog store={store.spotRunning} />
    </Suspense>
  )
}

const WiredConfirmationModal: FC = () => {
  const store = useSpotStore()
  const currency = useCurrencyStore()
  return (
    <Modal
      visible={store.showConfirmation != null}
      onClose={action(() => (store.showConfirmation = undefined))}
    >
      <Spensor>
        {() => (
          <SpotConfirmation
            onConfirm={() => {
              const formData = Result.maybeValue(store.spotFormData$)
              if (formData != null) {
                void store.spot(formData)
              }
            }}
            onClose={action(() => (store.showConfirmation = undefined))}
            fromToken={{
              token: currency.getTokenInfo$(store.fromCurrency.read$),
              count: store.inputtedFromAmount.read$,
            }}
            toToken={{
              token: currency.getTokenInfo$(store.toCurrency.read$),
              count: store.toAmount$,
            }}
            fromToExchangeRate={store.fromToExchangeRate$}
            fromUnitUSD={store.fromCurrencyUnitPrice$}
            liquidityProviderFee={suspenseResource(
              () => store.liquidityProviderFee.fee$,
            )}
            minimumReceived={suspenseResource(() => store.minimumReceived$)}
            slippage={store.slippagePercent.slippagePercentage}
            routes={store.routes$.map(currency.getTokenInfo$)}
          />
        )}
      </Spensor>
    </Modal>
  )
}

const WiredSelectTokenModal: FC = () => {
  const g = usePathGenerator()
  const store = useSpotStore()
  const currency = useCurrencyStore()

  const commonTokens = store.commonCurrencies$.map((c): TokenCandidate => {
    return {
      disabled: !store.routeAvailable(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => store.accountStore.getBalance$(c)),
    }
  })

  const allTokens = store.availableCurrencies$.map((c): TokenCandidate => {
    return {
      disabled: !store.routeAvailable(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => store.accountStore.getBalance$(c)),
    }
  })

  return (
    <Modal
      visible={store.onSelectType != null}
      onClose={action(() => (store.onSelectType = undefined))}
    >
      <SelectTokenModalContent
        commonBasesTokens={commonTokens}
        allTokens={allTokens}
        tokenListLink={g.tokenList()}
        onSelect={currency => {
          if (store.onSelectType == null) return
          store.setCurrency(
            store.onSelectType,
            TokenInfoPresets.toCurrency(currency) as SwappableCurrency,
          )
        }}
        onClose={action(() => (store.onSelectType = undefined))}
      />
    </Modal>
  )
}

export const SpotPage: FC = () => {
  return (
    <SpotStoreProvider>
      <PageStackPage>
        <PageContent />
      </PageStackPage>
    </SpotStoreProvider>
  )
}
