import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../button/Button"
import { HeadlessButton } from "../button/HeadlessButton"
import { MessageItem } from "./MessageItem"

export const SwitchToLatestAppVersionMessageItem: FC<{
  onSwitch: () => void
  onDismiss: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <MessageItem
      title={$t(
        defineMessage({
          defaultMessage: "ALEX web app updated",
          description: "AppUpdatedNotification/title",
        }),
      )}
      content={
        <>
          <p>
            {$t(
              defineMessage({
                defaultMessage: 'Click "Switch" to update to the new version',
                description: "AppUpdatedNotification/body text",
              }),
            )}
          </p>

          <Button
            textClassName={"text-left text-blue-600 underline"}
            Variant={HeadlessButton}
            onClick={props.onSwitch}
          >
            {$t(
              defineMessage({
                defaultMessage: "Switch",
                description: "AppUpdatedNotification/switch button text",
              }),
            )}
          </Button>
        </>
      }
      onClose={props.onDismiss}
    />
  )
}
