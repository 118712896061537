import { FC, Suspense } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useMessage } from "../../components/message/MessageProvider"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { claimTestTokens } from "./store/FaucetStore.services"

const Content: FC = () => {
  const authStore = useAuthStore()
  const message = useMessage()
  const { $t } = useIntl()
  return (
    <>
      <button
        className="mt-2"
        onClick={async () => {
          const loading = message.show({
            message: "Claiming tokens...",
          })
          try {
            const res = await claimTestTokens(authStore)
            message.show({
              message: `Successfully claimed ${res.stx} STX ${res.wbtc} WBTC, ${res.usda} USD, and ${res.alex} ALEX, from ${res.account}`,
            })
          } finally {
            loading()
          }
        }}
      >
        {$t(
          defineMessage({
            defaultMessage: "Claim",
            description: "Faucet/Content/Button text",
          }),
        )}
      </button>
    </>
  )
}

const FaucetPage: FC = () => {
  const { $t } = useIntl()
  return (
    <PageStackPage
      title={
        <PrimaryPageTitle>
          {$t(
            defineMessage({
              defaultMessage: "Faucet",
              description: "/FaucetPage/Title",
            }),
          )}
        </PrimaryPageTitle>
      }
    >
      <div className="w-full h-full flex justify-center overflow-y-scroll">
        <div className="max-w-5xl w-full px-4 mt-40 sm:px-10">
          <div className="w-full flex justify-center pb-16">
            <div className="w-full md:w-6/12 flex flex-col">
              <div className="relative bg-white h-full mr-4 rounded-2xl flex flex-col p-6">
                <Suspense
                  fallback={
                    <div>
                      {$t(
                        defineMessage({
                          defaultMessage: "Not Connected",
                          description: "Faucet/Content fallback text",
                        }),
                      )}
                    </div>
                  }
                >
                  <Content />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageStackPage>
  )
}

export default FaucetPage
