/**
 * This file defined all the chains that this App supported in any environment.
 *
 * Considering someday we will support other chains, like Solana, Bitcoin, etc.
 * So we defined another enum instead of reusing the ETHChain enum directly
 */

import { ENV_NAME } from "../../../config"
import { OneOrMore } from "../../../utils/types"

export enum BridgeChain {
  Unknown = "Unknown",
  Stacks = "Stacks",
  Ethereum = "Ethereum",
  Goerli = "Goerli",
  Sepolia = "Sepolia",
  SepoliaFork = "Sepolia Fork",
  BSC = "Binance Smart Chain",
  BSCTest = "Binance Smart Chain Testnet",
  BSCTestFork = "BSC Testnet Fork",
}
export type OppositeBridgeChain = Exclude<
  BridgeChain,
  BridgeChain.Stacks | BridgeChain.Unknown
>
export type EthBridgeChain =
  | BridgeChain.Ethereum
  | BridgeChain.Goerli
  | BridgeChain.Sepolia
  | BridgeChain.SepoliaFork
  | BridgeChain.BSC
  | BridgeChain.BSCTest
  | BridgeChain.BSCTestFork

export function isOppositeBridgeChain(
  chain: BridgeChain,
): chain is OppositeBridgeChain {
  return chain !== BridgeChain.Stacks && chain !== BridgeChain.Unknown
}

export function isEthBridgeChain(chain: BridgeChain): chain is EthBridgeChain {
  return (
    chain === BridgeChain.Ethereum ||
    chain === BridgeChain.Goerli ||
    chain === BridgeChain.Sepolia ||
    chain === BridgeChain.SepoliaFork ||
    chain === BridgeChain.BSC ||
    chain === BridgeChain.BSCTest ||
    chain === BridgeChain.BSCTestFork
  )
}

export const supportedOppositeBridgeChains: OneOrMore<SupportedOppositeBridgeChain> =
  ENV_NAME === "dev"
    ? [
        BridgeChain.BSCTestFork as SupportedOppositeBridgeChain,
        BridgeChain.SepoliaFork as SupportedOppositeBridgeChain,
      ]
    : ENV_NAME === "publicTest"
    ? [
        BridgeChain.BSCTest as SupportedOppositeBridgeChain,
        BridgeChain.Sepolia as SupportedOppositeBridgeChain,
      ]
    : [
        BridgeChain.BSC as SupportedOppositeBridgeChain,
        BridgeChain.Ethereum as SupportedOppositeBridgeChain,
      ]

export type SupportedOppositeBridgeChain = OppositeBridgeChain & {
  __brand: "SupportedOppositeBridgeChain"
}

export function isSupportedOppositeBridgeChain(
  chain: BridgeChain,
): chain is SupportedOppositeBridgeChain {
  return supportedOppositeBridgeChains.includes(chain as any)
}
