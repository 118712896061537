import { FC } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import { usePathGenerator } from "../../routes/routes"
import { OrderbookMarketId } from "../Orderbook/store/stxdx_shared/StxDxMarket.service"

export const PerpetualIndexPage: FC = () => {
  const gen = usePathGenerator()

  const [searchParams] = useSearchParams()

  const search = searchParams.toString()

  const to = gen.orderbookDetail("ALEX-USD" as OrderbookMarketId)

  return (
    <Navigate
      to={search.length !== 0 ? `${to}?${search}` : to}
      replace={true}
    />
  )
}
