import clsx from "clsx"
import { FC, ReactNode } from "react"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { BigNumber } from "../../../../../utils/BigNumber"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  CardDivider,
  CardHeroText,
  CardText,
  RenderBRC20CardFn,
  defaultRenderBRC20Card,
} from "./BRC20Card"

export interface BRC20InscriptionCardProps {
  className?: string
  token: TokenInfo
  tokenCount: BigNumber
  inscriptionNumber?: number
  renderBRC20Card?: RenderBRC20CardFn
  topArea?: ReactNode
  bottomArea?: ReactNode
}

export const BRC20InscriptionCard: FC<BRC20InscriptionCardProps> = props => {
  const renderBRC20Card = props.renderBRC20Card ?? defaultRenderBRC20Card

  return renderBRC20Card({
    className: clsx("text-center items-center justify-center", props.className),
    children: (
      <>
        {props.topArea != null && (
          <>
            {props.topArea}
            <CardDivider />
          </>
        )}

        <CardHeroText>
          <TokenCount token={props.token} count={props.tokenCount} />
          <br />
          <TokenName token={props.token} />
        </CardHeroText>

        {props.inscriptionNumber != null && (
          <CardText>#{props.inscriptionNumber}</CardText>
        )}

        {props.bottomArea != null && (
          <div className={"mt-auto"}>{props.bottomArea}</div>
        )}
      </>
    ),
  })
}
