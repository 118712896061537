import { FC, useRef } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientSecondaryPageTitle } from "../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { WiredBridgePanel } from "./components/WiredBridgePanel"
import { WiredMetaMaskTransactionStateModal } from "./components/WiredMetaMaskTransactionStateModal"
import WiredWhiteListCheck from "./components/WiredWhiteListCheck"
import { WiredWrapBridgeHistoryContent } from "./components/WiredWrapBridgeHistoryContent"
import { WiredWrapBridgeRoute } from "./components/WiredWrapBridgeRoute"
import {
  WrapBridgeStoreProvider,
  useWrapBridgeStore,
} from "./store/useWrapBridgeStore"

const WrapBridgePageContent: FC = () => {
  const { wrapForm } = useWrapBridgeStore()
  const historyOrderRef = useRef<HTMLDivElement>(null)

  return (
    <div className="flex flex-col gap-8">
      <WiredBridgePanel />
      <WiredWrapBridgeRoute />
      <WiredWrapBridgeHistoryContent headerRef={historyOrderRef} />

      <WiredMetaMaskTransactionStateModal />
      <WiredTransactionStateDialog store={wrapForm.stacksTransactionStore} />
      <WiredWhiteListCheck />
    </div>
  )
}

export const WrapBridgePage: FC = () => {
  const { $t } = useIntl()
  return (
    <PageStackPage
      title={
        <>
          <GradientSecondaryPageTitle returnIcon={<></>}>
            {$t(
              defineMessage({
                defaultMessage: "Bridge",
                description: "/Bridge/Content/title",
              }),
            )}
          </GradientSecondaryPageTitle>
        </>
      }
    >
      <WrapBridgeStoreProvider>
        <WrapBridgePageContent />
      </WrapBridgeStoreProvider>
    </PageStackPage>
  )
}
