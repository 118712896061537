import { TopNavBarHeight } from "../../../../components/TopNavBar/TopNavBar"
import { FCC } from "../../../../utils/reactHelpers/types"
import bgImgSrc from "./_/bg.svg"

export const BridgeBackgroundContainer: FCC<{ className?: string }> = props => {
  return (
    <div
      className={props.className}
      style={{
        backgroundImage: `url(${bgImgSrc})`,
        backgroundSize: "120%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: `center top ${TopNavBarHeight}px`,
      }}
    >
      {props.children}
    </div>
  )
}
