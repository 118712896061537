import { callReadOnlyFunction } from "@stacks/transactions"
import { unwrapResponse } from "clarity-codegen"
import { memoize } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { type PaginationInfo } from "../../../components/Pagination"
import { CONTRACT_DEPLOYER, STACK_NETWORK } from "../../../config"
import { AlexContracts } from "../../../generated/smartContract/contracts_Alex"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { readResource, suspenseResource } from "../../../utils/SuspenseResource"
import { contractNameFromAssetIdentifier } from "../../../utils/addressHelpers"
import { Currency } from "../../../utils/alexjs/Currency"
import {
  currencyScale,
  getAssetIdentifierByCurrency,
} from "../../../utils/alexjs/currencyHelpers"
import { suspenseMap$ } from "../../../utils/waitFor"
import { B20TokenListInfo, TokenListStatus } from "../types"
import {
  DEFAULT_MIN_LIQ_PERCENTAGE,
  FetchedB20TokenListInfo,
  fetchAllRecords,
} from "./B20TokenListStore.service"

const recordCountPerPage = 10

export type B20TokenListInfoFromStore = Omit<B20TokenListInfo, "pegInLink"> & {
  tradeTokenCurrency: Currency
}

export class B20TokenListStore {
  constructor(
    private currencyStore: CurrencyStore,
    private authStore: AuthStore,
    private chainStore: ChainStore,
  ) {
    makeObservable(this)
  }

  @observable searching = ""
  @observable filteringStatus: null | TokenListStatus = null
  @observable currentPage = 0

  private currencyCurrentSupply = memoize(
    (currency: Currency) =>
      new LazyValue(
        () => ({
          _block: this.chainStore.currentBlockHeight$,
          stxAddress: this.authStore.stxAddress$,
          contractName: contractNameFromAssetIdentifier(
            getAssetIdentifierByCurrency(currency),
          ),
        }),
        async ({ stxAddress, contractName }) => {
          const functionDescriptor =
            AlexContracts["age000-governance-token"]["get-total-supply-fixed"]

          return contractName == null
            ? 0
            : callReadOnlyFunction({
                network: STACK_NETWORK,
                contractAddress: CONTRACT_DEPLOYER,
                contractName: contractName,
                functionName: "get-total-supply-fixed",
                senderAddress: stxAddress,
                functionArgs: [],
              })
                .then(value => functionDescriptor.output.decode(value))
                .then(unwrapResponse)
                .then(a => a / currencyScale(currency))
        },
      ),
  )

  private allRecords = new LazyValue(
    () => ({}),
    () => fetchAllRecords(),
  )

  @computed private get transformedAllRecord$(): B20TokenListInfoFromStore[] {
    return this.allRecords.immediateValue$.map(r =>
      this.serverDataToB20TokenListInfo$(r),
    )
  }

  private serverDataToB20TokenListInfo$(
    data: FetchedB20TokenListInfo,
  ): B20TokenListInfoFromStore {
    return {
      tradeTokenCurrency: data.baseTokenCurrency,
      tradeToken: this.currencyStore.getTokenInfo$(data.baseTokenCurrency),
      priceToken: this.currencyStore.getTokenInfo$(Currency.sUSDT),
      minLiquidity: data.minLiquidity,
      liquidity: suspenseResource(
        () => this.currencyCurrentSupply(data.baseTokenCurrency).value$,
      ),
      raisingLiquidityPercentage: suspenseResource(() =>
        Math.min(
          this.currencyCurrentSupply(data.baseTokenCurrency).value$ /
            data.minLiquidity,
          1,
        ),
      ),
      status: data.isListed
        ? TokenListStatus.Listed
        : TokenListStatus.Candidate,
      inscription: data.inscription,
      inscriptionLink: data.inscriptionLink,
      decimal: data.decimal,
      limitPerMint: data.limitPerMint,
      totalSupply: data.totalSupply,
      minted: data.minted,
      mintedPercent: data.minted / data.totalSupply,
    }
  }

  @action onUpdateSearching = (searching: string): void => {
    this.searching = searching
    this.currentPage = 0
  }

  @action onUpdateFilteringStatus = (status: null | TokenListStatus): void => {
    this.filteringStatus = status
    this.currentPage = 0
  }

  @action onUpdateCurrentPage = (currPage: number): void => {
    this.currentPage = currPage
  }

  get minLiquidityPercentage$(): number {
    return DEFAULT_MIN_LIQ_PERCENTAGE
  }

  @computed private get filteredAllRecord$(): B20TokenListInfoFromStore[] {
    const result = this.transformedAllRecord$.filter(r => {
      if (this.filteringStatus != null) {
        if (this.filteringStatus !== r.status) {
          return false
        }
      }

      if (this.searching) {
        if (
          !r.tradeToken.displayName
            .toLowerCase()
            .includes(this.searching.toLowerCase())
        ) {
          return false
        }
      }

      return true
    })
    return suspenseMap$(
      result,
      r => [r, readResource(r.raisingLiquidityPercentage)] as const,
    )
      .sort((a, b) => b[1] - a[1])
      .map(a => a[0])
  }

  @computed get pagination$(): PaginationInfo {
    return {
      currentPage: this.currentPage,
      recordCountPerPage: recordCountPerPage,
      recordCountTotal: this.filteredAllRecord$.length,
    }
  }

  @computed get records$(): B20TokenListInfoFromStore[] {
    return this.filteredAllRecord$.slice(
      this.currentPage * recordCountPerPage,
      (this.currentPage + 1) * recordCountPerPage,
    )
  }
}
