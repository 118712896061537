import { Component, ErrorInfo, ReactNode } from "react"
import { PropsWithChildren } from "../utils/reactHelpers/types"

interface ErrorBoundaryState {
  hasError: boolean
  error?: unknown
  errorInfo?: ErrorInfo
}

export interface ErrorBoundaryFallbackRenderProps {
  error?: unknown
  errorInfo?: ErrorInfo
  retry: () => void
}

export interface ErrorBoundaryProps extends PropsWithChildren {
  fallback: (renderProps: ErrorBoundaryFallbackRenderProps) => ReactNode
  onDidCatchError?: (error: Error, errorInfo: ErrorInfo) => void
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.onDidCatchError?.(error, errorInfo)
    this.setState({ hasError: true, error, errorInfo })
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <>
          {this.props.fallback({
            error: this.state.error,
            errorInfo: this.state.errorInfo,
            retry: () => this.retry(),
          })}
        </>
      )
    }
    return <>{this.props.children}</>
  }

  retry(): void {
    this.setState({ hasError: false })
  }
}
