import { FungibleConditionCode } from "@stacks/transactions"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "../../../../utils/alexjs/AMMSwapPool"
import { Currency } from "../../../../utils/alexjs/Currency"
import {
  autoTokenForYTP,
  isSimpleAlexPool,
  isYTPToken,
  LiquidityPoolToken,
  liquidityTokenPairs,
  yieldTokenForYTP,
  ytpBreakDown,
} from "../../../../utils/alexjs/currencyHelpers"
import {
  AlexVault,
  AlexVaultV1_1,
  transfer,
} from "../../../../utils/alexjs/postConditions"

export async function removeLiquidity(
  stxAddress: string,
  poolToken: LiquidityPoolToken,
  amountToRemove: number,
  percentage: number,
): Promise<string> {
  const [tokenA, tokenB] = liquidityTokenPairs(poolToken)
  const postConditions = [
    transfer(
      stxAddress,
      poolToken,
      amountToRemove * 1.001,
      FungibleConditionCode.LessEqual,
    ),
    transfer(AlexVault, tokenA, 0, FungibleConditionCode.GreaterEqual),
    transfer(AlexVault, tokenB, 0, FungibleConditionCode.GreaterEqual),
  ]
  if (isYTPToken(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("redeem-auto-helper")
      .func("redeem-auto-and-reduce-from-yield-token-pool")
      .call(
        {
          "token-trait": Currency.ALEX,
          "yield-token-trait": yieldTokenForYTP(poolToken),
          "pool-token-trait": poolToken,
          "auto-token-trait": autoTokenForYTP(poolToken),
          percent: percentage,
        },
        [
          transfer(
            stxAddress,
            ytpBreakDown(poolToken).autoYTP,
            amountToRemove * 1.001,
            FungibleConditionCode.LessEqual,
          ),
          transfer(AlexVault, poolToken, 0, FungibleConditionCode.GreaterEqual),
          transfer(
            stxAddress,
            poolToken,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ytpBreakDown(poolToken).underlying,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ytpBreakDown(poolToken).yieldToken,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
    return result.txId
  }
  if (AMMSwapPool.isV1PoolToken(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("reduce-position")
      .call(
        {
          factor: AMMSwapPool.getFactor(poolToken),
          "token-x-trait": tokenA,
          "token-y-trait": tokenB,
          percent: percentage,
        },
        postConditions,
      )
    return result.txId
  }
  if (AMMSwapPool.isV1_1PoolToken(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("amm-swap-pool-v1-1")
      .func("reduce-position")
      .call(
        {
          factor: AMMSwapPool.getFactor(poolToken),
          "token-x-trait": tokenA,
          "token-y-trait": tokenB,
          percent: percentage,
        },
        [
          transfer(
            stxAddress,
            poolToken,
            amountToRemove * 1.001,
            FungibleConditionCode.LessEqual,
          ),
          transfer(
            AlexVaultV1_1,
            tokenA,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVaultV1_1,
            tokenB,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
    return result.txId
  }
  if (isSimpleAlexPool(poolToken)) {
    const result = await asSender(stxAddress)
      .contract("simple-weight-pool-alex")
      .func("reduce-position")
      .call(
        {
          "token-x-trait": tokenA,
          "token-y-trait": tokenB,
          "pool-token-trait": poolToken,
          percent: percentage,
        },
        postConditions,
      )
    return result.txId
  }
  const result = await asSender(stxAddress)
    .contract("fixed-weight-pool-v1-01")
    .func("reduce-position")
    .call(
      {
        "token-x-trait": tokenA,
        "token-y-trait": tokenB,
        "weight-x": 0.5e8,
        "weight-y": 0.5e8,
        "pool-token-trait": poolToken,
        percent: percentage,
      },
      postConditions,
    )
  return result.txId
}
