import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "../BaseRoundedFilledButton"
import styles from "./GradientBorderButton.module.scss"

export interface GradientBorderButtonProps extends RoundedButtonVariantProps {
  borderClassName?: string
}

export const GradientBorderButton: FC<GradientBorderButtonProps> = props => {
  const { borderClassName, ...restProps } = props

  return (
    <BaseRoundedFilledButton
      textClassName={"text-gray-200 hover:text-white active:text-white"}
      {...restProps}
      className={clsx(borderClassName ?? styles.buttonBorder, props.className)}
    >
      {props.children}
    </BaseRoundedFilledButton>
  )
}
