import { from, interval, Observable, startWith, switchMap } from "rxjs"

export const intervalFetch = <T>(
  intervalTime: number,
  fetch: () => Promise<T>,
): Observable<T> =>
  interval(intervalTime).pipe(
    startWith(0),
    switchMap(() => from(fetch())),
  )
