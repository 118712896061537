import {
  LazyValue,
  SharedLazyValue,
} from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { perMinuteTimerValue } from "../../../../stores/TimerValue"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  BRC20Currency,
  brc20CurrencyFromNativeBrc20Ticker,
} from "../../../../utils/alexjs/Currency"
import { isNotNull } from "../../../../utils/utils"
import { waitFor } from "../../../../utils/waitFor"
import { InscribeOrderStatus } from "./BitcoinClient/BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "./BitcoinClient/BitcoinClientBRC20Wallet"
import { honoClient } from "./BitcoinClient/request.service"
import { WalletAdapterAddresses } from "./WalletAdapters/WalletAdapters.types"

export type RecentInscribeOrderStatus = InscribeOrderStatus & {
  paymentTxId: string
  currency: BRC20Currency
  amount: BigNumber
}

export class RecentInscribeOrderModule {
  constructor(
    private authStore: AuthStore,
    private brc20Wallet: SharedLazyValue<BitcoinClientBRC20Wallet>,
    private btcWalletAddresses: SharedLazyValue<WalletAdapterAddresses>,
  ) {}

  notifyOrderCompleted(info: { orderId: string }): void {
    void this.removeOrder(info.orderId)
  }

  private async removeOrder(orderId: string): Promise<void> {
    // do nothing
  }

  async notifyNewOrderPaid(info: {
    orderId: string
    paymentTxId: string
    currency: BRC20Currency
    currencyAmount: BigNumber
  }): Promise<void> {
    const stxAddress = await waitFor(() => this.authStore.stxAddress$)

    await honoClient()._api.paidInscribeOrders.$post({
      json: {
        stxAddress,
        orderId: info.orderId,
        paymentTxId: info.paymentTxId,
      },
    })

    this.recentOrdersRefreshSignal.set(Date.now())
  }

  private recentOrdersRefreshSignal = new SuspenseObservable(Date.now())
  recentOrders = new LazyValue(
    () => ({
      _time: perMinuteTimerValue.value,
      _refreshSignal: this.recentOrdersRefreshSignal.read$,
      stxAddress: this.authStore.stxAddress$,
    }),
    async ({ stxAddress }) => {
      const resp = await honoClient()._api.paidInscribeOrders.$get({
        query: { stxAddress },
      })

      return Promise.all(
        (await resp.json()).map(order =>
          this.brc20Wallet.value$
            .getTokenTransferInscribeOrderStatus(order.orderId)
            .then(
              async (
                status,
              ): Promise<undefined | RecentInscribeOrderStatus> => {
                if (status == null) return undefined

                return {
                  ...status,
                  paymentTxId: order.paymentTxId,
                  currency: brc20CurrencyFromNativeBrc20Ticker(
                    order.brc20Ticker,
                  ),
                  amount: BigNumber.from(order.brc20TokenAmount),
                }
              },
            ),
        ),
      ).then(res => res.filter(isNotNull))
    },
  )
}

interface JSONLocalStorage<T> {
  localStorageKey: string
  get(): T | undefined
  set(v: T): void
  cleanup(): void
}
const jsonLocalStorage = <T>(localStorageKey: string): JSONLocalStorage<T> => {
  return {
    localStorageKey,
    get() {
      const v = localStorage.getItem(localStorageKey)
      if (v == null) return undefined
      try {
        return JSON.parse(v)
      } catch {
        localStorage.removeItem(localStorageKey)
        return undefined
      }
    },
    set(v) {
      localStorage.setItem(localStorageKey, JSON.stringify(v))
    },
    cleanup() {
      localStorage.removeItem(localStorageKey)
    },
  }
}
