import { keys, values, zipObject } from "lodash"
import tailwindSpacing from "./tailwindSpacing.json"

export const defaultThemeSpacing: {
  [K in keyof typeof tailwindSpacing as K extends `${infer K1 extends number}`
    ? K1
    : K]: number
} = zipObject(
  keys(tailwindSpacing).map((k): `${typeof k}` => String(k) as any),
  values(tailwindSpacing).map(
    value => parseFloat(value) * (value.endsWith("px") ? 1 : 16),
  ),
) as any
