import { action } from "mobx"
import { FC, ReactNode, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Modal } from "../../../components/Modal"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import {
  ErrorIcon,
  MessageItem,
  SuccessIcon,
} from "../../../components/message/MessageItem"
import { useMessage } from "../../../components/message/MessageProvider"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { CancelError } from "../../../utils/error"
import { CreateOrderFormContainer } from "../components/CreateOrder/CreateOrderFormContainer"
import { CreateOrderPanelFrame } from "../components/CreateOrder/CreateOrderPanelFrame"
import { CreateOrderSwitchers } from "../components/CreateOrder/CreateOrderSwitchers"
import { LimitOrderForm } from "../components/CreateOrder/LimitOrderForm"
import { MarketOrderForm } from "../components/CreateOrder/MarketOrderForm"
import { StopLimitOrderConfirmModalContent } from "../components/CreateOrder/StopLimitOrderConfirmModalContent"
import { StopLimitOrderForm } from "../components/CreateOrder/StopLimitOrderForm"
import { StxDxOrderType } from "../components/types"
import { OrderbookTradeModule } from "../store/modules/OrderbookTradeModule"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const orderCreatedNotificationTitle = defineMessage({
  defaultMessage: `{orderType, select, other {Limit} market {Market} stopLimit {Stop-limit}} {orderDirection, select, other {Buy} sell {Sell}} Order Created`,
  description: "Orderbook/order created notification title",
})

export const orderCreatedNotificationBody = defineMessage({
  defaultMessage:
    "Submitted {orderDirection, select, other {buy} sell {sell}} {orderType, select, other {limit} market {market} stopLimit {stop-limit}} order for {receivingTokenCount} {receivingTokenName} by using {sendingTokenName}，{executedPercentage} is executed",
  description: "Orderbook/order created notification body",
})

export const orderCreateFailedNotificationTitle = defineMessage({
  defaultMessage: `{orderType, select, other {Limit} market {Market} stopLimit {Stop-limit}} {orderDirection, select, other {Buy} sell {Sell}} Order Create Failed`,
  description: "Orderbook/order create failed notification title",
})

const WiredLimitOrderForm: FC<{
  tradeModule: OrderbookTradeModule
}> = props => {
  return (
    <LimitOrderForm
      formError={suspenseResource(() =>
        props.tradeModule.formData$.type === "error"
          ? props.tradeModule.formData$.payload
          : props.tradeModule.formData$.payload.warning,
      )}
      outgoingToken={props.tradeModule.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => props.tradeModule.outgoingTokenBalance,
      )}
      priceToken={props.tradeModule.priceTokenInfo$}
      tradeToken={props.tradeModule.tradeTokenInfo$}
      perTradeTokenPrice={suspenseResource(
        () => props.tradeModule.perTradeTokenPrice$,
      )}
      tradeTokenCount={suspenseResource(
        () => props.tradeModule.getTradeAmount() ?? null,
      )}
      onTradeTokenCountChange={count =>
        count == null
          ? props.tradeModule.emptyTradeAmount()
          : props.tradeModule.setTradeAmount(count)
      }
      totalTradeTokenPrice={suspenseResource(
        () => props.tradeModule.priceTokenCount$,
      )}
      tradeTokenCountPercentage={suspenseResource(
        () => props.tradeModule.tradePercentage$,
      )}
      onPerTradeTokenPriceChange={a => {
        props.tradeModule.setCustomPrice(a ?? 0)
      }}
      onTotalTradeTokenPriceChange={t =>
        props.tradeModule.setTotal(t ?? undefined)
      }
      onTradeTokenCountPercentageChange={p =>
        props.tradeModule.setPercentage(p)
      }
      perTradeTokenPricePrecision={props.tradeModule.priceTokenPrecision$}
    />
  )
}

const WiredMarketOrderForm: FC<{
  tradeModule: OrderbookTradeModule
}> = props => {
  const [inputMode, setInputMode] = useState<"amount" | "total">("amount")

  const priceToken = props.tradeModule.priceTokenInfo$
  const tradeToken = props.tradeModule.tradeTokenInfo$

  return (
    <MarketOrderForm
      formError={suspenseResource(() =>
        props.tradeModule.formData$.type === "error"
          ? props.tradeModule.formData$.payload
          : props.tradeModule.formData$.payload.warning,
      )}
      outgoingToken={props.tradeModule.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => props.tradeModule.outgoingTokenBalance,
      )}
      priceToken={priceToken}
      tradeToken={tradeToken}
      pieceToken={inputMode === "amount" ? tradeToken : priceToken}
      pieceTokenCount={suspenseResource(() =>
        inputMode === "amount"
          ? props.tradeModule.getTradeAmount() ?? 0
          : props.tradeModule.priceTokenCount$,
      )}
      onPieceTokenCountPercentageChange={p =>
        props.tradeModule.setPercentage(p)
      }
      onPieceTokenCountChange={count =>
        inputMode === "amount"
          ? count == null
            ? props.tradeModule.emptyTradeAmount()
            : props.tradeModule.setTradeAmount(count)
          : props.tradeModule.setTotal(count ?? undefined)
      }
      onPieceTokenChange={token =>
        setInputMode(token === tradeToken ? "amount" : "total")
      }
      pieceTokenCountPercentage={props.tradeModule.tradePercentage$}
    />
  )
}

const WiredStopLimitOrderForm: FC<{
  tradeModule: OrderbookTradeModule
}> = props => {
  return (
    <StopLimitOrderForm
      formError={suspenseResource(() =>
        props.tradeModule.formData$.type === "error"
          ? props.tradeModule.formData$.payload
          : props.tradeModule.formData$.payload.warning,
      )}
      outgoingToken={props.tradeModule.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => props.tradeModule.outgoingTokenBalance,
      )}
      priceToken={props.tradeModule.priceTokenInfo$}
      tradeToken={props.tradeModule.tradeTokenInfo$}
      perTradeTokenLimitPrice={suspenseResource(
        () => props.tradeModule.perTradeTokenPrice$,
      )}
      tradeTokenCount={suspenseResource(
        () => props.tradeModule.getTradeAmount() ?? null,
      )}
      onTradeTokenCountChange={count =>
        count == null
          ? props.tradeModule.emptyTradeAmount()
          : props.tradeModule.setTradeAmount(count)
      }
      totalTradeTokenPrice={suspenseResource(
        () => props.tradeModule.priceTokenCount$,
      )}
      tradeTokenCountPercentage={suspenseResource(
        () => props.tradeModule.tradePercentage$,
      )}
      onPerTradeTokenLimitPriceChange={a => {
        props.tradeModule.setCustomPrice(a ?? undefined)
      }}
      onTotalTradeTokenPriceChange={t =>
        props.tradeModule.setTotal(t ?? undefined)
      }
      onTradeTokenCountPercentageChange={p =>
        props.tradeModule.setPercentage(p)
      }
      perTradeTokenStopPrice={suspenseResource(
        () => props.tradeModule.stopPrice.read$,
      )}
      onPerTradeTokenStopPriceChange={p =>
        props.tradeModule.stopPrice.set(p ?? undefined)
      }
      perTradeTokenPricePrecision={props.tradeModule.priceTokenPrecision$}
    />
  )
}

const WiredStopLimitOrderConfirmModal: FC<{
  tradeModule: OrderbookTradeModule
}> = store => {
  const { $t } = useIntl()
  const message = useMessage()

  return (
    <Modal
      visible={store.tradeModule.confirming}
      onClose={action(() => (store.tradeModule.confirming = false))}
    >
      <Spensor>
        {() => (
          <StopLimitOrderConfirmModalContent
            orderDirection={store.tradeModule.side}
            priceToken={store.tradeModule.priceTokenInfo$}
            tradeToken={store.tradeModule.tradeTokenInfo$}
            perTradeTokenLimitPrice={store.tradeModule.perTradeTokenPrice$}
            totalTradeTokenPrice={store.tradeModule.priceTokenCount$}
            tradeTokenCount={store.tradeModule.tradeAmount$}
            onClose={action(() => (store.tradeModule.confirming = false))}
            onCancel={action(() => (store.tradeModule.confirming = false))}
            onConfirm={async () => {
              const data = Result.maybeValue(store.tradeModule.formData$)
              if (data == null) return
              try {
                await store.tradeModule.trade(data)
                message.show(({ close }) => ({
                  message: (
                    <MessageItem
                      icon={<SuccessIcon />}
                      title={$t(orderCreatedNotificationTitle, {
                        orderType: data.orderType,
                        orderDirection: data.side,
                      })}
                      onClose={close}
                    />
                  ),
                }))
              } catch (e) {
                console.error(e)
                message.show(({ close }) => ({
                  message: (
                    <MessageItem
                      icon={<ErrorIcon />}
                      title={$t(orderCreateFailedNotificationTitle, {
                        orderType: data.orderType,
                        orderDirection: data.side,
                      })}
                      content={(e as Error).message}
                      onClose={close}
                    />
                  ),
                }))
              }
            }}
          />
        )}
      </Spensor>
    </Modal>
  )
}

const WiredSingleSideCreateOrderForm: FC<{
  className?: string
  gapClassName?: string
  tradeModule: OrderbookTradeModule
  aboveForm: ReactNode
}> = props => {
  const { $t } = useIntl()
  const { showWalletSelector: doAuth } = useAuthStore()
  const message = useMessage()

  return (
    <>
      <CreateOrderFormContainer
        className={props.className}
        gapClassName={props.gapClassName}
        aboveForm={props.aboveForm}
        orderDirection={props.tradeModule.side}
        orderType={props.tradeModule.orderType}
        onSubmit={async () => {
          const data = Result.maybeValue(props.tradeModule.formData$)
          if (data == null) return

          try {
            await props.tradeModule.trade(data)
            message.show(({ close }) => ({
              message: (
                <MessageItem
                  icon={<SuccessIcon />}
                  title={$t(orderCreatedNotificationTitle, {
                    orderType: data.orderType,
                    orderDirection: data.side,
                  })}
                  content={$t(orderCreatedNotificationBody, {
                    orderType: data.orderType,
                    orderDirection: data.side,
                    receivingTokenCount: (
                      <TokenCount
                        token={data.incomingToken}
                        count={data.size}
                      />
                    ),
                    receivingTokenName: (
                      <TokenName token={data.incomingToken} />
                    ),
                    sendingTokenName: <TokenName token={data.outgoingToken} />,
                    executedPercentage: <PercentNumber number={0} />,
                  })}
                  onClose={close}
                />
              ),
            }))
          } catch (e) {
            if (e instanceof CancelError) {
              return
            }
            console.error(e)
            message.show(({ close }) => ({
              message: (
                <MessageItem
                  icon={<ErrorIcon />}
                  title={$t(orderCreateFailedNotificationTitle, {
                    orderType: data.orderType,
                    orderDirection: data.side,
                  })}
                  content={(e as Error).message}
                  onClose={close}
                />
              ),
            }))
          }
        }}
        onConnectWallet={doAuth}
        onDeposit={suspenseResource(() => props.tradeModule.onDeposit$)}
        onReplacePrice={() => props.tradeModule.onReplacePrice()}
        formError={suspenseResource(() =>
          props.tradeModule.formData$.type === "error"
            ? props.tradeModule.formData$.payload
            : props.tradeModule.formData$.payload.warning,
        )}
      >
        {props.tradeModule.orderType === StxDxOrderType.Limit && (
          <WiredLimitOrderForm tradeModule={props.tradeModule} />
        )}
        {props.tradeModule.orderType === StxDxOrderType.Market && (
          <WiredMarketOrderForm tradeModule={props.tradeModule} />
        )}
        {props.tradeModule.orderType === StxDxOrderType.StopLimit && (
          <WiredStopLimitOrderForm tradeModule={props.tradeModule} />
        )}
      </CreateOrderFormContainer>

      <WiredStopLimitOrderConfirmModal tradeModule={props.tradeModule} />
    </>
  )
}

export const WiredCreateOrder: FC<{ className?: string }> = props => {
  const store = useOrderbookStore()

  return (
    <CreateOrderPanelFrame
      className={props.className}
      switchers={p => (
        <CreateOrderSwitchers
          className={p.className}
          gapClassName={p.gapClassName}
          orderType={store.buyFormModule.orderType}
          onChangeOrderType={type => {
            store.buyFormModule.setOrderType(type)
            store.sellFormModule.setOrderType(type)
          }}
          feeRates={store.buyFormModule.feeRates$}
        />
      )}
      buyForm={p => (
        <WiredSingleSideCreateOrderForm
          className={p.className}
          gapClassName={p.gapClassName}
          aboveForm={null}
          tradeModule={store.buyFormModule}
        />
      )}
      sellForm={p => (
        <WiredSingleSideCreateOrderForm
          className={p.className}
          gapClassName={p.gapClassName}
          aboveForm={null}
          tradeModule={store.sellFormModule}
        />
      )}
    />
  )
}
