import clsx from "clsx"
import { FC, Suspense } from "react"
import { useParams } from "react-router-dom"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { MediaLinks } from "../../components/MediaLinks/MediaLinks"
import { GradientSecondaryPageTitle } from "../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { Spensor } from "../../components/Spensor"
import { usePathGenerator } from "../../routes/routes"
import { WiredIDODetailSidePanel } from "./component/IDODetailSidePanel/WiredIDODetailSidePanel"
import { WiredIDOIntro } from "./component/IDOIntro/WiredIDOIntro"
import { IDOProjectBadgeList } from "./component/IDOProjectBadgeList/IDOProjectBadgeList"
import { IDOStatusBadge } from "./component/IDOStatusBadge"
import { LaunchingStatus } from "./store/LaunchPadContractStore"
import {
  LaunchPadProvider,
  useLaunchPadContentfulStore,
} from "./store/useLaunchPadStores"

const WiredLaunchpadDetailPageContent: FC = () => {
  const store = useLaunchPadContentfulStore()
  const g = usePathGenerator()

  return (
    <PageStackPage
      title={
        <>
          <GradientSecondaryPageTitle
            titleRightSide={
              <IDOStatusBadge
                status={
                  store.contractStore$?.tokenProfileViewModule.status ?? {
                    type: LaunchingStatus.Upcoming,
                  }
                }
              />
            }
            subtitle={store.meta$.subtitle}
            returnFallbackURL={g.launchpad()}
          >
            ${store.meta$.title}
          </GradientSecondaryPageTitle>
          <MediaLinks links={store.meta$.links} />
        </>
      }
    >
      <Spensor>
        {() => (
          <IDOProjectBadgeList
            className={"mb-4"}
            idoTokenType={store.meta$.tokenType}
            idoVoteStatus={store.meta$.governanceVote}
          />
        )}
      </Spensor>

      <div className={"grid grid-cols-12 grid-rows-1 gap-y-8 lg:gap-8 mb-8"}>
        <div className={clsx("col-span-12 lg:col-span-7 justify-center")}>
          <Suspense fallback={<LoadingIndicator className={"mx-auto"} />}>
            <WiredIDOIntro />
          </Suspense>
        </div>

        <div
          className={clsx(
            "col-span-12 order-first lg:order-none lg:col-span-5",
          )}
        >
          <Suspense fallback={<LoadingIndicator className={"mx-auto"} />}>
            <WiredIDODetailSidePanel className={"sticky top-[100px]"} />
          </Suspense>
        </div>
      </div>
    </PageStackPage>
  )
}

export const LaunchpadDetailPage: FC = () => {
  const urlSlug = useParams<"urlSlug">().urlSlug!

  return (
    <LaunchPadProvider urlSlug={urlSlug}>
      <Suspense fallback={<LoadingIndicator className={"m-auto"} />}>
        <WiredLaunchpadDetailPageContent />
      </Suspense>
    </LaunchPadProvider>
  )
}
