import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardInset } from "../../../../components/Card"
import { CountNumber } from "../../../../components/CountNumber"
import { InfoList } from "../../../../components/InfoList/InfoList"
import { InfoListItem } from "../../../../components/InfoList/InfoListItem"
import { InfoListItemDetail } from "../../../../components/InfoList/InfoListItemDetail"
import { InfoListItemTitle } from "../../../../components/InfoList/InfoListItemTitle"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"
import { TokenCount } from "../../../../components/TokenCount"
import { InfoIconButton } from "../../../../components/button/variants/InfoIconButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { trunc } from "../../../../utils/addressHelpers"

import { TokenIconStack } from "../../../../components/TokenIconStack"
import { TokenNameStack } from "../../../../components/TokenNameStack"
import { B20TokenListInfo } from "../../types"
import { ListStatus } from "./_/ListStatus"
import { RaisingLiquidityProgress } from "./_/RaisingLiquidityProgress"
import { ReactComponent as InfoIcon } from "./_/info.svg"

const ListInfo: FC<{
  className?: string
  record: B20TokenListInfo
}> = props => {
  const { $t } = useIntl()
  return (
    <CardInset
      className={clsx("w-full min-w-[fit-content]", props.className)}
      roundClassName={"rounded-md"}
      boxClassName={"py-2 px-4"}
    >
      <InfoList
        className="flex gap-x-8"
        direction={"row"}
        listItemDirection={"column"}
      >
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Inscription",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <NavLink
              className="text-blue-600"
              to={props.record.inscriptionLink}
            >
              {trunc(props.record.inscription, { head: 8, tail: 8 })}
            </NavLink>
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Total Supply",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail className="text-green-600">
            <TokenCount
              token={props.record.tradeToken}
              count={props.record.totalSupply}
            />
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Minted",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.record.tradeToken}
              count={props.record.minted}
            />
            &nbsp; (<PercentNumber number={props.record.mintedPercent} />)
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Limit Per Mint",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount
              token={props.record.tradeToken}
              count={props.record.limitPerMint}
            />
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Decimal",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <CountNumber number={props.record.decimal} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CardInset>
  )
}

export const TokenListRow: FC<{
  className?: string
  record: B20TokenListInfo
}> = props => {
  const { $t } = useIntl()
  const [showInfo, setShowInfo] = useState(false)
  return (
    <Card className={props.className} boxClassName="px-4 py-2.5 space-y-3.5">
      <div className="flex items-center gap-x-6">
        <div className="flex items-center gap-x-3 w-[300px] justify-between">
          <div className="flex items-center space-x-2.5">
            <TokenIconStack
              size={24}
              tradeToken={props.record.tradeToken}
              priceToken={props.record.priceToken}
            />
            <p>
              <TokenNameStack
                tradeToken={props.record.tradeToken}
                priceToken={props.record.priceToken}
              />
            </p>
            <ListStatus status={props.record.status} />
          </div>

          <InfoIconButton
            className="text-white flex items-center"
            icon={InfoIcon}
            activated={showInfo}
            onClick={() => setShowInfo(prev => !prev)}
          >
            {$t(
              defineMessage({
                defaultMessage: "info",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/info text",
              }),
            )}
          </InfoIconButton>
        </div>

        <div className="w-0.5 h-10 bg-white/5 flex-none" />

        <InfoList
          className="space-x-8"
          direction={"row"}
          listItemDirection={"column"}
        >
          <InfoListItem>
            <InfoListItemTitle className="underline">
              {$t(
                defineMessage({
                  defaultMessage: "Target Threshold",
                  description:
                    "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount
                token={props.record.tradeToken}
                count={props.record.minLiquidity}
              />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Peg-in circulation",
                  description:
                    "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount
                token={props.record.priceToken}
                count={props.record.liquidity}
              />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Countdown to Trading: {percentage}",
                  description:
                    "/Orderbook/B20TokenListPage/TokenListFrame/info list item title",
                }),
                {
                  percentage: (
                    <PercentNumber
                      number={props.record.raisingLiquidityPercentage}
                    />
                  ),
                },
              )}
            </InfoListItemTitle>
            <InfoListItemDetail className="h-6 flex items-center">
              <RaisingLiquidityProgress
                percentage={props.record.raisingLiquidityPercentage}
              />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>

        <NavLink
          className={"ml-auto"}
          to={props.record.pegInLink}
          target={"_blank"}
        >
          <WhiteFilledButton
            boxClassName="py-1 px-8 h-6"
            textClassName="text-xs font-medium"
          >
            {$t(
              defineMessage({
                defaultMessage: "Peg in",
                description:
                  "/Orderbook/B20TokenListPage/TokenListFrame/peg in button",
              }),
            )}
          </WhiteFilledButton>
        </NavLink>
      </div>

      {showInfo && <ListInfo record={props.record} />}
    </Card>
  )
}
