import { reverse, sortBy } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { createElement } from "react"
import { defineMessage } from "react-intl"
import { TokenName } from "../../../../components/TokenName"
import { CONTRACT_DEPLOYER } from "../../../../config"
import {
  asSender,
  contractAddr,
} from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { Result } from "../../../../utils/Result"
import {
  Currency,
  isCurrencyOrB20,
  type BRC20Currency,
} from "../../../../utils/alexjs/Currency"
import {
  currencyScale,
  percentageScale,
} from "../../../../utils/alexjs/currencyHelpers"
import {
  ContractCallArgs,
  getContractCallHelpers,
} from "../../../../utils/contractHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { isNotNull } from "../../../../utils/utils"
import { safelyGet, waitFor, waitUntilExist$ } from "../../../../utils/waitFor"
import {
  PegInRequest,
  PegInRequestStatus,
} from "../../components/BRC20/DepositBrc20GuideModalContent/DepositBrc20GuideModalContent"
import {
  DepositBrc20SubmitTransactionIdModalFormError,
  DepositBrc20SubmitTransactionIdModalFormErrorType,
} from "../../components/BRC20/DepositBrc20SubmitTransactionIdModalContent/DepositBrc20SubmitTransactionIdModalContent.types"
import { FormErrorDetail } from "../../components/BRC20/PegOutBrc20ModalContent/PegOutBrc20ModalContent"
import { type OrderbookAsset } from "../OrderbookStore.service/OrderbookStore.service"
import { StxDxInfoModule } from "../stxdx_shared/StxDxInfoModule"
import {
  TemporarilyStoredSignInfoForInitialDeposit,
  getAuthSignatureFromWallet,
} from "../stxdx_shared/StxDxMyInfoModule.service"
import { StxDxStore } from "../stxdx_shared/StxDxStore"
import {
  checkInscriptionTransferable,
  generatePegInBtcAddress,
  getCandidateBtcAddresses,
  getPegInBtcAddress,
  getQueuedPegRequestCount,
  getRecentPegInRecords,
  getTokenFees,
  initialSkipPegInAdditionalChecking,
  isOrdinalWalletAddress,
  requestPegIn,
  requestPegOut,
} from "./Brc20PeggingModule.service"

export interface TokenPegInOutInfos {
  pegInFeeRate: number
  pegOutFeeRate: number
  pegOutGasFee: number
  pegInPaused: boolean
  pegOutPaused: boolean
  token: OrderbookAsset
}

export class Brc20PeggingModule {
  constructor(
    private txStore: ConfirmTransactionStore,
    private infoStore: StxDxInfoModule,
    private authStore: AuthStore,
    private chainStore: ChainStore,
    private accountStore: AccountStore,
    private currencyStore: CurrencyStore,
    private stxDxStore: StxDxStore,
  ) {
    makeObservable(this)
  }

  private pegInBtcAddress = new LazyValue(
    () => ({ stxAddr: this.authStore.stxAddress$ }),
    ({ stxAddr }) => getPegInBtcAddress(asSender(stxAddr), stxAddr),
  )

  @computed
  get isPegInAlexOfficialBtcAddressGenerated$(): boolean {
    return this.pegInBtcAddress.value$ != null
  }

  @computed
  get pegInAlexOfficialBtcAddress$(): string {
    return waitUntilExist$(() => this.pegInBtcAddress.value$)
  }

  @computed
  get pegInUserStxAddress$(): string {
    return this.authStore.stxAddress$
  }

  private queuedPegRequestCount = new LazyValue(
    () => ({
      _block: this.chainStore.currentBlockHeight$,
      stxAddr: this.authStore.stxAddress$,
    }),
    ({ stxAddr }) => getQueuedPegRequestCount(asSender(stxAddr)),
  )

  @computed
  get queuedTransactionCount$(): number {
    return this.queuedPegRequestCount.value$
  }

  async generatePegInBtcAddress(): Promise<void> {
    const hasRegisteredForStxDx = await waitFor(
      () => this.stxDxStore.myInfo.hasRegistered$,
    )
    const addr = await waitFor(() => this.authStore.stxAddress$)
    let publicKey: string | undefined
    if (!hasRegisteredForStxDx) {
      const result = await getAuthSignatureFromWallet(
        addr,
        Math.floor(60 * 60 * 5 + Date.now() / 1000),
      )
      TemporarilyStoredSignInfoForInitialDeposit.saveSig(
        result.payload,
        result.signature,
      )
      publicKey = result.publicKey
    }
    await this.txStore.run(() =>
      generatePegInBtcAddress(asSender(addr), publicKey),
    )
  }

  private allMarketPegInOutInfos = new LazyValue(
    () =>
      this.stxDxStore.info.allMarkets$
        .filter(m => m.isBrc20Token)
        .map(a => a.tradeToken),
    tokens =>
      asSender(CONTRACT_DEPLOYER)
        .contract("b20-bridge-endpoint")
        .func("get-token-details-many")
        .call({ tokens })
        .then(a =>
          a.filter(isNotNull).map((a, i): TokenPegInOutInfos => {
            return {
              pegInFeeRate: a["peg-in-fee"] / percentageScale,
              pegOutFeeRate: a["peg-out-fee"] / percentageScale,
              pegOutGasFee:
                a["peg-out-gas-fee"] / currencyScale(Currency.sUSDT),
              pegInPaused: a["peg-in-paused"],
              pegOutPaused: a["peg-out-paused"],
              token: tokens[i]!,
            }
          }),
        ),
  )

  @computed get allTokenPegInOutInfos$(): TokenPegInOutInfos[] {
    const info = this.allMarketPegInOutInfos.value$
    return reverse(
      sortBy(info, a => this.infoStore.getPegFlowTokenOrder$(a.token) ?? -1),
    )
  }

  private recentPegInRequests = new LazyValue(
    () => ({
      _block: this.chainStore.currentBlockHeight$,
      stxAddr: this.authStore.stxAddress$,
    }),
    ({ stxAddr }) => getRecentPegInRecords(asSender(stxAddr)),
  )

  refreshForMempool = new SuspenseObservable(Math.random())

  private mempoolPegInRequests = new LazyValue(
    () => ({
      _refresh: this.refreshForMempool.read$,
      _block: this.chainStore.currentBlockHeight$,
      mempool: this.accountStore.transactions$,
    }),
    ({ mempool }) =>
      mempool
        .fetchMemPoolTransactions()
        .then(
          (a): ContractCallArgs<["b20-bridge-endpoint"], "request-peg-in">[] =>
            a
              .map(t => {
                if (t.tx_status !== "pending") return null

                if (
                  t.tx_type === "contract_call" &&
                  t.contract_call.contract_id ===
                    contractAddr("b20-bridge-endpoint") &&
                  t.contract_call.function_name === "request-peg-in"
                ) {
                  return {
                    ...getContractCallHelpers(
                      "b20-bridge-endpoint",
                      "request-peg-in",
                      t,
                    ).getArgs(),
                    txId: t.tx_id,
                  }
                }

                if (
                  t.tx_type === "contract_call" &&
                  t.contract_call.contract_id ===
                    contractAddr("b20-bridge-endpoint-helper")
                ) {
                  if (
                    t.contract_call.function_name ===
                    "register-stxdx-and-request-peg-in"
                  ) {
                    return {
                      ...getContractCallHelpers(
                        "b20-bridge-endpoint-helper",
                        "register-stxdx-and-request-peg-in",
                        t,
                      ).getArgs(),
                      txId: t.tx_id,
                    }
                  }
                  if (
                    t.contract_call.function_name ===
                    "register-and-request-peg-in"
                  ) {
                    return {
                      ...getContractCallHelpers(
                        "b20-bridge-endpoint-helper",
                        "register-and-request-peg-in",
                        t,
                      ).getArgs(),
                      txId: t.tx_id,
                    }
                  }
                }

                return null
              })
              .filter(isNotNull),
        ),
  )

  @computed get recentPegInRequests$(): PegInRequest[] {
    const pending = this.mempoolPegInRequests.value$
      .map((r, i) => {
        const currency = r.token

        if (!isCurrencyOrB20(currency)) return null

        return {
          id: `M-${i}`,
          token: this.currencyStore.getTokenInfo$(currency),
          amount: r.amount / currencyScale(currency),
          pegInTransactionId: r.memo,
          status: PegInRequestStatus.Mempool,
          pegInAddress: this.pegInAlexOfficialBtcAddress$,
        }
      })
      .filter(isNotNull)

    const confirmed = this.recentPegInRequests.value$.map(r => ({
      id: r.id,
      token: this.currencyStore.getTokenInfo$(r.currency),
      amount: r.amount,
      pegInTransactionId: r.pegInTransactionId,
      status: r.status,
      pegInAddress: this.pegInAlexOfficialBtcAddress$,
    }))

    return [...pending, ...confirmed]
  }

  pegInForm = new PegInFormModule(
    this.accountStore,
    this.currencyStore,
    this.authStore,
    this,
  )

  pegOutForm = new PegOutFormModule(
    this.accountStore,
    this.currencyStore,
    this.authStore,
    this,
  )

  async pegInToken(formData: PegInFormData): Promise<void> {
    const stacksAddr = await waitFor(() => this.authStore.stxAddress$)
    await this.txStore.run(async () =>
      requestPegIn(
        asSender(stacksAddr),
        stacksAddr,
        await waitFor(() => this.stxDxStore.myInfo.hasRegistered$),
        await waitFor(() => this.isPegInAlexOfficialBtcAddressGenerated$),
        formData,
      ),
    )
  }

  async pegOutToken(formData: PegOutFormData): Promise<{ txId: string }> {
    const hasRegisteredForB20PegIn = await waitFor(
      () => this.isPegInAlexOfficialBtcAddressGenerated$,
    )
    return requestPegOut(
      asSender(await waitFor(() => this.authStore.stxAddress$)),
      await waitFor(() => this.authStore.stxAddress$),
      formData.currency,
      formData.amount,
      formData.toBtcAddr,
      formData.gasFeeCurrency,
      formData.gasFeeAmount,
      hasRegisteredForB20PegIn,
    )
  }
}

interface PegInFormData {
  currency: BRC20Currency
  amount: number
  inscriptionNumber: string
}
class PegInFormModule {
  constructor(
    private accountStore: AccountStore,
    private currencyStore: CurrencyStore,
    private authStore: AuthStore,
    private peggingModule: Brc20PeggingModule,
  ) {
    makeObservable(this)
  }

  @observable private skipPegInAdditionalChecking =
    initialSkipPegInAdditionalChecking

  @observable isSelectingToken = false
  @action
  onStartSelectToken(): void {
    this.isSelectingToken = true
  }
  @action
  onSelectedToken(currency: BRC20Currency): void {
    this.currency.set(currency)
    this.isSelectingToken = false
  }
  @action
  onCancelledSelectToken(): void {
    this.isSelectingToken = false
  }

  currency = new SuspenseObservable<BRC20Currency>()
  amount = new SuspenseObservable<number>()
  inscriptionNumber = new SuspenseObservable<string>()

  @computed get selectedTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.currency.read$)
  }

  @computed get availablePegInCurrencies$(): OrderbookAsset[] {
    return this.peggingModule.allTokenPegInOutInfos$
      .filter(a => !a.pegInPaused)
      .map(a => a.token)
  }

  reset(): void {
    this.skipPegInAdditionalChecking = false
    this.currency.set(undefined)
    this.amount.set(undefined)
    this.inscriptionNumber.set(undefined)
  }

  private tokenFees = new LazyValue(
    () => ({
      currency: this.currency.read$,
      stxAddr: this.authStore.stxAddress$,
    }),
    ({ stxAddr, currency }) => getTokenFees(asSender(stxAddr), currency),
  )
  private inscriptionTransferable = new LazyValue(
    () => ({
      inscriptionId: this.inscriptionNumber.read$,
      receivingAddress: safelyGet(
        () => this.peggingModule.pegInAlexOfficialBtcAddress$,
      ),
      receivingAmount: this.amount.read$,
      skipChecking: this.skipPegInAdditionalChecking,
    }),
    async ({
      inscriptionId,
      receivingAddress,
      receivingAmount,
      skipChecking,
    }) =>
      skipChecking
        ? true
        : checkInscriptionTransferable(
            Number(inscriptionId),
            receivingAddress,
            receivingAmount,
          ),
  )

  @computed
  get pegInFeeToken$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.currency.read$)
  }

  @computed
  get pegInFeeRate$(): number {
    return this.tokenFees.value$.pegInFeeRate
  }

  @computed
  get pegInFeeAmount$(): number {
    return this.tokenFees.value$.pegInFeeRate * this.amount.read$
  }

  @computed get formData$(): Result<
    PegInFormData,
    DepositBrc20SubmitTransactionIdModalFormError
  > {
    if (!this.amount.get()) {
      return Result.error({
        type: DepositBrc20SubmitTransactionIdModalFormErrorType.AmountIsRequired,
      })
    }
    if (!this.currency.get()) {
      return Result.error({
        type: DepositBrc20SubmitTransactionIdModalFormErrorType.TokenIsRequired,
      })
    }
    if (!this.inscriptionNumber.get()) {
      return Result.error({
        type: DepositBrc20SubmitTransactionIdModalFormErrorType.InscriptionNumberIsRequired,
      })
    }
    if (!this.inscriptionTransferable.value$) {
      return Result.error({
        type: DepositBrc20SubmitTransactionIdModalFormErrorType.InscriptionNotTransferable,
      })
    }

    return Result.ok({
      currency: this.currency.read$,
      amount: this.amount.read$,
      inscriptionNumber: this.inscriptionNumber.read$,
    })
  }
}

interface PegOutFormData {
  currency: BRC20Currency
  amount: number
  toBtcAddr: string
  gasFeeCurrency: Currency
  gasFeeAmount: number
}
class PegOutFormModule {
  constructor(
    private accountStore: AccountStore,
    private currencyStore: CurrencyStore,
    private authStore: AuthStore,
    private peggingModule: Brc20PeggingModule,
  ) {
    makeObservable(this)
  }

  @observable isSelectingToken = false
  @action
  onStartSelectToken(): void {
    this.isSelectingToken = true
  }
  @action
  onSelectedToken(currency: BRC20Currency): void {
    this.currency.set(currency)
    this.isSelectingToken = false
  }
  @action
  onCancelledSelectToken(): void {
    this.isSelectingToken = false
  }

  currency = new SuspenseObservable<BRC20Currency>()
  amount = new SuspenseObservable<number>()
  toBtcAddr = new SuspenseObservable<string>()

  reset(): void {
    this.currency.set(undefined)
    this.amount.set(undefined)
    this.toBtcAddr.set(undefined)
  }

  private candidateBtcAddresses = new LazyValue(
    () => ({}),
    () => getCandidateBtcAddresses(),
  )

  @computed
  get candidateBtcAddress$(): undefined | string {
    return this.candidateBtcAddresses.value$[0]
  }

  @computed get availablePegOutCurrencies$(): OrderbookAsset[] {
    return this.peggingModule.allTokenPegInOutInfos$
      .filter(a => !a.pegOutPaused)
      .map(a => a.token)
  }

  @computed
  get pegOutFeeToken$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.currency.read$)
  }

  private pegFeeRate = new LazyValue(
    () => ({
      currency: this.currency.read$,
      stxAddr: this.authStore.stxAddress$,
    }),
    ({ stxAddr, currency }) => getTokenFees(asSender(stxAddr), currency),
  )
  @computed
  get pegOutFee$(): number {
    return this.pegFeeRate.value$.pegOutFeeRate * this.amount.read$
  }

  @computed
  get pegOutFeeRate$(): number {
    return this.pegFeeRate.value$.pegOutFeeRate
  }

  @computed
  get pegOutGasFeeToken$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(Currency.sUSDT)
  }

  @computed
  get pegOutGasFee$(): number {
    return this.pegFeeRate.value$.pegOutGasFee
  }

  @computed
  get formData$(): Result<PegOutFormData, FormErrorDetail> {
    if (this.currency.get() == null) {
      return Result.error(
        defineMessage({
          defaultMessage: "BRC-20 Token is Required",
          description: "Orderbook/PegOutBrc20TokenForm/error message",
        }),
      )
    }

    if (this.amount.get() == null || this.amount.get() === 0) {
      return Result.error(
        defineMessage({
          defaultMessage: "Amount is Required",
          description: "Orderbook/PegOutBrc20TokenForm/error message",
        }),
      )
    }

    if (this.toBtcAddr.get() == null || this.toBtcAddr.get() === "") {
      return Result.error(
        defineMessage({
          defaultMessage: "Bitcoin Address is Required",
          description: "Orderbook/PegOutBrc20TokenForm/error message",
        }),
      )
    }

    if (!isOrdinalWalletAddress(this.toBtcAddr.read$.toLowerCase())) {
      return Result.error(
        defineMessage({
          defaultMessage: "Unsupported Bitcoin Address",
          description: "Orderbook/PegOutBrc20TokenForm/error message",
        }),
      )
    }

    if (this.accountStore.getBalance$(Currency.sUSDT) < this.pegOutGasFee$) {
      const tokenInfo = this.currencyStore.getTokenInfo$(Currency.sUSDT)
      return Result.error(intl =>
        intl.$t(
          defineMessage({
            defaultMessage: "Insufficient {gasFeeToken} Balance",
            description: "Orderbook/PegOutBrc20TokenForm/error message",
          }),
          { gasFeeToken: createElement(TokenName, { token: tokenInfo }) },
        ),
      )
    }

    return Result.ok({
      currency: this.currency.read$,
      amount: this.amount.read$,
      toBtcAddr: this.toBtcAddr.read$,
      gasFeeCurrency: Currency.sUSDT,
      gasFeeAmount: this.pegOutGasFee$,
    })
  }
}
