import { FC, ReactNode } from "react"
import { RoundedButton } from "../button/RoundedButton"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { CardModalContent } from "../CardModal/CardModal"

export interface AlertDialogModalContentProps {
  titleText?: string
  className?: string
  message: ReactNode
  onConfirm: () => void
  confirmText?: string
}

export const AlertDialogModalContent: FC<
  AlertDialogModalContentProps
> = props => {
  return (
    <CardModalContent
      className={"min-w-[480px]"}
      title={props.titleText ?? "Confirm"}
    >
      <div
        className={
          "pt-[24px] pb-[30px] w-full flex items-center justify-center text-xl leading-7 font-normal text-gray-200"
        }
      >
        {props.message}
      </div>
      <div className={"flex gap-2.5"}>
        <RoundedButton
          className={"flex-1"}
          roundedClassName={"rounded-full"}
          Variant={GradientFilledButton}
          onClick={props.onConfirm}
        >
          {props.confirmText ?? "Okay"}
        </RoundedButton>
      </div>
    </CardModalContent>
  )
}
