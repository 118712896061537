import { createStore } from "../../utils/createStore"
import { FCC } from "../../utils/reactHelpers/types"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { LongTextStore } from "./LongTextStore"

const Store = createStore<LongTextStore>("LongTextStore")

export const LongTextStoreProvider: FCC = props => {
  const store = useCreation(() => new LongTextStore(), [])
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useLongTextStore = Store.useStore.bind(null)
