import { action, computed, makeObservable, observable } from "mobx"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { MemoValue } from "../../../stores/MemoValue"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { BRC20Currency } from "../../../utils/alexjs/Currency"
import { hasAny } from "../../../utils/arrayHelpers"
import { type OneOrMore } from "../../../utils/types"
import { WithdrawRequest } from "../wiredComponents/WiredTradingAccountContent"
import {
  getBrc20MarketInfo,
  type OrderbookAsset,
} from "./OrderbookStore.service/OrderbookStore.service"
import { Brc20PeggingModule } from "./modules/Brc20PeggingModule"
import { OrderbookAccountSettingModule } from "./modules/OrderbookAccountSettingModule"
import { OrderbookLongTextI18nModule } from "./modules/OrderbookLongTextI18nModule"
import { OrderbookMyHistoryModule } from "./modules/OrderbookMyHistoryModule"
import { OrderbookOrderbookModule } from "./modules/OrderbookOrderbookModule"
import { OrderbookTradeModule } from "./modules/OrderbookTradeModule"
import { StxDxDepositModule } from "./modules/StxDxDepositModule"
import { StxDxWithdrawModule } from "./modules/StxDxWithdrawModule"
import { ChartDatafeedModule } from "./stxdx_shared/ChartDatafeedModule"
import { StxDxInfoModule } from "./stxdx_shared/StxDxInfoModule"
import * as OrderbookInfoModuleService from "./stxdx_shared/StxDxInfoModule.service"
import { MarketTotalVolume } from "./stxdx_shared/StxDxInfoModule.service"
import type {
  OrderbookMarket,
  OrderbookMarketId,
} from "./stxdx_shared/StxDxMarket.service"
import { StxDxMyInfoModule } from "./stxdx_shared/StxDxMyInfoModule"
import type { MyStatsPoints } from "./stxdx_shared/StxDxMyInfoModule.service"
import { StxDxStore } from "./stxdx_shared/StxDxStore"

export class OrderbookStore {
  constructor(
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly currency: CurrencyStore,
    readonly account: AccountStore,
    readonly stxDxStore: StxDxStore,
  ) {
    makeObservable(this)
  }

  destroy(): void {
    this.accountSetting.destroy()
  }

  market = new SuspenseObservable<OrderbookMarket>()

  @computed get marketId$(): OrderbookMarketId {
    return this.market.read$.marketId
  }

  brc20MarketInfo = new LazyValue(
    () => ({ marketId: this.marketId$ }),
    ({ marketId }) => getBrc20MarketInfo(marketId),
  )

  @action setMarket(market: OrderbookMarket): void {
    this.market.set(market)

    this.buyFormModule = new OrderbookTradeModule(this, "buy")
    this.sellFormModule = new OrderbookTradeModule(this, "sell")
  }

  txStore = new ConfirmTransactionStore()

  longTextI18n = new OrderbookLongTextI18nModule()

  @computed get currentPrice$(): number {
    return this.currentMarketSummary$.price
  }

  @computed get lastPrice$(): number {
    return this.currentMarketSummary$.lastPrice
  }

  @computed
  get currentMarketSummary$(): OrderbookInfoModuleService.MarketSummary &
    MarketTotalVolume {
    const info = this.stxDxStore.info
    return {
      ...info.marketSummary$(this.marketId$),
      ...info.marketTotalVolume(this.marketId$).value$,
    }
  }

  private memoCurrentPrice = new MemoValue(() => this.currentPrice$)
  @computed get currentPriceDeltaPercentage$(): number {
    return (
      (this.memoCurrentPrice.previousValue ?? 0 - this.currentPrice$) /
      this.currentPrice$
    )
  }

  get info(): StxDxInfoModule {
    return this.stxDxStore.info
  }

  get myInfo(): StxDxMyInfoModule {
    return this.stxDxStore.myInfo
  }

  @computed get myPointStats$(): MyStatsPoints {
    return this.myInfo.pointsStats.value$
  }

  orderbook = new OrderbookOrderbookModule(this)

  get deposit(): StxDxDepositModule {
    return this.stxDxStore.deposit
  }
  get withdraw(): StxDxWithdrawModule {
    return this.stxDxStore.withdraw
  }

  @observable.ref buyFormModule = new OrderbookTradeModule(this, "buy")
  @observable.ref sellFormModule = new OrderbookTradeModule(this, "sell")

  brc20Pegging = new Brc20PeggingModule(
    this.txStore,
    this.info,
    this.authStore,
    this.chainStore,
    this.account,
    this.currency,
    this.stxDxStore,
  )
  myHistory = new OrderbookMyHistoryModule(this)
  accountSetting = new OrderbookAccountSettingModule(this)
  chartDatafeed = new ChartDatafeedModule(this)

  depositFlow = new DepositFlowModule(this.stxDxStore.myInfo, this.brc20Pegging)
  withdrawFlow = new WithdrawFlowModule(this.withdraw)
}

type DepositFlowStandardStepType =
  | "entry"
  | "depositBrc20Guide"
  | "depositStacksTokens"
class DepositFlowModule {
  constructor(
    private myInfo: StxDxMyInfoModule,
    private peggingModule: Brc20PeggingModule,
  ) {
    makeObservable(this)
  }

  @computed private get hasRegistered$(): boolean {
    return this.myInfo.userId$ != null
  }

  @computed get isFlowStarted(): boolean {
    return this.step != null
  }
  @computed get agreementModalVisible(): boolean {
    return this.step?.type === "agreement"
  }
  @computed get entryModalVisible(): boolean {
    return this.isFlowStarted && !this.agreementModalVisible
  }
  @computed get pegInBrc20TokensModalVisible(): boolean {
    return this.step?.type === "depositBrc20Guide"
  }
  @computed get depositStacksTokensModalVisible(): boolean {
    return this.step?.type === "depositStacksTokens"
  }

  @observable private step:
    | null
    | { type: "agreement"; next: DepositFlowStandardStepType }
    | { type: DepositFlowStandardStepType } = null

  @observable initialPegInCurrency: BRC20Currency | null = null

  @computed get onStartFlow$(): (info?: {
    depositCurrency?: OrderbookAsset
    pegInCurrency?: BRC20Currency
    startFrom?: DepositFlowStandardStepType
  }) => void {
    const hasRegistered = this.hasRegistered$

    return action("onStartFlow", (info = {}) => {
      const startFrom = info.startFrom ?? "entry"

      if (!hasRegistered) {
        this.step = { type: "agreement", next: startFrom }
      } else {
        this.step = { type: startFrom }
      }

      if (info.depositCurrency != null) {
        this.myInfo.currency.set(info.depositCurrency)
      }

      if (info.pegInCurrency != null) {
        this.peggingModule.pegInForm.onSelectedToken(info.pegInCurrency)
        this.initialPegInCurrency = info.pegInCurrency
      }
    })
  }

  @action
  onQuitFlow(): void {
    this.step = null
    this.initialPegInCurrency = null
  }

  @action
  onStartPegInBrc20Tokens(): void {
    this.step = { type: "depositBrc20Guide" }
  }
  @action
  onFinishedDepositBrc20Tokens(): void {
    this.step = { type: "entry" }
  }

  @action
  onStartDepositStxTokens(): void {
    this.step = { type: "depositStacksTokens" }
  }
  @action
  onCancelDepositStxTokens(): void {
    this.step = { type: "entry" }
  }
  @action
  onFinishedDepositStxTokens(): void {
    this.step = { type: "entry" }
  }

  @action
  onAcceptAgreement(): void {
    if (this.step?.type === "agreement") {
      this.step = { type: this.step.next }
    }
  }
  @action
  onDismissAgreement(): void {
    this.step = null
  }
}

class WithdrawFlowModule {
  constructor(private withdraw: StxDxWithdrawModule) {
    makeObservable(this)
  }

  @computed get isFlowStarted(): boolean {
    return this.step != null
  }
  @computed get entryModalVisible(): boolean {
    return this.isFlowStarted
  }
  @computed get withdrawStacksTokensModalVisible(): boolean {
    return this.step?.type === "withdrawStacksTokens"
  }
  @computed get pegOutBrc20TokensModalVisible(): boolean {
    return this.step?.type === "pegOutBrc20Tokens"
  }
  @computed get withdrawDuplicateWarningModalVisibleWithRequests():
    | undefined
    | OneOrMore<WithdrawRequest> {
    if (this.step?.type === "withdrawDuplicateWarning") {
      return this.step.requests
    }
  }

  @observable step:
    | null
    | { type: "entry" }
    | { type: "withdrawDuplicateWarning"; requests: OneOrMore<WithdrawRequest> }
    | { type: "withdrawStacksTokens" }
    | { type: "pegOutBrc20Tokens" } = null

  @action
  onStartFlow(): void {
    this.step = { type: "entry" }
  }

  @action
  onQuitFlow(): void {
    this.step = null
  }

  @action
  onStartPegOutBrc20Tokens(): void {
    this.step = { type: "pegOutBrc20Tokens" }
  }

  @action
  onCanceledPegOutBrc20Tokens(): void {
    this.step = { type: "entry" }
  }
  @action
  onFinishedPegOutBrc20Tokens(): void {
    this.step = { type: "entry" }
  }

  @action
  onWithdrawDuplicateWarning(
    withdrawRequests: OneOrMore<WithdrawRequest>,
  ): void {
    this.step = { type: "withdrawDuplicateWarning", requests: withdrawRequests }
  }

  @computed
  get onStartWithdrawStxTokens$(): { run: () => void } {
    const recentWithdrawRequests = this.withdraw.recentWithdrawRequest$

    if (hasAny(recentWithdrawRequests)) {
      return {
        run: () => this.onWithdrawDuplicateWarning(recentWithdrawRequests),
      }
    } else {
      return { run: () => this.onStartWithdrawStxTokensWithoutWarning() }
    }
  }

  @action onStartWithdrawStxTokensWithoutWarning(): void {
    this.step = { type: "withdrawStacksTokens" }
  }

  @action
  onCancelWithdrawStxTokens(): void {
    this.step = { type: "entry" }
  }
  @action
  onFinishedWithdrawStxTokens(): void {
    this.step = { type: "entry" }
  }
}
