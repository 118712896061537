import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { cancel$t, confirm$t } from "../../../../commonIntlMessages"
import { CardDescription } from "../../../../components/Card"
import {
  ActionRow,
  CardModalContent,
} from "../../../../components/CardModal/CardModal"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import {
  TokenBlock,
  TokenCountPair,
} from "../../../../components/TokenInput/TokenBlock"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { RectButton } from "../wrappedCommonComponents/RectButton"

export const TokensDepositConfirmModalContent: FC<{
  tokens: TokenCountPair[]
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Deposit",
          description: `Orderbook/DepositConfirmModal/title`,
        }),
      )}
      onClose={props.onClose}
    >
      <CardDescription>
        {$t(
          defineMessage({
            defaultMessage: "Transfer into your trading account:",
            description: `Orderbook/DepositConfirmModal/prompt text`,
          }),
        )}
      </CardDescription>

      <TokenBlock tokens={props.tokens} />

      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage: "You can withdraw your available balance any time",
            description: `Orderbook/DepositConfirmModal/tip text`,
          }),
        )}
      </NoteParagraph>

      <ActionRow>
        <RectButton
          className={"flex-1"}
          Variant={WhiteFilledButton}
          onClick={props.onCancel}
        >
          {$t(cancel$t)}
        </RectButton>
        <RectButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          onClick={props.onConfirm}
        >
          {$t(confirm$t)}
        </RectButton>
      </ActionRow>
    </CardModalContent>
  )
}
