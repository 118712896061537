import React, { FC } from "react"
import { Route, Routes } from "react-router-dom"
import { NotFoundPage } from "./components/GlobalErrorBoundaryPage"
import { WiredNewBrandWrapBridgePageLayout } from "./pages/NewBrandWrapBridgePage/components/NewBrandWrapBridgePageLayout/WiredNewBrandWrapBridgePageLayout"
import { NewBrandWrapBridgePage } from "./pages/NewBrandWrapBridgePage/NewBrandWrapBridgePage"

export const AppRoutes$StackBridgeStandalone: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <WiredNewBrandWrapBridgePageLayout>
            <NewBrandWrapBridgePage />
          </WiredNewBrandWrapBridgePageLayout>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
