import { FC } from "react"
import { DepositRolloverRowData } from "../../../../store/transformerGroups/lend/depositRollover"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { OneLineAmount } from "../../OneLineAmount"
import { BaseRow } from "../BaseRow"

export const DepositRolloverRow: FC<{
  rowData: DepositRolloverRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.yieldCurrencyAmount]}
        toCurrencyAmounts={[rowData.yieldCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <BaseCellContainer>
        {rowData.rollovers.map((r, i) => (
          <>
            <OneLineAmount
              key={i}
              fromCurrencyAmounts={[r.from]}
              toCurrencyAmounts={[r.to]}
              allTokenInfos={allTokenInfos}
            />
          </>
        ))}
      </BaseCellContainer>
    }
    tradeInfoCell={
      <BaseCellContainer>#{rowData.expiryToRoll}</BaseCellContainer>
    }
  />
)
