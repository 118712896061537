import { FC, ReactNode } from "react"
import { defineMessage, IntlFormatters, useIntl } from "react-intl"
import { useAPowerRatio } from "../../../components/APowerTip/APowerTip"
import { MayDollarSignTokenName } from "../../../components/MayDollarSignTokenName"
import { TokenName } from "../../../components/TokenName"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { readResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { CopywritingProviderValue } from "../../Stake/components/CopywritingProvider"

export class FarmingCopywriting implements CopywritingProviderValue {
  constructor(readonly intl: IntlFormatters<ReactNode>) {}

  aPowerAlertContent = (<APowerAlertContent />)
  cycleTip = this.intl.$t(
    defineMessage({
      defaultMessage:
        "Farming locks up the set amount in the contract for the selected number of reward cycles.",
      description: "/Farming/CopyWriting cycle tip",
    }),
  )

  myStaking = {
    panelTitle: this.intl.$t(
      defineMessage({
        defaultMessage: "My Farming",
        description: "/Farming/CopyWriting",
      }),
    ),
    activeStaking: this.intl.$t(
      defineMessage({
        defaultMessage: "Active farming LP",
        description: "/Farming/CopyWriting",
      }),
    ),
    averageAPRTip: this.intl.$t(
      defineMessage({
        defaultMessage:
          "The average APR is calculated based on your Farming cycles.",
        description: "/Farming/CopyWriting",
      }),
    ),
    principalClaim: this.intl.$t(
      defineMessage({
        defaultMessage: "LP to claim",
        description: "/Farming/CopyWriting",
      }),
    ),
    cycleTip: this.cycleTip,
    toBeStaked: {
      finishedStatus: this.intl.$t(
        defineMessage({
          defaultMessage: "Farmed",
          description: "/Farming/CopyWriting",
        }),
      ),
      currentState: this.intl.$t(
        defineMessage({
          defaultMessage: "Farming",
          description: "/Farming/CopyWriting",
        }),
      ),
      upcomingStatus: this.intl.$t(
        defineMessage({
          defaultMessage: "To be farmed",
          description: "/Farming/CopyWriting",
        }),
      ),
    },
  }

  addStaking = {
    panelTitle: this.intl.$t(
      defineMessage({
        defaultMessage: "Add LP Staking",
        description: "/Farming/CopyWriting",
      }),
    ),
    cycleTip: this.cycleTip,
  }

  principalTokenInfoUpdater = (tokenInfo: TokenInfo): TokenInfo => ({
    ...tokenInfo,
    displayName: "LP",
  })
}

const APowerAlertContent: FC = () => {
  const { displayableRatio } = useAPowerRatio()
  const currency = useCurrencyStore()
  const { $t } = useIntl()

  return (
    <>
      {$t(
        defineMessage({
          defaultMessage:
            "The amount of {token} (IDO access) received for yield farming will be {ratio}x the amount of {signToken} rewarded.",
          description: "/Farm/A Power Alert Content",
        }),
        {
          token: <TokenName token={TokenInfoPresets.APower} />,
          ratio: readResource(displayableRatio),
          signToken: (
            <MayDollarSignTokenName
              token={currency.getTokenInfo$(Currency.ALEX)}
            />
          ),
        },
      )}
    </>
  )
}
