export const scrollIntoView = (
  element: HTMLElement,
  options: { offset?: number } = {},
): void => {
  const { offset = 0 } = options

  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}
