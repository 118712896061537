import {
  isStacksWalletInstalled,
  useConnect as useStackConnect,
} from "@stacks/connect-react"
import {
  FC,
  ReactChild,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { isMobileDevice } from "../../utils/domHelpers/browserEnv"
import { ConnectXverseWalletDialog } from "./ConnectXverseWalletDialog"

const ConnectContext = createContext<{
  connect: (type?: "browser" | "wallet-connect" | "okx-wallet") => void
} | null>(null)

export const ConnectProvider: FC<{ children: ReactChild }> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const { doOpenAuth } = useStackConnect()
  const auth = useAuthStore()

  const connect = useCallback(
    (type?: "browser" | "wallet-connect" | "okx-wallet") => {
      if (type === "browser") {
        if (isMobileDevice() && !isStacksWalletInstalled()) {
          return setVisible(true)
        }
        doOpenAuth()
      } else if (type === "wallet-connect") {
        void auth.walletConnectSession.connect()
      } else if (type === "okx-wallet") {
        void auth.okxWalletSession.connect()
      } else {
        auth.showWalletSelector()
      }
    },
    [doOpenAuth, auth],
  )

  return (
    <>
      <ConnectContext.Provider value={{ connect }}>
        {children}
      </ConnectContext.Provider>
      <ConnectXverseWalletDialog
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export const useConnect = (): [
  (type?: "browser" | "wallet-connect" | "okx-wallet") => void,
] => {
  const context = useContext(ConnectContext)
  if (!context) {
    throw new Error("[useConnect] must be used inside a ConnectProvider")
  }
  return [context.connect]
}
