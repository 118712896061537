import {
  createFungiblePostCondition,
  createSTXPostCondition,
  FungibleConditionCode,
  FungiblePostCondition,
  STXPostCondition,
} from "@stacks/transactions"
import BigNumber from "bignumber.js"
import { contractAddr } from "../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "./AMMSwapPool"
import {
  CurrencyAndBRC20s,
  DownstreamCurrency,
  WrappedCurrency,
} from "./Currency"
import { currencyScale, getAssetIdentifierByCurrency } from "./currencyHelpers"

export const AlexVault = contractAddr("alex-vault")
export const AlexVaultV1_1 = contractAddr("alex-vault-v1-1")

export function transfer(
  senderAddress: string,
  currency: CurrencyAndBRC20s,
  amount: number,
  compare: FungibleConditionCode = FungibleConditionCode.Equal,
): FungiblePostCondition | STXPostCondition {
  const unwrappedCurrency = WrappedCurrency.safeUnwrap(currency)

  const scale = currencyScale(unwrappedCurrency)
  const roundedAmount = new BigNumber(amount)
    .multipliedBy(scale)
    .decimalPlaces(
      0,
      compare === FungibleConditionCode.LessEqual
        ? BigNumber.ROUND_UP
        : BigNumber.ROUND_DOWN,
    )
    .toString()
  if (unwrappedCurrency === DownstreamCurrency.STX) {
    return createSTXPostCondition(
      senderAddress,
      compare,
      roundedAmount.toString(),
    )
  }
  const assetIdentifier = AMMSwapPool.isPoolToken(currency)
    ? AMMSwapPool.getAssetIdentifier(currency)
    : getAssetIdentifierByCurrency(unwrappedCurrency)
  return createFungiblePostCondition(
    senderAddress,
    compare,
    roundedAmount.toString(),
    assetIdentifier,
  )
}
