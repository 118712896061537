import {
  booleanT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const fwpWstxAlexTranched64 = defineContract({
  "fwp-wstx-alex-tranched-64": {
    "add-approved-contract": {
      input: [{ name: "new-approved-contract", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "add-to-position": {
      input: [{ name: "dx", type: numberT }],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
    "claim-and-add-to-position": {
      input: [{ name: "reward-cycle", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "claim-and-add-to-position-many": {
      input: [{ name: "reward-cycles", type: listT(numberT) }],
      output: responseSimpleT(
        listT(
          responseSimpleT(
            tupleT({ "entitled-token": numberT, "to-return": numberT }),
          ),
        ),
      ),
      mode: "public",
    },
    "claim-and-stake": {
      input: [{ name: "reward-cycle", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    distribute: {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
        { name: "recipients", type: listT(principalT) },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "reduce-position": {
      input: [],
      output: responseSimpleT(
        tupleT({
          "alex-residual": numberT,
          "stx-residual": numberT,
          "stx-to-return": numberT,
        }),
      ),
      mode: "public",
    },
    "set-approved-contract": {
      input: [
        { name: "owner", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-available-alex": {
      input: [{ name: "new-amount", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-bounty-in-fixed": {
      input: [{ name: "new-bounty-in-fixed", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-end-cycle": {
      input: [{ name: "new-end-cycle", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-open-to-all": {
      input: [{ name: "new-open-to-all", type: booleanT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-shortfall-coverage": {
      input: [{ name: "new-shortfall-coverage", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-start-block": {
      input: [{ name: "new-start-block", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-available-alex": { input: [], output: numberT, mode: "readonly" },
    "get-borrowed-alex": { input: [], output: numberT, mode: "readonly" },
    "get-bounty-in-fixed": {
      input: [],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-distributable-per-cycle-or-default": {
      input: [{ name: "cycle", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-distributed-per-cycle-or-default": {
      input: [{ name: "cycle", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-end-cycle": { input: [], output: numberT, mode: "readonly" },
    "get-open-to-all": { input: [], output: booleanT, mode: "readonly" },
    "get-shortfall-coverage": {
      input: [],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-start-block": { input: [], output: numberT, mode: "readonly" },
    "get-total-balance-per-cycle-or-default": {
      input: [{ name: "cycle", type: numberT }],
      output: numberT,
      mode: "readonly",
    },
    "get-total-stx": { input: [], output: numberT, mode: "readonly" },
    "get-user-balance-per-cycle-or-default": {
      input: [
        { name: "user", type: principalT },
        { name: "cycle", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-user-distributed-per-cycle-or-default": {
      input: [
        { name: "user", type: principalT },
        { name: "cycle", type: numberT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "get-user-stx-or-default": {
      input: [{ name: "user", type: principalT }],
      output: numberT,
      mode: "readonly",
    },
    "is-cycle-batch-processed": {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "is-cycle-bountiable": {
      input: [{ name: "reward-cycle", type: numberT }],
      output: booleanT,
      mode: "readonly",
    },
    "approved-contracts": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "distributable-per-cycle": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "distributed-per-cycle": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "processed-batches": {
      input: tupleT({ batch: numberT, cycle: numberT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "total-balance-per-cycle": {
      input: numberT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "user-balance-per-cycle": {
      input: tupleT({ cycle: numberT, user: principalT }),
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "user-distributed-per-cycle": {
      input: tupleT({ cycle: numberT, user: principalT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "user-stx": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "available-alex": { input: noneT, output: numberT, mode: "variable" },
    "borrowed-alex": { input: noneT, output: numberT, mode: "variable" },
    "bounty-in-fixed": { input: noneT, output: numberT, mode: "variable" },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    "end-cycle": { input: noneT, output: numberT, mode: "variable" },
    "open-to-all": { input: noneT, output: booleanT, mode: "variable" },
    "shortfall-coverage": { input: noneT, output: numberT, mode: "variable" },
    "start-block": { input: noneT, output: numberT, mode: "variable" },
    "total-stx": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
