import clsx from "clsx"
import { FC } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { RootLayoutContainerProvider } from "../../components/LayoutContainer/RootLayoutContainer"
import { MediaLinks } from "../../components/MediaLinks/MediaLinks"
import { NavLink } from "../../components/NavLink"
import { PageContentErrorBoundary } from "../../components/PageContentErrorBoundary"
import { TopNavBarHeight } from "../../components/TopNavBar/TopNavBar"
import { usePathGenerator } from "../../routes/routes"
import styles from "../Layout/Layout.module.scss"
import { WiredBugBounty } from "../components/BugBounty/BugBounty"
import { ReactComponent as AlexLogo } from "./alexLogo.svg"
import { ReactComponent as AlexLogoText } from "./alexLogoText.svg"

const TWITTER_LINK = "https://twitter.com/alexgobtc"
const DISCORD_LINK = "https://discord.gg/alexlab"

export const CRPSimulatorLayout: FC = () => {
  const gen = usePathGenerator()
  const { key: locationKey } = useLocation()

  return (
    <div>
      <div className={"fixed top-0 left-0 right-0 z-1"}>
        <div
          className={
            "flex items-center py-5 px-4 sm:px-8 backdrop-blur-lg justify-between gap-1 relative z-1"
          }
          style={{
            height: TopNavBarHeight,
            background: "rgba(17, 24, 39, 0.2)",
          }}
        >
          <NavLink
            className={"flex items-center cursor-pointer gap-1.5"}
            to={gen.index()}
          >
            <AlexLogo width={32} height={32} />
            <AlexLogoText className="hidden md:block" width={106} height={28} />
          </NavLink>

          <MediaLinks
            links={{
              twitter: TWITTER_LINK,
              discord: DISCORD_LINK,
            }}
          />
        </div>
      </div>

      <RootLayoutContainerProvider
        className={"pb-8 sm:py-[8.75rem] grow"}
        topNavHeight={TopNavBarHeight}
        topExtraPadding={16}
      >
        <PageContentErrorBoundary key={locationKey}>
          <Outlet />
        </PageContentErrorBoundary>
      </RootLayoutContainerProvider>

      <div
        className={clsx(
          styles["widgets-container"],
          "sm:fixed bottom-0 left-0 right-0 mt-16 p-5 flex items-end",
        )}
      >
        <WiredBugBounty className={"ml-auto"} />
      </div>
    </div>
  )
}
