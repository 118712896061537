import clsx from "clsx"
import React, { FC } from "react"
import { PlainIconButton } from "../../button/variants/PlainIconButton"
import { ReactComponent as BellIcon } from "./bell.svg"
import { NotificationCount } from "./NotificationCount"

export interface NotificationButtonProps {
  className?: string
  count?: number
  onClick?: () => void
}

export const NotificationButton: FC<NotificationButtonProps> = props => (
  <div
    className={clsx(
      props.className,
      "relative cursor-pointer inline-block text-white",
    )}
  >
    <PlainIconButton icon={BellIcon} onClick={props.onClick} />
    <NotificationCount className="absolute right-0 top-0" count={props.count} />
  </div>
)
