import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import clsx from "clsx"
import { noop, set, sum } from "lodash"
import {
  createContext,
  CSSProperties,
  forwardRef,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { FCC } from "../utils/reactHelpers/types"

export function SegmentSwitch<T>(props: {
  className?: string
  textClassName?: string
  itemMinWidth?: number
  availableValues: { label: string; value: T }[]
  value: null | T
  onChange?: (newValue: T) => void
}): JSX.Element {
  const onChange = props.onChange ?? noop
  const selectedIndex = props.availableValues.findIndex(
    v => v.value === props.value,
  )

  return (
    <RadioGroupPrimitive.Root
      className={props.className}
      value={selectedIndex == null ? undefined : String(selectedIndex)}
      onValueChange={index => {
        const value = props.availableValues[Number(index)]
        if (value == null) return
        onChange(value.value)
      }}
    >
      <SegmentSwitchContainer
        selectedIndex={selectedIndex < 0 ? undefined : selectedIndex}
      >
        {props.availableValues.map((v, idx) => (
          <RadioGroupPrimitive.Item key={idx} value={String(idx)}>
            <SegmentSwitchItem
              minWidth={props.itemMinWidth ?? 96}
              index={idx}
              active={idx === selectedIndex}
            >
              <span className={props.textClassName}>{v.label}</span>
            </SegmentSwitchItem>
          </RadioGroupPrimitive.Item>
        ))}
      </SegmentSwitchContainer>
    </RadioGroupPrimitive.Root>
  )
}

interface ItemSize {
  width: number
  height: number
}
interface SegmentSwitchContainerContextValue {
  reportItemSize: (index: number, size: ItemSize) => void
}
const SegmentSwitchContainerContext =
  createContext<SegmentSwitchContainerContextValue>({
    reportItemSize: noop,
  })

export const SegmentSwitchContainer: FCC<{
  selectedIndex?: number
}> = props => {
  const [itemSizeArray, setItemSizeArray] = useState<ItemSize[]>([])

  const ctxValue: SegmentSwitchContainerContextValue = useMemo(
    () => ({
      reportItemSize: (index, size): void => {
        setItemSizeArray(ary => set(ary.slice(), index, size))
      },
    }),
    [],
  )

  const selectedItemOffset = sum(
    itemSizeArray.slice(0, props.selectedIndex).map(s => s.width),
  )

  return (
    <div className="flex flex-row p-[5px] w-fit-content rounded-full bg-black/[0.15] text-xs leading-4 font-normal">
      {props.selectedIndex != null && (
        <SegmentSwitchItemContainer
          className={clsx("absolute transition-all")}
          style={{
            transform: `translateX(${selectedItemOffset}px)`,
            width: itemSizeArray[props.selectedIndex]?.width,
            height: itemSizeArray[props.selectedIndex]?.height,
          }}
          active={true}
        />
      )}

      <SegmentSwitchContainerContext.Provider value={ctxValue}>
        {props.children}
      </SegmentSwitchContainerContext.Provider>
    </div>
  )
}

export const SegmentSwitchItem: FCC<{
  className?: string
  boxClassName?: string
  minWidth?: number
  index: number
  active: boolean
}> = props => {
  const { reportItemSize } = useContext(SegmentSwitchContainerContext)

  const containerRef = useRef<HTMLElement>(null)

  useLayoutEffect(() => {
    if (!containerRef.current) return
    reportItemSize(props.index, containerRef.current.getBoundingClientRect())
  })

  return (
    <SegmentSwitchItemContainer
      ref={containerRef}
      className={clsx(
        "cursor-pointer",
        props.active ? "text-white" : "text-gray-400",
        props.className,
      )}
      boxClassName={props.boxClassName}
      style={{ minWidth: props.minWidth }}
    >
      {props.children}
    </SegmentSwitchItemContainer>
  )
}

const SegmentSwitchItemContainer = forwardRef<
  HTMLElement,
  {
    className?: string
    boxClassName?: string
    style?: CSSProperties
    active?: boolean
    children?: ReactNode
  }
>((props, ref) => (
  <span
    ref={ref}
    className={clsx(
      "flex items-center justify-center rounded-full",
      props.boxClassName ?? "p-1",
      props.active && "bg-white/20",
      props.className,
    )}
    style={props.style}
  >
    {props.children}
  </span>
))
