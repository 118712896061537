import { createContext, ReactNode, useContext } from "react"

export interface PageInfo {
  title?: ReactNode
}

type PopPageFn = () => void
type PushPageFn = (page: PageInfo) => PopPageFn
export interface PageStackContextValue {
  pageStack: PageInfo[]
  pushPage: PushPageFn
}

export const PageStackContext = createContext<null | PageStackContextValue>(
  null,
)

export const useContextInComponent = (
  componentName: string,
): PageStackContextValue => {
  const pageStackCtx = useContext(PageStackContext)
  if (pageStackCtx == null) {
    throw new Error(`[${componentName}] must be rendered in PageStackProvider`)
  }
  return pageStackCtx
}
