import { FC } from "react"
import { getPrecisionFloor } from "../utils/numberHelpers"
import { readResource, SuspenseResource } from "../utils/SuspenseResource"
import { Spensor } from "./Spensor"

export interface PercentNumberProps extends PercentNumberFormatOptions {
  number: SuspenseResource<number>
}

export const PercentNumber: FC<PercentNumberProps> = props => {
  return (
    <Spensor fallback={"-%"}>
      {() => (
        <>
          {formatPercentNumber(readResource(props.number), {
            precision: props.precision,
          })}
        </>
      )}
    </Spensor>
  )
}

export interface PercentNumberFormatOptions {
  /**
   * @default 2
   */
  precision?: number
}

export function formatPercentNumber(
  number: number,
  options: PercentNumberFormatOptions = {},
): string {
  const { precision = 2 } = options

  if (number === 0) return "0%"

  if (number < getPrecisionFloor(precision) / 100) {
    return `≤ ${getPrecisionFloor(precision)}%`
  }

  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: precision,
  }).format(number)
}
