import clsx from "clsx"
import { format } from "date-fns"
import { FC, ReactNode, Ref } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  AlignSelf,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { Spensor } from "../../../../../components/Spensor"
import { useResponsiveValue } from "../../../../../components/Themed/breakpoints"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { Truncatable } from "../../../../../components/Truncatable"
import {
  SuspenseResource,
  readResource,
} from "../../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { WrapBridgeNetwork } from "../../../types/types"
import { FeeTooltip } from "../../FeeTooltip"
import { ReactComponent as EstimatedArrowIcon } from "./_/estimatedArrowIcon.svg"

const BlockTitle: FC<{
  className?: string
  children: ReactNode
}> = props => (
  <p className={clsx("text-gray-400 text-sm", props.className)}>
    {props.children}
  </p>
)

const TimelineItem: FC<{
  className?: string
  titleClassName?: string
  icon: ReactNode
  title: ReactNode
}> = props => {
  return (
    <div className={clsx("flex items-center", props.className)}>
      {props.icon}
      <p className={clsx("ml-2.5 font-bold", props.titleClassName)}>
        {props.title}
      </p>
    </div>
  )
}

const DetailCardContainer: FC<{
  className?: string
  children: ReactNode
}> = props => {
  return (
    <div className={clsx("rounded bg-white bg-opacity-5 p-4", props.className)}>
      {props.children}
    </div>
  )
}

const InfoListTitle: FC<{
  className?: string
  title: ReactNode
  token: SuspenseResource<TokenInfo>
  amount: SuspenseResource<number | undefined>
  alignSelf: AlignSelf
}> = props => {
  return (
    <InfoListItem>
      <InfoListItemTitle className="text-white font-bold text-base">
        {props.title}
      </InfoListItemTitle>
      <InfoListItemDetail
        className="text-white font-bold text-base"
        alignSelf={props.alignSelf}
      >
        <Spensor fallback="-">
          {() => (
            <>
              <TokenCount
                token={readResource(props.token)}
                count={readResource(props.amount) ?? 0}
              />
              <TokenName className="ml-1" token={readResource(props.token)} />
            </>
          )}
        </Spensor>
      </InfoListItemDetail>
    </InfoListItem>
  )
}

export const EstimatedUSD: FC<{
  className?: string
  usdCount: SuspenseResource<number>
  error?: boolean
}> = props => (
  <Truncatable
    className={clsx(
      "text-xs sm:text-base text-gray-500",
      props.error && "text-red-500",
      props.className,
    )}
  >
    {" ( $"}
    <Spensor fallback={"-"}>
      {() => (
        <TokenCount
          token={TokenInfoPresets.USD}
          count={readResource(props.usdCount)}
        />
      )}
    </Spensor>
    {" )"}
  </Truncatable>
)

const InfoListDetailItem: FC<{
  className?: string
  alignSelf?: AlignSelf
  title: ReactNode
  token: SuspenseResource<TokenInfo>
  count: SuspenseResource<number | undefined>
  usdCount: SuspenseResource<number>
}> = props => {
  return (
    <InfoListItem>
      <InfoListItemTitle>{props.title}</InfoListItemTitle>
      <InfoListItemDetail alignSelf={props.alignSelf}>
        <Spensor fallback={"-"}>
          {() => (
            <>
              <TokenCount
                token={readResource(props.token)}
                count={readResource(props.count) ?? 0}
              />{" "}
              <TokenName token={readResource(props.token)} />
            </>
          )}
        </Spensor>
        <EstimatedUSD usdCount={props.usdCount} />
      </InfoListItemDetail>
    </InfoListItem>
  )
}

type WrapBridgePanelDetailsProps = {
  containerRef?: Ref<HTMLElement>
  className?: string
  fee: SuspenseResource<number>
  feeRate: SuspenseResource<number>
  minFeeAmount: SuspenseResource<number>
  feeToken: SuspenseResource<TokenInfo>
  feeUsdCount: SuspenseResource<number>
  fromToken: SuspenseResource<TokenInfo>
  fromAmount: SuspenseResource<number | undefined>
  fromUsdCount: SuspenseResource<number>
  fromNetwork: WrapBridgeNetwork
  toNetwork: WrapBridgeNetwork
  toAmount: SuspenseResource<number | undefined>
  toUsdCount: SuspenseResource<number>
  toToken: SuspenseResource<TokenInfo>
  direction: "wrap" | "unwrap"
  waitingBlocks: SuspenseResource<number>
  bridgingStartedAt: SuspenseResource<Date>
  bridgingEndedAt: SuspenseResource<Date>
}

export const WrapBridgePanelDetailsSuspensible: FC<
  WrapBridgePanelDetailsProps
> = props => {
  const { $t } = useIntl()
  const alignSelf: AlignSelf = useResponsiveValue({
    md: true,
  })
    ? undefined
    : "right"

  return (
    <div
      className={clsx(
        "flex flex-col border-y border-gray-500 border-opacity-30",
        props.className,
      )}
      ref={props.containerRef as any}
    >
      <div className="flex items-stretch flex-col md:flex-row py-5 md:px-5">
        <div className="flex-1 basis-1/2 sm:mr-10">
          <BlockTitle className="mb-2.5">
            {$t(
              defineMessage({
                defaultMessage:
                  "Here are the estimated costs of using the bridge.",
                description: "/Bridge/PaneDetail/Content description",
              }),
            )}
          </BlockTitle>
          <DetailCardContainer>
            <InfoList>
              <InfoListTitle
                alignSelf={alignSelf}
                title={$t(
                  defineMessage({
                    defaultMessage: "You pay on {network}",
                    description: "/Bridge/PanelDetail/Block title",
                  }),
                  {
                    network: props.fromNetwork.name,
                  },
                )}
                token={props.fromToken}
                amount={props.fromAmount}
              />
              <InfoListDetailItem
                alignSelf={alignSelf}
                token={props.feeToken}
                count={props.fee}
                usdCount={props.feeUsdCount}
                title={$t(
                  defineMessage({
                    defaultMessage: "Fee {tip}",
                    description: "/Bridge/PanelDetails/Block list item label",
                  }),
                  {
                    tip: (
                      <FeeTooltip
                        direction={props.direction}
                        sourceToken={props.fromToken}
                        targetToken={props.toToken}
                        feeToken={props.feeToken}
                        feeRate={props.feeRate}
                        minFeeAmount={props.minFeeAmount}
                      />
                    ),
                  },
                )}
              />
            </InfoList>
          </DetailCardContainer>
          <DetailCardContainer className="mt-2.5">
            <InfoList>
              <InfoListTitle
                alignSelf={alignSelf}
                title={$t(
                  defineMessage({
                    defaultMessage: "You receive on {network}",
                    description: "/Bridge/PanelDetails/Block title",
                  }),
                  {
                    network: props.toNetwork.name,
                  },
                )}
                token={props.toToken}
                amount={props.toAmount}
              />
              <InfoListDetailItem
                alignSelf={alignSelf}
                title={$t(
                  defineMessage({
                    defaultMessage: "Receive (estimated)",
                    description: "/Bridge/PanelDetails/Info list label",
                  }),
                )}
                token={props.toToken}
                count={props.toAmount}
                usdCount={props.toUsdCount}
              />
            </InfoList>
          </DetailCardContainer>
        </div>
        <div className="flex-1 basis-1/2 mt-5 md:mt-0">
          <BlockTitle className="mb-2.5">
            {$t(
              defineMessage({
                defaultMessage:
                  "This is the estimated timeline of your bridge transfer. Wrap and Unwrap time may vary significantly depending on bridge traffic",
                description:
                  "/Bridge/PanelDetail/Estimated Timeline description",
              }),
            )}
          </BlockTitle>
          <TimelineItem
            icon={<TokenIcon token={readResource(props.fromToken)} />}
            title={$t(
              defineMessage({
                defaultMessage: "{time} • Funds sent • {network}",
                description: "/Bridge/PanelDetail/Estimated Timeline item",
              }),
              {
                time: format(readResource(props.bridgingStartedAt), "h:mm a"),
                network: props.fromNetwork.name,
              },
            )}
          />
          <TimelineItem
            className="my-2"
            titleClassName="font-normal text-gray-400"
            icon={<EstimatedArrowIcon />}
            title={$t(
              defineMessage({
                defaultMessage: "ALEX Bridge • {network}",
                description: "/Bridge/PanelDetail/Estimated Timeline item",
              }),
              {
                network: props.fromNetwork.name,
              },
            )}
          />
          <TimelineItem
            icon={<TokenIcon token={readResource(props.toToken)} />}
            title={$t(
              defineMessage({
                defaultMessage: "{time} • Funds received • {network}",
                description: "/Bridge/PanelDetail/Estimated Timeline",
              }),
              {
                time: format(readResource(props.bridgingEndedAt), "h:mm a"),
                network: props.toNetwork.name,
              },
            )}
          />
        </div>
      </div>
    </div>
  )
}
