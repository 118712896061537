import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"

export const BetterPricesInSwap: FC<{
  discountPercentage: number
  swapLink: string
  onProceed: () => void
  onClose?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Better deal on staking",
          description: "/Stakes/AutoStake/Better prices title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <CardPlate className="text-gray-200 text-center">
        {$t(
          defineMessage({
            defaultMessage: `You can get a {discountPercentage} discount by
             <line>
                <strong>Swapping atALEX</strong> rather than <strong>staking directly</strong>
             </line>`,
            description: "/Stakes/AutoStake/Better prices plate",
          }),
          {
            discountPercentage: (
              <strong className="text-green-600 text-xl font-bold">
                <PercentNumber number={props.discountPercentage} />
              </strong>
            ),
            line: dontWrapObserver(children => <p>{children}</p>),
            strong: dontWrapObserver(children => <strong>{children}</strong>),
          },
        )}
      </CardPlate>
      <NavLink to={props.swapLink}>
        <GradientFilledButton>
          {$t(
            defineMessage({
              defaultMessage: "Go and Swap",
              description: "/Stakes/BetterPrices/Nav link text",
            }),
          )}
        </GradientFilledButton>
      </NavLink>
      <WhiteFilledButton onClick={props.onProceed}>
        {$t(
          defineMessage({
            defaultMessage: "Close and Proceed",
            description: "/Stakes/BetterPrices/Button text",
          }),
        )}
      </WhiteFilledButton>
    </CardModalContent>
  )
}

export const BetterHarvestAndSwap: FC<{
  discountPercentage: number
  onClose: () => void
  onOnlyHarvest: () => void
  onHarvestAndAutoStake: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Better deal on staking",
          description: "/Stakes/BetterHarvestAndSwap/Modal title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <CardPlate className="text-gray-200 text-center">
        {$t(
          defineMessage({
            defaultMessage: `You can get a <strong>{discountPercentage}</strong> discount by
              <line>
                <strong>Swapping atALEX</strong> after <strong>Only Harvest</strong> rather than <strong>Autostaking directly</strong>
              </line>`,
            description: "/Stakes/BetterHarvestAndSwap/Plate",
          }),
          {
            discountPercentage: (
              <PercentNumber number={props.discountPercentage} />
            ),
            line: dontWrapObserver(children => <p>{children}</p>),
            strong: dontWrapObserver(children => (
              <strong className="text-green-600 text-xl font-bold">
                {children}
              </strong>
            )),
          },
        )}
      </CardPlate>
      <GradientFilledButton onClick={props.onOnlyHarvest}>
        {$t(
          defineMessage({
            defaultMessage: "Only Harvest",
            description: "/Stakes/BetterHarvestAndSwap/button text",
          }),
        )}
      </GradientFilledButton>
      <WhiteFilledButton onClick={props.onHarvestAndAutoStake}>
        {$t(
          defineMessage({
            defaultMessage: "Harvest and AutoStake",
            description: "/Stakes/BetterHarvestAndSwap/button text",
          }),
        )}
      </WhiteFilledButton>
    </CardModalContent>
  )
}
