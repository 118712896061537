import clsx from "clsx"
import { FC, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import WiredBlockStatus from "../../components/BlockStatus/WiredBlockStatus"
import { ConnectProvider } from "../../components/ConnectWallet/ConnectProvider"
import {
  WiredDisclaimerModal,
  WiredDisclaimerModalPreventProvider,
} from "../../components/DisclaimerModalContent/WiredDisclaimerModal"
import { RootLayoutContainerProvider } from "../../components/LayoutContainer/RootLayoutContainer"
import { PageContentErrorBoundary } from "../../components/PageContentErrorBoundary"
import { PageStackProvider } from "../../components/PageStack/PageStackProvider"
import { WiredTestnetRewardsClaimBanner } from "../../components/TestnetRewardsClaimBanner/WiredTestnetRewardsClaimBanner"
import { TopNavBarHeight } from "../../components/TopNavBar/TopNavBar"
import { TopNavBarLogoExtraFunctionalProvider } from "../../components/TopNavBar/TopNavBarLogoExtraProvider"
import { WiredTopNavBar } from "../../components/TopNavBar/WiredTopNavBar"
import { WiredUnSupportAlertModal } from "../../components/UnSupportAlertModal/WiredUnSupportAlertModal"
import { WiredBugBounty } from "../components/BugBounty/BugBounty"
import styles from "./Layout.module.scss"

export const Layout: FC = () => {
  const { key: locationKey } = useLocation()

  const [topNavBarHeight, setTopNavBarHeight] = useState(TopNavBarHeight)

  return (
    <ConnectProvider>
      <PageStackProvider>
        <WiredDisclaimerModalPreventProvider>
          <TopNavBarLogoExtraFunctionalProvider>
            <div className={"fixed top-0 left-0 right-0 z-1"}>
              <WiredTopNavBar onHeightChanged={setTopNavBarHeight} />
            </div>

            <WiredTestnetRewardsClaimBanner
              style={{ marginTop: topNavBarHeight }}
            />
            <RootLayoutContainerProvider
              className={"pb-8 sm:py-[8.75rem] grow"}
              topNavHeight={0}
              topExtraPadding={32}
            >
              <PageContentErrorBoundary key={locationKey}>
                <Outlet />
              </PageContentErrorBoundary>
            </RootLayoutContainerProvider>

            <div
              className={clsx(
                styles["widgets-container"],
                "sm:fixed bottom-0 left-0 right-0 mt-16 p-5 flex items-end",
              )}
            >
              <WiredBugBounty className={"ml-auto"} />
            </div>

            <WiredUnSupportAlertModal />
            <WiredDisclaimerModal />
            {/*<WiredOutageModalContent />*/}
            <WiredBlockStatus />
          </TopNavBarLogoExtraFunctionalProvider>
        </WiredDisclaimerModalPreventProvider>
      </PageStackProvider>
    </ConnectProvider>
  )
}
