import {
  defineContract,
  numberT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const redeemAutoHelper = defineContract({
  "redeem-auto-helper": {
    "redeem-auto-and-reduce-from-yield-token-pool": {
      input: [
        { name: "yield-token-trait", type: principalT },
        { name: "token-trait", type: principalT },
        { name: "pool-token-trait", type: principalT },
        { name: "auto-token-trait", type: principalT },
        { name: "percent", type: numberT },
      ],
      output: responseSimpleT(tupleT({ dx: numberT, dy: numberT })),
      mode: "public",
    },
  },
} as const)
