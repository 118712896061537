import { useIntl } from "react-intl"
import {
  readResource,
  suspenseResource,
  SuspenseResource,
  SuspenseResourceFactory,
} from "../../utils/SuspenseResource"
import { LongTextIntlMessage } from "./contentfulLongText"

export const useContentfulLongText = (
  factory: SuspenseResourceFactory<LongTextIntlMessage>,
): SuspenseResource<string> => {
  const { locale } = useIntl()

  return suspenseResource(() => {
    const msg = readResource(suspenseResource(factory))

    if (locale in msg) {
      return msg[locale as keyof LongTextIntlMessage] ?? msg["en-US"]
    }

    return msg["en-US"]
  })
}
