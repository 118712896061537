import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BorrowRolloverRowData } from "../../../../store/transformerGroups/lend/borrowRollover"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { BaseRow } from "../BaseRow"

export const BorrowRolloverRow: FC<{
  rowData: BorrowRolloverRowData
}> = ({ rowData }) => {
  const { $t } = useIntl()
  return (
    <BaseRow
      rowData={rowData}
      currencyCell={<BaseCellContainer>-</BaseCellContainer>}
      amountCell={
        <BaseCellContainer>
          {$t(
            defineMessage({
              defaultMessage: "(Collateral Rollover)",
              description: "/Notify/Transaction/Table/Borrow rollover row",
            }),
          )}
        </BaseCellContainer>
      }
      tradeInfoCell={<BaseCellContainer>#{rowData.expiry}</BaseCellContainer>}
    />
  )
}
