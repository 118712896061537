import { memoize, sum } from "lodash"
import { computed, makeObservable, observable } from "mobx"
import { computedFn } from "mobx-utils"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { ytpBreakDown } from "../../../utils/alexjs/currencyHelpers"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import LendStore from "./LendStore"
import {
  claimBorrow,
  CRPPoolDetail,
  fetchCRPHourlyStateRecords,
  fetchCRPHourlyStateTotalRecord,
  getFlashLoadFee,
  rollOverBorrow,
} from "./LendStore.service"

class MyBorrowModule {
  constructor(readonly store: LendStore) {
    makeObservable(this)
  }

  @computed get claimableBreakdown$(): { collateral: number; uToken: number } {
    const expires = this.store.keyTokenAmount$.filter(
      s => s.expiry < this.store.currentExpiry$,
    )
    return {
      collateral: sum(
        expires.map(
          a =>
            this.collateralBreakdown$(a.expiry).estCollateralPerKeyToken *
            a.amount,
        ),
      ),
      uToken: sum(
        expires.map(
          a =>
            this.collateralBreakdown$(a.expiry).estUTokenPerKeyToken * a.amount,
        ),
      ),
    }
  }

  @computed get pendingBreakdown(): { collateral: number; uToken: number } {
    const expires = this.store.keyTokenAmount$.filter(
      s => s.expiry >= this.store.currentExpiry$,
    )
    return {
      collateral: sum(
        expires.map(
          a =>
            this.collateralBreakdown$(a.expiry).collateralPerShare * a.amount,
        ),
      ),
      uToken: sum(
        expires.map(
          a => this.collateralBreakdown$(a.expiry).uTokenPerShare * a.amount,
        ),
      ),
    }
  }

  collateralBreakdown$(expiry: number): CRPPoolDetail {
    return this.store.poolDetail.value$.details[expiry]! ?? ({} as any)
  }

  keyTokenBalance$(expiry: number): number {
    return (
      this.store.keyTokenAmount$.find(s => s.expiry === expiry)?.amount ?? 0
    )
  }

  @computed get collateralPricedInUnderlying$(): number {
    const { collateral, underlying } = ytpBreakDown(this.store.poolToken)
    return (
      this.store.currencyStore.getPrice$(collateral) /
      this.store.currencyStore.getPrice$(underlying)
    )
  }

  @observable expiryToClaim?: number
  @observable viewCollateralRebalanceHourlyStats?: {
    expiry: number
    page: number
  }

  @observable expiryToRoll?: number
  // expiryToRollSlippage = new SlippageStore(30)

  @computed get yieldTokenAmountToRoll$(): number {
    if (this.expiryToRoll == null) {
      return 0
    }
    const keyTokenBalance = this.keyTokenBalance$(this.expiryToRoll)
    const { estCollateralPerKeyToken, estUTokenPerKeyToken } =
      this.collateralBreakdown$(this.expiryToRoll)
    const keyTokenValueInAlex =
      keyTokenBalance * estUTokenPerKeyToken +
      keyTokenBalance *
        estCollateralPerKeyToken *
        this.collateralPricedInUnderlying$
    return (keyTokenValueInAlex * this.store.ltv$) / (1 - this.store.ltv$)
  }

  @computed get rollOverLiquidityProviderFee$(): number {
    return this.store.ytpLiquidityFeeCalc("yield", this.yieldTokenAmountToRoll$)
  }

  @computed get rollOverEstAbsoluteInterestFee$(): number {
    return (
      this.yieldTokenAmountToRoll$ * (1 - this.store.addBorrow.uTokenPerYToken$)
    )
  }

  private flashLoadFee = new LazyValue(
    () => null,
    () => getFlashLoadFee(),
  )

  @computed get rollOverFlashLoadFee$(): number {
    return this.flashLoadFee.value$
  }

  rollOverTxStore = new ConfirmTransactionStore()

  @asyncAction async rollOver(run = runAsyncAction): Promise<void> {
    if (this.expiryToRoll == null) return
    try {
      const formData = {
        stxAddress: this.store.authStore.stxAddress$,
        poolToken: this.store.poolToken,
        currentExpiry: this.store.currentExpiry$,
        fromExpiry: this.expiryToRoll,
        // slippage: this.expiryToRollSlippage.slippagePercentage,
        // yieldTokenAmount: this.yieldTokenAmountToRoll$,
        // uTokenPerYieldToken: this.store.addBorrow.uTokenPerYToken$,
      }
      this.expiryToRoll = undefined
      const { txId } = await run(rollOverBorrow(formData))
      this.rollOverTxStore.successRunning(txId)
    } catch (e) {
      this.rollOverTxStore.errorRunning(e as Error)
    }
  }

  hourlyStatRecord = computedFn(
    (expiry: number, page: number) =>
      new LazyValue(
        () => [this.store.poolToken, expiry, page] as const,
        fetchCRPHourlyStateRecords,
      ),
    {
      keepAlive: true,
    },
  )

  hourlyStatTotalRecord = memoize(
    (expiry: number) =>
      new LazyValue(
        () => [this.store.poolToken, expiry] as const,
        fetchCRPHourlyStateTotalRecord,
      ),
  )

  txStore = new ConfirmTransactionStore()

  @asyncAction async claim(run = runAsyncAction): Promise<void> {
    if (this.expiryToClaim == null) {
      return
    }
    const expiry = this.expiryToClaim
    this.expiryToClaim = undefined
    try {
      const { txId } = await run(
        claimBorrow({
          stxAddress: this.store.authStore.stxAddress$,
          poolToken: this.store.poolToken,
          expiry,
        }),
      )
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }
}

export default MyBorrowModule
