import { FC, PropsWithChildren } from "react"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../utils/createStore"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import LaunchPadContentfulStore from "./LaunchPadContentfulStore"
import LaunchPadContractStore from "./LaunchPadContractStore"

const { Provider, useStore } = createStore<LaunchPadContentfulStore>(
  "LaunchPadContentful",
)

const LaunchPadContentfulProvider: FC<
  PropsWithChildren<{
    store: LaunchPadContentfulStore
  }>
> = props => {
  return <Provider store={props.store}>{props.children}</Provider>
}

export const useLaunchPadContentfulStore = useStore

export const LaunchPadProvider: FC<
  PropsWithChildren<{ urlSlug: string }>
> = props => {
  const chainStore = useChainStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const detailStore = useCreation(
    () =>
      new LaunchPadContentfulStore(
        { type: "urlSlug", urlSlug: props.urlSlug },
        authStore,
        accountStore,
        currencyStore,
        chainStore,
      ),
    [accountStore, authStore, chainStore, currencyStore, props.urlSlug],
  )
  return (
    <LaunchPadContentfulProvider store={detailStore}>
      {props.children}
    </LaunchPadContentfulProvider>
  )
}

export const useLaunchPadContractStore = (): LaunchPadContractStore => {
  const store = useLaunchPadContentfulStore()
  if (store.contractStore$ == null) {
    throw new Error(
      `IDO ${store.urlSlug} does not have contract idoID configured`,
    )
  }
  return store.contractStore$
}
