import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BlueCard, Card } from "../../../../../components/Card"
import { GradientText } from "../../../../../components/GradientText"
import { NavLink } from "../../../../../components/NavLink"
import { TokenIcon } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { WrapBridgeNetwork } from "../../../types/types"
import { WrapBridgeNetworkComponent } from "../WrapBridgeNetwork/WrapBridgeNetworkComponent"
import BridgeBg from "./assets/birdge.png"
import { ReactComponent as BridgeArrowIcon } from "./assets/bridgeArrow.svg"
import { ReactComponent as PointyArrowIcon } from "./assets/pointyArrow.svg"

export type WrapBridgeRouteProps = {
  /**
   * @default true
   */
  withBackground?: boolean
  fromNetwork: WrapBridgeNetwork
  toNetwork: WrapBridgeNetwork
  fromToken: TokenInfo
  toToken: TokenInfo
}

const PointyArrow: FC<{ title: string }> = props => (
  <div className="flex flex-col justify-center items-center gap-1">
    <div className="text-sm leading-5 font-medium text-gray-400">
      {props.title}
    </div>
    <PointyArrowIcon />
  </div>
)

export const WrapBridgeRoute: FC<WrapBridgeRouteProps> = props => {
  const { withBackground = true } = props
  const { $t } = useIntl()
  return (
    <Card
      className={clsx(
        "flex flex-col gap-4",
        withBackground && "bg-black bg-opacity-20",
      )}
      boxClassName={withBackground ? undefined : ""}
      backgroundImage={withBackground ? `url(${BridgeBg})` : "none"}
      style={{
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <h3 className="text-xl leading-7 font-medium">Bridge Route</h3>
      <div className="flex flex-col md:flex-row gap-2.5">
        <Card className="flex-1 flex flex-col gap-4 items-center">
          <div className="flex gap-2.5 items-center">
            {$t(
              defineMessage({
                defaultMessage: "From {network}",
                description: "WrapBridge/bridge route",
              }),
              {
                network: (
                  <WrapBridgeNetworkComponent network={props.fromNetwork} />
                ),
              },
            )}
          </div>
          <div className="flex flex-col gap-1 items-center">
            <TokenIcon token={props.fromToken} />
            <TokenName token={props.fromToken} />
          </div>
        </Card>
        <PointyArrow title="Send" />
        <BlueCard className="flex-1 flex flex-col gap-2 items-center">
          <GradientText
            className="text-lg leading-7 font-bold"
            gradientStyle="linear-gradient(90deg, #FFFFFF 0%, #0B54FF 100%)"
          >
            {$t(
              defineMessage({
                defaultMessage: "Bridge",
                description: "WrapBridge/bridge route card",
              }),
            )}
          </GradientText>
          <div className="flex flex-col gap-1 items-center">
            <div className="flex flex-row items-center gap-2">
              <TokenIcon token={props.fromToken} />
              <BridgeArrowIcon />
              <TokenIcon token={props.toToken} />
            </div>
            <div className="font-bold leading-6 text-base">
              <TokenName token={props.fromToken} /> to{" "}
              <TokenName token={props.toToken} />
            </div>
            <NavLink
              className="text-blue-600 leading-4 text-xs"
              to={
                "https://medium.com/alexgobtc/what-is-stacks-bridge-29be1fc71ede"
              }
            >
              {$t(
                defineMessage({
                  defaultMessage: "What is the bridge?",
                  description: "WrapBridge/bridge route link",
                }),
              )}
            </NavLink>
          </div>
        </BlueCard>
        <PointyArrow title="Receive" />
        <Card className="flex-1 flex flex-col gap-4 items-center">
          <div className="flex gap-2.5 items-center">
            {$t(
              defineMessage({
                defaultMessage: "To {network}",
                description: "WrapBridge/bridge route card text",
              }),
              {
                network: (
                  <WrapBridgeNetworkComponent network={props.toNetwork} />
                ),
              },
            )}
          </div>
          <div className="flex flex-col gap-1 items-center">
            <TokenIcon token={props.toToken} />
            <TokenName token={props.toToken} />
          </div>
        </Card>
      </div>
    </Card>
  )
}
