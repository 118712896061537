import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../../../components/Card"
import { NavLink } from "../../../../../../components/NavLink"
import { TextTokenCount } from "../../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../../components/Spensor"
import {
  SuspenseResource,
  readResource,
} from "../../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { ReactComponent as LinkIcon } from "./link.svg"

export const DetailSection: FC<{
  token: SuspenseResource<TokenInfo>
  userBalance: SuspenseResource<number>
  withdrawPendingAmount: SuspenseResource<number>
  withdrawRequests: SuspenseResource<{ txId: string; txExplorerLink: string }[]>
}> = props => {
  const { $t } = useIntl()
  return (
    <CardInset
      className={"flex flex-col gap-2.5"}
      bgClassName="bg-gray-900"
      boxClassName={"py-2.5 px-4"}
    >
      <div className="text-white font-bold">
        {$t(
          defineMessage({
            defaultMessage: "Your Account Balance: {totalBalance}",
            description:
              "/OrderBook/BRC20/WithdrawDuplicateWarningModalContent/AccountSection/Info list",
          }),
          {
            totalBalance: (
              <TextTokenCount token={props.token} count={props.userBalance} />
            ),
          },
        )}
      </div>

      <div>
        <div className="text-white font-bold">
          {$t(
            defineMessage({
              defaultMessage:
                "Your Pending Withdrawal: {withdrawPendingBalance}",
              description:
                "/OrderBook/BRC20/WithdrawDuplicateWarningModalContent/AccountSection/Info list",
            }),
            {
              withdrawPendingBalance: (
                <TextTokenCount
                  token={props.token}
                  count={props.withdrawPendingAmount}
                />
              ),
            },
          )}
        </div>

        <Spensor>
          {() =>
            readResource(props.withdrawRequests).map((r, idx) => (
              <NavLink
                key={idx}
                className="flex items-center text-xs text-blue-600 space-x-2.5"
                to={r.txExplorerLink}
                target="_blank"
              >
                <span>{r.txId}</span>

                <LinkIcon />
              </NavLink>
            ))
          }
        </Spensor>
      </div>
    </CardInset>
  )
}
