import { FC } from "react"
import { LayoutContainer } from "../../components/LayoutContainer/LayoutContainer"
import { RootLayoutContainer } from "../../components/LayoutContainer/RootLayoutContainer"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { Pagination } from "../../components/Pagination"
import { Spensor } from "../../components/Spensor"
import { usePathGenerator } from "../../routes/routes"
import { suspenseResource } from "../../utils/SuspenseResource"
import { dontWrapObserver } from "../../utils/mobxHelpers"
import { useContextBridge } from "../../utils/useContextBridge"
import { ScreenType } from "../Orderbook/OrderbookPage.types"
import { TitleBar } from "./components/TitleBar/TitleBar"
import { TokenListRow } from "./components/TokenListRow/TokenListRow"
import { TokenListTabBar } from "./components/TokenListTabBar"
import { TokenListTabContent } from "./components/TokenListTabContent"
import {
  B20TokenListContextBridgeSymbol,
  B20TokenListStoreProvider,
  useB20TokenListStore,
} from "./stores/useB20TokenListStore"
import { B20TokenListInfo } from "./types"

const PageContent: FC = () => {
  const store = useB20TokenListStore()

  const gen = usePathGenerator()

  return (
    <div className="space-y-8">
      <LayoutContainer>
        <TokenListTabBar
          filterStatus={store.filteringStatus}
          onFilterStatusChange={status => store.onUpdateFilteringStatus(status)}
        />
      </LayoutContainer>

      <TokenListTabContent
        records={suspenseResource(() =>
          store.records$.map(
            (r): B20TokenListInfo => ({
              ...r,
              pegInLink: gen.orderbook({
                type: ScreenType.PegIn,
                token: r.tradeTokenCurrency,
              }),
            }),
          ),
        )}
        renderItem={dontWrapObserver(p => (
          <LayoutContainer className={"overflow-x-auto"}>
            <TokenListRow
              className={"w-full min-w-[fit-content]"}
              record={p.record}
            />
          </LayoutContainer>
        ))}
      />

      <Spensor>
        {() => (
          <LayoutContainer>
            <Pagination
              {...store.pagination$}
              onChange={i => store.onUpdateCurrentPage(i.page)}
            />
          </LayoutContainer>
        )}
      </Spensor>
    </div>
  )
}

const WiredTitleBar: FC = () => {
  const store = useB20TokenListStore()

  return (
    <TitleBar
      listingLiquidityThresholdPercentage={suspenseResource(
        () => store.minLiquidityPercentage$,
      )}
      searchValue={store.searching ?? ""}
      applyListLink={
        "https://blocksurvey.io/survey/t/c01fc113-4e54-4a64-92ae-52fd46b6b075/r/l"
      }
      onSearchChange={v => {
        store.onUpdateSearching(v)
      }}
    />
  )
}

const B20TokenListPageContent: FC = () => {
  const ContextBridge = useContextBridge(B20TokenListContextBridgeSymbol)
  return (
    <PageStackPage
      title={
        <LayoutContainer>
          <ContextBridge>
            <WiredTitleBar />
          </ContextBridge>
        </LayoutContainer>
      }
    >
      <RootLayoutContainer fullWidth={true}>
        <PageContent />
      </RootLayoutContainer>
    </PageStackPage>
  )
}

export const B20TokenListPage: FC = () => {
  return (
    <B20TokenListStoreProvider>
      <B20TokenListPageContent />
    </B20TokenListStoreProvider>
  )
}
