import {
  ParameterObjOfDescriptor,
  ReturnTypeOfDescriptor,
  UnboxResponse,
  unwrapResponse,
} from "clarity-codegen"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  Contracts,
  asSender,
} from "../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../utils/alexjs/Currency"
import { assertNever } from "../../../utils/types"

export type LaunchToken =
  | Currency.ALEX
  | Currency.W_BANANA
  | Currency.ORDINALS_BLUEWHEEL
  | Currency.BRC20_ORMM

export type TicketToken = Currency.APOWER
export type PaymentToken = Currency.W_STX | Currency.ALEX

export type LaunchPadVersion =
  | "alex-launchpad-v1-1"
  | "alex-launchpad-v1-2"
  | "alex-launchpad-v1-3"

export type LaunchPadDetail =
  | {
      version: "alex-launchpad-v1-1"
      detail: NonNullable<
        UnboxResponse<
          ReturnTypeOfDescriptor<Contracts["alex-launchpad-v1-1"]["get-ido"]>
        >
      >
    }
  | {
      version: "alex-launchpad-v1-2"
      detail: NonNullable<
        UnboxResponse<
          ReturnTypeOfDescriptor<Contracts["alex-launchpad-v1-2"]["get-launch"]>
        >
      >
    }
  | {
      version: "alex-launchpad-v1-3"
      detail: NonNullable<
        UnboxResponse<
          ReturnTypeOfDescriptor<Contracts["alex-launchpad-v1-3"]["get-launch"]>
        >
      >
    }

export type WalkParameters = UnboxResponse<
  ReturnTypeOfDescriptor<
    Contracts["alex-launchpad-v1-1"]["get-offering-walk-parameters"]
  >
>

export type CreateIdoOptions = ParameterObjOfDescriptor<
  Contracts["alex-launchpad-v1-3"]["create-pool"]
>["offering"]

export async function fetchLaunchPad(
  idoId: number,
  version: LaunchPadVersion,
): Promise<LaunchPadDetail> {
  if (version === "alex-launchpad-v1-1") {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("alex-launchpad-v1-1")
      .func("get-ido")
      .call({ "ido-id": idoId })
      .then(unwrapResponse)
    if (result == null) {
      throw new Error(`IdoID ${idoId} not found`)
    }
    return {
      version: "alex-launchpad-v1-1",
      detail: result,
    }
  }
  if (version === "alex-launchpad-v1-2") {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("alex-launchpad-v1-2")
      .func("get-launch")
      .call({ "launch-id": idoId })
      .then(unwrapResponse)
    if (result == null) {
      throw new Error(`IdoID ${idoId} not found`)
    }
    return {
      version: "alex-launchpad-v1-2",
      detail: result,
    }
  }
  if (version === "alex-launchpad-v1-3") {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("alex-launchpad-v1-3")
      .func("get-launch")
      .call({ "launch-id": idoId })
      .then(unwrapResponse)
    if (result == null) {
      throw new Error(`IdoID ${idoId} not found`)
    }
    return {
      version: "alex-launchpad-v1-3",
      detail: result,
    }
  }
  assertNever(version)
}

export async function fetchLaunchPadCurrentRegistered(
  idoId: number,
  version: LaunchPadVersion,
): Promise<number> {
  switch (version) {
    case "alex-launchpad-v1-1":
      return await asSender(CONTRACT_DEPLOYER)
        .contract("alex-launchpad-v1-1")
        .func("get-total-tickets-registered")
        .call({ "ido-id": idoId })
    case "alex-launchpad-v1-2":
      return await asSender(CONTRACT_DEPLOYER)
        .contract("alex-launchpad-v1-2")
        .func("get-total-tickets-registered")
        .call({ "launch-id": idoId })
    case "alex-launchpad-v1-3":
      return await asSender(CONTRACT_DEPLOYER)
        .contract("alex-launchpad-v1-3")
        .func("get-total-tickets-registered")
        .call({ "launch-id": idoId })
    default:
      assertNever(version)
  }
}

export const idoWalkResolution = 1e5

export async function fetchUserSubscription(
  stxAddress: string,
  idoId: number,
  version: LaunchPadVersion,
): Promise<{ start: number; end: number } | undefined> {
  switch (version) {
    case "alex-launchpad-v1-1":
      return await asSender(stxAddress)
        .contract("alex-launchpad-v1-1")
        .func("get-offering-ticket-bounds")
        .call({ "ido-id": idoId, owner: stxAddress })
        .then(a =>
          a == null
            ? undefined
            : {
                start: a.start / idoWalkResolution,
                end: a.end / idoWalkResolution,
              },
        )
    case "alex-launchpad-v1-2":
      return await asSender(stxAddress)
        .contract("alex-launchpad-v1-2")
        .func("get-offering-ticket-bounds")
        .call({ "launch-id": idoId, owner: stxAddress })
        .then(a =>
          a == null
            ? undefined
            : {
                start: a.start / idoWalkResolution,
                end: a.end / idoWalkResolution,
              },
        )
    case "alex-launchpad-v1-3":
      return await asSender(stxAddress)
        .contract("alex-launchpad-v1-3")
        .func("get-offering-ticket-bounds")
        .call({ "launch-id": idoId, owner: stxAddress })
        .then(a =>
          a == null
            ? undefined
            : {
                start: a.start / idoWalkResolution,
                end: a.end / idoWalkResolution,
              },
        )
    default:
      assertNever(version)
  }
}
