import clsx from "clsx"
import { FC, ReactNode } from "react"
import { useIntl } from "react-intl"
import { connectWallet$t } from "../../../../commonIntlMessages"
import { Spensor } from "../../../../components/Spensor"
import { Button } from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"

export interface ConnectedWalletInfo {
  walletAddress: ReactNode
  onDisconnect: () => void
}

export interface WalletConnectionLineProps {
  className?: string
  labelText: ReactNode
  wallet: SuspenseResource<ConnectedWalletInfo>
  onConnectWallet: () => void
}

export const WalletConnectionLine: FC<WalletConnectionLineProps> = props => {
  const { $t } = useIntl()

  return (
    <div
      className={clsx(
        props.className,
        "flex flex-row flex-wrap gap-x-2.5 items-center",
      )}
    >
      <div className={"text-lg leading-7 font-medium text-gray-400"}>
        {props.labelText}
      </div>

      <Spensor
        fallback={
          <div className={"flex flex-row flex-wrap justify-between flex-1"}>
            <div className={"text-lg leading-7 font-medium text-gray-200"}>
              --
            </div>
            <Button
              renderVariant={p => (
                <GradientFilledButton
                  {...p}
                  boxClassName={"px-6 py-0.5"}
                  textClassName={"text-base leading-6 font-semibold"}
                />
              )}
              onClick={props.onConnectWallet}
            >
              {$t(connectWallet$t)}
            </Button>
          </div>
        }
      >
        {() => (
          <>
            <span
              className={
                "text-lg leading-7 font-medium text-gray-200 break-words max-w-full flex flex-row items-center"
              }
            >
              {readResource(props.wallet).walletAddress}
            </span>
            <Button
              renderVariant={p => (
                <PlainIconButton
                  {...p}
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#9CA3AF"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.93308 12.7613L8.07975 14.614C6.23242 16.462 3.23442 16.462 1.38575 14.614C-0.46225 12.7653 -0.461583 9.768 1.38575 7.92L3.23842 6.06733L4.65242 7.48133L2.79975 9.334C1.73442 10.3993 1.73442 12.134 2.79975 13.1987C3.86642 14.2653 5.60042 14.2653 6.66575 13.1987L8.51842 11.3473L9.93308 12.7613ZM7.91975 1.386L6.06708 3.23867L7.48108 4.65267L9.33442 2.8C10.3997 1.73467 12.1337 1.73467 13.1977 2.80067C14.2657 3.866 14.2657 5.6 13.2004 6.66533L11.3477 8.518L12.7617 9.932L14.6144 8.08C16.4617 6.23133 16.4617 3.234 14.6144 1.386C12.7651 -0.462 9.76775 -0.462 7.91975 1.386ZM3.95642 0.856L4.91642 0.72L5.20842 2.77533L4.24975 2.912L3.95642 0.856ZM0.71975 4.916L0.857083 3.95533L2.91308 4.24867L2.77575 5.20867L0.71975 4.916ZM3.91508 3.23533L3.22975 3.922L1.75975 2.45333L2.44575 1.76733L3.91508 3.23533ZM12.0424 15.142L11.0817 15.278L10.7897 13.2227L11.7491 13.086L12.0424 15.142ZM15.2797 11.082L15.1417 12.0427L13.0857 11.75L13.2237 10.7893L15.2797 11.082ZM12.0844 12.762L12.7691 12.076L14.2391 13.5453L13.5531 14.2313L12.0844 12.762Z" />
                    </svg>
                  }
                />
              )}
              onClick={readResource(props.wallet).onDisconnect}
            />
          </>
        )}
      </Spensor>
    </div>
  )
}
