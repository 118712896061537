import { TopNavBarHeight } from "../TopNavBar/TopNavBar"
import { MessageProviderProps } from "./MessageProvider"

export const wiredRecommendedMessageProviderProps: MessageProviderProps = {
  messagesContainerStyles: {
    position: "fixed",
    left: "auto",
    bottom: "auto",
    top: TopNavBarHeight + 10,
    right: 33,
  },
}
