import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  NoteParagraph,
  SuccessIcon,
} from "../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../components/TokenName"
import { dontWrapObserver } from "../../../utils/mobxHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export const CoFarmWhy: FC<{
  className?: string
  lpLeftToken: TokenInfo
  rewardToken: TokenInfo
  endCycle: number
}> = props => {
  const { $t } = useIntl()

  return (
    <ul className={clsx(props.className, "flex flex-col gap-2.5")}>
      {$t<ReactNode>(
        defineMessage({
          defaultMessage: `<line>You commit {lpLeftToken} half of the pool only.</line>
            <line>The ALEX Lab Foundation will provide the other half and co-farm.</line>
            <line>At the end of farming, you will receive the same amount the {lpLeftToken} as what you initially committed.</line>
            <line>Co-Farming will conclude at cycle #{cycle}.</line>
            <line>{rewardToken} rewards will be automatically sent to your wallet address after each cycle.</line>`,
          description: "/Farm/Co-farm why",
        }),
        {
          line: dontWrapObserver(children => (
            <li>
              <NoteParagraph icon={<SuccessIcon />}>{children}</NoteParagraph>
            </li>
          )),
          lpLeftToken: <TokenName token={props.lpLeftToken} />,
          rewardToken: <TokenName token={props.rewardToken} />,
          cycle: props.endCycle,
        },
      )}
    </ul>
  )
}
