import browserUpdate, { RequiredBrowsers } from "browser-update"
import { constant, times, zipObject } from "lodash"
import { FC, useEffect } from "react"
import { oneOf } from "../utils/arrayHelpers"

export const BrowserUpdateBanner: FC = () => {
  useEffect(() => {
    showBanner()
  }, [])

  return null
}

function showBanner(): void {
  const supportBrowserList: (keyof RequiredBrowsers)[] = [
    "e",
    "c",
    "f",
    "v",
    "ios",
  ]

  const options: browserUpdate.Options = {
    l: "en",
    noclose: false,
    no_permanent_hide: true,
    reminder: 0,
    unsupported: true,
    required: zipObject(
      supportBrowserList,
      times(supportBrowserList.length, constant(-3)),
    ),
  }

  const browserInfo: browserUpdate.DetectedBrowser =
    window.$bu_getBrowser() as any

  if (
    // if not in the support list, we will show the hiro wallet alert
    oneOf(supportBrowserList, browserInfo.engine)
  ) {
    browserUpdate(options)
  }
}
