import { ENV_NAME, EXPLORER_ADDR_URL, EXPLORER_TX_URL } from "../../../config"
import { ETHChain } from "../../../stores/appEnvStore/ETHChain"
import { assertExclude, assertNever, checkNever } from "../../../utils/types"
import { BridgeChains as ServerBridgeChains } from "../store/HistoryModule/getHistoryRecords.serverTypes"
import {
  BridgeChain,
  EthBridgeChain,
  isOppositeBridgeChain,
  isSupportedOppositeBridgeChain,
  OppositeBridgeChain,
  SupportedOppositeBridgeChain,
} from "./BridgeChain"
import { WrapBridgeNetwork } from "./types"
import { WrapBridgeNetworkPresets } from "./WrapBridgeNetworkPresets/WrapBridgeNetworkPresets"

export function contractAssignedChainIdFromBridgeChain(
  chain: SupportedOppositeBridgeChain,
): number {
  if (!isSupportedOppositeBridgeChain(chain)) {
    throw new Error(
      `[contractAssignedChainIdFromBridgeChain] Not supported chain in current environment: ${chain}`,
    )
  }

  switch (chain) {
    case BridgeChain.Ethereum:
    case BridgeChain.Goerli:
    case BridgeChain.Sepolia:
    case BridgeChain.SepoliaFork:
      return 1
    case BridgeChain.BSC:
    case BridgeChain.BSCTest:
    case BridgeChain.BSCTestFork:
      return 2
    default:
      assertNever(chain)
  }
}

export function mapBridgeChainToEthChain(
  bridgeChain: EthBridgeChain,
): ETHChain {
  switch (bridgeChain) {
    case BridgeChain.Ethereum:
      return ETHChain.Ethereum
    case BridgeChain.Goerli:
      return ETHChain.Goerli
    case BridgeChain.Sepolia:
      return ETHChain.Sepolia
    case BridgeChain.SepoliaFork:
      return ETHChain.SepoliaFork
    case BridgeChain.BSC:
      return ETHChain.BSC
    case BridgeChain.BSCTest:
      return ETHChain.BSCTest
    case BridgeChain.BSCTestFork:
      return ETHChain.BSCTestFork
    default:
      assertNever(
        bridgeChain,
        new Error(
          `[mapBridgeChainToEthChain] Unsupported eth bridge chain: ${bridgeChain}`,
        ),
      )
  }
}

export function mapEthChainToBridgeChain(
  ethChain: ETHChain,
): undefined | EthBridgeChain {
  const restEthBridgeChains = assertExclude.i<EthBridgeChain>()

  if (ethChain === ETHChain.Ethereum) {
    return BridgeChain.Ethereum
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.Ethereum>())

  if (ethChain === ETHChain.Goerli) {
    return BridgeChain.Goerli
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.Goerli>())

  if (ethChain === ETHChain.Sepolia) {
    return BridgeChain.Sepolia
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.Sepolia>())

  if (ethChain === ETHChain.SepoliaFork) {
    return BridgeChain.SepoliaFork
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.SepoliaFork>())

  if (ethChain === ETHChain.BSC) {
    return BridgeChain.BSC
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.BSC>())

  if (ethChain === ETHChain.BSCTest) {
    return BridgeChain.BSCTest
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.BSCTest>())

  if (ethChain === ETHChain.BSCTestFork) {
    return BridgeChain.BSCTestFork
  }
  assertExclude(restEthBridgeChains, assertExclude.i<BridgeChain.BSCTestFork>())

  checkNever(restEthBridgeChains)
}

export function getOppositeChainExplorerAddrLink(
  chain: OppositeBridgeChain,
  addr: string,
): string {
  switch (chain) {
    case BridgeChain.Ethereum:
      return `https://etherscan.io/address/${addr}`
    case BridgeChain.Goerli:
      return `https://goerli.etherscan.io/address/${addr}`
    case BridgeChain.Sepolia:
      return `https://sepolia.etherscan.io/address/${addr}`
    case BridgeChain.SepoliaFork:
      // TODO: fix this
      return `https://sepolia.etherscan.io/address/${addr}`
    case BridgeChain.BSC:
      return `https://bscscan.com/address/${addr}`
    case BridgeChain.BSCTest:
      return `https://testnet.bscscan.com/address/${addr}`
    case BridgeChain.BSCTestFork:
      // TODO: fix this
      return `https://testnet.bscscan.com/address/${addr}`
    default:
      assertNever(
        chain,
        new Error(
          `[getOppositeChainExplorerAddrLink] Unsupported opposite chain: ${chain}`,
        ),
      )
  }
}

export function getExplorerAddrLink(
  chain: BridgeChain,
  txid: string,
): string | undefined {
  if (chain === BridgeChain.Unknown) {
    return undefined
  } else if (chain === BridgeChain.Stacks) {
    return EXPLORER_ADDR_URL(txid)
  } else if (isOppositeBridgeChain(chain)) {
    return getOppositeChainExplorerAddrLink(chain, txid)
  } else {
    checkNever(chain)
  }
}

export function getOppositeChainExplorerTxLink(
  chain: OppositeBridgeChain,
  txHash: string,
): string {
  switch (chain) {
    case BridgeChain.Ethereum:
      return `https://etherscan.io/tx/${txHash}`
    case BridgeChain.Goerli:
      return `https://goerli.etherscan.io/tx/${txHash}`
    case BridgeChain.Sepolia:
      return `https://sepolia.etherscan.io/tx/${txHash}`
    case BridgeChain.SepoliaFork:
      // TODO: fix this
      return `https://sepolia.etherscan.io/tx/${txHash}`
    case BridgeChain.BSC:
      return `https://bscscan.com/tx/${txHash}`
    case BridgeChain.BSCTest:
      return `https://testnet.bscscan.com/tx/${txHash}`
    case BridgeChain.BSCTestFork:
      // TODO: fix this
      return `https://testnet.bscscan.com/tx/${txHash}`
    default:
      assertNever(
        chain,
        new Error(
          `[getOppositeChainExplorerTxLink] Unsupported chain: ${chain}`,
        ),
      )
  }
}

export function getExplorerTxLink(
  chain: BridgeChain,
  hash: string,
): string | undefined {
  if (chain === BridgeChain.Unknown) {
    return undefined
  } else if (chain === BridgeChain.Stacks) {
    return EXPLORER_TX_URL(hash)
  } else if (isOppositeBridgeChain(chain)) {
    return getOppositeChainExplorerTxLink(chain, hash)
  } else {
    checkNever(chain)
  }
}

export const mapAPIBridgeChainToBridgeChain = (
  serverBridgeChain: ServerBridgeChains,
): undefined | BridgeChain => {
  switch (serverBridgeChain) {
    case ServerBridgeChains.stacks:
      return BridgeChain.Stacks
    case ServerBridgeChains.ethereum:
      return BridgeChain.Ethereum
    case ServerBridgeChains.sepolia:
      if (ENV_NAME === "dev") {
        return BridgeChain.SepoliaFork
      } else {
        return BridgeChain.Sepolia
      }
    case ServerBridgeChains.bsc:
      return BridgeChain.BSC
    case ServerBridgeChains.bscTestnet:
      if (ENV_NAME === "dev") {
        return BridgeChain.BSCTestFork
      } else {
        return BridgeChain.BSCTest
      }
    default:
      checkNever(serverBridgeChain)
  }
}

export const mapBridgeChainToAPIBridgeChain = (
  chain: BridgeChain,
): undefined | ServerBridgeChains => {
  switch (chain) {
    case BridgeChain.Unknown:
    case BridgeChain.Goerli:
      return
    case BridgeChain.Stacks:
      return ServerBridgeChains.stacks
    case BridgeChain.Ethereum:
      return ServerBridgeChains.ethereum
    case BridgeChain.Sepolia:
    case BridgeChain.SepoliaFork:
      return ServerBridgeChains.sepolia
    case BridgeChain.BSC:
      return ServerBridgeChains.bsc
    case BridgeChain.BSCTest:
    case BridgeChain.BSCTestFork:
      return ServerBridgeChains.bscTestnet
    default:
      checkNever(chain)
  }
}

export function wrapBridgeNetworkFromBridgeChain(
  chain: BridgeChain,
): WrapBridgeNetwork {
  if (!isSupportedOppositeBridgeChain(chain) && chain !== BridgeChain.Stacks) {
    return WrapBridgeNetworkPresets.Unknown
  }

  switch (chain) {
    case BridgeChain.Stacks:
      return WrapBridgeNetworkPresets.Stacks
    case BridgeChain.Ethereum:
      return WrapBridgeNetworkPresets.Ethereum
    case BridgeChain.Goerli:
      return WrapBridgeNetworkPresets.Goerli
    case BridgeChain.Sepolia:
      return WrapBridgeNetworkPresets.Sepolia
    case BridgeChain.SepoliaFork:
      return WrapBridgeNetworkPresets.SepoliaFork
    case BridgeChain.BSC:
      return WrapBridgeNetworkPresets.BSC
    case BridgeChain.BSCTest:
      return WrapBridgeNetworkPresets.BSCTest
    case BridgeChain.BSCTestFork:
      return WrapBridgeNetworkPresets.BSCTestFork
    default:
      assertNever(chain)
  }
}

export function wrapBridgeNetworkToBridgeChain(
  network: WrapBridgeNetwork,
): undefined | BridgeChain {
  const restBridgeChains = assertExclude.i<OppositeBridgeChain>()

  if (network.id === WrapBridgeNetworkPresets.Stacks.id) {
    return BridgeChain.Stacks
  }

  if (network.id === WrapBridgeNetworkPresets.Ethereum.id) {
    return BridgeChain.Ethereum
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.Ethereum>())

  if (network.id === WrapBridgeNetworkPresets.Goerli.id) {
    return BridgeChain.Goerli
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.Goerli>())

  if (network.id === WrapBridgeNetworkPresets.Sepolia.id) {
    return BridgeChain.Sepolia
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.Sepolia>())

  if (network.id === WrapBridgeNetworkPresets.SepoliaFork.id) {
    return BridgeChain.SepoliaFork
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.SepoliaFork>())

  if (network.id === WrapBridgeNetworkPresets.BSC.id) {
    return BridgeChain.BSC
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.BSC>())

  if (network.id === WrapBridgeNetworkPresets.BSCTest.id) {
    return BridgeChain.BSCTest
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.BSCTest>())

  if (network.id === WrapBridgeNetworkPresets.BSCTestFork.id) {
    return BridgeChain.BSCTestFork
  }
  assertExclude(restBridgeChains, assertExclude.i<BridgeChain.BSCTestFork>())

  checkNever(restBridgeChains)
  console.error(
    `[wrapBridgeNetworkToBridgeChain] unsupported network "${network.id}"`,
  )
  return BridgeChain.Unknown
}
