import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BuyLotteryRowData } from "../../../../store/transformerGroups/lottery/buyLottery"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmount } from "../../OneLineAmount"
import { OneLineCurrency } from "../../OneLineCurrency"
import { OneLineTokenAmount } from "../../OneLineTokenAmount"
import { BaseRow } from "../BaseRow"

export const BuyLotteryRow: FC<{
  rowData: BuyLotteryRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => {
  const { $t } = useIntl()
  return (
    <BaseRow
      rowData={rowData}
      currencyCell={
        <BaseCellContainer>
          <OneLineCurrency
            fromCurrencyAmounts={[rowData.tokenCurrencyAmount]}
            allTokenInfos={allTokenInfos}
          />
          {Boolean(rowData.bonusTickets) && (
            <OneLineCurrency
              fromCurrencyAmounts={[rowData.bonusCurrencyAmount]}
              allTokenInfos={allTokenInfos}
            />
          )}
          <p>
            {$t(
              defineMessage({
                defaultMessage: "→ Lottery",
                description: "/Notify/Transaction/Table/Buy lottery row",
              }),
            )}
          </p>
        </BaseCellContainer>
      }
      amountCell={
        <BaseCellContainer>
          <OneLineAmount
            allTokenInfos={allTokenInfos}
            fromCurrencyAmounts={[rowData.tokenCurrencyAmount]}
          />
          <p className="whitespace-nowrap">
            {$t(
              defineMessage({
                defaultMessage: "→ {tickets} Lottery",
                description: "/Notify/Transaction/Table/Buy lottery row",
              }),
              {
                tickets: rowData.tickets,
              },
            )}
          </p>
          {Boolean(rowData.bonusTickets) && (
            <>
              <p className="whitespace-nowrap">
                <OneLineTokenAmount
                  currencyAmount={rowData.bonusCurrencyAmount}
                  allTokenInfos={allTokenInfos}
                />
              </p>
              <p className="whitespace-nowrap">
                {$t(
                  defineMessage({
                    defaultMessage: "→ {bonusTickets} Lottery",
                    description: "/Notify/Transaction/Table/Buy lottery row",
                  }),
                  {
                    bonusTickets: rowData.bonusTickets,
                  },
                )}
              </p>
            </>
          )}
        </BaseCellContainer>
      }
      tradeInfoCell={
        <BaseCellContainer>
          {$t(
            defineMessage({
              defaultMessage: "Round #{lotteryId}",
              description: "/Notify/Transaction/Table/Buy lottery row",
            }),
            {
              lotteryId: rowData.lotteryId,
            },
          )}
        </BaseCellContainer>
      }
    />
  )
}
