import { ReactNode } from "react"
import { defineMessage, IntlFormatters } from "react-intl"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export namespace orderlistHeaders$t {
  export const price = (
    { $t }: IntlFormatters<ReactNode>,
    token: TokenInfo,
  ): ReactNode =>
    $t(
      defineMessage({
        defaultMessage: "Price ({token})",
        description: "Perpetual/Orderlist field title",
      }),
      {
        token: <TokenName token={token} />,
      },
    )

  export const amount = (
    { $t }: IntlFormatters<ReactNode>,
    token: TokenInfo,
  ): ReactNode =>
    $t(
      defineMessage({
        defaultMessage: "Amount ({token})",
        description: "Perpetual/Orderlist field title",
      }),
      {
        token: <TokenName token={token} />,
      },
    )

  export const total = (
    { $t }: IntlFormatters<ReactNode>,
    token: TokenInfo,
  ): ReactNode =>
    $t(
      defineMessage({
        defaultMessage: "Total ({token})",
        description: "Perpetual/Orderlist field title",
      }),
      {
        token: <TokenName token={token} />,
      },
    )

  export const time = ({ $t }: IntlFormatters<ReactNode>): ReactNode =>
    $t(
      defineMessage({
        defaultMessage: "Time",
        description: "Perpetual/Orderlist field title",
      }),
    )
}
