import * as Sentry from "@sentry/react"
import { bytesToHex } from "@stacks/common"
import { FinishedTxData, openContractCall } from "@stacks/connect"
import { SponsoredFinishedTxData } from "@stacks/connect/dist/types/types/transactions"
import {
  AnchorMode,
  ClarityValue,
  PostCondition,
  PostConditionMode,
  callReadOnlyFunction,
  serializeCV,
  serializePostCondition,
} from "@stacks/transactions"
import {
  ContractEntryDescriptor,
  OpenCallFunctionDescriptor,
  ParameterObjOfDescriptor,
  ReadonlyFunctionDescriptor,
  ReturnTypeOfDescriptor,
} from "clarity-codegen"
import {
  CONTRACT_DEPLOYER,
  CURRENCY_CONTRACT_OVERWRITES,
  DISABLE_POST_CONDITION,
  FORCE_SPONSORED_TX,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../config"
import { OKXWalletAuthSession } from "../../stores/authStore/OKXWalletAuthSession"
import { WalletConnectAuthSession } from "../../stores/authStore/WalletConnectAuthSession"
import { CancelError } from "../../utils/error"
import { StringOnly } from "../../utils/types"
import { AlexContracts } from "../smartContract/contracts_Alex"
import { broadcastSponsoredTx } from "./sponsoredTx"

export type Contracts = typeof AlexContracts
export type ContractName = keyof Contracts

export type ContractCallSender = ReturnType<typeof asSender>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const asSender = (senderAddress: string, _tip?: string) => ({
  contract: <T extends keyof Contracts>(contract: StringOnly<T>) => ({
    func: <F extends keyof Contracts[T]>(functionName: StringOnly<F>) => ({
      call: async <Descriptor extends Contracts[T][F]>(
        args: ParameterObjOfDescriptor<Descriptor>,
        postConditions?: PostCondition[],
        options?: { sponsored?: boolean },
      ): Promise<
        Descriptor extends ReadonlyFunctionDescriptor
          ? ReturnTypeOfDescriptor<Descriptor>
          : Descriptor extends OpenCallFunctionDescriptor
          ? { txId: string }
          : never
      > => {
        if (senderAddress == null) {
          throw new Error(`senderAddress is not set`)
        }
        const functionDescriptor = AlexContracts[contract][
          functionName
        ] as any as ContractEntryDescriptor
        if (
          functionDescriptor.mode === "mapEntry" ||
          functionDescriptor.mode === "variable"
        ) {
          throw new Error(`Map Entry not supported`)
        }
        const clarityArgs = functionDescriptor.input.map(arg =>
          arg.type.encode(args[arg.name]),
        )
        const sharedOptions = {
          network: STACK_NETWORK as any,
          contractName: CURRENCY_CONTRACT_OVERWRITES[contract] ?? contract,
          contractAddress: CONTRACT_DEPLOYER,
          functionName: String(functionName),
          senderAddress,
        } as const
        if (functionDescriptor.mode === "public") {
          console.log(`Calling public ${contract}.${functionName} with: `, args)
          if (WalletConnectAuthSession.current?.currentWalletAddress != null) {
            const client = await WalletConnectAuthSession.current.client()
            const chainId = WalletConnectAuthSession.current.chain!
            const session = WalletConnectAuthSession.current.session!
            const result: unknown = await client.request({
              chainId,
              topic: session.topic,
              request: {
                method: "stacks_contractCall",
                params: {
                  pubkey: senderAddress,
                  contractAddress: CONTRACT_DEPLOYER,
                  contractName: contract,
                  functionName: String(functionName),
                  functionArgs: clarityArgs as (string | ClarityValue)[],
                  ...(postConditions == null || DISABLE_POST_CONDITION
                    ? { postConditionMode: PostConditionMode.Allow }
                    : {
                        postConditionMode: PostConditionMode.Deny,
                        postConditions,
                      }),
                  version: "1",
                },
              },
            })
            if (typeof result === "string") {
              return { txId: result } as any
            }
            return result as any
          }
          if (OKXWalletAuthSession.current?.currentWalletAddress != null) {
            const { txHash, signature } =
              await window.okxwallet.stacks.signTransaction({
                functionName: String(functionName),
                contractName: contract,
                contractAddress: CONTRACT_DEPLOYER,
                stxAddress: senderAddress,
                txType: "contract_call",
                anchorMode: 1,
                functionArgs: clarityArgs.map(x => bytesToHex(serializeCV(x))),
                ...(postConditions == null || DISABLE_POST_CONDITION
                  ? { postConditionMode: PostConditionMode.Allow }
                  : {
                      postConditionMode: PostConditionMode.Deny,
                      postConditions: postConditions.map(x =>
                        bytesToHex(serializePostCondition(x)),
                      ),
                    }),
              })
            return { txId: txHash } as any
          }
          const sponsored = FORCE_SPONSORED_TX || options?.sponsored
          return (await new Promise<{ txId: string }>((resolve, reject) => {
            openContractCall({
              ...sharedOptions,
              ...(postConditions == null || DISABLE_POST_CONDITION
                ? { postConditionMode: PostConditionMode.Allow }
                : {
                    postConditionMode: PostConditionMode.Deny,
                    postConditions,
                  }),
              functionArgs: clarityArgs as (string | ClarityValue)[],
              anchorMode: AnchorMode.Any,
              appDetails: STACK_APP_DETAILS,
              sponsored,
              onFinish: (e: SponsoredFinishedTxData | FinishedTxData) => {
                if (sponsored) {
                  const txData = bytesToHex(
                    (
                      e as SponsoredFinishedTxData
                    ).stacksTransaction.serialize(),
                  )
                  broadcastSponsoredTx(txData)
                    .then(txId => {
                      resolve({ txId })
                    })
                    .catch(e => reject(e))
                } else {
                  resolve({ txId: (e as FinishedTxData).txId })
                }
              },
              onCancel: () => reject(new CancelError("Cancelled")),
            }).catch(e => {
              if (!(e instanceof CancelError)) {
                console.error(e)
                Sentry.captureException(e)
              }
              reject(e)
            })
          })) as any
        } else {
          console.log(
            `Calling readonly ${contract}.${functionName} with: `,
            args,
          )
          const result = await callReadOnlyFunction({
            ...sharedOptions,
            functionArgs: clarityArgs,
          })
          const value = functionDescriptor.output.decode(result)
          console.log(
            `Finished readonly ${contract}.${functionName} with: `,
            value,
          )
          return value
        }
      },
    }),
  }),
})

export function contractAddr(
  c: StringOnly<keyof Contracts>,
): `${string}.${string}` {
  return `${CONTRACT_DEPLOYER}.${CURRENCY_CONTRACT_OVERWRITES[c] ?? c}`
}

export const currentContractName = (contract: ContractName): string =>
  String(contract)

// @ts-ignore
window._asSender = asSender
