import { Card, CardProps } from "../../../../components/Card"
import { FCC } from "../../../../utils/reactHelpers/types"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"

export const CompactCard: FCC<CardProps> = props => (
  <Card boxClassName={"p-3"} {...props} />
)

export const CompactCardTitle = withClassName(
  "text-sm leading-5 font-semibold text-gray-200 grow",
  "span",
)
