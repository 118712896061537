import { Duration } from "date-fns"
import { FC, useCallback, useEffect, useState } from "react"
import { longFormatDuration, subDuration } from "./helpers"

export const TimeCountdownText: FC<{
  time: Date
  /**
   * @default longFormatDuration
   */
  format?: (duration: Duration) => string
}> = props => {
  const getResult = useCallback(
    () =>
      formatTimeCountdownText(props.time, {
        format: props.format,
      }),
    [props.format, props.time],
  )

  const [result, setResult] = useState(getResult)

  useEffect(() => {
    const timer = setInterval(() => {
      setResult(getResult)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [getResult])

  return <>{result}</>
}

export function formatTimeCountdownText(
  time: Date,
  options: {
    /**
     * @default longFormatDuration
     */
    format?: (duration: Duration) => string
  } = {},
): string {
  const duration = subDuration(new Date(), time)
  if (duration == null) return "<1M"

  const format = options.format ?? longFormatDuration
  const formatted = format(duration)

  if (formatted === "") {
    // format function omitted some unit (e.g. nanosecond)
    return "<1M"
  } else {
    return formatted
  }
}
