import { noop } from "lodash"
import { RefObject, useEffect, useMemo } from "react"
import { usePersistFn } from "./usePersistFn"

export interface UseOnElementResizeOptions {
  immediately?: boolean
}

type ArgsSimple = [
  ref: RefObject<HTMLElement>,
  callback: (entries: ResizeObserverEntry[]) => void,
]
type ArgsNonImme = [
  ref: RefObject<HTMLElement>,
  options: UseOnElementResizeOptions & { immediately: false },
  callback: (entries: ResizeObserverEntry[]) => void,
]
type ArgsImme = [
  ref: RefObject<HTMLElement>,
  options: UseOnElementResizeOptions & { immediately: true },
  callback: (entries?: ResizeObserverEntry[]) => void,
]

export function useOnElementResize(...args: ArgsNonImme): void
export function useOnElementResize(...args: ArgsImme): void
export function useOnElementResize(...args: ArgsSimple): void

export function useOnElementResize(
  ...args: ArgsSimple | ArgsNonImme | ArgsImme
): void {
  let ref: RefObject<HTMLElement>
  let options: undefined | UseOnElementResizeOptions
  let _immediateCallback: undefined | (() => void)
  let _eventCallback: (entries: ResizeObserverEntry[]) => void
  if (args.length === 2) {
    ;[ref, _eventCallback] = args
  } else {
    ;[ref, options] = args
    _immediateCallback = args[2] as any
    _eventCallback = args[2]
  }

  const immediateCallback = usePersistFn(_immediateCallback ?? noop)
  const eventCallback = usePersistFn(_eventCallback)

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(entries => {
        eventCallback(entries)
      }),
    [eventCallback],
  )

  const { immediately } = options ?? {}
  useEffect(() => {
    if (immediately) immediateCallback()
  }, [immediateCallback, immediately])

  useEffect(() => {
    const el = ref.current
    el && resizeObserver.observe(el)
    return () => {
      el && resizeObserver.unobserve(el)
    }
  }, [ref, resizeObserver])
}
