import { gql } from "@urql/core"
import { Observable } from "rxjs"
import {
  FetchB20TokenListQuery,
  FetchB20TokenListQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { Currency, isCurrencyOrB20 } from "../../../utils/alexjs/Currency"
import { gqlQuery } from "../../../utils/graphqlHelpers"

export const DEFAULT_MIN_LIQ_PERCENTAGE = 0.05 / 100

export interface FetchedB20TokenListInfo {
  baseTokenCurrency: Currency
  isListed: boolean
  minLiquidity: number
  inscription: string
  inscriptionLink: string
  totalSupply: number
  minted: number
  limitPerMint: number
  decimal: number
}

export function fetchAllRecords(): Observable<FetchedB20TokenListInfo[]> {
  return fromUrqlSource(
    gqlQuery<FetchB20TokenListQuery, FetchB20TokenListQueryVariables>(
      gql`
        query FetchB20TokenList {
          brc20PermissionlessTokenListCollection {
            items {
              baseToken {
                id
              }
              listed
              minimumLiquidity
              marketInfo {
                inscriptionAddress
                inscriptionAddressLink
                totalSupply
                minted
                decimals
                limitPerMint
              }
            }
          }
        }
      `,
    ),
    resp => {
      const ret =
        resp.data.brc20PermissionlessTokenListCollection?.items.flatMap(
          (item): FetchedB20TokenListInfo[] => {
            if (item == null) return []

            const baseTokenCurrency = item.baseToken?.id
            if (
              baseTokenCurrency == null ||
              !isCurrencyOrB20(baseTokenCurrency)
            ) {
              return []
            }

            const totalSupply = item.marketInfo?.totalSupply ?? 0

            return [
              {
                baseTokenCurrency: baseTokenCurrency as Currency,
                isListed: item.listed ?? false,
                minLiquidity:
                  item.minimumLiquidity ??
                  totalSupply * DEFAULT_MIN_LIQ_PERCENTAGE,
                inscription: item.marketInfo?.inscriptionAddress ?? "",
                inscriptionLink: item.marketInfo?.inscriptionAddressLink ?? "",
                decimal: item.marketInfo?.decimals ?? 0,
                limitPerMint: item.marketInfo?.limitPerMint ?? 0,
                totalSupply,
                minted: item.marketInfo?.minted ?? 0,
              },
            ]
          },
        )

      return ret ?? []
    },
  )
}
