import { FungibleConditionCode } from "@stacks/transactions"
import { action, computed, makeObservable, observable } from "mobx"
import { createTransformer } from "mobx-utils"
import {
  asSender,
  contractAddr,
} from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Result } from "../../../../utils/Result"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { Currency } from "../../../../utils/alexjs/Currency"
import { AlexVault, transfer } from "../../../../utils/alexjs/postConditions"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { isNotNull } from "../../../../utils/utils"
import { waitFor } from "../../../../utils/waitFor"
import type {
  FormModeSwitchInfo,
  StakeForm,
} from "../../mixedStakeComponents/AddStakeSection/types"
import { FormError } from "../../mixedStakeComponents/AddStakeSection/types"
import AutoStakeStore from "../autoStaking/AutoStakeStore"
import ManualStakeStore from "../manualStaking/ManualStakeStore"
import StakeChainModule from "../shared/StakeChainModule"
import { MixedStakeCycleViewModel } from "./MixedStakeCycleViewModel"
import { mixedEarningPreview } from "./MixedStakingStore.service"

class MixedStakingStore {
  constructor(
    readonly manualStore: ManualStakeStore,
    readonly autoStore: AutoStakeStore,
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly appEnvStore: AppEnvStore,
  ) {
    makeObservable(this)
  }

  token = Currency.ALEX as const

  get stakeChain(): StakeChainModule {
    return this.chainStore.stakeChainModule(this.token)
  }

  @computed get hasAnyStaking(): boolean {
    return (
      this.manualStore.myStaking.hasAnyStaking ||
      this.autoStore.atAlexBalance$ > 0
    )
  }

  @observable private mode: "auto" | "manual" = "auto"

  @computed({ keepAlive: true }) get form(): StakeForm {
    const input: StakeForm.AlexTokenInput = {
      alexTokenCount: this.autoStore.amountToStake.get() ?? null,
      alexTokenCountToUSD: suspenseResource(
        () => this.autoStore.amountToStakeInUSD$,
      ),
      setAlexTokenCount: value => this.setAmount(value ?? undefined),
      alexBalance: suspenseResource(() => this.autoStore.alexBalance$),
    }

    if (this.mode === "manual") {
      const addStake = this.manualStore.addStake
      return {
        ...input,
        mode: "manual",
        apr: suspenseResource(() => this.upcomingCycleAPR$),
        cycleCount: addStake.userSelectedCycleCount,
        maxCycleCount:
          this.manualStore.addStake.dynamicStakingCycleNumberLimit$ != null
            ? this.manualStore.addStake.maxSelectableCycleCount$
            : undefined,
        setCycleCount: count => addStake.selectCycleCount(count),
        startedAtCycleNumber: addStake.startedAtCycleNumber$,
        endedAtCycleNumber: addStake.endedAtCycleNumber$,
        startedAtBlock: addStake.startedAtBlock$,
        endedAtBlock: addStake.endedAtBlock$,
        estimateDayCount: addStake.estimateDayCount$,
      }
    } else {
      return {
        ...input,
        mode: "auto",
        apy: suspenseResource(() => this.upcomingCycleAPY$),
        stakePrice: suspenseResource(() => this.autoStore.intrinsic$),
        recentAutoAlexPrices: suspenseResource(
          () => this.autoStore.upcomingCycles,
        ),
        autoAlexTokenCount: suspenseResource(
          () => this.autoStore.amountToStakeInAtAlex$,
        ),
      }
    }
  }

  @action setAmount(value: number | undefined): void {
    this.autoStore.amountToStake.set(value ?? undefined)
    this.manualStore.addStake.amountToStake.set(value ?? undefined)
  }

  @action switchInfo(info: FormModeSwitchInfo): void {
    this.mode = info.mode
    if (info.mode === "manual") {
      this.manualStore.addStake.selectCycleCount(info.cycleCount)
    }
  }

  @action submit(): void {
    if (this.form.mode === "auto") {
      const data = Result.maybeValue(this.autoStore.addStakeData)
      if (data == null) {
        return
      }
      if (data.swapDiscount != null && data.swapDiscount > 0.01) {
        this.autoStore.confirmingDiscount = data
      } else {
        this.autoStore.confirmingStakeData = data
      }
    } else {
      this.manualStore.addStake.confirming = true
    }
  }

  @action clear(): void {
    this.setAmount(undefined)
  }

  @computed get formError$(): undefined | FormError {
    return this.form.mode === "auto"
      ? Result.maybeError(this.autoStore.addStakeData)
      : Result.maybeError(this.manualStore.addStake.stakeData)
  }

  @computed get addStakeTx(): ConfirmTransactionStore {
    return this.form.mode === "auto"
      ? this.autoStore.addStakeTx
      : this.manualStore.addStake.addStake
  }

  cycleViewModule = createTransformer(
    (cycle: number) => new MixedStakeCycleViewModel(this, cycle),
    { keepAlive: true },
  )

  @computed get upcomingCycle$(): number {
    const current = this.stakeChain.currentCycle$
    let upcoming = current + 1
    const endCycle = this.manualStore.v1StakeEndCycle$
    if (endCycle != null) {
      upcoming = Math.min(upcoming, Math.floor((current + endCycle) / 2))
    }
    return upcoming
  }

  @computed get upcomingCycleAPR$(): number {
    return this.cycleViewModule(this.upcomingCycle$).manualStakingApr
  }

  @computed get upcomingCycleAPY$(): number {
    return this.cycleViewModule(this.upcomingCycle$).autoStakingApy
  }

  @observable harvestConfirming?: "autoMint" | "harvestOnly"
  @observable confirmStakingDiscount?: boolean

  @asyncAction async onHarvestClicked(
    autoMint: boolean,
    run = runAsyncAction,
  ): Promise<void> {
    if (!autoMint) {
      this.harvestConfirming = "harvestOnly"
      return
    }
    const discountPercentage = await run(
      waitFor(() => this.autoStore.swapDiscountPercentage$),
    )
    if (discountPercentage > 0.01) {
      this.confirmStakingDiscount = true
    } else {
      this.harvestConfirming = "harvestOnly"
    }
  }

  harvestTransaction = new ConfirmTransactionStore()
  @asyncAction async harvestAndMintAlex(run = runAsyncAction): Promise<void> {
    this.harvestConfirming = undefined
    const { pendingReward, pendingReturns } = this.manualStore.myStaking
    try {
      const { txId } = await run(
        asSender(this.authStore.stxAddress$)
          .contract("dual-farming-pool-v1-03")
          .func("claim-and-mint-auto-alex")
          .call(
            {
              "reward-cycles": this.manualStore.myStaking.harvestableCycles,
              "dual-token-trait": Currency.BRC20_DB20,
            },
            [
              transfer(
                AlexVault,
                Currency.ALEX,
                pendingReturns,
                FungibleConditionCode.LessEqual,
              ),
              transfer(
                contractAddr("auto-alex-v2"),
                Currency.ALEX,
                pendingReward + pendingReturns,
                FungibleConditionCode.LessEqual,
              ),
              transfer(
                this.authStore.stxAddress$,
                Currency.ALEX,
                pendingReward + pendingReturns,
                FungibleConditionCode.LessEqual,
              ),
              this.manualStore.dualYieldV1_1 != null
                ? transfer(
                    contractAddr("dual-farming-pool-v1-03"),
                    this.manualStore.dualYieldV1_1.underlyingToken$,
                    0,
                    FungibleConditionCode.GreaterEqual,
                  )
                : null,
            ].filter(isNotNull),
          ),
      )
      this.harvestTransaction.successRunning(txId)
    } catch (e) {
      this.harvestTransaction.errorRunning(e as Error)
    }
  }

  earningPreview = new LazyValue(() => null, mixedEarningPreview)
}

export default MixedStakingStore
