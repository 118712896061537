import { bytesToHex } from "@stacks/common"
import { openStructuredDataSignatureRequestPopup } from "@stacks/connect"
import { SignatureData } from "@stacks/connect/dist/types/types/signature"
import { serializeCV } from "@stacks/transactions"
import { gql } from "@urql/core"
import { stringT, tupleT } from "clarity-codegen"
import { round } from "lodash"
import { Observable } from "rxjs"
import {
  ALLOW_CONTRACT_ARGUMENTATION,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../../../config"
import {
  FetchMyStatsPointsQuery,
  FetchMyStatsPointsQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { numberT } from "../../../../generated/smartContractHelpers/codegenImport"
import {
  sendPublicRequest,
  sendRequest,
} from "../../../../generated/stxdxHelpers/stxdxApi"
import { WalletConnectAuthSession } from "../../../../stores/authStore/WalletConnectAuthSession"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import { CancelError } from "../../../../utils/error"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { signatureDomain } from "../constants"

export async function fetchPNLInfo(
  uid: number,
  auth: string,
): Promise<{ count: number; percentage: number }> {
  const {
    data: { today_pnl, today_pnl_percent },
  } = await sendRequest(auth)("AccountController_getAccountTodayPnlById", {
    path: { uid },
  })
  return {
    count: Number(today_pnl) / 1e8,
    percentage: Number(today_pnl_percent),
  }
}

const AuthPayloadCodec = tupleT({
  address: stringT,
  expiration: numberT,
})

export namespace AuthJWTToken {
  const AuthInfoJWTTokenKey = "AuthInfoJWTToken_Key"

  export function set(token: string): void {
    window.localStorage.setItem(AuthInfoJWTTokenKey, token)
  }

  export function get(): null | string {
    const res = window.localStorage.getItem(AuthInfoJWTTokenKey)
    return res || null
  }

  export function clear(): void {
    window.localStorage.removeItem(AuthInfoJWTTokenKey)
  }
}

export namespace TemporarilyStoredSignInfoForInitialDeposit {
  const AuthInfoJWTSignatureKey = "AuthInfoJWTSignature_Key"

  export async function restoreSig(): Promise<null | {
    payload: string
    signature: string
  }> {
    const savedSign = window.localStorage.getItem(AuthInfoJWTSignatureKey)
    if (savedSign != null) {
      try {
        const res = JSON.parse(savedSign)
        return { payload: res.payload, signature: res.signature }
      } finally {
        window.localStorage.removeItem(AuthInfoJWTSignatureKey)
      }
    }
    return null
  }

  export function saveSig(payload: string, signature: string): void {
    window.localStorage.setItem(
      AuthInfoJWTSignatureKey,
      JSON.stringify({ signature, payload }),
    )
  }
}

export const argumentSignature = ALLOW_CONTRACT_ARGUMENTATION
  ? new URLSearchParams(window.location.search).get("signature")
  : null

export async function getAuthSignatureFromWallet(
  address: string,
  expireTimestamp: number,
): Promise<{ payload: string; signature: string; publicKey: string }> {
  const clairty = AuthPayloadCodec.encode({
    address,
    expiration: expireTimestamp,
  })
  let result: { signature: string; publicKey: string }
  if (WalletConnectAuthSession.current?.currentWalletAddress != null) {
    const session = WalletConnectAuthSession.current.session!
    const chain = WalletConnectAuthSession.current.chain!
    const client = await WalletConnectAuthSession.current.client()
    result = await client.request({
      chainId: chain,
      topic: session.topic,
      request: {
        method: "stacks_signMessage",
        params: {
          pubkey: address,
          message: clairty,
          // domain: signatureDomain,
        },
      },
    })
  } else {
    result = await new Promise<SignatureData>(async (resolve, reject) => {
      openStructuredDataSignatureRequestPopup({
        stxAddress: address,
        domain: signatureDomain,
        message: clairty,
        appDetails: STACK_APP_DETAILS,
        network: STACK_NETWORK,
        onFinish: resolve,
        onCancel: () => reject(new CancelError("User cancelled")),
      }).catch(reject)
    })
  }
  const payload = bytesToHex(serializeCV(clairty))
  return { payload, ...result }
}

export async function signInBySignature(
  payload: string,
  signature: string,
): Promise<string> {
  const {
    data: { access_token },
  } = await sendPublicRequest("AccountController_login", {
    body: {
      payload,
      signature,
    },
  })
  return access_token
}
export async function signInAndCacheBySignature(
  payload: string,
  signature: string,
): Promise<string> {
  const token = await signInBySignature(payload, signature)
  AuthJWTToken.set(token)
  return token
}
export async function signInByArgumentSignature(
  address: string,
  argumentSignature: string,
  duration = 600,
): Promise<string> {
  const expireTimestamp = Math.floor(duration + Date.now() / 1000)
  return signInBySignature(
    bytesToHex(
      serializeCV(
        AuthPayloadCodec.encode({
          address,
          expiration: expireTimestamp,
        }),
      ),
    ),
    argumentSignature,
  )
}

export type MyStatsPoints = {
  totalTradingVolume: number
  weeklyTradingVolume: number
  totalBuyOrderAmount: number
  weeklyBuyOrderAmount: number
  totalPoints: number
  weeklyPoints: number
  weeklyPointsPercentage: number
}

export function fetchingMyPointsInfo(
  address: string,
): Observable<MyStatsPoints> {
  return fromUrqlSource(
    gqlQuery<FetchMyStatsPointsQuery, FetchMyStatsPointsQueryVariables>(
      gql`
        query FetchMyStatsPoints($address: String!) {
          public_dbt_dim_volume_by_address(
            where: { address: { _eq: $address } }
            limit: 1
          ) {
            volume
          }
          public_dbt_dim_volume_by_address_7days(
            where: { address: { _eq: $address } }
            limit: 1
          ) {
            volume
          }
          public_dbt_dim_buy_order_by_address(
            where: { address: { _eq: $address } }
            limit: 1
          ) {
            volume
          }
          public_dbt_dim_buy_order_by_address_7days(
            where: { address: { _eq: $address } }
            limit: 1
          ) {
            volume
          }
          public_dbt_dim_trading_points_by_address(
            where: { address: { _eq: $address } }
            limit: 1
          ) {
            trading_point
          }
          public_dbt_dim_trading_points_by_address_7days(
            where: { address: { _eq: $address } }
            limit: 1
          ) {
            trading_point
            #            percentage
          }
        }
      `,
      { address },
    ),
    result => {
      return {
        totalTradingVolume: round(
          result.data.public_dbt_dim_volume_by_address[0]?.volume ?? 0,
          2,
        ),
        weeklyTradingVolume: round(
          result.data.public_dbt_dim_volume_by_address_7days[0]?.volume ?? 0,
          2,
        ),
        totalBuyOrderAmount: round(
          result.data.public_dbt_dim_buy_order_by_address[0]?.volume ?? 0,
          2,
        ),
        weeklyBuyOrderAmount: round(
          result.data.public_dbt_dim_buy_order_by_address_7days[0]?.volume ?? 0,
          2,
        ),
        totalPoints: round(
          result.data.public_dbt_dim_trading_points_by_address[0]
            ?.trading_point ?? 0,
          2,
        ),
        // weeklyPointsPercentage: round(
        //   result.data.public_dbt_dim_trading_points_by_address_7days[0]
        //     ?.percentage ?? 0,
        //   3,
        // ),
        weeklyPointsPercentage: 0,
        weeklyPoints: round(
          result.data.public_dbt_dim_trading_points_by_address_7days[0]
            ?.trading_point ?? 0,
          2,
        ),
      }
    },
  )
}
