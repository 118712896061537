import clsx from "clsx"
import { format, subDays, subMonths, subWeeks } from "date-fns"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { DatePicker } from "../../../../components/DatePicker/DatePicker"
import { Dropdown } from "../../../../components/Dropdown"
import {
  SegmentSwitch,
  SegmentSwitchContainer,
  SegmentSwitchItem,
} from "../../../../components/SegmentSwitch"
import { assertNever } from "../../../../utils/types"

export type DateRange = DateRange.CustomRange | DateRange.Primitive
export namespace DateRange {
  export type CustomRange = [from: Date, to: Date]

  export enum Primitive {
    Recent10Days,
    Recent1Week,
    Recent1Month,
    Recent3Months,
  }

  export const defaultValue = Primitive.Recent10Days
}
export const selectorValueToDateRange = (
  value: DateRange,
): [from: Date, to: Date] => {
  if (Array.isArray(value)) return value

  switch (value) {
    case DateRange.Primitive.Recent10Days:
      return [subDays(new Date(), 10), new Date()]
    case DateRange.Primitive.Recent1Week:
      return [subWeeks(new Date(), 1), new Date()]
    case DateRange.Primitive.Recent1Month:
      return [subMonths(new Date(), 1), new Date()]
    case DateRange.Primitive.Recent3Months:
      return [subMonths(new Date(), 3), new Date()]
    default:
      assertNever(value)
  }
}

export interface DateRangeSelectorProps {
  className?: string
  value: DateRange
  onChange: (newValue: DateRange) => void
}

export const DateRangeSelector: FC<DateRangeSelectorProps> = props => {
  const { $t } = useIntl()

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const isCustomRangeRadioActive = isCustomRange(props.value) || dropdownVisible

  const [selectingDateRange, setSelectingDateRange] = useState<
    null | [from: Date, to?: Date]
  >(null)
  const rangeSelectorValue =
    selectingDateRange ?? (isCustomRange(props.value) ? props.value : null)

  const daysMsg = defineMessage({
    defaultMessage: "{days, plural, one {1 Day} other {{days} Days}}",
    description:
      "Orderbook/OrderHistory/Date Range Selector shortcut button text",
  })
  const weeksMsg = defineMessage({
    defaultMessage: "{weeks, plural, one {1 Week} other {{weeks} Weeks}}",
    description:
      "Orderbook/OrderHistory/Date Range Selector shortcut button text",
  })
  const monthsMsg = defineMessage({
    defaultMessage: "{months, plural, one {1 Month} other {{months} Months}}",
    description:
      "Orderbook/OrderHistory/Date Range Selector shortcut button text",
  })

  return (
    <div className={clsx("flex items-center", props.className)}>
      <SegmentSwitch
        availableValues={[
          {
            label: $t(daysMsg, { days: 10 }),
            value: DateRange.Primitive.Recent10Days,
          },
          {
            label: $t(weeksMsg, { weeks: 1 }),
            value: DateRange.Primitive.Recent1Week,
          },
          {
            label: $t(monthsMsg, { months: 1 }),
            value: DateRange.Primitive.Recent1Month,
          },
          {
            label: $t(monthsMsg, { months: 3 }),
            value: DateRange.Primitive.Recent3Months,
          },
        ]}
        value={isCustomRangeRadioActive ? null : props.value}
        onChange={props.onChange}
      />

      <SegmentSwitchContainer
        selectedIndex={isCustomRangeRadioActive ? 0 : undefined}
      >
        <Dropdown
          visible={dropdownVisible}
          onVisibleChange={setDropdownVisible}
          withoutInnerContainer={true}
          triggerMethod={"click"}
          trigger={
            <SegmentSwitchItem
              className="flex items-center"
              boxClassName="px-4 py-1"
              minWidth={140}
              index={0}
              active={isCustomRangeRadioActive}
            >
              <CalanderIcon />
              &nbsp;&nbsp;
              {isCustomRange(props.value)
                ? [formatDate(props.value[0]), formatDate(props.value[1])].join(
                    " - ",
                  )
                : $t(
                    defineMessage({
                      defaultMessage: "Select Date",
                      description:
                        "Orderbook/OrderHistory/Date Range Selector button text",
                    }),
                  )}
            </SegmentSwitchItem>
          }
        >
          <DatePicker
            mode="range"
            selected={
              rangeSelectorValue == null
                ? undefined
                : {
                    from: rangeSelectorValue[0],
                    to: rangeSelectorValue[1],
                  }
            }
            onSelect={range => {
              if (range?.from && range?.to) {
                props.onChange([range.from, range.to])
                setSelectingDateRange(null)
              } else {
                setSelectingDateRange(
                  range?.from ? [range.from, range.to] : null,
                )
              }
            }}
          />
        </Dropdown>
      </SegmentSwitchContainer>
    </div>
  )
}

const CalanderIcon: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 13.3333H10.6667V10.6667H13.3333V13.3333ZM9.33333 6.66667H6.66667V9.33333H9.33333V6.66667ZM13.3333 6.66667H10.6667V9.33333H13.3333V6.66667ZM5.33333 10.6667H2.66667V13.3333H5.33333V10.6667ZM9.33333 10.6667H6.66667V13.3333H9.33333V10.6667ZM5.33333 6.66667H2.66667V9.33333H5.33333V6.66667ZM16 1.33333V16H0V1.33333H2V2C2 2.73533 2.598 3.33333 3.33333 3.33333C4.06867 3.33333 4.66667 2.73533 4.66667 2V1.33333H11.3333V2C11.3333 2.73533 11.9313 3.33333 12.6667 3.33333C13.402 3.33333 14 2.73533 14 2V1.33333H16ZM14.6667 5.33333H1.33333V14.6667H14.6667V5.33333ZM13.3333 0.666667C13.3333 0.298667 13.0353 0 12.6667 0C12.298 0 12 0.298667 12 0.666667V2C12 2.368 12.298 2.66667 12.6667 2.66667C13.0353 2.66667 13.3333 2.368 13.3333 2V0.666667ZM4 2C4 2.368 3.702 2.66667 3.33333 2.66667C2.96467 2.66667 2.66667 2.368 2.66667 2V0.666667C2.66667 0.298667 2.96467 0 3.33333 0C3.702 0 4 0.298667 4 0.666667V2Z"
      fill="#C4C4C4"
    />
  </svg>
)

const formatDate = (date: Date): string => format(date, "PP")

const isCustomRange = (value: DateRange): value is DateRange.CustomRange =>
  Array.isArray(value)
