import clsx from "clsx"
import { Component, FC, PropsWithChildren, ReactNode } from "react"
import { FCC } from "../utils/reactHelpers/types"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { Card } from "./Card"
import { createInfoListItemDetail, InfoList, InfoListProps } from "./InfoList"
import { LoadingIndicator } from "./LoadingIndicator/LoadingIndicator"

type ErrorBoundaryState = { hasError: false } | { hasError: true; error: Error }
class ErrorBoundary extends Component<
  PropsWithChildren<unknown>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error): ErrorBoundary["state"] {
    return {
      hasError: true,
      error,
    }
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div className="h-[300px] flex justify-center items-center text-red-600">
          <div>{this.state.error.message}</div>
        </div>
      )
    }
    return <>{this.props.children}</>
  }
}

export interface ChartPanelFrameProps {
  className?: string
  header?: ReactNode
}

export const ChartPanelFrame: FCC<ChartPanelFrameProps> = props => {
  return (
    <Card
      boxClassName={"flex flex-col px-[46px] py-[52px]"}
      className={clsx("gap-[22px]", props.className)}
    >
      {props.header && <div>{props.header}</div>}

      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Card>
  )
}

export namespace ChartTitle {
  export const Primary = withClassName(
    "text-sm leading-5 font-normal text-gray-400",
    "p",
  )

  export const Secondary = withClassName(
    "text-lg leading-7 font-medium text-gray-200",
    "p",
  )
}

export const StyledInfoList: FCC<InfoListProps> = props => (
  <InfoList
    gapClassName={"gap-6"}
    direction={"row-responsive"}
    listItemDirection={"column"}
    InfoListItemDetail={createInfoListItemDetail({
      textClassName: "text-2xl font-bold text-white",
    })}
    {...props}
  >
    {props.children}
  </InfoList>
)

export namespace ChartPlaceholder {
  const Container = withClassName(
    "h-[300px] flex justify-center items-center",
    "div",
  )

  export const Loading: FC = () => (
    <Container>
      <LoadingIndicator />
    </Container>
  )

  export const Unavailable: FC = () => (
    <Container className="text-gray-500">Temporarily unavailable</Container>
  )
}

export namespace Legend {
  export const Container: FCC = props => (
    <div className="flex flex-row flex-wrap gap-10 justify-center text-gray-200">
      {props.children}
    </div>
  )

  export const Item: FCC = props => (
    <div className="flex gap-3.5 items-center">{props.children}</div>
  )

  export const Line: FC<{
    /**
     * @default 48
     */
    width?: number

    /**
     * @default 2
     */
    strokeWidth?: number

    strokeColor: string

    /**
     * @default solid
     */
    strokeStyle?: "solid" | "dashed"
  }> = props => (
    <div
      style={{
        width: props.width ?? 48,
        borderWidth: props.strokeWidth ?? 2,
        borderColor: props.strokeColor,
        borderStyle: props.strokeStyle,
      }}
    />
  )

  export const Circle: FC<{
    /**
     * @default 16
     */
    size?: number

    fill: string
  }> = props => (
    <div
      style={{
        width: props.size ?? 16,
        height: props.size ?? 16,
        borderRadius: 999,
        backgroundColor: props.fill,
      }}
    />
  )

  export const Label: FCC<{
    className?: string
    subtitle?: string
    color?: string
  }> = props => (
    <div className={props.className}>
      <p style={{ color: props.color }}>{props.children}</p>
      {props.subtitle && <p className="text-gray-500">{props.subtitle}</p>}
    </div>
  )
}
