import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  Card,
  CardDivider,
  CardInset,
  CardTitle,
} from "../../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { IconTokenCount } from "../../../../../components/RichTokenCount"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCountPair } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export const TransformToWalletCard: FC<{
  className?: string
  transformToToken: TokenInfo
  transformToTokenCount: number
  transformToTokenAPY: number
  originToken: TokenInfo
  harvestableRewards: TokenCountPair[]
}> = props => {
  const { $t } = useIntl()

  return (
    <Card className={clsx("flex flex-col gap-3", props.className)}>
      <CardTitle>
        {$t(
          defineMessage({
            defaultMessage: "Sent to your wallet",
            description: "/Farm/Transform to wallet card title",
          }),
        )}
      </CardTitle>
      <CardInset>
        <InfoList
          direction={"row"}
          listItemDirection={"column"}
          listItemClassName={"flex-1 items-center gap-2.5"}
        >
          <InfoListItem>
            <InfoListItemDetail>
              <IconTokenCount
                inline={false}
                iconSize={32}
                className={"text-2xl font-medium"}
                tokenNameClassName={"sr-only"}
                token={props.transformToToken}
                count={props.transformToTokenCount}
              />
            </InfoListItemDetail>
            <InfoListItemTitle className={"text-xl font-medium"}>
              <TokenName token={props.transformToToken} />
            </InfoListItemTitle>
          </InfoListItem>
        </InfoList>
      </CardInset>
      <InfoList direction={"column"} listItemDirection={"row-responsive"}>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Est. APY",
                description: "/Farm/Transform to wallet card",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <PercentNumber number={props.transformToTokenAPY} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>

      <CardDivider className={"w-full"} />

      <InfoList direction={"row"} listItemDirection={"column"}>
        <InfoListItem className={"gap-2.5"}>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Harvest interest {icon}",
                description: "/Farm/Transform to wallet",
              }),
              {
                icon: (
                  <TipIcon
                    className="ml-1"
                    inline
                    tip={
                      <>
                        {$t(
                          defineMessage({
                            defaultMessage:
                              "The harvest interests are the {originToken} rewards from your previous LP cycles. Co-Farming with {originToken} will mint those rewards into {transformToToken} providing compound interest.",
                            description: "/Farm/Transform to wallet",
                          }),
                          {
                            originToken: (
                              <TokenName token={props.originToken} />
                            ),
                            transformToToken: (
                              <TokenName token={props.transformToToken} />
                            ),
                          },
                        )}
                      </>
                    }
                  />
                ),
              },
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <ul>
              {props.harvestableRewards.map((r, idx) => (
                <li key={idx}>
                  <IconTokenCount
                    inline={false}
                    iconSize={24}
                    className={"text-xl"}
                    tokenNameClassName={"text-base"}
                    token={r.token}
                    count={r.count}
                  />
                </li>
              ))}
            </ul>
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </Card>
  )
}
