import clsx from "clsx"
import { createRef, PureComponent } from "react"
import {
  ChartingLibraryWidgetOptions,
  IBasicDataFeed,
  IChartingLibraryWidget,
  ResolutionString,
  widget,
} from "../../vendors/charting_library/charting_library.esm"

export interface ChartContainerProps
  extends Omit<ChartingLibraryWidgetOptions, "locale"> {
  className?: string
}

const defaultProps: Omit<
  ChartContainerProps,
  "container" | "datafeed" | "symbol"
> = {
  interval: "1" as ResolutionString,
  theme: "Dark",
  library_path: "/charting_library/",
  // client_id: "tradingview.com",
  // user_id: "public_user_id",
  fullscreen: false,
  autosize: true,
  studies_overrides: {},
  disabled_features: [
    "header_symbol_search",
    "symbol_search_hot_key",
    "header_undo_redo",
    "header_settings",
    "header_compare",
    "header_screenshot",
    "header_fullscreen_button",
    "header_indicators",
    "left_toolbar",
    "context_menus",
    "timeframes_toolbar",
  ],
  enabled_features: [
    "move_logo_to_main_pane",
    "adaptive_logo",
    "timezone_menu",
  ],
}

/**
 * react binding of trading-view charting library
 */
export class ChartContainer extends PureComponent<
  Partial<ChartContainerProps> & { datafeed: IBasicDataFeed; symbol: string }
> {
  private tvWidget: IChartingLibraryWidget | null = null
  private ref: React.RefObject<HTMLDivElement> = createRef()

  componentDidMount(): void {
    if (!this.ref.current) {
      return
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultProps,
      ...this.props,
      container: this.ref.current,
      locale: "en",
    }

    const tvWidget = new widget(widgetOptions)
    this.tvWidget = tvWidget

    tvWidget.onChartReady(() => {
      const chart = tvWidget.activeChart()
      chart.clearMarks()
      ;[7, 25, 99].map(length =>
        chart.createStudy("moving average", true, false, [
          length,
          "close",
          0,
          "sma",
          5,
        ]),
      )
    })
  }

  componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget.remove()
      this.tvWidget = null
    }
  }

  render(): JSX.Element {
    return (
      <div
        ref={this.ref}
        className={clsx(this.props.className, "TVChartContainer")}
      />
    )
  }
}
