import { FC, Fragment } from "react"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import {
  SuspenseResource,
  readResource,
} from "../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ReactComponent as ConvertIcon } from "./convert.svg"

export type RateItem = {
  token: TokenInfo
  rate: SuspenseResource<number>
}

export const RateList: FC<{
  className?: string
  rates: RateItem[]
}> = props => {
  return (
    <div className="flex items-center justify-end">
      {props.rates
        .map((rate, index) => (
          <Fragment key={index}>
            <p>
              <TextTokenCount
                token={rate.token}
                count={readResource(rate.rate)}
              />
            </p>
          </Fragment>
        ))
        .reduce((prev, curr) => (
          <>
            {prev}
            <ConvertIcon />
            {curr}
          </>
        ))}
    </div>
  )
}
