import clsx from "clsx"
import React, { FC, PropsWithChildren, ReactNode } from "react"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { GradientText } from "../GradientText"
import { NavBackLink } from "../NavLink"
import { SecondaryPageTitleProps } from "./SecondaryPageTitle"
import { returnIconMargin } from "./_/consts"
import { ReturnIcon } from "./_/ReturnIcon"

export interface GradientSecondaryPageTitleProps
  extends SecondaryPageTitleProps {
  titleRightSide?: ReactNode

  subtitle?: ReactNode

  returnIcon?: ReactNode

  /**
   * @default "bottom"
   */
  subtitlePosition?: "top" | "bottom"

  gradientStyle?: string
}

export const GradientSecondaryPageTitle: FC<
  PropsWithChildren<GradientSecondaryPageTitleProps>
> = props => {
  const { subtitlePosition = "bottom" } = props

  const wrapNavLink = (
    attrs: { className?: string },
    content: ReactNode,
  ): ReactNode =>
    props.returnFallbackURL ? (
      <NavBackLink
        className={attrs.className}
        fallbackURL={props.returnFallbackURL}
        onClick={props.onClickReturn}
      >
        {content}
      </NavBackLink>
    ) : (
      <div className={attrs.className}>{content}</div>
    )

  return (
    <>
      {wrapNavLink(
        { className: clsx("flex", props.className) },
        <>
          {props.returnIcon ?? (
            <span
              className={"flex items-center justify-center"}
              style={{ marginRight: returnIconMargin }}
            >
              <ReturnIcon width={24} height={24} fill={"currentColor"} />
            </span>
          )}

          <div className={"flex flex-col"}>
            {props.subtitle && subtitlePosition === "top" && (
              <Subtitle>{props.subtitle}</Subtitle>
            )}

            <p className={"flex flex-row gap-2.5 items-center"}>
              <GradientText
                className={"text-3xl font-medium"}
                gradientStyle={
                  props.gradientStyle ??
                  "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
                }
              >
                {props.children}
              </GradientText>

              {props.titleRightSide}
            </p>

            {props.subtitle && subtitlePosition === "bottom" && (
              <Subtitle>{props.subtitle}</Subtitle>
            )}
          </div>
        </>,
      )}
    </>
  )
}

const Subtitle = withClassName(
  "text-base leading-6 font-normal text-gray-400",
  "span",
)
