import React, { ReactNode, Ref } from "react"
import * as _tabbar from "../../../components/TabBar"
import { OneOrMore } from "../../../utils/types"

export function TabBar<T>(props: {
  className?: string
  tabbarRef?: Ref<_tabbar.TabBarRefValue<T>>
  tabs: OneOrMore<{ label: ReactNode; value: T }>
  children: (info: { value: T }) => JSX.Element
}): JSX.Element {
  return (
    <_tabbar.TabBar
      className={props.className}
      tabClassName={"-mb-[1px] px-10 py-5"}
      tabbarRef={props.tabbarRef}
      tabs={props.tabs}
    >
      {props.children}
    </_tabbar.TabBar>
  )
}
