import { FC } from "react"
import { useIntl } from "react-intl"
import {
  createInfoListItemDetail,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { SuspenseResource } from "../../../../../utils/SuspenseResource"
import { formFields$t } from "./commonIntlMessages"

export const AvailableOutgoingTokenCount: FC<{
  token: TokenInfo
  tokenCount: SuspenseResource<number>
}> = props => {
  const { $t } = useIntl()
  return (
    <InfoList
      direction={"column"}
      listItemDirection={"row"}
      InfoListItemDetail={createInfoListItemDetail({ alignSelf: "left" })}
    >
      <InfoListItem>
        <InfoListItemTitle>{$t(formFields$t.available)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount token={props.token} count={props.tokenCount} />
        </InfoListItemDetail>
      </InfoListItem>
    </InfoList>
  )
}
