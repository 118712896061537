import clsx from "clsx"
import { FC } from "react"
import { NavLink } from "../NavLink"
import { ReactComponent as DiscordIcon } from "./discord.svg"
import { ReactComponent as MediumIcon } from "./medium.svg"
import { ReactComponent as TwitterIcon } from "./twitter.svg"
import { ReactComponent as WebsiteIcon } from "./website.svg"

export interface MediaLinksProps {
  className?: string
  links: {
    website?: string
    twitter?: string
    medium?: string
    discord?: string
  }
}

export const MediaLinks: FC<MediaLinksProps> = props => {
  const { links } = props

  return (
    <div className={clsx("flex gap-6", props.className)}>
      {links.website && <Link Icon={WebsiteIcon} url={links.website} />}
      {links.medium && <Link Icon={MediumIcon} url={links.medium} />}
      {links.twitter && <Link Icon={TwitterIcon} url={links.twitter} />}
      {links.discord && <Link Icon={DiscordIcon} url={links.discord} />}
    </div>
  )
}

const Link: FC<{
  Icon: typeof WebsiteIcon
  url: string
}> = ({ Icon, url }) => {
  return (
    <NavLink className={"text-gray-200 opacity-40 hover:opacity-20"} to={url}>
      <Icon fill={"currentColor"} />
    </NavLink>
  )
}
