import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  FinishedBadge,
  ProgressingBadge,
  UpcomingBadge,
} from "../../../components/Badge"
import { assertNever } from "../../../utils/types"
import { StakeCycleStatus } from "../types"

export const CycleStatusBadge: FC<{
  status: StakeCycleStatus
}> = props => {
  const { $t } = useIntl()
  switch (props.status) {
    case StakeCycleStatus.Upcoming:
      return (
        <UpcomingBadge>
          {$t(
            defineMessage({
              defaultMessage: "Upcoming",
              description: "/Stake/Cycle status badge/Upcoming",
            }),
          )}
        </UpcomingBadge>
      )
    case StakeCycleStatus.InProgress:
      return (
        <ProgressingBadge>
          {$t(
            defineMessage({
              defaultMessage: "Current",
              description: "/Stake/Cycle status badge/InProgress",
            }),
          )}
        </ProgressingBadge>
      )
    case StakeCycleStatus.Finished:
      return (
        <FinishedBadge>
          {$t(
            defineMessage({
              defaultMessage: "Finished",
              description: "/Stake/Cycle status badge/Finished",
            }),
          )}
        </FinishedBadge>
      )
    default:
      assertNever(props.status)
  }
}
