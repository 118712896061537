import { optionalResponse } from "clarity-codegen"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { isNotNull } from "../../../../../utils/utils"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["collateral-rebalancing-pool-v1"] as const
const functionName = "roll-deposit-many" as const

export interface DepositRolloverRowData extends BaseRowData {
  type: NotifyTransactionType.DepositRollover
  yieldCurrencyAmount: CurrencyAmount
  rollovers: {
    from: CurrencyAmount
    to: CurrencyAmount
  }[]
  expiryToRoll: number
}

export const depositRolloverTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  DepositRolloverRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.DepositRollover,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()

    const yieldTokenTrait = args["yield-token-trait"] as Currency

    const rollovers = (optionalResponse(result) ?? [])
      .map(r => optionalResponse(r))
      .filter(isNotNull)

    return {
      yieldCurrencyAmount: {
        currency: yieldTokenTrait,
      },
      rollovers: rollovers.map(r => ({
        from: {
          currency: yieldTokenTrait,
          amount: getAmountFromContract(r.dx),
        },
        to: {
          currency: yieldTokenTrait,
          amount: getAmountFromContract(r.dy),
        },
      })),
      expiryToRoll: args["expiry-to-roll"],
    }
  },
  pendingOrFailedTransformer: args => {
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    return {
      yieldCurrencyAmount: {
        currency: yieldTokenTrait,
      },
      rollovers: [],
      expiryToRoll: args["expiry-to-roll"],
    }
  },
}
