import { ComponentType } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { ButtonVariantProps } from "./ButtonVariant"
import * as btnPresets from "./_/defaultStyles"
import { WhiteFilledButton } from "./variants/WhiteFilledButton"

export { btnPresets }

export interface ButtonProps extends ButtonVariantProps {
  Variant?: ComponentType<ButtonVariantProps>
  renderVariant?: (renderProps: ButtonVariantProps) => JSX.Element
}
export const Button: FCC<ButtonProps> = props => {
  const {
    Variant: _Variant,
    renderVariant: _renderVariant,
    ...restProps
  } = props
  const renderVariant =
    _renderVariant ??
    ((props: ButtonVariantProps) => {
      const Variant = _Variant ?? WhiteFilledButton
      return <Variant {...props} />
    })

  return renderVariant(restProps)
}
