import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { SwitchLabel, ThumbSwitch } from "../../../../../components/ThumbSwitch"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { HarvestIcon } from "../../HarvestIcon"

export interface HarvestButtonProps {
  className?: string
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  alexTokenCount: number
  apowerTokenCount: number
  onClick?: (autoMint: boolean) => void
}

export const HarvestButton: FC<HarvestButtonProps> = props => {
  const [autoMint, setAutoMint] = useState(false)
  const canHarvest = props.alexTokenCount > 0 || props.apowerTokenCount > 0
  const { $t } = useIntl()

  return (
    <>
      <WhiteFilledButton
        disabled={!canHarvest}
        className={clsx(
          props.className,
          "flex flex-row flex-wrap gap-x-6 items-center justify-center",
        )}
        onClick={() => props.onClick?.(autoMint)}
      >
        <span className={"text-base text-gray-900"}>
          {$t(
            defineMessage({
              defaultMessage: "{icon} Harvest",
              description: "/Staking/MixedStake/HarvestButton",
            }),
            { icon: <HarvestIcon className={"inline-block"} /> },
          )}
        </span>
        {canHarvest && (
          <span className={"flex flex-row gap-2.5 text-sm text-blue-600"}>
            <span className={"block"}>
              <TokenIcon
                className={"inline-block align-text-bottom"}
                token={props.alexToken}
                size={16}
              />{" "}
              <TokenCount
                token={props.alexToken}
                count={props.alexTokenCount}
              />
            </span>

            <span className={"block"}>
              <TokenIcon
                className={"inline-block align-text-bottom"}
                token={TokenInfoPresets.APower}
                size={16}
              />{" "}
              <TokenCount
                token={TokenInfoPresets.APower}
                count={props.apowerTokenCount}
              />
            </span>
          </span>
        )}
      </WhiteFilledButton>

      {canHarvest && (
        <AutoMintToggle
          alexToken={props.alexToken}
          autoAlexToken={props.autoAlexToken}
          enabled={autoMint}
          onChange={setAutoMint}
        />
      )}
    </>
  )
}

const AutoMintToggle: FC<{
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  enabled: boolean
  onChange?: (enabled: boolean) => void
}> = props => {
  const { $t } = useIntl()
  return (
    <div className={"flex justify-center"}>
      <ThumbSwitch
        checked={props.enabled}
        onCheckedChange={props.onChange}
        right={
          <SwitchLabel>
            {$t(
              defineMessage({
                defaultMessage: "Auto Stake {tipIcon}",
                description: "/Staking/MixedStake/AutoMintToggle label",
              }),
              {
                tipIcon: (
                  <TipIcon
                    className={"inline-block align-text-bottom"}
                    tip={
                      <>
                        {$t(
                          defineMessage({
                            defaultMessage:
                              "Auto stake allows user to stake their manual {alexToken} staking rewards into {autoAlexToken} in a single transaction",
                            description:
                              "/Staking/MixedStake/AutoMintToggle tip",
                          }),
                          {
                            alexToken: <TokenName token={props.alexToken} />,
                            autoAlexToken: (
                              <TokenName token={props.autoAlexToken} />
                            ),
                          },
                        )}
                      </>
                    }
                  />
                ),
              },
            )}
          </SwitchLabel>
        }
      />
    </div>
  )
}
