import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["fwp-wstx-alex-tranched-64"] as const
const functionName = "distribute" as const

export interface CoFarmAutoHarvestRowData extends BaseRowData {
  type: NotifyTransactionType.CoFarmAutoHarvest
  cycle: number
  rewardCurrencyAmounts: CurrencyAmount[]
}

export const coFarmAutoHarvestTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  CoFarmAutoHarvestRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.CoFarmAutoHarvest,
  confirmedTransformer: (helpers, context) => {
    if (!context?.address) {
      throw new Error("context of address not found")
    }
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, context.address)

    return {
      cycle: args.cycle,
      rewardCurrencyAmounts: [
        {
          currency: Currency.ATALEX,
          amount: transferAmounts[Currency.ATALEX]?.in ?? undefined,
        },
      ],
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      cycle: args.cycle,
      rewardCurrencyAmounts: [
        {
          currency: Currency.ATALEX,
        },
      ],
    }
  },
}
