import { createContext } from "react"
import { ListItemDirection } from "../InfoList"

export interface InfoListDirectionContextValue {
  listItemDirection: ListItemDirection
}

export const InfoListDirectionContext =
  createContext<InfoListDirectionContextValue>({
    listItemDirection: "row",
  })
