import * as Sentry from "@sentry/react"
import { ErrorInfo, useEffect, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { FCC } from "../utils/reactHelpers/types"
import { LoadingIndicator } from "./LoadingIndicator/LoadingIndicator"
import { MaskErrorBoundary } from "./MaskErrorBoundary"
import { Button } from "./button/Button"
import { HeadlessButton } from "./button/HeadlessButton"
import { ErrorIcon, MessageItem } from "./message/MessageItem"
import { useMessage } from "./message/MessageProvider"

export const PageContentErrorBoundary: FCC = props => {
  const { $t } = useIntl()

  const msg = useMessage()

  const [caughtError, setCaughtError] = useState<null | {
    error: Error
    errorInfo: ErrorInfo
  }>(null)

  useEffect(() => {
    if (!caughtError) return

    const { error } = caughtError

    msg.show(p => ({
      autoDismiss: null,
      message: (
        <MessageItem
          onClose={p.close}
          icon={<ErrorIcon />}
          title={$t(
            defineMessage({
              defaultMessage: "Oops! Something went wrong.",
              description: "Page level error toast/title",
            }),
          )}
          content={
            <>
              <p>{error.message}</p>

              <div className={"flex flex-row gap-x-2.5"}>
                <Button
                  textClassName={"text-left text-blue-600 underline"}
                  Variant={HeadlessButton}
                  onClick={() => window.location.reload()}
                >
                  {$t(
                    defineMessage({
                      defaultMessage: "Reload Page",
                      description:
                        "Page level error toast/reload page button text",
                    }),
                  )}
                </Button>

                <a href={"/"}>
                  <Button
                    textClassName={"text-left text-blue-600 underline"}
                    Variant={HeadlessButton}
                  >
                    {$t(
                      defineMessage({
                        defaultMessage: "Go to Home",
                        description:
                          "Page level error toast/go to home button text",
                      }),
                    )}
                  </Button>
                </a>
              </div>
            </>
          }
        />
      ),
    }))
  }, [$t, caughtError, msg])

  return (
    <MaskErrorBoundary
      onDidCatchError={(error, errorInfo) => {
        Sentry.withScope(scope => {
          scope.setExtras({ errorInfo })
          Sentry.captureException(error)
        })
        setCaughtError({ error, errorInfo })
      }}
      fallbackContainerStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        minHeight: 300,
      }}
      fallback={(renderProps, contextInfo) =>
        contextInfo.hasSnapshot ? null : <LoadingIndicator />
      }
    >
      {() => props.children}
    </MaskErrorBoundary>
  )
}
