import { createContext, Suspense, useContext } from "react"
import { FCC } from "./reactHelpers/types"
import {
  ContextBridgeSymbol,
  createContextBridgeSymbol,
} from "./useContextBridge"

interface CreatedStore<T> {
  Provider: FCC<{ store: T }>
  useStore: () => T
  ContextBridgeSymbol: ContextBridgeSymbol<T | null>
}

export function createStore<T>(name: string): CreatedStore<T> {
  const Context = createContext<T | null>(null)

  const Provider: CreatedStore<T>["Provider"] = props => (
    <Context.Provider value={props.store}>
      <Suspense>{props.children}</Suspense>
    </Context.Provider>
  )
  Provider.displayName = `${name}.Provider`

  const useStore: CreatedStore<T>["useStore"] = () => {
    const store = useContext(Context)
    if (store == null) {
      throw new Error(
        `use${name} must be used within a ${Provider.displayName}`,
      )
    }
    return store
  }

  return {
    Provider,
    useStore,
    ContextBridgeSymbol: createContextBridgeSymbol(Context),
  }
}
