export namespace Colors {
  export const profitTextClassName = "text-green-600"
  export const lossTextClassName = "text-pink-500"

  export const depositTextClassName = profitTextClassName
  export const withdrawTextClassName = lossTextClassName

  export const increaseTextClassName = profitTextClassName
  export const decreaseTextClassName = lossTextClassName

  export const buyTextClassName = profitTextClassName
  export const sellTextClassName = lossTextClassName
}
