import clsx from "clsx"
import { FC, ReactNode, SVGProps } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../../../components/NavLink"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { Tooltip } from "../../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { assertNever } from "../../../../../utils/types"
import {
  LotteryTicket,
  LotteryTicketPrizeType,
  LotteryTicketType,
} from "../../types"
import { CopperCrown } from "../CopperCrown"
import { GoldenCrown } from "../GoldenCrown"
import { SilverCrown } from "../SilverCrown"
import { ReactComponent as UnknownStatusIcon } from "./default.svg"
import { ReactComponent as LoseStatusIcon } from "./lose.svg"
import loseBgImgSrc from "./loseBg.svg"
import { ReactComponent as OpenLinkIcon } from "./openLink.svg"
import unknownBgImgSrc from "./unknownBg.svg"
import winBgImgSrc from "./winBg.svg"

const LotteryTicketCardContainer: FC<{
  className?: string
  bgImageSrc?: string
  top: ReactNode
  bottom: ReactNode
}> = props => {
  return (
    <div
      className={clsx(
        "w-[160px] rounded overflow-hidden flex flex-col items-center",
        props.bgImageSrc == null ? "bg-blue-500/20" : "",
      )}
      style={
        props.bgImageSrc == null
          ? {}
          : {
              backgroundImage: `url(${props.bgImageSrc})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "160px 100%",
              backgroundPosition: "top center",
            }
      }
    >
      <div className={"p-2.5 w-full h-[102px] flex flex-col items-center"}>
        {props.top}
      </div>
      <div
        className={
          "p-2.5 w-full h-[138px] flex flex-col items-center justify-center"
        }
      >
        {props.bottom}
      </div>
    </div>
  )
}

const TicketNumber: FCC<{
  className?: string
  textClassName?: string
  number: number
}> = props => {
  const { $t } = useIntl()

  return (
    <p
      className={clsx(
        props.className,
        "text-xs leading-4 font-normal",
        props.textClassName ?? "text-gray-100",
      )}
    >
      <Tooltip title={"Lottery Ticket Number"}>
        {$t(
          defineMessage({
            defaultMessage: "No. {number}",
            description: "/Lottery/TicketCard/Number",
          }),
          { number: props.number },
        )}
      </Tooltip>
    </p>
  )
}

export const LotteryTicketCard$Unknown: FC<{
  ticket: LotteryTicket.Unknown
}> = props => {
  const { $t } = useIntl()

  return (
    <LotteryTicketCardContainer
      bgImageSrc={unknownBgImgSrc}
      top={
        <>
          <TicketNumber
            textClassName={"text-gray-400"}
            number={props.ticket.number}
          />
          <p className={"mt-[13px] text-lg font-semibold"}>
            {$t(
              defineMessage({
                defaultMessage: "Wait to open",
                description: "/Lottery/TicketCard/Content",
              }),
            )}
          </p>
        </>
      }
      bottom={<UnknownStatusIcon />}
    />
  )
}

export const LotteryTicketCard$Won: FC<{
  prizeToken: TokenInfo
  ticket: LotteryTicket.Won
}> = props => {
  const { $t } = useIntl()
  return (
    <LotteryTicketCardContainer
      bgImageSrc={winBgImgSrc}
      top={
        <>
          <TicketNumber number={props.ticket.number} />
          <p className={"text-lg leading-7 font-semibold text-white"}>
            {$t(
              defineMessage({
                defaultMessage: "WIN",
                description: "/Lottery/TicketCard/Content",
              }),
            )}
          </p>
          <p className={"text-xs text-gray-100"}>
            <TextTokenCount
              token={props.prizeToken}
              count={props.ticket.wonPrizeTokenCount}
            />
          </p>
        </>
      }
      bottom={
        <ul className={"flex flex-col gap-2.5 w-full h-full my-auto"}>
          {props.ticket.prizes.map((p, idx) => (
            <WinPrizeRow
              key={idx}
              prize={p}
              direction={props.ticket.prizes.length > 1 ? "row" : "column"}
            />
          ))}
        </ul>
      }
    />
  )
}

const WinPrizeRow: FC<{
  prize: LotteryTicket.Won["prizes"][number]
  direction: "row" | "column"
}> = props => {
  const { prize } = props

  let Icon: FC<SVGProps<SVGSVGElement>>
  let title: string
  let bgImg: string

  if (prize.type === LotteryTicketPrizeType.First) {
    Icon = GoldenCrown
    title = "1st Prize"
    bgImg = "linear-gradient(90deg, #DFA613 43.75%, #826500 100%)"
  } else if (prize.type === LotteryTicketPrizeType.Second) {
    Icon = SilverCrown
    title = "2nd Prize"
    bgImg = "linear-gradient(90deg, #85AAE2 43.75%, #4373AC 100%)"
  } else {
    Icon = CopperCrown
    title = "3rd Prize"
    bgImg = "linear-gradient(90deg, #A79974 43.75%, #715A36 100%)"
  }

  return (
    <div
      className={
        "w-full flex-1 border border-white/10 rounded overflow-hidden flex gap-1 items-center justify-center px-2.5 py-1"
      }
      style={{
        flexDirection: props.direction,
        backgroundImage: bgImg,
        backgroundClip: "border-box",
        backgroundPosition: "-1px",
        backgroundSize: "calc(100% + 2px)",
      }}
    >
      <span
        className={
          "bg-gray-900 rounded-full flex items-center justify-center p-[3px]"
        }
      >
        <Icon width={10} height={10} />
      </span>
      <span className={"text-sm text-white"}>{title}</span>
      {prize.explorerLink != null && (
        <NavLink
          className={props.direction === "row" ? "ml-auto" : ""}
          to={prize.explorerLink}
        >
          <OpenLinkIcon />
        </NavLink>
      )}
    </div>
  )
}

export const LotteryTicketCard$Lose: FC<{
  prizeToken: TokenInfo
  ticket: LotteryTicket.Lose
}> = props => {
  const { $t } = useIntl()
  return (
    <LotteryTicketCardContainer
      bgImageSrc={loseBgImgSrc}
      top={
        <>
          <TicketNumber
            className={"text-gray-400"}
            number={props.ticket.number}
          />
          <p className={"mt-[13px] text-lg font-semibold"}>
            {$t(
              defineMessage({
                defaultMessage: "LOSE",
                description: "/Lottery/TicketCard/Content",
              }),
            )}
          </p>
        </>
      }
      bottom={
        <>
          <LoseStatusIcon />
          <p className={"mt-2.5 text-sm text-gray-100 text-center"}>
            {$t(
              defineMessage({
                defaultMessage: "Better luck next time",
                description: "/Lottery/TicketCard/Content",
              }),
            )}
          </p>
        </>
      }
    />
  )
}

export interface LotteryTicketCardProps {
  prizeToken: TokenInfo
  lotteryTicket: LotteryTicket
}

export const LotteryTicketCard: FC<LotteryTicketCardProps> = props => {
  switch (props.lotteryTicket.type) {
    case LotteryTicketType.Unknown:
      return <LotteryTicketCard$Unknown ticket={props.lotteryTicket} />
    case LotteryTicketType.Won:
      return (
        <LotteryTicketCard$Won
          prizeToken={props.prizeToken}
          ticket={props.lotteryTicket}
        />
      )
    case LotteryTicketType.Lose:
      return (
        <LotteryTicketCard$Lose
          prizeToken={props.prizeToken}
          ticket={props.lotteryTicket}
        />
      )
    default:
      assertNever(props.lotteryTicket)
  }
}
