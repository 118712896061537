import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { btnPresets } from "../../../components/button/Button"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import {
  BlockGroup,
  BlockGroupPlusIcon,
} from "../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPair,
} from "../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../components/TokenName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { SlippageAndLPHint } from "./ChangeLiquiditySection/ChangeLiquiditySection"

export interface AddLiquidityConfirmationProps {
  tokenA: TokenCountPair
  tokenB: TokenCountPair
  tokenLP: TokenCountPair
  slippage: number
  tokenBInRiskOfSlippage: SuspenseResource<boolean>
  suggestedAAmount: SuspenseResource<number>
  suggestedBAmount: SuspenseResource<number>
  onConfirmAdjust?: () => void
  onConfirm?: () => void
  onClose?: () => void
}

const AddLiquidityConfirmation: FC<AddLiquidityConfirmationProps> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Add Liquidity",
          description: "/Pool/AddLiquidityConfirmation/Modal title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        icon={<BlockGroupPlusIcon />}
        firstBlock={<TokenBlock tokens={[props.tokenA]} />}
        secondBlock={<TokenBlock tokens={[props.tokenB]} />}
      />
      <SlippageAndLPHint slippage={props.slippage} tokenLP={props.tokenLP} />
      <Spensor>
        {() =>
          readResource(props.tokenBInRiskOfSlippage) && (
            <div className="w-full bg-white bg-opacity-5 rounded-lg p-3 sm:p-4 mb-8 flex flex-col items-center">
              <span className="text-red-500 text-sm">
                {$t(
                  defineMessage({
                    defaultMessage:
                      "The amounts entered may fail to be executed due to shifting market prices. According to your slippage settings, the below amounts are recommend to facilitate transaction execution.",
                    description: "/Pool/AddLiquidityConfirmation/Content",
                  }),
                )}
              </span>
              <div className="my-3 border-t border-red-600 w-full border-opacity-30" />
              <div className="w-full flex items-center justify-between">
                <div className="flex flex-col w-3/4">
                  <span className="text-gray-400 text-xs sm:text-sm mb-2">
                    {$t(
                      defineMessage({
                        defaultMessage: "Recommended Amount",
                        description: "/Pool/AddLiquidityConfirmation/Content",
                      }),
                    )}
                  </span>
                  <div className="flex w-1/4 justify-start">
                    <TokenIcon
                      token={props.tokenA.token}
                      size={24}
                      className="mr-3"
                    />
                    <Spensor>
                      {() => (
                        <TokenCount
                          token={props.tokenA.token}
                          count={readResource(props.suggestedAAmount)}
                        />
                      )}
                    </Spensor>
                    <TokenName token={props.tokenA.token} className="ml-3" />
                  </div>
                  <div className="flex w-1/4 justify-start mt-2">
                    <TokenIcon
                      token={props.tokenB.token}
                      size={24}
                      className="mr-3"
                    />
                    <Spensor>
                      {() => (
                        <TokenCount
                          token={props.tokenB.token}
                          count={readResource(props.suggestedBAmount)}
                        />
                      )}
                    </Spensor>
                    <TokenName token={props.tokenB.token} className="ml-3" />
                  </div>
                </div>
                <GradientFilledButton
                  {...btnPresets.small}
                  onClick={props.onConfirmAdjust}
                >
                  {$t(
                    defineMessage({
                      defaultMessage: "Adjust",
                      description: "/Pool/AddLiquidityConfirmation/Button text",
                    }),
                  )}
                </GradientFilledButton>
              </div>
            </div>
          )
        }
      </Spensor>

      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Pool/AddLiquidityConfirmation/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Pool/AddLiquidityConfirmation/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}

export default AddLiquidityConfirmation
