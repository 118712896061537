import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { loading$t } from "../../../commonIntlMessages"
import { CardInset } from "../../../components/Card"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { NoteParagraph } from "../../../components/NoteParagraph/NoteParagraph"
import { Spensor } from "../../../components/Spensor"
import {
  LoadableButton,
  SmartLoadableButton,
} from "../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"

export const ResetThenApproveNoticeModalContent: FC<{
  onStart: () => Promise<void>
  onDismiss: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      title={$t(
        defineMessage({
          defaultMessage: "Info",
          description: `WrapBridge/"Reset-Approve" notice modal/modal title`,
        }),
      )}
      onClose={props.onDismiss}
      width={500}
    >
      <div className={"mb-[14px] flex flex-col gap-y-2.5"}>
        <CardInset bgClassName={"bg-blue-100"} boxClassName={"p-2.5"}>
          <NoteParagraph
            textSizingClassName={"text-sm leading-5 font-semibold"}
            textColorClassName={"text-blue-900"}
          >
            {$t(
              defineMessage({
                defaultMessage:
                  "To change allowance you would need to send two transactions",
                description: `WrapBridge/"Reset-Approve" notice modal/notice text`,
              }),
            )}
          </NoteParagraph>
        </CardInset>

        <p className={"text-gray-200"}>
          {$t(
            defineMessage({
              defaultMessage:
                "Certain tokens require several transactions to change allowance. First you would need to reset allowance to zero, and only then set new allowance value.",
              description: `WrapBridge/"Reset-Approve" notice modal/notice text`,
            }),
          )}
        </p>
      </div>

      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(loading$t)}
          </LoadableButton>
        }
      >
        {() => (
          <SmartLoadableButton
            Variant={GradientFilledButton}
            onClick={props.onStart}
          >
            {$t(
              defineMessage({
                defaultMessage: "Proceed with two step approve",
                description: `WrapBridge/"Reset-Approve" notice modal/"Proceed with two step approve" button`,
              }),
            )}
          </SmartLoadableButton>
        )}
      </Spensor>
    </CardModalContent>
  )
}
