import { MotionConfig } from "framer-motion"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../components/button/Button"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import { Card, CardTitle } from "../../../../components/Card"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  suspenseResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { FormErrorType as AutoStakeFormErrorType } from "../../autoStakeComponents/AddStakeSection/types"
import { FormErrorType as ManualStakeFormErrorType } from "../../manualStakeComponents/AddStakeSection/types"
import { FormError, OnSwitchFormModeFn, StakeForm } from "./types"
import { renderAutoModeForm } from "./_/AutoModeForm"
import { renderManualModeForm } from "./_/ManualModeForm"

export interface AddStakeSectionProps {
  className?: string

  alexToken: TokenInfo
  autoAlexToken: TokenInfo

  form: StakeForm
  onSwitchFormMode?: OnSwitchFormModeFn

  maxCycleCount?: number

  error?: SuspenseResource<FormError | undefined>
  onSubmit?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
}

export const AddStakeSection: FC<AddStakeSectionProps> = props => {
  return (
    <MotionConfig transition={{ duration: 0.3 }}>
      <AddStakeSectionImpl {...props} />
    </MotionConfig>
  )
}

const AddStakeSectionImpl: FC<AddStakeSectionProps> = props => {
  const [customizeEnabled, setCustomizeEnabled] = useState(false)
  const { maxCycleCount } = props
  const intl = useIntl()
  const { $t } = intl

  return (
    <Card className={props.className}>
      <div className={"flex flex-row items-center justify-between"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Add Staking",
              description: "/Stake/MixedStake/AddStakeSection/Card title",
            }),
          )}
        </CardTitle>
        {props.onClear && (
          <HeadlessButton className={"text-blue-600"} onClick={props.onClear}>
            {$t(
              defineMessage({
                defaultMessage: "Clear",
                description: "/Stake/MixedStake/AddStakeSection/Button text",
              }),
            )}
          </HeadlessButton>
        )}
      </div>
      <div className={"mt-4 mb-[30px] flex flex-col gap-4"}>
        {maxCycleCount == null &&
          (props.form.mode === "auto"
            ? renderAutoModeForm({
                gapClassName: "gap-4",
                alexToken: props.alexToken,
                autoAlexToken: props.autoAlexToken,
                isTokenInputShouldBeErrorState: suspenseResource(() =>
                  isTokenInputShouldBeErrorState(readResource(props.error)),
                ),
                form: props.form,
                customizeEnabled: customizeEnabled,
                onCustomizeEnableChanged: setCustomizeEnabled,
                onSwitchFormMode: props.onSwitchFormMode,
                intl,
              })
            : renderManualModeForm({
                gapClassName: "gap-4",
                alexToken: props.alexToken,
                isTokenInputShouldBeErrorState: suspenseResource(() =>
                  isTokenInputShouldBeErrorState(readResource(props.error)),
                ),
                form: props.form,
                customizeEnabled: customizeEnabled,
                onCustomizeEnableChanged: enabled => {
                  setCustomizeEnabled(enabled)
                  if (!enabled) {
                    props.onSwitchFormMode?.({ mode: "auto" })
                  }
                },
                onSwitchFormMode: props.onSwitchFormMode,
                intl,
              }))}

        {maxCycleCount != null &&
          (props.form.mode === "auto"
            ? renderAutoModeForm({
                gapClassName: "gap-4",
                alexToken: props.alexToken,
                autoAlexToken: props.autoAlexToken,
                isTokenInputShouldBeErrorState: suspenseResource(() =>
                  isTokenInputShouldBeErrorState(readResource(props.error)),
                ),
                form: props.form,
                customizeEnabled: false,
                onCustomizeEnableChanged: enabled => {
                  if (enabled) {
                    props.onSwitchFormMode?.({
                      mode: "manual",
                      cycleCount: maxCycleCount,
                    })
                  }
                },
                onSwitchFormMode: props.onSwitchFormMode,
                intl,
              })
            : renderManualModeForm({
                gapClassName: "gap-4",
                alexToken: props.alexToken,
                isTokenInputShouldBeErrorState: suspenseResource(() =>
                  isTokenInputShouldBeErrorState(readResource(props.error)),
                ),
                form: props.form,
                customizeEnabled: true,
                onCustomizeEnableChanged: enabled => {
                  if (!enabled) {
                    props.onSwitchFormMode?.({ mode: "auto" })
                  }
                },
                onSwitchFormMode: props.onSwitchFormMode,
                intl,
              }))}
      </div>

      {isErrorStateButtonShouldRenderAsConnectWallet(
        safeReadResource(props.error),
      ) ? (
        <GradientFilledButton onClick={props.onConnectWallet}>
          {$t(
            defineMessage({
              defaultMessage: "Connect Wallet",
              description: "/Stake/MixedStake/AddStakeSection/Button text",
            }),
          )}
        </GradientFilledButton>
      ) : safeReadResource(props.error) ? (
        <Button
          Variant={
            isErrorStateButtonShouldRenderAsGradientButton(
              safeReadResource(props.error),
            )
              ? GradientFilledButton
              : RedFilledButton
          }
          disabled={true}
        >
          {safeReadResource(props.error)?.message}
        </Button>
      ) : (
        <GradientFilledButton onClick={props.onSubmit}>
          {$t(
            defineMessage({
              defaultMessage: "Stake",
              description: "/Stake/MixedStake/AddStakeSection/Button text",
            }),
          )}
        </GradientFilledButton>
      )}
    </Card>
  )
}

const isErrorStateButtonShouldRenderAsConnectWallet = (
  error?: FormError,
): boolean => {
  if (!error) return false
  return error.type === AutoStakeFormErrorType.WalletNotConnected
  // || error.type === ManualStakeFormErrorType.WalletNotConnected
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: FormError,
): boolean => {
  if (!error) return false
  return error.type === AutoStakeFormErrorType.AmountIsEmpty
  // || error.type === ManualStakeFormErrorType.AmountIsEmpty
}

const isTokenInputShouldBeErrorState = (error?: FormError): boolean => {
  if (!error) return false
  return (
    // error.type === AutoStakeFormErrorType.InsufficientTokenBalance ||
    error.type === ManualStakeFormErrorType.InsufficientTokenBalance ||
    // error.type === AutoStakeFormErrorType.LessThanMinimizeAmount ||
    error.type === ManualStakeFormErrorType.LessThanMinimizeAmount
  )
}
