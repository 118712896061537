import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "./BaseRoundedFilledButton"

export const WhiteBorderButton: FC<RoundedButtonVariantProps> = props => {
  return (
    <BaseRoundedFilledButton
      {...props}
      className={clsx(
        "text-gray-200",
        "border-2 border-gray-100 hover:border-gray-300 active:border-gray-400 disabled:border-gray-400 disabled:opacity-30",
        props.className,
      )}
    >
      {props.children}
    </BaseRoundedFilledButton>
  )
}
