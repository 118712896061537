import clsx from "clsx"
import { createElement, FC, Ref, useRef } from "react"
import { useIsFocusing } from "../../utils/reactHelpers/useIsFocusing"
import { Block, BlockProps } from "./Block"

export interface ChildrenProps {
  inputRef: Ref<HTMLInputElement>
}

export interface BlockInputContainerProps {
  className?: string
  boxClassName?: string
  disabled?: boolean
  error?: boolean
  readonly?: boolean
  renderBlock?: (props: BlockProps) => JSX.Element
  children?: (props: ChildrenProps) => JSX.Element
}

export const BlockInputContainer: FC<BlockInputContainerProps> = props => {
  const {
    renderBlock = props => createElement(Block, props),
    children = props => createElement("input", props),
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const isInputFocusing = useIsFocusing(inputRef)

  // prettier-ignore
  const borderClassName = (
    props.disabled ? undefined :
    isInputFocusing ? "border-blue-800" :
    props.error ? "border-red-600" :
    undefined
  );

  const blockProps: BlockProps = {
    className: clsx(
      "rounded-lg flex flex-col border",
      (!props.readonly || props.disabled) && "bg-black/20",
      props.disabled && "opacity-30 pointer-events-none",
      props.className,
    ),
    boxClassName: props.boxClassName,
    borderClassName,
    onClick: e => {
      if (e.defaultPrevented) return
      if (isInputFocusing) return
      queueMicrotask(() => {
        inputRef.current?.focus()
      })
    },
    children: children({ inputRef }),
  }

  // Components must have JSX mark, or it won't be wrapped by `observer`
  // automatically, then it will not auto-refresh once any dependent mobx state
  // changed
  return <>{renderBlock(blockProps)}</>
}

export const defaultInputClassNames = (
  info: {
    error?: boolean
    typography?: boolean
  } = {},
): string =>
  clsx(
    "appearance-none bg-transparent outline-none border-none",
    "flex-1 min-w-0 w-full",
    ["text-gray-200", info.error && "text-red-500"],
    info.typography && "text-lg leading-7",
  )
