import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"
import { TokenInfoPresets } from "../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenName } from "./TokenName"

export const MayDollarSignTokenName: FC<{ token: TokenInfo }> = props => (
  <>
    {TokenInfoPresets.isAlex(props.token) ? "$" : ""}
    <TokenName token={props.token} />
  </>
)
