import { FC, useMemo, useState } from "react"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { SmartLoadableButton } from "../../../components/button/LoadableButton"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { waitFor } from "../../../utils/waitFor"

const SponsorTx: FC = () => {
  const auth = useAuthStore()
  const [tx, setTx] = useState("")
  const txStore = useMemo(() => new ConfirmTransactionStore(), [])
  return (
    <div className="flex flex-col gap-y-2">
      <textarea
        className="w-full"
        value={tx}
        onChange={e => setTx(e.target.value)}
      />
      <SmartLoadableButton
        onClick={async () => {
          const sender = await waitFor(() => auth.stxAddress$)
          await txStore.run(() =>
            asSender(sender)
              .contract("amm-swap-pool-v1-1")
              .func("swap-helper")
              .call(
                {
                  "token-x-trait": Currency.W_STX,
                  "token-y-trait": Currency.ALEX,
                  factor: 1e8,
                  "min-dy": undefined,
                  dx: 1e8,
                },
                undefined,
                {
                  sponsored: true,
                },
              ),
          )
        }}
      >
        Execute
      </SmartLoadableButton>
      <WiredTransactionStateDialog store={txStore} />
    </div>
  )
}

export default SponsorTx
