import { FC, Fragment } from "react"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import { ClaimTokenInfo } from "../types"

export const ClaimTokenInfoList: FC<{
  claimTokenInfos: ClaimTokenInfo[]
}> = props => (
  <>
    {props.claimTokenInfos.map((c, idx) => (
      <Fragment key={idx}>
        {idx !== 0 && <>&nbsp;+&nbsp;</>}
        <span>
          <TokenCount token={c.token} count={c.count} />
          &nbsp;
          <TokenName token={c.token} />
        </span>
      </Fragment>
    ))}
  </>
)
