import { gql } from "@urql/core"
import { Observable } from "rxjs"
import { ALLOW_CONTRACT_ARGUMENTATION, IS_MAIN_NET } from "../../../config"
import {
  FetchLaunchPadCmsDetailQuery,
  FetchLaunchPadCmsDetailQueryVariables,
  LaunchPadCmsDetailFragmentFragment,
} from "../../../generated/graphql/graphql.generated"
import {
  TokenFragment,
  parseTokenFragment,
} from "../../../stores/currencyStore/CurrencyStore.service"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import {
  APowerRuleRange,
  IDOGovernanceVoteStatus,
  TokenMetaInfo,
} from "../component/types"
import { LaunchPadVersion } from "./LaunchPadContractStore.service"

export interface LaunchPadContentfulDetail {
  urlSlug: string
  featuredImage?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  meta: TokenMetaInfo
  introHtml: string
  idoId?: number
  idoVersion?: LaunchPadVersion
  hotBadge: boolean
  estimateIdoAmount: number
  estimateValidationStart?: Date
  estimateValidationEnd?: Date
  totalRaisePriceTokenCount?: number
  pricePerIdoToken?: number
  aPowerRules?: APowerRuleRange[]
  maxAllowedTickets?: number
  tosLink?: string
  finishedAllTimeHigh?: number
  finishedFillRate?: number
  finishedParticipateWallets?: number
  finishedDate?: Date
  distributionDate?: Date
}

export const LaunchPadCMSDetailFragment = gql`
  ${TokenFragment}
  fragment LaunchPadCMSDetailFragment on Ido {
    urlSlug
    idoToken {
      ...TokenFragment
    }
    priceToken {
      ...TokenFragment
    }
    title
    subtitle
    governanceVotePageLink
    governanceVoteStatus
    featuredImage {
      url(transform: { width: 1024, height: 606 })
    }
    idoId
    idoIdDev
    idoVersion
    tokenImage {
      url(transform: { width: 200, height: 200 })
    }
    website
    discordLink
    mediumLink
    twitterLink
    content
    hotBadge
    estimateIdoAmount
    estimateValidationStart
    estimateValidationEnd
    estimatedPriceTokenCount
    idoTokenPriceInPriceToken
    aPowerRules
    maxAllowTicket
    tosLink
    finishedAllTimeHigh
    finishedFillRate
    finishedParticipateWallets
    finishedDate
    distributionDate
  }
`

const argumentIdoID =
  ALLOW_CONTRACT_ARGUMENTATION &&
  new URLSearchParams(window.location.search).has("asIdoId")
    ? Number(new URLSearchParams(window.location.search).get("asIdoId"))
    : undefined

export function mapLaunchPadCmsDetailFragment(
  ido: LaunchPadCmsDetailFragmentFragment,
): LaunchPadContentfulDetail {
  const idoToken = parseTokenFragment(ido.idoToken!)

  const vote =
    ido.governanceVoteStatus == null || ido.governanceVotePageLink == null
      ? undefined
      : ({
          link: ido.governanceVotePageLink,
          status:
            ido.governanceVoteStatus === "Not Started"
              ? IDOGovernanceVoteStatus.Upcoming
              : ido.governanceVoteStatus === "In Progress"
              ? IDOGovernanceVoteStatus.Ongoing
              : ido.governanceVoteStatus === "Approved"
              ? IDOGovernanceVoteStatus.Approved
              : ido.governanceVoteStatus === "Rejected"
              ? IDOGovernanceVoteStatus.Rejected
              : IDOGovernanceVoteStatus.Upcoming,
        } satisfies NonNullable<TokenMetaInfo["governanceVote"]>)

  return {
    urlSlug: ido.urlSlug!,
    featuredImage: ido.featuredImage?.url ?? undefined,
    idoId:
      argumentIdoID ?? (IS_MAIN_NET ? ido.idoId : ido.idoIdDev) ?? undefined,
    idoVersion: (ido.idoVersion as LaunchPadVersion) ?? "alex-launchpad-v1-3",
    meta: {
      title: ido.title!,
      subtitle: ido.subtitle ?? undefined,
      logoSrc: ido.tokenImage!.url!,
      tokenType: idoToken.propertyTags.includes("brc-20")
        ? "brc20Token"
        : idoToken.propertyTags.includes("ordinals-voucher")
        ? "ordinals"
        : "stacksToken",
      governanceVote: vote,
      links: {
        website: ido.website!,
        discord: ido.discordLink!,
        medium: ido.mediumLink!,
        twitter: ido.twitterLink!,
      },
    },
    introHtml: ido.content ?? "",
    hotBadge: ido.hotBadge ?? false,
    estimateIdoAmount: ido.estimateIdoAmount!,
    estimateValidationStart: ido.estimateValidationStart
      ? new Date(ido.estimateValidationStart)
      : undefined,
    estimateValidationEnd: ido.estimateValidationEnd
      ? new Date(ido.estimateValidationEnd)
      : undefined,
    idoToken,
    priceToken: parseTokenFragment(ido.priceToken!),
    pricePerIdoToken: ido.idoTokenPriceInPriceToken ?? undefined,
    totalRaisePriceTokenCount: ido.estimatedPriceTokenCount ?? undefined,
    aPowerRules: ido.aPowerRules ?? undefined,
    maxAllowedTickets: ido.maxAllowTicket ?? undefined,
    tosLink: ido.tosLink ?? undefined,
    finishedAllTimeHigh: ido.finishedAllTimeHigh ?? undefined,
    finishedFillRate: ido.finishedFillRate ?? undefined,
    finishedParticipateWallets: ido.finishedParticipateWallets ?? undefined,
    finishedDate: ido.finishedDate ? new Date(ido.finishedDate) : undefined,
    distributionDate: ido.distributionDate
      ? new Date(ido.distributionDate)
      : undefined,
  }
}

export const fetchContentfulDetails = (
  urlSlug: string,
): Observable<LaunchPadContentfulDetail> =>
  fromUrqlSource(
    gqlQuery<
      FetchLaunchPadCmsDetailQuery,
      FetchLaunchPadCmsDetailQueryVariables
    >(
      gql`
        ${LaunchPadCMSDetailFragment}
        query FetchLaunchPadCMSDetail($urlSlug: String!) {
          idoCollection(where: { urlSlug: $urlSlug }, limit: 1) {
            items {
              ...LaunchPadCMSDetailFragment
            }
          }
        }
      `,
      { urlSlug },
    ),
    ({ data }) => {
      const ido = data?.idoCollection?.items?.[0]
      if (ido == null) {
        throw new Error(`No IDO data for urlSlug: ${urlSlug}`)
      }
      return mapLaunchPadCmsDetailFragment(ido)
    },
  )
