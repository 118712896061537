import { FC, ReactNode } from "react"
import { DateRange } from "react-day-picker"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../components/Card"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import {
  Block,
  DefaultTokenNameArea,
} from "../../../components/TokenInput/Block"
import { TokenInput } from "../../../components/TokenInput/TokenInput"
import { Truncatable } from "../../../components/Truncatable"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { DateRangeSelector, SelectorItem } from "./DateRangeSelector"

const tokenX = TokenInfoPresets.MockXBTC
const tokenY = TokenInfoPresets.MockXUSD

export interface SimulatorPanelProps {
  collateralBtcCount?: number
  onCollateralBtcCountChange?: (value?: number | null) => void
  estimateReceivedUSDCount: SuspenseResource<number | undefined>

  selectorItems: SelectorItem[]
  selectorValue?: string
  selectorDateRange?: DateRange
  onDateRangeSelect?: (value: string, dateRange?: DateRange) => void
}

export const SimulatorPanel: FC<SimulatorPanelProps> = props => {
  const { $t } = useIntl()
  return (
    <Card className="flex flex-col gap-y-6">
      <CardTitle className="flex flex-row items-center gap-x-2.5">
        <span className="text-xl leading-7 font-medium text-gray-200">
          {$t(
            defineMessage({
              defaultMessage: "CRP Simulator",
              description: "/CRPSimulator/SimulatorPanel/Title",
            }),
          )}
        </span>
        <TipIcon
          tip={$t(
            defineMessage({
              defaultMessage:
                "The CRP simulator demonstrates how the CRP would have performed using historical data.",
              description: "/CRPSimulator/SimulatorPanel/Tip text",
            }),
          )}
        />
      </CardTitle>
      <ListItem
        title={$t(
          defineMessage({
            defaultMessage: "1. Select Collateral:",
            description: "/CRPSimulator/SimulatorPanel/ListItem title",
          }),
        )}
      >
        <TokenInput
          token={tokenX}
          value={props.collateralBtcCount ?? 0}
          onChange={props.onCollateralBtcCountChange}
        />
      </ListItem>
      <ListItem
        title={$t(
          defineMessage({
            defaultMessage: "2. Select Collateral Dates:",
            description: "/CRPSimulator/SimulatorPanel/ListItem title",
          }),
        )}
        tip={$t(
          defineMessage({
            defaultMessage: "Select the date range of historical data.",
            description: "/CRPSimulator/SimulatorPanel/ListItem tip text",
          }),
        )}
      >
        <DateRangeSelector
          selectorItems={props.selectorItems}
          value={props.selectorValue}
          onSelect={props.onDateRangeSelect}
          dateRange={props.selectorDateRange}
        />
      </ListItem>
      <ListItem
        title={$t(
          defineMessage({
            defaultMessage: "3. Estimate Amount Received",
            description: "/CRPSimulator/SimulatorPanel/ListItem title",
          }),
        )}
        tip={$t(
          defineMessage({
            defaultMessage:
              "Est amount of xUSD borrowed against collateral using max LTV (Loan-to-Value).",
            description: "/CRPSimulator/SimulatorPanel/ListItem tip text",
          }),
        )}
      >
        <Block boxClassName={"px-3 sm:px-6 py-3 sm:py-4"}>
          <div className={"flex flex-col gap-2.5"}>
            <div className={"flex items-center gap-4"}>
              <DefaultTokenNameArea token={tokenY} boxClassName={""} />
              <Spensor
                fallback={
                  <span className="flex-1 text-lg leading-7 font-medium text-gray-200/30 text-right">
                    {props.selectorValue ? "-" : "Select a date"}
                  </span>
                }
              >
                {() => (
                  <Truncatable
                    className={
                      "text-2xl sm:text-3xl leading-9 font-medium text-gray-200 text-right flex-1 min-w-0"
                    }
                  >
                    <TokenCount
                      token={tokenY}
                      count={readResource(props.estimateReceivedUSDCount) ?? 0}
                    />
                  </Truncatable>
                )}
              </Spensor>
            </div>
          </div>
        </Block>
      </ListItem>
    </Card>
  )
}

const ListItem: FC<{ title: string; tip?: string; children: ReactNode }> = ({
  title,
  tip,
  children,
}) => (
  <div className="flex flex-col gap-y-2.5">
    <div className="flex flex-row items-center gap-x-2.5 text-sm leading-5 font-normal text-gray-400">
      <span>{title}</span>
      {tip && <TipIcon tip={tip} />}
    </div>
    {children}
  </div>
)
