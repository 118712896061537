import { action, computed, makeObservable } from "mobx"
import { map } from "rxjs"
import {
  LazyValue,
  SharedLazyValue,
} from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { BigNumber } from "../../../../utils/BigNumber"
import { Result } from "../../../../utils/Result"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { BRC20Currency, isBRC20Token } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { waitFor } from "../../../../utils/waitFor"
import { type NetworkFeeRatePatch } from "../components/EditFeeModalContent"
import {
  TransferFormError,
  TransferFormErrorType,
} from "../components/PegInFormModalContent.types"
import type { AvailableTokenInfo } from "../components/TransferPanel"
import { BitcoinClient } from "./BitcoinClient/BitcoinClient"
import { BitcoinNetwork } from "./BitcoinClient/BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "./BitcoinClient/BitcoinClientBRC20Wallet"
import { WalletAdapter } from "./WalletAdapters/WalletAdapters.types"

export class PegInFormModule {
  constructor(
    private currencyStore: CurrencyStore,
    private btcClient: SharedLazyValue<BitcoinClient>,
    private btcAdapter: SharedLazyValue<WalletAdapter>,
    private brc20Wallet: SharedLazyValue<BitcoinClientBRC20Wallet>,
    private brc20WalletBalance: SharedLazyValue<
      (AvailableTokenInfo & { brc20Currency: BRC20Currency; symbol: string })[]
    >,
    private onInscribeOrderPaid: (info: {
      orderId: string
      paymentTxId: string
      currency: BRC20Currency
      currencyAmount: BigNumber
    }) => Promise<void>,
  ) {
    makeObservable(this)
  }

  recommendedBitcoinNetworkFeeRates = new LazyValue(
    () => this.btcClient.value$,
    btcClient => btcClient.networkInfo.pipe(map(info => info.fees)),
  )

  peggingCurrency = new SuspenseObservable<BRC20Currency>()

  peggingAmount = new SuspenseObservable<BigNumber>()

  networkFeeRate = new SuspenseObservable<{
    type: "fast" | "avg" | "slow" | "custom"
    rate: BigNumber
  }>()

  inscribeFormData = new SuspenseObservable<{
    originFormData: PegInFormModuleFormData
    token: TokenInfo
    tokenCount: BigNumber
    btcNetwork: BitcoinNetwork
    inscribeServiceFeeToken: TokenInfo
    inscribeServiceFeeTokenCount: BigNumber
    inscribeServiceFeeTokenCountToUSD: BigNumber
    networkFeeRateToken: TokenInfo
    networkFeeRateTokenCount: BigNumber
    chargeBtcAddress: string
    inscribeOrderId: string
    inscribeOrderLink: string
  }>()

  reset(): void {
    this.peggingCurrency.set(undefined)
    this.peggingAmount.set(undefined)
    this.inscribeFormData.set(undefined)
    this.networkFeeRate.set(undefined)
  }

  @computed
  get selectedTokenBalance$(): AvailableTokenInfo & {
    brc20Currency: BRC20Currency
    symbol: string
  } {
    return this.brc20WalletBalance.value$.find(
      token => token.brc20Currency === this.peggingCurrency.read$,
    )!
  }

  @computed
  get peggingToken$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.peggingCurrency.read$)
  }

  @computed
  get peggingTokenAvailableAmount$(): BigNumber {
    return (
      this.brc20WalletBalance.value$.find(
        b => b.brc20Currency === this.peggingCurrency.read$,
      )?.availableBalance ?? BigNumber.ZERO
    )
  }

  @computed
  get formData$(): Result<PegInFormModuleFormData, TransferFormError> {
    if (this.peggingCurrency.get() == null) {
      return Result.error({ type: TransferFormErrorType.TokenNotSelected })
    }

    if (
      this.peggingAmount.get() == null ||
      BigNumber.isZero(this.peggingAmount.read$)
    ) {
      return Result.error({ type: TransferFormErrorType.AmountIsEmpty })
    }

    if (
      BigNumber.isLt(
        this.selectedTokenBalance$.totalBalance,
        this.peggingAmount.read$,
      )
    ) {
      return Result.error({ type: TransferFormErrorType.InsufficientBalance })
    }

    return Result.ok({
      tokenSymbol: this.selectedTokenBalance$.symbol,
      tokenCurrency: this.peggingCurrency.read$,
      amount: this.peggingAmount.read$,
    })
  }

  @action
  onDismissInscribeConfirmModal(): void {
    this.inscribeFormData.set(undefined)
  }

  async trySelectBRC20Currency(currency: string): Promise<void> {
    if (!isBRC20Token(currency)) return

    const tokenInfo = waitFor(() => this.currencyStore.getTokenInfo$(currency))
    if (tokenInfo == null) return

    this.peggingCurrency.set(currency)
  }

  async onChangeNetworkFeeRate(
    formData: PegInFormModuleFormData,
    rate: NetworkFeeRatePatch,
  ): Promise<void> {
    if (rate.type === "custom") {
      this.networkFeeRate.set({
        type: "custom",
        rate: rate.rate,
      })
      await this.regenerateInscribeOrder(formData, rate.rate)
      return
    }

    const networkFee = await waitFor(
      () => this.recommendedBitcoinNetworkFeeRates.value$,
    )
    const finalFeeRate =
      networkFee[
        rate.type === "fast"
          ? "fastestFee"
          : rate.type === "avg"
          ? "halfHourFee"
          : rate.type === "slow"
          ? "hourFee"
          : "fastestFee"
      ]
    await this.regenerateInscribeOrder(
      formData,
      BigNumber.from(networkFee.fastestFee),
    )
  }

  async submit(formData: PegInFormModuleFormData): Promise<void> {
    const networkFee = await waitFor(
      () => this.recommendedBitcoinNetworkFeeRates.value$,
    )

    this.networkFeeRate.set({
      type: "fast",
      rate: BigNumber.from(networkFee.fastestFee),
    })

    await this.regenerateInscribeOrder(
      formData,
      BigNumber.from(networkFee.fastestFee),
    )
  }

  async regenerateInscribeOrder(
    formData: PegInFormModuleFormData,
    networkFeeRate: BigNumber,
  ): Promise<void> {
    const token = await waitFor(() =>
      this.currencyStore.getTokenInfo$(formData.tokenCurrency),
    )

    const resp = await this.brc20Wallet.value$.createTokenTransferInscribeOrder(
      formData.tokenSymbol,
      formData.amount,
      BigNumber.toNumber(networkFeeRate),
    )

    this.inscribeFormData.set({
      originFormData: formData,
      token,
      tokenCount: formData.amount,
      btcNetwork: this.btcClient.value$.network,
      inscribeServiceFeeToken: TokenInfoPresets.MockSats,
      inscribeServiceFeeTokenCount: resp.charge.amount,
      inscribeServiceFeeTokenCountToUSD: resp.charge.feeToUSD,
      networkFeeRateToken: TokenInfoPresets.MockSats,
      networkFeeRateTokenCount: networkFeeRate,
      chargeBtcAddress: resp.charge.address,
      inscribeOrderId: resp.id,
      inscribeOrderLink: resp.orderLink,
    })
  }

  async payInscribeOrder(): Promise<
    undefined | { txId: string; explorerLink: string }
  > {
    const formData = this.inscribeFormData.get()
    if (formData == null) return

    const adapter = await waitFor(() => this.btcAdapter.value$)

    const resp = await adapter.sendBitcoin(
      formData.chargeBtcAddress,
      formData.inscribeServiceFeeTokenCount,
      { feeRate: BigNumber.toNumber(formData.networkFeeRateTokenCount) },
    )

    await this.onInscribeOrderPaid({
      orderId: formData.inscribeOrderId,
      paymentTxId: resp.txId,
      currency: formData.originFormData.tokenCurrency,
      currencyAmount: formData.tokenCount,
    })

    this.onDismissInscribeConfirmModal()
    this.reset()
    return {
      txId: resp.txId,
      explorerLink: await this.btcClient.value$.explorerLink(resp.txId),
    }
  }
}

export interface PegInFormModuleFormData {
  tokenCurrency: BRC20Currency
  tokenSymbol: string
  amount: BigNumber
}
