import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { TipIcon } from "../../../../../../components/TipIcon/TipIcon"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "../../../../../../components/TokenInput/BlockInputContainer"
import { WhiteFilledButton } from "../../../../../../components/button/variants/WhiteFilledButton"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../../../../utils/SuspenseResource"
import { trunc } from "../../../../../../utils/addressHelpers"
import { normal } from "../../../../../Perpetual/components/buttonPresets"
import { RectButton } from "../../../../../Perpetual/components/wrappedCommonComponents/RectButton"

export const AddressSection: FC<{
  targetBtcAddress?: SuspenseResource<string>
  onTargetBtcAddressChange: (value: string) => void
  candidateTargetBtcAddress: SuspenseResource<string | undefined>
  onFillCandidateTargetBtcAddress: undefined | (() => void)
}> = props => {
  const { $t } = useIntl()
  return (
    <div className="bg-black/10 rounded-lg p-4 space-y-2.5">
      <p className="text-gray-200 text-xs">
        {$t(
          defineMessage({
            defaultMessage:
              "ALEX will send your Pegged-out BRC-20 into your Bitcoin address:",
            description:
              "/OrderBook/BRC20/PegOutBrc20ModalContent/AddressSection text",
          }),
        )}
      </p>
      <BlockInputContainer boxClassName={"px-4 py-3"}>
        {p => (
          <input
            ref={p.inputRef}
            type="text"
            placeholder={$t(
              defineMessage({
                defaultMessage: "Input Your BTC address to receive BRC-20...",
                description:
                  "/OrderBook/BRC20/PegOutBrc20ModalContent/Input placeholder",
              }),
            )}
            className={defaultInputClassNames()}
            value={readResource(props.targetBtcAddress)}
            onChange={e => props.onTargetBtcAddressChange(e.target.value)}
          />
        )}
      </BlockInputContainer>

      {safeReadResource(props.candidateTargetBtcAddress) != null && (
        <div className="flex bg-blue-900 px-4 py-2.5 items-center rounded-lg justify-between">
          <div className="text-sm font-bold text-white flex items-center space-x-1.5">
            <span>
              {trunc(readResource(props.candidateTargetBtcAddress)!, {
                head: 8,
                tail: 8,
              })}
            </span>
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "The following address is provided by your Hiro/xVerse wallet, with the same memonic to your stacks address:",
                  description:
                    "/OrderBook/BRC20/PegOutBrc20ModalContent/Tip text",
                }),
              )}
            />
          </div>
          <RectButton
            className={"flex items-center space-x-2.5"}
            Variant={WhiteFilledButton}
            {...normal}
            onClick={props.onFillCandidateTargetBtcAddress}
          >
            <span className="text-xs">
              {$t(
                defineMessage({
                  defaultMessage: "Auto Fill",
                  description:
                    "/OrderBook/BRC20/PegOutBrc20ModalContent/Auto Fill text",
                }),
              )}
            </span>
          </RectButton>
        </div>
      )}
    </div>
  )
}
