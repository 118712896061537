import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  MaskErrorBoundary,
  defaultMaskErrorBoundaryFallbackFactory,
} from "../../../components/MaskErrorBoundary"
import { PageStackPage } from "../../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../../components/PageStack/PrimaryPageTitle"
import { useColors } from "../../../components/Themed/color"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { WiredTransactionStateDialogForGeneral } from "../../../components/TransactionStateDialog/WiredTransactionStateDialogForGeneral"
import {
  BulkTransferInscriptionPageStoreProvider,
  useBulkTransferInscriptionPageStore,
} from "./stores/useBulkTransferInscriptionPageStore"
import { WiredConnectedWalletsPanel } from "./wiredComponents/WiredConnectedWalletsPanel"
import { WiredTransferPanel } from "./wiredComponents/WiredTransferPanel"
import { WiredTransferablePanel } from "./wiredComponents/WiredTransferablePanel"

const BulkTransferInscriptionPageContent: FC<{
  className?: string
}> = props => {
  const intl = useIntl()
  const colors = useColors()

  const { $t } = intl

  const pegInStore = useBulkTransferInscriptionPageStore()

  return (
    <PageStackPage
      title={
        <PrimaryPageTitle>
          {$t(
            defineMessage({
              defaultMessage: "Send Inscriptions",
              description: "Admin Bulk Send Inscriptions Page/page title",
            }),
          )}
        </PrimaryPageTitle>
      }
    >
      <div className={clsx(props.className, "flex flex-col gap-y-8")}>
        <MaskErrorBoundary
          fallback={defaultMaskErrorBoundaryFallbackFactory({ intl, colors })}
        >
          {p => <WiredConnectedWalletsPanel containerRef={p.containerDomRef} />}
        </MaskErrorBoundary>

        <MaskErrorBoundary
          fallback={defaultMaskErrorBoundaryFallbackFactory({ intl, colors })}
        >
          {p => <WiredTransferPanel containerRef={p.containerDomRef} />}
        </MaskErrorBoundary>

        <MaskErrorBoundary
          fallback={defaultMaskErrorBoundaryFallbackFactory({ intl, colors })}
        >
          {p => <WiredTransferablePanel containerRef={p.containerDomRef} />}
        </MaskErrorBoundary>
      </div>

      <WiredTransactionStateDialogForGeneral
        store={pegInStore.generalTxStore}
        onClose={() => pegInStore.generalTxStore.closeRunning()}
      />
      <WiredTransactionStateDialog
        store={pegInStore.txStore}
        onClose={() => pegInStore.txStore.closeRunning()}
      />
    </PageStackPage>
  )
}

export const BulkTransferInscriptionPage: FC = () => {
  return (
    <BulkTransferInscriptionPageStoreProvider>
      <BulkTransferInscriptionPageContent />
    </BulkTransferInscriptionPageStoreProvider>
  )
}
