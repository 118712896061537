import clsx from "clsx"
import { FC } from "react"
import { ReactComponent as MenuIcon } from "./menu.svg"

export const MenuButton: FC<{
  className?: string
  isOpen?: boolean
}> = props => {
  return (
    <div
      className={clsx(
        "text-white rounded-full cursor-pointer",
        "hover:hover-hover:text-black hover:hover-hover:bg-white",
        props.isOpen && "text-black bg-white",
        props.className,
      )}
    >
      <MenuIcon className={"fill-current"} />
    </div>
  )
}
