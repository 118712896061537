import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardSubTitle } from "../../../../../components/Card"
import { TokenName } from "../../../../../components/TokenName"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LinkedButton } from "../../../../../components/button/LinkedButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { LaunchingStatus } from "../../../store/LaunchPadContractStore"
import { IDOFailedIcon } from "../../IDOFailedIcon/IDOFailedIcon"
import { IDOProgress } from "../../IDOProgress/IDOProgress"
import { TokenIDORaiseNumbers, TokenIDOStatus } from "../../types"
import { BottomActionsSection } from "./utilComponents/BottomActionsSection"
import { IDODetailSidePanelFrame } from "./utilComponents/IDODetailSidePanelFrame"

export interface IDODetailSidePanel$FailedProps {
  className?: string
  priceToken: TokenInfo
  idoNumbers: TokenIDORaiseNumbers
  idoStatus: TokenIDOStatus
  titleRightSide?: ReactNode
  dashboardLink?: null | string
  prepareAPowerLink?: null | string
}

export const IDODetailSidePanel$Failed: FC<
  IDODetailSidePanel$FailedProps
> = props => {
  const { $t } = useIntl()
  return (
    <IDODetailSidePanelFrame
      className={props.className}
      titleRightSide={props.titleRightSide}
    >
      <div className={"flex flex-col items-center gap-4"}>
        <IDOFailedIcon />
        <CardSubTitle>
          {$t(
            defineMessage({
              defaultMessage: "The project has failed to launch",
              description: "/Launchpad/IDODetailSidePanel/Failed subtitle",
            }),
          )}
        </CardSubTitle>
      </div>
      <IDOProgress
        priceToken={props.priceToken}
        idoNumbers={props.idoNumbers}
        isFinished={props.idoStatus.type === LaunchingStatus.Finished}
      />
      <BottomActionsSection>
        {props.dashboardLink !== null && (
          <LinkedButton
            Variant={GradientFilledButton}
            link={props.dashboardLink}
          >
            {$t(
              defineMessage({
                defaultMessage: "LaunchPad Dashboard",
                description: "/Launchpad/IDODetailSidePanel/Failed link button",
              }),
            )}
          </LinkedButton>
        )}
        {props.prepareAPowerLink !== null && (
          <LinkedButton
            className={"text-blue-600"}
            Variant={HeadlessButton}
            link={props.prepareAPowerLink}
          >
            {$t(
              defineMessage({
                defaultMessage: "Prepare {aPowerToken} to join more LaunchPad",
                description: "/Launchpad/IDODetailSidePanel/Failed link button",
              }),
              { aPowerToken: <TokenName token={TokenInfoPresets.APower} /> },
            )}
          </LinkedButton>
        )}
      </BottomActionsSection>
    </IDODetailSidePanelFrame>
  )
}
