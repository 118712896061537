import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../components/Card"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { DefaultTokenNameArea } from "../../../components/TokenInput/Block"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { LiquidityNumber } from "../../Farm/components/LiquidityNumber"
import { LiquidityInfo } from "../store/detail/PoolDetailStore.services"

const Container = withClassName(
  "border border-gray-700 rounded-lg p-4 flex flex-col gap-2",
  "div",
)
const Title = withClassName("text-sm text-gray-200 text-opacity-50", "p")
const Detail = withClassName("text-2xl font-bold break-all", "p")

const AMMPoolStatsInfo: FC<
  Omit<LiquidityInfo, "tokenXPercentage" | "tokenYPercentage"> & {
    className?: string
    tokenX: TokenInfo
    tokenXAmount: SuspenseResource<number>
    tokenXPercentage: SuspenseResource<number>
    tokenY: TokenInfo
    tokenYAmount: SuspenseResource<number>
    tokenYPercentage: SuspenseResource<number>
    totalXYInUSD: SuspenseResource<number>
    ammFees: SuspenseResource<number>
    factor: SuspenseResource<number>
    ammFeeRateRate: SuspenseResource<number>
  }
> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx("flex flex-col gap-5", props.className)}>
      <CardTitle>
        {$t(
          defineMessage({
            defaultMessage: "Pool Info",
            description: "/Pool/AMMPoolStatsInfo/Card title",
          }),
        )}
      </CardTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-1 sm:grid-rows-2 gap-3">
        <Container>
          <Title>
            {$t(
              defineMessage({
                defaultMessage: "Liquidity",
                description: "/Pool/AMMPoolStatsInfo/Card title",
              }),
            )}
          </Title>
          <Detail>
            $ <LiquidityNumber count={props.liquidity} />
          </Detail>
        </Container>
        <Container>
          <div className="flex flex-row items-center gap-2">
            <Title>
              {$t(
                defineMessage({
                  defaultMessage: "APR",
                  description: "/Pool/AMMPoolStatsInfo/Card title",
                }),
              )}
            </Title>
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "By adding liquidity you'll earn fees from all trades on this pair proportional to your share of the pool.\\nFees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.",
                  description: "/Pool/AMMPoolStatsInfo/Card title tip",
                }),
              )}
            />
          </div>
          <Detail>
            {props.apr == null ? (
              $t(
                defineMessage({
                  defaultMessage: "N/A",
                  description: "/Pool/AMMPoolStatsInfo/Card Detail",
                }),
              )
            ) : (
              <PercentNumber number={props.apr} />
            )}
          </Detail>
        </Container>
        <Container>
          <Title>
            {$t(
              defineMessage({
                defaultMessage: "Volume 24h",
                description: "/Pool/AMMPoolStatsInfo/Card title",
              }),
            )}
          </Title>
          <Detail>
            $ <LiquidityNumber count={props.volume24h} />
          </Detail>
        </Container>
        <Container>
          <Title>
            {$t(
              defineMessage({
                defaultMessage: "Volume 7d",
                description: "/Pool/AMMPoolStatsInfo/Card title",
              }),
            )}
          </Title>
          <Detail>
            $ <LiquidityNumber count={props.volume7d} />
          </Detail>
        </Container>
      </div>
      <Container>
        <Title>
          {$t(
            defineMessage({
              defaultMessage: "Pool Composition",
              description: "/Pool/AMMPoolStatsInfo/Card title",
            }),
          )}
        </Title>
        <div className="flex flex-row items-center">
          <DefaultTokenNameArea token={props.tokenX} className="mr-auto" />
          <span className="text-lg font-semibold">
            <Spensor fallback={"-"}>
              {() => (
                <TokenCount
                  token={props.tokenX}
                  count={readResource(props.tokenXAmount)}
                />
              )}
            </Spensor>
          </span>
          <span className="text-base text-[#E5E7EB] font-medium">
            (
            <Spensor fallback={"-"}>
              {() => (
                <PercentNumber number={readResource(props.tokenXPercentage)} />
              )}
            </Spensor>
            )
          </span>
        </div>
        <div className="flex flex-row items-center">
          <DefaultTokenNameArea token={props.tokenY} className="mr-auto" />
          <span className="text-lg font-semibold">
            <Spensor fallback={"-"}>
              {() => (
                <TokenCount
                  token={props.tokenY}
                  count={readResource(props.tokenYAmount)}
                />
              )}
            </Spensor>
          </span>
          <span className="text-base text-[#E5E7EB] font-medium">
            (
            <Spensor fallback={"-"}>
              {() => (
                <PercentNumber number={readResource(props.tokenYPercentage)} />
              )}
            </Spensor>
            )
          </span>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <p className="text-sm font-medium mr-auto">
            {$t(
              defineMessage({
                defaultMessage: "Total",
                description: "/Pool/AMMPoolStatsInfo/Card content",
              }),
            )}
          </p>
          <p className="text-lg font-medium text-sm">
            ${" "}
            <Spensor fallback="-">
              {() => (
                <LiquidityNumber count={readResource(props.totalXYInUSD)} />
              )}
            </Spensor>
          </p>
        </div>
      </Container>
      <div className="space-x-3 flex flex-row">
        <Container className="flex-1">
          <div className="flex flex-row items-center gap-2">
            <Title>
              {$t(
                defineMessage({
                  defaultMessage: "Fees",
                  description: "/Pool/AMMPoolStatsInfo/Card title",
                }),
              )}
            </Title>
          </div>
          <Detail>
            <Spensor fallback="--">
              {() => <PercentNumber number={readResource(props.ammFees)} />}
            </Spensor>
          </Detail>
          <ul className="text-gray-500 text-xs list-disc list-inside">
            <li>
              {$t(
                defineMessage({
                  defaultMessage: "{ammFeeRate} to ALEX platform",
                  description: "/Pool/AMMPoolStatsInfo/Card content",
                }),
                {
                  ammFeeRate: (
                    <Spensor fallback="-">
                      {() => (
                        <PercentNumber
                          number={1 - readResource(props.ammFeeRateRate)}
                        />
                      )}
                    </Spensor>
                  ),
                },
              )}
            </li>
            <li>
              {$t(
                defineMessage({
                  defaultMessage:
                    "{ammFeeRate} rebated to LP pool automatically",
                  description: "/Pool/AMMPoolStatsInfo/Card content",
                }),
                {
                  ammFeeRate: (
                    <Spensor fallback="-">
                      {() => (
                        <PercentNumber
                          number={readResource(props.ammFeeRateRate)}
                        />
                      )}
                    </Spensor>
                  ),
                },
              )}
            </li>
          </ul>
        </Container>
        <Container className="flex-1">
          <div className="flex flex-row items-center gap-2">
            <Title>
              {$t(
                defineMessage({
                  defaultMessage: "Factor",
                  description: "/Pool/AMMPoolStatsInfo/Card title",
                }),
              )}
            </Title>
          </div>
          <Detail>
            <Spensor>{() => readResource(props.factor)}</Spensor>
          </Detail>
          <ul className="text-gray-500 text-xs list-disc list-inside">
            <li>
              {$t(
                defineMessage({
                  defaultMessage:
                    "The smaller the factor (closer to zero), the more correlated the pair (i.e. stableswap).",
                  description: "/Pool/AMMPoolStatsInfo/Card content",
                }),
              )}
            </li>
            <li>
              {$t(
                defineMessage({
                  defaultMessage:
                    "The bigger the factor (closer to one), the less correlated the pair.",
                  description: "/Pool/AMMPoolStatsInfo/Card content",
                }),
              )}
            </li>
          </ul>
        </Container>
      </div>
    </Card>
  )
}

export default AMMPoolStatsInfo
