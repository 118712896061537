import { CSSProperties, ReactNode } from "react"
import { FCC } from "../utils/reactHelpers/types"

export const GoldenBox: FCC<{
  className?: string
  style?: CSSProperties
  children?: ReactNode
  renderContainer?: (props: {
    className?: string
    style?: CSSProperties
    children?: ReactNode
  }) => ReactNode
}> = props => {
  const { renderContainer = props => <div {...props} /> } = props

  return (
    <>
      {renderContainer({
        className: props.className,
        style: {
          background:
            "linear-gradient(0deg, #DBC26A, #DBC26A), radial-gradient(85.31% 57.53% at 125.94% 4.69%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(133.81deg, #755930 0%, #554328 17.36%, #52422B 77.08%, #775D37 98.01%)",
          backgroundBlendMode: "overlay, overlay, normal",
          backgroundOrigin: "border-box",
          ...props.style,
        },
        children: props.children,
      })}
    </>
  )
}
