import { FC } from "react"
import { useIntl } from "react-intl"
import { OrderBookDepositRowData } from "../../../../store/transformerGroups/orderbook/registerAndTransferIn"
import { AllTokenInfos } from "../../../../types"
import { BaseRow } from "../BaseRow"

export const OrderbookDepositRow: FC<{
  rowData: OrderBookDepositRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => {
  const { $t } = useIntl()
  return <BaseRow rowData={rowData} />
}
