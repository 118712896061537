import clsx from "clsx"
import React, {
  ComponentType,
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useRef,
} from "react"
import { RangerTick, SegmentProps, TrackProps, useRanger } from "react-ranger"
import styles from "./Slider.module.scss"
import { Tooltip } from "./Tooltip/Tooltip"

export type SliderTooltipContentProps = { value: number }

export interface SliderProps {
  className?: string

  value: number

  TooltipContent?: ComponentType<SliderTooltipContentProps>

  onChange?: (newValue: number) => void

  /**
   * @default 0
   */
  min?: number

  max: number

  /**
   * @default 1
   */
  stepSize?: number

  disabled?: boolean

  insetPadding?: boolean

  ticks?: number[]
}

export const Slider: FC<SliderProps> = props => {
  const { onChange } = props
  const min = props.min ?? 0
  const max = props.max ?? 1
  const stepSize = props.stepSize ?? 1
  const { getTrackProps, segments, handles, ticks } = useRanger({
    min,
    max,
    stepSize,
    ticks: props.ticks,
    values: [props.value],
    onDrag: values => {
      if (props.disabled) return
      onChange?.(values[0]!)
    },
    onChange: opts => {
      if (props.disabled) return
      onChange?.(opts[0]!)
    },
  })

  const trackRef = useRef<HTMLDivElement>(null)
  const handleTrackOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()

      if (!onChange || !trackRef.current) {
        return
      }

      const clickPosition =
        e.clientX - trackRef.current.getBoundingClientRect().left
      const trackWidth = trackRef.current.getBoundingClientRect().width
      const x =
        Math.round(((max - min) * clickPosition) / trackWidth / stepSize) *
          stepSize +
        min
      onChange?.(Math.max(min, Math.min(max, x)))
    },
    [max, min, onChange, stepSize],
  )

  // like: { min: 1, max: 1 }
  const isAllSegmentsSameValue = segments
    .map(s => s.value)
    .every((v, i, a) => v === a[0])

  return (
    <div
      className={props.className}
      style={{
        paddingInline: props.insetPadding ? sliderHandleSize / 2 : 0,
      }}
    >
      <Track
        {...getTrackProps({
          className: clsx(
            props.disabled
              ? "opacity-30 pointer-events-none"
              : "cursor-pointer",
          ),
          onClick: handleTrackOnClick,
          ref: trackRef,
        })}
      >
        {isAllSegmentsSameValue ? (
          <Segment
            key={"defaultSegment"}
            style={{ position: "absolute", left: 0, width: "100%" }}
            isHead={true}
            isTail={true}
            isAchieved={true}
          />
        ) : (
          segments.map(({ getSegmentProps }, i) => (
            // eslint-disable-next-line react/jsx-key
            <Segment
              {...getSegmentProps()}
              isHead={i === 0}
              isTail={i === segments.length - 1}
              isAchieved={i === 0}
            />
          ))
        )}

        {props.ticks != null &&
          ticks.map((tick, idx) => (
            <Tick
              key={idx}
              isArrived={props.value >= tick.value}
              tick={tick}
              onClick={() => {
                props.onChange?.(tick.value)
              }}
            />
          ))}

        {handles.map(({ value, active, getHandleProps }) => {
          const handleProps = { ...getHandleProps({}) }

          return (
            // eslint-disable-next-line react/jsx-key
            <button
              {...handleProps}
              style={{
                ...handleProps.style,
                left: isAllSegmentsSameValue ? "100%" : handleProps.style.left,
                appearance: "none",
                border: "none",
                background: "transparent",
                outline: "none",
              }}
            >
              <Handle
                TooltipContent={props.TooltipContent}
                active={active}
                value={value}
              />
            </button>
          )
        })}
      </Track>
    </div>
  )
}

const Tick = forwardRef<
  HTMLDivElement,
  { isArrived?: boolean; tick: RangerTick; onClick: () => void }
>((props, ref) => (
  <div
    {...props.tick.getTickProps({
      ref: ref,
      className: clsx(
        "bg-gray-50 rounded-full cursor-pointer",
        props.isArrived
          ? styles.tickGradientBorder
          : "border-4 border-black/30",
      ),
      style: {
        width: 12,
        height: 12,
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    })}
    onClick={props.onClick}
  />
))

const Track = forwardRef<
  HTMLDivElement,
  TrackProps & { className?: string; children?: ReactNode }
>((props, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx("w-full h-1 rounded-full", props.className)}
  >
    {props.children}
  </div>
))

const Segment = forwardRef<
  HTMLDivElement,
  SegmentProps & {
    isHead: boolean
    isTail: boolean
    isAchieved: boolean
    children?: ReactNode
  }
>(({ isHead, isTail, isAchieved, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx(
      "h-full",
      isHead && "rounded-l-full",
      isTail && "rounded-r-full",
      isAchieved
        ? "bg-gradient-to-r from-blue-700 to-purple-600"
        : "bg-gray-300",
    )}
  />
))

const handleInsetSize = 16
const handleBorderSize = 4
export const sliderHandleSize = handleInsetSize + handleBorderSize * 2
const DefaultHandleTooltipContent: FC<SliderTooltipContentProps> = props => (
  <>{String(props.value)}</>
)
const Handle: FC<{
  TooltipContent?: ComponentType<SliderTooltipContentProps>
  active: boolean
  value: number
}> = props => {
  const { TooltipContent = DefaultHandleTooltipContent } = props

  const content = (
    <div
      className={clsx(
        styles.handleGradientBorder,
        "rounded-full",
        "bg-gray-50 hover:bg-gray-300 active:bg-gray-400",
        "text-gray-500 hover:text-gray-500 active:text-gray-500",
      )}
      style={{
        padding: handleBorderSize,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))",
      }}
    >
      <svg
        width={handleInsetSize}
        height={handleInsetSize}
        viewBox={`0 0 ${handleInsetSize} ${handleInsetSize}`}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 5L13 11L11 11L11 5L13 5ZM9 5L9 11L7 11L7 5L9 5ZM5 11L5 5L3 5L3 11L5 11Z"
        />
      </svg>
    </div>
  )

  return (
    <Tooltip
      title={<TooltipContent value={props.value} />}
      visible={props.active}
    >
      {content}
    </Tooltip>
  )
}
