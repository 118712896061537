import clsx from "clsx"
import { FC } from "react"
import { TokenName } from "../../../../components/TokenName"
import { Currency } from "../../../../utils/alexjs/Currency"
import { AllTokenInfos } from "../../types"

export interface OneLineTokenNameProps {
  prefix?: string
  suffix?: string
  className?: string
  currency: Currency
  allTokenInfos: AllTokenInfos
}

export const OneLineTokenName: FC<OneLineTokenNameProps> = props => {
  if (!props.allTokenInfos[props.currency]) {
    throw new Error("Token info not found for currency: " + props.currency)
  }
  const prefix = props.prefix ? props.prefix + " " : ""
  const suffix = props.suffix ? " " + props.suffix : ""
  return (
    <span className="whitespace-nowrap">
      {prefix}
      <TokenName
        className={clsx(props.className, "whitespace-nowrap")}
        token={props.allTokenInfos[props.currency]!}
      />
      {suffix}
    </span>
  )
}
