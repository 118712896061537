import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../../utils/createStore"
import { FCC } from "../../../../utils/reactHelpers/types"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import { StxDxStore } from "./StxDxStore"

const Store = createStore<StxDxStore>("StxDxStore")

export const StxDxStoreProvider: FCC = props => {
  const chain = useChainStore()
  const appEnv = useAppEnvStore()
  const auth = useAuthStore()
  const currency = useCurrencyStore()
  const account = useAccountStore()
  const store = useCreation(
    () => new StxDxStore(appEnv, chain, auth, currency, account),
    [appEnv, account, auth, chain, currency],
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useStxDxStore = Store.useStore.bind(null)
