import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../../components/button/Button"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  CardInset,
  CardInsetTitle,
  CardNote,
  CardSubTitle,
} from "../../../../../components/Card"
import { TokenName } from "../../../../../components/TokenName"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { OneOrMore } from "../../../../../utils/types"
import {
  PageClockCountdown,
  PageClockCountdownTitleFrame,
} from "../../PageClockCountdown"
import { LotteryTicket } from "../../types"
import { LotteryTicketCardGrid } from "../../_/LotteryTicketCardGrid"
import { PrizeWinningInfo } from "./types"
import { LotteryFinishedIcon } from "./_/LotteryFinishedIcon/LotteryFinishedIcon"
import { RoundResult } from "./_/RoundResult"

export const ActionPanel$Finished: FC<{
  roundNumber: number
  nextRoundStartedAt: Date
  nextRoundStartedAtBlock: number
  currentBlock: number
  prizeToken: TokenInfo
  firstPrize: PrizeWinningInfo
  secondPrize: PrizeWinningInfo
  thirdPrize: PrizeWinningInfo
  burnPrizeTokenCount: number
  rolloverPrizeTokenCount: number
  bottomArea: ReactNode
}> = props => {
  const { $t } = useIntl()

  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <LotteryFinishedIcon />
        <CardInsetTitle>
          {$t(
            defineMessage({
              defaultMessage: "Round #{roundNumber} has been finalized.",
              description: "/Lottery/ActionPanel$Finished/Card title",
            }),
            {
              roundNumber: props.roundNumber,
            },
          )}
        </CardInsetTitle>
      </div>
      <PageClockCountdownTitleFrame
        title={
          <CardNote>
            {$t(
              defineMessage({
                defaultMessage: "Next round will start in",
                description: "/Lottery/ActionPanel$Finished/Card note",
              }),
            )}
          </CardNote>
        }
      >
        <PageClockCountdown
          targetBlock={props.nextRoundStartedAtBlock}
          currentBlock={props.currentBlock}
          time={props.nextRoundStartedAt}
        />
      </PageClockCountdownTitleFrame>

      <CardInset className={clsx("w-full overflow-x-auto")}>
        <div className={"flex flex-col gap-4"}>
          <RoundResult
            roundNumber={props.roundNumber}
            token={props.prizeToken}
            firstPrize={props.firstPrize}
            secondPrize={props.secondPrize}
            thirdPrize={props.thirdPrize}
            burnPrizeTokenCount={props.burnPrizeTokenCount}
            rolloverPrizeTokenCount={props.rolloverPrizeTokenCount}
          />
        </div>
      </CardInset>
      {props.bottomArea}
    </CardInset>
  )
}

export const ConnectWalletBottomArea: FC<{
  onConnectWallet: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <Button
      className={"w-full"}
      Variant={GradientFilledButton}
      onClick={props.onConnectWallet}
    >
      {$t(
        defineMessage({
          defaultMessage: "Connect wallet to view My Lottery Ticket",
          description: "/Lottery/ConnectWalletBottomArea/Button text",
        }),
      )}
    </Button>
  )
}

export const LotteryTicketsBottomArea: FC<{
  prizeToken: TokenInfo
  lotteryTickets: OneOrMore<LotteryTicket>
}> = props => {
  const { $t } = useIntl()

  return (
    <>
      <div className={"flex flex-col gap-2.5 text-center"}>
        <CardSubTitle>
          {$t(
            defineMessage({
              defaultMessage: "My Lottery Ticket",
              description: "/Lottery/TicketsBottomArea/Card sub title",
            }),
          )}
        </CardSubTitle>
        <CardNote>
          {$t<ReactNode>(
            defineMessage({
              defaultMessage: `<line>The prize has been drawn!</line>
              <line>The {prizeTokenName} tokens will be distributed in sequence.</line>
              <line>One ticket can win multiple prizes.</line>,
              `,
              description: "/Lottery/TicketsBottomArea/Card note",
            }),
            {
              prizeTokenName: <TokenName token={props.prizeToken} />,
              line: dontWrapObserver(children => <p>{children}</p>),
            },
          )}
        </CardNote>
      </div>
      <LotteryTicketCardGrid
        prizeToken={props.prizeToken}
        lotteryTickets={props.lotteryTickets}
      />
    </>
  )
}
