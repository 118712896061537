import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { APowerTip } from "../../../../components/APowerTip/APowerTip"
import { GrayBadge, GreenBadge } from "../../../../components/Badge"
import { NavLink } from "../../../../components/NavLink"
import { PercentNumber } from "../../../../components/PercentNumber"
import { TokenIcon } from "../../../../components/TokenIcon"
import { usePathGenerator } from "../../../../routes/routes"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"
import { CycleStatusBadge } from "../../../Stake/components/CycleStatusBadge"
import { StakeCycleStatus } from "../../../Stake/types"
import { LiquidityNumber } from "../LiquidityNumber"
import { ListButton } from "../ListButton"
import { ListItemContainer, ListSection } from "../ListContainer"
import { ReactComponent as IntoIcon } from "../MyFarmsPanel/into.svg"
import PoolTokenName from "../PoolTokenName"
import { OtherFarm, OtherFarmItem } from "./AllFarmsPanel"

export const OtherFarmListRowHeader: FC = () => {
  const { $t } = useIntl()
  return (
    <ListItemContainer
      className={
        "p-4 border-b border-gray-500/30 text-sm leading-5 font-normal text-gray-500"
      }
    >
      <HeaderListSection type={"head"}>
        {$t(
          defineMessage({
            defaultMessage: "Trading Pair",
            description: "/Farm/Other farm list row header",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Liquidity",
            description: "/Farm/Other farm list row header",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Farm APR",
            description: "/Farm/Other farm list row header",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Fee Rebate",
            description: "/Farm/Other farm list row header",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Reward",
            description: "/Farm/Other farm list row header",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection />
      <HeaderListSection type={"tail"} />
    </ListItemContainer>
  )
}

const HeaderListSection = withClassName(
  "flex flex-row items-center gap-2.5",
  ListSection,
)

export const OtherFarmListRow: FC<{
  className?: string
  farm: OtherFarm
  onClick?: () => void
}> = props => {
  const gen = usePathGenerator()
  const farm = props.farm.mainPool
  if (props.farm.legacyPools.length === 0) {
    return (
      <NavLink to={gen.farmDetail(farm.liquidityPoolToken)}>
        <ListItemContainer
          className={clsx(
            "group p-4 border-b border-gray-500/30 text-gray-200 hover:bg-black/10 active:bg-black/20",
            props.className,
          )}
        >
          <ListSection
            type={"head"}
            className={"flex flex-row items-center gap-2.5"}
          >
            <TokenIcon token={farm.liquidityPoolToken} />

            <PoolTokenName
              className={"text-sm leading-5 font-medium truncate"}
              token={farm.liquidityPoolToken}
            />

            {farm.cycleStatus === StakeCycleStatus.Finished && (
              <CycleStatusBadge status={farm.cycleStatus} />
            )}

            {farm.isDualYield && <GreenBadge>Dual Yield</GreenBadge>}
          </ListSection>

          <InfoSection farm={farm} />
        </ListItemContainer>
      </NavLink>
    )
  }
  return (
    <div className="flex px-4 py-2 border-b border-gray-500/30 gap-x-2">
      <div className={"flex flex-row items-center gap-2.5 w-[180px]"}>
        <TokenIcon token={farm.liquidityPoolToken} />

        <PoolTokenName
          className={"text-sm leading-5 font-medium truncate"}
          token={farm.liquidityPoolToken}
        />

        {farm.cycleStatus === StakeCycleStatus.Finished && (
          <CycleStatusBadge status={farm.cycleStatus} />
        )}
      </div>
      <div className="flex-1">
        {[props.farm.mainPool, ...props.farm.legacyPools].map((p, index) => (
          <NavLink key={index} to={gen.farmDetail(p.liquidityPoolToken)}>
            <ListItemContainer
              className={clsx(
                "group py-3 pl-2 hover:bg-black/10 active:bg-black/20 w-full flex-row border-l border-gray-500/30",
                index === 0 ? "text-gray-200" : "text-gray-500",
              )}
            >
              <div className="w-[60px]">
                {index === 0 ? (
                  <GreenBadge>Main</GreenBadge>
                ) : (
                  <GrayBadge>Migrating</GrayBadge>
                )}
              </div>
              <InfoSection farm={p} />
            </ListItemContainer>
          </NavLink>
        ))}
      </div>
    </div>
  )
}

const InfoSection: FC<{ farm: OtherFarmItem }> = ({ farm }) => {
  const { $t } = useIntl()
  return (
    <>
      <ListSection className={"uppercase"}>
        $&nbsp;
        {farm.liquidityCount != null ? (
          <LiquidityNumber count={farm.liquidityCount} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        {farm.totalApr != null ? (
          <PercentNumber number={farm.totalApr} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        {farm.tradingFee != null ? (
          <PercentNumber number={farm.tradingFee} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        <ul>
          {farm.rewardTokens.map((token, idx) => (
            <li key={idx} className={"flex items-center gap-1.5 justify-end"}>
              <TokenIcon token={token} size={16} />
              <PoolTokenName token={token} />
              {TokenInfoPresets.isAPower(token) ? <APowerTip /> : null}
            </li>
          ))}
        </ul>
      </ListSection>

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="#111827"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.5 5.25H6.75V1.5H5.25V5.25H1.5V6.75H5.25V10.5H6.75V6.75H10.5V5.25Z" />
          </svg>
          {$t(
            defineMessage({
              defaultMessage: "Stake LP",
              description: "/Farm/Other farm list row",
            }),
          )}
        </ListButton>
      </ListSection>

      <ListSection type={"tail"} className={"flex items-center justify-end"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </>
  )
}
