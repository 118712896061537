import { FC, SVGProps } from "react"

export const ReturnIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.41939 4.98321L9.32254 4.8658C8.90682 4.42085 8.24679 4.37643 7.78227 4.76213L7.67259 4.8658L1.84174 11.1085C1.42606 11.5535 1.38456 12.26 1.74489 12.7572L1.84174 12.8734L7.67142 19.1236C8.10534 19.5993 8.81132 19.6277 9.27745 19.1884C9.74358 18.749 9.80649 17.9958 9.42056 17.4748L9.32371 17.3574L5.48587 13.2431H21.3331C21.9247 13.243 22.4226 12.7691 22.4918 12.1402L22.5 11.9941C22.5 11.3608 22.0572 10.8279 21.4697 10.7538L21.3331 10.745H5.48237L9.32254 6.63194C9.73822 6.18695 9.77972 5.48044 9.41939 4.98321L9.32254 4.8658L9.41939 4.98321Z" />
    </svg>
  )
}
