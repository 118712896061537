import { ComponentType } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { ButtonProps } from "./Button"
import { ButtonVariantProps } from "./ButtonVariant"
import { WhiteFilledButton } from "./variants/WhiteFilledButton"

export const RoundedButton: FCC<
  ButtonProps & {
    Variant?: ComponentType<ButtonVariantProps & { roundedClassName?: string }>
    roundedClassName?: string
  }
> = props => {
  const { Variant = WhiteFilledButton, ...restProps } = props
  return (
    <Variant
      roundedClassName={props.roundedClassName ?? "rounded-lg"}
      {...restProps}
    >
      {props.children}
    </Variant>
  )
}
