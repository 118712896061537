import { ReturnTypeOfDescriptor, UnboxResponse } from "clarity-codegen"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  asSender,
  Contracts,
} from "../../../generated/smartContractHelpers/asSender"

export async function getCurrentLotteryId(): Promise<number> {
  const nonce = await asSender(CONTRACT_DEPLOYER)
    .contract("alex-lottery")
    .func("get-lottery-id-nonce")
    .call({})
  return nonce - 1
}

export const LOTTERY_WALK_RESOLUTION = 1e5

export type WalkParameters = UnboxResponse<
  ReturnTypeOfDescriptor<
    Contracts["alex-lottery"]["get-lottery-walk-parameters"]
  >
>

export function getAllWonTicketIds(
  result: WalkParameters,
  walkResolution: number = LOTTERY_WALK_RESOLUTION,
): number[] {
  let walkPosition = result["walk-position"]
  const maxStepSize = result["max-step-size"]
  const ticketsForSale = result["total-tickets"]
  const lcg = new LCG(lotteryLcgA, lotteryLcgC, lotteryLcgM)
  const wonTickets: number[] = []
  for (let i = 0; i < ticketsForSale; i++) {
    wonTickets.push(Math.floor(walkPosition / walkResolution))
    walkPosition =
      (Math.floor(walkPosition / walkResolution) + 1) * walkResolution +
      lcg.next(walkPosition, maxStepSize)
  }
  return wonTickets
}

const lotteryLcgA = 134775813
const lotteryLcgC = 1
const lotteryLcgM = 4294967296

class LCG {
  constructor(readonly a: number, readonly c: number, readonly m: number) {}

  next(current: number, maxStep = 0): number {
    // to avoid overflow, cast numbers to bigint first.
    const next =
      (BigInt(current) * BigInt(this.a) + BigInt(this.c)) % BigInt(this.m)
    return maxStep > 1 ? Number(next % BigInt(maxStep)) : Number(next)
  }
}
