import { MutableRefObject, useRef } from "react"

/**
 * like `useState(() => ...)`, but for Ref
 */
export function useLazyRef<T>(factory: () => T): MutableRefObject<T> {
  const ref = useRef<T>()
  const isAssigned = useRef(false)

  if (!isAssigned.current) {
    isAssigned.current = true
    ref.current = factory()
  }

  return ref as any
}
