import * as RadixPortal from "@radix-ui/react-portal"
import {
  createContext,
  CSSProperties,
  ReactElement,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { useResponsiveValue } from "../Themed/breakpoints"
import { MessageItem } from "./MessageItem"

export interface MessageController {
  show(
    options:
      | MessageController.ShowOptions
      | MessageController.ShowOptionsFactory,
  ): () => void
}

export namespace MessageController {
  export interface ShowOptionsFactoryInfo {
    close: () => void
  }

  export type ShowOptionsFactory = (info: ShowOptionsFactoryInfo) => ShowOptions

  export interface ShowOptions {
    /**
     * Message will be replaced by the new message which has the same key
     */
    key?: string

    message: string | ReactElement

    /**
     * Auto dismiss after how many ms
     *
     * @default 5000 (5s)
     */
    autoDismiss?: null | number
  }
}

const MessageContext = createContext<null | MessageController>(null)

type ShowOptions = MessageController.ShowOptions
type ShowOptionsFactory = MessageController.ShowOptionsFactory

interface MessageInstance extends MessageController.ShowOptions {
  id: string
}

export interface MessageProviderProps {
  messagesContainerStyles?: CSSProperties

  messageControllerRef?: Ref<MessageController>
}

export const MessageProvider: FCC<MessageProviderProps> = props => {
  const [messages, setMessages] = useState<MessageInstance[]>([])

  // ensure messages portal always be rendered in the bottom of the DOM tree
  const [portalKey, setPortalKey] = useState(Date.now())

  const closedMessageIdsRef = useRef<string[]>([])

  const autoDismissTimersRef = useRef<ReturnType<typeof setTimeout>[]>([])

  const messagesContainerLayoutRight = useResponsiveValue({
    sm: 33,
  })

  const closeMsg = usePersistFn((msgId: string) => {
    closedMessageIdsRef.current.push(msgId)
    setMessages(msgs => msgs.filter(m => m.id !== msgId))
  })

  const addMsg = usePersistFn((_opts: ShowOptions | ShowOptionsFactory) => {
    const id = getGuid()

    const closeThisMsg = (): void => {
      closeMsg(id)
    }

    const factoryInfo: MessageController.ShowOptionsFactoryInfo = {
      close: closeThisMsg,
    }

    const opts = typeof _opts === "function" ? _opts(factoryInfo) : _opts

    const { autoDismiss = 1000 * 5 } = opts

    setMessages(messages => {
      if (opts.key != null) {
        messages = messages.filter(m => m.key !== opts.key)
      }

      return messages.concat({ id, ...opts })
    })
    setPortalKey(Date.now())

    if (autoDismiss != null) {
      autoDismissTimersRef.current.push(
        setTimeout(() => {
          closeMsg(id)
        }, autoDismiss),
      )
    }

    return closeThisMsg
  })

  const ctrl: MessageController = useMemo(
    () => ({
      show(opts) {
        return addMsg(opts)
      },
    }),
    [addMsg],
  )

  useEffect(
    () => () => {
      autoDismissTimersRef.current.forEach(timer => {
        clearTimeout(timer)
      })
    },
    [],
  )

  useImperativeHandle(props.messageControllerRef, () => ctrl)

  return (
    <>
      <MessageContext.Provider value={ctrl}>
        {props.children}
      </MessageContext.Provider>
      <RadixPortal.Root
        key={portalKey}
        className={
          "flex flex-col gap-2.5 transform translate-x-1/2 sm:translate-x-0 z-50"
        }
        style={{
          ...props.messagesContainerStyles,
          right: messagesContainerLayoutRight ?? "50%",
        }}
      >
        {messages
          .slice()
          .reverse()
          .slice(0, 5)
          .map(m => (
            <div key={m.id} data-id={m.id}>
              {typeof m.message === "string" ? (
                <MessageItem title={m.message} onClose={() => closeMsg(m.id)} />
              ) : (
                m.message
              )}
            </div>
          ))}
      </RadixPortal.Root>
    </>
  )
}

export interface MessageControllerFromHook extends MessageController {}

export const useMessage = (): MessageControllerFromHook => {
  const ctrl = useContext(MessageContext)
  if (!ctrl) {
    throw new Error("[useMessage] must be used in MessageProvider subtree")
  }

  const closeFnsRef = useRef(new Set<() => void>())

  const wrappedCtrl: MessageControllerFromHook = useMemo(
    () => ({
      ...ctrl,
      show(opts) {
        const close = ctrl.show(opts)
        closeFnsRef.current.add(close)
        return () => {
          closeFnsRef.current.delete(close)
          close()
        }
      },
    }),
    [ctrl],
  )

  useEffect(
    () => () => {
      closeFnsRef.current.forEach(fn => fn())
      closeFnsRef.current.clear()
    },
    [],
  )

  return wrappedCtrl
}

const getGuid = (() => {
  let id = 0
  return (): string => {
    return "" + id++
  }
})()
