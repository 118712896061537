import type { MotionProps } from "framer-motion"

// storybook will crash if the type of `showhideAnimationProps` is `MotionProps`
export const collapseAnimationProps: any = {
  initial: { opacity: 0, height: 0 },
  animate: {
    opacity: 1,
    height: "auto",
    transition: { duration: 0.1, ease: "easeIn" },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.1, ease: "easeOut" },
  },
} as MotionProps

// storybook will crash if the type of `showhideAnimationProps` is `MotionProps`
export const showhideAnimationProps: any = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
} as MotionProps
