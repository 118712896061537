import React, { FC } from "react"
import { SlippageStore } from "../../../stores/slippageStore/SlippageStore"
import { HeadlessButton } from "../../button/HeadlessButton"
import { Modal } from "../../Modal"
import { SlippageModalContent } from "../SlippageModalContent"
import { ReactComponent as SettingIcon } from "./settingIcon.svg"

export const SlippageSettingsButton: FC<{
  onClick?: () => void
  className?: string
}> = ({ onClick, className }) => {
  return (
    <HeadlessButton className={className} onClick={onClick}>
      <SettingIcon className="text-gray-200 opacity-40 hover:opacity-100 active:opacity-40" />
    </HeadlessButton>
  )
}

export const WiredSlippageModal: FC<{
  modalDebugName?: string
  slippagePercent: SlippageStore
}> = ({ modalDebugName, slippagePercent }) => (
  <Modal
    modalDebugName={modalDebugName}
    visible={slippagePercent.showSlippagePop}
    onClose={() => slippagePercent.closeClicked()}
  >
    <SlippageModalContent
      isDefault={slippagePercent.isDefaultInput}
      isInputValid={slippagePercent.isInputValid}
      isMinuteValid={true}
      defaultValue={slippagePercent.defaultPercent}
      onResetToDefault={() => slippagePercent.setInputToDefault()}
      inputValue={slippagePercent.inputValue}
      onClose={() => slippagePercent.closeClicked()}
      onConfirmed={() => slippagePercent.save()}
      onInputValueChanged={newVal => slippagePercent.setInputValue(newVal)}
    />
  </Modal>
)
