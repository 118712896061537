import clsx from "clsx"
import { FC, ReactNode } from "react"
import { ReactComponent as RightArrowIcon } from "./rightArrow.svg"
import { ReactComponent as TopRightArrow } from "./rightTopArrow.svg"

export const SelectedListItem: FC<{
  className?: string
  title: string
  description: string
  icon: ReactNode
  disabled?: boolean
  arrowDirection: "right" | "topRight"
  onClick?: () => void
}> = props => {
  return (
    <div
      className={clsx(
        "bg-gray-600 flex items-center justify-between py-2.5 px-4 rounded cursor-pointer hover:bg-gray-700 active:bg-gray-800",
        props.disabled && "opacity-30 pointer-events-none",
        props.className,
      )}
      onClick={props.onClick}
    >
      <div className="flex items-center space-x-2.5">
        {props.icon}
        <div className="text-white space-y-1">
          <p className="text-base font-bold">{props.title}</p>
          <p className="text-xs">{props.description}</p>
        </div>
      </div>
      {props.arrowDirection === "right" ? (
        <RightArrowIcon />
      ) : (
        <TopRightArrow />
      )}
    </div>
  )
}
