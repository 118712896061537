import { FungibleConditionCode } from "@stacks/transactions"
import { gql } from "@urql/core"
import { ClarityError, unwrapResponse } from "clarity-codegen"
import { subDays } from "date-fns"
import { reverse } from "lodash"
import { Observable } from "rxjs"
import { CONTRACT_DEPLOYER, IS_MAIN_NET } from "../../../config"
import {
  CrpPoolDetailQuery,
  CrpPoolDetailQueryVariables,
  FetchBorrowChartDataQuery,
  FetchBorrowChartDataQueryVariables,
  FetchCrpHourlyStateTotalRecordQuery,
  FetchCrpHourlyStateTotalRecordQueryVariables,
  FetchCrpHourlyStatRecordsQuery,
  FetchCrpHourlyStatRecordsQueryVariables,
  FetchDepositChartDataQuery,
  FetchDepositChartDataQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import {
  asSender,
  contractAddr,
} from "../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../utils/alexjs/Currency"
import {
  CollateralToken,
  currencyScale,
  YieldToken,
  yieldTokenForYTP,
  ytpBreakDown,
  YTPToken,
} from "../../../utils/alexjs/currencyHelpers"
import { errorCodesFromName } from "../../../utils/alexjs/errorCode"
import { AlexVault, transfer } from "../../../utils/alexjs/postConditions"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { Result } from "../../../utils/Result"
import { currentExpiry } from "../../Pool/store/detail/PoolDetailStore.services"
import type { BorrowChartPanelPropsChartData } from "../components/borrowPage/BorrowChartPanel/BorrowChartPanel"
import type { DepositChartPanelPropsChartData } from "../components/depositPage/DepositChartPanel/DepositChartPanel"

export type YieldTokenPool = YTPToken

export type TokenWithId =
  | Currency.YIELD_ALEX
  | Currency.KEY_ALEX_AUTOALEX
  | Currency.YTP_ALEX

export type TokenWithIdBalance = { expiry: number; amount: number }[]

export async function fetchYieldTokenDetails(
  stxAddress: string,
  token: TokenWithId,
): Promise<TokenWithIdBalance> {
  const result = await asSender(CONTRACT_DEPLOYER)
    .contract(token)
    .func("get-token-balance-owned-in-fixed")
    .call({
      owner: stxAddress,
    })
  return result
    .map(a => ({ expiry: a["token-id"], amount: a.balance / 1e8 }))
    .filter(a => a.amount > 0)
}

export function getExpiry(poolToken: YTPToken): Promise<number> {
  return asSender(CONTRACT_DEPLOYER)
    .contract("collateral-rebalancing-pool-v1")
    .func("get-expiry")
    .call({ "pool-token": poolToken })
    .then(unwrapResponse)
}

export type AddDepositFormData = {
  poolToken: YTPToken
  stxAddress: string
  slippage: number
  expiry: number
  amount: number
  yieldTokenPerUnderlyingToken: number
}

export async function addDeposit(
  data: AddDepositFormData,
): Promise<{ txId: string }> {
  const minPrice =
    (data.yieldTokenPerUnderlyingToken - 1) * (1 - data.slippage) + 1
  const { underlying, yieldToken } = ytpBreakDown(data.poolToken)
  return await asSender(data.stxAddress)
    .contract("yield-token-pool")
    .func("swap-x-for-y")
    .call(
      {
        // "min-dy": undefined,
        "min-dy": minPrice * data.amount * 1e8,
        dx: data.amount * 1e8,
        expiry: data.expiry,
        "token-trait": underlying,
        "yield-token-trait": yieldToken,
      },
      [
        transfer(data.stxAddress, underlying, data.amount),
        transfer(
          AlexVault,
          yieldToken,
          minPrice * data.amount,
          FungibleConditionCode.GreaterEqual,
        ),
      ],
    )
}

export type AddBorrowForm = {
  ytpToken: YTPToken
  stxAddress: string
  expiry: number
  collateralAmount: number
  yieldTokenAmount: number
  uTokenPerYieldToken: number
  slippage: number
}

export async function addBorrow(
  form: AddBorrowForm,
): Promise<{ txId: string }> {
  const { underlying, yieldToken, keyToken, collateral } = ytpBreakDown(
    form.ytpToken,
  )
  const minDy =
    form.yieldTokenAmount /
    (1 + (1 / form.uTokenPerYieldToken - 1) * (1 + form.slippage))
  return await asSender(form.stxAddress)
    .contract("collateral-rebalancing-pool-v1")
    .func("add-to-position-and-switch")
    .call(
      {
        dx: form.collateralAmount * 1e8,
        expiry: form.expiry,
        "yield-token-trait": yieldToken,
        "token-trait": underlying,
        "min-dy": minDy * 1e8,
        "key-token-trait": keyToken,
        "collateral-trait": collateral,
      },
      [
        transfer(form.stxAddress, collateral, form.collateralAmount),
        transfer(
          AlexVault,
          underlying,
          minDy,
          FungibleConditionCode.GreaterEqual,
        ),
        transfer(
          form.stxAddress,
          yieldToken,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
        transfer(
          form.stxAddress,
          underlying,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
      ],
    )
}

export async function getYieldTokenPerUnderlyingToken(
  poolToken: YTPToken,
  inputAmount: number,
): Promise<Result<number, "ERR-DY-BIGGER-THAN-AVAILABLE">> {
  const unit = inputAmount * 1e8
  const result = await asSender(CONTRACT_DEPLOYER)
    .contract("yield-token-pool")
    .func("get-y-given-x")
    .call({
      "yield-token": yieldTokenForYTP(poolToken),
      expiry: await currentExpiry(poolToken),
      dx: unit,
    })
  if (result.type === "success") {
    return Result.ok(result.value / unit)
  }
  if (
    result.error instanceof ClarityError &&
    errorCodesFromName(
      "ERR-DY-BIGGER-THAN-AVAILABLE",
      "ERR-INVALID-BALANCE",
      "ERR-MAX-OUT-RATIO",
      "ERR-INVALID-POOL",
    ).includes(result.error.code)
  ) {
    return Result.error("ERR-DY-BIGGER-THAN-AVAILABLE" as const)
  }
  throw result.error
}

export async function expiryCorrespondingPoolExist(
  expiry: number,
  uToken: Currency.ALEX,
  collateral: CollateralToken,
): Promise<boolean> {
  const result = await asSender(CONTRACT_DEPLOYER)
    .contract("collateral-rebalancing-pool-v1")
    .func("get-pool-details")
    .call({
      expiry,
      token: uToken,
      collateral: collateral,
    })
  return result.type === "success"
}

export async function claimDeposit(form: {
  stxAddress: string
  ytpToken: YTPToken
  expires: number[]
}): Promise<{ txId: string }> {
  const { collateral, yieldToken, underlying } = ytpBreakDown(form.ytpToken)
  return await asSender(form.stxAddress)
    .contract("collateral-rebalancing-pool-v1")
    .func("reduce-position-yield-many")
    .call(
      {
        "collateral-trait": collateral,
        "yield-token-trait": yieldToken,
        percent: 1e8,
        "token-trait": underlying,
        expiries: form.expires,
      },
      // [transfer(AlexVault, underlying, 0, FungibleConditionCode.GreaterEqual)],
    )
}

export async function rollOverDeposit(form: {
  stxAddress: string
  ytpToken: YTPToken
  expires: number[]
  currentExpiry: number
}): Promise<{ txId: string }> {
  const { collateral, yieldToken, underlying } = ytpBreakDown(form.ytpToken)
  return await asSender(form.stxAddress)
    .contract("collateral-rebalancing-pool-v1")
    .func("roll-deposit-many")
    .call({
      "collateral-trait": collateral,
      "yield-token-trait": yieldToken,
      percent: 1e8,
      "token-trait": underlying,
      "expiry-to-roll": form.currentExpiry,
      expiries: form.expires,
    })
}

export async function yTokenPerCollateral(
  expiry: number,
  uToken: Currency.ALEX,
  collateral: CollateralToken,
  collateralPrice: number,
  blockHash: string,
): Promise<{ kTokenAmount: number; yTokenAmount: number } | undefined> {
  const amount = (200 / collateralPrice) * 1e8
  const result = await asSender(CONTRACT_DEPLOYER, blockHash)
    .contract("collateral-rebalancing-pool-v1")
    .func("get-token-given-position")
    .call({
      expiry,
      token: uToken,
      collateral: collateral,
      dx: amount,
    })
  if (result.type === "error") {
    if (
      result.error instanceof ClarityError &&
      errorCodesFromName("ERR-POOL-AT-CAPACITY").includes(result.error.code)
    ) {
      return undefined
    }
    throw result.error
  }
  return {
    kTokenAmount: result.value["key-token"] / amount,
    yTokenAmount: result.value["yield-token"] / amount,
  }
}

export async function uTokenPerYToken(
  expiry: number,
  uToken: Currency.ALEX,
  yToken: YieldToken,
  uTokenPrice: number,
  blockHash: string,
): Promise<number> {
  const amount = (200 / uTokenPrice) * 1e8
  const result = await asSender(CONTRACT_DEPLOYER, blockHash)
    .contract("yield-token-pool")
    .func("get-x-given-y")
    .call({
      expiry,
      "yield-token": yToken,
      dy: amount,
    })
    .then(unwrapResponse)
  return result / amount
}

export async function getCollateralUTokenBreakdownFromKeyToken(
  expiry: number,
  uToken: Currency.ALEX,
  collateral: CollateralToken,
  blockHash: string,
): Promise<{ collateral: number; uToken: number }> {
  const { dx, dy } = await asSender(CONTRACT_DEPLOYER, blockHash)
    .contract("collateral-rebalancing-pool-v1")
    .func("get-position-given-burn-key")
    .call({
      expiry,
      token: uToken,
      collateral: collateral,
      shares: 1e8,
    })
    .then(unwrapResponse)
  return {
    collateral: dx / 1e8,
    uToken: dy / 1e8,
  }
}

export type CRPPoolDetail = {
  balanceX: number
  balanceY: number
  uTokenPerShare: number
  collateralPerShare: number
  estUTokenPerKeyToken: number
  estCollateralPerKeyToken: number
  // ltv: number
  feeRateToken: number
  feeRateYieldToken: number
  yieldPerToken: number
  minFee: number
}

export function crpPoolDetail(ytp: YTPToken): Observable<{
  details: {
    [expiry: number]: CRPPoolDetail
  }
  totalDeposit: number
  totalBorrow: number
  borrowApr: number
  depositApr: number
}> {
  return fromUrqlSource(
    gqlQuery<CrpPoolDetailQuery, CrpPoolDetailQueryVariables>(
      gql`
        query CRPPoolDetail(
          $collateral: String!
          $underlying: String!
          $ytpToken: String!
          $yieldToken: String!
        ) {
          laplace_latest_collateral_per_share(
            where: {
              token_x: { _eq: $collateral }
              token_y: { _eq: $underlying }
            }
          ) {
            token_per_share
            collateral_per_share
            expiry
          }
          laplace_latest_collateral_rebalancing_pool(
            where: {
              token_x: { _eq: $collateral }
              token_y: { _eq: $underlying }
            }
            order_by: { expiry: desc }
          ) {
            expiry
            est_collateral_price_per_key_token
            est_token_price_per_key_token
            bs_vol
            #            ltv_0
            balance_x
            balance_y
          }
          laplace_borrow_deposit_stats(
            where: { pool_token: { _eq: $ytpToken } }
            order_by: { block_height: desc }
            limit: 1
          ) {
            total_borrow
            total_deposit
          }
          laplace_latest_yield_token_pool(
            where: { yield_token: { _eq: $yieldToken } }
            order_by: { expiry: desc }
          ) {
            expiry
            fee_rate_token
            fee_rate_yield_token
            yield_per_token
            borrow_apr
            deposit_apr
            min_fee
          }
          laplace_borrow_deposit_daily_stats(
            where: { pool_token: { _eq: $ytpToken } }
            order_by: { day: desc }
          ) {
            apr_borrow_avg
            apr_deposit_avg
            day
            latest_total_borrow
            latest_total_deposit
          }
        }
      `,
      {
        ytpToken: contractAddr(ytp),
        collateral: contractAddr(ytpBreakDown(ytp).collateral),
        underlying: contractAddr(ytpBreakDown(ytp).underlying),
        yieldToken: contractAddr(ytpBreakDown(ytp).yieldToken),
      },
    ),
    result => {
      const pools: {
        [expiry: number]: CRPPoolDetail
      } = {}
      for (const crp of result.data
        .laplace_latest_collateral_rebalancing_pool) {
        pools[crp.expiry] ??= {} as any
        const pool = pools[crp.expiry]!
        pool.estUTokenPerKeyToken = crp.est_token_price_per_key_token / 1e8
        pool.estCollateralPerKeyToken =
          crp.est_collateral_price_per_key_token / 1e8
        pool.balanceX = crp.balance_x / 1e8
        pool.balanceY = crp.balance_y / 1e8
        // pool.ltv = crp.ltv_0 / 1e8
      }
      for (const perShare of result.data.laplace_latest_collateral_per_share) {
        pools[perShare.expiry] ??= {} as any
        pools[perShare.expiry]!.collateralPerShare =
          perShare.collateral_per_share / 1e8
        pools[perShare.expiry]!.uTokenPerShare = perShare.token_per_share / 1e8
      }
      for (const ytp of result.data.laplace_latest_yield_token_pool) {
        pools[ytp.expiry]!.yieldPerToken = (ytp.yield_per_token ?? 0) / 1e8
        pools[ytp.expiry]!.feeRateToken = (ytp.fee_rate_token ?? 0) / 1e8
        pools[ytp.expiry]!.feeRateYieldToken =
          (ytp.fee_rate_yield_token ?? 0) / 1e8
        pools[ytp.expiry]!.minFee = (ytp.min_fee ?? 0) / 1e8
      }
      return {
        details: pools,
        totalBorrow:
          (result.data.laplace_borrow_deposit_stats[0]?.total_borrow ?? 0) /
          1e8,
        totalDeposit:
          (result.data.laplace_borrow_deposit_stats[0]?.total_deposit ?? 0) /
          1e8,
        borrowApr:
          result.data.laplace_latest_yield_token_pool[0]?.borrow_apr ?? 0,
        depositApr:
          result.data.laplace_latest_yield_token_pool[0]?.deposit_apr ?? 0,
      }
    },
  )
}

export async function claimBorrow({
  stxAddress,
  expiry,
  poolToken,
}: {
  stxAddress: string
  expiry: number
  poolToken: YTPToken
}): Promise<{ txId: string }> {
  const { underlying, collateral, keyToken } = ytpBreakDown(poolToken)
  return await asSender(stxAddress)
    .contract("collateral-rebalancing-pool-v1")
    .func("reduce-position-key")
    .call(
      {
        expiry,
        percent: 1e8,
        "token-trait": underlying,
        "collateral-trait": collateral,
        "key-token-trait": keyToken,
      },
      // [
      //   transfer(AlexVault, underlying, 0, FungibleConditionCode.GreaterEqual),
      //   transfer(AlexVault, collateral, 0, FungibleConditionCode.GreaterEqual),
      // ],
    )
}

export function fetchCRPHourlyStateTotalRecord([poolToken, expiry]: readonly [
  poolToken: YTPToken,
  expiry: number,
]): Observable<number> {
  return fromUrqlSource(
    gqlQuery<
      FetchCrpHourlyStateTotalRecordQuery,
      FetchCrpHourlyStateTotalRecordQueryVariables
    >(
      gql`
        query FetchCRPHourlyStateTotalRecord(
          $expiry: numeric!
          $collateral: String!
          $underlying: String!
        ) {
          laplace_borrow_deposit_hourly_stats_aggregate(
            where: {
              token_x: { _eq: $collateral }
              token_y: { _eq: $underlying }
              expiry: { _eq: $expiry }
            }
          ) {
            aggregate {
              count
            }
          }
        }
      `,
      {
        expiry,
        collateral: contractAddr(ytpBreakDown(poolToken).collateral),
        underlying: contractAddr(ytpBreakDown(poolToken).underlying),
      },
    ),
    result =>
      result.data.laplace_borrow_deposit_hourly_stats_aggregate.aggregate
        ?.count ?? 0,
  )
}

export function fetchCRPHourlyStateRecords([poolToken, expiry, page]: readonly [
  poolToken: YTPToken,
  expiry: number,
  page: number,
]): Observable<
  {
    hour: number
    collateralPerShare: number
    valuePerShare: number
    underlyingPerShare: number
  }[]
> {
  return fromUrqlSource(
    gqlQuery<
      FetchCrpHourlyStatRecordsQuery,
      FetchCrpHourlyStatRecordsQueryVariables
    >(
      gql`
        query FetchCRPHourlyStatRecords(
          $expiry: numeric!
          $offset: Int!
          $collateral: String!
          $underlying: String!
        ) {
          laplace_borrow_deposit_hourly_stats(
            where: {
              token_x: { _eq: $collateral }
              token_y: { _eq: $underlying }
              expiry: { _eq: $expiry }
            }
            offset: $offset
            order_by: { hour: desc }
            limit: 20
          ) {
            hour
            collateral_per_share
            token_per_share
            value_per_share
            burn_block_time
          }
        }
      `,
      {
        expiry,
        collateral: contractAddr(ytpBreakDown(poolToken).collateral),
        underlying: contractAddr(ytpBreakDown(poolToken).underlying),
        offset: page * 20,
      },
    ),
    result =>
      result.data.laplace_borrow_deposit_hourly_stats.map(a => ({
        hour: a.hour,
        underlyingPerShare: a.token_per_share / 1e8,
        collateralPerShare: a.collateral_per_share / 1e8,
        valuePerShare: a.value_per_share / 1e8,
      })),
  )
}

export type DepositSellPendingFormData = {
  expiry: number
  poolToken: YTPToken
  stxAddress: string
  amount: number
  slippage: number
  yTokenPriceInUToken: number
}

export async function sellPendingDeposit(
  form: DepositSellPendingFormData,
): Promise<{ txId: string }> {
  const yTokenAmount = form.amount
  const minUTokenAmount =
    form.amount * form.yTokenPriceInUToken * (1 - form.slippage)
  const { yieldToken, underlying } = ytpBreakDown(form.poolToken)
  return await asSender(form.stxAddress)
    .contract("yield-token-pool")
    .func("swap-y-for-x")
    .call(
      {
        expiry: form.expiry,
        "token-trait": underlying,
        "yield-token-trait": yieldToken,
        dy: yTokenAmount * 1e8,
        "min-dx": minUTokenAmount * 1e8,
      },
      [
        transfer(form.stxAddress, yieldToken, yTokenAmount),
        transfer(
          AlexVault,
          underlying,
          minUTokenAmount,
          FungibleConditionCode.GreaterEqual,
        ),
      ],
    )
}

export function fetchBorrowChartData([
  ytpToken,
  xTokenInfo,
  yTokenInfo,
]: readonly [
  ytpToken: YTPToken,
  xTokenInfo: TokenInfo,
  yTokenInfo: TokenInfo,
]): Observable<BorrowChartPanelPropsChartData> {
  return fromUrqlSource(
    gqlQuery<FetchBorrowChartDataQuery, FetchBorrowChartDataQueryVariables>(
      gql`
        query FetchBorrowChartData($ytpToken: String!) {
          laplace_borrow_deposit_daily_stats(
            where: { pool_token: { _eq: $ytpToken } }
            order_by: { day: desc }
            limit: 8
          ) {
            day
            latest_active_crp_pool_value
            pool_weight_x
            pool_weight_y
          }
        }
      `,
      {
        ytpToken: contractAddr(ytpToken),
      },
    ),
    result => ({
      data: reverse(
        result.data.laplace_borrow_deposit_daily_stats.map((a, idx) => ({
          date: parseApiChartDate(a.day, idx),
          totalVolume:
            a.latest_active_crp_pool_value /
            currencyScale(ytpBreakDown(ytpToken).underlying),
          percentages: [
            { token: xTokenInfo, percentage: a.pool_weight_x },
            { token: yTokenInfo, percentage: a.pool_weight_y },
          ],
        })),
      ),
    }),
  )
}

export function fetchDepositChartData([ytpToken]: readonly [
  ytpToken: YTPToken,
]): Observable<DepositChartPanelPropsChartData> {
  return fromUrqlSource(
    gqlQuery<FetchDepositChartDataQuery, FetchDepositChartDataQueryVariables>(
      gql`
        query FetchDepositChartData($ytpToken: String!) {
          laplace_borrow_deposit_daily_stats(
            where: { pool_token: { _eq: $ytpToken } }
            order_by: { day: desc }
            limit: 30
          ) {
            day
            apr_deposit_avg
            latest_total_deposit
          }
        }
      `,
      {
        ytpToken: contractAddr(ytpToken),
      },
    ),
    result => ({
      totalDepositCurve: reverse(
        result.data.laplace_borrow_deposit_daily_stats.map((a, idx) => ({
          date: parseApiChartDate(a.day, idx),
          tokenCount:
            a.latest_total_deposit /
            currencyScale(ytpBreakDown(ytpToken).underlying),
        })),
      ),
      aprCurve: reverse(
        result.data.laplace_borrow_deposit_daily_stats.map((a, idx) => ({
          date: parseApiChartDate(a.day, idx),
          apr: a.apr_deposit_avg,
        })),
      ),
    }),
  )
}

function parseApiChartDate(date: number, dataIndex: number): Date {
  // we accelerated time in dev network (1 hour = 1 day), so we need also to transform the day field
  return subDays(new Date(date * 1000), IS_MAIN_NET ? 0 : dataIndex)
}

export function getFlashLoadFee(): Promise<number> {
  return asSender(CONTRACT_DEPLOYER)
    .contract("alex-vault")
    .func("get-flash-loan-fee-rate")
    .call({})
    .then(unwrapResponse)
    .then(a => a / 1e8)
}

export type RollOverBorrowFormData = {
  stxAddress: string
  currentExpiry: number
  fromExpiry: number
  poolToken: YTPToken
  // yieldTokenAmount: number
  // uTokenPerYieldToken: number
  // slippage: number
}

export function rollOverBorrow({
  stxAddress,
  currentExpiry,
  fromExpiry,
  poolToken,
}: RollOverBorrowFormData): Promise<{ txId: string }> {
  const { underlying, yieldToken, collateral, keyToken } =
    ytpBreakDown(poolToken)

  // const minDx =
  //   yieldTokenAmount / (1 + (1 / uTokenPerYieldToken - 1) * (1 + slippage))
  return asSender(stxAddress)
    .contract("collateral-rebalancing-pool-v1")
    .func("roll-borrow")
    .call({
      expiry: fromExpiry,
      "expiry-to-roll": currentExpiry,
      "token-trait": underlying,
      "yield-token-trait": yieldToken,
      "collateral-trait": collateral,
      "key-token-trait": keyToken,
      "min-dx": undefined,
      // "min-dx": minDx * 1e8,
    })
}

export const currentLTV = ([expiry, ytpToken]: readonly [
  number,
  YTPToken,
]): Promise<number> =>
  asSender(CONTRACT_DEPLOYER)
    .contract("collateral-rebalancing-pool-v1")
    .func("get-ltv")
    .call({
      expiry,
      token: ytpBreakDown(ytpToken).underlying,
      collateral: ytpBreakDown(ytpToken).collateral,
    })
    .then(unwrapResponse)
    .then(a => a / 1e8)
