import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { AllTokenInfos, CurrencyAmount } from "../../../types"
import { ExchangeRatio } from "../ExchangeRatio"
import { BaseCellContainer } from "./BaseCellContainer"

export const OneLineExchangeRatioCell: FC<{
  confirmed: boolean
  fromCurrencyAmount: CurrencyAmount
  toCurrencyAmount: CurrencyAmount
  allTokenInfos: AllTokenInfos
}> = props => {
  const { $t } = useIntl()
  return (
    <BaseCellContainer>
      {props.confirmed ? (
        <ExchangeRatio
          allTokenInfos={props.allTokenInfos}
          fromCurrencyAmount={
            props.fromCurrencyAmount as CurrencyAmount.Confirmed
          }
          toCurrencyAmount={props.toCurrencyAmount as CurrencyAmount.Confirmed}
        />
      ) : (
        $t(
          defineMessage({
            defaultMessage: "Wait to Confirm",
            description: "/Notify/Transaction/Table/Exchange ratio cell text",
          }),
        )
      )}
    </BaseCellContainer>
  )
}
