import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { dontWrapObserver } from "../../../../../../utils/mobxHelpers"

export const WarningSection: FC = () => {
  const { $t } = useIntl()
  return (
    <div className="bg-red-900 px-4 py-2.5 rounded-lg">
      {$t(
        defineMessage({
          defaultMessage: `<line>Please be patient, you have a pending transaction in the process of withdraw. </line>
            <line>Do not repeat submission before the transaction is completed. Otherwise the withdrawal amount is greater than the balance,</line>
            <line><underline>WILL resulting in an account anomaly.</underline></line>
          `,
          description:
            "/OrderBook/BRC20/WithdrawDuplicateWarningModalContent/DetailSection text",
        }),
        {
          line: dontWrapObserver(children => (
            <p className="text-yellow-400 text-xs">{children}</p>
          )),
          underline: dontWrapObserver(children => (
            <span className={"underline"}>{children}</span>
          )),
        },
      )}
    </div>
  )
}
