import { Currency } from "../../../../../utils/alexjs/Currency"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = [
  "autoalex-apower-helper",
  "autoalex-apower-helper-v2",
] as const
const functionName = "mint-and-burn-apower" as const

export interface AutoStakingAPowerDistributionRowData extends BaseRowData {
  type: NotifyTransactionType.AutoStakingAPowerDistribution
  apowerCurrencyAmount: CurrencyAmount
  cycle: number
}

export const autoStakingAPowerDistributionTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  AutoStakingAPowerDistributionRowData,
  { address: string }
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.AutoStakingAPowerDistribution,
  confirmedTransformer: (helpers, context) => {
    if (!context?.address) {
      throw new Error("context of address not found")
    }
    const args = helpers.getArgs()
    const amount = getAmountFromContract(
      args.recipients.find(r => r.recipient === context.address)?.amount ?? 0,
    )

    return {
      apowerCurrencyAmount: {
        currency: Currency.APOWER,
        amount,
      },
      cycle: args.cycle,
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      apowerCurrencyAmount: {
        currency: Currency.APOWER,
      },
      cycle: args.cycle,
    }
  },
}
