import { FC } from "react"
import { useIntl } from "react-intl"
import {
  MaskErrorBoundary,
  MaskErrorBoundaryProps,
  defaultMaskErrorBoundaryFallbackFactory,
} from "../../../../../components/MaskErrorBoundary"
import { useColors } from "../../../../../components/Themed/color"

export const WrapBridgePanelDetailsErrorBoundary: FC<
  Omit<MaskErrorBoundaryProps, "fallback">
> = props => {
  const intl = useIntl()
  const colors = useColors()

  return (
    <MaskErrorBoundary
      fallback={defaultMaskErrorBoundaryFallbackFactory({ intl, colors })}
    >
      {props.children}
    </MaskErrorBoundary>
  )
}
