import clsx from "clsx"
import { FC, useEffect } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  MaskErrorBoundary,
  defaultMaskErrorBoundaryFallbackFactory,
} from "../../../components/MaskErrorBoundary"
import { GradientSecondaryPageTitle } from "../../../components/PageStack/GradientSecondaryPageTitle"
import { PageStackPage } from "../../../components/PageStack/PageStackPage"
import { useColors } from "../../../components/Themed/color"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { WiredTransactionStateDialogForGeneral } from "../../../components/TransactionStateDialog/WiredTransactionStateDialogForGeneral"
import { usePathGenerator } from "../../../routes/routes"
import {
  OrderbookPegInStoreProvider,
  useOrderbookPegInStore,
} from "./stores/useOrderbookPegInStore"
import { WiredInProgressPanel } from "./wiredComponents/WiredInProgressPanel"
import { WiredStacksWalletConnectionLine } from "./wiredComponents/WiredStacksWalletConnectionLine"
import { WiredTransferPanel } from "./wiredComponents/WiredTransferPanel"

const OrderbookPegInPageContent: FC<{
  className?: string
  initialSelectedCurrency?: null | string
}> = props => {
  const gen = usePathGenerator()
  const intl = useIntl()
  const colors = useColors()

  const { $t } = intl

  const pegInStore = useOrderbookPegInStore()

  useEffect(() => {
    if (props.initialSelectedCurrency == undefined) return
    void pegInStore.pegInForm.trySelectBRC20Currency(
      props.initialSelectedCurrency,
    )
  }, [pegInStore.pegInForm, props.initialSelectedCurrency])

  return (
    <PageStackPage
      title={
        <GradientSecondaryPageTitle
          gradientStyle={
            "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(16, 185, 129, 1))"
          }
          returnFallbackURL={gen.orderbook()}
          subtitle={<WiredStacksWalletConnectionLine />}
        >
          {$t(
            defineMessage({
              defaultMessage: "Peg-in BRC-20",
              description: "Orderbook Peg-In Page/page title",
            }),
          )}
        </GradientSecondaryPageTitle>
      }
    >
      <div className={clsx(props.className, "flex flex-col gap-y-8")}>
        <MaskErrorBoundary
          fallback={defaultMaskErrorBoundaryFallbackFactory({ intl, colors })}
        >
          {p => (
            <WiredTransferPanel
              containerRef={p.containerDomRef}
              inProgressPanel={
                <MaskErrorBoundary
                  fallback={defaultMaskErrorBoundaryFallbackFactory({
                    intl,
                    colors,
                  })}
                >
                  {p => (
                    <WiredInProgressPanel
                      className="w-full mt-[1.875rem]"
                      containerRef={p.containerDomRef}
                    />
                  )}
                </MaskErrorBoundary>
              }
            />
          )}
        </MaskErrorBoundary>
      </div>

      <WiredTransactionStateDialogForGeneral
        store={pegInStore.generalTxStore}
        onClose={() => pegInStore.generalTxStore.closeRunning()}
      />
      <WiredTransactionStateDialog
        store={pegInStore.txStore}
        onClose={() => pegInStore.txStore.closeRunning()}
      />
    </PageStackPage>
  )
}

export const OrderbookPegInPage: FC = () => {
  const [params] = useSearchParams()

  return (
    <OrderbookPegInStoreProvider>
      <OrderbookPegInPageContent
        initialSelectedCurrency={params.get("token")}
      />
    </OrderbookPegInStoreProvider>
  )
}
