import { ElementRef, FC, Suspense, useRef } from "react"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { Divider } from "../../components/Divider"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { ConfirmTransactionStore } from "../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { ProcessLaunchPad } from "./components/LaunchpadSetup"
import { MetaMask } from "./components/MetaMask"
import { SignMessage } from "./components/SignMessage"
import SponsorTx from "./components/SponsorTx"

export const ConnectWallet: FC = () => {
  const [connect] = useConnect()
  return (
    <PageStackPage>
      <div>
        <h1>Connect your wallet</h1>
        <GradientFilledButton onClick={() => connect()}>
          Connect Wallet
        </GradientFilledButton>
      </div>
    </PageStackPage>
  )
}

const Content: FC = () => {
  const authStore = useAuthStore()
  const chainStore = useChainStore()
  const store = useCreation(() => new ConfirmTransactionStore(), [])
  const block = chainStore.currentBlockHeight$
  const processRef = useRef<ElementRef<typeof ProcessLaunchPad>>(null)

  return (
    <PageStackPage title={"Debug"}>
      <Suspense fallback={<div>MetaMask initializing...</div>}>
        <MetaMask />
      </Suspense>
      <Divider className={"w-full my-3"} />
      <SignMessage />
      <Divider className={"w-full my-3"} />
      <SponsorTx />
    </PageStackPage>
  )
}

export const DebugPage: FC = () => {
  const authStore = useAuthStore()
  if (!authStore.isWalletConnected) {
    return <ConnectWallet />
  }
  return (
    <Suspense fallback={null}>
      <Content />
    </Suspense>
  )
}
