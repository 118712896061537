import clsx from "clsx"
import { useMemo } from "react"
import { CardDivider as _CardDivider } from "../../../../../components/Card"
import { useSpacing } from "../../../../../components/Themed/spacing"
import { FCC, PropsWithChildren } from "../../../../../utils/reactHelpers/types"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"

export type RenderBRC20CardFn = (
  props: PropsWithChildren<BRC20CardProps>,
) => JSX.Element

export const defaultRenderBRC20Card: RenderBRC20CardFn = props => (
  <BRC20Card {...props} />
)

export interface BRC20CardProps {
  className?: string
  gap?: number
  onClick?: () => void
}

export const BRC20Card: FCC<BRC20CardProps> = props => {
  const defaultStyles = useBRC20CardDefaultStyles()

  const gap = props.gap ?? defaultStyles.gap

  return (
    <div
      className={clsx(
        "px-4 py-2.5 flex flex-col rounded",
        cardTextClassName,
        props.className,
      )}
      style={{
        rowGap: gap,
        backgroundColor: "rgba(59, 130, 246, 0.20)",
      }}
      onClick={() => props.onClick?.()}
    >
      {props.children}
    </div>
  )
}

export const SelectableBRC20Card: FCC<
  BRC20CardProps & {
    selected: boolean
    onToggleSelect: () => void
  }
> = props => {
  return (
    <BRC20Card
      {...props}
      className={clsx(
        props.className,
        "cursor-pointer",
        "border-[3px] ",
        props.selected ? "border-blue-800" : "border-transparent",
      )}
      onClick={props.onToggleSelect}
    />
  )
}

export const useBRC20CardDefaultStyles = (): { gap: number } => {
  const spacing = useSpacing()

  return useMemo(() => ({ gap: spacing(2.5) }), [spacing])
}

const cardTextClassName = "text-xs leading-4 font-normal"
export const CardText = withClassName(cardTextClassName, "span")

export const CardHeroText = withClassName("text-lg leading-7 font-bold", "span")

export const CardStrongText = withClassName(
  "text-sm leading-5 font-semibold",
  "span",
)

export const CardDivider = _CardDivider
