import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["yield-token-pool"] as const
const functionName = "swap-x-for-y" as const

export interface DepositRowData extends BaseRowData {
  type: NotifyTransactionType.Deposit
  fromCurrencyAmount: CurrencyAmount
  toCurrencyAmount: CurrencyAmount
  expiry: number
}

export const depositTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  DepositRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.Deposit,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    return {
      fromCurrencyAmount: {
        currency: tokenTrait,
        amount: transferAmounts[tokenTrait]?.out,
      },
      toCurrencyAmount: {
        currency: yieldTokenTrait,
        amount: transferAmounts[yieldTokenTrait]?.in,
      },
      expiry: args.expiry,
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    return {
      fromCurrencyAmount: {
        currency: tokenTrait,
        amount: getAmountFromContract(args.dx),
      },
      toCurrencyAmount: {
        currency: yieldTokenTrait,
        minAmount: getAmountFromContract(args["min-dy"]),
      },
      expiry: args.expiry,
    }
  },
}
