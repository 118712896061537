import { ordinal } from "../../../../utils/stringHelpers"
import { isNotNull } from "../../../../utils/utils"
import { LotteryWonRowData } from "../../store/transformerGroups/lottery/lotteryWon"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const lotteryWonExporter: Exporter<LotteryWonRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = [
    (rowData.winningTickets != null ? rowData.winningTickets + " " : "") +
      "Lottery",
    parseAmount({
      toCurrencyAmounts: [rowData.winningCurrencyAmount],
      allTokenInfos,
    }),
  ]
    .filter(isNotNull)
    .join("\n")
  const tradeInfo = [
    `Round #${rowData.lotteryId}`,
    rowData.prize != null ? `${ordinal(rowData.prize!)} Prize` : undefined,
  ]
    .filter(isNotNull)
    .join("\n")
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
