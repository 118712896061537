/**
 * https://github.com/0no-co/wonka/blob/20692519a51f5f7e35a191b2045da409d5f2497e/src/observable.ts
 */

import { noop } from "lodash"
import { Subscribable } from "rxjs"
import { SignalKind, Source, TalkbackKind } from "./types"

export function toSubscribable<T>(source: Source<T>): Subscribable<T> {
  return {
    subscribe(observer) {
      let talkback = noop
      let ended = false
      source(signal => {
        if (ended) {
          /*noop*/
        } else if (signal === SignalKind.End) {
          ended = true
          observer.complete?.()
        } else if (signal.tag === SignalKind.Start) {
          ;(talkback = signal[0])(TalkbackKind.Pull)
        } else {
          observer.next?.(signal[0])
          talkback(TalkbackKind.Pull)
        }
      })
      const subscription = {
        closed: false,
        unsubscribe() {
          subscription.closed = true
          ended = true
          talkback(TalkbackKind.Close)
        },
      }
      return subscription
    },
  }
}
