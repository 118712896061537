import { FC } from "react"

export const FullPageIframe: FC<{ iframe: string }> = props => {
  return (
    <iframe
      src={props.iframe}
      className="flex-1 w-full"
      title="analytics"
      key={props.iframe}
    />
  )
}
