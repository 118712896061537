import { computed, makeObservable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { AMMSwapPool } from "../../../../utils/alexjs/AMMSwapPool"
import { Currency } from "../../../../utils/alexjs/Currency"
import { isDualYieldPool } from "../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import type { RewardInfos } from "../../types"
import StakeChainModule from "../shared/StakeChainModule"
import {
  cycleStartBlockForToken,
  stakeCycleLength,
} from "../shared/StakeSharedModule.service"
import type { StakableCurrency, StakingToken } from "./ManualStakeStore.service"
import { getEarningPreview } from "./ManualStakeStore.service"
import AddStakeViewModule from "./_/AddStakeViewModule"
import { DualYieldModule } from "./_/DualYieldModule"
import { DualYieldModuleV1_1 } from "./_/DualYieldModuleV1_1"
import MyStakingViewModule from "./_/MyStakingViewModule"

export const stakeStoreCache: Partial<{
  [P in StakableCurrency]: ManualStakeStore
}> = {}

class ManualStakeStore {
  constructor(
    readonly stakableToken: StakableCurrency,
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight$" | "isWalletConnected"
    >,
    readonly currencyStore: CurrencyStore,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$" | "stakeChainModule"
    >,
  ) {
    this.addStake = new AddStakeViewModule(this, appEnv)
    makeObservable(this)
    stakeStoreCache[stakableToken] = this
  }

  @computed({ keepAlive: true }) get token$(): StakingToken {
    return this.currencyStore.stakingCurrencyToStakingToken$(this.stakableToken)
  }

  @computed({ keepAlive: true }) get dualYield(): DualYieldModule | null {
    if (
      this.stakableToken !== Currency.ALEX &&
      !AMMSwapPool.isPoolToken(this.stakableToken) &&
      isDualYieldPool(this.stakableToken)
    ) {
      return new DualYieldModule(this.stakableToken, this)
    }
    return null
  }

  @computed({ keepAlive: true })
  get dualYieldV1_1(): DualYieldModuleV1_1 | null {
    if (this.stakableToken === Currency.ALEX) {
      return new DualYieldModuleV1_1(this.stakableToken, this)
    }
    return null
  }

  @computed get tokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.stakableToken)
  }

  addStake: AddStakeViewModule
  myStaking = new MyStakingViewModule(this)

  @computed get stakeChain$(): StakeChainModule {
    return this.chainStore.stakeChainModule(this.token$)
  }

  private _apowerMultiplier$ = new LazyValue(
    () => this.token$,
    token => {
      if (typeof token !== "string") {
        return asSender(CONTRACT_DEPLOYER)
          .contract("alex-reserve-pool-sft")
          .func("get-apower-multiplier-in-fixed-or-default")
          .call({ token: token.currency, "token-id": token.tokenId })
          .then(a => a / 1e8)
      }
      return asSender(CONTRACT_DEPLOYER)
        .contract("alex-reserve-pool")
        .func("get-apower-multiplier-in-fixed-or-default")
        .call({ token })
        .then(a => a / 1e8)
    },
  )

  @computed get apowerMultiplier$(): number {
    return this._apowerMultiplier$.value$
  }

  @computed get currentCycle$(): number {
    return this.stakeChain$.currentCycle$
  }

  @computed get nextCycle$(): number {
    return this.currentCycle$ + 1
  }

  blocksPerCycle = new LazyValue(() => null, stakeCycleLength)

  firstCycleBlock = new LazyValue(
    () => [this.token$] as const,
    ([token]) => cycleStartBlockForToken(token),
  )

  @computed get nextCycleBlock$(): number {
    return this.stakeChain$.nextCycleBlock$
  }

  @computed get nextCycleDate$(): Date {
    return this.stakeChain$.nextCycleDate$
  }

  private _earningPreview = new LazyValue(
    () => [this.token$] as const,
    ([token]) => getEarningPreview(token),
  )

  @computed get idealEarningPreview(): number[] {
    return this._earningPreview.value$.map(
      a =>
        a *
        (this.dualYield?.aprMultiplier$ ??
          this.dualYieldV1_1?.aprMultiplier$ ??
          1),
    )
  }

  @computed get alexTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(Currency.ALEX)
  }

  @computed get apowerTokenInfo$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(Currency.APOWER)
  }

  @computed get rewardInfos$(): RewardInfos {
    const dualYield = this.dualYield
    const dualYieldV11 = this.dualYieldV1_1
    const alexReward = {
      token: this.alexTokenInfo$,
      count: this.myStaking.pendingReward,
      countToUSD: suspenseResource(() => {
        return (
          this.myStaking.pendingReward *
          this.currencyStore.getPrice$(Currency.ALEX)
        )
      }),
    }
    const apowerReward = {
      token: this.apowerTokenInfo$,
      count: this.myStaking.pendingReward * this.apowerMultiplier$,
    }
    const dualYieldReward =
      dualYield != null
        ? {
            token: this.currencyStore.getTokenInfo$(dualYield.underlyingToken$),
            count: this.myStaking.pendingReward * dualYield.multiplier$,
            countToUSD: suspenseResource(() => {
              return (
                this.myStaking.pendingReward *
                dualYield.multiplier$ *
                this.currencyStore.getPrice$(dualYield.underlyingToken$)
              )
            }),
          }
        : dualYieldV11 != null
        ? {
            token: this.currencyStore.getTokenInfo$(
              dualYieldV11.underlyingToken$,
            ),
            count: dualYieldV11.pendingReward$,
            countToUSD: suspenseResource(() => {
              return (
                dualYieldV11.pendingReward$ *
                this.currencyStore.getPrice$(dualYieldV11.underlyingToken$)
              )
            }),
          }
        : null
    return dualYieldReward == null
      ? [alexReward, apowerReward]
      : [alexReward, dualYieldReward, apowerReward]
  }

  @computed get v1StakeEndCycle$(): undefined | number {
    return this.stakeChain$.v1StakeEndCycle$
  }
}

export default ManualStakeStore
