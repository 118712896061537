import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../components/Card"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { NoteParagraph } from "../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../components/PercentNumber"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { BlockTopLine } from "../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface ConfirmCoFarmModalContentProps {
  lpLeftToken: TokenInfo
  lpRightToken: TokenInfo

  lpRightTokenTransformedToken: TokenInfo
  lpRightTokenTransformedTokenEstimateApy: number
  lpRightTokenTransformedTokenStakePrice: number

  coFarmEstimateApr: number
  alexFoundationOffer: TokenCountPairPlain
  fromCycle: number
  toCycle: number
  harvestAssets: TokenCountPairPlain[]
  coFarmAssets: TokenCountPairPlain[]

  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmCoFarmModalContent: FC<
  ConfirmCoFarmModalContentProps
> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Co-Farm LP",
          description: "/Farm/Confirm co farm modal content title",
        }),
      )}
      className="flex flex-col gap-4"
      width={480}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            topArea={
              <BlockTopLine className="flex flex-row gap-x-2.5">
                <span>
                  {$t(
                    defineMessage({
                      defaultMessage: "Harvest",
                      description:
                        "/Farm/Confirm co farm modal content harvest",
                    }),
                  )}
                </span>
              </BlockTopLine>
            }
            tokens={props.harvestAssets}
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Co-Farm {icon}",
                    description: "/Farm/Confirm co farm modal content",
                  }),
                  {
                    icon: (
                      <TipIcon
                        className="ml-1"
                        inline
                        tip={
                          <>
                            {$t(
                              defineMessage({
                                defaultMessage:
                                  "Your {tokenName} in the Co-Farm LP will be protected from impermanent loss by the ALEX Lab Foundation via the pool value.",
                                description:
                                  "/Farm/Confirm co farm modal content",
                              }),
                              {
                                tokenName: (
                                  <TokenName token={props.lpLeftToken} />
                                ),
                              },
                            )}
                          </>
                        }
                      />
                    ),
                  },
                )}
              </BlockTopLine>
            }
            tokens={props.coFarmAssets}
          />
        }
      />
      <CardPlate>
        <InfoList>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Co-Farm Est APR",
                  description: "/Farm/Confirm co farm modal content",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <PercentNumber number={props.coFarmEstimateApr} />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "ALEX Lab Foundation provides",
                  description: "/Farm/Confirm co farm modal content",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount {...props.alexFoundationOffer} />
              <TokenName token={props.alexFoundationOffer.token} />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Cycle numbers",
                  description: "/Farm/Confirm co farm modal content",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              #{props.fromCycle}-{props.toCycle}
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              <TokenName token={props.lpRightTokenTransformedToken} /> Est APY
            </InfoListItemTitle>
            <InfoListItemDetail>
              <PercentNumber
                number={props.lpRightTokenTransformedTokenEstimateApy}
              />
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "{tokenName} current value",
                  description: "/Farm/Confirm co farm modal content",
                }),
                {
                  tokenName: (
                    <TokenName token={props.lpRightTokenTransformedToken} />
                  ),
                },
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              {props.lpRightTokenTransformedTokenStakePrice}
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardPlate>

      <NoteParagraph textSizingClassName={"text-sm"}>
        {$t(
          defineMessage({
            defaultMessage:
              "Co-Farming is not a riskless commitment. In an extreme event, if the impermanent loss exceeds the capital of the {leftToken}/{rightToken} pool, the {leftToken} committed will be subject to a loss.",
            description: "/Farm/Confirm co farm modal content",
          }),
          {
            leftToken: <TokenName token={props.lpLeftToken} />,
            rightToken: <TokenName token={props.lpRightToken} />,
          },
        )}
      </NoteParagraph>
      <div className={"flex flex-row gap-2.5 pt-4"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Farm/Confirm co farm modal content cancel button",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Farm/Confirm co farm modal content confirm button",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
