import { produce } from "immer"
import { oneOf } from "../../../utils/arrayHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { StakeCycle } from "../../Stake/types"

export function changeCycleTokenDisplayName(
  cycle: StakeCycle,
  tokenInfoUpdater: (token: TokenInfo) => TokenInfo,
): StakeCycle {
  return new Proxy(cycle, {
    get(
      target: StakeCycle,
      propName: keyof StakeCycle,
    ): StakeCycle[typeof propName] {
      if (oneOf("totalStaked", "myStaked", "principalToClaim")(propName)) {
        return produce(target[propName], (val: StakeCycle[typeof propName]) => {
          ;(Array.isArray(val) ? val : [val]).forEach(val => {
            val.token = tokenInfoUpdater(val.token)
          })
        })
      }

      return target[propName]
    },
  })
}
