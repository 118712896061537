import { FC, useCallback, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Modal } from "../../../../components/Modal"
import { useLaunchPadContentfulStore } from "../../store/useLaunchPadStores"
import { DisclaimerModalContent } from "./DisclaimerModalContent"

const storageKeyPrefix = `Launchpad-WiredDisclaimerModal-accepted-version-`
const currVersion = "1"

export const WiredDisclaimerModal: FC = () => {
  const store = useLaunchPadContentfulStore()
  const [, rerenderComponent] = useState(0)
  const { $t } = useIntl()
  const acceptedVersion = localStorage.getItem(storageKeyPrefix + store.urlSlug)

  const onAccept = useCallback(() => {
    localStorage.setItem(storageKeyPrefix + store.urlSlug, currVersion)
    rerenderComponent(n => n + 1)
  }, [store.urlSlug])

  return (
    <Modal visible={acceptedVersion !== currVersion}>
      <DisclaimerModalContent
        ToSs={[
          {
            name: $t(
              defineMessage({
                defaultMessage:
                  "Terms and Conditions of Use of the ALEX Launchpad",
                description: "/Launchpad/Disclaimer modal content name",
              }),
            ),
            link: "https://terms.alexlab.co/launchpad",
            briefIntro: $t(
              defineMessage({
                defaultMessage: `BY ACCESSING AND/OR USING THE ALEX LAUNCHPAD LOCATED AT **HTTPS://APP.ALEXLAB.CO/LAUNCHPAD** (“INTERFACE” OR “WEBSITE”) IN ANY CAPACITY, REGISTERING FOR, USING AND/OR ACCESSING THE TOOLS AND INFORMATION MADE AVAILABLE VIA THE INTERFACE, USER AGREES THAT: (I) USER HAS READ, UNDERSTOOD AND ACCEPTS THE TERMS AND CONDITIONS OF USE OF THE ALEX LAUNCHPAD ("TERMS"); AND (II) USER SHALL BE BOUND BY THESE TERMS, WHICH SETS FORTH LEGALLY BINDING TERMS AND CONDITIONS BETWEEN USER AND ALEX. DO NOT CONTINUE TO USE THE INTERFACE IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS STATED ON THIS PAGE.`,
                description: "/Launchpad/Disclaimer modal content brief intro",
              }),
            ),
          },
          store.tosItem$,
        ]}
        onAccept={onAccept}
      />
    </Modal>
  )
}
