import React, { ReactNode, Suspense } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { LoadingControllerFactoryProvider } from "./useLoading"
import { useLoadingControllerFactory } from "./useLoadingControllerFactory"

export const GlobalLoadingBoundary: FCC<{
  loadingIndicator: ReactNode
}> = props => {
  const [isSubtreeLoading, ctrlFactory] = useLoadingControllerFactory()

  const loadingJsx = (
    <div className={"fixed inset-0 z-1 bg-gray-400/50 grid place-items-center"}>
      {props.loadingIndicator}
    </div>
  )

  return (
    <LoadingControllerFactoryProvider factory={ctrlFactory}>
      <Suspense fallback={loadingJsx}>{props.children}</Suspense>
      {isSubtreeLoading && loadingJsx}
    </LoadingControllerFactoryProvider>
  )
}
