import { createContext, FC, ReactNode, useContext } from "react"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { CRPSimulatorStore } from "./CRPSimulatorStore"

const CRPSimulatorStoreContext = createContext<CRPSimulatorStore | null>(null)

export const CRPSimulatorStoreProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const store = useCreation(() => new CRPSimulatorStore(), [])
  return (
    <CRPSimulatorStoreContext.Provider value={store}>
      {children}
    </CRPSimulatorStoreContext.Provider>
  )
}

export const useCRPSimulatorStore = (): CRPSimulatorStore => {
  const store = useContext(CRPSimulatorStoreContext)
  if (store == null) {
    throw new Error(
      "useCRPSimulatorStore must be used within a CRPSimulatorStoreProvider",
    )
  }
  return store
}
