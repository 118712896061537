import clsx from "clsx"
import { startCase } from "lodash"
import { FC, ReactNode } from "react"
import { NotifyTransactionStatus } from "../../../types"
import { BaseCellContainer } from "./BaseCellContainer"
import { ReactComponent as ConfirmedIcon } from "./confirmed.svg"
import { ReactComponent as FailedIcon } from "./failed.svg"
import { ReactComponent as PendingIcon } from "./pending.svg"

const iconMap: { [status in NotifyTransactionStatus]: ReactNode } = {
  [NotifyTransactionStatus.Pending]: <PendingIcon />,
  [NotifyTransactionStatus.Confirmed]: <ConfirmedIcon />,
  [NotifyTransactionStatus.Failed]: <FailedIcon />,
}

const classNameMap: { [status in NotifyTransactionStatus]: string } = {
  [NotifyTransactionStatus.Pending]: "",
  [NotifyTransactionStatus.Confirmed]: "text-lime-500",
  [NotifyTransactionStatus.Failed]: "text-red-500",
}

export const StatusCell: FC<{ status: NotifyTransactionStatus }> = ({
  status,
}) => (
  <BaseCellContainer
    className={clsx("flex flex-row items-center gap-x-2", classNameMap[status])}
  >
    {iconMap[status]}
    <span>{startCase(status)}</span>
  </BaseCellContainer>
)
