import clsx from "clsx"
import {
  Children,
  cloneElement,
  FC,
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react"
import { Tooltip } from "../../../components/Tooltip/Tooltip"
import { isElementOfType } from "../../../utils/reactHelpers/isElementOfType"
import { StakeCycleStatus } from "../types"
import { CycleStatusBadge } from "./CycleStatusBadge"

export interface StakeCycleTableProps extends PropsWithChildren<unknown> {
  className?: string
  gapClassName?: string
  divider?: ReactElement
  columnCount: number
}

export const StakeCycleTable: FC<StakeCycleTableProps> = props => {
  const rowArray = Children.toArray(props.children).filter(
    (child): child is ReactElement<StakeCycleTableRowProps> => {
      if (
        !isElementOfType(child, StakeCycleTableRow) &&
        !isElementOfType(child, StakeCycleTableHeaderRow)
      ) {
        throw new Error(
          "`StakeCycleTable` only accepts `StakeCycleTableRow | StakeCycleTableHeaderRow` as children",
        )
      }
      return true
    },
  )

  const children = rowArray.map((child, idx) => {
    return (
      <Fragment key={idx}>
        {idx === 0 || !props.divider
          ? null
          : cloneElement(props.divider, {
              className: clsx(props.divider.props.className, "col-span-full"),
            })}
        {child}
      </Fragment>
    )
  })

  return (
    <div
      className={clsx(
        "grid overflow-x-auto",
        props.className,
        props.gapClassName ?? "gap-4",
      )}
      style={{
        gridTemplateColumns: `repeat(${
          props.columnCount + 1 /* cycle number column */
        }, auto)`,
      }}
    >
      {children}
    </div>
  )
}

export interface StakeCycleTableRowProps extends PropsWithChildren<unknown> {
  cycleNumber: number
  status: StakeCycleStatus
  fromBlock: number
  targetBlock: number
}

export const StakeCycleTableRow: FC<StakeCycleTableRowProps> = props => {
  return (
    <>
      <div className={"flex flex-col gap-1 items-start"}>
        <Tooltip title={`Block ${props.fromBlock} - ${props.targetBlock - 1}`}>
          <h1 className={"text-base leading-6 font-bold text-white"}>
            #{props.cycleNumber}
          </h1>
        </Tooltip>
        <CycleStatusBadge status={props.status} />
      </div>

      {props.children}
    </>
  )
}

export interface StakeCycleTableHeaderRowProps
  extends PropsWithChildren<unknown> {
  titles: ReactNode[]
}

export const StakeCycleTableHeaderRow: FC<
  StakeCycleTableHeaderRowProps
> = props => {
  return (
    <>
      {props.titles.map((title, i) => (
        <div key={i} className={"text-sm leading-5 font-normal text-gray-400"}>
          {title}
        </div>
      ))}
    </>
  )
}
