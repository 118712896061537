import {
  booleanT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const autoalexApowerHelperV2 = defineContract({
  "autoalex-apower-helper-v2": {
    "add-approved-contract": {
      input: [{ name: "new-approved-contract", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "mint-and-burn-apower": {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
        {
          name: "recipients",
          type: listT(tupleT({ amount: numberT, recipient: principalT })),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-approved-contract": {
      input: [
        { name: "owner", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "is-cycle-batch-processed": {
      input: [
        { name: "cycle", type: numberT },
        { name: "batch", type: numberT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "approved-contracts": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "processed-batches": {
      input: tupleT({ batch: numberT, cycle: numberT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
  },
} as const)
