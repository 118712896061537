import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { LotteryPageStore } from "./LotteryPageStore"

const Store = createStore<LotteryPageStore>("LotteryPageStore")

export const LotteryPageStoreProvider: FCC = props => {
  const chain = useChainStore()
  const auth = useAuthStore()
  const account = useAccountStore()
  const store = useCreation(
    () => new LotteryPageStore(chain, auth, account),
    [chain, auth, account],
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useLotteryPageStore = Store.useStore.bind(null)
