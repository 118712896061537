import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../utils/types"
import { StxDxOrderType } from "../../../Orderbook/components/types"
import { OrderbookMarket } from "../../../Orderbook/store/stxdx_shared/StxDxMarket.service"
import { OrderDirection, TradingFormWarningType } from "../../components/types"

export const convertSubOrderDirection = (
  side: OrderDirection,
): "buy" | "sell" => {
  switch (side) {
    case OrderDirection.Long:
      return "buy"
    case OrderDirection.Short:
      return "sell"
    default:
      assertNever(side)
  }
}

export type PerpetualTradeFormData = {
  warning?: { type: TradingFormWarningType }
  side: OrderDirection
  orderType: StxDxOrderType
  outgoingToken: TokenInfo
  incomingToken: TokenInfo
  market: OrderbookMarket
  stxAddress: string
  currentHeight: number
  currentUserId: number
  currentUserAuth: string
  currentPrice: number
  price: number
  stopPrice?: number
  size: number
  senderFeeRate: number
}

export async function tradeInStxDx(
  data: PerpetualTradeFormData,
): Promise<void> {
  throw new Error("WIP")
  // const size = round(data.size, 8 - data.market.pricePrecision)
  // const res = await sendRequest(data.currentUserAuth)(
  //   "PerpetualController_previewPerpetualOrder",
  //   {
  //     body: {
  //       type: "vanilla",
  //       market: data.market.marketId as any,
  //       maker: String(data.currentUserId),
  //       expiration_height: String(1e8),
  //       sender_fee: String(data.senderFeeRate * 1e8),
  //       side: "buy",
  //       size: String(900),
  //       risk: false,
  //       price: String(20000),
  //       leverage: String(10),
  //       timestamp: String(BigInt(Date.now())),
  //       stop_price: "100.00",
  //       salt: "2",
  //     },
  //   },
  // )
}
