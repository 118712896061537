import { action } from "mobx"
import { FC } from "react"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { Currency } from "../../../utils/alexjs/Currency"
import { useAutoStakeStore } from "../store/autoStaking/useAutoStakeStore"
import { ConfirmStakeModalContent } from "./ConfirmStakeModalContent/ConfirmStakeModalContent"

export const WiredAddStakingConfirmation: FC = () => {
  const store = useAutoStakeStore()
  const currency = useCurrencyStore()
  return (
    <>
      <Modal
        visible={!!store.confirmingStakeData}
        onClose={action(() => (store.confirmingStakeData = undefined))}
      >
        <Spensor>
          {() => (
            <ConfirmStakeModalContent
              alexToken={currency.getTokenInfo$(Currency.ALEX)}
              autoAlexToken={currency.getTokenInfo$(Currency.ATALEXV2)}
              alexTokenCount={store.confirmingStakeData?.alexAmount ?? 0}
              autoAlexTokenCount={store.getAmountToStakeInAtAlex$(
                store.confirmingStakeData?.alexAmount ?? 0,
              )}
              stakePrice={suspenseResource(() => store.intrinsic$)}
              startStakeCycleNumber={store.currentCycle$}
              onConfirm={async () => {
                if (!store.confirmingStakeData) return
                await store.addStake(store.confirmingStakeData)
              }}
              onClose={action(() => (store.confirmingStakeData = undefined))}
            />
          )}
        </Spensor>
      </Modal>
      <WiredTransactionStateDialog store={store.addStakeTx} />
    </>
  )
}
