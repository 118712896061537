import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"

export const IDOProgramDescribe: FC = () => {
  const { $t } = useIntl()
  return (
    <p className={"text-base leading-6 font-normal text-gray-200"}>
      {$t(
        defineMessage({
          defaultMessage: "Raised through hybrid On-chain/Off-chain lottery",
          description: "/Launchpad/IDO program describe",
        }),
      )}
    </p>
  )
}
