import { defineMessages } from "react-intl"

export const formFields$t = defineMessages({
  available: {
    defaultMessage: "Available: ",
    description: "Perpetual/Create Order Panel/field label",
  },
  limitPrice: {
    defaultMessage: "Limit Price",
    description: "Perpetual/Create Order Panel/field label",
  },
  stopPrice: {
    defaultMessage: "Stop Price",
    description: "Perpetual/Create Order Panel/field label",
  },
  price: {
    defaultMessage: "Price",
    description: "Perpetual/Create Order Panel/field label",
  },
  amount: {
    defaultMessage: "Amount",
    description: "Perpetual/Create Order Panel/field label",
  },
  total: {
    defaultMessage: "Total",
    description: "Perpetual/Create Order Panel/field label",
  },
  longPositionSize: {
    defaultMessage: "Buy",
    description:
      "Perpetual/Create Order Panel/field label for long position size",
  },
  shortPositionSize: {
    defaultMessage: "Sell",
    description:
      "Perpetual/Create Order Panel/field label for short position size",
  },
  cost: {
    defaultMessage: "Cost",
    description: "Perpetual/Create Order Panel/field label",
  },
  max: {
    defaultMessage: "Max",
    description: "Perpetual/Create Order Panel/field label",
  },
})
