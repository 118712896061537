import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { CardModalContent } from "../CardModal/CardModal"
import { ReactComponent as DisableIcon } from "./disableIcon.svg"

const CustomInput: FC<{
  isSelected: boolean
  isValid: boolean
  value: string
  onInputValueChanged?: (newValue: string) => void
}> = props => {
  const { $t } = useIntl()
  return (
    <div
      className={clsx(
        "flex gap-2.5 justify-center items-center w-full h-12 px-4 rounded-lg",
        props.isSelected
          ? "border-blue-500 border-2"
          : "border-gray-700 border",
        !props.isValid ? "border-red-500" : null,
      )}
    >
      <p className="text-xl dark:text-gray-200 font-medium">
        {$t(
          defineMessage({
            defaultMessage: "Customize",
            description: "/Components/SlippageModalContent/Custom label",
          }),
        )}
      </p>
      <input
        placeholder="0.00"
        type="number"
        inputMode="decimal"
        min={0}
        max={100}
        value={props.value}
        onChange={e => props.onInputValueChanged?.(e.target.value)}
        className={
          "flex-1 w-full content-center justify-end items-center text-gray-200 text-right text-3xl font-medium bg-transparent outline-none spin-button-none"
        }
      />
      <div className="flex justify-end items-center text-3xl font-medium text-gray-200">
        %
      </div>
    </div>
  )
}

const DefaultInput: FC<{
  defaultValue: number
  isSelected: boolean
  onSelected?: () => void
}> = slippagePercent => {
  const { $t } = useIntl()
  return (
    <div
      className={clsx(
        "flex justify-center w-full h-12 px-4 py-6  rounded-lg",
        slippagePercent.isSelected
          ? "border-blue-500 border-2"
          : "border-gray-700 border",
      )}
      onClick={slippagePercent.onSelected}
    >
      <div className="flex flex-col justify-center">
        <p className="text-xl font-medium dark:text-gray-200">
          {$t(
            defineMessage({
              defaultMessage: "Recommended",
              description: "/Components/SlippageModalContent/Recommended label",
            }),
          )}
        </p>
        <p className="text-xs dark:text-gray-500">
          {$t(
            defineMessage({
              defaultMessage: "90% of users select this setting",
              description:
                "/Components/SlippageModalContent/Recommended description",
            }),
          )}
        </p>
      </div>
      <div className="flex flex-grow content-center justify-end items-center text-3xl font-medium dark:text-gray-200">
        {slippagePercent.defaultValue}
        <span className="ml-2.5">%</span>
      </div>
    </div>
  )
}

export const SlippageModalContent: FC<{
  isDefault: boolean
  isMinuteValid: boolean
  isInputValid: boolean

  defaultValue: number
  inputValue: string
  onInputValueChanged?: (newValue: string) => void

  onResetToDefault?: () => void
  onConfirmed?: () => void
  onClose?: () => void
}> = props => {
  const buttonDisable = !props.isInputValid || !props.isMinuteValid
  const { $t } = useIntl()
  return (
    <CardModalContent
      className="flex flex-col align-middle gap-3"
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Transaction Settings",
          description: "/Components/SlippageModalContent/Title",
        }),
      )}
      onClose={props.onClose}
    >
      <div className="flex flex-col gap-3">
        <p className="text-xs text-gray-400 text-sm sm:text-base">
          {$t(
            defineMessage({
              defaultMessage: "Slippage Tolerance",
              description:
                "/Components/SlippageModalContent/Slippage Tolerance label",
            }),
          )}
        </p>
        <DefaultInput
          isSelected={props.isDefault}
          defaultValue={props.defaultValue}
          onSelected={props.onResetToDefault}
        />
        <CustomInput
          isSelected={!props.isDefault}
          isValid={props.isInputValid}
          value={props.inputValue}
          onInputValueChanged={props.onInputValueChanged}
        />
        {!props.isInputValid && (
          <p className="flex text-xs text-red-500 mb-2.5">
            <DisableIcon />
            <span className="ml-2.5">
              {$t(
                defineMessage({
                  defaultMessage: "Enter a valid slippage percentage",
                  description:
                    "/Components/SlippageModalContent/validation message",
                }),
              )}
            </span>
          </p>
        )}
        <GradientFilledButton
          className="flex items-center justify-center"
          disabled={buttonDisable}
          onClick={props.onConfirmed}
        >
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Components/SlippageModalContent/Confirm button",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
