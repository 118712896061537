import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { InfoList } from "../../../../../components/InfoList/InfoList"
import { InfoListItem } from "../../../../../components/InfoList/InfoListItem"
import { InfoListItemDetail } from "../../../../../components/InfoList/InfoListItemDetail"
import { InfoListItemTitle } from "../../../../../components/InfoList/InfoListItemTitle"
import { NavLink } from "../../../../../components/NavLink"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { SegmentControl } from "../../../../../components/SegmentControl"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { Block } from "../../../../../components/TokenInput/Block"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "../../../../../components/TokenInput/BlockInputContainer"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenNameSelectTrigger } from "../../../../../components/TokenInput/TokenNameSelect"
import { SmartLoadableButton } from "../../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { checkNever } from "../../../../../utils/types"
import { RectButton } from "../../wrappedCommonComponents/RectButton"
import {
  DepositBrc20SubmitTransactionIdModalFormError,
  DepositBrc20SubmitTransactionIdModalFormErrorType,
} from "./DepositBrc20SubmitTransactionIdModalContent.types"
import __Hiro_inscription_id_png from "./Hiro_inscription_id.png"
import __UniSat_inscription_id_png from "./UniSat_inscription_id.png"
import __Xverse_inscription_id_png from "./Xverse_inscription_id.png"

export const DepositBrc20SubmitTransactionIdModalContent: FC<{
  className?: string
  selectedToken: SuspenseResource<TokenInfo>
  onChangeSelectedToken: () => void
  amount: null | number
  onChangeAmount: (amount: null | number) => void
  inscriptionId: null | string
  onChangeInscriptionId: (txId: null | string) => void
  pegInFeeToken: SuspenseResource<TokenInfo>
  pegInFeeRate: SuspenseResource<number>
  pegInFeeAmount: SuspenseResource<number>
  error?: SuspenseResource<
    undefined | DepositBrc20SubmitTransactionIdModalFormError
  >
  onSubmit: () => void | Promise<void>
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  const error = safeReadResource(props.error)

  const [transferApproach, setTransferApproach] = useState<
    "unisat" | "xverse" | "hiro"
  >("unisat")

  return (
    <CardModalContent
      className={clsx("flex flex-col gap-4", props.className)}
      bgClassName="bg-gray-800"
      width={640}
      title={$t(
        defineMessage({
          defaultMessage: "Submit inscription ID",
          description: "/OrderBook/BRC20/DepositModal/Title text",
        }),
      )}
      onClose={props.onClose}
    >
      <TokenInput
        error={
          error?.type ===
            DepositBrc20SubmitTransactionIdModalFormErrorType.AmountIsRequired ||
          error?.type ===
            DepositBrc20SubmitTransactionIdModalFormErrorType.TokenIsRequired
        }
        renderBlock={p => <Block {...p} boxClassName={"p-4"} />}
        tokenNameArea={
          <TokenNameSelectTrigger
            token={safeReadResource(props.selectedToken)}
            onClick={props.onChangeSelectedToken}
          />
        }
        token={props.selectedToken}
        value={props.amount}
        onChange={props.onChangeAmount}
      />

      <div className={"flex flex-col gap-y-2"}>
        <p className="text-gray-200 text-xs">
          {$t(
            defineMessage({
              defaultMessage: "Peg-in Inscription",
              description:
                "/OrderBook/BRC20 Peg Flow/submit transaction record modal/transaction id input label",
            }),
          )}
        </p>

        <SegmentControl
          className={"w-full"}
          boxClassName={"p-1"}
          segmentBoxClassName={"flex-1 min-h-[32px]"}
          segmentTextClassName={"text-sm leading-5 font-medium capitalize"}
          controls={[
            {
              title: $t(
                defineMessage({
                  defaultMessage: `from Unisat`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                }),
              ),
              isActive: transferApproach === "unisat",
              onClick: () => setTransferApproach("unisat"),
            },
            {
              title: $t(
                defineMessage({
                  defaultMessage: `from Xverse`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                }),
              ),
              isActive: transferApproach === "xverse",
              onClick: () => setTransferApproach("xverse"),
            },
            {
              title: $t(
                defineMessage({
                  defaultMessage: `from Hiro`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                }),
              ),
              isActive: transferApproach === "hiro",
              onClick: () => setTransferApproach("hiro"),
            },
          ]}
        />

        <div className={"flex flex-row gap-x-2.5"}>
          {/*<img*/}
          {/*  src={transferApproach === "unisat" ? UniSetIconSrc : XverseIconSrc}*/}
          {/*  className={"w-[56px] h-[56px]"}*/}
          {/*/>*/}

          <BlockInputContainer
            boxClassName={"flex-1 px-4 py-3"}
            error={
              error?.type ===
                DepositBrc20SubmitTransactionIdModalFormErrorType.InscriptionNotTransferable ||
              error?.type ===
                DepositBrc20SubmitTransactionIdModalFormErrorType.InscriptionNumberIsRequired
            }
          >
            {p => (
              <input
                ref={p.inputRef}
                type="number"
                placeholder={$t(
                  defineMessage({
                    defaultMessage: "Enter the transfer Inscription ID",
                    description:
                      "/OrderBook/BRC20 Peg Flow/submit transaction record modal/transaction id input placeholder",
                  }),
                )}
                className={defaultInputClassNames()}
                value={props.inscriptionId ?? ""}
                onChange={e =>
                  props.onChangeInscriptionId(e.target.value || null)
                }
              />
            )}
          </BlockInputContainer>
        </div>

        {transferApproach === "unisat" && (
          <>
            <img
              className={"aspect-[590/215]"}
              src={__UniSat_inscription_id_png}
              alt="UniSat Guide"
            />
            <NavLink
              className={"text-blue-600 flex flex-row items-center"}
              to={
                "https://perfect-bulb-261.notion.site/Inscribe-Transfer-Inscription-eeb3ac045bfe4eb591046e4a66aac862"
              }
            >
              {$t(
                defineMessage({
                  defaultMessage: "How to Peg-in from UniSat? {linkIcon}",
                  description:
                    "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                }),
                {
                  linkIcon: (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#6B7280"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.76562 3.99896C1.76562 2.76552 2.76552 1.76562 3.99896 1.76562H6.66562C7.16268 1.76562 7.56562 2.16857 7.56562 2.66562C7.56562 3.16268 7.16268 3.56562 6.66562 3.56562H3.99896C3.75963 3.56562 3.56562 3.75963 3.56562 3.99896V11.999C3.56562 12.2383 3.75963 12.4323 3.99896 12.4323H11.999C12.2383 12.4323 12.4323 12.2383 12.4323 11.999V9.33229C12.4323 8.83524 12.8352 8.43229 13.3323 8.43229C13.8293 8.43229 14.2323 8.83524 14.2323 9.33229V11.999C14.2323 13.2324 13.2324 14.2323 11.999 14.2323H3.99896C2.76553 14.2323 1.76562 13.2324 1.76562 11.999V3.99896ZM9.09896 2.66562C9.09896 2.16857 9.5019 1.76562 9.99896 1.76562H13.3323C13.8293 1.76562 14.2323 2.16857 14.2323 2.66562V5.99896C14.2323 6.49601 13.8293 6.89896 13.3323 6.89896C12.8352 6.89896 12.4323 6.49601 12.4323 5.99896V4.83842L7.96869 9.30202C7.61722 9.65349 7.04737 9.65349 6.6959 9.30202C6.34442 8.95055 6.34442 8.3807 6.6959 8.02923L11.1595 3.56562H9.99896C9.5019 3.56562 9.09896 3.16268 9.09896 2.66562Z"
                      />
                    </svg>
                  ),
                },
              )}
            </NavLink>
          </>
        )}
        {transferApproach === "xverse" && (
          <>
            <img
              className={"aspect-[590/215]"}
              src={__Xverse_inscription_id_png}
              alt="Xverse Guide"
            />
            <NavLink
              className={"text-blue-600 flex flex-row items-center"}
              to={
                "https://perfect-bulb-261.notion.site/Guide-to-Creating-a-Transfer-Inscription-with-Xverse-Wallet-72436a00f8f042c593149b1440956e15"
              }
            >
              {$t(
                defineMessage({
                  defaultMessage: "How to Peg-in from Xverse? {linkIcon}",
                  description:
                    "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                }),
                {
                  linkIcon: (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#6B7280"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.76562 3.99896C1.76562 2.76552 2.76552 1.76562 3.99896 1.76562H6.66562C7.16268 1.76562 7.56562 2.16857 7.56562 2.66562C7.56562 3.16268 7.16268 3.56562 6.66562 3.56562H3.99896C3.75963 3.56562 3.56562 3.75963 3.56562 3.99896V11.999C3.56562 12.2383 3.75963 12.4323 3.99896 12.4323H11.999C12.2383 12.4323 12.4323 12.2383 12.4323 11.999V9.33229C12.4323 8.83524 12.8352 8.43229 13.3323 8.43229C13.8293 8.43229 14.2323 8.83524 14.2323 9.33229V11.999C14.2323 13.2324 13.2324 14.2323 11.999 14.2323H3.99896C2.76553 14.2323 1.76562 13.2324 1.76562 11.999V3.99896ZM9.09896 2.66562C9.09896 2.16857 9.5019 1.76562 9.99896 1.76562H13.3323C13.8293 1.76562 14.2323 2.16857 14.2323 2.66562V5.99896C14.2323 6.49601 13.8293 6.89896 13.3323 6.89896C12.8352 6.89896 12.4323 6.49601 12.4323 5.99896V4.83842L7.96869 9.30202C7.61722 9.65349 7.04737 9.65349 6.6959 9.30202C6.34442 8.95055 6.34442 8.3807 6.6959 8.02923L11.1595 3.56562H9.99896C9.5019 3.56562 9.09896 3.16268 9.09896 2.66562Z"
                      />
                    </svg>
                  ),
                },
              )}
            </NavLink>
          </>
        )}
        {transferApproach === "hiro" && (
          <>
            <img
              className={"aspect-[590/215]"}
              src={__Hiro_inscription_id_png}
              alt="Hiro Guide"
            />
            <NavLink
              className={"text-blue-600 flex flex-row items-center"}
              to={
                "https://hirowallet.gitbook.io/guides/bitcoin/fast-and-easy-brc-20-inscription-guide-with-hiro-wallet#heres-how-to-make-a-transfer-inscription-available-on-hiro-wallet-v4.28-onwards"
              }
            >
              {$t(
                defineMessage({
                  defaultMessage: "How to Peg-in from Hiro Wallet? {linkIcon}",
                  description:
                    "/OrderBook/BRC20 Peg Flow/Add new transaction text",
                }),
                {
                  linkIcon: (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#6B7280"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.76562 3.99896C1.76562 2.76552 2.76552 1.76562 3.99896 1.76562H6.66562C7.16268 1.76562 7.56562 2.16857 7.56562 2.66562C7.56562 3.16268 7.16268 3.56562 6.66562 3.56562H3.99896C3.75963 3.56562 3.56562 3.75963 3.56562 3.99896V11.999C3.56562 12.2383 3.75963 12.4323 3.99896 12.4323H11.999C12.2383 12.4323 12.4323 12.2383 12.4323 11.999V9.33229C12.4323 8.83524 12.8352 8.43229 13.3323 8.43229C13.8293 8.43229 14.2323 8.83524 14.2323 9.33229V11.999C14.2323 13.2324 13.2324 14.2323 11.999 14.2323H3.99896C2.76553 14.2323 1.76562 13.2324 1.76562 11.999V3.99896ZM9.09896 2.66562C9.09896 2.16857 9.5019 1.76562 9.99896 1.76562H13.3323C13.8293 1.76562 14.2323 2.16857 14.2323 2.66562V5.99896C14.2323 6.49601 13.8293 6.89896 13.3323 6.89896C12.8352 6.89896 12.4323 6.49601 12.4323 5.99896V4.83842L7.96869 9.30202C7.61722 9.65349 7.04737 9.65349 6.6959 9.30202C6.34442 8.95055 6.34442 8.3807 6.6959 8.02923L11.1595 3.56562H9.99896C9.5019 3.56562 9.09896 3.16268 9.09896 2.66562Z"
                      />
                    </svg>
                  ),
                },
              )}
            </NavLink>
          </>
        )}
      </div>

      <CardInset boxClassName={"p-2.5"}>
        <InfoList>
          <InfoListItem>
            <InfoListItemTitle className="flex items-center">
              <span className="underline">
                {$t(
                  defineMessage({
                    defaultMessage: "Fees",
                    description:
                      "/OrderBook/BRC20/PegOutBrc20ModalContent/DetailSection/label",
                  }),
                )}
              </span>
              &nbsp;
              <TipIcon
                tip={$t(
                  defineMessage({
                    defaultMessage: "Peg in fees: {feeRate}",
                    description:
                      '/OrderBook/BRC20/PegOutBrc20ModalContent/DetailSection/"Fees" label tip text',
                  }),
                  {
                    feeRate: <PercentNumber number={props.pegInFeeRate} />,
                  },
                )}
              />
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TextTokenCount
                token={props.pegInFeeToken}
                count={props.pegInFeeAmount}
              />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardInset>

      <CardInset boxClassName={"p-2.5"}>
        <NoteParagraph
          icon={<WarnIcon />}
          textColorClassName={"text-yellow-500"}
        >
          {$t(
            defineMessage({
              defaultMessage:
                'Please submit your "transfer inscription ID" and will generate your peg-in address for you to peg-in BRC-20.',
              description:
                "/OrderBook/BRC20 Peg Flow/submit transaction record modal/notice text",
            }),
          )}
        </NoteParagraph>
      </CardInset>

      <SmartLoadableButton
        disabled={error !== undefined}
        renderVariant={p => (
          <RectButton {...p} Variant={GradientFilledButton} />
        )}
        onClick={props.onSubmit}
      >
        {error?.type ===
        DepositBrc20SubmitTransactionIdModalFormErrorType.InscriptionNumberIsRequired
          ? $t(
              defineMessage({
                defaultMessage: "Transfer inscription ID is required",
                description: "/OrderBook/BRC20/DepositModal/Submit button text",
              }),
            )
          : error?.type ===
            DepositBrc20SubmitTransactionIdModalFormErrorType.InscriptionNotTransferable
          ? $t(
              defineMessage({
                defaultMessage: "Token is not transferable",
                description: "/OrderBook/BRC20/DepositModal/Submit button text",
              }),
            )
          : error?.type ===
            DepositBrc20SubmitTransactionIdModalFormErrorType.AmountIsRequired
          ? $t(
              defineMessage({
                defaultMessage: "Amount is required",
                description: "/OrderBook/BRC20/DepositModal/Submit button text",
              }),
            )
          : error?.type ===
            DepositBrc20SubmitTransactionIdModalFormErrorType.TokenIsRequired
          ? $t(
              defineMessage({
                defaultMessage: "Token is required",
                description: "/OrderBook/BRC20/DepositModal/Submit button text",
              }),
            )
          : (error == null || checkNever(error.type),
            $t(
              defineMessage({
                defaultMessage: "Submit “transfer inscription ID”",
                description: "/OrderBook/BRC20/DepositModal/Submit button text",
              }),
            ))}
      </SmartLoadableButton>
    </CardModalContent>
  )
}
