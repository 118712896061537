import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { DefaultTokenNameArea } from "../../../components/TokenInput/Block"
import { useMessage } from "../../../components/message/MessageProvider"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { alertError, getErrorMessage } from "../../../utils/error"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { WiredChainConnector } from "./WiredChainConnector"
import {
  WrapBridgeNetworkComponent,
  WrapBridgeNetworkSelectComponent,
} from "./WrapBridgePanel/WrapBridgeNetwork/WrapBridgeNetworkComponent"
import {
  SideFrame,
  ToInput,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"

export const WiredToForm: FC = () => {
  const { $t } = useIntl()
  const store = useWrapBridgeStore()
  const message = useMessage()

  return (
    <SideFrame
      network={
        store.wrapForm.direction === "wrap" ? (
          <WrapBridgeNetworkComponent
            network={store.wrapFormViewModule.toNetwork}
          />
        ) : (
          <WrapBridgeNetworkSelectComponent
            network={store.wrapFormViewModule.toNetwork}
            availableNetworks={store.wrapFormViewModule.availableETHNetworks$}
            onChange={network =>
              store.wrapFormViewModule.switchToETHNetwork(network).catch(
                alertError(message, err =>
                  $t(
                    defineMessage({
                      defaultMessage:
                        "Switch chain failed{errorMessage, select, empty {} other {: {errorMessage}}}",
                      description: `/WrapBridge/"to" panel/switch chain failed`,
                    }),
                    {
                      errorMessage: getErrorMessage(err) ?? "empty",
                    },
                  ),
                ),
              )
            }
          />
        )
      }
      address={<WiredChainConnector chain={store.wrapForm.toChain} />}
    >
      <ToInput
        token={suspenseResource(() => store.wrapFormViewModule.toToken$)}
        value={suspenseResource(() => store.wrapForm.toTokenCount$)}
        tokenNameArea={
          <DefaultTokenNameArea
            token={suspenseResource(() => store.wrapFormViewModule.toToken$)}
          />
        }
        usdCount={suspenseResource(
          () => store.wrapFormViewModule.toTokenCountToUSD$,
        )}
      />
    </SideFrame>
  )
}
