import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { GrayBadge, GreenBadge } from "../../components/Badge"
import { Card } from "../../components/Card"
import { CollapsableCard } from "../../components/CollapsableCard/CollapsableCard"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { NoteParagraph } from "../../components/NoteParagraph/NoteParagraph"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { SecondaryPageTitle } from "../../components/PageStack/SecondaryPageTitle"
import { Spensor } from "../../components/Spensor"
import { roundDownTokenCount } from "../../components/TokenCount"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { usePathGenerator } from "../../routes/routes"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { suspenseResource } from "../../utils/SuspenseResource"
import { poolMigration } from "../../utils/alexjs/PoolMigrations"
import { LiquidityPoolToken } from "../../utils/alexjs/currencyHelpers"
import PoolTokenName from "../Farm/components/PoolTokenName"
import AMMPoolStatsInfo from "./components/AMMPoolStatsInfo"
import { WiredChangeLiquiditySection } from "./components/ChangeLiquiditySection/ChangeLiquiditySection"
import MyLiquidityRow from "./components/MyLiquidityRow"
import PoolStatsInfo from "./components/PoolStatsInfo"
import {
  PoolDetailStoreProvider,
  usePoolDetailStore,
} from "./store/detail/usePoolDetailStore"

const WiredMyLiquidity: FC<{ className?: string }> = props => {
  const store = usePoolDetailStore()
  const authStore = useAuthStore()
  const [connect] = useConnect()
  const currency = useCurrencyStore()
  const { $t } = useIntl()

  return (
    <Spensor
      fallback={
        <CollapsableCard
          title={$t(
            defineMessage({
              defaultMessage: "My Liquidity",
              description: "/Pool/MyLiquidity/Card title",
            }),
          )}
          className={props.className}
        >
          {authStore.isWalletConnected ? (
            <LoadingIndicator className="mx-auto" />
          ) : (
            <GradientFilledButton className="mx-auto w-48" onClick={connect}>
              {$t(
                defineMessage({
                  defaultMessage: "Connect Wallet",
                  description: "/Pool/MyLiquidity/Button text",
                }),
              )}
            </GradientFilledButton>
          )}
        </CollapsableCard>
      }
    >
      {() => (
        <CollapsableCard
          title={$t(
            defineMessage({
              defaultMessage: "My Liquidity",
              description: "/Pool/MyLiquidity/Card title",
            }),
          )}
          className={props.className}
          defaultCollapsed={store.pooledAmount$ === 0}
        >
          <div className={"w-full overflow-x-auto"}>
            <div className={"min-w-[500px]"}>
              <MyLiquidityRow
                poolToken={currency.getTokenInfo$(store.poolToken)}
                balance={store.pooledAmount$}
                share={suspenseResource(() => store.myLiquidity.share$)}
                tokenA={{
                  token: currency.getTokenInfo$(store.myLiquidity.tokenA),
                  balance: store.myLiquidity.pooledTokenAmount$(
                    store.myLiquidity.tokenA,
                  ),
                }}
                tokenB={{
                  token: currency.getTokenInfo$(store.myLiquidity.tokenB),
                  balance: store.myLiquidity.pooledTokenAmount$(
                    store.myLiquidity.tokenB,
                  ),
                }}
                indicativeValue={suspenseResource(() =>
                  roundDownTokenCount(
                    store.pooledAmount$ * currency.getPrice$(store.poolToken),
                    2,
                  ),
                )}
              />
            </div>
          </div>
          <NoteParagraph className={"mt-5"}>
            {$t(
              defineMessage({
                defaultMessage:
                  "If you have staked your LP tokens in a yield farm, they must first be unstaked to be shown here.",
                description: "/Pool/MyLiquidity/Note",
              }),
            )}
          </NoteParagraph>
        </CollapsableCard>
      )}
    </Spensor>
  )
}

const WiredPoolStatsInfo: FC<{ className?: string }> = props => {
  const store = usePoolDetailStore()
  const currency = useCurrencyStore()
  return (
    <Spensor
      fallback={
        <Card
          className={clsx("flex items-center justify-center", props.className)}
        >
          <LoadingIndicator />
        </Card>
      }
    >
      {() => {
        return store.ammPoolId$ != null ? (
          <AMMPoolStatsInfo
            className={props.className}
            {...store.info$}
            tokenX={currency.getTokenInfo$(store.addLiquidity.tokenA)}
            tokenY={currency.getTokenInfo$(store.addLiquidity.tokenB)}
            factor={suspenseResource(() => store.ammPoolExtraInfo$.factor)}
            ammFees={suspenseResource(() => store.ammPoolExtraInfo$.fees)}
            tokenXAmount={suspenseResource(
              () => store.ammPoolExtraInfo$.xBalance,
            )}
            tokenYAmount={suspenseResource(
              () => store.ammPoolExtraInfo$.yBalance,
            )}
            totalXYInUSD={suspenseResource(
              () => store.ammPoolExtraInfo$.totalValueInUSD,
            )}
            tokenXPercentage={suspenseResource(
              () => store.ammPoolExtraInfo$.xPercentage,
            )}
            tokenYPercentage={suspenseResource(
              () => store.ammPoolExtraInfo$.yPercentage,
            )}
            ammFeeRateRate={suspenseResource(
              () => store.ammPoolExtraInfo$.feeRebate,
            )}
          />
        ) : (
          <PoolStatsInfo
            className={props.className}
            {...store.info$}
            tokenX={currency.getTokenInfo$(store.addLiquidity.tokenA)}
            tokenY={currency.getTokenInfo$(store.addLiquidity.tokenB)}
          />
        )
      }}
    </Spensor>
  )
}

const PageContent: FC = () => (
  <div className={"grid grid-cols-24 gap-y-8 lg:gap-8"}>
    <WiredMyLiquidity className="col-span-full" />
    <WiredChangeLiquiditySection className="col-span-full lg:col-span-13" />
    <WiredPoolStatsInfo className="col-span-full lg:col-span-11" />
  </div>
)

const PoolDetailPage: FC = () => {
  const gen = usePathGenerator()
  const poolToken = useParams<"token">().token as LiquidityPoolToken
  const currency = useCurrencyStore()
  const { $t } = useIntl()

  return (
    <PoolDetailStoreProvider token={poolToken}>
      <PageStackPage
        title={
          <div className="flex items-center gap-x-2">
            <SecondaryPageTitle
              returnFallbackURL={gen.poolList()}
              className="items-center"
            >
              {$t(
                defineMessage({
                  defaultMessage: "LP: {token}",
                  description: "/Pool/PoolDetail/Page title",
                }),
                {
                  token: (
                    <PoolTokenName token={currency.getTokenInfo$(poolToken)} />
                  ),
                },
              )}
            </SecondaryPageTitle>
            {poolToken in poolMigration ? (
              <GrayBadge>Migrating</GrayBadge>
            ) : (
              <GreenBadge>Main</GreenBadge>
            )}
          </div>
        }
      >
        <PageContent />
      </PageStackPage>
    </PoolDetailStoreProvider>
  )
}

export default PoolDetailPage
