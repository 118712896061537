import clsx from "clsx"
import { CSSProperties, FC, useContext } from "react"
import { PropsWithChildren } from "../../utils/reactHelpers/types"
import { InfoListDirectionContext } from "./_/InfoListDirectionContext"
import { InfoListRenderersContext } from "./_/InfoListRenderersContext"

export interface InfoListItemDetailProps extends PropsWithChildren<unknown> {
  className?: string
  style?: CSSProperties

  /**
   * will be `left` when `ListItem` direction is `column`, and be `right` when
   * direction is `row`
   */
  alignSelf?: "left" | "right"
}

export const InfoListItemDetail: FC<InfoListItemDetailProps> = props => {
  const { renderInfoListItemDetail } = useContext(InfoListRenderersContext)

  if (renderInfoListItemDetail) {
    return <>{renderInfoListItemDetail(props)}</>
  } else {
    return <DefaultInfoListItemDetail {...props} />
  }
}

export interface DefaultInfoListItemDetailProps
  extends PropsWithChildren<unknown> {
  className?: string
  textClassName?: string
  style?: CSSProperties
  /**
   * @default true
   */
  noWrap?: boolean
  /**
   * will be `left` when `ListItem` direction is `column`, and be `right` when
   * direction is `row`
   */
  alignSelf?: "left" | "right"
}

export const DefaultInfoListItemDetail: FC<
  DefaultInfoListItemDetailProps
> = props => {
  const { listItemDirection } = useContext(InfoListDirectionContext)

  const alignSelf =
    props.alignSelf ?? listItemDirection === "column" ? "left" : "right"

  return (
    <dd
      className={clsx(
        (props.noWrap ?? true) && "whitespace-pre",
        props.className,
        alignSelf === "right" && "ml-auto",
        props.textClassName ?? "text-base text-gray-200",
      )}
      style={{
        ...props.style,
        ...(alignSelf === "right" && { marginLeft: "auto" }),
      }}
    >
      {props.children}
    </dd>
  )
}
