export enum AddLiquidityFormErrorType {
  EmptyTokenCount = "EmptyTokenCount",
  InsufficientTokenBalance = "InsufficientTokenBalance",
  LoadingDxDy = "LoadingDxDy",
}

export type AddLiquidityFormError =
  | {
      type: AddLiquidityFormErrorType.InsufficientTokenBalance
      tokenA: boolean
      tokenB: boolean
    }
  | {
      type:
        | AddLiquidityFormErrorType.EmptyTokenCount
        | AddLiquidityFormErrorType.LoadingDxDy
    }

export enum RemoveLiquidityFormErrorType {
  EmptyTokenCount = "EmptyTokenCount",
  InsufficientTokenBalance = "InsufficientTokenBalance",
  LoadingDxDy = "LoadingDxDy",
}

export type RemoveLiquidityFormError = {
  type:
    | RemoveLiquidityFormErrorType.InsufficientTokenBalance
    | RemoveLiquidityFormErrorType.EmptyTokenCount
    | RemoveLiquidityFormErrorType.LoadingDxDy
}
