import { collectionFilters } from "./store/TransactionsModule"

export const FLOAT_RATIO = 1e8
export const TO_PREFIX = "→"
export const TRANSACTION_COLUMNS = [
  "type",
  "status",
  "currency",
  "amount",
  "tradeInfo",
  "time",
] as const
export const EXPORTER_COLUMNS = [
  "Transaction Id",
  "Type",
  "Status",
  "Amount",
  "Trade Info",
  "Time",
]
export const ALL_TRANSACTIONS_FILTER = "allTransactions"
export const DEFAULT_FILTER = ALL_TRANSACTIONS_FILTER

export const PAGE_SIZE = 20

export const TRANSACTION_FILTERS = [
  ALL_TRANSACTIONS_FILTER,
  ...collectionFilters,
] as const
export const SYSTEM_MESSAGE_FILTER = "systemMessages" as const
export const NOTIFY_FILTERS = [
  ...TRANSACTION_FILTERS,
  SYSTEM_MESSAGE_FILTER,
] as const
