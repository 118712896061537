import { trimEnd } from "lodash"
import { FC, Suspense, useDebugValue } from "react"
import { SuspenseResource, readResource } from "../utils/SuspenseResource"

export interface CountNumberProps extends CountNumberFormatOptions {
  number: SuspenseResource<number>
}

export const CountNumber: FC<CountNumberProps> = props => {
  return (
    <Suspense fallback={"-"}>
      <CountNumberSuspensible {...props} />
    </Suspense>
  )
}

const CountNumberSuspensible: FC<CountNumberProps> = props => {
  const parts = formatCountNumberToParts(readResource(props.number), {
    precision: props.precision,
    padDecimals: props.padDecimals,
  })

  useDebugValue(parts)

  return <>{parts.map(p => p.value).join("")}</>
}

export interface CountNumberFormatOptions {
  /**
   * @default 2
   */
  precision?: number

  /**
   * @default false
   */
  padDecimals?: boolean
}

export function formatCountNumberToParts(
  count: number,
  options: CountNumberFormatOptions = {},
): { value: string }[] {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 20,
    minimumFractionDigits: 20,
  })

  return formatNumberToParts(formatter, count, options)
}

export interface NumberFormatOptions {
  /**
   * @default 2
   */
  precision?: number

  /**
   * @default false
   */
  padDecimals?: boolean
}
export function formatNumberToParts(
  formatter: Intl.NumberFormat,
  count: number,
  options: NumberFormatOptions = {},
): { value: string }[] {
  const { padDecimals = false, precision = 2 } = options

  const trimedParts = formatter.formatToParts(count).map(p => {
    if (p.type !== "fraction") return p

    const decimals = trimEnd(p.value, "0")

    if (decimals.length >= precision || !padDecimals) {
      return { ...p, value: decimals }
    } else {
      return { ...p, value: decimals.padEnd(precision, "0") }
    }
  })

  const nonEmptyFractionParts = trimedParts.filter(
    p => p.type === "fraction" && p.value !== "",
  )

  if (!padDecimals) {
    if (nonEmptyFractionParts.length === 0) {
      return trimedParts.filter(p => p.type !== "decimal")
    }
  }

  return trimedParts
}
