import clsx from "clsx"
import { ComponentType, isValidElement, ReactElement, SVGProps } from "react"
import { ButtonVariantProps } from "../ButtonVariant"
import { HeadlessButton } from "../HeadlessButton"

export interface InfoIconButtonProps<T> extends ButtonVariantProps {
  icon: ReactElement<SVGProps<T>> | ComponentType<SVGProps<T>>
  inline?: boolean
  activated?: boolean
}

export function InfoIconButton<T>(props: InfoIconButtonProps<T>): JSX.Element {
  const { className, textClassName, activated, ...restProps } = props
  const icon = isValidElement(props.icon) ? props.icon : <props.icon />
  return (
    <HeadlessButton
      {...restProps}
      className={clsx(
        `hover:opacity-60 active:opacity-40 visited:opacity-40`,
        props.inline ? "inline-flex" : "flex",
        "items-center",
        activated && "opacity-40",
        className,
      )}
    >
      {icon}
      {props.children && (
        <span className={textClassName}>&nbsp;{props.children}</span>
      )}
    </HeadlessButton>
  )
}
