import clsx from "clsx"
import { CSSProperties, FC, useContext } from "react"
import { PropsWithChildren } from "../../utils/reactHelpers/types"
import { InfoListDirectionContext } from "./_/InfoListDirectionContext"
import { InfoListRenderersContext } from "./_/InfoListRenderersContext"

export interface InfoListItemTitleProps extends PropsWithChildren<unknown> {
  className?: string
  style?: CSSProperties
}

export const InfoListItemTitle: FC<InfoListItemTitleProps> = props => {
  const { renderInfoListItemTitle } = useContext(InfoListRenderersContext)

  if (renderInfoListItemTitle) {
    return <>{renderInfoListItemTitle(props)}</>
  } else {
    return <DefaultInfoListItemTitle {...props} />
  }
}

export interface DefaultInfoListItemTitleProps
  extends PropsWithChildren<unknown> {
  className?: string
  textClassName?: string
  style?: CSSProperties
  /**
   * @default true
   */
  noWrap?: boolean
}

export const DefaultInfoListItemTitle: FC<
  DefaultInfoListItemTitleProps
> = props => {
  const { listItemDirection } = useContext(InfoListDirectionContext)

  return (
    <dt
      className={clsx(
        (props.noWrap ?? true) && "whitespace-pre",
        props.className,
        props.textClassName ?? "text-sm text-gray-400",
      )}
      style={{
        ...props.style,
        ...(listItemDirection === "row" && { minWidth: 0 }),
      }}
    >
      {props.children}
    </dt>
  )
}
