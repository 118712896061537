import { FC } from "react"
import { useIntl } from "react-intl"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { usePerpetualStore } from "../store/usePerpetualStore"

export const WiredOrderHistory: FC<{ className?: string }> = props => {
  const store = usePerpetualStore()
  const [connect] = useConnect()
  const { $t } = useIntl()

  // if (!store.authStore.isWalletConnected) {
  //   return (
  //     <div className={props.className}>
  //       <OrderHistoryFrame
  //         openOrderCount={0}
  //         isHideOtherSymbols={true}
  //         onChangeHideOtherSymbols={store.myHistory.changeFilterByCurrentMarket}
  //       >
  //         {() => (
  //           <GradientFilledButton
  //             className="m-auto w-[340px]"
  //             onClick={connect}
  //           >
  //             {$t(connectWallet$t)}
  //           </GradientFilledButton>
  //         )}
  //       </OrderHistoryFrame>
  //     </div>
  //   )
  // }

  return (
    <div className={props.className}>
      {/*<OrderHistoryFrame*/}
      {/*  openOrderCount={suspenseResource(*/}
      {/*    () => store.myHistory.myPendingOrders$.length,*/}
      {/*  )}*/}
      {/*  isHideOtherSymbols={store.myHistory.filterByCurrentMarket}*/}
      {/*  onChangeHideOtherSymbols={store.myHistory.changeFilterByCurrentMarket}*/}
      {/*>*/}
      {/*  {({ value }) => {*/}
      {/*    if (value === HistoryContentType.OpenOrders) {*/}
      {/*      return <WiredOpenOrdersTabContent />*/}
      {/*    } else if (value === HistoryContentType.OrdersHistory) {*/}
      {/*      return <WiredMyOrdersTabContent />*/}
      {/*    } else if (value === HistoryContentType.TradeHistory) {*/}
      {/*      return <WiredMyTradesTabContent />*/}
      {/*    } else if (value === HistoryContentType.FundsHistory) {*/}
      {/*      return <WiredFundsHistoryTabContent />*/}
      {/*    } else {*/}
      {/*      assertNever(value)*/}
      {/*    }*/}
      {/*    return null*/}
      {/*  }}*/}
      {/*</OrderHistoryFrame>*/}
    </div>
  )
}
