import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "./BaseRoundedFilledButton"

export const RedFilledButton: FC<RoundedButtonVariantProps> = props => (
  <BaseRoundedFilledButton
    {...props}
    className={clsx("text-gray-100", props.className)}
    bgClassName={
      "bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-700"
    }
  >
    {props.children}
  </BaseRoundedFilledButton>
)
