import { FC, ReactNode } from "react"
import { shortFormatDuration } from "./helpers"
import { TimeCountdownText } from "./TimeCountdownText"
import { Container } from "./_/Container"
import { PrefixText } from "./_/PrefixText"

export interface TimeCountdownProps {
  className?: string

  /**
   * use `null` to hide the default icon
   */
  icon?: ReactNode

  prefix?: ReactNode

  time?: Date

  durationFormatter?: (duration: Duration) => string
}

export const TimeCountdown: FC<TimeCountdownProps> = props => (
  <Container className={props.className} icon={props.icon}>
    {props.prefix && <PrefixText>{props.prefix}</PrefixText>}

    {props.time && (
      <div className={"text-gray-200"}>
        <TimeCountdownText
          time={props.time}
          format={props.durationFormatter ?? defaultDurationFormatter}
        />
      </div>
    )}
  </Container>
)

const defaultDurationFormatter = (duration: Duration): string => {
  return shortFormatDuration(duration, { delimiter: ":" }).replace(/ /g, "")
}
