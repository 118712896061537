import { FC, Fragment } from "react"
import { arrayJoin } from "../utils/arrayHelpers"
import { TokenInfo } from "../utils/models/TokenInfo"
import { TokenName } from "./TokenName"

export const TokenExchangeRoute: FC<{ tokens: TokenInfo[] }> = props => (
  <span className={"inline-flex items-center"}>
    {arrayJoin(
      ({ index }) => (
        <Fragment key={`sep-${index}`}>
          &nbsp;
          <ArrowIcon />
          &nbsp;
        </Fragment>
      ),
      props.tokens.map((r, i) => <TokenName key={i} token={r} />),
    )}
  </span>
)

const ArrowIcon: FC<{ className?: string }> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#C4C4C4"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <g opacity="0.4">
      <path d="M11.6304 7.97577L5.85786 13.7914L4.91504 12.8486L9.78821 7.97539L4.91579 3.10297L5.85859 2.16016L11.6304 7.97577Z" />
    </g>
  </svg>
)
