import React, { FC, PropsWithChildren, ReactNode, useEffect } from "react"
import { useContextInComponent } from "./_/PageStackContext"
import { PageStackNavBar } from "./_/PageStackNavBar"

export interface PageStackPageProps {
  title?: ReactNode
}

export const PageStackPage: FC<
  PropsWithChildren<PageStackPageProps>
> = props => {
  const { pushPage } = useContextInComponent("PageStackPage")

  useEffect(() => {
    return pushPage({
      title: props.title,
    })
  }, [props.title, pushPage])

  return (
    <>
      <PageStackNavBar className={"mb-10"} />
      {props.children}
    </>
  )
}
