import { defer } from "../../../utils/promiseHelpers"
import { MetaMaskEthereumProvider } from "./MetaMaskEthereumProvider"
import {
  MetaMaskEthereumProviderSubscription,
  MetaMaskEthereumProviderSubscriptionMessages,
} from "./MetaMaskEthereumProviderSubscription"

export function ethSubscribe(
  provider: MetaMaskEthereumProvider,
  eventName: keyof MetaMaskEthereumProviderSubscription,
  callback: (
    result: MetaMaskEthereumProviderSubscription[typeof eventName],
  ) => void,
): {
  promise: Promise<void>
  unsubscribe: () => Promise<void>
} {
  const deferred = defer()

  let subscriptionId: undefined | string
  let unsubscribed = false

  provider.on("message", onMessage)

  provider
    .request({ method: "eth_subscribe", params: [eventName as any] })
    .then(async subId => {
      subscriptionId = subId

      if (unsubscribed) {
        await provider.request({ method: "eth_unsubscribe", params: [subId] })
      }
    })
    .then(deferred.resolve, deferred.reject)

  return { promise: deferred.promise, unsubscribe }

  async function unsubscribe(): Promise<void> {
    unsubscribed = true

    provider.removeListener("message", onMessage)

    if (subscriptionId != null) {
      await provider.request({
        method: "eth_unsubscribe",
        params: [subscriptionId],
      })
    }
  }

  function onMessage(
    message: MetaMaskEthereumProviderSubscriptionMessages,
  ): void {
    if (
      message.type === "eth_subscription" &&
      message.data.subscription === subscriptionId &&
      message.data.result != null
    ) {
      callback(message.data.result)
    }
  }
}
