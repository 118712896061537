import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../components/button/Button"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"

export interface RedeemOrdinalsNoticeProps {
  className?: string
  onRedeem: () => void
}

export const RedeemOrdinalsNotice: FC<RedeemOrdinalsNoticeProps> = props => {
  const { $t } = useIntl()

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-center gap-2.5",
        props.className,
      )}
    >
      <div className="self-stretch px-2.5 py-[15px] bg-gradient-to-r from-violet-400 to-indigo-600 rounded flex-col justify-start items-center gap-2.5 flex">
        <div className="flex-col justify-start items-center flex">
          <div className="text-white text-base font-semibold leading-normal">
            {$t(
              defineMessage({
                defaultMessage: "Redeem Ordinals",
                description:
                  "/Launchpad/Redeem Ordinals Notice/Redeem Ordinals text",
              }),
            )}
          </div>
          <div className="w-[257px] text-center text-blue-200 text-xs font-normal leading-none">
            {$t(
              defineMessage({
                defaultMessage:
                  "The prize has been drawn to stacks chain,You can redeem Ordinals to Bitcoin Chain.",
                description:
                  "/Launchpad/Redeem Ordinals Notice/Redeem Ordinals text",
              }),
            )}
          </div>
        </div>

        <Button
          renderVariant={p => (
            <WhiteFilledButton
              {...p}
              boxClassName={"px-3 py-1"}
              textClassName={"text-gray-900 text-xs font-semibold leading-none"}
            />
          )}
          onClick={props.onRedeem}
        >
          {$t(
            defineMessage({
              defaultMessage: "Redeem to Bitcoin Chain",
              description:
                "/Launchpad/Redeem Ordinals Notice/Redeem to Bitcoin Chain text",
            }),
          )}
        </Button>
      </div>
    </div>
  )
}
