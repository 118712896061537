import { FungibleConditionCode } from "@stacks/transactions"
import { optionalResponse, Response, unwrapResponse } from "clarity-codegen"
import { isEqual, memoize, range, sum, uniq } from "lodash"
import { computed, makeObservable, observable } from "mobx"
import { createTransformer } from "mobx-utils"
import { IS_MAIN_NET } from "../../../../../config"
import {
  asSender,
  contractAddr,
  currentContractName,
} from "../../../../../generated/smartContractHelpers/asSender"
import { ConfirmTransactionStore } from "../../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { LazyValue } from "../../../../../stores/LazyValue/LazyValue"
import { Currency } from "../../../../../utils/alexjs/Currency"
import {
  AlexVault,
  AlexVaultV1_1,
  transfer,
} from "../../../../../utils/alexjs/postConditions"
import { asyncAction, runAsyncAction } from "../../../../../utils/asyncAction"
import { isNotNull } from "../../../../../utils/utils"
import { StakeCycle } from "../../../types"
import ManualStakeStore from "../ManualStakeStore"
import MyStackingCellViewModule from "./MyStackingCellViewModule"
import { stakingTokenStats } from "./MyStakingViewModule.services"

class MyStakingViewModule {
  constructor(readonly store: ManualStakeStore) {
    makeObservable(this)
  }

  private stakedTxs = new LazyValue(
    () =>
      [
        this.store.token$,
        this.store.accountStore.transactions$,
        this.store.firstCycleBlock.value$,
        this.store.blocksPerCycle.value$,
        this.store.authStore.currentBlockHeight$,
      ] as const,
    async ([token, transactions, firstCycleBlock, blockPerCycle]) => {
      await transactions.sync()
      if (typeof token === "string") {
        const txs = await transactions.decodedContractCallTransactions(
          "alex-reserve-pool",
          "stake-tokens",
        )
        const blockToCycle = (block: number): number =>
          Math.floor((block - firstCycleBlock) / blockPerCycle)
        return txs
          .filter(t => t.args["token-trait"] === token)
          .map(t => ({
            fromCycle: blockToCycle(t.raw.block_height) + 1,
            token: t.args["token-trait"],
            amountToStake: t.args["amount-token"] / 1e8,
            cyclesToStake: t.args["lock-period"],
          }))
      }
      const txs = await transactions.decodedContractCallTransactions(
        "alex-reserve-pool-sft",
        "stake-tokens",
      )
      const blockToCycle = (block: number): number =>
        Math.floor((block - firstCycleBlock) / blockPerCycle)
      return txs
        .filter(
          t =>
            t.args["token-trait"] === token.currency &&
            t.args["token-id"] === token.tokenId,
        )
        .map(t => ({
          fromCycle: blockToCycle(t.raw.block_height) + 1,
          token: t.args["token-trait"],
          amountToStake: t.args["amount-token"] / 1e8,
          cyclesToStake: t.args["lock-period"],
        }))
    },
  )

  private claimTxs = new LazyValue(
    () =>
      [
        this.store.dualYield,
        this.store.dualYieldV1_1,
        this.store.token$,
        this.store.accountStore.transactions$,
        this.store.authStore.currentBlockHeight$,
      ] as const,
    async ([dualYield, dualYieldV1_1, token, transactions]) => {
      await transactions.sync()
      let txs: {
        cyclesToClaim: number[]
        claimResults: Response<{
          "entitled-token": number
          "to-return": number
        }>[]
      }[] = []
      if (typeof token !== "string") {
        txs = (
          await transactions.decodedContractCallTransactions(
            "staking-helper-sft",
            "claim-staking-reward",
          )
        )
          .filter(
            a =>
              a.args.token === token.currency &&
              a.args["token-id"] === token.tokenId,
          )
          .map(t => ({
            claimResults: unwrapResponse(t.result),
            cyclesToClaim: t.args["reward-cycles"],
          }))
      } else {
        txs = (
          await transactions.decodedContractCallTransactions(
            "staking-helper",
            "claim-staking-reward",
          )
        )
          .filter(t => t.args["token"] === token)
          .map(t => ({
            cyclesToClaim: t.args["reward-cycles"],
            claimResults: unwrapResponse(t.result),
          }))
        if (dualYield != null) {
          txs.push(
            ...(
              await transactions.decodedContractCallTransactions(
                "dual-farming-pool",
                "claim-staking-reward",
              )
            )
              .filter(t => t.args["token"] === token)
              .map(t => ({
                claimResults: unwrapResponse(t.result),
                cyclesToClaim: t.args["reward-cycles"],
              })),
          )
        }
        if (dualYieldV1_1 != null) {
          txs.push(
            ...(
              await transactions.decodedContractCallTransactions(
                "dual-farming-pool-v1-03",
                "claim-staking-reward",
              )
            )
              .filter(t => t.args["token"] === token)
              .map(t => ({
                claimResults: unwrapResponse(t.result),
                cyclesToClaim: t.args["reward-cycles"],
              })),
          )
        }
        if (token === Currency.ALEX) {
          txs.push(
            ...(
              await transactions.decodedContractCallTransactions(
                "auto-alex",
                "claim-and-mint",
              )
            ).map(t => ({
              claimResults: unwrapResponse(t.result),
              cyclesToClaim: t.args["reward-cycles"],
            })),
          )
          txs.push(
            ...(
              await transactions.decodedContractCallTransactions(
                "dual-farming-pool-v1-03",
                "claim-and-mint-auto-alex",
              )
            ).map(t => ({
              claimResults: unwrapResponse(t.result),
              cyclesToClaim: t.args["reward-cycles"],
            })),
          )
        } else if (token === Currency.FWP_STX_ALEX_50_50_V1_01) {
          txs.push(
            ...(
              await transactions.decodedContractCallTransactions(
                "fwp-wstx-alex-tranched-64",
                "claim-and-add-to-position-many",
              )
            ).map(t => ({
              claimResults: unwrapResponse(t.result),
              cyclesToClaim: t.args["reward-cycles"],
            })),
          )
        }
      }
      const result = txs.flatMap(t =>
        t.cyclesToClaim
          .map((c, i) => {
            const result = optionalResponse(t.claimResults[i]!)
            if (result == null) {
              return null
            }
            return {
              cycle: c,
              result,
            }
          })
          .filter(isNotNull),
      )
      const claimedCycles = uniq(result.map(r => r.cycle)).sort()
      return claimedCycles.map(c => ({
        cycle: c,
        "entitled-token": sum(
          result
            .filter(r => r.cycle === c)
            .map(r => r.result["entitled-token"]),
        ),
        "to-return": sum(
          result.filter(r => r.cycle === c).map(r => r.result["to-return"]),
        ),
      }))
    },
  )

  @computed({ equals: isEqual }) get claimedCycles(): number[] {
    return this.claimTxs.value$.map(t => t.cycle)
  }

  @computed get hasAnythingToClaim$(): boolean {
    return this.stakedCycles
      .filter(c => c < this.currentCycle)
      .some(c => !this.claimedCycles.includes(c))
  }

  stakedAt = createTransformer((circle: number): number => {
    return sum(
      this.stakedTxs.value$
        .filter(
          t => t.fromCycle <= circle && t.fromCycle + t.cyclesToStake > circle,
        )
        .map(t => t.amountToStake),
    )
  })

  toReturnAt = createTransformer((cycle: number): number => {
    const alreadyClaimed = this.claimTxs.value$.find(t => t.cycle === cycle)?.[
      "to-return"
    ]
    if (alreadyClaimed != null) {
      return alreadyClaimed / 1e8
    }
    return sum(
      this.stakedTxs.value$
        .filter(t => t.fromCycle + t.cyclesToStake === cycle + 1)
        .map(t => t.amountToStake),
    )
  })

  @computed({ equals: isEqual }) get stakedCycles(): number[] {
    return range(0, this.store.nextCycle$ + 32).filter(
      c => this.stakedAt(c) > 0,
    )
  }

  stakeStats = new LazyValue(() => this.store.token$, stakingTokenStats)

  rewardPerStakedUnit = createTransformer(
    (cycle: number) => this.stakeStats.value$[cycle]?.rewardPerStakedUnit ?? 0,
  )

  rewardAt = createTransformer((cycle: number) => {
    const claimed = this.claimTxs.value$.find(t => t.cycle === cycle)?.[
      "entitled-token"
    ]
    if (claimed != null) {
      return claimed / 1e8
    }
    return this.rewardPerStakedUnit(cycle) * this.stakedAt(cycle)
  })

  totalStakedStats = createTransformer(
    (cycle: number) => this.stakeStats.value$[cycle]?.totalStaked ?? 0,
  )

  @computed get tokenScale(): number {
    return 1e8
  }

  @computed get hasAnyStaking(): boolean {
    return (
      this.activeStaking > 0 ||
      (this.pendingReward > 0 && this.pendingReturns > 0)
    )
  }

  @computed get activeStaking(): number {
    const toBeClaimed = this.stakedCycles.filter(
      c => !this.claimedCycles.includes(c),
    )
    if (toBeClaimed.length === 0) {
      return 0
    }
    return Math.max(...toBeClaimed.map(this.stakedAt))
  }

  @computed get currentCycle(): number {
    return this.store.nextCycle$ - 1
  }

  @computed get recentNextCycles(): StakeCycle[] {
    return range(this.currentCycle, this.currentCycle + 3).map(c =>
      this.cellViewModule(c),
    )
  }

  @computed get pastCircles(): number[] {
    return range(0, this.store.nextCycle$ - 1)
  }

  @computed get pendingReward(): number {
    return sum(
      this.pastCircles
        .filter(c => !this.claimedCycles.includes(c))
        .map(this.rewardAt),
    )
  }

  @computed get pendingReturns(): number {
    return sum(
      this.pastCircles
        .filter(c => !this.claimedCycles.includes(c))
        .map(this.toReturnAt),
    )
  }

  @computed get tokenPriceInUSD(): number {
    return this.store.currencyStore.getPrice$(this.store.stakableToken)
  }

  @computed get alexPriceInUSD(): number {
    return this.store.currencyStore.getPrice$(Currency.ALEX)
  }

  @computed get pendingReturnsToUSD(): number {
    return this.tokenPriceInUSD * this.pendingReturns
  }

  @computed get pendingRewardsToUSD(): number {
    return this.alexPriceInUSD * this.pendingReward
  }

  aprIn = createTransformer((circle: number) => {
    const stats = this.stakeStats.value$[circle]
    if (
      this.store.v1StakeEndCycle$ != null &&
      circle > this.store.v1StakeEndCycle$
    ) {
      return 0
    }
    if (stats == null) {
      return 0
    }
    const alexRewards = stats.totalReward * this.alexPriceInUSD
    const apr =
      (alexRewards / (stats.totalStaked * this.tokenPriceInUSD)) *
      this.store.stakeChain$.annualFactor$
    return (
      apr *
      (this.store.dualYield?.aprMultiplier$ ??
        this.store.dualYieldV1_1?.aprMultiplier$ ??
        1)
    )
  })

  @computed get stakingAPR$(): number {
    if (this.stakedCycles.length === 0) {
      return 0
    }
    const totalRewardsInUSD =
      sum(this.stakedCycles.map(this.rewardAt)) * this.alexPriceInUSD
    const totalStakedInUSD =
      sum(this.stakedCycles.map(this.stakedAt)) * this.tokenPriceInUSD
    return (
      (totalRewardsInUSD / totalStakedInUSD) *
      this.store.stakeChain$.annualFactor$
    )
  }

  cellViewModule = memoize(
    (cycle: number) => new MyStackingCellViewModule(cycle, this),
  )

  @observable confirmation = false

  @computed get harvestableCycles(): number[] {
    return this.pastCircles
      .filter(c => this.rewardAt(c) > 0 || this.toReturnAt(c) > 0)
      .filter(c => !this.claimedCycles.includes(c))
  }

  harvestTransaction = new ConfirmTransactionStore()
  @asyncAction async harvest(run = runAsyncAction): Promise<void> {
    this.confirmation = false
    try {
      let txId: string
      const token = this.store.token$
      if (typeof token !== "string") {
        txId = (
          await run(
            asSender(this.store.authStore.stxAddress$)
              .contract("staking-helper-sft")
              .func("claim-staking-reward")
              .call(
                {
                  token: token.currency,
                  "token-id": token.tokenId,
                  "reward-cycles": this.harvestableCycles,
                },
                [
                  transfer(
                    AlexVaultV1_1,
                    token.currency,
                    this.pendingReturns,
                    FungibleConditionCode.GreaterEqual,
                  ),
                ],
              ),
          )
        ).txId
      } else if (this.store.dualYield) {
        txId = (
          await run(
            asSender(this.store.authStore.stxAddress$)
              .contract("dual-farming-pool")
              .func("claim-staking-reward")
              .call(
                {
                  token,
                  "dual-token": currentContractName("dual-farm-diko-helper"),
                  "reward-cycles": this.harvestableCycles.slice(0, 20),
                },
                [
                  transfer(
                    AlexVault,
                    token,
                    0,
                    FungibleConditionCode.GreaterEqual,
                  ),
                  transfer(
                    IS_MAIN_NET
                      ? "SP2C2YFP12AJZB4MABJBAJ55XECVS7E4PMMZ89YZR.arkadiko-alex-dual-yield-v1-1"
                      : contractAddr("dual-farm-diko-helper"),
                    this.store.dualYield.underlyingToken$,
                    0,
                    FungibleConditionCode.GreaterEqual,
                  ),
                ],
              ),
          )
        ).txId
      } else if (this.store.dualYieldV1_1) {
        txId = (
          await run(
            asSender(this.store.authStore.stxAddress$)
              .contract("dual-farming-pool-v1-03")
              .func("claim-staking-reward")
              .call(
                {
                  token,
                  "dual-token": "brc20-db20",
                  "reward-cycles": this.harvestableCycles.slice(0, 200),
                },
                [
                  transfer(
                    AlexVault,
                    token,
                    this.pendingReturns,
                    FungibleConditionCode.GreaterEqual,
                  ),
                  transfer(
                    contractAddr("dual-farming-pool-v1-03"),
                    this.store.dualYieldV1_1.underlyingToken$,
                    0,
                    FungibleConditionCode.GreaterEqual,
                  ),
                ],
              ),
          )
        ).txId
      } else {
        txId = (
          await run(
            asSender(this.store.authStore.stxAddress$)
              .contract("staking-helper")
              .func("claim-staking-reward")
              .call(
                {
                  token,
                  "reward-cycles": this.harvestableCycles,
                },
                [
                  transfer(
                    AlexVault,
                    token,
                    this.pendingReturns,
                    FungibleConditionCode.GreaterEqual,
                  ),
                ],
              ),
          )
        ).txId
      }
      this.harvestTransaction.successRunning(txId)
    } catch (e) {
      this.harvestTransaction.errorRunning(e as Error)
    }
  }

  @observable showAllCycles = false
  @observable tab: "live" | "finished" = "live"
  @computed get allCycles(): MyStackingCellViewModule[] {
    if (this.tab === "live") {
      return (
        range(this.currentCycle, this.currentCycle + 33)
          // .filter(cycle => this.stakedAt(cycle) > 0)
          .map(this.cellViewModule)
      )
    }
    return range(0, this.currentCycle).reverse().map(this.cellViewModule)
  }
}

export default MyStakingViewModule
