import { Slot } from "@radix-ui/react-slot"
import { ComponentType, FC, PropsWithChildren } from "react"

export function withSlot<P, Tag extends keyof JSX.IntrinsicElements = "div">(
  tag: Tag,
  Comp: FC<
    PropsWithChildren<
      P & { Comp: ComponentType<JSX.IntrinsicElements[Tag]>; asChild?: boolean }
    >
  >,
): ComponentType<PropsWithChildren<P & { asChild?: boolean }>>
export function withSlot<P, CompP = unknown>(
  DefaultComp: ComponentType<CompP>,
  Comp: FC<
    PropsWithChildren<P & { Comp: ComponentType<CompP>; asChild?: boolean }>
  >,
): ComponentType<PropsWithChildren<P & { asChild?: boolean }>>
export function withSlot<P>(
  DefaultComp: string | ComponentType<any>,
  Comp: FC<
    PropsWithChildren<P & { Comp: typeof DefaultComp; asChild?: boolean }>
  >,
): ComponentType<PropsWithChildren<P & { asChild?: boolean }>> {
  const fn: ComponentType<any> = props => {
    const FinalComp = props.asChild ? Slot : DefaultComp
    return <Comp {...props} Comp={FinalComp} asChild={props.asChild} />
  }

  fn.displayName = `withSlot(${
    typeof DefaultComp === "string"
      ? DefaultComp
      : DefaultComp.displayName ?? DefaultComp.name ?? "Anonymous"
  })`

  return fn
}
