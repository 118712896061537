/**
 * All from https://github.com/0no-co/wonka/blob/20692519a51f5f7e35a191b2045da409d5f2497e/src/types.ts
 */

export type TalkbackKind = TalkbackKind.Pull | TalkbackKind.Close
export namespace TalkbackKind {
  export const Pull = 0
  export type Pull = typeof Pull

  export const Close = 1
  export type Close = typeof Close
}

export namespace SignalKind {
  export const Start = 0
  export type Start = typeof Start

  export const Push = 1
  export type Push = typeof Push

  export const End = 0
  export type End = typeof End
}

export namespace SignalValue {
  interface Tag<T> {
    tag: T
  }

  export type Any<T> = Start<T> | Push<T> | End<T>

  export type Start<T> = Tag<SignalKind.Start> & [TalkbackFn]

  export function start<T>(talkback: TalkbackFn): Start<T> {
    const box: any = [talkback]
    box.tag = SignalKind.Start
    return box
  }

  export type Push<T> = Tag<SignalKind.Push> & [T]

  export function push<T>(value: T): Push<T> {
    const box: any = [value]
    box.tag = SignalKind.Push
    return box
  }

  export type End<T> = SignalKind.End

  export function end<T>(): End<T> {
    return SignalKind.End
  }
}

/** A sink accepts new values from a [Source], like [Push], [Start], and an end signal. The [Start] is used to receive a callback to send talkback signals back to the source. */
export type Sink<T> = (signal: SignalValue.Any<T>) => void
/** A source is a function that accepts a [Sink] and then starts sending [Signal]s to it. */
export type Source<T> = (sink: Sink<T>) => void
/** An operator transforms a [Source] and returns a new [Source], potentially with different timings or output types. */
export type Operator<In, Out> = (a: Source<In>) => Source<Out>

/** A talkback callback is sent to the sink with the [Start] signal to communicate signals back to the source. */
export type TalkbackFn = (signal: TalkbackKind) => void

export type TeardownFn = () => void
