import { addDays, format, startOfDay } from "date-fns"
import { times } from "lodash"
import { FC, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory"
import { formatPercentNumber } from "../../../../../../components/PercentNumber"
import { formatTokenName } from "../../../../../../components/TokenName"
import { last } from "../../../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { simplifyWithUnit } from "../../../../../../utils/numberHelpers"
import { VictoryDatum } from "../../../../../Stake/components/EarningsPreviewChartFrame"

export const lineStyles = {
  firstTokenBar: {
    width: 4,
    color: "#F472B6",
  },
  secondTokenBar: {
    width: 4,
    color: "#34D399",
  },
} as const

interface CRPPercentage {
  token: TokenInfo
  percentage: number
}

interface ChartPoint {
  date: Date
  totalVolume: number
  percentages: [CRPPercentage, CRPPercentage]
}

export interface BorrowChartProps {
  data: ChartPoint[]
}

export const BorrowChart: FC<BorrowChartProps> = props => {
  const { $t } = useIntl()
  const yAxisMaxTickValue = Math.max(...props.data.map(d => d.totalVolume))
  // maxValueNumber / yAxisMaxTickValueScale => ma.xValueNumber
  const yAxisMaxTickValueScale = Math.pow(
    10,
    Math.ceil(yAxisMaxTickValue).toString().length - 1,
  )
  const yAxisTickCeilMaxValue =
    Math.ceil(yAxisMaxTickValue / yAxisMaxTickValueScale) *
    yAxisMaxTickValueScale

  const data: ((ChartPoint | { date: Date }) & { hasData: boolean })[] =
    useMemo(
      () =>
        props.data.length >= 7
          ? props.data.map(d => ({ ...d, hasData: true }))
          : [
              ...props.data.map(d => ({ ...d, hasData: true })),
              ...times(7 - props.data.length, i => ({
                date: addDays(last(props.data)?.date ?? new Date(), i),
                hasData: false,
              })),
            ],
      [props.data],
    )

  return (
    <div style={{ margin: "-40px -50px" }}>
      <VictoryChart
        padding={{ top: 40, right: 80, bottom: 40, left: 35 }}
        domain={{ y: [0, yAxisTickCeilMaxValue] }}
        domainPadding={{ x: 30 }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={["firstTokenBar", "secondTokenBar"]}
            labels={data => {
              const point: VictoryDatum<ChartPoint> = data.datum

              return point.percentages
                .map(
                  p =>
                    `${formatPercentNumber(p.percentage)} ${formatTokenName(
                      p.token,
                    )}`,
                )
                .join(" / ")
            }}
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
          />
        }
      >
        {/* date axis */}
        <VictoryAxis
          label=""
          tickFormat={(t, idx) => (data[idx]!.hasData ? format(t, "M/d") : "")}
          tickValues={data.map(d => startOfDay(d.date))}
          tickLabelComponent={
            <VictoryLabel style={{ fill: "#9CA3AF", fontSize: 10 }} />
          }
          fixLabelOverlap={false}
          style={{
            axis: {
              stroke: "#7E8597",
              strokeWidth: 1,
            },
          }}
        />

        {/* right side y axis */}
        <VictoryAxis
          dependentAxis
          label={$t(
            defineMessage({
              defaultMessage: "Total collateral value",
              description: "/Lend/Borrow Chart Axis Label",
            }),
          )}
          axisLabelComponent={<VictoryLabel dy={-400} angle={90} />}
          tickFormat={tick => {
            const [n, unit] = simplifyWithUnit(tick)
            return `${n} ${unit.toUpperCase()}`
          }}
          tickLabelComponent={
            <VictoryLabel
              style={{ fill: "#9CA3AF", fontSize: 10 }}
              textAnchor={"start"}
              dx={350}
            />
          }
          style={{
            axis: {
              strokeWidth: 0,
            },
            grid: {
              stroke: "#E5E7EB",
              strokeWidth: 0.5,
              opacity: 0.25,
            },
            axisLabel: {
              fill: "#4B5563",
            },
          }}
        />

        {/* second token bar charts */}
        <VictoryBar
          name={"secondTokenBar"}
          style={{ data: { fill: lineStyles.secondTokenBar.color } }}
          alignment={"middle"}
          data={props.data}
          barWidth={38}
          x={(d: ChartPoint) => startOfDay(d.date)}
          y={(d: ChartPoint) => d.totalVolume}
          y0={0}
        />

        {/* first token bar charts */}
        <VictoryBar
          name={"firstTokenBar"}
          style={{ data: { fill: lineStyles.firstTokenBar.color } }}
          barWidth={38}
          data={props.data}
          x={(d: ChartPoint) => startOfDay(d.date)}
          y={(d: ChartPoint) => d.percentages[0].percentage * d.totalVolume}
          y0={0}
        />

        {/* for trigger tooltip */}
        <VictoryBar
          style={{ data: { fill: "transparent" } }}
          barWidth={38}
          data={props.data}
          x={(d: ChartPoint) => startOfDay(d.date)}
          y={(d: ChartPoint) => d.totalVolume}
          y0={0}
        />
      </VictoryChart>
    </div>
  )
}
