import { action, computed, makeObservable, observable } from "mobx"
import { EXPLORER_TX_URL } from "../../../../config"
import { contractAddr } from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { Result } from "../../../../utils/Result"
import { Currency, isCurrencyOrB20 } from "../../../../utils/alexjs/Currency"
import { currencyScale } from "../../../../utils/alexjs/currencyHelpers"
import { hasAny } from "../../../../utils/arrayHelpers"
import {
  ContractCallArgs,
  getContractCallHelpers,
} from "../../../../utils/contractHelpers"
import { sleep } from "../../../../utils/promiseHelpers"
import { isNotNull } from "../../../../utils/utils"
import {
  WithdrawFormError,
  WithdrawFormErrorType,
} from "../../components/types"
import { WithdrawRequest } from "../../wiredComponents/WiredTradingAccountContent"
import type {
  OrderbookAsset,
  WithdrawFormData,
} from "../OrderbookStore.service/OrderbookStore.service"
import { withdrawFromStxDx } from "../OrderbookStore.service/OrderbookStore.service"
import { StxDxStore } from "../stxdx_shared/StxDxStore"

export class StxDxWithdrawModule {
  constructor(
    private store: StxDxStore,
    private accountStore: AccountStore,
    private chainStore: ChainStore,
    private currencyStore: CurrencyStore,
  ) {
    makeObservable(this)
  }

  private refreshForMempool = new SuspenseObservable(Date.now())

  @observable confirming = false
  @observable selectingFromToken?: OrderbookAsset
  @observable tokens: {
    token: OrderbookAsset
    amount: SuspenseObservable<number>
  }[] = [
    {
      token: this.basePriceToken$,
      amount: new SuspenseObservable<number>(),
    },
  ]

  private mempoolWithdrawRequests = new LazyValue(
    () => ({
      _refresh: this.refreshForMempool.read$,
      _block: this.chainStore.currentBlockHeight$,
      mempool: this.accountStore.transactions$,
    }),
    ({ mempool }) =>
      mempool.fetchMemPoolTransactions().then(
        (
          a,
        ): {
          txId: string
          args: ContractCallArgs<
            ["stxdx-wallet-zero"],
            "request-transfer-out-many"
          >
        }[] =>
          a
            .map(t => {
              const isWithdrawRequest =
                t.tx_type === "contract_call" &&
                t.contract_call.contract_id ===
                  contractAddr("stxdx-wallet-zero") &&
                t.contract_call.function_name === "request-transfer-out-many"
              if (!isWithdrawRequest) return null
              return {
                txId: t.tx_id,
                args: getContractCallHelpers(
                  "stxdx-wallet-zero",
                  "request-transfer-out-many",
                  t,
                ).getArgs(),
              }
            })
            .filter(isNotNull),
      ),
  )

  @computed get recentWithdrawRequest$(): WithdrawRequest[] {
    return this.mempoolWithdrawRequests.value$
      .filter(r => r.args.assets.length > 0)
      .map(r => ({
        txId: r.txId,
        txExplorerLink: EXPLORER_TX_URL(r.txId),
        withdraws: r.args.assets.flatMap((a, idx) => {
          const amount = r.args.amounts[idx]
          if (amount == null) return []

          if (!isCurrencyOrB20(a)) return []

          return [
            {
              token: this.currencyStore.getTokenInfo$(a),
              amount: amount / currencyScale(a),
            },
          ]
        }),
      }))
      .filter((a): a is WithdrawRequest => hasAny(a.withdraws))
  }

  @computed get allStxDxAssets$(): OrderbookAsset[] {
    return this.store.info.allAccessibleMarketCurrencies$
  }

  @computed get brc20StxDxAssets$(): OrderbookAsset[] {
    return this.store.info.allAccessibleMarkets$
      .filter(m => m.isBrc20Token)
      .map(m => m.tradeToken)
  }

  @computed get basePriceToken$(): OrderbookAsset {
    return (
      this.store.info.allAccessibleMarkets$[0]?.priceToken ??
      (Currency.sUSDT as OrderbookAsset)
    )
  }

  @computed
  get userDecidedTokens(): {
    token: OrderbookAsset
    amount: SuspenseObservable<number>
  }[] {
    return this.tokens.filter(t => t.amount.get() != null && t.amount.read$ > 0)
  }

  @action resetForm(): void {
    this.tokens = [
      {
        token: this.basePriceToken$,
        amount: new SuspenseObservable<number>(),
      },
    ]
  }

  @action reinit(): void {
    this.resetForm()
    this.confirming = false
    this.selectingFromToken = undefined
  }

  @computed get canDelete(): boolean {
    return this.tokens.length > 1
  }

  @computed get canAddMoreToken(): boolean {
    return this.tokens.length < this.allStxDxAssets$.length
  }

  @action addToken(): void {
    const existing = this.tokens.map(t => t.token)
    const notIncluded = this.allStxDxAssets$.filter(
      a => !existing.includes(a),
    )[0]
    if (notIncluded == null) {
      return
    }
    this.tokens.push({
      token: notIncluded,
      amount: new SuspenseObservable<number>(),
    })
  }

  @action deleteToken(token: OrderbookAsset): void {
    this.tokens = this.tokens.filter(t => t.token !== token)
  }

  @computed get formData$(): Result<WithdrawFormData, WithdrawFormError> {
    const tokens = this.userDecidedTokens

    if (tokens.length === 0) {
      return Result.error({
        type: WithdrawFormErrorType.AmountIsEmpty,
        message: "Input Amount",
      })
    }
    if (
      this.userDecidedTokens.some(
        t => t.amount.read$ > this.withdrawableBalance$(t.token),
      )
    ) {
      return Result.error({
        type: WithdrawFormErrorType.ExceedMaxAmount,
        message: "Exceed max amount",
      })
    }

    return Result.ok({
      stxAddress: this.store.authStore.stxAddress$,
      userId: this.store.myInfo.registeredUserId$,
      tokens: this.userDecidedTokens.map(t => ({
        asset: t.token,
        assetId: this.store.info.currencyAssetId$(t.token),
        amount: t.amount.read$,
      })),
    })
  }

  async withdraw(form: WithdrawFormData): Promise<{ txId: string }> {
    const res = await withdrawFromStxDx(form)
    void sleep(1000).then(() => {
      this.refreshForMempool.set(Date.now())
    })
    return res
  }

  withdrawableBalance$(currency: OrderbookAsset): number {
    const balance = this.store.myInfo.stxDxBalance$(currency)
    if (balance == null) return 0
    return balance.balance - balance.locked - balance.withdraw
  }

  @computed get maxWithdrawAmount$(): number {
    return this.store.myInfo.selectedTokenAvailableBalance$
  }

  private isAccountAnomaly$(currency: OrderbookAsset): boolean {
    return this.withdrawableBalance$(currency) < 0
  }

  @computed get isAnyMarketAmountAnomaly$(): boolean {
    return this.store.info.allAccessibleMarketCurrencies$.some(c =>
      this.isAccountAnomaly$(c),
    )
  }
}
