import clsx from "clsx"
import { FC, Fragment, ReactNode } from "react"
import { EmptyState } from "../../../components/EmptyState/EmptyState"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../components/Spensor"
import { SuspenseResource, readResource } from "../../../utils/SuspenseResource"
import { B20TokenListInfo } from "../types"

export const TokenListTabContent: FC<{
  records: SuspenseResource<B20TokenListInfo[]>
  renderItem: (props: { record: B20TokenListInfo }) => ReactNode
}> = props => {
  return (
    <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
      {() =>
        readResource(props.records).length <= 0 ? (
          <EmptyState className={clsx("m-auto")} />
        ) : (
          <div className={"flex flex-col gap-y-2.5"}>
            {readResource(props.records).map((r, idx) => (
              <Fragment key={idx}>{props.renderItem({ record: r })}</Fragment>
            ))}
          </div>
        )
      }
    </Spensor>
  )
}
