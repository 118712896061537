import { FC } from "react"
import { useIntl } from "react-intl"
import {
  connectWallet$t,
  connectWalletPrompt$t,
} from "../../../../commonIntlMessages"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CompactCardPlate } from "../wrappedCommonComponents/CompactCardPlate"
import { RectButton } from "../wrappedCommonComponents/RectButton"

export const TradingAccountContent$ConnectWallet: FC<{
  onConnectWallet?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <CompactCardPlate
        className={"px-2.5 py-6 text-center text-xs font-medium"}
      >
        {$t(connectWalletPrompt$t)}
      </CompactCardPlate>

      <RectButton
        Variant={GradientFilledButton}
        onClick={props.onConnectWallet}
      >
        {$t(connectWallet$t)}
      </RectButton>
    </>
  )
}
