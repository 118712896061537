import { DefaultContentContainer } from "../../../components/CollapsableCard/CollapsableCard"
import { FCC } from "../../../utils/reactHelpers/types"

export const CollapsableCardScrollableRow: FCC<{
  contentContainerBoxClassName?: string
}> = props => (
  <div className={"overflow-x-auto"}>
    <DefaultContentContainer
      className={"min-w-[700px]"}
      boxClassName={props.contentContainerBoxClassName}
    >
      {props.children}
    </DefaultContentContainer>
  </div>
)
