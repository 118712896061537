export function determineApower(
  tickets: number,
  apowerPerTicketInFixed: {
    "apower-per-ticket-in-fixed": number
    "tier-threshold": number
  }[],
): number {
  let apower = 0
  let remaining_tickets = tickets
  for (let i = 0; i < apowerPerTicketInFixed.length; i++) {
    const tickets_to_process =
      remaining_tickets < apowerPerTicketInFixed[i]!["tier-threshold"] ||
      i === apowerPerTicketInFixed.length - 1
        ? remaining_tickets
        : apowerPerTicketInFixed[i]!["tier-threshold"]
    remaining_tickets -= tickets_to_process
    apower +=
      apowerPerTicketInFixed[i]!["apower-per-ticket-in-fixed"] *
      tickets_to_process
  }
  return apower
}
