import { filter, flow } from "lodash"
import { isNotNull } from "../utils"

export const cleanupNullishFields = <T>(obj: T): T => {
  return flow([
    Object.entries,
    entries => filter(entries, ([k, v]) => isNotNull(v)),
    Object.fromEntries,
  ])(obj)
}
