import { FCC } from "../../../../../utils/reactHelpers/types"
import { pointCircleSize } from "./consts"

export interface TrackCanvasProps {
  className?: string
}

export const TrackCanvas: FCC<TrackCanvasProps> = props => {
  return (
    <div
      className={props.className}
      style={{
        paddingLeft: pointCircleSize / 2,
        paddingRight: pointCircleSize / 2,
      }}
    >
      <div className={"flex flex-row items-center relative"}>
        {props.children}
      </div>
    </div>
  )
}
