import { useCallback, useState } from "react"

const prefix = "useLocalStorageState" as const

export function useLocalStorageState<T>(
  storageName: string,
  initial: () => T,
  options?: {
    toString: (val: T) => undefined | string
    fromString: (input: undefined | string) => T
  },
): [state: T, setState: (data: T) => void] {
  const { toString, fromString } = options ?? {
    toString: JSON.stringify,
    fromString: JSON.parse,
  }

  const storageKey = `${prefix}-${storageName}`

  const [state, _setState] = useState<T>(() => {
    try {
      return fromString((localStorage.getItem(storageKey) as any) || undefined)
    } catch {
      return initial()
    }
  })

  const setState = useCallback(
    (v: T): void => {
      _setState(v)
      localStorage.setItem(storageKey, toString(v) ?? "")
    },
    [storageKey, toString],
  )

  return [state, setState]
}
