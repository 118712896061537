import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { ordinal } from "../../../../../../utils/stringHelpers"
import { LotteryWonRowData } from "../../../../store/transformerGroups/lottery/lotteryWon"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { OneLineTokenAmount } from "../../OneLineTokenAmount"
import { BaseRow } from "../BaseRow"

export const LotteryWonRow: FC<{
  rowData: LotteryWonRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => {
  const { $t } = useIntl()
  return (
    <BaseRow
      rowData={rowData}
      currencyCell={
        <OneLineCurrencyCell
          fromCurrencyAmounts={[rowData.winningCurrencyAmount]}
          allTokenInfos={allTokenInfos}
        />
      }
      amountCell={
        <BaseCellContainer>
          <p className="whitespace-nowrap">
            {rowData.winningTickets != null ? rowData.winningTickets + " " : ""}
            {$t(
              defineMessage({
                defaultMessage: "Lottery",
                description: "/Notify/Transaction/Table/Lottery won row",
              }),
            )}
          </p>
          <p className="whitespace-nowrap">
            <OneLineTokenAmount
              currencyAmount={rowData.winningCurrencyAmount}
              allTokenInfos={allTokenInfos}
              prefix="→"
            />
          </p>
        </BaseCellContainer>
      }
      tradeInfoCell={
        <BaseCellContainer>
          <p className="whitespace-nowrap">
            {$t(
              defineMessage({
                defaultMessage: "Round #{lotteryId}",
                description: "/Notify/Transaction/Table/Lottery won row",
              }),
              {
                lotteryId: rowData.lotteryId,
              },
            )}
          </p>
          {rowData.prize != null && (
            <p className="whitespace-nowrap">
              {$t(
                defineMessage({
                  defaultMessage: "{ordinal} Prize",
                  description: "/Notify/Transaction/Table/Lottery won row",
                }),
                {
                  ordinal: ordinal(rowData.prize!),
                },
              )}
            </p>
          )}
        </BaseCellContainer>
      }
    />
  )
}
