import { FC, SVGProps } from "react"

export const CoFarmInfinityIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0968 6C16.1192 6 14.5423 6.99339 13.5677 7.8271L13.5334 7.85661L12.9784 8.46L14.2035 10.4734L15.1103 9.56419C16.0592 8.7629 17.1184 8.32258 18.0968 8.32258C20.071 8.32258 21.6774 9.88548 21.6774 11.8065C21.6774 13.7274 20.071 15.2903 18.0968 15.2903C15.0968 15.2903 13.0577 11.325 13.0374 11.2848C12.9194 11.0463 10.3335 6 5.90323 6C2.64823 6 0 8.60468 0 11.8065C0 15.0082 2.64823 17.6129 5.90323 17.6129C7.49758 17.6129 9.06677 16.9785 10.4415 15.7781L10.471 15.7519L11.0216 15.1529L9.79597 13.1395L8.88484 14.0531C7.93548 14.8742 6.93194 15.2903 5.90323 15.2903C3.92903 15.2903 2.32258 13.7274 2.32258 11.8065C2.32258 9.88548 3.92903 8.32258 5.90323 8.32258C8.90323 8.32258 10.9423 12.2879 10.9626 12.3281C11.0806 12.5666 13.6665 17.6129 18.0968 17.6129C21.3518 17.6129 24 15.0082 24 11.8065C24 8.60468 21.3518 6 18.0968 6Z"
      fill="white"
    />
  </svg>
)
