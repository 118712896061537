import clsx from "clsx"
import { NavLink, NavLinkProps } from "../../../../../components/NavLink"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { ReactComponent as JumpOutsideIcon } from "./jumpOutside.svg"

export interface JumpOutNavLinkProps extends NavLinkProps {
  className?: string
  linkClassName?: string

  /**
   * @default false
   */
  inline?: boolean
}

export const JumpOutNavLink: FCC<JumpOutNavLinkProps> = props => {
  const { inline = false } = props

  return (
    <div
      className={clsx(
        props.className,
        inline ? "inline-flex" : "flex",
        "flex-row items-center",
      )}
    >
      <NavLink {...props} className={props.linkClassName} />
      &nbsp;
      <JumpOutsideIcon />
    </div>
  )
}
