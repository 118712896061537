import { action } from "mobx"
import { FC } from "react"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { hasAny } from "../../../utils/arrayHelpers"
import { ActionPanel$DrawForJoined } from "../component/ActionPanel/ActionPanel$DrawForJoined/ActionPanel$DrawForJoined"
import { ActionPanel$DrawForNotJoined } from "../component/ActionPanel/ActionPanel$DrawForNotJoined/ActionPanel$DrawForNotJoined"
import {
  ActionPanel$Finished,
  ConnectWalletBottomArea,
  LotteryTicketsBottomArea,
} from "../component/ActionPanel/ActionPanel$Finished/ActionPanel$Finished"
import { ActionPanel$NotJoin } from "../component/ActionPanel/ActionPanel$NotJoin/ActionPanel$NotJoin"
import { LotteryStageType } from "../component/types"
import { useLotteryPageStore } from "../store/useLotteryPageStore"
import { WiredBuyLotteryModal } from "./WiredBuyLotteryModal"

export const WiredActionPanelContent: FC = () => {
  const currency = useCurrencyStore()
  const lottery = useLotteryPageStore()
  const authStore = useAuthStore()
  const doAuth = authStore.showWalletSelector
  const alex = currency.getTokenInfo$(Currency.ALEX)
  const chain = useChainStore()

  if (lottery.info.status$.type === LotteryStageType.BuyTicket) {
    if (!authStore.isWalletConnected) {
      return (
        <ActionPanel$NotJoin
          prizeToken={alex}
          prizeTokenTotalCount={lottery.info.totalPot$}
          prizeTokenCountFromAlex={lottery.info.totalBonus$}
          currentBlock={chain.currentBlockHeight$}
          drawStartedAtBlock={lottery.info.drawBlock$}
          drawStartedAt={lottery.info.drawTime$}
          actions={{ onConnectWallet: doAuth }}
        />
      )
    }

    if (!lottery.my.participated$) {
      return (
        <>
          <ActionPanel$NotJoin
            prizeToken={alex}
            prizeTokenTotalCount={lottery.info.totalPot$}
            prizeTokenCountFromAlex={lottery.info.totalBonus$}
            currentBlock={chain.currentBlockHeight$}
            drawStartedAtBlock={lottery.info.drawBlock$}
            drawStartedAt={lottery.info.drawTime$}
            actions={{
              onBuyLottery: action(() => {
                lottery.buy.showModal = true
              }),
            }}
          />
          <WiredBuyLotteryModal />
        </>
      )
    } else {
      return (
        <ActionPanel$DrawForJoined
          prizeToken={alex}
          lotteryTickets={lottery.my.lotteryTickets$}
          startedAtBlock={lottery.info.drawBlock$}
        />
      )
    }
  }

  if (lottery.info.status$.type === LotteryStageType.Drawing) {
    if (!authStore.isWalletConnected) {
      return (
        <ActionPanel$DrawForNotJoined
          prizeToken={alex}
          prizeTokenCount={lottery.info.totalPot$}
          onConnectWallet={doAuth}
        />
      )
    }

    if (!lottery.my.participated$) {
      return (
        <ActionPanel$DrawForNotJoined
          prizeToken={alex}
          prizeTokenCount={lottery.info.totalPot$}
        />
      )
    } else {
      return (
        <ActionPanel$DrawForJoined
          prizeToken={alex}
          lotteryTickets={lottery.my.lotteryTickets$}
          startedAtBlock={lottery.info.drawBlock$}
        />
      )
    }
  }

  if (lottery.info.status$.type === LotteryStageType.Finished) {
    return (
      <ActionPanel$Finished
        roundNumber={lottery.info.currentLotteryId$}
        nextRoundStartedAt={lottery.info.nextLotteryDate$}
        nextRoundStartedAtBlock={lottery.info.nextLotteryBlock$}
        currentBlock={chain.currentBlockHeight$}
        prizeToken={alex}
        firstPrize={{
          prizeTokenCount: lottery.my.roundPayoutAmount(3) ?? 0,
          winningTicketCount: lottery.info.roundInfo$(3)["total-tickets"],
        }}
        secondPrize={{
          prizeTokenCount: lottery.my.roundPayoutAmount(2) ?? 0,
          winningTicketCount: lottery.info.roundInfo$(2)["total-tickets"],
        }}
        thirdPrize={{
          prizeTokenCount: lottery.my.roundPayoutAmount(1) ?? 0,
          winningTicketCount: lottery.info.roundInfo$(1)["total-tickets"],
        }}
        burnPrizeTokenCount={lottery.info.totalPot$ * 0.2}
        rolloverPrizeTokenCount={0}
        bottomArea={
          authStore.isWalletConnected ? (
            hasAny(lottery.my.lotteryTickets$) ? (
              <LotteryTicketsBottomArea
                prizeToken={alex}
                lotteryTickets={lottery.my.lotteryTickets$}
              />
            ) : null
          ) : (
            <ConnectWalletBottomArea onConnectWallet={doAuth} />
          )
        }
      />
    )
  }

  throw new Error(`Unhandled case ${lottery.info.status$.type}`)
}
