import { interval, Observable, startWith, switchMap } from "rxjs"
import { components } from "../../../../generated/wrapBridge/types"
import { sendRequest } from "../../../../generated/wrapBridgeHelpers/wrapBridgeApi"
import { Currency } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../utils/types"
import { BridgeChain } from "../../types/BridgeChain"
import {
  getExplorerAddrLink,
  getExplorerTxLink,
  mapAPIBridgeChainToBridgeChain,
  wrapBridgeNetworkFromBridgeChain,
} from "../../types/BridgeChainHelpers"
import {
  WrapBridgeHistoryRecord,
  WrapBridgeSegment,
  WrapBridgeSegmentStatus,
} from "../../types/types"
import {
  BridgeCurrency,
  parseBridgeCurrencyServerBigIntAmount,
  tokenInfoFromBridgeCurrency,
} from "../utils/BridgeCurrency"
import {
  waitBlocksForUnwrapping,
  waitBlocksForWrapping,
} from "../WrapFormModule.service"
import {
  OnchainStatus,
  ProcessingStatus,
} from "./getHistoryRecords.serverTypes"

export type ServerOrderRecord =
  components["schemas"]["APIDTOBridgeStepsTransactions"]["orders"][number]

const transformServerTransferSegment = (
  serverData: {
    currency: BridgeCurrency
    userAddress: string
    chain: BridgeChain
    amount: number
  },
  context: {
    getTokenInfo: (currency: Currency) => TokenInfo
  },
): WrapBridgeSegment => {
  return {
    token: tokenInfoFromBridgeCurrency(
      serverData.currency,
      context.getTokenInfo,
    ),
    address: serverData.userAddress,
    estimatedAmount: serverData.amount,
    amount: serverData.amount,
    network: wrapBridgeNetworkFromBridgeChain(serverData.chain),
    addressExploreLink: getExplorerAddrLink(
      serverData.chain,
      serverData.userAddress,
    ),
  }
}

const transformServerTransferSegmentStatus = (serverData: {
  onChainStatus: OnchainStatus
  explorerUrl?: string
}): WrapBridgeSegmentStatus => {
  if (serverData.onChainStatus === "confirmed") {
    return {
      type: "success",
      explorerLink: serverData.explorerUrl,
    }
  } else if (serverData.onChainStatus === "failed") {
    return {
      type: "warning",
      message: "Failed",
      explorerLink: serverData.explorerUrl,
    }
  } else if (
    serverData.onChainStatus == null ||
    serverData.onChainStatus === "pending"
  ) {
    return {
      type: "inProgress",
      message: "In progress",
      explorerLink: serverData.explorerUrl,
    }
  } else {
    assertNever(serverData.onChainStatus)
  }
}

const transformServerWrappingSegmentStatus = (
  sourceProcessingStatus: ProcessingStatus,
  sourceProcessingFailedReason: null | string,
  destinationProcessingStatus: null | ProcessingStatus,
  destinationProcessingFailedReason: null | string,
): WrapBridgeSegmentStatus => {
  if (sourceProcessingStatus === "failed") {
    return {
      type: "warning",
      message: sourceProcessingFailedReason ?? "",
    }
  }
  if (destinationProcessingStatus === "failed") {
    return {
      type: "warning",
      message: destinationProcessingFailedReason ?? "",
    }
  }

  if (
    sourceProcessingStatus === "processed" &&
    destinationProcessingStatus === "processed"
  ) {
    return {
      type: "success",
    }
  }

  return {
    type: "inProgress",
    message: "",
  }
}

export const transformServerOrderRecord = (
  serverRecord: ServerOrderRecord,
  context: {
    getTokenInfo: (currency: Currency) => TokenInfo
    getBridgeCurrency: (
      chain: BridgeChain,
      assetContractAddr: string,
    ) => undefined | BridgeCurrency
  },
): undefined | WrapBridgeHistoryRecord => {
  const sourceChain = mapAPIBridgeChainToBridgeChain(
    serverRecord.source_chain as any,
  )
  const sourceCurrency =
    sourceChain == null
      ? undefined
      : context.getBridgeCurrency(sourceChain, serverRecord.source_asset)
  const sourceAmountExcludeFee =
    sourceCurrency == null
      ? null
      : parseBridgeCurrencyServerBigIntAmount(
          serverRecord.source_amount,
          sourceCurrency,
        )
  const sourceFeeCurrency = sourceCurrency
  const sourceFeeAmount =
    sourceFeeCurrency == null
      ? null
      : parseBridgeCurrencyServerBigIntAmount(
          serverRecord.source_fee,
          sourceFeeCurrency,
        )

  if (
    sourceCurrency == null ||
    sourceAmountExcludeFee == null ||
    sourceFeeCurrency == null ||
    sourceFeeAmount == null ||
    sourceChain == null
  ) {
    return
  }

  const destinationChain =
    serverRecord.sent_user_chain == null
      ? null
      : mapAPIBridgeChainToBridgeChain(serverRecord.sent_user_chain as any)
  const destinationCurrency =
    serverRecord.sent_user_asset == null || destinationChain == null
      ? null
      : context.getBridgeCurrency(
          destinationChain,
          serverRecord.sent_user_asset,
        )

  // eslint-disable @typescript-eslint/no-unused-vars
  const source_processing_status: ProcessingStatus =
    serverRecord.source_processing_status as any
  const source_onchain_status: OnchainStatus =
    serverRecord.source_onchain_status as any
  const sent_user_processing_status: ProcessingStatus =
    serverRecord.sent_user_processing_status as any
  const sent_user_onchain_status: OnchainStatus =
    serverRecord.sent_user_onchain_status as any
  // eslint-enable @typescript-eslint/no-unused-vars
  const isWrappingStepStarted = source_onchain_status === "confirmed"
  const isWrappingStepFinished = sent_user_processing_status === "processed"

  return {
    orderNumber: `${serverRecord.source_tx_hash}-${serverRecord.source_evt_index}`,
    createdAt: new Date(serverRecord.created_at),
    status: {
      source: transformServerTransferSegmentStatus({
        onChainStatus: serverRecord.source_onchain_status as any,
        explorerUrl: getExplorerTxLink(
          sourceChain,
          serverRecord.source_tx_hash,
        ),
      }),
      wrapping: isWrappingStepStarted
        ? transformServerWrappingSegmentStatus(
            serverRecord.source_processing_status as any,
            serverRecord.source_error,
            serverRecord.sent_user_processing_status as any,
            serverRecord.sent_user_error,
          )
        : undefined,
      destination:
        !isWrappingStepFinished || serverRecord.sent_user_onchain_status == null
          ? undefined
          : transformServerTransferSegmentStatus({
              onChainStatus: serverRecord.sent_user_onchain_status as any,
              explorerUrl: getExplorerTxLink(
                destinationChain!,
                serverRecord.sent_user_tx_hash!,
              ),
            }),
    },
    source: {
      waitBlocks:
        sourceChain === BridgeChain.Stacks
          ? waitBlocksForUnwrapping
          : waitBlocksForWrapping,
      ...transformServerTransferSegment(
        {
          currency: sourceCurrency,
          userAddress: serverRecord.source_sender_address,
          chain: sourceChain,
          amount: sourceAmountExcludeFee + sourceFeeAmount,
        },
        context,
      ),
    },
    target:
      destinationCurrency == null ||
      destinationChain == null ||
      serverRecord.sent_user_recipient_address == null
        ? undefined
        : transformServerTransferSegment(
            {
              currency: destinationCurrency,
              userAddress: serverRecord.sent_user_recipient_address,
              chain: destinationChain,
              amount: sourceAmountExcludeFee,
            },
            context,
          ),
    fee: sourceFeeAmount,
    feeToken: tokenInfoFromBridgeCurrency(
      sourceFeeCurrency,
      context.getTokenInfo,
    ),
  }
}

export const getServerHistoryRecords = (
  address0: string,
  address1: string,
): Observable<ServerOrderRecord[]> => {
  return interval(30 * 1000).pipe(
    startWith(null),
    switchMap(() =>
      sendRequest("TokenBridgeController_getTokenBridgeOrders", {
        query: { address0, address1 },
      }).then(r => r.data.orders),
    ),
  )
}
