import { DepositSellRowData } from "../../store/transformerGroups/lend/depositSell"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const depositSellExporter: Exporter<DepositSellRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.fromCurrencyAmount],
    toCurrencyAmounts: [rowData.toCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = `#${rowData.expiry}`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
