import { gql } from "@urql/core"
import { Observable } from "rxjs"
import {
  FetchAppConfigQuery,
  FetchAppConfigQueryVariables,
} from "../../generated/graphql/graphql.generated"
import { OrderbookAsset } from "../../pages/Orderbook/store/OrderbookStore.service/OrderbookStore.service"
import {
  OrderbookMarket,
  OrderbookMarketId,
} from "../../pages/Orderbook/store/stxdx_shared/StxDxMarket.service"
import { StakableCurrency } from "../../pages/Stake/store/manualStaking/ManualStakeStore.service"
import { fromUrqlSource } from "../../utils/Observable/fromUrqlSource"
import { AMMSwapPool } from "../../utils/alexjs/AMMSwapPool"
import {
  LiquidityPoolToken,
  SwappableCurrency,
  isFWPToken,
  isYTPToken,
} from "../../utils/alexjs/currencyHelpers"
import { gqlQuery } from "../../utils/graphqlHelpers"
import { isNotNull } from "../../utils/utils"

export interface AppConfigs {
  commonSwapCoins: SwappableCurrency[]
  allSwapCoins: SwappableCurrency[]
  farmingPool: StakableCurrency[]
  pools: LiquidityPoolToken[]
  analyticsTabs: AnalyticsTabs[]
  enableLendFeature: boolean
  enableCoFarmFeature: boolean
  enableBlockIndicator: boolean
  enableOrderBook: boolean
  enableSwapFeature: boolean
  enableLotteryFeature: boolean
  enableLaunchPadFeature: boolean
  enablePoolFeature: boolean
  enableStakeFeature: boolean
  enableFarmFeature: boolean
  enableWrapBridgeFeature: boolean
  enablePerpetualFeature: boolean
  enableLanguageSelector: boolean
  enableBrc20Feature: boolean
  enableBridgeSubmit: boolean
  enable202307PegInFlowFeature: boolean

  stakingCycleLimitBlockHeight: undefined | number

  orderbookMarkets: OrderbookMarket[]
  orderbookSenderFee: number | undefined

  enableOrderbookTradingView: boolean
  b20TotalDepositSUsdt?: number
  swapDefaultSlippage?: number
  testnetRewardBanner?: number
}

export interface AnalyticsTabs {
  urlSlug: string
  title: string
  iframeUrl: string
}

export function getAppConfig(env: string): Observable<AppConfigs> {
  return fromUrqlSource(
    gqlQuery<FetchAppConfigQuery, FetchAppConfigQueryVariables>(
      gql`
        query FetchAppConfig($env: String!) {
          appConfigCollection(where: { env: $env }, limit: 1) {
            items {
              env
              commonSwapCoinsCollection {
                items {
                  id
                }
              }
              allSwapCoinsCollection {
                items {
                  id
                }
              }
              poolsCollection {
                items {
                  id
                }
              }
              farmingsCollection {
                items {
                  id
                }
              }
              enableLendFeature
              enableCoFarmFeature
              enableBlockIndicator
              enableOrderBook
              enableSwapFeature
              enableLotteryFeature
              enableLaunchPadFeature
              enablePoolFeature
              enableStakeFeature
              enableFarmFeature
              enableWrapBridgeFeature
              enablePerpetualFeature
              v1StakingCycleLimitBlockHeight
              enableLanguageSelector
              enableBrc20Feature
              enableBridgeSubmit
              orderbookMarketsCollection(limit: 100) {
                items {
                  marketId
                  pricePrecision
                  priceToken {
                    id
                  }
                  tradeToken {
                    id
                  }
                  brc20MarketInfo {
                    baseTokenSymbol
                  }
                }
              }
              orderbookSenderFee
              enableOrderbookTradingView
              b20TotalDepositSUsdt
              swapDefaultSlippage
              enable202307PegInFlowFeature
              testnetRewardBanner
            }
          }
          analyticsTabsCollection(
            where: { enabled: true }
            order: [sort_DESC]
          ) {
            items {
              urlSlug
              title
              iframeLink
            }
          }
        }
      `,
      {
        env,
      },
    ),
    result => {
      const item = result.data.appConfigCollection?.items[0]
      if (item == null) {
        throw new Error(`No app config found for env: ${env}`)
      }
      return {
        commonSwapCoins: item.commonSwapCoinsCollection!.items.map(
          i => i!.id! as SwappableCurrency,
        ),
        allSwapCoins: item.allSwapCoinsCollection!.items.map(
          i => i!.id! as SwappableCurrency,
        ),
        pools: item
          .poolsCollection!.items.map(i => i!.id!)
          .filter(
            (a): a is LiquidityPoolToken =>
              isFWPToken(a) || isYTPToken(a) || AMMSwapPool.isPoolToken(a),
          ),
        farmingPool: item
          .farmingsCollection!.items.map(i => i!.id!)
          .filter(
            (a): a is StakableCurrency =>
              isFWPToken(a) || AMMSwapPool.isPoolToken(a),
          ),
        analyticsTabs:
          result.data.analyticsTabsCollection?.items.filter(isNotNull).map(
            (i): AnalyticsTabs => ({
              title: i.title!,
              iframeUrl: i.iframeLink!,
              urlSlug: i.urlSlug!,
            }),
          ) ?? [],
        enableLendFeature: !!item.enableLendFeature,
        enableCoFarmFeature: !!item.enableCoFarmFeature,
        enableBlockIndicator: !!item.enableBlockIndicator,
        enableOrderBook: !!item.enableOrderBook,
        enableSwapFeature: !!item.enableSwapFeature,
        enableLotteryFeature: !!item.enableLotteryFeature,
        enableLaunchPadFeature: !!item.enableLaunchPadFeature,
        enablePoolFeature: !!item.enablePoolFeature,
        enableStakeFeature: !!item.enableStakeFeature,
        enableFarmFeature: !!item.enableFarmFeature,
        enableWrapBridgeFeature: !!item.enableWrapBridgeFeature,
        enablePerpetualFeature: !!item.enablePerpetualFeature,
        enableLanguageSelector: !!item.enableLanguageSelector,
        stakingCycleLimitBlockHeight:
          item.v1StakingCycleLimitBlockHeight ?? undefined,
        enableBrc20Feature: !!item.enableBrc20Feature,
        enableBridgeSubmit: !!item.enableBridgeSubmit,
        orderbookMarkets: item
          .orderbookMarketsCollection!.items.filter(isNotNull)
          .map(
            (i): OrderbookMarket => ({
              marketId: i.marketId! as OrderbookMarketId,
              pricePrecision: i.pricePrecision!,
              priceToken: i.priceToken!.id as OrderbookAsset,
              tradeToken: i.tradeToken!.id as OrderbookAsset,
              isBrc20Token: i.brc20MarketInfo != null,
            }),
          ),
        orderbookSenderFee: item.orderbookSenderFee ?? undefined,
        enableOrderbookTradingView: !!item.enableOrderbookTradingView,
        b20TotalDepositSUsdt: item.b20TotalDepositSUsdt ?? undefined,
        swapDefaultSlippage: item.swapDefaultSlippage ?? undefined,
        enable202307PegInFlowFeature:
          item.enable202307PegInFlowFeature ?? false,
        testnetRewardBanner: item.testnetRewardBanner ?? undefined,
      }
    },
  )
}
