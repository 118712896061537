import { startOfDay, subDays } from "date-fns"
import { action, computed, makeObservable, observable } from "mobx"
import type { DateRange } from "react-day-picker"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { isNotNull } from "../../../utils/utils"
import { CRPSimulatorDayData } from "../types"
import { fetchSimulationData } from "./CRPSimulatorStore.services"

const today = startOfDay(new Date())
export const recentMonth: DateRange = { from: subDays(today, 30), to: today }

export class CRPSimulatorStore {
  constructor() {
    makeObservable(this)
  }

  /**
   * configs assume by server
   */
  readonly interestRate = 0.02
  readonly defaultCollateralUsdCount = 10_000_000
  readonly platformConfigs = [
    {
      name: "AAVE V2",
      maxLTV: 0.7,
      interestRate: 0.0226,
      liquidationThreshold: 0.75,
    },
    {
      name: "Celsius",
      maxLTV: 0.5,
      interestRate: 0.0895,
      liquidationThreshold: 0.8,
    },
  ]

  @observable showDataComparison = false
  @observable collateralBtcCount?: number = 1

  @observable fromDate?: Date = recentMonth.from
  @observable toDate?: Date = recentMonth.to
  @action setDateRange(dateRange?: DateRange): void {
    this.fromDate = dateRange?.from
    this.toDate = dateRange?.to
  }
  @computed get dateRange(): DateRange {
    return {
      from: this.fromDate,
      to: this.toDate,
    }
  }

  private simulationData = new LazyValue(
    () => this.dateRange,
    fetchSimulationData,
  )

  @computed get simulationData$(): CRPSimulatorDayData[] {
    return this.simulationData.value$.data
  }

  @computed get initialLTV$(): number {
    return this.simulationData.value$.config.initialLTV
  }

  @computed private get initialBtcPrice(): number | undefined {
    const firstDay = this.simulationData$[0]
    if (!firstDay) {
      return
    }
    return firstDay.yPrice
  }

  @computed private get actualCollateralUsdCount(): number | undefined {
    if (!this.initialBtcPrice || !this.collateralBtcCount) {
      return
    }
    return this.initialBtcPrice * this.collateralBtcCount
  }

  @computed get valueRatio(): number | undefined {
    if (!this.actualCollateralUsdCount) {
      return
    }
    return this.actualCollateralUsdCount / this.defaultCollateralUsdCount
  }

  @computed get estimateReceived$(): number | undefined {
    const dateLength = this.simulationData$.length
    if (
      !(dateLength && this.actualCollateralUsdCount && this.collateralBtcCount)
    ) {
      return
    }
    return (
      this.actualCollateralUsdCount *
      this.initialLTV$ *
      (1 - (this.interestRate * dateLength) / 365)
    )
  }

  @computed get endDatePosition$():
    | { xValue: number; yValue: number; yPrice: number }
    | undefined {
    const lastDay = this.simulationData$[this.simulationData$.length - 1]
    if (!lastDay || !this.valueRatio) {
      return
    }
    return {
      xValue: lastDay.xValue * this.valueRatio,
      yValue: lastDay.yValue * this.valueRatio,
      yPrice: lastDay.yPrice,
    }
  }

  @computed get liquidations$(): Array<{ platformName: string; date: string }> {
    if (this.initialBtcPrice == null) {
      return []
    }
    const alexConvert = this.simulationData$.find(data => data.convert)
    return [
      ...this.platformConfigs
        .map(config => {
          const liquidationPrice =
            (this.initialBtcPrice! *
              config.maxLTV *
              (1 - (config.interestRate * this.simulationData$.length) / 365)) /
            config.liquidationThreshold

          const liquidationDate = this.simulationData$.find(data => {
            return (
              Math.min(...data.hoursData.map(x => x.yPrice)) < liquidationPrice
            )
          })
          if (!liquidationDate) {
            return null
          }
          console.log(
            "!!liquidated:",
            liquidationDate.hoursData.map(x => x.yPrice),
            liquidationPrice,
            this.initialBtcPrice,
            config.maxLTV,
          )
          return { platformName: config.name, date: liquidationDate.date }
        })
        .filter(isNotNull),
      ...(alexConvert
        ? [{ platformName: "ALEX", date: alexConvert.date }]
        : []),
    ]
  }
}
