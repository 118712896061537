import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["collateral-rebalancing-pool-v1"] as const
const functionName = "add-to-position-and-switch" as const

export interface BorrowRowData extends BaseRowData {
  type: NotifyTransactionType.Borrow
  collateralCurrencyAmount: CurrencyAmount
  tokenCurrencyAmount: CurrencyAmount
  yieldCurrencyAmount: CurrencyAmount
}

export const borrowTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  BorrowRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.Borrow,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency
    const collateralTokenTrait = args["collateral-trait"] as Currency

    const tokenAmountIn = transferAmounts[tokenTrait]?.in
    const tokenAmountOut = transferAmounts[tokenTrait]?.out

    return {
      collateralCurrencyAmount: {
        currency: collateralTokenTrait,
        amount: transferAmounts[collateralTokenTrait]?.out,
      },
      tokenCurrencyAmount: {
        currency: tokenTrait,
        amount:
          tokenAmountIn && tokenAmountOut
            ? tokenAmountIn - tokenAmountOut
            : undefined,
      },
      yieldCurrencyAmount: {
        currency: yieldTokenTrait,
        amount: transferAmounts[yieldTokenTrait]?.in,
      },
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency
    const collateralTokenTrait = args["collateral-trait"] as Currency

    return {
      collateralCurrencyAmount: {
        currency: collateralTokenTrait,
        amount: getAmountFromContract(args.dx),
      },
      tokenCurrencyAmount: {
        currency: tokenTrait,
        minAmount: getAmountFromContract(args["min-dy"]),
      },
      yieldCurrencyAmount: {
        currency: yieldTokenTrait,
      },
    }
  },
}
