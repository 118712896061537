import { optionalResponse } from "clarity-codegen"
import { sum } from "lodash"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { dualYieldToken } from "../../../../../utils/alexjs/currencyHelpers"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = [
  "staking-helper",
  "dual-farming-pool",
  "staking-helper-sft",
] as const
const functionName = "claim-staking-reward" as const

export interface FarmHarvestRowData extends BaseRowData {
  type: NotifyTransactionType.FarmHarvest
  principalCurrencyAmount: CurrencyAmount.Unknown | CurrencyAmount.Confirmed
  rewards: CurrencyAmount[]
}

export const farmHarvestTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  FarmHarvestRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.FarmHarvest,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const result = helpers.getResult()
    const transfers = helpers.getTransfers()

    const tokenTrait = args.token as Currency
    if (tokenTrait === Currency.ALEX) {
      return undefined
    }
    const dualTrait =
      tokenTrait === Currency.FWP_ALEX_USDA
        ? dualYieldToken(tokenTrait)
        : undefined
    const principalAmount = getAmountFromContract(
      sum(
        (optionalResponse(result) ?? []).map(
          r => optionalResponse(r)?.["to-return"] ?? 0,
        ),
      ),
    )
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)
    const rewards = [
      {
        currency: Currency.ALEX,
        amount: transferAmounts[Currency.ALEX]?.in,
      },
      {
        currency: Currency.APOWER,
        amount: transferAmounts[Currency.APOWER]?.in,
      },
    ]
    if (dualTrait) {
      rewards.push({
        currency: dualTrait,
        amount: transferAmounts[dualTrait]?.in,
      })
    }
    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
        amount: principalAmount,
      },
      rewards,
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args.token as Currency
    if (tokenTrait === Currency.ALEX) {
      return undefined
    }
    const dualTrait =
      tokenTrait === Currency.FWP_ALEX_USDA
        ? dualYieldToken(tokenTrait)
        : undefined
    const rewards = [
      {
        currency: Currency.ALEX,
      },
      {
        currency: Currency.APOWER,
      },
    ]
    if (dualTrait) {
      rewards.push({ currency: dualTrait })
    }
    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
      },
      rewards,
    }
  },
}
