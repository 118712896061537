import clsx from "clsx"
import { values } from "lodash"
import { FC } from "react"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"
import { connectWallet$t } from "../../../../commonIntlMessages"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "../../../../components/button/variants/BaseRoundedFilledButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { SegmentControl } from "../../../../components/SegmentControl"
import { Spensor } from "../../../../components/Spensor"
import { TabBar } from "../../../../components/TabBar"
import { ExclamationOutlinedIcon } from "../../../../components/TipIcon/ExclamationOutlinedIcon"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { oneOf } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { StxDxOrderType } from "../../../Orderbook/components/types"
import { leverages } from "../../store/modules/PerpetualTradeModule"
import { orderDirection$t, orderType$t } from "../_/commonIntlMessages"
import { normal } from "../buttonPresets"
import { LeverageSelected } from "../LeverageSelected"
import {
  Leverage,
  OrderDirection,
  TradingFormError,
  TradingFormErrorType,
  TradingFormWarningType,
} from "../types"
import { CompactCard } from "../wrappedCommonComponents/CompactCard"
import { CompactCardPlate } from "../wrappedCommonComponents/CompactCardPlate"
import { RectButton } from "../wrappedCommonComponents/RectButton"
import { formFields$t } from "./_/commonIntlMessages"

export interface CreateOrderFrameProps {
  className?: string

  quotaToken: TokenInfo

  orderType: StxDxOrderType
  onChangeOrderType: (newType: StxDxOrderType) => void

  leverage: Leverage
  onChangeLeverage: (newLeverage: Leverage) => void

  feeRates: SuspenseResource<{
    maker: number
    taker: number
  }>

  longOrderInfo: SuspenseResource<{
    cost: number
    positionSize: number
    maxPositionSize: number
  }>
  shortOrderInfo: SuspenseResource<{
    cost: number
    positionSize: number
    maxPositionSize: number
  }>

  formError?: SuspenseResource<TradingFormError | undefined>
  onConnectWallet: () => void
  onDeposit?: () => void
  onReplacePrice?: () => void
  onSubmit: (orderDirection: OrderDirection) => void
}

export const CreateOrderFrame: FCC<CreateOrderFrameProps> = props => {
  const intl = useIntl()
  const { $t } = intl

  const error = safeReadResource(props.formError)
  const formErrorContent = useFormErrorContent({
    formError: error,
    onDeposit: props.onDeposit,
    onReplacePrice: props.onReplacePrice,
  })

  return (
    <CompactCard className={clsx("flex flex-col gap-4", props.className)}>
      <SegmentControl
        className={"w-full"}
        boxClassName={"p-1"}
        segmentBoxClassName={"flex-1 min-h-[32px]"}
        segmentTextClassName={"text-sm leading-5 font-medium capitalize"}
        controls={[
          {
            title: "Isolated",
            isActive: false,
          },
        ]}
      >
        <LeverageSelected
          leverage={props.leverage}
          leverages={leverages}
          onLeverageChange={props.onChangeLeverage}
        />
      </SegmentControl>
      <TabBar
        className={"mt-2.5 mb-2"}
        tabClassName={"px-4 pb-3 text-sm leading-5 font-semibold"}
        tabs={[
          { label: $t(orderType$t.limit), value: StxDxOrderType.Limit },
          { label: $t(orderType$t.market), value: StxDxOrderType.Market },
          // { label: $t(orderType$t.stopLimit), value: StxDxOrderType.StopLimit },
        ]}
        selectedTab={{
          tabValue: props.orderType,
          onChange: tab => props.onChangeOrderType?.(tab.value),
        }}
      >
        <div className={"flex flex-col gap-2.5"}>{props.children}</div>
      </TabBar>

      {formErrorContent != null && (
        <CompactCardPlate>
          <NoteParagraph className={"text-red-500"} icon={<WarnIcon />}>
            {formErrorContent}
          </NoteParagraph>
        </CompactCardPlate>
      )}

      <InfoList direction={"column"} listItemDirection={"row"}>
        <div className={"flex justify-between"}>
          <InfoListItem className={"gap-1"}>
            <InfoListItemTitle>
              {$t(formFields$t.longPositionSize)}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <TextTokenCount
                    token={props.quotaToken}
                    count={readResource(props.longOrderInfo).positionSize}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem className={"gap-1"}>
            <InfoListItemTitle>
              {$t(formFields$t.shortPositionSize)}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <TextTokenCount
                    token={props.quotaToken}
                    count={readResource(props.shortOrderInfo).positionSize}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </div>
      </InfoList>

      {error?.type === TradingFormErrorType.ConnectWalletRequired ? (
        <RectButton
          Variant={GradientFilledButton}
          {...normal}
          onClick={props.onConnectWallet}
        >
          {$t(connectWallet$t)}
        </RectButton>
      ) : (
        <div className={"flex gap-2.5"}>
          <RectButton
            className={"flex-1"}
            Variant={LongButtonVarant}
            {...normal}
            disabled={
              error != null && oneOf(...values(TradingFormErrorType))(error)
            }
            onClick={() => props.onSubmit(OrderDirection.Long)}
          >
            {orderDirection$t.fromOrderDirection(intl, OrderDirection.Long)}
          </RectButton>

          <RectButton
            className={"flex-1"}
            Variant={ShortButtonVariant}
            {...normal}
            disabled={
              error != null && oneOf(...values(TradingFormErrorType))(error)
            }
            onClick={() => props.onSubmit(OrderDirection.Short)}
          >
            {orderDirection$t.fromOrderDirection(intl, OrderDirection.Short)}
          </RectButton>
        </div>
      )}

      <InfoList direction={"column"} listItemDirection={"row"}>
        <div className={"flex justify-between"}>
          <InfoListItem className={"gap-1"}>
            <InfoListItemTitle>{$t(formFields$t.cost)}</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <TextTokenCount
                    token={props.quotaToken}
                    count={readResource(props.longOrderInfo).cost}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem className={"gap-1"}>
            <InfoListItemTitle>{$t(formFields$t.cost)}</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <TextTokenCount
                    token={props.quotaToken}
                    count={readResource(props.shortOrderInfo).cost}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </div>

        <div className={"flex justify-between"}>
          <InfoListItem className={"gap-1"}>
            <InfoListItemTitle>{$t(formFields$t.max)}</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <TextTokenCount
                    token={props.quotaToken}
                    count={readResource(props.longOrderInfo).maxPositionSize}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem className={"gap-1"}>
            <InfoListItemTitle>{$t(formFields$t.max)}</InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <TextTokenCount
                    token={props.quotaToken}
                    count={readResource(props.shortOrderInfo).maxPositionSize}
                  />
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </div>
      </InfoList>

      <div
        className={
          "flex justify-center text-xs leading-4 font-medium text-gray-400 px-2.5 pb-2.5"
        }
      >
        Fees Details&nbsp;
        <TipIcon
          Icon={ExclamationOutlinedIcon}
          tip={
            <span className={"text-xs leading-4 font-medium text-gray-200/70"}>
              <Spensor fallback={"..."}>
                {() =>
                  $t(
                    defineMessage({
                      defaultMessage:
                        "Maker {makerFeeRate} / Taker {makerFeeRate}",
                      description:
                        "Perpetual/Create Order Panel/fee rate tooltip",
                    }),
                    {
                      makerFeeRate: (
                        <span className={"font-semibold text-gray-200"}>
                          <PercentNumber
                            number={readResource(props.feeRates).maker}
                            precision={3}
                          />
                        </span>
                      ),
                      takerFeeRate: (
                        <span className={"font-semibold text-gray-200"}>
                          <PercentNumber
                            number={readResource(props.feeRates).taker}
                            precision={3}
                          />
                        </span>
                      ),
                    },
                  )
                }
              </Spensor>
            </span>
          }
        />
      </div>
    </CompactCard>
  )
}

export const LongButtonVarant: FC<RoundedButtonVariantProps> = props => (
  <BaseRoundedFilledButton
    {...props}
    className={clsx("text-gray-100", props.className)}
    bgClassName={
      "bg-green-600 hover:bg-green-700 active:bg-green-800 disabled:bg-green-800"
    }
  >
    {props.children}
  </BaseRoundedFilledButton>
)

export const ShortButtonVariant: FC<RoundedButtonVariantProps> = props => (
  <BaseRoundedFilledButton
    {...props}
    className={clsx("text-gray-100", props.className)}
    bgClassName={
      "bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-700"
    }
  >
    {props.children}
  </BaseRoundedFilledButton>
)

const useFormErrorContent = (props: {
  formError?: TradingFormError
  onDeposit?: () => void
  onReplacePrice?: () => void
}): null | undefined | JSX.Element => {
  const { $t } = useIntl()

  if (props.formError?.type === TradingFormErrorType.TotalPriceTooSmall) {
    return (
      <>
        {$t(
          defineMessage({
            defaultMessage: "Total value cannot be less than {minTotalPrice}.",
            description: "Perpetual/Create Order Panel/error messages",
          }),
          {
            minTotalPrice: (
              <TextTokenCount
                token={props.formError.priceToken}
                count={props.formError.minimumPriceTokenCount}
              />
            ),
          },
        )}
      </>
    )
  }

  if (
    props.formError?.type ===
      TradingFormErrorType.InsufficientPriceTokenBalance ||
    props.formError?.type === TradingFormErrorType.InsufficientTradeTokenBalance
  ) {
    return (
      <>
        {$t(
          defineMessage({
            defaultMessage: "Insufficient Balance {depositButton}",
            description: "Perpetual/Create Order Panel/error messages",
          }),
          {
            depositButton: (
              <HeadlessButton
                className={"inline text-blue-600"}
                onClick={props.onDeposit}
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Deposit >",
                    description:
                      "Perpetual/Create Order Panel/deposit button belongs error messages",
                  }),
                )}
              </HeadlessButton>
            ),
          },
        )}
      </>
    )
  }

  let warningMessage: undefined | MessageDescriptor
  if (props.formError?.type === TradingFormWarningType.AbnormalBuyingPrice) {
    warningMessage = defineMessage({
      defaultMessage:
        "Limit Price is higher than the lowest selling order price. {replaceButton}",
      description: "Perpetual/Create Order Panel/warning messages",
    })
  } else if (
    props.formError?.type === TradingFormWarningType.AbnormalSellingPrice
  ) {
    warningMessage = defineMessage({
      defaultMessage:
        "Limit Price is lower than the highest buying order price. {replaceButton}",
      description: "Perpetual/Create Order Panel/warning messages",
    })
  }
  if (warningMessage != null) {
    return (
      <>
        {$t(warningMessage, {
          replaceButton: (
            <HeadlessButton
              className={"inline text-blue-600"}
              onClick={props.onReplacePrice}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Replace",
                  description:
                    "Perpetual/Create Order Panel/replace button belongs error messages",
                }),
              )}
            </HeadlessButton>
          ),
        })}
      </>
    )
  }

  return null
}
