import { SwapRowData } from "../../store/transformerGroups/swap/swap"
import { CurrencyAmount, Exporter, NotifyTransactionStatus } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount, parseExchangeRatio } from "../parser"

export const swapExporter: Exporter<SwapRowData> = (rowData, allTokenInfos) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.fromCurrencyAmount],
    toCurrencyAmounts: [rowData.toCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo =
    rowData.status === NotifyTransactionStatus.Confirmed
      ? parseExchangeRatio({
          fromCurrencyAmount: rowData.fromCurrencyAmount,
          toCurrencyAmount:
            rowData.toCurrencyAmount as CurrencyAmount.Confirmed,
          allTokenInfos,
        })
      : ""
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
