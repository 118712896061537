import clsx from "clsx"
import React, { FC, ReactNode } from "react"
import { NavLink } from "../../NavLink"
import { ReactComponent as VideoIcon } from "./video.svg"

export const VideoIntro: FC<{
  className?: string
  textClassName?: string
  inline?: boolean
  /**
   * @default 30
   */
  iconSize?: number
  link: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  children: ReactNode
}> = props => (
  <NavLink
    className={clsx(
      "items-center",
      props.inline ? "inline-flex align-bottom" : "flex",
      props.className,
      props.textClassName ?? "text-base leading-6 font-normal text-gray-100",
    )}
    to={props.link}
    onClick={props.onClick}
  >
    <VideoIcon
      className={clsx(!props.inline && "mr-3", "max-w-none")}
      width={props.iconSize ?? 30}
      height={props.iconSize ?? 30}
    />
    {props.inline && <>&nbsp;</>}
    {props.children}
  </NavLink>
)
