import { DependencyList, useDebugValue, useEffect, useRef } from "react"

type CleanupFn<T> = (creation: T) => void

type RefValue<T> = {
  deps: DependencyList
  obj: undefined | T
  initialized: boolean
  cleanup?: CleanupFn<T>
}

const initialRefValue: RefValue<any> = Object.freeze({
  deps: [],
  obj: undefined,
  initialized: false,
})

/**
 * Like ahook/useCreation , but added cleanup callback
 *
 * @see https://ahooks.js.org/hooks/advanced/use-creation
 */
export function useCreation<T>(
  factory: () => T,
  deps: DependencyList,
  cleanup?: CleanupFn<T>,
): T {
  const ref = useRef<RefValue<T>>({ ...initialRefValue })

  const scheduledCleanupArrayRef = useRef<[cleanup: CleanupFn<T>, obj: T][]>([])

  // cleanup before component unmount
  useEffect(
    () => () => {
      if (ref.current.initialized && ref.current.cleanup) {
        ref.current.cleanup?.(ref.current.obj!)
        ref.current = { ...initialRefValue }
      }
    },
    [],
  )

  // perform all scheduled cleanup
  useEffect(() => () => {
    scheduledCleanupArrayRef.current.forEach(([cleanup, obj]) => {
      cleanup(obj)
    })
    scheduledCleanupArrayRef.current = []
  })

  if (!ref.current.initialized || !depsAreSame(ref.current.deps, deps)) {
    if (ref.current.initialized && ref.current.cleanup) {
      scheduledCleanupArrayRef.current.push([
        ref.current.cleanup,
        ref.current.obj!,
      ])
    }

    ref.current = {
      deps: deps,
      obj: factory(),
      initialized: true,
      cleanup: cleanup,
    }
  }

  useDebugValue(ref.current.obj)

  return ref.current.obj!
}

function depsAreSame(oldDeps: DependencyList, deps: DependencyList): boolean {
  if (oldDeps === deps) return true

  /**
   * The length of react hook deps is always required to be consistent
   * so we can skip the length checking
   */
  return oldDeps.every((i, idx) => Object.is(i, deps[idx]))
}
