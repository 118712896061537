import {
  BaseRowData,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["collateral-rebalancing-pool-v1"] as const
const functionName = "reduce-position-key" as const

export interface BorrowRolloverRowData extends BaseRowData {
  type: NotifyTransactionType.BorrowRollover
  expiry: number
}

export const borrowRolloverTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  BorrowRolloverRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.BorrowRollover,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()

    return {
      expiry: args.expiry,
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      expiry: args.expiry,
    }
  },
}
