import { useEffect, useRef } from "react"
import { usePersistFn } from "./usePersistFn"

export function useIsMounted(): () => boolean {
  const ref = useRef(false)

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  return usePersistFn(() => ref.current)
}
