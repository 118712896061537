import clsx from "clsx"
import { FC, Ref } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardTitle } from "../../../../components/Card"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../components/Spensor"
import { TokenName } from "../../../../components/TokenName"
import { BaseRoundedFilledButton } from "../../../../components/button/variants/BaseRoundedFilledButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { BRC20InscriptionStatus } from "./BRC20Card/BRC20InscriptionStatus.types"
import { BRC20InscriptionStatusCard } from "./BRC20Card/BRC20InscriptionStatusCard"
import { ReactComponent as CloseIcon } from "./_/close.svg"

export interface RecentInscriptionRecord {
  token: TokenInfo
  tokenAmount: BigNumber
  inscriptionNumber?: number
  status: BRC20InscriptionStatus
}

export interface InProgressPanelProps {
  className?: string
  containerRef?: Ref<HTMLElement>
  brc20Token: TokenInfo
  recentInscriptionRecords: SuspenseResource<RecentInscriptionRecord[]>
  onInscribeNew?: () => void
  onClose?: () => void
}

export const InProgressPanel: FC<InProgressPanelProps> = props => {
  const { $t } = useIntl()

  return (
    <div
      ref={props.containerRef as any}
      className={clsx(
        props.className,
        "flex flex-col gap-y-5 p-4 bg-black/30 rounded-lg",
      )}
    >
      <CardTitle className="flex flex-row justify-center items-center">
        <GradientFilledButton
          className="inline-flex flex-row gap-x-2.5 justify-center items-center"
          boxClassName="px-4 py-1 sm:px-6 sm:py-0"
          roundedClassName="rounded-3xl"
          onClick={props.onInscribeNew}
        >
          <span className="w-4 h-4 shrink-0">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_20048722_6760)">
                <path
                  d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM12 8.66667H8.66667V12H7.33333V8.66667H4V7.33333H7.33333V4H8.66667V7.33333H12V8.66667Z"
                  fill="#F3F4F6"
                />
              </g>
              <defs>
                <clipPath id="clip0_20048722_6760">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className="hidden sm:inline-block">
            {$t(
              defineMessage({
                defaultMessage: "Inscribe to peg-in",
                description: "Orderbook Peg-In Page/In Progress Panel/button",
              }),
            )}
          </span>
        </GradientFilledButton>
        <span className="grow text-center">
          {$t(
            defineMessage({
              defaultMessage: "Peg-in {tokenName}",
              description: "Orderbook Peg-In Page/In Progress Panel/title",
            }),
            {
              tokenName: <TokenName token={props.brc20Token} />,
            },
          )}
        </span>
        <PlainIconButton
          icon={CloseIcon}
          className="basis:auto sm:basis-52 flex flex-row justify-end"
          onClick={props.onClose}
        />
      </CardTitle>

      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() => (
          <div className="flex flex-col gap-y-2.5">
            {readResource(props.recentInscriptionRecords).map((info, idx) => (
              <BRC20InscriptionStatusCard
                key={idx}
                token={info.token}
                tokenCount={info.tokenAmount}
                inscriptionNumber={info.inscriptionNumber}
                status={info.status}
              />
            ))}
          </div>
        )}
      </Spensor>

      <div className="flex flex-col gap-y-5 items-center">
        <BaseRoundedFilledButton
          className="flex flex-col gap-y-2.5 items-center justify-center text-white"
          roundedClassName="rounded"
          boxClassName="px-4 py-4 sm:py-10 w-full"
          bgClassName="bg-blue-900"
          onClick={props.onInscribeNew}
        >
          <span className="w-10 h-10">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_20047801_155523)">
                <path
                  d="M20 0C8.955 0 0 8.955 0 20C0 31.045 8.955 40 20 40C31.045 40 40 31.045 40 20C40 8.955 31.045 0 20 0ZM30 21.6667H21.6667V30H18.3333V21.6667H10V18.3333H18.3333V10H21.6667V18.3333H30V21.6667Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_20047801_155523">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className="text-base leading-6 font-semibold">
            {$t(
              defineMessage({
                defaultMessage: "Inscribe New Transfer to Peg-in",
                description: "Orderbook Peg-In Page/In Progress Panel/button",
              }),
            )}
          </span>
        </BaseRoundedFilledButton>
        <ul className="text-xs leading-4 font-normal w-full justify-self-start">
          <li>
            {$t(
              defineMessage({
                defaultMessage:
                  "* It usually takes 24 hours to send a peg-in request. If the transaction is not confirmed after 24 hours, please open ticket in {contactLink} for help.",
                description: "Orderbook Peg-In Page/In Progress Panel/rules",
              }),
              {
                contactLink: (
                  <a className="text-blue-600" href="http://discord.gg/alexlab">
                    Discord&gt;
                  </a>
                ),
              },
            )}
          </li>
          <li>
            {$t(
              defineMessage({
                defaultMessage:
                  "* After successfully peg-in, go to your B20 trading account to view your BRC-20 token {tokenLink}",
                description: "Orderbook Peg-In Page/In Progress Panel/rules",
              }),
              {
                tokenLink: (
                  <a
                    className="text-blue-600"
                    href="https://app.alexlab.co/orderbook/DB20-USD"
                  >
                    ALEX B20&gt;
                  </a>
                ),
              },
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}
