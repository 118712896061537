import { last } from "lodash"
import { FC } from "react"
import { Slider } from "../../../../components/Slider"
import { ComboboxSelector } from "../../components/ComboboxSelector/ComboboxSelector"

export interface CycleCountSliderShortcut {
  isRecommend?: boolean
  count: number
}

export interface CycleCountSliderProps {
  shortcuts: [
    CycleCountSliderShortcut,
    CycleCountSliderShortcut,
    CycleCountSliderShortcut,
    ...CycleCountSliderShortcut[]
  ]
  value: number
  onChange?: (value: number) => void
}

export const CycleCountSlider: FC<CycleCountSliderProps> = props => {
  const max = last(props.shortcuts)!.count

  return (
    <div>
      <ComboboxSelector
        availableValues={props.shortcuts.map(s => ({
          label: String(s.count),
          value: s.count,
          isRecommend: s.isRecommend,
        }))}
        value={[props.value]}
        onSelect={props.onChange}
      />

      <Slider min={1} max={max} value={props.value} onChange={props.onChange} />
    </div>
  )
}
