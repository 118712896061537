import { CoFarmAPowerDistributionRowData } from "../../store/transformerGroups/farm/coFarmAPowerDistribution"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const coFarmAPowerDistributionExporter: Exporter<
  CoFarmAPowerDistributionRowData
> = (rowData, allTokenInfos) => {
  const amount = parseAmount({
    toCurrencyAmounts: rowData.rewardCurrencyAmounts,
    allTokenInfos,
  })
  const tradeInfo = `Reward Harvest`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
