import clsx from "clsx"
import { cloneElement, FC, ReactElement } from "react"
import {
  arrivedTextColorClassName,
  idleTextColorClassName,
  pointCircleSize,
  pointHeight,
  succeedTextColorClassName,
} from "./consts"
import { LabelProps } from "./Label"
import { ReactComponent as SucceedIcon } from "./succeed.svg"

export interface PointProps {
  className?: string

  state: "idle" | "arrived" | "bypassed"

  label?: ReactElement<LabelProps>

  /**
   * @default 'left'
   *
   * will affect the `align` prop of the `label`
   */
  labelAlignment?: "left" | "right"
}
export namespace PointProps {
  export const getLabelAlignment = (
    props: PointProps,
  ): NonNullable<PointProps["labelAlignment"]> => props.labelAlignment ?? "left"
}

export const Point: FC<PointProps> = props => {
  const labelAlignment = PointProps.getLabelAlignment(props)

  const colorClassName = [
    props.state === "idle" && idleTextColorClassName,
    props.state === "arrived" && succeedTextColorClassName,
    props.state === "bypassed" && arrivedTextColorClassName,
  ]

  return (
    <div className={clsx("flex flex-col", props.className)}>
      {props.label && (
        <div
          className={clsx(
            "absolute flex",
            labelAlignment === "left" && "flex-row left-0",
            labelAlignment === "right" && "flex-row-reverse right-0",
          )}
          style={{ bottom: pointCircleSize }}
        >
          {/* line */}
          <div
            className={clsx("bg-current w-[1px]", ...colorClassName)}
            style={{
              height: pointHeight - pointCircleSize / 2,
              marginLeft: (pointCircleSize - 1) / 2,
              marginRight: (pointCircleSize - 1) / 2,
            }}
          />

          <div className={"p-b-[15px] p-x-[6px]"}>
            {cloneElement(props.label, {
              align: labelAlignment,
              detailColorClassName:
                props.state === "arrived"
                  ? succeedTextColorClassName
                  : undefined,
            })}
          </div>
        </div>
      )}

      {/* circle */}
      <div
        className={clsx(
          "bg-current rounded-full flex items-center justify-center overflow-hidden",
          ...colorClassName,
          labelAlignment === "left" && "self-start",
          labelAlignment === "right" && "self-end",
        )}
        style={{ width: pointCircleSize, height: pointCircleSize }}
      >
        {props.state === "arrived" && (
          <SucceedIcon
            className={"block"}
            width={pointCircleSize}
            height={pointCircleSize}
          />
        )}
      </div>
    </div>
  )
}
