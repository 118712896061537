import { CoFarmClaimRowData } from "../../store/transformerGroups/farm/coFarmClaim"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const coFarmClaimExporter: Exporter<CoFarmClaimRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    toCurrencyAmounts: [rowData.claimedPrincipalCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = `Claim Co-Farm`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
