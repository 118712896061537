import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const stxdxRegistry = defineContract({
  "stxdx-registry": {
    "apply-whitelist": {
      input: [{ name: "new-use-whitelist", type: booleanT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "approve-exchange": {
      input: [
        { name: "exchange", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "register-asset": {
      input: [{ name: "asset", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "register-user": {
      input: [{ name: "pub-key", type: bufferT }],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "register-user-on-behalf": {
      input: [
        { name: "pub-key", type: bufferT },
        { name: "maker", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "new-owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-order-approval": {
      input: [
        { name: "order-hash", type: bufferT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-order-fill": {
      input: [
        { name: "order-hash", type: bufferT },
        { name: "new-fill", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-order-fills": {
      input: [
        {
          name: "fills",
          type: listT(tupleT({ "new-fill": numberT, "order-hash": bufferT })),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-two-order-fills": {
      input: [
        { name: "order-hash-1", type: bufferT },
        { name: "new-fill-1", type: numberT },
        { name: "order-hash-2", type: bufferT },
        { name: "new-fill-2", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    whitelist: {
      input: [
        { name: "user", type: principalT },
        { name: "whitelisted", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "whitelist-many": {
      input: [
        { name: "users", type: listT(principalT) },
        { name: "whitelisted", type: listT(booleanT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "asset-from-id": {
      input: [{ name: "id", type: numberT }],
      output: optionalT(principalT),
      mode: "readonly",
    },
    "asset-from-id-or-fail": {
      input: [{ name: "id", type: numberT }],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-asset-id": {
      input: [{ name: "asset", type: principalT }],
      output: optionalT(numberT),
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-order-approval": {
      input: [
        { name: "maker", type: principalT },
        { name: "order-hash", type: bufferT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "get-order-fill": {
      input: [{ name: "order-hash", type: bufferT }],
      output: numberT,
      mode: "readonly",
    },
    "get-order-fills": {
      input: [{ name: "order-hashes", type: listT(bufferT) }],
      output: listT(numberT),
      mode: "readonly",
    },
    "get-two-order-fills": {
      input: [
        { name: "order-hash-1", type: bufferT },
        { name: "order-hash-2", type: bufferT },
      ],
      output: tupleT({ "order-1": numberT, "order-2": numberT }),
      mode: "readonly",
    },
    "get-two-users-from-id-or-fail": {
      input: [
        { name: "id-1", type: numberT },
        { name: "id-2", type: numberT },
      ],
      output: responseSimpleT(
        tupleT({
          "user-1": tupleT({ maker: principalT, "pub-key": bufferT }),
          "user-2": tupleT({ maker: principalT, "pub-key": bufferT }),
        }),
      ),
      mode: "readonly",
    },
    "get-user-id": {
      input: [{ name: "user", type: principalT }],
      output: optionalT(numberT),
      mode: "readonly",
    },
    "get-user-id-or-fail": {
      input: [{ name: "user", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "is-approved-exchange": {
      input: [{ name: "exchange", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "is-whitelisted": {
      input: [{ name: "user", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "user-from-id": {
      input: [{ name: "id", type: numberT }],
      output: optionalT(tupleT({ maker: principalT, "pub-key": bufferT })),
      mode: "readonly",
    },
    "user-from-id-or-fail": {
      input: [{ name: "id", type: numberT }],
      output: responseSimpleT(
        tupleT({ maker: principalT, "pub-key": bufferT }),
      ),
      mode: "readonly",
    },
    "user-maker-from-id": {
      input: [{ name: "id", type: numberT }],
      output: optionalT(principalT),
      mode: "readonly",
    },
    "user-maker-from-id-or-fail": {
      input: [{ name: "id", type: numberT }],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "asset-registry": {
      input: numberT,
      output: optionalT(principalT),
      mode: "mapEntry",
    },
    "asset-registry-ids": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "authorised-exchanges": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "order-approvals": {
      input: tupleT({ maker: principalT, "order-hash": bufferT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "order-fills": {
      input: bufferT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "user-id-registry": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "user-registry": {
      input: numberT,
      output: optionalT(tupleT({ maker: principalT, "pub-key": bufferT })),
      mode: "mapEntry",
    },
    "whitelisted-users": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "asset-registry-nonce": { input: noneT, output: numberT, mode: "variable" },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    "use-whitelist": { input: noneT, output: booleanT, mode: "variable" },
    "user-registry-nonce": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
