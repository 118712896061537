import { cloneElement, FC, ReactElement, SVGProps, useId } from "react"

export const FlexArrow: FC<{
  className?: string

  /**
   * @default 30
   */
  width?: number

  /**
   * @default 60
   */
  height?: number

  /**
   * @default width * 0.6
   */
  triangleHeight?: number

  linearGradient?: ReactElement<SVGProps<SVGLinearGradientElement>>
}> = props => {
  const {
    width = 30,
    height = 60,
    triangleHeight = width * 0.6,
    linearGradient = defaultLinearGradient,
  } = props

  const linearGradientId = "linearGradient-" + useId()

  return (
    <svg
      className={props.className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={`url(#${linearGradientId})`}>
        <rect
          x={width / 3}
          width={width / 3}
          height={height - triangleHeight}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={`M0 ${height - triangleHeight}H${width}L${width / 2} ${height}L0 ${
            height - triangleHeight
          }Z`}
        />
      </g>
      <defs>{cloneElement(linearGradient, { id: linearGradientId })}</defs>
    </svg>
  )
}

const defaultLinearGradient = (
  <linearGradient
    x1="50%"
    y1="0%"
    x2="50%"
    y2="100%"
    gradientUnits="userSpaceOnUse"
  >
    <stop stopColor="#4D4DED" />
    <stop offset="1" stopColor="#8989FF" />
  </linearGradient>
)
