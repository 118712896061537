import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "./BaseRoundedFilledButton"

export const WhiteFilledButton: FC<RoundedButtonVariantProps> = props => (
  <BaseRoundedFilledButton
    {...props}
    className={clsx("text-gray-900", props.className)}
    bgClassName={
      "bg-gray-100 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-400"
    }
  >
    {props.children}
  </BaseRoundedFilledButton>
)
