import { FC, Fragment } from "react"
import { defineMessage, useIntl } from "react-intl"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import {
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { longFormatDuration } from "../../../../components/TimeCountdown/helpers"
import { TimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { arrayJoin } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { EditIcon } from "../depositPage/DepositInfoListContent"

export interface BorrowInfoListContentProps {
  expirationDate: SuspenseResource<Date>
  expirationBlockHeight: SuspenseResource<number>
  estimateAbsoluteInterest: SuspenseResource<number>
  interestToken: TokenInfo
  liquidityProviderFee: SuspenseResource<number>
  crpInfo: { token: TokenInfo; percentage: SuspenseResource<number> }[]
  slippage?: number
  onEditSlippage?: () => void
}

export const BorrowInfoListContent: FC<BorrowInfoListContentProps> = props => {
  const { $t } = useIntl()
  return (
    <>
      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Maturity",
              description: "/Lend/BorrowInfoListContent/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          {$t(
            defineMessage({
              defaultMessage: "Block #{expirationBlockHeight} (Est {time})",
              description: "/Lend/BorrowInfoListContent/Info list item detail",
            }),
            {
              expirationBlockHeight: (
                <Spensor fallback="--">
                  {() => readResource(props.expirationBlockHeight)}
                </Spensor>
              ),
              time: (
                <Spensor fallback="--">
                  {() => (
                    <TimeCountdownText
                      time={readResource(props.expirationDate)}
                      format={d =>
                        longFormatDuration(d, {
                          includes: ["days", "hours", "minutes"],
                        })
                      }
                    />
                  )}
                </Spensor>
              ),
            },
          )}
        </InfoListItemDetail>
      </InfoListItem>
      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Est. Interest",
              description: "/Lend/BorrowInfoListContent/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          <Spensor fallback="--">
            {() => (
              <TokenCount
                token={props.interestToken}
                count={readResource(props.estimateAbsoluteInterest)}
              />
            )}
          </Spensor>
          &nbsp;
          <TokenName token={props.interestToken} />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "CRP Position",
              description: "/Lend/BorrowInfoListContent/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          {arrayJoin(
            idx => (
              <Fragment key={`sep-${idx}`}>&nbsp;/&nbsp;</Fragment>
            ),
            props.crpInfo.map((i, idx) => (
              <Fragment key={idx}>
                <Spensor fallback="--">
                  {() => <PercentNumber number={readResource(i.percentage)} />}
                </Spensor>
                &nbsp;
                <TokenName token={i.token} />
              </Fragment>
            )),
          )}
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Liquidity Provider Fee",
              description: "/Lend/BorrowInfoListContent/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail className={"flex items-center"}>
          <Spensor fallback="--">
            {() => (
              <TokenCount
                token={props.interestToken}
                count={readResource(props.liquidityProviderFee)}
              />
            )}
          </Spensor>
          &nbsp;
          <TokenName token={props.interestToken} />
        </InfoListItemDetail>
      </InfoListItem>

      {props.slippage != null && (
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Slippage Tolerance",
                description: "/Lend/BorrowInfoListContent/Info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail className={"flex items-center"}>
            <PercentNumber number={props.slippage} />
            {props.onEditSlippage && (
              <>
                &nbsp;
                <PlainIconButton
                  icon={EditIcon}
                  onClick={props.onEditSlippage}
                />
              </>
            )}
          </InfoListItemDetail>
        </InfoListItem>
      )}
    </>
  )
}
