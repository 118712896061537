import BigNumber from "bignumber.js"
import { unwrapResponse } from "clarity-codegen"
import { computed, makeObservable, observable, runInAction } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../../../config"
import {
  asSender,
  contractAddr,
} from "../../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../../../stores/LazyValue/pMemoizeDecorator"
import { ConfirmTransactionStore } from "../../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { transfer } from "../../../../../utils/alexjs/postConditions"
import { props } from "../../../../../utils/promiseHelpers"
import AutoStakeStore from "../AutoStakeStore"

const toBN = (n: number): BigNumber => new BigNumber(n).dividedBy(1e8)
const min = (n: BigNumber, m: BigNumber): BigNumber => (n.isLessThan(m) ? n : m)
const max = (n: BigNumber, m: BigNumber): BigNumber => (n.isLessThan(m) ? m : n)

class MigrationV2Module {
  constructor(readonly autoStore: AutoStakeStore) {
    makeObservable(this)
  }

  @observable migrateVisible = false
  @observable claimVisible = false

  #intrinsicV2 = new LazyValue(
    () => this.autoStore.chainStore.currentBlockHeight$,
    () =>
      asSender(CONTRACT_DEPLOYER)
        .contract("auto-alex-v2")
        .func("get-intrinsic")
        .call([])
        .then(a => toBN(unwrapResponse(a)))
        .then(a => (a.isLessThan(1) ? 1 : a)),
  )

  #autoAlexV1Balance = new LazyValue(
    () => ({
      _block: this.autoStore.chainStore.currentBlockHeight$,
      stxAddr: this.autoStore.authStore.stxAddress$,
    }),
    ({ stxAddr }) =>
      asSender(CONTRACT_DEPLOYER)
        .contract("auto-alex")
        .func("get-balance")
        .call({ who: stxAddr })
        .then(a => toBN(unwrapResponse(a))),
  )

  @computed get availableAtAlexV1ToMigrateToDisplay$(): number {
    return this.availableAtAlexV1ToMigrate$.toNumber()
  }
  @computed get availableAtAlexV1ToMigrate$(): BigNumber {
    const amount = this.buyBackAmount$

    const currentBalance = this.#autoAlexV1Balance.value$
    const availableBuybackAmount = amount.buyback103825
      .plus(amount.buyback104305)
      .minus(amount.boughtback103825)
      .minus(amount.boughtback104305)

    return min(currentBalance, availableBuybackAmount)
  }

  @computed get receivingAlexAmountToDisplay$(): number {
    return this.receivingAlexAmount$.toNumber()
  }
  @computed get receivingAlexAmount$(): BigNumber {
    const amount = this.buyBackAmount$
    const { rate103825, rate104305 } = this.buyBackRates$

    // return (
    //   (amount.buyback103825 - amount.boughtback103825) * rate103825 +
    //   (amount.buyback104305 - amount.boughtback104305) * rate104305
    // )

    const currentBalance = this.#autoAlexV1Balance.value$
    const availableBuyBack103825 = amount.buyback103825.minus(
      amount.boughtback103825,
    )
    const availableBuyBack104305 = amount.buyback104305.minus(
      amount.boughtback104305,
    )
    const execute103825 = min(availableBuyBack103825, currentBalance)
    const execute104305 = min(
      availableBuyBack104305,
      max(currentBalance.minus(execute103825), new BigNumber(0)),
    )
    return execute103825
      .multipliedBy(rate103825)
      .plus(execute104305.multipliedBy(rate104305))
  }

  @computed get atAlexToAlexRateToDisplay$(): number {
    return this.receivingAlexAmount$
      .dividedBy(this.availableAtAlexV1ToMigrate$)
      .toNumber()
  }

  @computed get receivingAtAlexV2AmountToDisplay$(): number {
    return this.receivingAtAlexV2Amount$.toNumber()
  }
  @computed get receivingAtAlexV2Amount$(): BigNumber {
    return this.receivingAlexAmount$.dividedBy(this.#intrinsicV2.value$)
  }

  @computed get atAlexToAtAlexV2RateToDisplay$(): number {
    return this.receivingAtAlexV2Amount$
      .dividedBy(this.availableAtAlexV1ToMigrate$)
      .toNumber()
  }

  @computed get blockNumber$(): number {
    return 104305
  }

  #buyBackAmount = new LazyValue(
    () => ({
      stx: this.autoStore.authStore.stxAddress$,
      _block: this.autoStore.chainStore.currentBlockHash$,
    }),
    ({ stx }) =>
      asSender(stx)
        .contract("auto-alex-buyback-helper")
        .func("get-buyback-amount")
        .call({
          user: stx,
        })
        .then(a => ({
          buyback103825: a["buyback-103825"],
          buyback104305: a["buyback-104305"],
          boughtback103825: a["boughtback-103825"],
          boughtback104305: a["boughtback-104305"],
        })),
    {
      decorator: pMemoizeDecorator({
        persistKey: "auto-alex-buyback-helper.get-buyback-amount.v2",
      }),
    },
  )
  private get buyBackAmount$(): {
    buyback103825: BigNumber
    buyback104305: BigNumber
    boughtback103825: BigNumber
    boughtback104305: BigNumber
  } {
    const a = this.#buyBackAmount.value$
    return {
      buyback103825: toBN(a.buyback103825),
      buyback104305: toBN(a.buyback104305),
      boughtback103825: toBN(a.boughtback103825),
      boughtback104305: toBN(a.boughtback104305),
    }
  }

  #buyBackRates = new LazyValue(
    () => null,
    () =>
      props({
        rate103825: asSender(CONTRACT_DEPLOYER)
          .contract("auto-alex-buyback-helper")
          .func("get-rate-103825")
          .call({}),
        rate104305: asSender(CONTRACT_DEPLOYER)
          .contract("auto-alex-buyback-helper")
          .func("get-rate-104305")
          .call({}),
      }),
    {
      decorator: pMemoizeDecorator({
        persistKey:
          "auto-alex-buyback-helper.get-rate-103825/get-rate-104305.v2",
      }),
    },
  )
  private get buyBackRates$(): {
    rate103825: BigNumber
    rate104305: BigNumber
  } {
    const a = this.#buyBackRates.value$
    return {
      rate103825: toBN(a.rate103825),
      rate104305: toBN(a.rate104305),
    }
  }

  confirmationStore = new ConfirmTransactionStore()
  async onMigrate(): Promise<void> {
    runInAction(() => (this.migrateVisible = false))
    await this.confirmationStore.run(() =>
      asSender(CONTRACT_DEPLOYER)
        .contract("auto-alex-buyback-helper")
        .func("upgrade")
        .call(
          {
            amount: this.availableAtAlexV1ToMigrate$
              .multipliedBy(1e8)
              .toNumber(),
          },
          [
            transfer(
              this.autoStore.authStore.stxAddress$,
              Currency.ATALEX,
              this.availableAtAlexV1ToMigrate$.toNumber(),
            ),
            transfer(
              contractAddr("auto-alex-buyback-helper"),
              Currency.ALEX,
              this.receivingAlexAmount$.toNumber(),
            ),
            transfer(
              this.autoStore.authStore.stxAddress$,
              Currency.ALEX,
              this.receivingAlexAmount$.toNumber(),
            ),
            transfer(
              contractAddr("auto-alex-v2"),
              Currency.ALEX,
              this.receivingAlexAmount$.toNumber(),
            ),
          ],
        ),
    )
  }
  async onClaim(): Promise<void> {
    runInAction(() => (this.claimVisible = false))
    await this.confirmationStore.run(() =>
      asSender(CONTRACT_DEPLOYER)
        .contract("auto-alex-buyback-helper")
        .func("claim")
        .call(
          {
            amount: this.availableAtAlexV1ToMigrate$
              .multipliedBy(1e8)
              .toNumber(),
          },
          [
            transfer(
              this.autoStore.authStore.stxAddress$,
              Currency.ATALEX,
              this.availableAtAlexV1ToMigrate$.toNumber(),
            ),
            transfer(
              contractAddr("auto-alex-buyback-helper"),
              Currency.ALEX,
              this.receivingAlexAmount$.toNumber(),
            ),
          ],
        ),
    )
  }
}

export default MigrationV2Module
