export function trunc(
  str: string,
  {
    longerThan = 8,
    head = 4,
    tail = 3,
  }: { head?: number; tail?: number; longerThan?: number } = {},
): string {
  if (str.length > longerThan) {
    return `${str.substring(0, head)}....${str.substring(str.length - tail)}`
  }
  return str
}

export function assetIdentifierEquals(a: string, b: string): boolean {
  if (a === b) {
    return true
  }
  // SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9.token-wbtc
  const sameFungibleToken = a.split("::")[0] === b.split("::")[0]
  // SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9.token-wbtc.token-amm-swap-pool-v1-1::amm-swap-pool-v1-1/token-wstx,token-wvibes,1e8
  const sameSemiFungibleTokenId = a.split("/")[1] === b.split("/")[1]
  return a === b || (sameFungibleToken && sameSemiFungibleTokenId)
}

export function contractNameFromAssetIdentifier(a: string): undefined | string {
  return a.split("::")[0]?.split(".")[1]
}
