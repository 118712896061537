/**
 * Copy from alex-cloud/packages/libs/types/src/lib/db.types.ts
 *
 * 2023-03-31
 */

export enum BridgeAssets {
  susdt = "susdt",
  xbtc = "xbtc",
  usdt = "usdt",
  btc = "btc",
}

export enum BridgeChains {
  stacks = "stacks",
  ethereum = "ethereum",
  sepolia = "sepolia",
  bsc = "bnb",
  bscTestnet = "bnb_testnet",
}

export enum BridgeRoutes {
  user_to_alex = "user_to_alex",
  alex_to_user = "alex_to_user",
}

export enum OnchainStatus {
  pending = "pending",
  confirmed = "confirmed",
  failed = "failed",
}

export enum ProcessingStatus {
  pending = "pending",
  processed = "processed",
  failed = "failed",
}
