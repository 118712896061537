import AuthStore from "../../../stores/authStore/AuthStore"
import { fetchTransaction } from "../../../utils/alexjs/fetchTransaction"
import { retryWhenBlockChangedUntil } from "../../../utils/retryWhenBlockChangedUntil"

const successSample = {
  success: true,
  code: 0,
  account: "ST3DNHSRVVT9BJEG2A7VTD06F8PJNAS9YAVWT8N1G",
  txid: "7e19d12c6ab16d8df86d28bcf56d4a1f115263ce274905b4657af882833bc710",
  amount: { wbtc: 5, usda: 500000, stx: 250, alex: 50 },
}

export async function claimTestTokens(
  authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
): Promise<{
  account: string
  stx: number
  wbtc: number
  usda: number
  alex: number
}> {
  const response = await fetch("https://alextest.xyz/showMeTheMoneyV2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      account: authStore.stxAddress$,
    }),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  // const data = successSample;
  const data = (await response.json()) as typeof successSample
  if (!data.success) {
    throw new Error((data as any).message)
  }
  await retryWhenBlockChangedUntil(
    authStore,
    () => fetchTransaction(data.txid),
    tx => tx.tx_status !== "pending",
  )
  return {
    account: data.account,
    stx: data.amount.stx,
    wbtc: data.amount.wbtc,
    usda: data.amount.usda,
    alex: data.amount.alex,
  }
}
