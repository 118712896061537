import { AddLiquidityRowData } from "../../store/transformerGroups/pool/addLiquidity"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const addLiquidityExporter: Exporter<AddLiquidityRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.currencyAmountX, rowData.currencyAmountY],
    toCurrencyAmounts: [rowData.lpCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = "Add Liquidity"
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
