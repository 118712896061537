export type Bound = "top" | "right" | "bottom" | "left"

export interface OversteppedDistances {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

export const screenEdgeBound = (padding?: {
  top?: number
  bottom?: number
  left?: number
  right?: number
}): {
  top: number
  left: number
  bottom: number
  right: number
} => {
  return {
    top: padding?.top ?? 0,
    left: padding?.left ?? 0,
    bottom:
      (window.innerHeight || document.documentElement.clientHeight) -
      (padding?.bottom ?? 0),
    right:
      (window.innerWidth || document.documentElement.clientWidth) -
      (padding?.right ?? 0),
  }
}

export const getOversteppedViewportDistances = (
  bounding: { top: number; left: number; bottom: number; right: number },
  screenBound: { top: number; bottom: number; left: number; right: number },
): OversteppedDistances => {
  const distances: OversteppedDistances = {}

  const topDistance = bounding.top - screenBound.top
  if (topDistance < 0) {
    distances.top = -topDistance
  }

  const leftDistance = bounding.left - screenBound.left
  if (leftDistance < 0) {
    distances.left = -leftDistance
  }

  const bottomDistance = bounding.bottom - screenBound.bottom
  if (bottomDistance > 0) {
    distances.bottom = bottomDistance
  }

  const rightDistance = bounding.right - screenBound.right
  if (rightDistance > 0) {
    distances.right = rightDistance
  }

  return distances
}

export const getSuggestedKeepInViewportSize = (
  bounding: {
    top: number
    left: number
    bottom: number
    right: number
    width: number
    height: number
  },
  padding?: { top?: number; bottom?: number; left?: number; right?: number },
): { width: number; height: number } => {
  const screenBound = screenEdgeBound(padding)
  const distances = getOversteppedViewportDistances(bounding, screenBound)

  if (Object.keys(distances).length === 0) {
    return {
      width: screenBound.right - bounding.left,
      height: screenBound.bottom - bounding.top,
    }
  }

  return {
    width: bounding.width - (distances.left ?? 0) - (distances.right ?? 0),
    height: bounding.height - (distances.top ?? 0) - (distances.bottom ?? 0),
  }
}

export const isElementInViewport = (
  el: HTMLElement,
  padding?: { top?: number; bottom?: number; left?: number; right?: number },
): boolean => {
  return (
    Object.keys(
      getOversteppedViewportDistances(
        el.getBoundingClientRect(),
        screenEdgeBound(padding),
      ),
    ).length === 0
  )
}
