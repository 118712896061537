import { FC, ReactNode } from "react"
import {
  IntlShape,
  MessageDescriptor,
  defineMessage,
  useIntl,
} from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { NavLink } from "../../../../../components/NavLink"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../../components/NoteParagraph/NoteParagraph"
import { BalanceBottomArea } from "../../../../../components/TokenInput/BalanceBottomArea"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenNameSelectTrigger } from "../../../../../components/TokenInput/TokenNameSelect"
import { Button } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  SuspenseResource,
  safeReadResource,
  suspenseResource,
} from "../../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { waitUntilExist$ } from "../../../../../utils/waitFor"
import { normal } from "../../../../Perpetual/components/buttonPresets"
import { RectButton } from "../../../../Perpetual/components/wrappedCommonComponents/RectButton"
import { QueuedSection } from "../_/QueuedSection"
import { WarningSection } from "../_/WarningSection"
import { AddressSection } from "./_/AddressSection"
import { DetailSection } from "./_/DetailSection"

export type FormErrorDetail =
  | MessageDescriptor
  | ((intl: IntlShape) => ReactNode)

export const PegOutBrc20ModalContent: FC<{
  queuingPegRequestCount: SuspenseResource<number>
  formErrorMessage?: SuspenseResource<undefined | FormErrorDetail>
  brc20TokenBalance: SuspenseResource<number>
  brc20Token: SuspenseResource<TokenInfo | undefined>
  pegOutAmount: SuspenseResource<null | number>
  onPegOutAmountChange: (newAmount: null | number) => void
  onPressMax: SuspenseResource<() => void>
  targetBtcAddress?: SuspenseResource<string>
  onTargetBtcAddressChange: (newAddress: null | string) => void
  candidateTargetBtcAddress: SuspenseResource<string | undefined>
  onFillCandidateTargetBtcAddress: SuspenseResource<undefined | (() => void)>
  pegOutFeeToken: SuspenseResource<TokenInfo>
  pegOutFee: SuspenseResource<number>
  pegOutFeeRate: SuspenseResource<number>
  pegOutGasFeeToken: SuspenseResource<TokenInfo>
  pegOutGasFee: SuspenseResource<number>
  onSelectBrc20Token: () => void
  onWithdrawBrc20: SuspenseResource<() => void>
  onSubmit: SuspenseResource<undefined | (() => Promise<void>)>
  onClose: () => void
}> = props => {
  const intl = useIntl()
  const { $t } = intl

  const formErrorMessage = safeReadResource(props.formErrorMessage)

  return (
    <CardModalContent
      className="flex flex-col gap-4"
      bgClassName="bg-gray-800"
      width={640}
      title={$t(
        defineMessage({
          defaultMessage: "To Peg-out your BRC-20",
          description:
            "/Orderbook/BRC20 Peg Out Flow/PegOutBrc20ModalContent/Title text",
        }),
      )}
      onClose={props.onClose}
    >
      <QueuedSection
        queuingPegRequestCount={props.queuingPegRequestCount}
        warningText={$t(
          defineMessage({
            defaultMessage:
              "ALEX Orderbook could only proceed 100 peg in/out request every 24hrs.",
            description:
              "/Orderbook/BRC20 Peg Out Flow/QueuedSection/Warning text",
          }),
        )}
      />
      <div className={"flex flex-col gap-1"}>
        <NoteParagraph
          icon={<WarnIcon />}
          textColorClassName={"text-yellow-500"}
        >
          {$t(
            defineMessage({
              defaultMessage:
                "Before you peg-out, withdraw assets from your Trading Account. <link>Withdraw BRC-20</link>",
              description:
                "/Orderbook/BRC20 Peg Out Flow/NoteParagraph/Description text",
            }),
            {
              link: dontWrapObserver(children => (
                <Button
                  className={"text-blue-600 inline"}
                  Variant={HeadlessButton}
                  onClick={safeReadResource(props.onWithdrawBrc20)}
                >
                  {children}
                </Button>
              )),
            },
          )}
        </NoteParagraph>

        <TokenInput
          tokenNameArea={
            <TokenNameSelectTrigger
              token={safeReadResource(props.brc20Token)}
              onClick={props.onSelectBrc20Token}
            />
          }
          token={suspenseResource(() =>
            waitUntilExist$(() => safeReadResource(props.brc20Token)),
          )}
          onChange={props.onPegOutAmountChange}
          value={props.pegOutAmount}
          bottomArea={
            <BalanceBottomArea
              token={props.brc20Token}
              balance={props.brc20TokenBalance}
              onPressMax={props.onPressMax}
            />
          }
        />
      </div>
      <AddressSection
        candidateTargetBtcAddress={props.candidateTargetBtcAddress}
        onFillCandidateTargetBtcAddress={safeReadResource(
          props.onFillCandidateTargetBtcAddress,
        )}
        targetBtcAddress={props.targetBtcAddress}
        onTargetBtcAddressChange={props.onTargetBtcAddressChange}
      />
      <DetailSection
        pegOutFeeToken={props.pegOutFeeToken}
        pegOutFee={props.pegOutFee}
        pegOutFeeRate={props.pegOutFeeRate}
        pegOutGasFeeToken={props.pegOutGasFeeToken}
        pegOutGasFee={props.pegOutGasFee}
      />
      <WarningSection>
        {$t(
          defineMessage({
            defaultMessage: `
<line>Once you peg out your BRC-20, ALEX protocol will process your peg-out request automatically to your BTC address.</line>
<line>If you haven't received your BRC20 token in your BTC address within 24hrs after your transaction confirmed on Stacks chain, Please contact mods to reports issue on Discord.</line>`,
            description:
              "/Orderbook/BRC20 Peg Out Flow/WarningSection/Description text",
          }),
          {
            line: dontWrapObserver(children => (
              <p className="text-yellow-500 text-xs">{children}</p>
            )),
            link: dontWrapObserver(children => (
              <NavLink
                className="text-blue-500"
                to={"https://discord.gg/alexlab"}
              >
                {children}
              </NavLink>
            )),
          },
        )}
      </WarningSection>
      <RectButton
        className={"flex items-center space-x-2.5"}
        Variant={GradientFilledButton}
        {...normal}
        disabled={!safeReadResource(props.onSubmit)}
        onClick={safeReadResource(props.onSubmit)}
      >
        <p className="text-center flex-1">
          {formErrorMessage == null
            ? $t(
                defineMessage({
                  defaultMessage: "Peg out",
                  description:
                    "/Orderbook/BRC20 Peg Out Flow/PegOutBrc20ModalContent/Button text",
                }),
              )
            : typeof formErrorMessage === "function"
            ? formErrorMessage(intl)
            : $t(formErrorMessage)}
        </p>
      </RectButton>
    </CardModalContent>
  )
}
