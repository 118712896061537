import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { APowerRatioProvider } from "../../components/APowerTip/APowerTip"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import {
  safeReadResource,
  suspenseResource,
} from "../../utils/SuspenseResource"
import { Currency } from "../../utils/alexjs/Currency"
import { poolMigration } from "../../utils/alexjs/PoolMigrations"
import {
  LiquidityPoolToken,
  dualYieldToken,
  isDualYieldPool,
  isFWPToken,
} from "../../utils/alexjs/currencyHelpers"
import { isNotNull } from "../../utils/utils"
import { CopywritingProvider } from "../Stake/components/CopywritingProvider"
import { WiredAPowerAlert } from "../Stake/components/WiredAPowerAlert"
import { StakableCurrency } from "../Stake/store/manualStaking/ManualStakeStore.service"
import { StakeCycleStatus } from "../Stake/types"
import { FarmingCopywriting } from "./_/FarmingCopywriting"
import {
  AllFarmsPanel,
  OtherFarmItem,
} from "./components/AllFarmsPanel/AllFarmsPanel"
import { MyFarmsPanel } from "./components/MyFarmsPanel/MyFarmsPanel"
import { FarmStoreProvider, useFarmStore } from "./stores/useFarmStore"

const WiredMyFarmsPanel: FC<{ className?: string }> = props => {
  const store = useFarmStore()
  const currencyStore = useCurrencyStore()
  return (
    <MyFarmsPanel
      className={props.className}
      farms={suspenseResource(() =>
        store.myFarms$.map(f => {
          const stackedCount =
            f.myStaking.activeStaking +
            (f.token$ === Currency.FWP_STX_ALEX_50_50_V1_01
              ? store.coFarmStore.nextCycleBalance$
              : 0)
          return {
            cycleStatus: StakeCycleStatus.InProgress,
            aprs: [
              { percentage: f.myStaking.stakingAPR$, token: f.alexTokenInfo$ },
              f.dualYield
                ? {
                    token: f.dualYield.tokenInfo$,
                    percentage:
                      f.myStaking.stakingAPR$ *
                      (f.dualYield.aprMultiplier$ - 1),
                  }
                : undefined,
              f.dualYieldV1_1
                ? {
                    token: f.dualYieldV1_1.tokenInfo$,
                    percentage:
                      f.myStaking.stakingAPR$ *
                      (f.dualYieldV1_1.aprMultiplier$ - 1),
                  }
                : undefined,
            ].filter(isNotNull),
            liquidityPoolToken: f.tokenInfo$,
            stackedCount: stackedCount,
            indicativeValue: suspenseResource(
              () => currencyStore.getPrice$(f.stakableToken) * stackedCount,
            ),
            reward: f.rewardInfos$,
          }
        }),
      )}
    />
  )
}

const WiredAllFarmsPanel: FC<{ className?: string }> = props => {
  const store = useFarmStore()
  const currency = useCurrencyStore()

  return (
    <AllFarmsPanel
      className={props.className}
      recommendedFarms={suspenseResource(() =>
        store.allPoolTokens$
          .map(pool => ({
            liquidityPoolToken: currency.getTokenInfo$(pool),
            myBalanceCount: suspenseResource(() => store.getBalance$(pool)),
            apr: store.aprForPool$(pool),
          }))
          .filter(f => safeReadResource(f.myBalanceCount)! > 0),
      )}
      otherFarms={suspenseResource(() => {
        function getDetail(pool: StakableCurrency): OtherFarmItem {
          return {
            isDualYield: isFWPToken(pool) && isDualYieldPool(pool),
            liquidityPoolToken: currency.getTokenInfo$(pool),
            cycleStatus: StakeCycleStatus.InProgress,
            liquidityCount: store.poolStats$(pool)?.liquidity,
            rewardTokens: [
              currency.getTokenInfo$(Currency.ALEX),
              isFWPToken(pool) && isDualYieldPool(pool)
                ? currency.getTokenInfo$(dualYieldToken(pool))
                : null,
              currency.getTokenInfo$(Currency.APOWER),
            ].filter(isNotNull),
            totalApr: store.aprForPool$(pool),
            tradingFee: store.poolStats$(pool)?.fees,
            isMigratingPool: pool in poolMigration,
            isMainPool: Object.values(poolMigration).includes(
              pool as LiquidityPoolToken,
            ),
          }
        }
        return store.allPoolTokens$
          .filter(x => !(x in poolMigration))
          .map(pool => ({
            mainPool: getDetail(pool),
            legacyPools: Object.entries(poolMigration)
              .filter(
                ([k, v]) =>
                  v === pool &&
                  store.allPoolTokens$.includes(k as StakableCurrency),
              )
              .map(([k, _]) => getDetail(k as StakableCurrency)),
          }))
      })}
    />
  )
}

const PageContent: FC = () => {
  const store = useFarmStore()
  const intl = useIntl()
  return (
    <APowerRatioProvider
      ratio={suspenseResource(() => store.apowerMultiplier$)}
    >
      <CopywritingProvider value={new FarmingCopywriting(intl)}>
        <div className={"flex flex-col gap-5"}>
          <WiredAPowerAlert />
          <WiredMyFarmsPanel />
          <WiredAllFarmsPanel />
        </div>
      </CopywritingProvider>
    </APowerRatioProvider>
  )
}

export const FarmListPage: FC = () => {
  const { $t } = useIntl()
  return (
    <FarmStoreProvider>
      <PageStackPage
        title={
          <PrimaryPageTitle
            subtitle={$t(
              defineMessage({
                defaultMessage: "Stake LP Tokens to earn more rewards.",
                description: "/Farm/ListPage subtitle",
              }),
            )}
          >
            {$t(
              defineMessage({
                defaultMessage: "Farm",
                description: "/farm/ListPage/title",
              }),
            )}
          </PrimaryPageTitle>
        }
      >
        <PageContent />
      </PageStackPage>
    </FarmStoreProvider>
  )
}
