import { action, makeObservable, observable } from "mobx"
import { defineMessage } from "react-intl"
import type {
  TransactionStateForStacks,
  TransactionStateMessageRenderer,
} from "../../components/TransactionStateDialog/TransactionStateDialog"
import {
  ADDRESS_PREFIX_IN_TX_ID,
  EXPLORER_ADDR_URL,
  EXPLORER_TX_URL,
  TG_SUB_URL,
} from "../../config"
import { CancelError } from "../../utils/error"

export class ConfirmTransactionStore {
  constructor() {
    makeObservable(this)
  }

  @observable running?: TransactionStateForStacks

  @action closeRunning(): void {
    this.running = undefined
  }

  @action startRunning(msg?: TransactionStateMessageRenderer): void {
    this.running = {
      type: "running",
      msg,
    }
  }

  @action successRunning(transactionId: string): void {
    this.running = {
      type: "success",
      explorerLink: transactionId.startsWith(ADDRESS_PREFIX_IN_TX_ID)
        ? EXPLORER_ADDR_URL(transactionId.slice(ADDRESS_PREFIX_IN_TX_ID.length))
        : EXPLORER_TX_URL(transactionId),
      telegramSubscribeLink: transactionId.startsWith(ADDRESS_PREFIX_IN_TX_ID)
        ? undefined
        : TG_SUB_URL(transactionId),
    }
  }

  @action errorRunning(e: Error): void {
    if (e instanceof CancelError) {
      this.running = undefined
    } else {
      this.running = {
        type: "error",
        error: ({ intl }) =>
          intl.$t(
            defineMessage({
              defaultMessage: "Error: {error}",
              description: "Common/Transaction Broadcast Modal/error message",
            }),
            {
              error: "message" in e ? e.message : "Unknown error",
            },
          ),
      }
    }
  }

  async run(fn: () => Promise<{ txId: string }>): Promise<void> {
    try {
      this.startRunning()
      const { txId } = await fn()
      this.successRunning(txId)
    } catch (e) {
      this.errorRunning(e as Error)
    }
  }
}
