import { action } from "mobx"
import React, { FC } from "react"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../components/Modal"
import { WiredSlippageModal } from "../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../components/Spensor"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Result } from "../../utils/Result"
import { suspenseResource } from "../../utils/SuspenseResource"
import { ConfirmClaimModalContent } from "./components/depositPage/ConfirmClaimModalContent/ConfirmClaimModalContent"
import { ConfirmRolloverModalContent } from "./components/depositPage/ConfirmRolloverModalContent/ConfirmRolloverModalContent"
import { ConfirmSellDepositModalContent } from "./components/depositPage/ConfirmSellDepositModalContent/ConfirmSellDepositModalContent"
import { MyDepositsPanel } from "./components/depositPage/MyDepositsPanel"
import { SellDepositModalContent } from "./components/depositPage/SellDepositModalContent/SellDepositModalContent"
import { useLendStore } from "./store/useLendStore"

export const WiredMyDepositsPanel: FC = () => {
  const store = useLendStore()
  const chain = useChainStore()
  const currency = useCurrencyStore()
  const yToken = currency.getTokenInfo$(store.yieldToken)
  const uToken = currency.getTokenInfo$(store.underlyingToken)
  const myDeposit = store.myDeposit
  return (
    <>
      <MyDepositsPanel
        defaultCollapsed={store.yieldTokenBalance$ === 0}
        depositRecords={[
          {
            status: "active" as const,
            yieldToken: yToken,
            intrinsicToken: uToken,
            yieldTokenCount: myDeposit.pendingBalance$,
            intrinsicTokenCount: myDeposit.pendingBalance$,
            expiredAt: chain.estimatedDateForBlock$(store.currentExpiry$),
            expiredAtBlock: store.currentExpiry$,
            onSell: action(() => (myDeposit.sellingActiveDeposit = true)),
          },
          {
            status: "expired" as const,
            yieldToken: yToken,
            intrinsicToken: uToken,
            yieldTokenCount: store.claimableYieldToken$,
            intrinsicTokenCount: store.claimableYieldToken$,
            onClaim: action(() => (myDeposit.claimConfirming = true)),
            onRollover: action(() => (myDeposit.rolloverConfirming = true)),
          },
        ].filter(a => a.yieldTokenCount > 0)}
      />
      <Spensor>
        {() => (
          <Modal
            visible={myDeposit.claimConfirming}
            onClose={action(() => (myDeposit.claimConfirming = false))}
          >
            <ConfirmClaimModalContent
              claimToken={uToken}
              yieldToken={yToken}
              claimTokenCount={store.claimableYieldToken$}
              yieldTokenCount={store.claimableYieldToken$}
              onClose={action(() => (myDeposit.claimConfirming = false))}
              onConfirm={() => myDeposit.claim()}
            />
          </Modal>
        )}
      </Spensor>
      <WiredRollOverModal />
      <WiredSellModal />
      <WiredTransactionStateDialog store={myDeposit.txStore} />
    </>
  )
}

const WiredSellModal: FC = () => {
  const store = useLendStore()
  const currency = useCurrencyStore()
  const yToken = currency.getTokenInfo$(store.yieldToken)
  const uToken = currency.getTokenInfo$(store.underlyingToken)
  const { myDeposit } = store

  return (
    <>
      <Spensor>
        {() => (
          <Modal
            visible={myDeposit.sellingActiveDeposit}
            onClose={action(() => (myDeposit.sellingActiveDeposit = false))}
          >
            <SellDepositModalContent
              yieldToken={yToken}
              depositToken={uToken}
              yieldTokenCount={myDeposit.sellAmount.get() ?? null}
              setYieldTokenCount={a => myDeposit.sellAmount.set(a ?? undefined)}
              sellableYieldTokenBalance={suspenseResource(
                () => myDeposit.pendingBalance$,
              )}
              yieldTokenEstimatedUSD={suspenseResource(() => {
                // We don't have price info on GraphQL
                return myDeposit.sellingAmountInUSD$
                // return myDeposit.sellAmount.read$ *
                //   currency.getPrice$(store.yieldToken);
              })}
              depositTokenCount={suspenseResource(
                () => myDeposit.sellingUTokenAmount$,
              )}
              depositTokenEstimatedUSD={suspenseResource(
                () =>
                  myDeposit.sellingUTokenAmount$ *
                  currency.getPrice$(store.underlyingToken),
              )}
              liquidityProviderFee={suspenseResource(() => ({
                token: uToken,
                count: myDeposit.liquidityFee$,
              }))}
              slippage={myDeposit.sellSlippage.slippagePercentage}
              error={suspenseResource(() =>
                Result.maybeError(myDeposit.sellFormData$),
              )}
              onClose={() => myDeposit.cancelSellingActive()}
              onClickSlippage={() =>
                myDeposit.sellSlippage.showSlippagePopModal()
              }
              onSubmit={action(
                () => (myDeposit.sellingActiveDepositConfirming = true),
              )}
              onClear={() => myDeposit.sellAmount.set(undefined)}
            />
          </Modal>
        )}
      </Spensor>

      <Spensor>
        {() => (
          <Modal
            visible={myDeposit.sellingActiveDepositConfirming}
            onClose={action(
              () => (myDeposit.sellingActiveDepositConfirming = false),
            )}
          >
            <ConfirmSellDepositModalContent
              yieldAssets={{
                token: yToken,
                count: 1,
              }}
              depositAssets={{
                token: uToken,
                count: myDeposit.sellingUTokenAmount$,
              }}
              liquidityProviderFee={{
                token: uToken,
                count: myDeposit.liquidityFee$,
              }}
              slippage={myDeposit.sellSlippage.slippagePercentage}
              onClose={action(
                () => (myDeposit.sellingActiveDepositConfirming = false),
              )}
              onConfirm={async () => {
                const form = Result.maybeValue(myDeposit.sellFormData$)
                if (form != null) {
                  await myDeposit.sellPending(form)
                }
              }}
              yieldTokenPrice={{
                toCrypto: {
                  token: uToken,
                  count: myDeposit.uTokenPerYToken$,
                },
                toUSD: myDeposit.yieldTokenUSDPrice$,
              }}
            />
          </Modal>
        )}
      </Spensor>

      <WiredSlippageModal slippagePercent={myDeposit.sellSlippage} />
    </>
  )
}

const WiredRollOverModal: FC = () => {
  const store = useLendStore()
  const currency = useCurrencyStore()
  const chain = useChainStore()
  const yToken = currency.getTokenInfo$(store.yieldToken)
  const uToken = currency.getTokenInfo$(store.underlyingToken)
  return (
    <>
      <Modal
        visible={store.myDeposit.rolloverConfirming}
        onClose={action(() => (store.myDeposit.rolloverConfirming = false))}
      >
        <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
          {() => (
            <ConfirmRolloverModalContent
              depositToken={uToken}
              prevCycleYieldToken={yToken}
              nextCycleYieldToken={yToken}
              prevCycleYieldTokenCount={store.claimableYieldToken$}
              nextCycleYieldTokenCount={
                store.addDeposit.yieldTokenPerUnderlyingToken$ *
                store.claimableYieldToken$
              }
              tokenSwapRoute={[yToken, uToken, yToken]}
              expirationDate={chain.estimatedDateForBlock$(
                store.currentExpiry$,
              )}
              expirationBlockHeight={store.currentExpiry$}
              estimateApr={store.addDeposit.estimateAPR$}
              estimateClaimableValue={
                store.addDeposit.yieldTokenPerUnderlyingToken$ *
                store.claimableYieldToken$
              }
              slippage={store.myDeposit.rollOverSlippage.slippagePercentage}
              liquidityProviderFee={
                store.myDeposit.rollOverLiquidityProviderFee$
              }
              onEditSlippage={() =>
                store.myDeposit.rollOverSlippage.showSlippagePopModal()
              }
              onClose={action(
                () => (store.myDeposit.rolloverConfirming = false),
              )}
              onConfirm={() => store.myDeposit.rollOver()}
            />
          )}
        </Spensor>
      </Modal>
      <WiredSlippageModal slippagePercent={store.myDeposit.rollOverSlippage} />
    </>
  )
}
