import clsx from "clsx"
import { FC, ReactNode } from "react"
import { GradientText } from "../GradientText"
import styles from "./StepsBar.module.scss"

export const StepsBar: FC<{
  className?: string
  current: number
  steps: {
    aboveText: ReactNode
    belowText: ReactNode
  }[]
}> = props => (
  <ul className={clsx(styles.stepList, props.className)}>
    {props.steps.map((step, idx) => (
      <li
        key={idx}
        className={clsx(
          styles.step,
          idx === props.current
            ? styles.currentStep
            : idx < props.current
            ? styles.archivedStep
            : styles.futureStep,
        )}
      >
        <div className={clsx("", styles.stepContentContainer)}>
          <div className={styles.aboveText}>
            {idx === props.current ? (
              <GradientText
                gradientStyle={
                  "linear-gradient(89.81deg, #1D4ED8 0.17%, #A855F7 99.83%)"
                }
              >
                {step.aboveText}
              </GradientText>
            ) : (
              step.aboveText
            )}
          </div>
          <div className={styles.centerContent}>
            <div className={styles.centerIcon}>{idx + 1}</div>
            <div className={styles.stepLine} />
          </div>
          <div className={styles.belowText}>{step.belowText}</div>
        </div>
      </li>
    ))}
  </ul>
)
