import detectEthereumProvider from "@metamask/detect-provider"
import { Observable } from "rxjs"
import { MetaMaskEthereumProviderBasic } from "./MetaMaskEthereumProviderBasic"
import { MetaMaskEthereumProviderNativeEvents } from "./MetaMaskEthereumProviderNativeEvents"
import { MetaMaskEthereumProviderRequests } from "./MetaMaskEthereumProviderRequests"
import {
  MetaMaskEthereumProviderExtraRequests,
  MetaMaskEthereumProviderSubscriptionMessages,
} from "./MetaMaskEthereumProviderSubscription"

export type MetaMaskEthereumProvider = MetaMaskEthereumProviderBasic &
  MetaMaskEthereumProviderNativeEvents<MetaMaskEthereumProviderSubscriptionMessages> &
  MetaMaskEthereumProviderRequests<MetaMaskEthereumProviderExtraRequests> & {
    providers?: MetaMaskEthereumProvider[]
  }

export const getMetaMaskEthereumProvider = (): Promise<
  undefined | MetaMaskEthereumProvider
> => {
  return new Promise((resolve, reject) => {
    let settled = false

    const cleanup = (): void => {
      settled = true
      setTimeout(() => {
        sub.unsubscribe()
      })
    }

    const sub = getMetaMaskEthereumProvider$().subscribe({
      next(v) {
        if (v == null) return
        resolve(v)
        cleanup()
      },
      error(err) {
        reject(err)
        cleanup()
      },
      complete() {
        if (!settled) {
          resolve(undefined)
          cleanup()
        }
      },
    })
  })
}

export const getMetaMaskEthereumProvider$ = (): Observable<
  undefined | MetaMaskEthereumProvider
> => {
  return new Observable(ob => {
    let provider: undefined | MetaMaskEthereumProvider

    detectEthereumProvider()
      .then(_provider => {
        const ethereum: MetaMaskEthereumProvider = window.ethereum as any
        const providers =
          ethereum.providers == null ? [ethereum] : ethereum.providers
        const metamaskProvider = providers.find(p => p.isMetaMask)

        if (metamaskProvider != null) {
          return metamaskProvider
        } else {
          return undefined
        }
      })
      .then(_provider => {
        provider = _provider
        ob.next(_provider)
        ob.complete()
      })
      .catch(err => ob.error(err))

    setTimeout(() => {
      const isPromiseResolvedImmediately = provider != null
      if (!isPromiseResolvedImmediately) {
        ob.next(undefined)
      }
    })
  })
}
