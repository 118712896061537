import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../components/Spensor"
import { PathGenerator, usePathGenerator } from "../../routes/routes"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { SystemMessageList } from "./components/SystemMessageList"
import { ReactComponent as EmptyIcon } from "./components/TransactionsTable/empty.svg"
import { SYSTEM_MESSAGE_FILTER } from "./constants"
import { SystemMessages } from "./types"

export const WiredSystemMessageList: FC = () => {
  const store = useNotifyStore()
  const gen = usePathGenerator()
  const { $t } = useIntl()

  return store.filter === SYSTEM_MESSAGE_FILTER ? (
    <Spensor fallback={<LoadingIndicator className="m-auto" />}>
      {() => (
        <>
          <SystemMessageList
            data={store.systemMessages.messages$.map(value => ({
              ...value,
              link: getLink(gen, value.type)(value) ?? "",
            }))}
          />
          {store.systemMessages.count$ === 0 && (
            <div className="flex flex-col gap-y-4 items-center justify-center py-20">
              <EmptyIcon />
              <span className="text-base leading-6 font-normal text-gray-600">
                {$t(
                  defineMessage({
                    defaultMessage: "No data",
                    description: "/Notify/systemMessages/empty",
                  }),
                )}
              </span>
            </div>
          )}
        </>
      )}
    </Spensor>
  ) : null
}

function getLink(
  gen: PathGenerator,
  type: SystemMessages.Types,
): (data: SystemMessages.Data) => string {
  const map: {
    [type in SystemMessages.Types]: (data: SystemMessages.Data) => string
  } = {
    [SystemMessages.Types.StakingHarvest]: () => gen.stake(),
    [SystemMessages.Types.FarmingHarvest]: data => gen.farmDetail(data.token),
    [SystemMessages.Types.DepositClaim]: data =>
      gen.lendDetailDeposit(data.token),
    [SystemMessages.Types.BorrowClaim]: data =>
      gen.lendDetailBorrow(data.token),
  }
  return map[type]
}
