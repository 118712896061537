import { FC } from "react"
import { CollapsableCard } from "../../../../components/CollapsableCard/CollapsableCard"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../components/NavLink"
import { Spensor } from "../../../../components/Spensor"
import { usePathGenerator } from "../../../../routes/routes"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { useAsyncFnFactory } from "../../../../utils/useAsync"
import { LoadMoreButton } from "../../../Stake/components/LoadMoreButton/LoadMoreButton"
import { StakeCycleStatus } from "../../../Stake/types"
import { ListContainer } from "../ListContainer"
import { OtherFarmListRow, OtherFarmListRowHeader } from "./OtherFarmList"
import { RecommendedFarmListItem } from "./RecommendedFarmList"

export interface RecommendedFarm {
  liquidityPoolToken: TokenInfo
  myBalanceCount: SuspenseResource<number>
  apr?: number
}

export interface OtherFarmItem {
  liquidityPoolToken: TokenInfo
  liquidityCount?: number
  totalApr?: number
  tradingFee?: number
  rewardTokens: TokenInfo[]
  cycleStatus: StakeCycleStatus
  isDualYield?: boolean
  isMainPool?: boolean
  isMigratingPool?: boolean
}

export interface OtherFarm {
  mainPool: OtherFarmItem
  legacyPools: OtherFarmItem[]
}

export const AllFarmsPanel: FC<{
  className?: string
  recommendedFarms: SuspenseResource<RecommendedFarm[]>
  otherFarms: SuspenseResource<OtherFarm[]>
  isHasMoreOtherFarms?: boolean
  onLoadMoreOtherFarms?: () => Promise<void>
}> = props => {
  const gen = usePathGenerator()

  const propOnLoadMoreOtherFarms = props.onLoadMoreOtherFarms
  const [loadMoreReq, loadMore] = useAsyncFnFactory(
    () => async () => propOnLoadMoreOtherFarms?.(),
    [propOnLoadMoreOtherFarms],
  )

  return (
    <CollapsableCard className={props.className} title={"All Farms"}>
      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() => {
          const recommendedFarms = readResource(props.recommendedFarms)
          return (
            <div className={"flex flex-col items-center gap-4"}>
              {recommendedFarms.length > 0 && (
                <div className={"w-full overflow-x-auto"}>
                  <ListContainer className={"min-w-[750px]"}>
                    {recommendedFarms.map((f, i) => (
                      <NavLink
                        key={i}
                        to={gen.farmDetail(f.liquidityPoolToken)}
                      >
                        <RecommendedFarmListItem
                          className={"w-full"}
                          farm={f}
                        />
                      </NavLink>
                    ))}
                  </ListContainer>
                </div>
              )}

              <div className={"w-full overflow-x-auto"}>
                <div className={"min-w-[900px]"}>
                  <OtherFarmListRowHeader />

                  {readResource(props.otherFarms).map(f => (
                    <OtherFarmListRow
                      key={f.mainPool.liquidityPoolToken.id}
                      className={"w-full"}
                      farm={f}
                    />
                  ))}
                </div>
              </div>

              {props.isHasMoreOtherFarms && (
                <LoadMoreButton
                  loading={loadMoreReq.loading}
                  onLoadMore={loadMore}
                />
              )}
            </div>
          )
        }}
      </Spensor>
    </CollapsableCard>
  )
}
