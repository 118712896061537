import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../../components/Card"
import { Spensor } from "../../../../components/Spensor"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { assertNever } from "../../../../utils/types"
import { LotteryStage, LotteryStageType } from "../types"

export const ActionPanelFrame: FCC<{
  className?: string
  round: SuspenseResource<number>
  lotteryStage: SuspenseResource<LotteryStage>
  currentBlock: SuspenseResource<number>
}> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx("flex flex-col gap-4", props.className)}>
      <div className={"flex flex-wrap gap-2.5 justify-between"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Current Round",
              description: "/Lottery/ActionPanelFrame/Card Title",
            }),
          )}
        </CardTitle>
        <Spensor>
          {() => {
            const currentBlock = readResource(props.currentBlock)
            const status = readResource(props.lotteryStage)
            const round = readResource(props.round)
            if (status.type === LotteryStageType.BuyTicket) {
              return (
                <Countdown
                  prefix={$t(
                    defineMessage({
                      defaultMessage: "#{round} Draw begins in",
                      description: "/Lottery/ActionPanelFrame/Countdown text",
                    }),
                    { round },
                  )}
                  currentBlock={currentBlock}
                  lotteryStage={status}
                />
              )
            }
            if (status.type === LotteryStageType.Drawing) {
              return (
                <Countdown
                  prefix={$t(
                    defineMessage({
                      defaultMessage: "#{round} Drawing ends in",
                      description: "/Lottery/ActionPanelFrame/Countdown text",
                    }),
                    { round },
                  )}
                  currentBlock={currentBlock}
                  lotteryStage={status}
                />
              )
            }
            if (status.type === LotteryStageType.Finished) {
              return (
                <Countdown
                  prefix={$t(
                    defineMessage({
                      defaultMessage: "Next round starts in",
                      description: "/Lottery/ActionPanelFrame/Countdown text",
                    }),
                  )}
                  currentBlock={currentBlock}
                  lotteryStage={status}
                />
              )
            }
            assertNever(status.type)
          }}
        </Spensor>
      </div>
      {props.children}
    </Card>
  )
}

const Countdown: FC<{
  className?: string
  prefix: ReactNode
  lotteryStage: LotteryStage
  currentBlock: number
}> = props => {
  const { $t } = useIntl()
  return (
    <Tooltip
      title={$t(
        defineMessage({
          defaultMessage:
            "Start Block: {currentStageStartBlock} / End Block: {nextStageStartBlock}",
          description: "/Lottery/ActionPanelFrame/Countdown tooltip",
        }),
        {
          currentStageStartBlock: props.lotteryStage.currentStageStartBlock,
          nextStageStartBlock: props.lotteryStage.nextStageStartBlock,
        },
      )}
    >
      <BlockTimeCountdown
        prefix={props.prefix}
        currentBlock={props.currentBlock}
        targetBlock={props.lotteryStage.nextStageStartBlock}
        time={props.lotteryStage.nextStageStartDate}
      />
    </Tooltip>
  )
}
