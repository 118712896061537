import clsx from "clsx"
import { FC, ReactNode, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../components/NavLink"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TabBar } from "../../../components/TabBar"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "../../../components/TokenInput/BlockInputContainer"
import { TokenNameBRC20Badge } from "../../../components/TokenName"
import { TokenNameStack } from "../../../components/TokenNameStack"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../utils/SuspenseResource"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { Colors } from "./designTokens"
import { CompactCard } from "./wrappedCommonComponents/CompactCard"

export interface MarketOption {
  priceToken: TokenInfo
  tradeToken: TokenInfo
  tradeTokenLastPrice: SuspenseResource<number>
  tradeToken24HourChangePercentage: SuspenseResource<number>
  link: string
}
export const isSameMarketOption = (
  o1: MarketOption,
  o2: MarketOption,
): boolean => {
  return (
    TokenInfo.isIdentical(o1.priceToken, o2.priceToken) &&
    TokenInfo.isIdentical(o1.tradeToken, o2.tradeToken)
  )
}

export type MarketSelectorTabType = "all" | "brc-20" | "other"

export interface MarketSelectorProps {
  className?: string
  width?: number
  bottomArea?: ReactNode
  selectedTab: MarketSelectorTabType
  onSelectedTab: (newTab: MarketSelectorTabType) => void
  selectedOption: MarketOption
  marketOptions: SuspenseResource<MarketOption[]>
}

export const MarketSelector: FC<MarketSelectorProps> = props => {
  const marginHorizontal = "mx-3"
  const paddingHorizontal = "px-3"

  const borderRadius = 16

  const [query, setQuery] = useState("")

  const markets =
    safeReadResource(props.marketOptions)?.filter(a => {
      if (query) {
        return (a.tradeToken.displayName + "/" + a.priceToken.displayName)
          .toLowerCase()
          .includes(query.toLowerCase())
      }
      return props.selectedTab === "all"
        ? true
        : props.selectedTab === "brc-20"
        ? a.tradeToken.propertyTags.includes("brc-20")
        : !a.tradeToken.propertyTags.includes("brc-20")
    }) ?? []

  return (
    <CompactCard
      className={clsx(
        "flex flex-col",
        "bg-gray-900 backdrop-blur-2xl",
        props.className,
      )}
      roundClassName={""}
      boxClassName={""}
      style={{ borderRadius }}
    >
      <TabBar
        className={marginHorizontal}
        containerClassName="flex-1"
        tabClassName={
          "flex-1 text-center px-4 py-3 text-sm leading-5 font-semibold"
        }
        tabs={[
          { label: "All", value: "all" as const },
          { label: "BRC-20", value: "brc-20" as const },
          { label: "Others", value: "other" as const },
        ]}
        selectedTab={{
          tabValue: props.selectedTab,
          onChange: tab => props.onSelectedTab(tab.value),
        }}
      />

      <BlockInputContainer
        className={clsx("my-2", marginHorizontal)}
        boxClassName={"p-1"}
      >
        {p => (
          <div className="flex items-center gap-x-2">
            <svg
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.8727 14.4307L15.736 10.294C16.514 9.224 16.974 7.908 16.974 6.48667C16.974 2.91 14.064 0 10.4867 0C6.91 0 4 2.91 4 6.48667C4 10.064 6.91 12.9733 10.4867 12.9733C11.8427 12.9733 13.102 12.5553 14.1447 11.8413L18.3033 16L19.8727 14.4307ZM5.90267 6.48667C5.90267 3.95867 7.95933 1.902 10.4873 1.902C13.0153 1.902 15.072 3.95867 15.072 6.48667C15.072 9.01467 13.0153 11.0713 10.4873 11.0713C7.95867 11.0713 5.90267 9.01467 5.90267 6.48667Z"
                fill="#6B7280"
              />
            </svg>
            <input
              ref={p.inputRef}
              type="text"
              className={defaultInputClassNames()}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        )}
      </BlockInputContainer>

      <div className={"flex-1 flex flex-col gap-y-1.5 overflow-hidden"}>
        <ListTitleLine className={marginHorizontal} />

        <div
          className={clsx(
            "flex-1 max-h-[300px] overflow-y-auto",
            paddingHorizontal,
            props.bottomArea == null ? "pb-3" : "pb-2.5",
          )}
        >
          {markets.map((option, idx) => (
            <NavLink to={option.link}>
              <OptionRow
                key={idx}
                option={option}
                selected={isSameMarketOption(props.selectedOption, option)}
              />
            </NavLink>
          ))}
        </div>
      </div>

      {props.bottomArea == null ? (
        <div />
      ) : (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
          }}
        >
          {props.bottomArea}
        </div>
      )}
    </CompactCard>
  )
}

const ListTitleLine: FC<{ className?: string }> = props => {
  const { $t } = useIntl()

  return (
    <RowContainer
      className={clsx("text-xs font-normal text-gray-500", props.className)}
    >
      <SymbolCell>
        {$t(
          defineMessage({
            defaultMessage: "Symbol",
            description: `Orderbook/TokenPairSelector/header row/symbol`,
          }),
        )}
      </SymbolCell>
      <LastPriceCell>
        {$t(
          defineMessage({
            defaultMessage: "Last price",
            description: `Orderbook/TokenPairSelector/header row/last price`,
          }),
        )}
      </LastPriceCell>
      <ChangePercentCell>
        {$t(
          defineMessage({
            defaultMessage: "24h%",
            description: `Orderbook/TokenPairSelector/header row/24h%`,
          }),
        )}
      </ChangePercentCell>
    </RowContainer>
  )
}

const OptionRow: FC<{
  option: MarketOption
  selected?: boolean
}> = props => {
  const { option } = props

  return (
    <RowContainer
      className={clsx(
        props.selected ? "bg-white/5" : "cursor-pointer hover:bg-white/5",
        "text-xs text-gray-200 py-1.5",
      )}
    >
      <SymbolCell className={"flex items-center gap-x-1"}>
        <TokenIcon size={14} token={option.tradeToken} />
        <span>
          <TokenNameStack
            tradeToken={option.tradeToken}
            priceToken={option.priceToken}
          />
        </span>
        {option.tradeToken.propertyTags.includes("brc-20") && (
          <TokenNameBRC20Badge />
        )}
      </SymbolCell>

      <LastPriceCell>
        <Spensor fallback={"-"}>
          {() => (
            <TokenCount
              token={option.tradeToken}
              count={readResource(option.tradeTokenLastPrice)}
            />
          )}
        </Spensor>
      </LastPriceCell>

      <ChangePercentCell>
        <Spensor fallback={"-"}>
          {() => {
            const percent = readResource(
              option.tradeToken24HourChangePercentage,
            )

            return (
              <span
                className={clsx(
                  percent > 0
                    ? Colors.increaseTextClassName
                    : percent < 0
                    ? Colors.decreaseTextClassName
                    : "",
                )}
              >
                {percent < 0 ? "-" : ""}
                <PercentNumber number={Math.abs(percent)} />
              </span>
            )
          }}
        </Spensor>
      </ChangePercentCell>
    </RowContainer>
  )
}

const RowContainer = withClassName("flex gap-2.5 items-center", "div")

const SymbolCell = withClassName("flex-[180]", "div")
const LastPriceCell = withClassName("flex-[136] text-right", "div")
const ChangePercentCell = withClassName("flex-[80] text-right", "div")
