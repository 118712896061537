import clsx from "clsx"
import { cloneElement, FC, ReactElement } from "react"
import {
  failedTextColorClassName,
  pointCircleSize,
  pointHeight,
} from "./consts"
import { ReactComponent as FailedIcon } from "./failed.svg"
import { LabelProps } from "./Label"

export interface CursorPointProps {
  isFailed: boolean

  label: ReactElement<LabelProps>

  /**
   * @default 'left'
   *
   * will affect the `align` prop of the `label`
   */
  labelAlignment?: "left" | "right"
}
export namespace CursorPointProps {
  export const getLabelAlignment = (
    props: CursorPointProps,
  ): NonNullable<CursorPointProps["labelAlignment"]> =>
    props.labelAlignment ?? "left"
}

export const CursorPoint: FC<CursorPointProps> = props => {
  const labelAlignment = CursorPointProps.getLabelAlignment(props)

  return (
    <div className={"flex flex-col"}>
      {/* circle */}
      <div
        className={clsx(
          "bg-current rounded-full text-white flex items-center justify-center overflow-hidden",
          labelAlignment === "left" && "self-start",
          labelAlignment === "right" && "self-end",
          props.isFailed && failedTextColorClassName,
        )}
        style={{ width: pointCircleSize, height: pointCircleSize }}
      >
        {props.isFailed && (
          <FailedIcon
            className={"block"}
            width={pointCircleSize}
            height={pointCircleSize}
          />
        )}
      </div>

      <div
        className={clsx(
          "absolute flex",
          labelAlignment === "left" && "flex-row left-0",
          labelAlignment === "right" && "flex-row-reverse right-0",
        )}
        style={{ marginTop: pointCircleSize }}
      >
        {/* border */}
        <div
          className={clsx("bg-current w-[1px] text-white")}
          style={{
            height: pointHeight - pointCircleSize / 2,
            marginLeft: (pointCircleSize - 1) / 2,
            marginRight: (pointCircleSize - 1) / 2,
          }}
        />

        <div className={"p-t-[15px] p-x-[6px] flex items-end"}>
          {cloneElement(props.label, {
            align: labelAlignment,
            detailColorClassName: props.isFailed
              ? failedTextColorClassName
              : undefined,
          })}
        </div>
      </div>
    </div>
  )
}
