import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["cofarm-apower-helper"] as const
const functionName = "distribute" as const

export interface CoFarmAPowerDistributionRowData extends BaseRowData {
  type: NotifyTransactionType.CoFarmAPowerDistribution
  cycle: number
  rewardCurrencyAmounts: CurrencyAmount[]
}

export const coFarmAPowerDistributionTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  CoFarmAPowerDistributionRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.CoFarmAPowerDistribution,
  confirmedTransformer: (helpers, context) => {
    if (!context?.address) {
      throw new Error("context of address not found")
    }
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, context.address)

    return {
      cycle: args.cycle,
      rewardCurrencyAmounts: [
        {
          currency: Currency.ATALEX,
          amount: transferAmounts[Currency.ATALEX]?.in ?? undefined,
        },
        {
          currency: Currency.APOWER,
          amount: transferAmounts[Currency.APOWER]?.in ?? undefined,
        },
      ],
    }
  },
  pendingOrFailedTransformer: args => {
    return {
      cycle: args.cycle,
      rewardCurrencyAmounts: [
        {
          currency: Currency.ATALEX,
        },
        {
          currency: Currency.APOWER,
        },
      ],
    }
  },
}
