import clsx from "clsx"
import { ReactNode } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { GradientText } from "../GradientText"

export const PrimaryPageTitle: FCC<{
  className?: string
  uppercase?: boolean
  subtitle?: ReactNode
  subtitleClassName?: string
}> = props => (
  <div className={clsx("flex flex-col gap-2.5", props.className)}>
    <GradientText
      className={clsx(
        "text-2xl sm:text-3xl leading-9 font-medium",
        props.uppercase !== false && "uppercase",
      )}
      gradientStyle={"linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"}
    >
      {props.children}
    </GradientText>

    {props.subtitle && (
      <SubtitleText className={clsx(props.subtitleClassName)}>
        {props.subtitle}
      </SubtitleText>
    )}
  </div>
)

export const SubtitleText = withClassName(
  "text-base leading-6 font-normal text-gray-400",
  "div",
)
