import { noop } from "lodash"
import { FC, RefObject } from "react"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { WrapBridgeHistoryContent } from "./WrapBridgePanel/WrapBridgeHistory/WrapBridgeHistoryContent"

export const WiredWrapBridgeHistoryContent: FC<{
  headerRef: RefObject<HTMLDivElement>
}> = props => {
  const { history } = useWrapBridgeStore()

  if (!history.isReadyToLoadRecords) {
    return (
      <WrapBridgeHistoryContent
        records={[]}
        pagination={{
          currentPage: 0,
          recordCountPerPage: history.recordCountPerPage,
          recordCountTotal: 0,
        }}
        onChangePage={noop}
        headerRef={props.headerRef}
      />
    )
  }

  return (
    <WrapBridgeHistoryContent
      records={suspenseResource(() => history.records$)}
      pagination={suspenseResource(() => history.paginationInfo$)}
      onChangePage={n => history.onChangePage(n)}
      headerRef={props.headerRef}
    />
  )
}
