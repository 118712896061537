import { withClassName } from "../../../../../utils/reactHelpers/withClassName"

export const columnHeight = 20
export const ColumnText = withClassName(
  "leading-[20px] whitespace-nowrap ",
  "div",
)
export const Column1Cell = withClassName("flex-[90]", ColumnText)
export const Column2Cell = withClassName("flex-[90] text-right", ColumnText)
export const Column3Cell = withClassName("flex-[116] text-right", ColumnText)
