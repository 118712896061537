import * as React from "react"
import { FC } from "react"
import FinishedFailedIconUrl from "./finishedFailedImage.svg"

export const IDOFailedIcon: FC<{ size?: number }> = props => {
  return (
    <img
      alt="IDO failed icon"
      width={props.size ?? 96}
      height={props.size ?? 96}
      src={FinishedFailedIconUrl}
    />
  )
}
