import clsx from "clsx"
import { CSSProperties, FC, PropsWithChildren } from "react"
import { ButtonVariantProps } from "../ButtonVariant"
import { HeadlessButton } from "../HeadlessButton"
import * as defaultStyles from "../_/defaultStyles"

export interface RoundedButtonVariantProps extends ButtonVariantProps {
  roundedClassName?: string
}

export interface BaseRoundedFilledButtonProps
  extends PropsWithChildren<ButtonVariantProps> {
  bgClassName?: string
  roundedClassName?: string
  style?: CSSProperties
}

export const BaseRoundedFilledButton: FC<
  BaseRoundedFilledButtonProps
> = props => {
  return (
    <HeadlessButton
      className={clsx(
        `disabled:text-opacity-30`,
        props.boxClassName ?? defaultStyles.medium.boxClassName,
        props.textClassName ?? defaultStyles.medium.textClassName,
        props.roundedClassName ?? "rounded-full",
        props.bgClassName,
        props.className,
      )}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </HeadlessButton>
  )
}
