import clsx from "clsx"
import { FC, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Column, useTable } from "react-table"
import { GreenBadge } from "../../../../components/Badge"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../../components/table/StyledTable"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { customReactTableRender } from "../../../../utils/customReactTableRender"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ApowerDistributionCycle } from "../../store/autoStaking/AutoStakeStore.service"
import { ReactComponent as EmptyIcon } from "./empty.svg"

export interface AutoAlexApowerDistributionContentProps {
  cycles: SuspenseResource<ApowerDistributionCycle[]>
  currentCycleNumber: SuspenseResource<number>
  autoAlexToken: SuspenseResource<TokenInfo>
  containerClassName?: string
  onClose?: () => void
}

export const AutoAlexApowerDistributionContent: FC<
  AutoAlexApowerDistributionContentProps
> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      title={
        <span className="text-xl leading-7 font-medium text-gray-200">
          {$t(
            defineMessage({
              defaultMessage: "My Auto-Staking APower",
              description: "/Stake/MixedStake/ApowerDistribution/Content title",
            }),
          )}
        </span>
      }
      onClose={props.onClose}
      className={clsx(props.containerClassName, "flex flex-col")}
      width={"80vw"}
      minHeight={"80vh"}
    >
      <Spensor
        fallback={
          <div className="w-full grow flex items-center justify-center">
            <LoadingIndicator className="mx-auto" size={72} />
          </div>
        }
      >
        {() => (
          <DataTable
            cycles={readResource(props.cycles)}
            currentCycleNumber={readResource(props.currentCycleNumber)}
            autoAlexToken={readResource(props.autoAlexToken)}
          />
        )}
      </Spensor>
    </CardModalContent>
  )
}

const DataTable: FC<{
  cycles: ApowerDistributionCycle[]
  currentCycleNumber: number
  autoAlexToken: TokenInfo
}> = props => {
  const { $t } = useIntl()
  const tableInstance = useTable({
    columns: useTableSchema({
      currentCycleNumber: props.currentCycleNumber,
      apowerToken: TokenInfoPresets.APower,
      autoAlexToken: props.autoAlexToken,
    }),
    data: props.cycles,
  })
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } =
    tableInstance

  return (
    <div className="flex flex-col flex-1 w-full overflow-auto">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps()}
                  className="text-sm leading-5 font-normal text-gray-500"
                >
                  {customReactTableRender(column as any, "Header")}
                </Th>
              ))}
            </TableHeadRow>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableBodyRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </TableBodyRow>
            )
          })}
        </Tbody>
      </Table>
      {props.cycles.length === 0 && (
        <div className="grow w-full flex flex-col gap-y-4 items-center justify-center">
          <EmptyIcon />
          <span className="text-base leading-6 font-normal text-gray-600">
            {$t(
              defineMessage({
                defaultMessage: "No data",
                description:
                  "/Stake/MixedStake/ApowerDistribution/No data text",
              }),
            )}
          </span>
        </div>
      )}
    </div>
  )
}

const useTableSchema = (context: {
  currentCycleNumber: number
  apowerToken: TokenInfo
  autoAlexToken: TokenInfo
}): Column<ApowerDistributionCycle>[] => {
  const { $t } = useIntl()

  return useMemo(
    () => [
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Cycle",
            description: "/Stake/MixedStake/ApowerDistribution/Table header",
          }),
        ),
        accessor: "cycle",
        Cell: props => (
          <span className="flex flex-row items-center gap-x-2.5">
            <span className="text-lg leading-6 font-bold text-gray-200">
              #{props.cell.value}
            </span>
            {props.cell.value === context.currentCycleNumber ? (
              <GreenBadge>
                {$t(
                  defineMessage({
                    defaultMessage: "Current",
                    description:
                      "/Stake/MixedStake/ApowerDistribution/Table header",
                  }),
                )}
              </GreenBadge>
            ) : null}
          </span>
        ),
      },
      {
        Header: (
          <span className="flex flex-row gap-x-1 items-center">
            {$t(
              defineMessage({
                defaultMessage: "Eligible {tokenName}",
                description:
                  "/Stake/MixedStake/ApowerDistribution/Table header",
              }),
              {
                tokenName: <TokenName token={context.autoAlexToken} />,
              },
            )}
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "Your eligible atALEX amount when snapshot was taken",
                  description:
                    "/Stake/MixedStake/ApowerDistribution/Table header tip",
                }),
              )}
            />
          </span>
        ),
        accessor: "eligibleAutoAlex",
        Cell: props => (
          <>
            {props.cell.value ? (
              <TokenCount
                token={context.autoAlexToken}
                count={props.cell.value}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        Header: (
          <span className="flex flex-row gap-x-1 items-center">
            {$t(
              defineMessage({
                defaultMessage: "Snapshot Block",
                description:
                  "/Stake/MixedStake/ApowerDistribution/Table header",
              }),
            )}
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "The snapshot blocks are randomly chosen each cycle",
                  description:
                    "/Stake/MixedStake/ApowerDistribution/Table header tip",
                }),
              )}
            />
          </span>
        ),
        accessor: "snapshotBlockHeight",
        Cell: props => (
          <>
            {props.cell.value
              ? `#${props.cell.value}`
              : $t(
                  defineMessage({
                    defaultMessage: "Waiting for snapshot",
                    description:
                      "/Stake/MixedStake/ApowerDistribution/Table cell",
                  }),
                )}
          </>
        ),
      },
      {
        Header: (
          <span className="flex flex-row gap-x-1 items-center">
            {$t(
              defineMessage({
                defaultMessage: "{tokenName} Rewards",
                description:
                  "/Stake/MixedStake/ApowerDistribution/Table Header",
              }),
              {
                tokenName: <TokenName token={context.apowerToken} />,
              },
            )}
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "The calculated amount of APower rewards each cycle.",
                  description:
                    "/Stake/MixedStake/ApowerDistribution/Table Header tip",
                }),
              )}
            />
          </span>
        ),
        accessor: "apowerRewards",
        Cell: props => (
          <>
            {props.cell.value ? (
              <TokenCount
                count={props.cell.value}
                token={context.apowerToken}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Status",
            description: "/Stake/MixedStake/ApowerDistribution/Table Header",
          }),
        ),
        accessor: "status",
        Cell: props => <>{props.cell.value ?? "-"}</>,
      },
    ],
    [
      context.autoAlexToken,
      context.apowerToken,
      context.currentCycleNumber,
      $t,
    ],
  )
}
