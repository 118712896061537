import { FC, useState } from "react"
import { SmartLoadableButton } from "../../../components/button/LoadableButton"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { getAuthSignatureFromWallet } from "../../Orderbook/store/stxdx_shared/StxDxMyInfoModule.service"

export const SignMessage: FC = () => {
  const accStore = useAccountStore()

  const [signed, setSigned] = useState("")

  const onSignMessage = async (): Promise<void> => {
    const timestamp = Math.floor(60 * 60 * 5 + Date.now() / 1000)
    const { signature, publicKey, payload } = await getAuthSignatureFromWallet(
      accStore.authService.stxAddress$,
      timestamp - (timestamp % (60 * 60)), // make it round to hour
    )
    setSigned(JSON.stringify({ signature, publicKey, payload }))
  }

  return (
    <div>
      <div>
        <SmartLoadableButton onClick={onSignMessage}>
          Sign Message
        </SmartLoadableButton>
      </div>

      <p>{signed}</p>
    </div>
  )
}
