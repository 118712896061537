import { AutoStakingRowData } from "../../store/transformerGroups/stake/autoStaking"
import { CurrencyAmount, Exporter, NotifyTransactionStatus } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount, parseExchangeRatio } from "../parser"

export const autoStakingExporter: Exporter<AutoStakingRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.alexCurrencyAmount],
    toCurrencyAmounts: [rowData.atAlexCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo =
    rowData.status === NotifyTransactionStatus.Confirmed
      ? parseExchangeRatio({
          fromCurrencyAmount:
            rowData.alexCurrencyAmount as CurrencyAmount.Confirmed,
          toCurrencyAmount:
            rowData.atAlexCurrencyAmount as CurrencyAmount.Confirmed,
          allTokenInfos,
        })
      : ""
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
