import { ETHChain } from "../ETHChain"

type AddressMap = Partial<Record<ETHChain, string>>

export type ContractAddresses = Record<
  keyof typeof ethContractAddresses,
  AddressMap
>

export const ethContractAddresses = {
  usdc: {
    [ETHChain.Ethereum]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    [ETHChain.Goerli]: "0x7Ffd58D5bB024A982D918B127F9AbEf2C974dFCD",
    [ETHChain.AVAX]: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    [ETHChain.BSC]: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    [ETHChain.Polygon]: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  },
  usdt: {
    [ETHChain.Ethereum]: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    [ETHChain.Goerli]: "0x51445dcDdF5246229bAE8C0BA3EA462e63038641",
    [ETHChain.Sepolia]: "0xBa175fDaB00e7FCF603f43bE8f68dB7f4de9f3A9",
    [ETHChain.SepoliaFork]: "0xBa175fDaB00e7FCF603f43bE8f68dB7f4de9f3A9",
    [ETHChain.BSC]: "0x55d398326f99059ff775485246999027b3197955",
    [ETHChain.BSCTestFork]: "0xBBB0876a23f93C9Eb970BDFe885aA0C86c66885c",
    [ETHChain.Polygon]: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    [ETHChain.AVAX]: "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
  },
  lunr: {
    [ETHChain.Ethereum]: "0xA87135285Ae208e22068AcDBFf64B11Ec73EAa5A",
    [ETHChain.Sepolia]: "0x50c99C14eD859Cde37f6badE0b3887B30D028386",
    [ETHChain.SepoliaFork]: "0x50c99C14eD859Cde37f6badE0b3887B30D028386",
    [ETHChain.BSC]: "0x37807D4fbEB84124347B8899Dd99616090D3e304",
  },
  wbtc: {
    [ETHChain.Ethereum]: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    [ETHChain.Goerli]: "0x577D296678535e4903D59A4C929B718e1D575e0A",
    // [ETHChain.BSC]: '', // not supported in bsc chain
    [ETHChain.Polygon]: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    [ETHChain.AVAX]: "0x50b7545627a5162F82A992c33b87aDc75187B218",
  },
  bridgeEndpoint: {
    [ETHChain.Ethereum]: "0xfd9F795B4C15183BDbA83dA08Da02D5f9536748f",
    [ETHChain.Sepolia]: "0x84a0cc1ab353dA6b7817947F7B116b8ea982C3D2",
    [ETHChain.SepoliaFork]: "0x84a0cc1ab353dA6b7817947F7B116b8ea982C3D2",
    [ETHChain.BSC]: "0xb3955302E58FFFdf2da247E999Cd9755f652b13b",
    [ETHChain.BSCTestFork]: "0xb3955302E58FFFdf2da247E999Cd9755f652b13b",
  },
} satisfies Record<string, AddressMap>
