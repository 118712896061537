import { FC } from "react"
import { useSearchParams } from "react-router-dom"
import { Pagination } from "../../components/Pagination"
import { Spensor } from "../../components/Spensor"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { PaginationInfo } from "./types"

export const WiredPagination: FC = () => {
  const store = useNotifyStore()
  return (
    <Spensor>
      {() => (
        <PaginationContainer paginationData={store.transactions.pagination$} />
      )}
    </Spensor>
  )
}

const PaginationContainer: FC<{
  paginationData?: PaginationInfo
}> = ({ paginationData }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <>
      {paginationData && (
        <Pagination
          className="pt-8"
          onChange={({ page }) => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              page: (page + 1).toString(),
            })
            paginationData.setPageIndex(page)
          }}
          currentPage={paginationData.pageIndex}
          recordCountPerPage={paginationData.pageSize}
          recordCountTotal={paginationData.totalRows}
        />
      )}
    </>
  )
}
