import clsx from "clsx"
import { FC, Ref } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardInset, CardTitle } from "../../../../components/Card"
import { EmptyState } from "../../../../components/EmptyState/EmptyState"
import { MaskedScopedLoadingBoundary } from "../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor, SpensorR } from "../../../../components/Spensor"
import { renderThinnerBlock } from "../../../../components/TokenInput/Block"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { SmartLoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  SuspenseResource,
  isResourceLoading,
  readResource,
  safeReadResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { checkNever } from "../../../../utils/types"
import { BRC20BalanceCard } from "./BRC20Card/BRC20BalanceCard"
import { SelectableBRC20Card } from "./BRC20Card/BRC20Card"
import { TransferFormError, TransferFormErrorType } from "./TransferPanel.types"

export interface AvailableTokenInfo {
  token: TokenInfo
  totalBalance: BigNumber
  transferableBalance: BigNumber
  availableBalance: BigNumber
}

export interface TransferPanelProps {
  className?: string
  containerRef?: Ref<HTMLElement>
  formError?: SuspenseResource<undefined | TransferFormError>
  prerequisites?: SuspenseResource<undefined | "connectWallet">
  availableTokens: SuspenseResource<AvailableTokenInfo[]>
  selectedToken: SuspenseResource<TokenInfo>
  onSelectedToken: (token: null | TokenInfo) => void
  tokenAmount: null | BigNumber
  onTokenAmountChange: (amount: null | BigNumber) => void
  onSubmit: SuspenseResource<undefined | (() => void | Promise<void>)>
}

export const TransferPanel: FC<TransferPanelProps> = props => {
  const { $t } = useIntl()

  return (
    <Card
      ref={props.containerRef as any}
      className={clsx(props.className, "flex flex-col gap-y-4")}
      boxClassName={"p-6"}
    >
      <CardTitle>
        {$t(
          defineMessage({
            defaultMessage: "Your BRC-20",
            description:
              "Admin Bulk Send Inscriptions Page/Transfer Panel/title",
          }),
        )}
      </CardTitle>

      <SpensorR read={{ prerequisites: props.prerequisites }}>
        {({ prerequisites }) =>
          prerequisites != null && (
            <CardInset
              className={"text-gray-200 text-center"}
              boxClassName={"p-5"}
            >
              {prerequisites === "connectWallet" ? (
                <p>
                  {$t(
                    defineMessage({
                      defaultMessage:
                        "Please connect your Stacks wallet (Stacks Chain) {breakLine} and {breakLine} Your Ordinal Wallet to peg-in with (BTC Taproot)",
                      description:
                        "Admin Bulk Send Inscriptions Page/Transfer Panel/connect wallet",
                    }),
                    { breakLine: <br /> },
                  )}
                </p>
              ) : (
                (checkNever(prerequisites), null)
              )}
            </CardInset>
          )
        }
      </SpensorR>

      <Spensor>
        {() => (
          <CardInset
            className={
              "flex flex-row flex-wrap items-center justify-center gap-2.5"
            }
            boxClassName={"p-5"}
          >
            {readResource(props.availableTokens).length <= 0 && (
              <EmptyState>
                {$t(
                  defineMessage({
                    defaultMessage: "No BRC-20 token",
                    description:
                      "Admin Bulk Send Inscriptions Page/Transfer Panel/No BRC-20 token text",
                  }),
                )}
              </EmptyState>
            )}
            {readResource(props.availableTokens).map((info, idx) => (
              <BRC20BalanceCard
                key={idx}
                className={clsx("w-[200px] min-h-[200px]")}
                token={info.token}
                totalBalance={info.totalBalance}
                transferableBalance={info.transferableBalance}
                availableBalance={info.availableBalance}
                renderBRC20Card={p => {
                  const isSelected =
                    safeReadResource(props.selectedToken)?.id === info.token.id

                  return (
                    <SelectableBRC20Card
                      {...p}
                      selected={isSelected}
                      onToggleSelect={() => {
                        props.onSelectedToken(isSelected ? null : info.token)
                      }}
                    />
                  )
                }}
              />
            ))}
          </CardInset>
        )}
      </Spensor>

      <div className={"flex flex-col gap-y-2.5"}>
        <p className={"text-sm leading-5 font-normal text-gray-200"}>
          {$t(
            defineMessage({
              defaultMessage: "Amount:",
              description:
                "Admin Bulk Send Inscriptions Page/Transfer Panel/amount label",
            }),
          )}
        </p>

        <TokenInput
          error={
            safeReadResource(props.formError)?.type ===
            TransferFormErrorType.InsufficientBalance
          }
          disabled={safeReadResource(props.selectedToken) == null}
          token={props.selectedToken}
          value={
            props.tokenAmount == null
              ? null
              : BigNumber.toNumber(props.tokenAmount)
          }
          onChange={v =>
            props.onTokenAmountChange(v == null ? null : BigNumber.from(v))
          }
          renderBlock={renderThinnerBlock}
        />
      </div>

      <MaskedScopedLoadingBoundary
        loadingIndicator={<LoadingIndicator />}
        read={{ submit: props.onSubmit }}
        defaultRead={{ submit: undefined }}
      >
        {({ submit }) => (
          <SmartLoadableButton
            Variant={GradientFilledButton}
            onClick={submit}
            loading={isResourceLoading(props.formError)}
            disabled={
              submit == null || safeReadResource(props.formError) != null
            }
          >
            {$t(
              defineMessage({
                defaultMessage: "Peg-in",
                description:
                  "Admin Bulk Send Inscriptions Page/Transfer Panel/submit button text",
              }),
            )}
          </SmartLoadableButton>
        )}
      </MaskedScopedLoadingBoundary>
    </Card>
  )
}
