import copy from "copy-to-clipboard"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardDivider } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { ScopedLoadingBoundary } from "../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { MarkdownContent } from "../../../../components/MarkdownContent/MarkdownContent"
import { RenderChildrenFn } from "../../../../components/Spensor"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { useMessage } from "../../../../components/message/MessageProvider"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import styles from "./TestNetworkSetupGuideModalContent.module.scss"

export const TestNetworkSetupGuideModalContent: FC<{
  markdownContent: SuspenseResource<string>
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  const message = useMessage()

  return (
    <CardModalContent
      className={"flex items-center justify-center"}
      width={800}
      title={$t(
        defineMessage({
          defaultMessage: 'Make sure you switch to ALEX "Testnet"',
          description: "Orderbook/Testnet setup guide modal title",
        }),
      )}
      onClose={props.onClose}
    >
      <ScopedLoadingBoundary
        className={"w-full min-h-[300px]"}
        overlay={false}
        loadingIndicator={<LoadingIndicator />}
      >
        <RenderChildrenFn>
          {() => (
            <MarkdownContent
              className={styles.markdown}
              components={{
                hr: () => <CardDivider className={"w-full"} />,
                copy: (props: {
                  title?: string
                  content?: string
                }): null | JSX.Element => {
                  if (props.title == null || props.content == null) return null
                  return (
                    <HeadlessButton
                      className={"inline text-blue-500"}
                      onClick={() => {
                        if (copy(props.content!)) {
                          message.show({ message: "Copied", autoDismiss: null })
                        }
                      }}
                    >
                      <CopyIcon className={"inline"} /> {props.title}
                    </HeadlessButton>
                  )
                },
              }}
              markdown={readResource(props.markdownContent)}
            />
          )}
        </RenderChildrenFn>
      </ScopedLoadingBoundary>
    </CardModalContent>
  )
}

const CopyIcon: FC<{ className?: string }> = props => (
  <svg
    {...props}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.34766V0.347656H0V12.3477H4V16.3477H16V4.34766H12ZM4 11.0143H1.33333V1.68099H10.6667V4.34766H4V11.0143ZM14.6667 15.0143H5.33333V5.68099H14.6667V15.0143Z"
      fill="#2563EB"
    />
  </svg>
)
