import type { CurvePoint } from "../../mixedStakeComponents/EarningsPreviewSection/EarningsPreviewSection"

export const MOCK_ATALEX_RETURN = [
  1, 1.02, 1.04, 1.06019802, 1.080590177, 1.101174567, 1.121949253, 1.142912282,
  1.16406169, 1.185395503, 1.206911742, 1.228608419, 1.250483543, 1.272535123,
  1.294761165, 1.317159677, 1.339728669, 1.362466156, 1.385370156, 1.408438697,
  1.431669811, 1.455061541, 1.478611939, 1.502319069, 1.526181004, 1.550195832,
  1.574361654, 1.598676584, 1.623138752, 1.647746302, 1.672497394, 1.697390207,
  1.722422933, 1.747593784, 1.77290099, 1.798342797, 1.823917472, 1.849623297,
  1.875458577, 1.901421634, 1.92751081, 1.953724464, 1.980060979, 2.006518755,
  2.033096211, 2.059791787, 2.086603943, 2.113531158, 2.140571931, 2.167724782,
  2.194988248, 2.222360888, 2.249841281, 2.277428022, 2.305119728, 2.332915036,
  2.3608126, 2.388811094, 2.416909211, 2.445105661, 2.473399175, 2.501788501,
  2.530272404, 2.55884967, 2.587519099, 2.616279512, 2.645129746, 2.674068654,
  2.703095108, 2.732207995, 2.76140622, 2.790688704, 2.820054383, 2.849502211,
  2.879031156, 2.908640202, 2.938328349, 2.96809461, 2.997938017, 3.027857612,
  3.057852455, 3.087921617, 3.118064186, 3.148279263, 3.178565961, 3.208923409,
  3.239350746, 3.269847129, 3.300411721, 3.331043705, 3.36174227, 3.39250662,
  3.423335973, 3.454229555, 3.485186607, 3.516206378, 3.547288132, 3.57843114,
  3.609634689,
]

export const MOCK_ALEX_RETURNS = [
  1, 1.02, 1.04, 1.05980198, 1.079409823, 1.098825433, 1.118050747, 1.137087718,
  1.15593831, 1.174604497, 1.193088258, 1.211391581, 1.229516457, 1.247464877,
  1.265238835, 1.282840323, 1.300271331, 1.317533844, 1.334629844, 1.351561303,
  1.368330189, 1.384938459, 1.401388061, 1.417680931, 1.433818996, 1.449804168,
  1.465638346, 1.481323416, 1.496861248, 1.512253698, 1.527502606, 1.542609793,
  1.557577067, 1.572406216, 1.58709901, 1.601657203, 1.616082528, 1.630376703,
  1.644541423, 1.658578366, 1.67248919, 1.686275536, 1.699939021, 1.713481245,
  1.726903789, 1.740208213, 1.753396057, 1.766468842, 1.779428069, 1.792275218,
  1.805011752, 1.817639112, 1.830158719, 1.842571978, 1.854880272, 1.867084964,
  1.8791874, 1.891188906, 1.903090789, 1.914894339, 1.926600825, 1.938211499,
  1.949727596, 1.96115033, 1.972480901, 1.983720488, 1.994870254, 2.005931346,
  2.016904892, 2.027792005, 2.03859378, 2.049311296, 2.059945617, 2.070497789,
  2.080968844, 2.091359798, 2.101671651, 2.11190539, 2.122061983, 2.132142388,
  2.142147545, 2.152078383, 2.161935814, 2.171720737, 2.181434039, 2.191076591,
  2.200649254, 2.210152871, 2.219588279, 2.228956295, 2.23825773, 2.24749338,
  2.256664027, 2.265770445, 2.274813393, 2.283793622, 2.292711868, 2.30156886,
  2.310365311,
]

export async function mixedEarningPreview(): Promise<{
  alex: CurvePoint[]
  atAlex: CurvePoint[]
}> {
  return {
    alex: MOCK_ALEX_RETURNS.map((n, idx) => ({ cycle: idx + 1, apr: n })),
    atAlex: MOCK_ATALEX_RETURN.map((n, idx) => ({ cycle: idx + 1, apr: n })),
  }
}
