import { optionalResponse } from "clarity-codegen"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { isNotNull } from "../../../../../utils/utils"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["collateral-rebalancing-pool-v1"] as const
const functionName = "reduce-position-yield-many" as const

export interface DepositClaimRowData extends BaseRowData {
  type: NotifyTransactionType.DepositClaim
  yieldCurrencyAmount: CurrencyAmount
  tokenCurrencyAmount: CurrencyAmount
  claimCurrencyAmounts: { from: CurrencyAmount; to: CurrencyAmount }[]
  expiries: number[]
}

export const depositClaimTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  DepositClaimRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.DepositClaim,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()

    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    const claims = (optionalResponse(result) ?? [])
      .map(r => optionalResponse(r))
      .filter(isNotNull)
      .filter(c => !(c.dx === 0 && c.dy === 0))

    return {
      yieldCurrencyAmount: {
        currency: yieldTokenTrait,
      },
      tokenCurrencyAmount: {
        currency: tokenTrait,
      },
      claimCurrencyAmounts: claims.map(c => ({
        from: {
          currency: yieldTokenTrait,
          amount: getAmountFromContract(c.dy), // dx is always 0
        },
        to: {
          currency: tokenTrait,
          amount: getAmountFromContract(c.dy),
        },
      })),
      expiries: args.expiries,
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    return {
      yieldCurrencyAmount: {
        currency: yieldTokenTrait,
      },
      tokenCurrencyAmount: {
        currency: tokenTrait,
      },
      claimCurrencyAmounts: [],
      expiries: args.expiries,
    }
  },
}
