import { noop } from "lodash"
import { FC } from "react"
import { useIntl } from "react-intl"
import { Spensor } from "../../../../components/Spensor"
import { oneOf } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { LabelTokenInput } from "../LabelTokenInput"
import { TradingFormError, TradingFormErrorType } from "../types"
import { AvailableOutgoingTokenCount } from "./_/AvailableOutgoingTokenCount"
import { formFields$t } from "./_/commonIntlMessages"
import { PercentSlider } from "./_/PercentSlider"

export interface LimitOrderFormProps {
  formError: SuspenseResource<undefined | TradingFormError>
  outgoingToken: TokenInfo
  maxAvailableOutgoingTokenCount: SuspenseResource<number>
  priceToken: TokenInfo
  tradeToken: TokenInfo
  perTradeTokenPrice: SuspenseResource<null | number>
  perTradeTokenPricePrecision: number
  tradeTokenCount: SuspenseResource<null | number>
  tradeTokenCountPercentage: SuspenseResource<number>
  onPerTradeTokenPriceChange: (newPrice: null | number) => void
  onTradeTokenCountChange: (count: null | number) => void
  onTradeTokenCountPercentageChange: (percentage: number) => void
}

export const LimitOrderForm: FC<LimitOrderFormProps> = props => {
  const { $t } = useIntl()
  return (
    <>
      <AvailableOutgoingTokenCount
        token={props.outgoingToken}
        tokenCount={props.maxAvailableOutgoingTokenCount}
      />

      <LabelTokenInput
        label={$t(formFields$t.limitPrice)}
        token={{
          ...props.priceToken,
          precision: props.perTradeTokenPricePrecision,
        }}
        value={props.perTradeTokenPrice}
        onChange={props.onPerTradeTokenPriceChange}
        error={oneOf(TradingFormErrorType.LimitPriceTooSmall)(
          safeReadResource(props.formError)?.type,
        )}
      />

      <LabelTokenInput
        label={
          <span className={"flex items-center"}>{$t(formFields$t.amount)}</span>
        }
        token={props.tradeToken}
        value={props.tradeTokenCount}
        onChange={props.onTradeTokenCountChange}
        disabled={safeReadResource(props.maxAvailableOutgoingTokenCount) === 0}
        error={oneOf(
          TradingFormErrorType.InsufficientTradeTokenBalance,
          TradingFormErrorType.TradeTokenCountTooSmall,
        )(safeReadResource(props.formError)?.type)}
      />

      <Spensor
        fallback={<PercentSlider disabled={true} value={0} onChange={noop} />}
      >
        {() => (
          <PercentSlider
            value={readResource(props.tradeTokenCountPercentage)}
            onChange={props.onTradeTokenCountPercentageChange}
            disabled={
              safeReadResource(props.maxAvailableOutgoingTokenCount) === 0
            }
          />
        )}
      </Spensor>
    </>
  )
}
