import clsx from "clsx"
import { FC, SVGProps } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../../components/NavLink"
import { useSpacing } from "../../../../../components/Themed/spacing"
import { Button } from "../../../../../components/button/Button"
import { ButtonVariantProps } from "../../../../../components/button/ButtonVariant"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { isNotNull } from "../../../../../utils/utils"
import { ReactComponent as HiroIcon } from "./hiro.svg"
import { ReactComponent as OkxIcon } from "./okx.svg"
import { ReactComponent as UnisatIcon } from "./unisat.svg"
import { ReactComponent as XverseIcon } from "./xverse.svg"

export const availableWalletTypes = ["hiro", "xverse", "unisat", "okx"] as const

export type AvailableWalletType = (typeof availableWalletTypes)[number]

export interface SelectOridinalsWalletModalContentProps {
  availableWallets: SuspenseResource<
    Partial<Record<AvailableWalletType, () => void | Promise<void>>>
  >
  onClose: () => void
}

export const SelectOridinalsWalletModalContent: FC<
  SelectOridinalsWalletModalContentProps
> = props => {
  const { $t } = useIntl()

  const spacing = useSpacing()

  const rowGap = spacing(4)

  const wallets = safeReadResource(props.availableWallets)

  return (
    <CardModalContent
      className={"flex flex-col"}
      gap={rowGap}
      padding={spacing(5)}
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Select a wallet",
          description:
            "Admin Bulk Send Inscriptions Page/Select Wallet Modal/title",
        }),
      )}
      onClose={props.onClose}
    >
      {wallets == null ? (
        <div className={"min-h-[200px] flex items-center justify-center"}>
          <LoadingIndicator />
        </div>
      ) : Object.values(wallets).filter(isNotNull).length <= 0 ? (
        <>
          <p className={"text-center"}>
            {$t(
              defineMessage({
                defaultMessage: "Please install a wallet first",
                description:
                  "Admin Bulk Send Inscriptions Page/Select Wallet Modal/empty state",
              }),
            )}
          </p>
          <div className={"flex flex-row items-center justify-center gap-x-5"}>
            <NavLink to={"https://unisat.io/download"}>
              <Button Variant={UnisatWalletButton} />
            </NavLink>
          </div>
        </>
      ) : (
        <div
          className={
            "flex flex-row flex-wrap items-center justify-center gap-5"
          }
        >
          {wallets.hiro != null && (
            <Button onClick={wallets.hiro} Variant={HiroWalletButton} />
          )}
          {wallets.xverse != null && (
            <Button onClick={wallets.xverse} Variant={XverseWalletButton} />
          )}
          {wallets.unisat != null && (
            <Button onClick={wallets.unisat} Variant={UnisatWalletButton} />
          )}
          {wallets.okx != null && (
            <Button onClick={wallets.okx} Variant={OkxWalletButton} />
          )}
        </div>
      )}
    </CardModalContent>
  )
}

const HiroWalletButton: FC<ButtonVariantProps> = props => {
  return <WalletButton {...props} Icon={HiroIcon} name={"Hiro Wallet"} />
}

const XverseWalletButton: FC<ButtonVariantProps> = props => {
  return <WalletButton {...props} Icon={XverseIcon} name={"Xverse Wallet"} />
}

const UnisatWalletButton: FC<ButtonVariantProps> = props => {
  return <WalletButton {...props} Icon={UnisatIcon} name={"Unisat Wallet"} />
}

const OkxWalletButton: FC<ButtonVariantProps> = props => {
  return <WalletButton {...props} Icon={OkxIcon} name={"OKX Wallet"} />
}

const WalletButton: FC<
  ButtonVariantProps & {
    Icon: FC<SVGProps<SVGSVGElement>>
    name: string
  }
> = props => {
  return (
    <WhiteFilledButton
      {...props}
      className={clsx(
        props.className,
        "box-content min-w-[120px] min-h-[130px]",
        "flex flex-col items-center justify-center gap-y-2.5",
      )}
      boxClassName={"px-3 py-5"}
      roundedClassName={"rounded-lg"}
    >
      <props.Icon className={"w-[64px] h-[64px]"} />
      <div className={"text-sm font-medium"}>{props.name}</div>
    </WhiteFilledButton>
  )
}
