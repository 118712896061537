import { computed, makeObservable } from "mobx"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import {
  PRODUCTION_ANNUAL_FACTOR,
  StakingToken,
} from "../manualStaking/ManualStakeStore.service"
import {
  cycleStartBlockForToken,
  stakeCycleLength,
} from "./StakeSharedModule.service"

class StakeChainModule {
  constructor(
    readonly token: StakingToken,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$" | "appEnv"
    >,
  ) {
    makeObservable(this)
  }

  private _blocksPerCycle = new LazyValue(() => null, stakeCycleLength)

  private _firstCycleBlock = new LazyValue(
    () => [this.token] as const,
    ([token]) => cycleStartBlockForToken(token),
  )

  @computed get annualFactor$(): number {
    // const yearBlock = (365 * 24 * 60 * 60 * 1000) / ESTIMATED_BLOCK_DURATION
    // return yearBlock / this.blocksPerCycle$
    return PRODUCTION_ANNUAL_FACTOR
  }

  @computed get currentCycle$(): number {
    return this.getCycleNumberFromBlockHeight(
      this.chainStore.currentBlockHeight$,
    )
  }

  @computed get nextCycle$(): number {
    return this.currentCycle$ + 1
  }

  @computed get blocksPerCycle$(): number {
    return this._blocksPerCycle.value$
  }

  getCycleNumberFromBlockHeight(block: number): number {
    if (block < this.firstCycleBlock$) return 0
    return Math.floor((block - this.firstCycleBlock$) / this.blocksPerCycle$)
  }

  @computed get v1StakeEndCycle$(): undefined | number {
    const appEnv = this.chainStore.appEnv
    if (appEnv.config$.stakingCycleLimitBlockHeight == null) return
    return this.getCycleNumberFromBlockHeight(
      appEnv.config$.stakingCycleLimitBlockHeight,
    )
  }

  @computed get firstCycleBlock$(): number {
    return this._firstCycleBlock.value$
  }

  @computed get currentCycleBlock$(): number {
    return this.firstCycleBlock$ + this.blocksPerCycle$ * this.currentCycle$
  }

  @computed get nextCycleBlock$(): number {
    return this.firstCycleBlock$ + this.blocksPerCycle$ * this.nextCycle$
  }

  @computed get nextCycleDate$(): Date {
    return this.chainStore.estimatedDateForBlock$(this.nextCycleBlock$)
  }
}

export default StakeChainModule
