import { FC } from "react"
import { Spensor } from "../../../../../components/Spensor"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"

export const RaisingLiquidityProgress: FC<{
  percentage: SuspenseResource<number>
}> = props => {
  return (
    <div className="w-48 h-2 bg-emerald-900 rounded-full overflow-hidden">
      <Spensor>
        {() => (
          <div
            style={{
              width: `${Math.min(readResource(props.percentage), 1) * 100}%`,
            }}
            className="bg-emerald-500 h-full rounded-full"
          />
        )}
      </Spensor>
    </div>
  )
}
