import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { EmptyState } from "../../../../../components/EmptyState/EmptyState"
import {
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import {
  Pagination,
  PaginationInfo,
} from "../../../../../components/Pagination"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../components/Spensor"
import { TokenCount } from "../../../../../components/TokenCount"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { assertNever } from "../../../../../utils/types"
import {
  StxDxOrderType,
  TradeHistoryRecord,
  TradesHistoryRecordStatus,
} from "../../types"
import { DateRange, DateRangeSelector } from "../DateRangeSelector"
import { fields$t } from "../_/commonIntlMessages"
import {
  CreateDateCell,
  FieldCell,
  RecordRowContainer,
  TokenPairCell,
} from "../_/RecordRow"
import { ReactComponent as FailureIcon } from "./_/statusFailure.svg"
import { ReactComponent as SettlingIcon } from "./_/statusSettling.svg"
import { ReactComponent as SuccessIcon } from "./_/statusSuccess.svg"

export const TradeHistoryTabContent: FC<{
  records: SuspenseResource<TradeHistoryRecord[]>
  pagination?: SuspenseResource<PaginationInfo>
  dateRange?: DateRange
  onChangeDateRange?: (range: DateRange) => void
  onChangePage?: (page: number) => void
}> = props => {
  return (
    <>
      {props.dateRange != null && props.onChangeDateRange != null && (
        <DateRangeSelector
          value={props.dateRange}
          onChange={props.onChangeDateRange}
        />
      )}

      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() =>
          readResource(props.records).length <= 0 ? (
            <EmptyState className={"m-auto"} />
          ) : (
            <ul className={"flex flex-col gap-1"}>
              {readResource(props.records).map((r, idx) => (
                <li key={idx}>
                  {
                    // prettier-ignore
                    r.orderType === StxDxOrderType.Limit ? (<LimitRow record={r} />) :
                    r.orderType === StxDxOrderType.Market ? (<MarketRow record={r} />) :
                    r.orderType === StxDxOrderType.StopLimit ? (<StopLimitRow record={r} />) :
                    assertNever(r)
                  }
                </li>
              ))}
            </ul>
          )
        }
      </Spensor>

      <Spensor>
        {() =>
          props.pagination == null ? null : (
            <Pagination
              {...readResource(props.pagination)}
              onChange={i => props.onChangePage?.(i.page)}
            />
          )
        }
      </Spensor>
    </>
  )
}

const LimitRow: FC<{ record: TradeHistoryRecord.Limit }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountPerTradeToken}
      />
    }
  />
)

const MarketRow: FC<{ record: TradeHistoryRecord.Market }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountAverage}
      />
    }
  />
)

const StopLimitRow: FC<{ record: TradeHistoryRecord.StopLimit }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountPerTradeToken}
      />
    }
  />
)

const StatusIcons = {
  [TradesHistoryRecordStatus.Settling]: SettlingIcon,
  [TradesHistoryRecordStatus.Succeed]: SuccessIcon,
  [TradesHistoryRecordStatus.Failed]: FailureIcon,
}

const statusColors = {
  [TradesHistoryRecordStatus.Settling]: "text-white",
  [TradesHistoryRecordStatus.Succeed]: "text-lime-500",
  [TradesHistoryRecordStatus.Failed]: "text-red-500",
}

const RowTemplate: FC<{
  record: TradeHistoryRecord
  price: ReactNode
}> = props => {
  const { $t } = useIntl()
  const { record } = props
  const Icon = StatusIcons[record.status]

  return (
    <RecordRowContainer>
      <TokenPairCell
        clickToCopyRecordIdentifier={$t(
          defineMessage({
            defaultMessage: "Trade ID: {tradeId} (click to copy)",
            description: "Orderbook/OrderHistory/Trades",
          }),
          {
            tradeId: record.id,
          },
        )}
        recordIdentifier={record.id}
        tradeToken={record.tradeToken}
        priceToken={record.priceToken}
        orderDirection={record.orderDirection}
        orderType={record.orderType}
      />

      {props.record.status != null && (
        <FieldCell columnId={"status"}>
          <InfoListItemTitle>{$t(fields$t.status)}</InfoListItemTitle>
          <InfoListItemDetail>
            <div
              className={clsx(
                "flex flex-row gap-2 items-center",
                statusColors[props.record.status],
              )}
            >
              <Icon className="fill-current" />
              {props.record.status}
            </div>
          </InfoListItemDetail>
        </FieldCell>
      )}

      <FieldCell columnId={"price"}>
        <InfoListItemTitle>{$t(fields$t.price)}</InfoListItemTitle>
        <InfoListItemDetail>{props.price}</InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"amount"}>
        <InfoListItemTitle>{$t(fields$t.amount)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.tradeToken}
            count={props.record.executedTradeTokenCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"total"}>
        <InfoListItemTitle>{$t(fields$t.total)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.priceToken}
            count={props.record.priceTokenTotalCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"fee"}>
        <InfoListItemTitle>{$t(fields$t.fee)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={
              props.record.orderDirection === "buy"
                ? props.record.priceToken
                : props.record.tradeToken
            }
            count={props.record.feePriceTokenCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      <CreateDateCell createdAt={props.record.createdAt} />
    </RecordRowContainer>
  )
}
