import { action, computed, makeObservable, observable } from "mobx"
import { defer } from "../utils/promiseHelpers"

export class SuspenseObservable<T> {
  constructor(defaultValue?: T) {
    this.value = defaultValue
    makeObservable(this)
  }

  @observable.ref private value?: T

  private inProgress?: defer.Deferred<void>

  get(): T | undefined {
    return this.value
  }

  @computed get read$(): T {
    if (this.value === undefined) {
      throw this.reset()
    }
    return this.value
  }

  @action set(value?: T): void {
    this.value = value
    if (value !== undefined) {
      this.inProgress?.resolve()
      this.inProgress = undefined
    }
  }

  private reset(): Promise<void> {
    this.inProgress = this.inProgress ?? defer<void>()
    return this.inProgress.promise
  }
}
