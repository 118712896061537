import { plural } from "../../../../utils/stringHelpers"
import { FarmRowData } from "../../store/transformerGroups/farm/farm"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const farmExporter: Exporter<FarmRowData> = (rowData, allTokenInfos) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.principalCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = `Staking ${rowData.period} ${plural(rowData.period, {
    one: "Cycle",
    many: "Cycles",
  })}`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
