import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { InfoList } from "../../../../../components/InfoList/InfoList"
import {
  DefaultInfoListItem,
  InfoListItem,
} from "../../../../../components/InfoList/InfoListItem"
import {
  DefaultInfoListItemDetail,
  InfoListItemDetail,
} from "../../../../../components/InfoList/InfoListItemDetail"
import {
  DefaultInfoListItemTitle,
  InfoListItemTitle,
} from "../../../../../components/InfoList/InfoListItemTitle"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { BigNumber } from "../../../../../utils/BigNumber"
import { SuspenseResource } from "../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  CardDivider,
  CardHeroText,
  CardStrongText,
  CardText,
  RenderBRC20CardFn,
  defaultRenderBRC20Card,
  useBRC20CardDefaultStyles,
} from "./BRC20Card"

export interface BRC20BalanceCardProps {
  className?: string
  token: TokenInfo
  totalBalance: SuspenseResource<BigNumber>
  transferableBalance: SuspenseResource<BigNumber>
  availableBalance: SuspenseResource<BigNumber>
  renderBRC20Card?: RenderBRC20CardFn
  tailArea?: ReactNode
}

export const BRC20BalanceCard: FC<BRC20BalanceCardProps> = props => {
  const { $t } = useIntl()

  const cardDefaultStyle = useBRC20CardDefaultStyles()

  const renderBRC20Card = props.renderBRC20Card ?? defaultRenderBRC20Card

  return renderBRC20Card({
    className: props.className,
    children: (
      <>
        <CardHeroText>
          <TokenName token={props.token} />
        </CardHeroText>

        <InfoList
          style={{ rowGap: cardDefaultStyle.gap }}
          direction={"column"}
          listItemDirection={"row"}
          renderInfoListItem={p => (
            <DefaultInfoListItem
              {...p}
              className={clsx(p.className, "gap-x-2.5")}
              alignInRow={"start"}
            />
          )}
          renderInfoListItemTitle={p => (
            <DefaultInfoListItemTitle
              {...p}
              className={clsx(p.className, "shrink-0")}
            >
              <CardText className={"block"}>{p.children}</CardText>
            </DefaultInfoListItemTitle>
          )}
          renderInfoListItemDetail={p => (
            <DefaultInfoListItemDetail
              {...p}
              className={clsx(p.className, "text-right break-all")}
              noWrap={false}
            >
              <CardText className={"block"}>{p.children}</CardText>
            </DefaultInfoListItemDetail>
          )}
        >
          <InfoListItem>
            <InfoListItemTitle>
              <CardStrongText>
                {$t(
                  defineMessage({
                    defaultMessage: "Balance",
                    description: "BRC20 Peg-In Page/Balance Card/label",
                  }),
                )}
              </CardStrongText>
            </InfoListItemTitle>
            <InfoListItemDetail>
              <CardStrongText>
                <TokenCount token={props.token} count={props.totalBalance} />
              </CardStrongText>
            </InfoListItemDetail>
          </InfoListItem>

          <CardDivider direction={"horizontal"} />

          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Transferable",
                  description: "BRC20 Peg-In Page/Balance Card/label",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount
                token={props.token}
                count={props.transferableBalance}
              />
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Available",
                  description: "BRC20 Peg-In Page/Balance Card/label",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount token={props.token} count={props.availableBalance} />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>

        {props.tailArea != null && (
          <div className={"mt-auto"}>{props.tailArea}</div>
        )}
      </>
    ),
  })
}
