import clsx from "clsx"
import { FC } from "react"
import { MarkdownContent } from "../../../../components/MarkdownContent/MarkdownContent"
import styles from "./IDOIntro.module.scss"
import { rehypeTransformIdHashLink } from "./_/rehypeTransformIdHashLink"

export interface IDOIntroProps {
  className?: string
  idoId: string
  markdown: string
}

export const IDOIntro: FC<IDOIntroProps> = props => {
  return (
    <MarkdownContent
      key={props.idoId}
      plugins={[
        [rehypeTransformIdHashLink, (id: string) => `${props.idoId}-${id}`],
      ]}
      className={clsx(
        "prose dark:prose-invert",
        styles.article,
        props.className,
      )}
      markdown={props.markdown}
    />
  )
}
