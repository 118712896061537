import clsx from "clsx"
import { FC } from "react"
import { MediaLinks } from "../../../components/MediaLinks/MediaLinks"
import { Truncatable } from "../../../components/Truncatable"
import { IDOStatusBadge } from "./IDOStatusBadge"
import { TokenIDOStatus, TokenMetaInfo } from "./types"

export interface TokenNameCardProps {
  className?: string
  meta: TokenMetaInfo
  idoStatus: TokenIDOStatus
}

export const TokenNameCard: FC<TokenNameCardProps> = props => (
  <div className={clsx("flex gap-6", props.className)}>
    <img
      className={"w-24 h-24 rounded-lg"}
      alt={"Token logo"}
      src={props.meta.logoSrc}
    />
    <div className={"flex-1 flex flex-col min-w-0"}>
      <div className={"flex-1 flex flex-row flex-wrap"}>
        <div className={"w-full flex-1"}>
          <div>
            <span className={"text-gray-200 text-2xl leading-8 font-medium"}>
              {props.meta.title}
            </span>
          </div>

          <p
            className={"text-base leading-6 font-normal text-gray-400 mb-auto"}
          >
            <Truncatable className={"inline-block w-full whitespace-nowrap"}>
              {props.meta.subtitle}
            </Truncatable>
          </p>
        </div>

        <div className={"mb-6"}>
          <IDOStatusBadge status={props.idoStatus} />
        </div>
      </div>

      <MediaLinks links={props.meta.links} />
    </div>
  </div>
)
