import { FC, Ref } from "react"
import { Spensor } from "../../../../components/Spensor"
import { safelyGet } from "../../../../utils/waitFor"
import { TransferablePanel } from "../components/TransferablePanel"
import { useBulkTransferInscriptionPageStore } from "../stores/useBulkTransferInscriptionPageStore"

export const WiredTransferablePanel: FC<{
  className?: string
  containerRef?: Ref<HTMLElement>
}> = props => {
  const pegInStore = useBulkTransferInscriptionPageStore()

  if (safelyGet(() => pegInStore.bitcoinWalletAddresses$) == null) return null

  return (
    <Spensor>
      {() => {
        if (pegInStore.transferable.recentTransferInscriptions$.length <= 0) {
          return null
        }

        return (
          <TransferablePanel
            containerRef={props.containerRef}
            className={props.className}
            transferableInscriptions={
              pegInStore.transferable.recentTransferInscriptions$
            }
            onSend={
              pegInStore.transferable.sendable
                ? () => pegInStore.transferable.sendInscriptions()
                : undefined
            }
          />
        )
      }}
    </Spensor>
  )
}
