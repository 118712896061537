import { parseISO } from "date-fns"
import { action } from "mobx"
import { FC } from "react"
import { Modal } from "../../../components/Modal"
import { useCRPSimulatorStore } from "../store/useCRPSimulatorStore"
import { DataComparison, DataComparisonRowData } from "./DataComparison"

export const dataComparisonData: DataComparisonRowData[] = [
  {
    platform: "ALEX",
    maxLTV: 75,
    loanableTokenLimit: 31033,
    remainingValues: [39986, 35679],
    liquidated: false,
  },
  {
    platform: "AAVE V2",
    maxLTV: 70,
    loanableTokenLimit: 28964,
    liquidationPrice: 38619,
    remainingValues: [36688, 36688],
    liquidated: true,
  },
  {
    platform: "Celsius",
    maxLTV: 50,
    loanableTokenLimit: 20689,
    liquidationPrice: 25861,
    remainingValues: [38619, 30066],
    liquidated: false,
  },
]

export const WiredDataComparisonModal: FC = () => {
  const store = useCRPSimulatorStore()

  return (
    <Modal
      visible={store.showDataComparison}
      onClose={action(() => {
        store.showDataComparison = false
      })}
    >
      <DataComparison
        remainingValueDates={[parseISO("2022-04-25"), parseISO("2022-05-10")]}
        dateRange={[parseISO("2022-04-25"), parseISO("2022-05-10")]}
        initialPrice={41377}
        data={dataComparisonData}
        onClose={action(() => {
          store.showDataComparison = false
        })}
      />
    </Modal>
  )
}
