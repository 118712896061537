import { from, map, Observable, switchMap } from "rxjs"
import { sendRequest } from "../../../../../generated/stxdxHelpers/stxdxApi"
import {
  Order,
  OrderListOrderType,
} from "../../../components/OrderbookList/types"
import { refreshOrderHistorySignal } from "../../stxdx_shared/orderbookHelpers"
import { OrderbookMarketId } from "../../stxdx_shared/StxDxMarket.service"

export const fetchMyTradeOrders = (
  auth: string,
  market: OrderbookMarketId,
): Observable<Order.MyTradeOrder[]> => {
  return refreshOrderHistorySignal(auth).pipe(
    switchMap(() =>
      from(
        sendRequest(auth)("OrderController_getFills", {
          query: {
            market,
          },
        }),
      ),
    ),
    map(resp =>
      resp.data.fills.map(
        (f): Order.MyTradeOrder => ({
          type: OrderListOrderType.MyTradeOrders,
          isBelongsToCurrentUser: true,
          createdAt: new Date(f.created_at),
          tradeTokenCount: Number(f.size),
          priceTokenCountPerTradeToken: Number(f.price),
          orderDirection: f.side,
        }),
      ),
    ),
  )
}
