import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { WhiteFilledButton } from "../../../../../../components/button/variants/WhiteFilledButton"
import {
  SuspenseResource,
  readResource,
} from "../../../../../../utils/SuspenseResource"
import { trunc } from "../../../../../../utils/addressHelpers"
import { normal } from "../../../../../Perpetual/components/buttonPresets"
import { RectButton } from "../../../../../Perpetual/components/wrappedCommonComponents/RectButton"
import { ReactComponent as CopyIcon } from "../../_/copy.svg"

export const AddressRow: FC<{
  className?: string
  title?: ReactNode
  disabled?: boolean
  address: SuspenseResource<string>
  addressFontSizeClassName?: string
  icon: ReactNode
  truncate: boolean
  backgroundClassName?: string
  onCopy?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <div className={clsx("space-y-2.5", props.className)}>
      {props.title != null && (
        <div className="font-bold text-sm text-white whitespace-nowrap">
          {props.title}
        </div>
      )}
      <div
        className={clsx(
          "rounded px-2.5 py-2 h-12 flex justify-between items-center",
          props.backgroundClassName ?? "bg-blue-600",
        )}
      >
        <div
          className={clsx(
            "text-white flex items-center space-x-2.5",
            props.addressFontSizeClassName ?? "text-sm",
          )}
        >
          {props.icon}
          <span className={clsx(props.disabled ? "opacity-50" : "")}>
            {props.truncate
              ? trunc(readResource(props.address), { head: 8, tail: 8 })
              : readResource(props.address)}
          </span>
        </div>
        {props.onCopy && (
          <RectButton
            disabled={props.disabled}
            className={"flex items-center space-x-2.5 "}
            Variant={WhiteFilledButton}
            {...normal}
            onClick={props.onCopy}
          >
            <CopyIcon />
            <span className="text-xs">
              {$t(
                defineMessage({
                  defaultMessage: "Copy",
                  description:
                    "/OrderBook/BRC20/PegOutBrc20ModalContent/PegOutProgress/Copy text",
                }),
              )}
            </span>
          </RectButton>
        )}
      </div>
    </div>
  )
}
