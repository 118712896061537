import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { BlockTimeCountdownText } from "../../../../../components/TimeCountdown/BlockTimeCountdownText"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ReactComponent as AfterRegisteredIcon } from "./afterRegisteredImage.svg"
import { HowLotteryWorkLink } from "./HowLotteryWorkLink"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"
import { LotteryTicket } from "./LotteryTicketCard/types"
import { PurpleBlock } from "./PurpleBlock"

export const ActionSectionContent$AfterRegistered: FC<{
  currentBlock: number
  startedAt: Date
  startedAtBlock: number
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTickets: LotteryTicket[]
}> = props => {
  const { $t } = useIntl()
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <AfterRegisteredIcon />

        <CardInsetTitle>
          {$t(
            defineMessage({
              defaultMessage:
                "You have completed validation, Lottery drawing will begin in",
              description:
                "/Launchpad/ActionSectionContentAfterRegistered/Title",
            }),
          )}
        </CardInsetTitle>

        <PurpleBlock
          primaryText={
            <BlockTimeCountdownText
              targetBlock={props.startedAtBlock}
              currentBlock={props.currentBlock}
              time={props.startedAt}
              estimateSuffix={<> (Estimate)</>}
            />
          }
          secondaryText={
            <>
              {$t(
                defineMessage({
                  defaultMessage: "start at: Block {startBlock}",
                  description:
                    "/Launchpad/ActionSectionContentAfterRegistered/Secondary text",
                }),
                {
                  startBlock: props.startedAtBlock,
                },
              )}
            </>
          }
        />
      </div>

      <CardInsetDivider />
      <CardInsetTitle>
        {$t(
          defineMessage({
            defaultMessage: "Your Lottery Ticket",
            description:
              "/Launchpad/ActionSectionContentAfterRegistered/Card inset title",
          }),
        )}
      </CardInsetTitle>
      <ul className={"flex flex-wrap justify-center gap-2.5"}>
        {props.lotteryTickets.map((t, idx) => (
          <li key={idx}>
            <LotteryTicketCard
              idoToken={props.idoToken}
              priceToken={props.priceToken}
              lotteryTicket={t}
            />
          </li>
        ))}
      </ul>

      <HowLotteryWorkLink />
    </CardInset>
  )
}
