export interface LongTextIntlMessage {
  "en-US": string
  "zh-CN"?: string
  "zh-TW"?: string
}

export const fromGraphqlResult = (
  result:
    | null
    | undefined
    | {
        en?: null | undefined | string
        cn?: null | undefined | string
      },
): LongTextIntlMessage => ({
  "en-US": result?.en ?? "",
  "zh-CN": result?.cn ?? undefined,
})
