import { FC, useState } from "react"
import { Modal } from "../../../../components/Modal"
import { TimeCountdown } from "../../../../components/TimeCountdown/TimeCountdown"
import { useChainStore } from "../../../../stores/chainStore/useChainStore"
import { useLaunchPadContentfulStore } from "../../store/useLaunchPadStores"
import { IDOSteps } from "../IDOSteps"
import { StepEndCountdown } from "../StepEndCountdown"
import { ValidateAPowerRuleModalContent } from "../ValidateAPowerRuleModalContent/ValidateAPowerRuleModalContent"
import { WiredIDOSteps as WiredDashboardIDOSteps } from "../WiredIDOSteps"
import { WiredIDODetailSidePanelImpl } from "./_/WiredIDODetailSidePanelImpl"

export const WiredIDODetailSidePanel: FC<{ className?: string }> = props => {
  const [apowerRuleModalVisible, setApowerRuleModalVisible] = useState(false)
  const store = useLaunchPadContentfulStore()
  const ranges =
    store.contractStore$?.apowerRanges$ ?? store.contentfulDetail$.aPowerRules
  const maxAllowTicket =
    store.contractStore$?.register.maxAllowedTicket$ ??
    store.contentfulDetail$.maxAllowedTickets
  return (
    <>
      <WiredIDODetailSidePanelImpl
        className={props.className}
        idoSteps={<WiredIDOSteps />}
        titleRightSide={<WiredBlockTimeCountdown />}
        onLearnMoreAPowerRule={() => {
          setApowerRuleModalVisible(true)
        }}
      />

      <Modal
        visible={apowerRuleModalVisible}
        onClose={() => {
          setApowerRuleModalVisible(false)
        }}
      >
        <ValidateAPowerRuleModalContent
          ranges={ranges}
          maxTickets={maxAllowTicket}
          onConfirm={() => {
            setApowerRuleModalVisible(false)
          }}
        />
      </Modal>
    </>
  )
}

const WiredBlockTimeCountdown: FC = () => {
  const store = useLaunchPadContentfulStore()
  const chainStore = useChainStore()

  if (store.contractStore$ == null) {
    const start = store.contentfulDetail$.estimateValidationStart
    if (start) {
      return <TimeCountdown prefix="Step countdown" time={start} />
    }
    return null
  }

  const endDate = store.contractStore$.steps.currentStatusEndedAt$
  const endBlock = store.contractStore$.steps.currentStatusEndedAtBlock$
  if (endDate == null || endBlock == null) return null

  return (
    <StepEndCountdown
      currentStepNumber={store.contractStore$.steps.currentStepNumber$}
      currentStepEndedAt={endDate}
      endBlock={endBlock}
      currentBlock={chainStore.currentBlockHeight$}
    />
  )
}

const WiredIDOSteps: FC = () => {
  const store = useLaunchPadContentfulStore()

  if (store.contractStore$ == null) {
    return (
      <IDOSteps
        currentStepNumber={0}
        validateTime={store.contentfulDetail$.estimateValidationStart}
        lotteryTime={store.contentfulDetail$.estimateValidationEnd}
      />
    )
  }

  return <WiredDashboardIDOSteps />
}
