import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { brc20CurrencyFromNativeBrc20Ticker } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { isPromiseLike } from "../../../../utils/promiseHelpers"

export function getTokenInfoFromBRC20Symbol$(
  currencyStore: CurrencyStore,
  symbol: string,
): TokenInfo | undefined {
  const currency = brc20CurrencyFromNativeBrc20Ticker(symbol)

  try {
    return currencyStore.getTokenInfo$(currency)
  } catch (e) {
    if (isPromiseLike(e)) throw e
    return undefined
  }
}
