import clsx from "clsx"
import { FC, SVGProps } from "react"
import { FCC } from "../utils/reactHelpers/types"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { OpacityButton } from "./button/variants/OpacityButton"

export interface PaginationInfo {
  /**
   * zero based page number
   */
  currentPage: number

  recordCountPerPage: number

  recordCountTotal: number
}

export interface PaginationProps extends PaginationInfo {
  className?: string
  onChange?: (info: { page: number }) => void
}

export const SimplerPagination: FC<PaginationProps> = props => {
  const totalPageCount = Math.ceil(
    props.recordCountTotal / props.recordCountPerPage,
  )

  const isFirstPage = props.currentPage === 0
  const isLastPage = props.currentPage === totalPageCount - 1

  return (
    <div
      className={clsx(
        "flex flex-row justify-between align-center",
        props.className,
      )}
    >
      <PaginateButton
        disabled={isFirstPage}
        onClick={() => props.onChange?.({ page: props.currentPage - 1 })}
      >
        <ArrowIcon />
      </PaginateButton>

      <p
        className={
          "self-center inline-block text-sm leading-5 font-normal text-white"
        }
      >
        <PaginateNumber>{props.currentPage + 1}</PaginateNumber> /{" "}
        <PaginateNumber>{totalPageCount}</PaginateNumber>
      </p>

      <PaginateButton
        disabled={isLastPage}
        onClick={() => props.onChange?.({ page: props.currentPage + 1 })}
      >
        <ArrowIcon className={"rotate-180"} />
      </PaginateButton>
    </div>
  )
}

const PaginateButton: FCC<{
  className?: string
  disabled?: boolean
  onClick?: () => void
}> = props => {
  return (
    <OpacityButton
      className={clsx(props.className)}
      disabled={props.disabled}
      boxClassName={"px-4 py-2.5"}
      textClassName={
        "text-sm leading-5 font-normal text-gray-400 hover:text-white active:text-white"
      }
      bgClassName={clsx(
        "bg-white hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-15 disabled:bg-black disabled:bg-opacity-15",
        "bg-opacity-5",
      )}
      roundedClassName={"rounded-lg"}
      onClick={props.onClick}
    >
      {props.children}
    </OpacityButton>
  )
}

const PaginateNumber = withClassName("font-bold", "span")

const ArrowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#9CA3AF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.91502 7.97589L10.6876 13.7915L11.6304 12.8487L6.7572 7.97551L11.6296 3.10309L10.6868 2.16028L4.91502 7.97589Z" />
  </svg>
)
