import { reaction } from "mobx"
import AuthStore from "../stores/authStore/AuthStore"
import { defer } from "./promiseHelpers"

export async function retryWhenBlockChangedUntil<T>(
  authStore: Pick<AuthStore, "currentBlockHeight$">,
  action: () => Promise<T>,
  predicate: (value: T) => boolean,
  options?: { fireImmediately?: boolean },
): Promise<T> {
  const { resolve, promise } = defer<T>()
  const dispose = reaction(
    () => authStore.currentBlockHeight$,
    async () => {
      const result = await action().catch(() => null)
      if (result != null && predicate(result)) {
        dispose()
        resolve(result)
      }
    },
    { fireImmediately: options?.fireImmediately },
  )
  return promise
}
