import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../../components/NoteParagraph/NoteParagraph"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"

export const QueuedSection: FC<{
  className?: string
  queuingPegRequestCount: SuspenseResource<number>
  warningText: string
}> = props => {
  const { $t } = useIntl()
  return (
    <div className="flex flex-col items-center bg-gray-900 rounded-lg p-3 space-y-1.5">
      <h5 className="text-white text-lg font-medium flex flex-row gap-x-2.5 items-center">
        {$t(
          defineMessage({
            defaultMessage: "Current requests queue: <strong>{count}</strong>",
            description: "/Launchpad/RedeemOrdinalsModalContent/Title text",
          }),
          {
            strong: dontWrapObserver(contents => (
              <p className="text-yellow-300 font-semibold text-2xl">
                {contents}
              </p>
            )),
            count: safeReadResource(props.queuingPegRequestCount) ?? "-",
          },
        )}
      </h5>

      <NoteParagraph textColorClassName={"text-yellow-500"} icon={<WarnIcon />}>
        {props.warningText}
      </NoteParagraph>
    </div>
  )
}
