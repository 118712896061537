import clsx from "clsx"
import { FC } from "react"
import { RoundedButtonVariantProps } from "./BaseRoundedFilledButton"

export const LinkStyleButton: FC<RoundedButtonVariantProps> = props => (
  <span
    {...props}
    className={clsx(
      "text-blue-600",
      typeof props.onClick === "function" && "cursor-pointer",
      props.className,
    )}
  >
    {props.children}
  </span>
)
