import * as RadixPortal from "@radix-ui/react-portal"
import clsx from "clsx"
import { ComponentType, ReactNode, useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { FCC } from "../utils/reactHelpers/types"
import {
  isClickOnAnyChildNode,
  useOnClickOutside,
} from "../utils/reactHelpers/useOnClickOutside"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { withSlot } from "../utils/reactHelpers/withSlot"
import { useDialogVisibleManager } from "./DialogVisibleManager/DialogVisibleManager"

export interface ModalProps {
  modalDebugName?: string
  visible: boolean
  onClose?: () => void
  ContentLimiter?: ComponentType<{ children?: ReactNode }>
}

export const Modal: FCC<ModalProps> = props => {
  const { ContentLimiter = ContentContainerNarrowerThanScreen } = props

  const manager = useDialogVisibleManager(props.modalDebugName)

  const contentContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    manager.requestChangeVisible(props.visible)
  }, [manager, props.visible])

  useOnClickOutside(
    event =>
      manager.isFocusing &&
      (contentContainerRef.current == null ||
        !isClickOnAnyChildNode(event, contentContainerRef.current)),
    () => {
      props.onClose?.()
    },
  )

  return (
    <>
      {manager.visible && (
        <RadixPortal.Root className={"fixed inset-0 z-1"}>
          <Helmet>
            <html style={"height: 100%; overflow: hidden;" as any} />
          </Helmet>
          {manager.isFocusing && (
            <div className="absolute inset-0 bg-black bg-opacity-60" />
          )}
          <div
            ref={contentContainerRef}
            className={"absolute inset-0 overflow-y-auto flex"}
          >
            <ContentLimiter>{props.children}</ContentLimiter>
          </div>
        </RadixPortal.Root>
      )}
    </>
  )
}

export const ContentContainerWiderThanScreen = withSlot<{
  className?: string
}>("div", props => (
  <props.Comp className={clsx("m-auto p-4", props.className)}>
    {props.children}
  </props.Comp>
))

export const ContentContainerNarrowerThanScreen = withClassName(
  "max-w-full",
  ContentContainerWiderThanScreen,
)
