import {
  booleanT,
  bufferT,
  defineContract,
  numberT,
  principalT,
  responseSimpleT,
  stringAsciiT,
} from "../smartContractHelpers/codegenImport"

export const b20BridgeEndpointHelper = defineContract({
  "b20-bridge-endpoint-helper": {
    "register-and-request-peg-in": {
      input: [
        { name: "token", type: principalT },
        { name: "amount", type: numberT },
        { name: "memo", type: stringAsciiT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "register-and-request-peg-out": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "amount", type: numberT },
        { name: "peg-out-address", type: stringAsciiT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "register-stxdx-and-request-peg-in": {
      input: [
        { name: "pub-key", type: bufferT },
        { name: "token", type: principalT },
        { name: "amount", type: numberT },
        { name: "memo", type: stringAsciiT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
  },
} as const)
