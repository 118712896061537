class Node<T> {
  value: T
  next?: Node<T>

  constructor(value: T) {
    this.value = value
  }
}

class Queue<T> {
  #head?: Node<T>
  #tail?: Node<T>
  #size = 0

  constructor() {
    this.clear()
  }

  enqueue(value: T): void {
    const node = new Node(value)

    if (this.#head != null && this.#tail != null) {
      this.#tail.next = node
      this.#tail = node
    } else {
      this.#head = node
      this.#tail = node
    }

    this.#size++
  }

  dequeue(): T | undefined {
    if (this.#head == null) {
      return
    }

    const current = this.#head
    this.#head = this.#head.next
    this.#size--
    return current.value
  }

  clear(): void {
    this.#head = undefined
    this.#tail = undefined
    this.#size = 0
  }

  get size(): number {
    return this.#size
  }

  *[Symbol.iterator](): Generator<T> {
    let current = this.#head

    while (current) {
      yield current.value
      current = current.next
    }
  }
}

export type LimitedFunction = <Input extends any[], Output>(
  fn: (...input: Input) => Promise<Output>,
  ...args: Input
) => Promise<Output>

export default function pLimit(concurrency: number): LimitedFunction {
  if (
    !(
      (Number.isInteger(concurrency) ||
        concurrency === Number.POSITIVE_INFINITY) &&
      concurrency > 0
    )
  ) {
    throw new TypeError("Expected `concurrency` to be a number from 1 and up")
  }

  const queue = new Queue<() => void>()
  let activeCount = 0

  const next = (): void => {
    activeCount--

    if (queue.size > 0) {
      queue.dequeue()?.()
    }
  }

  const run = async <Input extends any[], Output>(
    fn: (...input: Input) => Promise<Output>,
    resolve: (output: Promise<Output>) => void,
    args: Input,
  ): Promise<void> => {
    activeCount++
    const result = (async () => fn(...args))()
    resolve(result)

    try {
      await result
    } catch {}

    next()
  }

  const enqueue = <Input extends any[], Output>(
    fn: (...input: Input) => Promise<Output>,
    resolve: (p: Promise<Output>) => void,
    args: Input,
  ): void => {
    queue.enqueue((run as any).bind(undefined, fn, resolve, args))
    void (async () => {
      // This function needs to wait until the next microtask before comparing
      // `activeCount` to `concurrency`, because `activeCount` is updated asynchronously
      // when the run function is dequeued and called. The comparison in the if-statement
      // needs to happen asynchronously as well to get an up-to-date value for `activeCount`.
      await Promise.resolve()

      if (activeCount < concurrency && queue.size > 0) {
        queue.dequeue()?.()
      }
    })()
  }

  const generator = <Input extends any[], Output>(
    fn: (...input: Input) => Promise<Output>,
    ...args: Input
  ): Promise<Output> =>
    new Promise(resolve => {
      enqueue(fn, resolve, args)
    })

  Object.defineProperties(generator, {
    activeCount: {
      get: () => activeCount,
    },
    pendingCount: {
      get: () => queue.size,
    },
    clearQueue: {
      value: () => {
        queue.clear()
      },
    },
  })

  return generator
}
