import { FC, Ref } from "react"
import { defineMessage } from "react-intl"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../../components/Modal"
import { Spensor } from "../../../../components/Spensor"
import { Result } from "../../../../utils/Result"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { isBRC20Token } from "../../../../utils/alexjs/Currency"
import { InscribeConfirmModalContent } from "../components/InscribeConfirmModalContent"
import { TransferPanel } from "../components/TransferPanel"
import { useBulkTransferInscriptionPageStore } from "../stores/useBulkTransferInscriptionPageStore"

export const WiredTransferPanel: FC<{
  className?: string
  containerRef?: Ref<HTMLElement>
}> = props => {
  const pegInStore = useBulkTransferInscriptionPageStore()

  const { pegInForm } = pegInStore
  const { inscribeFormData } = pegInStore.pegInForm

  return (
    <>
      <TransferPanel
        className={props.className}
        containerRef={props.containerRef}
        prerequisites={suspenseResource(() =>
          pegInStore.bitcoinWalletAddresses$ == null
            ? "connectWallet"
            : undefined,
        )}
        availableTokens={suspenseResource(
          () => pegInStore.pegInForm.brc20WalletBalance$,
        )}
        selectedToken={suspenseResource(
          () => pegInStore.pegInForm.peggingToken$,
        )}
        onSelectedToken={token => {
          const currency = token?.id
          pegInStore.pegInForm.peggingCurrency.set(
            currency == null || !isBRC20Token(currency) ? undefined : currency,
          )
        }}
        tokenAmount={pegInStore.pegInForm.peggingAmount.get() ?? null}
        onTokenAmountChange={amount => {
          pegInStore.pegInForm.peggingAmount.set(amount ?? undefined)
        }}
        formError={suspenseResource(() =>
          Result.maybeError(pegInStore.pegInForm.formData$),
        )}
        onSubmit={suspenseResource(() => {
          const formData = Result.maybeValue(pegInStore.pegInForm.formData$)
          return formData == null
            ? undefined
            : () => pegInStore.pegInForm.submit(formData)
        })}
      />

      <Modal
        visible={pegInForm.inscribeFormData.get() != null}
        onClose={() => pegInForm.onDismissInscribeConfirmModal()}
      >
        <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
          {() => (
            <InscribeConfirmModalContent
              token={inscribeFormData.read$.token}
              tokenCount={inscribeFormData.read$.tokenCount}
              btcNetwork={inscribeFormData.read$.btcNetwork}
              inscribeServiceFeeToken={
                inscribeFormData.read$.inscribeServiceFeeToken
              }
              inscribeServiceFeeTokenCount={
                inscribeFormData.read$.inscribeServiceFeeTokenCount
              }
              inscribeServiceFeeTokenCountToUSD={
                inscribeFormData.read$.inscribeServiceFeeTokenCountToUSD
              }
              onConfirm={async () => {
                await pegInStore.generalTxStore.run(
                  () => pegInForm.confirmInscribeTransfer(),
                  {
                    successMsg: ({ intl }) =>
                      intl.$t(
                        defineMessage({
                          defaultMessage:
                            "The inscription may take up to several hours to appear in your wallet. Once received, head to your collectible dashboard and send it to your recipient to complete the token transfer.",
                          description:
                            "Admin Bulk Send Inscriptions Page/Transfer Panel/Inscribe Confirm Modal/Success Message",
                        }),
                      ),
                  },
                )
              }}
              onClose={() => pegInForm.onDismissInscribeConfirmModal()}
            />
          )}
        </Spensor>
      </Modal>
    </>
  )
}
