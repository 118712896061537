import { openContractDeploy } from "@stacks/connect"
import { AnchorMode, PostConditionMode } from "@stacks/transactions"
import { ElementRef, FC, Suspense, useRef, useState } from "react"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { Divider } from "../../components/Divider"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  CONTRACT_DEPLOYER,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../config"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { ConfirmTransactionStore } from "../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { CreateNewIdo, ProcessLaunchPad } from "./components/LaunchpadSetup"

export const ConnectWallet: FC = () => {
  const [connect] = useConnect()
  return (
    <PageStackPage>
      <div>
        <h1>Connect your wallet</h1>
        <GradientFilledButton onClick={() => connect()}>
          Connect Wallet
        </GradientFilledButton>
      </div>
    </PageStackPage>
  )
}

const DeployContract: FC = () => {
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  return (
    <div className="flex flex-col gap-2">
      <input
        placeholder="Name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <textarea
        placeholder="Code"
        value={code}
        onChange={e => setCode(e.target.value)}
      />
      <GradientFilledButton
        onClick={async () => {
          await openContractDeploy({
            network: STACK_NETWORK,
            codeBody: code,
            contractName: name,
            appDetails: STACK_APP_DETAILS,
            anchorMode: AnchorMode.Any,
            postConditionMode: PostConditionMode.Allow,
            onFinish: e => {
              console.log(e)
            },
            onCancel: () => console.error("Cancelled"),
          })
        }}
      >
        Deploy
      </GradientFilledButton>
    </div>
  )
}

const Content: FC = () => {
  const authStore = useAuthStore()
  const chainStore = useChainStore()
  const store = useCreation(() => new ConfirmTransactionStore(), [])
  const block = chainStore.currentBlockHeight$
  const processRef = useRef<ElementRef<typeof ProcessLaunchPad>>(null)

  return (
    <PageStackPage
      title={`Create IDO: ${block} - ${
        authStore.stxAddress$ === CONTRACT_DEPLOYER ? "asOwner" : "asUser"
      }`}
    >
      <Divider className={"w-full my-3"} />
      <div className="grid grid-cols-24 gap-3">
        <div className="flex flex-col col-span-8 gap-3">
          <DeployContract />
        </div>
        <CreateNewIdo
          store={store}
          onAdded={id => processRef.current?.setIdoId(id)}
        />
        <ProcessLaunchPad ref={processRef} store={store} />
      </div>
      <WiredTransactionStateDialog store={store} />
    </PageStackPage>
  )
}

export const AdminPage: FC = () => {
  const authStore = useAuthStore()
  if (!authStore.isWalletConnected) {
    return <ConnectWallet />
  }
  return (
    <Suspense fallback={null}>
      <Content />
    </Suspense>
  )
}
