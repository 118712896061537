import clsx from "clsx"
import { FC, ReactNode } from "react"
import { CardPlate } from "../../../components/Card"
import { FCC } from "../../../utils/reactHelpers/types"

export const AddStakeSectionInfoList: FCC<{
  className?: string
}> = props => (
  <CardPlate className={clsx("flex flex-col gap-2", props.className)}>
    {props.children}
  </CardPlate>
)

export const AddStakeSectionInfoListItem: FC<{
  title: ReactNode
  detail: ReactNode
}> = props => (
  <dl className={"text-sm leading-5 font-normal flex flex-row justify-between"}>
    <dt className={"text-gray-400"}>{props.title}</dt>
    <dd className={"text-gray-200"}>{props.detail}</dd>
  </dl>
)
