import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Truncatable } from "../../../../../components/Truncatable"

export interface LabelProps {
  className?: string

  /**
   * @default "left"
   */
  align?: "left" | "right"

  title: ReactNode
  titleColorClassName?: string

  detail: ReactNode
  detailColorClassName?: string
}

export const Label: FC<LabelProps> = props => {
  return (
    <dl
      className={clsx(
        props.className,
        "flex flex-col gap-1",
        (props.align ?? "left") === "left" ? "items-start" : "items-end",
      )}
    >
      <dt
        className={clsx(
          "text-sm leading-none whitespace-nowrap max-w-[100px] sm:max-w-[140px]",
          props.titleColorClassName ?? "text-gray-400",
        )}
      >
        <Truncatable className={"block"}>{props.title}</Truncatable>
      </dt>
      <dd
        className={clsx(
          "text-base leading-none whitespace-nowrap max-w-[100px] sm:max-w-[140px]",
          props.detailColorClassName ?? "text-gray-200",
        )}
      >
        <Truncatable className={"block"}>{props.detail}</Truncatable>
      </dd>
    </dl>
  )
}
