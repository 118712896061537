import { FC } from "react"
import { ConnectorBgProps } from "../../../WrapBridge/components/WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"
import bgImgSrc from "./_/bg.svg"

export const ConnectorBg: FC<ConnectorBgProps> = props => (
  <div
    style={{
      width: props.width,
      height: props.height,
      backgroundImage: `url(${bgImgSrc})`,
      backgroundSize: "100% 100%",
    }}
  />
)
