import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CoFarmClaimRowData } from "../../../../store/transformerGroups/farm/coFarmClaim"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const CoFarmClaimRow: FC<{
  rowData: CoFarmClaimRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => {
  const { $t } = useIntl()
  return (
    <BaseRow
      rowData={rowData}
      currencyCell={
        <OneLineCurrencyCell
          allTokenInfos={allTokenInfos}
          toCurrencyAmounts={[rowData.claimedPrincipalCurrencyAmount]}
        />
      }
      amountCell={
        <OneLineAmountCell
          allTokenInfos={allTokenInfos}
          toCurrencyAmounts={[rowData.claimedPrincipalCurrencyAmount]}
        />
      }
      tradeInfoCell={
        <BaseCellContainer>
          <p className="whitespace-nowrap">
            {$t(
              defineMessage({
                defaultMessage: "Co-Farm Claim",
                description: "/Notify/Transaction/Table/Co farm claim row",
              }),
            )}
          </p>
        </BaseCellContainer>
      }
    />
  )
}
