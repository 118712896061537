import { SharedLazyValue } from "./LazyValue"

export function sharedLazyValue<T>(factory$: () => T): SharedLazyValue<T> {
  return {
    get value$() {
      return factory$()
    },
    triggerUpdate: async () => {
      // do nothing
    },
  }
}
