import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { TokenNameSelect } from "../../../components/TokenInput/TokenNameSelect"
import { useMessage } from "../../../components/message/MessageProvider"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { Result } from "../../../utils/Result"
import {
  safeReadResource,
  suspenseResource,
} from "../../../utils/SuspenseResource"
import { alertError, getErrorMessage } from "../../../utils/error"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { FormErrorType } from "../types/types"
import { WiredChainConnector } from "./WiredChainConnector"
import {
  WrapBridgeNetworkComponent,
  WrapBridgeNetworkSelectComponent,
} from "./WrapBridgePanel/WrapBridgeNetwork/WrapBridgeNetworkComponent"
import {
  FromInput,
  SideFrame,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"

export const WiredFromForm: FC = () => {
  const { $t } = useIntl()
  const store = useWrapBridgeStore()
  const message = useMessage()
  const { metaMaskModule } = useAuthStore()

  const errorType = safeReadResource(
    suspenseResource(
      () => Result.maybeError(store.wrapFormViewModule.formData$)?.type,
    ),
  )
  const shouldDisable = [
    FormErrorType.UnsupportedSourceToken,
    FormErrorType.UnsupportedTargetToken,
    FormErrorType.UnsupportedSourceChain,
    FormErrorType.UnsupportedTargetChain,
  ].includes(errorType!)

  return (
    <SideFrame
      network={
        store.wrapForm.direction === "wrap" ? (
          <WrapBridgeNetworkSelectComponent
            network={store.wrapFormViewModule.fromNetwork}
            availableNetworks={store.wrapFormViewModule.availableETHNetworks$}
            onChange={async network => {
              if (!metaMaskModule.isWalletConnected) {
                await metaMaskModule.connect()
              }
              store.wrapFormViewModule.switchToETHNetwork(network).catch(
                alertError(message, err =>
                  $t(
                    defineMessage({
                      defaultMessage:
                        "Switch chain failed{errorMessage, select, empty {} other {: {errorMessage}}}",
                      description: `/WrapBridge/"from" panel/switch chain failed`,
                    }),
                    {
                      errorMessage: getErrorMessage(err) ?? "empty",
                    },
                  ),
                ),
              )
            }}
          />
        ) : (
          <WrapBridgeNetworkComponent
            network={store.wrapFormViewModule.fromNetwork}
          />
        )
      }
      address={<WiredChainConnector chain={store.wrapForm.fromChain} />}
    >
      <FromInput
        error={[
          FormErrorType.SourceTokenInsufficientBalance,
          FormErrorType.UnsupportedSourceToken,
          FormErrorType.SourceTokenAmountTooSmall,
          FormErrorType.SourceTokenAmountTooLarge,
        ].includes(errorType!)}
        disabled={shouldDisable}
        token={suspenseResource(() => store.wrapFormViewModule.fromToken$)}
        value={store.wrapForm.fromTokenCount.get() ?? null}
        onChange={newV => store.wrapForm.setFromTokenCount(newV)}
        onPressMax={
          shouldDisable
            ? undefined
            : () => store.wrapForm.setMaxFromTokenCount()
        }
        tokenNameArea={
          <TokenNameSelect
            availableTokens={store.wrapFormViewModule.availableFromTokens$}
            onChange={token => store.wrapFormViewModule.setFromToken(token)}
            selectedToken={store.wrapFormViewModule.fromToken$}
          />
        }
        balance={suspenseResource(
          () => store.wrapForm.fromChainCurrencyBalance$,
        )}
        usdCount={suspenseResource(
          () => store.wrapFormViewModule.fromTokenCountToUSD$,
        )}
      />
    </SideFrame>
  )
}
