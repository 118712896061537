import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../../utils/createStore"
import { FCC } from "../../../../utils/reactHelpers/types"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import { OrderbookPegInStore } from "./OrderbookPegInStore"

const Store = createStore<OrderbookPegInStore>("OrderbookPegInStore")

export const OrderbookPegInStoreProvider: FCC = props => {
  const authStore = useAuthStore()
  const chainStore = useChainStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()

  const store = useCreation(
    () =>
      new OrderbookPegInStore(
        authStore,
        chainStore,
        accountStore,
        currencyStore,
      ),
    [accountStore, authStore, chainStore, currencyStore],
  )

  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useOrderbookPegInStore = Store.useStore.bind(null)
