import { action } from "mobx"
import { FC, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Modal } from "../../../components/Modal"
import { usePrevious } from "../../../utils/reactHelpers/usePrevious"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { AccountSettingModalContent } from "../components/AccountSettingModalContent"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredAccountSettingDialog: FC = () => {
  const store = useOrderbookStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get("verifyToken")
  const lastToken = usePrevious(token)
  useEffect(() => {
    if (token != null && token !== lastToken) {
      store.accountSetting
        .verifyEmail(token)
        .then(() => {
          searchParams.delete("verifyToken")
          setSearchParams(
            { ...Object.fromEntries(searchParams.entries()) },
            { replace: true },
          )
        })
        .catch(err => console.error("verify email failed", err))
    }
  }, [lastToken, searchParams, setSearchParams, store.accountSetting, token])
  return (
    <Modal
      visible={store.accountSetting.showSettingModal}
      onClose={action(() => (store.accountSetting.showSettingModal = false))}
    >
      <AccountSettingModalContent
        onClose={action(() => (store.accountSetting.showSettingModal = false))}
        onSave={() => store.accountSetting.save(store.accountSetting.formData$)}
        onSendEmail={() => store.accountSetting.sendVerificationEmail()}
        emailVerified={suspenseResource(
          () => store.accountSetting.savedSetting$.emailVerified,
        )}
        username={suspenseResource(() => store.accountSetting.username)}
        onUsernameChange={action(
          (value: string) => (store.accountSetting.username = value),
        )}
        savedUsername={suspenseResource(
          () => store.accountSetting.savedSetting$.username,
        )}
        email={suspenseResource(() => store.accountSetting.email)}
        onEmailChange={action(
          (value: string) => (store.accountSetting.email = value),
        )}
        savedEmail={suspenseResource(
          () => store.accountSetting.savedSetting$.email,
        )}
        enableEmailSubscription={suspenseResource(
          () => store.accountSetting.enableEmailSubscription,
        )}
        onEmailSubscriptionChange={action(
          (value: boolean) =>
            (store.accountSetting.enableEmailSubscription = value),
        )}
      />
    </Modal>
  )
}
