import clsx from "clsx"
import { FC, ReactNode } from "react"

export const SelectedListTitle: FC<{
  children: ReactNode
  className?: string
}> = props => {
  return (
    <p className={clsx("text-white font-semibold text-sm", props.className)}>
      {props.children}
    </p>
  )
}
