import { FC } from "react"
import { safelyGet } from "../../../../../../utils/waitFor"
import {
  useLaunchPadContentfulStore,
  useLaunchPadContractStore,
} from "../../../../store/useLaunchPadStores"
import { ActionSectionContent$ClaimForRegistered } from "../../_/ActionSectionContent$ClaimForRegistered"

export const WiredActionSectionContent$ClaimForRegistered: FC = () => {
  const store = useLaunchPadContractStore()
  const contentfulStore = useLaunchPadContentfulStore()

  return (
    <ActionSectionContent$ClaimForRegistered
      idoToken={store.tokenInfo$}
      priceToken={store.priceTokenInfo$}
      lotteryTickets={store.claimViewModule.tickets}
      distributedAt={
        contentfulStore.contentfulDetail$.distributionDate ?? undefined
      }
      onRedeemOrdinals={
        safelyGet(() => store.redeem.eligibleForRedeem$)
          ? () => store.redeem.showRedeemModal()
          : undefined
      }
    />
  )
}
