import clsx from "clsx"
import { FC, ReactNode } from "react"
import { NavLink } from "../../../../../components/NavLink"
import {
  CardDivider,
  CardHeroText,
  CardStrongText,
  CardText,
  RenderBRC20CardFn,
  defaultRenderBRC20Card,
} from "./BRC20Card"

export interface OrdinalsInscriptionCardProps {
  className?: string
  inscriptionNumber?: number
  mimeType: string
  previewUrl: string
  renderBRC20Card?: RenderBRC20CardFn
  topArea?: ReactNode
  bottomArea?: ReactNode
}

export const OrdinalsInscriptionCard: FC<
  OrdinalsInscriptionCardProps
> = props => {
  const renderBRC20Card = props.renderBRC20Card ?? defaultRenderBRC20Card

  return renderBRC20Card({
    className: clsx("text-center items-center justify-center", props.className),
    children: (
      <>
        {props.topArea != null && (
          <>
            {props.topArea}
            <CardDivider />
          </>
        )}

        <CardHeroText>{props.mimeType}</CardHeroText>

        <NavLink to={props.previewUrl} className="text-blue-500">
          <CardStrongText>Preview</CardStrongText>
        </NavLink>

        {props.inscriptionNumber != null && (
          <CardText>#{props.inscriptionNumber}</CardText>
        )}

        {props.bottomArea != null && (
          <div className={"mt-auto"}>{props.bottomArea}</div>
        )}
      </>
    ),
  })
}
