import clsx from "clsx"
import { format } from "date-fns"
import { FC, ReactNode, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import {
  Card,
  CardDivider,
  CardInset,
  CardInsetDivider,
} from "../../../components/Card"
import { CountNumber } from "../../../components/CountNumber"
import { Dropdown } from "../../../components/Dropdown"
import { FiatCount } from "../../../components/FiatCount"
import {
  InfoListItemDetail as InfoListItemDetailLegacy,
  InfoListItem as InfoListItemLegacy,
  InfoListItemTitle as InfoListItemTitleLegacy,
  InfoList as InfoListLegacy,
} from "../../../components/InfoList"
import { InfoList } from "../../../components/InfoList/InfoList"
import { InfoListItem } from "../../../components/InfoList/InfoListItem"
import { InfoListItemDetail } from "../../../components/InfoList/InfoListItemDetail"
import { InfoListItemTitle } from "../../../components/InfoList/InfoListItemTitle"
import { NavLink } from "../../../components/NavLink"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { useSpacing } from "../../../components/Themed/spacing"
import { ExclamationOutlinedIcon } from "../../../components/TipIcon/ExclamationOutlinedIcon"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName, TokenNameBRC20Badge } from "../../../components/TokenName"
import { TokenNameStack } from "../../../components/TokenNameStack"
import { Button } from "../../../components/button/Button"
import { SuspenseResource, readResource } from "../../../utils/SuspenseResource"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { useEffectWithRef } from "../../../utils/reactHelpers/useEffectWithRef"
import { simplifyIdentifier } from "../../../utils/stringHelpers"
import { BlueOrangeGradientFilledButton } from "./_/BlueOrangeGradientFilledButton/BlueOrangeGradientFilledButton"
import { Colors } from "./designTokens"

export interface Brc20MarketInfo {
  baseTokenSymbol: string
  inscriptionAddr: string
  inscriptionAddrUrl: string
  totalSupply: number
  minted: number
  mintedPercentage: number
  limitPerMint: number
  decimals: number
  deployerAddr: string
  deployerAddrUrl: string
  deployedAt: Date
  completedAt: Date
  inscriptionNumber: [from: number, to: number]
}

export const SummaryBarForBrc20: FC<{
  className?: string
  tradeToken: TokenInfo
  priceToken: TokenInfo
  currentPrice: SuspenseResource<number>
  currentPriceInUSD: SuspenseResource<number>
  currentPriceDeltaPercentage: SuspenseResource<number>
  priceChangeIn24Hours?: SuspenseResource<{
    priceBefore24Hours: number
    priceDeltaPercentage: number
  }>
  priceLowestIn24Hours?: SuspenseResource<number>
  priceHighestIn24Hours?: SuspenseResource<number>
  tradeTokenVolumeIn24Hours?: SuspenseResource<number>
  priceTokenVolumeIn24Hours?: SuspenseResource<number>
  totalTradeVolume: SuspenseResource<number>
  tokenPairSelector: ReactNode
  marketInfo: SuspenseResource<Brc20MarketInfo>
}> = props => {
  const { $t } = useIntl()
  const spacing = useSpacing()

  const horizontalPadding = spacing(4)

  const [selectorVisible, setSelectorVisible] = useState(false)
  const [marketInfoVisible, setMarketInfoVisible] = useState(false)

  useEffectWithRef<[TokenInfo, TokenInfo]>(
    memoRef => {
      if (memoRef.current == null) {
        memoRef.current = [props.tradeToken, props.priceToken]
        return
      }

      if (
        !TokenInfo.isIdentical(memoRef.current[0], props.tradeToken) ||
        !TokenInfo.isIdentical(memoRef.current[1], props.priceToken)
      ) {
        memoRef.current = [props.tradeToken, props.priceToken]
        setSelectorVisible(false)
      }
    },
    [props.priceToken, props.tradeToken],
  )

  return (
    <Card
      className={clsx("overflow-y-auto", props.className)}
      boxClassName={""}
    >
      <div
        className={"flex flex-col gap-y-2.5 min-w-[fit-content] w-full"}
        style={{
          paddingBlock: spacing(2.5),
          paddingInline: horizontalPadding,
        }}
      >
        <div
          className={
            "flex flex-row items-center min-w-[fit-content] w-full gap-x-[24px]"
          }
        >
          <Dropdown
            visible={selectorVisible}
            onVisibleChange={setSelectorVisible}
            triggerMethod={"click"}
            triggerContainerClassName={"flex items-center"}
            trigger={
              <Button
                className={"flex items-center gap-x-2"}
                renderVariant={p => (
                  <BlueOrangeGradientFilledButton
                    {...p}
                    boxClassName={"px-4 py-0.5"}
                  />
                )}
              >
                <span>
                  <TokenNameStack
                    tradeToken={props.tradeToken}
                    priceToken={props.priceToken}
                  />
                </span>
                {props.tradeToken.propertyTags.includes("brc-20") && (
                  <TokenNameBRC20Badge />
                )}
              </Button>
            }
            withoutInnerContainer={true}
            placement={"start"}
          >
            <div style={{ marginLeft: -horizontalPadding }}>
              {props.tokenPairSelector}
            </div>
          </Dropdown>

          <Spensor>
            {() =>
              readResource(props.marketInfo) && (
                <button
                  onClick={() => setMarketInfoVisible(p => !p)}
                  className="-ml-3 -mr-3"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="white"
                      fillOpacity="0.05"
                    />
                    <path
                      d="M9.03116 4.73623C9.03593 4.36453 8.84035 4.01902 8.51922 3.8318C8.19808 3.64458 7.80105 3.64461 7.47995 3.83188C7.15884 4.01916 6.96332 4.36471 6.96815 4.7364C6.97548 5.30089 7.4352 5.75464 7.99974 5.75459C8.56428 5.75455 9.02393 5.30072 9.03116 4.73623ZM9.03116 11.2675C9.02388 10.703 8.5642 10.2492 7.99966 10.2492C7.43512 10.2492 6.97544 10.703 6.96815 11.2675C6.97544 11.832 7.43512 12.2858 7.99966 12.2858C8.5642 12.2858 9.02388 11.832 9.03116 11.2675ZM9.03116 8.00185C9.03593 7.63016 8.84035 7.28464 8.51922 7.09742C8.19808 6.9102 7.80105 6.91024 7.47995 7.09751C7.15884 7.28478 6.96332 7.63033 6.96815 8.00203C6.97548 8.56652 7.4352 9.02027 7.99974 9.02022C8.56428 9.02017 9.02392 8.56635 9.03116 8.00185Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </button>
              )
            }
          </Spensor>

          <CardDivider
            borderClassName={"border-white/5"}
            direction={"vertical"}
          />

          <InfoListLegacy
            gapClassName={"gap-x-8"}
            listItemClassName={"justify-center"}
            direction={"row"}
            listItemDirection={"column"}
          >
            <InfoListItemLegacy>
              <InfoListItemTitleLegacy className={"sr-only"}>
                {$t(i18n.currentPrice)}
              </InfoListItemTitleLegacy>
              <InfoListItemDetailLegacy
                className={"flex flex-row items-center"}
              >
                <Spensor>
                  {() => (
                    <>
                      <div>
                        <div
                          className={clsx(
                            "text-base font-semibold",
                            readResource(props.currentPriceDeltaPercentage) < 0
                              ? Colors.decreaseTextClassName
                              : readResource(
                                  props.currentPriceDeltaPercentage,
                                ) > 0
                              ? Colors.increaseTextClassName
                              : "",
                          )}
                        >
                          <TokenCount
                            token={props.priceToken}
                            count={readResource(props.currentPrice)}
                          />
                        </div>
                        <div className={"text-xs text-gray-500"}>
                          <FiatCount
                            count={readResource(props.currentPriceInUSD)}
                          />
                        </div>
                      </div>
                      &nbsp;
                      <TipIcon
                        Icon={ExclamationOutlinedIcon}
                        inline
                        tip={$t(i18n.currentPriceDisclaimer)}
                      />
                    </>
                  )}
                </Spensor>
              </InfoListItemDetailLegacy>
            </InfoListItemLegacy>

            {props.priceChangeIn24Hours && (
              <InfoListItemLegacy>
                <InfoListItemTitleLegacy>
                  {$t(i18n["24hChange"])}
                </InfoListItemTitleLegacy>
                <Spensor>
                  {() => {
                    const priceChange = readResource(
                      props.priceChangeIn24Hours!,
                    )
                    return (
                      <InfoListItemDetailLegacy
                        className={
                          priceChange.priceDeltaPercentage < 0
                            ? Colors.decreaseTextClassName
                            : Colors.increaseTextClassName
                        }
                      >
                        <TokenCount
                          token={props.priceToken}
                          count={Math.abs(priceChange.priceBefore24Hours)}
                        />{" "}
                        {priceChange.priceDeltaPercentage !== 0 && (
                          <>
                            {priceChange.priceDeltaPercentage < 0 ? "-" : "+"}
                            <PercentNumber
                              number={Math.abs(
                                priceChange.priceDeltaPercentage,
                              )}
                            />
                          </>
                        )}
                      </InfoListItemDetailLegacy>
                    )
                  }}
                </Spensor>
              </InfoListItemLegacy>
            )}

            {props.priceHighestIn24Hours && (
              <InfoListItemLegacy>
                <InfoListItemTitleLegacy>
                  {$t(i18n["24hHigh"])}
                </InfoListItemTitleLegacy>
                <InfoListItemDetailLegacy>
                  <Spensor fallback="--">
                    {() => (
                      <TokenCount
                        token={props.priceToken}
                        count={readResource(props.priceHighestIn24Hours)!}
                      />
                    )}
                  </Spensor>
                </InfoListItemDetailLegacy>
              </InfoListItemLegacy>
            )}

            {props.priceLowestIn24Hours && (
              <InfoListItemLegacy>
                <InfoListItemTitleLegacy>
                  {$t(i18n["24hLow"])}
                </InfoListItemTitleLegacy>
                <InfoListItemDetailLegacy>
                  <Spensor fallback="--">
                    {() => (
                      <TokenCount
                        token={props.priceToken}
                        count={readResource(props.priceLowestIn24Hours)!}
                      />
                    )}
                  </Spensor>
                </InfoListItemDetailLegacy>
              </InfoListItemLegacy>
            )}

            {props.tradeTokenVolumeIn24Hours && (
              <InfoListItemLegacy>
                <InfoListItemTitleLegacy>
                  {$t(i18n["24hVolume"], {
                    token: <TokenName token={props.tradeToken} />,
                  })}
                </InfoListItemTitleLegacy>
                <InfoListItemDetailLegacy>
                  <Spensor fallback="--">
                    {() => (
                      <TokenCount
                        token={props.tradeToken}
                        count={readResource(props.tradeTokenVolumeIn24Hours)!}
                      />
                    )}
                  </Spensor>
                </InfoListItemDetailLegacy>
              </InfoListItemLegacy>
            )}

            {props.priceTokenVolumeIn24Hours && (
              <InfoListItemLegacy>
                <InfoListItemTitleLegacy>
                  {$t(i18n["24hVolume"], {
                    token: <TokenName token={props.priceToken} />,
                  })}
                </InfoListItemTitleLegacy>
                <InfoListItemDetailLegacy>
                  <Spensor fallback="--">
                    {() => (
                      <TokenCount
                        token={props.priceToken}
                        count={readResource(props.priceTokenVolumeIn24Hours!)}
                      />
                    )}
                  </Spensor>
                </InfoListItemDetailLegacy>
              </InfoListItemLegacy>
            )}
            {props.totalTradeVolume && (
              <InfoListItemLegacy>
                <InfoListItemTitleLegacy>
                  {$t(i18n["totalTradeVolume"], {
                    token: <TokenName token={props.priceToken} />,
                  })}
                </InfoListItemTitleLegacy>
                <InfoListItemDetailLegacy>
                  <Spensor fallback="--">
                    {() => (
                      <TokenCount
                        token={props.priceToken}
                        count={readResource(props.totalTradeVolume)}
                      />
                    )}
                  </Spensor>
                </InfoListItemDetailLegacy>
              </InfoListItemLegacy>
            )}
          </InfoListLegacy>
        </div>

        {marketInfoVisible && (
          <Spensor>
            {() => (
              <CardInset
                className={"flex flex-row items-center"}
                boxClassName={
                  "px-4 py-1 max-w-none min-h-[52px] min-w-[fit-content] w-full"
                }
              >
                <InfoList
                  className={"gap-x-[24px]"}
                  direction={"row"}
                  listItemDirection={"column"}
                >
                  <InfoListItem>
                    <InfoListItemTitle>BRC-20</InfoListItemTitle>
                    <InfoListItemDetail>
                      {readResource(props.marketInfo).baseTokenSymbol}
                    </InfoListItemDetail>
                  </InfoListItem>

                  <CardInsetDivider
                    borderClassName={"border-white/5"}
                    direction={"vertical"}
                  />

                  <InfoListItem>
                    <InfoListItemTitle>
                      {$t(i18n.inscription)}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      <NavLink
                        className={"text-blue-600"}
                        to={readResource(props.marketInfo).inscriptionAddrUrl}
                      >
                        {simplifyIdentifier(
                          readResource(props.marketInfo).inscriptionAddr,
                        )}
                      </NavLink>
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>
                      {$t(i18n.totalSupply)}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      <span className={"text-green-600"}>
                        <CountNumber
                          number={readResource(props.marketInfo).totalSupply}
                        />
                      </span>
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>{$t(i18n.minted)}</InfoListItemTitle>
                    <InfoListItemDetail>
                      <CountNumber
                        number={readResource(props.marketInfo).minted}
                      />
                      &nbsp; (
                      <PercentNumber
                        number={readResource(props.marketInfo).mintedPercentage}
                      />
                      )
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>
                      {$t(i18n.limitPerMint)}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      <CountNumber
                        number={readResource(props.marketInfo).limitPerMint}
                      />
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>{$t(i18n.decimal)}</InfoListItemTitle>
                    <InfoListItemDetail>
                      <CountNumber
                        number={readResource(props.marketInfo).decimals}
                      />
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>{$t(i18n.deployBy)}</InfoListItemTitle>
                    <InfoListItemDetail>
                      <NavLink
                        className={"text-blue-600"}
                        to={readResource(props.marketInfo).deployerAddrUrl}
                      >
                        {simplifyIdentifier(
                          readResource(props.marketInfo).deployerAddr,
                        )}
                      </NavLink>
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>{$t(i18n.deployTime)}</InfoListItemTitle>
                    <InfoListItemDetail>
                      {format(
                        readResource(props.marketInfo).deployedAt,
                        "yyyy-MM-dd HH:mm:ss",
                      )}
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>
                      {$t(i18n.completedTime)}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      {format(
                        readResource(props.marketInfo).completedAt,
                        "yyyy-MM-dd HH:mm:ss",
                      )}
                    </InfoListItemDetail>
                  </InfoListItem>

                  <InfoListItem>
                    <InfoListItemTitle>
                      {$t(i18n.inscriptionNumber)}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      #{readResource(props.marketInfo).inscriptionNumber[0]}- #
                      {readResource(props.marketInfo).inscriptionNumber[1]}
                    </InfoListItemDetail>
                  </InfoListItem>
                </InfoList>
              </CardInset>
            )}
          </Spensor>
        )}
      </div>
    </Card>
  )
}

const i18n = defineMessages({
  currentPrice: {
    defaultMessage: "Current Price",
    description: "Orderbook/brc20/top summary bar",
  },
  currentPriceDisclaimer: {
    defaultMessage:
      "Disclaimer: the prices reflect the latest traded on the orderbook, which may be away from those on other exchanges/AMMs",
    description: "Orderbook/brc20/top summary bar",
  },
  "24hChange": {
    defaultMessage: "24h Change",
    description: "Orderbook/brc20/top summary bar",
  },
  "24hHigh": {
    defaultMessage: "24h High",
    description: "Orderbook/brc20/top summary bar",
  },
  "24hLow": {
    defaultMessage: "24h Low",
    description: "Orderbook/brc20/top summary bar",
  },
  "24hVolume": {
    defaultMessage: "24h Volume ({token})",
    description: "Orderbook/brc20/top summary bar",
  },
  totalTradeVolume: {
    defaultMessage: "Total trade volume ({token})",
    description: "Orderbook/top summary bar",
  },
  inscription: {
    defaultMessage: "Inscription",
    description: "Orderbook/BRC20/top summary bar",
  },
  totalSupply: {
    defaultMessage: "Total Supply",
    description: "Orderbook/BRC20/top summary bar",
  },
  minted: {
    defaultMessage: "Minted",
    description: "Orderbook/BRC20/top summary bar",
  },
  limitPerMint: {
    defaultMessage: "Limit Per Mint",
    description: "Orderbook/BRC20/top summary bar",
  },
  decimal: {
    defaultMessage: "Decimal",
    description: "Orderbook/BRC20/top summary bar",
  },
  deployBy: {
    defaultMessage: "Deployed By",
    description: "Orderbook/BRC20/top summary bar",
  },
  deployTime: {
    defaultMessage: "Deploy Time",
    description: "Orderbook/BRC20/top summary bar",
  },
  completedTime: {
    defaultMessage: "Completed Time",
    description: "Orderbook/BRC20/top summary bar",
  },
  inscriptionNumber: {
    defaultMessage: "Inscription Number",
    description: "Orderbook/BRC20/top summary bar",
  },
})
