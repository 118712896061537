import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { TokenCount } from "../../../../components/TokenCount"
import { BlockTopTitleLine } from "../../../../components/TokenInput/Block"
import { BlockGroup } from "../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../components/TokenName"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { NotEmptyArray } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../../components/AddStakeSectionInfoList"
import { ClaimTokenInfo } from "../../types"

export interface ConfirmHarvestAndMintAtAlexModalContentProps {
  harvestTokens: ClaimTokenInfo[]
  mintedTokens: NotEmptyArray<ClaimTokenInfo>
  alexToken: TokenInfo
  atAlexToken: TokenInfo
  intrinsicAlexValue: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmHarvestAndMintAtAlexModalContent: FC<
  ConfirmHarvestAndMintAtAlexModalContentProps
> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Harvest",
          description:
            "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Card modal content title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            topArea={
              <BlockTopTitleLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Harvest",
                    description:
                      "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Block title",
                  }),
                )}
              </BlockTopTitleLine>
            }
            tokens={props.harvestTokens}
          />
        }
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopTitleLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Auto Stake (receive)",
                    description:
                      "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Block title",
                  }),
                )}
              </BlockTopTitleLine>
            }
            tokens={props.mintedTokens}
          />
        }
      />

      <AddStakeSectionInfoList>
        <AddStakeSectionInfoListItem
          title={$t(
            defineMessage({
              defaultMessage: "Stake Price",
              description:
                "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Info list item title",
            }),
          )}
          detail={
            <span>
              {$t(
                defineMessage({
                  defaultMessage: "{oneAtAlexToken} = {intrinsicAlexValue}",
                  description:
                    "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Info list item detail",
                }),
                {
                  oneAtAlexToken: (
                    <TextTokenCount token={props.atAlexToken} count={1} />
                  ),
                  intrinsicAlexValue: (
                    <TextTokenCount
                      token={props.alexToken}
                      count={props.intrinsicAlexValue}
                    />
                  ),
                },
              )}
            </span>
          }
        />
        <AddStakeSectionInfoListItem
          title={$t(
            defineMessage({
              defaultMessage: "Total Claim",
              description:
                "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Info list item",
            }),
          )}
          detail={
            <ul className={"text-right"}>
              {props.mintedTokens.map((group, idx) => (
                <li key={idx}>
                  <ClaimCountInfo token={group.token} count={group.count} />
                </li>
              ))}
            </ul>
          }
        />
      </AddStakeSectionInfoList>
      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description:
                "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description:
                "/Stake/MixedStake/ConfirmHarvestAndMintAtAlexModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}

const ClaimCountInfo: FC<{
  token: TokenInfo
  count: number
  countToUSD?: number
}> = props => (
  <>
    <TokenCount token={props.token} count={props.count} />
    &nbsp;
    <TokenName token={props.token} />
    {props.countToUSD != null && !Number.isNaN(props.countToUSD) && (
      <span className={"text-gray-500 ml-[5px]"}>
        ($&nbsp;
        <TokenCount token={TokenInfoPresets.USD} count={props.countToUSD} />)
      </span>
    )}
  </>
)
