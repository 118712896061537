import { FC } from "react"
import { BigNumber } from "../utils/BigNumber"
import { roundNumber } from "../utils/numberHelpers"
import { readResource, SuspenseResource } from "../utils/SuspenseResource"
import { formatNumberToParts, NumberFormatOptions } from "./CountNumber"
import { Spensor } from "./Spensor"

export interface FiatCountProps {
  count: SuspenseResource<number | BigNumber>
  precision?: number
  padDecimals?: boolean
}

export const FiatCount: FC<FiatCountProps> = props => {
  return (
    <Spensor>
      {() => {
        const s = formatFiatCount(readResource(props.count), {
          precision: props.precision,
          padDecimals: props.padDecimals,
        })
        return <span title={String(readResource(props.count))}>{s}</span>
      }}
    </Spensor>
  )
}

export function formatFiatCount(
  count: number | BigNumber,
  options: NumberFormatOptions = {},
): string {
  const precision = options.precision ?? 2

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 20,
    minimumFractionDigits: 20,
  })

  return formatNumberToParts(formatter, BigNumber.toNumber(count), {
    ...options,
    precision,
  })
    .map(p => p.value)
    .join("")
}

export const roundDownFiatCount = (
  tokenCount: number,
  precision: number,
): number => {
  return roundNumber(tokenCount, {
    precision,
    rounder: Math.floor,
  })
}
