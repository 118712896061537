import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { FarmRowData } from "../../../../store/transformerGroups/farm/farm"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const FarmRow: FC<{
  rowData: FarmRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => {
  const { $t } = useIntl()
  return (
    <BaseRow
      rowData={rowData}
      currencyCell={
        <OneLineCurrencyCell
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={[rowData.principalCurrencyAmount]}
        />
      }
      amountCell={
        <OneLineAmountCell
          allTokenInfos={allTokenInfos}
          fromCurrencyAmounts={[rowData.principalCurrencyAmount]}
        />
      }
      tradeInfoCell={
        <BaseCellContainer>
          {$t(
            defineMessage({
              defaultMessage: `Staking {period} {period, plural, 
                one {Cycle} 
                other {Cycles}
              }`,
              description: "/Notify/Transaction/Table/Farm row",
            }),
            {
              period: rowData.period,
            },
          )}
        </BaseCellContainer>
      }
    />
  )
}
