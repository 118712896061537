import {
  AddressTransactionWithTransfers,
  Transaction,
} from "@stacks/stacks-blockchain-api-types/generated"
import Dexie from "dexie"
import { PERSIST_KEY_PREFIX } from "../../config"
import { CONTRACT_CALL_INDEX } from "./constants"

/**
 * v0.0.2: Table<Transactions[], string>
 * v0.0.3: Table<AddressTransactionWithTransfers[], string>
 */
export class TransactionDatabase extends Dexie {
  transactions!: Dexie.Table<AddressTransactionWithTransfers, string>

  constructor(stxAddress: string) {
    super(`${PERSIST_KEY_PREFIX}_account_transactions_${stxAddress}_v0.0.3`)

    this.version(1).stores({
      transactions: `&tx.tx_id, ${CONTRACT_CALL_INDEX}, tx.nonce, tx.burn_block_time`,
    })
  }
}

export class TransactionClass implements AddressTransactionWithTransfers {
  tx!: Transaction
  /**
   * Total sent from the given address, including the tx fee, in micro-STX as an integer string.
   */
  stx_sent!: string
  /**
   * Total received by the given address in micro-STX as an integer string.
   */
  stx_received!: string

  stx_transfers!: {
    /**
     * Amount transferred in micro-STX as an integer string.
     */
    amount: string
    /**
     * Principal that sent STX. This is unspecified if the STX were minted.
     */
    sender?: string
    /**
     * Principal that received STX. This is unspecified if the STX were burned.
     */
    recipient?: string
  }[]

  ft_transfers?: {
    /**
     * Fungible Token asset identifier.
     */
    asset_identifier: string
    /**
     * Amount transferred as an integer string. This balance does not factor in possible SIP-010 decimals.
     */
    amount: string
    /**
     * Principal that sent the asset.
     */
    sender?: string
    /**
     * Principal that received the asset.
     */
    recipient?: string
  }[]
}
