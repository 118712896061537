import clsx from "clsx"
import { CSSProperties } from "react"
import { FCC } from "../../utils/reactHelpers/types"

interface TableCommonProps {
  style?: CSSProperties | undefined
  className?: string | undefined
  role?: string | undefined
  onClick?: () => void
}

export const Table: FCC<TableCommonProps> = props => {
  return (
    <table
      style={props.style}
      role={props.role}
      className={clsx(props.className, "w-full")}
    >
      {props.children}
    </table>
  )
}

export const Thead: FCC<TableCommonProps> = props => {
  return (
    <thead style={props.style} role={props.role} className={props.className}>
      {props.children}
    </thead>
  )
}

export const Tbody: FCC<TableCommonProps> = props => {
  return (
    <tbody
      style={props.style}
      role={props.role}
      className={clsx(props.className)}
    >
      {props.children}
    </tbody>
  )
}

export const Tr: FCC<TableCommonProps> = props => {
  return (
    <tr
      style={props.style}
      role={props.role}
      className={clsx(
        props.className,
        "border-b border-gray-500 border-opacity-30",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </tr>
  )
}

export const TableHeadRow: FCC<TableCommonProps> = props => {
  return (
    <Tr
      {...props}
      className={clsx(
        props.className,
        "text-sm leading-5 font-normal text-gray-500 text-left",
      )}
    >
      {props.children}
    </Tr>
  )
}

export const TableBodyRow: FCC<TableCommonProps> = props => {
  return (
    <Tr
      {...props}
      className={clsx(
        props.className,
        "text-base leading-6 font-normal text-gray-200 text-left",
      )}
    >
      {props.children}
    </Tr>
  )
}

export interface ThProps extends TableCommonProps {
  colSpan?: number | undefined
  rowSpan?: number | undefined
}
export const Th: FCC<ThProps> = props => {
  return (
    <th
      style={props.style}
      role={props.role}
      className={clsx(props.className, "px-2 py-4")}
      colSpan={props.colSpan}
      rowSpan={props.rowSpan}
    >
      {props.children}
    </th>
  )
}

export const Td: FCC<TableCommonProps> = props => {
  return (
    <td
      style={props.style}
      role={props.role}
      className={clsx(props.className, "px-2 py-4")}
    >
      {props.children}
    </td>
  )
}
