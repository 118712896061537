import clsx from "clsx"
import { EmptyState } from "../../../../../components/EmptyState/EmptyState"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../../components/Spensor"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { Order } from "../types"

export interface OrderListContainerProps<T extends Order> {
  className?: string
  orders: SuspenseResource<T[]>
  children: (orders: T[]) => JSX.Element | JSX.Element[]
}

export function OrderListContainer<T extends Order>(
  props: OrderListContainerProps<T>,
): JSX.Element {
  const clsName = clsx(props.className, "flex-1")

  return (
    <Spensor
      fallback={
        <div className={clsx("flex", clsName)}>
          <LoadingIndicator className={"m-auto"} />
        </div>
      }
    >
      {() => {
        const orders = readResource(props.orders)

        if (orders.length <= 0) {
          return (
            <div className={clsx("flex", clsName)}>
              <EmptyState className={"m-auto"} />
            </div>
          )
        }

        return <div className={clsName}>{props.children(orders)}</div>
      }}
    </Spensor>
  )
}
