import { formatTokenCount } from "../../../components/TokenCount"
import { Currency } from "../../../utils/alexjs/Currency"
import { TO_PREFIX } from "../constants"
import { AllTokenInfos, CurrencyAmount } from "../types"

export const parseAmount = ({
  fromCurrencyAmounts = [],
  toCurrencyAmounts = [],
  allTokenInfos,
}: {
  fromCurrencyAmounts?: (CurrencyAmount.Confirmed | CurrencyAmount.Unknown)[]
  toCurrencyAmounts?: (CurrencyAmount.Confirmed | CurrencyAmount.Unknown)[]
  allTokenInfos: AllTokenInfos
}): string => {
  return [
    ...fromCurrencyAmounts.map(currencyAmount =>
      parseCurrencyAmount(currencyAmount, allTokenInfos),
    ),
    ...toCurrencyAmounts.map(currencyAmount =>
      parseCurrencyAmount(currencyAmount, allTokenInfos, TO_PREFIX),
    ),
  ].join("\n")
}

export const parseExchangeRatio = ({
  fromCurrencyAmount,
  toCurrencyAmount,
  allTokenInfos,
}: {
  fromCurrencyAmount: CurrencyAmount.Confirmed
  toCurrencyAmount: CurrencyAmount.Confirmed
  allTokenInfos: AllTokenInfos
}): string => {
  const ratio = toCurrencyAmount.amount / fromCurrencyAmount.amount
  return `${parseTokenCount({
    currency: toCurrencyAmount.currency,
    count: ratio,
    allTokenInfos,
  })} ${parseTokenName(
    toCurrencyAmount.currency,
    allTokenInfos,
  )} per ${parseTokenName(fromCurrencyAmount.currency, allTokenInfos)}`
}

export function parseCurrencyAmount(
  currencyAmount: CurrencyAmount.Confirmed | CurrencyAmount.Unknown,
  allTokenInfos: AllTokenInfos,
  prefix?: string,
): string {
  const tokenAmount = parseTokenCount({
    currency: currencyAmount.currency,
    count: (currencyAmount as CurrencyAmount.Confirmed).amount,
    allTokenInfos,
  })
  return (
    (prefix ? prefix + " " : "") +
    (tokenAmount ? tokenAmount + " " : "") +
    parseTokenName(currencyAmount.currency, allTokenInfos)
  )
}

function parseTokenCount(args: {
  currency: Currency
  count: number
  allTokenInfos: AllTokenInfos
}): string
function parseTokenCount(args: {
  currency: Currency
  allTokenInfos: AllTokenInfos
}): undefined
function parseTokenCount({
  currency,
  count,
  allTokenInfos,
}: {
  currency: Currency
  count?: number
  allTokenInfos: AllTokenInfos
}): string | undefined {
  if (count == null) {
    return
  }
  if (!allTokenInfos[currency]) {
    throw new Error("No token info for currency: " + currency)
  }
  return formatTokenCount(allTokenInfos[currency]!, count)
}

function parseTokenName(
  currency: Currency,
  allTokenInfos: AllTokenInfos,
): string {
  const tokenInfo = allTokenInfos[currency]
  if (!tokenInfo) {
    throw new Error("No token info for currency: " + currency)
  }
  return tokenInfo.displayName
}
