export async function utilWindowFocus(): Promise<void> {
  return new Promise(resolve => {
    if (document.hasFocus()) {
      resolve()
    } else {
      const onFocus = (): void => {
        resolve()
        window.removeEventListener("focus", onFocus)
      }
      window.addEventListener("focus", onFocus)
    }
  })
}
