import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "../../../../components/button/variants/BaseRoundedFilledButton"

export const PinkFilledButton: FC<RoundedButtonVariantProps> = props => (
  <BaseRoundedFilledButton
    {...props}
    className={clsx("text-gray-100", props.className)}
    bgClassName={
      "bg-pink-600 hover:bg-pink-700 active:bg-pink-800 disabled:bg-pink-800"
    }
  >
    {props.children}
  </BaseRoundedFilledButton>
)
