import { debounce, DebounceSettings } from "lodash"
import { DependencyList, useMemo } from "react"
import { AnyFunc } from "../types"

export function useDebounceFn<F extends AnyFunc>(
  callback: F,
  deps: DependencyList,
  wait?: number,
  debounceOptions?: DebounceSettings,
): (...args: Parameters<F>) => void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => debounce(callback, wait, debounceOptions), deps)
}
