import clsx from "clsx"
import { CSSProperties, FC, forwardRef, ReactNode } from "react"
import { FCC } from "../utils/reactHelpers/types"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { withProps } from "../utils/reactHelpers/withProps"
import {
  PaddingStyle,
  useNormalizePaddingStyle,
} from "../utils/styleHelpers/PaddingStyle"
import { Divider, DividerProps } from "./Divider"

export interface CardProps {
  className?: string
  boxClassName?: string
  padding?: number | PaddingStyle
  roundClassName?: string
  backgroundImage?: string
  style?: CSSProperties
  tabIndex?: number
  children?: ReactNode
}

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const paddingStyle = useNormalizePaddingStyle(props.padding)

  return (
    <div
      ref={ref}
      className={clsx(
        "drop-shadow backdrop-blur bg-none",
        props.className,
        props.padding == null && (props.boxClassName ?? "p-3 sm:p-6"),
        props.roundClassName ?? "rounded-2xl",
      )}
      style={{
        ["--tw-drop-shadow" as any]:
          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05))",

        ["--tw-backdrop-blur" as any]: "blur(40px)",

        backgroundImage:
          props.backgroundImage ??
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",

        ...paddingStyle,

        ...props.style,
      }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </div>
  )
})

export const CardTitle = withClassName(
  "text-base sm:text-xl leading-7 font-bold text-gray-200",
  "div",
)

export const CardSubTitle = withClassName(
  "text-lg leading-7 font-normal text-gray-200",
  "div",
)

export const CardDescription = withClassName(
  "text-sm leading-5 font-normal text-gray-400",
  "p",
)
export const CardNote = withClassName(
  "text-sm leading-5 font-normal text-gray-500",
  "p",
)

export const CardDivider: FC<
  DividerProps & { borderClassName?: string }
> = props => (
  <Divider
    {...props}
    className={clsx(
      props.className,
      props.borderClassName ?? "border-gray-500 opacity-30",
    )}
  />
)

export const cardInsetBgClassName = "bg-black bg-opacity-20"

export interface CardInsetProps {
  className?: string
  boxClassName?: string
  bgClassName?: string
  roundClassName?: string
  backgroundImageUrl?: string
  style?: CSSProperties
}
export const CardInset: FCC<CardInsetProps> = props => (
  <div
    className={clsx(
      props.className,
      props.roundClassName ?? `rounded-lg`,
      props.bgClassName ?? cardInsetBgClassName,
      props.boxClassName ?? "px-4 py-6",
    )}
    style={{
      ...props.style,
      backgroundImage:
        props.backgroundImageUrl != null
          ? `url(${props.backgroundImageUrl})`
          : undefined,
    }}
  >
    {props.children}
  </div>
)

export const CardInsetTitle = CardSubTitle

export const CardInsetSubTitle = withClassName(
  "text-sm leading-5 font-normal text-gray-400",
  "span",
)

export const CardInsetDescription = withClassName(
  "text-sm leading-5 font-normal text-gray-500",
  "span",
)

export const CardInsetDivider = CardDivider

export const CardNested: FCC<CardProps> = props => (
  <Card {...props} boxClassName={props.boxClassName ?? "p-4"} />
)

export interface CardPlateProps {
  className?: string
  boxClassName?: string
}
export const CardPlate: FCC<CardPlateProps> = props => (
  <div
    className={clsx(
      "bg-white bg-opacity-5 rounded-lg",
      props.className,
      props.boxClassName ?? "p-3 sm:p-4",
    )}
  >
    {props.children}
  </div>
)

export const BlueCard = withProps(Card, {
  backgroundImage:
    "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 7, 255, 0.2) 100%), linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
})
