import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetDescription,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { IDOSucceedNumbersList } from "../../IDOSucceedNumbersList"
import { IDOSuccessIcon } from "../../IDOSuccessIcon/IDOSuccessIcon"
import { RedeemOrdinalsNotice } from "../../RedeemOrdinalsNotice"
import { TokenIDOSuccessInfo } from "../../types"
import { LotteryTicketCard } from "./LotteryTicketCard/LotteryTicketCard"
import { LotteryTicket } from "./LotteryTicketCard/types"

export const ActionSectionContent$FinishedSucceed: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  lotteryTickets: LotteryTicket[]
  tokenIDOSuccessSummary: TokenIDOSuccessInfo
  onRedeemOrdinals?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      {props.onRedeemOrdinals != null && (
        <RedeemOrdinalsNotice onRedeem={props.onRedeemOrdinals} />
      )}

      <CardInset className={"flex flex-col items-center gap-6"}>
        <div className={"flex flex-col items-center gap-4"}>
          <IDOSuccessIcon />
          <CardInsetTitle>
            {$t(
              props.idoToken.propertyTags.includes("ordinals-voucher")
                ? defineMessage({
                    defaultMessage: "{idoToken} launch has been finalized",
                    description:
                      "/Launchpad/ActionSectionContent/Finished succeed title for nft/ordinals",
                  })
                : defineMessage({
                    defaultMessage:
                      "{idoToken} Token launch has been finalized",
                    description:
                      "/Launchpad/ActionSectionContent/Finished succeed title for normal token",
                  }),
              {
                idoToken: <TokenName token={props.idoToken} />,
              },
            )}
          </CardInsetTitle>
        </div>

        <IDOSucceedNumbersList
          className={"w-full px-4 md:px-24 py-6"}
          idoToken={props.idoToken}
          tokenIDOSuccessSummary={props.tokenIDOSuccessSummary}
        />

        {props.lotteryTickets.length > 0 && (
          <>
            <CardInsetDivider />
            <div className={"flex flex-col items-center gap-2.5"}>
              {$t<ReactNode>(
                defineMessage({
                  defaultMessage: `
                  <title>Your Lottery Ticket</title>
                  <content>
                    <line>Your winning Ordinals Exchangeables have been sent to your Stacks wallet.</line>
                    <line> Receive your Ordinals on your Bitcoin wallet by clicking "Redeem to Bitcoin Chain"</line>
                  </content>`,
                  description:
                    "/Launchpad/ActionSectionContent/Finished succeed",
                }),
                {
                  title: dontWrapObserver(children => (
                    <CardInsetTitle>{children}</CardInsetTitle>
                  )),
                  line: dontWrapObserver(children => <div>{children}</div>),
                  content: dontWrapObserver(children => (
                    <CardInsetDescription className={"text-center"}>
                      {children}
                    </CardInsetDescription>
                  )),
                },
              )}
            </div>
            <div className={"flex flex-col gap-4"}>
              <ul className={"flex flex-wrap justify-center gap-2.5"}>
                {props.lotteryTickets.map((t, idx) => (
                  <li key={idx}>
                    <LotteryTicketCard
                      idoToken={props.idoToken}
                      priceToken={props.priceToken}
                      lotteryTicket={t}
                    />
                  </li>
                ))}
              </ul>
              <NoteParagraph>
                {$t(
                  defineMessage({
                    defaultMessage:
                      "The {priceToken} submitting with your lottery ticket will be refunded if you draw a losing ticket, and  will be swapped for LaunchPad tokens if you win. The {aPower} submitted is burnt either win or lose.",
                    description:
                      "/Launchpad/ActionSectionContent/Finished succeed note",
                  }),
                  {
                    priceToken: <TokenName token={props.priceToken} />,
                    aPower: <TokenName token={TokenInfoPresets.APower} />,
                  },
                )}
              </NoteParagraph>
            </div>
          </>
        )}
      </CardInset>
    </>
  )
}
