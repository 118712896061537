import clsx from "clsx"
import { FC } from "react"
import styles from "./PegInStepsBar.module.scss"

const pastClass = `${styles.pastIcon} text-transparent`
const activeClass = "bg-[#2196F3] text-white"
const failedClass = `${styles.failedIcon} text-transparent`
const futureClass = "bg-[rgba(0,0,0,0.38)] text-white"

export const PegInStepsBarItem: FC<{
  index: number
  name: string
  currentStep: number
  failed?: boolean
}> = props => (
  <li className={clsx("flex flex-row gap-x-2 items-center justify-start")}>
    <div
      className={clsx(
        "text-sm font-semibold flex items-center justify-center rounded-full w-6 h-6 shrink-0",
        {
          [pastClass]: props.index < props.currentStep,
          [activeClass]: props.index === props.currentStep && !props.failed,
          [failedClass]: props.index === props.currentStep && props.failed,
          [futureClass]: props.index > props.currentStep,
        },
      )}
    >
      {props.index + 1}
    </div>
    <div className={clsx("text-xs sm:text-sm font-medium text-white")}>
      {props.name}
    </div>
  </li>
)
