import { keys, toPairs, values, zipObject } from "lodash"
import { isEnumValue } from "../enumHelpers"

export enum Currency {
  ALEX = "age000-governance-token",
  ATALEX = "auto-alex",
  ATALEXV2 = "auto-alex-v2",
  APOWER = "token-apower",
  sUSDT = "token-susdt",
  sLUNR = "token-slunr",

  // CRP tokens
  YIELD_ALEX = "yield-alex-v1",
  KEY_ALEX_AUTOALEX = "key-alex-autoalex-v1",

  // Fixed Weight liquidity Pool tokens
  FWP_STX_ALEX_50_50_V1_01 = "fwp-wstx-alex-50-50-v1-01",
  FWP_STX_ALEX_TRANCHED = "fwp-wstx-alex-tranched-120",
  FWP_STX_XBTC_50_50_V1_01 = "fwp-wstx-wbtc-50-50-v1-01",
  FWP_ALEX_BANANA = "fwp-alex-wban",
  FWP_ALEX_USDA = "fwp-alex-usda",
  FWP_ALEX_ATALEX = "fwp-alex-autoalex",
  FWP_ALEX_SLIME = "fwp-alex-wslm",
  FWP_STX_XUSD = "fwp-wstx-wxusd-50-50-v1-01",
  FWP_STX_MIA = "fwp-wstx-wmia-50-50-v1-01",
  FWP_STX_NYCC = "fwp-wstx-wnycc-50-50-v1-01",

  // Yield token pool
  YTP_ALEX = "ytp-alex-v1",
  AUTO_YTP_ALEX = "auto-ytp-alex",

  // Wrapped tokens, above tokens are native tokens
  W_USDA = "token-wusda",
  W_STX = "token-wstx",
  W_BANANA = "token-wban",
  W_XBTC = "token-wbtc",
  W_DIKO = "token-wdiko",
  W_SLIME = "token-wslm",
  W_XUSD = "token-wxusd",
  W_MIA = "token-wmia",
  W_NYCC = "token-wnycc",
  W_CORGI = "token-wcorgi",
  W_VIBES = "token-wvibes",

  // BRC-20 pegged tokens
  BRC20_DB20 = "brc20-db20",
  BRC20_ORMM = "brc20-ormm",

  ORDINALS_BLUEWHEEL = "ordinals-bluewheel",

  AMM_SWAP_POOL = "token-amm-swap-pool",
  AMM_SWAP_POOL_V1_1 = "token-amm-swap-pool-v1-1",

  // AMM_SWAP_POOL
  AMM_SWAP_POOL_WXUSD_WUSDA = "token-amm-swap-pool:token-wxusd,token-wusda,0.0001e8",
  AMM_SWAP_POOL_WXUSD_WUSDA_2 = "token-amm-swap-pool:token-wxusd,token-wusda,0.005e8",
  AMM_SWAP_POOL_WSTX_ALEX = "token-amm-swap-pool:token-wstx,age000-governance-token,1e8",
  AMM_SWAP_POOL_WSTX_XBTC = "token-amm-swap-pool:token-wstx,token-wbtc,1e8",
  AMM_SWAP_POOL_ALEX_WBAN = "token-amm-swap-pool:age000-governance-token,token-wban,1e8",
  AMM_SWAP_POOL_ALEX_WUSDA = "token-amm-swap-pool:age000-governance-token,token-wusda,1e8",
  AMM_SWAP_POOL_ALEX_WDIKO = "token-amm-swap-pool:age000-governance-token,token-wdiko,1e8",
  AMM_SWAP_POOL_WSTX_WCORGI = "token-amm-swap-pool:token-wstx,token-wcorgi,1e8",
  AMM_SWAP_POOL_WSTX_SUSDT = "token-amm-swap-pool:token-wstx,token-susdt,1e8",

  AMM_SWAP_POOL_V1_1_WSTX_XBTC = "token-amm-swap-pool-v1-1:token-wstx,token-wbtc,1e8",
  AMM_SWAP_POOL_V1_1_WSTX_SUSDT = "token-amm-swap-pool-v1-1:token-wstx,token-susdt,1e8",
  AMM_SWAP_POOL_V1_1_WSTX_ALEX = "token-amm-swap-pool-v1-1:token-wstx,age000-governance-token,1e8",
  AMM_SWAP_POOL_V1_1_ALEX_DIKO = "token-amm-swap-pool-v1-1:age000-governance-token,token-wdiko,1e8",
  AMM_SWAP_POOL_V1_1_ALEX_ATALEXV2 = "token-amm-swap-pool-v1-1:age000-governance-token,auto-alex-v2,1e8",
  AMM_SWAP_POOL_V1_1_WSTX_WVIBES = "token-amm-swap-pool-v1-1:token-wstx,token-wvibes,1e8",
  AMM_SWAP_POOL_V1_1_ALEX_BRC20DB20 = "token-amm-swap-pool-v1-1:age000-governance-token,brc20-db20,1e8",
  AMM_SWAP_POOL_V1_1_SUSDT_XUSD = "token-amm-swap-pool-v1-1:token-susdt,token-wxusd,0.05e8",
}

export type BRC20Currency = `brc20-${string}`
export type OrdinalsCurrency = `ordinals-${string}`

export type CurrencyAndBRC20s = Currency | BRC20Currency

export function isCurrencyOrB20(input: string): input is CurrencyAndBRC20s {
  return isCurrency(input) || isBRC20Token(input)
}

export function isBRC20Token(input: string): input is BRC20Currency {
  return input.startsWith("brc20-")
}

export function isOrdinalsToken(input: string): input is OrdinalsCurrency {
  return input.startsWith("ordinals-")
}

export function brc20CurrencyFromNativeBrc20Ticker(
  ticker: string,
): BRC20Currency {
  if (ticker.toLowerCase() === "atmt") {
    return `brc20-aiptp`
  }

  if (ticker.toLowerCase() === " ₿") {
    return `brc20-spacesignb`
  }

  if (ticker.toLowerCase() === "$b20") {
    return `brc20-db20`
  }

  if (ticker.toLowerCase() === "@LFG") {
    return `brc20-atlfg`
  }

  if (ticker.toLowerCase() === "$BIT") {
    return `brc20-dbit`
  }

  return `brc20-${ticker.toLowerCase()}`
}

export const isCurrency = (tokenTrait: unknown): tokenTrait is Currency =>
  isEnumValue(Currency, tokenTrait)
export type NativeCurrency = Exclude<Currency, WrappedCurrency>

export enum DownstreamCurrency {
  USDA = "token-wusda-downstream",
  STX = "token-wstx-downstream",
  BANANA = "token-wban-downstream",
  XBTC = "token-wbtc-downstream",
  DIKO = "token-wdiko-downstream",
  SLIME = "token-wslm-downstream",
  XUSD = "token-wxusd-downstream",
  MIA = "token-wmia-downstream",
  NYCC = "token-wnycc-downstream",
  CORGI = "token-wcorgi-downstream",
  VIBES = "token-wvibes-downstream",
}

type PickWrappedCurrency<T> = T extends `W_${string}` ? T : never
export type WrappedCurrency = (typeof Currency)[PickWrappedCurrency<
  keyof typeof Currency
>]
export namespace WrappedCurrency {
  export const isWrapped = (
    currency: CurrencyAndBRC20s | DownstreamCurrency,
  ): currency is WrappedCurrency => {
    if (!isCurrency(currency)) return false

    const pair = toPairs(Currency).find(([, value]) => value === currency)
    if (!pair) {
      throw new Error(
        `[WrappedCurrency.isWrapped] Unknown currency: ${currency}`,
      )
    }

    return pair[0].startsWith("W_")
  }

  const wrappedCurrencyMap: Record<DownstreamCurrency, WrappedCurrency> = {
    [DownstreamCurrency.USDA]: Currency.W_USDA,
    [DownstreamCurrency.STX]: Currency.W_STX,
    [DownstreamCurrency.BANANA]: Currency.W_BANANA,
    [DownstreamCurrency.XBTC]: Currency.W_XBTC,
    [DownstreamCurrency.DIKO]: Currency.W_DIKO,
    [DownstreamCurrency.SLIME]: Currency.W_SLIME,
    [DownstreamCurrency.XUSD]: Currency.W_XUSD,
    [DownstreamCurrency.MIA]: Currency.W_MIA,
    [DownstreamCurrency.NYCC]: Currency.W_NYCC,
    [DownstreamCurrency.CORGI]: Currency.W_CORGI,
    [DownstreamCurrency.VIBES]: Currency.W_VIBES,
  }
  const wrappedCurrencyRevertMap: Record<WrappedCurrency, DownstreamCurrency> =
    zipObject(values(wrappedCurrencyMap), keys(wrappedCurrencyMap)) as any

  export const wrap = (currency: DownstreamCurrency): WrappedCurrency => {
    return wrappedCurrencyMap[currency]
  }

  export const unwrap = (currency: WrappedCurrency): DownstreamCurrency => {
    return wrappedCurrencyRevertMap[currency]
  }

  export const safeUnwrap = (
    currency: CurrencyAndBRC20s,
  ): BRC20Currency | NativeCurrency | DownstreamCurrency => {
    if (isWrapped(currency)) {
      return unwrap(currency)
    } else {
      return currency
    }
  }
}
