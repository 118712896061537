import { BtcKitRequestFn } from "@btckit/types"
import { RpcError, RpcResponse } from "@btckit/types/dist/types/rpc"
import { hex } from "@scure/base"
import * as btc from "@scure/btc-signer"
import { SignatureHash } from "@stacks/connect"
import {
  concatWith,
  defer,
  ignoreElements,
  interval,
  of,
  shareReplay,
  startWith,
  takeWhile,
} from "rxjs"
import { BigNumber } from "../../../../../utils/BigNumber"
import { hasAny } from "../../../../../utils/arrayHelpers"
import { getTapInternalKeyOfP2TRPublicKey } from "../../../../../utils/bitcoinHelpers"
import { DisplayableError } from "../../../../../utils/error"
import { BitcoinNetwork } from "../BitcoinClient/BitcoinClient.types"
import {
  WalletAdapter,
  WalletAdapterAddresses,
  WalletAdapterStatic,
} from "./WalletAdapters.types"

type Addresses = WalletAdapterAddresses<object, { publicKey: string }>

const localStorageKey = "alex:hiro-wallet-adapter"

class HiroWalletAdapterImpl implements WalletAdapter {
  static getAdapter: WalletAdapterStatic<HiroWalletAdapterImpl>["getAdapter"] =
    (network: BitcoinNetwork) => {
      return interval(3000).pipe(
        startWith(null),
        // complete once detect it installed
        takeWhile(() => (window as any).HiroWalletProvider == null),
        ignoreElements(),

        concatWith(
          defer(() =>
            of(new HiroWalletAdapterImpl(network, window.btc!.request)),
          ),
        ),

        shareReplay({ bufferSize: 1, refCount: true }),
      )
    }

  constructor(
    private network: BitcoinNetwork,
    private request: BtcKitRequestFn,
  ) {}

  async connect(): Promise<void> {
    await this.getAddresses()
  }

  async disconnect(): Promise<void> {
    localStorage.removeItem(localStorageKey)
    return Promise.resolve()
  }

  async getAddresses(): Promise<Addresses> {
    let resp: GetAddressesResponseData | undefined

    const stored = localStorage.getItem(localStorageKey) || undefined
    if (stored != null) {
      try {
        resp = JSON.parse(stored)
        if (
          resp == null ||
          !("addresses" in resp) ||
          !Array.isArray(resp.addresses)
        ) {
          throw new Error("Invalid stored addresses")
        }
      } catch {
        localStorage.removeItem(localStorageKey)
      }
    }
    if (resp == null) {
      resp = handleRpcError<GetAddressesResponseData>(
        (await this.request("getAddresses")) as any,
      )
      localStorage.setItem(localStorageKey, JSON.stringify(resp))
    }

    const addresses = resp.addresses.filter(
      a => (a as any).symbol === "BTC" && a.type,
    )

    const ordinals: Addresses["ordinals"][number][] = addresses
      .filter(a => a.type === "p2tr")
      .map(a => ({
        address: a.address,
        publicKey: a.publicKey!,
        tapInternalKey: hex.encode(
          getTapInternalKeyOfP2TRPublicKey(
            this.network,
            hex.decode(a.publicKey!),
          ),
        ),
      }))
    const bitcoin: Addresses["bitcoin"][number][] = addresses
      .filter(a => a.type !== "p2tr")
      .map(a => ({
        address: a.address,
        publicKey: a.publicKey!,
      }))

    if (!hasAny(ordinals) || !hasAny(bitcoin)) {
      if (stored != null) {
        localStorage.removeItem(localStorageKey)
        return this.getAddresses()
      } else {
        throw new DisplayableError("Request ordinals addresses failed")
      }
    }

    return { ordinals, bitcoin }
  }

  async sendBitcoin(
    receiverAddress: string,
    satoshiAmount: BigNumber,
  ): Promise<{ txId: string }> {
    const resp = handleRpcError<{ txid: string }>(
      await this.request("sendTransfer", {
        address: receiverAddress,
        amount: BigNumber.toString(satoshiAmount),
      }),
    )

    return { txId: resp.txid }
  }

  async signAndFinalizePsbt(
    psbtHex: string,
    ordinalWalletSignIndices: number[],
    bitcoinWalletSignIndices: number[],
  ): Promise<{ signedPsbtHex: string }> {
    const { ordinals, bitcoin } = await this.getAddresses()

    const { hex: ordinalWalletSignedTxHex } = handleRpcError<{ hex: string }>(
      await this.request("signPsbt" as any, {
        hex: psbtHex,
        publicKey: ordinals[0].publicKey,
        signAtIndex: ordinalWalletSignIndices,
      } satisfies SignPsbtRequestParams),
    )

    const { hex: bitcoinWalletSignedTxHex } = handleRpcError<{ hex: string }>(
      await this.request("signPsbt" as any, {
        hex: ordinalWalletSignedTxHex,
        publicKey: bitcoin[0].publicKey,
        signAtIndex: bitcoinWalletSignIndices,
      } satisfies SignPsbtRequestParams),
    )

    const tx = btc.Transaction.fromPSBT(hex.decode(bitcoinWalletSignedTxHex))
    tx.finalize()

    return { signedPsbtHex: hex.encode(tx.toPSBT()) }
  }
}

export class HiroWalletAdapterError extends DisplayableError {
  constructor(rpcError: RpcError) {
    super("Hiro wallet error: " + rpcError.message, { cause: rpcError })
  }
}
const handleRpcError = <T extends object>(resp: RpcResponse<T>): T => {
  if ("error" in resp) {
    throw new HiroWalletAdapterError(resp.error)
  }
  return resp.result
}

const getAddressesResponseMockData = {
  addresses: [
    {
      symbol: "BTC",
      type: "p2wpkh",
      address: "bc1q…3y6ms",
      publicKey: "0224…590b",
      derivationPath: "m/84'/0'/4/0/0",
    },
    {
      symbol: "BTC",
      type: "p2tr",
      address: "bc1p…v6w",
      publicKey: "0233…c7fa",
      derivationPath: "m/86'/0'/4/0/0",
    },
    {
      symbol: "STX",
      address: "SPGH…W1QF",
    },
  ],
}
type GetAddressesResponseData = typeof getAddressesResponseMockData

/**
 * https://github.com/hirosystems/wallet/blob/dfa60f5ffb4bea5fdcbc0abf34b16fea0ec562b7/src/shared/rpc/methods/sign-psbt.ts
 */
interface SignPsbtRequestParams {
  publicKey: string
  hex: string
  allowedSighash?: SignatureHash[]
  signAtIndex?: number | number[]
  account?: number // default is user's current account
}

export const HiroWalletAdapter: WalletAdapterStatic<HiroWalletAdapterImpl> =
  HiroWalletAdapterImpl
