import { FC, HTMLProps, SVGProps } from "react"

export const QuestionFilledIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM8 12.1667C7.54 12.1667 7.16667 11.7933 7.16667 11.3333C7.16667 10.8733 7.54 10.5 8 10.5C8.46067 10.5 8.83333 10.8733 8.83333 11.3333C8.83333 11.7933 8.46067 12.1667 8 12.1667ZM9.30733 8.21467C8.70467 8.86467 8.676 9.224 8.684 9.66667H7.34733C7.34267 8.68333 7.36067 8.25 8.30133 7.35467C8.68333 6.992 8.98467 6.70467 8.94267 6.14067C8.904 5.604 8.456 5.32333 8.03267 5.32333C7.56 5.32333 7.00733 5.67467 7.00733 6.66533H5.66733C5.66733 5.06533 6.60667 4.032 8.06067 4.032C8.75133 4.032 9.35533 4.258 9.76067 4.66867C10.1407 5.054 10.3373 5.58333 10.33 6.20067C10.3193 7.12267 9.75867 7.728 9.30733 8.21467Z" />
  </svg>
)
