import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  stringAsciiT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const b20BridgeEndpoint = defineContract({
  "b20-bridge-endpoint": {
    "add-peg-in-address": {
      input: [{ name: "address", type: stringAsciiT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "add-peg-in-address-many": {
      input: [{ name: "addresses", type: listT(stringAsciiT) }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "approve-token": {
      input: [
        { name: "token", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "authorise-user": {
      input: [
        { name: "user", type: principalT },
        { name: "authorised", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "mark-expired": {
      input: [
        { name: "request-id", type: numberT },
        { name: "memo", type: stringAsciiT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "mark-expired-many": {
      input: [
        {
          name: "marked",
          type: listT(tupleT({ memo: stringAsciiT, "request-id": numberT })),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "mark-sent": {
      input: [
        { name: "request-id", type: numberT },
        { name: "memo", type: stringAsciiT },
        { name: "token-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "mark-sent-many": {
      input: [
        {
          name: "sent",
          type: listT(
            tupleT({
              memo: stringAsciiT,
              "request-id": numberT,
              token: principalT,
            }),
          ),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "pause-peg-in": {
      input: [
        { name: "token", type: principalT },
        { name: "paused", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "pause-peg-out": {
      input: [
        { name: "token", type: principalT },
        { name: "paused", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "register-stxdx-user-by-tx-sender": {
      input: [{ name: "pub-key", type: bufferT }],
      output: responseSimpleT(
        tupleT({ "peg-in-id": numberT, "peg-out-address": stringAsciiT }),
      ),
      mode: "public",
    },
    "register-user-by-tx-sender": {
      input: [],
      output: responseSimpleT(
        tupleT({ "peg-in-id": numberT, "peg-out-address": stringAsciiT }),
      ),
      mode: "public",
    },
    "register-user-on-behalf": {
      input: [{ name: "user", type: principalT }],
      output: responseSimpleT(
        tupleT({ "peg-in-id": numberT, "peg-out-address": stringAsciiT }),
      ),
      mode: "public",
    },
    "request-peg-in": {
      input: [
        { name: "token", type: principalT },
        { name: "amount", type: numberT },
        { name: "memo", type: stringAsciiT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "request-peg-out": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "amount", type: numberT },
        { name: "peg-out-address", type: stringAsciiT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "new-contract-owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-fee-address": {
      input: [{ name: "new-fee-address", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-peg-in-fee": {
      input: [
        { name: "token", type: principalT },
        { name: "new-peg-in-fee", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-peg-out-fee": {
      input: [
        { name: "token", type: principalT },
        { name: "new-peg-out-fee", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-peg-out-gas-fee": {
      input: [
        { name: "token", type: principalT },
        { name: "new-peg-out-gas-fee", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "div-down": {
      input: [
        { name: "a", type: numberT },
        { name: "b", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-fee-address": { input: [], output: principalT, mode: "readonly" },
    "get-pending-request": {
      input: [{ name: "request-id", type: numberT }],
      output: optionalT(
        tupleT({
          amount: numberT,
          expired: booleanT,
          memo: stringAsciiT,
          "peg-in": booleanT,
          "requested-at": numberT,
          sent: booleanT,
          token: principalT,
          user: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-pending-requests": { input: [], output: numberT, mode: "readonly" },
    "get-pending-requests-many": {
      input: [{ name: "requests-id", type: listT(numberT) }],
      output: listT(
        optionalT(
          tupleT({
            amount: numberT,
            expired: booleanT,
            memo: stringAsciiT,
            "peg-in": booleanT,
            "requested-at": numberT,
            sent: booleanT,
            token: principalT,
            user: principalT,
          }),
        ),
      ),
      mode: "readonly",
    },
    "get-request": {
      input: [{ name: "request-id", type: numberT }],
      output: optionalT(
        tupleT({
          amount: numberT,
          expired: booleanT,
          memo: stringAsciiT,
          "peg-in": booleanT,
          "requested-at": numberT,
          sent: booleanT,
          token: principalT,
          user: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-request-by-tx-sender": {
      input: [{ name: "request-id", type: numberT }],
      output: optionalT(
        tupleT({
          amount: numberT,
          expired: booleanT,
          memo: stringAsciiT,
          "peg-in": booleanT,
          "requested-at": numberT,
          sent: booleanT,
          token: principalT,
          user: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-request-by-tx-sender-many": {
      input: [{ name: "requests-id", type: listT(numberT) }],
      output: listT(
        optionalT(
          tupleT({
            amount: numberT,
            expired: booleanT,
            memo: stringAsciiT,
            "peg-in": booleanT,
            "requested-at": numberT,
            sent: booleanT,
            token: principalT,
            user: principalT,
          }),
        ),
      ),
      mode: "readonly",
    },
    "get-request-by-user": {
      input: [
        { name: "user", type: principalT },
        { name: "request-id", type: numberT },
      ],
      output: optionalT(
        tupleT({
          amount: numberT,
          expired: booleanT,
          memo: stringAsciiT,
          "peg-in": booleanT,
          "requested-at": numberT,
          sent: booleanT,
          token: principalT,
          user: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-request-by-user-many": {
      input: [
        { name: "user", type: listT(principalT) },
        { name: "requests-id", type: listT(numberT) },
      ],
      output: listT(
        optionalT(
          tupleT({
            amount: numberT,
            expired: booleanT,
            memo: stringAsciiT,
            "peg-in": booleanT,
            "requested-at": numberT,
            sent: booleanT,
            token: principalT,
            user: principalT,
          }),
        ),
      ),
      mode: "readonly",
    },
    "get-request-or-fail": {
      input: [{ name: "request-id", type: numberT }],
      output: responseSimpleT(
        tupleT({
          amount: numberT,
          expired: booleanT,
          memo: stringAsciiT,
          "peg-in": booleanT,
          "requested-at": numberT,
          sent: booleanT,
          token: principalT,
          user: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-requests-many": {
      input: [{ name: "requests-id", type: listT(numberT) }],
      output: listT(
        optionalT(
          tupleT({
            amount: numberT,
            expired: booleanT,
            memo: stringAsciiT,
            "peg-in": booleanT,
            "requested-at": numberT,
            sent: booleanT,
            token: principalT,
            user: principalT,
          }),
        ),
      ),
      mode: "readonly",
    },
    "get-requests-processing-progress": {
      input: [],
      output: tupleT({
        "requests-applied": numberT,
        "requests-processed": numberT,
      }),
      mode: "readonly",
    },
    "get-token-details-many": {
      input: [{ name: "tokens", type: listT(principalT) }],
      output: listT(
        optionalT(
          tupleT({
            approved: booleanT,
            "peg-in-fee": numberT,
            "peg-in-paused": booleanT,
            "peg-out-fee": numberT,
            "peg-out-gas-fee": numberT,
            "peg-out-paused": booleanT,
          }),
        ),
      ),
      mode: "readonly",
    },
    "get-token-details-or-fail": {
      input: [{ name: "token", type: principalT }],
      output: responseSimpleT(
        tupleT({
          approved: booleanT,
          "peg-in-fee": numberT,
          "peg-in-paused": booleanT,
          "peg-out-fee": numberT,
          "peg-out-gas-fee": numberT,
          "peg-out-paused": booleanT,
        }),
      ),
      mode: "readonly",
    },
    "get-user-details-or-fail": {
      input: [{ name: "user", type: principalT }],
      output: responseSimpleT(
        tupleT({ "peg-in-id": numberT, "peg-out-address": stringAsciiT }),
      ),
      mode: "readonly",
    },
    "get-user-peg-in-address-or-fail": {
      input: [{ name: "user", type: principalT }],
      output: responseSimpleT(stringAsciiT),
      mode: "readonly",
    },
    "is-approved-token": {
      input: [{ name: "token", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "is-memo-used": {
      input: [{ name: "memo", type: stringAsciiT }],
      output: booleanT,
      mode: "readonly",
    },
    "memo-to-request-id": {
      input: [{ name: "memo", type: stringAsciiT }],
      output: numberT,
      mode: "readonly",
    },
    "mul-down": {
      input: [
        { name: "a", type: numberT },
        { name: "b", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "approved-tokens": {
      input: principalT,
      output: optionalT(
        tupleT({
          approved: booleanT,
          "peg-in-fee": numberT,
          "peg-in-paused": booleanT,
          "peg-out-fee": numberT,
          "peg-out-gas-fee": numberT,
          "peg-out-paused": booleanT,
        }),
      ),
      mode: "mapEntry",
    },
    "authorised-users": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "memo-used": {
      input: stringAsciiT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "peg-in-address-to-id": {
      input: stringAsciiT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "peg-in-id-to-address": {
      input: numberT,
      output: optionalT(stringAsciiT),
      mode: "mapEntry",
    },
    requests: {
      input: numberT,
      output: optionalT(
        tupleT({
          amount: numberT,
          expired: booleanT,
          memo: stringAsciiT,
          "peg-in": booleanT,
          "requested-at": numberT,
          sent: booleanT,
          token: principalT,
          user: principalT,
        }),
      ),
      mode: "mapEntry",
    },
    users: {
      input: principalT,
      output: optionalT(
        tupleT({ "peg-in-id": numberT, "peg-out-address": stringAsciiT }),
      ),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    "fee-address": { input: noneT, output: principalT, mode: "variable" },
    "peg-in-id-assigned": { input: noneT, output: numberT, mode: "variable" },
    "peg-in-id-nonce": { input: noneT, output: numberT, mode: "variable" },
    "request-nonce": { input: noneT, output: numberT, mode: "variable" },
    "requests-processed": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
