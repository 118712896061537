export function isShallowEqual(
  objA: any,
  objB: any,
  compare?: (
    objValue: any,
    otherValue: any,
    key?: string,
  ) => boolean | undefined,
  compareContext?: any,
): boolean {
  let ret = compare ? compare.call(compareContext, objA, objB) : undefined

  if (ret !== undefined) return Boolean(ret)

  if (Object.is(objA, objB)) return true

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) return false

  for (let i = 0; i < keysA.length; i++) {
    const key = keysA[i]!

    if (!Object.prototype.hasOwnProperty.call(objB, key)) {
      return false
    }

    const valueA = objA[key]
    const valueB = objB[key]

    ret = compare
      ? compare.call(compareContext, valueA, valueB, key)
      : undefined
    if (ret !== undefined) return Boolean(ret)

    if (!Object.is(valueA, valueB)) {
      return false
    }
  }

  return true
}
