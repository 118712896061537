import { hex } from "@scure/base"
import {
  concatWith,
  defer,
  ignoreElements,
  interval,
  of,
  shareReplay,
  startWith,
  takeWhile,
} from "rxjs"
import { BigNumber } from "../../../../../utils/BigNumber"
import { hasAny } from "../../../../../utils/arrayHelpers"
import { getTapInternalKeyOfP2TRPublicKey } from "../../../../../utils/bitcoinHelpers"
import { DisplayableError } from "../../../../../utils/error"
import { BitcoinNetwork } from "../BitcoinClient/BitcoinClient.types"
import {
  WalletAdapter,
  WalletAdapterAddresses,
  WalletAdapterStatic,
} from "./WalletAdapters.types"

class UnisatWalletAdapterImpl implements WalletAdapter {
  static getAdapter: WalletAdapterStatic<UnisatWalletAdapterImpl>["getAdapter"] =
    network => {
      return interval(3000).pipe(
        startWith(null),
        // complete once detect it installed
        takeWhile(() => (window as any).unisat == null),
        ignoreElements(),

        concatWith(
          defer(() =>
            of(new UnisatWalletAdapterImpl(network, (window as any).unisat)),
          ),
        ),

        shareReplay({ bufferSize: 1, refCount: true }),
      )
    }

  private constructor(private network: BitcoinNetwork, private unisat: any) {}

  async connect(): Promise<void> {
    // not authorized yet
    // https://docs.unisat.io/dev/unisat-wallet-api#requestAccounts
    if (this.unisat.getAccounts().length === 0) {
      await this.unisat.requestAccounts()
    }
  }

  disconnect(): Promise<void> {
    // do nothing
    return Promise.resolve()
  }

  async getAddresses(): Promise<WalletAdapterAddresses> {
    /**
     * https://docs.unisat.io/dev/unisat-wallet-api#requestAccounts
     */
    const addresses: string[] = await this.unisat.requestAccounts()

    /**
     * https://docs.unisat.io/dev/unisat-wallet-api#getpublickey
     */
    const publicKey: string = await this.unisat.getPublicKey()

    if (!hasAny(addresses)) {
      throw new DisplayableError("Request ordinals addresses failed")
    }

    return {
      ordinals: [
        {
          address: addresses[0],
          tapInternalKey: hex.encode(
            getTapInternalKeyOfP2TRPublicKey(
              this.network,
              hex.decode(publicKey),
            ),
          ),
        },
      ],
      bitcoin: [{ address: addresses[0], publicKey: publicKey }],
    }
  }

  async sendBitcoin(
    receiverAddress: string,
    satoshiAmount: BigNumber,
    options?: { feeRate?: number },
  ): Promise<{ txId: string }> {
    /**
     * https://docs.unisat.io/dev/unisat-wallet-api#sendbitcoin
     */
    const txId = await this.unisat.sendBitcoin(
      receiverAddress,
      BigNumber.toNumber(satoshiAmount),
      { feeRate: options?.feeRate },
    )
    return { txId }
  }

  // The default fee rate of unisat is not aligned with mempool.space, so
  // sometimes the tx will be rejected by mempool due to uneligible network fee.
  //
  // async sendInscription(
  //   receiverAddress: string,
  //   inscriptionId: string,
  //   options?: { feeRate?: number },
  // ): Promise<{ txId: string }> {
  //   /**
  //    * https://docs.unisat.io/dev/unisat-wallet-api#sendInscription
  //    */
  //   const txId = await this.unisat.sendInscription(
  //     receiverAddress,
  //     inscriptionId,
  //     { feeRate: options?.feeRate },
  //   )
  //   return { txId }
  // }

  async signAndFinalizePsbt(
    psbtHex: string,
    ordinalWalletSignIndices: number[],
    bitcoinWalletSignIndices: number[],
  ): Promise<{
    signedPsbtHex: string
  }> {
    /**
     * https://docs.unisat.io/dev/unisat-wallet-api#signpsbt
     */
    const signedPsbtHex = await this.unisat.signPsbt(psbtHex, {
      // no matter what the value we set here, it will always return the finalized tx hex
      autoFinalize: true,
    })
    return { signedPsbtHex }
  }

  // Sometimes this method will throw some [object Object] error
  //
  // async broadcastTx(txHex: string): Promise<{ txId: string }> {
  //   /**
  //    * https://docs.unisat.io/dev/unisat-wallet-api#pushtx
  //    */
  //   const txId = await this.unisat.pushTx({ rawtx: txHex })
  //   return { txId }
  // }
}

export const UnisatWalletAdapter: WalletAdapterStatic<UnisatWalletAdapterImpl> =
  UnisatWalletAdapterImpl
