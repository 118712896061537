import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { FCC } from "../../utils/reactHelpers/types"

interface ContextValue {
  extra: ReactNode
  setExtra: (extra: ReactNode) => void
}

const Context = createContext<null | ContextValue>(null)

export const TopNavBarLogoExtraFunctionalProvider: FCC = props => {
  const [extra, setExtra] = useState<ReactNode>(null)

  const ctxValue: ContextValue = useMemo(
    () => ({
      extra,
      setExtra,
    }),
    [extra],
  )

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>
}

export const useTopNavBarLogoExtra = (): ReactNode => {
  return useContext(Context)?.extra
}

export const useSetTopNavBarLogoExtra = (extra: ReactNode): void => {
  const { setExtra } = useContext(Context) ?? {}

  useEffect(() => {
    setExtra?.(extra)
    return () => {
      setExtra?.(null)
    }
  }, [extra, setExtra])
}
