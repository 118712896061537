import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { Card } from "../../../../components/Card"
import { Divider } from "../../../../components/Divider"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { Pagination, PaginationProps } from "../../../../components/Pagination"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { RoundPrize } from "../types"
import { ReactComponent as EmptyIcon } from "./empty.svg"

export interface HistoryTabContentProps {
  rows: HistoryRowData[]
  paginationInfo: PaginationProps
  onWinnerListClick?: (lotteryId: number) => void
}

interface HistoryRowData {
  round: number
  token: TokenInfo
  prizePot: SuspenseResource<number>
  prizes: SuspenseResource<RoundPrize[]>
  participants: SuspenseResource<number>
  burnedAlexCount: SuspenseResource<number>
}

export const HistoryTabContent: FC<HistoryTabContentProps> = props => {
  const { $t } = useIntl()
  return (
    <div className="flex flex-col gap-y-6">
      <ul className="flex flex-col gap-y-4">
        {props.rows.map(row => (
          <HistoryRowCard
            key={row.round}
            data={row}
            onWinnerListClick={props.onWinnerListClick}
          />
        ))}
        {props.rows.length === 0 && (
          <div className="grow w-full flex flex-col gap-y-4 items-center justify-center">
            <EmptyIcon />
            <span className="text-base leading-6 font-normal text-gray-600">
              {$t(
                defineMessage({
                  defaultMessage: "No lottery history yet",
                  description: "/Lottery/HistoryTabContent/Empty text",
                }),
              )}
            </span>
          </div>
        )}
      </ul>
      <Pagination
        className={"w-full flex-wrap gap-3"}
        {...props.paginationInfo}
      />
    </div>
  )
}

const HistoryRowCard: FC<{
  data: HistoryRowData
  onWinnerListClick?: (lotteryId: number) => void
}> = ({ data, onWinnerListClick }) => {
  const { $t } = useIntl()

  return (
    <li>
      <Card backgroundImage="" className="bg-black/20 flex flex-col gap-y-2.5">
        <div className="flex flex-row items-center gap-x-2.5">
          <div className="text-xl leading-6 grow">
            <span className="font-semibold text-gray-200">
              {$t(
                defineMessage({
                  defaultMessage: "Round #{round}: {token}",
                  description: "/Lottery/HistoryRowCard/Card text",
                }),
                {
                  round: data.round,
                  token: (
                    <span className="font-medium text-yellow-200">
                      <Spensor>
                        {() => (
                          <TokenCount
                            count={readResource(data.prizePot)}
                            token={data.token}
                          />
                        )}
                      </Spensor>
                      &nbsp;
                      <TokenName token={data.token} />
                    </span>
                  ),
                },
              )}
            </span>
          </div>
          <span className="text-sm leading-5 font-normal text-gray-200">
            {$t(
              defineMessage({
                defaultMessage: "Participants: {participants}",
                description: "/Lottery/HistoryRowCard/Content text",
              }),
              {
                participants: (
                  <Spensor fallback="--">
                    {() => readResource(data.participants)}
                  </Spensor>
                ),
              },
            )}
          </span>
          <Divider direction="vertical" className="h-4 border-gray-800" />
          <HeadlessButton
            onClick={() => onWinnerListClick?.(data.round)}
            className="text-blue-600"
          >
            {$t(
              defineMessage({
                defaultMessage: "Winner List",
                description: "/Lottery/HistoryRowCard/Button text",
              }),
            )}
          </HeadlessButton>
        </div>
        <InfoList
          gapClassName={"gap-4"}
          direction={"row-responsive"}
          listItemDirection={"column"}
        >
          <Spensor>
            {() => (
              <>
                {readResource(data.prizes).map(prize => (
                  <InfoListItem
                    key={prize.type}
                    className="flex-1 gap-1 text-base leading-6 font-normal text-gray-200"
                  >
                    <InfoListItemTitle className="capitalize text-xs leading-4 font-medium text-gray-200/50">
                      {$t(
                        defineMessage({
                          defaultMessage: "{type} Prize",
                          description:
                            "/Lottery/HistoryRowCard/Info list item title",
                        }),
                        { type: prize.type },
                      )}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      <div>
                        <TokenCount
                          count={prize.prizeTokenCount}
                          token={data.token}
                        />
                        &nbsp;
                        <TokenName token={data.token} />
                      </div>
                      <div>
                        {$t(
                          defineMessage({
                            defaultMessage:
                              "{winningTicketsCount} Winning Tickets",
                            description:
                              "/Lottery/HistoryRowCard/Info list item detail",
                          }),
                          { winningTicketsCount: prize.winningTicketsCount },
                        )}
                      </div>
                    </InfoListItemDetail>
                  </InfoListItem>
                ))}
                {readResource(data.burnedAlexCount) >= 0 && (
                  <InfoListItem
                    key="burned"
                    className="flex-1 gap-1 text-base leading-6 font-normal text-gray-200"
                  >
                    <InfoListItemTitle className="capitalize text-xs leading-4 font-medium text-gray-200/50">
                      {$t(
                        defineMessage({
                          defaultMessage: "🔥 Burned",
                          description:
                            "/Lottery/HistoryRowCard/Info list item title",
                        }),
                      )}
                    </InfoListItemTitle>
                    <InfoListItemDetail>
                      <TokenCount
                        count={readResource(data.burnedAlexCount)}
                        token={data.token}
                      />
                      &nbsp;
                      <TokenName token={data.token} />
                    </InfoListItemDetail>
                  </InfoListItem>
                )}
              </>
            )}
          </Spensor>
        </InfoList>
      </Card>
    </li>
  )
}
