import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../components/NavLink"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { Spensor } from "../../components/Spensor"
import { BlueGradientAlert } from "../../components/alert/BlueGradientAlert"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { usePathGenerator } from "../../routes/routes"
import { useAccountStore } from "../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../utils/createStore"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { IDOListLargeCard } from "./component/IDOListLargeCard/IDOListLargeCard"
import { WiredAPowerAlert } from "./component/WiredAPowerAlert"
import { LaunchingStatus } from "./store/LaunchPadContractStore"
import LaunchpadListStore from "./store/list/LaunchpadListStore"

const { useStore, Provider } =
  createStore<LaunchpadListStore>("LaunchpadListStore")

const WiredLaunchpadListPageContent: FC = () => {
  const store = useStore()
  const g = usePathGenerator()
  const { $t } = useIntl()

  return (
    <PageStackPage
      title={
        <PrimaryPageTitle
          subtitle={$t(
            defineMessage({
              defaultMessage:
                "Participate in the Launchpads of exciting projects emerging on Stacks. ",
              description: "/Launchpad/ListPageContent/Primary page sub title",
            }),
          )}
        >
          {$t(
            defineMessage({
              defaultMessage: "Launchpad",
              description: "/Launchpad/ListPageContent/Primary page title",
            }),
          )}
        </PrimaryPageTitle>
      }
    >
      <WiredAPowerAlert />

      <div className={"grid grid-cols-12 grid-rows-1 gap-y-8 lg:gap-8 my-8"}>
        <Spensor
          fallback={
            <div className={"col-span-12 place-self-center"}>
              <LoadingIndicator />
            </div>
          }
        >
          {() => (
            <>
              {store.idoStores$.map((ido, idx) => (
                <IDOListLargeCard
                  key={ido.urlSlug}
                  className="col-span-24"
                  isHotProject={ido.contentfulDetail$.hotBadge}
                  featureImageUrl={ido.contentfulDetail$.featuredImage!}
                  idoTokenMeta={ido.contentfulDetail$.meta}
                  idoStatus={
                    ido.isLegacyAlexIdo
                      ? { type: LaunchingStatus.Finished, success: true }
                      : ido.contractStore$?.tokenProfileViewModule.status ?? {
                          type: LaunchingStatus.Upcoming,
                        }
                  }
                  idoInfo={store.idoInfos$[idx]}
                  detailPageLink={
                    ido.isLegacyAlexIdo ? null : g.launchpadDetail(ido.urlSlug)
                  }
                  dashboardPageLink={
                    ido.isLegacyAlexIdo
                      ? "https://ido.alexlab.co"
                      : ido.contractStore$ != null
                      ? g.launchpadDashboard(ido.urlSlug)
                      : undefined
                  }
                />
              ))}
            </>
          )}
        </Spensor>
      </div>

      <BlueGradientAlert>
        <div
          className={
            "w-full flex flex-col gap-3.5 lg:flex-row lg:items-center lg:justify-between"
          }
        >
          <div className={"flex flex-col gap-2.5"}>
            <p className={"text-lg leading-7 font-medium text-gray-200"}>
              {$t(
                defineMessage({
                  defaultMessage: "Apply for launchpad on ALEX Launchpad",
                  description: "/Launchpad/ListPageContent/Alert content",
                }),
              )}
            </p>

            <p>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Launch your token on ALEX to connect with our core community of stakers and long-term holders.",
                  description: "/Launchpad/ListPageContent/Alert content",
                }),
              )}
            </p>
          </div>

          <NavLink to={"https://shrl.ink/E2Wm"}>
            <GradientFilledButton boxClassName={"px-[60px] py-2"}>
              {$t(
                defineMessage({
                  defaultMessage: "Apply Launchpad",
                  description: "/Launchpad/ListPageContent/Alert button",
                }),
              )}
            </GradientFilledButton>
          </NavLink>
        </div>
      </BlueGradientAlert>
    </PageStackPage>
  )
}

export const LaunchpadListPage: FC = () => {
  const chainStore = useChainStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(
    () =>
      new LaunchpadListStore(
        authStore,
        accountStore,
        currencyStore,
        chainStore,
      ),
    [accountStore, authStore, chainStore, currencyStore],
  )
  return (
    <Provider store={store}>
      <WiredLaunchpadListPageContent />
    </Provider>
  )
}
