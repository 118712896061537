import { BigNumber } from "../../../../../utils/BigNumber"

export interface FeesInfo {
  economyFee: number
  fastestFee: number
  halfHourFee: number
  hourFee: number
  minimumFee: number
}

export interface BlockInfo {
  height: number
  blockHash: string
  previousBlockHash: string
  unixTimestamp: number
  nonce: number
}

export type TransactionInfoVout =
  | TransactionInfoVout.WithoutAddress
  | TransactionInfoVout.WithAddress
export namespace TransactionInfoVout {
  interface Common {
    scriptpubkey: string
    scriptpubkey_asm: string
    value: number
  }

  export interface WithoutAddress extends Common {
    scriptpubkey_type: "multisig" | "op_return" | "nulldata" | "nonstandard"
  }

  export interface WithAddress extends Common {
    scriptpubkey_type: string
    scriptpubkey_address: string
  }
}
export interface TransactionInfo {
  txid: string
  vin: {
    txid: string
    vout: number
  }[]
  vout: TransactionInfoVout[]
  fee: number
  blockHeight?: number
}

export interface BitcoinNetworkBasicInfo {
  block: BlockInfo
  fees: FeesInfo
}

export type BitcoinNetwork = "mainnet" | "testnet"

export interface UTXOBasic {
  txId: string
  index: number
  amount: BigNumber
}
export type UTXOWithConfirmation =
  | UTXOWithConfirmation.Confirmed
  | UTXOWithConfirmation.Unconfirmed
export namespace UTXOWithConfirmation {
  export interface Confirmed extends UTXOBasic {
    blockHeight: number
  }

  export interface Unconfirmed extends UTXOBasic {
    blockHeight: undefined
  }

  export const isConfirmed = (
    utxo: UTXOWithConfirmation,
  ): utxo is Confirmed => {
    return utxo.blockHeight != null
  }
}
export interface UTXOSpendable extends UTXOBasic {
  address: string
  witnessUtxoScript: Uint8Array
  redeemScript?: Uint8Array
  tapInternalKey?: Uint8Array
}

export function isSameUTXO(a: UTXOBasic, b: UTXOBasic): boolean {
  return a.txId === b.txId && a.index === b.index
}

export interface BRC20WalletBalance {
  tokens: {
    symbol: string
    balance: BigNumber
    transferableBalance: BigNumber
    restBalance: BigNumber
  }[]
}

export interface BRC20WalletBalanceDetails {
  symbol: string
  balance: BigNumber
  transferableBalance: BigNumber
  restBalance: BigNumber
  transferableInscriptions: {
    inscriptionId: string
    inscriptionNumber: string
    amount: BigNumber
  }[]
}

export interface BRC20WalletRecentTransferableInscription extends UTXOBasic {
  txId: string
  index: number
  amount: BigNumber
  symbol: string
  inscriptionId: string
  inscriptionNumber: number
  transferResult: null | "success" | "failed"
  toAddress: null | string
  blockHeight: number
}
export interface BRC20WalletRecentTransferables {
  transferableInscriptions: BRC20WalletRecentTransferableInscription[]
}

export interface WalletOrdinalInscription extends UTXOBasic {
  txId: string
  index: number
  amount: BigNumber
  inscriptionId: string
  inscriptionNumber: number
  mimeType: string
  previewUrl: string
  blockHeight: number
}

export interface InscribeOrderResponse {
  id: string
  orderLink: string
  createdAt: Date
  charge: {
    address: string
    amount: BigNumber
    feeToUSD: BigNumber
  }
}

/**
 * Because different indexers have different response confirm time, so we didn't
 * add `sent` status here, you need to check it through the mempool you are
 * using, by the `txId` field.
 */
export type InscribeOrderStatus = {
  id: string
  orderLink: string
} & (
  | { status: "unpaid" }
  | { status: "processing" }
  | { status: "sending"; txId: string }
  | { status: "sent"; txId: string }
)
