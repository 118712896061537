import { action, computed, makeObservable, observable } from "mobx"
import { computedFn } from "mobx-utils"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { Result } from "../../../../utils/Result"
import { Currency } from "../../../../utils/alexjs/Currency"
import { DepositFormError, DepositFormErrorType } from "../../components/types"
import type {
  DepositFormData,
  OrderbookAsset,
} from "../OrderbookStore.service/OrderbookStore.service"
import { depositToStxDx } from "../OrderbookStore.service/OrderbookStore.service"
import { StxDxStore } from "../stxdx_shared/StxDxStore"

export class StxDxDepositModule {
  constructor(readonly store: StxDxStore) {
    makeObservable(this)
  }

  @observable confirming = false
  @observable selectingFromToken?: OrderbookAsset
  @observable tokens: {
    token: OrderbookAsset
    amount: SuspenseObservable<number>
  }[] = [
    {
      token: this.basePriceToken$,
      amount: new SuspenseObservable<number>(),
    },
  ]

  @computed get allStxDxAssets$(): OrderbookAsset[] {
    return this.store.info.allAccessibleMarketCurrencies$
  }

  @computed get basePriceToken$(): OrderbookAsset {
    return (
      this.store.info.allAccessibleMarkets$[0]?.priceToken ??
      (Currency.sUSDT as OrderbookAsset)
    )
  }

  @computed
  get userDecidedTokens(): {
    token: OrderbookAsset
    amount: SuspenseObservable<number>
  }[] {
    return this.tokens.filter(t => t.amount.get() != null && t.amount.read$ > 0)
  }

  @action resetForm(): void {
    this.tokens = [
      {
        token: this.basePriceToken$,
        amount: new SuspenseObservable<number>(),
      },
    ]
  }

  @action reinit(): void {
    this.resetForm()
    this.confirming = false
    this.selectingFromToken = undefined
  }

  @computed get canDelete(): boolean {
    return this.tokens.length > 1
  }

  @computed get canAddMoreToken(): boolean {
    return this.tokens.length < this.allStxDxAssets$.length
  }

  @action addToken(): void {
    const existing = this.tokens.map(t => t.token)
    const notIncluded = this.allStxDxAssets$.filter(
      a => !existing.includes(a),
    )[0]
    if (notIncluded == null) {
      return
    }
    this.tokens.push({
      token: notIncluded,
      amount: new SuspenseObservable<number>(),
    })
  }

  @action deleteToken(token: OrderbookAsset): void {
    this.tokens = this.tokens.filter(t => t.token !== token)
  }

  @computed get maxSTXWarning(): boolean {
    const stxAmount =
      this.tokens.find(s => s.token === Currency.W_STX)?.amount.get() ?? 0
    const balance = this.store.account.getBalance$(Currency.W_STX)
    return stxAmount !== 0 && stxAmount >= balance - 0.1
  }

  @computed get formData$(): Result<DepositFormData, DepositFormError> {
    if (this.userDecidedTokens.length === 0) {
      return Result.error({
        type: DepositFormErrorType.AmountIsEmpty,
        message: "Input Amount",
      })
    }
    if (
      this.userDecidedTokens.some(
        t => t.amount.read$ > this.store.account.getBalance$(t.token),
      )
    ) {
      return Result.error({
        type: DepositFormErrorType.InsufficientTokenBalance,
        message: "Insufficient Balance",
      })
    }

    return Result.ok({
      stxAddress: this.store.authStore.stxAddress$,
      userId: this.store.myInfo.userId$,
      tokens: this.userDecidedTokens.map(t => ({
        asset: t.token,
        assetId: this.store.info.currencyAssetId$(t.token),
        amount: t.amount.read$,
      })),
    })
  }

  async deposit(form: DepositFormData): Promise<{ txId: string }> {
    return await depositToStxDx(form)
  }

  maxDepositableCount$ = computedFn((_currency: OrderbookAsset) => {
    return 1000000
  })
}
