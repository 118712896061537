import { CoreNodeInfoResponse } from "@stacks/stacks-blockchain-api-types"
import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import {
  API_HOST,
  ESTIMATED_BLOCK_DURATION,
  REFRESH_FOR_NEW_BLOCK,
} from "../../config"
import { StakingToken } from "../../pages/Stake/store/manualStaking/ManualStakeStore.service"
import StakeChainModule from "../../pages/Stake/store/shared/StakeChainModule"
import { LazyValue } from "../LazyValue/LazyValue"
import { pMemoizeDecorator } from "../LazyValue/pMemoizeDecorator"
import TimerValue from "../TimerValue"
import { AppEnvStore } from "../appEnvStore/AppEnvStore"
import { getBlockHeightTimestamp } from "./ChainStore.service"

export class ChainStore {
  constructor(readonly appEnv: AppEnvStore) {
    makeObservable(this)
  }

  private refreshTimer = new TimerValue(REFRESH_FOR_NEW_BLOCK)

  private currentInfo = new LazyValue(
    () => this.refreshTimer.value,
    (): Promise<CoreNodeInfoResponse> =>
      fetch(`${API_HOST}/v2/info`).then(r => r.json()),
  )

  @computed get currentBlockHeight$(): number {
    return this.currentInfo.value$["stacks_tip_height"]
  }

  @computed get currentBlockHash$(): string {
    return this.currentInfo.value$["stacks_tip"]
  }

  estimatedDateForBlock$ = createTransformer((targetBlock: number) => {
    return new Date(
      new Date().getTime() +
        (targetBlock - this.currentBlockHeight$) * ESTIMATED_BLOCK_DURATION,
    )
  })

  private blockBurntAt = memoize(
    (block: number) =>
      new LazyValue(
        () => block,
        b => getBlockHeightTimestamp(b).catch(() => null),
        {
          decorator: pMemoizeDecorator({
            persistKey: "blockBurntAt",
          }),
        },
      ),
  )

  @computed get speedStatus$(): "normal" | "busy" | "stuck" {
    const burntAt = this.blockBurntAt(this.currentBlockHeight$ - 2).value$
    if (burntAt == null) {
      return "normal"
    }
    const delta = new Date().getTime() - burntAt
    if (delta < ESTIMATED_BLOCK_DURATION * 3) {
      return "normal"
    }
    if (delta < ESTIMATED_BLOCK_DURATION * 5) {
      return "busy"
    }
    return "stuck"
  }

  stakeChainModule = memoize(
    (token: StakingToken) => new StakeChainModule(token, this),
  )
}
