import { format } from "date-fns"
import { startCase } from "lodash"
import { BaseRowData, CsvRow, Exporter } from "../types"

const DEFAULT_FORMAT = "MMM dd yyyy HH:mm"

export const exporterBuilder: (
  amount: string,
  tradeInfo: string,
) => Exporter<BaseRowData> = (amount, tradeInfo) => rowData => {
  return [
    rowData.id,
    rowData.type,
    startCase(rowData.status),
    amount,
    tradeInfo,
    format(rowData.time, DEFAULT_FORMAT),
  ].map(quote) as CsvRow
}

export function quote(str: string): string {
  return `"${str}"`
}
