import { FungibleConditionCode } from "@stacks/transactions"
import { computed, makeObservable, observable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  asSender,
  contractAddr,
} from "../../../generated/smartContractHelpers/asSender"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { Currency } from "../../../utils/alexjs/Currency"
import { transfer } from "../../../utils/alexjs/postConditions"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { Result } from "../../../utils/Result"
import {
  BuyLotteryFormError,
  BuyLotteryFormErrorType,
} from "../component/types"
import { LotteryPageStore } from "./LotteryPageStore"

export class BuyLotteryModule {
  constructor(readonly store: LotteryPageStore) {
    makeObservable(this)
  }

  @observable showModal = false
  @observable showConfirmation = false
  ticketAmount = new SuspenseObservable<number>()

  @computed get alexPerTicket$(): number {
    return 10
  }

  @computed get alexBalance$(): number {
    return this.store.accountStore.getBalance$(Currency.ALEX)
  }

  @computed get maxTicketToPurchaseWithAlex$(): number {
    return Math.floor(this.alexBalance$ / this.alexPerTicket$)
  }

  @computed get amountInAlex$(): number {
    return this.ticketAmount.read$ * this.alexPerTicket$
  }

  @computed get apowerUnblockAt$(): number {
    return 5
  }

  @computed get apowerUnlocked$(): boolean {
    if (this.ticketAmount.get() == null) {
      return false
    }
    return this.ticketAmount.read$ >= this.apowerUnblockAt$
  }

  apowerTicketAmount = new SuspenseObservable<number>(0)

  @computed get apowerTicketAmount$(): number {
    if (!this.apowerUnlocked$) {
      return 0
    }
    return this.apowerTicketAmount.read$
  }

  @computed get apowerPerTicket$(): number {
    return 50
  }

  eligibleApowerTicket = new LazyValue(
    () => this.ticketAmount.read$,
    ticketAmount =>
      asSender(CONTRACT_DEPLOYER)
        .contract("alex-lottery")
        .func("get-max-bonus-for-tickets")
        .call({
          tickets: ticketAmount,
        }),
  )

  @computed get maxEligibleApowerTicketCount$(): number {
    return this.eligibleApowerTicket.value$
  }

  @computed get apowerAmount$(): number {
    return this.apowerTicketAmount$ * this.apowerPerTicket$
  }

  @computed get apowerBalance$(): number {
    return this.store.accountStore.getBalance$(Currency.APOWER)
  }

  @computed get maxTicketToPurchaseWithApower$(): number {
    return Math.floor(this.apowerBalance$ / this.apowerPerTicket$)
  }

  @computed get formData$(): Result<any, BuyLotteryFormError> {
    if (!this.ticketAmount.get()) {
      return Result.error({
        type: BuyLotteryFormErrorType.EmptyNormalLotteryTicketCount,
        message: "Enter amount",
      })
    }
    if (this.ticketAmount.read$ > this.maxTicketToPurchaseWithAlex$) {
      return Result.error({
        type: BuyLotteryFormErrorType.InsufficientNormalLotteryTicketPriceTokenBalance,
        message: "Insufficient ALEX",
      })
    }
    if (this.apowerTicketAmount$ > this.maxEligibleApowerTicketCount$) {
      return Result.error({
        type: BuyLotteryFormErrorType.ExceedMaxAllowAdditionalLotteryTicketCount,
        message: "Exceed max APower ticket",
      })
    }
    if (this.apowerTicketAmount$ > this.maxTicketToPurchaseWithApower$) {
      return Result.error({
        type: BuyLotteryFormErrorType.InsufficientAdditionalLotteryTicketPriceTokenBalance,
        message: "Insufficient APower",
      })
    }
    return Result.ok({})
  }

  txStore = new ConfirmTransactionStore()

  @asyncAction
  async register(run = runAsyncAction): Promise<void> {
    try {
      this.showModal = false
      this.showConfirmation = false
      const stxAddress$ = this.store.authStore.stxAddress$
      const { txId } = await run(
        asSender(stxAddress$)
          .contract("alex-lottery")
          .func("register")
          .call(
            {
              "lottery-id": this.store.info.currentLotteryId$,
              tickets: this.ticketAmount.read$,
              "token-trait": Currency.ALEX,
              "bonus-tickets": this.apowerTicketAmount$,
            },
            [
              transfer(
                stxAddress$,
                Currency.ALEX,
                this.amountInAlex$,
                FungibleConditionCode.Equal,
              ),
              transfer(
                stxAddress$,
                Currency.APOWER,
                this.apowerAmount$,
                FungibleConditionCode.Equal,
              ),
              transfer(
                contractAddr("alex-lottery"),
                Currency.ALEX,
                0,
                FungibleConditionCode.GreaterEqual,
              ),
            ],
          ),
      )
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }
}
