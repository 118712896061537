import { identity } from "rxjs"
import stableHash from "stable-hash"

function stringToUint8Array(str: string): Uint8Array {
  const encoder = new TextEncoder()
  return encoder.encode(str)
}

function arrayBufferToHex(buffer: ArrayBuffer): string {
  return Array.from(new Uint8Array(buffer))
    .map(e => e.toString(16).padStart(2, "0"))
    .join("")
}

const digest =
  crypto?.subtle?.digest.bind(crypto.subtle) ??
  // for some case that the page is not loaded from a https url, e.g. 192.168.x.x
  identity
export const objectHash = async (obj: any): Promise<string> => {
  const unfixedLengthHash = stableHash(obj)

  return arrayBufferToHex(
    await digest("sha-256", stringToUint8Array(unfixedLengthHash)),
  )
}
