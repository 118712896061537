import { Tx } from "@mempool/mempool.js/lib/interfaces"
import { uniqWith } from "lodash"
import { combineLatest, map, Observable, switchMap } from "rxjs"
import { hasAny, last } from "../../../../../utils/arrayHelpers"
import { BigNumber } from "../../../../../utils/BigNumber"
import {
  BitcoinNetwork,
  BRC20WalletRecentTransferableInscription,
  BRC20WalletRecentTransferables,
  InscribeOrderStatus,
  isSameUTXO,
  UTXOWithConfirmation,
  WalletOrdinalInscription,
} from "./BitcoinClient.types"
import {
  fetchHiroOrdinalsAPI,
  fetchOklink,
  honoClient,
  mempoolFetch,
} from "./request.service"

export const fetchInscribeOrderStatus = async (
  orderId: string,
): Promise<undefined | InscribeOrderStatus> => {
  const resp = await honoClient()._api.inscribeOrders.$get({
    query: { orderId },
  })
  const data = await resp.json()

  if (data.status === "error") {
    return undefined
  }

  return data
}

export async function filterPureSpendableBtcUTXOs<
  UTXO extends UTXOWithConfirmation,
>(network: BitcoinNetwork, utxos: UTXO[]): Promise<UTXO[]> {
  const confirmedUTXOs: UTXOWithConfirmation.Confirmed[] = utxos.filter(
    UTXOWithConfirmation.isConfirmed,
  ) as any

  const resp = await honoClient()._api.utxoInscriptionCheckings.$post({
    json: {
      utxos: confirmedUTXOs.map(u => ({
        txid: u.txId,
        vout: u.index,
        blockHeight: u.blockHeight,
      })),
    },
  })
  const data = await resp.json()

  return utxos.filter(
    utxo =>
      confirmedUTXOs.find(u => isSameUTXO(u, utxo)) &&
      data.resultByLocation[`${utxo.txId}:${utxo.index}`] === false,
  )
}

const getInscriptionUTXOMockResp = [
  {
    page: "1",
    limit: "1",
    totalPage: "10000",
    totalInscription: "5146426",
    inscriptionsList: [
      {
        inscriptionId:
          "9e54767636589dd70af60b2074d23c1689b68f6a5d8036f9dddaa2bc31a0b5ffi0",
        inscriptionNumber: "999922",
        location:
          "9e54767636589dd70af60b2074d23c1689b68f6a5d8036f9dddaa2bc31a0b5ff:0:0",
        token: "",
        state: "fail",
        msg: "amount exceed limit: 1000000000000000000000",
        tokenType: "BRC20",
        actionType: "mint",
        logoUrl: "",
        ownerAddress:
          "bc1pupvkqkxe2wnxg2ytf7twr07pychh8htx0cy5w439yyzzgdu2pzqsukzqwd",
        txId: "9e54767636589dd70af60b2074d23c1689b68f6a5d8036f9dddaa2bc31a0b5ff",
        blockHeight: "784528",
        contentSize: "",
        time: "1680975864000",
      },
    ],
  },
]
export const fetchWalletUnspentInscriptionUTXO = (
  network: BitcoinNetwork,
  inscriptionId: string,
): Observable<undefined | { txId: string; index: number }> => {
  return fetchOklink<typeof getInscriptionUTXOMockResp>({
    network,
    path: `/api/v5/explorer/btc/inscriptions-list`,
    query: { inscriptionId },
  }).pipe(
    map(resp =>
      !hasAny(resp.data) || !hasAny(resp.data[0].inscriptionsList)
        ? undefined
        : {
            txId: resp.data[0].inscriptionsList[0].txId,
            index: Number(
              resp.data[0].inscriptionsList[0].location.split(":")[1]!,
            ),
          },
    ),
  )
}

const getInscriptionUTXOByAddressMockResp = {
  limit: 20,
  offset: 0,
  total: 1,
  results: [
    {
      id: "1463d48e9248159084929294f64bda04487503d30ce7ab58365df1dc6fd58218i0",
      number: 248751,
      address: "bc1pvwh2dl6h388x65rqq47qjzdmsqgkatpt4hye6daf7yxvl0z3xjgq247aq8",
      genesis_address:
        "bc1pvwh2dl6h388x65rqq47qjzdmsqgkatpt4hye6daf7yxvl0z3xjgq247aq8",
      genesis_block_height: 778921,
      genesis_block_hash:
        "0000000000000000000452773967cdd62297137cdaf79950c5e8bb0c62075133",
      genesis_tx_id:
        "1463d48e9248159084929294f64bda04487503d30ce7ab58365df1dc6fd58218",
      genesis_fee: "3179",
      genesis_timestamp: 0,
      tx_id: "1463d48e9248159084929294f64bda04487503d30ce7ab58365df1dc6fd58218",
      location:
        "1463d48e9248159084929294f64bda04487503d30ce7ab58365df1dc6fd58218:0:0",
      output:
        "1463d48e9248159084929294f64bda04487503d30ce7ab58365df1dc6fd58218:0",
      value: "546",
      offset: "0",
      sat_ordinal: "1232735286933201",
      sat_rarity: "common",
      sat_coinbase_height: 430521,
      mime_type: "text/plain",
      content_type: "text/plain;charset=utf-8",
      content_length: 59,
      timestamp: 1677733170000,
    },
  ],
}
export const fetchWalletOrdinalInscriptions = (
  network: BitcoinNetwork,
  walletAddress: string,
): Observable<WalletOrdinalInscription[]> => {
  return combineLatest(
    fetchWalletOrdinalInscriptionsImpl(network, {
      address: walletAddress,
      offset: 0,
    }),
    fetchWalletOrdinalInscriptionsImpl(network, {
      address: walletAddress,
      offset: 60,
    }),
    fetchWalletOrdinalInscriptionsImpl(network, {
      address: walletAddress,
      offset: 120,
    }),
  ).pipe(
    map(([a, b, c]) =>
      uniqWith(
        [...a, ...b, ...c],
        (a, b) => a.inscriptionId === b.inscriptionId,
      ),
    ),
  )
}
const fetchWalletOrdinalInscriptionsImpl = (
  network: BitcoinNetwork,
  query: {
    limit?: number
    offset?: number
    address?: string
    output?: { txId: string; index: number }
  } = {},
): Observable<WalletOrdinalInscription[]> => {
  return fetchHiroOrdinalsAPI<typeof getInscriptionUTXOByAddressMockResp>({
    network,
    path: `/v1/inscriptions`,
    query: {
      order: "desc",
      limit: query.limit ?? 60,
      offset: query.offset,
      address: query.address,
      output:
        query.output == null
          ? undefined
          : `${query.output.txId}:${query.output.index}`,
    },
  }).pipe(
    map(resp =>
      resp.results.flatMap(i => {
        const lastIdx = i.id.lastIndexOf("i")
        if (lastIdx < 0) return []
        const index = Number(i.id.slice(lastIdx + 1))

        return [
          {
            txId: i.genesis_tx_id,
            index: index,
            amount: BigNumber.from(i.value),
            inscriptionId: i.id,
            inscriptionNumber: i.number,
            mimeType: i.mime_type,
            previewUrl: `https://api.hiro.so/ordinals/v1/inscriptions/${i.id}/content`,
            blockHeight: i.genesis_block_height,
          },
        ]
      }),
    ),
  )
}

/**
 * https://www.oklink.com/docs/zh/#explorer-api-brc20-query-token-transfer-list
 */
const transactionListMockResp = {
  page: "1",
  limit: "20",
  totalPage: "1",
  totalTransaction: "20",
  inscriptionsList: [
    {
      txId: "1916d340d6d2de990c4b135bd189fcf30274a6b7f42fece75539015e8eb752f6",
      blockHeight: "788553",
      state: "success",
      tokenType: "BRC20",
      actionType: "mint",
      fromAddress: "",
      toAddress:
        "bc1pvg84hnrpvumw5kf9mj208njs0nks6f5sy9s40zcwvg8g5gx2d8gs5cmvn2",
      amount: "4200",
      token: "doge",
      inscriptionId:
        "1916d340d6d2de990c4b135bd189fcf30274a6b7f42fece75539015e8eb752f6i0",
      inscriptionNumber: "4074429",
      index: "0",
      location:
        "1916d340d6d2de990c4b135bd189fcf30274a6b7f42fece75539015e8eb752f6:0:0",
      msg: "",
      time: "1683402829000",
    },
  ],
}
export const walletBRC20RecentTransferInscriptions = (
  network: BitcoinNetwork,
  blockHeight: Observable<number>,
  walletAddress: string,
): Observable<BRC20WalletRecentTransferables> => {
  return blockHeight.pipe(
    switchMap(() =>
      combineLatest(
        fetchOklink<(typeof transactionListMockResp)[]>({
          network,
          path: "/api/v5/explorer/btc/transaction-list",
          query: {
            address: walletAddress,
            actionType: "inscribeTransfer",
            limit: 100,
          },
        }),
        fetchOklink<(typeof transactionListMockResp)[]>({
          network,
          path: "/api/v5/explorer/btc/transaction-list",
          query: {
            address: walletAddress,
            actionType: "transfer",
            limit: 100,
          },
        }),
      ),
    ),
    map(([inscribeTransferResp, transferResp]) => {
      const transferInscriptions = transferResp.data[0]?.inscriptionsList ?? []
      const earliestTransfer = last(transferInscriptions)

      /**
       * Only return inscriptions created after earliest transfer
       * this way we can ensure that no inscription transfer is mistakenly thought to be unspent because it has been
       * pushed out of the last 100 by other transfers
       */
      const outputInscribeTransfers = (
        inscribeTransferResp.data[0]?.inscriptionsList ?? []
      ).filter(i =>
        i.state === "fail"
          ? false
          : earliestTransfer == null
          ? true
          : Number(i.blockHeight) > Number(earliestTransfer.blockHeight),
      )

      return {
        transferableInscriptions: outputInscribeTransfers.map(
          (item): BRC20WalletRecentTransferableInscription => {
            const spendingTransfer = transferInscriptions.find(
              i => i.inscriptionId === item.inscriptionId,
            )

            return {
              symbol: item.token,
              txId: item.txId,
              index: Number(item.index),
              inscriptionId: item.inscriptionId,
              inscriptionNumber: Number(item.inscriptionNumber),
              amount: BigNumber.from(item.amount),
              transferResult:
                spendingTransfer == null
                  ? null
                  : spendingTransfer.state === "success"
                  ? "success"
                  : "failed",
              toAddress:
                spendingTransfer == null ? null : spendingTransfer.toAddress,
              blockHeight: Number(item.blockHeight),
            }
          },
        ),
      }
    }),
  )
}

export interface InscriptionTransferMempool {
  find: (
    inscriptionInputUTXO: { txId: string; index: number },
    receiverAddress?: string,
  ) => undefined | { txId: string }
}
export const getInscriptionTransferMempool = (
  network: BitcoinNetwork,
  wallet: string,
): Observable<InscriptionTransferMempool> => {
  return mempoolFetch<Tx[]>({
    network,
    path: `/address/${wallet}/txs/mempool`,
  }).pipe(
    map((resp): InscriptionTransferMempool => {
      return {
        find: (inscriptionInputUTXO, receiverAddress) => {
          const tx = resp.find(
            tx =>
              tx.vin.find(
                i =>
                  i.txid === inscriptionInputUTXO.txId &&
                  i.vout === inscriptionInputUTXO.index,
              ) != null,
          )

          if (tx == null) {
            return undefined
          }

          if (receiverAddress != null) {
            const outputToReceiver = tx.vout.find(
              i => i.scriptpubkey_address === receiverAddress,
            )

            if (outputToReceiver == null) {
              return undefined
            }
          }

          return { txId: tx.txid }
        },
      }
    }),
  )
}
