import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "../BaseRoundedFilledButton"
import styles from "./GradientFilledButton.module.scss"

export interface GradientFilledButtonProps extends RoundedButtonVariantProps {
  bgClassName?: string
}

export const GradientFilledButton: FC<GradientFilledButtonProps> = props => {
  return (
    <BaseRoundedFilledButton
      {...props}
      className={clsx("text-gray-100", styles.buttonSvg, props.className)}
      bgClassName={props.bgClassName ?? styles.buttonBg}
    >
      {props.children}
    </BaseRoundedFilledButton>
  )
}
