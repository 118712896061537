import clsx from "clsx"
import { isObject } from "lodash"
import { createContext, CSSProperties, useContext } from "react"
import { ResponsiveStyleValueObject } from "../../utils/domHelpers/mediaQuery"
import { FCC } from "../../utils/reactHelpers/types"
import { useBreakpointsSelector } from "../../utils/reactHelpers/useBreakpointsSelector"

export const defaultHorizontalPadding: ResponsiveStyleValueObject<number> = {
  "": 5 * 4,
  md: 20 * 4,
}

const PaddingHorizontalContext = createContext<
  ResponsiveStyleValueObject<number>
>(defaultHorizontalPadding)

export const LayoutContainer: FCC<{
  className?: string
  sizingClassName?: string
  paddingHorizontal?: ResponsiveStyleValueObject<number>
  paddingTop?: number
  style?: CSSProperties
}> = props => {
  const paddingHorizontal = props.paddingHorizontal ?? defaultHorizontalPadding

  const padding = useBreakpointsSelector(
    ResponsiveStyleValueObject.toMediaQueries(paddingHorizontal),
  )

  return (
    <PaddingHorizontalContext.Provider value={paddingHorizontal}>
      <div
        className={clsx(
          "mx-auto",
          props.sizingClassName ?? "w-full xl:w-[80rem]",
          props.className,
        )}
        style={{
          ...props.style,
          paddingInline: padding,
          paddingTop: props.paddingTop ?? 0,
        }}
      >
        {props.children}
      </div>
    </PaddingHorizontalContext.Provider>
  )
}

export const HorizontalScrollableLayoutContainerInMobile: FCC<{
  className?: string
}> = props => {
  const paddingHorizontal = useContext(PaddingHorizontalContext)

  const childContainerPaddingHorizontal: ResponsiveStyleValueObject<number> =
    isObject(paddingHorizontal)
      ? { "": paddingHorizontal[""], md: 0 }
      : { "": paddingHorizontal, md: 0 }

  const padding = useBreakpointsSelector(
    ResponsiveStyleValueObject.toMediaQueries(childContainerPaddingHorizontal),
  )

  return (
    <div
      className={clsx("overflow-x-auto", props.className)}
      style={{ marginInline: padding == null ? undefined : -padding }}
    >
      <LayoutContainer
        sizingClassName={"w-[fit-content]"}
        paddingHorizontal={childContainerPaddingHorizontal}
      >
        {props.children}
      </LayoutContainer>
    </div>
  )
}
