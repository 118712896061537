import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../../components/button/variants/RedFilledButton"
import { CardPlate, CardTitle } from "../../../../../components/Card"
import {
  CardModalContent,
  DefaultCloseButton,
} from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { SlippageSettingsButton } from "../../../../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../../../../components/Spensor"
import { TokenCount } from "../../../../../components/TokenCount"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../../components/TokenInput/BalanceBottomArea"
import {
  BlockBottomLine,
  renderThinnerBlock,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
  suspenseResource,
} from "../../../../../utils/SuspenseResource"
import { FormError, FormErrorType } from "./types"

export interface SellDepositModalContentProps {
  className?: string

  yieldToken: TokenInfo
  depositToken: TokenInfo

  yieldTokenCount: null | number
  setYieldTokenCount?: (newCount: null | number) => void
  sellableYieldTokenBalance: SuspenseResource<number>
  yieldTokenEstimatedUSD: SuspenseResource<number>

  depositTokenCount: SuspenseResource<number>
  depositTokenEstimatedUSD: SuspenseResource<number>

  liquidityProviderFee: SuspenseResource<TokenCountPairPlain>
  slippage: number

  error?: SuspenseResource<FormError | undefined>
  onSubmit?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
  onClickSlippage?: () => void
  onClose?: () => void
}

export const SellDepositModalContent: FC<
  SellDepositModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      className={clsx(props.className, "flex flex-col gap-4")}
      titleBar={
        <div className={clsx("flex w-full items-center", props.className)}>
          <CardTitle className={"flex-1 text-center"}>
            {$t(
              defineMessage({
                defaultMessage: "Sell",
                description: "/Lend/DepositPage/SellDepositModalContent/title",
              }),
            )}
          </CardTitle>
          <div className={"relative"}>
            <div className="absolute -translate-y-1/2 right-[-5px] flex items-center gap-2">
              {props.onClear && (
                <HeadlessButton
                  className={"text-blue-600"}
                  onClick={props.onClear}
                >
                  {$t(
                    defineMessage({
                      defaultMessage: "Clear",
                      description:
                        "/Lend/DepositPage/SellDepositModalContent/Button text",
                    }),
                  )}
                </HeadlessButton>
              )}
              <SlippageSettingsButton onClick={props.onClickSlippage} />
              {props.onClose && <DefaultCloseButton onClick={props.onClose} />}
            </div>
          </div>
        </div>
      }
    >
      <BlockGroup
        firstBlock={
          <TokenInput
            renderBlock={renderThinnerBlock}
            token={props.yieldToken}
            error={isTokenInputShouldBeErrorState(
              safeReadResource(props.error),
            )}
            value={props.yieldTokenCount}
            onChange={props.setYieldTokenCount}
            bottomArea={
              <BalanceBottomArea
                token={props.yieldToken}
                rightSide={
                  <EstimatedUSD
                    error={isTokenInputShouldBeErrorState(
                      safeReadResource(props.error),
                    )}
                    usdCount={props.yieldTokenEstimatedUSD}
                  />
                }
                balanceLabel={"Active: "}
                balance={props.sellableYieldTokenBalance}
                onPressMax={suspenseResource(() => {
                  const balance = readResource(props.sellableYieldTokenBalance)
                  if (props.yieldTokenCount === balance) return undefined
                  if (!balance) return
                  return () => {
                    props.setYieldTokenCount?.(balance)
                  }
                })}
              />
            }
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            renderBlock={renderThinnerBlock}
            tokens={[
              { token: props.depositToken, count: props.depositTokenCount },
            ]}
            bottomArea={
              <BlockBottomLine className={"flex"}>
                <EstimatedUSD
                  className={"ml-auto"}
                  usdCount={props.depositTokenEstimatedUSD}
                />
              </BlockBottomLine>
            }
          />
        }
      />
      <CardPlate>
        <InfoList listItemDirection={"row-responsive"}>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Liquidity Provider Fee",
                  description:
                    "/Lend/DepositPage/SellDepositModalContent/Info List Item Title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback="--">
                {() => {
                  const fee = readResource(props.liquidityProviderFee)
                  return (
                    <>
                      <TokenCount token={fee.token} count={fee.count} />
                      &nbsp;
                      <TokenName token={fee.token} />
                    </>
                  )
                }}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Slippage Tolerance",
                  description:
                    "/Lend/DepositPage/SellDepositModalContent/Info List Item Title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <PercentNumber number={props.slippage} />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardPlate>
      <Spensor
        fallback={
          <GradientFilledButton>
            <LoadingIndicator className="mx-auto" size={18} />
          </GradientFilledButton>
        }
      >
        {() => {
          const error = readResource(props.error)
          if (error != null) {
            return (
              <Button
                Variant={
                  isErrorStateButtonShouldRenderAsGradientButton(error)
                    ? GradientFilledButton
                    : RedFilledButton
                }
                disabled={true}
              >
                {error.message}
              </Button>
            )
          }
          return (
            <GradientFilledButton onClick={props.onSubmit}>
              {$t(
                defineMessage({
                  defaultMessage: "Sell",
                  description:
                    "/Lend/DepositPage/SellDepositModalContent/Button text",
                }),
              )}
            </GradientFilledButton>
          )
        }}
      </Spensor>
    </CardModalContent>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: FormError,
): boolean => {
  if (!error) return false
  return error.type === FormErrorType.AmountIsEmpty
}

const isTokenInputShouldBeErrorState = (error?: FormError): boolean => {
  if (!error) return false
  return (
    error.type === FormErrorType.InsufficientTokenBalance ||
    error.type === FormErrorType.LessThanMinimizeAmount
  )
}
