import { FC, ReactNode } from "react"
import { IntlFormatters, defineMessage, useIntl } from "react-intl"
import { useAPowerRatio } from "../../../components/APowerTip/APowerTip"
import { MayDollarSignTokenName } from "../../../components/MayDollarSignTokenName"
import { TokenName } from "../../../components/TokenName"
import { readResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { CopywritingProviderValue } from "../components/CopywritingProvider"

export class StakingCopywriting implements CopywritingProviderValue {
  constructor(
    private token: TokenInfo,
    readonly intl: IntlFormatters<ReactNode>,
  ) {
    this.aPowerAlertContent = <APowerAlertContent token={token} />
    this.addStaking = {
      panelTitle: (
        <>
          {this.intl.$t(
            defineMessage({
              defaultMessage: "Add {tokenName} Staking",
              description: "/Staking/CopyWriting panel title",
            }),
            {
              tokenName: <TokenName token={token} />,
            },
          )}
        </>
      ),
      cycleTip: this.cycleTip,
    }
  }

  private cycleTip = this.intl.$t(
    defineMessage({
      defaultMessage:
        "Staking locks up the set amount in the contract for the selected number of reward cycles.",
      description: "/Staking/CopyWriting cycle tip",
    }),
  )

  aPowerAlertContent: ReactNode

  myStaking = {
    panelTitle: this.intl.$t(
      defineMessage({
        defaultMessage: "My Staking",
        description: "/Staking/CopyWriting/panel title",
      }),
    ),
    activeStaking: this.intl.$t(
      defineMessage({
        defaultMessage: "Active staking",
        description: "/Staking/CopyWriting/Active staking",
      }),
    ),
    averageAPRTip: this.intl.$t(
      defineMessage({
        defaultMessage:
          "The average APR is calculated based on your Staking cycles.",
        description: "/Staking/CopyWriting/Average APR tip",
      }),
    ),
    principalClaim: this.intl.$t(
      defineMessage({
        defaultMessage: "Principal to claim",
        description: "/Staking/CopyWriting/Principal claim",
      }),
    ),
    cycleTip: this.cycleTip,
    toBeStaked: {
      finishedStatus: this.intl.$t(
        defineMessage({
          defaultMessage: "Staked",
          description: "/Staking/CopyWriting/Finished status",
        }),
      ),
      currentState: this.intl.$t(
        defineMessage({
          defaultMessage: "Staking",
          description: "/Staking/CopyWriting/Current State",
        }),
      ),
      upcomingStatus: this.intl.$t(
        defineMessage({
          defaultMessage: "To be staked",
          description: "/Staking/CopyWriting/Upcoming status",
        }),
      ),
    },
  }

  addStaking: CopywritingProviderValue["addStaking"]

  principalTokenInfoUpdater = (tokenInfo: TokenInfo): TokenInfo => tokenInfo
}

const APowerAlertContent: FC<{ token: TokenInfo }> = props => {
  const { displayableRatio } = useAPowerRatio()
  const { $t } = useIntl()
  return (
    <>
      {$t(
        defineMessage({
          defaultMessage:
            "The amount of {aPower} (launchpad access) received for {token} staking will be {ratio}x the amount of {token} rewarded.",
          description: "/Stake/APowerAlertContent",
        }),
        {
          aPower: <TokenName token={TokenInfoPresets.APower} />,
          token: <MayDollarSignTokenName token={props.token} />,
          ratio: readResource(displayableRatio),
        },
      )}
    </>
  )
}
