export enum TransferFormErrorType {
  AmountIsEmpty = "AmountIsEmpty",
  TokenNotSelected = "TokenNotSelected",
  InsufficientBalance = "InsufficientBalance",
  NetworkFeeRateIsEmpty = "NetworkFeeRateIsEmpty",
}

export interface TransferFormError {
  type: TransferFormErrorType
}
