import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  ChartPanelFrame,
  ChartPlaceholder,
  Legend,
  StyledInfoList,
} from "../../../../../components/ChartPanel"
import {
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { Spensor } from "../../../../../components/Spensor"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { DepositChart, DepositChartProps, lineStyles } from "./_/DepositChart"

export interface DepositChartPanelPropsChartData {
  totalDepositCurve: DepositChartProps["totalDepositCurve"]
  aprCurve: DepositChartProps["aprCurve"]
}

export interface DepositChartPanelProps {
  className?: string
  depositToken: TokenInfo
  totalDepositTokenCount: SuspenseResource<number>
  apr: SuspenseResource<number>
  chartCurves?: SuspenseResource<DepositChartPanelPropsChartData>
}

export const DepositChartPanel: FC<DepositChartPanelProps> = props => {
  const { $t } = useIntl()
  return (
    <ChartPanelFrame
      className={props.className}
      header={
        <StyledInfoList>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Total Deposit",
                  description:
                    "/Lend/DepositPage/DepositChartPanel/Info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => (
                  <>
                    <TokenCount
                      token={props.depositToken}
                      count={readResource(props.totalDepositTokenCount)}
                    />
                    &nbsp;
                    <TokenName token={props.depositToken} />
                  </>
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "APR",
                  description:
                    "/Lend/DepositPage/DepositChartPanel/Info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <Spensor fallback={"-"}>
                {() => <PercentNumber number={readResource(props.apr)} />}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
        </StyledInfoList>
      }
    >
      <Spensor fallback={<ChartPlaceholder.Loading />}>
        {() =>
          props.chartCurves == null ? (
            <ChartPlaceholder.Unavailable />
          ) : (
            <DepositChart
              depositToken={props.depositToken}
              {...readResource(props.chartCurves)}
            />
          )
        }
      </Spensor>

      <Legend.Container>
        <Legend.Item>
          <Legend.Line strokeColor={lineStyles.totalDepositCurve.color} />
          <Legend.Label>
            {$t(
              defineMessage({
                defaultMessage: "Total Deposit",
                description: "/Lend/DepositPage/DepositChartPanel/Legend label",
              }),
            )}
          </Legend.Label>
        </Legend.Item>
        <Legend.Item>
          <Legend.Line strokeColor={lineStyles.aprCurve.color} />
          <Legend.Label>
            {$t(
              defineMessage({
                defaultMessage: "APR",
                description: "/Lend/DepositPage/DepositChartPanel/Legend label",
              }),
            )}
          </Legend.Label>
        </Legend.Item>
      </Legend.Container>
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "The above chart is based on the (UTC 00:00) snapshot.",
            description: "/Lend/DepositPage/DepositChartPanel/Note",
          }),
        )}
      </NoteParagraph>
    </ChartPanelFrame>
  )
}
