import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { BlockTopTitleLine } from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export interface ConfirmClaimModalContentProps {
  claimToken: TokenInfo
  yieldToken: TokenInfo
  claimTokenCount: number
  yieldTokenCount: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmClaimModalContent: FC<
  ConfirmClaimModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Claim",
          description: "/Lend/DepositPage/ConfirmClaimModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            tokens={[{ token: props.claimToken, count: props.claimTokenCount }]}
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopTitleLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Claim (receive)",
                    description:
                      "/Lend/DepositPage/ConfirmClaimModalContent/Block text",
                  }),
                )}
              </BlockTopTitleLine>
            }
            tokens={[{ token: props.yieldToken, count: props.yieldTokenCount }]}
          />
        }
      />

      <div className={"mt-[50px] flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Lend/ConfirmClaimModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description:
                "/Lend/DepositPage/ConfirmClaimModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
