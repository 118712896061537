import { base64, hex } from "@scure/base"
import * as btc from "@scure/btc-signer"
import {
  concatWith,
  defer,
  ignoreElements,
  interval,
  of,
  shareReplay,
  startWith,
  takeWhile,
} from "rxjs"
import {
  AddressPurposes,
  GetAddressResponse,
  getAddress,
  sendBtcTransaction,
  signTransaction,
} from "sats-connect"
import { BigNumber } from "../../../../../utils/BigNumber"
import { hasAny } from "../../../../../utils/arrayHelpers"
import { CancelError, DisplayableError } from "../../../../../utils/error"
import { BitcoinNetwork } from "../BitcoinClient/BitcoinClient.types"
import {
  WalletAdapter,
  WalletAdapterAddresses,
  WalletAdapterStatic,
} from "./WalletAdapters.types"

const localStorageKey = "alex:xverse-wallet-adapter"

class XverseWalletAdapterImpl implements WalletAdapter {
  static getAdapter: WalletAdapterStatic<XverseWalletAdapterImpl>["getAdapter"] =
    network => {
      return interval(3000).pipe(
        startWith(null),
        // complete once detect it installed
        takeWhile(() => (window as any).BitcoinProvider == null),
        ignoreElements(),

        concatWith(defer(() => of(new XverseWalletAdapterImpl(network)))),

        shareReplay({ bufferSize: 1, refCount: true }),
      )
    }

  private constructor(private network: BitcoinNetwork) {}

  async connect(): Promise<void> {
    await this.getAddresses()
  }

  async disconnect(): Promise<void> {
    localStorage.removeItem(localStorageKey)
    return Promise.resolve()
  }

  async getAddresses(): Promise<WalletAdapterAddresses> {
    let resp: GetAddressResponse | undefined

    const stored = localStorage.getItem(localStorageKey) || undefined
    if (stored != null) {
      try {
        resp = JSON.parse(stored)
        if (
          resp == null ||
          !("addresses" in resp) ||
          !Array.isArray(resp.addresses)
        ) {
          throw new Error("Invalid stored addresses")
        }
      } catch {
        localStorage.removeItem(localStorageKey)
      }
    }
    if (resp == null) {
      resp = await this.getAddressesImpl()
      localStorage.setItem(localStorageKey, JSON.stringify(resp))
    }

    const ordinals: WalletAdapterAddresses["ordinals"][number][] =
      resp.addresses
        .filter(a => a.purpose === AddressPurposes.ORDINALS)
        .map(a => ({
          ...a,
          tapInternalKey: a.publicKey,
        }))
    const bitcoin: WalletAdapterAddresses["bitcoin"][number][] = resp.addresses
      .filter(a => a.purpose === AddressPurposes.PAYMENT)
      .map(a => ({
        ...a,
        redeemScript: hex.encode(
          btc.p2sh(btc.p2wpkh(hex.decode(a.publicKey))).redeemScript!,
        ),
      }))

    if (!hasAny(ordinals) || !hasAny(bitcoin)) {
      if (stored != null) {
        localStorage.removeItem(localStorageKey)
        return this.getAddresses()
      } else {
        throw new DisplayableError("Request ordinals addresses failed")
      }
    }

    return { bitcoin, ordinals }
  }
  private async getAddressesImpl(): Promise<GetAddressResponse> {
    return new Promise<GetAddressResponse>((resolve, reject) => {
      void getAddress({
        payload: {
          purposes: [AddressPurposes.ORDINALS, AddressPurposes.PAYMENT],
          message: "Address for receiving Ordinals and payments",
          network: {
            type: this.network === "mainnet" ? "Mainnet" : "Testnet",
          },
        },
        onFinish(resp) {
          resolve(resp)
        },
        onCancel() {
          reject(new CancelError())
        },
      }).catch(reject)
    })
  }

  async sendBitcoin(
    receiverAddress: string,
    satoshiAmount: BigNumber,
  ): Promise<{ txId: string }> {
    const senderAddress = (await this.getAddresses()).bitcoin[0].address

    return new Promise((resolve, reject) => {
      void sendBtcTransaction({
        payload: {
          network: {
            type: this.network === "mainnet" ? "Mainnet" : "Testnet",
          },
          message: "Send Bitcoin",
          recipients: [
            {
              address: receiverAddress,
              amountSats: BigNumber.toBigInt(satoshiAmount),
            },
          ],
          senderAddress,
        },
        onFinish(resp) {
          return resolve({ txId: resp })
        },
        onCancel() {
          reject(new CancelError())
        },
      }).catch(reject)
    })
  }

  async signAndFinalizePsbt(
    psbtHex: string,
    ordinalWalletSignIndices: number[],
    bitcoinWalletSignIndices: number[],
  ): Promise<{
    signedPsbtHex: string
  }> {
    const addrs = await this.getAddresses()

    const psbtBase64 = base64.encode(hex.decode(psbtHex))

    const signedPsbt = await new Promise<Uint8Array>((resolve, reject) => {
      void signTransaction({
        payload: {
          network: {
            type: this.network === "mainnet" ? "Mainnet" : "Testnet",
          },
          message: "Sign transaction",
          psbtBase64,
          inputsToSign: [
            {
              address: addrs.ordinals[0].address,
              signingIndexes: ordinalWalletSignIndices,
            },
            {
              address: addrs.bitcoin[0].address,
              signingIndexes: bitcoinWalletSignIndices,
            },
          ],
          broadcast: false,
        },
        onFinish(resp) {
          resolve(base64.decode(resp.psbtBase64))
        },
        onCancel() {
          reject(new CancelError())
        },
      }).catch(reject)
    })

    const tx = btc.Transaction.fromPSBT(signedPsbt)
    tx.finalize()

    return { signedPsbtHex: hex.encode(tx.toPSBT()) }
  }
}

export const XverseWalletAdapter: WalletAdapterStatic<XverseWalletAdapterImpl> =
  XverseWalletAdapterImpl
