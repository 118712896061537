import { firstValueFrom, Observable, shareReplay } from "rxjs"
import { scanWithObservableSeed } from "../../../../../utils/rxjsHelpers/scanWithObservableSeed"
import { WalletAdapter } from "../WalletAdapters/WalletAdapters.types"
import {
  bitcoinNetworkBasicInfo,
  broadcastSignedTransaction,
  fetchBitcoinNetworkBasicInfo,
} from "./bitcoin.service"
import { BitcoinNetwork, BitcoinNetworkBasicInfo } from "./BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "./BitcoinClientBRC20Wallet"

export class BitcoinClient {
  constructor(readonly network: BitcoinNetwork) {}

  networkInfo: Observable<BitcoinNetworkBasicInfo> = bitcoinNetworkBasicInfo(
    this.network,
  ).pipe(
    scanWithObservableSeed(
      (acc, info) => ({ ...acc, ...info }),
      fetchBitcoinNetworkBasicInfo(this.network),
      { emitSeed: true },
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  async createBRC20Wallet(
    wallet: WalletAdapter,
  ): Promise<BitcoinClientBRC20Wallet> {
    return new BitcoinClientBRC20Wallet(this, wallet)
  }

  explorerLink(txId: string): string {
    return `https://mempool.space/${
      this.network === "testnet" ? "testnet/" : ""
    }tx/${txId}`
  }

  async broadcastSignedTransaction(txHex: string): Promise<{ txId: string }> {
    return firstValueFrom(broadcastSignedTransaction(this.network, txHex))
  }
}
