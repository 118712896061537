import { hex } from "@scure/base"
import * as btc from "@scure/btc-signer"
import {
  concatWith,
  defer,
  ignoreElements,
  interval,
  of,
  shareReplay,
  startWith,
  takeWhile,
} from "rxjs"
import { BigNumber } from "../../../../../utils/BigNumber"
import { getTapInternalKeyOfP2TRPublicKey } from "../../../../../utils/bitcoinHelpers"
import { BitcoinNetwork } from "../BitcoinClient/BitcoinClient.types"
import {
  WalletAdapter,
  WalletAdapterAddresses,
  WalletAdapterStatic,
} from "./WalletAdapters.types"

class OkxWalletAdapterImpl implements WalletAdapter {
  static getAdapter: WalletAdapterStatic<OkxWalletAdapterImpl>["getAdapter"] =
    network => {
      return interval(3000).pipe(
        startWith(null),
        // complete once detect it installed
        takeWhile(() => (window as any).okxwallet?.bitcoin == null),
        ignoreElements(),

        concatWith(
          defer(() =>
            of(
              new OkxWalletAdapterImpl(
                network,
                (window as any).okxwallet?.bitcoin,
              ),
            ),
          ),
        ),

        shareReplay({ bufferSize: 1, refCount: true }),
      )
    }

  private constructor(
    private network: BitcoinNetwork,
    private okxwallet: any,
  ) {}

  async connect(): Promise<void> {
    // if not authorized, okx wallet will pop up a window to ask user to authorize
    // if authorized, okx wallet will do nothing
    await this.okxwallet.connect()
  }

  disconnect(): Promise<void> {
    // do nothing
    return Promise.resolve()
  }

  async getAddresses(): Promise<WalletAdapterAddresses> {
    /**
     * https://www.okx.com/web3/build/docs/extension/injected-providers#connect-to-wallet
     */
    const address: {
      address: string
      publicKey: string
    } = await this.okxwallet.connect()

    return {
      ordinals: [
        {
          address: address.address,
          tapInternalKey: hex.encode(
            getTapInternalKeyOfP2TRPublicKey(
              this.network,
              hex.decode(address.publicKey),
            ),
          ),
        },
      ],
      bitcoin: [address],
    }
  }

  async sendBitcoin(
    receiverAddress: string,
    satoshiAmount: BigNumber,
    options?: { feeRate?: number },
  ): Promise<{ txId: string }> {
    /**
     * https://www.okx.com/web3/build/docs/extension/injected-providers#send
     */
    const { bitcoin } = await this.getAddresses()
    const txId = await this.okxwallet.send({
      from: bitcoin[0],
      to: receiverAddress,
      value: BigNumber.toString(
        BigNumber.moveDecimals({ distance: 8 }, satoshiAmount),
      ),
      satBytes:
        options?.feeRate == null
          ? undefined
          : BigNumber.toString(options.feeRate),
    })
    return { txId }
  }

  async sendInscription(
    receiverAddress: string,
    inscriptionId: string,
  ): Promise<{ txId: string }> {
    /**
     * https://www.okx.com/cn/web3/build/docs/extension/injected-providers#%E8%BD%AC%E7%A7%BB-nft
     */
    const { bitcoin } = await this.getAddresses()
    const { txhash } = await this.okxwallet.transferNft({
      from: bitcoin[0],
      to: receiverAddress,
      data: inscriptionId,
      type: 55,
    })
    return { txId: txhash }
  }

  async signAndFinalizePsbt(
    txHex: string,
    ordinalWalletSignIndices: number[],
    bitcoinWalletSignIndices: number[],
  ): Promise<{ signedPsbtHex: string }> {
    const { bitcoin } = await this.getAddresses()

    const signedPsbtHex = await this.okxwallet.signPsbt(txHex, {
      from: bitcoin[0].address,
    })

    const tx = btc.Transaction.fromPSBT(hex.decode(signedPsbtHex))
    tx.finalize()

    return { signedPsbtHex }
  }
}

export const OkxWalletAdapter: WalletAdapterStatic<OkxWalletAdapterImpl> =
  OkxWalletAdapterImpl
