import {
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
} from "mobx"

export class MemoValue<T> {
  @computed get previousValue(): T | undefined {
    return this.history[this.history.length - 2]
  }

  @observable.shallow private history: T[] = []

  private stopReaction: IReactionDisposer | undefined

  constructor(private getValue: () => T) {
    makeObservable(this)

    onBecomeObserved(this, "previousValue", () => {
      try {
        this.history.push(this.getValue())
      } catch {}

      this.stopReaction = reaction(
        () => this.getValue(),
        value => {
          this.history.push(value)
          this.history = this.history.slice(-2)
        },
      )
    })

    onBecomeUnobserved(this, "previousValue", () => {
      this.stopReaction?.()
      this.history.length = 0
    })
  }
}
