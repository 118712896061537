import clsx from "clsx"
import { defineMessage, useIntl } from "react-intl"
import { FCC } from "../../utils/reactHelpers/types"
import { ReactComponent as EmptyIcon } from "./_/EmptyStateIcon.svg"

export const EmptyState: FCC<{
  className?: string
  tint?: string
}> = props => {
  const { $t } = useIntl()

  const { tint = "#4B5563" } = props

  return (
    <div
      className={clsx(
        "flex flex-col gap-y-4 items-center justify-center",
        props.className,
      )}
    >
      <EmptyIcon fill={tint} />

      <span className="text-base leading-6 font-normal" style={{ color: tint }}>
        {props.children ??
          $t(
            defineMessage({
              defaultMessage: "No data",
              description: "/Components/EmptyState/No data text",
            }),
          )}
      </span>
    </div>
  )
}
