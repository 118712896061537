import clsx from "clsx"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { ReactComponent as WarningIcon } from "./warning.svg"

export const WarningSection: FCC<{
  className?: string
  textClassName?: string
  inContainer?: boolean
}> = props => {
  const { inContainer = true } = props

  return (
    <div
      className={clsx(
        "flex space-x-2.5 ",
        inContainer && "bg-white/5 rounded-lg p-2.5",
        props.className,
      )}
    >
      <WarningIcon className="flex-none mt-1" />
      <div className="space-y-4">{props.children}</div>
    </div>
  )
}
