import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { AllTokenInfos, CurrencyAmount } from "../../types"
import { OneLineTokenCount } from "./OneLineTokenCount"
import { OneLineTokenName } from "./OneLineTokenName"

export interface ExchangeRatioProps {
  fromCurrencyAmount: CurrencyAmount.Confirmed
  toCurrencyAmount: CurrencyAmount.Confirmed
  allTokenInfos: AllTokenInfos
}

export const ExchangeRatio: FC<ExchangeRatioProps> = props => {
  const ratio = props.toCurrencyAmount.amount / props.fromCurrencyAmount.amount
  const { $t } = useIntl()
  return (
    <>
      <span className="whitespace-nowrap">
        <OneLineTokenCount
          currency={props.toCurrencyAmount.currency}
          count={ratio}
          allTokenInfos={props.allTokenInfos}
        />
        &nbsp;
        <OneLineTokenName
          currency={props.toCurrencyAmount.currency}
          allTokenInfos={props.allTokenInfos}
        />
        &nbsp;
      </span>
      <span className="whitespace-nowrap">
        {$t(
          defineMessage({
            defaultMessage: "per {tokenName}",
            description: "/Notify/Exchange ratio text",
          }),
          {
            tokenName: (
              <OneLineTokenName
                currency={props.fromCurrencyAmount.currency}
                allTokenInfos={props.allTokenInfos}
              />
            ),
          },
        )}
      </span>
    </>
  )
}
