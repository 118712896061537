import clsx from "clsx"
import { FC } from "react"
import { Card } from "../Card"
import { AlertCommonProps } from "./types"

export interface WhiteGradientAlertProps extends AlertCommonProps {
  withInteractionEffect?: boolean
}

export const WhiteGradientAlert: FC<WhiteGradientAlertProps> = props => (
  <Card
    className={clsx(
      "flex flex-row",
      !props.withInteractionEffect &&
        "hover:bg-black/10 hover:!bg-none hover:!backdrop-filter-none hover:!drop-shadow-none active:bg-black/20 active:!bg-none active:!backdrop-filter-none active:!drop-shadow-none",
      props.className,
    )}
    boxClassName={"px-4 py-2"}
    roundClassName={"rounded-lg"}
  >
    {props.children}
  </Card>
)
