import {
  AMMSwapRoute,
  resolveAmmRoute,
} from "../../pages/Spot/store/ammRouteResolver"
import { bridgeHelperResolver } from "../../pages/Spot/store/bridgeHelperResolver"
import { assertNever } from "../types"
import { Currency } from "./Currency"
import {
  SwappableCurrency,
  getAssetIdentifierByCurrency,
} from "./currencyHelpers"

export namespace AMMSwapPool {
  export type SwapTokens = SwappableCurrency

  export const getRoute = (
    tokenX: SwappableCurrency,
    tokenY: SwappableCurrency,
    ammPools: PoolTokens[],
  ): AMMSwapRoute[] =>
    resolveAmmRoute(
      tokenX,
      tokenY,
      // AMM_SWAP_POOL_WXUSD_WUSDA is deprecated now, don't use it if we have AMM_SWAP_POOL_WXUSD_WUSDA_2 is available
      ammPools.includes(Currency.AMM_SWAP_POOL_WXUSD_WUSDA_2)
        ? ammPools.filter(a => a !== Currency.AMM_SWAP_POOL_WXUSD_WUSDA)
        : ammPools,
    )

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  export const reachableInAMM: typeof bridgeHelperResolver = (...args) =>
    bridgeHelperResolver(...args)

  export const ammV1Tokens = [
    Currency.AMM_SWAP_POOL_WXUSD_WUSDA,
    Currency.AMM_SWAP_POOL_WXUSD_WUSDA_2,
    Currency.AMM_SWAP_POOL_WSTX_ALEX,
    Currency.AMM_SWAP_POOL_WSTX_XBTC,
    Currency.AMM_SWAP_POOL_ALEX_WBAN,
    Currency.AMM_SWAP_POOL_ALEX_WUSDA,
    Currency.AMM_SWAP_POOL_ALEX_WDIKO,
    Currency.AMM_SWAP_POOL_WSTX_WCORGI,
    Currency.AMM_SWAP_POOL_WSTX_SUSDT,
  ] as const

  export const ammV1_1Tokens = [
    Currency.AMM_SWAP_POOL_V1_1_WSTX_SUSDT,
    Currency.AMM_SWAP_POOL_V1_1_WSTX_XBTC,
    Currency.AMM_SWAP_POOL_V1_1_WSTX_ALEX,
    Currency.AMM_SWAP_POOL_V1_1_ALEX_DIKO,
    Currency.AMM_SWAP_POOL_V1_1_ALEX_ATALEXV2,
    Currency.AMM_SWAP_POOL_V1_1_WSTX_WVIBES,
    Currency.AMM_SWAP_POOL_V1_1_ALEX_BRC20DB20,
    Currency.AMM_SWAP_POOL_V1_1_SUSDT_XUSD,
  ] as const

  export const ammTokens = [...ammV1Tokens, ...ammV1_1Tokens] as const

  export type PoolTokens = (typeof ammTokens)[number]

  export type PoolV1_1Tokens = (typeof ammV1_1Tokens)[number]

  export const isPoolToken = (token: string): token is PoolTokens =>
    ammTokens.includes(token as PoolTokens)

  export const isV1PoolToken = (token: string): token is PoolTokens =>
    ammTokens.includes(token as PoolTokens) &&
    !ammV1_1Tokens.includes(token as PoolV1_1Tokens)

  export const isV1_1PoolToken = (token: string): token is PoolV1_1Tokens =>
    ammV1_1Tokens.includes(token as PoolV1_1Tokens)

  export const fromXYV1 = (
    x: Currency,
    y: Currency,
    factor: number,
  ): PoolTokens => {
    const match = ammV1Tokens.find(a => {
      const [tx, ty] = breakDown(a)
      return tx === x && ty === y && getFactor(a) === factor
    })
    if (match) {
      return match
    }
    throw new Error(`Invalid AMM token: ${x} ${y}`)
  }

  export const fromXYV1_1 = (
    x: Currency,
    y: Currency,
    factor: number,
  ): PoolTokens => {
    const match = ammV1_1Tokens.find(a => {
      const [tx, ty] = breakDown(a)
      return tx === x && ty === y && getFactor(a) === factor
    })
    if (match) {
      return match
    }
    throw new Error(`Invalid AMM token: ${x} ${y}`)
  }

  export function breakDown(
    poolToken: AMMSwapPool.PoolTokens,
  ): [
    Currency.W_STX | Currency.ALEX | Currency.W_XUSD | Currency.sUSDT,
    AMMSwapPool.SwapTokens,
  ] {
    switch (poolToken) {
      case Currency.AMM_SWAP_POOL_WXUSD_WUSDA:
      case Currency.AMM_SWAP_POOL_WXUSD_WUSDA_2:
        return [Currency.W_XUSD, Currency.W_USDA]
      case Currency.AMM_SWAP_POOL_WSTX_ALEX:
        return [Currency.W_STX, Currency.ALEX]
      case Currency.AMM_SWAP_POOL_WSTX_XBTC:
        return [Currency.W_STX, Currency.W_XBTC]
      case Currency.AMM_SWAP_POOL_ALEX_WBAN:
        return [Currency.ALEX, Currency.W_BANANA]
      case Currency.AMM_SWAP_POOL_ALEX_WUSDA:
        return [Currency.ALEX, Currency.W_USDA]
      case Currency.AMM_SWAP_POOL_ALEX_WDIKO:
        return [Currency.ALEX, Currency.W_DIKO]
      case Currency.AMM_SWAP_POOL_WSTX_WCORGI:
        return [Currency.W_STX, Currency.W_CORGI]
      case Currency.AMM_SWAP_POOL_WSTX_SUSDT:
        return [Currency.W_STX, Currency.sUSDT]
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_XBTC:
        return [Currency.W_STX, Currency.W_XBTC]
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_ALEX:
        return [Currency.W_STX, Currency.ALEX]
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_SUSDT:
        return [Currency.W_STX, Currency.sUSDT]
      case Currency.AMM_SWAP_POOL_V1_1_ALEX_DIKO:
        return [Currency.ALEX, Currency.W_DIKO]
      case Currency.AMM_SWAP_POOL_V1_1_ALEX_ATALEXV2:
        return [Currency.ALEX, Currency.ATALEXV2]
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_WVIBES:
        return [Currency.W_STX, Currency.W_VIBES]
      case Currency.AMM_SWAP_POOL_V1_1_ALEX_BRC20DB20:
        return [Currency.ALEX, Currency.BRC20_DB20]
      case Currency.AMM_SWAP_POOL_V1_1_SUSDT_XUSD:
        return [Currency.sUSDT, Currency.W_XUSD]
      default:
        assertNever(poolToken)
    }
  }

  export function getFactor(poolToken: PoolTokens): number {
    switch (poolToken) {
      case Currency.AMM_SWAP_POOL_WXUSD_WUSDA:
        return 0.0001e8
      case Currency.AMM_SWAP_POOL_WXUSD_WUSDA_2:
        return 0.005e8
      case Currency.AMM_SWAP_POOL_WSTX_ALEX:
      case Currency.AMM_SWAP_POOL_WSTX_XBTC:
      case Currency.AMM_SWAP_POOL_ALEX_WBAN:
      case Currency.AMM_SWAP_POOL_ALEX_WUSDA:
      case Currency.AMM_SWAP_POOL_ALEX_WDIKO:
      case Currency.AMM_SWAP_POOL_WSTX_WCORGI:
      case Currency.AMM_SWAP_POOL_WSTX_SUSDT:
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_SUSDT:
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_ALEX:
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_XBTC:
      case Currency.AMM_SWAP_POOL_V1_1_ALEX_DIKO:
      case Currency.AMM_SWAP_POOL_V1_1_ALEX_ATALEXV2:
      case Currency.AMM_SWAP_POOL_V1_1_WSTX_WVIBES:
      case Currency.AMM_SWAP_POOL_V1_1_ALEX_BRC20DB20:
        return 1e8
      case Currency.AMM_SWAP_POOL_V1_1_SUSDT_XUSD:
        return 0.05e8
      default:
        assertNever(poolToken)
    }
  }

  export const getAssetIdentifier = (poolToken: PoolTokens): string =>
    getAssetIdentifierByCurrency(poolToken).split("/")[0]!
}
