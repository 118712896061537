import clsx from "clsx"
import { FC } from "react"

export const BaseCellContainer: FC<{
  className?: string
  children?: React.ReactNode
}> = props => {
  return (
    <td className="align-top py-4">
      <div className={clsx("p-2 text-sm text-gray-200", props.className)}>
        {props.children}
      </div>
    </td>
  )
}
