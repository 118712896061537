import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { ButtonVariantProps } from "../../../../../components/button/ButtonVariant"
import { SmartLoadableButton } from "../../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { BigNumber } from "../../../../../utils/BigNumber"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { checkNever } from "../../../../../utils/types"
import { JumpOutNavLink } from "../JumpOutNavLink/JumpOutNavLink"
import { BRC20Card, CardStrongText } from "./BRC20Card"
import { BRC20InscriptionCard } from "./BRC20InscriptionCard"

export type BRC20InscriptionStatus =
  | { type: "inscribing"; explorerUrl: string }
  | { type: "transferable"; isSelected: boolean; onToggleSelection: () => void }
  | { type: "sending"; explorerUrl: string }
  | { type: "sent"; explorerUrl: string }
  | { type: "send-failed"; explorerUrl: string }

export interface BRC20InscriptionStatusCardProps {
  className?: string
  token: TokenInfo
  tokenCount: BigNumber
  inscriptionNumber: number
  status: BRC20InscriptionStatus
}

export const BRC20InscriptionStatusCard: FC<
  BRC20InscriptionStatusCardProps
> = props => {
  const { $t } = useIntl()

  let headContent: ReactNode = null
  let tailContent: ReactNode = null

  if (props.status.type === "inscribing") {
    headContent = (
      <CardStrongText>
        {$t(
          defineMessage({
            defaultMessage: "Transfer",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/state",
          }),
        )}
      </CardStrongText>
    )
    tailContent = (
      <JumpOutNavLink
        to={props.status.explorerUrl}
        linkClassName={"text-blue-500"}
      >
        {$t(
          defineMessage({
            defaultMessage: "Waiting for confirm...",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/link text",
          }),
        )}
      </JumpOutNavLink>
    )
  } else if (props.status.type === "transferable") {
    headContent = (
      <CardStrongText
        className={"flex flex-row items-center"}
        style={{ color: "#84CC16" }}
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.88462 13.7692L1.5 8.06262L3.00662 6.52315L6.85608 10.5471L13.9336 3L15.5 4.51092L6.88462 13.7692Z" />
        </svg>
        &nbsp;
        {$t(
          defineMessage({
            defaultMessage: "Transferable",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/state",
          }),
        )}
      </CardStrongText>
    )

    tailContent = (
      <SmartLoadableButton
        Variant={ActionButton}
        onClick={props.status.onToggleSelection}
      >
        {$t(
          props.status.isSelected
            ? defineMessage({
                defaultMessage: "Unselect",
                description:
                  "Admin Bulk Send Inscription Page/Inscription Card/button text",
              })
            : defineMessage({
                defaultMessage: "Select",
                description:
                  "Admin Bulk Send Inscription Page/Inscription Card/button text",
              }),
        )}
      </SmartLoadableButton>
    )
  } else if (props.status.type === "sending") {
    headContent = (
      <CardStrongText
        className={"flex flex-row items-center"}
        style={{ color: "#84CC16" }}
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.88462 13.7692L1.5 8.06262L3.00662 6.52315L6.85608 10.5471L13.9336 3L15.5 4.51092L6.88462 13.7692Z" />
        </svg>
        &nbsp;
        {$t(
          defineMessage({
            defaultMessage: "Sending",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/state",
          }),
        )}
      </CardStrongText>
    )
    tailContent = (
      <JumpOutNavLink
        to={props.status.explorerUrl}
        linkClassName={"text-blue-500"}
      >
        {$t(
          defineMessage({
            defaultMessage: "Sending",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/link text",
          }),
        )}
      </JumpOutNavLink>
    )
  } else if (props.status.type === "sent") {
    headContent = (
      <CardStrongText
        className={"flex flex-row items-center"}
        style={{ color: "#84CC16" }}
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.88462 13.7692L1.5 8.06262L3.00662 6.52315L6.85608 10.5471L13.9336 3L15.5 4.51092L6.88462 13.7692Z" />
        </svg>
        &nbsp;
        {$t(
          defineMessage({
            defaultMessage: "Sent",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/state",
          }),
        )}
      </CardStrongText>
    )
    tailContent = (
      <JumpOutNavLink
        to={props.status.explorerUrl}
        linkClassName={"text-blue-500"}
      >
        {$t(
          defineMessage({
            defaultMessage: "View in Explorer",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/link text",
          }),
        )}
      </JumpOutNavLink>
    )
  } else if (props.status.type === "send-failed") {
    headContent = (
      <CardStrongText
        className={"flex flex-row items-center"}
        style={{ color: "#EF4444" }}
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.373 7.75405L15.2919 2.7973C15.5189 2.57027 15.5189 2.22973 15.2919 2.0027L14.5351 1.20811C14.3081 0.981081 13.9676 0.981081 13.7405 1.20811L8.78378 6.16486C8.63243 6.31622 8.40541 6.31622 8.25405 6.16486L3.2973 1.17027C3.07027 0.943243 2.72973 0.943243 2.5027 1.17027L1.70811 1.96486C1.48108 2.19189 1.48108 2.53243 1.70811 2.75946L6.66487 7.71622C6.81622 7.86757 6.81622 8.09459 6.66487 8.24594L1.67027 13.2405C1.44324 13.4676 1.44324 13.8081 1.67027 14.0351L2.46486 14.8297C2.69189 15.0568 3.03243 15.0568 3.25946 14.8297L8.21622 9.87297C8.36757 9.72162 8.59459 9.72162 8.74595 9.87297L13.7027 14.8297C13.9297 15.0568 14.2703 15.0568 14.4973 14.8297L15.2919 14.0351C15.5189 13.8081 15.5189 13.4676 15.2919 13.2405L10.373 8.28378C10.2216 8.13243 10.2216 7.9054 10.373 7.75405Z" />
        </svg>
        &nbsp;
        {$t(
          defineMessage({
            defaultMessage: "Transfer Failed",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/state",
          }),
        )}
      </CardStrongText>
    )
    tailContent = (
      <JumpOutNavLink
        to={props.status.explorerUrl}
        linkClassName={"text-blue-500"}
      >
        {$t(
          defineMessage({
            defaultMessage: "View in Explorer",
            description:
              "Admin Bulk Send Inscription Page/Inscription Card/link text",
          }),
        )}
      </JumpOutNavLink>
    )
  } else {
    checkNever(props.status)
  }

  return (
    <BRC20InscriptionCard
      className={props.className}
      token={props.token}
      tokenCount={props.tokenCount}
      inscriptionNumber={props.inscriptionNumber}
      renderBRC20Card={p => (
        <BRC20Card
          {...p}
          className={clsx(
            p.className,
            "border-[3px]",
            props.status.type === "transferable" && props.status.isSelected
              ? "border-blue-800"
              : "border-transparent",
          )}
        />
      )}
      topArea={headContent}
      bottomArea={tailContent ?? <div />}
    />
  )
}

const ActionButton: FC<ButtonVariantProps> = props => {
  return (
    <GradientFilledButton
      {...props}
      roundedClassName={"rounded"}
      textClassName={"text-xs leading-4 font-medium"}
      boxClassName={"px-4 py-2"}
    />
  )
}
