import clsx from "clsx"
import { FC } from "react"
import { GoldenBox } from "../../GoldenBox"
import { ButtonVariantProps } from "../ButtonVariant"
import { BaseRoundedFilledButton } from "./BaseRoundedFilledButton"

export const GoldenFilledButton: FC<ButtonVariantProps> = props => (
  <GoldenBox
    renderContainer={goldenBoxProps => (
      <BaseRoundedFilledButton
        {...props}
        className={clsx(
          "text-gray-100",
          goldenBoxProps.className,
          props.className,
        )}
        bgClassName={
          "opacity-80 hover:opacity-100 active:opacity-50 disabled:opacity-50"
        }
        style={goldenBoxProps.style}
      >
        {goldenBoxProps.children}
      </BaseRoundedFilledButton>
    )}
  >
    {props.children}
  </GoldenBox>
)
