import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardInset, CardNote } from "../../../../../components/Card"
import {
  IconTokenCount,
  TextTokenCount,
} from "../../../../../components/RichTokenCount"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import {
  PageClockCountdown,
  PageClockCountdownTitleFrame,
} from "../../PageClockCountdown"
import buyButtonBgSrc from "./_/buyButtonBg.svg"
import bgSrc from "./_/lightBg.svg"

export const ActionPanel$NotJoin: FC<{
  prizeToken: TokenInfo
  prizeTokenTotalCount: number
  prizeTokenCountFromAlex: number
  currentBlock: number
  drawStartedAtBlock: number
  drawStartedAt: Date
  actions:
    | {
        onBuyLottery: () => void
        onConnectWallet?: undefined
      }
    | {
        onBuyLottery?: undefined
        onConnectWallet: () => void
      }
}> = props => {
  const { $t } = useIntl()

  return (
    <CardInset
      className={clsx(
        "flex flex-col items-center justify-center",
        "md:aspect-square",
        "bg-no-repeat bg-center bg-cover",
        "text-center",
      )}
      backgroundImageUrl={bgSrc}
    >
      <div className={"max-w-full flex flex-col gap-2.5 items-center"}>
        <p className={"text-3xl text-gray-200"}>Prize Pot</p>
        <IconTokenCount
          className={
            "text-4xl font-medium text-yellow-200 flex-wrap justify-center"
          }
          iconSize={40}
          token={props.prizeToken}
          count={props.prizeTokenTotalCount}
        />
        <CardNote className={"break-words"}>
          {$t(
            defineMessage({
              defaultMessage: "( {tokenCount} from ALEX Lab Foundation )",
              description: "/Lottery/ActionPanel$Joined/Card note",
            }),
            {
              tokenCount: (
                <TextTokenCount
                  token={props.prizeToken}
                  count={props.prizeTokenCountFromAlex}
                />
              ),
            },
          )}
        </CardNote>
      </div>
      <PageClockCountdownTitleFrame
        className={"mt-[28px] mb-[40px]"}
        title={
          <span className={"text-white capitalize"}>
            {$t(
              defineMessage({
                defaultMessage: "Time Until Draw",
                description: "/Lottery/ActionPanel$Joined/Countdown title",
              }),
            )}
          </span>
        }
      >
        <PageClockCountdown
          currentBlock={props.currentBlock}
          targetBlock={props.drawStartedAtBlock}
          time={props.drawStartedAt}
        />
      </PageClockCountdownTitleFrame>
      <div
        className={clsx(
          "px-[22px] py-[26px]",
          "w-full max-w-[340px]",
          "bg-no-repeat bg-center bg-contain",
        )}
        style={{
          backgroundImage: `url(${buyButtonBgSrc})`,
        }}
      >
        {props.actions.onBuyLottery ? (
          <ActionButton onClick={props.actions.onBuyLottery}>
            {$t(
              defineMessage({
                defaultMessage: "Buy Lottery",
                description: "/Lottery/ActionPanel$Joined/Action button",
              }),
            )}
          </ActionButton>
        ) : (
          <ActionButton onClick={props.actions.onConnectWallet}>
            {$t(
              defineMessage({
                defaultMessage: "Connect Wallet to Buy Lottery",
                description: "/Lottery/ActionPanel$Joined/Action button",
              }),
            )}
          </ActionButton>
        )}
      </div>
    </CardInset>
  )
}

const ActionButton: FCC<{
  className?: string
  onClick: () => void
}> = props => (
  <GradientFilledButton
    className={"w-full"}
    boxClassName={"p-[10px]"}
    textClassName={"text-lg font-semibold text-gray-200"}
    onClick={props.onClick}
  >
    {props.children}
  </GradientFilledButton>
)
