import clsx from "clsx"
import { cardInsetBgClassName } from "../../../../components/Card"

export interface ComboboxSelectorProps<T> {
  availableValues: { label: string; value: T; isRecommend?: boolean }[]
  value: T[]
  onSelect?: (item: T) => void
}

export function ComboboxSelector<T>(
  props: ComboboxSelectorProps<T>,
): JSX.Element {
  return (
    <ul
      className={
        "flex gap-1.5 sm:gap-2 h-[64px] mb-[24px] text-lg leading-7 font-normal text-white"
      }
    >
      {props.availableValues.map((s, idx) => (
        <li
          key={idx}
          className={clsx(
            cardInsetBgClassName,
            "flex-1 rounded-lg",
            "flex items-center justify-center",
            "cursor-pointer",
            "border border-2",
            props.value.includes(s.value)
              ? "border-blue-600"
              : "border-transparent hover:border-gray-300",
            "relative",
          )}
          onClick={() => {
            props.onSelect?.(s.value)
          }}
        >
          {s.isRecommend && (
            <span
              className={"absolute top-[-4px] w-[64px] sm:w-[80px] h-[42px]"}
            >
              <img
                className={"w-full h-full"}
                alt={"Suggested"}
                src={
                  /* eslint-disable-next-line @typescript-eslint/no-var-requires */
                  require("./suggested.svg").default
                }
              />
            </span>
          )}
          {s.label}
        </li>
      ))}
    </ul>
  )
}
