import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "./BaseRoundedFilledButton"

export const RedBorderButton: FC<RoundedButtonVariantProps> = props => {
  return (
    <BaseRoundedFilledButton
      textClassName={"text-gray-200 hover:text-white active:text-white"}
      {...props}
      className={clsx(
        "border-2 rounded-full border-red-700 hover:border-white active:border-gray-400 disabled:border-gray-400",
        props.className,
      )}
    >
      {props.children}
    </BaseRoundedFilledButton>
  )
}
