import clsx from "clsx"
import type { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { ButtonVariantProps } from "../../../../../components/button/ButtonVariant"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { BigNumber } from "../../../../../utils/BigNumber"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { assertNever } from "../../../../../utils/types"
import { JumpOutNavLink } from "../JumpOutNavLink/JumpOutNavLink"
import { PegInStepsBar } from "../PegInStepsBar/PegInStepsBar"
import { RenderBRC20CardFn } from "./BRC20Card"
import { BRC20InscriptionStatus } from "./BRC20InscriptionStatus.types"

export type BRC20InscriptionStatusType = BRC20InscriptionStatus["type"]

export interface BRC20InscriptionStatusCardProps {
  className?: string
  token: TokenInfo
  tokenCount: BigNumber
  inscriptionNumber?: number
  status: BRC20InscriptionStatus
  renderBRC20Card?: RenderBRC20CardFn
}

export const BRC20InscriptionStatusCard: FC<
  BRC20InscriptionStatusCardProps
> = props => {
  const { $t } = useIntl()

  let content: ReactNode = null

  switch (props.status.type) {
    case "inscribing":
      content = (
        <ActionButton disabled>
          <LoadingIndicator size={16} />
          <span>
            {$t(
              defineMessage({
                defaultMessage: "Waiting for confirm...",
                description: "BRC20 Peg-In Page/Inscription Card/button text",
              }),
            )}
          </span>
        </ActionButton>
      )
      break

    case "transferable":
      content = (
        <ActionButton onClick={props.status.onSendPegInRequest}>
          {$t(
            defineMessage({
              defaultMessage: "Send Peg-In Request",
              description: "BRC20 Peg-In Page/Inscription Card/button text",
            }),
          )}
        </ActionButton>
      )
      break

    case "sending-peg-in-request":
      content = (
        <ActionButton disabled>
          <LoadingIndicator size={16} />
          <span>
            {$t(
              defineMessage({
                defaultMessage: "Pending...",
                description: "BRC20 Peg-In Page/Inscription Card/button text",
              }),
            )}
          </span>
        </ActionButton>
      )
      break

    case "send-peg-in-request-failed":
      content = (
        <p>
          {$t(
            defineMessage({
              defaultMessage:
                "* Your transaction failed, please click 'Inscribe New Transfer' to resubmit.",
              description: "BRC20 Peg-In Page/Inscription Card/text",
            }),
          )}
        </p>
      )
      break

    case "sent-peg-in-request":
      content = (
        <ActionButton onClick={props.status.onSendToAlex}>
          {$t(
            defineMessage({
              defaultMessage: "Send to ALEX",
              description: "BRC20 Peg-In Page/Inscription Card/button text",
            }),
          )}
        </ActionButton>
      )
      break

    case "sending-to-alex":
      content = (
        <ActionButton disabled>
          <LoadingIndicator size={16} />
          <span>
            {$t(
              defineMessage({
                defaultMessage: "Pending...",
                description: "BRC20 Peg-In Page/Inscription Card/button text",
              }),
            )}
          </span>
        </ActionButton>
      )
      break

    case "send-to-alex-failed":
      content = (
        <div className="flex flex-col items-start justify-stretch gap-y-2.5 w-full">
          <Failed reason={props.status.reason} />
          <Note />
        </div>
      )
      break

    case "sent-to-alex":
      content = (
        <div className="flex flex-col items-start justify-stretch gap-y-2.5 w-full">
          <ActionButton disabled>
            <LoadingIndicator size={16} />
            <span>
              {$t(
                defineMessage({
                  defaultMessage: "Wait for distribution to trading account...",
                  description: "BRC20 Peg-In Page/Inscription Card/button text",
                }),
              )}
            </span>
          </ActionButton>

          <Note />
        </div>
      )
      break

    case "pegged-in":
      content = (
        <div className="px-3 py-2 flex flex-row gap-x-2.5 rounded w-full bg-white/5 justify-center items-center">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.78846 10.3269L0.75 6.04696L1.87996 4.89237L4.76706 7.91031L10.0752 2.25L11.25 3.38319L4.78846 10.3269Z"
              fill="#84CC16"
            />
          </svg>
          <span className="text-xs leading-4 font-medium text-[#84CC16]">
            {$t(
              defineMessage({
                defaultMessage: "Completed",
                description: "BRC20 Peg-In Page/Inscription Card/state",
              }),
            )}
          </span>
        </div>
      )
      break

    case "peg-in-failed":
      content = <Failed />
      break

    default:
      assertNever(props.status)
  }

  return (
    <li
      className={clsx(
        "flex flex-row flex-wrap p-5 gap-x-0 sm:gap-x-10 gap-y-1 bg-[rgba(59,130,246,0.20)] rounded justify-center items-center",
        props.className,
      )}
    >
      <div className="flex flex-col gap-y-1 w-60 rounded-lg bg-black/30 py-4 items-center justify-center">
        <TokenName
          token={props.token}
          className="text-xl leading-7 font-extrabold"
        />
        <span className="text-lg leading-7 font-normal">
          <TokenCount token={props.token} count={props.tokenCount} />
        </span>
        <div className="text-gray-400 text-xs leading-4 font-normal">
          #{props.inscriptionNumber ?? "-"}
        </div>
      </div>

      <div className="flex flex-col gap-y-2.5 items-center justify-center grow">
        <PegInStepsBar currentStatus={props.status.type} className="w-full" />
        {content}
        {"explorerUrl" in props.status ? (
          <JumpOutNavLink
            to={props.status.explorerUrl}
            linkClassName="text-blue-500 text-xs leading-4 font-normal"
          >
            {$t(
              defineMessage({
                defaultMessage: "View in Explorer",
                description: "BRC20 Peg-In Page/Inscription Card/link text",
              }),
            )}
          </JumpOutNavLink>
        ) : null}
      </div>
    </li>
  )
}

const ActionButton: FC<ButtonVariantProps> = props => {
  return (
    <GradientFilledButton
      {...props}
      className={clsx(
        "w-full flex flex-row gap-x-1 sm:gap-x-2.5 items-center justify-center",
        props.className,
      )}
      roundedClassName={"rounded"}
      textClassName={"text-xs leading-4 font-medium"}
      boxClassName={"sm:px-3 py-2"}
    />
  )
}

const Note: FC = () => {
  const { $t } = useIntl()
  return (
    <div className="text-xs leading-4 font-normal">
      {$t(
        defineMessage({
          defaultMessage:
            "* If the transaction is not confirmed after 24 hours, please contact {contactLink}",
          description: "Orderbook Peg-In Page/In Progress Panel/rules",
        }),
        {
          contactLink: (
            <a className="text-blue-600" href="http://discord.gg/alexlab">
              Discord administrator&gt;
            </a>
          ),
        },
      )}
    </div>
  )
}

const Failed: FC<{ reason?: string }> = props => {
  const { $t } = useIntl()
  return (
    <div className="flex flex-row gap-x-2.5 px-3 py-2 w-full rounded bg-red-500 justify-center items-center">
      <span className="text-xs leading-4 font-medium">
        {$t(
          defineMessage({
            defaultMessage: "Failed",
            description: "BRC20 Peg-In Page/Inscription Card/state",
          }),
        )}
      </span>
      {props.reason && <TipIcon tip={props.reason} />}
    </div>
  )
}
