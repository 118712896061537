import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../../components/InfoList"
import { PercentNumber } from "../../../../../../components/PercentNumber"
import { TextTokenCount } from "../../../../../../components/RichTokenCount"
import { TipIcon } from "../../../../../../components/TipIcon/TipIcon"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"

export const DetailSection: FC<{
  pegOutFeeToken: SuspenseResource<TokenInfo>
  pegOutFee: SuspenseResource<number>
  pegOutFeeRate: SuspenseResource<number>
  pegOutGasFeeToken: SuspenseResource<TokenInfo>
  pegOutGasFee: SuspenseResource<number>
}> = props => {
  const { $t } = useIntl()
  return (
    <div className="bg-white/5 rounded-lg p-4">
      <InfoList>
        <InfoListItem>
          <InfoListItemTitle className="flex items-center">
            <span className="underline">
              {$t(
                defineMessage({
                  defaultMessage: "Fees",
                  description:
                    "/OrderBook/BRC20/PegOutBrc20ModalContent/DetailSection/label",
                }),
              )}
            </span>
            &nbsp;
            {(safeReadResource(props.pegOutFeeRate) ?? 0) !== 0 && (
              <TipIcon
                tip={$t(
                  defineMessage({
                    defaultMessage: "Peg out fees: {feeRate}",
                    description:
                      '/OrderBook/BRC20/PegOutBrc20ModalContent/DetailSection/"Fees" label tip text',
                  }),
                  {
                    feeRate: <PercentNumber number={props.pegOutFeeRate} />,
                  },
                )}
              />
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.pegOutFeeToken}
              count={props.pegOutFee}
            />
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle className="flex items-center">
            <span className="underline">
              {$t(
                defineMessage({
                  defaultMessage: "Peg-out gas fees",
                  description:
                    "/OrderBook/BRC20/PegOutBrc20ModalContent/DetailSection/label",
                }),
              )}
            </span>
            &nbsp;
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "Peg-out gas fees cover the Bitcoin transaction fee.",
                  description:
                    '/OrderBook/BRC20/PegOutBrc20ModalContent/DetailSection/"Fees" label tip text',
                }),
              )}
            />
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.pegOutGasFeeToken}
              count={props.pegOutGasFee}
            />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </div>
  )
}
