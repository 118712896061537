import { action } from "mobx"
import { FC } from "react"
import { Modal } from "../../../components/Modal"
import {
  SelectTokenModalContent,
  TokenCandidate,
} from "../../../components/SelectTokenModalContent/SelectTokenModalContent"
import { Spensor } from "../../../components/Spensor"
import { usePathGenerator } from "../../../routes/routes"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { tap } from "../../../utils/promiseHelpers"
import { TokensWithdrawConfirmModalContent } from "../components/depositWithdraw/TokensWithdrawConfirmModalContent"
import { TokensWithdrawModalContent } from "../components/depositWithdraw/TokensWithdrawModalContent/TokensWithdrawModalContent"
import { OrderbookAsset } from "../store/OrderbookStore.service/OrderbookStore.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredTokensWithdrawDialog: FC<{
  txStore: ConfirmTransactionStore
  visible: boolean
  onDismiss: () => void
  onFinish: () => void
}> = props => {
  const store = useOrderbookStore()
  const onCloseConfirm = action(() => (store.withdraw.confirming = false))
  const { currency } = store
  return (
    <>
      <Modal visible={props.visible} onClose={props.onDismiss}>
        <Spensor>
          {() => (
            <TokensWithdrawModalContent
              onSelectCurrency={action(
                from =>
                  (store.withdraw.selectingFromToken =
                    TokenInfoPresets.toCurrency(from) as OrderbookAsset),
              )}
              onClose={props.onDismiss}
              onSubmit={action(() => (store.withdraw.confirming = true))}
              tokens={store.withdraw.tokens.map(token => ({
                token: currency.getTokenInfo$(token.token),
                onPressMax: () =>
                  token.amount.set(
                    store.withdraw.withdrawableBalance$(token.token),
                  ),
                tokenCount: suspenseResource(() => token.amount.get() ?? null),
                isError: suspenseResource(
                  () =>
                    token.amount.read$ >
                    store.withdraw.withdrawableBalance$(token.token),
                ),
                onDelete: store.withdraw.canDelete
                  ? () => store.withdraw.deleteToken(token.token)
                  : undefined,
                onTokenCountChange: newCount =>
                  token.amount.set(newCount ?? undefined),
                tokenBalanceCount: suspenseResource(() =>
                  store.withdraw.withdrawableBalance$(token.token),
                ),
              }))}
              onAdd={
                store.withdraw.canAddMoreToken
                  ? () => store.withdraw.addToken()
                  : undefined
              }
              onClear={() => store.withdraw.resetForm()}
              error={suspenseResource(() =>
                Result.maybeError(store.withdraw.formData$),
              )}
            />
          )}
        </Spensor>
      </Modal>
      <WiredSelectTokenModal />
      <Modal visible={store.withdraw.confirming} onClose={onCloseConfirm}>
        <Spensor>
          {() => (
            <TokensWithdrawConfirmModalContent
              onCancel={onCloseConfirm}
              onClose={onCloseConfirm}
              onConfirm={async () => {
                const form = Result.maybeValue(store.withdraw.formData$)
                if (form != null) {
                  await props.txStore.run(() =>
                    store.withdraw.withdraw(form).then(
                      tap(() => {
                        store.withdraw.reinit()
                      }),
                    ),
                  )
                  props.onFinish()
                }
              }}
              tokens={store.withdraw.userDecidedTokens.map(token => ({
                token: store.currency.getTokenInfo$(token.token),
                count: token.amount.read$,
              }))}
            />
          )}
        </Spensor>
      </Modal>
    </>
  )
}

const WiredSelectTokenModal: FC = () => {
  const g = usePathGenerator()
  const store = useOrderbookStore()
  const { currency } = store

  const existingOnes = store.withdraw.tokens.map(t => t.token)

  const allStxDxAssets = store.info.allAccessibleMarketCurrencies$

  const allTokens = allStxDxAssets.map((c): TokenCandidate => {
    return {
      disabled: existingOnes.includes(c),
      tokenInfo: currency.getTokenInfo$(c),
      balance: suspenseResource(() => store.withdraw.withdrawableBalance$(c)),
    }
  })

  const commonTokens = allTokens.slice(0, 6)

  return (
    <Modal
      visible={store.withdraw.selectingFromToken != null}
      onClose={action(() => (store.withdraw.selectingFromToken = undefined))}
    >
      <SelectTokenModalContent
        commonBasesTokens={commonTokens}
        allTokens={allTokens}
        tokenListLink={g.tokenList()}
        onSelect={action(currency => {
          const fromToken = store.withdraw.selectingFromToken
          if (fromToken == null) return
          const pair = store.withdraw.tokens.find(a => a.token === fromToken)
          if (pair == null) return
          pair.token = TokenInfoPresets.toCurrency(currency) as OrderbookAsset
          store.withdraw.selectingFromToken = undefined
        })}
        onClose={action(() => (store.withdraw.selectingFromToken = undefined))}
      />
    </Modal>
  )
}
