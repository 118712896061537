import { useMediaQuery } from "./useMediaQuery"

export function useBreakpointsSelector<V>(
  breakpoints: Record<string, V>,
): undefined | V
export function useBreakpointsSelector<V>(
  breakpoints: Record<string, V>,
  defaultValue: V,
): V
export function useBreakpointsSelector<V>(
  breakpoints: Record<string, V>,
  defaultValue?: V,
): undefined | V {
  const queries = Object.keys(breakpoints)
  const matchedQuery = useMediaQuery(queries)

  if (matchedQuery == null) {
    return defaultValue
  } else {
    return breakpoints[matchedQuery]!
  }
}
