import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { AbsolutePositionPointContainer } from "./AbsolutePositionPointContainer"
import { CursorPoint } from "./CursorPoint"
import { Label } from "./Label"
import { Point } from "./Point"
import { Track } from "./Track"
import { TrackCanvas } from "./TrackCanvas"
import { TrackModeProps } from "./types"

const IncompleteState: FC<TrackModeProps> = props => {
  const percentage = props.currentRaiseTokenCount / props.maxRaiseTokenCount
  const { $t } = useIntl()
  return (
    <>
      <AbsolutePositionPointContainer
        position={0}
        point={<Point state={"bypassed"} />}
      />

      <Track
        className={"w-full"}
        percentage={Math.min(1, percentage)}
        cursor={
          <CursorPoint
            isFailed={props.isFinished && percentage < 1}
            labelAlignment={percentage > 0.5 ? "right" : "left"}
            label={
              <Label
                title={$t(
                  defineMessage({
                    defaultMessage: "Current",
                    description: "/Launchpad/IDOProgress/label",
                  }),
                )}
                detail={
                  <>
                    <TokenCount
                      token={props.unitToken}
                      count={props.currentRaiseTokenCount}
                    />{" "}
                    <TokenName token={props.unitToken} />
                  </>
                }
              />
            }
          />
        }
      />

      <AbsolutePositionPointContainer
        position={1}
        point={
          <Point
            state={percentage >= 1 ? "arrived" : "idle"}
            labelAlignment={"right"}
            label={
              <Label
                title={$t(
                  defineMessage({
                    defaultMessage: "Total Raise",
                    description: "/Launchpad/IDOProgress/label",
                  }),
                )}
                detail={
                  <>
                    <TokenCount
                      token={props.unitToken}
                      count={props.maxRaiseTokenCount}
                    />{" "}
                    <TokenName token={props.unitToken} />
                  </>
                }
              />
            }
          />
        }
      />
    </>
  )
}

const CompleteState: FC<TrackModeProps> = props => {
  const { $t } = useIntl()
  return (
    <>
      <AbsolutePositionPointContainer
        position={0}
        point={
          <Point
            state={"arrived"}
            labelAlignment={"left"}
            label={
              <Label
                title={$t(
                  defineMessage({
                    defaultMessage: "Total Raise Reached",
                    description: "/Launchpad/IDOProgress/label",
                  }),
                )}
                detail={
                  <>
                    <TokenCount
                      token={props.unitToken}
                      count={props.maxRaiseTokenCount}
                    />{" "}
                    <TokenName token={props.unitToken} />
                  </>
                }
              />
            }
          />
        }
      />
      <Track
        className={"w-full"}
        percentage={1}
        cursor={
          <CursorPoint
            isFailed={false}
            labelAlignment={"right"}
            label={
              <Label
                title={$t(
                  defineMessage({
                    defaultMessage: "Current",
                    description: "/Launchpad/IDOProgress/label",
                  }),
                )}
                detail={
                  <>
                    <TokenCount
                      token={props.unitToken}
                      count={props.currentRaiseTokenCount}
                    />{" "}
                    <TokenName token={props.unitToken} />
                  </>
                }
              />
            }
          />
        }
      />
    </>
  )
}

export const OneTrackMode: FC<TrackModeProps> = props => {
  const percentage = props.currentRaiseTokenCount / props.maxRaiseTokenCount

  return (
    <TrackCanvas className={"w-full h-full"}>
      {percentage <= 1 ? (
        <IncompleteState {...props} />
      ) : (
        <CompleteState {...props} />
      )}
    </TrackCanvas>
  )
}
