import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../../components/Card"
import { NoteParagraph } from "../../../../../../components/NoteParagraph/NoteParagraph"
import { BlockTimeCountdownText } from "../../../../../../components/TimeCountdown/BlockTimeCountdownText"
import { TokenIcon } from "../../../../../../components/TokenIcon"
import { TokenName } from "../../../../../../components/TokenName"
import { HeadlessButton } from "../../../../../../components/button/HeadlessButton"
import { TokenInfoPresets } from "../../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { dontWrapObserver } from "../../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../../../utils/reactHelpers/withClassName"
import {
  PlainVerticalInfoListContainer,
  PlainVerticalInfoListContainerGroup,
  PlainVerticalInfoListItem,
} from "../../../InfoList"
import { TokenCountRange } from "../../../TokenCountRange"
import { TokenIDOPrices } from "../../../types"
import { PurpleBlock } from "../PurpleBlock"
import { ReactComponent as UpcomingIcon } from "./upcomingImage.svg"

export const ActionSectionContent$Upcoming: FC<{
  priceToken: TokenInfo
  idoPrices: TokenIDOPrices
  validateStepStartedAt: Date
  validateStepStartedAtBlock: number
  currentBlock: number
  onLearnMoreAPowerRule?: () => void
}> = props => {
  const { idoPrices } = props
  const { $t } = useIntl()
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <UpcomingIcon />
      <WidthLimitedContainer className={"flex flex-col items-center gap-1"}>
        <CardInsetTitle className={"text-center"}>
          {$t(
            defineMessage({
              defaultMessage: "Countdown to start of LaunchPad",
              description: "/Launchpad/ActionSectionContent/Upcoming title",
            }),
          )}
        </CardInsetTitle>

        <PlainVerticalInfoListContainerGroup className={"w-full py-6"}>
          <PlainVerticalInfoListContainer>
            <PlainVerticalInfoListItem
              title={
                <>
                  {$t<ReactNode>(
                    defineMessage({
                      defaultMessage:
                        "{aPower} Per Ticket ( <button>Details</button> )",
                      description:
                        "/Launchpad/ActionSectionContent/Upcoming list item title",
                    }),
                    {
                      aPower: <TokenName token={TokenInfoPresets.APower} />,
                      button: dontWrapObserver(children => (
                        <HeadlessButton
                          className={"inline text-blue-600"}
                          onClick={props.onLearnMoreAPowerRule}
                        >
                          {children}
                        </HeadlessButton>
                      )),
                    },
                  )}
                </>
              }
              detail={
                <>
                  <TokenIcon
                    className={"mr-2"}
                    token={TokenInfoPresets.APower}
                    size={24}
                  />
                  <TokenCountRange
                    token={TokenInfoPresets.APower}
                    range={idoPrices.aPowerCountPerTicket}
                  />
                  &nbsp;
                  <TokenName token={TokenInfoPresets.APower} />
                </>
              }
            />
          </PlainVerticalInfoListContainer>

          <PlainVerticalInfoListContainer>
            <PlainVerticalInfoListItem
              title={
                <>
                  {$t(
                    defineMessage({
                      defaultMessage: "{priceToken} Per Ticket",
                      description:
                        "/Launchpad/ActionSectionContent/Upcoming/list item title",
                    }),
                    {
                      priceToken: <TokenName token={props.priceToken} />,
                    },
                  )}
                </>
              }
              detail={
                <>
                  <TokenIcon
                    className={"mr-2"}
                    token={props.priceToken}
                    size={24}
                  />
                  <TokenCountRange
                    token={props.priceToken}
                    range={idoPrices.pricePerTicket}
                  />
                  &nbsp;
                  <TokenName token={props.priceToken} />
                </>
              }
            />
          </PlainVerticalInfoListContainer>
        </PlainVerticalInfoListContainerGroup>
      </WidthLimitedContainer>

      <WidthLimitedContainer>
        <PurpleBlock
          primaryText={
            <>
              {$t(
                defineMessage({
                  defaultMessage: "Validate will start in {countdown}",
                  description:
                    "/Launchpad/ActionSectionContent/Upcoming primary text",
                }),
                {
                  countdown: (
                    <BlockTimeCountdownText
                      targetBlock={props.validateStepStartedAtBlock}
                      currentBlock={props.currentBlock}
                      time={props.validateStepStartedAt}
                      estimateSuffix={<> (Estimate)</>}
                    />
                  ),
                },
              )}
            </>
          }
          secondaryText={
            <>
              {$t(
                defineMessage({
                  defaultMessage: "open at: Block {startBlock}",
                  description:
                    "/Launchpad/ActionSectionContent/Upcoming secondary text",
                }),
                {
                  startBlock: props.validateStepStartedAtBlock,
                },
              )}
            </>
          }
        />
      </WidthLimitedContainer>

      <CardInsetDivider className={"w-full"} />

      <div className={"text-sm leading-5 flex flex-col gap-[5px]"}>
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "{priceToken} submitted with validation will be refunded if the lottery is not won. The {aPower} submitted with validation will be burnt, win or lose.",
              description:
                "L/Launchpad/ActionSectionContent/Upcoming Note text",
            }),
            {
              priceToken: <TokenName token={props.priceToken} />,
              aPower: <TokenName token={TokenInfoPresets.APower} />,
            },
          )}
        </NoteParagraph>
      </div>
    </CardInset>
  )
}

const WidthLimitedContainer = withClassName("w-full sm:px-[32px]", "div")
