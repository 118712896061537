import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset, CardInsetTitle } from "../../../../../components/Card"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { MainButton } from "./MainButton"
import { ReactComponent as ConnectWalletIcon } from "./connectWalletImage.svg"

export const ActionSectionContent$ConnectWallet: FC<{
  onConnect?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardInsetTitle>
          {$t(
            defineMessage({
              defaultMessage: "Connect wallet to join LaunchPad",
              description:
                "/Launchpad/ActionSectionContent/Connect wallet title",
            }),
          )}
        </CardInsetTitle>
        <ConnectWalletIcon />
      </CardInset>
      <MainButton Variant={GradientFilledButton} onClick={props.onConnect}>
        {$t(
          defineMessage({
            defaultMessage: "Connect Wallet",
            description: "/Launchpad/Connect wallet button",
          }),
        )}
      </MainButton>
    </>
  )
}
