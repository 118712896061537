import { BorrowRolloverRowData } from "../../store/transformerGroups/lend/borrowRollover"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"

export const borrowRolloverExporter: Exporter<BorrowRolloverRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = "(Collateral Rollover)"
  const tradeInfo = `#${rowData.expiry}`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
