import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import React, { ReactElement } from "react"
import ReactDOMClient from "react-dom/client"
import { filter, firstValueFrom, ReplaySubject } from "rxjs"
import App from "./App"
import { OpacityButton } from "./components/button/variants/OpacityButton"
import { GlobalErrorBoundaryPageContent } from "./components/GlobalErrorBoundaryPage"
import { MessageController } from "./components/message/MessageProvider"
import { ENV_NAME, IS_IN_MAINTENANCE_MODE } from "./config"
import "./styles/index.css"
import { enableWorkboxServiceWorker } from "./utils/enableWorkboxServiceWorker"
import { reportWebVitals } from "./utils/reportWebVitals"
import { isNotNull } from "./utils/utils"

Sentry.init({
  environment: ENV_NAME,
  dsn: "https://b426e868435d4e43aa126549c83a2fbd@o1020431.ingest.sentry.io/6090179",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1,
  ignoreErrors: [],
})

const reactDomRender = (el: ReactElement): void => {
  ReactDOMClient.createRoot(document.getElementById("root")!).render(el)
  // ReactDOM.render(el, document.getElementById("root")!)
}
if (IS_IN_MAINTENANCE_MODE) {
  reactDomRender(
    <React.StrictMode>
      <GlobalErrorBoundaryPageContent
        title="Maintenance"
        code="404"
        subTitle="Sorry, we are under maintenance."
        actions={
          <OpacityButton className="min-w-[240px]">
            We will be back soon
          </OpacityButton>
        }
      />
    </React.StrictMode>,
  )
} else {
  reactDomRender(
    <React.StrictMode>
      <App messageControllerRef={ctrl => msgCtrlSubject.next(ctrl)} />
    </React.StrictMode>,
  )
}

if (!process.env.STORYBOOK) {
  // Forcibly enable tailwind dark mode in production environment before light
  // mode complete
  document.documentElement.classList.add("dark")
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

const msgCtrlSubject = new ReplaySubject<null | MessageController>()
enableWorkboxServiceWorker(callbackOnce =>
  firstValueFrom(msgCtrlSubject.pipe(filter(isNotNull))).then(callbackOnce),
)
