import { ReactElement, ReactNode } from "react"
import { BaseRowData } from "../../../types"
import { BaseCellContainer } from "../cells/BaseCellContainer"
import { StatusCell } from "../cells/StatusCell"
import { TimeCell } from "../cells/TimeCell"
import { TypeCell } from "../cells/TypeCell"
import { BaseRowContainer } from "./BaseRowContainer"

export function BaseRow<Row extends BaseRowData = BaseRowData>({
  rowData,
  currencyCell,
  amountCell,
  tradeInfoCell,
}: {
  rowData: Row
  currencyCell?: ReactNode
  amountCell?: ReactNode
  tradeInfoCell?: ReactNode
}): ReactElement<any, any> {
  currencyCell = currencyCell ?? <BaseCellContainer />
  amountCell = amountCell ?? <BaseCellContainer />
  tradeInfoCell = tradeInfoCell ?? <BaseCellContainer />
  return (
    <BaseRowContainer txId={rowData.id}>
      <TypeCell type={rowData.type} id={rowData.id} />
      <StatusCell status={rowData.status} />
      {currencyCell}
      {amountCell}
      {tradeInfoCell}
      <TimeCell time={rowData.time} />
    </BaseRowContainer>
  )
}
