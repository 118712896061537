import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { NotFoundPage } from "./components/GlobalErrorBoundaryPage"
import { AnalyticsLayout } from "./layouts/AnalyticsLayout"
import { CRPSimulatorLayout } from "./layouts/CRPSimulatorLayout/CRPSimulatorLayout"
import { Layout } from "./layouts/Layout/Layout"
import { AdminPage } from "./pages/Admin/AdminPage"
import { BulkTransferInscriptionPage } from "./pages/Admin/BulkTransferInscriptionPage/BulkTransferInscriptionPage"
import { DebugPage } from "./pages/Admin/DebugPage"
import { SandboxPage } from "./pages/Admin/SandboxPage"
import TransactionDetailPage from "./pages/Admin/TransactionDetailPage"
import { AnalyticsDetailPage } from "./pages/Analytics/AnalyticsDetailPage"
import { AnalyticsIndexPage } from "./pages/Analytics/AnalyticsIndexPage"
import { B20TokenListPage } from "./pages/B20TokenListPage/B20TokenListPage"
import { CRPSimulatorPage } from "./pages/CRPSimulator/CRPSimulatorPage"
import { CoFarmDetailPage } from "./pages/Farm/CoFarmDetailPage"
import { FarmDetailPage } from "./pages/Farm/FarmDetailPage/FarmDetailPage"
import { FarmListPage } from "./pages/Farm/FarmListPage"
import FaucetPage from "./pages/Faucet/FaucetPage"
import { LaunchpadDashboardPage } from "./pages/Launchpad/LaunchpadDashboardPage"
import { LaunchpadDetailPage } from "./pages/Launchpad/LaunchpadDetailPage"
import { LaunchpadListPage } from "./pages/Launchpad/LaunchpadListPage"
import { LaunchpadLotteryListPage } from "./pages/Launchpad/LaunchpadLotteryListPage"
import { LaunchpadValidateListPage } from "./pages/Launchpad/LaunchpadValidateListPage"
import { BorrowPage } from "./pages/Lend/BorrowPage"
import { DepositPage } from "./pages/Lend/DepositPage"
import { LendIndexPage } from "./pages/Lend/LendIndexPage"
import { LotteryPage } from "./pages/Lottery/LotteryPage/LotteryPage"
import { NewBrandWrapBridgePage } from "./pages/NewBrandWrapBridgePage/NewBrandWrapBridgePage"
import { WiredNewBrandWrapBridgePageLayout } from "./pages/NewBrandWrapBridgePage/components/NewBrandWrapBridgePageLayout/WiredNewBrandWrapBridgePageLayout"
import { NotifyPage } from "./pages/Notify/NotifyPage"
import { OrderbookIndexPage } from "./pages/Orderbook/OrderbookIndexPage"
import { OrderbookPage } from "./pages/Orderbook/OrderbookPage"
import { OrderbookPegInPage } from "./pages/Orderbook/OrderbookPegInPage/OrderbookPegInPage"
import { StxDxStoreProvider } from "./pages/Orderbook/store/stxdx_shared/useStxDxStore"
import { OrderbookStoreProvider } from "./pages/Orderbook/store/useOrderbookStore"
import { PerpetualIndexPage } from "./pages/Perpetual/PerpetualIndexPage"
import { PerpetualPage } from "./pages/Perpetual/PerpetualPage"
import { PerpetualStoreProvider } from "./pages/Perpetual/store/usePerpetualStore"
import PoolDetailPage from "./pages/Pool/PoolDetailPage"
import PoolListPage from "./pages/Pool/PoolListPage"
import { SpotPage } from "./pages/Spot/SpotPage"
import { StakePage } from "./pages/Stake/StakePage"
import TokenListPage from "./pages/TokenList/TokenListPage"
import { WrapBridgePage } from "./pages/WrapBridge/WrapBridgePage"
import { RoutePath, RoutePathDefault } from "./routes/routes"

export const AppRoutes$Default: FC = () => (
  <Routes>
    <Route path={`/`} element={<AnalyticsLayout />}>
      <Route path={RoutePath.Analytics} element={<AnalyticsIndexPage />} />
      <Route
        path={RoutePath.AnalyticsDetail}
        element={<AnalyticsDetailPage />}
      />
    </Route>

    {/* layout without default header */}
    <Route path="/" element={<CRPSimulatorLayout />}>
      <Route path={RoutePath.CRPSimulator} element={<CRPSimulatorPage />} />
    </Route>

    <Route path="/" element={<Layout />}>
      <Route
        index
        element={<Navigate to={RoutePathDefault} replace={true} />}
      />
      <Route path={RoutePath.Faucet} element={<FaucetPage />} />
      <Route path={RoutePath.CoFarmDetail} element={<CoFarmDetailPage />} />
      <Route path={RoutePath.FarmDetail} element={<FarmDetailPage />} />
      <Route path={RoutePath.Farm} element={<FarmListPage />} />
      <Route path={RoutePath.Stake} element={<StakePage />} />
      <Route path={RoutePath.Pool} element={<PoolListPage />} />
      <Route path={RoutePath.PoolDetail} element={<PoolDetailPage />} />
      <Route path={RoutePath.Swap} element={<SpotPage />} />
      <Route path={RoutePath.Launchpad} element={<LaunchpadListPage />} />
      <Route
        path={RoutePath.LaunchpadDashboard}
        element={<LaunchpadDashboardPage />}
      />
      <Route
        path={RoutePath.LaunchpadDetail}
        element={<LaunchpadDetailPage />}
      />
      <Route
        path={RoutePath.LaunchpadValidateList}
        element={<LaunchpadValidateListPage />}
      />
      <Route
        path={RoutePath.LaunchpadLotteryList}
        element={<LaunchpadLotteryListPage />}
      />
      <Route path={RoutePath.Lottery} element={<LotteryPage />} />
      <Route path={RoutePath.TokenList} element={<TokenListPage />} />
      <Route path={RoutePath.Admin} element={<AdminPage />} />
      <Route
        path={RoutePath.AdminBulkTransferInscription}
        element={<BulkTransferInscriptionPage />}
      />
      <Route path={RoutePath.Sandbox} element={<SandboxPage />} />
      <Route path={RoutePath.Debug} element={<DebugPage />} />
      <Route
        path={RoutePath.TransactionDetail}
        element={<TransactionDetailPage />}
      />
      <Route path={RoutePath.Notify} element={<NotifyPage />} />
      <Route path={RoutePath.Lend} element={<LendIndexPage />} />
      <Route path={RoutePath.LendDetailDeposit} element={<DepositPage />} />
      <Route path={RoutePath.LendDetailBorrow} element={<BorrowPage />} />
      <Route path={RoutePath.WrapBridge} element={<WrapBridgePage />} />
      <Route
        element={
          <StxDxStoreProvider>
            <OrderbookStoreProvider>
              <Outlet />
            </OrderbookStoreProvider>
          </StxDxStoreProvider>
        }
      >
        <Route path={RoutePath.Orderbook} element={<OrderbookIndexPage />} />
        <Route path={RoutePath.OrderbookDetail} element={<OrderbookPage />} />
      </Route>
      <Route path={RoutePath.OrderbookPegIn} element={<OrderbookPegInPage />} />
      <Route path={RoutePath.B20TokenList} element={<B20TokenListPage />} />
      <Route
        element={
          <StxDxStoreProvider>
            <PerpetualStoreProvider>
              <Outlet />
            </PerpetualStoreProvider>
          </StxDxStoreProvider>
        }
      >
        <Route path={RoutePath.Perpetual} element={<PerpetualIndexPage />} />
        <Route path={RoutePath.PerpetualDetail} element={<PerpetualPage />} />
      </Route>
    </Route>

    <Route
      path="/stacks-bridge-standalone"
      element={
        <WiredNewBrandWrapBridgePageLayout>
          <NewBrandWrapBridgePage />
        </WiredNewBrandWrapBridgePageLayout>
      }
    />

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)
