import { defineMessages } from "react-intl"

export const formFields$t = defineMessages({
  available: {
    defaultMessage: "Available:",
    description: "Orderbook/Create Order Panel/field label",
  },
  limitPrice: {
    defaultMessage: "Limit Price",
    description: "Orderbook/Create Order Panel/field label",
  },
  stopPrice: {
    defaultMessage: "Stop Price",
    description: "Orderbook/Create Order Panel/field label",
  },
  price: {
    defaultMessage: "Price",
    description: "Orderbook/Create Order Panel/field label",
  },
  amount: {
    defaultMessage: "Amount",
    description: "Orderbook/Create Order Panel/field label",
  },
  total: {
    defaultMessage: "Total",
    description: "Orderbook/Create Order Panel/field label",
  },
})
