export enum SwapFormErrorType {
  TokenNotSelected = "TokenNotSelected",
  TokenRouteNotSupported = "TokenRouteNotSupported",
  InsufficientTokenBalance = "InsufficientTokenBalance",
  AmountIsEmpty = "AmountIsEmpty",
  WalletNotConnected = "WalletNotConnected",
  FetchingExchangeRate = "FetchingExchangeRate",
  ErrorMaxInRatio = "ErrorMaxInRatio",
  ErrorMaxOutRatio = "ErrorMaxOutRatio",
}
