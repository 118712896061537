import { useEffect, useState } from "react"

export interface Dimensions {
  width: number
  height: number
}

const getDocumentElement = (): HTMLElement => window.document.documentElement
const getDimensions = (): Dimensions => ({
  width: getDocumentElement().clientWidth,
  height: getDocumentElement().offsetHeight,
})

export const useDimensions = (): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>(getDimensions)

  useEffect(() => {
    const onResize = (): void => {
      setDimensions(oldDim => {
        const newDim = getDimensions()
        if (newDim.width === oldDim.width && newDim.height === oldDim.height) {
          return oldDim
        } else {
          return newDim
        }
      })
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return dimensions
}
