import { FC, ReactNode } from "react"
import { usePathGenerator } from "../../../../../routes/routes"
import { useCurrencyStore } from "../../../../../stores/currencyStore/useCurrencyStore"
import { LaunchingStatus } from "../../../store/LaunchPadContractStore"
import { useLaunchPadContentfulStore } from "../../../store/useLaunchPadStores"
import { IDODetailSidePanel$Allocation } from "./IDODetailSidePanel$Allocation"
import { IDODetailSidePanel$Failed } from "./IDODetailSidePanel$Failed"
import { IDODetailSidePanel$Succeed } from "./IDODetailSidePanel$Succeed"
import { IDODetailSidePanel$Upcoming } from "./IDODetailSidePanel$Upcoming"
import { IDODetailSidePanel$Validate } from "./IDODetailSidePanel$Validate"

export const WiredIDODetailSidePanelImpl: FC<{
  className?: string
  titleRightSide: ReactNode
  idoSteps: ReactNode
  onLearnMoreAPowerRule: () => void
}> = props => {
  const currency = useCurrencyStore()
  const store = useLaunchPadContentfulStore()
  const g = usePathGenerator()

  const sharedProps = {
    className: props.className,
    idoSteps: props.idoSteps,
    titleRightSide: props.titleRightSide,
    onLearnMoreAPowerRule: props.onLearnMoreAPowerRule,
    prepareAPowerLink: null,
  }

  if (store.contractStore$ == null) {
    return (
      <IDODetailSidePanel$Upcoming
        {...sharedProps}
        idoToken={store.contentfulDetail$.idoToken}
        priceToken={store.contentfulDetail$.priceToken}
        maxRaiseIdoTokenCount={store.contentfulDetail$.estimateIdoAmount}
      />
    )
  }
  const module = store.contractStore$.tokenProfileViewModule
  const currentStatus$ = store.contractStore$.steps.currentStatus$

  if (currentStatus$ === LaunchingStatus.Upcoming) {
    return (
      <IDODetailSidePanel$Upcoming
        {...sharedProps}
        idoToken={store.contractStore$.tokenInfo$}
        priceToken={store.contractStore$.priceTokenInfo$}
        maxRaiseIdoTokenCount={
          module.maxRaiseTicketCount * module.idoTokenCountPerTicket
        }
        maxRaiseIdoTokenPrice={
          module.maxRaiseTicketCount * module.pricePerTicket
        }
        dashboardLink={g.launchpadDashboard(store.urlSlug)}
      />
    )
  }
  if (currentStatus$ === LaunchingStatus.Registration) {
    return (
      <IDODetailSidePanel$Validate
        {...sharedProps}
        idoToken={store.contractStore$.tokenInfo$}
        priceToken={store.contractStore$.priceTokenInfo$}
        idoNumbers={module}
        idoPrices={module}
        idoStatus={module.status}
        dashboardLink={g.launchpadDashboard(store.urlSlug)}
      />
    )
  }
  if (currentStatus$ === LaunchingStatus.Claim) {
    return (
      <IDODetailSidePanel$Allocation
        {...sharedProps}
        idoToken={store.contractStore$.tokenInfo$}
        priceToken={store.contractStore$.priceTokenInfo$}
        idoNumbers={module}
        idoPrices={module}
        idoStatus={module.status}
        dashboardLink={g.launchpadDashboard(store.urlSlug)}
      />
    )
  }
  if (currentStatus$ === LaunchingStatus.Finished) {
    if (store.contractStore$.activationProgress$ >= 1) {
      return (
        <IDODetailSidePanel$Succeed
          {...sharedProps}
          idoSuccessSummary={module}
          idoToken={store.contractStore$.tokenInfo$}
          priceToken={store.contractStore$.priceTokenInfo$}
          idoNumbers={module}
          idoStatus={module.status}
          dashboardLink={g.launchpadDashboard(store.urlSlug)}
        />
      )
    } else {
      return (
        <IDODetailSidePanel$Failed
          {...sharedProps}
          priceToken={store.contractStore$.priceTokenInfo$}
          idoNumbers={module}
          idoStatus={module.status}
          dashboardLink={g.launchpadDashboard(store.urlSlug)}
        />
      )
    }
  }
  return null
}
