import { Currency } from "./Currency"
import { LiquidityPoolToken } from "./currencyHelpers"

export const poolMigration = {
  [Currency.FWP_STX_ALEX_50_50_V1_01]: Currency.AMM_SWAP_POOL_V1_1_WSTX_ALEX,
  [Currency.AMM_SWAP_POOL_WSTX_ALEX]: Currency.AMM_SWAP_POOL_V1_1_WSTX_ALEX,

  [Currency.FWP_STX_XBTC_50_50_V1_01]: Currency.AMM_SWAP_POOL_V1_1_WSTX_XBTC,
  [Currency.AMM_SWAP_POOL_WSTX_XBTC]: Currency.AMM_SWAP_POOL_V1_1_WSTX_XBTC,

  [Currency.AMM_SWAP_POOL_WSTX_SUSDT]: Currency.AMM_SWAP_POOL_V1_1_WSTX_SUSDT,

  [Currency.AMM_SWAP_POOL_ALEX_WDIKO]: Currency.AMM_SWAP_POOL_V1_1_ALEX_DIKO,

  [Currency.FWP_ALEX_ATALEX]: Currency.AMM_SWAP_POOL_V1_1_ALEX_ATALEXV2,

  [Currency.AMM_SWAP_POOL_WXUSD_WUSDA]: Currency.AMM_SWAP_POOL_WXUSD_WUSDA_2,
} as Partial<{ [key in LiquidityPoolToken]: LiquidityPoolToken }>
