import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { NavLink } from "../../../../../components/NavLink"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { BlockTopLine } from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { SuspenseResource } from "../../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { NoteParagraphWrap } from "../_/NoteParagraphWrap"
import { RateItem, RateList } from "../_/RateList"

export const ConfirmClaimModalContent: FC<{
  burnToken: TokenInfo
  burnTokenCount: SuspenseResource<number>
  receiveToken: TokenInfo
  receiveTokenCount: SuspenseResource<number>
  rates: RateItem[]
  blockNumber: number
  onClose: () => void
  onConfirm: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      title={$t(
        defineMessage({
          defaultMessage: "Claim atALEX",
          description:
            "/Stake/MixedStake/ConfirmClaimModalContent/Card modal content title",
        }),
      )}
      width={480}
      className="flex flex-col gap-4"
      onClose={props.onClose}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            topArea={
              <BlockTopLine className="flex flex-row gap-x-2.5 justify-between">
                <span>
                  {$t(
                    defineMessage({
                      defaultMessage: "Burn",
                      description:
                        "/Stake/MixedStake/ConfirmClaimModalContent/Block title",
                    }),
                  )}
                </span>
                <span className="text-sm text-gray-400">
                  {$t(
                    defineMessage({
                      defaultMessage:
                        "Your {burnToken} (snapshot: block#{blockNumber})",
                      description:
                        "/Stake/MixedStake/ConfirmClaimModalContent/Block text",
                    }),
                    {
                      burnToken: <TokenName token={props.burnToken} />,
                      blockNumber: props.blockNumber,
                    },
                  )}
                </span>
              </BlockTopLine>
            }
            tokens={[
              {
                token: props.burnToken,
                count: props.burnTokenCount,
              },
            ]}
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Recieve",
                    description:
                      "/Stake/MixedStake/ConfirmClaimModalContent/Block title",
                  }),
                )}
              </BlockTopLine>
            }
            tokens={[
              { token: props.receiveToken, count: props.receiveTokenCount },
            ]}
          />
        }
      />
      <RateList rates={props.rates} />
      <NoteParagraphWrap>
        <NoteParagraph textColorClassName="text-gray-200" className="mb-7 mt-4">
          {$t(
            defineMessage({
              defaultMessage: `Your {burnToken} will be converted into {receiveToken} at the exchange rate announced by {receiveToken} LAB Foundation. For more information, please visit <link>[Discord announcement]</link>.`,
              description:
                "/State/AutoStake/ConfirmStakeModalContent/Note text",
            }),
            {
              burnToken: <TokenName token={props.burnToken} />,
              receiveToken: <TokenName token={props.receiveToken} />,
              link: dontWrapObserver(c => (
                <NavLink
                  className="underline"
                  to="https://discord.com/channels/856358412303990794/892817922014216263/1105876933012619274"
                >
                  {c}
                </NavLink>
              )),
            },
          )}
        </NoteParagraph>
      </NoteParagraphWrap>
      <div className="flex ">
        <WhiteFilledButton className="mr-5 py-2 flex-1" onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Close",
              description:
                "/Stake/MixedStake/ConfirmStakeModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className="py-2 flex-1" onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Stake/MixedStake/ConfirmStakeModalContent/Button",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
