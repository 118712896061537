import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientText } from "../../../components/GradientText"
import { VideoIntro } from "../../../components/PageStack/VideoIntro/VideoIntro"

export const NewBrandHeader: FC<{ className?: string }> = props => {
  const { $t } = useIntl()
  return (
    <div
      className={clsx("flex flex-col items-center gap-2.5", props.className)}
    >
      <GradientText
        className={"font-semibold text-7xl leading-tight text-center"}
        gradientStyle={
          "linear-gradient(90.83deg, #FFFFFF 0.71%, #F97316 99.29%)"
        }
      >
        {$t(
          defineMessage({
            defaultMessage: "Bridge",
            description: "/Bridge/New Brand Header",
          }),
        )}
      </GradientText>

      <div
        className={"text-base leading-6 font-normal text-gray-400 text-center"}
      >
        {$t(
          defineMessage({
            defaultMessage:
              "Bridge is a cross-chain bridge between USDT (ERC20) and xUSD",
            description: "/Bridge/New Brand Header",
          }),
        )}
        <br />
        {$t(
          defineMessage({
            defaultMessage: "designed for high performance and speed",
            description: "/Bridge/New Brand Header",
          }),
        )}
      </div>
      <div>
        <VideoIntro
          iconSize={20}
          textClassName={"text-gray-100 text-sm leading-5 font-normal"}
          link={
            "https://medium.com/alexgobtc/alex-bridge-testnet-guide-222f72fc095e"
          }
        >
          {$t(
            defineMessage({
              defaultMessage: "How does the bridge work?",
              description: "/Bridge/New Brand Header",
            }),
          )}
        </VideoIntro>
      </div>
    </div>
  )
}
