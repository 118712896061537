import { FC, ReactNode } from "react"

export const TokenCountDetailInfoList: FC<{
  primary: ReactNode
  secondary: ReactNode
}> = props => (
  <div className={"flex flex-col gap-1 whitespace-pre"}>
    <div className={"text-base leading-6 font-normal text-gray-200"}>
      {props.primary}
    </div>
    <div
      className={
        "text-sm leading-5 font-normal text-gray-500 flex-1 flex items-center"
      }
    >
      {props.secondary}
    </div>
  </div>
)
