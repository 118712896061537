import { FC } from "react"
import { SwapRowData } from "../../../../store/transformerGroups/swap/swap"
import {
  AllTokenInfos,
  CurrencyAmount,
  NotifyTransactionStatus,
} from "../../../../types"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { OneLineExchangeRatioCell } from "../../cells/OneLineExchangeRatioCell"
import { BaseRow } from "../BaseRow"

export const SwapRow: FC<{
  rowData: SwapRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.fromCurrencyAmount]}
        toCurrencyAmounts={[rowData.toCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <OneLineAmountCell
        fromCurrencyAmounts={[rowData.fromCurrencyAmount]}
        toCurrencyAmounts={[rowData.toCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    tradeInfoCell={
      <OneLineExchangeRatioCell
        fromCurrencyAmount={rowData.fromCurrencyAmount}
        toCurrencyAmount={rowData.toCurrencyAmount as CurrencyAmount.Confirmed}
        allTokenInfos={allTokenInfos}
        confirmed={rowData.status === NotifyTransactionStatus.Confirmed}
      />
    }
  />
)
