import { gql } from "@urql/core"
import { range } from "lodash"
import { Observable } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../../../../config"
import {
  RewardStatsQuery,
  RewardStatsQueryVariables,
  RewardStatsSftQuery,
  RewardStatsSftQueryVariables,
} from "../../../../../generated/graphql/graphql.generated"
import { contractAddr } from "../../../../../generated/smartContractHelpers/asSender"
import { fromUrqlSource } from "../../../../../utils/Observable/fromUrqlSource"
import { gqlQuery } from "../../../../../utils/graphqlHelpers"
import { StakingToken } from "../ManualStakeStore.service"

interface StakingTokenStats {
  [cycle: number]: {
    totalStaked: number
    totalReward: number
    rewardPerStakedUnit: number
  }
}

export function stakingTokenStats(
  token: StakingToken,
): Observable<StakingTokenStats> {
  if (typeof token !== "string") {
    return fromUrqlSource(
      gqlQuery<RewardStatsSftQuery, RewardStatsSftQueryVariables>(
        gql`
          query RewardStatsSft(
            $token: String!
            $tokenId: numeric!
            $deployer: String!
            $contract: String!
          ) {
            laplace_sft_history_staking_stats(
              where: { token: { _eq: $contract }, token_id: { _eq: $tokenId } }
            ) {
              cycle
              total_staked
            }
            laplace_latest_alex_reserve_pool_sft_by_pk(
              token_deployer_address: $deployer
              token_name: $token
              token_id: $tokenId
            ) {
              coinbase_amount_1
              coinbase_amount_2
              coinbase_amount_3
              coinbase_amount_4
              coinbase_amount_5
              staking_stats
              current_cycle
              token_halving_cycle
            }
          }
        `,
        {
          token: token.currency,
          deployer: CONTRACT_DEPLOYER,
          contract: contractAddr(token.currency),
          tokenId: token.tokenId,
        },
      ),
      ({ data }) => {
        const result: StakingTokenStats = {}
        const reservePool = data.laplace_latest_alex_reserve_pool_sft_by_pk
        if (reservePool == null) {
          return result
        }
        const coinbases = [
          reservePool.coinbase_amount_1,
          reservePool.coinbase_amount_2,
          reservePool.coinbase_amount_3,
          reservePool.coinbase_amount_4,
          reservePool.coinbase_amount_5,
        ].map(Number)
        const futureStats = reservePool.staking_stats.map(Number)
        for (const cycle of range(0, reservePool.current_cycle + 33)) {
          const totalStaked =
            futureStats[cycle - reservePool.current_cycle - 1] ??
            data.laplace_sft_history_staking_stats.find(t => t.cycle === cycle)
              ?.total_staked ??
            0
          const totalReward =
            coinbases[
              Math.floor((cycle - 1) / reservePool.token_halving_cycle)
            ]!
          const rewardPerStakedUnit =
            totalStaked === 0 ? 0 : totalReward / totalStaked
          result[cycle] = {
            totalStaked: totalStaked / 1e8,
            totalReward: totalReward / 1e8,
            rewardPerStakedUnit,
          }
        }
        return result
      },
    )
  }
  return fromUrqlSource(
    gqlQuery<RewardStatsQuery, RewardStatsQueryVariables>(
      gql`
        query RewardStats(
          $token: String!
          $deployer: String!
          $contract: String!
        ) {
          laplace_history_staking_stats(where: { token: { _eq: $contract } }) {
            cycle
            total_staked
          }
          laplace_latest_alex_reserve_pools_by_pk(
            token_deployer_address: $deployer
            token_name: $token
          ) {
            coinbase_amount_1
            coinbase_amount_2
            coinbase_amount_3
            coinbase_amount_4
            coinbase_amount_5
            staking_stats
            current_cycle
            token_halving_cycle
          }
        }
      `,
      {
        token,
        deployer: CONTRACT_DEPLOYER,
        contract: contractAddr(token),
      },
    ),
    ({ data }) => {
      const result: StakingTokenStats = {}
      const reservePool = data.laplace_latest_alex_reserve_pools_by_pk!
      const coinbases = [
        reservePool.coinbase_amount_1,
        reservePool.coinbase_amount_2,
        reservePool.coinbase_amount_3,
        reservePool.coinbase_amount_4,
        reservePool.coinbase_amount_5,
      ].map(Number)
      const futureStats = reservePool.staking_stats.map(Number)
      for (const cycle of range(0, reservePool.current_cycle + 33)) {
        const totalStaked =
          futureStats[cycle - reservePool.current_cycle - 1] ??
          data.laplace_history_staking_stats.find(t => t.cycle === cycle)
            ?.total_staked ??
          0
        const totalReward =
          coinbases[Math.floor((cycle - 1) / reservePool.token_halving_cycle)]!
        const rewardPerStakedUnit =
          totalStaked === 0 ? 0 : totalReward / totalStaked
        result[cycle] = {
          totalStaked: totalStaked / 1e8,
          totalReward: totalReward / 1e8,
          rewardPerStakedUnit,
        }
      }
      return result
    },
  )
}
