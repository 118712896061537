import { sum } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { SlippageStore } from "../../../stores/slippageStore/SlippageStore"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { Result } from "../../../utils/Result"
import {
  FormError,
  FormErrorType,
} from "../components/depositPage/SellDepositModalContent/types"
import LendStore from "./LendStore"
import type { DepositSellPendingFormData } from "./LendStore.service"
import {
  claimDeposit,
  rollOverDeposit,
  sellPendingDeposit,
} from "./LendStore.service"

export class MyDepositModule {
  constructor(readonly store: LendStore) {
    makeObservable(this)
  }

  @observable claimConfirming = false
  @observable rolloverConfirming = false

  rollOverSlippage = new SlippageStore()

  txStore = new ConfirmTransactionStore()

  @asyncAction
  async claim(run = runAsyncAction): Promise<void> {
    try {
      this.claimConfirming = false
      const { txId } = await run(
        claimDeposit({
          stxAddress: this.store.authStore.stxAddress$,
          ytpToken: this.store.poolToken,
          expires: this.store.yieldTokenAmount$
            .map(a => a.expiry)
            .filter(e => e < this.store.currentExpiry$),
        }),
      )
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }

  @computed get rollOverAmount$(): number {
    return sum(
      this.store.yieldTokenAmount$
        .filter(a => a.expiry < this.store.currentExpiry$)
        .map(a => a.amount),
    )
  }

  @computed get rollOverLiquidityProviderFee$(): number {
    return this.store.ytpLiquidityFeeCalc("underlying", this.rollOverAmount$)
  }

  @asyncAction
  async rollOver(run = runAsyncAction): Promise<void> {
    try {
      this.rolloverConfirming = false
      const { txId } = await run(
        rollOverDeposit({
          stxAddress: this.store.authStore.stxAddress$,
          ytpToken: this.store.poolToken,
          expires: this.store.yieldTokenAmount$
            .map(a => a.expiry)
            .filter(e => e < this.store.currentExpiry$),
          currentExpiry: this.store.currentExpiry$,
        }),
      )
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }

  @computed get pendingBalance$(): number {
    return this.store.yieldTokenBalance$ - this.store.claimableYieldToken$
  }

  @observable sellingActiveDeposit = false
  @observable sellingActiveDepositConfirming = false
  sellAmount = new SuspenseObservable<number>()
  sellSlippage = new SlippageStore()
  @computed get uTokenPerYToken$(): number {
    return this.store.addBorrow.uTokenPerYToken$
  }
  @computed get sellingUTokenAmount$(): number {
    return this.sellAmount.read$ * this.uTokenPerYToken$
  }
  @computed get sellingAmountInUSD$(): number {
    return (
      this.sellingUTokenAmount$ *
      this.store.currencyStore.getPrice$(this.store.underlyingToken)
    )
  }
  @computed get yieldTokenUSDPrice$(): number {
    return (
      this.uTokenPerYToken$ *
      this.store.currencyStore.getPrice$(this.store.underlyingToken)
    )
  }

  @computed get liquidityFee$(): number {
    return this.store.ytpLiquidityFeeCalc("yield", this.sellAmount.read$)
  }

  @action cancelSellingActive(): void {
    this.sellingActiveDeposit = false
    this.sellAmount.set(undefined)
    this.sellSlippage.reset()
  }

  @computed get sellFormData$(): Result<DepositSellPendingFormData, FormError> {
    if (this.sellAmount.get() == null) {
      return Result.error({
        type: FormErrorType.AmountIsEmpty,
        message: "Enter amount",
      })
    }
    if (this.sellAmount.read$ > this.pendingBalance$) {
      return Result.error({
        type: FormErrorType.InsufficientTokenBalance,
        message: "Insufficient balance",
      })
    }
    return Result.ok({
      expiry: this.store.currentExpiry$,
      poolToken: this.store.poolToken,
      stxAddress: this.store.authStore.stxAddress$,
      amount: this.sellAmount.read$,
      yTokenPriceInUToken: this.uTokenPerYToken$,
      slippage: this.sellSlippage.slippagePercentage,
    })
  }

  @asyncAction
  async sellPending(
    formData: DepositSellPendingFormData,
    run = runAsyncAction,
  ): Promise<void> {
    try {
      this.cancelSellingActive()
      const { txId } = await run(sellPendingDeposit(formData))
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }
}
