import { FC } from "react"
import { DepositClaimRowData } from "../../../../store/transformerGroups/lend/depositClaim"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { OneLineAmount } from "../../OneLineAmount"
import { BaseRow } from "../BaseRow"

export const DepositClaimRow: FC<{
  rowData: DepositClaimRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.yieldCurrencyAmount]}
        toCurrencyAmounts={[rowData.tokenCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <BaseCellContainer>
        {rowData.claimCurrencyAmounts.map((c, i) => (
          <>
            <OneLineAmount
              key={i}
              fromCurrencyAmounts={[c.from]}
              toCurrencyAmounts={[c.to]}
              allTokenInfos={allTokenInfos}
            />
          </>
        ))}
      </BaseCellContainer>
    }
    tradeInfoCell={
      <BaseCellContainer>
        {rowData.expiries.map(e => (
          <p key={e}>#{e}</p>
        ))}
      </BaseCellContainer>
    }
  />
)
