import { FC } from "react"
import { Truncatable } from "../../../../../components/Truncatable"
import { trunc } from "../../../../../utils/addressHelpers"
import { BaseCellContainer } from "./BaseCellContainer"

/**
 * shows the type of the transaction and truncated transaction id
 */
export const TypeCell: FC<{
  type: string
  id: string
}> = props => (
  <BaseCellContainer className="flex flex-col gap-y-[5px]">
    <span className="font-semibold">{props.type}</span>
    <Truncatable title={props.id} className="text-gray-500 max-w-[6rem]">
      {trunc(props.id)}
    </Truncatable>
  </BaseCellContainer>
)
