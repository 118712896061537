import { FC, useRef } from "react"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { WiredBridgePanel } from "../WrapBridge/components/WiredBridgePanel"
import { WiredMetaMaskTransactionStateModal } from "../WrapBridge/components/WiredMetaMaskTransactionStateModal"
import { WiredWrapBridgeHistoryContent } from "../WrapBridge/components/WiredWrapBridgeHistoryContent"
import { WiredWrapBridgeRoute } from "../WrapBridge/components/WiredWrapBridgeRoute"
import {
  useWrapBridgeStore,
  WrapBridgeStoreProvider,
} from "../WrapBridge/store/useWrapBridgeStore"
import { ConnectorBg } from "./components/ConnectorBg/ConnectorBg"
import { NewBrandFooter } from "./components/NewBrandFooter/NewBrandFooter"
import { NewBrandHeader } from "./components/NewBrandHeader"
import styles from "./NewBrandWrapBridgePage.module.scss"

const WiredNewBrandWrapBridgePageContent: FC = () => {
  const { wrapForm } = useWrapBridgeStore()
  const historyOrderRef = useRef<HTMLDivElement>(null)

  return (
    <div className="flex flex-col gap-8 mt-[50px]">
      <NewBrandHeader />
      <WiredBridgePanel
        ConnectorBg={ConnectorBg}
        backgroundImage={
          "linear-gradient(90deg, rgba(249, 115, 22, 0.3) 0%, rgba(11, 84, 255, 0.3) 100%), linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)"
        }
        SubmitButtonVariant={props => (
          <GradientFilledButton {...props} bgClassName={styles.mainButtonBg} />
        )}
      />
      <WiredWrapBridgeRoute withBackground={false} />
      <WiredWrapBridgeHistoryContent headerRef={historyOrderRef} />
      <NewBrandFooter />

      <WiredMetaMaskTransactionStateModal />
      <WiredTransactionStateDialog store={wrapForm.stacksTransactionStore} />
    </div>
  )
}

export const NewBrandWrapBridgePage: FC = () => {
  return (
    <WrapBridgeStoreProvider>
      <WiredNewBrandWrapBridgePageContent />
    </WrapBridgeStoreProvider>
  )
}
