import clsx from "clsx"
import { FC } from "react"
import { RoundedFlatButton } from "./RoundedFlatButton"
import { ReactComponent as UpArrowIcon } from "./_/UpArrow.svg"

export const DetailCollapseButton: FC<{
  show: boolean
  onClick?: () => void
}> = props => (
  <RoundedFlatButton
    Svg={UpArrowIcon}
    svgProps={{
      className: clsx(
        !props.show && "rotate-180",
        "transition ease-in-out duration-200",
      ),
      fill: "#C4C4C4",
    }}
    onClick={props.onClick}
  />
)
