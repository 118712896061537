import {
  ComponentType,
  Context,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from "react"

export interface ContextBridgeSymbol<T> {
  ___DO_NOT_USE_THIS_PROPERTY___: Context<T>
}

export function createContextBridgeSymbol<T>(
  context: Context<T>,
): ContextBridgeSymbol<T> {
  return { ___DO_NOT_USE_THIS_PROPERTY___: context }
}

export function useContextBridge(
  ...contextSymbols: Array<ContextBridgeSymbol<any>>
): ComponentType<{ children: ReactNode }> {
  const contextValues = contextSymbols.map(s =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useContext(s.___DO_NOT_USE_THIS_PROPERTY___),
  )

  return useMemo(
    () =>
      ({ children }: { children: ReactNode }): ReactElement =>
        contextSymbols.reduceRight(
          (acc, Context, i) => (
            <Context.___DO_NOT_USE_THIS_PROPERTY___.Provider
              value={contextValues[i]}
            >
              {acc}
            </Context.___DO_NOT_USE_THIS_PROPERTY___.Provider>
          ),
          <>{children}</>,
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...contextValues, ...contextSymbols],
  )
}
