import { FC, useRef, useState } from "react"
import { hasAny } from "../utils/arrayHelpers"
import { useOnElementResize } from "../utils/reactHelpers/useOnElementResize"

export interface LayoutRect {
  width: number
  height: number
}

export const LayoutMeasurer: FC<{
  className?: string
  children: (layout: LayoutRect) => JSX.Element
}> = props => {
  const [layout, setLayout] = useState<null | LayoutRect>(null)

  const divRef = useRef<HTMLDivElement>(null)

  useOnElementResize(divRef, entries => {
    if (!hasAny(entries)) return

    const newLayout = entries[0].contentRect

    setLayout(l => {
      if (l?.width === newLayout.width && l.height === newLayout.height) {
        return l
      }

      return newLayout
    })
  })

  return (
    <div ref={divRef} className={props.className}>
      {layout && props.children(layout)}
    </div>
  )
}
