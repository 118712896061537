import { ReactNode, useContext, useMemo } from "react"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { ConfirmDialogModalContent } from "./ConfirmDialogModalContent"
import { DialogInstance } from "./types"
import { DialogContext } from "./_/DialogContext"
import { DialogBaseController } from "./_/types"

export interface DialogExtendedController extends DialogBaseController {
  confirm(message: ReactNode): DialogInstance<void>
}

export const useDialog = (): DialogExtendedController => {
  const ctrl = useContext(DialogContext)

  if (!ctrl) {
    throw new Error("[useDialog] must be used in DialogProvider subtree")
  }

  const showConfirmDialog = usePersistFn(
    (message: ReactNode): DialogInstance<void> => {
      return ctrl.show(info => ({
        dialogContent: (
          <ConfirmDialogModalContent
            message={message}
            onConfirm={info.resolve}
            onCancel={info.dismiss}
          />
        ),
      }))
    },
  )

  return useMemo(
    () => ({
      ...ctrl,
      confirm: showConfirmDialog,
    }),
    [ctrl, showConfirmDialog],
  )
}
