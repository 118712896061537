import { trimEnd } from "lodash"
import { FC } from "react"
import { BigNumber } from "../utils/BigNumber"
import { TokenInfo } from "../utils/models/TokenInfo"
import { roundNumber } from "../utils/numberHelpers"
import { readResource, SuspenseResource } from "../utils/SuspenseResource"
import { Spensor } from "./Spensor"

export interface TokenCountProps {
  token: SuspenseResource<TokenInfo>
  count: SuspenseResource<number | BigNumber>
}

export const TokenCount: FC<TokenCountProps> = props => {
  return (
    <Spensor fallback="-">
      {() => {
        return (
          <span title={String(readResource(props.count))}>
            {formatTokenCount(
              readResource(props.token),
              readResource(props.count),
            )}
          </span>
        )
      }}
    </Spensor>
  )
}

export function formatTokenCount(
  token: TokenInfo,
  _count: number | BigNumber,
): string {
  const count = BigNumber.isBigNumber(_count)
    ? BigNumber.toNumber(_count)
    : _count

  const precision = TokenInfo.getPrecision(token)
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 20,
    minimumFractionDigits: 20,
  })

  if (count === 0) return "0"

  const trimedParts = formatter.formatToParts(count).map(p => {
    if (p.type !== "fraction") return p

    const decimals = trimEnd(p.value, "0")

    if (decimals.length > precision) {
      return { ...p, value: decimals.slice(0, precision) + "…" }
    } else {
      return { ...p, value: decimals }
    }
  })

  const nonEmptyFractionParts = trimedParts.filter(
    p => p.type === "fraction" && p.value !== "",
  )

  if (nonEmptyFractionParts.length === 0) {
    return trimedParts
      .filter(p => p.type !== "decimal")
      .map(p => p.value)
      .join("")
  } else {
    return trimedParts.map(p => p.value).join("")
  }
}

export const roundDownTokenCount = (
  tokenCount: number,
  precision: number,
): number => {
  return roundNumber(tokenCount, {
    precision,
    rounder: Math.floor,
  })
}
