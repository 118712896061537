import {
  BaseRowData,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = [
  "register-user-helper-v1-01",
  "register-user-helper-v1-02",
  "register-user-helper-v1-03",
] as const
const functionName = "register-and-deposit" as const

export interface OrderBookDepositRowData extends BaseRowData {
  type: NotifyTransactionType.OrderBookRegister
}

export const orderBookRowDataTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  OrderBookDepositRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.OrderBookRegister,
  confirmedTransformer: helpers => {
    return {}
  },
  pendingOrFailedTransformer: args => {
    return {}
  },
}
