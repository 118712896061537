import {
  AddressTxsUtxo,
  Block,
  FeesRecommended,
  Tx,
} from "@mempool/mempool.js/lib/interfaces"
import mempoolWs from "@mempool/mempool.js/lib/services/ws/client"
import { Observable, combineLatest, map, switchMap } from "rxjs"
import { BigNumber } from "../../../../../utils/BigNumber"
import {
  BitcoinNetwork,
  BitcoinNetworkBasicInfo,
  BlockInfo,
  FeesInfo,
  TransactionInfo,
  UTXOWithConfirmation,
} from "./BitcoinClient.types"
import { getMempoolAPIPrefix, mempoolFetch } from "./request.service"

function transformToBlockInfo(block: Block): BlockInfo {
  return {
    nonce: block.nonce,
    height: block.height,
    unixTimestamp: block.timestamp,
    blockHash: block.id,
    previousBlockHash: block.previousblockhash,
  }
}

function transformToFeeInfo(fees: FeesRecommended): FeesInfo {
  return fees as any
}

export const fetchBitcoinNetworkBasicInfo = (
  network: BitcoinNetwork,
): Observable<BitcoinNetworkBasicInfo> => {
  return combineLatest(
    mempoolFetch<Block[]>({ network, path: "/v1/blocks" }),
    mempoolFetch<FeesRecommended>({ network, path: "/v1/fees/recommended" }),
  ).pipe(
    map(([blocks, fees]) => ({
      block: transformToBlockInfo(blocks[0]!),
      fees: transformToFeeInfo(fees),
    })),
  )
}
export const bitcoinNetworkBasicInfo = (
  network: BitcoinNetwork,
): Observable<Partial<BitcoinNetworkBasicInfo>> =>
  new Observable(ob => {
    const ws = mempoolWs(
      ["blocks", "address-transactions", "block-transactions"],
      `wss://${getMempoolAPIPrefix(network)}v1/ws`,
    )

    ws.addEventListener("message", incoming)
    return () => {
      ws.removeEventListener("message", incoming)
    }

    function incoming(e: MessageEvent): void {
      const data = JSON.parse(e.data.toString())
      if (data.block) ob.next({ block: transformToBlockInfo(data.block) })
      if (data.fees) ob.next({ fees: transformToFeeInfo(data.fees) })
    }
  })

export const fetchTransactionInfo = (
  network: BitcoinNetwork,
  txId: string,
): Observable<TransactionInfo> => {
  return mempoolFetch<Tx>({
    network,
    path: `/tx/${txId}`,
  }).pipe(
    map(data => ({
      ...data,
      blockHeight: data.status.confirmed ? data.status.block_height : undefined,
    })),
  )
}

export const walletUnspentUTXOs = (
  network: BitcoinNetwork,
  blockHeight: Observable<number>,
  address: string,
): Observable<UTXOWithConfirmation[]> => {
  return blockHeight.pipe(
    switchMap(() =>
      mempoolFetch<AddressTxsUtxo[]>({
        network,
        path: `/address/${address}/utxo`,
      }),
    ),
    map(mempoolResp =>
      mempoolResp.map(
        (item): UTXOWithConfirmation => ({
          txId: item.txid,
          index: item.vout,
          amount: BigNumber.from(item.value),
          blockHeight:
            item.status.block_height == null
              ? undefined
              : item.status.block_height,
        }),
      ),
    ),
  )
}

export const broadcastSignedTransaction = (
  network: BitcoinNetwork,
  transactionHex: string,
): Observable<{ txId: string }> => {
  return mempoolFetch<string>({
    network,
    method: "post",
    path: "/tx",
    body: transactionHex,
    parseResponse: resp => resp.text(),
  }).pipe(map(resp => ({ txId: resp })))
}
