import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardDivider } from "../../../../../components/Card"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIconProps } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { PlainIconButton } from "../../../../../components/button/variants/PlainIconButton"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  ClaimTokenInfoVerticalList,
  StakingSummaryList,
  StakingSummaryListItem,
} from "../../../components/StakingSummaryList"
import { ReactComponent as OpenIcon } from "./open.svg"

export const MyStakingSummary: FC<{
  className?: string
  gapClassName?: string
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  autoAlexTokenCount: number
  autoStakingAPY: number
  manualStakingAlexTokenCount: number
  manualStakingAPR: number
  autoAlexApowerToBeDistributed?: number
  onShowApowerDistribution?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <StakingSummaryList
      className={props.className}
      gapClassName={props.gapClassName ?? "gap-6"}
    >
      <StakingSummaryListItem
        titleClassName={"flex flex-col"}
        title={$t(
          defineMessage({
            defaultMessage: "Auto Staking(V2)",
            description:
              "/Stake/MixedStake/MyStakingSection/Summary List item title",
          }),
        )}
        detail={
          <ClaimTokenInfoVerticalList
            tokenIconSize={TokenIconProps.defaultSize}
            countTextClassName={
              "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
            }
            infos={[
              { token: props.autoAlexToken, count: props.autoAlexTokenCount },
            ]}
          />
        }
      />
      <StakingSummaryListItem
        titleClassName={"flex flex-col"}
        title={$t(
          defineMessage({
            defaultMessage: "APY",
            description:
              "/Stake/MixedStake/MyStakingSection/Summary List item title",
          }),
        )}
        detail={
          <span
            className={
              "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
            }
          >
            <PercentNumber number={props.autoStakingAPY} />
          </span>
        }
      />
      {props.autoAlexApowerToBeDistributed != null && (
        <StakingSummaryListItem
          titleClassName="flex flex-row gap-x-2.5"
          title={
            <>
              <span>
                {$t(
                  defineMessage({
                    defaultMessage: "APower to be Distributed",
                    description:
                      "/Stake/MixedStake/MyStakingSection/Summary List item title",
                  }),
                )}
              </span>
              <PlainIconButton
                icon={<OpenIcon />}
                onClick={props.onShowApowerDistribution}
              />
            </>
          }
          detail={
            <span>
              <TokenCount
                token={TokenInfoPresets.APower}
                count={props.autoAlexApowerToBeDistributed}
              />{" "}
              <TokenName token={TokenInfoPresets.APower} />
            </span>
          }
        />
      )}
      <CardDivider />
      <StakingSummaryListItem
        titleClassName={"flex flex-col"}
        title={$t(
          defineMessage({
            defaultMessage: "Manual Staking",
            description:
              "/Stake/MixedStake/MyStakingSection/Summary List item title",
          }),
        )}
        detail={
          <ClaimTokenInfoVerticalList
            tokenIconSize={TokenIconProps.defaultSize}
            countTextClassName={
              "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
            }
            infos={[
              {
                token: props.alexToken,
                count: props.manualStakingAlexTokenCount,
              },
            ]}
          />
        }
      />
      <StakingSummaryListItem
        title={$t(
          defineMessage({
            defaultMessage: "APR",
            description:
              "/Stake/MixedStake/MyStakingSection/Summary List item title",
          }),
        )}
        detail={
          <span
            className={
              "text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]"
            }
          >
            <PercentNumber number={props.manualStakingAPR} />
          </span>
        }
      />
    </StakingSummaryList>
  )
}
