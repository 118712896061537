import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../../../components/NavLink"
import { BasicCardTab } from "./basicCardTab"

const BasicTab: FC<{
  className?: string
  link: string
  icon: ReactNode
  text: ReactNode
  color?: string
}> = props => (
  <NavLink className={props.className} to={props.link}>
    <BasicCardTab
      className={"flex flex-row items-center"}
      style={props.color == null ? {} : { color: props.color }}
    >
      {props.icon}
      &nbsp;
      {props.text}
      &nbsp;
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="#9CA3AF"
      >
        <path d="M10.5 6.5V11.5H0V2H6V3H1V10.5H9.5V6.5H10.5ZM12 0.5H6.506L8.5235 2.5L5.035 6.035L6.449 7.449L9.9375 3.914L12 6V0.5Z" />
      </svg>
    </BasicCardTab>
  </NavLink>
)

export const VoteUpcomingTab: FC<{
  className?: string
  link: string
}> = props => {
  const { $t } = useIntl()
  return (
    <BasicTab
      className={props.className}
      link={props.link}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
        >
          <path d="M6 0C2.6865 0 0 2.6865 0 6C0 9.3135 2.6865 12 6 12C9.3135 12 12 9.3135 12 6C12 2.6865 9.3135 0 6 0ZM9 7H5.5V3H6.5V6H9V7Z" />
        </svg>
      }
      text={$t(
        defineMessage({
          defaultMessage: "Voting soon",
          description: "/Launchpad/IDO Vote Badge/Waiting to start",
        }),
      )}
    />
  )
}

export const VoteOngoingTab: FC<{
  className?: string
  link: string
}> = props => {
  const { $t } = useIntl()
  return (
    <BasicTab
      className={props.className}
      link={props.link}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
        >
          <path d="M9.74943 6.60034H9.34146L8.14153 7.80026H9.28746L10.3494 9.00019H1.94992L3.01786 7.80026H4.24778L3.04785 6.60034H2.54989L0.75 8.40023V10.8001C0.75 11.46 1.28397 12 1.94392 12H10.3494C11.0094 12 11.5493 11.466 11.5493 10.8001V8.40023L9.74943 6.60034ZM9.14947 3.57053L6.17966 6.54034L4.05579 4.41648L7.0256 1.44667L9.14947 3.57053ZM6.60563 0.174746L2.78387 3.9965C2.54989 4.23049 2.54989 4.60847 2.78387 4.84245L5.75368 7.81226C5.98767 8.04625 6.36565 8.04625 6.59963 7.81226L10.4154 3.9965C10.6494 3.76252 10.6494 3.38454 10.4154 3.15056L7.44558 0.180746C7.21759 -0.0592391 6.83962 -0.0592391 6.60563 0.174746Z" />
        </svg>
      }
      text={$t(
        defineMessage({
          defaultMessage: "Voting in progress",
          description: "/Launchpad/IDO Vote Badge/Voting in progress",
        }),
      )}
    />
  )
}

export const VoteApprovedTab: FC<{
  className?: string
  link: string
}> = props => {
  const { $t } = useIntl()
  return (
    <BasicTab
      className={props.className}
      link={props.link}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
        >
          <path d="M4.78846 10.3269L0.75 6.04696L1.87996 4.89237L4.76706 7.91031L10.0752 2.25L11.25 3.38319L4.78846 10.3269Z" />
        </svg>
      }
      text={$t(
        defineMessage({
          defaultMessage: "Approved",
          description: "/Launchpad/IDO Vote Badge/Approved",
        }),
      )}
    />
  )
}

export const VoteRejectedTab: FC<{
  className?: string
  link: string
}> = props => {
  const { $t } = useIntl()
  return (
    <BasicTab
      className={props.className}
      link={props.link}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
        >
          <path d="M7.40479 5.81554L11.094 2.09797C11.2643 1.9277 11.2643 1.6723 11.094 1.50203L10.5264 0.906081C10.3561 0.735811 10.1007 0.735811 9.93047 0.906081L6.2129 4.62365C6.09939 4.73716 5.92911 4.73716 5.8156 4.62365L2.09803 0.877703C1.92776 0.707432 1.67236 0.707432 1.50209 0.877703L0.906142 1.47365C0.735872 1.64392 0.735872 1.89932 0.906142 2.06959L4.62371 5.78716C4.73722 5.90068 4.73722 6.07095 4.62371 6.18446L0.877764 9.9304C0.707493 10.1007 0.707493 10.3561 0.877764 10.5264L1.47371 11.1223C1.64398 11.2926 1.89939 11.2926 2.06966 11.1223L5.78722 7.40473C5.90074 7.29122 6.07101 7.29122 6.18452 7.40473L9.90209 11.1223C10.0724 11.2926 10.3278 11.2926 10.498 11.1223L11.094 10.5264C11.2643 10.3561 11.2643 10.1007 11.094 9.9304L7.40479 6.21284C7.29128 6.09932 7.29128 5.92905 7.40479 5.81554Z" />
        </svg>
      }
      text={$t(
        defineMessage({
          defaultMessage: "Rejected",
          description: "/Launchpad/IDO Vote Badge/Rejected",
        }),
      )}
    />
  )
}
