import { FC, useCallback } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../components/NavLink"
import { usePathGenerator } from "../../../routes/routes"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { useLocalStorageState } from "../../../utils/reactHelpers/useLocalStorageState"
import {
  MarketOption,
  MarketSelector,
  MarketSelectorTabType,
} from "../components/MarketSelector"
import { OrderbookMarket } from "../store/stxdx_shared/StxDxMarket.service"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredMarketSelector: FC<{ className?: string }> = props => {
  const store = useOrderbookStore()
  const g = usePathGenerator()
  const { $t } = useIntl()
  const currency = store.currency
  const getInfo = useCallback(
    (market: OrderbookMarket): MarketOption => ({
      priceToken: currency.getTokenInfo$(market.priceToken),
      tradeToken: currency.getTokenInfo$(market.tradeToken),
      tradeTokenLastPrice: suspenseResource(
        () => store.info.marketSummary$(market.marketId).lastPrice,
      ),
      tradeToken24HourChangePercentage: suspenseResource(
        () => store.info.marketSummary$(market.marketId).price24hChangePercent,
      ),
      link: g.orderbookDetail(market.marketId),
    }),
    [currency, g, store],
  )

  const [tab, setTab] = useLocalStorageState<MarketSelectorTabType>(
    "WiredMarketSelector",
    () => "all",
  )

  return (
    <MarketSelector
      className={props.className}
      selectedOption={getInfo(store.market.read$)}
      marketOptions={suspenseResource(() =>
        store.info.allAccessibleMarkets$
          .sort(
            (a, b) =>
              store.info.marketSummary$(b.marketId).amount24h -
              store.info.marketSummary$(a.marketId).amount24h,
          )
          .map(getInfo),
      )}
      selectedTab={tab}
      onSelectedTab={setTab}
      bottomArea={
        <div className={"px-3 py-2 flex flex-row gap-x-6 text-xs"}>
          <NavLink
            className={
              "text-blue-600 flex flex-row items-center gap-x-2.5 whitespace-nowrap"
            }
            to={g.b20TokenList()}
            target={"_blank"}
          >
            {$t(
              defineMessage({
                defaultMessage: "{externalLinkIcon} All Token List",
                description: "/Orderbook/TokenPairSelector/AllTokenList",
              }),
              {
                externalLinkIcon: (
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="#C4C4C4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14 10.668V17.3346H0V4.66797H8V6.0013H1.33333V16.0013H12.6667V10.668H14ZM16 2.66797H8.67467L11.3647 5.33464L6.71333 10.048L8.59867 11.9333L13.25 7.21997L16 10.0013V2.66797Z" />
                  </svg>
                ),
              },
            )}
          </NavLink>

          <NavLink
            className={
              "text-blue-600 flex flex-row items-center gap-x-2.5 whitespace-nowrap"
            }
            to={
              "https://blocksurvey.io/survey/t/c01fc113-4e54-4a64-92ae-52fd46b6b075/r/l"
            }
            target={"_blank"}
          >
            {$t(
              defineMessage({
                defaultMessage: "{externalLinkIcon} Preparing Listing BRC-20",
                description:
                  "/Orderbook/TokenPairSelector/PreparingListingBRC20",
              }),
              {
                externalLinkIcon: (
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="#C4C4C4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14 10.668V17.3346H0V4.66797H8V6.0013H1.33333V16.0013H12.6667V10.668H14ZM16 2.66797H8.67467L11.3647 5.33464L6.71333 10.048L8.59867 11.9333L13.25 7.21997L16 10.0013V2.66797Z" />
                  </svg>
                ),
              },
            )}
          </NavLink>
        </div>
      }
    />
  )
}
