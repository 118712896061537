import clsx from "clsx"
import { TabBar } from "../../../../components/TabBar"
import { FCC } from "../../../../utils/reactHelpers/types"
import { OrderListOrderType } from "./types"

export const gapSpacing = 2

export interface OrderbookListProps {
  className?: string
}

export const OrderbookListFrameTabbar: FCC<{
  orderType: OrderListOrderType
  onChangeOrderType: (newType: OrderListOrderType) => void
}> = props => (
  <TabBar
    tabClassName={"px-4 pb-3 text-sm leading-5 font-semibold"}
    tabs={[
      { label: "Order Book", value: OrderListOrderType.OrderbookOrders },
      { label: "Trades", value: OrderListOrderType.TradeOrders },
      { label: "My Trades", value: OrderListOrderType.MyTradeOrders },
    ]}
    selectedTab={{
      tabValue: props.orderType,
      onChange: tab => props.onChangeOrderType?.(tab.value),
    }}
  />
)

export const OrderbookListFrame: FCC<OrderbookListProps> = props => {
  return (
    <div
      className={clsx("p-3 bg-black/30 rounded-lg text-xs", props.className)}
    >
      {props.children}
    </div>
  )
}
