import type { Element, Properties, Root } from "hast"
import { hasProperty as _hasProperty } from "hast-util-has-property"
import { visit } from "unist-util-visit"

export const rehypeTransformIdHashLink = (
  transformer: (id: string) => string,
): ((tree: Root) => void) => {
  return tree => {
    visit(tree, "element", (node: Element) => {
      if (hasProperty(node, "id")) {
        node.properties.id = transformer(String(node.properties.id))
      }

      if (
        hasProperty(node, "href") &&
        String(node.properties.href).startsWith("#")
      ) {
        node.properties.href =
          "#" + transformer(String(node.properties.href).slice(1))
      }
    })
  }
}

const hasProperty: <N extends string>(
  node: Element,
  propertyName: N,
) => node is Element & { properties: Record<N, Properties[string]> } =
  _hasProperty as any
