import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GrayBadge, GreenBadge } from "../../../components/Badge"
import { Card } from "../../../components/Card"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../components/NavLink"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor, SpensorR } from "../../../components/Spensor"
import { TabBar } from "../../../components/TabBar"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenIcon } from "../../../components/TokenIcon"
import { usePathGenerator } from "../../../routes/routes"
import { SuspenseResource, readResource } from "../../../utils/SuspenseResource"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { LiquidityNumber } from "../../Farm/components/LiquidityNumber"
import { ListButton } from "../../Farm/components/ListButton"
import {
  ListItemContainer,
  ListSection,
} from "../../Farm/components/ListContainer"
import { ReactComponent as IntoIcon } from "../../Farm/components/MyFarmsPanel/into.svg"
import PoolTokenName from "../../Farm/components/PoolTokenName"
import { LiquidityInfo } from "../store/detail/PoolDetailStore.services"

export const LiquidityTipIcon: FC = () => (
  <TipIcon tip="By adding liquidity you'll earn fees from all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity." />
)

export const AllPoolListRowHeader: FC = () => {
  const { $t } = useIntl()
  return (
    <ListItemContainer
      className={
        "p-4 border-b border-gray-500/30 text-sm leading-5 font-normal text-gray-500"
      }
    >
      <HeaderListSection type={"head"}>
        {$t(
          defineMessage({
            defaultMessage: "Trading Pair",
            description: "Pool/AllPoolSection/Header text",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Liquidity",
            description: "Pool/AllPoolSection/Header text",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Volume (24h)",
            description: "Pool/AllPoolSection/Header text",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Volume (7d)",
            description: "Pool/AllPoolSection/Header text",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Fee Rebate {icon}",
            description: "Pool/AllPoolSection/Header text",
          }),
          { icon: <LiquidityTipIcon /> },
        )}
      </HeaderListSection>
      <HeaderListSection>
        {$t(
          defineMessage({
            defaultMessage: "Farm Yield",
            description: "Pool/AllPoolSection/Header text",
          }),
        )}
      </HeaderListSection>
      <HeaderListSection />
      <HeaderListSection type={"tail"} />
    </ListItemContainer>
  )
}

const HeaderListSection = withClassName(
  "flex flex-row items-center gap-2.5",
  ListSection,
)

export type AllPoolListInfoItem = LiquidityInfo & {
  poolTokenInfo: TokenInfo
  farmYield: SuspenseResource<number | null>
}

export interface AllPoolListInfo {
  isStableSwap?: boolean
  mainPool: AllPoolListInfoItem
  legacyPool?: AllPoolListInfoItem[]
}

export const AllPoolListRow: FC<{
  className?: string
  pool: AllPoolListInfo
}> = ({ pool, className }) => {
  const gen = usePathGenerator()
  const info = pool.mainPool

  if (pool.legacyPool == null || pool.legacyPool.length === 0) {
    const token = pool.mainPool.poolTokenInfo
    return (
      <NavLink to={gen.poolDetail(token)} className={className}>
        <ListItemContainer
          className={clsx(
            "group p-4 border-b border-gray-500/30 text-gray-200 hover:bg-black/10 active:bg-black/20 w-full",
          )}
        >
          <ListSection
            type={"head"}
            className={"flex flex-row items-center gap-2.5"}
          >
            <TokenIcon token={token} />
            <PoolTokenName
              className={"text-sm leading-5 font-medium truncate"}
              token={token}
            />
          </ListSection>
          <PoolInfo info={info} farmYield={info.farmYield} />
        </ListItemContainer>
      </NavLink>
    )
  }

  return (
    <div className="flex px-4 py-2 border-b border-gray-500/30 gap-x-2">
      <div className={"flex flex-row items-center gap-2.5 w-[180px]"}>
        <TokenIcon token={pool.mainPool.poolTokenInfo} />

        <PoolTokenName
          className={"text-sm leading-5 font-medium truncate"}
          token={pool.mainPool.poolTokenInfo}
        />
      </div>
      <div className="flex-1">
        {[pool.mainPool, ...pool.legacyPool].map((p, index) => (
          <NavLink
            key={p.poolTokenInfo.id}
            to={gen.poolDetail(p.poolTokenInfo)}
          >
            <ListItemContainer
              className={clsx(
                "group py-3 pl-2 hover:bg-black/10 active:bg-black/20 w-full flex-row border-l border-gray-500/30",
                index === 0 ? "text-gray-200" : "text-gray-500",
              )}
            >
              <div className="w-[60px]">
                {index === 0 ? (
                  <GreenBadge>Main</GreenBadge>
                ) : (
                  <GrayBadge>Migrating</GrayBadge>
                )}
              </div>
              <PoolInfo
                info={p}
                farmYield={p.farmYield}
                showTrendDown={index !== 0}
              />
            </ListItemContainer>
          </NavLink>
        ))}
      </div>
    </div>
  )
}

const PoolInfo: FC<{
  info: LiquidityInfo
  farmYield: SuspenseResource<number | null>
  showTrendDown?: boolean
}> = ({ info, farmYield, showTrendDown }) => {
  const { $t } = useIntl()
  return (
    <>
      <ListSection className={"uppercase"}>
        $&nbsp;
        {info.liquidity != null ? (
          <LiquidityNumber count={info.liquidity} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection className={"uppercase"}>
        $&nbsp;
        {info.volume24h != null ? (
          <LiquidityNumber count={info.volume24h} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection className={"uppercase"}>
        $&nbsp;
        {info.volume7d != null ? (
          <LiquidityNumber count={info.volume7d} />
        ) : (
          "--"
        )}
      </ListSection>

      <ListSection>
        {info.apr != null ? <PercentNumber number={info.apr} /> : "--"}
      </ListSection>
      <ListSection className={clsx("flex items-center gap-x-1")}>
        <SpensorR read={{ farm: farmYield }} fallback="--">
          {({ farm }) =>
            farm == null ? (
              "--"
            ) : (
              <>
                {farmYield != null ? (
                  <span
                    className={clsx(
                      showTrendDown && "underline decoration-dashed",
                    )}
                  >
                    <PercentNumber number={farm} />
                  </span>
                ) : (
                  "--"
                )}
              </>
            )
          }
        </SpensorR>
      </ListSection>

      <ListSection className={"flex items-center justify-center"}>
        <ListButton className={"invisible group-hover:visible"}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="#111827"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.5 5.25H6.75V1.5H5.25V5.25H1.5V6.75H5.25V10.5H6.75V6.75H10.5V5.25Z" />
          </svg>
          {$t(
            defineMessage({
              defaultMessage: "Liquidity",
              description: "Pool/AllPoolSection/Button text",
            }),
          )}
        </ListButton>
      </ListSection>

      <ListSection type={"tail"} className={"flex items-center justify-end"}>
        <IntoIcon className={"opacity-40"} />
      </ListSection>
    </>
  )
}

export const AllPoolsSection: FC<{
  pools: SuspenseResource<AllPoolListInfo[]>
}> = props => {
  const { $t } = useIntl()
  const [tab, setTab] = useState<"all" | "swap" | "stable">("all")

  return (
    <Card>
      <TabBar
        className="border-transparent border-b-0"
        containerClassName="gap-x-8"
        selectedTab={{
          tabValue: tab,
          onChange: x => setTab(x.value),
        }}
        tabs={[
          {
            label: $t(
              defineMessage({
                defaultMessage: "All",
                description: "Pool/AllPoolSection/Card text",
              }),
            ),
            value: "all" as const,
          },
          {
            label: $t(
              defineMessage({
                defaultMessage: "Swap",
                description: "Pool/AllPoolSection/Card text",
              }),
            ),
            value: "swap" as const,
          },
          {
            label: $t(
              defineMessage({
                defaultMessage: "StableSwap",
                description: "Pool/AllPoolSection/Card text",
              }),
            ),
            value: "stable" as const,
          },
        ]}
      />
      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() => (
          <div className="w-full overflow-x-auto">
            <div className={"min-w-[900px]"}>
              <AllPoolListRowHeader />
              {readResource(props.pools)
                .filter(
                  x =>
                    tab === "all" ||
                    (tab === "swap" && !x.isStableSwap) ||
                    (tab === "stable" && x.isStableSwap),
                )
                .map(pool => (
                  <AllPoolListRow
                    key={pool.mainPool.poolTokenInfo.id}
                    pool={pool}
                  />
                ))}
            </div>
          </div>
        )}
      </Spensor>
    </Card>
  )
}
