import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { PercentNumber } from "../../../components/PercentNumber"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../components/TokenCount"
import { TokenName } from "../../../components/TokenName"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../utils/mobxHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface FeeTooltipProps {
  direction: "wrap" | "unwrap"
  feeToken: SuspenseResource<TokenInfo>
  feeRate: SuspenseResource<number>
  sourceToken: SuspenseResource<TokenInfo>
  targetToken: SuspenseResource<TokenInfo>
  minFeeAmount: SuspenseResource<number>
}

export const FeeTooltip: FC<FeeTooltipProps> = props => {
  const { $t } = useIntl()
  return (
    <TipIcon
      inline
      tip={
        <>
          <div>
            {$t(
              defineMessage({
                defaultMessage: `<headLine>{direction, select, wrap {Wrap} other {Unwrap}} are subject to {feeRate} fee.</headLine>
                    <list>
                      <item>
                        {minFeeAmountForSelect, plural, =0 {There are no minimum fee for {direction, select,
                            wrap {wrapping}
                            other {unwrapping}
                          } {sourceToken} to {targetToken}}
                          other {Minimum {direction, select,
                            wrap {wrap}
                            other {unwrap}
                          } fee is {minFeeAmountForDisplay} {feeToken}}
                        }
                      </item>
                    </list>`,
                description: "/Bridge/PanelDetails/Fee tool tip",
              }),
              {
                headLine: dontWrapObserver(contents => <p>{contents}</p>),
                list: dontWrapObserver(contents => (
                  <ul className={"list-disc list-inside"}>{contents}</ul>
                )),
                item: dontWrapObserver(contents => <li>{contents}</li>),
                direction: props.direction,
                sourceToken: (
                  <Spensor fallback={"--"}>
                    {() => (
                      <TokenName token={readResource(props.sourceToken)} />
                    )}
                  </Spensor>
                ),
                targetToken: (
                  <Spensor fallback={"--"}>
                    {() => (
                      <TokenName token={readResource(props.targetToken)} />
                    )}
                  </Spensor>
                ),
                minFeeAmountForSelect:
                  safeReadResource(props.minFeeAmount) ?? "--",
                minFeeAmountForDisplay: (
                  <Spensor fallback={"--"}>
                    {() => (
                      <TokenCount
                        token={readResource(props.feeToken)}
                        count={readResource(props.minFeeAmount)}
                      />
                    )}
                  </Spensor>
                ),
                feeToken: (
                  <Spensor>
                    {() => <TokenName token={readResource(props.feeToken)} />}
                  </Spensor>
                ),
                feeRate: (
                  <Spensor fallback={"--"}>
                    {() => (
                      <PercentNumber number={readResource(props.feeRate)} />
                    )}
                  </Spensor>
                ),
              },
            )}
          </div>
        </>
      }
    />
  )
}
