import * as React from "react"
import { FC, ReactNode, useCallback } from "react"
import {
  NavLink as RouteNavLink,
  NavLinkProps as _NavLinkProps,
  resolvePath,
  To,
  useLocation,
} from "react-router-dom"
import { usePersistFn } from "../utils/reactHelpers/usePersistFn"

export interface NavLinkProps extends Omit<_NavLinkProps, "children"> {
  children?: ReactNode | ((props: { isActive: boolean }) => ReactNode)
}

export const NavLink: FC<NavLinkProps> = props => {
  const {
    children: propChildren,
    style: propStyle,
    className: propClassName,
    to: propTo,
    ...rest
  } = props

  const checkIsActive = useCheckIsActive()

  const to = propTo ?? ""

  const isGoToOuterWorld = typeof to === "string" && isURI(to)
  const isHashLink = typeof to === "string" && to.startsWith("#")
  const doNotUseReactRouter = isGoToOuterWorld || isHashLink

  let isActive = checkIsActive(doNotUseReactRouter ? "" : to, {
    caseSensitive: props.caseSensitive,
    end: props.end,
  })
  if (doNotUseReactRouter) isActive = false

  const className = getPropResult({ isActive }, propClassName)
  const style = getPropResult({ isActive }, propStyle)
  const children = getPropResult({ isActive }, propChildren)

  if (isGoToOuterWorld) {
    return (
      <a
        target={"_blank"}
        rel={"noreferrer"}
        {...rest}
        style={style}
        href={to as string}
        className={className}
      >
        {children}
      </a>
    )
  }
  if (isHashLink) {
    return (
      <a {...rest} style={style} href={to as string} className={className}>
        {children}
      </a>
    )
  }

  return (
    <RouteNavLink {...rest} style={style} className={className} to={to}>
      {children}
    </RouteNavLink>
  )
}

export const NavBackLink: FC<
  { fallbackURL: string } & Omit<NavLinkProps, "to">
> = props => {
  const { fallbackURL, ...restProps } = props

  const onClick = usePersistFn(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      props.onClick?.(e)

      if (!e.defaultPrevented) {
        const historyState = window.history.state
        if (!fallbackURL || historyState?.idx > 0) {
          e.preventDefault()
          window.history.back()
        }
      }
    },
  )

  return <NavLink to={fallbackURL} {...restProps} onClick={onClick} />
}

function getPropResult<T1, T2>(
  info: T1,
  prop: undefined | T2 | ((info: T1) => T2),
): undefined | T2 {
  return typeof prop === "function" ? (prop as any)(info) : prop
}

function isURI(input: string): boolean {
  // https://datatracker.ietf.org/doc/html/rfc3986#section-3.1
  return /^[\w\d+-.]+:/.test(input)
}

// from react-router NavLink component
export function useCheckIsActive(): (
  to: To,
  options?: {
    caseSensitive?: boolean
    end?: boolean
  },
) => boolean {
  const location = useLocation()

  return useCallback(
    (to, options = {}) => {
      const path = resolvePath(to, location.pathname)

      let locationPathname = location.pathname
      let toPathname = path.pathname

      if (!options.caseSensitive) {
        locationPathname = locationPathname.toLowerCase()
        toPathname = toPathname.toLowerCase()
      }

      return (
        locationPathname === toPathname ||
        (!options.end &&
          locationPathname.startsWith(toPathname) &&
          locationPathname.charAt(toPathname.length) === "/")
      )
    },
    [location],
  )
}
