import clsx from "clsx"
import { CSSProperties, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../../../components/Card"
import { useSpacing } from "../../../../../../components/Themed/spacing"
import { FCC } from "../../../../../../utils/reactHelpers/types"
import {
  NormalizedPaddingStyle,
  PaddingStyle,
  useNormalizePaddingStyle,
} from "../../../../../../utils/styleHelpers/PaddingStyle"

export const useStepPanelPadding = (): NormalizedPaddingStyle => {
  const spacing = useSpacing()
  return useNormalizePaddingStyle(spacing(4))
}

export const StepPanel: FCC<{
  className?: string
  hairlineStyle?: CSSProperties
  stepNumber: number
  title?: ReactNode
  padding?: number | PaddingStyle
}> = props => {
  const { $t } = useIntl()

  const paddingStyle = useNormalizePaddingStyle(props.padding)
  const defaultPaddingStyle = useStepPanelPadding()

  return (
    <CardInset
      className={clsx("flex flex-col gap-y-5", props.className)}
      boxClassName={""}
      bgClassName={"bg-[#374151]"}
      style={{
        ...defaultPaddingStyle,
        ...paddingStyle,
      }}
    >
      <div
        className={clsx("flex items-start gap-x-2.5")}
        style={props.hairlineStyle}
      >
        <div
          className={clsx(
            "px-2.5 py-1 rounded-full bg-yellow-200 flex items-center justify-center flex-none",
            "text-gray-900 text-sm font-bold leading-5",
          )}
        >
          {$t(
            defineMessage({
              defaultMessage: "Step {stepNumber}",
              description:
                "/Orderbook/Brc20/DepositBrc20GuideModalContent/StepPanel",
            }),
            {
              stepNumber: props.stepNumber,
            },
          )}
        </div>

        <div className={"text-white text-lg leading-7 font-medium"}>
          {props.title}
        </div>
      </div>

      {props.children}
    </CardInset>
  )
}

export const StepPanelSection: FCC<{
  className?: string
  style?: CSSProperties
}> = props => {
  return (
    <div
      className={clsx("flex flex-col gap-y-2.5", props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  )
}
