import clsx from "clsx"
import { FC, Suspense } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInsetDivider, CardInsetTitle } from "../../../../components/Card"
import { useConnect } from "../../../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../components/NavLink"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { usePathGenerator } from "../../../../routes/routes"
import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { Currency } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { plural } from "../../../../utils/stringHelpers"
import { assertNever } from "../../../../utils/types"
import { LaunchingStatus } from "../../store/LaunchPadContractStore"
import { useLaunchPadContractStore } from "../../store/useLaunchPadStores"
import {
  HorizontalInfoListContainer,
  HorizontalInfoListItem,
} from "../InfoList"
import { MyIDOFrame } from "./components"
import { ReactComponent as CongratsIcon } from "./congrats.svg"
import { ReactComponent as FinishedIcon } from "./finished.svg"
import { ReactComponent as OopsIcon } from "./oppps.svg"

export const WiredUserIDOStatusSection: FC<{ className?: string }> = (props: {
  className?: string
}) => {
  const authStore = useAuthStore()
  const [connect] = useConnect()

  return (
    <MyIDOFrame className={props.className}>
      <Suspense fallback={<LoadingIndicator />}>
        {!authStore.isWalletConnected ? (
          <UserIDOStatusSectionContent$ConnectWallet onConnect={connect} />
        ) : (
          <WiredUserIDOStatusSectionContent />
        )}
      </Suspense>
    </MyIDOFrame>
  )
}

const WiredUserIDOStatusSectionContent: FC = () => {
  const store = useLaunchPadContractStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const module = store.userIDOViewModule
  const status = store.tokenProfileViewModule.status

  if (status.type === LaunchingStatus.Upcoming) {
    return (
      <UserIDOStatusSectionContent$Upcoming
        myAPowerCount={accountStore.getBalance$(Currency.APOWER)}
      />
    )
  }

  if (status.type === LaunchingStatus.Registration) {
    if (!module.hasValidated) {
      return (
        <UserIDOStatusSectionContent$OpenPoolNotRegistered
          aPowerBalanceCount={accountStore.getBalance$(Currency.APOWER)}
          priceToken={store.priceTokenInfo$}
        />
      )
    } else {
      return (
        <UserIDOStatusSectionContent$OpenPoolAfterRegistered
          registeredTicketCount={module.registeredTicketCount}
          priceToken={store.priceTokenInfo$}
          lockedPriceTokenCount={module.lockedPriceToken$}
        />
      )
    }
  }

  if (
    status.type === LaunchingStatus.Claim ||
    status.type === LaunchingStatus.Finished
  ) {
    if (
      !module.hasValidated ||
      (status.type === LaunchingStatus.Finished && !status.success)
    ) {
      return <UserIDOStatusSectionContent$DidNotJoin />
    }

    return (
      <UserIDOStatusSectionContent$LotteryFinished
        wonLotteryCount={module.lotteryWon$}
        idoToken={store.tokenInfo$}
        priceToken={store.priceTokenInfo$}
        returnedPriceTokenCount={module.returnedPriceTokenCount$}
        allocatedIDOTokenCount={module.allocatedTokenCount$}
        addressExplorerLink={authStore.addressExplorerLink$}
      />
    )
  }

  assertNever(status.type)
}

export const UserIDOStatusSectionContent$ConnectWallet: FC<{
  onConnect?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <CardInsetTitle>
        {$t(
          defineMessage({
            defaultMessage: "Please connect wallet first",
            description:
              "/Launchpad/UserIDOStatusSectionContent/ConnectWallet title",
          }),
        )}
      </CardInsetTitle>
      <GradientFilledButton
        className={"min-w-[7.5rem]"}
        onClick={props.onConnect}
      >
        {$t(
          defineMessage({
            defaultMessage: "Connect Wallet",
            description:
              "/Launchpad/UserIDOStatusSectionContent/ConnectWallet button text",
          }),
        )}
      </GradientFilledButton>
    </>
  )
}

export const UserIDOStatusSectionContent$Upcoming: FC<{
  myAPowerCount: number
}> = props => {
  const g = usePathGenerator()
  const { $t } = useIntl()

  return (
    <div className={"flex flex-col gap-6 items-center w-full"}>
      <CardInsetTitle>
        {$t(
          defineMessage({
            defaultMessage: "Validation not Started",
            description: "/Launchpad/UserIDOStatusSectionContent/Ucoming title",
          }),
        )}
      </CardInsetTitle>
      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          title={
            <span>
              {$t(
                defineMessage({
                  defaultMessage: "My {tokenName}",
                  description:
                    "/Launchpad/UserIDOStatusSectionContent/Ucoming title",
                }),
                { tokenName: <TokenName token={TokenInfoPresets.APower} /> },
              )}
            </span>
          }
          detail={
            <>
              <TokenCount
                token={TokenInfoPresets.APower}
                count={props.myAPowerCount}
              />
            </>
          }
        />
      </HorizontalInfoListContainer>
      <CardInsetDivider />
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "Go to {farmLink} or {stakeLink} to get more {aPowerToken}. {learnMore}",
            description: "/Launchpad/UserIDOStatusSectionContent/Ucoming note",
          }),
          {
            farmLink: (
              <NavLink className={"text-blue-600"} to={g.farmList()}>
                {$t(
                  defineMessage({
                    defaultMessage: "Farm",
                    description:
                      "/Launchpad/UserIDOStatusSectionContent/Ucoming nav link",
                  }),
                )}
              </NavLink>
            ),
            stakeLink: (
              <NavLink className={"text-blue-600"} to={g.stake()}>
                {$t(
                  defineMessage({
                    defaultMessage: "Stake",
                    description:
                      "/Launchpad/UserIDOStatusSectionContent/Ucoming nav link",
                  }),
                )}
              </NavLink>
            ),
            aPowerToken: <TokenName token={TokenInfoPresets.APower} />,
            learnMore: (
              <NavLink
                className={"text-blue-600"}
                to={
                  "https://medium.com/alexgobtc/what-is-alex-staking-power-and-how-do-i-use-it-1b3de3797fa2"
                }
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Learn more",
                    description:
                      "/Launchpad/UserIDOStatusSectionContent/Ucoming nav link",
                  }),
                )}
              </NavLink>
            ),
          },
        )}
      </NoteParagraph>
    </div>
  )
}

export const UserIDOStatusSectionContent$DidNotJoin: FC = () => {
  const { $t } = useIntl()
  return (
    <>
      <FinishedIcon />
      <CardInsetTitle>
        {$t(
          defineMessage({
            defaultMessage: "Validation Finished",
            description:
              "/Launchpad/UserIDOStatusSectionContent/DidNotJoin title",
          }),
        )}
      </CardInsetTitle>
    </>
  )
}

export const UserIDOStatusSectionContent$OpenPoolNotRegistered: FC<{
  aPowerBalanceCount: number
  priceToken: TokenInfo
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <div className={"w-full"}>
        <CardInsetTitle className={"mb-2"}>
          {$t(
            defineMessage({
              defaultMessage: "Not Validated",
              description:
                "/Launchpad/User IDO status section content open pool not registered",
            }),
          )}
        </CardInsetTitle>
        <p
          className={
            "text-sm leading-5 font-normal text-gray-500 flex items-center justify-center"
          }
        >
          {$t(
            defineMessage({
              defaultMessage: "My {aPowerToken} Balance: {count}",
              description:
                "/launchpad/User IDO status section content open pool not registered",
            }),
            {
              aPowerToken: <TokenName token={TokenInfoPresets.APower} />,
              count: (
                <TokenCount
                  token={TokenInfoPresets.IDOLotteryTicket}
                  count={props.aPowerBalanceCount}
                />
              ),
            },
          )}
          <TipIcon
            className={"ml-2.5"}
            tip={
              <>
                {$t(
                  defineMessage({
                    defaultMessage:
                      "This is the total balance of {aPowerToken} in your wallet. You can use {aPowerToken} to participate IDO.",
                    description:
                      "/launchpad/User IDO status section content open pool not registered",
                  }),
                  {
                    aPowerToken: <TokenName token={TokenInfoPresets.APower} />,
                  },
                )}
              </>
            }
          />
        </p>
      </div>
      <OpenPoolLotteryInfoGroup
        lotteryCount={0}
        lockedToken={{
          token: props.priceToken,
          count: 0,
        }}
      />
    </>
  )
}

export const UserIDOStatusSectionContent$OpenPoolAfterRegistered: FC<{
  registeredTicketCount: number
  priceToken: TokenInfo
  lockedPriceTokenCount: number
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <div className={"w-full"}>
        <CardInsetTitle className={"mb-2"}>
          {$t(
            defineMessage({
              defaultMessage: "Validated Tickets",
              description:
                "/Launchpad/UserIDOStatusSectionContent/OpenPoolAfterRegistered/Title",
            }),
          )}
        </CardInsetTitle>
      </div>
      <OpenPoolLotteryInfoGroup
        lotteryCount={props.registeredTicketCount}
        lockedToken={{
          token: props.priceToken,
          count: props.lockedPriceTokenCount,
        }}
      />
    </>
  )
}

export const UserIDOStatusSectionContent$LotteryFinished: FC<{
  idoToken: TokenInfo
  priceToken: TokenInfo
  wonLotteryCount: number
  allocatedIDOTokenCount: number
  returnedPriceTokenCount: number
  addressExplorerLink: string
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <div className={"flex flex-col items-center gap-4"}>
        {props.allocatedIDOTokenCount > 0 ? (
          <>
            <CongratsIcon />
            <CardInsetTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Congratulations!",
                  description:
                    "/Launchpad/UserIDOStatusSectionContent/LotteryFinished title",
                }),
              )}
            </CardInsetTitle>
          </>
        ) : (
          <>
            <OopsIcon />
            <CardInsetTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Oops...maybe next time",
                  description:
                    "/Launchpad/UserIDOStatusSectionContent/LotteryFinished title",
                }),
              )}
            </CardInsetTitle>
          </>
        )}
      </div>
      <CardInsetDivider />
      <p
        className={clsx(
          props.allocatedIDOTokenCount > 0 ? "text-yellow-200" : "",
          "flex flex-row items-center",
        )}
      >
        <span className={"text-5xl leading-none font-normal"}>
          <TokenCount token={props.idoToken} count={props.wonLotteryCount} />
          &nbsp;
        </span>
        <span
          className={clsx(
            "text-lg leading-7 font-normal",
            props.allocatedIDOTokenCount > 0 ? "" : "text-gray-500",
          )}
        >
          {$t(
            defineMessage({
              defaultMessage: `{wonLotteryCount, plural,
                one {Lottery}
                other {Lotteries}
              } Won`,
              description:
                "/Launchpad/UserIDOStatusSectionContent/LotteryFinished content",
            }),
            {
              wonLotteryCount: props.wonLotteryCount,
            },
          )}
        </span>
      </p>
      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          detailTextClassName={"text-lg leading-7 font-normal"}
          title={$t(
            defineMessage({
              defaultMessage: "Total Allocation",
              description:
                "/Launchpad/UserIDOStatusSectionContent/LotteryFinished info list item title",
            }),
          )}
          detail={
            <>
              <TokenCount
                token={props.idoToken}
                count={props.allocatedIDOTokenCount}
              />
              &nbsp;
              <span className={"text-xs leading-4 font-normal"}>
                {props.idoToken.propertyTags.includes("ordinals-voucher") ? (
                  plural(props.allocatedIDOTokenCount, {
                    one: "Ordinal",
                    many: "Ordinals",
                  })
                ) : (
                  <TokenName token={props.idoToken} />
                )}
              </span>
            </>
          }
        />
        <HorizontalInfoListItem
          detailTextClassName={"text-lg leading-7 font-normal"}
          title={$t(
            defineMessage({
              defaultMessage: "Returned",
              description:
                "/Launchpad/UserIDOStatusSectionContent/LotteryFinished info list item title",
            }),
          )}
          detail={
            <>
              <TokenCount
                token={props.priceToken}
                count={props.returnedPriceTokenCount}
              />
              &nbsp;
              <span className={"text-xs leading-4 font-normal"}>
                <TokenName token={props.priceToken} />
              </span>
            </>
          }
        />
      </HorizontalInfoListContainer>
    </>
  )
}

interface OpenPoolLotteryInfoGroupProps {
  className?: string
  lotteryCount: number
  lockedToken: {
    token: TokenInfo
    count: number
  }
}

const OpenPoolLotteryInfoGroup: FC<OpenPoolLotteryInfoGroupProps> = props => {
  const { $t } = useIntl()
  return (
    <HorizontalInfoListContainer className={props.className}>
      <HorizontalInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: `My {lotteryCount} {lotteryCount, plural,
              one {Lottery}
              other {Lotteries}
            }`,
            description: "/Launchpad/OpenPoolLotteryInfoGroup/titlte",
          }),
          {
            lotteryCount: props.lotteryCount,
          },
        )}
        detail={props.lotteryCount}
      />
      <HorizontalInfoListItem
        title={
          <p className={"flex flex-row items-center"}>
            {$t(
              defineMessage({
                defaultMessage: "Locked {tokenName} ",
                description:
                  "/Launchpad/OpenPoolLotteryInfoGroup/Info list Item",
              }),
              {
                tokenName: (
                  <TokenName className="mx-1" token={props.lockedToken.token} />
                ),
              },
            )}
            <TipIcon
              className={"inline-block"}
              tip={
                <>
                  {$t(
                    defineMessage({
                      defaultMessage:
                        "The Locked {tokenName} will automatically swapped when ticket won, or returned to your wallet when lose.",
                      description:
                        "/Launchpad/OpenPoolLotteryInfoGroup/Info list Item tip",
                    }),
                    {
                      tokenName: <TokenName token={props.lockedToken.token} />,
                    },
                  )}
                </>
              }
            />
          </p>
        }
        detail={props.lockedToken.count}
      />
    </HorizontalInfoListContainer>
  )
}
