import { useState } from "react"
import { IntlProvider } from "react-intl"
import { FCC } from "../../utils/reactHelpers/types"
import {
  LocaleSwitchProvider,
  LocaleSwitchProviderProps,
} from "./LocaleSwitchProvider"
import { ImportLanguage } from "./_/ImportLanguage"

const throwMissingTranslation = process.env["APP_I18N_LOG_MISSING"]

const selectedLocaleIdStorageKey = "__APP__selectedLocaleId"

export const WiredIntlProvider: FCC<{ forceLanguageTag?: string }> = props => {
  const [languageTag, setLanguageTag] = useState(() => {
    const a = localStorage.getItem(selectedLocaleIdStorageKey)
    return typeof a === "string" ? a : navigator.language
  })

  const onLocaleSwitch: NonNullable<
    LocaleSwitchProviderProps["onLocaleSwitch"]
  > = async (info): Promise<void> => {
    localStorage.setItem(selectedLocaleIdStorageKey, info.languageTag)
    setLanguageTag(info.languageTag)
  }

  return (
    <ImportLanguage
      languageTag={props.forceLanguageTag ?? languageTag}
      availableLanguages={languages}
    >
      {info => (
        <LocaleSwitchProvider
          currentLocale={info?.language ?? defaultLanguage}
          availableLocales={languages}
          onLocaleSwitch={onLocaleSwitch}
        >
          <IntlProvider
            locale={info?.language.languageTag ?? defaultLanguage.languageTag}
            messages={info?.messages}
            defaultLocale={defaultLanguage.languageTag}
            defaultRichTextElements={{
              strong: chunks => (
                <span style={{ fontWeight: "700" }}>{chunks}</span>
              ),
            }}
            onError={err => {
              if (err.code === "MISSING_TRANSLATION") {
                if (throwMissingTranslation) {
                  console.error(err)
                }
              } else {
                throw err
              }
            }}
          >
            {props.children}
          </IntlProvider>
        </LocaleSwitchProvider>
      )}
    </ImportLanguage>
  )
}

export const defaultLanguage = {
  languageTag: "en-US",
  displayName: "English",
  regex: /.*/,
  content: () =>
    import("../../generated/i18n/compiled/en-US.json").then(a => a.default),
} as const

export const languages = [
  {
    languageTag: "zh-CN",
    displayName: "简体中文",
    regex: /^zh\b/,
    content: () =>
      import("../../generated/i18n/compiled/zh-CN.json").then(a => a.default),
  },
  {
    languageTag: "ko-KR",
    displayName: "한국어",
    regex: /^ko\b/,
    content: () =>
      import("../../generated/i18n/compiled/ko-KR.json").then(a => a.default),
  },
  defaultLanguage,
] as const
