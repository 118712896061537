import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import LogoImgSrc from "./_/logo.svg"

export const NewBrandFooter: FC<{ className?: string }> = props => {
  const { $t } = useIntl()
  return (
    <div
      className={clsx(
        "flex items-center justify-center gap-[15px]",
        props.className,
      )}
    >
      <span className={"text-gray-500"}>
        {$t(
          defineMessage({
            defaultMessage: "Powered by ALEX",
            description: "/Bridge/New Brand Footer",
          }),
        )}
      </span>
      <img width={120} src={LogoImgSrc} alt={"ALEX Logo"} />
    </div>
  )
}
