import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { Modal } from "../Modal"
import { DisclaimerModalContent } from "./DisclaimerModalContent"

const storageKey = `WiredDisclaimerModal-accepted-version`
const currVersion = "1"

export const WiredDisclaimerModal: FC = () => {
  const { prevented } = useContext(ModalPreventProviderContext)

  const [visible, setVisible] = useState(false)

  const acceptedVersion = localStorage.getItem(storageKey)

  const onAccept = usePersistFn(() => {
    localStorage.setItem(storageKey, currVersion)
    setVisible(false)
  })

  const tryToShowModal = usePersistFn(() => {
    if (acceptedVersion !== currVersion && !prevented) {
      setVisible(true)
    }
  })

  useEffect(() => {
    // give some time to `usePreventGlobalWiredDisclaimerModal`
    setTimeout(tryToShowModal)
  }, [tryToShowModal])

  return (
    <Modal visible={visible}>
      <DisclaimerModalContent onAccept={onAccept} />
    </Modal>
  )
}

const ModalPreventProviderContext = createContext({
  prevented: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  setPrevented: (prevented: boolean) => {},
})

export const WiredDisclaimerModalPreventProvider: FCC = props => {
  const [prevented, setPrevented] = useState(false)

  return (
    <ModalPreventProviderContext.Provider
      value={useMemo(() => ({ prevented, setPrevented }), [prevented])}
    >
      {props.children}
    </ModalPreventProviderContext.Provider>
  )
}

export const usePreventGlobalWiredDisclaimerModal = (): void => {
  const { setPrevented } = useContext(ModalPreventProviderContext)

  useEffect(() => {
    setPrevented(true)
  }, [setPrevented])
}
