import {
  idoWalkResolution,
  WalkParameters,
} from "./LaunchPadContractStore.service"

const idoLcgA = 134775813
const idoLcgC = 1
const idoLcgM = 4294967296

export class IDOLCG {
  constructor(
    readonly a = idoLcgA,
    readonly c = idoLcgC,
    readonly m = idoLcgM,
  ) {}

  next(current: number, maxStep = 0): number {
    // to avoid overflow, cast numbers to bigint first.
    const next =
      (BigInt(current) * BigInt(this.a) + BigInt(this.c)) % BigInt(this.m)
    return maxStep > 1 ? Number(next % BigInt(maxStep)) : Number(next)
  }
}

export function getAllWonTicketIds(result: WalkParameters): number[] {
  let walkPosition = result["walk-position"]
  const maxStepSize = result["max-step-size"]
  const ticketsForSale = result["total-tickets"]
  const lcg = new IDOLCG()
  const wonTickets: number[] = []
  for (let i = 0; i < ticketsForSale; i++) {
    wonTickets.push(Math.floor(walkPosition / idoWalkResolution))
    walkPosition =
      (Math.floor(walkPosition / idoWalkResolution) + 1) * idoWalkResolution +
      lcg.next(walkPosition, maxStepSize)
  }
  return wonTickets
}
