import { FC } from "react"
import { useCurrencyStore } from "../../../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { suspenseResource } from "../../../../../utils/SuspenseResource"
import { useLendIndexStore } from "../../../store/useLendStore"
import { SummaryRow } from "./SummaryRow"

export const WiredSummaryRow: FC<{
  className?: string
  gapClassName: string
}> = props => {
  const currencyStore = useCurrencyStore()
  const store = useLendIndexStore()

  return (
    <SummaryRow
      className={props.className}
      gapClassName={props.gapClassName}
      alexToken={currencyStore.getTokenInfo$(Currency.ALEX)}
      depositedAlexCount={suspenseResource(() => store.totalDeposit$)}
      depositIntroLink={
        "https://medium.com/alexgobtc/introducing-lend-borrow-8457f8064a56#dc3b"
      }
      borrowedAlexCount={suspenseResource(() => store.totalBorrowed$)}
      borrowIntroLink={
        "https://medium.com/alexgobtc/introducing-lend-borrow-8457f8064a56#f730"
      }
    />
  )
}
