import { hasAny } from "./arrayHelpers"

export function assertNever(x: never, err?: Error): never {
  throw err ?? new Error("Unexpected object: " + x)
}

export function checkNever(x: never): undefined {
  /* do nothing */
  return
}

type Writable<T> = {
  -readonly [k in keyof T]: T[k]
}

export function writable<T>(value: T): Writable<T> {
  return value
}

export type AnyFunc<Args extends any[] = any, Ret = any> = (
  ...args: Args
) => Ret

export type OneOrMore<T> = [T, ...T[]]
export function assertOneOrMore<T>(x: T[]): OneOrMore<T> {
  if (!hasAny(x)) {
    throw new Error("Expected one or more elements")
  }
  return x
}

export type Tail<T extends any[]> = T extends [any, ...infer U] ? U : never

export type StringOnly<T> = Extract<T, string>

export type NumberOnly<T> = Extract<T, number>

export type SelectKeyByValue<T, U> = {
  [K in keyof T as T[K] extends U ? K : never]: T[K]
}

export type RejectKeyByValue<T, U> = {
  [K in keyof T as T[K] extends U ? never : K]: T[K]
}

export type CompactType<T> = { [K in keyof T]: T[K] }

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never

/**
 * WARNING: This is a hack do type exhaustiveness check
 */
export function assertExclude<T, const E>(
  asserting: T,
  excluding: E,
): asserts asserting is Exclude<T, E> {
  return
}
export namespace assertExclude {
  export const i = <T>(): T => null as any
}
