import clsx from "clsx"
import { FC } from "react"
import {
  btnPresets,
  Button,
  ButtonProps,
} from "../../../components/button/Button"

export const ListButton: FC<ButtonProps> = props => {
  return (
    <Button
      {...btnPresets.small}
      {...props}
      className={clsx(
        "w-[120px] flex items-center justify-center gap-1.5",
        props.className,
      )}
    >
      {props.children}
    </Button>
  )
}
