import clsx from "clsx"
import { ReactNode } from "react"
import { FCC } from "../../../utils/reactHelpers/types"
import { ReactComponent as CountdownIcon } from "./countdown.svg"

export const Container: FCC<{
  className?: string

  /**
   * use `null` to hide the default icon
   */
  icon?: ReactNode
}> = props => (
  <div
    className={clsx(
      "px-2 py-1 rounded border border-white border-opacity-10",
      "flex gap-2 items-center",
      "text-sm leading-5",
      props.className,
    )}
  >
    {props.icon === null
      ? null
      : props.icon ?? <CountdownIcon width={16} height={16} fill={"#c4c4c4"} />}
    {props.children}
  </div>
)
