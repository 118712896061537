import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../../components/NavLink"
import {
  PrimaryPageTitle,
  SubtitleText,
} from "../../../../components/PageStack/PrimaryPageTitle"
import { PercentNumber } from "../../../../components/PercentNumber"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "../../../../components/TokenInput/BlockInputContainer"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { SuspenseResource } from "../../../../utils/SuspenseResource"
import { RectButton } from "../../../Orderbook/components/wrappedCommonComponents/RectButton"
import { ReactComponent as ApplyIcon } from "./_/apply.svg"

export const TitleBar: FC<{
  listingLiquidityThresholdPercentage: SuspenseResource<number>
  searchValue: string
  applyListLink: string
  onSearchChange: (val: string) => void
}> = props => {
  const { $t } = useIntl()
  return (
    <div className="flex justify-between flex-wrap items-center flex-1 gap-4">
      <PrimaryPageTitle className={"shrink-0"} uppercase={false}>
        {$t(
          defineMessage({
            defaultMessage: "B20 Token List",
            description: "/OrderBook/B20TokenListPage/title",
          }),
        )}
      </PrimaryPageTitle>

      <BlockInputContainer
        className={
          "ml-auto basis-[100%] sm:basis-[280px] lg:basis-[360px] order-last sm:order-none"
        }
        boxClassName={"p-3"}
      >
        {p => (
          <input
            ref={p.inputRef}
            type="text"
            placeholder={$t(
              defineMessage({
                defaultMessage: "Search...",
                description: "OrderBook/B20TokenListPage/SearchBar/placeholder",
              }),
            )}
            className={clsx(defaultInputClassNames(), "text-sm")}
            value={props.searchValue}
            onChange={e => props.onSearchChange(e.target.value)}
          />
        )}
      </BlockInputContainer>

      <NavLink
        className="shrink-0 grow sm:grow-0"
        to={props.applyListLink}
        target="_blank"
      >
        <RectButton
          Variant={GradientFilledButton}
          className={"flex items-center justify-center gap-x-2.5"}
          boxClassName={"py-2.5 px-4"}
        >
          <ApplyIcon />
          <span>
            {$t(
              defineMessage({
                defaultMessage: "Apply to list",
                description: "OrderBook/B20TokenListPage/SearchBar/Button text",
              }),
            )}
          </span>
        </RectButton>
      </NavLink>

      <SubtitleText className={"min-w-full order-[2] sm:order-none"}>
        {$t(
          defineMessage({
            defaultMessage:
              "Trading starts when the peg-in circulation reaches the target threshold (currently {percentage} of the maximum supply).",
            description: "/OrderBook/B20TokenListPage/title/subtitle",
          }),
          {
            percentage: (
              <PercentNumber
                number={props.listingLiquidityThresholdPercentage}
              />
            ),
          },
        )}
      </SubtitleText>
    </div>
  )
}
