import { BigNumber } from "../../../../../utils/BigNumber"
import { DisplayableError } from "../../../../../utils/error"
import { WalletAdapter, WalletAdapterAddresses } from "./WalletAdapters.types"

export class MockAddressWalletAdapter implements WalletAdapter {
  constructor(
    private inner: WalletAdapter,
    private addresses: Partial<Record<keyof WalletAdapterAddresses, string>>,
  ) {}

  async connect(): Promise<void> {
    await this.inner.connect()
  }

  async disconnect(): Promise<void> {
    await this.inner.disconnect()
  }

  async getAddresses(): Promise<WalletAdapterAddresses> {
    const addresses = await this.inner.getAddresses()
    return {
      bitcoin: [
        {
          address: this.addresses.bitcoin ?? addresses.bitcoin[0].address,
          publicKey: addresses.bitcoin[0].publicKey,
        },
      ],
      ordinals: [
        {
          address: this.addresses.ordinals ?? addresses.ordinals[0].address,
          tapInternalKey: addresses.ordinals[0].tapInternalKey,
        },
      ],
    }
  }

  sendBitcoin(
    receiverAddress: string,
    satoshiAmount: BigNumber,
  ): Promise<{ txId: string }> {
    throw new DisplayableError(
      `[MockAddressWalletAdapter] it's a mock adapter, can't send bitcoin`,
    )
  }

  sendInscription(
    receiverAddress: string,
    inscriptionId: string,
  ): Promise<{ txId: string }> {
    throw new DisplayableError(
      `[MockAddressWalletAdapter] it's a mock adapter, can't send inscription`,
    )
  }

  signAndFinalizePsbt(psbtHex: string): Promise<{ signedPsbtHex: string }> {
    throw new DisplayableError(
      `[MockAddressWalletAdapter] it's a mock adapter, can't sign transaction`,
    )
  }

  broadcastTx(txHex: string): Promise<{ txId: string }> {
    throw new DisplayableError(
      `[MockAddressWalletAdapter] it's a mock adapter, can't broadcast transaction`,
    )
  }
}
