import { hex } from "@scure/base"
import { Transaction } from "@scure/btc-signer"
import {
  Observable,
  defer,
  distinctUntilChanged,
  firstValueFrom,
  map,
  shareReplay,
  switchMap,
} from "rxjs"
import { BigNumber } from "../../../../../utils/BigNumber"
import { hasAny } from "../../../../../utils/arrayHelpers"
import { DisplayableError } from "../../../../../utils/error"
import {
  WalletAdapter,
  WalletAdapterAddresses,
} from "../WalletAdapters/WalletAdapters.types"
import {
  GetUTXOSpendableFn,
  InscriptionRecipient,
  createSendInscriptionTransaction,
} from "../helpers/createSendInscriptionTransaction"
import { BitcoinClient } from "./BitcoinClient"
import {
  BRC20WalletBalanceDetails,
  BitcoinNetwork,
  BitcoinNetworkBasicInfo,
  InscribeOrderResponse,
  InscribeOrderStatus,
  UTXOBasic,
  UTXOSpendable,
  UTXOWithConfirmation,
} from "./BitcoinClient.types"
import { fetchTransactionInfo, walletUnspentUTXOs } from "./bitcoin.service"
import {
  createBrc20TransferOrder,
  walletBalance,
  walletBalanceTokenDetails,
} from "./brc20.service"
import {
  InscriptionTransferMempool,
  fetchInscribeOrderStatus,
  fetchWalletOrdinalInscriptions,
  filterPureSpendableBtcUTXOs,
  getInscriptionTransferMempool,
  walletBRC20RecentTransferInscriptions,
} from "./inscription.service"

export class BitcoinClientBRC20Wallet {
  constructor(
    private bitcoinWallet: BitcoinClient,
    private brc20Wallet: WalletAdapter,
  ) {}

  get network(): BitcoinNetwork {
    return this.bitcoinWallet.network
  }

  get networkInfo(): Observable<BitcoinNetworkBasicInfo> {
    return this.bitcoinWallet.networkInfo
  }

  private blockHeight = this.networkInfo.pipe(
    map(info => info.block.height),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  private ordinalsWalletAddress = defer(() =>
    this.brc20Wallet.getAddresses(),
  ).pipe(
    map(addresses => addresses.ordinals[0]),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  private bitcoinWalletAddress = defer(() =>
    this.brc20Wallet.getAddresses(),
  ).pipe(
    map(addresses => addresses.bitcoin[0]),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  walletBalance = this.ordinalsWalletAddress.pipe(
    switchMap(address =>
      walletBalance(this.network, this.blockHeight, address.address),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  recentTransferBrc20Inscriptions = this.ordinalsWalletAddress.pipe(
    switchMap(address =>
      walletBRC20RecentTransferInscriptions(
        this.network,
        this.blockHeight,
        address.address,
      ),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  transferableOrdinalInscriptions = this.ordinalsWalletAddress.pipe(
    switchMap(address =>
      fetchWalletOrdinalInscriptions(this.network, address.address),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  btcAddressSpendableUTXOs: Observable<UTXOWithConfirmation[]> = defer(() =>
    this.brc20Wallet.getAddresses(),
  ).pipe(
    switchMap(addrs =>
      walletUnspentUTXOs(
        this.network,
        this.blockHeight,
        addrs.bitcoin[0].address,
      ),
    ),
    switchMap(utxos => filterPureSpendableBtcUTXOs(this.network, utxos)),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  getWalletBalanceDetails(
    token: string,
  ): Observable<BRC20WalletBalanceDetails> {
    return this.ordinalsWalletAddress.pipe(
      switchMap(address =>
        walletBalanceTokenDetails(
          this.network,
          this.blockHeight,
          address.address,
          token,
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    )
  }

  async createTokenTransferInscribeOrder(
    token: string,
    amount: BigNumber,
    networkFeeRate: number,
  ): Promise<InscribeOrderResponse> {
    const ordinalsWalletAddress = await firstValueFrom(
      this.ordinalsWalletAddress,
    )
    const resp = await createBrc20TransferOrder(
      ordinalsWalletAddress.address,
      token,
      amount,
      networkFeeRate,
    )
    return resp.inscribeOrder
  }

  async getTokenTransferInscribeOrderStatus(
    orderId: string,
  ): Promise<undefined | InscribeOrderStatus> {
    return fetchInscribeOrderStatus(orderId)
  }

  inscriptionExplorerLink(inscriptionId: string): string {
    return `https://ordinals.hiro.so/inscription/${inscriptionId}`
  }

  getInscriptionTransferMempool(): Observable<InscriptionTransferMempool> {
    return this.ordinalsWalletAddress.pipe(
      switchMap(address =>
        getInscriptionTransferMempool(this.network, address.address),
      ),
    )
  }

  async unsafelySendInscriptionTransactionHex(
    inscriptionRecipients: {
      receiverAddress: string
      inscriptionId: string
    }[],
    options: SendInscriptionOptions = {},
  ): Promise<{ txId: string }> {
    if (!hasAny(inscriptionRecipients)) {
      throw new DisplayableError("No inscription recipients")
    }

    if (
      this.brc20Wallet.sendInscription != null &&
      options.useUnsafelySendingApproach !== true &&
      inscriptionRecipients.length < 2 &&
      options.feeRate == null
    ) {
      return this.brc20Wallet.sendInscription(
        inscriptionRecipients[0].receiverAddress,
        inscriptionRecipients[0].inscriptionId,
      )
    }

    const spendableUTXOs = await firstValueFrom(this.btcAddressSpendableUTXOs)
    const networkInfo = await firstValueFrom(this.networkInfo)

    const bitcoinWalletAddress = await firstValueFrom(this.bitcoinWalletAddress)
    const ordinalsWalletAddress = await firstValueFrom(
      this.ordinalsWalletAddress,
    )

    const ordinalsInscriptions = await firstValueFrom(
      this.transferableOrdinalInscriptions,
    )

    const finalRecipients: InscriptionRecipient[] = inscriptionRecipients.map(
      r => {
        const ordinalsInscription = ordinalsInscriptions.find(
          i => r.inscriptionId === i.inscriptionId,
        )

        const inscriptionUtxo: undefined | UTXOWithConfirmation =
          ordinalsInscription == null
            ? undefined
            : {
                txId: ordinalsInscription.txId,
                index: ordinalsInscription.index,
                amount: ordinalsInscription.amount,
                blockHeight: ordinalsInscription.blockHeight,
              }

        if (inscriptionUtxo == null) {
          throw new DisplayableError(`Inscription ${r.inscriptionId} not found`)
        }
        return {
          inscriptionUtxo,
          address: r.receiverAddress,
        }
      },
    )

    // TODO: we should handle the case that some UTXO might be spent or not found
    const { tx, inscriptionUtxoInputIndices, bitcoinUtxoInputIndices } =
      await createSendInscriptionTransaction({
        network: this.network,
        inscriptionRecipients: finalRecipients,
        feeRate: options.feeRate ?? BigNumber.from(networkInfo.fees.fastestFee),
        availableFeeUtxos: spendableUTXOs,
        changeAddress: bitcoinWalletAddress.address,
        getUTXOSpendable: this.getSpendableUTXOFactory({
          cache: {},
          walletAddress: {
            bitcoin: [bitcoinWalletAddress],
            ordinals: [ordinalsWalletAddress],
          },
        }),
      })

    const { signedPsbtHex } = await this.brc20Wallet.signAndFinalizePsbt(
      hex.encode(tx.toPSBT()),
      inscriptionUtxoInputIndices,
      bitcoinUtxoInputIndices,
    )

    const signedTx = Transaction.fromPSBT(hex.decode(signedPsbtHex))

    if (this.brc20Wallet.broadcastTx != null) {
      return this.brc20Wallet.broadcastTx(signedTx.hex)
    } else {
      return this.bitcoinWallet.broadcastSignedTransaction(signedTx.hex)
    }
  }

  private getSpendableUTXOCacheKey(utxo: UTXOBasic): keyof SpendableUTXOCache {
    return `${utxo.txId}:${utxo.index}`
  }

  private getSpendableUTXOFactory(options: {
    cache: SpendableUTXOCache
    walletAddress: WalletAdapterAddresses
  }): GetUTXOSpendableFn {
    const bitcoinWalletAddress = options.walletAddress.bitcoin[0]
    const ordinalsWalletAddress = options.walletAddress.ordinals[0]

    return async utxo => {
      const cacheKey = this.getSpendableUTXOCacheKey(utxo)

      if (cacheKey in options.cache) {
        return options.cache[cacheKey]
      }

      const tx = await firstValueFrom(
        fetchTransactionInfo(this.network, utxo.txId),
      )

      const vout = tx.vout[utxo.index]
      if (vout == null) {
        throw new DisplayableError(
          `UTXO or script pub key not found for ${utxo.txId}:${utxo.index}`,
        )
      }

      const pubkey = vout.scriptpubkey
      if (pubkey == null) {
        throw new DisplayableError(
          `UTXO or script pub key not found for ${utxo.txId}:${utxo.index}`,
        )
      }

      if (
        !("scriptpubkey_address" in vout) ||
        (vout.scriptpubkey_address !== bitcoinWalletAddress.address &&
          vout.scriptpubkey_address !== ordinalsWalletAddress.address)
      ) {
        throw new DisplayableError(
          `UTXO "${utxo.txId}:${utxo.index}" is not owned by the wallet`,
        )
      }

      const spendableUTXO: UTXOSpendable & UTXOWithConfirmation = {
        ...utxo,
        address: vout.scriptpubkey_address,
        witnessUtxoScript: hex.decode(pubkey),
        tapInternalKey:
          vout.scriptpubkey_address === bitcoinWalletAddress.address
            ? undefined
            : hex.decode(ordinalsWalletAddress.tapInternalKey),
        redeemScript:
          vout.scriptpubkey_address === bitcoinWalletAddress.address &&
          bitcoinWalletAddress.redeemScript
            ? hex.decode(bitcoinWalletAddress.redeemScript)
            : undefined,
      }
      options.cache[cacheKey] = spendableUTXO
      return spendableUTXO
    }
  }
}

type SpendableUTXOCache = Record<
  `${string}:${number}`,
  undefined | (UTXOSpendable & UTXOWithConfirmation)
>

interface SendInscriptionOptions {
  useUnsafelySendingApproach?: boolean
  ordinalsSupport?: boolean
  feeRate?: BigNumber
}
