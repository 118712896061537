import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetDivider,
  CardInsetSubTitle,
  CardInsetTitle,
} from "../../../../../components/Card"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { IDOFailedIcon } from "../../IDOFailedIcon/IDOFailedIcon"
import {
  HorizontalInfoListContainer,
  HorizontalInfoListItem,
} from "../../InfoList"
import { ExplorerLink } from "./ExplorerLink/ExplorerLink"
import { PurpleBlock } from "./PurpleBlock"

export const ActionSectionContent$FinishedFailed: FC<{
  priceToken: TokenInfo
  userAssets?: {
    registeredTicketCount: number
    lockedAPowerCount: number
    lockedPriceTokenCount: number
    returnPriceTokenExplorerLink?: string
  }
}> = props => {
  const { $t } = useIntl()
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <IDOFailedIcon />
        <CardInsetTitle>
          {$t(
            defineMessage({
              defaultMessage: "The project has failed to launch",
              description:
                "/Launchpad/ActionSectionContent/Finished failed title",
            }),
          )}
        </CardInsetTitle>
        <CardInsetSubTitle>
          {$t(
            defineMessage({
              defaultMessage: "Please look forward to other projects",
              description:
                "/Launchpad/ActionSectionContent/Finished failed subtitle",
            }),
          )}
        </CardInsetSubTitle>
      </div>
      {props.userAssets && (
        <>
          <CardInsetDivider />
          <HorizontalInfoListContainer>
            <HorizontalInfoListItem
              title={"Validated Tickets"}
              detail={props.userAssets.registeredTicketCount}
            />
            <HorizontalInfoListItem
              title={
                <>
                  {$t(
                    defineMessage({
                      defaultMessage: "Validated {aPower}",
                      description:
                        "/Launchpad/Finished failed info list item title",
                    }),
                    {
                      aPower: <TokenName token={TokenInfoPresets.APower} />,
                    },
                  )}
                </>
              }
              detail={
                <TokenCount
                  token={TokenInfoPresets.APower}
                  count={props.userAssets.lockedAPowerCount}
                />
              }
            />
            <HorizontalInfoListItem
              title={
                <>
                  {$t(
                    defineMessage({
                      defaultMessage: "Locked {priceToken}",
                      description:
                        "/Launchpad/Finished failed info list item title",
                    }),
                    {
                      priceToken: <TokenName token={props.priceToken} />,
                    },
                  )}
                </>
              }
              detail={
                <TokenCount
                  token={props.priceToken}
                  count={props.userAssets.lockedPriceTokenCount}
                />
              }
            />
          </HorizontalInfoListContainer>
        </>
      )}

      {props.userAssets && (
        <PurpleBlock
          className={"mt-4"}
          primaryText={
            <>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Your locked {priceToken} will return to your wallet.",
                  description: "/Launchpad/Finished failed/User Assets",
                }),
                {
                  priceToken: <TokenName token={props.priceToken} />,
                },
              )}
            </>
          }
          bottom={
            props.userAssets.returnPriceTokenExplorerLink && (
              <ExplorerLink
                link={props.userAssets.returnPriceTokenExplorerLink}
              />
            )
          }
        />
      )}
    </CardInset>
  )
}
