import clsx from "clsx"
import { FC, ReactNode } from "react"

export const PurpleBlock: FC<{
  className?: string
  primaryText: ReactNode
  secondaryText?: ReactNode
  bottom?: ReactNode
}> = props => (
  <div
    className={clsx(
      "bg-purple-600 rounded p-2.5 font-medium text-white text-center",
      props.className,
    )}
  >
    <div className={"mx-4 text-xl leading-7"}>{props.primaryText}</div>

    {props.secondaryText && (
      <div className={"mx-4 text-base leading-6 opacity-50"}>
        {props.secondaryText}
      </div>
    )}

    {props.bottom}
  </div>
)
