import { STX_DX_API_HOST } from "../../config"
import { createSendRequest } from "../../utils/createSendRequest"
import { ApiPaths, paths } from "../stxdx/types"

export const sendPublicRequest = createSendRequest<paths, typeof ApiPaths>(
  ApiPaths,
  {
    baseUrl: STX_DX_API_HOST,
  },
)

export const sendRequest =
  (auth: string): typeof sendPublicRequest =>
  (operationId, request) =>
    sendPublicRequest(operationId, {
      ...request,
      header: {
        ...(request as any).header,
        Authorization: `Bearer ${auth}`,
      },
    })
