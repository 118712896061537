import * as Sentry from "@sentry/react"
import { AppConfig, AuthOptions, UserSession } from "@stacks/connect"
import { action, computed, makeObservable, observable } from "mobx"
import {
  ALLOW_CONTRACT_ARGUMENTATION,
  EXPLORER_ADDR_URL,
  IS_MAIN_NET,
} from "../../config"
import { AuthJWTToken } from "../../pages/Orderbook/store/stxdx_shared/StxDxMyInfoModule.service"
import { RoutePath } from "../../routes/routes"
import { safelyGet } from "../../utils/waitFor"
import { SuspenseObservable } from "../SuspenseObservable"
import { AppEnvStore } from "../appEnvStore/AppEnvStore"
import { ChainStore } from "../chainStore/ChainStore"
import { MetaMaskModule } from "./MetaMaskModule"
import { OKXWalletAuthSession } from "./OKXWalletAuthSession"
import { WalletConnectAuthSession } from "./WalletConnectAuthSession"

class HiroWalletAuthSession {
  constructor(readonly store: AuthStore) {}

  provider = "browser" as const

  private appConfig = new AppConfig(
    ["store_write", "store_read", "publish_data"],
    "https://app.alexlab.co",
  )

  private userSession = new UserSession({ appConfig: this.appConfig })

  get currentWalletAddress(): string | undefined {
    if (this.userSession.isUserSignedIn()) {
      const { mainnet, testnet } =
        this.userSession.loadUserData().profile?.stxAddress
      return IS_MAIN_NET ? mainnet : testnet
    } else {
      return undefined
    }
  }

  getAuthOptions(): AuthOptions {
    const onFinish: AuthOptions["onFinish"] = () => {
      this.store.refreshAddress()
    }

    const onCancel: AuthOptions["onCancel"] = () => {
      this.store.refreshAddress()
    }

    return {
      manifestPath: "",
      redirectTo: "/" + RoutePath.Swap,
      userSession: this.userSession,
      onFinish,
      onCancel,
      appDetails: {
        name: "ALEX",
        icon: "https://cdn.alexlab.co/logos/ALEX_Token.png",
      },
    }
  }

  signUserOut(): void {
    this.userSession.signUserOut()
  }
}

class AuthStore {
  constructor(
    private chainStore: Pick<ChainStore, "currentBlockHeight$">,
    private appEnv: AppEnvStore,
  ) {
    makeObservable(this)
    this.refreshAddress()
  }

  metaMaskModule = new MetaMaskModule(this.appEnv)
  hiroSession = new HiroWalletAuthSession(this)
  walletConnectSession = new WalletConnectAuthSession(this)
  okxWalletSession = new OKXWalletAuthSession(this)

  get session():
    | HiroWalletAuthSession
    | WalletConnectAuthSession
    | OKXWalletAuthSession {
    if (this.walletConnectSession.currentWalletAddress != null) {
      return this.walletConnectSession
    }
    if (this.okxWalletSession.currentWalletAddress != null) {
      return this.okxWalletSession
    }
    return this.hiroSession
  }

  private stxAddress = new SuspenseObservable<string>()
  connectedProvider = new SuspenseObservable<
    "browser" | "wallet-connect" | "okx-wallet"
  >()

  @computed get supportSignTransaction$(): boolean {
    return this.connectedProvider.read$ === "browser"
  }

  /**
   * @deprecated use {@link ChainStore.currentBlockHeight$} instead
   */
  @computed get currentBlockHeight$(): number {
    return this.chainStore.currentBlockHeight$
  }

  @computed get stxAddress$(): string {
    const asAddress = ALLOW_CONTRACT_ARGUMENTATION
      ? new URLSearchParams(window.location.search).get("asAddress")
      : undefined
    return asAddress || this.stxAddress.read$
  }

  @computed get addressExplorerLink$(): string {
    return EXPLORER_ADDR_URL(this.stxAddress$)
  }

  @computed get isWalletConnected(): boolean {
    return safelyGet(() => this.stxAddress$) != null
  }

  @action refreshAddress(): void {
    const address = this.session.currentWalletAddress
    this.stxAddress.set(address)
    this.connectedProvider.set(this.session.provider)
    this.isConnectingWallet = false
    Sentry.configureScope(scope => {
      scope.setUser(address ? { id: address } : null)
    })
  }

  async signOut(): Promise<void> {
    this.session.signUserOut()
    this.refreshAddress()
    AuthJWTToken.clear()
  }

  @observable isConnectingWallet = false
  showWalletSelector = action(() => {
    this.isConnectingWallet = true
  })
}

export default AuthStore
