import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../../components/AddStakeSectionInfoList"

export interface ConfirmStakeModalContentProps {
  alexToken: TokenInfo
  alexTokenCount: number
  autoAlexToken: TokenInfo
  autoAlexTokenCount: number
  stakePrice: SuspenseResource<number>
  startStakeCycleNumber: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmStakeModalContent: FC<
  ConfirmStakeModalContentProps
> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Auto Staking",
          description: "/State/AutoStake/ConfirmStakeModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        icon={<BlockGroupDownArrowIcon />}
        firstBlock={
          <TokenBlock
            tokens={[{ token: props.alexToken, count: props.alexTokenCount }]}
          />
        }
        secondBlock={
          <TokenBlock
            tokens={[
              { token: props.autoAlexToken, count: props.autoAlexTokenCount },
            ]}
          />
        }
      />
      <AddStakeSectionInfoList>
        <AddStakeSectionInfoListItem
          title={$t(
            defineMessage({
              defaultMessage: "Stake Price",
              description:
                "/State/AutoStake/ConfirmStakeModalContent/Info list item",
            }),
          )}
          detail={
            <>
              {$t(
                defineMessage({
                  defaultMessage: "{oneAutoAlexToken} = {stakePrice}",
                  description:
                    "/State/AutoStake/ConfirmStakeModalContent/Info list item detail",
                }),
                {
                  oneAutoAlexToken: (
                    <TextTokenCount token={props.autoAlexToken} count={1} />
                  ),
                  stakePrice: (
                    <TextTokenCount
                      token={props.alexToken}
                      count={readResource(props.stakePrice)}
                    />
                  ),
                },
              )}
            </>
          }
        />
        <AddStakeSectionInfoListItem
          title={$t(
            defineMessage({
              defaultMessage: "Start Cycle",
              description:
                "/State/AutoStake/ConfirmStakeModalContent/Info list item title",
            }),
          )}
          detail={$t(
            defineMessage({
              defaultMessage: "Cycle #{startStakeCycleNumber}",
              description:
                "/State/AutoStake/ConfirmStakeModalContent/Info list item detail",
            }),
            {
              startStakeCycleNumber: props.startStakeCycleNumber,
            },
          )}
        />
      </AddStakeSectionInfoList>
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "{autoAlexToken} auto-stakes your {alexToken}, growing your rewards automatically over time. You can swap {autoAlexToken} any time with other assets.",
            description: "/State/AutoStake/ConfirmStakeModalContent/Note",
          }),
          {
            autoAlexToken: <TokenName token={props.autoAlexToken} />,
            alexToken: <TokenName token={props.alexToken} />,
          },
        )}
      </NoteParagraph>
      <GradientFilledButton className={"mt-3.5"} onClick={props.onConfirm}>
        {$t(
          defineMessage({
            defaultMessage: "Confirm",
            description:
              "/State/AutoStake/ConfirmStakeModalContent/Button text",
          }),
        )}
      </GradientFilledButton>
    </CardModalContent>
  )
}
