import { sum } from "lodash"
import { FC, useCallback } from "react"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { useContentfulLongText } from "../../../generated/i18nHelpers/useContentfulLongText"
import { usePathGenerator } from "../../../routes/routes"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { OneOrMore } from "../../../utils/types"
import { AcknowledgeTermsModalContent } from "../components/AcknowledgeTermsModalContent/AcknowledgeTermsModalContent"
import { DepositEntryModalContent } from "../components/BRC20/DepositEntryModalContent/DepositEntryModalContent"
import { WithdrawDuplicateWarningModalContent } from "../components/BRC20/WithdrawDuplicateWarningModalContent/WithdrawDuplicateWarningModalContent"
import { WithdrawEntryModalContent } from "../components/BRC20/WithdrawEntryModalContent/WithdrawEntryModalContent"
import { TestNetworkSetupGuideModalContent } from "../components/TestNetworkSetupGuideModalContent/TestNetworkSetupGuideModalContent"
import { TradingAccountContent } from "../components/TradingAccount/TradingAccountContent"
import { TradingAccountContent$ConnectWallet } from "../components/TradingAccount/TradingAccountContent$ConnectWallet"
import { useOrderbookStore } from "../store/useOrderbookStore"
import { WiredPegInBrc20Modal } from "./WiredPegInBrc20Modal"
import { WiredPegOutBrc20Modal } from "./WiredPegOutBrc20Modal"
import { WiredTokensDepositDialog } from "./WiredTokensDepositDialog"
import { WiredTokensWithdrawDialog } from "./WiredTokensWithdrawDialog"

export const WiredTestNetworkSetupGuideModalContent: FC = () => {
  const store = useOrderbookStore()

  const testnetSetupGuideMarkdownContent = useContentfulLongText(
    () => store.longTextI18n.testnetSetupGuide.value$,
  )

  return (
    <Modal
      visible={store.myInfo.showingSwitchNetworkGuide}
      onClose={store.myInfo.hideSwitchNetworkGuide}
    >
      <TestNetworkSetupGuideModalContent
        markdownContent={testnetSetupGuideMarkdownContent}
        onClose={store.myInfo.hideSwitchNetworkGuide}
      />
    </Modal>
  )
}

export const WiredTradingAccountContent: FC<{
  gapClassName?: string
}> = props => {
  const store = useOrderbookStore()
  const { authStore } = store
  const doAuth = authStore.showWalletSelector

  if (!authStore.isWalletConnected) {
    return <TradingAccountContent$ConnectWallet onConnectWallet={doAuth} />
  }

  return (
    <>
      <TradingAccountContent
        gapClassName={props.gapClassName}
        totalBalance={suspenseResource(() => store.myInfo.totalBalanceInUSD$)}
        viewingToken={store.currency.getTokenInfo$(store.myInfo.currency.read$)}
        viewingTokenAvailableCount={suspenseResource(
          () => store.myInfo.selectedTokenAvailableBalance$,
        )}
        viewingTokenLockedCount={suspenseResource(
          () => store.myInfo.selectedTokenLockedBalance$,
        )}
        availableTokens={suspenseResource(() =>
          (store.info.allHasFundsCurrencies$.length > 0
            ? store.info.allHasFundsCurrencies$
            : [store.market.read$.priceToken]
          ).map(c => ({
            token: store.currency.getTokenInfo$(c),
            availableCount: suspenseResource(
              () => store.myInfo.stxDxBalance$(c)?.available ?? 0,
            ),
          })),
        )}
        isAccountAnomaly={suspenseResource(
          () => store.withdraw.isAnyMarketAmountAnomaly$,
        )}
        onViewingTokenChange={newToken =>
          store.myInfo.currency.set(newToken.id as any)
        }
        onDeposit={suspenseResource(() => store.depositFlow.onStartFlow$)}
        onWithdraw={() => store.withdrawFlow.onStartFlow()}
      />
      <WiredDepositFlow />
      <WiredWithdrawFlow />
    </>
  )
}

const WiredDepositFlow: FC = () => {
  const genPath = usePathGenerator()
  const appEnv = useAppEnvStore()
  const store = useOrderbookStore()

  const agreementContentMarkdown = useContentfulLongText(
    () => store.longTextI18n.acknowledgeTerm.value$,
  )

  if (!store.depositFlow.isFlowStarted) return null

  return (
    <>
      <Modal
        visible={store.depositFlow.agreementModalVisible}
        onClose={() => store.depositFlow.onDismissAgreement()}
      >
        <AcknowledgeTermsModalContent
          contentMarkdown={agreementContentMarkdown}
          onClose={() => store.depositFlow.onDismissAgreement()}
          onCancel={() => store.depositFlow.onDismissAgreement()}
          onAccept={() => store.depositFlow.onAcceptAgreement()}
        />
      </Modal>
      <Modal
        visible={store.depositFlow.entryModalVisible}
        onClose={() => store.depositFlow.onQuitFlow()}
      >
        <DepositEntryModalContent
          bridgeInEthereumTokensLink={genPath.wrapBridge()}
          pegInAction={
            appEnv.config$.enable202307PegInFlowFeature
              ? {
                  pegInBrc20TokensLink: genPath.orderbookPegIn(
                    store.depositFlow.initialPegInCurrency,
                  ),
                }
              : {
                  onDepositBrc20Tokens: () =>
                    store.depositFlow.onStartPegInBrc20Tokens(),
                }
          }
          onGeneratePegInBtcAddress={() =>
            store.brc20Pegging.generatePegInBtcAddress()
          }
          onDepositStxTokens={() => store.depositFlow.onStartDepositStxTokens()}
          onClose={() => store.depositFlow.onQuitFlow()}
        />
      </Modal>
      <WiredPegInBrc20Modal />
      <WiredTokensDepositDialog
        txStore={store.txStore}
        visible={store.depositFlow.depositStacksTokensModalVisible}
        onFinish={() => store.depositFlow.onFinishedDepositStxTokens()}
        onDismiss={() => store.depositFlow.onCancelDepositStxTokens()}
      />
    </>
  )
}

const WiredWithdrawFlow: FC = () => {
  const genPath = usePathGenerator()
  const store = useOrderbookStore()
  if (!store.withdrawFlow.isFlowStarted) return null

  return (
    <>
      <Modal
        visible={store.withdrawFlow.entryModalVisible}
        onClose={() => store.withdrawFlow.onQuitFlow()}
      >
        <WithdrawEntryModalContent
          isPegInBtcAddressGenerated={suspenseResource(
            () => store.brc20Pegging.isPegInAlexOfficialBtcAddressGenerated$,
          )}
          bridgeOutEthereumTokensLink={genPath.wrapBridge()}
          onWithdrawStxTokens={suspenseResource(
            () => store.withdrawFlow.onStartWithdrawStxTokens$.run,
          )}
          onPegOutBrc20Tokens={() =>
            store.withdrawFlow.onStartPegOutBrc20Tokens()
          }
          onClose={() => store.withdrawFlow.onQuitFlow()}
        />
      </Modal>
      <WiredTokensWithdrawDialog
        txStore={store.txStore}
        visible={store.withdrawFlow.withdrawStacksTokensModalVisible}
        onFinish={() => store.withdrawFlow.onFinishedWithdrawStxTokens()}
        onDismiss={() => store.withdrawFlow.onCancelWithdrawStxTokens()}
      />
      <WiredPegOutBrc20Modal />
      <WiredWithdrawDuplicateWarningModal />
    </>
  )
}

export interface WithdrawRequest {
  txId: string
  txExplorerLink: string
  withdraws: OneOrMore<{ token: TokenInfo; amount: number }>
}

export const WiredWithdrawDuplicateWarningModal: FC = () => {
  const store = useOrderbookStore()
  const onClose = useCallback(() => store.withdrawFlow.onQuitFlow(), [store])
  const requests =
    store.withdrawFlow.withdrawDuplicateWarningModalVisibleWithRequests
  return (
    <Modal visible={requests != null} onClose={onClose}>
      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() => (
          <WithdrawDuplicateWarningModalContent
            token={store.currency.getTokenInfo$(store.myInfo.currency.read$)}
            userBalance={suspenseResource(
              () => store.myInfo.totalBalanceInUSD$,
            )}
            withdrawPendingAmount={suspenseResource(() =>
              sum(
                requests?.map(r =>
                  sum(
                    r.withdraws
                      .filter(w => w.token.id === store.myInfo.currency.read$)
                      .map(w => w.amount),
                  ),
                ),
              ),
            )}
            withdrawRequests={requests ?? []}
            onStillWithdraw={() =>
              store.withdrawFlow.onStartWithdrawStxTokensWithoutWarning()
            }
            onOk={onClose}
            onClose={onClose}
          />
        )}
      </Spensor>
    </Modal>
  )
}
