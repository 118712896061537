import { FC, SVGProps } from "react"

export const ExclamationOutlinedIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333ZM8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM7.99933 3.83333C8.45933 3.83333 8.83333 4.20667 8.83333 4.66667C8.83333 5.12667 8.45933 5.5 7.99933 5.5C7.53933 5.5 7.16667 5.12667 7.16667 4.66667C7.16667 4.20667 7.53933 3.83333 7.99933 3.83333ZM9.33333 12H6.66667V11.3333C6.98933 11.214 7.33333 11.1993 7.33333 10.8433V7.86533C7.33333 7.50933 6.98933 7.45333 6.66667 7.334V6.66733H8.66667V10.844C8.66667 11.2007 9.01133 11.216 9.33333 11.334V12Z" />
  </svg>
)
