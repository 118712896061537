import clsx from "clsx"
import { FC, Ref } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardInset, CardTitle } from "../../../../components/Card"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../components/Spensor"
import { Button } from "../../../../components/button/Button"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  BRC20InscriptionStatus,
  BRC20InscriptionStatusCard,
} from "./BRC20Card/BRC20InscriptionStatusCard"
import { OrdinalsInscriptionStatusCard } from "./BRC20Card/OrdinalsInscriptionStatusCard"

export type TransferableInscriptionRecord =
  | {
      type: "brc20"
      token: TokenInfo
      tokenAmount: BigNumber
      inscriptionNumber: number
      status: BRC20InscriptionStatus
    }
  | {
      type: "ordinals"
      inscriptionNumber: number
      mimeType: string
      previewUrl: string
      status: BRC20InscriptionStatus
    }

export interface TransferablePanelProps {
  className?: string
  containerRef?: Ref<HTMLElement>
  transferableInscriptions: SuspenseResource<TransferableInscriptionRecord[]>
  onSend?: () => void | Promise<void>
}

export const TransferablePanel: FC<TransferablePanelProps> = props => {
  const { $t } = useIntl()

  return (
    <Card
      ref={props.containerRef as any}
      className={clsx(props.className, "flex flex-col gap-y-4")}
      boxClassName={"p-6"}
    >
      <CardTitle>
        {$t(
          defineMessage({
            defaultMessage: "Transferable Inscriptions",
            description:
              "Admin Bulk Send Inscriptions Page/In Progress Panel/title",
          }),
        )}
      </CardTitle>

      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() => (
          <CardInset
            className={
              "flex flex-row flex-wrap items-center justify-center gap-2.5"
            }
            boxClassName={"p-5"}
          >
            {readResource(props.transferableInscriptions).map((info, idx) =>
              info.type === "brc20" ? (
                <BRC20InscriptionStatusCard
                  key={idx}
                  className={clsx("w-[200px] min-h-[200px]")}
                  token={info.token}
                  tokenCount={info.tokenAmount}
                  inscriptionNumber={info.inscriptionNumber}
                  status={info.status}
                />
              ) : (
                <OrdinalsInscriptionStatusCard
                  key={idx}
                  className={clsx("w-[200px] min-h-[200px]")}
                  mimeType={info.mimeType}
                  previewUrl={info.previewUrl}
                  inscriptionNumber={info.inscriptionNumber}
                  status={info.status}
                />
              ),
            )}
          </CardInset>
        )}
      </Spensor>

      <Button disabled={props.onSend == null} onClick={props.onSend}>
        Send
      </Button>
    </Card>
  )
}
