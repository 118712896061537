import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardDivider,
  CardInset,
  CardInsetDescription,
  CardInsetTitle,
} from "../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NavLink } from "../../../../components/NavLink"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { TokenName } from "../../../../components/TokenName"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"

export const MyLotteryPanel$NotJoin: FC<{
  alexToken: TokenInfo
  alexTokenBalance: number
  swapAlexLink: string
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <CardInset className={"flex flex-col gap-6"}>
        <div className={"text-center"}>
          <CardInsetTitle>
            {$t(
              defineMessage({
                defaultMessage: "Not Join",
                description: "/Lottery/MyLotteryPanel$NotJoin/Card title",
              }),
            )}
          </CardInsetTitle>
          <CardInsetDescription>
            {$t(
              defineMessage({
                defaultMessage: `My {tokenName} Balance: {tokenCount}. <span>Swap More {icon}</span>`,
                description: "/Lottery/MyLotteryPanel$NotJoin/Card description",
              }),
              {
                tokenName: <TokenName token={props.alexToken} />,
                tokenCount: (
                  <TextTokenCount
                    token={props.alexToken}
                    count={props.alexTokenBalance}
                  />
                ),
                icon: <>&gt;</>,
                span: dontWrapObserver(children => (
                  <NavLink
                    className={"text-blue-600"}
                    target={"_blank"}
                    to={props.swapAlexLink}
                  >
                    {children}
                  </NavLink>
                )),
              },
            )}
          </CardInsetDescription>
        </div>
        <CardDivider className={"w-full"} />
        <InfoList
          className={"m-auto"}
          direction={"row"}
          listItemDirection={"column"}
        >
          <InfoListItem className={"gap-2 items-center"}>
            <InfoListItemDetail className={"text-5xl"}>0</InfoListItemDetail>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "My Lottery Tickets",
                  description:
                    "/Lottery/MyLotteryPanel$NotJoin/Info list title",
                }),
              )}
            </InfoListItemTitle>
          </InfoListItem>
        </InfoList>
      </CardInset>
    </>
  )
}
