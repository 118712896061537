import clsx from "clsx"
import { FC, MouseEvent, PropsWithChildren, ReactNode } from "react"
import { SuspenseResource, readResource } from "../../utils/SuspenseResource"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { FCC } from "../../utils/reactHelpers/types"
import { Spensor } from "../Spensor"
import { TokenIcon, TokenIconProps } from "../TokenIcon"
import { TokenName, TokenNameBRC20Badge } from "../TokenName"
import { Truncatable } from "../Truncatable"

export interface BlockProps extends PropsWithChildren<unknown> {
  className?: string
  boxClassName?: string
  borderClassName?: string
  roundedClassName?: string
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

export const Block: FC<BlockProps> = props => (
  <div
    className={clsx(
      "rounded-lg flex flex-col",
      props.roundedClassName ?? "rounded-lg",
      props.borderClassName ?? "border border-gray-700",
      props.boxClassName ?? "p-3 sm:p-6",
      props.className,
    )}
    onClick={props.onClick}
  >
    {props.children}
  </div>
)

export const BlockTopLine: FCC<{
  className?: string
  boxClassName?: string
}> = props => (
  <div className={clsx(props.boxClassName ?? "mb-2", props.className)}>
    {props.children}
  </div>
)

export const BlockBottomLine: FCC<{
  className?: string
  boxClassName?: string
}> = props => (
  <div className={clsx(props.boxClassName ?? "mt-4", props.className)}>
    {props.children}
  </div>
)

export const BlockTopTitleLine: FCC<{ className?: string }> = props => {
  return (
    <BlockTopLine
      className={clsx(
        "text-sm sm:text-base leading-6 font-normal text-gray-200",
        props.className,
      )}
    >
      {props.children}
    </BlockTopLine>
  )
}

export interface BlockTokenLineProps {
  tokenNameArea?: ReactNode
  tokenCountArea: ReactNode
}
export const BlockTokenLine: FC<BlockTokenLineProps> = props => (
  <div className={"flex items-center gap-4"}>
    {props.tokenNameArea}

    <Truncatable
      className={
        "text-2xl sm:text-3xl leading-9 font-medium text-gray-200 text-right flex-1 min-w-0"
      }
    >
      {props.tokenCountArea}
    </Truncatable>
  </div>
)

export const DefaultTokenNameArea: FC<{
  className?: string
  boxClassName?: string
  token: SuspenseResource<TokenInfo>
  afterTokenNameArea?: ReactNode
  TokenNameComponent?: FC<{ className?: string; token: TokenInfo }>
}> = props => {
  const TokenNameComponent = props.TokenNameComponent ?? TokenName
  return (
    <div
      className={clsx(
        "flex gap-2.5 text-gray-200 flex items-center",
        props.boxClassName,
        props.className,
      )}
    >
      <Spensor fallback={<span>&nbsp;</span>}>
        {() => (
          <>
            <TokenIcon
              token={readResource(props.token)}
              size={TokenIconProps.sizes.normal}
            />
            <TokenNameComponent
              className={
                "text-xl leading-7 font-medium max-w-[100px] sm:max-w-[150px]"
              }
              token={readResource(props.token)}
            />
            {readResource(props.token).propertyTags.includes("brc-20") && (
              <TokenNameBRC20Badge />
            )}
            {props.afterTokenNameArea}
          </>
        )}
      </Spensor>
    </div>
  )
}

export const renderThinnerBlock = (props: BlockProps): JSX.Element => (
  <Block {...props} boxClassName={"px-3 sm:px-6 py-3 sm:py-4"} />
)
