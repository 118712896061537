import clsx from "clsx"
import { RenderFn } from "../../../../utils/reactHelpers/childrenHelpers"
import { FCC } from "../../../../utils/reactHelpers/types"
import { CompactCard } from "../wrappedCommonComponents/CompactCard"

export interface CreateOrderPanelFrameProps {
  className?: string
  switchers: RenderFn<{ className?: string; gapClassName?: string }>
  buyForm: RenderFn<{ className?: string; gapClassName?: string }>
  sellForm: RenderFn<{ className?: string; gapClassName?: string }>
}

export const CreateOrderPanelFrame: FCC<CreateOrderPanelFrameProps> = props => {
  const gapClassName = "gap-4"

  return (
    <CompactCard
      className={clsx(props.className, "grid gap-6")}
      boxClassName={"p-3"}
      style={{
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      {props.switchers({
        className: '"col-start-1 col-span-2 row-start-1 row-span-1"',
        gapClassName,
      })}

      {props.buyForm({
        className: "col-start-1 col-span-1 row-start-2 row-span-1",
        gapClassName,
      })}
      {props.sellForm({
        className: "col-start-2 col-span-1 row-start-2 row-span-1",
        gapClassName,
      })}
    </CompactCard>
  )
}
