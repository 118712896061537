import { gql } from "@urql/core"
import { Dictionary, memoize, range, sum, zipObject } from "lodash"
import { computed, makeObservable, observable } from "mobx"
import { computedFn } from "mobx-utils"
import { CONTRACT_DEPLOYER, EXPLORER_TX_URL } from "../../../config"
import {
  AllLotteryClaimedInfoQuery,
  AllLotteryClaimedInfoQueryVariables,
  LotteryParticipantCountQuery,
  LotteryParticipantCountQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import {
  LotteryTicket,
  LotteryTicketPrizeType,
  LotteryTicketType,
  RoundPrize,
} from "../component/types"
import { LotteryPageStore } from "./LotteryPageStore"

export class LotteryHistoricalModule {
  constructor(readonly store: LotteryPageStore) {
    makeObservable(this)
  }

  @observable showMyHistory = false
  @observable showWinnerList = false
  @observable winnerListCurrentLotteryId = 0

  registerTxs = new LazyValue(
    () => this.store.accountStore.transactions$,
    async transactions => {
      await transactions.sync()
      return await transactions
        .decodedContractCallTransactions("alex-lottery", "register")
        .then(a =>
          a.slice().sort((x, y) => y.args["lottery-id"] - x.args["lottery-id"]),
        )
    },
  )

  claimTxs = new LazyValue(
    () => null,
    () =>
      fromUrqlSource(
        gqlQuery<
          AllLotteryClaimedInfoQuery,
          AllLotteryClaimedInfoQueryVariables
        >(
          gql`
            query AllLotteryClaimedInfo {
              laplace_contract_calls(
                where: {
                  contract_name: {
                    _in: ["alex-lottery", "lottery-claim-helper"]
                  }
                  function_name: { _eq: "claim" }
                }
              ) {
                tx_id
                function_args
                transaction_result
              }
            }
          `,
        ),
        ({ data }) =>
          data.laplace_contract_calls.map(call => ({
            lotteryId: call.function_args[0].value as number,
            roundId: call.function_args[1].value as number,
            winners: call.function_args[2].value.map(
              (a: any) => a.value as string,
            ) as string[],
            payout:
              (call.transaction_result.value.payout.value as number) / 1e8,
            txId: call.tx_id.replace(/^\\/, "0"),
          })),
      ),
  )

  @computed get myRegistrations$(): {
    round: number
    myLottery: number
    won: LotteryTicket.Won | null
  }[] {
    let txs = this.registerTxs.value$
    // remove not drawn round
    if (!this.store.info.drawEnded$) {
      txs = txs.filter(
        tx => tx.args["lottery-id"] !== this.store.info.currentLotteryId$,
      )
    }

    return txs.map(tx => {
      const lotteryId = tx.args["lottery-id"]
      const claimTxs = this.claimTxs.value$.filter(
        a => a.lotteryId === lotteryId,
      )
      const stxAddress = this.store.authStore.stxAddress$
      const resultFor = (
        round: 1 | 2 | 3,
      ): null | { reward: number; count: number; link: string } => {
        const roundTxs = claimTxs.find(a => a.roundId === round)
        if (roundTxs == null) {
          return null
        }
        const reward = roundTxs.payout
        const count = roundTxs.winners.filter(
          (a: any) => a === stxAddress,
        ).length
        return {
          reward,
          count,
          link: EXPLORER_TX_URL(roundTxs.txId),
        }
      }
      const rounds = ([1, 2, 3] as const).map(resultFor)
      const hasRewards = rounds.some(a => a != null && a.count! > 0)
      return {
        round: lotteryId,
        myLottery: tx.args.tickets + tx.args["bonus-tickets"],
        won: !hasRewards
          ? null
          : {
              type: LotteryTicketType.Won,
              wonPrizeTokenCount: sum(
                rounds.map(r => (r == null ? 0 : r.count * r.reward)),
              ),
              number: sum(rounds.map(r => r?.count ?? 0)),
              prizes: [
                ...range(0, rounds[0]?.count ?? 0).map(() => ({
                  type: LotteryTicketPrizeType.Third,
                  explorerLink: rounds[0]?.link,
                })),
                ...range(0, rounds[1]?.count ?? 0).map(() => ({
                  type: LotteryTicketPrizeType.Second,
                  explorerLink: rounds[1]?.link,
                })),
                ...range(0, rounds[2]?.count ?? 0).map(() => ({
                  type: LotteryTicketPrizeType.First,
                  explorerLink: rounds[2]?.link,
                })),
              ],
            },
      }
    })
  }

  @observable inlineHistoryCurrentPage = 0
  inlineHistoryRecordPerPage = 5

  @computed get totalLotteryCount$(): number {
    return this.store.info.currentLotteryId$
  }

  @computed get currentHistories$(): number[] {
    if (this.totalLotteryCount$ === 0) {
      return []
    }
    const start =
      this.store.info.currentLotteryId$ -
      1 -
      this.inlineHistoryRecordPerPage * this.inlineHistoryCurrentPage
    return range(start, Math.max(-1, start - 5))
  }

  participantCount = memoize(
    (lotteryId: number) =>
      new LazyValue(
        () => lotteryId,
        id =>
          gqlQuery<
            LotteryParticipantCountQuery,
            LotteryParticipantCountQueryVariables
          >(
            gql`
              query LotteryParticipantCount($lotteryId: String!) {
                laplace_contract_calls_aggregate(
                  where: {
                    contract_name: { _eq: "alex-lottery" }
                    function_name: { _eq: "register" }
                    arg1: { _eq: $lotteryId }
                  }
                ) {
                  aggregate {
                    count
                  }
                }
              }
            `,
            { lotteryId: String(id) },
          )
            .toPromise()
            .then(
              ({ data }) =>
                data?.laplace_contract_calls_aggregate.aggregate?.count ?? 0,
            ),
        {
          decorator: pMemoizeDecorator({
            persistKey: "lottery-participant-count-for-id",
          }),
        },
      ),
  )

  totalPotCount = memoize(
    (lotteryId: number) =>
      new LazyValue(
        () => lotteryId,
        id =>
          asSender(CONTRACT_DEPLOYER)
            .contract("alex-lottery")
            .func("get-total-pot-in-fixed")
            .call({ "lottery-id": id })
            .then(a => (a.type === "success" ? a.value / 1e8 : 0)),
      ),
  )

  prizeTokenCount$ = computedFn((lotteryId: number, roundId: number) => {
    return (
      this.claimTxs.value$.find(
        a => a.lotteryId === lotteryId && a.roundId === roundId,
      )?.payout ?? 0
    )
  })

  @computed get currentWinnerList$(): {
    lotteryId: number
    prizes: RoundPrize[]
    winners: Dictionary<string[]>
  } {
    const lotteryId = this.winnerListCurrentLotteryId
    return {
      lotteryId,
      winners: this.winnerListForLotteryId$(lotteryId),
      prizes: this.prizesForLotteryId$(lotteryId),
    }
  }

  @computed get currentClaimExplorerLinks$(): string[] {
    const lotteryId = this.winnerListCurrentLotteryId
    return this.claimTxIdsForLotteryId$(lotteryId).map((txId: string) =>
      EXPLORER_TX_URL(txId),
    )
  }

  prizesForLotteryId$ = computedFn((lotteryId: number) => {
    return [
      {
        type: LotteryTicketPrizeType.First,
        prizeTokenCount: this.prizeTokenCount$(lotteryId, 3),
        winningTicketsCount: this.store.info.roundInfo$(3)["total-tickets"],
      },
      {
        type: LotteryTicketPrizeType.Second,
        prizeTokenCount: this.prizeTokenCount$(lotteryId, 2),
        winningTicketsCount: this.store.info.roundInfo$(2)["total-tickets"],
      },
      {
        type: LotteryTicketPrizeType.Third,
        prizeTokenCount: this.prizeTokenCount$(lotteryId, 1),
        winningTicketsCount: this.store.info.roundInfo$(1)["total-tickets"],
      },
    ]
  })

  winnerListForLotteryId$ = computedFn((lotteryId: number) => {
    const rounds = [1, 2, 3]
    const winnerList = rounds.map(roundId =>
      this.winnerListPerRound$(lotteryId, roundId),
    )
    return zipObject(rounds, winnerList)
  })

  claimTxIdsForLotteryId$ = computedFn((lotteryId: number) => {
    const rounds = [3, 2, 1]
    return rounds.map(roundId => this.claimTxPerRound$(lotteryId, roundId))
  })

  private winnerListPerRound$ = computedFn(
    (lotteryId: number, roundId: number) => {
      return (
        this.claimTxs.value$.find(
          a => a.lotteryId === lotteryId && a.roundId === roundId,
        )?.winners ?? []
      )
    },
  )

  private claimTxPerRound$ = computedFn(
    (lotteryId: number, roundId: number): string => {
      return this.claimTxs.value$.find(
        a => a.lotteryId === lotteryId && a.roundId === roundId,
      )?.txId
    },
  )
}
