import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import TokenListStore from "./TokenListStore"

const { Provider, useStore } = createStore<TokenListStore>("TokenListStore")

export const useTokenListStore = useStore

export const TokenListStoreProvider: FCC = props => {
  const store = useCreation(() => new TokenListStore(), [])
  return <Provider store={store}>{props.children}</Provider>
}
