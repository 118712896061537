import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { cancel$t } from "../../../../commonIntlMessages"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { MaskedScopedLoadingBoundary } from "../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { BalanceBottomArea } from "../../../../components/TokenInput/BalanceBottomArea"
import { renderThinnerBlock } from "../../../../components/TokenInput/Block"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { Button } from "../../../../components/button/Button"
import { SmartLoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  SuspenseResource,
  isResourceLoading,
  readResource,
  safeReadResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  TransferFormError,
  TransferFormErrorType,
} from "./PegInFormModalContent.types"

export interface PegInFormModalContentProps {
  className?: string
  formError?: SuspenseResource<undefined | TransferFormError>
  token: SuspenseResource<TokenInfo>
  tokenAmount: null | BigNumber
  availableTokenAmount: SuspenseResource<BigNumber>
  onTokenAmountChange: (amount: null | BigNumber) => void
  onTokenAmountMax: SuspenseResource<() => void>
  onSubmit: SuspenseResource<undefined | (() => void | Promise<void>)>
  onClose: () => void
}

export const PegInFormModalContent: FC<PegInFormModalContentProps> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      className={clsx("flex flex-col gap-y-4", props.className)}
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Inscribe Transfer",
          description:
            "Orderbook Peg-In Page/Transfer Panel/Inscribe Transfer Modal/title",
        }),
      )}
    >
      <div>
        <p className={"text-sm leading-5 font-normal text-gray-200 mb-2.5"}>
          {$t(
            defineMessage({
              defaultMessage: "Amount:",
              description:
                "Orderbook Peg-In Page/Transfer Panel/Inscribe Transfer Modal/amount label",
            }),
          )}
        </p>

        <TokenInput
          error={
            safeReadResource(props.formError)?.type ===
            TransferFormErrorType.InsufficientBalance
          }
          disabled={safeReadResource(props.token) == null}
          token={props.token}
          value={
            props.tokenAmount == null
              ? null
              : BigNumber.toNumber(props.tokenAmount)
          }
          onChange={v =>
            props.onTokenAmountChange(v == null ? null : BigNumber.from(v))
          }
          bottomArea={
            <BalanceBottomArea
              token={props.token}
              balance={suspenseResource(() =>
                BigNumber.toNumber(readResource(props.availableTokenAmount)),
              )}
              onPressMax={props.onTokenAmountMax}
            />
          }
          renderBlock={renderThinnerBlock}
        />
      </div>

      <div className={"flex flex-row gap-x-2.5"}>
        <Button className={"basis-6/12"} onClick={props.onClose}>
          {$t(cancel$t)}
        </Button>

        <MaskedScopedLoadingBoundary
          className={"basis-6/12"}
          loadingIndicator={<LoadingIndicator />}
          read={{ submit: props.onSubmit }}
          defaultRead={{ submit: undefined }}
        >
          {({ submit }) => (
            <SmartLoadableButton
              Variant={GradientFilledButton}
              onClick={submit}
              loading={isResourceLoading(props.formError)}
              disabled={
                submit == null || safeReadResource(props.formError) != null
              }
            >
              {$t(
                defineMessage({
                  defaultMessage: "Inscribe Transfer",
                  description:
                    "Orderbook Peg-In Page/Transfer Panel/Inscribe Transfer Modal/submit button text",
                }),
              )}
            </SmartLoadableButton>
          )}
        </MaskedScopedLoadingBoundary>
      </div>
    </CardModalContent>
  )
}
