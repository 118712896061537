import { useCallback, useState } from "react"
import { LoadingControllerFactory } from "./useLoading"

export const useLoadingControllerFactory = (): [
  isSubtreeLoading: boolean,
  factory: LoadingControllerFactory,
] => {
  const [loadingCountInSubtree, setLoadingCountInSubtree] = useState(
    () => new Map<unknown, number>(),
  )

  const ctrlFactory: LoadingControllerFactory = useCallback(
    componentId => ({
      show() {
        setLoadingCountInSubtree(oldMap => {
          const newMap = new Map(oldMap)
          if (oldMap.has(componentId)) {
            newMap.set(componentId, newMap.get(componentId)! + 1)
          } else {
            newMap.set(componentId, 1)
          }
          return newMap
        })
      },
      hide() {
        setLoadingCountInSubtree(oldMap => {
          if (!oldMap.has(componentId)) return oldMap
          const newMap = new Map(oldMap)
          const count = newMap.get(componentId)!
          const newCount = count - 1
          if (newCount <= 0) {
            newMap.delete(componentId)
          } else {
            newMap.set(componentId, newCount)
          }
          return newMap
        })
      },
      cleanup() {
        setLoadingCountInSubtree(oldMap => {
          if (!oldMap.has(componentId)) return oldMap
          const newMap = new Map(oldMap)
          newMap.delete(componentId)
          return newMap
        })
      },
    }),
    [],
  )

  const loadingCount = Array.from(loadingCountInSubtree.entries()).reduce(
    (acc, pair) => acc + pair[1],
    0,
  )

  return [loadingCount > 0, ctrlFactory]
}
