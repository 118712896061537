import clsx from "clsx"
import { Card, CardProps } from "../../../../components/Card"
import { FCC } from "../../../../utils/reactHelpers/types"

export const CompactCard: FCC<CardProps> = props => (
  <Card boxClassName={"p-3"} {...props} />
)

export const CompactCardTitleText: FCC<{ className?: string }> = props => (
  <span
    className={clsx(
      "text-sm leading-5 font-semibold text-gray-200 grow",
      props.className,
    )}
  >
    {props.children}
  </span>
)
