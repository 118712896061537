import { first } from "lodash"
import { FC, ReactNode, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Column, useTable } from "react-table"
import {
  CardModalContent,
  TitleBarWithLeftArea,
} from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../../components/table/StyledTable"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { formatTimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { customReactTableRender } from "../../../../utils/customReactTableRender"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  readResource,
  SuspenseResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { useAsyncFnFactory } from "../../../../utils/useAsync"
import { CycleStatusBadge } from "../../components/CycleStatusBadge"
import { LoadMoreButton } from "../../components/LoadMoreButton/LoadMoreButton"
import { ClaimTokenInfo, MixedStakeCycle } from "../../types"
import { ClaimTokenInfoList } from "../ClaimTokenInfoList"
import { TokenCountDetailInfoList } from "../TokenCountDetailInfoList"

export interface AllCyclesModalContentProps {
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  currentBlock: number
  currentCycleEndedAt: Date
  currentCycleStartedAtBlock: number
  currentCycleEndedAtBlock: number
  cycles: SuspenseResource<MixedStakeCycle[]>
  isHasMore: boolean
  onLoadMore?: () => void | Promise<void>
  onClose?: () => void
}

export const AllCyclesModalContent: FC<AllCyclesModalContentProps> = props => {
  const { $t } = useIntl()

  return (
    <Frame
      title={
        <>
          {$t(
            defineMessage({
              defaultMessage: "All Cycles ({token})",
              description:
                "/Stake/MixedStake/AllCyclesModalContent/Frame title",
            }),
            { token: <TokenName token={props.alexToken} /> },
          )}
        </>
      }
      currentBlock={props.currentBlock}
      currentCycleEndedAt={props.currentCycleEndedAt}
      currentCycleStartedAtBlock={props.currentCycleStartedAtBlock}
      currentCycleEndedAtBlock={props.currentCycleEndedAtBlock}
      onClose={props.onClose}
      currentCycle={suspenseResource(() => first(readResource(props.cycles)))}
    >
      <Spensor fallback={<LoadingIndicator className={"m-6"} />}>
        {() => (
          <DataTable
            alexToken={props.alexToken}
            autoAlexToken={props.autoAlexToken}
            cycles={readResource(props.cycles)}
            isHasMore={props.isHasMore}
            onLoadMore={props.onLoadMore}
          />
        )}
      </Spensor>
    </Frame>
  )
}

const DataTable: FC<{
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  cycles: MixedStakeCycle[]
  isHasMore: boolean
  onLoadMore?: () => void | Promise<void>
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({
      alexToken: props.alexToken,
      autoAlexToken: props.autoAlexToken,
    }),
    data: props.cycles,
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const propOnLoadMore = props.onLoadMore
  const [loadMoreReq, onLoadMore] = useAsyncFnFactory(
    () => async () => propOnLoadMore?.(),
    [propOnLoadMore],
  )

  return (
    <div className={"flex-1 w-full flex flex-col items-center mt-3.5"}>
      <div className={"flex-1 w-full overflow-scroll"}>
        <Table {...getTableProps()} className={"flex-1"}>
          <Thead>
            {headerGroups.map(g => (
              <TableHeadRow {...g.getHeaderGroupProps()}>
                {g.headers.map(cell =>
                  cell.id === tableActionColumnId ? null : (
                    <Th
                      {...cell.getHeaderProps()}
                      colSpan={cell.id === tablePrincipalColumnId ? 2 : 1}
                    >
                      {customReactTableRender(cell, "Header")}
                    </Th>
                  ),
                )}
              </TableHeadRow>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()} className={"h-full overflow-scroll"}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <TableBodyRow {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td {...cell.getCellProps()} className={"break-words"}>
                      {cell.render("Cell")}
                    </Td>
                  ))}
                </TableBodyRow>
              )
            })}
          </Tbody>
        </Table>
      </div>
      {props.isHasMore && (
        <LoadMoreButton
          className={"mt-4"}
          loading={loadMoreReq.loading}
          onLoadMore={onLoadMore}
        />
      )}
    </div>
  )
}

const Frame: FCC<{
  title: ReactNode
  currentCycle: SuspenseResource<undefined | MixedStakeCycle>
  currentBlock: number
  currentCycleEndedAt: Date
  currentCycleStartedAtBlock: number
  currentCycleEndedAtBlock: number
  onClose?: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      layoutClassName={"flex flex-col items-center justify-center"}
      gapClassName={""}
      width={"80vw"}
      titleBar={
        <Spensor>
          {() => {
            const currentCycle = readResource(props.currentCycle)
            if (!currentCycle) return null
            return (
              <TitleBarWithLeftArea
                title={props.title}
                onClose={props.onClose}
                leftArea={
                  <div className={"w-fit-content"}>
                    <Tooltip
                      title={$t(
                        defineMessage({
                          defaultMessage:
                            'Current Cycle Countdown: "{countdown}" {breakLine} Start Block: {startBlock} / End Block: {endBlock}',
                          description:
                            "/Stake/MixedStake/AllCyclesModalContent/Frame/Tooltip title",
                        }),
                        {
                          breakLine: <br />,
                          countdown: formatTimeCountdownText(
                            props.currentCycleEndedAt,
                          ),
                          startBlock: props.currentCycleStartedAtBlock,
                          endBlock: props.currentCycleEndedAtBlock,
                        },
                      )}
                    >
                      <BlockTimeCountdown
                        prefix={$t(
                          defineMessage({
                            defaultMessage: "Next Cycle",
                            description:
                              "/Stake/MixedStake/AllCyclesModalContent/Frame/Countdown prefix",
                          }),
                        )}
                        targetBlock={currentCycle.targetBlock}
                        currentBlock={currentCycle.currentBlock}
                        time={currentCycle.targetDate}
                      />
                    </Tooltip>
                  </div>
                }
              />
            )
          }}
        </Spensor>
      }
    >
      {props.children}
    </CardModalContent>
  )
}

const tablePrincipalColumnId = "cyclePrincipalColumn"
const tableActionColumnId = "cycleActionColumn"

const useTableSchema = (context: {
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
}): Column<MixedStakeCycle>[] => {
  const { $t } = useIntl()

  return useMemo(
    () => [
      {
        id: "cycleNumber",
        Header: $t(
          defineMessage({
            defaultMessage: "Cycle",
            description: "/Stake/MixedStake/AllCyclesModalContent/Table header",
          }),
        ),
        accessor: "cycleNumber",
        width: 122,
        Cell: props => (
          <div className={"flex flex-row items-center gap-2.5"}>
            <Tooltip
              title={$t(
                defineMessage({
                  defaultMessage: "Block {fromBlock} - {toBlock}",
                  description: "/Stake/table schema",
                }),
                {
                  fromBlock: props.row.original.fromBlock,
                  toBlock: props.row.original.targetBlock - 1,
                },
              )}
            >
              <span className={"font-bold"}>
                #{props.row.original.cycleNumber}
              </span>
            </Tooltip>
            <CycleStatusBadge status={props.row.original.cycleStatus} />
          </div>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Total staked",
            description: "/Stake/MixedStake/AllCyclesModalContent/Table header",
          }),
        ),
        accessor: "totalStakedAlexCount",
        Cell: props => (
          <>
            <TokenCount
              token={context.alexToken}
              count={props.row.original.totalStakedAlexCount}
            />{" "}
            <TokenName token={context.alexToken} />
          </>
        ),
      },
      {
        Header: (
          <>
            {$t(
              defineMessage({
                defaultMessage: "My auto {tokenName} staking",
                description:
                  "/Stake/MixedStake/AllCyclesModalContent/Table header",
              }),
              {
                tokenName: <TokenName token={context.alexToken} />,
              },
            )}
          </>
        ),
        accessor: "autoAlexTokenCount",
        Cell: props => (
          <Spensor>
            {() => (
              <TokenCountDetailInfoList
                primary={
                  <>
                    <TokenCount
                      token={context.autoAlexToken}
                      count={props.row.original.autoAlexTokenCount}
                    />{" "}
                    <TokenName token={context.autoAlexToken} />
                  </>
                }
                secondary={
                  <ul>
                    <li>
                      {$t(
                        defineMessage({
                          defaultMessage: "APY: {apy}",
                          description:
                            "/Stake/MixedStake/AllCyclesModalContent/Cell",
                        }),
                        {
                          apy: (
                            <PercentNumber
                              number={props.row.original.autoStakingApy}
                            />
                          ),
                        },
                      )}
                    </li>
                    <li>
                      {$t(
                        defineMessage({
                          defaultMessage:
                            "Intrinsic value: {tokenCount} {tokenName}",
                          description:
                            "/Stake/MixedStake/AllCyclesModalContent/Cell",
                        }),
                        {
                          tokenCount: (
                            <TokenCount
                              token={context.alexToken}
                              count={
                                props.row.original.autoAlexIntrinsicAlexValue
                              }
                            />
                          ),
                          tokenName: <TokenName token={context.alexToken} />,
                        },
                      )}
                    </li>
                  </ul>
                }
              />
            )}
          </Spensor>
        ),
      },
      {
        Header: (
          <>
            {$t(
              defineMessage({
                defaultMessage: "My manual {tokenName} staking",
                description:
                  "/Stake/MixedStake/AllCyclesModalContent/Table header",
              }),
              { tokenName: <TokenName token={context.alexToken} /> },
            )}
          </>
        ),
        accessor: "manualStakingAlexTokenCount",
        Cell: props => (
          <Spensor>
            {() => (
              <TokenCountDetailInfoList
                primary={
                  <>
                    <TokenCount
                      token={context.alexToken}
                      count={props.row.original.manualStakingAlexTokenCount}
                    />{" "}
                    <TokenName token={context.alexToken} />
                  </>
                }
                secondary={
                  <ul>
                    <li>
                      {$t(
                        defineMessage({
                          defaultMessage: "APR: {apr}",
                          description:
                            "/Stake/MixedStake/AllCyclesModalContent/Table cell",
                        }),
                        {
                          apr: (
                            <PercentNumber
                              number={props.row.original.manualStakingApr}
                            />
                          ),
                        },
                      )}
                    </li>
                    <li>
                      {$t(
                        defineMessage({
                          defaultMessage: "Claim: {claimable}",
                          description:
                            "/Stake/MixedStake/AllCyclesModalContent/Table cell",
                        }),
                        {
                          claimable: (
                            <ClaimTokenInfoList
                              claimTokenInfos={ClaimTokenInfo.mergeSameTokenInfo(
                                props.row.original.manualStakingClaimable,
                              )}
                            />
                          ),
                        },
                      )}
                    </li>
                  </ul>
                }
              />
            )}
          </Spensor>
        ),
      },
    ],
    [context.alexToken, context.autoAlexToken, $t],
  )
}
