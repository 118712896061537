import { action, computed, makeObservable, observable } from "mobx"
import type { PaginationInfo } from "../../../../components/Pagination"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import { ETHCurrency } from "../../../../stores/appEnvStore/ETHCurrency"
import AuthStore from "../../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { isPromiseLike } from "../../../../utils/promiseHelpers"
import { assertExclude, checkNever } from "../../../../utils/types"
import { isNotNull } from "../../../../utils/utils"
import {
  BridgeChain,
  isEthBridgeChain,
  isOppositeBridgeChain,
} from "../../types/BridgeChain"
import { mapBridgeChainToEthChain } from "../../types/BridgeChainHelpers"
import { WrapBridgeHistoryRecord } from "../../types/types"
import { BridgeCurrency, isBridgeCurrency } from "../utils/BridgeCurrency"
import {
  getServerHistoryRecords,
  transformServerOrderRecord,
} from "./getHistoryRecords.service"

export class HistoryModule {
  constructor(
    private readonly currencyStore: Pick<
      CurrencyStore,
      "getTokenInfo$" | "getCurrencyForAsset$"
    >,
    private readonly accountStore: Pick<AccountStore, "getBalance$">,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,
    private readonly appEnvStore: AppEnvStore,
  ) {
    makeObservable(this)
  }

  @computed get fetchRecordsArguments$(): [addr1: string, addr2: string] {
    return [
      this.authStore.stxAddress$,
      this.authStore.metaMaskModule.connectedWalletAddress$,
    ]
  }

  private _serverRecords = new LazyValue(
    () => this.fetchRecordsArguments$,
    ([address0, address1]) => getServerHistoryRecords(address0, address1),
    { debug: "serverRecord" },
  )
  @computed private get allRecords$(): WrapBridgeHistoryRecord[] {
    return (
      this._serverRecords.value$
        .map(r =>
          transformServerOrderRecord(r, {
            getTokenInfo: c => this.currencyStore.getTokenInfo$(c),
            getBridgeCurrency: (chain, assetContractAddr) =>
              this.getBridgeCurrency$(chain, assetContractAddr),
          }),
        )
        // TODO: use a better way to render invalid records
        .filter(isNotNull)
    )
  }

  @computed get isReadyToLoadRecords(): boolean {
    try {
      void this.fetchRecordsArguments$
      return true
    } catch (e) {
      if (isPromiseLike(e)) {
        return false
      } else {
        throw e
      }
    }
  }

  @observable currentPage = 0

  readonly recordCountPerPage = 30

  @action onChangePage(pageNumber: number): void {
    this.currentPage = pageNumber
  }

  @computed get paginationInfo$(): PaginationInfo {
    return {
      recordCountPerPage: this.recordCountPerPage,
      currentPage: this.currentPage,
      recordCountTotal: this.allRecords$.length,
    }
  }

  @computed get records$(): WrapBridgeHistoryRecord[] {
    return this.allRecords$.slice(
      this.paginationInfo$.currentPage *
        this.paginationInfo$.recordCountPerPage,
      this.paginationInfo$.recordCountPerPage,
    )
  }

  getBridgeCurrency$(
    chain: BridgeChain,
    assetContractAddr: string,
  ): undefined | BridgeCurrency {
    if (chain === BridgeChain.Unknown) {
      return undefined
    }

    if (chain === BridgeChain.Stacks) {
      const currency =
        this.currencyStore.getCurrencyForAsset$(assetContractAddr)
      if (!currency || !isBridgeCurrency(currency)) return
      return currency
    }

    if (isOppositeBridgeChain(chain)) {
      if (isEthBridgeChain(chain)) {
        const ethChain = mapBridgeChainToEthChain(chain)
        const restETHCurrency = assertExclude.i<ETHCurrency>()

        if (
          ethChain != null &&
          this.appEnvStore.ethContractAddress$.usdt[ethChain]?.toLowerCase() ===
            assetContractAddr.toLowerCase()
        ) {
          return ETHCurrency.USDT
        }
        assertExclude(restETHCurrency, ETHCurrency.USDT)

        if (
          ethChain != null &&
          this.appEnvStore.ethContractAddress$.lunr[ethChain]?.toLowerCase() ===
            assetContractAddr.toLowerCase()
        ) {
          return ETHCurrency.LUNR
        }
        assertExclude(restETHCurrency, ETHCurrency.LUNR)

        assertExclude(restETHCurrency, ETHCurrency.WBTC)
        assertExclude(restETHCurrency, ETHCurrency.USDC)
        checkNever(restETHCurrency)
        return
      }
      checkNever(chain)
      return undefined
    }

    checkNever(chain)
    return undefined
  }
}
