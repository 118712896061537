import * as Popover from "@radix-ui/react-popover"
import clsx from "clsx"
import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react"
import { isTouchDevice } from "../utils/domHelpers/browserEnv"
import { FCC } from "../utils/reactHelpers/types"
import { useIsMounted } from "../utils/reactHelpers/useIsMounted"
import { useOnClickOutsideWithCustomEventName } from "../utils/reactHelpers/useOnClickOutside"
import { useSuggestedKeepInViewportSize } from "../utils/reactHelpers/useOversteppedViewportDistances"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { useCombinedRef } from "../utils/refHelpers"
import { OneOrMore, assertNever } from "../utils/types"
import { Divider } from "./Divider"
import { useSpacing } from "./Themed/spacing"
import { HeadlessButton } from "./button/HeadlessButton"

type TriggerMethod = "hover" | "click"
type DismissMethod = "hover-outside" | "click-outside" | "click"

export type Placement = "center" | "start" | "end"

export interface DropdownProps {
  triggerContainerRef?: Ref<HTMLElement>
  triggerContainerClassName?: string
  contentContainerRef?: Ref<HTMLElement>
  contentContainerClassName?: string
  withoutInnerContainer?: boolean

  /**
   * if be used in touch device, the value will forcibly be `click`,
   * otherwise the default value is `hover`
   */
  triggerMethod?: TriggerMethod
  /**
   * if `triggerMethod` is `hover`, the default value is `hover-outside`
   * if `triggerMethod` is `click`, the default value is `click-outside`
   */
  dismissMethod?: DismissMethod | OneOrMore<DismissMethod>

  trigger: ReactNode | ((contextInfo: { isOpen: boolean }) => ReactNode)

  placement?: Placement

  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}
export const Dropdown: FCC<DropdownProps> = props => {
  const spacing = useSpacing()

  const contentPadding = spacing(2)

  const isMounted = useIsMounted()

  const [_visible, _setVisible] = useState(false)
  const [visible, setVisible] =
    props.visible != null
      ? [props.visible, props.onVisibleChange]
      : [_visible, _setVisible]

  const [suggestedContentContainerSize, sizeCalcRef] =
    useSuggestedKeepInViewportSize({ padding: contentPadding })

  const setVisibleIfMounted = (visible: boolean): void => {
    isMounted() && setVisible?.(visible)
  }

  const internalTriggerContainerRef = useRef<HTMLElement>(null)
  const triggerContainerRef = useCombinedRef(
    internalTriggerContainerRef,
    props.triggerContainerRef,
  )

  const internalContentContainerRef = useRef<HTMLElement>(null)
  const contentContainerRef = useCombinedRef(
    internalContentContainerRef,
    sizeCalcRef,
    props.contentContainerRef,
  )

  const triggerMethod = getTriggerMethod(props.triggerMethod)
  const dismissMethod = getDismissMethod(props.dismissMethod, triggerMethod)

  const onMouseEnter = (): void => {
    if (triggerMethod !== "hover") return
    setVisibleIfMounted(true)
  }
  const onMouseLeave: MouseEventHandler = (e): void => {
    // prevent this event handler in touch device
    // mobile safari will simulate this event when the trigger loses focus
    if (isTouchDevice()) return

    if (!dismissMethod.includes("hover-outside")) return

    if (
      e.target instanceof HTMLElement &&
      internalTriggerContainerRef.current?.contains(e.target) &&
      internalContentContainerRef.current?.contains(e.target)
    ) {
      return
    }

    setVisibleIfMounted(false)
  }

  const onClickTrigger = (): void => {
    if (!visible) {
      if (triggerMethod !== "click") return
      setVisibleIfMounted(true)
    } else {
      if (!dismissMethod.includes("click")) return
      setVisibleIfMounted(false)
    }
  }
  useOnClickOutsideWithCustomEventName(
    "click",
    e => {
      if (dismissMethod.includes("click")) {
        return !internalTriggerContainerRef.current?.contains(e.target)
      }
      if (dismissMethod.includes("click-outside")) {
        return (
          !internalTriggerContainerRef.current?.contains(e.target) &&
          !internalContentContainerRef.current?.contains(e.target)
        )
      }
      return true
    },
    () => {
      setVisibleIfMounted(false)
    },
  )

  useEffect(() => {
    if (!isTouchDevice()) return
    if (visible) {
      document.documentElement.classList.add("overflow-hidden")
    }
    return () => {
      if (visible) {
        document.documentElement.classList.remove("overflow-hidden")
      }
    }
  }, [visible])

  const trigger =
    typeof props.trigger === "function"
      ? props.trigger({ isOpen: visible })
      : props.trigger

  return (
    <Popover.Root open={visible}>
      <Popover.Trigger asChild>
        <div
          ref={triggerContainerRef as any}
          className={clsx("outline-none", props.triggerContainerClassName)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClickTrigger}
        >
          {trigger}
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className={clsx("outline-none z-50", props.contentContainerClassName)}
          style={{ padding: contentPadding }}
          align={props.placement}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            ref={contentContainerRef as any}
            style={
              suggestedContentContainerSize == null
                ? {}
                : {
                    maxWidth: suggestedContentContainerSize.width,
                    maxHeight: suggestedContentContainerSize.height,
                  }
            }
          >
            {props.withoutInnerContainer ? (
              props.children
            ) : (
              <DropdownContentInnerContainer
                overflowClassName={
                  suggestedContentContainerSize ? "overflow-auto" : undefined
                }
                style={
                  suggestedContentContainerSize == null
                    ? {}
                    : {
                        maxWidth: suggestedContentContainerSize.width,
                        maxHeight: suggestedContentContainerSize.height,
                      }
                }
              >
                {props.children}
              </DropdownContentInnerContainer>
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

const getTriggerMethod = (
  definedTriggerMethod?: TriggerMethod,
): TriggerMethod => {
  if (isTouchDevice()) {
    return "click"
  } else {
    return definedTriggerMethod ?? "hover"
  }
}

const getDismissMethod = (
  definedDismissMethod: undefined | DismissMethod | OneOrMore<DismissMethod>,
  triggerMethod: TriggerMethod,
): OneOrMore<DismissMethod> => {
  if (triggerMethod === "hover") {
    return definedDismissMethod != null
      ? ensureArray(definedDismissMethod)
      : ["hover-outside"]
  } else if (triggerMethod === "click") {
    return definedDismissMethod != null
      ? ensureArray(definedDismissMethod)
      : ["click-outside"]
  } else {
    assertNever(triggerMethod)
  }

  function ensureArray<T>(a: T | T[]): OneOrMore<T> {
    return Array.isArray(a) ? (a as OneOrMore<T>) : [a]
  }
}

export const DropdownContentInnerContainer: FCC<{
  className?: string
  overflowClassName?: string
  style?: CSSProperties
}> = props => (
  <div
    className={clsx(
      "rounded-xl py-3 text-white shadow-lg backdrop-blur-2xl focus:outline-none flex flex-col gap-2",
      props.className,
      props.overflowClassName ?? "overflow-hidden",
    )}
    style={{
      ...props.style,
      minWidth: 180,
      boxShadow: "box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05)",
      background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), rgba(17, 24, 39, 0.95)",
    }}
  >
    {props.children}
  </div>
)

export const DropdownItem: FCC<{
  className?: string
  selected?: boolean
  contentContainerClassName?: string
  icon?: JSX.Element
  onClick?: () => void
}> = props => {
  return (
    <HeadlessButton
      className={clsx(
        `px-4 py-2 outline-none cursor-pointer`,
        `flex items-center justify-between`,
        `text-sm leading-5 font-normal text-gray-200`,
        `hover:text-white hover:bg-white/10`,
        `active:text-white active:bg-white/5`,
        `disabled:text-white/30 disabled:bg-white/5`,
        props.className,
      )}
      disabled={props.selected}
      onClick={props.onClick}
    >
      <div className={props.contentContainerClassName}>{props.children}</div>

      {props.icon && (
        <span className={"flex items-center text-gray-500"}>{props.icon}</span>
      )}
    </HeadlessButton>
  )
}

export const DropdownCloseButton = Popover.Close

export const DropdownDivider = withClassName("border-gray-500/30", Divider)
