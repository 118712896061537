import { FC } from "react"

export interface ResponsiveImageInfo {
  srcmap: { base: string; "1x"?: string } | { "1x": string; base?: string }
  srcset: string
}

export const ResponsiveImage: FC<{
  className?: string
  image: ResponsiveImageInfo
  width?: number
  height?: number
  alt?: string
  title?: string
}> = ({ image, ...restProps }) => {
  const src = image.srcmap.base != null ? image.srcmap.base : image.srcmap["1x"]

  return (
    <img alt={restProps.alt} src={src} srcSet={image.srcset} {...restProps} />
  )
}
