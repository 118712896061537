import { unwrapResponse } from "clarity-codegen"
import { computed, makeObservable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../../../config"
import { asSender } from "../../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../../../stores/LazyValue/pMemoizeDecorator"
import { Currency } from "../../../../../utils/alexjs/Currency"
import type { DualYieldToken } from "../../../../../utils/alexjs/currencyHelpers"
import { DualYieldFWPPool } from "../../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import ManualStakeStore from "../ManualStakeStore"

export class DualYieldModule {
  constructor(
    readonly poolToken: DualYieldFWPPool,
    readonly store: ManualStakeStore,
  ) {
    makeObservable(this)
  }

  private _multiplier = new LazyValue(
    () => this.poolToken,
    token =>
      asSender(CONTRACT_DEPLOYER)
        .contract("dual-farming-pool")
        .func("get-multiplier-in-fixed-or-default")
        .call({ token })
        .then(a => a / 1e8),
    {
      decorator: pMemoizeDecorator({
        persistKey: "dual-token-multiplier-update",
      }),
    },
  )

  @computed get multiplier$(): number {
    return this._multiplier.value$
  }

  private underlyingToken = new LazyValue(
    () => this.poolToken,
    token =>
      asSender(CONTRACT_DEPLOYER)
        .contract("dual-farming-pool")
        .func("get-dual-token-underlying")
        .call({ token })
        .then(unwrapResponse),
    { decorator: pMemoizeDecorator({ persistKey: "dual-token-underlying" }) },
  )

  @computed get underlyingToken$(): DualYieldToken {
    // return dualYieldToken(this.poolToken)
    return this.underlyingToken.value$ as DualYieldToken
  }

  @computed get tokenInfo$(): TokenInfo {
    return this.store.currencyStore.getTokenInfo$(this.underlyingToken$)
  }

  @computed get priceInUSD$(): number {
    return this.store.currencyStore.getPrice$(this.underlyingToken$)
  }

  @computed get aprMultiplier$(): number {
    const alexPrice = this.store.currencyStore.getPrice$(Currency.ALEX)
    return 1 + (this.priceInUSD$ / alexPrice) * this.multiplier$
  }
}
