import { ClarityError } from "clarity-codegen"
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from "mobx"
import { computedFn } from "mobx-utils"
import AccountStore from "../../../stores/accountStore/AccountStore"
import { AppConfigs } from "../../../stores/appEnvStore/appEnv.services"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { SlippageStore } from "../../../stores/slippageStore/SlippageStore"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { AMMSwapPool } from "../../../utils/alexjs/AMMSwapPool"
import { Currency, isBRC20Token } from "../../../utils/alexjs/Currency"
import type { SwappableCurrency } from "../../../utils/alexjs/currencyHelpers"
import { errorCodesFromName } from "../../../utils/alexjs/errorCode"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { closeTo } from "../../../utils/numberHelpers"
import { Result } from "../../../utils/Result"
import { waitUntil$, waitUntilExist$ } from "../../../utils/waitFor"
import { SwapFormErrorType } from "../types"
import { LiquidityProviderFeeModule } from "./LiquidityProviderFeeModule"
import {
  classicSwappableCurrency,
  getRoute,
  getYAmountFromOneX,
  runSpot,
} from "./SpotStore.services"

class SpotStore {
  private disposeFnArray: IReactionDisposer[]
  constructor(
    readonly appConfig: {
      config$: Pick<AppConfigs, "commonSwapCoins" | "allSwapCoins" | "pools">
    },
    readonly authStore: Pick<AuthStore, "stxAddress$" | "isWalletConnected">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "updateBalance">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$">,
    readonly chainStore: Pick<AuthStore, "currentBlockHeight$">,
  ) {
    makeObservable(this)
    this.disposeFnArray = [
      reaction(
        () => this.availableCurrencies$,
        currencies => {
          if (currencies) {
            const queries = new URLSearchParams(window.location.search)
            const from: SwappableCurrency = queries.get("fromCurrency") as any
            const to: SwappableCurrency = queries.get("toCurrency") as any
            if (from && currencies.includes(from)) {
              this.fromCurrency.set(from)
            } else {
              this.fromCurrency.set(currencies[0])
            }
            if (to && currencies.includes(to)) {
              this.toCurrency.set(to)
            }
          }
        },
        { fireImmediately: true },
      ),
    ]
  }

  destroy(): void {
    this.disposeFnArray.forEach(fn => fn())
    this.disposeFnArray.length = 0
  }

  slippagePercent = new SlippageStore()
  liquidityProviderFee = new LiquidityProviderFeeModule(this)

  @computed get availableAMMPools$(): AMMSwapPool.PoolTokens[] {
    return this.appConfig.config$.pools.filter(AMMSwapPool.isV1PoolToken)
  }

  @observable selectPoolTypes: "AMMV1_1" | "classPools" = "AMMV1_1"

  @computed get currentPairAvailableInAmmV1_1$(): boolean {
    const from = this.fromCurrency.read$
    const to = this.toCurrency.read$
    return (
      AMMSwapPool.getRoute(from, to, this.availableAMMV1_1Pools$).length > 0
    )
  }

  @computed get currentPairAvailableInClassicSwap$(): boolean {
    const from = this.fromCurrency.read$
    const to = this.toCurrency.read$
    return (
      classicSwappableCurrency.includes(from) &&
      classicSwappableCurrency.includes(to)
    )
  }

  @computed get enabledAMMV1_1Pools$(): AMMSwapPool.PoolV1_1Tokens[] {
    if (!this.currentPairAvailableInAmmV1_1$) {
      return []
    }
    if (!this.currentPairAvailableInClassicSwap$) {
      return this.availableAMMV1_1Pools$
    }
    return this.selectPoolTypes === "AMMV1_1" ? this.availableAMMV1_1Pools$ : []
  }
  @computed get availableAMMV1_1Pools$(): AMMSwapPool.PoolV1_1Tokens[] {
    return this.appConfig.config$.pools.filter(AMMSwapPool.isV1_1PoolToken)
  }

  @computed get commonCurrencies$(): SwappableCurrency[] {
    return this.appConfig.config$.commonSwapCoins
  }

  @computed get availableCurrencies$(): SwappableCurrency[] {
    return this.appConfig.config$.allSwapCoins
  }

  @observable fromCurrency = new SuspenseObservable<SwappableCurrency>()
  @observable toCurrency = new SuspenseObservable<SwappableCurrency>()

  @observable onSelectType?: "from" | "to"
  @observable showConfirmation?: SpotFormData

  spotRunning = new ConfirmTransactionStore()
  inputtedFromAmount = new SuspenseObservable<number>()

  @computed get fromCurrencyBalance$(): number {
    return this.accountStore.getBalance$(this.fromCurrency.read$)
  }

  private _route = new LazyValue(
    () => ({
      from: this.fromCurrency.read$,
      to: this.toCurrency.read$,
      amm: this.availableAMMPools$,
      ammV1_1: this.enabledAMMV1_1Pools$,
      _block: this.chainStore.currentBlockHeight$,
      _supported: waitUntil$(() => this.tokenRouteSupported$),
    }),
    ({ from, to, amm, ammV1_1 }) => getRoute(from, to, amm, ammV1_1),
    { decorator: pMemoizeDecorator({ persistKey: "spotStore.route" }) },
  )

  @computed get routes$(): SwappableCurrency[] {
    return this._route.value$
  }
  @computed get minimumReceived$(): number {
    return this.toAmount$ * (1 - this.slippagePercent.slippagePercentage)
  }

  @computed get isMaxSTX$(): boolean {
    return (
      this.fromCurrency.read$ === Currency.W_STX &&
      closeTo(
        this.accountStore.getBalance$(this.fromCurrency.read$),
        Number(this.inputtedFromAmount.read$),
        0.01,
      )
    )
  }

  @computed get fromCurrencyUnitPrice$(): number {
    return this.currencyStore.getPrice$(this.fromCurrency.read$)
  }

  @computed get toCurrencyUnitPrice$(): number {
    return this.currencyStore.getPrice$(this.toCurrency.read$)
  }

  @computed get fromAmountEstimatedUSD$(): number {
    return Number(this.inputtedFromAmount.read$) * this.fromCurrencyUnitPrice$
  }

  @computed get toAmountEstimatedUSD$(): number {
    return (
      Number(this.toAmount$) *
      this.currencyStore.getPrice$(this.toCurrency.read$)
    )
  }

  @computed get toAmount$(): number {
    return this.inputtedFromAmount.read$ * this.fromToExchangeRate$
  }

  @computed get bothTokenSelected(): boolean {
    return this.fromCurrency !== null && this.toCurrency !== null
  }

  @action reset(): void {
    this.setFromAmount(undefined)
  }

  @action swapFromAndTo(): void {
    this.setCurrency("to", this.fromCurrency.read$)
  }

  @action setFromAmount(amount?: number): void {
    this.inputtedFromAmount.set(amount)
  }

  @action setCurrency(
    type: "from" | "to",
    newCurrency?: SwappableCurrency,
  ): void {
    this.onSelectType = undefined
    const currencyKey = type === "from" ? "fromCurrency" : "toCurrency"
    if (this[currencyKey].get() === newCurrency) {
      return
    }
    const otherCurrencyKey = type === "from" ? "toCurrency" : "fromCurrency"
    if (this[otherCurrencyKey].get() === newCurrency) {
      this[otherCurrencyKey].set(this[currencyKey].get())
      this.setFromAmount(undefined)
    }
    this[currencyKey].set(newCurrency)
  }

  exchangeRates = new LazyValue(
    () => {
      return {
        stxAddress: this.authStore.stxAddress$,
        from: this.fromCurrency.read$,
        to: this.toCurrency.read$,
        amount: this.inputtedFromAmount.read$,
        ammPools: this.availableAMMPools$,
        ammV1_1Pools: this.enabledAMMV1_1Pools$,
        _block: this.chainStore.currentBlockHeight$,
        _supported$: waitUntil$(() => this.tokenRouteSupported$),
      }
    },
    ({ stxAddress, from, to, amount, ammPools, ammV1_1Pools }) => {
      return getYAmountFromOneX(
        stxAddress,
        from,
        to,
        amount,
        ammPools,
        ammV1_1Pools,
      )
        .then(r => Result.ok(r))
        .catch(e => {
          if (e instanceof ClarityError) {
            if (errorCodesFromName("ERR-MAX-IN-RATIO").includes(e.code)) {
              return Result.error(SwapFormErrorType.ErrorMaxInRatio)
            } else if (
              errorCodesFromName("ERR-MAX-OUT-RATIO").includes(e.code)
            ) {
              return Result.error(SwapFormErrorType.ErrorMaxOutRatio)
            } else {
              throw e
            }
          } else {
            throw e
          }
        })
    },
    {
      decorator: pMemoizeDecorator({ persistKey: "exchangeRate" }),
    },
  )
  @computed get fromToExchangeRate$(): number {
    return waitUntilExist$(() =>
      Result.maybeValue(this.exchangeRates.immediateValue$),
    )
  }

  @computed get toFromExchangeRate$(): number {
    return waitUntilExist$(() =>
      Result.maybeValue(this.exchangeRates.immediateValue$),
    )
  }

  @computed get tokenRouteSupported$(): boolean {
    return (
      this.currentPairAvailableInClassicSwap$ ||
      this.currentPairAvailableInAmmV1_1$
    )
  }

  @computed get spotFormData$(): Result<SpotFormData, SwapFormErrorType> {
    if (!this.authStore.isWalletConnected) {
      return Result.error(SwapFormErrorType.WalletNotConnected)
    }
    if (this.fromCurrency.get() == null || this.toCurrency.get() == null) {
      return Result.error(SwapFormErrorType.TokenNotSelected)
    }
    if (!this.tokenRouteSupported$) {
      return Result.error(SwapFormErrorType.TokenRouteNotSupported)
    }
    const amount = this.inputtedFromAmount.get()
    if (amount == null || amount === 0) {
      return Result.error(SwapFormErrorType.AmountIsEmpty)
    }
    if (amount > this.fromCurrencyBalance$) {
      return Result.error(SwapFormErrorType.InsufficientTokenBalance)
    }
    const exchangeRate = this.exchangeRates.immediateValue$
    if (exchangeRate.type === "error") {
      return exchangeRate
    }
    return Result.ok({
      exchangeRate: exchangeRate.payload,
      stxAddress: this.authStore.stxAddress$,
      fromCurrency: this.fromCurrency.read$,
      toCurrency: this.toCurrency.read$,
      slippageAmount:
        this.toAmount$ * (1 - this.slippagePercent.slippagePercentage),
      fromAmount: Number(this.inputtedFromAmount.read$),
      middleSteps: this.routes$.slice(1, -1),
      ammPools: this.availableAMMPools$,
      ammV1_1Pools: this.enabledAMMV1_1Pools$,
    })
  }

  @action showConfirmationModal(): void {
    if (this.spotFormData$.type !== "ok") {
      return
    }
    this.showConfirmation = this.spotFormData$.payload
  }

  @action hideConfirmationModal(): void {
    this.showConfirmation = undefined
  }

  @asyncAction async spot(
    spotFormData: SpotFormData,
    run = runAsyncAction,
  ): Promise<void> {
    if (this.spotRunning.running) return
    if (
      spotFormData.fromCurrency === null ||
      spotFormData.toCurrency === null
    ) {
      return
    }
    this.showConfirmation = undefined
    await run(
      this.spotRunning.run(() =>
        runSpot(
          spotFormData.stxAddress,
          spotFormData.fromCurrency,
          spotFormData.toCurrency,
          spotFormData.fromAmount,
          spotFormData.slippageAmount,
          spotFormData.middleSteps,
          spotFormData.ammPools,
          spotFormData.ammV1_1Pools,
        ).then(txId => ({ txId })),
      ),
    )
    this.reset()
  }

  routeAvailable = computedFn((target: SwappableCurrency): boolean => {
    const alreadySelected =
      this.onSelectType === "from"
        ? this.fromCurrency.get()
        : this.toCurrency.get()
    if (target === alreadySelected) {
      return false
    }
    const swapWith =
      this.onSelectType === "from"
        ? this.toCurrency.get()
        : this.fromCurrency.get()
    if (swapWith == null) {
      return true
    }
    // if (swapWith === Currency.W_DIKO) {
    //   return target === Currency.ALEX
    // }
    // if (target === Currency.W_DIKO) {
    //   return swapWith === Currency.ALEX
    // }
    // if (swapWith === Currency.W_CORGI) {
    //   return target === Currency.W_STX
    // }
    // if (target === Currency.W_CORGI) {
    //   return swapWith === Currency.W_STX
    // }
    return true
  })

  @computed get isTradingBRC20Tokens$(): boolean {
    const from = this.fromCurrency.get()
    const to = this.toCurrency.get()
    return (!!from && isBRC20Token(from)) || (!!to && isBRC20Token(to))
  }
}

export interface SpotFormData {
  exchangeRate: number
  stxAddress: string
  fromCurrency: SwappableCurrency
  toCurrency: SwappableCurrency
  fromAmount: number
  slippageAmount: number
  middleSteps: Array<SwappableCurrency>
  ammPools: Array<AMMSwapPool.PoolTokens>
  ammV1_1Pools: Array<AMMSwapPool.PoolV1_1Tokens>
}

export default SpotStore
