import { DependencyList, MutableRefObject, useEffect, useRef } from "react"

export function useEffectWithRef<S>(
  callback: (memoRef: MutableRefObject<undefined | S>) => void | (() => void),
  deps: DependencyList,
): void {
  const memoRef = useRef<undefined | S>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callback(memoRef), deps)
}
