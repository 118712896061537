import clsx from "clsx"
import { ComponentType, isValidElement, ReactElement, SVGProps } from "react"
import { ButtonVariantProps } from "../ButtonVariant"
import { HeadlessButton } from "../HeadlessButton"

export interface PlainIconButtonProps<T> extends ButtonVariantProps {
  iconClassName?: string
  effectOnIconOnly?: boolean
  icon: ReactElement<SVGProps<T>> | ComponentType<SVGProps<T>>
  inline?: boolean
}

export function PlainIconButton<T>(
  props: PlainIconButtonProps<T>,
): JSX.Element {
  const { className, textClassName, ...restProps } = props

  const icon = isValidElement(props.icon) ? props.icon : <props.icon />

  return (
    <HeadlessButton
      {...restProps}
      className={clsx(
        props.effectOnIconOnly
          ? "group/PlainIconButton"
          : `opacity-40 hover:opacity-100 active:opacity-40 visited:opacity-40`,
        props.inline ? "inline-flex" : "flex",
        "items-center",
        className,
      )}
    >
      <span
        className={clsx(
          props.effectOnIconOnly &&
            `opacity-40 group-hover/PlainIconButton:opacity-100 group-active/PlainIconButton:opacity-40 group-visited/PlainIconButton:opacity-40`,
          props.iconClassName,
        )}
      >
        {icon}
      </span>

      {props.children && (
        <span className={textClassName}>&nbsp;{props.children}</span>
      )}
    </HeadlessButton>
  )
}
