import AccountStore from "../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { HistoryModule } from "./HistoryModule/HistoryModule"
import { WrapFormModule } from "./WrapFormModule"
import { WrapFormViewModule } from "./WrapFormViewModule"

export class WrapBridgeStore {
  history: HistoryModule
  wrapForm: WrapFormModule
  wrapFormViewModule: WrapFormViewModule

  constructor(
    private readonly currencyStore: Pick<
      CurrencyStore,
      "getTokenInfo$" | "getPrice$" | "getCurrencyForAsset$"
    >,
    private readonly accountStore: Pick<AccountStore, "getBalance$">,
    private readonly authStore: Pick<
      AuthStore,
      "metaMaskModule" | "stxAddress$"
    >,

    private readonly appEnvStore: AppEnvStore,
  ) {
    this.history = new HistoryModule(
      currencyStore,
      accountStore,
      authStore,
      appEnvStore,
    )
    this.wrapForm = new WrapFormModule(accountStore, authStore, appEnvStore)
    this.wrapFormViewModule = new WrapFormViewModule(
      currencyStore,
      authStore,
      this.wrapForm,
    )
  }

  destroy(): void {
    this.wrapForm.destroy()
  }
}
