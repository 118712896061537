/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, get, makeObservable, observable, set } from "mobx"
import {
  Bar,
  DatafeedConfiguration,
  ErrorCallback,
  HistoryCallback,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SubscribeBarsCallback,
  SymbolResolveExtension,
} from "../../../../vendors/charting_library/charting_library.esm"
import { PerpetualStore } from "../../../Perpetual/store/PerpetualStore"
import { OrderbookStore } from "../OrderbookStore"
import { fetchChartDataFor } from "../OrderbookStore.service/OrderbookStore.service"
import { INTRADAY_RESOLUTIONS, RESOLUTIONS, RESOLUTION_MAP } from "../constants"
import {
  fillEmptyBarsByCount,
  fillEmptyBarsInByTimeRange,
} from "./ChartDatafeedModule.service"
import { OrderbookMarketId } from "./StxDxMarket.service"

const defaultConfiguration: DatafeedConfiguration = {
  exchanges: [],
  supports_marks: false,
  supports_timescale_marks: false,
  supported_resolutions: RESOLUTIONS,
}

export class ChartDatafeedModule
  implements IDatafeedChartApi, IExternalDatafeed
{
  constructor(readonly store: OrderbookStore | PerpetualStore) {
    makeObservable(this)
  }
  @observable.deep lastTicks: { [key in OrderbookMarketId]?: Bar } = {}
  /**
   * @see https://github.com/alexgo-io/charting-library/wiki/Symbology#common-prices
   */
  @computed get priceScale(): number {
    return Math.pow(10, this.store.market.read$.pricePrecision)
  }
  /**
   * @see https://github.com/alexgo-io/charting-library/wiki/Symbology#common-prices
   */
  @computed get minMovement(): number {
    return 1
  }

  onReady(callback: OnReadyCallback): void {
    callback(defaultConfiguration)
  }
  searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback,
  ): void {
    return
  }
  resolveSymbol(
    symbolName: string,
    onResolve: ResolveCallback,
    onError: ErrorCallback,
    extension?: SymbolResolveExtension | undefined,
  ): void {
    const symbolInfo: LibrarySymbolInfo = {
      name: `ALEX:${this.store.marketId$}`,
      ticker: this.store.marketId$,
      full_name: this.store.marketId$,
      description: this.store.marketId$,
      type: "crypto",
      session: "24x7",
      exchange: "ALEX",
      listed_exchange: "ALEX",
      timezone: "Etc/UTC",
      format: "price",
      pricescale: this.priceScale,
      minmov: this.minMovement,
      supported_resolutions: RESOLUTIONS,
      has_seconds: false,
      has_intraday: true,
      intraday_multipliers: INTRADAY_RESOLUTIONS,
      has_daily: true,
      has_weekly_and_monthly: true,
      volume_precision: this.store.orderbook.tradeTokenInfo$.precision,
    }
    onResolve(symbolInfo)
    return
  }
  getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: ErrorCallback,
  ): void {
    console.log(`[Datafeed] getBars for symbol ${symbolInfo.name}`, {
      symbolInfo,
      resolution,
      periodParams,
    })
    // `from`, `to` in seconds
    const { from, to, countBack, firstDataRequest } = periodParams
    const resolutionInMinute = RESOLUTION_MAP[resolution]!
    const alignedTo = to - (to % (resolutionInMinute * 60))
    fetchChartDataFor({
      market: this.store.marketId$,
      resolution: resolutionInMinute,
      countBack,
      from: countBack == null ? from : undefined, // use countBack first
      to: firstDataRequest ? "now" : alignedTo.toString(),
    })
      .then(response => {
        const bars = response.meta.noData
          ? []
          : countBack
          ? fillEmptyBarsByCount(
              countBack,
              alignedTo * 1000,
              resolutionInMinute,
              response.bars,
            )
          : fillEmptyBarsInByTimeRange(
              from * 1000,
              alignedTo * 1000,
              resolutionInMinute,
              response.bars,
            )
        const lastTick = bars[bars.length - 1]
        set(this.lastTicks, {
          [this.store.marketId$]: { ...lastTick, volume: 0 },
        })
        onResult(bars, response.meta)
      })
      .catch(error => {
        onError(error)
        throw error
      })
    return
  }
  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void,
  ): void {
    console.log(`[Datafeed] subscribeBars for symbol ${symbolInfo.name}`, {
      symbolInfo,
      resolution,
      listenerGuid,
    })
    const resolutionInMinute = RESOLUTION_MAP[resolution]!
    const now = Date.now() / 1000
    const alignedTo = now - (now % (resolutionInMinute * 60))
    let nextTo = alignedTo
    setInterval(() => {
      fetchChartDataFor({
        market: this.store.marketId$,
        resolution: resolutionInMinute,
        countBack: 1,
        to: nextTo.toString(),
      })
        .then(response => {
          const tick =
            response.bars[0] ?? get(this.lastTicks, this.store.marketId$)
          tick && onTick(tick)
        })
        .catch(error => {
          throw error
        })
      nextTo += resolutionInMinute * 60
    }, resolutionInMinute * 60 * 1000)
    return
  }
  unsubscribeBars(listenerGuid: string): void {
    console.log("[Datafeed] unsubscribeBars", { listenerGuid })
    return
  }
}
