import { action, computed, makeObservable } from "mobx"
import { firstValueFrom } from "rxjs"
import {
  LazyValue,
  SharedLazyValue,
} from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { BigNumber } from "../../../../utils/BigNumber"
import { Result } from "../../../../utils/Result"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { BRC20Currency, isBRC20Token } from "../../../../utils/alexjs/Currency"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { waitFor } from "../../../../utils/waitFor"
import { BitcoinClient } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClient"
import { BitcoinNetwork } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClientBRC20Wallet"
import { getTokenInfoFromBRC20Symbol$ } from "../../../Orderbook/OrderbookPegInPage/stores/OrderbookPegInStore.services"
import { WalletAdapter } from "../../../Orderbook/OrderbookPegInPage/stores/WalletAdapters/WalletAdapters.types"
import type { AvailableTokenInfo } from "../components/TransferPanel"
import {
  TransferFormError,
  TransferFormErrorType,
} from "../components/TransferPanel.types"

export class PegInFormModule {
  constructor(
    private currencyStore: CurrencyStore,
    private btcClient: SharedLazyValue<BitcoinClient>,
    private btcAdapter: SharedLazyValue<WalletAdapter>,
    private brc20Wallet: SharedLazyValue<BitcoinClientBRC20Wallet>,
  ) {
    makeObservable(this)
  }

  private brc20WalletBalance = new LazyValue(
    () => ({ wallet: this.brc20Wallet.value$ }),
    ({ wallet }) => wallet.walletBalance,
  )

  @computed
  get brc20WalletBalance$(): (AvailableTokenInfo & {
    brc20Currency: BRC20Currency
    symbol: string
  })[] {
    return this.brc20WalletBalance.value$.tokens.flatMap(token => {
      const tokenInfo = getTokenInfoFromBRC20Symbol$(
        this.currencyStore,
        token.symbol,
      )

      if (tokenInfo == null) return []

      return [
        {
          brc20Currency: TokenInfoPresets.toCurrency(
            tokenInfo,
          ) as BRC20Currency,
          token: tokenInfo,
          symbol: token.symbol,
          totalBalance: BigNumber.from(token.balance),
          transferableBalance: BigNumber.from(token.transferableBalance),
          availableBalance: BigNumber.from(token.restBalance),
        },
      ]
    })
  }

  peggingCurrency = new SuspenseObservable<BRC20Currency>()

  peggingAmount = new SuspenseObservable<BigNumber>()

  @computed
  get selectedTokenBalance$(): AvailableTokenInfo & {
    brc20Currency: BRC20Currency
    symbol: string
  } {
    return this.brc20WalletBalance$.find(
      token => token.brc20Currency === this.peggingCurrency.read$,
    )!
  }

  @computed
  get peggingToken$(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.peggingCurrency.read$)
  }

  @computed
  get formData$(): Result<PegInFormModuleFormData, TransferFormError> {
    if (this.peggingCurrency.get() == null) {
      return Result.error({ type: TransferFormErrorType.TokenNotSelected })
    }

    if (
      this.peggingAmount.get() == null ||
      BigNumber.isZero(this.peggingAmount.read$)
    ) {
      return Result.error({ type: TransferFormErrorType.AmountIsEmpty })
    }

    if (
      BigNumber.isLt(
        this.selectedTokenBalance$.totalBalance,
        this.peggingAmount.read$,
      )
    ) {
      return Result.error({ type: TransferFormErrorType.InsufficientBalance })
    }

    return Result.ok({
      tokenSymbol: this.selectedTokenBalance$.symbol,
      tokenCurrency: this.peggingCurrency.read$,
      amount: this.peggingAmount.read$,
    })
  }

  inscribeFormData = new SuspenseObservable<{
    token: TokenInfo
    tokenCount: BigNumber
    btcNetwork: BitcoinNetwork
    inscribeServiceFeeToken: TokenInfo
    inscribeServiceFeeTokenCount: BigNumber
    inscribeServiceFeeTokenCountToUSD: BigNumber
    chargeBtcAddress: string
    inscribeOrderId: string
    inscribeOrderLink: string
  }>()

  @action
  onDismissInscribeConfirmModal(): void {
    this.inscribeFormData.set(undefined)
  }

  async trySelectBRC20Currency(currency: string): Promise<void> {
    if (!isBRC20Token(currency)) return

    const tokenInfo = waitFor(() => this.currencyStore.getTokenInfo$(currency))
    if (tokenInfo == null) return

    this.peggingCurrency.set(currency)
  }

  async submit(formData: PegInFormModuleFormData): Promise<void> {
    const token = await waitFor(() =>
      this.currencyStore.getTokenInfo$(formData.tokenCurrency),
    )

    const networkInfo = await firstValueFrom(this.btcClient.value$.networkInfo)

    const resp = await this.brc20Wallet.value$.createTokenTransferInscribeOrder(
      formData.tokenSymbol,
      formData.amount,
      networkInfo.fees.fastestFee,
    )

    this.inscribeFormData.set({
      token,
      tokenCount: formData.amount,
      btcNetwork: this.btcClient.value$.network,
      inscribeServiceFeeToken: TokenInfoPresets.MockSats,
      inscribeServiceFeeTokenCount: resp.charge.amount,
      inscribeServiceFeeTokenCountToUSD: resp.charge.feeToUSD,
      chargeBtcAddress: resp.charge.address,
      inscribeOrderId: resp.id,
      inscribeOrderLink: resp.orderLink,
    })
  }

  async confirmInscribeTransfer(): Promise<
    undefined | { txId: string; explorerLink: string }
  > {
    const formData = this.inscribeFormData.get()
    if (formData == null) return

    const resp = await this.btcAdapter.value$.sendBitcoin(
      formData.chargeBtcAddress,
      formData.inscribeServiceFeeTokenCount,
    )

    this.onDismissInscribeConfirmModal()
    return {
      txId: resp.txId,
      explorerLink: await this.btcClient.value$.explorerLink(resp.txId),
    }
  }
}

export interface PegInFormModuleFormData {
  tokenCurrency: BRC20Currency
  tokenSymbol: string
  amount: BigNumber
}
