import { computed, makeObservable } from "mobx"
import { ESTIMATED_BLOCK_DURATION } from "../../../../../config"
import CurrencyStore from "../../../../../stores/currencyStore/CurrencyStore"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { isNotNull } from "../../../../../utils/utils"
import { ClaimTokenInfo, StakeCycle, StakeCycleStatus } from "../../../types"
import MyStakingViewModule from "./MyStakingViewModule"

class MyStackingCellViewModule implements StakeCycle {
  constructor(
    readonly cycleNumber: number,
    readonly myStacking: MyStakingViewModule,
  ) {
    makeObservable(this)
  }

  private get currencyStore(): Pick<CurrencyStore, "getTokenInfo$"> {
    return this.myStacking.store.currencyStore
  }

  @computed get currentBlock(): number {
    return this.myStacking.store.authStore.currentBlockHeight$
  }

  @computed get fromBlock(): number {
    return (
      this.myStacking.store.nextCycleBlock$ +
      (this.cycleNumber - this.myStacking.store.currentCycle$ - 1) *
        this.myStacking.store.blocksPerCycle.value$
    )
  }

  @computed get targetBlock(): number {
    return (
      this.myStacking.store.nextCycleBlock$ +
      (this.cycleNumber - this.myStacking.store.currentCycle$) *
        this.myStacking.store.blocksPerCycle.value$
    )
  }
  @computed get targetDate(): Date {
    return new Date(
      new Date().getTime() +
        (this.targetBlock - this.currentBlock) * ESTIMATED_BLOCK_DURATION,
    )
  }

  @computed get cycleStatus(): StakeCycleStatus {
    if (this.cycleNumber > this.myStacking.currentCycle) {
      return StakeCycleStatus.Upcoming
    }
    if (this.cycleNumber < this.myStacking.currentCycle) {
      return StakeCycleStatus.Finished
    }
    return StakeCycleStatus.InProgress
  }

  @computed get apr(): number {
    return this.myStacking.aprIn(this.cycleNumber)
  }

  @computed private get totalStakedCount$(): number {
    return this.myStacking.totalStakedStats(this.cycleNumber)
  }

  @computed get totalStaked(): ClaimTokenInfo {
    return {
      token: this.currencyStore.getTokenInfo$(
        this.myStacking.store.stakableToken,
      ),
      count: this.totalStakedCount$,
    }
  }

  @computed get myStaked(): ClaimTokenInfo {
    return {
      token: this.currencyStore.getTokenInfo$(
        this.myStacking.store.stakableToken,
      ),
      count: this.myStacking.stakedAt(this.cycleNumber),
    }
  }

  @computed get principalToClaim(): ClaimTokenInfo[] {
    const info: ClaimTokenInfo = {
      token: this.currencyStore.getTokenInfo$(
        this.myStacking.store.stakableToken,
      ),
      count: this.myStacking.toReturnAt(this.cycleNumber),
    }

    if (
      info.count > 0 || // https://www.notion.so/alexgo-io/Dual-Yield-Farm-3916a51de85d4c36b9237d94bb3cb4fc#5656716f528a4dc08a1779a8c7728b26
      this.myStacking.store.stakableToken === Currency.ALEX
    ) {
      return [info]
    } else {
      return []
    }
  }

  @computed get rewardToClaim(): ClaimTokenInfo[] {
    const dualYield = this.myStacking.store.dualYield
    const dualYieldV11 = this.myStacking.store.dualYieldV1_1
    // token order is important
    // https://www.notion.so/alexgo-io/Dual-Yield-Farm-3916a51de85d4c36b9237d94bb3cb4fc#236b7965edc3429b9509c29d1fd8d81f
    return [
      {
        token: this.currencyStore.getTokenInfo$(Currency.ALEX),
        count: this.myStacking.rewardAt(this.cycleNumber),
      },
      dualYield != null
        ? {
            token: dualYield.tokenInfo$,
            count:
              this.myStacking.rewardAt(this.cycleNumber) *
              dualYield.multiplier$,
          }
        : dualYieldV11 != null && this.cycleNumber >= dualYieldV11.startCycle$
        ? {
            token: dualYieldV11.tokenInfo$,
            count:
              this.myStacking.rewardAt(this.cycleNumber) *
              dualYieldV11.multiplier$,
          }
        : null,
      {
        token: this.currencyStore.getTokenInfo$(Currency.APOWER),
        count:
          this.myStacking.rewardAt(this.cycleNumber) *
          this.myStacking.store.apowerMultiplier$,
      },
    ].filter(isNotNull)
  }
}

export default MyStackingCellViewModule
