import clsx from "clsx"
import { defineMessage, useIntl } from "react-intl"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../utils/SuspenseResource"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  CompactCard,
  CompactCardTitle,
} from "../wrappedCommonComponents/CompactCard"
import { ReactComponent as SettingIcon } from "./_/setting.svg"

export const TradingAccountFrame: FCC<{
  className?: string
  gapClassName?: string
  onSettingClick?: () => void
  hasRegistered?: SuspenseResource<boolean>
}> = props => {
  const { $t } = useIntl()
  return (
    <CompactCard
      className={clsx(
        "flex flex-col",
        props.gapClassName ?? "gap-y-4",
        props.className,
      )}
    >
      <div className="flex flex-row items-center">
        <CompactCardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Trading account",
              description: `Orderbook/Trading Account Panel/title`,
            }),
          )}
        </CompactCardTitle>
        {safeReadResource(props.hasRegistered) && (
          <PlainIconButton
            icon={<SettingIcon />}
            onClick={props.onSettingClick}
          />
        )}
      </div>

      {props.children}
    </CompactCard>
  )
}
