import { AutoStakingAPowerDistributionRowData } from "../../store/transformerGroups/stake/autoStakingAPowerDistribution"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const autoStakingAPowerDistributionExporter: Exporter<
  AutoStakingAPowerDistributionRowData
> = (rowData, allTokenInfos) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.apowerCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = `Cycle #${rowData.cycle} Reward Harvest`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
