import clsx from "clsx"
import { flow, range, sortBy } from "lodash"
import { FC } from "react"
import { useIntl } from "react-intl"
import { CustomScrollBarContainer } from "../../../../components/CustomScrollBarContainer"
import { TokenCount } from "../../../../components/TokenCount"
import { SuspenseResource } from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { Colors } from "../designTokens"
import { gapSpacing } from "./OrderbookListFrame"
import { Column1Cell, Column2Cell, Column3Cell, ColumnText } from "./_/Cells"
import { OrderListContainer } from "./_/OrderListContainer"
import {
  AmountCell,
  OrderListItemContainer,
  PriceCell,
  ProgressBarOrderListItemContainer,
  TotalPriceCell,
} from "./_/OrderListItem"
import { orderlistHeaders$t } from "./_/commonIntlMessages"
import { Order } from "./types"

const listItemCount = 15

export interface OrderbookListContentProps {
  priceToken: TokenInfo
  tradeToken: TokenInfo
  buyOrders: SuspenseResource<Order.OrderbookOrder[]>
  sellOrders: SuspenseResource<Order.OrderbookOrder[]>
  lastPriceTokenCountPerTradeTokenDelta: number
  lastPriceTokenCountPerTradeToken: number
  onClickOrder: (order: Order.OrderbookOrder) => void
}

export const OrderbookListContent: FC<OrderbookListContentProps> = props => {
  const intl = useIntl()

  return (
    <>
      <div className={"flex text-gray-500"} style={{ marginBlock: gapSpacing }}>
        <Column1Cell>
          {orderlistHeaders$t.price(intl, props.priceToken)}
        </Column1Cell>
        <Column2Cell>
          {orderlistHeaders$t.amount(intl, props.tradeToken)}
        </Column2Cell>
        <Column3Cell>
          {orderlistHeaders$t.total(intl, props.priceToken)}
        </Column3Cell>
      </div>

      <OrderListContainer orders={props.sellOrders}>
        {flow(
          orders =>
            sortBy(orders, o => -o.priceTokenCountPerTradeToken).slice(
              -listItemCount,
            ),
          orders =>
            orders.map((o, idx) => (
              <OrderListItem
                key={idx}
                priceToken={props.priceToken}
                tradeToken={props.tradeToken}
                order={o}
                onClick={() => props.onClickOrder(o)}
              />
            )),
          orders => [
            ...(orders.length >= listItemCount
              ? []
              : range(listItemCount - orders.length).map(i => (
                  <OrderListItemPlaceholder
                    key={`OrderListItemPlaceholder-${i}`}
                  />
                ))),
            ...orders,
          ],
          jsx => (
            <CustomScrollBarContainer>
              <ul className={"flex flex-col"} style={{ rowGap: gapSpacing }}>
                {jsx}
              </ul>
            </CustomScrollBarContainer>
          ),
        )}
      </OrderListContainer>

      <ColumnText
        className={clsx("border-gray-500/30 border-t border-b leading-none")}
        style={{ marginBlock: gapSpacing, paddingBlock: gapSpacing }}
      >
        {(() => {
          if (props.lastPriceTokenCountPerTradeTokenDelta < 0) {
            return (
              <span className={Colors.decreaseTextClassName}>
                <TokenCount
                  token={props.priceToken}
                  count={props.lastPriceTokenCountPerTradeToken}
                />
                &nbsp;↓
              </span>
            )
          } else if (props.lastPriceTokenCountPerTradeTokenDelta > 0) {
            return (
              <span className={Colors.increaseTextClassName}>
                <TokenCount
                  token={props.priceToken}
                  count={props.lastPriceTokenCountPerTradeToken}
                />
                &nbsp;↑
              </span>
            )
          } else {
            return (
              <TokenCount
                token={props.priceToken}
                count={props.lastPriceTokenCountPerTradeToken}
              />
            )
          }
        })()}
      </ColumnText>

      <OrderListContainer orders={props.buyOrders}>
        {flow(
          orders =>
            sortBy(orders, o => -o.priceTokenCountPerTradeToken).slice(
              0,
              listItemCount,
            ),
          orders =>
            orders.map((o, idx) => (
              <OrderListItem
                key={idx}
                priceToken={props.priceToken}
                tradeToken={props.tradeToken}
                order={o}
                onClick={() => props.onClickOrder(o)}
              />
            )),
          orders => [
            ...orders,
            ...(orders.length >= listItemCount
              ? []
              : range(listItemCount - orders.length).map(i => (
                  <OrderListItemPlaceholder
                    key={`OrderListItemPlaceholder-${i}`}
                  />
                ))),
          ],
          jsx => (
            <CustomScrollBarContainer>
              <ul className={"flex flex-col"} style={{ rowGap: gapSpacing }}>
                {jsx}
              </ul>
            </CustomScrollBarContainer>
          ),
        )}
      </OrderListContainer>
    </>
  )
}

const OrderListItemPlaceholder: FC<{
  className?: string
}> = props => {
  return (
    <OrderListItemContainer className={clsx("text-gray-500", props.className)}>
      <Column1Cell>-</Column1Cell>
      <Column2Cell>-</Column2Cell>
      <Column3Cell>-</Column3Cell>
    </OrderListItemContainer>
  )
}

const OrderListItem: FC<{
  className?: string
  priceToken: TokenInfo
  tradeToken: TokenInfo
  order: Order.OrderbookOrder
  onClick: () => void
}> = props => {
  return (
    <ProgressBarOrderListItemContainer
      className={props.className}
      order={props.order}
      onClick={props.onClick}
    >
      <PriceCell
        isBelongsCurrentUser={props.order.isBelongsToCurrentUser}
        priceToken={props.priceToken}
        order={props.order}
      />
      <AmountCell tradeToken={props.tradeToken} order={props.order} />
      <TotalPriceCell priceToken={props.priceToken} order={props.order} />
    </ProgressBarOrderListItemContainer>
  )
}
