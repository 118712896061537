import {
  booleanT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const autoAlexBuybackHelper = defineContract({
  "auto-alex-buyback-helper": {
    claim: {
      input: [{ name: "amount", type: numberT }],
      output: responseSimpleT(
        tupleT({
          "alex-103825": numberT,
          "alex-104305": numberT,
          "claimed-103825": numberT,
          "claimed-104305": numberT,
        }),
      ),
      mode: "public",
    },
    pause: {
      input: [{ name: "new-paused", type: booleanT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-buyback": {
      input: [
        {
          name: "users",
          type: listT(
            tupleT({ amount: numberT, cycle: numberT, user: principalT }),
          ),
        },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-rate-103825": {
      input: [{ name: "new-rate", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-rate-104305": {
      input: [{ name: "new-rate", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-alex": {
      input: [{ name: "amount", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-autoalex": {
      input: [{ name: "amount", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    upgrade: {
      input: [{ name: "amount", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-buyback-amount": {
      input: [{ name: "user", type: principalT }],
      output: tupleT({
        "boughtback-103825": numberT,
        "boughtback-104305": numberT,
        "buyback-103825": numberT,
        "buyback-104305": numberT,
      }),
      mode: "readonly",
    },
    "get-contract-owner": { input: [], output: principalT, mode: "readonly" },
    "get-rate-103825": { input: [], output: numberT, mode: "readonly" },
    "get-rate-104305": { input: [], output: numberT, mode: "readonly" },
    "is-paused": { input: [], output: booleanT, mode: "readonly" },
    "boughtback-103825": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "boughtback-104305": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "buyback-103825": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "buyback-104305": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    paused: { input: noneT, output: booleanT, mode: "variable" },
    "rate-103825": { input: noneT, output: numberT, mode: "variable" },
    "rate-104305": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
