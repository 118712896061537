import clsx from "clsx"
import { type FC, type ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { cancel$t, save$t } from "../../../../commonIntlMessages"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { TokenCountInput } from "../../../../components/TokenInput/TokenInput"
import { Button } from "../../../../components/button/Button"
import { SmartLoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { BigNumber } from "../../../../utils/BigNumber"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { isNotNull } from "../../../../utils/utils"

export type NetworkFeeRatePatch =
  | { type: "fast" | "avg" | "slow" }
  | { type: "custom"; rate: BigNumber }

export interface EditFeeModalContentProps {
  className?: string
  recommendedNetworkFeeRates: {
    fast: BigNumber
    avg: BigNumber
    slow: BigNumber
  }
  networkFeeRate: {
    type: "fast" | "avg" | "slow" | "custom"
    rate: BigNumber
  }
  feeTokenInfo: TokenInfo
  customRate?: BigNumber
  onChangeNetworkFeeRate: (ratePatch: NetworkFeeRatePatch) => void

  onConfirm: () => void | Promise<void>
  onClose: () => void
}

export const EditFeeModalContent: FC<EditFeeModalContentProps> = props => {
  const { $t } = useIntl()

  const feeNameMapping: {
    [key in EditFeeModalContentProps["networkFeeRate"]["type"]]: string
  } = {
    fast: $t(
      defineMessage({
        defaultMessage: "Fast",
        description: "Orderbook Peg-In Page/Edit Fee Modal Content/fee config",
      }),
    ),
    avg: $t(
      defineMessage({
        defaultMessage: "Avg",
        description: "Orderbook Peg-In Page/Edit Fee Modal Content/fee config",
      }),
    ),
    slow: $t(
      defineMessage({
        defaultMessage: "Slow",
        description: "Orderbook Peg-In Page/Edit Fee Modal Content/fee config",
      }),
    ),
    custom: $t(
      defineMessage({
        defaultMessage: "Custom",
        description: "Orderbook Peg-In Page/Edit Fee Modal Content/fee config",
      }),
    ),
  }

  return (
    <CardModalContent
      title={$t(
        defineMessage({
          defaultMessage: "Edit Fee",
          description: "Orderbook Peg-In Page/Edit Fee Modal Content/title",
        }),
      )}
      onClose={props.onClose}
      className={props.className}
    >
      <div>
        {$t(
          defineMessage({
            defaultMessage: "Fees:",
            description:
              "Orderbook Peg-In Page/Edit Fee Modal Content/field name",
          }),
        )}
      </div>

      <ul className="flex flex-row gap-x-2.5 justify-start text-center">
        {(
          Object.entries(props.recommendedNetworkFeeRates) as [
            "fast" | "avg" | "slow",
            BigNumber,
          ][]
        ).map(([type, rate]) => (
          <Item
            key={type}
            isActive={type === props.networkFeeRate.type}
            onClick={() => {
              props.onChangeNetworkFeeRate({ type })
            }}
          >
            <div className="text-sm leading-5 font-bold text-gray-200">
              {feeNameMapping[type]}
            </div>
            <div className="text-xs leading-4 font-normal text-gray-400">
              {$t(
                defineMessage({
                  defaultMessage: "{rate} sat/vB",
                  description:
                    "Orderbook Peg-In Page/Edit Fee Modal Content/fee rate",
                }),
                { rate: BigNumber.toString(rate) },
              )}
            </div>
          </Item>
        ))}
        <Item
          isActive={"custom" === props.networkFeeRate.type}
          onClick={() => {
            props.onChangeNetworkFeeRate({
              type: "custom",
              rate: props.customRate ?? BigNumber.from(0),
            })
          }}
        >
          <div>
            <TokenCountInput
              token={props.feeTokenInfo}
              onChange={newValue => {
                isNotNull(newValue) &&
                  newValue !== BigNumber.toNumber(props.customRate ?? "") &&
                  props.onChangeNetworkFeeRate({
                    type: "custom",
                    rate: BigNumber.from(newValue),
                  })
              }}
              placeholder={feeNameMapping.custom}
              value={BigNumber.toNumber(props.customRate ?? "")}
              className="bg-transparent max-w-[3.75rem] outline-none w-max h-max text-center"
            />
          </div>
          <div className="text-xs leading-4 font-normal text-gray-400">
            {$t(
              defineMessage({
                defaultMessage: "sat/vB",
                description:
                  "Orderbook Peg-In Page/Edit Fee Modal Content/fee rate",
              }),
            )}
          </div>
        </Item>
      </ul>

      <div className="flex flex-row gap-x-2.5 mt-[0.875rem]">
        <Button className="flex-1" onClick={props.onClose}>
          {$t(cancel$t)}
        </Button>

        <SmartLoadableButton
          className="flex-1"
          Variant={GradientFilledButton}
          onClick={props.onConfirm}
        >
          {$t(save$t)}
        </SmartLoadableButton>
      </div>
    </CardModalContent>
  )
}

const Item: FC<{
  isActive?: boolean
  onClick?: () => void
  children: ReactNode
}> = props => (
  <li
    className={clsx(
      "min-w-[6.25rem] p-4 flex flex-col gap-y-1 items-center justify-center",
      "rounded-lg bg-black/20 cursor-pointer border-2",
      {
        "border-blue-600": props.isActive,
        "border-transparent hover:border-gray-300": !props.isActive,
      },
    )}
    onClick={props.onClick}
  >
    {props.children}
  </li>
)
