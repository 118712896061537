/**
 * Guide to add new route:
 *
 * 1. add new path item in RoutePath
 * 2. add new item in pathGenerator
 * 3. add Route in App.tsx
 */
import { pathGenerator, PathGenerator } from "./_/pathGenerator"

export type { PathGenerator } from "./_/pathGenerator"
export { RoutePath, RoutePathDefault } from "./_/RoutePath"

export function usePathGenerator(): PathGenerator {
  return pathGenerator
}
