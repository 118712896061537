import { FC } from "react"
import { Helmet } from "react-helmet-async"
import { RootLayoutContainer } from "../../../components/LayoutContainer/RootLayoutContainer"
import styles from "./ComingSoonPage.module.scss"
import ComingSoonIconForLargeScreen from "./assets/BRC-20-comingsoon-large.png"
import ComingSoonIconForSmallScreen from "./assets/BRC-20-comingsoon-small.png"
import { ReactComponent as HeaderDesktop } from "./assets/headerDesktop.svg"
import { ReactComponent as HeaderMobile } from "./assets/headerMobile.svg"
import { ReactComponent as RoadmapDesktop } from "./assets/roadMapDesktop.svg"
import { ReactComponent as RoadmapMobile } from "./assets/roadMapMobile.svg"

export const ComingSoonPage: FC = () => {
  return (
    <RootLayoutContainer fullWidth={true}>
      <Helmet>
        <body className={styles.defaultBackground} />
      </Helmet>
      <div className="hidden lg:flex flex-col space-y-20 items-center relative">
        <HeaderDesktop style={{ maxWidth: "100%" }} />
        <img
          src={ComingSoonIconForLargeScreen}
          className="max-w-full w-[1212px] h-[582px]"
          alt="Currently Upgrading"
        />
        <RoadmapDesktop style={{ maxWidth: "100%" }} />
      </div>
      <div className="flex lg:hidden flex-col space-y-20 items-center relative">
        <HeaderMobile style={{ maxWidth: "100%" }} />
        <img
          src={ComingSoonIconForSmallScreen}
          className="max-w-full w-[451px] h-[531px] object-cover"
          alt="Currently Upgrading"
        />
        <RoadmapMobile style={{ maxWidth: "100%" }} />
      </div>
    </RootLayoutContainer>
  )
}
