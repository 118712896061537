import {
  ComponentType,
  createContext,
  createElement,
  ReactNode,
  useContext,
} from "react"
import { FCC } from "./types"

export const createUseCheckNested = (
  name: string,
): {
  Provider: ComponentType<{ children?: ReactNode }>
  useCheckNested: () => boolean
  useThrowNested: () => void
} => {
  const Context = createContext(false)

  const Provider: FCC = props =>
    createElement(Context.Provider, { value: true }, props.children)
  Provider.displayName = `${name}CheckNested.Provider`

  const useCheckNested = (): boolean => useContext(Context)

  const useThrowNested = (): void => {
    const isNested = useCheckNested()

    if (isNested) {
      throw new Error(`${name} can not be nested`)
    }
  }

  return { Provider, useCheckNested, useThrowNested }
}
