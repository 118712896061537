import { createContext, FC, ReactNode, useContext } from "react"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useAuthStore } from "../authStore/useAuthStore"
import CurrencyStore from "./CurrencyStore"

const CurrencyStoreContext = createContext<CurrencyStore | null>(null)

export const CurrencyStoreProvider: FC<{
  children: ReactNode
}> = props => {
  const authStore = useAuthStore()
  const store = useCreation(() => new CurrencyStore(authStore), [authStore])
  return (
    <CurrencyStoreContext.Provider value={store}>
      {props.children}
    </CurrencyStoreContext.Provider>
  )
}

export const useCurrencyStore = (): CurrencyStore => {
  const store = useContext(CurrencyStoreContext)
  if (store == null) {
    throw new Error(
      "useCurrencyStore must be used within a CurrencyStoreProvider",
    )
  }
  return store
}
