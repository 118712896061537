import { FC, ReactNode } from "react"
import {
  IntlShape,
  MessageDescriptor,
  defineMessage,
  useIntl,
} from "react-intl"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { NavLink } from "../../../../components/NavLink"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { Button } from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { AddressSection } from "./_/AddressSection"
import { DetailSection } from "./_/DetailSection"
import { QueuedSection } from "./_/QueuedSection"

export type FormErrorDetail =
  | MessageDescriptor
  | ((intl: IntlShape) => ReactNode)

export const RedeemOrdinalsModalContent: FC<{
  queuingPegRequestCount: SuspenseResource<number>
  formErrorMessage?: SuspenseResource<undefined | FormErrorDetail>
  token: SuspenseResource<TokenInfo>
  tokenAmount: SuspenseResource<number>
  targetBtcAddress?: SuspenseResource<string>
  onTargetBtcAddressChange: (newAddress: null | string) => void
  candidateTargetBtcAddress: SuspenseResource<string | undefined>
  onFillCandidateTargetBtcAddress: SuspenseResource<undefined | (() => void)>
  redeemFeeToken: SuspenseResource<TokenInfo>
  redeemFee: SuspenseResource<number>
  redeemFeeRate: SuspenseResource<number>
  redeemGasFeeToken: SuspenseResource<TokenInfo>
  redeemGasFee: SuspenseResource<number>
  onSubmit: SuspenseResource<undefined | (() => Promise<void>)>
  onClose: () => void
}> = props => {
  const intl = useIntl()
  const { $t } = intl

  const formErrorMessage = safeReadResource(props.formErrorMessage)

  return (
    <CardModalContent
      className="flex flex-col gap-4"
      bgClassName="bg-gray-800"
      width={640}
      title={$t(
        defineMessage({
          defaultMessage: "Redeem your Ordinals",
          description: "/Launchpad/RedeemOrdinalsModalContent/Title text",
        }),
      )}
      onClose={props.onClose}
    >
      <QueuedSection
        queuingPegRequestCount={props.queuingPegRequestCount}
        warningText={$t(
          defineMessage({
            defaultMessage:
              "ALEX protocol could only proceed 100 request every 24hrs.",
            description: "/Launchpad/RedeemOrdinalsModalContent/Warning text",
          }),
        )}
      />
      <div className={"flex flex-col gap-1"}>
        <TokenBlock
          tokens={[{ token: props.token, count: props.tokenAmount }]}
        />
      </div>
      <div className="bg-black/10 rounded-lg p-4 space-y-2.5">
        <AddressSection
          candidateTargetBtcAddress={props.candidateTargetBtcAddress}
          onFillCandidateTargetBtcAddress={safeReadResource(
            props.onFillCandidateTargetBtcAddress,
          )}
          targetBtcAddress={props.targetBtcAddress}
          onTargetBtcAddressChange={props.onTargetBtcAddressChange}
        />
        <DetailSection
          redeemFeeToken={props.redeemFeeToken}
          redeemFee={props.redeemFee}
          redeemFeeRate={props.redeemFeeRate}
          redeemGasFeeToken={props.redeemGasFeeToken}
          redeemGasFee={props.redeemGasFee}
        />
        <NoteParagraph textSizingClassName="text-sm">
          {$t(
            defineMessage({
              defaultMessage:
                "Please note: To receive your ordinals you need to peg out with service fees.",
              description: "/Launchpad/RedeemOrdinalsModalContent/Note text",
            }),
          )}
        </NoteParagraph>
      </div>
      <NoteParagraph icon={<WarnIcon />} textColorClassName={"text-yellow-500"}>
        {$t(
          defineMessage({
            defaultMessage: `
<line>Once you redeem your Ordinals, ALEX protocol will process your redeem request automatically to your Bitcoin address.</line>
<line>If you haven't received your Ordinals in your Bitcoin address within 24hrs after your transaction confirmed on Stacks chain, Please contact mods to reports issue on Discord. </line>
`,
            description:
              "/Launchpad/RedeemOrdinalsModalContent/Description text",
          }),
          {
            line: dontWrapObserver(children => (
              <p className="text-yellow-500 text-xs">{children}</p>
            )),
            link: dontWrapObserver(children => (
              <NavLink
                className="text-blue-500"
                to={"https://discord.gg/alexlab"}
              >
                {children}
              </NavLink>
            )),
          },
        )}
      </NoteParagraph>
      <Button
        className={"flex items-center space-x-2.5"}
        Variant={GradientFilledButton}
        disabled={!safeReadResource(props.onSubmit)}
        onClick={safeReadResource(props.onSubmit)}
      >
        <p className="text-center flex-1">
          {formErrorMessage == null
            ? $t(
                defineMessage({
                  defaultMessage: "Redeem",
                  description:
                    "/Launchpad/RedeemOrdinalsModalContent/Button text",
                }),
              )
            : typeof formErrorMessage === "function"
            ? formErrorMessage(intl)
            : $t(formErrorMessage)}
        </p>
      </Button>
    </CardModalContent>
  )
}
