import AuthStore from "./AuthStore"

const LOCAL_STORAGE_KEY = "okx_auto_session"

declare namespace okxwallet {
  namespace stacks {
    function connect(): Promise<ConnectionResponse>

    function signTransaction(
      transaction: Transaction,
    ): Promise<TransactionResult>

    interface ConnectionResponse {
      address: string
      publicKey: string
    }

    interface Transaction {
      stxAddress: string
      txType: "contract_call" | "token_transfer"
      contractName?: string
      contractAddress?: string
      functionName?: string
      functionArgs?: string[]
      postConditionMode?: 1 | 2
      postConditions?: string[]
      anchorMode?: 1 | 2 | 3
      recipient?: string
      amount?: string
      memo?: string
    }

    interface TransactionResult {
      txHash: string
      signature: string
    }

    interface Error {
      code: number
      message: string
    }
  }
}

declare global {
  interface Window {
    okxwallet: typeof okxwallet
  }
}

export class OKXWalletAuthSession {
  static current?: OKXWalletAuthSession

  static isAvailable(): boolean {
    return window.okxwallet != null
  }

  provider = "okx-wallet" as const
  constructor(readonly store: AuthStore) {
    const cache = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    if (cache) {
      this.session = JSON.parse(cache)
    }
    OKXWalletAuthSession.current = this
  }

  session?: { address: string; publicKey: string }

  get currentWalletAddress(): string | undefined {
    return this.session?.address
  }

  signUserOut(): void {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY)
    this.session = undefined
    this.store.refreshAddress()
  }

  async connect(): Promise<void> {
    this.session = await window.okxwallet.stacks.connect()
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.session))
    this.store.refreshAddress()
  }
}
