import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["alex-lottery"] as const
const functionName = "register" as const

export interface BuyLotteryRowData extends BaseRowData {
  type: NotifyTransactionType.BuyLottery
  lotteryId: number
  tickets: number
  tokenCurrencyAmount: CurrencyAmount
  bonusTickets: number
  bonusCurrencyAmount: CurrencyAmount
}

export const buyLotteryTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  BuyLotteryRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.BuyLottery,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const tokenTrait = args["token-trait"] as Currency

    return {
      lotteryId: args["lottery-id"],
      tickets: args["tickets"],
      tokenCurrencyAmount: {
        currency: tokenTrait,
        amount: transferAmounts[tokenTrait]?.out ?? undefined,
      },
      bonusTickets: args["bonus-tickets"],
      bonusCurrencyAmount: {
        currency: Currency.APOWER,
        amount: transferAmounts[Currency.APOWER]?.out ?? undefined,
      },
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency

    return {
      lotteryId: args["lottery-id"],
      tickets: args["tickets"],
      tokenCurrencyAmount: {
        currency: tokenTrait,
      },
      bonusTickets: args["bonus-tickets"],
      bonusCurrencyAmount: {
        currency: Currency.APOWER,
      },
    }
  },
}
