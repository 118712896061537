import { FC } from "react"
import { suspenseResource } from "../../../utils/SuspenseResource"
import B20TradePointsContent from "../components/B20TradePointsContent/B20TradePointsContent"
import { useOrderbookStore } from "../store/useOrderbookStore"

export const WiredB20TradePointsContent: FC<{
  className?: string
}> = props => {
  const store = useOrderbookStore()
  return (
    <B20TradePointsContent
      className={props.className}
      totalTradingVolume={suspenseResource(
        () => store.myPointStats$.totalTradingVolume,
      )}
      weeklyTradingVolume={suspenseResource(
        () => store.myPointStats$.weeklyTradingVolume,
      )}
      totalBuyOrderAmount={suspenseResource(
        () => store.myPointStats$.totalBuyOrderAmount,
      )}
      weeklyBuyOrderAmount={suspenseResource(
        () => store.myPointStats$.weeklyBuyOrderAmount,
      )}
      totalPoints={suspenseResource(() => store.myPointStats$.totalPoints)}
      weeklyPoints={suspenseResource(() => store.myPointStats$.weeklyPoints)}
      weeklyPointsPercentage={suspenseResource(
        () => store.myPointStats$.weeklyPointsPercentage,
      )}
    />
  )
}
