import { action } from "mobx"
import { FC } from "react"
import { APowerRatioProvider } from "../../../components/APowerTip/APowerTip"
import {
  CentralizedContentContainer,
  CollapsableCard,
} from "../../../components/CollapsableCard/CollapsableCard"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { PinnacleLayoutMode } from "../../../components/PinnacleLayoutMode"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { changeCycleTokenDisplayName } from "../../Farm/FarmDetailPage/changeCycleTokenDisplayName"
import { AddStakeSection } from "../manualStakeComponents/AddStakeSection/AddStakeSection"
import { AllCyclesModalContent } from "../manualStakeComponents/AllCyclesModalContent/AllCyclesModalContent"
import { ConfirmHarvestModalContent } from "../manualStakeComponents/ConfirmHarvestModalContent/ConfirmHarvestModalContent"
import { ConfirmStakeModalContent } from "../manualStakeComponents/ConfirmStakeModalContent/ConfirmStakeModalContent"
import { EarningsPreviewSection } from "../manualStakeComponents/EarningsPreviewSection/EarningsPreviewSection"
import { MyStakingSection } from "../manualStakeComponents/MyStakingSection/MyStakingSection"
import { useManualStakeStore } from "../store/manualStaking/useManualStakeStore"
import { useCopywriting } from "./CopywritingProvider"
import { WiredAPowerAlert } from "./WiredAPowerAlert"
import { WiredConnectWalletButton } from "./WiredConnectWalletButton"

const WiredHarvestAllConfirmation: FC = () => {
  const { myStaking, tokenInfo$, rewardInfos$ } = useManualStakeStore()
  const copy = useCopywriting()
  return (
    <Modal
      visible={myStaking.confirmation}
      onClose={action(() => (myStaking.confirmation = false))}
    >
      <ConfirmHarvestModalContent
        principalToClaim={{
          token: copy.principalTokenInfoUpdater(tokenInfo$),
          count: myStaking.pendingReturns,
          countToUSD: suspenseResource(() => myStaking.pendingReturnsToUSD),
        }}
        rewardToClaim={rewardInfos$}
        onClose={action(() => (myStaking.confirmation = false))}
        onConfirm={() => myStaking.harvest()}
      />
    </Modal>
  )
}

const WiredMyStakingShowAllCircles: FC = () => {
  const { myStaking, tokenInfo$ } = useManualStakeStore()
  const copy = useCopywriting()
  const onClose = action(() => (myStaking.showAllCycles = false))
  return (
    <Modal visible={myStaking.showAllCycles} onClose={onClose}>
      <AllCyclesModalContent
        stakingToken={tokenInfo$}
        cycles={suspenseResource(() =>
          myStaking.allCycles.map(cycle =>
            changeCycleTokenDisplayName(cycle, token =>
              copy.principalTokenInfoUpdater(token),
            ),
          ),
        )}
        tab={myStaking.tab}
        isHasMore={false}
        onClose={onClose}
        onSelectTab={action(tab => (myStaking.tab = tab))}
      />
    </Modal>
  )
}

const WiredMyStakingSection: FC<{ className?: string }> = props => {
  const store = useManualStakeStore()
  const currency = useCurrencyStore()
  const { myStaking, tokenInfo$, dualYield, dualYieldV1_1, stakableToken } =
    store

  const { isWalletConnected } = useAuthStore()

  const copy = useCopywriting()

  return (
    <Spensor
      fallback={
        <CollapsableCard
          className={props.className}
          title={copy.myStaking.panelTitle}
          ContentContainer={CentralizedContentContainer}
        >
          {isWalletConnected ? (
            <LoadingIndicator />
          ) : (
            <WiredConnectWalletButton />
          )}
        </CollapsableCard>
      }
    >
      {() => (
        <>
          <MyStakingSection
            title={copy.myStaking.panelTitle}
            copywriting={copy.myStaking}
            defaultCollapsed={
              myStaking.activeStaking === 0 &&
              myStaking.pendingReward === 0 &&
              myStaking.pendingReturns === 0
            }
            principalToken={copy.principalTokenInfoUpdater(tokenInfo$)}
            className={props.className}
            currentBlock={store.authStore.currentBlockHeight$}
            stakingCount={myStaking.activeStaking}
            stakingIndicativeValue={
              myStaking.activeStaking * currency.getPrice$(stakableToken)
            }
            averageAPR={
              myStaking.stakingAPR$ *
              (dualYield?.aprMultiplier$ ?? dualYieldV1_1?.aprMultiplier$ ?? 1)
            }
            rewardPairs={store.rewardInfos$}
            principalToClaimCount={myStaking.pendingReturns}
            currentCycleEndedAt={store.nextCycleDate$}
            currentCycleStartedAtBlock={store.stakeChain$.currentCycleBlock$}
            currentCycleEndedAtBlock={store.stakeChain$.nextCycleBlock$ - 1}
            stakingCycles={myStaking.recentNextCycles.map(c =>
              changeCycleTokenDisplayName(c, token =>
                copy.principalTokenInfoUpdater(token),
              ),
            )}
            onHarvestAll={action(() => (myStaking.confirmation = true))}
            onShowAllCycles={action(() => (myStaking.showAllCycles = true))}
          />

          <WiredHarvestAllConfirmation />
          <WiredMyStakingShowAllCircles />
          <WiredTransactionStateDialog store={myStaking.harvestTransaction} />
        </>
      )}
    </Spensor>
  )
}

const WiredAddStakingSection: FC<{ className?: string }> = props => {
  const { addStake, tokenInfo$ } = useManualStakeStore()
  const [connect] = useConnect()
  const copy = useCopywriting()
  return (
    <>
      <AddStakeSection
        className={props.className}
        title={copy.addStaking.panelTitle}
        token={tokenInfo$}
        balance={suspenseResource(() => addStake.balance$)}
        tokenCount={addStake.amountToStake.get() ?? null}
        tokenCountToUSD={suspenseResource(() => addStake.amountToUSD)}
        cycleCount={addStake.userSelectedCycleCount}
        maxCycleCount={
          addStake.dynamicStakingCycleNumberLimit$ != null
            ? addStake.maxSelectableCycleCount$
            : undefined
        }
        cycleTip={copy.addStaking.cycleTip}
        startedAtCycleNumber={suspenseResource(
          () => addStake.startedAtCycleNumber$,
        )}
        endedAtCycleNumber={suspenseResource(
          () => addStake.endedAtCycleNumber$,
        )}
        startedAtBlock={suspenseResource(() => addStake.startedAtBlock$)}
        endedAtBlock={suspenseResource(() => addStake.endedAtBlock$)}
        estimateDayCount={suspenseResource(() => addStake.estimateDayCount$)}
        setTokenCount={count => addStake.setAmountToStake(count ?? undefined)}
        setCycleCount={count => addStake.selectCycleCount(count)}
        onConnectWallet={connect}
        onSubmit={action(() => {
          addStake.confirming = true
        })}
        onClear={() => {
          addStake.clear()
        }}
        error={suspenseResource(() => Result.maybeError(addStake.stakeData))}
      />

      <WiredManualAddStakeConfirmation cycleTip={copy.addStaking.cycleTip} />
    </>
  )
}

export const WiredManualAddStakeConfirmation: FC<{ cycleTip: string }> = ({
  cycleTip,
}) => {
  const { addStake, tokenInfo$ } = useManualStakeStore()
  return (
    <>
      <Modal
        visible={addStake.confirming}
        onClose={action(() => (addStake.confirming = false))}
      >
        <ConfirmStakeModalContent
          stakingToken={tokenInfo$}
          stakingTokenCount={addStake.amountToStake.get() ?? 0}
          cycleCount={addStake.userSelectedCycleCount}
          cycleTip={cycleTip}
          startedAtCycleNumber={suspenseResource(
            () => addStake.startedAtCycleNumber$,
          )}
          endedAtCycleNumber={suspenseResource(
            () => addStake.endedAtCycleNumber$,
          )}
          startedAtBlock={suspenseResource(() => addStake.startedAtBlock$)}
          endedAtBlock={suspenseResource(() => addStake.endedAtBlock$)}
          estimateDayCount={suspenseResource(() => addStake.estimateDayCount$)}
          onClose={action(() => (addStake.confirming = false))}
          onConfirm={() =>
            addStake.stakeData.type === "ok"
              ? addStake.stake(addStake.stakeData.payload)
              : null
          }
        />
      </Modal>
      <WiredTransactionStateDialog store={addStake.addStake} />
    </>
  )
}

export const WiredEarningsPreviewSection: FC<{
  className?: string
}> = props => {
  const store = useManualStakeStore()
  const copy = useCopywriting()

  const chartCurves = {
    idealEarningsCurve: suspenseResource(
      () => store.addStake.idealEarningsCurve,
    ),
    estimatedEarningsCurve: suspenseResource(
      () => store.addStake.estimatedEarningsCurve,
    ),
    gapCurve: suspenseResource(() => store.addStake.gapCurve),
  }

  return (
    <EarningsPreviewSection
      className={props.className}
      token={copy.principalTokenInfoUpdater(store.tokenInfo$)}
      userSelectedCycleCount={store.addStake.userSelectedCycleCount}
      maxSelectableCycleCount={store.addStake.maxSelectableCycleCount$}
      missedCycleCount={store.addStake.missedCycleCount}
      chartCurves={chartCurves}
    />
  )
}

export const WiredStakePageContent: FC = () => {
  const store = useManualStakeStore()

  return (
    <APowerRatioProvider
      ratio={suspenseResource(() => store.apowerMultiplier$)}
    >
      <WiredAPowerAlert className={"mb-5"} />
      <PinnacleLayoutMode.Container>
        <PinnacleLayoutMode.Top asChild>
          <WiredMyStakingSection />
        </PinnacleLayoutMode.Top>

        <PinnacleLayoutMode.BottomLeft asChild>
          <WiredAddStakingSection />
        </PinnacleLayoutMode.BottomLeft>

        <PinnacleLayoutMode.BottomRight asChild>
          <WiredEarningsPreviewSection />
        </PinnacleLayoutMode.BottomRight>
      </PinnacleLayoutMode.Container>
    </APowerRatioProvider>
  )
}
