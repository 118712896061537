import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../../../../../components/NavLink"
import { ReactComponent as ViewInExplorerIcon } from "./viewInExplorer.svg"

export const ExplorerLink: FC<{ className?: string; link: string }> = props => {
  const { $t } = useIntl()

  return (
    <NavLink
      className={clsx(
        "text-xs leading-4 font-normal text-gray-100",
        props.className,
      )}
      to={props.link}
    >
      <ViewInExplorerIcon
        className={"inline align-baseline mr-[5px] opacity-50"}
      />
      {$t(
        defineMessage({
          defaultMessage: "View in explorer",
          description: "/Launchpad/ExplorerLink/Link text",
        }),
      )}
    </NavLink>
  )
}
