import { gql } from "@urql/core"
import { format } from "date-fns"
import { groupBy } from "lodash"
import { DateRange } from "react-day-picker"
import { Observable } from "rxjs"
import {
  FetchSimulationDataQuery,
  FetchSimulationDataQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { CRPSimulatorDayData, CRPSimulatorHourData } from "../types"

export interface ServerData {
  new_x_value: number
  time: number
  y_price: number
  y_price_raw: number
  weights_ytoken: number
  new_y_value: number
  ltv: number
  t: number
  convert: number
}

export const fetchSimulationData = (
  dateRange?: DateRange,
): Observable<{
  data: CRPSimulatorDayData[]
  config: { initialLTV: number }
}> => {
  if (!(dateRange && dateRange.from && dateRange.to)) {
    throw new Error(`dateRange invalid: ${JSON.stringify(dateRange)}`)
  }
  return fromUrqlSource(
    gqlQuery<FetchSimulationDataQuery, FetchSimulationDataQueryVariables>(
      gql`
        query FetchSimulationData($startDate: String!, $endDate: String!) {
          get_crp_simulation(
            args: { start_date: $startDate, end_date: $endDate }
          ) {
            data
            config
          }
        }
      `,
      {
        startDate: format(dateRange.from, "yyyy-MM-dd"),
        endDate: format(dateRange.to, "yyyy-MM-dd"),
      },
    ),
    ({
      data,
    }): { data: CRPSimulatorDayData[]; config: { initialLTV: number } } => {
      const result: ServerData[] = data.get_crp_simulation?.data ?? []
      return {
        data: simulationDataTransformer(result),
        config: { initialLTV: data.get_crp_simulation?.config?.ltv ?? 0.75 },
      }
    },
  )
}

export function simulationDataTransformer(
  serverData: ServerData[],
): CRPSimulatorDayData[] {
  const dict = groupBy(
    serverData
      .sort((a, b) => a.time - b.time)
      .map<CRPSimulatorHourData>(datum => {
        const time = datum.time * 1000
        const date = new Date(time)
        return {
          time,
          date: `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date
            .getUTCDate()
            .toString()
            .padStart(2, "0")}`,
          ltv: datum.ltv,
          xValue: datum.new_x_value,
          yWeight: datum.weights_ytoken,
          yValue: datum.new_y_value,
          yPrice: datum.y_price_raw,
          convert: Boolean(datum.convert),
        }
      }),
    "date",
  )
  return Object.keys(dict).reduce<CRPSimulatorDayData[]>((acc, curr) => {
    const group = dict[curr]!
    return [...acc, { hoursData: group, ...group[0] }]
  }, [])
}
