import * as React from "react"
import { FC } from "react"
import FinishedSucceedIconUrl from "./finishedSucceedImage.svg"

export const IDOSuccessIcon: FC<{ size?: number }> = props => {
  return (
    <img
      alt="IDO successes icon"
      width={props.size ?? 96}
      height={props.size ?? 96}
      src={FinishedSucceedIconUrl}
    />
  )
}
