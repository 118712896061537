import { BorrowClaimRowData } from "../../store/transformerGroups/lend/borrowClaim"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const borrowClaimExporter: Exporter<BorrowClaimRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.keyTokenCurrencyAmount],
    toCurrencyAmounts: [
      rowData.collateralCurrencyAmount,
      rowData.tokenCurrencyAmount,
    ],
    allTokenInfos,
  })
  const tradeInfo = `Collateral Claim`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
