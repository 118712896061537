import { action } from "mobx"
import React, { FC } from "react"
import { Modal } from "../../../components/Modal"
import { usePathGenerator } from "../../../routes/routes"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { MyHistoryModalContent } from "../component/MyHistoryModalContent/MyHistoryModalContent"
import { MyLotteryPanel$Congrats } from "../component/MyLotteryPanel/MyLotteryPanel$Congrats/MyLotteryPanel$Congrats"
import { MyLotteryPanel$Joined } from "../component/MyLotteryPanel/MyLotteryPanel$Joined"
import { MyLotteryPanel$Lose } from "../component/MyLotteryPanel/MyLotteryPanel$Lose/MyLotteryPanel$Lose"
import { MyLotteryPanel$NotJoin } from "../component/MyLotteryPanel/MyLotteryPanel$NotJoin"
import { LotteryTicketPrizeType } from "../component/types"
import { useLotteryPageStore } from "../store/useLotteryPageStore"

export const WiredMyLotteryPanel: FC = () => {
  const currency = useCurrencyStore()
  const account = useAccountStore()
  const lottery = useLotteryPageStore()
  const g = usePathGenerator()
  if (!lottery.my.participated$) {
    return (
      <MyLotteryPanel$NotJoin
        alexToken={currency.getTokenInfo$(Currency.ALEX)}
        alexTokenBalance={account.getBalance$(Currency.ALEX)}
        swapAlexLink={g.swap()}
      />
    )
  }
  if (!lottery.my.allRoundHasDraw$) {
    return (
      <MyLotteryPanel$Joined
        rewardToken={currency.getTokenInfo$(Currency.ALEX)}
        lotteryTicketCount={lottery.my.participatedTicketsCount$}
      />
    )
  }
  if (lottery.my.totalWonTicketsCount$ === 0) {
    return <MyLotteryPanel$Lose />
  }
  return (
    <MyLotteryPanel$Congrats
      wonPrize={{
        token: currency.getTokenInfo$(Currency.ALEX),
        count: lottery.my.totalWonAlexCount$,
      }}
      wonFirstLottery={lottery.my.wonTicketCount$(LotteryTicketPrizeType.First)}
      wonSecondLottery={lottery.my.wonTicketCount$(
        LotteryTicketPrizeType.Second,
      )}
      wonThirdLottery={lottery.my.wonTicketCount$(LotteryTicketPrizeType.Third)}
    />
  )
}

export const WiredMyLotteryHistory: FC = () => {
  const store = useLotteryPageStore()
  const onClose = action(() => (store.historical.showMyHistory = false))
  return (
    <Modal visible={store.historical.showMyHistory} onClose={onClose}>
      <MyHistoryModalContent
        rows={suspenseResource(() => store.historical.myRegistrations$)}
        onClose={onClose}
      />
    </Modal>
  )
}
