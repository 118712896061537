import { clamp, range } from "lodash"
import {
  ContractCallSender,
  contractAddr,
} from "../../../../generated/smartContractHelpers/asSender"
import AccountTransactions from "../../../../stores/accountStore/AccountTransactions"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  CurrencyAndBRC20s,
  isCurrencyOrB20,
} from "../../../../utils/alexjs/Currency"
import { currencyScale } from "../../../../utils/alexjs/currencyHelpers"
import { getContractCallHelpers } from "../../../../utils/contractHelpers"
import { isNotNull } from "../../../../utils/utils"

export enum PegInRequestStatus {
  Pending = "Pending",
  Processed = "Processed",
  Rejected = "Rejected",
}

export interface PegInRequest {
  pegInRequestId: string
  inscriptionNumber: number
  currency: CurrencyAndBRC20s
  amount: BigNumber
  status: PegInRequestStatus
}

export const getRecentPegInRecordsByInscriptionNumbers = async (
  sender: ContractCallSender,
  inscriptionNumbers: number[],
): Promise<(undefined | PegInRequest)[]> => {
  const requestIdResps = await Promise.allSettled(
    inscriptionNumbers.map(inscriptionNumber =>
      sender
        .contract("b20-bridge-endpoint")
        .func("memo-to-request-id")
        .call({ memo: String(inscriptionNumber) }),
    ),
  )

  const requestIdFallbackValue = 0
  const requestsId = requestIdResps.flatMap(r =>
    r.status === "fulfilled" && r.value !== requestIdFallbackValue
      ? [r.value]
      : [],
  )
  const res = await sender
    .contract("b20-bridge-endpoint")
    .func("get-request-by-tx-sender-many")
    .call({ ["requests-id"]: requestsId })

  return requestIdResps.map(r => {
    if (r.status === "rejected") return undefined

    const reqIdx = requestsId.indexOf(r.value)
    if (res[reqIdx] == null) return undefined

    return contractPegRequestToPegInRequest(String(r.value), res[reqIdx]!)
  })
}

export const getRecentPegInRecords = async (
  sender: ContractCallSender,
): Promise<PegInRequest[]> => {
  const processProgress = await sender
    .contract("b20-bridge-endpoint")
    .func("get-requests-processing-progress")
    .call({})

  // get at least n requests from the queue tail, and should include all unprocessed requests
  const atLeastRequestCount = 500
  const requestIds = range(
    clamp(
      processProgress["requests-applied"] - atLeastRequestCount,
      1,
      processProgress["requests-processed"],
    ),
    processProgress["requests-applied"] + 1,
  ).reverse()

  return sender
    .contract("b20-bridge-endpoint")
    .func("get-request-by-tx-sender-many")
    .call({ "requests-id": requestIds })
    .then(async resp => {
      return resp
        .map((r, idx): undefined | (typeof r & { id: string }) =>
          r == null ? r : { id: String(requestIds[idx]!), ...r },
        )
        .filter(isNotNull)
        .filter(a => a["peg-in"])
        .map(
          (req): PegInRequest => contractPegRequestToPegInRequest(req.id, req),
        )
    })
}

function contractPegRequestToPegInRequest(
  id: string,
  req: {
    amount: number
    expired: boolean
    memo: string
    "peg-in": boolean
    "requested-at": number
    sent: boolean
    token: string
    user: string
  },
): PegInRequest {
  return {
    pegInRequestId: id,
    currency: req.token as CurrencyAndBRC20s,
    inscriptionNumber: Number(req.memo),
    amount: BigNumber.div(
      BigNumber.from(req.amount),
      currencyScale(req.token as CurrencyAndBRC20s),
    ),
    status: req.expired
      ? PegInRequestStatus.Rejected
      : req.sent
      ? PegInRequestStatus.Processed
      : PegInRequestStatus.Pending,
  }
}

export async function getRecentPegInRecordsFromMempool(
  mempool: AccountTransactions,
): Promise<
  (Omit<PegInRequest, "status"> & {
    txId: string
    status: "mempool"
  })[]
> {
  const txs = await mempool.fetchMemPoolTransactions()

  return txs
    .map(t => {
      if (t.tx_status !== "pending") return null

      if (
        t.tx_type === "contract_call" &&
        t.contract_call.contract_id === contractAddr("b20-bridge-endpoint") &&
        t.contract_call.function_name === "request-peg-in"
      ) {
        return {
          ...getContractCallHelpers(
            "b20-bridge-endpoint",
            "request-peg-in",
            t,
          ).getArgs(),
          txId: t.tx_id,
        }
      }

      if (
        t.tx_type === "contract_call" &&
        t.contract_call.contract_id ===
          contractAddr("b20-bridge-endpoint-helper")
      ) {
        if (
          t.contract_call.function_name === "register-stxdx-and-request-peg-in"
        ) {
          return {
            ...getContractCallHelpers(
              "b20-bridge-endpoint-helper",
              "register-stxdx-and-request-peg-in",
              t,
            ).getArgs(),
            txId: t.tx_id,
          }
        }
        if (t.contract_call.function_name === "register-and-request-peg-in") {
          return {
            ...getContractCallHelpers(
              "b20-bridge-endpoint-helper",
              "register-and-request-peg-in",
              t,
            ).getArgs(),
            txId: t.tx_id,
          }
        }
      }

      return null
    })
    .flatMap((r, i) => {
      if (r == null) return []

      const currency = r.token

      if (!isCurrencyOrB20(currency)) return []

      return [
        {
          txId: r.txId,
          pegInRequestId: `M-${i}`,
          currency,
          amount: BigNumber.div(
            BigNumber.from(r.amount),
            currencyScale(currency),
          ),
          inscriptionNumber: Number(r.memo),
          status: "mempool",
        },
      ]
    })
}
