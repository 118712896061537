import { RefObject, useCallback, useEffect } from "react"
import { useCreation } from "./useCreation"
import { usePersistFn } from "./usePersistFn"

export function useObserveElementIntersection(
  callback: (entries: IntersectionObserverEntry[]) => void,
  options?: IntersectionObserverInit,
): (elements: HTMLElement[]) => () => void {
  const fn = usePersistFn(callback)

  const observer = useCreation(
    () =>
      new IntersectionObserver(
        entries => {
          fn(entries)
        },
        {
          root: options?.root,
          threshold: options?.threshold,
          rootMargin: options?.rootMargin,
        },
      ),
    [fn, options?.root, options?.rootMargin, options?.threshold],
    observer => observer.disconnect(),
  )

  return useCallback(
    els => {
      observer.disconnect()
      els.forEach(el => observer.observe(el))
      return () => {
        els.forEach(el => observer.unobserve(el))
      }
    },
    [observer],
  )
}

export function useElementIntersection(
  ref: RefObject<HTMLElement>,
  callback: (entries: IntersectionObserverEntry[]) => void,
  options?: IntersectionObserverInit,
): void {
  const observe = useObserveElementIntersection(callback, options)

  useEffect(() => {
    const el = ref.current
    if (el == null) return
    return observe([el])
  }, [observe, ref])
}
