import { CSSProperties, FC, useContext } from "react"
import { PropsWithChildren } from "../../utils/reactHelpers/types"
import { InfoListDirectionContext } from "./_/InfoListDirectionContext"
import { InfoListRenderersContext } from "./_/InfoListRenderersContext"

export interface InfoListItemProps extends PropsWithChildren<unknown> {
  className?: string
  style?: CSSProperties
}

export const InfoListItem: FC<InfoListItemProps> = props => {
  const { renderInfoListItem } = useContext(InfoListRenderersContext)

  if (renderInfoListItem) {
    return <>{renderInfoListItem(props)}</>
  } else {
    return <DefaultInfoListItem {...props} />
  }
}

export interface DefaultInfoListItemProps extends InfoListItemProps {}

export const DefaultInfoListItem: FC<
  DefaultInfoListItemProps & {
    /**
     * @default "center"
     */
    alignInRow?: "start" | "center" | "end"
  }
> = props => {
  const { listItemDirection } = useContext(InfoListDirectionContext)

  return (
    <div
      className={props.className}
      style={{
        ...props.style,
        display: "flex",
        flexDirection: listItemDirection,
        ...(listItemDirection === "row" && {
          alignItems: props.alignInRow ?? "center",
        }),
      }}
    >
      {props.children}
    </div>
  )
}
