import { createContext, ReactNode, useContext } from "react"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { FCC } from "../../../utils/reactHelpers/types"

export interface CopywritingProviderValue {
  aPowerAlertContent: ReactNode
  myStaking: {
    panelTitle: ReactNode
    activeStaking: ReactNode
    averageAPRTip: ReactNode
    principalClaim: ReactNode
    cycleTip: ReactNode
    toBeStaked: {
      finishedStatus: string
      currentState: string
      upcomingStatus: string
    }
  }
  addStaking: {
    panelTitle: ReactNode
    cycleTip: string
  }
  principalTokenInfoUpdater: (token: TokenInfo) => TokenInfo
}

const Context = createContext<null | CopywritingProviderValue>(null)

export const CopywritingProvider: FCC<{
  value: CopywritingProviderValue
}> = props => {
  return (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
  )
}

export const useCopywriting = (): CopywritingProviderValue => {
  const value = useContext(Context)

  if (value === null) {
    throw new Error("useCopywriting must be used within a CopywritingProvider")
  }

  return value
}
