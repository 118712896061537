import clsx from "clsx"
import { Children, FC, Fragment, isValidElement, ReactNode } from "react"
import { CardInsetDivider } from "../../../components/Card"
import { FCC } from "../../../utils/reactHelpers/types"
import styles from "./InfoList.module.scss"

export const HorizontalInfoListContainer: FCC<{
  className?: string
}> = props => {
  return (
    <div
      className={clsx("flex gap-2.5 justify-between w-full", props.className)}
    >
      {props.children}
    </div>
  )
}

export const HorizontalInfoListItem: FC<{
  className?: string
  title: ReactNode
  detail: ReactNode
  detailTextClassName?: string
}> = props => (
  <dl className={clsx("flex-1", props.className)}>
    <dd
      className={clsx(
        "mb-2 flex justify-center items-center text-center whitespace-pre",
        props.detailTextClassName ??
          "text-5xl leading-none font-normal text-gray-200",
      )}
    >
      {props.detail}
    </dd>
    <dt
      className={
        "text-sm leading-5 font-normal text-gray-500 flex justify-center text-center whitespace-pre"
      }
    >
      {props.title}
    </dt>
  </dl>
)

export const VerticalInfoListContainer: FCC<{ className?: string }> = props => (
  <div className={clsx(styles.verticalInfoListContainer, props.className)}>
    {props.children}
  </div>
)

export const VerticalInfoListItem: FC<{
  title: ReactNode
  detail: ReactNode
}> = props => (
  <dl className={"flex items-center justify-between py-3"}>
    <dt className={"text-sm leading-5 font-normal text-gray-400"}>
      {props.title}
    </dt>
    <dd
      className={
        "text-base leading-6 font-semibold text-gray-200 flex items-center whitespace-pre"
      }
    >
      {props.detail}
    </dd>
  </dl>
)

export const PlainVerticalInfoListContainerGroup: FCC<{
  className?: string
}> = props => {
  return (
    <div className={props.className}>
      {Children.toArray(props.children).map((child, idx) => {
        if (
          !isValidElement(child) ||
          child.type !== PlainVerticalInfoListContainer
        ) {
          throw new Error(
            "[PlainVerticalInfoListContainerGroup] only support `PlainVerticalInfoListContainer` as subtree",
          )
        }

        let res: JSX.Element
        if (idx !== 0) {
          res = (
            <Fragment key={`PlainVerticalInfoListContainerGroup-${idx}`}>
              <CardInsetDivider className={"my-3"} />
              {child}
            </Fragment>
          )
        } else {
          res = child
        }
        return res
      })}
    </div>
  )
}

export const PlainVerticalInfoListContainer: FCC<{
  className?: string
}> = props => (
  <div className={clsx("flex flex-col gap-3", props.className)}>
    {props.children}
  </div>
)

export const PlainVerticalInfoListItem: FC<{
  title: ReactNode
  detail: ReactNode
}> = props => (
  <dl className={"flex items-center justify-between"}>
    <dt className={"text-sm leading-5 font-normal text-gray-400"}>
      {props.title}
    </dt>
    <dd
      className={
        "text-base leading-6 font-semibold text-gray-200 flex items-center whitespace-pre"
      }
    >
      {props.detail}
    </dd>
  </dl>
)
