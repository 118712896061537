import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import {
  BlockTopLine,
  BlockTopTitleLine,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import {
  BorrowInfoListContent,
  BorrowInfoListContentProps,
} from "../BorrowInfoListContent"

export interface ConfirmDepositModalContentProps
  extends BorrowInfoListContentProps {
  collateralAssets: TokenCountPairPlain
  borrowedAssets: TokenCountPairPlain

  ltv: number

  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmBorrowModalContent: FC<
  ConfirmDepositModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Borrow",
          description: "/Lend/ConfirmBorrowModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            topArea={
              <BlockTopLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Collateral",
                    description: "/Lend/ConfirmBorrowModalContent/Token block",
                  }),
                )}
              </BlockTopLine>
            }
            tokens={[props.collateralAssets]}
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopTitleLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Receive",
                    description: "/Lend/ConfirmBorrowModalContent/Token block",
                  }),
                )}
              </BlockTopTitleLine>
            }
            tokens={[props.borrowedAssets]}
          />
        }
      />
      <CardPlate className={"flex flex-col gap-2"}>
        <InfoList listItemDirection={"row-responsive"}>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "LTV",
                  description:
                    "/Lend/ConfirmBorrowModalContent/Info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <PercentNumber number={props.ltv} />
            </InfoListItemDetail>
          </InfoListItem>
          <BorrowInfoListContent
            interestToken={props.interestToken}
            expirationDate={props.expirationDate}
            expirationBlockHeight={props.expirationBlockHeight}
            estimateAbsoluteInterest={props.estimateAbsoluteInterest}
            liquidityProviderFee={props.liquidityProviderFee}
            crpInfo={props.crpInfo}
            slippage={props.slippage}
          />
        </InfoList>
      </CardPlate>

      <NoteParagraph>
        {$t<ReactNode>(
          defineMessage({
            defaultMessage: `<line>Your collateral will be actively managed by the CRP Pool.</line>
              <line>You can claim your excess collateral upon maturity.</line>
          `,
            description: "/Lend/ConfirmBorrowModalContent/Note",
          }),
          {
            line: dontWrapObserver(children => <p>{children}</p>),
          },
        )}
      </NoteParagraph>

      <div className={"flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Lend/ConfirmBorrowModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Lend/ConfirmBorrowModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
