import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { WrapBridgeStore } from "./WrapBridgeStore"

const WrapBridgeStoreContext = createStore<WrapBridgeStore>(
  "WrapBridgeStoreContext",
)

export const WrapBridgeStoreProvider: FCC = props => {
  const currencyStore = useCurrencyStore()
  const accountStore = useAccountStore()
  const authStore = useAuthStore()
  const appEnvStore = useAppEnvStore()

  const store = useCreation(
    () =>
      new WrapBridgeStore(currencyStore, accountStore, authStore, appEnvStore),
    [accountStore, appEnvStore, authStore, currencyStore],
    creation => {
      creation.destroy()
    },
  )

  return (
    <WrapBridgeStoreContext.Provider store={store}>
      {props.children}
    </WrapBridgeStoreContext.Provider>
  )
}

export const useWrapBridgeStore = WrapBridgeStoreContext.useStore
