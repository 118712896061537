import { FC } from "react"
import { TO_PREFIX } from "../../constants"
import { AllTokenInfos, CurrencyAmount } from "../../types"
import { OneLineTokenName } from "./OneLineTokenName"

export interface OneLineCurrencyProps {
  fromCurrencyAmounts?: CurrencyAmount[]
  toCurrencyAmounts?: CurrencyAmount[]
  fromPrefix?: string
  fromSuffix?: string
  toPrefix?: string
  toSuffix?: string
  allTokenInfos: AllTokenInfos
}

export const OneLineCurrency: FC<OneLineCurrencyProps> = ({
  fromCurrencyAmounts = [],
  toCurrencyAmounts = [],
  fromPrefix,
  fromSuffix,
  toPrefix,
  toSuffix,
  allTokenInfos,
}) => (
  <p className="flex flex-col whitespace-nowrap">
    {fromCurrencyAmounts.map(
      currencyAmount =>
        currencyAmount && (
          <OneLineTokenName
            currency={currencyAmount.currency}
            allTokenInfos={allTokenInfos}
            prefix={fromPrefix}
            suffix={fromSuffix}
          />
        ),
    )}
    {toCurrencyAmounts.map(
      currencyAmount =>
        currencyAmount && (
          <OneLineTokenName
            currency={currencyAmount.currency}
            allTokenInfos={allTokenInfos}
            prefix={toPrefix ?? TO_PREFIX}
            suffix={toSuffix}
          />
        ),
    )}
  </p>
)
