import { memoize, sum } from "lodash"
import { computed, makeObservable } from "mobx"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import LendStore from "./LendStore"
import { YieldTokenPool } from "./LendStore.service"

export class LendIndexStore {
  constructor(
    readonly chainStore: Pick<ChainStore, "currentBlockHash$">,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "isWalletConnected">,
    readonly accountStore: Pick<AccountStore, "getBalance$">,
    readonly currencyStore: Pick<CurrencyStore, "getPrice$" | "getTokenInfo$">,
  ) {
    makeObservable(this)
  }

  poolStore = memoize(
    (pool: YieldTokenPool) =>
      new LendStore(
        pool,
        this.chainStore,
        this.authStore,
        this.accountStore,
        this.currencyStore,
      ),
  )

  allPools: YieldTokenPool[] = [Currency.YTP_ALEX]

  @computed get allPoolStores(): LendStore[] {
    return this.allPools.map(pool => this.poolStore(pool))
  }

  @computed get myDeposits$(): LendStore[] {
    return this.allPoolStores.filter(s => s.yieldTokenBalance$ > 0)
  }

  @computed get myBorrows$(): LendStore[] {
    return this.allPoolStores.filter(s => s.keyTokenBalance$ > 0)
  }

  @computed get totalBorrowed$(): number {
    return sum(this.allPoolStores.map(s => s.poolTotalBorrowed$))
  }

  @computed get totalDeposit$(): number {
    return sum(this.allPoolStores.map(s => s.poolTotalDeposit$))
  }
}
