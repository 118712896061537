export enum ScreenType {
  PegIn = "peg-in",
}

export type Screens = {
  type: ScreenType.PegIn
  token?: string
}

export const getParamsFromScreens = (
  screen: undefined | null | Screens,
): Record<string, undefined | string> => {
  return {
    [`screen[type]`]: screen?.type,
    [`screen[token]`]: screen?.token,
  }
}

export const getScreensFromParams = (
  params: URLSearchParams,
): undefined | Screens => {
  const type = params.get(`screen[type]`)
  const token = params.get(`screen[token]`)

  if (type !== ScreenType.PegIn) return undefined

  return {
    type: ScreenType.PegIn,
    token: token ?? undefined,
  }
}
