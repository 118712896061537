import { action, makeObservable, observable } from "mobx"
import { defineMessage } from "react-intl"
import type {
  TransactionStateForGeneral,
  TransactionStateMessageRenderer,
} from "../../components/TransactionStateDialog/TransactionStateDialog"
import { CancelError } from "../../utils/error"

export class ConfirmTransactionStoreForGeneral {
  constructor() {
    makeObservable(this)
  }

  @observable running?: TransactionStateForGeneral

  @action closeRunning(): void {
    this.running = undefined
  }

  @action startRunning(
    options: {
      msg?: TransactionStateMessageRenderer
    } = {},
  ): void {
    this.running = {
      type: "running",
      msg: options.msg,
    }
  }

  @action successRunning(
    options: {
      msg?: TransactionStateMessageRenderer
      explorerLink?: string
    } = {},
  ): void {
    this.running = {
      type: "success",
      msg: options.msg,
      explorerLink: options.explorerLink,
    }
  }

  @action errorRunning(e: Error): void {
    if (e instanceof CancelError) {
      this.running = undefined
    } else {
      this.running = {
        type: "error",
        error: ({ intl }) =>
          intl.$t(
            defineMessage({
              defaultMessage: "Error: {error}",
              description: "Common/Transaction Broadcast Modal/error message",
            }),
            {
              error: "message" in e ? e.message : "Unknown error",
            },
          ),
      }
    }
  }

  async run(
    fn: () => Promise<void | { explorerLink?: string }>,
    options?: {
      successMsg?: TransactionStateMessageRenderer
    },
  ): Promise<boolean> {
    try {
      this.startRunning()
      const resp = await fn()
      this.successRunning({
        msg: options?.successMsg,
        explorerLink: resp?.explorerLink,
      })
      return true
    } catch (e) {
      console.error(e)
      if (isMetaMaskCancelError(e)) {
        this.errorRunning(new CancelError())
      } else {
        this.errorRunning(e as Error)
      }
      return false
    }
  }
}

function isMetaMaskCancelError(e: unknown): boolean {
  return (
    typeof e === "object" &&
    e != null &&
    "code" in e &&
    (e.code === "ACTION_REJECTED" || e.code === -32000) // metamask and wallet connect
  )
}
