import { FC, Suspense, useState } from "react"
import { Spensor } from "../../../components/Spensor"
import { TabBar } from "../../../components/TabBar"
import { SmartLoadableButton } from "../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { ETHCurrency } from "../../../stores/appEnvStore/ETHCurrency"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"

export const MetaMask: FC = () => {
  const authStore = useAuthStore()
  return (
    <div className={"flex items-center gap-2"}>
      <MetaMaskInfo />
      {authStore.metaMaskModule.isConnected ? (
        <SmartLoadableButton
          Variant={GradientFilledButton}
          onClick={() => authStore.metaMaskModule.disconnect()}
        >
          Disconnect
        </SmartLoadableButton>
      ) : (
        <SmartLoadableButton
          Variant={GradientFilledButton}
          onClick={() => authStore.metaMaskModule.connect()}
        >
          Connect
        </SmartLoadableButton>
      )}
      {authStore.metaMaskModule.isConnected && (
        <div className={"flex flex-col"}>
          <TabBar
            tabClassName={"px-3 py-2"}
            tabs={[
              {
                label: "Transfer ETH",
                value: "transferEth" as const,
              },
              {
                label: "Transfer",
                value: "transfer" as const,
              },
              {
                label: "Sign",
                value: "sign" as const,
              },
            ]}
          >
            {type => (
              <Suspense>
                {type.value === "sign" ? (
                  <SignWrapMessageForm />
                ) : type.value === "transfer" ? (
                  <TransferForm />
                ) : (
                  <TransferETHForm />
                )}
              </Suspense>
            )}
          </TabBar>
        </div>
      )}
    </div>
  )
}

const SignWrapMessageForm: FC = () => {
  const authStore = useAuthStore()
  const [toAddr, setToAddr] = useState("")
  const [txHash, setTxHash] = useState("")

  const signWrapTokenMessage = authStore.metaMaskModule.signTypedData.value$

  return (
    <div className={"flex-1 flex flex-col gap-2"}>
      <input
        type={"text"}
        placeholder="transaction hash"
        value={toAddr}
        onChange={e => setToAddr(e.target.value)}
      />

      <input
        type={"text"}
        placeholder="transaction hash"
        value={txHash}
        onChange={e => setTxHash(e.target.value)}
      />
      <SmartLoadableButton
        Variant={GradientFilledButton}
        onClick={() =>
          signWrapTokenMessage?.(
            {
              name: "Stacks Bridge",
              version: "1",
            },
            {
              WrapMessage: [
                { name: "tx_id", type: "string" },
                { name: "to_address", type: "string" },
              ],
            },

            { tx_id: txHash, to_address: toAddr },
          )
        }
      >
        Sign
      </SmartLoadableButton>
    </div>
  )
}

const TransferETHForm: FC = () => {
  const authStore = useAuthStore()

  const [addr, setAddr] = useState("")
  const [amount, setAmount] = useState(0)

  return (
    <div className={"flex-1 flex flex-col gap-2"}>
      <input
        type={"text"}
        placeholder="to address"
        value={addr}
        onChange={e => setAddr(e.target.value)}
      />

      <input
        type={"number"}
        placeholder="amount"
        value={amount}
        onChange={e => setAmount(Number(e.target.value))}
      />
      <SmartLoadableButton
        Variant={GradientFilledButton}
        onClick={() => authStore.metaMaskModule.transferEth(addr, amount)}
      >
        Transfer
      </SmartLoadableButton>
    </div>
  )
}

const TransferForm: FC = () => {
  const authStore = useAuthStore()
  const metamask = authStore.metaMaskModule
  const transfer = authStore.metaMaskModule.transfer.value$

  const [addr, setAddr] = useState("")
  const [amount, setAmount] = useState(0)

  return (
    <div className={"flex-1 flex flex-col gap-2"}>
      <input
        type={"text"}
        placeholder="to address"
        value={addr}
        onChange={e => setAddr(e.target.value)}
      />

      <input
        type={"number"}
        placeholder="amount"
        value={amount}
        onChange={e => setAmount(Number(e.target.value))}
      />
      <SmartLoadableButton
        Variant={GradientFilledButton}
        onClick={() =>
          transfer?.(
            metamask.getErc20ContractAddr$(
              metamask.connectedChain.value$.chain,
              ETHCurrency.USDT,
            ),
            addr,
            amount,
          )
        }
      >
        Transfer
      </SmartLoadableButton>
    </div>
  )
}

const MetaMaskInfo: FC = () => {
  const authStore = useAuthStore()

  return (
    <div>
      <Spensor>
        {() => (
          <p>
            {`Current Block Number: ${authStore.metaMaskModule.currentBlockNumber.value$}`}
          </p>
        )}
      </Spensor>
      <Spensor>
        {() => (
          <p>
            {`Connected chain: ${authStore.metaMaskModule.connectedChain.value$.chain} (${authStore.metaMaskModule.connectedChain.value$.chainId})`}
          </p>
        )}
      </Spensor>
      <Spensor>
        {() => (
          <p>
            {`Connected wallet address: ${authStore.metaMaskModule.connectedWalletAddress$}`}
          </p>
        )}
      </Spensor>

      <p>Balance:</p>
      <Spensor>
        {() => <p>{`USDC: ${authStore.metaMaskModule.usdcBalance.value$}`}</p>}
      </Spensor>
      <Spensor>
        {() => <p>{`USDT: ${authStore.metaMaskModule.usdtBalance.value$}`}</p>}
      </Spensor>
      <Spensor>
        {() => <p>{`WBTC: ${authStore.metaMaskModule.wbtcBalance.value$}`}</p>}
      </Spensor>
    </div>
  )
}
