import { sortBy } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { map } from "rxjs"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { MemoValue } from "../../../../stores/MemoValue"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { safelyGet } from "../../../../utils/waitFor"
import { Order, OrderListOrderType } from "../../components/OrderbookList/types"
import { PerpetualStore } from "../PerpetualStore"
import { fetchMyTradeOrders } from "./PerpetualOrderbookModule.service/fetchMyTradeOrders"
import { fetchOrderbook } from "./PerpetualOrderbookModule.service/fetchOrderbook"
import {
  fetchTradeOrders,
  transformTrade,
} from "./PerpetualOrderbookModule.service/fetchTradeOrders"

export class PerpetualOrderbookModule {
  constructor(readonly store: PerpetualStore) {
    makeObservable(this)
  }

  @observable showingOrderType: OrderListOrderType =
    OrderListOrderType.OrderbookOrders
  @action onChangeOrderType = (newType: OrderListOrderType): void => {
    this.showingOrderType = newType
  }

  @computed get priceTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(this.store.market.read$.priceToken)
  }

  @computed get tradeTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(this.store.market.read$.tradeToken)
  }

  @computed get lastPriceTokenCountPerTradeToken$(): number {
    return this.orderbook.immediateValue$.latestPrice
  }

  private prevMemoValue = new MemoValue(
    () => this.lastPriceTokenCountPerTradeToken$,
  )

  @computed get lastPriceTokenCountPerTradeTokenDelta$(): number {
    if (this.prevMemoValue.previousValue == null) return 0
    return this.prevMemoValue.previousValue - this.store.currentPrice$
  }

  @action onClickOrder = (order: Order): void => {
    this.store.trade.customPrice.set(order.priceTokenCountPerTradeToken)
  }

  private orderbook = new LazyValue(
    () => ({
      selectedMarketId: this.store.marketId$,
      jwt: safelyGet(() => this.store.myInfo.authJWT$),
      allMarkets: this.store.stxDxStore.info.allAccessibleMarkets$,
    }),
    ({ jwt, selectedMarketId, allMarkets }) =>
      fetchOrderbook(selectedMarketId, jwt, allMarkets),
  )
  orderbookSellOrders = new LazyValue(
    () => [this.orderbook.immediateValue$] as const,
    async ([value]) => value.sell,
  )
  orderbookBuyOrders = new LazyValue(
    () => [this.orderbook.immediateValue$] as const,
    async ([value]) => value.buy,
  )

  @computed get bestAskPrice$(): number | undefined {
    return sortBy(
      this.orderbookSellOrders.immediateValue$,
      o => o.priceTokenCountPerTradeToken,
    )[0]?.priceTokenCountPerTradeToken
  }

  @computed get bestBidPrice$(): number | undefined {
    return sortBy(
      this.orderbookBuyOrders.immediateValue$,
      o => -o.priceTokenCountPerTradeToken,
    )[0]?.priceTokenCountPerTradeToken
  }

  tradeOrders = new LazyValue(
    () => [this.store.marketId$, this.store.myInfo.userId.value] as const,
    ([market, currentUserId]) =>
      fetchTradeOrders(market).pipe(
        map(os => os.map(transformTrade(currentUserId))),
      ),
  )

  myTradeOrders = new LazyValue(
    () => [this.store.myInfo.authJWT$, this.store.marketId$] as const,
    ([auth, market]) => fetchMyTradeOrders(auth, market),
  )
}
