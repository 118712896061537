import { FC, Suspense, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Column, useTable } from "react-table"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Pagination, PaginationInfo } from "../../../components/Pagination"
import { Spensor } from "../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../components/table/StyledTable"
import { trunc } from "../../../utils/addressHelpers"
import { customReactTableRender } from "../../../utils/customReactTableRender"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"

export interface LotteryRecord {
  lotteryStartId: number
  lotteryEndId: number
  block: number
  walletAddress: string
  wonTicketIds: SuspenseResource<number[] | null>
}

const TableContent: FC<{
  hideResultColumn: boolean
  data: SuspenseResource<LotteryRecord[]>
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({
      hideResultCol: props.hideResultColumn,
    }),
    data: readResource(props.data),
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance
  return (
    <Table {...getTableProps()} className={"-mt-4"}>
      <Thead>
        {headerGroups.map(g => (
          <TableHeadRow {...g.getHeaderGroupProps()}>
            {g.headers.map(cell => (
              <Th {...cell.getHeaderProps()}>
                {customReactTableRender(cell, "Header")}
              </Th>
            ))}
          </TableHeadRow>
        ))}
      </Thead>

      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <TableBodyRow {...row.getRowProps()}>
              {row.cells.map(cell => (
                <Td {...cell.getCellProps()} className={"break-words"}>
                  {cell.render("Cell")}
                </Td>
              ))}
            </TableBodyRow>
          )
        })}
      </Tbody>
    </Table>
  )
}

export interface LotteryRecordsTableProps extends PaginationInfo {
  /**
   * @default true
   */
  hideResultColumn?: boolean
  records: SuspenseResource<LotteryRecord[]>
  onChangePage: (info: { page: number }) => void
}

export const LotteryRecordsTable: FC<LotteryRecordsTableProps> = props => {
  return (
    <div className={"flex flex-col items-center w-full"}>
      <Suspense
        fallback={
          <div className={"flex-1 flex items-center justify-center p-[30px]"}>
            <LoadingIndicator />
          </div>
        }
      >
        <TableContent
          data={props.records}
          hideResultColumn={props.hideResultColumn ?? true}
        />
      </Suspense>
      <Pagination
        className="w-full mt-6"
        currentPage={props.currentPage}
        recordCountPerPage={props.recordCountPerPage}
        recordCountTotal={props.recordCountTotal}
        onChange={props.onChangePage}
      />
    </div>
  )
}

const useTableSchema = (info: {
  hideResultCol: boolean
}): Column<LotteryRecord>[] => {
  const { $t } = useIntl()
  const commonColumns: Column<LotteryRecord>[] = useMemo(
    () => [
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Lottery Code",
            description: "/Launchpad/table header",
          }),
        ),
        accessor: "lotteryStartId",
        Cell: ({ cell }) => (
          <>
            No. {cell.value} - {cell.row.original.lotteryEndId - 1}
          </>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Block",
            description: "/Launchpad/table header",
          }),
        ),
        accessor: "block",
        Cell: ({ cell }) => <>#{cell.value}</>,
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Wallet Address",
            description: "/Launchpad/table header",
          }),
        ),
        accessor: "walletAddress",
        Cell: ({ cell }) => (
          <span title={cell.value}>{trunc(cell.value, { tail: 4 })}</span>
        ),
      },
    ],
    [$t],
  )

  const resultColumns: Column<LotteryRecord>[] = useMemo(
    () => [
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Result",
            description: "/Launchpad/table header",
          }),
        ),
        accessor: "wonTicketIds",
        Cell: ({ cell }) => (
          <Spensor>
            {() => {
              const wonIds = readResource(cell.value)
              if (wonIds == null) {
                return <span>--</span>
              }
              if (wonIds.length === 0) {
                return (
                  <span>
                    {$t(
                      defineMessage({
                        defaultMessage: "Lost all",
                        description: "/Launchpad/Table cell content",
                      }),
                    )}
                  </span>
                )
              }
              return (
                <span>
                  {$t(
                    defineMessage({
                      defaultMessage: "Won {wonIds} ({length} Tickets)",
                      description: "/Launchpad/Table content",
                    }),
                    {
                      wonIds: wonIds.slice(0, 10).join(","),
                      length: wonIds.length,
                    },
                  )}
                </span>
              )
            }}
          </Spensor>
        ),
      },
    ],
    [$t],
  )
  return useMemo(
    () => [...commonColumns, ...(info.hideResultCol ? [] : resultColumns)],
    [info.hideResultCol, resultColumns, commonColumns],
  )
}
