import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TimeCountdown } from "./TimeCountdown"
import { Container } from "./_/Container"
import { PrefixText } from "./_/PrefixText"

export interface BlockTimeCountdownProps {
  className?: string
  prefix?: ReactNode
  targetBlock: number
  currentBlock: SuspenseResource<number>
  time: SuspenseResource<Date>
}

/**
 * https://www.notion.so/alexgo-io/Countdown-4ac88cfd78344c2b8f2aa0f7762cf6cf
 */
export const BlockTimeCountdown: FC<BlockTimeCountdownProps> = props => {
  const { $t } = useIntl()
  return (
    <Spensor>
      {() => {
        const targetBlock = props.targetBlock
        const currentBlock = readResource(props.currentBlock)
        if (targetBlock - currentBlock > 1) {
          const time = readResource(props.time)
          return (
            <TimeCountdown
              className={props.className}
              prefix={props.prefix}
              time={time}
            />
          )
        }
        return (
          <Container className={props.className}>
            {props.prefix && <PrefixText>{props.prefix}</PrefixText>}
            <PrefixText>
              {$t(
                defineMessage({
                  defaultMessage: "1 Block Remaining",
                  description: "/Components/BlockTimeCountdown/Prefix text",
                }),
              )}
            </PrefixText>
          </Container>
        )
      }}
    </Spensor>
  )
}
