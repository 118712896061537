import { FC } from "react"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"

export const ActionSectionContent$Loading: FC = () => {
  return (
    <div className={"flex-1 flex items-center justify-center"}>
      <LoadingIndicator />
    </div>
  )
}
