import { when } from "mobx"
import { isPromiseLike, never } from "./promiseHelpers"

export function waitForNever$<T>(): T {
  return waitUntilExist$(() => {
    throw never()
  })
}

export function waitUntilExist$<T>(value$: () => T | null | undefined): T {
  const value = value$()
  if (value == null) {
    throw waitUntil(() => value$() != null)
  }
  return value
}

export function waitUntil$(filter$: () => boolean): null {
  const value = filter$()
  if (!value) {
    throw waitUntil(() => filter$())
  }
  return null
}

export async function waitUntil(retrieve$: () => boolean): Promise<void> {
  return new Promise((resolve, reject) => {
    const dispose = when(
      () => {
        try {
          return retrieve$()
        } catch (e) {
          if (isPromiseLike(e)) {
            return false
          } else {
            reject(e)
            dispose?.()
            return false
          }
        }
      },
      () => {
        resolve()
      },
    )
  })
}

export async function waitFor<T>(retrieve$: () => T): Promise<T> {
  return new Promise((resolve, reject) => {
    const dispose = when(
      () => {
        try {
          resolve(retrieve$())
          return true
        } catch (e) {
          if (isPromiseLike(e)) {
            return false
          } else {
            reject(e)
            dispose?.()
            return false
          }
        }
      },
      () => null,
    )
  })
}

export function safelyGet<T>(action$: () => T): T | undefined {
  let result: undefined | T
  try {
    result = action$()
  } catch (err) {
    if (isPromiseLike(err)) {
      result = undefined
    } else {
      throw err
    }
  }
  return result
}

export function suspenseMap$<T, U>(items: T[], mapping$: (item: T) => U): U[] {
  const promises: PromiseLike<any>[] = []
  const results: U[] = []
  for (const item of items) {
    try {
      results.push(mapping$(item))
    } catch (e) {
      if (isPromiseLike(e)) {
        promises.push(e)
      }
    }
  }
  if (promises.length > 0) {
    throw Promise.all(promises)
  }
  return results
}
