import { FC, ReactNode } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import { Spensor } from "../../components/Spensor"
import { usePathGenerator } from "../../routes/routes"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { ComingSoonPage } from "./ComingSoonPage/ComingSoonPage"
import { OrderbookMarketId } from "./store/stxdx_shared/StxDxMarket.service"

export const OrderbookIndexPage: FC = () => {
  const [searchParams] = useSearchParams()
  const appEnv = useAppEnvStore()

  const gen = usePathGenerator()

  const search = searchParams.toString()

  if (appEnv.config$.enableOrderBook) {
    return (
      <WithDefaultMarket>
        {market => {
          if (market != null) {
            const to = gen.orderbookDetail(market)
            return (
              <Navigate
                to={search.length !== 0 ? `${to}?${search}` : to}
                replace={true}
              />
            )
          }
          return <ComingSoonPage />
        }}
      </WithDefaultMarket>
    )
  } else {
    return <ComingSoonPage />
  }
}

const WithDefaultMarket: FC<{
  children: (market?: OrderbookMarketId) => ReactNode
}> = ({ children }) => {
  const appEnv = useAppEnvStore()
  return (
    <Spensor>
      {() => {
        const market = appEnv.config$.orderbookMarkets[0]?.marketId
        return children(market)
      }}
    </Spensor>
  )
}
