import { isObject, keys, sortBy, zipObject } from "lodash"
import { hasAny } from "../arrayHelpers"
import { isNotNull } from "../utils"

export const breakpointsMinimumWidth = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1600,
}

export const breakpoints = breakpointsGenerator(breakpointsMinimumWidth)

export type ResponsiveStyleValueObject<T = string | number> =
  | T
  | Partial<Record<keyof typeof breakpointsMinimumWidth | "", T>>
export namespace ResponsiveStyleValueObject {
  /**
   * 10 => { "(min-width: 0px)": 10 }
   *
   * { "": 20 } => { "(min-width: 0px)": 20 }
   *
   * { "md": 20 } => { breakpoints.gte('md'): 20 }
   *
   * { "": 10, "md": 20, "xl": 30 } =>
   * { breakpoints.lt('md'): 10, breakpoints.between('md', 'xl'): 20, breakpoints.gte('xl'): 30 }
   */
  export const toMediaQueries = <T>(
    obj: ResponsiveStyleValueObject<T>,
  ): Record<string, T> => {
    const defaultQuery = "(min-width: 0px)"

    if (!isObject(obj)) {
      return { [defaultQuery]: obj }
    }

    const ks = (keys(obj) as (keyof typeof obj)[]).filter(
      key => obj[key] != null,
    )
    if (hasAny(ks) && ks.length === 1) {
      if (ks[0] === "") {
        return { [defaultQuery]: obj[""]! }
      } else {
        return { [breakpoints.gte(ks[0])]: obj[ks[0]]! }
      }
    }

    const keysSortByWidth = sortBy(ks, key =>
      key === "" ? 0 : breakpointsMinimumWidth[key],
    )

    const keyAry = keysSortByWidth.map((key, idx) => {
      const nextKey = keysSortByWidth[
        idx + 1
      ] as keyof typeof breakpointsMinimumWidth

      if (key === "" || idx === 0) {
        return breakpoints.lt(nextKey)
      } else if (idx === keysSortByWidth.length - 1) {
        return breakpoints.gte(key)
      } else {
        return breakpoints.between(key, nextKey)
      }
    })

    const valueAry = keysSortByWidth.map(key => obj[key]!)

    return zipObject(keyAry, valueAry)
  }
}

export type BreakpointsGenerator<T> = {
  /**
   * [key, ∞)
   */
  gte(key: keyof T): string

  /**
   * [0, key)
   */
  lt(key: keyof T): string

  /**
   * [key1, key2)
   */
  between(key1: keyof T, key2: keyof T): string

  /**
   * [key, key + 1)
   */
  only(key: keyof T): string

  /**
   * [0, key) or (key + 1, ∞)
   */
  not(key: keyof T): string
}
export function breakpointsGenerator<T extends Record<string, number>>(
  breakpointsDef: T,
): BreakpointsGenerator<T> {
  const keysSortByValue = sortBy(
    Object.keys(breakpointsDef),
    key => breakpointsDef[key],
  )

  const generator: BreakpointsGenerator<T> = {
    gte: key => `(min-width: ${breakpointsDef[key]}px)`,
    lt: key => `(max-width: ${breakpointsDef[key]! - 0.01}px)`,
    between: (key1, key2) => `${generator.lt(key1)} and ${generator.gte(key2)}`,
    only: key => {
      const keyIdx = keysSortByValue.findIndex(key as any)
      const keyInTheStart = keyIdx === 0
      const keyInTheEnd = keyIdx + 1 >= keysSortByValue.length
      if (keyInTheStart) {
        return generator.lt(key)
      } else if (keyInTheEnd) {
        return generator.gte(key)
      } else {
        return generator.between(key, keysSortByValue[keyIdx + 1]!)
      }
    },
    not: key => {
      const keyIdx = keysSortByValue.findIndex(key as any)
      const prevKey = keysSortByValue[keyIdx - 1]
      const nextKey = keysSortByValue[keyIdx + 1]
      return [
        prevKey && generator.lt(prevKey),
        nextKey && generator.gte(nextKey),
      ]
        .filter(isNotNull)
        .join(", ")
    },
  }

  return generator
}
