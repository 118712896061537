import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { OpacityButton } from "../../button/variants/OpacityButton"
import { Dropdown, DropdownItem } from "../../Dropdown"
import { TokenCount } from "../../TokenCount"
import { TokenName } from "../../TokenName"
import { navbarButtonPresetStyle } from "../navbarButtonPresetStyle"

export interface BalanceButtonProps {
  className?: string
  availableTokens: TokenInfo[]
  balance: number
  selectedToken: TokenInfo
  onSelectToken?: (token: TokenInfo) => void
  onClick?: () => void
}

export const BalanceButton: FC<BalanceButtonProps> = props => {
  return (
    <Dropdown
      trigger={
        <OpacityButton
          {...navbarButtonPresetStyle}
          className={clsx(
            navbarButtonPresetStyle.className,
            "whitespace-nowrap",
          )}
          onClick={props.onClick}
        >
          <TokenCount token={props.selectedToken} count={props.balance} />{" "}
          <TokenName className={"ml-1"} token={props.selectedToken} />
        </OpacityButton>
      }
    >
      {props.availableTokens.map(t => (
        <DropdownItem
          key={t.displayName}
          onClick={() => props.onSelectToken?.(t)}
        >
          <TokenName token={t} />
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
