import clsx from "clsx"
import { range } from "lodash"
import { FC } from "react"
import { PercentNumber } from "../../../../../components/PercentNumber"
import {
  Slider,
  sliderHandleSize,
  SliderTooltipContentProps,
} from "../../../../../components/Slider"

export const PercentSlider: FC<{
  className?: string
  disabled?: boolean
  value: number
  onChange: (percentage: number) => void
}> = props => {
  return (
    <div
      className={clsx("flex items-center", props.className)}
      style={{ height: sliderHandleSize }}
    >
      <Slider
        className={"w-full"}
        disabled={props.disabled}
        insetPadding={true}
        min={0}
        max={1}
        stepSize={0.01}
        ticks={sliderTicks}
        TooltipContent={TooltipContent}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  )
}

const TooltipContent: FC<SliderTooltipContentProps> = props => (
  <PercentNumber number={props.value} />
)

const sliderTicks = range(0, 6).map(i => i * 0.2)
