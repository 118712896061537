import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  stringT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const bridgeEndpointV102 = defineContract({
  "bridge-endpoint-v1-02": {
    "add-validator": {
      input: [
        { name: "validator-pubkey", type: bufferT },
        { name: "validator", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "apply-whitelist": {
      input: [{ name: "new-use-whitelist", type: booleanT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "approve-relayer": {
      input: [
        { name: "relayer", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "collect-accrued-fee": {
      input: [{ name: "token-trait", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "register-user": {
      input: [{ name: "user", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "remove-validator": {
      input: [{ name: "validator", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-approved-chain": {
      input: [
        { name: "the-chain-id", type: numberT },
        {
          name: "chain-details",
          type: tupleT({ "buff-length": numberT, name: stringT }),
        },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-approved-token": {
      input: [
        { name: "token", type: principalT },
        { name: "approved", type: booleanT },
        { name: "burnable", type: booleanT },
        { name: "fee", type: numberT },
        { name: "min-amount", type: numberT },
        { name: "max-amount", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-min-fee": {
      input: [
        { name: "the-token-id", type: numberT },
        { name: "the-chain-id", type: numberT },
        { name: "the-min-fee", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-paused": {
      input: [{ name: "paused", type: booleanT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-required-validators": {
      input: [{ name: "new-required-validators", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-token-reserve": {
      input: [
        { name: "the-token-id", type: numberT },
        { name: "the-chain-id", type: numberT },
        { name: "the-reserve", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-to-unwrap": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "amount-in-fixed", type: numberT },
        { name: "the-chain-id", type: numberT },
        { name: "settle-address", type: bufferT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-to-wrap": {
      input: [
        {
          name: "order",
          type: tupleT({
            "amount-in-fixed": numberT,
            "chain-id": numberT,
            salt: bufferT,
            to: numberT,
            token: numberT,
          }),
        },
        { name: "token-trait", type: principalT },
        {
          name: "signature-packs",
          type: listT(
            tupleT({
              "order-hash": bufferT,
              signature: bufferT,
              signer: principalT,
            }),
          ),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    whitelist: {
      input: [
        { name: "user", type: principalT },
        { name: "whitelisted", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "whitelist-many": {
      input: [
        { name: "users", type: listT(principalT) },
        { name: "whitelisted", type: listT(booleanT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "check-is-approved-token": {
      input: [{ name: "token", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "readonly",
    },
    "get-approved-chain-or-fail": {
      input: [{ name: "the-chain-id", type: numberT }],
      output: responseSimpleT(
        tupleT({ "buff-length": numberT, name: stringT }),
      ),
      mode: "readonly",
    },
    "get-approved-token-by-id-or-fail": {
      input: [{ name: "token-id", type: numberT }],
      output: responseSimpleT(
        tupleT({
          "accrued-fee": numberT,
          approved: booleanT,
          burnable: booleanT,
          fee: numberT,
          "max-amount": numberT,
          "min-amount": numberT,
          token: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-approved-token-id-or-fail": {
      input: [{ name: "token", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-approved-token-or-fail": {
      input: [{ name: "token", type: principalT }],
      output: responseSimpleT(
        tupleT({
          "accrued-fee": numberT,
          approved: booleanT,
          burnable: booleanT,
          fee: numberT,
          "max-amount": numberT,
          "min-amount": numberT,
          token: principalT,
        }),
      ),
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-min-fee-or-default": {
      input: [
        { name: "the-token-id", type: numberT },
        { name: "the-chain-id", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-paused": { input: [], output: booleanT, mode: "readonly" },
    "get-required-validators": { input: [], output: numberT, mode: "readonly" },
    "get-token-reserve-or-default": {
      input: [
        { name: "the-token-id", type: numberT },
        { name: "the-chain-id", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-user-id": {
      input: [{ name: "user", type: principalT }],
      output: optionalT(numberT),
      mode: "readonly",
    },
    "get-user-id-or-fail": {
      input: [{ name: "user", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-validator-id": {
      input: [{ name: "validator", type: principalT }],
      output: optionalT(numberT),
      mode: "readonly",
    },
    "get-validator-id-or-fail": {
      input: [{ name: "validator", type: principalT }],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "hash-order": {
      input: [
        {
          name: "order",
          type: tupleT({
            "amount-in-fixed": numberT,
            "chain-id": numberT,
            salt: bufferT,
            to: numberT,
            token: numberT,
          }),
        },
      ],
      output: bufferT,
      mode: "readonly",
    },
    "is-whitelisted": {
      input: [{ name: "user", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "user-from-id": {
      input: [{ name: "id", type: numberT }],
      output: optionalT(principalT),
      mode: "readonly",
    },
    "user-from-id-or-fail": {
      input: [{ name: "id", type: numberT }],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "validator-from-id": {
      input: [{ name: "id", type: numberT }],
      output: optionalT(
        tupleT({ validator: principalT, "validator-pubkey": bufferT }),
      ),
      mode: "readonly",
    },
    "validator-from-id-or-fail": {
      input: [{ name: "id", type: numberT }],
      output: responseSimpleT(
        tupleT({ validator: principalT, "validator-pubkey": bufferT }),
      ),
      mode: "readonly",
    },
    "approved-relayers": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "chain-registry": {
      input: numberT,
      output: optionalT(tupleT({ "buff-length": numberT, name: stringT })),
      mode: "mapEntry",
    },
    "min-fee": {
      input: tupleT({ "chain-id": numberT, "token-id": numberT }),
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "order-sent": {
      input: bufferT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "order-validated-by": {
      input: tupleT({ "order-hash": bufferT, validator: principalT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "token-id-registry": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "token-registry": {
      input: numberT,
      output: optionalT(
        tupleT({
          "accrued-fee": numberT,
          approved: booleanT,
          burnable: booleanT,
          fee: numberT,
          "max-amount": numberT,
          "min-amount": numberT,
          token: principalT,
        }),
      ),
      mode: "mapEntry",
    },
    "token-reserve": {
      input: tupleT({ "chain-id": numberT, "token-id": numberT }),
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "user-id-registry": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "user-registry": {
      input: numberT,
      output: optionalT(principalT),
      mode: "mapEntry",
    },
    "validator-id-registry": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "validator-registry": {
      input: numberT,
      output: optionalT(
        tupleT({ validator: principalT, "validator-pubkey": bufferT }),
      ),
      mode: "mapEntry",
    },
    "whitelisted-users": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "chain-nonce": { input: noneT, output: numberT, mode: "variable" },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    "is-paused": { input: noneT, output: booleanT, mode: "variable" },
    "order-hash-to-iter": { input: noneT, output: bufferT, mode: "variable" },
    "required-validators": { input: noneT, output: numberT, mode: "variable" },
    "token-nonce": { input: noneT, output: numberT, mode: "variable" },
    "use-whitelist": { input: noneT, output: booleanT, mode: "variable" },
    "user-nonce": { input: noneT, output: numberT, mode: "variable" },
    "validator-count": { input: noneT, output: numberT, mode: "variable" },
    "validator-nonce": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
