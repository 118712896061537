import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"
import { ReactComponent as WarningIcon } from "../_/warning.svg"

export const AccountAnomaly: FC<{
  className?: string
}> = props => {
  const { $t } = useIntl()
  return (
    <div className={clsx(props.className, "bg-red-900 rounded-lg py-2.5 px-4")}>
      <div className="flex justify-center items-center space-x-2.5">
        <WarningIcon />
        <h5 className="text-lg font-medium text-white">
          {$t(
            defineMessage({
              defaultMessage: "Account anomaly",
              description: "/OrderBook/TradingAccount/AccountAnomaly title",
            }),
          )}
        </h5>
      </div>
      <div className="space-y-4">
        {$t(
          defineMessage({
            defaultMessage: `<li>Because your pending withdrawal transaction is larger than your balance, the account is currently in an abnormal state.  Need to deposit [Balance >  Withdrawal request] to get your account back in order.</li>
                              <li>Please be aware that your funds are safe and if you need help,please contact community managers or open ticket in discord.</li>`,
            description: `/OrderBook/TradingAccount/AccountAnomaly description`,
          }),
          {
            li: dontWrapObserver(children => (
              <p className="text-yellow-400 text-xs">{children}</p>
            )),
          },
        )}
      </div>
    </div>
  )
}
