import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "../../../../../components/button/variants/BaseRoundedFilledButton"
import styles from "./BlueOrangeGradientFilledButton.module.scss"

export interface BlueOrangeGradientFilledButtonProps
  extends RoundedButtonVariantProps {
  bgClassName?: string
}

export const BlueOrangeGradientFilledButton: FC<
  BlueOrangeGradientFilledButtonProps
> = props => {
  return (
    <BaseRoundedFilledButton
      {...props}
      className={clsx("text-gray-100", styles.buttonSvg, props.className)}
      bgClassName={props.bgClassName ?? styles.buttonBg}
    >
      {props.children}
    </BaseRoundedFilledButton>
  )
}
