import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Spensor } from "../../../components/Spensor"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenName } from "../../../components/TokenName"
import { SuspenseResource, readResource } from "../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../utils/mobxHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { WrapBridgeNetworkPresets } from "../types/WrapBridgeNetworkPresets/WrapBridgeNetworkPresets"
import { WrapBridgeNetwork } from "../types/types"

export const TimeTooltip: FC<{
  direction: "wrap" | "unwrap"
  blockCount: SuspenseResource<number>
  fromNetwork: WrapBridgeNetwork
  sourceToken: SuspenseResource<TokenInfo>
}> = ({ direction, blockCount, fromNetwork, sourceToken }) => {
  const { $t } = useIntl()
  return (
    <TipIcon
      inline
      tip={$t(
        defineMessage({
          defaultMessage: `
            <headLine>
              {blockCount} or more {fromNetwork} blocks are needed to process the
                {direction, select, 
                    wrap {wrapping}
                    other {unwrapping}
                } of {sourceToken}.
            </headLine>
          {isFromStacks, select,
              true {<list><listItem>1 {fromNetwork} block generally requires 10-20 minutes confirmation time.</listItem></list>}
              other {}}
          `,
          description: "/Bridge/PanelDetails/Info list item label tip content",
        }),
        {
          headLine: dontWrapObserver(contents => <p>{contents}</p>),
          list: dontWrapObserver(contents => (
            <ul className={"list-disc list-inside"}>{contents}</ul>
          )),
          listItem: dontWrapObserver(contents => <li>{contents}</li>),
          blockCount: (
            <Spensor fallback="--">{() => readResource(blockCount)}</Spensor>
          ),
          fromNetwork: fromNetwork.name,
          isFromStacks: fromNetwork.id === WrapBridgeNetworkPresets.Stacks.id,
          sourceToken: (
            <Spensor fallback={"--"}>
              {() => <TokenName token={readResource(sourceToken)} />}
            </Spensor>
          ),
          direction,
        },
      )}
    />
  )
}
