import { FC } from "react"
import { useLaunchPadContentfulStore } from "../../store/useLaunchPadStores"
import { IDOIntro } from "./IDOIntro"

export interface WiredIDOIntroProps {
  className?: string
}

export const WiredIDOIntro: FC<WiredIDOIntroProps> = props => {
  const store = useLaunchPadContentfulStore()

  return (
    <IDOIntro
      className={props.className}
      idoId={store.urlSlug}
      markdown={store.introHtml$}
    />
  )
}
