import clsx from "clsx"
import { CSSProperties, FC, ReactNode, useEffect } from "react"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import styles from "./CustomScrollBarContainer.module.scss"

export interface CustomScrollBarContainerProps {
  className?: string
  style?: CSSProperties
  children: NonNullable<SimpleBar.Props["children"]>
}

export const CustomScrollBarContainer: FC<
  CustomScrollBarContainerProps
> = props => {
  const wrappedChildren =
    typeof props.children !== "function"
      ? props.children
      : (childrenProps: SimpleBar.ChildrenProps) => (
          <ChildrenWrapper
            wrappingChildren={props.children as any}
            childrenProps={childrenProps}
          />
        )

  return (
    <SimpleBar
      className={clsx(styles.container, props.className)}
      style={props.style}
    >
      {wrappedChildren as any}
    </SimpleBar>
  )
}

type SimpleBarFnChildren = (props: SimpleBar.ChildrenProps) => ReactNode
const ChildrenWrapper: FC<{
  wrappingChildren: SimpleBarFnChildren
  childrenProps: SimpleBar.ChildrenProps
}> = props => {
  const { scrollableNodeRef } = props.childrenProps

  useEffect(() => {
    const { current: el } = scrollableNodeRef
    if (el == null) return

    if (!el.classList.contains(styles.hideNativeScrollBar!)) {
      el.classList.add(styles.hideNativeScrollBar!)
    }
  }, [scrollableNodeRef])

  return <>{props.wrappingChildren(props.childrenProps)}</>
}
