import clsx from "clsx"
import { ButtonVariantProps } from "../ButtonVariant"

export const small: ButtonVariantProps = {
  boxClassName: "px-3 py-1",
  textClassName: "text-sm leading-5 font-medium",
}
export const smallMerge = (props: ButtonVariantProps): ButtonVariantProps => {
  return {
    ...props,
    boxClassName: clsx(small.boxClassName, props.boxClassName),
    textClassName: clsx(small.textClassName, props.textClassName),
  }
}

export const medium: ButtonVariantProps = {
  boxClassName: "px-6 py-3",
  textClassName: "text-base leading-6 font-semibold",
}
export const mediumMerge = (props: ButtonVariantProps): ButtonVariantProps => {
  return {
    ...props,
    boxClassName: clsx(medium.boxClassName, props.boxClassName),
    textClassName: clsx(medium.textClassName, props.textClassName),
  }
}
