import { FC } from "react"
import { AutoStakingRowData } from "../../../../store/transformerGroups/stake/autoStaking"
import { AllTokenInfos, NotifyTransactionStatus } from "../../../../types"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { OneLineExchangeRatioCell } from "../../cells/OneLineExchangeRatioCell"
import { BaseRow } from "../BaseRow"

export const AutoStakingRow: FC<{
  rowData: AutoStakingRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.alexCurrencyAmount]}
        toCurrencyAmounts={[rowData.atAlexCurrencyAmount]}
      />
    }
    amountCell={
      <OneLineAmountCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmounts={[rowData.alexCurrencyAmount]}
        toCurrencyAmounts={[rowData.atAlexCurrencyAmount]}
      />
    }
    tradeInfoCell={
      <OneLineExchangeRatioCell
        allTokenInfos={allTokenInfos}
        fromCurrencyAmount={rowData.alexCurrencyAmount}
        toCurrencyAmount={rowData.atAlexCurrencyAmount}
        confirmed={rowData.status === NotifyTransactionStatus.Confirmed}
      />
    }
  />
)
