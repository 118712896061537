import { useRef } from "react"
import { ImmutableRefObject } from "../refHelpers"

export const useLatestValueRef = <T>(
  value: T,
  comparer = Object.is,
): ImmutableRefObject<T> => {
  const ref = useRef<T>(value)

  if (!comparer(ref.current, value)) {
    ref.current = value
  }

  return ref
}
