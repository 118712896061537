import { zipObject } from "lodash"
import {
  combineLatest,
  map,
  Observable,
  ObservableInput,
  ObservedValueOf,
} from "rxjs"

export type CombineLatestPropsInput = Record<string, ObservableInput<any>>

export type CombineLatestPropsOutputValue<T extends CombineLatestPropsInput> = {
  [K in keyof T]: ObservedValueOf<T[K]>
}
export type CombineLatestPropsOutput<T extends CombineLatestPropsInput> =
  Observable<CombineLatestPropsOutputValue<T>>

export function combineLatestProps<T extends CombineLatestPropsInput>(
  input: T,
): CombineLatestPropsOutput<T> {
  const keys = Object.keys(input) as Array<keyof T>
  const valueObservables = Object.values(input) as Array<ObservableInput<any>>

  return combineLatest(...valueObservables).pipe(
    map(values => zipObject(keys, values) as CombineLatestPropsOutputValue<T>),
  )
}
