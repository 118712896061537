import { createContext, useContext } from "react"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import SpotStore from "./SpotStore"

const SpotStoreContext = createContext<SpotStore | null>(null)

export const SpotStoreProvider: FCC = props => {
  const appEnvStore = useAppEnvStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const chainStore = useChainStore()

  const store = useCreation(
    () =>
      new SpotStore(
        appEnvStore,
        authStore,
        accountStore,
        currencyStore,
        chainStore,
      ),
    [appEnvStore, authStore, accountStore, currencyStore, chainStore],
    store => store.destroy(),
  )

  return (
    <SpotStoreContext.Provider value={store}>
      {props.children}
    </SpotStoreContext.Provider>
  )
}

export const useSpotStore = (): SpotStore => {
  const store = useContext(SpotStoreContext)
  if (store == null) {
    throw new Error(`useSpotStore must be used within a SpotStoreProvider`)
  }
  return store
}
