import { DepositRolloverRowData } from "../../store/transformerGroups/lend/depositRollover"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const depositRolloverExporter: Exporter<DepositRolloverRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = rowData.rollovers
    .map(r =>
      parseAmount({
        fromCurrencyAmounts: [r.from],
        toCurrencyAmounts: [r.to],
        allTokenInfos,
      }),
    )
    .join("\n")
  const tradeInfo = `#${rowData.expiryToRoll}`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
