import clsx from "clsx"
import { identity } from "lodash"
import {
  cloneElement,
  ComponentType,
  FC,
  ReactElement,
  ReactNode,
  SVGProps,
} from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { PlainIconButton } from "../button/variants/PlainIconButton"

export const BlockGroup: FC<{
  /**
   * @default "gap-1.5"
   */
  gapClassName?: string

  firstBlock: ReactElement<{ className?: string }>

  secondBlock: ReactElement<{ className?: string }>

  icon?: ReactNode

  /**
   * @default "column"
   */
  direction?: "row" | "column"
}> = identity(props => {
  return (
    <div
      className={clsx(
        "flex items-center",
        props.direction === "row" ? "flex-row" : "flex-col",
        props.gapClassName ?? "gap-1.5",
      )}
    >
      {props.firstBlock &&
        cloneElement(props.firstBlock, {
          className: clsx(props.firstBlock.props.className, "w-full"),
        })}

      {props.icon}

      {props.secondBlock &&
        cloneElement(props.secondBlock, {
          className: clsx(props.secondBlock.props.className, "w-full"),
        })}
    </div>
  )
})

export const BlockGroupIconContainer: FC<{
  Icon: (props: { width: number; height: number }) => null | JSX.Element
}> = props => {
  return (
    <div className={"relative z-0"}>
      <div
        className={clsx(
          "absolute -translate-x-1/2 -translate-y-1/2",
          "scale-90 sm:scale-100",
        )}
      >
        <props.Icon width={32} height={32} />
      </div>
    </div>
  )
}

export const BlockGroupIcon: FCC<{
  Icon: ComponentType<{ className?: string }>
  onPressIcon?: () => void
}> = props => {
  const { Icon = PlusIcon } = props

  const padding = 4

  return (
    <BlockGroupIconContainer
      Icon={cProps => (
        <div
          className={clsx(
            "rounded-full border border-gray-700 backdrop-blur-2xl",
          )}
          style={{
            padding,
            // we can not use rgba in here, the `drop-shadow` css property from `Card`
            // component will make the whole icon become transparency
            backgroundImage:
              "linear-gradient(152.97deg, rgb(49 53 66) 0%, rgb(36 40 46) 100%)",
          }}
        >
          <PlainIconButton
            icon={
              <Icon
                className={"text-gray-200"}
                width={cProps.width - padding * 2}
                height={cProps.height - padding * 2}
              />
            }
            onClick={props.onPressIcon}
          />
        </div>
      )}
    />
  )
}

export const BlockGroupPlusIcon: FC<{ onClick?: () => void }> = props => (
  <BlockGroupIcon Icon={PlusIcon} onPressIcon={props.onClick} />
)

export const BlockGroupDownArrowIcon: FC<{ onClick?: () => void }> = props => (
  <BlockGroupIcon Icon={DownArrowIcon} onPressIcon={props.onClick} />
)

const PlusIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox={"0 0 24 24"} fill={"currentColor"} {...props}>
    <path d="M21 10.5h-7.5V3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3z" />
  </svg>
)

const DownArrowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <g opacity="0.4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8571 14.5714L6.99994 14.5714L12.1428 21L17.2857 14.5714L13.4285 14.5714L13.4285 3L10.8571 3L10.8571 14.5714Z"
        fill="white"
      />
    </g>
  </svg>
)
