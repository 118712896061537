import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { SuspenseResource, readResource } from "../../utils/SuspenseResource"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { FCC } from "../../utils/reactHelpers/types"
import { CardModalContent, DefaultTitleBar } from "../CardModal/CardModal"
import { Divider } from "../Divider"
import { NavLink } from "../NavLink"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import { TokenIcon } from "../TokenIcon"
import {
  BlockInputContainer,
  defaultInputClassNames,
} from "../TokenInput/BlockInputContainer"
import { TokenName, TokenNameBRC20Badge } from "../TokenName"
import { OpacityButton } from "../button/variants/OpacityButton"

export type TokenCandidate = {
  tokenInfo: TokenInfo
  balance?: SuspenseResource<number>
  disabled?: boolean
}

export interface SelectTokenModalContentProps {
  commonBasesTokens: TokenCandidate[]
  allTokens: TokenCandidate[]
  tokenListLink?: string
  onSelect: (currency: TokenInfo) => void
  onClose: () => void
}

export const SelectTokenModalContent: FC<
  SelectTokenModalContentProps
> = props => {
  const [query, setQuery] = useState("")
  const { $t } = useIntl()
  return (
    <CardModalContent
      width={480}
      className="flex-col max-h-[90vh]"
      boxClassName={""}
      gapClassName={""}
      titleBar={
        <DefaultTitleBar
          className={"p-3 sm:p-6"}
          title={$t(
            defineMessage({
              defaultMessage: "Select a token",
              description: "/Components/SelectTokenModalContent/Title",
            }),
          )}
          onClose={props.onClose}
        />
      }
    >
      <RowContainer className={"flex flex-col mb-4 gap-4"}>
        <BlockInputContainer
          className={"hidden sm:block"}
          boxClassName={"px-4 py-3"}
        >
          {p => (
            <input
              ref={p.inputRef}
              type="text"
              placeholder={$t(
                defineMessage({
                  defaultMessage: "Search token name",
                  description:
                    "/Components/SelectTokenModalContent/Search placeholder",
                }),
              )}
              className={defaultInputClassNames()}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          )}
        </BlockInputContainer>

        {!query && (
          <div>
            <p className="text-sm dark:text-gray-400 mb-2.5">
              {$t(
                defineMessage({
                  defaultMessage: "Common Base Tokens",
                  description:
                    "/Components/SelectTokenModalContent/Common text",
                }),
              )}
            </p>

            <div className="flex flex-wrap gap-2.5">
              {props.commonBasesTokens.map((item, index) => (
                <CommonTokenButton
                  key={index}
                  item={item}
                  onClick={() =>
                    !item.disabled && props.onSelect?.(item.tokenInfo)
                  }
                />
              ))}
            </div>
          </div>
        )}
      </RowContainer>

      <Divider className="border-gray-500/30" />

      <RowContainer className="flex flex-col gap-[5px] flex-1 py-4 overflow-y-scroll">
        {props.allTokens
          .filter(t =>
            (t.tokenInfo.displayName ?? t.tokenInfo.displayName)
              .toLowerCase()
              .includes(query.toLowerCase()),
          )
          .map((item, index) => (
            <TokenListItem
              key={index}
              item={item}
              onClick={() => !item.disabled && props.onSelect?.(item.tokenInfo)}
            />
          ))}
      </RowContainer>

      {props.tokenListLink && (
        <RowContainer withoutHorizontalPadding={true}>
          <TokenListLink link={props.tokenListLink} />
        </RowContainer>
      )}
    </CardModalContent>
  )
}

const RowContainer: FCC<{
  className?: string
  withoutHorizontalPadding?: boolean
}> = props => {
  return (
    <div
      className={clsx(
        !props.withoutHorizontalPadding && "px-3 sm:px-6",
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}

const CommonTokenButton: FC<{
  className?: string
  item: TokenCandidate
  onClick?: () => void
}> = props => (
  <OpacityButton
    className={clsx(
      "relative flex justify-center items-center h-12 overflow-hidden",
      props.className,
    )}
    boxClassName={"px-4 py-3"}
    roundedClassName={"rounded-lg"}
    disabled={props.item.disabled}
    onClick={props.onClick}
  >
    <TokenIcon token={props.item.tokenInfo} />

    <TokenName
      className={"font-medium text-base text-gray-200 ml-2.5"}
      token={props.item.tokenInfo}
    />
    {props.item.tokenInfo.propertyTags.includes("brc-20") && (
      <TokenNameBRC20Badge className="ml-1" />
    )}

    {props.item.disabled && (
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20" />
    )}
  </OpacityButton>
)

const TokenListItem: FC<{
  className?: string
  item: TokenCandidate
  onClick?: () => void
}> = props => (
  <div
    className={clsx(
      "relative flex gap-2.5 justify-between items-center p-2 text-gray-200",
      !props.item.disabled
        ? "hover:bg-white/5 cursor-pointer"
        : "opacity-30 cursor-not-allowed",
      props.className,
    )}
    onClick={props.onClick}
  >
    <TokenIcon token={props.item.tokenInfo} />

    <div className="mr-auto">
      <div className="flex items-center gap-x-1">
        <TokenName
          className={clsx("text-base font-medium")}
          token={props.item.tokenInfo}
        />
        {props.item.tokenInfo.propertyTags.includes("brc-20") && (
          <TokenNameBRC20Badge />
        )}
      </div>

      <p className={clsx("text-xs text-gray-500")}>
        {props.item.tokenInfo.description ?? (
          <TokenName token={props.item.tokenInfo} />
        )}
      </p>
    </div>

    {props.item.balance != null && (
      <p className={clsx("ml-auto text-base font-medium")}>
        <Spensor>
          {() => (
            <TokenCount
              token={props.item.tokenInfo}
              count={readResource(props.item.balance)!}
            />
          )}
        </Spensor>
      </p>
    )}
  </div>
)

const TokenListLink: FC<{ link: string }> = props => {
  const { $t } = useIntl()
  return (
    <div className="bg-white bg-opacity-5 py-5 flex flex-row items-center justify-center">
      <NavLink
        target="_blank"
        to={props.link}
        className="flex flex-row items-center justify-center gap-2 text-blue-600"
      >
        <svg width="17" height="16" viewBox="0 0 17 16">
          <path
            d="M14.5 8.66602V15.3327H0.5V2.66602H8.5V3.99935H1.83333V13.9993H13.1667V8.66602H14.5ZM16.5 0.666016H9.17467L11.8647 3.33268L7.21333 8.04601L9.09867 9.93135L13.75 5.21802L16.5 7.99935V0.666016Z"
            fill="#C4C4C4"
          />
        </svg>
        {$t(
          defineMessage({
            defaultMessage: "Token List",
            description: "/Components/SelectTokenModalContent/Token list text",
          }),
        )}
      </NavLink>
    </div>
  )
}
