import { FC } from "react"
import { Spensor } from "../../components/Spensor"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { NotifyTabbar } from "./components/NotifyTabbar"

export const WiredNotifyTabbar: FC<{ className?: string }> = props => {
  const store = useNotifyStore()
  return (
    <Spensor
      fallback={
        <NotifyTabbar
          active={store.filter}
          className={props.className}
          systemMessagesCount={0}
        />
      }
    >
      {() => (
        <NotifyTabbar
          active={store.filter}
          systemMessagesCount={store.systemMessages.count$}
          className={props.className}
        />
      )}
    </Spensor>
  )
}
