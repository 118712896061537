import { FC, Ref } from "react"
import { defineMessage } from "react-intl"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../../components/Modal"
import { Spensor, SpensorR } from "../../../../components/Spensor"
import { BigNumber } from "../../../../utils/BigNumber"
import { Result } from "../../../../utils/Result"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { safelyGet, waitFor } from "../../../../utils/waitFor"
import { InProgressPanel } from "../components/InProgressPanel"
import { InscribeConfirmModalContent } from "../components/InscribeConfirmModalContent"
import { PegInFormModalContent } from "../components/PegInFormModalContent"
import { useOrderbookPegInStore } from "../stores/useOrderbookPegInStore"

export const WiredInProgressPanel: FC<{
  className?: string
  containerRef?: Ref<HTMLElement>
}> = props => {
  const pegInStore = useOrderbookPegInStore()
  const { pegInForm, recentTransferable } = pegInStore
  const { inscribeFormData } = pegInForm

  if (safelyGet(() => pegInStore.bitcoinWalletAddresses$) == null) return null

  if (recentTransferable.viewingBRC20Currency.get() == null) return null

  return (
    <SpensorR
      fallback={
        <div
          className={"min-h-[200px] m-auto flex items-center justify-center"}
        >
          <LoadingIndicator />
        </div>
      }
      read={{}}
    >
      {() => (
        <>
          <InProgressPanel
            containerRef={props.containerRef}
            className={props.className}
            brc20Token={recentTransferable.viewingBRC20Token$}
            recentInscriptionRecords={
              recentTransferable.recentTransferInscriptions$
            }
            onInscribeNew={() => {
              pegInForm.peggingCurrency.set(
                recentTransferable.viewingBRC20Currency.get(),
              )
            }}
            onClose={() => {
              recentTransferable.viewingBRC20Currency.set(undefined)
            }}
          />

          <Modal
            visible={pegInForm.peggingCurrency.get() != null}
            onClose={() => pegInForm.reset()}
          >
            <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
              {() => (
                <PegInFormModalContent
                  token={suspenseResource(() => pegInForm.peggingToken$)}
                  tokenAmount={pegInForm.peggingAmount.get() ?? null}
                  availableTokenAmount={suspenseResource(
                    () => pegInForm.peggingTokenAvailableAmount$,
                  )}
                  onTokenAmountChange={amount => {
                    pegInForm.peggingAmount.set(amount ?? undefined)
                  }}
                  onTokenAmountMax={suspenseResource(() => {
                    const amount = pegInForm.peggingTokenAvailableAmount$
                    return () => {
                      pegInForm.peggingAmount.set(amount)
                    }
                  })}
                  formError={suspenseResource(() =>
                    Result.maybeError(pegInForm.formData$),
                  )}
                  onSubmit={suspenseResource(() => {
                    const formData = Result.maybeValue(pegInForm.formData$)
                    return formData == null
                      ? undefined
                      : () => pegInForm.submit(formData)
                  })}
                  onClose={() => pegInForm.reset()}
                />
              )}
            </Spensor>
          </Modal>

          <Modal
            visible={pegInForm.inscribeFormData.get() != null}
            onClose={() => pegInForm.onDismissInscribeConfirmModal()}
          >
            <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
              {() => (
                <InscribeConfirmModalContent
                  token={inscribeFormData.read$.token}
                  tokenCount={inscribeFormData.read$.tokenCount}
                  btcNetwork={inscribeFormData.read$.btcNetwork}
                  networkFeeRate={suspenseResource(
                    () => pegInForm.networkFeeRate.read$,
                  )}
                  recommendedNetworkFeeRates={suspenseResource(() => ({
                    fast: BigNumber.from(
                      pegInForm.recommendedBitcoinNetworkFeeRates.value$
                        .fastestFee,
                    ),
                    avg: BigNumber.from(
                      pegInForm.recommendedBitcoinNetworkFeeRates.value$
                        .halfHourFee,
                    ),
                    slow: BigNumber.from(
                      pegInForm.recommendedBitcoinNetworkFeeRates.value$
                        .hourFee,
                    ),
                  }))}
                  inscribeServiceFeeToken={
                    inscribeFormData.read$.inscribeServiceFeeToken
                  }
                  inscribeServiceFeeTokenCount={
                    inscribeFormData.read$.inscribeServiceFeeTokenCount
                  }
                  inscribeServiceFeeTokenCountToUSD={
                    inscribeFormData.read$.inscribeServiceFeeTokenCountToUSD
                  }
                  networkFeeRateToken={
                    inscribeFormData.read$.networkFeeRateToken
                  }
                  networkFeeRateTokenCount={
                    inscribeFormData.read$.networkFeeRateTokenCount
                  }
                  onChangeNetworkFeeRate={async rate =>
                    pegInForm.onChangeNetworkFeeRate(
                      await waitFor(
                        () => inscribeFormData.read$.originFormData,
                      ),
                      rate,
                    )
                  }
                  onConfirm={async () => {
                    await pegInStore.generalTxStore.run(
                      () => pegInForm.payInscribeOrder(),
                      {
                        successMsg: ({ intl }) =>
                          intl.$t(
                            defineMessage({
                              defaultMessage:
                                "The inscription may take up to several hours to appear in your wallet. Once received, head to your collectible dashboard and send it to your recipient to complete the token transfer.",
                              description:
                                "Orderbook Peg-In Page/Transfer Panel/Inscribe Confirm Modal/Success Message",
                            }),
                          ),
                      },
                    )
                  }}
                  onClose={() => pegInForm.onDismissInscribeConfirmModal()}
                />
              )}
            </Spensor>
          </Modal>
        </>
      )}
    </SpensorR>
  )
}
