import clsx from "clsx"
import copy from "copy-to-clipboard"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { copied$t } from "../../../../../commonIntlMessages"
import { btnPresets } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LinkedButton } from "../../../../../components/button/LinkedButton"
import { OpacityButton } from "../../../../../components/button/variants/OpacityButton"
import { EmptyState } from "../../../../../components/EmptyState/EmptyState"
import {
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { useMessage } from "../../../../../components/message/MessageProvider"
import {
  Pagination,
  PaginationInfo,
} from "../../../../../components/Pagination"
import { TextTokenCount } from "../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../components/Spensor"
import { TokenName } from "../../../../../components/TokenName"
import { trunc } from "../../../../../utils/addressHelpers"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { Colors } from "../../designTokens"
import {
  FundHistoryRecord,
  FundHistoryRecordDirection,
  FundHistoryRecordStatus,
} from "../../types"
import { DateRange, DateRangeSelector } from "../DateRangeSelector"
import {
  fields$t,
  fundHistoryDirection$t,
  fundStatus$t,
} from "../_/commonIntlMessages"
import { ReactComponent as CopyIcon } from "../_/copyIcon.svg"
import {
  ActionsCell,
  CreateDateCell,
  FieldCell,
  RecordRowContainer,
} from "../_/RecordRow"
import { ReactComponent as ConfirmedStatusIcon } from "./_/confimedStatusIcon.svg"
import { ReactComponent as FailedStatusIcon } from "./_/failedStatusIcon.svg"
import { ReactComponent as OpenLinkIcon } from "./_/openLinkIcon.svg"
import { ReactComponent as PendingStatusIcon } from "./_/pendingStatusIcon.svg"

export const FundsHistoryTabContent: FC<{
  records: SuspenseResource<FundHistoryRecord[]>
  pagination?: SuspenseResource<PaginationInfo>
  dateRange?: DateRange
  onChangeDateRange?: (range: DateRange) => void
  onChangePage?: (page: number) => void
}> = props => {
  return (
    <>
      {props.dateRange != null && props.onChangeDateRange != null && (
        <DateRangeSelector
          value={props.dateRange}
          onChange={props.onChangeDateRange}
        />
      )}

      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() =>
          readResource(props.records).length <= 0 ? (
            <EmptyState className={"m-auto"} />
          ) : (
            <ul className={"flex flex-col gap-1"}>
              {readResource(props.records).map((r, idx) => (
                <li key={idx}>
                  <Row record={r} />
                </li>
              ))}
            </ul>
          )
        }
      </Spensor>

      {props.pagination != null && (
        <Spensor>
          {() => (
            <Pagination
              {...readResource(props.pagination!)}
              onChange={i => props.onChangePage?.(i.page)}
            />
          )}
        </Spensor>
      )}
    </>
  )
}

const Row: FC<{
  record: FundHistoryRecord
}> = props => {
  const { record } = props

  const intl = useIntl()
  const { $t } = intl

  const message = useMessage()

  const onCopyTransactionId = (): void => {
    if (copy(record.transactionId)) {
      message.show({ message: $t(copied$t) })
    }
  }

  return (
    <RecordRowContainer>
      <FieldCell columnId={"direction"}>
        <InfoListItemDetail>
          <div>
            <TokenName token={record.token} />
          </div>
          <div
            className={
              record.direction === FundHistoryRecordDirection.Deposit
                ? Colors.depositTextClassName
                : Colors.withdrawTextClassName
            }
          >
            {fundHistoryDirection$t.fromFundHistoryRecordDirection(
              intl,
              record.direction,
            )}
          </div>
        </InfoListItemDetail>
      </FieldCell>

      <StatusCell status={record.status} />

      <FieldCell columnId={"amount"}>
        <InfoListItemTitle>{$t(fields$t.amount)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.token}
            count={props.record.tokenCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"txHash"}>
        <InfoListItemTitle>{$t(fields$t.transactionHash)}</InfoListItemTitle>
        <InfoListItemDetail>
          <HeadlessButton
            className={"flex flex-row items-center"}
            onClick={onCopyTransactionId}
          >
            <CopyIcon fill={"currentColor"} />
            &nbsp;
            {trunc(record.transactionId)}
          </HeadlessButton>
        </InfoListItemDetail>
      </FieldCell>

      <CreateDateCell createdAt={record.createdAt} />

      <ActionsCell>
        <InfoListItemTitle className={"sr-only"}>
          {$t(fields$t.actions)}
        </InfoListItemTitle>
        <InfoListItemDetail className={"flex"}>
          <LinkedButton
            className={"ml-auto inline-flex"}
            Variant={props => (
              <OpacityButton
                {...props}
                {...btnPresets.small}
                boxClassName={"px-3 py-1.5"}
                className={clsx(
                  props.className,
                  "flex flex-row items-center justify-center min-w-[120px]",
                )}
              />
            )}
            link={record.explorerLink}
          >
            {$t(
              defineMessage({
                defaultMessage: "{icon} View",
                description:
                  "Orderbook/OrderHistory/Funds/View transaction button",
              }),
              {
                icon: <OpenLinkIcon />,
              },
            )}
          </LinkedButton>
        </InfoListItemDetail>
      </ActionsCell>
    </RecordRowContainer>
  )
}

const statusCellTextColorClassName: Record<FundHistoryRecordStatus, string> = {
  [FundHistoryRecordStatus.Pending]: "",
  [FundHistoryRecordStatus.Confirmed]: "text-lime-500",
  [FundHistoryRecordStatus.Failed]: "text-red-500",
}
const statusCellIcon: Record<
  FundHistoryRecordStatus,
  typeof PendingStatusIcon
> = {
  [FundHistoryRecordStatus.Pending]: PendingStatusIcon,
  [FundHistoryRecordStatus.Confirmed]: ConfirmedStatusIcon,
  [FundHistoryRecordStatus.Failed]: FailedStatusIcon,
}
const StatusCell: FC<{ status: FundHistoryRecordStatus }> = props => {
  const intl = useIntl()
  const { $t } = intl
  const cls = statusCellTextColorClassName[props.status]
  const Icon = statusCellIcon[props.status]

  return (
    <FieldCell columnId={"status"}>
      <InfoListItemTitle>{$t(fields$t.status)}</InfoListItemTitle>
      <InfoListItemDetail className={clsx(cls, "flex flex-row items-center")}>
        <Icon fill={"currentColor"} />
        &nbsp;{fundStatus$t.fromFundHistoryRecordStatus(intl, props.status)}
      </InfoListItemDetail>
    </FieldCell>
  )
}
