import { CoFarmRowData } from "../../store/transformerGroups/farm/coFarm"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const coFarmExporter: Exporter<CoFarmRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount =
    parseAmount({
      fromCurrencyAmounts: rowData.coFarmFromCurrencyAmounts,
      toCurrencyAmounts: rowData.coFarmToCurrencyAmounts,
      allTokenInfos,
    }) +
    "\n" +
    parseAmount({
      fromCurrencyAmounts: rowData.entitledFromCurrencyAmounts,
      toCurrencyAmounts: rowData.entitledToCurrencyAmounts,
      allTokenInfos,
    })
  const tradeInfo = `Reward Harvest\nCo-Farm`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
