import { FC } from "react"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../../components/TokenInput/BalanceBottomArea"
import { renderThinnerBlock } from "../../../../../components/TokenInput/Block"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
  suspenseResource,
} from "../../../../../utils/SuspenseResource"
import { StakeForm } from "../types"

export interface AlexTokenInputProps extends StakeForm.AlexTokenInput {
  className?: string
  alexToken: TokenInfo
  isTokenInputShouldBeErrorState?: SuspenseResource<boolean>
}

export const AlexTokenInput: FC<AlexTokenInputProps> = props => {
  return (
    <TokenInput
      className={props.className}
      token={props.alexToken}
      error={safeReadResource(props.isTokenInputShouldBeErrorState)}
      value={props.alexTokenCount}
      onChange={props.setAlexTokenCount}
      bottomArea={
        <BalanceBottomArea
          token={props.alexToken}
          balance={props.alexBalance}
          rightSide={
            props.alexTokenCount != null && (
              <EstimatedUSD
                error={safeReadResource(props.isTokenInputShouldBeErrorState)}
                usdCount={props.alexTokenCountToUSD}
              />
            )
          }
          onPressMax={suspenseResource(() => {
            const balance = readResource(props.alexBalance)
            if (props.alexTokenCount === balance) return undefined
            if (!balance) return
            return () => {
              props.setAlexTokenCount?.(balance)
            }
          })}
        />
      }
      renderBlock={renderThinnerBlock}
    />
  )
}
