import { mapValues } from "lodash"
import { observer } from "mobx-react-lite"
import { FC, ReactNode, Suspense, useMemo } from "react"
import {
  SuspenseResource,
  UnboxSuspenseResourceCollection,
  readResource,
} from "../utils/SuspenseResource"

export type SuspenseProps<T extends Record<string, any>> = {
  [P in keyof T]: SuspenseResource<T[P]>
}

export const RenderChildrenFn: FC<{
  suspenseTag?: string
  children: () => ReactNode
}> = props => {
  const WrappedComp = useMemo(() => {
    const Component = (p: { children: () => any }): any => <>{p.children()}</>
    Component.displayName = `RenderChildrenFnInside<${props.suspenseTag}>`
    return observer(Component)
  }, [props.suspenseTag])

  if (props.suspenseTag != null) {
    return <WrappedComp>{props.children}</WrappedComp>
  } else {
    return <>{props.children()}</>
  }
}

export interface SpensorProps {
  spensorTag?: string
  fallback?: ReactNode
  children: () => ReactNode
}
export const Spensor: FC<SpensorProps> = props => {
  return (
    <Suspense fallback={props.fallback ?? null}>
      <RenderChildrenFn suspenseTag={props.spensorTag}>
        {props.children}
      </RenderChildrenFn>
    </Suspense>
  )
}

export type SpensorRReadType = Record<string, SuspenseResource<any>>
export function SpensorR<Read extends SpensorRReadType>(props: {
  spensorTag?: string
  read: Read
  defaultValue?: UnboxSuspenseResourceCollection<Read>
  children: (values: UnboxSuspenseResourceCollection<Read>) => ReactNode
  fallback?: ReactNode
}): JSX.Element {
  return (
    <Suspense
      fallback={
        props.fallback ??
        (props.defaultValue == null ? null : props.children(props.defaultValue))
      }
    >
      <RenderChildrenFn suspenseTag={props.spensorTag}>
        {() => {
          const results: UnboxSuspenseResourceCollection<Read> = mapValues(
            props.read as any,
            readResource,
          ) as any
          return props.children(results)
        }}
      </RenderChildrenFn>
    </Suspense>
  )
}
