import clsx from "clsx"
import { FC, Suspense } from "react"
import { Card } from "../../../../components/Card"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { usePathGenerator } from "../../../../routes/routes"
import {
  useLaunchPadContentfulStore,
  useLaunchPadContractStore,
} from "../../store/useLaunchPadStores"
import { TokenProfileSection } from "./TokenProfileSection"

export const WiredTokenProfileSection: FC<{
  className?: string
}> = props => {
  const store = useLaunchPadContractStore()
  const contentfulStore = useLaunchPadContentfulStore()
  const g = usePathGenerator()

  return (
    <Suspense
      fallback={
        <Card
          className={clsx("flex items-center justify-center", props.className)}
        >
          <LoadingIndicator />
        </Card>
      }
    >
      <TokenProfileSection
        className={props.className}
        idoToken={store.tokenInfo$}
        priceToken={store.priceTokenInfo$}
        idoTokenMeta={contentfulStore.meta$}
        idoStatus={store.tokenProfileViewModule.status}
        idoRaiseNumbers={store.tokenProfileViewModule}
        idoPrices={store.tokenProfileViewModule}
        tokenIntroPageLink={g.launchpadDetail(contentfulStore.urlSlug)}
      />
    </Suspense>
  )
}
