import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const stxdxSenderProxyV102 = defineContract({
  "stxdx-sender-proxy-v1-02": {
    "match-orders": {
      input: [
        {
          name: "left-order",
          type: tupleT({
            "expiration-height": numberT,
            maker: numberT,
            "maker-asset": numberT,
            "maker-asset-data": numberT,
            "maximum-fill": numberT,
            risk: booleanT,
            salt: numberT,
            sender: numberT,
            "sender-fee": numberT,
            stop: numberT,
            "taker-asset": numberT,
            "taker-asset-data": numberT,
            timestamp: numberT,
            type: numberT,
          }),
        },
        {
          name: "right-order",
          type: tupleT({
            "expiration-height": numberT,
            maker: numberT,
            "maker-asset": numberT,
            "maker-asset-data": numberT,
            "maximum-fill": numberT,
            risk: booleanT,
            salt: numberT,
            sender: numberT,
            "sender-fee": numberT,
            stop: numberT,
            "taker-asset": numberT,
            "taker-asset-data": numberT,
            timestamp: numberT,
            type: numberT,
          }),
        },
        { name: "left-signature", type: bufferT },
        { name: "right-signature", type: bufferT },
        {
          name: "left-oracle-data",
          type: optionalT(
            tupleT({ signature: bufferT, timestamp: numberT, value: numberT }),
          ),
        },
        {
          name: "right-oracle-data",
          type: optionalT(
            tupleT({ signature: bufferT, timestamp: numberT, value: numberT }),
          ),
        },
        { name: "fill", type: optionalT(numberT) },
      ],
      output: responseSimpleT(
        tupleT({
          fillable: numberT,
          "left-order-make": numberT,
          "left-sender-fee": numberT,
          "right-order-make": numberT,
          "right-sender-fee": numberT,
        }),
      ),
      mode: "public",
    },
    "match-orders-many": {
      input: [
        {
          name: "matched-orders-list",
          type: listT(
            tupleT({
              fill: optionalT(numberT),
              "left-oracle-data": optionalT(
                tupleT({
                  signature: bufferT,
                  timestamp: numberT,
                  value: numberT,
                }),
              ),
              "left-order": tupleT({
                "expiration-height": numberT,
                maker: numberT,
                "maker-asset": numberT,
                "maker-asset-data": numberT,
                "maximum-fill": numberT,
                risk: booleanT,
                salt: numberT,
                sender: numberT,
                "sender-fee": numberT,
                stop: numberT,
                "taker-asset": numberT,
                "taker-asset-data": numberT,
                timestamp: numberT,
                type: numberT,
              }),
              "left-signature": bufferT,
              "right-oracle-data": optionalT(
                tupleT({
                  signature: bufferT,
                  timestamp: numberT,
                  value: numberT,
                }),
              ),
              "right-order": tupleT({
                "expiration-height": numberT,
                maker: numberT,
                "maker-asset": numberT,
                "maker-asset-data": numberT,
                "maximum-fill": numberT,
                risk: booleanT,
                salt: numberT,
                sender: numberT,
                "sender-fee": numberT,
                stop: numberT,
                "taker-asset": numberT,
                "taker-asset-data": numberT,
                timestamp: numberT,
                type: numberT,
              }),
              "right-signature": bufferT,
            }),
          ),
        },
      ],
      output: responseSimpleT(
        listT(
          responseSimpleT(
            tupleT({
              fillable: numberT,
              "left-order-make": numberT,
              "left-sender-fee": numberT,
              "right-order-make": numberT,
              "right-sender-fee": numberT,
            }),
          ),
        ),
      ),
      mode: "public",
    },
    "set-authorised-sender": {
      input: [
        { name: "authorised", type: booleanT },
        { name: "sender", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "new-owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "authorised-senders": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
  },
} as const)
