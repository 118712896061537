import { noop } from "lodash"
import { Ref, useEffect, useRef, useState } from "react"
import {
  OversteppedDistances,
  getOversteppedViewportDistances,
  getSuggestedKeepInViewportSize,
  screenEdgeBound,
} from "../domHelpers/getOversteppedViewportDistances"
import { isShallowEqual } from "../isShallowEqual"
import { useCombinedRef } from "../refHelpers"
import { useObserveElementIntersection } from "./useElementIntersection"
import { usePersistFn } from "./usePersistFn"

const useObserveCalc = <T>(
  calc: (el: undefined | HTMLElement) => T,
): [value: T, ref: Ref<HTMLElement>] => {
  const elRef = useRef<HTMLElement>(null)
  const calcRef = usePersistFn(calc)

  const [state, _setState] = useState<T>(() => calcRef(undefined))
  const setState = usePersistFn((newDistances: T) => {
    _setState(distances =>
      isShallowEqual(distances, newDistances) ? distances : newDistances,
    )
  })

  const unobserveRef = useRef(noop)
  const observe = useObserveElementIntersection(() => {
    if (elRef.current == null) return
    setState(calcRef(elRef.current))
  })

  useEffect(() => {
    if (elRef.current == null) return
    setState(calcRef(elRef.current))
  }, [calcRef, setState])

  const onElChange = usePersistFn((el: null | HTMLElement) => {
    unobserveRef.current?.()

    if (el) {
      setState(calcRef(el))
      unobserveRef.current = observe([el])
    }
  })

  const ref = useCombinedRef(elRef, onElChange)

  return [state, ref]
}

export const useOversteppedViewportDistances = (): [
  oversteppedDistances: OversteppedDistances,
  elRef: Ref<HTMLElement>,
] => {
  return useObserveCalc(el => {
    if (el == null) return {}
    return getOversteppedViewportDistances(
      el.getBoundingClientRect(),
      screenEdgeBound(),
    )
  })
}

export const useSuggestedKeepInViewportSize = (info?: {
  padding?:
    | number
    | { top?: number; right?: number; bottom?: number; left?: number }
}): [
  suggestedSize: undefined | { width: number; height: number },
  elRef: Ref<HTMLElement>,
] => {
  return useObserveCalc(el => {
    if (el == null) return undefined

    const padding =
      info?.padding == null
        ? {}
        : typeof info?.padding === "number"
        ? {
            top: info.padding,
            right: info.padding,
            bottom: info.padding,
            left: info.padding,
          }
        : info.padding

    return getSuggestedKeepInViewportSize(el.getBoundingClientRect(), padding)
  })
}
