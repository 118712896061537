import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import { BuyLotteryModule } from "./BuyLotteryModule"
import { LotteryHistoricalModule } from "./LotteryHistoricalModule"
import { LotteryInfoModule } from "./LotteryInfoModule"
import { LotteryMyModule } from "./LotteryMyModule"

export class LotteryPageStore {
  constructor(
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly accountStore: AccountStore,
  ) {}

  info = new LotteryInfoModule(this)
  my = new LotteryMyModule(this)
  buy = new BuyLotteryModule(this)
  historical = new LotteryHistoricalModule(this)
}
