import { memoize, range } from "lodash"
import { computed, makeObservable, observable } from "mobx"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import ManualStakeStore from "../../Stake/store/manualStaking/ManualStakeStore"
import { ClaimTokenInfo, StakeCycle, StakeCycleStatus } from "../../Stake/types"
import {
  claimAndTranch,
  farmingAPR,
  getAtAlexAPY,
  getAtAlexIntrinsicValue,
  getCurrentUserBalance,
  getEndCycle,
  reducePosition,
} from "./CoFarmStore.service"

class CoFarmStore {
  constructor(
    readonly token: Currency.FWP_STX_ALEX_50_50_V1_01,
    readonly farmStore: ManualStakeStore,
    readonly currencyStore: Pick<
      CurrencyStore,
      "getPrice$" | "getTokenInfo$" | "fetchPoolBreakdown$"
    >,
  ) {
    makeObservable(this)
  }

  @computed get pendingReturns$(): number {
    return this.farmStore.myStaking.pendingReturns
  }

  private get dxDy$(): { dx: number; dy: number } {
    return this.currencyStore.fetchPoolBreakdown$(this.token)
  }

  @computed get stxAmount$(): number {
    return this.dxDy$.dx * this.pendingReturns$
  }

  @computed get alexAmount$(): number {
    return this.dxDy$.dy * this.pendingReturns$
  }

  atAlexIntrinsic = new LazyValue(() => 1e8, getAtAlexIntrinsicValue)

  balance = memoize(
    (cycle: number) =>
      new LazyValue(
        () => [this.farmStore.authStore.stxAddress$, cycle] as const,
        ([stxAddress, cycle]) => getCurrentUserBalance(stxAddress, cycle),
        {
          decorator: pMemoizeDecorator({
            persistKey:
              "fwp-wstx-alex-tranched-64:get-user-balance-per-cycle-or-default",
          }),
        },
      ),
  )

  @computed get currentCycleBalance$(): number {
    return this.balance(
      Math.min(this.farmStore.currentCycle$, this.getEndCycle$),
    ).value$
  }

  @computed get nextCycleBalance$(): number {
    return this.balance(Math.min(this.farmStore.nextCycle$, this.getEndCycle$))
      .value$
  }

  @computed get fromCycle$(): number {
    return this.farmStore.nextCycle$
  }

  @computed get toCycle$(): number {
    return this.getEndCycle$
  }

  @computed get atAlexAmount$(): number {
    return this.alexAmount$ / this.atAlexIntrinsic.value$
  }

  @computed get pendingRewards$(): number {
    return this.farmStore.myStaking.pendingReward
  }

  private getEndCycle = new LazyValue(() => null, getEndCycle, {
    decorator: pMemoizeDecorator({
      persistKey: "fwp-wstx-alex-tranched-64.get-end-cycle",
    }),
  })

  @computed get getEndCycle$(): number {
    return this.getEndCycle.value$
  }

  @computed get coFarmEnded$(): boolean {
    return this.getEndCycle$ < this.farmStore.currentCycle$
  }
  @computed get coFarmToBeClaimed$(): boolean {
    return this.balance(this.getEndCycle$).value$ > 0
  }

  @computed get apowerAmount$(): number {
    return (
      this.farmStore.myStaking.pendingReward * this.farmStore.apowerMultiplier$
    )
  }

  private farmApr = new LazyValue(() => this.token, farmingAPR)
  @computed get farmRP$(): number {
    // return this.farmApr.value$
    const middleCycle = Math.floor(
      (this.farmStore.nextCycle$ + this.getEndCycle$) / 2,
    )
    return this.cellViewModule(middleCycle).apr
  }

  private atAlexAPY = new LazyValue(() => null, getAtAlexAPY)
  @computed get atAlexAPY$(): number {
    return this.atAlexAPY.value$
  }

  @observable confirming = false

  txStore = new ConfirmTransactionStore()

  @asyncAction async confirm(run = runAsyncAction): Promise<void> {
    this.confirming = false
    try {
      const { txId } = await run(
        claimAndTranch(
          this.farmStore.authStore.stxAddress$,
          this.farmStore.myStaking.harvestableCycles,
        ),
      )
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }

  @computed get claimablePrincipalAmount$(): number {
    return (
      this.currencyStore.fetchPoolBreakdown$(this.token).dx *
      this.currentCycleBalance$
    )
  }

  @observable confirmingReducePosition = false
  @asyncAction async reducePosition(run = runAsyncAction): Promise<void> {
    try {
      this.confirmingReducePosition = false
      const { txId } = await run(
        reducePosition(this.farmStore.authStore.stxAddress$),
      )
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }

  cellViewModule = memoize(
    (cycle: number) => new CoFarmStackingCellViewModule(cycle, this),
  )

  @computed get recentNextCycles$(): StakeCycle[] {
    return range(
      this.farmStore.currentCycle$,
      this.farmStore.currentCycle$ + 3,
    ).map(c => this.cellViewModule(c))
  }
}

export default CoFarmStore

class CoFarmStackingCellViewModule implements StakeCycle {
  constructor(readonly cycleNumber: number, readonly store: CoFarmStore) {
    makeObservable(this)
  }

  get manualStakeCycle(): StakeCycle {
    return this.store.farmStore.myStaking.cellViewModule(this.cycleNumber)
  }

  get apr(): number {
    return this.manualStakeCycle.apr
  }

  get currentBlock(): number {
    return this.manualStakeCycle.currentBlock
  }

  get cycleStatus(): StakeCycleStatus {
    return this.manualStakeCycle.cycleStatus
  }
  get fromBlock(): number {
    return this.manualStakeCycle.fromBlock
  }

  @computed private get coFarmBalance$(): number {
    if (this.cycleNumber > this.store.getEndCycle$) {
      return 0
    }
    return this.cycleStatus === StakeCycleStatus.InProgress
      ? this.store.currentCycleBalance$
      : this.cycleStatus === StakeCycleStatus.Upcoming
      ? this.store.nextCycleBalance$
      : 0
  }

  @computed get myStaked(): ClaimTokenInfo {
    const { myStaked } = this.manualStakeCycle
    return {
      token: myStaked.token,
      count: myStaked.count + this.coFarmBalance$,
    }
  }

  get principalToClaim(): ClaimTokenInfo[] {
    return this.manualStakeCycle.principalToClaim
  }

  get rewardToClaim(): ClaimTokenInfo[] {
    const myStaking = this.store.farmStore.myStaking
    // co-farm only half will go to user
    const myCoFarmBalanceRatio = this.coFarmBalance$ / 2
    const alexAmount =
      myStaking.rewardAt(this.cycleNumber) +
      myStaking.rewardPerStakedUnit(this.cycleNumber) * myCoFarmBalanceRatio
    return [
      {
        token: this.store.currencyStore.getTokenInfo$(Currency.ALEX),
        count: alexAmount,
      },
      {
        token: this.store.currencyStore.getTokenInfo$(Currency.APOWER),
        count: alexAmount * this.store.farmStore.apowerMultiplier$,
      },
    ]
  }
  get targetBlock(): number {
    return this.manualStakeCycle.targetBlock
  }
  get targetDate(): Date {
    return this.manualStakeCycle.targetDate
  }
  get totalStaked(): ClaimTokenInfo {
    return this.manualStakeCycle.totalStaked
  }
}
