import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../../../../components/Card"
import { GoldenBox } from "../../../../../../components/GoldenBox"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../../components/NoteParagraph/NoteParagraph"
import { IconTokenCount } from "../../../../../../components/RichTokenCount"
import { TokenCount } from "../../../../../../components/TokenCount"
import { Block } from "../../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupIconContainer,
} from "../../../../../../components/TokenInput/BlockGroup"
import { TokenName } from "../../../../../../components/TokenName"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { CoFarmInfinityIcon } from "../../../CoFarmInfinityIcon"
import { ReactComponent as PlusIcon } from "./plusIcon.svg"

export const CoFarmWithAlexCard: FC<{
  className?: string
  lpLeftToken: TokenInfo
  lpLeftTokenCount: number
  lpRightToken: TokenInfo
  lpRightTokenCount: number
}> = props => {
  const { $t } = useIntl()

  return (
    <GoldenBox
      className={clsx("flex flex-col gap-4", props.className)}
      renderContainer={props => <Card {...props} />}
    >
      <CardTitle className={"flex items-center"}>
        {$t(
          defineMessage({
            defaultMessage: "{icon} Co-Farm with ALEX LP",
            description: "/Farm/Co farm with ALEX card title",
          }),
          {
            icon: <CoFarmInfinityIcon className="mr-1" />,
          },
        )}
      </CardTitle>
      <BlockGroup
        gapClassName={"gap-3"}
        direction={"row"}
        icon={<BlockGroupIconContainer Icon={PlusIcon} />}
        firstBlock={
          <Block
            className={"bg-black/30"}
            boxClassName={"p-3"}
            borderClassName={"border border-transparent"}
            roundedClassName={"rounded-lg"}
          >
            <InfoList
              direction={"row"}
              listItemDirection={"column"}
              listItemClassName={"flex-1 items-center gap-1"}
            >
              <InfoListItem>
                <InfoListItemTitle className={"text-gray-300"}>
                  {$t(
                    defineMessage({
                      defaultMessage: "Your {token}",
                      description: "/Farm/Co farm with ALEX card",
                    }),
                    {
                      token: <TokenName token={props.lpLeftToken} />,
                    },
                  )}
                </InfoListItemTitle>
                <InfoListItemDetail>
                  <IconTokenCount
                    inline={false}
                    iconSize={32}
                    className={"text-xl font-medium"}
                    tokenNameClassName={"sr-only"}
                    token={props.lpLeftToken}
                    count={props.lpLeftTokenCount}
                  />
                </InfoListItemDetail>
              </InfoListItem>
            </InfoList>
          </Block>
        }
        secondBlock={
          <Block
            className={"bg-blue-900"}
            boxClassName={""}
            borderClassName={"border border-white/10"}
            roundedClassName={"rounded-lg"}
          >
            <div className={"relative w-full"}>
              <svg
                className={
                  "absolute -right-[1px] -top-[1px] rounded-tr-lg overflow-hidden"
                }
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path opacity="0.5" d="M36 0H0L36 36V0Z" fill="white" />
                <path
                  d="M24.2668 8.2999L19.6466 12.9363L18.8029 12.0896L19.7007 7.15438L19.6669 7.12042L16.7589 10.0386L15.7821 9.0583L20.4023 4.42188L21.2596 5.28215L20.3482 10.1993L20.3888 10.24L23.2945 7.32417L24.2668 8.2999Z"
                  fill="#1D294D"
                />
                <path
                  d="M21.9471 15.3807C21.5907 15.0231 21.3561 14.6427 21.2433 14.2398C21.1335 13.8368 21.1425 13.4323 21.2703 13.0263C21.3997 12.6188 21.6471 12.2317 22.0125 11.8649C22.369 11.5072 22.751 11.2628 23.1586 11.1314C23.5662 11.0001 23.9707 10.985 24.3723 11.0862C24.7754 11.1888 25.1468 11.4107 25.4867 11.7517C25.7153 11.9812 25.8913 12.2317 26.0146 12.5034C26.141 12.775 26.2049 13.058 26.2064 13.3523C26.2094 13.6481 26.1417 13.9485 26.0034 14.2533C25.8665 14.5567 25.6507 14.8563 25.3559 15.1521L25.0919 15.417L22.166 12.4807L22.7615 11.8831L24.7829 13.9115C24.9212 13.7726 25.0137 13.6194 25.0604 13.4519C25.107 13.2844 25.107 13.1169 25.0604 12.9493C25.0167 12.7818 24.925 12.6279 24.7851 12.4875C24.6392 12.3411 24.4761 12.2453 24.2956 12.2C24.1181 12.1547 23.9414 12.157 23.7654 12.2068C23.591 12.2551 23.4346 12.3471 23.2962 12.483L22.7299 13.0512C22.5585 13.2233 22.4419 13.4036 22.3803 13.5923C22.3201 13.7824 22.3148 13.9704 22.3645 14.156C22.4141 14.3416 22.5194 14.5152 22.6803 14.6767C22.7871 14.7838 22.8999 14.8668 23.0187 14.9257C23.1375 14.9846 23.2586 15.0155 23.3819 15.0185C23.5052 15.0215 23.6271 14.9959 23.7474 14.9416L24.5776 15.8924C24.3189 16.0614 24.0399 16.1557 23.7406 16.1754C23.4443 16.195 23.142 16.1391 22.8337 16.0078C22.5284 15.8765 22.2329 15.6675 21.9471 15.3807Z"
                  fill="#1D294D"
                />
                <path
                  d="M24.8912 18.1993L27.4133 13.7757L28.3857 14.7514L26.5944 17.6265L26.626 17.6582L29.5137 15.8833L30.4679 16.8409L28.7218 19.7342L28.7511 19.7636L31.5937 17.9706L32.5637 18.9441L28.1578 21.4774L27.1404 20.4564L28.7241 17.6718L28.6812 17.6288L25.9064 19.218L24.8912 18.1993Z"
                  fill="#1D294D"
                />
              </svg>
            </div>
            <InfoList
              className={"p-3"}
              direction={"row"}
              listItemDirection={"column"}
              listItemClassName={"flex-1 items-center gap-1"}
            >
              <InfoListItem>
                <InfoListItemTitle className={"text-gray-300"}>
                  {$t(
                    defineMessage({
                      defaultMessage: "ALEX Lab Foundation",
                      description: "/Farm/Co farm with alex card",
                    }),
                  )}
                </InfoListItemTitle>
                <InfoListItemDetail>
                  <IconTokenCount
                    inline={false}
                    iconSize={32}
                    className={"text-xl font-medium"}
                    tokenNameClassName={"sr-only"}
                    token={props.lpRightToken}
                    count={props.lpRightTokenCount}
                  />
                </InfoListItemDetail>
              </InfoListItem>
            </InfoList>
          </Block>
        }
      />

      <NoteParagraph className={"text-yellow-100"}>
        {$t(
          defineMessage({
            defaultMessage:
              "The ALEX Lab Foundation will provide the other half and co-farm with your {tokenName}.",
            description: "/Farm/Co farm with alex card",
          }),
          { tokenName: <TokenName token={props.lpLeftToken} /> },
        )}
        <br />
        {$t(
          defineMessage({
            defaultMessage:
              "At the end of farming you will receive the same {tokenCount} {tokenName} you submitted, subject to the pool value exceeding the amount.",
            description: "/Farm/Co farm with alex card",
          }),
          {
            tokenCount: (
              <TokenCount
                token={props.lpLeftToken}
                count={props.lpLeftTokenCount}
              />
            ),
            tokenName: <TokenName token={props.lpLeftToken} />,
          },
        )}
        <br />
      </NoteParagraph>
    </GoldenBox>
  )
}
