import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { useManualStakeStore } from "../../Stake/store/manualStaking/useManualStakeStore"
import CoFarmStore from "./CoFarmStore"

const Store = createStore<CoFarmStore>("CoFarmStore")

export const CoFarmStoreProvider: FCC<{
  token: Currency.FWP_STX_ALEX_50_50_V1_01
}> = props => {
  const manualStore = useManualStakeStore()
  const currency = useCurrencyStore()
  const store = useCreation(
    () => new CoFarmStore(props.token, manualStore, currency),
    [manualStore, props.token, currency],
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useCoFarmStore = Store.useStore.bind(null)
