import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { Modal } from "../../../../components/Modal"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import styles from "./OrderbookDisclaimer.module.scss"
import { ReactComponent as WarnIcon } from "./_/warn.svg"
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import termHtml from "markdown-loader!./_/term.md?raw"
import { AlertLayoutAsBanner } from "../../../../components/alert/AlertLayoutAsBanner"

export type OrderbookDisclaimerProps = {
  className?: string
}

const OrderbookDisclaimer: FC<OrderbookDisclaimerProps> = props => {
  const { $t } = useIntl()
  const [showModal, setShowModal] = useState(false)
  return (
    <AlertLayoutAsBanner
      className={clsx("flex flex-col justify-between", props.className)}
    >
      <div className="flex items-center space-x-2.5">
        <WarnIcon className="flex-none" width={16} height={16} />
        <p className="text-gray-500 mt-1">
          {$t(
            defineMessage({
              defaultMessage:
                "Please note ALEX B20, as is true for all dapps built on a blockchain,is subject to a reorg, which refers to an event where a block that was part of the canonical chain becomes no longer part of the canonical chain because a competing block beat it out. Short reorgs happen all the time due to latency.",
              description: "/Orderbook/B20 Stats",
            }),
          )}
        </p>
        <GradientFilledButton
          boxClassName="px-4 py-2"
          onClick={() => setShowModal(true)}
        >
          {$t(
            defineMessage({
              defaultMessage: "Details",
              description: `/Orderbook/B20 Stats`,
            }),
          )}
        </GradientFilledButton>
      </div>

      <Modal visible={showModal} onClose={() => setShowModal(false)}>
        <CardModalContent
          layoutClassName={"flex flex-col"}
          gapClassName={"gap-6"}
          title={
            <div className="flex justify-center items-center gap-5">
              <WarnIcon />
              {$t(
                defineMessage({
                  defaultMessage: "Orderbook Disclaimer",
                  description: "Components/OrderbookDisclaimer/Title",
                }),
              )}
            </div>
          }
          width={640}
          height={520}
        >
          <CardInset
            className={
              "p-4 flex-1 leading-lose font-normal text-white overflow-auto"
            }
          >
            <div
              className={styles.term}
              dangerouslySetInnerHTML={{ __html: termHtml }}
            />
          </CardInset>

          <GradientFilledButton onClick={() => setShowModal(false)}>
            {$t(
              defineMessage({
                defaultMessage: "Okay",
                description:
                  "Components/OrderbookDisclaimer/Accept button text",
              }),
            )}
          </GradientFilledButton>
        </CardModalContent>
      </Modal>
    </AlertLayoutAsBanner>
  )
}

export default OrderbookDisclaimer
