import clsx from "clsx"
import { CSSProperties, Ref, useMemo } from "react"
import { RenderFn } from "../../utils/reactHelpers/childrenHelpers"
import { FCC } from "../../utils/reactHelpers/types"
import { InfoListItemProps } from "./InfoListItem"
import { InfoListItemDetailProps } from "./InfoListItemDetail"
import { InfoListItemTitleProps } from "./InfoListItemTitle"
import { InfoListProvider } from "./InfoListProvider"
import { InfoListDirectionContext } from "./_/InfoListDirectionContext"

export type ListItemDirection = "row" | "column" | "column-reverse"

export interface InfoListProps {
  className?: string
  style?: CSSProperties

  containerRef?: Ref<HTMLElement>

  inheritRenderers?: boolean

  /**
   * @default column
   */
  direction?: "row" | "column"

  /**
   * @default row
   */
  listItemDirection?: ListItemDirection

  renderInfoListItem?: RenderFn<InfoListItemProps>
  renderInfoListItemTitle?: RenderFn<InfoListItemTitleProps>
  renderInfoListItemDetail?: RenderFn<InfoListItemDetailProps>
}

export const InfoList: FCC<InfoListProps> = props => {
  const direction = props.direction ?? "column"

  const listItemDirection = props.listItemDirection ?? "row"

  const directionCtxValue = useMemo(
    () => ({ listItemDirection }),
    [listItemDirection],
  )

  return (
    <InfoListProvider
      inheritRenderers={props.inheritRenderers}
      renderInfoListItem={props.renderInfoListItem}
      renderInfoListItemTitle={props.renderInfoListItemTitle}
      renderInfoListItemDetail={props.renderInfoListItemDetail}
    >
      <InfoListDirectionContext.Provider value={directionCtxValue}>
        <dl
          ref={props.containerRef as any}
          className={clsx(
            props.className,
            "flex",
            direction === "column" ? "flex-col" : "flex-row",
          )}
          style={props.style}
        >
          {props.children}
        </dl>
      </InfoListDirectionContext.Provider>
    </InfoListProvider>
  )
}
