import { FC, useCallback, useRef, useState } from "react"
import { usePathGenerator } from "../../../routes/routes"
import { suspenseResource } from "../../../utils/SuspenseResource"

import { WiredDailyTestNetworkSetupGuideModalRefValue } from "../../Orderbook/components/TestNetworkSetupGuideModalContent/WiredDailyTestNetworkSetupGuideModal"
import { OrderbookMarket } from "../../Orderbook/store/stxdx_shared/StxDxMarket.service"
import { useOrderbookStore } from "../../Orderbook/store/useOrderbookStore"
import { SummaryBar } from "../components/SummaryBar"
import {
  TokenPairOption,
  TokenPairSelector,
} from "../components/TokenPairSelector"
import { usePerpetualStore } from "../store/usePerpetualStore"

const WiredTokenPairSelector: FC = () => {
  const [query, setQuery] = useState<string | null>(null)
  const store = useOrderbookStore()
  const g = usePathGenerator()
  const currency = store.currency
  const getInfo = useCallback(
    (market: OrderbookMarket): TokenPairOption => ({
      priceToken: currency.getTokenInfo$(market.priceToken),
      tradeToken: currency.getTokenInfo$(market.tradeToken),
      tradeTokenLastPrice: suspenseResource(
        () => store.info.marketSummary$(market.marketId).price,
      ),
      tradeToken24HourChangePercentage: suspenseResource(
        () => store.info.marketSummary$(market.marketId).price24hChangePercent,
      ),
      link: g.orderbookDetail(market.marketId),
    }),
    [currency, g, store.info],
  )
  return (
    <TokenPairSelector
      query={query}
      onQueryChange={setQuery}
      selectedOption={getInfo(store.market.read$)}
      tokenPairOptions={store.info.allAccessibleMarkets$
        .map(getInfo)
        .filter(
          i =>
            !query ||
            (i.tradeToken.displayName + "/" + i.priceToken.displayName)
              .toLowerCase()
              .includes(query.toLowerCase()),
        )}
    />
  )
}

export const WiredSummaryBar: FC<{ className?: string }> = props => {
  const store = usePerpetualStore()
  const dialogRef = useRef<WiredDailyTestNetworkSetupGuideModalRefValue>(null)

  return (
    <>
      <SummaryBar
        className={props.className}
        tradeToken={store.currency.getTokenInfo$(store.trade.tradeToken)}
        priceToken={store.currency.getTokenInfo$(store.trade.priceToken)}
        currentPrice={suspenseResource(() => store.currentPrice$)}
        currentPriceDeltaPercentage={suspenseResource(
          () => store.currentPriceDeltaPercentage$,
        )}
        tokenPairSelector={<WiredTokenPairSelector />}
        onShowNetworkSetupGuide={() => {
          dialogRef.current?.show?.()
        }}
        priceChangeIn24Hours={suspenseResource(() => ({
          priceBefore24Hours: store.currentMarketSummary$.price24hChange,
          priceDeltaPercentage:
            store.currentMarketSummary$.price24hChangePercent,
        }))}
        priceHighestIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.highestPrice,
        )}
        priceLowestIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.lowestPrice,
        )}
        priceTokenVolumeIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.amount24h,
        )}
        tradeTokenVolumeIn24Hours={suspenseResource(
          () => store.currentMarketSummary$.volume24h,
        )}
      />
      {/* TODO: update component */}
      {/*<WiredDailyTestNetworkSetupGuideModal ref={dialogRef} />*/}
    </>
  )
}
