import React, { FC } from "react"
import { Navigate } from "react-router-dom"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { hasAny } from "../../utils/arrayHelpers"

export const AnalyticsIndexPage: FC = () => {
  const appEnvStore = useAppEnvStore()

  if (!hasAny(appEnvStore.config$.analyticsTabs)) {
    return null
  }

  return <Navigate to={appEnvStore.config$.analyticsTabs[0].urlSlug} />
}
