import { TokenInfo } from "../../../utils/models/TokenInfo"

export enum TradingFormErrorType {
  ConnectWalletRequired = "ConnectWalletRequired",
  AmountIsEmpty = "AmountIsEmpty",
  LimitPriceTooSmall = "LimitPriceTooSmall",
  TradeTokenCountTooSmall = "TradeTokenCountTooSmall",
  TotalPriceTooSmall = "TotalPriceTooSmall",
  InsufficientPriceTokenBalance = "InsufficientPriceTokenBalance",
  InsufficientTradeTokenBalance = "InsufficientTradeTokenBalance",
}

export enum TradingFormWarningType {
  AbnormalBuyingPrice = "AbnormalBuyingPrice",
  AbnormalSellingPrice = "AbnormalSellingPrice",
}

export type TradingFormError =
  | TradingFormError.Common
  | TradingFormError.TotalPriceTooSmall
export namespace TradingFormError {
  export interface Common {
    type: Exclude<
      TradingFormErrorType | TradingFormWarningType,
      TradingFormErrorType.TotalPriceTooSmall
    >
  }

  export interface TotalPriceTooSmall {
    type: TradingFormErrorType.TotalPriceTooSmall
    priceToken: TokenInfo
    minimumPriceTokenCount: number
  }
}

export enum DepositFormErrorType {
  InsufficientTokenBalance,
  LessThanMinimizeAmount,
  AmountIsEmpty,
}
export interface DepositFormError {
  type: DepositFormErrorType
  message?: string
}

export enum WithdrawFormErrorType {
  ExceedMaxAmount,
  AmountIsEmpty,
}
export interface WithdrawFormError {
  type: WithdrawFormErrorType
  message?: string
}

export enum OrderType {
  Limit = "limit",
  Market = "market",
  StopLimit = "stopLimit",
}

export enum OrderDirection {
  Long = "long",
  Short = "short",
}

export enum MarginManipulation {
  Increase = "increase",
  Decrease = "decrease",
}

export type ClosePositionTriggerCondition =
  | {
      sourceType: "price"
      price: number
      percentage: number
    }
  | {
      sourceType: "percentage"
      price: number
      percentage: number
    }

export interface OpenPositionTriggerCondition {
  type: "gte" | "lte"
  priceTokenCountPerTradeToken: number
}

export interface PositionRecord {
  tradeToken: TokenInfo
  priceToken: TokenInfo
  orderDirection: OrderDirection
  leverage: number
  positionSize: number
  entryPrice: number
  marketPrice: number
  liquidationPrice: number
  marginPercentage: number
  marginPriceTokenCount: number
  pnl: {
    priceTokenCount: number
    percentage: number
  }
  takeProfitTrigger: null | ClosePositionTriggerCondition
  stopLossTrigger: null | ClosePositionTriggerCondition
  onClose: () => void | Promise<void>
}

export type OpenOrderRecord =
  | OpenOrderRecord.Limit
  | OpenOrderRecord.Market
  | OpenOrderRecord.StopLimit
export namespace OpenOrderRecord {
  export interface Common {
    tradeToken: TokenInfo
    priceToken: TokenInfo
    orderDirection: OrderDirection
    orderType: OrderType
    leverage: number
    priceTokenCountPerTradeToken: number
    priceTokenTotalCount: number
    filledPriceTokenCount: number
    filledPercentage: number
    takeProfitTrigger: null | ClosePositionTriggerCondition
    stopLossTrigger: null | ClosePositionTriggerCondition
    createdAt: Date
    onCancel?: () => void | Promise<void>
  }

  export interface Limit extends Common {
    orderType: OrderType.Limit
  }

  export interface Market extends Common {
    orderType: OrderType.Market
  }

  export interface StopLimit extends Common {
    orderType: OrderType.StopLimit
    triggerCondition: OpenPositionTriggerCondition
  }
}
export enum OrderStatus {
  Matching = "matching",
  Matched = "matched",
  Settled = "settled",
  Expired = "expired",
  Canceled = "canceled",
}

export enum FundHistoryRecordDirection {
  Deposit = "deposit",
  Withdraw = "withdraw",
}
export enum FundHistoryRecordStatus {
  Pending = "pending",
  Confirmed = "confirmed",
  Failed = "failed",
}
export interface FundHistoryRecord {
  status: FundHistoryRecordStatus
  token: TokenInfo
  tokenCount: number
  direction: FundHistoryRecordDirection
  transactionId: string
  createdAt: Date
  explorerLink: string
}

export type TradeHistoryRecord =
  | TradeHistoryRecord.Limit
  | TradeHistoryRecord.Market
  | TradeHistoryRecord.StopLimit
export namespace TradeHistoryRecord {
  interface Common {
    id: string
    tradeToken: TokenInfo
    priceToken: TokenInfo
    orderDirection: OrderDirection
    executedTradeTokenCount: number
    priceTokenTotalCount: number
    feePriceTokenCount: number
    createdAt: Date
    status: TradesHistoryRecordStatus
  }

  export interface Limit extends Common {
    orderType: OrderType.Limit
    priceTokenCountPerTradeToken: number
  }

  export interface Market extends Common {
    orderType: OrderType.Market
    priceTokenCountAverage: number
  }

  export interface StopLimit extends Common {
    orderType: OrderType.StopLimit
    priceTokenCountPerTradeToken: number
  }
}
export enum TradesHistoryRecordStatus {
  Settling = "Settling",
  Succeed = "Succeed",
  Failed = "Failed",
}

export type OrdersHistoryRecord =
  | OrdersHistoryRecord.Limit
  | OrdersHistoryRecord.Market
  | OrdersHistoryRecord.StopLimit
export namespace OrdersHistoryRecord {
  export interface Common {
    tradeToken: TokenInfo
    priceToken: TokenInfo
    orderStatus: OrdersHistoryRecordStatus
    orderDirection: OrderDirection
    orderType: OrderType
    leverage: number
    priceTokenCountPerTradeToken: number
    priceTokenTotalCount: number
    filledPriceTokenCount: number
    filledPercentage: number
    takeProfitTrigger: null | ClosePositionTriggerCondition
    stopLossTrigger: null | ClosePositionTriggerCondition
    createdAt: Date
  }

  export interface Limit extends Common {
    orderType: OrderType.Limit
  }

  export interface Market extends Common {
    orderType: OrderType.Market
    priceTokenCountAverage: number
  }

  export interface StopLimit extends Common {
    orderType: OrderType.StopLimit
    triggerCondition: OpenPositionTriggerCondition
  }
}
export enum OrdersHistoryRecordStatus {
  Normal = "Normal",
  Expired = "Expired",
  Cancelled = "Cancelled",
}

export type Leverage = 10 | 20 | 30 | 50 | 100
