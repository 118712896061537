import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { useConnect } from "../../../components/ConnectWallet/ConnectProvider"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"

const WiredLoadingOrConnectWallet: FC = () => {
  const auth = useAuthStore()
  const [connect] = useConnect()
  const { $t } = useIntl()
  if (auth.isWalletConnected) {
    return <LoadingIndicator className="mx-auto" />
  }
  return (
    <GradientFilledButton
      className={"w-[18rem] mx-auto"}
      onClick={() => connect()}
    >
      {$t(
        defineMessage({
          defaultMessage: "Connect Wallet",
          description: "/Auth/Loading Or Connect Wallet",
        }),
      )}
    </GradientFilledButton>
  )
}

export default WiredLoadingOrConnectWallet
