import { FungibleConditionCode } from "@stacks/transactions"
import { gql } from "@urql/core"
import { unwrapResponse } from "clarity-codegen"
import { Observable } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  AtAlexApyQuery,
  AtAlexApyQueryVariables,
  FarmingAprQuery,
  FarmingAprQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import {
  asSender,
  contractAddr,
} from "../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../utils/alexjs/Currency"
import { AlexVault, transfer } from "../../../utils/alexjs/postConditions"
import { first } from "../../../utils/arrayHelpers"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"

export const getAtAlexIntrinsicValue = (): Promise<number> =>
  asSender(CONTRACT_DEPLOYER)
    .contract("auto-alex")
    .func("get-intrinsic")
    .call({})
    .then(unwrapResponse)
    .then(a => a / 1e8)

export function farmingAPR(
  token: Currency.FWP_STX_ALEX_50_50_V1_01,
): Observable<number> {
  return fromUrqlSource(
    gqlQuery<FarmingAprQuery, FarmingAprQueryVariables>(
      gql`
        query FarmingAPR($token: String!) {
          laplace_latest_alex_reserve_pools(
            where: { token_name: { _eq: $token } }
          ) {
            earning_preview
          }
        }
      `,
      {
        token,
      },
    ),
    ({ data }) => {
      const earningPreviews =
        data.laplace_latest_alex_reserve_pools?.[0]?.earning_preview
      return earningPreviews?.[1] ?? 0
    },
  )
}

export function getAtAlexAPY(): Observable<number> {
  return fromUrqlSource(
    gqlQuery<AtAlexApyQuery, AtAlexApyQueryVariables>(
      gql`
        query AtAlexAPY {
          laplace_latest_auto_alex {
            apys
          }
        }
      `,
    ),
    ({ data }) => {
      const apys = data.laplace_latest_auto_alex[0]?.apys.map(Number)
      if (apys == null) {
        return 0
      }
      return first(apys) ?? 0
    },
  )
}

export async function claimAndTranch(
  stxAddress: string,
  cycles: number[],
): Promise<{ txId: string }> {
  return await asSender(stxAddress)
    .contract("fwp-wstx-alex-tranched-64")
    .func("claim-and-add-to-position-many")
    .call(
      {
        "reward-cycles": cycles,
      },
      [
        transfer(
          AlexVault,
          Currency.FWP_STX_ALEX_50_50_V1_01,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
        transfer(
          stxAddress,
          Currency.FWP_STX_ALEX_50_50_V1_01,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
        transfer(
          contractAddr("fwp-wstx-alex-tranched-64"),
          Currency.FWP_STX_ALEX_50_50_V1_01,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
        transfer(
          stxAddress,
          Currency.ALEX,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
        transfer(
          contractAddr("auto-alex"),
          Currency.ALEX,
          0,
          FungibleConditionCode.GreaterEqual,
        ),
      ],
    )
}

export async function reducePosition(
  stxAddress: string,
): Promise<{ txId: string }> {
  return await asSender(stxAddress)
    .contract("fwp-wstx-alex-tranched-64")
    .func("reduce-position")
    .call({}, [
      transfer(
        AlexVault,
        Currency.W_STX,
        0,
        FungibleConditionCode.GreaterEqual,
      ),
      transfer(AlexVault, Currency.ALEX, 0, FungibleConditionCode.GreaterEqual),
      transfer(
        contractAddr("fwp-wstx-alex-tranched-64"),
        Currency.W_STX,
        0,
        FungibleConditionCode.GreaterEqual,
      ),
      transfer(
        contractAddr("fwp-wstx-alex-tranched-64"),
        Currency.FWP_STX_ALEX_50_50_V1_01,
        0,
        FungibleConditionCode.GreaterEqual,
      ),
      transfer(
        contractAddr("fwp-wstx-alex-tranched-64"),
        Currency.ALEX,
        0,
        FungibleConditionCode.GreaterEqual,
      ),
    ])
}

export const getCurrentUserBalance = async (
  stxAddress: string,
  cycle: number,
): Promise<number> => {
  return asSender(stxAddress)
    .contract("fwp-wstx-alex-tranched-64")
    .func("get-user-balance-per-cycle-or-default")
    .call({
      cycle,
      user: stxAddress,
    })
    .then(a => a / 1e8)
}

export const getEndCycle = async (): Promise<number> => {
  return asSender(CONTRACT_DEPLOYER)
    .contract("fwp-wstx-alex-tranched-64")
    .func("get-end-cycle")
    .call({})
}
