import clsx from "clsx"
import { FC, ReactElement } from "react"
import { isElementOfType } from "../../../../../utils/reactHelpers/isElementOfType"
import { pointCircleSize } from "./consts"
import { CursorPointProps } from "./CursorPoint"
import { Point, PointProps } from "./Point"

export interface AbsolutePositionPointContainerProps {
  className?: string
  position: number
  point: ReactElement<PointProps> | ReactElement<CursorPointProps>
}

export const AbsolutePositionPointContainer: FC<
  AbsolutePositionPointContainerProps
> = props => {
  return (
    <div
      className={clsx("absolute transform", props.className)}
      style={
        {
          left: `${props.position * 100}%`,
          "--tw-translate-x": `${0 - pointCircleSize / 2}px`,
          zIndex:
            isElementOfType(props.point, Point) &&
            props.point.props.state === "arrived"
              ? 1
              : undefined,
        } as any
      }
    >
      {props.point}
    </div>
  )
}
