import { FC } from "react"

export interface HarvestIconProps {
  className?: string
  width?: number
  height?: number
}

export const HarvestIcon: FC<HarvestIconProps> = props => (
  <svg
    className={props.className}
    width={props.width ?? 16}
    height={props.height ?? 16}
    viewBox="0 0 17 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50008 0.669922L5.16675 4.66992H7.83341V9.38766C8.05157 9.39564 8.27394 9.39977 8.5 9.39977C8.72612 9.39977 8.94854 9.39564 9.16675 9.38766V4.66992H11.8334L8.50008 0.669922ZM14 11.0499V13.3012L13.7319 13.5033C13.5463 13.6129 13.2879 13.736 12.9452 13.8584C11.8791 14.2392 10.3076 14.5033 8.5 14.5033C6.69239 14.5033 5.1209 14.2392 4.05476 13.8584C3.71205 13.736 3.45371 13.6129 3.26806 13.5033L3 13.2989V11.0499C4.28183 11.6305 6.26904 12.0033 8.5 12.0033C10.731 12.0033 12.7182 11.6305 14 11.0499ZM15.5 9.5033V13.5033V13.5033C15.5 14.884 12.366 16.0033 8.5 16.0033C4.63404 16.0033 1.50005 14.884 1.5 13.5033V13.5033V9.5033C1.5 9.50329 1.5 9.50329 1.5 9.50328C1.5 8.37078 3.60851 7.41415 6.5 7.10681V8.61606C5.53839 8.72816 4.70313 8.91657 4.05476 9.14813C3.71205 9.27052 3.45371 9.39364 3.26806 9.50328C3.45371 9.61293 3.71205 9.73604 4.05476 9.85844C5.1209 10.2392 6.69239 10.5033 8.5 10.5033C10.3076 10.5033 11.8791 10.2392 12.9452 9.85844C13.2879 9.73604 13.5463 9.61293 13.7319 9.50328C13.5463 9.39364 13.2879 9.27052 12.9452 9.14813C12.2969 8.91657 11.4616 8.72816 10.5 8.61606V7.10681C13.3915 7.41415 15.5 8.37078 15.5 9.50328C15.5 9.50329 15.5 9.50329 15.5 9.5033ZM14.0643 9.2499C14.0645 9.2499 14.0631 9.25206 14.0595 9.25636C14.0624 9.25204 14.0642 9.24989 14.0643 9.2499ZM2.93566 9.2499C2.93584 9.24989 2.93762 9.25204 2.9405 9.25636C2.93693 9.25206 2.93549 9.2499 2.93566 9.2499Z"
    />
  </svg>
)
