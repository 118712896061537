import clsx from "clsx"
import { clamp } from "lodash"
import { FC } from "react"

export interface ProgressBarProps {
  className?: string
  bgColorTextClassName: string
  fgColorTextClassName: string
  progress: number
}

export const ProgressBar: FC<ProgressBarProps> = props => {
  return (
    <div
      className={clsx(
        "bg-current",
        props.className,
        props.bgColorTextClassName,
      )}
    >
      <div
        className={clsx("h-full bg-current", props.fgColorTextClassName)}
        style={{ width: `${clamp(props.progress * 100, 0, 100)}%` }}
      />
    </div>
  )
}
