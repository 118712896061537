import { Slot } from "@radix-ui/react-slot"
import clsx from "clsx"
import { FC } from "react"
import { usePathGenerator } from "../../../routes/routes"
import { FCC } from "../../../utils/reactHelpers/types"
import { OneOrMore } from "../../../utils/types"
import { NavLink } from "../../NavLink"
import { NavBarButtonItemData } from "../NavBarItem"

export interface SecondaryNavBarProps {
  className?: string
  navItems: OneOrMore<NavBarButtonItemData>
}

export const SecondaryNavBarHeight = 40

export const SecondaryNavBar: FC<SecondaryNavBarProps> = props => {
  const gen = usePathGenerator()

  return (
    <SecondaryNavBarFrame
      asChild
      className={clsx("overflow-x-auto", props.className)}
    >
      <div>
        <ul className={"flex flex-row gap-x-2.5 mx-auto w-fit-content"}>
          {props.navItems.map((item, idx) => (
            <li key={idx}>
              <NavLink to={item.link(gen)}>
                {({ isActive }) => (
                  <span
                    className={clsx(
                      "block px-4 py-2 border-t-2 border-b-2 border-transparent",
                      "text-sm leading-5 font-semibold text-gray-500",
                      "hover:bg-white/5",
                      isActive && "border-b-white text-white",
                    )}
                  >
                    {item.name}
                  </span>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </SecondaryNavBarFrame>
  )
}

const SecondaryNavBarFrame: FCC<{
  className?: string
  asChild?: boolean
}> = props => {
  const Comp = props.asChild ? Slot : "div"

  return (
    <Comp
      className={clsx(
        `backdrop-blur-lg`,
        "text-sm leading-5 font-medium text-gray-200",
        props.className,
      )}
      style={{
        backgroundColor: "rgba(63, 63, 70, 0.2)",
        boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.05)",
      }}
    >
      {props.children}
    </Comp>
  )
}
