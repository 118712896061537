import { map, Observable, switchMap } from "rxjs"
import { BigNumber } from "../../../../../utils/BigNumber"
import {
  BitcoinNetwork,
  BRC20WalletBalance,
  BRC20WalletBalanceDetails,
  InscribeOrderResponse,
} from "./BitcoinClient.types"

import {
  fetchBestInSlotLegacy,
  fetchOklink,
  honoClient,
} from "./request.service"

export const walletBalance = (
  network: BitcoinNetwork,
  blockHeight: Observable<number>,
  walletAddress: string,
): Observable<BRC20WalletBalance> => {
  return blockHeight.pipe(
    switchMap(() =>
      fetchBestInSlotLegacy<
        {
          tick: string
          overall_balance: string
          available_balance: string
          image_url: null | string
          minlistedunitprice: number
        }[]
      >({
        network,
        path: `/v1/get_brc20_balance/${walletAddress}`,
      }),
    ),
    map(
      (res): BRC20WalletBalance => ({
        tokens: res.map(d => {
          const balance = BigNumber.from(d.overall_balance)
          const restBalance = BigNumber.from(d.available_balance)

          return {
            symbol: d.tick,
            balance,
            restBalance,
            transferableBalance: BigNumber.minus(balance, restBalance),
          }
        }),
      }),
    ),
  )
}

/**
 * https://www.oklink.com/docs/zh/#explorer-api-brc20-query-address-balance-details
 */
const addressBalanceDetailsMockResp = [
  {
    page: "1",
    limit: "20",
    totalPage: "1",
    token: "ordi",
    tokenType: "BRC20",
    balance: "1450",
    availableBalance: "0",
    transferBalance: "1450",
    transferBalanceList: [
      {
        inscriptionId:
          "1f52619324be608b0602fdfe9071758a64063051c0223ee5ac2c5fa51f9eee22i0",
        inscriptionNumber: "13727387",
        amount: "250",
      },
    ],
  },
]
export const walletBalanceTokenDetails = (
  network: BitcoinNetwork,
  blockHeight: Observable<number>,
  walletAddress: string,
  token: string,
): Observable<BRC20WalletBalanceDetails> => {
  return blockHeight.pipe(
    switchMap(() =>
      fetchOklink<typeof addressBalanceDetailsMockResp>({
        network,
        path: `/api/v5/explorer/btc/address-balance-details`,
        query: {
          address: walletAddress,
          token,
          limit: 50,
        },
      }),
    ),
    map((res): BRC20WalletBalanceDetails => {
      const data = res.data[0]!
      return {
        symbol: data.token,
        balance: BigNumber.from(data.balance),
        transferableBalance: BigNumber.from(data.transferBalance),
        restBalance: BigNumber.from(data.availableBalance),
        transferableInscriptions: data.transferBalanceList.map(i => ({
          inscriptionId: i.inscriptionId,
          inscriptionNumber: i.inscriptionNumber,
          amount: BigNumber.from(i.amount),
        })),
      }
    }),
  )
}

export const createBrc20TransferOrder = async (
  recipientAddress: string,
  token: string,
  amount: BigNumber,
  feeRate: number,
): Promise<{
  inscribeOrder: InscribeOrderResponse
}> => {
  const resp = await honoClient()._api.inscribeOrders.$post({
    json: {
      recipientAddress,
      networkFeeRate: feeRate,
      brc20Ticker: token,
      brc20TokenAmount: BigNumber.toString(amount),
    },
  })

  const data = await resp.json()

  return {
    inscribeOrder: {
      ...data,
      createdAt: new Date(data.createdAt),
      charge: {
        ...data.charge,
        amount: BigNumber.from(data.charge.amount),
        feeToUSD: BigNumber.from(data.charge.feeToUSD),
      },
    },
  }
}
