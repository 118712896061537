export type SupportedBrowserName = "Firefox" | "Chrome" | "Safari"
export const isBrowserName = (
  name: SupportedBrowserName,
  ua = navigator.userAgent,
): boolean => {
  /**
   * I designed this function as a predicate instead of a getter function,
   * it can give us more flexibility to adjust the detection logic in the future
   *
   * For example, we can add new `SupportedBrowserName` like `Chromium` or `IE`
   * without change function signature
   */

  let detectedName: undefined | SupportedBrowserName

  /**
   * {@see https://web.dev/migrate-to-ua-ch/#strategy:-on-demand-client-side-javascript-api}
   */
  const brands = navigator.userAgentData?.brands ?? []
  if (
    isHasBrand("Chromium", brands) ||
    isHasBrand("Chrome", brands) ||
    isHasBrand("Edge", brands)
  ) {
    detectedName = "Chrome"
  } else if (isHasBrand("Firefox", brands)) {
    detectedName = "Firefox"
  } else if (isHasBrand("Safari", brands)) {
    detectedName = "Safari"
  }

  if (detectedName == null) {
    /**
     * {@see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#browser_name}
     */
    if (ua.includes("Firefox/")) {
      detectedName = "Firefox"
    } else if (ua.includes("Chrome/") || ua.includes("Chromium/")) {
      detectedName = "Chrome"
    } else if (ua.includes("Safari/")) {
      detectedName = "Safari"
    }
  }

  return detectedName === name

  function isHasBrand(
    partialBrand: string,
    brands: NavigatorUAData["brands"],
  ): boolean {
    return !!brands.find(b => b.brand.includes(partialBrand))
  }
}

export const isMobileDevice = (): boolean => {
  if (navigator.userAgentData?.mobile) {
    return true
  }

  /**
   * {@see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop}
   */
  return navigator.userAgent.includes("Mobi")
}

export function isTouchDevice(): boolean {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0
}
