import clsx from "clsx"
import { ComponentType } from "react"

export function withClassName<Tag extends keyof JSX.IntrinsicElements>(
  className: string,
  Comp: Tag,
): ComponentType<JSX.IntrinsicElements[Tag]>
export function withClassName<P extends { className?: string }>(
  className: string,
  Comp: ComponentType<P>,
): ComponentType<P>
export function withClassName(
  className: string,
  Comp: string | ComponentType<any>,
): ComponentType<any> {
  const fn: ComponentType<any> = props => (
    <Comp {...props} className={clsx(className, props.className)} />
  )

  fn.displayName = `withClassName(${
    typeof Comp === "string"
      ? Comp
      : Comp.displayName ?? Comp.name ?? "Anonymous"
  })`

  return fn
}
