import {
  AddressTransactionWithTransfers,
  ContractCallTransaction,
  MempoolContractCallTransaction,
  MempoolTransaction,
  Transaction,
} from "@stacks/stacks-blockchain-api-types"
import { deserializeCV } from "@stacks/transactions"
import {
  OpenCallFunctionDescriptor,
  ParameterObjOfDescriptor,
  ReturnTypeOfDescriptor,
} from "clarity-codegen"
import { AlexContracts } from "../generated/smartContract/contracts_Alex"

export function isContractCallTransaction(
  input: Transaction | MempoolTransaction,
): input is ContractCallTransaction | MempoolContractCallTransaction {
  return Boolean((input as ContractCallTransaction).contract_call)
}

export function isMempoolTransaction(
  input: Transaction | MempoolTransaction,
): input is MempoolTransaction {
  return !Boolean((input as Transaction).block_height)
}

export function isTransactionWithTransfers(
  input: AddressTransactionWithTransfers | MempoolTransaction,
): input is AddressTransactionWithTransfers {
  return Boolean((input as AddressTransactionWithTransfers).tx)
}

export type ContractCallArgs<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof (typeof AlexContracts)[ContractNames[number]],
> = ParameterObjOfDescriptor<
  (typeof AlexContracts)[ContractNames[number]][FunctionName]
>

export type GetContractCallArgs<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof (typeof AlexContracts)[ContractNames[number]],
> = () => ContractCallArgs<ContractNames, FunctionName>

export type ContractCallResult<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof (typeof AlexContracts)[ContractNames[number]],
> = ReturnTypeOfDescriptor<
  (typeof AlexContracts)[ContractNames[number]][FunctionName]
>

export type GetContractCallResult<
  ContractNames extends Readonly<Array<keyof typeof AlexContracts>>,
  FunctionName extends keyof (typeof AlexContracts)[ContractNames[number]],
> = () => ContractCallResult<ContractNames, FunctionName>

export function getContractCallHelpers<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
  tx: MempoolContractCallTransaction,
): {
  getArgs: GetContractCallArgs<[ContractName], FunctionName>
  getResult?: undefined
}
export function getContractCallHelpers<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
  tx: ContractCallTransaction,
): {
  getArgs: GetContractCallArgs<[ContractName], FunctionName>
  getResult: GetContractCallResult<[ContractName], FunctionName>
}
export function getContractCallHelpers<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): {
  getArgs: GetContractCallArgs<[ContractName], FunctionName>
  getResult?: undefined | GetContractCallResult<[ContractName], FunctionName>
}
export function getContractCallHelpers<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): {
  getArgs: GetContractCallArgs<[ContractName], FunctionName>
  getResult?: undefined | GetContractCallResult<[ContractName], FunctionName>
} {
  const functionDescriptor = getContractCallFunctionDescriptor(
    contractName,
    functionName,
  )

  if (isMempoolTransaction(tx)) {
    return {
      getArgs: () => getArgsFromFunctionDescriptor(functionDescriptor, tx),
    }
  } else {
    return {
      getArgs: () => getArgsFromFunctionDescriptor(functionDescriptor, tx),
      getResult: () => getResultFromFunctionDescriptor(functionDescriptor, tx),
    }
  }
}

function getContractCallFunctionDescriptor<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  contractName: ContractName,
  functionName: FunctionName,
): (typeof AlexContracts)[ContractName][FunctionName] extends OpenCallFunctionDescriptor
  ? OpenCallFunctionDescriptor
  : never {
  return AlexContracts[contractName][functionName] as any
}

function getArgsFromFunctionDescriptor<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  functionDescriptor: OpenCallFunctionDescriptor,
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): ParameterObjOfDescriptor<
  (typeof AlexContracts)[ContractName][FunctionName]
> {
  const args = functionDescriptor.input.reduce(
    (acc, arg, index) => ({
      ...acc,
      [arg.name]: arg.type.decode(
        deserializeCV(tx.contract_call.function_args![index]!.hex),
      ),
    }),
    {} as Record<string, any>,
  )
  return args as any
}

function getResultFromFunctionDescriptor<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
>(
  functionDescriptor: OpenCallFunctionDescriptor,
  tx: ContractCallTransaction,
): ReturnTypeOfDescriptor<(typeof AlexContracts)[ContractName][FunctionName]> {
  return functionDescriptor.output.decode(deserializeCV(tx.tx_result.hex))
}
