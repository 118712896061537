import { Currency } from "../../../../../utils/alexjs/Currency"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["alex-reserve-pool"] as const
const functionName = "stake-tokens" as const

export interface ManualStakingRowData extends BaseRowData {
  type: NotifyTransactionType.ManualStaking
  principalCurrencyAmount: CurrencyAmount.Confirmed
  period: number
}

export const manualStakingTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  ManualStakingRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.ManualStaking,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()

    const tokenTrait = args["token-trait"] as Currency
    // staking and farming are the same transaction type, return undefined if is farming.
    // undefined result will be filtered out
    if (tokenTrait !== Currency.ALEX) {
      return undefined
    }

    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
        amount: getAmountFromContract(args["amount-token"]),
      },
      period: args["lock-period"],
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    if (tokenTrait !== Currency.ALEX) {
      return undefined
    }
    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
        amount: getAmountFromContract(args["amount-token"]),
      },
      period: args["lock-period"],
    }
  },
}
