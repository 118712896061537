import { OrderDirection } from "../types"

export type Order = Order.OrderbookOrder | Order.TradeOrder | Order.MyTradeOrder
export namespace Order {
  interface OrderCommon {
    isBelongsToCurrentUser?: boolean
    tradeTokenCount: number
    priceTokenCountPerTradeToken: number
    orderDirection: OrderDirection
  }

  export interface OrderbookOrder extends OrderCommon {
    type: OrderListOrderType.OrderbookOrders
    priceTokenCountTotal: number
    volumePercentage: number
  }

  export interface TradeOrder extends OrderCommon {
    type: OrderListOrderType.TradeOrders
    createdAt: Date
  }

  export interface MyTradeOrder extends OrderCommon {
    type: OrderListOrderType.MyTradeOrders
    createdAt: Date
  }
}

export enum OrderListOrderType {
  OrderbookOrders,
  TradeOrders,
  MyTradeOrders,
}
