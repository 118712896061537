import { gql } from "@urql/core"
import { range, sum } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import { EXPLORER_TX_URL } from "../../../config"
import {
  LotteryClaimedInfoQuery,
  LotteryClaimedInfoQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { isNotNull } from "../../../utils/utils"
import {
  LotteryTicket,
  LotteryTicketPrizeType,
  LotteryTicketType,
} from "../component/types"
import { LotteryPageStore } from "./LotteryPageStore"
import {
  getAllWonTicketIds,
  LOTTERY_WALK_RESOLUTION,
} from "./LotteryPageStore.service"

export class LotteryMyModule {
  constructor(readonly store: LotteryPageStore) {
    makeObservable(this)
  }

  private myParticipation = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.info.currentLotteryId$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([stxAddress, lotteryId]) =>
      asSender(stxAddress)
        .contract("alex-lottery")
        .func("get-ticket-bounds-or-fail")
        .call({
          "lottery-id": lotteryId,
          owner: stxAddress,
        }),
  )

  @computed get participated$(): boolean {
    return this.myParticipation.value$.type === "success"
  }

  @computed get participatedTickets$(): number[] {
    if (this.myParticipation.value$.type === "error") {
      return []
    }
    const { start, end } = this.myParticipation.value$.value
    return range(start / LOTTERY_WALK_RESOLUTION, end / LOTTERY_WALK_RESOLUTION)
  }

  @computed get participatedTicketsCount$(): number {
    return this.participatedTickets$.length
  }

  private claimRecords = new LazyValue(
    () => this.store.info.currentLotteryId$,
    lotteryId =>
      fromUrqlSource(
        gqlQuery<LotteryClaimedInfoQuery, LotteryClaimedInfoQueryVariables>(
          gql`
            query LotteryClaimedInfo($lotteryId: String!) {
              laplace_contract_calls(
                where: {
                  contract_name: {
                    _in: ["alex-lottery", "lottery-claim-helper"]
                  }
                  function_name: { _eq: "claim" }
                  arg1: { _eq: $lotteryId }
                }
              ) {
                arg2
                tx_id
                transaction_result
              }
            }
          `,
          {
            lotteryId: String(lotteryId),
          },
        ),
      ),
  )

  roundPayoutAmount = createTransformer((round: number): number | undefined => {
    const record = this.claimRecords.value$.laplace_contract_calls.find(
      s => s.arg2 === String(round),
    )
    if (record == null) {
      return undefined
    }
    return (record.transaction_result.value.payout.value as number) / 1e8
  })

  claimLink = createTransformer((round: number): string | undefined => {
    const record = this.claimRecords.value$.laplace_contract_calls.find(
      s => s.arg2 === String(round),
    )
    if (record == null) {
      return undefined
    }
    return EXPLORER_TX_URL(record.tx_id.replace(/^\\/, "0"))
  })

  @computed get allRoundHasDraw$(): boolean {
    const walkParams = ([1, 2, 3] as const).map(
      r => this.store.info.walkParams(r).value$,
    )
    return this.store.info.drawEnded$ && walkParams.every(a => a != null)
  }

  @computed get lotteryTickets$(): LotteryTicket[] {
    if (!this.allRoundHasDraw$) {
      return this.participatedTickets$.map(i => ({
        type: LotteryTicketType.Unknown,
        number: i,
      }))
    }
    const walkParams = ([1, 2, 3] as const).map(
      r => this.store.info.walkParams(r).value$,
    )
    const myParticipation = this.participatedTickets$
    const wonTickets = walkParams
      .filter(isNotNull)
      .map(wp =>
        getAllWonTicketIds(wp).filter(a => myParticipation.includes(a)),
      )
    return this.participatedTickets$.map(i => {
      if (wonTickets.some(a => a.includes(i))) {
        return {
          type: LotteryTicketType.Won,
          number: i,
          prizes: wonTickets
            .map((a, rr) =>
              a.includes(i)
                ? {
                    type: [
                      LotteryTicketPrizeType.Third,
                      LotteryTicketPrizeType.Second,
                      LotteryTicketPrizeType.First,
                    ][rr]!,
                    explorerLink: this.claimLink(rr + 1),
                  }
                : null,
            )
            .filter(isNotNull),
          wonPrizeTokenCount: sum(
            wonTickets.map((a, rr) =>
              a.includes(i) ? this.roundPayoutAmount(rr + 1) : 0,
            ),
          ),
        }
      }
      return {
        type: LotteryTicketType.Lose,
        number: i,
      }
    })
  }

  wonTicketCount$ = createTransformer(
    (type: LotteryTicketPrizeType) =>
      this.lotteryTickets$.filter(
        a =>
          a.type === LotteryTicketType.Won &&
          a.prizes.some(p => p.type === type),
      ).length,
  )

  @computed get totalWonTicketsCount$(): number {
    return this.lotteryTickets$.filter(a => a.type === LotteryTicketType.Won)
      .length
  }

  @computed get totalWonAlexCount$(): number {
    return sum(
      this.lotteryTickets$.map(l =>
        l.type === LotteryTicketType.Won ? l.wonPrizeTokenCount : 0,
      ),
    )
  }

  @computed get burntTicketCount$(): number {
    return this.lotteryTickets$.filter(a => a.type === LotteryTicketType.Lose)
      .length
  }
}
