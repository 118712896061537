import {
  ReturnTypeOfDescriptor,
  UnboxResponse,
  unwrapResponse,
} from "clarity-codegen"
import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import { CONTRACT_DEPLOYER } from "../../../config"
import {
  asSender,
  Contracts,
} from "../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import type { LotteryStage } from "../component/types"
import { LotteryStageType } from "../component/types"
import { LotteryPageStore } from "./LotteryPageStore"
import { getCurrentLotteryId } from "./LotteryPageStore.service"

/**
 * {@see https://www.notion.so/alexgo-io/ALEX-Lottery-2f07be6ca46449ee98615479d42e43e2#9c5ac674a3b34109aed0011697b4e725}
 */
const drawingStageBlockHeightDuration = 25

export class LotteryInfoModule {
  constructor(readonly store: LotteryPageStore) {
    makeObservable(this)
  }

  private currentLotteryId = new LazyValue(
    () => this.store.chainStore.currentBlockHash$,
    getCurrentLotteryId,
  )

  @computed get currentLotteryId$(): number {
    const overwrite = new URLSearchParams(window.location.search).get(
      "lotteryId",
    )
    if (overwrite != null) {
      return Number(overwrite)
    }
    const current = this.currentLotteryId.value$
    if (current < 0) {
      throw new Error(`No lottery have been setup yet`)
    }
    return current
  }

  private totalPot = new LazyValue(
    () =>
      [
        this.currentLotteryId$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([lotteryId]) =>
      asSender(CONTRACT_DEPLOYER)
        .contract("alex-lottery")
        .func("get-total-pot-in-fixed")
        .call({
          "lottery-id": lotteryId,
        })
        .then(unwrapResponse)
        .then(a => a / 1e8),
  )

  @computed get totalPot$(): number {
    return this.totalPot.value$
  }

  private totalBonus = new LazyValue(
    () =>
      [
        this.currentLotteryId$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([lotteryId]) =>
      asSender(CONTRACT_DEPLOYER)
        .contract("alex-lottery")
        .func("get-total-bonus-in-fixed")
        .call({
          "lottery-id": lotteryId,
        })
        .then(unwrapResponse)
        .then(a => a / 1e8),
  )

  @computed get totalBonus$(): number {
    return this.totalBonus.value$
  }

  detail = new LazyValue(
    () => this.currentLotteryId$,
    lotteryId =>
      asSender(CONTRACT_DEPLOYER)
        .contract("alex-lottery")
        .func("get-lottery-or-fail")
        .call({
          "lottery-id": lotteryId,
        })
        .then(unwrapResponse),
  )

  @computed get status$(): LotteryStage {
    if (!this.registrationEnded$) {
      return {
        type: LotteryStageType.BuyTicket,
        currentStageStartBlock: this.registrationStartBlock$,
        currentStageStartDate: this.store.chainStore.estimatedDateForBlock$(
          this.registrationStartBlock$,
        ),
        nextStageStartBlock: this.drawingStartBlock$,
        nextStageStartDate: this.drawingStartDate$,
      }
    }
    if (!this.drawEnded$) {
      return {
        type: LotteryStageType.Drawing,
        currentStageStartBlock: this.drawingStartBlock$,
        currentStageStartDate: this.store.chainStore.estimatedDateForBlock$(
          this.drawingStartBlock$,
        ),
        nextStageStartBlock: this.drawBlock$,
        nextStageStartDate: this.drawTime$,
      }
    }
    return {
      type: LotteryStageType.Finished,
      currentStageStartBlock: this.drawBlock$,
      currentStageStartDate: this.store.chainStore.estimatedDateForBlock$(
        this.drawBlock$,
      ),
      nextStageStartBlock: this.nextLotteryBlock$,
      nextStageStartDate: this.nextLotteryDate$,
    }
  }

  @computed get drawingStartBlock$(): number {
    return this.detail.value$["registration-end-height"]
  }

  @computed get drawingStartDate$(): Date {
    return this.store.chainStore.estimatedDateForBlock$(this.drawingStartBlock$)
  }

  @computed get drawBlock$(): number {
    return this.drawingStartBlock$ + drawingStageBlockHeightDuration
  }

  @computed get drawTime$(): Date {
    return this.store.chainStore.estimatedDateForBlock$(this.drawBlock$)
  }

  @computed get registrationStartBlock$(): number {
    return this.detail.value$["registration-start-height"]
  }

  @computed get registrationStarted$(): boolean {
    return (
      this.store.chainStore.currentBlockHeight$ > this.registrationStartBlock$
    )
  }

  @computed get drawEnded$(): boolean {
    return this.store.chainStore.currentBlockHeight$ > this.drawBlock$
  }

  @computed get registrationEnded$(): boolean {
    return this.store.chainStore.currentBlockHeight$ > this.drawingStartBlock$
  }

  @computed get nextLotteryBlock$(): number {
    return this.drawBlock$ + 75
  }

  @computed get nextLotteryDate$(): Date {
    return this.store.chainStore.estimatedDateForBlock$(this.nextLotteryBlock$)
  }

  private roundInfo = new LazyValue(
    () => this.currentLotteryId$,
    lotteryId =>
      Promise.all(
        [1, 2, 3].map(round =>
          asSender(CONTRACT_DEPLOYER)
            .contract("alex-lottery")
            .func("get-lottery-round-or-fail")
            .call({
              "lottery-id": lotteryId,
              "round-id": round,
            })
            .then(unwrapResponse),
        ),
      ),
    {
      decorator: pMemoizeDecorator({
        persistKey: "alex-lottery-rounds-info",
      }),
    },
  )

  roundInfo$ = createTransformer(
    (
      round: 1 | 2 | 3,
    ): UnboxResponse<
      ReturnTypeOfDescriptor<
        Contracts["alex-lottery"]["get-lottery-round-or-fail"]
      >
    > => {
      return this.roundInfo.value$[round - 1]!
    },
  )

  walkParams = memoize(
    (round: 1 | 2 | 3) =>
      new LazyValue(
        () =>
          [
            round,
            this.currentLotteryId$,
            this.store.chainStore.currentBlockHeight$ >
              this.roundInfo$(round)["draw-height"],
          ] as const,
        async ([round, lotteryId, blockReached]) => {
          if (!blockReached) {
            return null
          }
          return asSender(CONTRACT_DEPLOYER)
            .contract("alex-lottery")
            .func("get-lottery-walk-parameters")
            .call({
              "lottery-id": lotteryId,
              "round-id": round,
            })
            .then(unwrapResponse)
        },
      ),
  )
}
