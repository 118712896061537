import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { save$t } from "../../../commonIntlMessages"
import { HeadlessButton } from "../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardPlate } from "../../../components/Card"
import {
  ActionRow,
  CardModalContent,
} from "../../../components/CardModal/CardModal"
import { defaultInputClassNames } from "../../../components/TokenInput/BlockInputContainer"
import { FCC } from "../../../utils/reactHelpers/types"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { RectButton } from "./wrappedCommonComponents/RectButton"
import { ReactComponent as TickIcon } from "./_/tick.svg"
import { ReactComponent as WarningIcon } from "./_/warning.svg"

const optionalLabelPart$t = defineMessage({
  defaultMessage: "(Optional)",
  description: `Orderbook/AccountSettingModal/optional mark for field label`,
})

export const AccountSettingModalContent: FC<{
  onSave?: () => void
  onClose?: () => void
  onSendEmail?: () => void
  username?: SuspenseResource<string | undefined>
  onUsernameChange?: (value: string) => void
  savedUsername?: SuspenseResource<string | undefined>
  email?: SuspenseResource<string | undefined>
  onEmailChange?: (value: string) => void
  savedEmail?: SuspenseResource<string | undefined>
  emailVerified?: SuspenseResource<boolean>
  enableEmailSubscription?: SuspenseResource<boolean>
  onEmailSubscriptionChange?: (value: boolean) => void
}> = props => {
  const { $t } = useIntl()
  const [sent, setSent] = useState(false)
  const username = readResource(props.username)
  const savedUsername = readResource(props.savedUsername)
  const email = readResource(props.email)
  const savedEmail = readResource(props.savedEmail)
  const emailVerified = readResource(props.emailVerified)
  const enableEmailSubscription = readResource(props.enableEmailSubscription)
  return (
    <CardModalContent
      title={
        <div className="text-xl leading-7 font-medium text-gray-200 text-left">
          {$t(
            defineMessage({
              defaultMessage: "Account setting",
              description: `Orderbook/AccountSettingModal/modal title`,
            }),
          )}
        </div>
      }
      onClose={props.onClose}
    >
      <div className="flex flex-col gap-y-2.5">
        <Label htmlFor="account_setting_username">
          {$t(
            defineMessage({
              defaultMessage: "Username",
              description: `Orderbook/AccountSettingModal/username field label`,
            }),
          )}
          {savedUsername?.length ? (
            <TickIcon />
          ) : (
            <span className="text-gray-500">{$t(optionalLabelPart$t)}</span>
          )}
        </Label>
        <Input
          id="account_setting_username"
          type="text"
          placeholder={$t(
            defineMessage({
              defaultMessage: "@username",
              description: `Orderbook/AccountSettingModal/username field placeholder`,
            }),
          )}
          value={username}
          onChange={event =>
            props.onUsernameChange?.(event.currentTarget.value)
          }
        />
      </div>
      <div className="flex flex-col gap-y-2.5">
        <Label htmlFor="account_setting_email">
          {$t(
            defineMessage({
              defaultMessage: "Email",
              description: `Orderbook/AccountSettingModal/email field label`,
            }),
          )}
          {savedEmail?.length ? (
            emailVerified ? (
              <>
                <TickIcon />
                <span className="text-sm leading-5 font-normal text-gray-500">
                  {$t(
                    defineMessage({
                      defaultMessage: "Verified",
                      description: `Orderbook/AccountSettingModal/email field/label suffix`,
                    }),
                  )}
                </span>
              </>
            ) : (
              <>
                <WarningIcon />
                <span className="text-sm leading-5 font-normal text-yellow-500">
                  {$t(
                    defineMessage({
                      defaultMessage:
                        "Unverified {sendVerificationEmailButton}",
                      description: `Orderbook/AccountSettingModal/email field/label suffix`,
                    }),
                    {
                      sendVerificationEmailButton: sent ? (
                        <span>
                          {$t(
                            defineMessage({
                              defaultMessage: "Verification email sent...",
                              description: `Orderbook/AccountSettingModal/email field/send verification email button text`,
                            }),
                          )}
                        </span>
                      ) : (
                        <HeadlessButton
                          className="text-blue-600"
                          onClick={() => {
                            props.onSendEmail?.()
                            setSent(true)
                          }}
                        >
                          {$t(
                            defineMessage({
                              defaultMessage: "Resend verification email →",
                              description: `Orderbook/AccountSettingModal/email field/send verification email button text`,
                            }),
                          )}
                        </HeadlessButton>
                      ),
                    },
                  )}
                </span>
              </>
            )
          ) : null}
        </Label>
        <Input
          id="account_setting_email"
          type="email"
          placeholder={$t(
            defineMessage({
              defaultMessage: "email address",
              description: `Orderbook/AccountSettingModal/email field/placeholder`,
            }),
          )}
          value={email}
          onChange={event => props.onEmailChange?.(event.currentTarget.value)}
        />
      </div>

      <CardPlate>
        <dl className="list-disc list-outside pl-[1.125rem] flex flex-col gap-y-2.5">
          <Dr>
            <Dt>
              {$t(
                defineMessage({
                  defaultMessage: "General",
                  description: `Orderbook/AccountSettingModal/subscription section title`,
                }),
              )}
            </Dt>
            <Dd>
              {$t(
                defineMessage({
                  defaultMessage: "Newsletter, Market Updates, Product Updates",
                  description: `Orderbook/AccountSettingModal/subscription section detail`,
                }),
              )}
            </Dd>
          </Dr>
          <Dr>
            <Dt>
              {$t(
                defineMessage({
                  defaultMessage: "Account",
                  description: `Orderbook/AccountSettingModal/subscription section title`,
                }),
              )}
            </Dt>
            <Dd>
              {$t(
                defineMessage({
                  defaultMessage: "Deposits, Withdrawals",
                  description: `Orderbook/AccountSettingModal/subscription section detail`,
                }),
              )}
            </Dd>
          </Dr>
          <Dr>
            <Dt>
              {$t(
                defineMessage({
                  defaultMessage: "Trading",
                  description: `Orderbook/AccountSettingModal/subscription section title`,
                }),
              )}
            </Dt>
            <Dd>
              {$t(
                defineMessage({
                  defaultMessage: "Order Updates",
                  description: `Orderbook/AccountSettingModal/subscription section detail`,
                }),
              )}
            </Dd>
          </Dr>
        </dl>
      </CardPlate>

      <label className="text-sm leading-5 font-normal text-gray-200 flex flex-row gap-x-4 items-center">
        <input
          type="checkbox"
          checked={enableEmailSubscription}
          onChange={event =>
            props.onEmailSubscriptionChange?.(event.currentTarget.checked)
          }
        />
        {$t(
          defineMessage({
            defaultMessage: "Receive email subscription messages",
            description: `Orderbook/AccountSettingModal/email subscription checkbox label`,
          }),
        )}
      </label>

      <ActionRow className="pt-7">
        <RectButton
          className="flex-1"
          onClick={props.onSave}
          Variant={GradientFilledButton}
          disabled={email == null || email.length === 0}
        >
          {$t(save$t)}
        </RectButton>
      </ActionRow>
    </CardModalContent>
  )
}

const Label = withClassName(
  "text-sm leading-5 font-normal text-gray-400 flex items-center gap-x-2.5",
  "label",
)

const Input = withClassName(
  clsx(
    defaultInputClassNames(),
    "rounded-lg bg-black/20 py-3 px-4",
    "text-gray-200 placeholder-gray-700 border border-gray-700",
  ),
  "input",
)

const Dr: FCC = props => <div className={"list-item"}>{props.children}</div>
const Dt = withClassName("text-xs leading-4 font-normal text-gray-200", "dt")
const Dd = withClassName("text-xs leading-4 font-normal text-gray-400", "dd")
