import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenExchangeRoute } from "../../../../../components/TokenExchangeRoute"
import { BlockTopTitleLine } from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { readResource } from "../../../../../utils/SuspenseResource"
import { DepositInfoListContent } from "../DepositInfoListContent"

export interface ConfirmRolloverModalContentProps {
  depositToken: TokenInfo
  prevCycleYieldToken: TokenInfo
  nextCycleYieldToken: TokenInfo
  prevCycleYieldTokenCount: number
  nextCycleYieldTokenCount: number
  tokenSwapRoute: TokenInfo[]
  expirationDate: Date
  expirationBlockHeight: number
  estimateApr: number
  estimateClaimableValue: number
  slippage: number
  liquidityProviderFee: number
  onEditSlippage?: () => void
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmRolloverModalContent: FC<
  ConfirmRolloverModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Rollover",
          description: "/Lend/DepositPage/ConfirmRolloverModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <BlockGroup
        firstBlock={
          <TokenBlock
            tokens={[
              {
                token: props.prevCycleYieldToken,
                count: props.prevCycleYieldTokenCount,
              },
            ]}
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            topArea={
              <BlockTopTitleLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Rollover (receive) {tipIcon}",
                    description:
                      "/Lend/DepositPage/ConfirmRolloverModalContent/Block text",
                  }),
                  {
                    tipIcon: (
                      <TipIcon
                        inline
                        tip={$t(
                          defineMessage({
                            defaultMessage:
                              "Rollover extends your deposit period through the next reward cycle.",
                            description:
                              "/Lend/DepositPage/ConfirmRolloverModalContent/Block text tooltip",
                          }),
                        )}
                      />
                    ),
                  },
                )}
              </BlockTopTitleLine>
            }
            tokens={[
              {
                token: props.nextCycleYieldToken,
                count: props.nextCycleYieldTokenCount,
              },
            ]}
          />
        }
      />

      <CardPlate className={"flex flex-col gap-2"}>
        <InfoList listItemDirection={"row-responsive"}>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Route",
                  description:
                    "/Lend/DepositPage/ConfirmRolloverModalContent/Info list item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenExchangeRoute tokens={readResource(props.tokenSwapRoute)} />
            </InfoListItemDetail>
          </InfoListItem>
          <DepositInfoListContent
            depositToken={props.depositToken}
            expirationBlockHeight={props.expirationBlockHeight}
            expirationDate={props.expirationDate}
            estimateAbsoluteInterest={props.estimateApr}
            estimateClaimableValue={props.estimateClaimableValue}
            liquidityProviderFee={props.liquidityProviderFee}
            slippage={props.slippage}
            onEditSlippage={props.onEditSlippage}
          />
        </InfoList>
      </CardPlate>
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "Your {token} will roll over to the next deposit period (est 7 days), to continue generating yield.",
            description: "/Lend/DepositPage/ConfirmRolloverModalContent/Note",
          }),
          { token: <TokenName token={props.nextCycleYieldToken} /> },
        )}
      </NoteParagraph>
      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description:
                "/Lend/DepositPage/ConfirmRolloverModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description:
                "/Lend/DepositPage/ConfirmRolloverModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
