export class CancelError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = "CancelError"
  }
}

export class DisplayableError extends Error {
  constructor(public message: string, options?: ErrorConstructorOptions) {
    super(message, options)
    this.cause = options?.cause
  }
}

export interface ErrorAlerter {
  show(options: { message: string }): void
}
export const alertError = (
  alerter: ErrorAlerter,
  getErrorDisplayableMessage: (err: unknown) => string,
): ((err: unknown) => void) => {
  return err => {
    alerter.show({ message: getErrorDisplayableMessage(err) })
  }
}

export const getErrorMessage = (err: unknown): null | string => {
  if (err != null) {
    if (err instanceof DisplayableError || "message" in (err as any)) {
      return (err as any).message
    }
  }

  return null
}
