import { action } from "mobx"
import { FC } from "react"
import { Spensor } from "../../../components/Spensor"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { useCRPSimulatorStore } from "../store/useCRPSimulatorStore"
import { PerformancePanel, PerformancePanelLoading } from "./PerformancePanel"

export const WiredPerformancePanel: FC = () => {
  const store = useCRPSimulatorStore()
  return (
    <Spensor
      fallback={
        <PerformancePanelLoading
          onExpand={action(() => {
            store.showDataComparison = true
          })}
          className="w-[43rem]"
        />
      }
    >
      {() => (
        <PerformancePanel
          onExpand={action(() => {
            store.showDataComparison = true
          })}
          className="w-[43rem]"
          initialLTV={suspenseResource(() => store.initialLTV$)}
          interestRate={store.interestRate}
          endDatePosition={store.endDatePosition$}
          data={store.simulationData$}
          liquidations={store.liquidations$}
        />
      )}
    </Spensor>
  )
}
