import clsx from "clsx"
import { FC } from "react"
import styles from "./BlueGradientAlert.module.scss"
import { AlertCommonProps } from "./types"

export interface BlueGradientAlertProps extends AlertCommonProps {}

export const BlueGradientAlert: FC<BlueGradientAlertProps> = props => (
  <div
    className={clsx(
      "p-3 sm:p-4",
      "rounded-lg",
      "text-sm leading-5 font-normal",
      styles.BlueGradientAlert,
      props.className,
    )}
  >
    {props.children}
  </div>
)
