import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetSubTitle,
  CardTitle,
} from "../../../../../components/Card"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon, TokenIconProps } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ReactComponent as LotteryForNotRegisteredIcon } from "./lotteryForNotRegisteredImage.svg"

export const ActionSectionContent$ClaimForNotRegistered: FC<{
  token: TokenInfo
  currentAllocationTokenCount: number
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Project in lottery",
              description:
                "/Launchpad/ActionSectionContent/ClaimForNotRegistered/Title",
            }),
          )}
        </CardTitle>
        <p
          className={
            "w-full text-2xl leading-8 font-normal text-gray-200 flex flex-wrap gap-5 items-center justify-center"
          }
        >
          {$t(
            defineMessage({
              defaultMessage: "Current allocation:",
              description:
                "/Launchpad/ActionSectionContent/ClaimForNotRegistered/lable",
            }),
          )}
          <span className={"flex gap-2.5 items-center justify-center"}>
            <TokenIcon token={props.token} size={TokenIconProps.sizes.small} />
            <span className={"text-green-300"}>
              <TokenCount
                token={props.token}
                count={props.currentAllocationTokenCount}
              />
            </span>
            <TokenName className={"text-green-300"} token={props.token} />
          </span>
        </p>

        <div className={"flex items-center justify-center relative w-full"}>
          <LotteryForNotRegisteredIcon />
          <TokenIcon
            className={
              "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            }
            token={props.token}
            size={TokenIconProps.sizes.large}
          />
        </div>

        <CardInsetSubTitle>
          {$t(
            defineMessage({
              defaultMessage: "You did not join the {token} project LaunchPad",
              description:
                "/Launchpad/ActionSectionContent/ClaimForNotRegistered/Sub title",
            }),
            {
              token: <TokenName token={props.token} />,
            },
          )}
        </CardInsetSubTitle>
      </CardInset>
    </>
  )
}
