import clsx from "clsx"
import { FC } from "react"
import styles from "./CanScrollMask.module.scss"
import { useCanScrollMask } from "./useCanScrollMask"

/**
 * Masks to indicate content can scroll to left or right.
 * Only show masks on mobile (screen smaller than sm)
 */
export const CanScrollMask: FC<{
  className?: string
  children?: React.ReactNode
}> = ({ className, children, ...restProps }) => {
  // TODO: disable this for desktop environment to improve perf
  const [ref, canScroll] = useCanScrollMask()
  return (
    <div className="relative">
      {canScroll.left && <Mask key="left" className="left-0 rotate-180" />}
      {canScroll.right && <Mask key="right" className="right-0" />}
      <div
        {...restProps}
        className={clsx(
          className,
          styles["no-scrollbar"],
          "overflow-x-scroll w-full",
        )}
        ref={ref}
      >
        {children}
      </div>
    </div>
  )
}

const Mask: FC<{ className?: string }> = ({ className }) => (
  <div
    className={clsx(
      "flex items-stretch absolute top-0 bottom-0 z-10 sm:hidden pointer-events-none",
      className,
    )}
  >
    <div
      className="w-[60px]"
      style={{
        background:
          "linear-gradient(90deg, rgba(17, 24, 39, 0) 0%, #111827 90%)",
      }}
    />
    <div className="w-[20px]" style={{ background: "#111827" }} />
  </div>
)
