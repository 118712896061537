import { generatePath, Params } from "react-router-dom"
import {
  getParamsFromScreens,
  Screens,
} from "../../pages/Orderbook/OrderbookPage.types"
import { OrderbookMarketId } from "../../pages/Orderbook/store/stxdx_shared/StxDxMarket.service"
import { BRC20Currency } from "../../utils/alexjs/Currency"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { RoutePath } from "./RoutePath"

export type PathGenerator = typeof pathGenerator

export const pathGenerator = {
  index: g(RoutePath.Launchpad),
  faucet: g(RoutePath.Faucet),
  stake: g(RoutePath.Stake),
  swap: g(RoutePath.Swap),
  margin: g(RoutePath.Margin),
  tokenList: g(RoutePath.TokenList),

  poolList: g(RoutePath.Pool),
  poolDetail: g(RoutePath.PoolDetail, (token: TokenInfo) => ({
    token: token.id,
  })),

  farmList: g(RoutePath.Farm),
  farmDetail: g(RoutePath.FarmDetail, (token: { id: string }) => ({
    token: token.id,
  })),
  coFarmDetail: g(RoutePath.CoFarmDetail, (token: TokenInfo) => ({
    token: token.id,
  })),
  notify: g(RoutePath.Notify),
  launchpad: g(RoutePath.Launchpad),
  launchpadDashboard: g(RoutePath.LaunchpadDashboard, (urlSlug: string) => ({
    urlSlug,
  })),
  launchpadDetail: g(RoutePath.LaunchpadDetail, (urlSlug: string) => ({
    urlSlug,
  })),
  launchpadValidateList: g(
    RoutePath.LaunchpadValidateList,
    (urlSlug: string) => ({
      urlSlug,
    }),
  ),
  launchpadLotteryList: g(
    RoutePath.LaunchpadLotteryList,
    (urlSlug: string) => ({
      urlSlug,
    }),
  ),

  lottery: g(RoutePath.Lottery),

  analytics: g(RoutePath.Analytics),
  analyticsDetail: g(RoutePath.AnalyticsDetail, (urlSlug: string) => ({
    urlSlug,
  })),

  CRPSimulator: g(RoutePath.CRPSimulator),

  lend: g(RoutePath.Lend),
  lendDetailDeposit: g(
    RoutePath.LendDetailDeposit,
    (token: Pick<TokenInfo, "id">) => ({
      token: token.id,
    }),
  ),
  lendDetailBorrow: g(
    RoutePath.LendDetailBorrow,
    (token: Pick<TokenInfo, "id">) => ({
      token: token.id,
    }),
  ),

  orderbook: g(RoutePath.Orderbook, (screen?: Screens) => [
    {},
    screen == null ? undefined : getParamsFromScreens(screen),
  ]),
  orderbookDetail: g(
    RoutePath.OrderbookDetail,
    (market: OrderbookMarketId, screen?: Screens) => [
      { market },
      screen == null ? undefined : getParamsFromScreens(screen),
    ],
  ),
  orderbookPegIn: g(
    RoutePath.OrderbookPegIn,
    (currency?: null | BRC20Currency) => [
      {},
      currency == null ? undefined : { token: currency },
    ],
  ),
  b20TokenList: g(RoutePath.B20TokenList),

  perpetual: g(RoutePath.Perpetual),
  perpetualDetail: g(
    RoutePath.PerpetualDetail,
    (market: OrderbookMarketId) => ({
      market,
    }),
  ),

  wrapBridge: g(RoutePath.WrapBridge),
}

function g(pathName: RoutePath): () => string
function g<Args extends any[] = []>(
  pathName: RoutePath,
  params: (
    ...args: Args
  ) => Params | [pathParams: Params, queryParams?: Params],
): (...args: Args) => string
function g<Args extends any[] = []>(
  pathName: RoutePath,
  params?: (
    ...args: Args
  ) => Params | [pathParams: Params, queryParams?: Params],
): (...args: Args) => string {
  return (...args) => {
    const resultParams = params == null ? [] : params(...args)

    const [pathParams, queryParams] = Array.isArray(resultParams)
      ? resultParams
      : [resultParams]

    let searchQuery = ""
    if (queryParams != null) {
      searchQuery = `?${new URLSearchParams(queryParams as any).toString()}`
    }

    return generatePath(`/${pathName}`, pathParams) + searchQuery
  }
}
