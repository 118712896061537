import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { dontWrapObserver } from "../../utils/mobxHelpers"
import { GradientFilledButton } from "../button/variants/GradientFilledButton/GradientFilledButton"
import { Modal } from "../Modal"
import { ReactComponent as CloseIcon } from "./close.svg"
import { ReactComponent as XverseIcon } from "./xverse.svg"

export const ConnectXverseWalletDialog: FC<{
  onClose?: () => void
  onOpen?: () => void
  visible?: boolean
}> = ({ visible = false, onClose }) => {
  const { $t } = useIntl()
  return (
    <Modal
      visible={visible}
      ContentLimiter={props => (
        <div {...props} className="w-full absolute bottom-0" />
      )}
    >
      <section
        className="backdrop-blur-lg flex flex-col gap-4 p-6 pb-14 rounded-t-2xl absolute bottom-0 left-0 right-0"
        style={{
          background:
            "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      >
        <h1 className="flex flex-row items-center justify-between">
          <span className="text-base font-semibold text-gray-200 grow">
            {$t(
              defineMessage({
                defaultMessage: "Connect Wallet",
                description: "/Components/Connect Xverse Wallet Dialog/title",
              }),
            )}
          </span>
          <span onClick={onClose}>
            <CloseIcon />
          </span>
        </h1>
        <GradientFilledButton
          roundedClassName="rounded-lg"
          onClick={() => {
            // open deep link scheme first, if failed, redirect to xverse website
            // TODO: can extract this helper
            setTimeout(() => {
              window.location.href = "https://www.xverse.app/"
            }, 30)
            window.location.href = `xverse://browser?url=${encodeURIComponent(
              window.location.href,
            )}`
          }}
        >
          <div className="flex flex-row space-between items-center">
            <span className="text-left font-bold grow">
              {$t(
                defineMessage({
                  defaultMessage: "Xverse Wallet",
                  description:
                    "Components/Connect Xverse Wallet Dialog/button text",
                }),
              )}
            </span>
            <XverseIcon />
          </div>
        </GradientFilledButton>
        <div className="text-sm leading-5 font-normal text-center text-gray-400">
          <p>
            {$t(
              defineMessage({
                defaultMessage: "ALEX requires Xverse Wallet to run on mobile.",
                description:
                  "Components/Connect Xverse Wallet Dialog/tips text",
              }),
            )}
          </p>
          <p>
            {$t<ReactNode>(
              defineMessage({
                defaultMessage:
                  'Please install the app then revisit "<strong>alexlab.app</strong>" to access ALEX.',
                description:
                  "Components/Connect Xverse Wallet Dialog/tips text",
              }),
              {
                strong: dontWrapObserver(children => (
                  <span className={"font-bold text-white"}>{children}</span>
                )),
              },
            )}
          </p>
        </div>
      </section>
    </Modal>
  )
}
