import { computed, makeObservable, observable } from "mobx"
import { MemoValue } from "../../../stores/MemoValue"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { ChartDatafeedModule } from "../../Orderbook/store/stxdx_shared/ChartDatafeedModule"
import { StxDxInfoModule } from "../../Orderbook/store/stxdx_shared/StxDxInfoModule"
import * as OrderbookInfoModuleService from "../../Orderbook/store/stxdx_shared/StxDxInfoModule.service"
import type { OrderbookMarketId } from "../../Orderbook/store/stxdx_shared/StxDxMarket.service"
import { OrderbookMarket } from "../../Orderbook/store/stxdx_shared/StxDxMarket.service"
import { StxDxMyInfoModule } from "../../Orderbook/store/stxdx_shared/StxDxMyInfoModule"
import { StxDxStore } from "../../Orderbook/store/stxdx_shared/StxDxStore"
import { PerpetualOrderbookModule } from "./modules/PerpetualOrderbookModule"
import { PerpetualTradeModule } from "./modules/PerpetualTradeModule"

export class PerpetualStore {
  constructor(
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly currency: CurrencyStore,
    readonly account: AccountStore,
    readonly stxDxStore: StxDxStore,
  ) {
    makeObservable(this)
  }

  destroy(): void {
    // WIP
  }

  @computed get info(): StxDxInfoModule {
    return this.stxDxStore.info
  }

  @computed
  get currentMarketSummary$(): OrderbookInfoModuleService.MarketSummary {
    return this.stxDxStore.info.marketSummary$(this.marketId$)
  }

  @computed get currentPrice$(): number {
    return this.currentMarketSummary$.price
  }

  private memoCurrentPrice = new MemoValue(() => this.currentPrice$)

  @computed get currentPriceDeltaPercentage$(): number {
    return (
      (this.memoCurrentPrice.previousValue ?? 0 - this.currentPrice$) /
      this.currentPrice$
    )
  }

  market = new SuspenseObservable<OrderbookMarket>()

  @computed get marketId$(): OrderbookMarketId {
    return this.market.read$.marketId
  }

  @observable.ref trade = new PerpetualTradeModule(this)
  orderbook = new PerpetualOrderbookModule(this)
  chartDatafeed = new ChartDatafeedModule(this)

  get myInfo(): StxDxMyInfoModule {
    return this.stxDxStore.myInfo
  }
}
