import {
  defineContract,
  listT,
  numberT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const lotteryClaimHelper = defineContract({
  "lottery-claim-helper": {
    claim: {
      input: [
        { name: "lottery-id", type: numberT },
        { name: "round-id", type: numberT },
        { name: "winners", type: listT(principalT) },
        { name: "token-trait", type: principalT },
      ],
      output: responseSimpleT(
        tupleT({ gross: numberT, net: numberT, payout: numberT, tax: numberT }),
      ),
      mode: "public",
    },
  },
} as const)
