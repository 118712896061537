import {
  CompactType,
  RejectKeyByValue,
  SelectKeyByValue,
  StringOnly,
  UnionToIntersection,
} from "../../../utils/types"
import tailwindColors from "./tailwindColors.json"

type AnyFlatingColors = Record<string, string | Record<string, string>>

type FlatColors<T extends AnyFlatingColors> = CompactType<
  SelectKeyByValue<T, string> &
    UnionToIntersection<
      {
        [K in StringOnly<keyof RejectKeyByValue<T, string>>]: {
          [K2 in StringOnly<keyof RejectKeyByValue<T, string>[K]>]: {
            [K3 in string as `${K}-${K2}`]: T[K][K2]
          }
        }[StringOnly<keyof T[K]>]
      }[StringOnly<keyof RejectKeyByValue<T, string>>]
    >
>
// type A = FlatColors<typeof tailwindColors>
// type B = A["pink-50"]

const flatColors = <C extends AnyFlatingColors>(colors: C): FlatColors<C> => {
  const result: Record<string, string> = {}
  ;(Object.keys(colors) as StringOnly<keyof C>[]).forEach(key => {
    const value = colors[key]
    if (value == null) return
    if (typeof value === "string") {
      result[key] = value
    } else {
      ;(Object.keys(value) as StringOnly<keyof typeof value>[]).forEach(
        key2 => {
          const value2 = value[key2]
          if (typeof value2 === "string") {
            result[`${key}-${key2}`] = value2
          }
        },
      )
    }
  })
  return result as any
}

export const defaultThemePalette = flatColors(tailwindColors)
