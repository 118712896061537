import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../../utils/createStore"
import { FCC } from "../../../../utils/reactHelpers/types"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import AutoStakeStore from "./AutoStakeStore"

const AutoStakeStoreContext = createStore<AutoStakeStore>("AutoStakeStore")

export const AutoStakeProvider: FCC = props => {
  const chainStore = useChainStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(
    () =>
      new AutoStakeStore(chainStore, authStore, accountStore, currencyStore),
    [accountStore, authStore, chainStore, currencyStore],
  )
  return (
    <AutoStakeStoreContext.Provider store={store}>
      {props.children}
    </AutoStakeStoreContext.Provider>
  )
}

export const useAutoStakeStore = AutoStakeStoreContext.useStore
