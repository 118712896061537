import {
  booleanT,
  bufferT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const executorDao = defineContract({
  "executor-dao": {
    construct: {
      input: [{ name: "proposal", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    execute: {
      input: [
        { name: "proposal", type: principalT },
        { name: "sender", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "request-extension-callback": {
      input: [
        { name: "extension", type: principalT },
        { name: "memo", type: bufferT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-extension": {
      input: [
        { name: "extension", type: principalT },
        { name: "enabled", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-extensions": {
      input: [
        {
          name: "extension-list",
          type: listT(tupleT({ enabled: booleanT, extension: principalT })),
        },
      ],
      output: responseSimpleT(listT(booleanT)),
      mode: "public",
    },
    "executed-at": {
      input: [{ name: "proposal", type: principalT }],
      output: optionalT(numberT),
      mode: "readonly",
    },
    "is-extension": {
      input: [{ name: "extension", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "executed-proposals": {
      input: principalT,
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    extensions: {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    executive: { input: noneT, output: principalT, mode: "variable" },
  },
} as const)
