import { noop } from "lodash"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { ScopedLoadingBoundary } from "../../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../../components/NavLink"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { SelectedListItem } from "../_/SelectedListItem"
import { SelectedListTitle } from "../_/SelectedListTitle"
import { ReactComponent as Brc20Icon } from "../_/brc20.svg"
import { ReactComponent as BridgeIcon } from "../_/bridge.svg"
import { ReactComponent as SUSDTIcon } from "./_/withdrawSUSDT.svg"

export const WithdrawEntryModalContent: FC<{
  isPegInBtcAddressGenerated: SuspenseResource<boolean>
  bridgeOutEthereumTokensLink: SuspenseResource<string>
  onWithdrawStxTokens: SuspenseResource<() => void>
  onPegOutBrc20Tokens: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      className="flex flex-col gap-4"
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Withdraw",
          description: "/OrderBook/BRC20/WithdrawEntryModalContent/Title text",
        }),
      )}
      onClose={props.onClose}
    >
      <SelectedListTitle>
        {$t(
          defineMessage({
            defaultMessage: "Withdraw assets from your Trading Account",
            description:
              "/OrderBook/BRC20/WithdrawEntryModalContent/Description text",
          }),
        )}
      </SelectedListTitle>
      <ScopedLoadingBoundary
        loadingIndicator={<LoadingIndicator />}
        placeholder={
          <SelectedListItem
            icon={<SUSDTIcon />}
            arrowDirection="right"
            title={$t(
              defineMessage({
                defaultMessage: "Withdraw SIP-010 & BRC-20",
                description:
                  "/OrderBook/WithdrawEntryModalContent/Selected title text",
              }),
            )}
            description={$t(
              defineMessage({
                defaultMessage:
                  "Withdraw your Trading Account assets to your Stacks Address",
                description:
                  "/OrderBook/WithdrawEntryModalContent/Selected description text",
              }),
            )}
            onClick={noop}
          />
        }
      >
        {() => (
          <SelectedListItem
            icon={<SUSDTIcon />}
            arrowDirection="right"
            title={$t(
              defineMessage({
                defaultMessage: "Withdraw SIP-010 & BRC-20",
                description:
                  "/OrderBook/WithdrawEntryModalContent/Selected title text",
              }),
            )}
            description={$t(
              defineMessage({
                defaultMessage:
                  "Withdraw your Trading Account assets to your Stacks Address",
                description:
                  "/OrderBook/WithdrawEntryModalContent/Selected description text",
              }),
            )}
            onClick={readResource(props.onWithdrawStxTokens)}
          />
        )}
      </ScopedLoadingBoundary>
      <div className="space-y-2.5 mt-3.5">
        <SelectedListTitle className="mb-2.5">
          {$t(
            defineMessage({
              defaultMessage: "Bridge assets out from Stacks Chain",
              description:
                "/OrderBook/WithdrawEntryModalContent/Description text",
            }),
          )}
        </SelectedListTitle>
        <NavLink
          to={safeReadResource(props.bridgeOutEthereumTokensLink) ?? ""}
          target="_blank"
        >
          <SelectedListItem
            icon={<BridgeIcon />}
            arrowDirection="topRight"
            disabled={!props.isPegInBtcAddressGenerated}
            title={$t(
              defineMessage({
                defaultMessage: "Bridge sUSDT to USDT",
                description:
                  "/OrderBook/WithdrawEntryModalContent/Selected title text",
              }),
            )}
            description={$t(
              defineMessage({
                defaultMessage:
                  "Bridge your sUSDT to BNB/Ethereum from Stacks.",
                description:
                  "/OrderBook/WithdrawEntryModalContent/Selected description text",
              }),
            )}
          />
        </NavLink>

        <SelectedListItem
          icon={<Brc20Icon />}
          arrowDirection="right"
          disabled={!props.isPegInBtcAddressGenerated}
          title={$t(
            defineMessage({
              defaultMessage: "Peg-out BRC-20 to BTC Chain",
              description:
                "/OrderBook/WithdrawEntryModalContent/Selected title text",
            }),
          )}
          description={$t(
            defineMessage({
              defaultMessage:
                "Peg-out your BRC-20 from Stacks Address to BTC Address",
              description:
                "/OrderBook/WithdrawEntryModalContent/Selected description text",
            }),
          )}
          onClick={props.onPegOutBrc20Tokens}
        />
      </div>
    </CardModalContent>
  )
}
