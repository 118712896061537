export type LotteryTicket =
  | LotteryTicket.Unknown
  | LotteryTicket.Won
  | LotteryTicket.Lose

export namespace LotteryTicket {
  interface Common {
    number: number
  }

  export interface Unknown extends Common {
    type: LotteryTicketType.Unknown
  }

  export interface Won extends Common {
    type: LotteryTicketType.Won
    prizes: {
      type: LotteryTicketPrizeType
      explorerLink?: string
    }[]
    wonPrizeTokenCount: number
  }

  export interface Lose extends Common {
    type: LotteryTicketType.Lose
  }
}

export enum LotteryTicketPrizeType {
  First = "First",
  Second = "Second",
  Third = "Third",
}

export enum LotteryTicketType {
  Unknown = "Unknown",
  Won = "Won",
  Lose = "Lose",
}

export enum BuyLotteryFormErrorType {
  WalletNotConnected = "WalletNotConnected",

  EmptyNormalLotteryTicketCount = "EmptyNormalLotteryTicketCount",

  ExceedMaxAllowNormalLotteryTicketCount = "ExceedMaxAllowNormalLotteryTicketCount",
  InsufficientNormalLotteryTicketPriceTokenBalance = "InsufficientNormalLotteryTicketPriceTokenBalance",

  ExceedMaxAllowAdditionalLotteryTicketCount = "ExceedMaxAllowAdditionalLotteryTicketCount",
  InsufficientAdditionalLotteryTicketPriceTokenBalance = "InsufficientAdditionalLotteryTicketPriceTokenBalance",
}

export type BuyLotteryFormError = {
  type: BuyLotteryFormErrorType
  message: string
}

export enum LotteryStageType {
  BuyTicket = "BuyTicket",
  Drawing = "Drawing",
  Finished = "Finished",
}
export type LotteryStage = {
  type: LotteryStageType
  currentStageStartBlock: number
  currentStageStartDate: Date
  nextStageStartBlock: number
  nextStageStartDate: Date
}

export interface RoundPrize {
  type: LotteryTicketPrizeType
  prizeTokenCount: number
  winningTicketsCount: number
}
