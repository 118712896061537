/* eslint-disable @typescript-eslint/no-var-requires */

import {
  Currency,
  CurrencyAndBRC20s,
  DownstreamCurrency,
  isCurrencyOrB20,
  WrappedCurrency,
} from "../alexjs/Currency"
import { isEnumValue } from "../enumHelpers"
import { TokenInfo } from "../models/TokenInfo"

export namespace TokenInfoPresets {
  export const toCurrency = (token: TokenInfo): CurrencyAndBRC20s => {
    let currency = token.id
    if (isEnumValue(DownstreamCurrency, currency)) {
      currency = WrappedCurrency.wrap(currency)
    }
    if (!isCurrencyOrB20(currency)) {
      throw new Error(`Unknown currency ${token.id}`)
    }
    return currency
  }

  export const isAlex = (token: TokenInfo): boolean => {
    return token.id === Currency.ALEX
  }

  export const isAPower = (token: TokenInfo): boolean => {
    return token.id === Currency.APOWER
  }

  export const APower: TokenInfo = {
    id: Currency.APOWER,
    displayName: "APower",
    precision: 4,
    propertyTags: [],
    icons: [require("./apower.svg").default],
  }

  export const MockAtAlex: TokenInfo = {
    id: "mock-atALEX",
    displayName: "atALEX",
    description: "atALEX Token",
    precision: 4,
    propertyTags: [],
    icons: [require("./atALEX.svg").default],
  }

  export const MockAlex: TokenInfo = {
    id: Currency.ALEX,
    displayName: "ALEX",
    description: "ALEX Token",
    precision: 4,
    propertyTags: [],
    icons: [require("./alex.svg").default],
  }

  export const MockYieldAlex: TokenInfo = {
    id: Currency.YIELD_ALEX,
    displayName: "yALEX",
    description: "Yield ALEX Token",
    precision: 4,
    propertyTags: [],
    icons: [require("./yAlex.svg").default],
  }

  export const MockSTX: TokenInfo = {
    id: Currency.W_STX,
    displayName: "STX",
    description: "Stacks Token",
    precision: 4,
    propertyTags: [],
    icons: [require("./STX.svg").default],
  }

  export const MockXBTC: TokenInfo = {
    id: Currency.W_XBTC,
    displayName: "xBTC",
    description: "Wrapped Bitcoin",
    precision: 5,
    propertyTags: [],
    icons: [require("./XBTC.svg").default],
  }

  export const MockBTC: TokenInfo = {
    id: "btc",
    displayName: "BTC",
    description: "Bitcoin",
    precision: 5,
    propertyTags: [],
    icons: [require("./BTC.svg").default],
  }

  export const MockSats: TokenInfo = {
    id: "btc-sats",
    displayName: "Sats",
    description: "Bitcoin Satoshis",
    precision: 0,
    propertyTags: [],
    icons: [require("./BTC.svg").default],
  }

  export const USD: TokenInfo = {
    id: Currency.W_USDA,
    displayName: "USD",
    precision: 2,
    propertyTags: [],
    icons: [require("./USDA.svg").default],
  }

  /**
   * only used in CRP Simulator
   */
  export const MockXUSD: TokenInfo = {
    id: "xusd",
    displayName: "xUSD",
    precision: 2,
    propertyTags: [],
    icons: [require("./xUSD.svg").default],
  }

  export const IDOLotteryTicket: TokenInfo = {
    id: "ido-lottery-icon",
    displayName: "Lottery Ticket",
    precision: 0,
    propertyTags: [],
    icons: [require("./idoLotteryTicket.svg").default],
  }

  export const LotteryTicket: TokenInfo = {
    id: "lottery-icon",
    displayName: "Lottery Ticket",
    precision: 0,
    propertyTags: [],
    icons: [require("./lotteryTicket.svg").default],
  }

  export const MOCK_FWP_ATALEX_ALEX_50_50_V1_01: TokenInfo = {
    id: "mock-atALEX-ALEX",
    displayName: "atALEX-ALEX-50-50",
    precision: MockAtAlex.precision,
    propertyTags: [],
    icons: [...MockAtAlex.icons, ...MockAlex.icons] as any,
  }

  export const MOCK_FWP_WSTX_ALEX_50_50_V1_01: TokenInfo = {
    id: Currency.FWP_STX_ALEX_50_50_V1_01,
    displayName: "STX-ALEX-50-50",
    precision: MockSTX.precision,
    propertyTags: [],
    icons: [...MockSTX.icons, ...MockAlex.icons] as any,
  }

  export const MOCK_FWP_WSTX_ALEX_TRANCHED: TokenInfo = {
    id: Currency.FWP_STX_ALEX_TRANCHED,
    displayName: "CO-FARM-STX-ALEX",
    precision: MockSTX.precision,
    propertyTags: [],
    icons: [...MockSTX.icons, ...MockAlex.icons] as any,
  }

  export const MOCK_KEY_ALEX_AUTOLEXT: TokenInfo = {
    id: Currency.KEY_ALEX_AUTOALEX,
    displayName: "Key ALEX atAlex",
    precision: 4,
    propertyTags: [],
    icons: [...MockAlex.icons],
  }

  export const MOCK_FWP_WSTX_WBTC_50_50_V1_01: TokenInfo = {
    id: Currency.FWP_STX_XBTC_50_50_V1_01,
    displayName: "STX-XBTC-50-50",
    precision: MockSTX.precision,
    propertyTags: [],
    icons: [...MockSTX.icons, ...MockXBTC.icons] as any,
  }

  export const MOCK_BANANA: TokenInfo = {
    id: Currency.W_BANANA,
    description: "Bitcoin Monkey",
    displayName: "BANANA",
    precision: USD.precision,
    propertyTags: [],
    icons: [require("./BANANA.svg").default],
  }

  export const MOCK_DIKO: TokenInfo = {
    id: Currency.W_DIKO,
    description: "Arkadiko Coin",
    displayName: "DIKO",
    precision: 4,
    propertyTags: [],
    icons: [require("./DIKO.svg").default],
  }

  export const USDC: TokenInfo = {
    id: "usdc",
    description: "USDC",
    displayName: "USDC",
    precision: 4,
    propertyTags: [],
    icons: [require("./USDC.svg").default],
  }

  export const USDT: TokenInfo = {
    id: "usdt",
    description: "USDT",
    displayName: "USDT",
    precision: 4,
    propertyTags: [],
    icons: [require("./USDT.svg").default],
  }

  export const LUNR: TokenInfo = {
    id: "lunr",
    description: "LUNR",
    displayName: "LUNR",
    precision: 4,
    propertyTags: [],
    icons: [require("./LUNR.png")],
  }

  export const WBTC: TokenInfo = {
    id: "wbtc",
    description: "WBTC",
    displayName: "WBTC",
    precision: 4,
    propertyTags: [],
    icons: [require("./WBTC.svg").default],
  }

  export const MOCK_sUSDT: TokenInfo = {
    id: Currency.sUSDT,
    description: "sUSDT",
    displayName: "sUSDT",
    precision: 4,
    propertyTags: [],
    icons: [require("./sUSDT.svg").default],
  }
}
