import { createContext, useContext } from "react"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import FarmStore from "./FarmStore"

const FarmStoreContext = createContext<FarmStore | null>(null)

export const FarmStoreProvider: FCC = props => {
  const appEnvStore = useAppEnvStore()
  const chainStore = useChainStore()
  const authStore = useAuthStore()
  const currencyStore = useCurrencyStore()
  const accountStore = useAccountStore()
  const store = useCreation(
    () =>
      new FarmStore(
        appEnvStore,
        authStore,
        currencyStore,
        accountStore,
        chainStore,
      ),
    [accountStore, appEnvStore, authStore, chainStore, currencyStore],
  )
  return (
    <FarmStoreContext.Provider value={store}>
      {props.children}
    </FarmStoreContext.Provider>
  )
}

export const useFarmStore = (): FarmStore => {
  const store = useContext(FarmStoreContext)
  if (store == null) {
    throw new Error(`useFarmStore must be used within a FarmStoreProvider`)
  }
  return store
}
