import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../../components/Card"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { LotteryTicket } from "../../types"
import { LotteryTicketCardGrid } from "../../_/LotteryTicketCardGrid"
import { PurpleBlock } from "../../_/PurpleBlock"
import { ReactComponent as AfterRegisteredIcon } from "./_/afterRegisteredImage.svg"

export const ActionPanel$DrawForJoined: FC<{
  startedAtBlock: number
  prizeToken: TokenInfo
  lotteryTickets: LotteryTicket[]
}> = props => {
  const { $t } = useIntl()

  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <AfterRegisteredIcon />
        <CardInsetTitle>
          {$t(
            defineMessage({
              defaultMessage: "You have joined this round, Lottery in drawing",
              description: "/Lottery/ActionPanel$DrawForJoined/Card title",
            }),
          )}
        </CardInsetTitle>
        <PurpleBlock
          primaryText={$t(
            defineMessage({
              defaultMessage: "Lottery drawing",
              description: "/Lottery/ActionPanel$DrawForJoined/Block text",
            }),
          )}
          secondaryText={
            <>
              {$t(
                defineMessage({
                  defaultMessage: "Draw at: Block {block}",
                  description: "/Lottery/ActionPanel$DrawForJoined/Block text",
                }),
                { block: props.startedAtBlock },
              )}
            </>
          }
        />
      </div>
      <CardInsetDivider />
      <CardInsetTitle>
        {$t(
          defineMessage({
            defaultMessage: "My Lottery Ticket",
            description: "/Lottery/ActionPanel$DrawForJoined/Card title text",
          }),
        )}
      </CardInsetTitle>
      <LotteryTicketCardGrid
        prizeToken={props.prizeToken}
        lotteryTickets={props.lotteryTickets}
      />
    </CardInset>
  )
}
