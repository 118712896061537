import { isEqual } from "lodash"
import { distinctUntilChanged, from, map, Observable, switchMap } from "rxjs"
import { components } from "../../../../generated/stxdx/types"
import { sendRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { currencyScale } from "../../../../utils/alexjs/currencyHelpers"
import { assertNever } from "../../../../utils/types"
import { isNotNull } from "../../../../utils/utils"
import {
  OpenOrderRecord,
  OrderDirection,
  StxDxOrderStatus,
  StxDxOrderType,
  TradeHistoryRecord,
  TradesHistoryRecordStatus,
  TriggerCondition,
} from "../../components/types"
import { refreshOrderHistorySignal } from "../stxdx_shared/orderbookHelpers"
import {
  OrderbookMarket,
  OrderbookMarketId,
} from "../stxdx_shared/StxDxMarket.service"

export type ServerOrderData = Omit<
  OpenOrderRecord.Common,
  "tradeToken" | "priceToken" | "onCancel"
> & {
  orderType: StxDxOrderType
  market: OrderbookMarket
  status: StxDxOrderStatus
  priceTokenCountPerTradeToken: number
  priceTokenTotalCount: number
  orderPriceTokenCountPerTradeToken: number
  priceTokenCountAverage: number
  triggerCondition?: TriggerCondition
}
export type ServerTradeOrderData = Omit<
  TradeHistoryRecord.Limit,
  "tradeToken" | "priceToken" | "onCancel"
> & {
  market: OrderbookMarket
}

export function getTradeHistory({
  auth,
  selectedMarketId,
  allMarkets,
}: {
  auth: string
  selectedMarketId?: OrderbookMarketId
  allMarkets: OrderbookMarket[]
}): Observable<ServerTradeOrderData[]> {
  return refreshOrderHistorySignal(auth).pipe(
    switchMap(() =>
      from(
        sendRequest(auth)("OrderController_getFills", {
          query: {
            market: selectedMarketId,
          },
        }),
      ),
    ),
    map(({ data: { fills } }) =>
      fills
        .map((order): ServerTradeOrderData | null => {
          const size = Number(order.size)
          const price = Number(order.price)
          const market = allMarkets.find(a => a.marketId === order.market)
          if (market == null) {
            return null
          }
          return {
            id: order.id.toString(),
            market,
            status: orderFillDTOStatusEnumToTradesStatus(order.status),
            orderType: StxDxOrderType.Limit,
            createdAt: new Date(Number(order.created_at)),
            executedTradeTokenCount: size,
            feePriceTokenCount: Number(order.sender_fee) / 1e8,
            orderDirection: order.side,
            priceTokenTotalCount: price * size,
            priceTokenCountPerTradeToken: price,
          }
        })
        .filter(isNotNull),
    ),
    distinctUntilChanged(isEqual),
  )
}

function orderFillDTOStatusEnumToTradesStatus(
  input: components["schemas"]["OrderFillDTO"]["status"],
): TradesHistoryRecordStatus {
  switch (input) {
    case "settled":
      return TradesHistoryRecordStatus.Succeed
    case "failed":
      return TradesHistoryRecordStatus.Failed
    case "assigned":
    case "fatal":
    case "generated":
    case "matched":
    case "pending":
    case "submitted":
      return TradesHistoryRecordStatus.Settling
    default:
      assertNever(input)
  }
}

export function getOrderHistory({
  auth,
  side,
  selectedMarketId,
  status,
  allMarkets,
  limit,
}: {
  auth: string
  selectedMarketId?: OrderbookMarketId
  side?: OrderDirection
  status?: StxDxOrderStatus
  allMarkets: OrderbookMarket[]
  limit?: number
}): Observable<ServerOrderData[]> {
  return refreshOrderHistorySignal(auth).pipe(
    switchMap(() =>
      from(
        sendRequest(auth)("OrderController_getOrders", {
          query: {
            market: selectedMarketId as any,
            side,
            limit: limit ?? 40,
            status,
          },
        }),
      ),
    ),
    map(({ data: { orders } }) => {
      return orders
        .map((order): ServerOrderData | null => {
          const market = allMarkets.find(a => a.marketId === order.market)
          if (market == null) {
            return null
          }
          const { priceToken } = market

          const size = Number(order.size)
          const settledPrice =
            Number(order.avg_settled_price) / currencyScale(priceToken)
          const orderPrice = Number(order.price) / currencyScale(priceToken)
          const price =
            isNaN(settledPrice) || settledPrice === 0
              ? orderPrice
              : settledPrice
          const stopPrice = Number(order.stop_price) / currencyScale(priceToken)
          const isStopLimit = !isNaN(stopPrice) && stopPrice !== 0
          return {
            orderHash: order.order_hash,
            market,
            status: order.status as unknown as StxDxOrderStatus,
            orderType:
              order.type === "vanilla"
                ? isStopLimit
                  ? StxDxOrderType.StopLimit
                  : StxDxOrderType.Limit
                : StxDxOrderType.Market,
            createdAt: new Date(Number(order.created_at)),
            expectedTradeTokenCount: size,
            filledPercentage: Number(order.filled) / Number(order.maximum_fill),
            filledPriceTokenCount:
              (price * Number(order.filled)) /
              Math.pow(10, 8 - market.pricePrecision),
            orderDirection: order.side,
            priceTokenTotalCount: price * size,
            priceTokenCountPerTradeToken: price,
            orderPriceTokenCountPerTradeToken: orderPrice,
            priceTokenCountAverage: price,
            triggerCondition: isStopLimit
              ? {
                  priceTokenCountPerTradeToken: stopPrice,
                  type:
                    order.side === "sell"
                      ? order.risk
                        ? "lte"
                        : "gte"
                      : order.risk
                      ? "gte"
                      : "lte",
                }
              : undefined,
          }
        })
        .filter(isNotNull)
    }),
    distinctUntilChanged(isEqual),
  )
}

export type ServerFundsHistoryRow = Pick<
  components["schemas"]["GetFundsResponse"]["history"][number],
  "type" | "status"
> & {
  amount: number
  assetId: number
  txId: string
  createdAt: Date
}

export async function getMyFundsHistory({
  uid,
  auth,
}: {
  uid: number
  auth: string
}): Promise<ServerFundsHistoryRow[]> {
  const {
    data: { history },
  } = await sendRequest(auth)("AccountController_getFundHistory", {
    path: {
      uid,
    },
    query: {
      limit: 40,
    },
  })

  return history.map(found => {
    const type = found.type
    const amount = Number(found.amount) / 1e8
    const status = found.status
    const txId = found.tx_id
    const assetId = Number(found.asset_id)
    const createdAt = new Date(Number(found.created_at) * 1000)
    return {
      type,
      amount,
      status,
      txId,
      assetId,
      createdAt,
    }
  })
}
