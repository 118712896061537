import { FC } from "react"
import { DepositRowData } from "../../../../store/transformerGroups/lend/deposit"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const DepositRow: FC<{
  rowData: DepositRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => (
  <BaseRow
    rowData={rowData}
    currencyCell={
      <OneLineCurrencyCell
        fromCurrencyAmounts={[rowData.fromCurrencyAmount]}
        toCurrencyAmounts={[rowData.toCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    amountCell={
      <OneLineAmountCell
        fromCurrencyAmounts={[rowData.fromCurrencyAmount]}
        toCurrencyAmounts={[rowData.toCurrencyAmount]}
        allTokenInfos={allTokenInfos}
      />
    }
    tradeInfoCell={<BaseCellContainer>#{rowData.expiry}</BaseCellContainer>}
  />
)
