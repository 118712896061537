import {
  booleanT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const stxdxWalletZero = defineContract({
  "stxdx-wallet-zero": {
    "approve-and-transfer-out": {
      input: [
        { name: "request-id", type: numberT },
        { name: "asset-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "approve-and-transfer-out-many": {
      input: [
        { name: "asset-trait", type: principalT },
        { name: "request-ids", type: listT(numberT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "approve-exchange": {
      input: [
        { name: "exchange", type: principalT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "approve-transfer-out": {
      input: [
        { name: "request-id", type: numberT },
        { name: "approved", type: booleanT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "request-transfer-out": {
      input: [
        { name: "amount", type: numberT },
        { name: "user-id", type: numberT },
        { name: "asset-id", type: numberT },
        { name: "asset", type: principalT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "request-transfer-out-many": {
      input: [
        { name: "user-id", type: numberT },
        { name: "amounts", type: listT(numberT) },
        { name: "asset-ids", type: listT(numberT) },
        { name: "assets", type: listT(principalT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(numberT))),
      mode: "public",
    },
    "set-authorised-approver": {
      input: [
        { name: "authorised", type: booleanT },
        { name: "sender", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "new-owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-request-grace-period": {
      input: [{ name: "new-grace-period", type: numberT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    transfer: {
      input: [
        { name: "amount", type: numberT },
        { name: "sender-id", type: numberT },
        { name: "recipient-id", type: numberT },
        { name: "asset-id", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-in": {
      input: [
        { name: "amount", type: numberT },
        { name: "user-id", type: numberT },
        { name: "asset-id", type: numberT },
        { name: "asset-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "transfer-in-many": {
      input: [
        { name: "user-id", type: numberT },
        { name: "amounts", type: listT(numberT) },
        { name: "asset-ids", type: listT(numberT) },
        { name: "asset-traits", type: listT(principalT) },
      ],
      output: responseSimpleT(listT(responseSimpleT(booleanT))),
      mode: "public",
    },
    "transfer-out": {
      input: [
        { name: "request-id", type: numberT },
        { name: "asset-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-request-grace-period": {
      input: [],
      output: responseSimpleT(numberT),
      mode: "readonly",
    },
    "get-request-or-fail": {
      input: [{ name: "request-id", type: numberT }],
      output: responseSimpleT(
        tupleT({
          amount: numberT,
          approved: booleanT,
          asset: principalT,
          "asset-id": numberT,
          "request-block": numberT,
          "transferred-block": numberT,
          "user-id": numberT,
        }),
      ),
      mode: "readonly",
    },
    "get-user-balance-or-default": {
      input: [
        { name: "user-id", type: numberT },
        { name: "asset-id", type: numberT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "is-approved-exchange": {
      input: [{ name: "exchange", type: principalT }],
      output: booleanT,
      mode: "readonly",
    },
    "authorised-approvers": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    "authorised-exchanges": {
      input: principalT,
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    requests: {
      input: numberT,
      output: optionalT(
        tupleT({
          amount: numberT,
          approved: booleanT,
          asset: principalT,
          "asset-id": numberT,
          "request-block": numberT,
          "transferred-block": numberT,
          "user-id": numberT,
        }),
      ),
      mode: "mapEntry",
    },
    "user-balance": {
      input: tupleT({ "asset-id": numberT, "user-id": numberT }),
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    "request-grace-period": { input: noneT, output: numberT, mode: "variable" },
    "request-nonce": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
