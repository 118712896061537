import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardDivider,
  CardInsetDescription,
} from "../../../../../../components/Card"
import {
  createInfoListItemDetail,
  createInfoListItemTitle,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../../components/InfoList"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenName } from "../../../../../../components/TokenName"
import { dontWrapObserver } from "../../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../../utils/models/TokenInfo"
import { Burn } from "../../../_/Burn"
import { PrizeWinningInfo } from "../types"

export const RoundResult: FC<{
  roundNumber: number
  token: TokenInfo
  firstPrize: PrizeWinningInfo
  secondPrize: PrizeWinningInfo
  thirdPrize: PrizeWinningInfo
  burnPrizeTokenCount: number
  rolloverPrizeTokenCount: number
}> = props => {
  const { $t } = useIntl()

  return (
    <>
      <CardInsetDescription className={"text-center"}>
        {$t(
          defineMessage({
            defaultMessage: "Round #{roundNumber} results:",
            description:
              "/Lottery/ActionPanelFinished/RoundResult/Card description",
          }),
          { roundNumber: props.roundNumber },
        )}
      </CardInsetDescription>
      <InfoList
        className={"flex-wrap"}
        direction={"row"}
        listItemDirection={"column"}
        listItemClassName={"flex-1 text-center"}
        InfoListItemTitle={createInfoListItemTitle({
          textClassName: "text-base text-gray-200",
        })}
        InfoListItemDetail={createInfoListItemDetail({
          textClassName: "text-xs text-gray-200/50",
        })}
      >
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "First Prize",
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Card info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            {$t<ReactNode>(
              defineMessage({
                defaultMessage: `<line>{tokenCount} {tokenName} each</line>
                    <line>{winningTicketCount} Winning Tickets</line>`,
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Card info list item detail",
              }),
              {
                tokenCount: (
                  <TokenCount
                    token={props.token}
                    count={props.firstPrize.prizeTokenCount}
                  />
                ),
                winningTicketCount: props.firstPrize.winningTicketCount,
                tokenName: <TokenName token={props.token} />,
                line: dontWrapObserver(children => <p>{children}</p>),
              },
            )}
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Second Prize",
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            {$t(
              defineMessage({
                defaultMessage: `<line>{tokenCount} {tokenName} each</line>
                  <line>{winningTicketCount} Winning Tickets</line>`,
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Info list item detail",
              }),
              {
                tokenCount: (
                  <TokenCount
                    token={props.token}
                    count={props.secondPrize.prizeTokenCount}
                  />
                ),
                tokenName: <TokenName token={props.token} />,
                winningTicketCount: props.secondPrize.winningTicketCount,
                line: dontWrapObserver(children => <p>{children}</p>),
              },
            )}
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Third Prize",
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Info list item title",
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            {$t(
              defineMessage({
                defaultMessage: `<line>{tokenCount} {tokenName} each</line>
                  <line>{winningTicketCount} Winning Tickets</line>`,
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Info list item detail",
              }),
              {
                tokenCount: (
                  <TokenCount
                    token={props.token}
                    count={props.thirdPrize.prizeTokenCount}
                  />
                ),
                tokenName: <TokenName token={props.token} />,
                winningTicketCount: props.thirdPrize.winningTicketCount,
                line: dontWrapObserver(children => <p>{children}</p>),
              },
            )}
          </InfoListItemDetail>
        </InfoListItem>
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "{icon} Burn",
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Info list item title",
              }),
              {
                icon: (
                  <Burn
                    className={"inline-block align-text-bottom"}
                    width={"1em"}
                    height={"1em"}
                  />
                ),
              },
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TokenCount token={props.token} count={props.burnPrizeTokenCount} />{" "}
            <TokenName token={props.token} />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>

      {props.rolloverPrizeTokenCount > 0 && (
        <>
          <CardDivider className={"w-full"} />
          <CardInsetDescription className={"text-center"}>
            {$t(
              defineMessage({
                defaultMessage: "{token} rollover to next round.",
                description:
                  "/Lottery/ActionPanelFinished/RoundResult/Card description",
              }),
              {
                token: (
                  <span className={"font-bold"}>
                    <TokenCount
                      token={props.token}
                      count={props.rolloverPrizeTokenCount}
                    />{" "}
                    <TokenName token={props.token} />
                  </span>
                ),
              },
            )}
          </CardInsetDescription>
        </>
      )}
    </>
  )
}
