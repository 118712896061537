import * as secp256k1 from "@noble/secp256k1"
import { hex } from "@scure/base"
import * as btc from "@scure/btc-signer"
import { BigNumber } from "../../../../../utils/BigNumber"
import {
  getAddressType,
  getP2TRInternalPublicKey,
} from "../../../../../utils/bitcoinHelpers"
import { DisplayableError } from "../../../../../utils/error"
import { isNotNull } from "../../../../../utils/utils"
import {
  BitcoinNetwork,
  UTXOBasic,
  UTXOSpendable,
  UTXOWithConfirmation,
} from "../BitcoinClient/BitcoinClient.types"

const bigNumberToBigInt = (n: BigNumber): bigint =>
  BigInt(BigNumber.toNumber(n))

export type GetUTXOSpendableFn = (
  utxo: UTXOWithConfirmation,
) => Promise<undefined | (UTXOSpendable & UTXOWithConfirmation)>

export interface InscriptionRecipient {
  inscriptionUtxo: UTXOWithConfirmation
  address: string
}

export async function createSendInscriptionTransaction(options: {
  network: BitcoinNetwork
  inscriptionRecipients: InscriptionRecipient[]
  availableFeeUtxos: UTXOWithConfirmation[]
  changeAddress: string
  feeRate: BigNumber
  getUTXOSpendable: GetUTXOSpendableFn
}): Promise<{
  tx: btc.Transaction
  inscriptionUtxoInputIndices: number[]
  bitcoinUtxoInputIndices: number[]
}> {
  const inscriptionUTXOSpendables = (
    await Promise.all(
      options.inscriptionRecipients.map(r =>
        options.getUTXOSpendable(r.inscriptionUtxo),
      ),
    )
  ).filter(isNotNull)

  const { newSelectedUnspentOutputs, changeAmount } = await getFee(
    options.availableFeeUtxos,
    inscriptionUTXOSpendables,
    BigNumber.sum(
      options.inscriptionRecipients.map(r => r.inscriptionUtxo.amount),
    ),
    options.inscriptionRecipients.map(r => ({
      address: r.address,
      amountSats: r.inscriptionUtxo.amount,
    })),
    options.changeAddress,
    options.feeRate,
    options.network,
    options.getUTXOSpendable,
    options.inscriptionRecipients.map(r => r.inscriptionUtxo),
  )

  const tx = new btc.Transaction()

  for (const utxo of newSelectedUnspentOutputs) {
    tx.addInput({
      txid: utxo.txId,
      index: utxo.index,
      witnessUtxo: {
        script: utxo.witnessUtxoScript,
        amount: bigNumberToBigInt(utxo.amount),
      },
      tapInternalKey: utxo.tapInternalKey,
      redeemScript: utxo.redeemScript,
      // Enable RBF
      sequence: btc.DEFAULT_SEQUENCE - 2,
    })
  }

  options.inscriptionRecipients.forEach(i => {
    tx.addOutputAddress(i.address, bigNumberToBigInt(i.inscriptionUtxo.amount))
  })
  if (BigNumber.isGtZero(changeAmount)) {
    tx.addOutputAddress(options.changeAddress, bigNumberToBigInt(changeAmount))
  }

  const inscriptionCount = options.inscriptionRecipients.length
  return {
    tx,
    inscriptionUtxoInputIndices: newSelectedUnspentOutputs
      .slice(0, inscriptionCount)
      .map((_, i) => i),
    bitcoinUtxoInputIndices: newSelectedUnspentOutputs
      .slice(inscriptionCount - 1)
      .map((_, i) => i + inscriptionCount),
  }
}

function getBtcNetwork(network: BitcoinNetwork): typeof btc.NETWORK {
  return network === "mainnet" ? btc.NETWORK : btc.TEST_NETWORK
}

interface Recipient {
  address: string
  amountSats: BigNumber
}

const MINIMUM_CHANGE_OUTPUT_SATS = 1000

async function getFee(
  unspentOutputs: Array<UTXOWithConfirmation>,
  selectedUnspentOutputs: Array<UTXOWithConfirmation & UTXOSpendable>,
  satsToSend: BigNumber,
  recipients: Array<Recipient>,
  changeAddress: string,
  feeRate: BigNumber,
  network: BitcoinNetwork,
  getUTXOSpendable: GetUTXOSpendableFn,
  pinnedOutputs?: UTXOWithConfirmation[],
): Promise<{
  newSelectedUnspentOutputs: Array<UTXOSpendable>
  fee: BigNumber
  changeAmount: BigNumber
}> {
  let newSelectedUnspentOutputs = selectedUnspentOutputs.slice()

  let lastSelectedUnspentOutputCount = newSelectedUnspentOutputs.length
  let sumSelectedOutputs = sumUnspentOutputs(newSelectedUnspentOutputs)

  // Calculate fee
  let calculatedFee = await calculateFee(
    newSelectedUnspentOutputs,
    satsToSend,
    recipients,
    changeAddress,
    feeRate,
    network,
  )

  let count = 0
  while (
    BigNumber.isLt(sumSelectedOutputs, BigNumber.add(satsToSend, calculatedFee))
  ) {
    const newSatsToSend = BigNumber.add(satsToSend, calculatedFee)

    // Select unspent outputs
    const _selectedUnspentOutputs = await selectUnspentOutputs(
      newSatsToSend,
      unspentOutputs,
      getUTXOSpendable,
      pinnedOutputs,
    )
    newSelectedUnspentOutputs = (
      await Promise.all(_selectedUnspentOutputs.map(getUTXOSpendable))
    ).filter(isNotNull)

    // Check if select output count has changed since last iteration
    // If it hasn't, there is insufficient balance
    if (!(newSelectedUnspentOutputs.length > lastSelectedUnspentOutputCount)) {
      throw new DisplayableError(
        "Insufficient Bitcoin balance with transaction fee",
      )
    }

    lastSelectedUnspentOutputCount = newSelectedUnspentOutputs.length
    sumSelectedOutputs = sumUnspentOutputs(newSelectedUnspentOutputs)

    // Re-calculate fee
    calculatedFee = await calculateFee(
      newSelectedUnspentOutputs,
      satsToSend,
      recipients,
      changeAddress,
      feeRate,
      network,
    )

    count++
    if (count > 500) {
      // Exit after max 500 iterations
      throw new DisplayableError(
        "Insufficient Bitcoin balance with transaction fee",
      )
    }
  }

  const changeAmount = BigNumber.minus(
    sumSelectedOutputs,
    BigNumber.sum([satsToSend, calculatedFee]),
  )

  return {
    newSelectedUnspentOutputs,
    fee: calculatedFee,
    changeAmount: BigNumber.isGt(changeAmount, MINIMUM_CHANGE_OUTPUT_SATS)
      ? changeAmount
      : BigNumber.ZERO,
  }
}

async function calculateFee(
  selectedUnspentOutputs: Array<UTXOSpendable>,
  satsToSend: BigNumber,
  recipients: Array<Recipient>,
  changeAddress: string,
  feeRate: BigNumber,
  network: BitcoinNetwork,
): Promise<BigNumber> {
  const dummyPrivateKey1 =
    "0000000000000000000000000000000000000000000000000000000000000001"
  const dummyPublicKey = secp256k1.getPublicKey(
    hex.decode(dummyPrivateKey1),
    true,
  )

  // Create transaction for estimation
  const tx = createTransaction(
    selectedUnspentOutputs.map((utxo): UTXOSpendable => {
      let address: string
      let witnessUtxoScript: Uint8Array
      let tapInternalKey: undefined | Uint8Array
      switch (getAddressType(network, utxo.address)) {
        case "p2pkh":
          const p2pkh = btc.p2pkh(dummyPublicKey, getBtcNetwork(network))
          address = p2pkh.address!
          witnessUtxoScript = p2pkh.script
          break
        case "p2wpkh":
          const p2wpkh = btc.p2wpkh(dummyPublicKey, getBtcNetwork(network))
          address = p2wpkh.address!
          witnessUtxoScript = p2wpkh.script
          break
        case "p2sh":
          const p2sh = btc.p2sh(
            btc.p2pkh(dummyPublicKey, getBtcNetwork(network)),
            getBtcNetwork(network),
          )
          address = p2sh.address!
          witnessUtxoScript = p2sh.script
          break
        case "p2wsh":
          const p2wsh = btc.p2wsh(
            btc.p2pkh(dummyPublicKey, getBtcNetwork(network)),
            getBtcNetwork(network),
          )
          address = p2wsh.address!
          witnessUtxoScript = p2wsh.script
          break
        case "p2tr":
          const p2tr = btc.p2tr(
            getP2TRInternalPublicKey(network, dummyPublicKey),
            undefined,
            getBtcNetwork(network),
          )
          address = p2tr.address!
          witnessUtxoScript = p2tr.script
          tapInternalKey = p2tr.tapInternalKey
          break
        case "unknown":
        default:
          throw new DisplayableError(
            `Not supported address type: ${utxo.address}`,
          )
      }

      return {
        ...utxo,
        address,
        tapInternalKey,
        witnessUtxoScript,
      }
    }),
    satsToSend,
    recipients,
    changeAddress,
    network,
  )

  selectedUnspentOutputs.forEach((utxo, i) => {
    tx.signIdx(hex.decode(dummyPrivateKey1), i)
  })
  tx.finalize()

  const txSize = tx.vsize

  return BigNumber.mul(feeRate, txSize)
}

function createTransaction(
  selectedUnspentOutputs: Array<UTXOSpendable>,
  totalSatsToSend: BigNumber,
  recipients: Array<Recipient>,
  changeAddress: string,
  network: BitcoinNetwork,
): btc.Transaction {
  // Create Bitcoin transaction
  const tx = new btc.Transaction()
  const btcNetwork = getBtcNetwork(network)

  // Calculate utxo sum
  const sumValue = sumUnspentOutputs(selectedUnspentOutputs)

  // Calculate change
  const changeSats = BigNumber.minus(sumValue, totalSatsToSend)

  // Add inputs
  selectedUnspentOutputs.forEach(utxo => {
    tx.addInput({
      txid: utxo.txId,
      index: utxo.index,
      witnessUtxo: {
        script: utxo.witnessUtxoScript,
        amount: bigNumberToBigInt(utxo.amount),
      },
      tapInternalKey: utxo.tapInternalKey,
      redeemScript: utxo.redeemScript,
    })
  })

  // Add outputs
  recipients.forEach(recipient => {
    tx.addOutputAddress(
      recipient.address,
      bigNumberToBigInt(recipient.amountSats),
      btcNetwork,
    )
  })

  // Add change output
  if (BigNumber.isGt(changeSats, MINIMUM_CHANGE_OUTPUT_SATS)) {
    tx.addOutputAddress(
      changeAddress,
      bigNumberToBigInt(changeSats),
      btcNetwork,
    )
  }

  return tx
}

function sumUnspentOutputs(unspentOutputs: Array<UTXOBasic>): BigNumber {
  return BigNumber.sum(unspentOutputs.map(utxo => utxo.amount))
}

export async function selectUnspentOutputs(
  amountSats: BigNumber,
  unspentOutputs: Array<UTXOWithConfirmation>,
  getUTXOSpendable: GetUTXOSpendableFn,
  pinnedOutputs?: UTXOWithConfirmation[],
): Promise<Array<UTXOWithConfirmation>> {
  const inputs: Array<UTXOWithConfirmation> = []
  let sumValue = BigNumber.ZERO

  unspentOutputs = unspentOutputs.slice()

  if (pinnedOutputs && pinnedOutputs.length > 0) {
    inputs.push(...pinnedOutputs)
    sumValue = BigNumber.sum([sumValue, ...pinnedOutputs.map(o => o.amount)])
  }

  // Sort UTXOs based on block height in ascending order
  unspentOutputs.sort((a, b) => {
    if (a.blockHeight && b.blockHeight) {
      return a.blockHeight - b.blockHeight
    } else if (a.blockHeight) {
      return 1
    } else if (b.blockHeight) {
      return -1
    } else {
      return BigNumber.toNumber(BigNumber.minus(a.amount, b.amount))
    }
  })

  for (const unspentOutput of unspentOutputs) {
    if (BigNumber.isGte(sumValue, amountSats)) {
      break
    }

    const spendable = await getUTXOSpendable(unspentOutput)
    if (spendable != null) {
      inputs.push(unspentOutput)
      sumValue = BigNumber.add(sumValue, unspentOutput.amount)
    }
  }

  return inputs
}
