import {
  action,
  IReactionOptions,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
} from "mobx"
import { from, Observable } from "rxjs"
import { defer, isPromiseLike } from "../../utils/promiseHelpers"
import type {
  LazyValueState,
  LazyValueState$Refreshing,
} from "./_/LazyValueState"

export interface SharedLazyValue<Value> {
  value$: Value

  triggerUpdate(): Promise<void>
}

export type FetchValueFn<P, V> = (params: P) => V

export type FetchValueFnDecorator<P, V> = (
  fetchValue: FetchValueFn<P, V>,
) => FetchValueFn<P, V>

export type LazyValueInside<LV extends LazyValue<any, any>> =
  LV extends LazyValue<infer R, any> ? UnwrappedLazyValue<R> : never

// prettier-ignore
export type UnwrappedLazyValue<T> =
  T extends Observable<infer R> ? R :
  T extends Promise<infer R> ? R :
  never

export class LazyValue<
  WrappedValue extends Promise<any> | Observable<any>,
  Params,
> {
  @observable.ref private state: LazyValueState<
    UnwrappedLazyValue<WrappedValue>
  > = { type: "idle", defer: defer() }

  private readonly trace: (msg: string, ...extraArgs: any[]) => void

  get value(): undefined | UnwrappedLazyValue<WrappedValue> {
    if (this.state.type === "idle") {
      return undefined
    }
    if (this.state.type === "refreshing") {
      return this.state.keptValue
    }
    if (this.state.type === "error") {
      return undefined
    }
    return this.state.value
  }

  get steadyValue$(): UnwrappedLazyValue<WrappedValue> {
    if (this.state.type === "idle") {
      throw this.state.defer.promise
    }
    if (this.state.type === "refreshing") {
      return this.state.keptValue
    }
    if (this.state.type === "error") {
      throw this.state.error
    }
    return this.state.value
  }

  get value$(): UnwrappedLazyValue<WrappedValue> {
    if (this.state.type === "idle") {
      throw this.state.defer.promise
    }
    if (this.state.type === "refreshing") {
      if (this.state.dependOnPromise) {
        throw this.state.dependOnPromise
      } else {
        return this.state.keptValue
      }
    }
    if (this.state.type === "error") {
      throw this.state.error
    }
    return this.state.value
  }

  get immediateValue$(): UnwrappedLazyValue<WrappedValue> {
    if (this.state.type === "idle") {
      throw this.state.defer.promise
    }
    if (this.state.type === "refreshing") {
      if (this.state.dependOnPromise) {
        throw this.state.dependOnPromise
      } else {
        throw this.state.promise
      }
    }
    if (this.state.type === "error") {
      throw this.state.error
    }
    return this.state.value
  }

  get isRefreshing(): boolean {
    return this.state.type === "refreshing"
  }

  private readonly fetchValue: FetchValueFn<Params, WrappedValue>

  constructor(
    private readonly paramsGetter$: () => Params,
    fetchValue: FetchValueFn<Params, WrappedValue>,
    options?: {
      reactionOptions?: IReactionOptions<Params, boolean>
      decorator?: FetchValueFnDecorator<Params, WrappedValue>
      debug?: boolean | string
    },
  ) {
    makeObservable(this)

    if (options?.decorator) {
      this.fetchValue = options.decorator(fetchValue)
    } else {
      this.fetchValue = fetchValue
    }

    this.trace = (msg: string, ...extraArgs: any[]): void => {
      if (options?.debug) {
        console.log(`${options?.debug || "LazyValue"} ${msg}`, ...extraArgs)
      }
    }

    this.trace("constructor")

    let dispose: () => void
    onBecomeObserved(this, "state", async () => {
      this.trace("become observed")
      dispose = reaction(
        () => this.paramsGetter$(),
        params => {
          this.trace("params changed", params)
          void this.updateValue(params)
        },
        {
          ...options?.reactionOptions,
          fireImmediately: false,
          onError: error => {
            if (isPromiseLike(error)) {
              this.onStartRefresh(error, undefined)
            }
          },
        },
      )
      // We need awaitOnThrownPromise to catch the cases
      // where this.paramsGetter$() throws a promise
      // and we need to await on it to reinitialize
      void awaitOnThrownPromise(() => this.triggerUpdate()).catch(
        action(e => {
          this.state = { type: "error", error: e }
        }),
      )
    })
    onBecomeUnobserved(this, "state", () => {
      this.trace("become unobserved")
      this.unsubscribeLatestFetchValueCall?.("unobserve")
      dispose?.()
    })
  }

  private unsubscribeLatestFetchValueCall?: (
    scene: "cleanup" | "unobserve",
  ) => void
  private updateValue = (params: Params): Promise<void> => {
    this.trace("update value", params)

    this.unsubscribeLatestFetchValueCall?.("cleanup")

    const source = this.ensureObservable(this.fetchValue(params))

    const handleResult = this.updateValueFromObservable(source)

    this.unsubscribeLatestFetchValueCall = handleResult.unsubscribe
    return handleResult.promise
  }

  private ensureObservable(
    source: WrappedValue,
  ): Observable<UnwrappedLazyValue<WrappedValue>> {
    return isPromiseLike(source) ? from(source) : source
  }

  private updateValueFromObservable(
    observable: Observable<UnwrappedLazyValue<WrappedValue>>,
  ): FetchValueSourceHandleResult {
    const deferred = defer()
    this.trace("subscribed")
    this.onStartRefresh(undefined, deferred.promise)
    const sub = observable.subscribe({
      next: value => {
        this.trace("next", value)
        this.onReceiveValue(value)
        deferred.resolve()
      },
      error: err => {
        this.trace("error", err)
        this.onEncounterError(err)
        deferred.reject(err)
      },
    })
    return {
      promise: deferred.promise,
      unsubscribe: (scene: "cleanup" | "unobserve") => {
        if (scene === "cleanup") {
          deferred.resolve()
          sub.unsubscribe()
        } else if (scene === "unobserve") {
          // we want to wait for the first value filled the state before unsubscribing
          deferred.promise.finally(() => {
            sub.unsubscribe()
          })
        }
      },
    }
  }

  @action private onReinitialize(): void {
    if (this.state.type !== "idle") {
      this.state = { type: "idle", defer: defer() }
    }
  }

  private onStartRefresh(
    _dependOnPromise: undefined,
    _promise: PromiseLike<void>,
  ): void
  private onStartRefresh(
    _dependOnPromise: PromiseLike<void>,
    _promise: undefined,
  ): void
  @action private onStartRefresh(
    _dependOnPromise?: PromiseLike<void>,
    _promise?: PromiseLike<void>,
  ): void {
    if (this.state.type === "value") {
      this.state = genState(this.state.value)
    } else if (this.state.type === "refreshing") {
      this.state = genState(this.state.keptValue)
    }

    function genState(
      keptValue: UnwrappedLazyValue<WrappedValue>,
    ): LazyValueState$Refreshing<UnwrappedLazyValue<WrappedValue>> {
      if (_dependOnPromise) {
        return {
          type: "refreshing",
          keptValue,
          dependOnPromise: ensurePromise(_dependOnPromise),
          promise: undefined,
        }
      } else if (_promise) {
        return {
          type: "refreshing",
          keptValue,
          dependOnPromise: undefined,
          promise: ensurePromise(_promise),
        }
      } else {
        throw new Error("[LazyValue#onStartRefresh] unexpected arguments")
      }
    }

    function ensurePromise(p: PromiseLike<void>): Promise<void> {
      return new Promise<void>((r, j) => p.then(r, j))
    }
  }

  @action private onReceiveValue(
    value: UnwrappedLazyValue<WrappedValue>,
  ): void {
    this.trace("received value", value)
    if (this.state.type === "idle") {
      this.state.defer.resolve()
    }
    this.state = { type: "value", value }
  }

  @action private onEncounterError(error: any): void {
    this.trace("encountered error", error)
    if (this.state.type === "idle") {
      this.state.defer.resolve()

      /**
       * only goto error state if previous state is idle
       * we don't want UI to go from success to error on updates like block height change
       */
      this.state = { type: "error", error }
    }
    if (this.state.type === "refreshing") {
      this.state = {
        type: "value",
        value: this.state.keptValue,
      }
    }
  }

  async triggerUpdate(): Promise<void> {
    this.trace("trigger update")
    await this.updateValue(this.paramsGetter$())
  }
}

interface FetchValueSourceHandleResult {
  promise: Promise<void>
  unsubscribe: (scene: "cleanup" | "unobserve") => void
}

async function awaitOnThrownPromise(
  action: () => void | Promise<void>,
): Promise<void> {
  try {
    await action()
  } catch (error) {
    if (error instanceof Promise) {
      error.finally(() => awaitOnThrownPromise(action))
    } else {
      throw error
    }
  }
}
