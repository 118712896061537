import { FC, useState } from "react"
import { useIntl } from "react-intl"
import { useMessage } from "../../../components/message/MessageProvider"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { MarketOrderForm } from "../../Orderbook/components/CreateOrder/MarketOrderForm"
import { StopLimitOrderForm } from "../../Orderbook/components/CreateOrder/StopLimitOrderForm"
import { StxDxOrderType } from "../../Orderbook/components/types"
import { useOrderbookStore } from "../../Orderbook/store/useOrderbookStore"
import { CreateOrderFrame } from "../components/CreateOrder/CreateOrderFrame"
import { LimitOrderForm } from "../components/CreateOrder/LimitOrderForm"
import { usePerpetualStore } from "../store/usePerpetualStore"

const WiredLimitOrderForm: FC = () => {
  const store = usePerpetualStore()
  return (
    <LimitOrderForm
      formError={suspenseResource(() =>
        store.trade.formData$.type === "error"
          ? store.trade.formData$.payload
          : store.trade.formData$.payload.warning,
      )}
      outgoingToken={store.trade.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => store.trade.outgoingTokenBalance,
      )}
      priceToken={store.currency.getTokenInfo$(store.trade.priceToken)}
      tradeToken={store.currency.getTokenInfo$(store.trade.tradeToken)}
      perTradeTokenPrice={suspenseResource(
        () => store.trade.perTradeTokenPrice$,
      )}
      tradeTokenCount={suspenseResource(
        () => store.trade.getTradeAmount() ?? null,
      )}
      onTradeTokenCountChange={count =>
        count == null
          ? store.trade.emptyTradeAmount()
          : store.trade.setTradeAmount(count)
      }
      tradeTokenCountPercentage={suspenseResource(
        () => store.trade.tradePercentage$,
      )}
      onPerTradeTokenPriceChange={a => {
        store.trade.setCustomPrice(a ?? undefined)
      }}
      onTradeTokenCountPercentageChange={p => store.trade.setPercentage(p)}
      perTradeTokenPricePrecision={store.trade.priceTokenPrecision$}
    />
  )
}

const WiredMarketOrderForm: FC = () => {
  const store = useOrderbookStore()
  const [inputMode, setInputMode] = useState<"amount" | "total">("amount")

  const priceToken = store.currency.getTokenInfo$(
    store.buyFormModule.priceToken$,
  )
  const tradeToken = store.currency.getTokenInfo$(
    store.buyFormModule.tradeToken$,
  )
  return (
    <MarketOrderForm
      formError={suspenseResource(() =>
        store.buyFormModule.formData$.type === "error"
          ? store.buyFormModule.formData$.payload
          : store.buyFormModule.formData$.payload.warning,
      )}
      outgoingToken={store.buyFormModule.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => store.buyFormModule.outgoingTokenBalance,
      )}
      priceToken={priceToken}
      tradeToken={tradeToken}
      pieceToken={inputMode === "amount" ? tradeToken : priceToken}
      pieceTokenCount={suspenseResource(() =>
        inputMode === "amount"
          ? store.buyFormModule.getTradeAmount() ?? 0
          : store.buyFormModule.priceTokenCount$,
      )}
      onPieceTokenCountPercentageChange={p =>
        store.buyFormModule.setPercentage(p)
      }
      onPieceTokenCountChange={count =>
        inputMode === "amount"
          ? count == null
            ? store.buyFormModule.emptyTradeAmount()
            : store.buyFormModule.setTradeAmount(count)
          : store.buyFormModule.setTotal(count ?? undefined)
      }
      onPieceTokenChange={token =>
        setInputMode(token === tradeToken ? "amount" : "total")
      }
      pieceTokenCountPercentage={store.buyFormModule.tradePercentage$}
    />
  )
}

const WiredStopLimitOrderForm: FC = () => {
  const store = useOrderbookStore()
  return (
    <StopLimitOrderForm
      formError={suspenseResource(() =>
        store.buyFormModule.formData$.type === "error"
          ? store.buyFormModule.formData$.payload
          : store.buyFormModule.formData$.payload.warning,
      )}
      outgoingToken={store.buyFormModule.outgoingTokenInfo$}
      maxAvailableOutgoingTokenCount={suspenseResource(
        () => store.buyFormModule.outgoingTokenBalance,
      )}
      priceToken={store.currency.getTokenInfo$(store.buyFormModule.priceToken$)}
      tradeToken={store.currency.getTokenInfo$(store.buyFormModule.tradeToken$)}
      perTradeTokenLimitPrice={suspenseResource(
        () => store.buyFormModule.perTradeTokenPrice$,
      )}
      tradeTokenCount={suspenseResource(
        () => store.buyFormModule.getTradeAmount() ?? null,
      )}
      onTradeTokenCountChange={count =>
        count == null
          ? store.buyFormModule.emptyTradeAmount()
          : store.buyFormModule.setTradeAmount(count)
      }
      totalTradeTokenPrice={suspenseResource(
        () => store.buyFormModule.priceTokenCount$,
      )}
      tradeTokenCountPercentage={suspenseResource(
        () => store.buyFormModule.tradePercentage$,
      )}
      onPerTradeTokenLimitPriceChange={a => {
        store.buyFormModule.setCustomPrice(a ?? undefined)
      }}
      onTotalTradeTokenPriceChange={t =>
        store.buyFormModule.setTotal(t ?? undefined)
      }
      onTradeTokenCountPercentageChange={p =>
        store.buyFormModule.setPercentage(p)
      }
      perTradeTokenStopPrice={suspenseResource(
        () => store.buyFormModule.stopPrice.read$,
      )}
      onPerTradeTokenStopPriceChange={p =>
        store.buyFormModule.stopPrice.set(p ?? undefined)
      }
      perTradeTokenPricePrecision={store.buyFormModule.priceTokenPrecision$}
    />
  )
}

export const WiredCreateOrder: FC<{ className?: string }> = props => {
  const store = usePerpetualStore()
  const { showWalletSelector: doAuth } = useAuthStore()
  const { $t } = useIntl()
  const message = useMessage()
  // TODO:
  const mockData = {
    longOrderInfo: {
      positionSize: 15.621,
      maxPositionSize: 100,
      cost: 2.439,
    },
    shortOrderInfo: {
      positionSize: 15.621,
      maxPositionSize: 100,
      cost: 2.439,
    },
  }

  return (
    <>
      <CreateOrderFrame
        className={props.className}
        quotaToken={store.orderbook.priceTokenInfo$}
        orderType={store.trade.orderType}
        onChangeOrderType={type => store.trade.setOrderType(type)}
        leverage={store.trade.leverage}
        onChangeLeverage={leverage => store.trade.setLeverage(leverage)}
        feeRates={store.trade.feeRates$}
        longOrderInfo={mockData.longOrderInfo}
        shortOrderInfo={mockData.shortOrderInfo}
        onConnectWallet={doAuth}
        onSubmit={async () => {
          await store.trade.trade()
          // const data = Result.maybeValue(store.trade.formData$)
          // if (data == null) return
          // try {
          //   await store.trade.trade(data)
          //   message.show(({ close }) => ({
          //     message: (
          //       <MessageItem
          //         icon={<SuccessIcon />}
          //         title={$t(orderCreatedNotificationTitle, {
          //           orderType: data.orderType,
          //           orderDirection: data.side,
          //         })}
          //         content={$t(orderCreatedNotificationBody, {
          //           orderType: data.orderType,
          //           orderDirection: data.side,
          //           receivingTokenCount: (
          //             <TokenCount
          //               token={data.incomingToken}
          //               count={data.size}
          //             />
          //           ),
          //           receivingTokenName: (
          //             <TokenName token={data.incomingToken} />
          //           ),
          //           sendingTokenName: <TokenName token={data.outgoingToken} />,
          //           executedPercentage: <PercentNumber number={0} />,
          //         })}
          //         onClose={close}
          //       />
          //     ),
          //   }))
          // } catch (e) {
          //   if (e instanceof CancelError) {
          //     return
          //   }
          //   console.error(e)
          //   message.show(({ close }) => ({
          //     message: (
          //       <MessageItem
          //         icon={<ErrorIcon />}
          //         title={$t(orderCreateFailedNotificationTitle, {
          //           orderType: data.orderType,
          //           orderDirection: data.side,
          //         })}
          //         content={(e as Error).message}
          //         onClose={close}
          //       />
          //     ),
          //   }))
          // }
        }}
      >
        {store.trade.orderType === StxDxOrderType.Limit && (
          <WiredLimitOrderForm />
        )}
        {store.trade.orderType === StxDxOrderType.Market && (
          <WiredMarketOrderForm />
        )}
        {store.trade.orderType === StxDxOrderType.StopLimit && (
          <WiredStopLimitOrderForm />
        )}
      </CreateOrderFrame>
    </>
  )
}
