import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../components/Card"
import {
  createInfoListItemTitle,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemDetailProps,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { normal } from "../buttonPresets"
import { RectButton } from "../wrappedCommonComponents/RectButton"

const StyledInfoListTitle = createInfoListItemTitle({
  textClassName: "text-xs leading-4 font-medium text-gray-400",
})

export const PerpetualAccountContent: FC<{
  gapClassName?: string

  totalBalance: SuspenseResource<number>
  totalBalancePnlInTokenCount?: SuspenseResource<number>
  totalBalancePnlInPercentage?: SuspenseResource<number>

  quotaToken: TokenInfo
  quotaTokenAvailableCount: SuspenseResource<number>
  unrealizedPnl: SuspenseResource<number>

  onDeposit: () => void
  onWithdraw: SuspenseResource<(() => void) | null>
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <InfoList
        gapClassName={props.gapClassName}
        direction={"column"}
        listItemDirection={"row-responsive"}
        InfoListItemTitle={StyledInfoListTitle}
      >
        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Balance",
                description: `Perpetual/Perpetual Account Panel/field label`,
              }),
            )}
          </InfoListItemTitle>
          <SpensorInfoListItemDetail>
            {() => (
              <>
                ~${" "}
                <TokenCount
                  token={TokenInfoPresets.USD}
                  count={readResource(props.totalBalance)}
                />
              </>
            )}
          </SpensorInfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Available",
                description: `Perpetual/Perpetual Account Panel/field label`,
              }),
            )}
          </InfoListItemTitle>
          <SpensorInfoListItemDetail>
            {() => (
              <>
                <TextTokenCount
                  token={props.quotaToken}
                  count={readResource(props.quotaTokenAvailableCount)}
                />
              </>
            )}
          </SpensorInfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Unrealized PnL",
                description: `Perpetual/Perpetual Account Panel/field label`,
              }),
            )}
          </InfoListItemTitle>
          <SpensorInfoListItemDetail>
            {() => (
              <>
                <TextTokenCount
                  token={props.quotaToken}
                  count={readResource(props.unrealizedPnl)}
                />
              </>
            )}
          </SpensorInfoListItemDetail>
        </InfoListItem>
      </InfoList>

      <Spensor>
        {() =>
          readResource(props.quotaTokenAvailableCount) <= 0 && (
            <CardPlate boxClassName={"p-2.5"}>
              <NoteParagraph
                textSizingClassName={"text-xs leading-4 font-normal"}
              >
                {$t(
                  defineMessage({
                    defaultMessage:
                      "Please transfer assets into your trading account to start trading.",
                    description: `Perpetual/Perpetual Account Panel/tip text`,
                  }),
                )}
              </NoteParagraph>
            </CardPlate>
          )
        }
      </Spensor>

      <div className={"flex gap-2.5"}>
        <RectButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          {...normal}
          onClick={props.onDeposit}
        >
          {$t(
            defineMessage({
              defaultMessage: "Deposit",
              description: `Perpetual/Perpetual Account Panel/"Deposit" button text`,
            }),
          )}
        </RectButton>

        <Spensor>
          {() => {
            const onClick = readResource(props.onWithdraw)
            if (onClick == null) {
              return null
            }
            return (
              <RectButton
                className={"flex-1"}
                {...normal}
                Variant={WhiteFilledButton}
                onClick={onClick}
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Withdraw",
                    description: `Perpetual/Perpetual Account Panel/"Withdraw" button text`,
                  }),
                )}
              </RectButton>
            )
          }}
        </Spensor>
      </div>
    </>
  )
}

const SpensorInfoListItemDetail: FC<
  InfoListItemDetailProps & {
    children: () => ReactNode
  }
> = props => (
  <InfoListItemDetail {...props}>
    <Spensor fallback={"-"}>{props.children}</Spensor>
  </InfoListItemDetail>
)
