import { FC, useCallback, useState } from "react"
import { AlertLayoutAsBanner } from "../../../components/alert/AlertLayoutAsBanner"
import { Spensor } from "../../../components/Spensor"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { useCopywriting } from "./CopywritingProvider"

export const WiredAPowerAlert: FC<{
  className?: string
}> = props => {
  const copy = useCopywriting()

  const [, rerenderComponent] = useState(0)

  const storageKey = `WiredAPowerBanner-isClosed`
  const isClosed = localStorage.getItem(storageKey)
  const onClose = useCallback(() => {
    localStorage.setItem(storageKey, "true")
    rerenderComponent(n => n + 1)
  }, [storageKey])

  if (isClosed) {
    return null
  }

  return (
    <Spensor>
      {() => (
        <AlertLayoutAsBanner
          className={props.className}
          icon={<TokenIcon token={TokenInfoPresets.APower} size={24} />}
          onClose={onClose}
        >
          {copy.aPowerAlertContent}
        </AlertLayoutAsBanner>
      )}
    </Spensor>
  )
}
