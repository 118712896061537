import clsx from "clsx"
import { CSSProperties } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { ButtonVariantProps } from "./ButtonVariant"

export interface HeadlessButtonProps extends ButtonVariantProps {
  style?: CSSProperties
}

/**
 * Basic component for buttons.
 * Can be used as text button.
 */
export const HeadlessButton: FCC<HeadlessButtonProps> = props => {
  return (
    <div
      className={clsx(
        props.disabled && "pointer-events-none",
        props.onClick && "cursor-pointer",
        props.className,
        props.boxClassName,
        props.textClassName ?? "text-center",
      )}
      onClick={props.onClick}
      role={"button"}
      aria-disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </div>
  )
}
