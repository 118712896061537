import clsx from "clsx"
import { FC } from "react"

export interface DividerProps {
  className?: string

  /**
   * @default horizontal
   */
  direction?: "horizontal" | "vertical"
}

export const Divider: FC<DividerProps> = props => {
  return (
    <hr
      className={clsx(
        `flex clear-both self-stretch`,
        props.direction === "vertical"
          ? "h-[auto] border-l"
          : "w-full min-w-full border-t",
        props.className,
      )}
    />
  )
}
