import { FungibleConditionCode } from "@stacks/transactions"
import BigNumber from "bignumber.js"
import { unwrapResponse } from "clarity-codegen"
import { BigNumber as EtherBigNumber, utils } from "ethers"
import { Observable, of } from "rxjs"
import { BridgeEndpoint } from "../../../generated/evmContract"
import { ERC20 } from "../../../generated/evmContract/ERC20"
import { ContractCallSender } from "../../../generated/smartContractHelpers/asSender"
import { sendRequest } from "../../../generated/wrapBridgeHelpers/wrapBridgeApi"
import { currencyScale } from "../../../utils/alexjs/currencyHelpers"
import { transfer } from "../../../utils/alexjs/postConditions"
import pMemoize from "../../../utils/pMemorize"
import { props } from "../../../utils/promiseHelpers"
import { BridgeChain, SupportedOppositeBridgeChain } from "../types/BridgeChain"
import {
  contractAssignedChainIdFromBridgeChain,
  mapBridgeChainToAPIBridgeChain,
} from "../types/BridgeChainHelpers"
import {
  BridgeCurrency,
  UnwrappableBridgeCurrency,
  WrappableBridgeCurrency,
} from "./utils/BridgeCurrency"

export const waitBlocksForWrapping = 8
export const waitBlocksForUnwrapping = 8

export function isWalletAddressInWhitelist(
  _walletAddress: string,
): Observable<boolean> {
  return of(true)
}

export function fromContractToNative(
  currency: BridgeCurrency,
  contractValue: EtherBigNumber,
): number {
  return new BigNumber(contractValue.toString()).div(1e18).toNumber()
}

export function fromNativeToContract(
  currency: BridgeCurrency,
  nativeValue: number,
): EtherBigNumber {
  return EtherBigNumber.from(
    new BigNumber(nativeValue)
      .multipliedBy(1e18)
      .toFixed(0, BigNumber.ROUND_DOWN),
  )
}

export interface TransferProphet {
  costedMilliseconds: number
  feeToken: BridgeCurrency
  feeRate: number
  minFeeAmount: number
  minAmount: null | number
  maxAmount: null | number
  waitBlocks: number
}

interface CurrencyBridgingInfo {
  feeRate: number
  minFeeAmount: number
  maxAmount: null | number
  minAmount: null | number
}

const getUnwrapCurrencyBridgingInfo = pMemoize(
  async (
    sender: ContractCallSender,
    currency: UnwrappableBridgeCurrency,
    chainId: number,
  ): Promise<CurrencyBridgingInfo> => {
    const resp = await sender
      .contract("bridge-endpoint-v1-02")
      .func("get-approved-token-or-fail")
      .call({ token: currency })
      .then(unwrapResponse)
    const tokenId = await sender
      .contract("bridge-endpoint-v1-02")
      .func("get-approved-token-id-or-fail")
      .call({
        token: currency,
      })
      .then(unwrapResponse)
    const minFeeAmount = await sender
      .contract("bridge-endpoint-v1-02")
      .func("get-min-fee-or-default")
      .call({
        "the-token-id": tokenId,
        "the-chain-id": chainId,
      })
    return {
      feeRate: resp.fee / currencyScale(currency),
      minFeeAmount: minFeeAmount / currencyScale(currency),
      maxAmount:
        resp["max-amount"] === 0
          ? null
          : resp["max-amount"] / currencyScale(currency),
      minAmount:
        resp["min-amount"] === 0
          ? null
          : resp["min-amount"] / currencyScale(currency),
    }
  },
  { skipCaching: true },
)

const getWrapCurrencyBridgingInfo = pMemoize(
  async (
    bridgeContract: BridgeEndpoint,
    currencyContract: ERC20,
    currency: WrappableBridgeCurrency,
  ): Promise<CurrencyBridgingInfo> => {
    return props({
      feeRate: bridgeContract
        .feePctPerToken(currencyContract.address)
        .then(n => fromContractToNative(currency, n)),
      minFeeAmount: bridgeContract
        .minFeePerToken(currencyContract.address)
        .then(n => fromContractToNative(currency, n)),
      minAmount: bridgeContract
        .minAmountPerToken(currencyContract.address)
        .then(n => (n.isZero() ? null : fromContractToNative(currency, n))),
      maxAmount: bridgeContract
        .maxAmountPerToken(currencyContract.address)
        .then(n => (n.isZero() ? null : fromContractToNative(currency, n))),
    })
  },
  { skipCaching: true },
)

const getAvgCostMs = pMemoize(
  async (from: string, to: string): Promise<number> => {
    const resp = await sendRequest(
      "TokenBridgeController_getAvgSecondsToWrap",
      {
        query: { from, to },
      },
    )
    return resp.data.avg_seconds_to_wrap * 1000
  },
  { skipCaching: true },
)

export const createUnwrappableTransferProphet = async (
  sender: ContractCallSender,
  toNetwork: SupportedOppositeBridgeChain,
  fromCurrency: UnwrappableBridgeCurrency,
): Promise<TransferProphet> => {
  const from = mapBridgeChainToAPIBridgeChain(BridgeChain.Stacks)
  if (from == null) {
    throw new Error(`Unsupported source chain: ${BridgeChain.Stacks}`)
  }

  const to = mapBridgeChainToAPIBridgeChain(toNetwork)
  if (to == null) {
    throw new Error(`Unsupported destination chain: ${toNetwork}`)
  }

  const [costedMs, info] = await Promise.all([
    getAvgCostMs(from, to),
    getUnwrapCurrencyBridgingInfo(
      sender,
      fromCurrency,
      contractAssignedChainIdFromBridgeChain(toNetwork),
    ),
  ])
  return {
    costedMilliseconds: costedMs,
    feeToken: fromCurrency,
    feeRate: info.feeRate,
    minAmount: info.minAmount,
    maxAmount: info.maxAmount,
    minFeeAmount: info.minFeeAmount,
    waitBlocks: waitBlocksForUnwrapping,
  }
}

export const createWrappableTransferProphet = async (
  bridgeContract: BridgeEndpoint,
  currencyContract: ERC20,
  fromNetwork: SupportedOppositeBridgeChain,
  fromCurrency: WrappableBridgeCurrency,
): Promise<TransferProphet> => {
  const from = mapBridgeChainToAPIBridgeChain(fromNetwork)
  if (from == null) {
    throw new Error(`Unsupported source chain: ${fromNetwork}`)
  }

  const to = mapBridgeChainToAPIBridgeChain(BridgeChain.Stacks)
  if (to == null) {
    throw new Error(`Unsupported destination chain: ${BridgeChain.Stacks}`)
  }

  const [costedMs, info] = await Promise.all([
    getAvgCostMs(from, to),
    getWrapCurrencyBridgingInfo(bridgeContract, currencyContract, fromCurrency),
  ])
  return {
    costedMilliseconds: costedMs,
    feeToken: fromCurrency,
    feeRate: info.feeRate,
    minAmount: info.minAmount,
    maxAmount: info.maxAmount,
    minFeeAmount: info.minFeeAmount,
    waitBlocks: waitBlocksForWrapping,
  }
}

export const wrapAsStacksToken = async (
  bridgeContract: BridgeEndpoint,
  currencyContract: ERC20,
  currency: WrappableBridgeCurrency,
  ethAddress: string,
  stxAddress: string,
  amount: number,
): Promise<string> => {
  const resp = await bridgeContract.transferToWrap(
    currencyContract.address,
    fromNativeToContract(currency, amount),
    stxAddress,
  )

  await resp.wait()

  return resp.hash
}

export const unwrapStacksToken = async (
  sender: ContractCallSender,
  contractAssignedTargetChainId: number,
  currency: UnwrappableBridgeCurrency,
  stxAddress: string,
  ethAddress: string,
  amount: number,
): Promise<string> => {
  const resp = await sender
    .contract("bridge-endpoint-v1-02")
    .func("transfer-to-unwrap")
    .call(
      {
        "token-trait": currency,
        "amount-in-fixed": amount * currencyScale(currency),
        "the-chain-id": contractAssignedTargetChainId,
        "settle-address": utils.arrayify(ethAddress),
      },
      [transfer(stxAddress, currency, amount, FungibleConditionCode.Equal)],
    )

  return resp.txId
}
