import { memoize, sum, uniq } from "lodash"
import { computed, makeObservable } from "mobx"
import { computedFn } from "mobx-utils"
import {
  ALLOW_CONTRACT_ARGUMENTATION,
  CONTRACT_DEPLOYER,
} from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { sendPublicRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { assetIdentifierEquals } from "../../../../utils/addressHelpers"
import { isCurrencyOrB20 } from "../../../../utils/alexjs/Currency"
import { OrderbookAsset } from "../OrderbookStore.service/OrderbookStore.service"
import {
  MarketSummary,
  fetchAllMarketSummary,
  fetchAllMarkets,
  fetchMarketTotalVolume,
} from "./StxDxInfoModule.service"
import { OrderbookMarket, OrderbookMarketId } from "./StxDxMarket.service"
import { StxDxMyInfoModule } from "./StxDxMyInfoModule"
import { StxDxStore } from "./StxDxStore"

export class StxDxInfoModule {
  constructor(
    private store: StxDxStore,
    private myInfo: StxDxMyInfoModule,
    private currencyStore: CurrencyStore,
  ) {
    makeObservable(this)
  }

  @computed get allAccessibleMarkets$(): OrderbookMarket[] {
    return this.store.appEnv.config$.orderbookMarkets.filter(
      market =>
        isCurrencyOrB20(market.tradeToken) &&
        isCurrencyOrB20(market.priceToken),
    )
  }

  @computed get allAccessibleMarketCurrencies$(): OrderbookAsset[] {
    return uniq([
      ...this.allAccessibleMarkets$.map(market => market.tradeToken),
      ...this.allAccessibleMarkets$.map(market => market.priceToken),
    ])
  }

  private allMarkets = new LazyValue(
    () => ({}),
    () => fetchAllMarkets(),
  )
  @computed get allMarkets$(): OrderbookMarket[] {
    return this.allMarkets.value$.orderbookMarkets.filter(
      market =>
        isCurrencyOrB20(market.tradeToken) &&
        isCurrencyOrB20(market.priceToken),
    )
  }
  @computed get allMarketCurrencies$(): OrderbookAsset[] {
    return uniq([
      ...this.allMarkets$.map(market => market.tradeToken),
      ...this.allMarkets$.map(market => market.priceToken),
    ])
  }

  @computed get allHasFundsCurrencies$(): OrderbookAsset[] {
    return this.allMarketCurrencies$.filter(c => {
      const balance = this.myInfo.stxDxBalance$(c)
      if (balance == null) return false
      return sum([balance.available, balance.locked, balance.incoming]) > 0
    })
  }

  getPegFlowTokenOrder$(token: OrderbookAsset): undefined | number {
    const market = this.allMarkets.value$.orderbookMarkets.find(
      m => m.tradeToken === token,
    )
    if (market == null) return

    if (market.isBrc20Token && market.pegFlowTokenSelectorOrder != null) {
      return market.pegFlowTokenSelectorOrder
    }

    return
  }

  private tokenAssetIdFromBackend = new LazyValue(
    () => ({}),
    async () => {
      const resp = await sendPublicRequest("AccountController_getAssets", {})
      return (resp.data as any).assets as { assetId: string; asset: string }[]
    },
  )

  #tokenPairs = memoize(
    (currency: OrderbookAsset) =>
      new LazyValue(
        () => ({
          currency,
          ids: this.tokenAssetIdFromBackend.value$,
        }),
        async ({ currency, ids }) => {
          const identifier = this.currencyStore.getAssetIdentifiers$(currency)

          if (identifier != null) {
            const idFromBackend = ids.find(id =>
              assetIdentifierEquals(id.asset, identifier),
            )
            if (idFromBackend != null) return Number(idFromBackend.assetId)
          }

          return asSender(CONTRACT_DEPLOYER)
            .contract("stxdx-registry")
            .func("get-asset-id")
            .call({ asset: currency })
        },
      ),
  )

  currencyAssetId$ = computedFn((currency: OrderbookAsset) => {
    const assetId = this.#tokenPairs(currency).value$
    if (assetId == null) {
      throw new Error(`Currency ${currency} is not registered`)
    }
    return assetId
  })

  @computed get allAssetIdMaps(): { [assetId: number]: OrderbookAsset } {
    const result: { [assetId: number]: OrderbookAsset } = {}
    for (const currency of this.allMarketCurrencies$) {
      const id = this.#tokenPairs(currency).value$
      if (id != null) {
        result[id] = currency
      }
    }
    return result
  }

  allMarketSummary = new LazyValue(() => null, fetchAllMarketSummary)

  marketSummary$ = (market: OrderbookMarketId): MarketSummary =>
    this.allMarketSummary.value$.find(a => a.market === market)!

  marketTotalVolume = memoize(
    (market: OrderbookMarketId) =>
      new LazyValue(() => market, fetchMarketTotalVolume),
  )

  @computed get senderFeeRate$(): number {
    if (ARGUMENT_ORDERBOOK_FEE_RATE != null) return ARGUMENT_ORDERBOOK_FEE_RATE
    return (
      this.store.appEnv.config$.orderbookSenderFee ?? DEFAULT_ORDERBOOK_FEE_RATE
    )
  }

  @computed get amountPlusSenderFeeRate$(): number {
    return 1 + this.senderFeeRate$
  }
}

const ARGUMENT_ORDERBOOK_FEE_RATE =
  (ALLOW_CONTRACT_ARGUMENTATION &&
    (() => {
      const result = new URLSearchParams(window.location.search).get(
        "orderbookFeeRate",
      )
      if (result != null) return Number(result)
      return undefined
    })()) ||
  undefined

const DEFAULT_ORDERBOOK_FEE_RATE = 0.01
