import clsx from "clsx"
import { format } from "date-fns"
import { FC, forwardRef, MouseEventHandler, ReactNode, useState } from "react"
import { DateRange } from "react-day-picker"
import { defineMessage, useIntl } from "react-intl"
import { PlainIconButton } from "../../../components/button/variants/PlainIconButton"
import { cardInsetBgClassName } from "../../../components/Card"
import { DatePicker } from "../../../components/DatePicker/DatePicker"
import { Dropdown } from "../../../components/Dropdown"
import { ReactComponent as CalendarIcon } from "./calendar.svg"
import { ReactComponent as CloseIcon } from "./close.svg"

export interface SelectorItem {
  label: string
  value: string
  dateRange: DateRange
}

export interface DateRangeSelectorProps {
  className?: string
  selectorItems: SelectorItem[]
  value?: string
  dateRange?: DateRange
  onSelect?: (value: string, dateRange?: DateRange) => void
}

export const DateRangeSelector: FC<DateRangeSelectorProps> = props => {
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const { $t } = useIntl()

  return (
    <div className={props.className}>
      <ul className="flex flex-col gap-y-2.5 text-sm leading-5 font-semibold text-gray-200">
        <div className="flex flex-row gap-x-2">
          {props.selectorItems.map(x => (
            <Item
              key={x.value}
              active={x.value === props.value}
              onClick={() => {
                props.onSelect?.(x.value, x.dateRange)
              }}
            >
              <span className="text-xs leading-4 font-semibold text-gray-200">
                {x.label}
              </span>
            </Item>
          ))}
        </div>

        <Dropdown
          withoutInnerContainer={true}
          triggerMethod={"click"}
          placement={"start"}
          visible={isPopperOpen}
          onVisibleChange={setIsPopperOpen}
          trigger={
            <Item
              active={props.value === "picker"}
              className="gap-x-4 px-4 py-[1.625rem]"
              onClick={() => {
                props.onSelect?.("picker")
              }}
            >
              <CalendarIcon />
              <span>
                {props.dateRange?.from &&
                props.dateRange?.to &&
                props.value === "picker"
                  ? `${format(props.dateRange.from, "LLL d yyyy")} - ${format(
                      props.dateRange.to,
                      "LLL d yyyy",
                    )}`
                  : $t(
                      defineMessage({
                        defaultMessage: "Select Dates",
                        description:
                          "/CRPSimulator/DateRangeSelector/Placeholder",
                      }),
                    )}
              </span>
            </Item>
          }
        >
          <PlainIconButton
            className="absolute top-10 right-6 z-10"
            icon={CloseIcon}
            onClick={() => setIsPopperOpen(false)}
          />

          <DatePicker
            initialFocus={isPopperOpen}
            mode="range"
            selected={props.dateRange}
            toDate={new Date()}
            onSelect={r => {
              props.onSelect?.("picker", r)
              if (r?.from && r?.to) {
                setIsPopperOpen(false)
              }
            }}
          />
        </Dropdown>
      </ul>
    </div>
  )
}

const Item = forwardRef<
  HTMLLIElement,
  {
    active: boolean
    onClick?: MouseEventHandler
    children: ReactNode
    className?: string
  }
>(({ active, onClick, children, className }, ref) => (
  <li
    ref={ref}
    className={clsx(
      cardInsetBgClassName,
      className,
      "flex-1 rounded-lg px-4 py-3",
      "flex items-center justify-start",
      "cursor-pointer",
      "border border-2",
      active ? "border-blue-600" : "border-transparent hover:border-gray-300",
      "relative",
    )}
    onClick={onClick}
  >
    {children}
  </li>
))
