export const pointCircleSize = 15

export const succeedTextColorClassName = "text-green-500"

export const failedTextColorClassName = "text-pink-400"

export const arrivedTextColorClassName = "text-blue-600"

export const idleTextColorClassName = "text-gray-600"

export const pointHeight = 48
