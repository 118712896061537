import pMemoize, { PMemoizeOption } from "../../utils/pMemorize"
import { FetchValueFnDecorator } from "./LazyValue"

export const pMemoizeDecorator = <V extends Promise<any>, P>(
  options?: PMemoizeOption,
): FetchValueFnDecorator<P, V> => {
  return fetchValue => {
    return pMemoize(fetchValue, options)
  }
}
