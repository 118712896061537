import { BorrowRowData } from "../../store/transformerGroups/lend/borrow"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const borrowExporter: Exporter<BorrowRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.collateralCurrencyAmount],
    toCurrencyAmounts: [rowData.tokenCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = `Borrow`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
