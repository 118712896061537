import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useConnect } from "../../../../components/ConnectWallet/ConnectProvider"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { WalletConnectionLine } from "../components/WalletConnectionLine"

export const WiredStacksWalletConnectionLine: FC<{
  className?: string
}> = props => {
  const authStore = useAuthStore()
  const { $t } = useIntl()

  const [connect] = useConnect()

  return (
    <WalletConnectionLine
      className={props.className}
      labelText={$t(
        defineMessage({
          defaultMessage: "Your stacks address:",
          description:
            "Orderbook Peg-In Page/Stacks Wallet Connection Info/label",
        }),
      )}
      wallet={suspenseResource(() => ({
        walletAddress: <>{authStore.stxAddress$}</>,
        onDisconnect: () => authStore.signOut(),
      }))}
      onConnectWallet={connect}
    />
  )
}
