import * as React from "react"
import { FC, SVGProps, useMemo } from "react"
import { uuid } from "../../../../utils/stringHelpers"

export const Burn: FC<SVGProps<SVGSVGElement>> = props => {
  const id1 = useMemo(() => uuid(), [])
  const id2 = useMemo(() => uuid(), [])

  return (
    <svg
      width={24}
      height={24}
      viewBox={"0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.13 17.578c-1.138 2.433 1.071 4.774 2.137 5.423h5.352c1.386-.286 3.51-2.809 3.15-5.292-.407.91-2.282 1.78-2.779 1.64.948-1.171 2.018-3.793.766-5.377-.412 1.136-1.38 2.013-2.228 2.011 1.038-1.435 1.226-5.688-4.227-6.524 2.405 2.926-3.649 6.446 1.051 10.005-1.283-.011-2.567-.889-3.222-1.886z"
        fill={`url(#${id1})`}
      />
      <path
        d="M21.813 12.954c-.364 1.276-2.072 2.532-3.199 2.54 1.185-2.23 1.399-5.686-1.13-8.584a3.377 3.377 0 01-2.577 2.744c1.718-4.213-2.878-8.394-7.892-8.653 3.272 5.3-7.3 8.744-1.037 14.978-.982-.035-3.309-.673-3.75-2.887-1.097 4.158 1.847 9.45 7.038 9.91-1.066-.65-3.276-2.991-2.138-5.424.656.997 1.94 1.875 3.223 1.886-4.7-3.559 1.353-7.079-1.052-10.005 5.454.836 5.265 5.089 4.227 6.524.848.002 1.817-.875 2.228-2.011 1.252 1.584.182 4.206-.765 5.377.496.14 2.371-.73 2.78-1.64.359 2.483-1.766 5.006-3.151 5.293 3.192 0 8.438-3.934 7.195-10.048z"
        fill={`url(#${id2})`}
      />
      <defs>
        <linearGradient
          id={`${id1}`}
          x1={12.311}
          y1={9.459}
          x2={12.311}
          y2={23.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCE4E" />
          <stop offset={1} stopColor="#FEFFE3" />
        </linearGradient>
        <linearGradient
          id={`${id2}`}
          x1={12}
          y1={1.001}
          x2={12}
          y2={23.002}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.43} stopColor="#F93701" />
          <stop offset={1} stopColor="#FFEB53" />
        </linearGradient>
      </defs>
    </svg>
  )
}
