import { useCallback, useMemo, useState } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import {
  PageInfo,
  PageStackContext,
  PageStackContextValue,
} from "./_/PageStackContext"

export const PageStackProvider: FCC = props => {
  const [pageStack, setPageStack] = useState<PageInfo[]>([])

  const pushPage = useCallback((page: PageInfo) => {
    setPageStack(stack => stack.concat(page))
    return () => {
      setPageStack(stack => stack.filter(s => s !== page))
    }
  }, [])
  const ctxValue: PageStackContextValue = useMemo(
    () => ({
      pageStack,
      pushPage,
    }),
    [pageStack, pushPage],
  )

  return (
    <PageStackContext.Provider value={ctxValue}>
      {props.children}
    </PageStackContext.Provider>
  )
}
