import {
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from "mobx"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { waitFor } from "../../../../utils/waitFor"
import { OrderbookStore } from "../OrderbookStore"
import type {
  Setting,
  SettingFormData,
} from "./OrderbookAccountSetting.service"
import {
  fetchAccountSetting,
  saveAccountSetting,
  sendVerificationEmail,
  verifyEmail,
} from "./OrderbookAccountSetting.service"

export class OrderbookAccountSettingModule {
  #savedSetting = new LazyValue(
    () =>
      [this.store.authStore.stxAddress$, this.store.myInfo.authJWT$] as const,
    ([stxAddress, auth]) => fetchAccountSetting(stxAddress, auth),
  )
  @computed get savedSetting$(): Setting {
    return this.#savedSetting.value$
  }

  @computed get principal$(): string {
    return this.store.authStore.stxAddress$
  }
  @observable username: string | undefined
  @observable email: string | undefined
  @observable enableEmailSubscription = true

  #disposer?: IReactionDisposer
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
    this.#disposer = reaction(
      () => this.savedSetting$,
      savedSetting => {
        this.username = savedSetting.username
        this.email = savedSetting.email
        this.enableEmailSubscription = savedSetting.enableEmailSubscription
      },
    )
  }
  destroy(): void {
    this.#disposer?.()
  }

  @observable showSettingModal = false

  @computed get formData$(): SettingFormData {
    return {
      uid: this.store.myInfo.registeredUserId$,
      auth: this.store.myInfo.authJWT$,
      username: this.username,
      email: this.email,
      enableEmailSubscription: this.enableEmailSubscription,
    }
  }

  @asyncAction async sendVerificationEmail(
    run = runAsyncAction,
  ): Promise<void> {
    if (this.email == null || this.email.length === 0) {
      console.error("send verification email failed, email is undefined.")
      return
    }
    const token = await run(waitFor(() => this.store.myInfo.authJWT$))
    const uid = await run(waitFor(() => this.store.myInfo.registeredUserId$))
    await run(sendVerificationEmail(uid, token, this.email))
  }

  @asyncAction async verifyEmail(
    verifyToken: string,
    run = runAsyncAction,
  ): Promise<void> {
    const uid = await run(waitFor(() => this.store.myInfo.registeredUserId$))
    await run(verifyEmail(uid, verifyToken))
    await run(this.#savedSetting.triggerUpdate())
  }

  @asyncAction async save(
    data: SettingFormData,
    run = runAsyncAction,
  ): Promise<void> {
    await run(saveAccountSetting(data))
    if (data.email && data.email !== this.#savedSetting.value$.email) {
      await run(this.sendVerificationEmail())
    }
    this.showSettingModal = false
    await run(this.#savedSetting.triggerUpdate())
  }
}
