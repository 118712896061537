import clsx from "clsx"
import { ReactNode } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { NavBackLink } from "../NavLink"
import { returnIconMargin, titleLineHeight } from "./_/consts"
import { ReturnIcon } from "./_/ReturnIcon"

export interface SecondaryPageTitleProps {
  className?: string
  returnFallbackURL?: string
  onClickReturn?: (event: { preventDefault: () => void }) => void
  subtitle?: ReactNode
}

export const SecondaryPageTitle: FCC<SecondaryPageTitleProps> = props => {
  const wrapNavLink = (content: ReactNode): ReactNode =>
    props.returnFallbackURL ? (
      <NavBackLink
        fallbackURL={props.returnFallbackURL}
        onClick={props.onClickReturn}
      >
        {content}
      </NavBackLink>
    ) : (
      content
    )

  return (
    <div
      className={clsx("text-3xl font-medium text-white", props.className)}
      style={{ lineHeight: titleLineHeight }}
    >
      {wrapNavLink(
        <>
          <span
            className={"inline-flex items-center justify-center"}
            style={{ marginRight: returnIconMargin, height: titleLineHeight }}
          >
            <ReturnIcon width={24} height={24} fill={"currentColor"} />
          </span>

          {props.children}
        </>,
      )}

      {props.subtitle && (
        <div
          className={"text-base text-gray-400"}
          style={{ marginLeft: 24 + returnIconMargin }}
        >
          {props.subtitle}
        </div>
      )}
    </div>
  )
}
