import { FC, useCallback, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { AlertLayoutAsBanner } from "../../../components/alert/AlertLayoutAsBanner"
import { NavLink } from "../../../components/NavLink"
import { Spensor } from "../../../components/Spensor"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { usePathGenerator } from "../../../routes/routes"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"

export const WiredAPowerAlert: FC<{
  className?: string
}> = props => {
  const g = usePathGenerator()
  const { $t } = useIntl()
  const [, rerenderComponent] = useState(0)
  const storageKey = `Launchpad-WiredAPowerBanner-isClosed`
  const isClosed = localStorage.getItem(storageKey)
  const onClose = useCallback(() => {
    localStorage.setItem(storageKey, "true")
    rerenderComponent(n => n + 1)
  }, [storageKey])

  return isClosed ? null : (
    <Spensor>
      {() => (
        <AlertLayoutAsBanner
          className={props.className}
          icon={<TokenIcon token={TokenInfoPresets.APower} size={24} />}
          onClose={onClose}
        >
          {$t(
            defineMessage({
              defaultMessage:
                "You must first earn {aPowerToken}, either through {stakeLink} or {farmLink}, in order to validate IDO tickets and participate.",
              description: "/Launchpad/Wired a-power alert",
            }),
            {
              aPowerToken: <TokenName token={TokenInfoPresets.APower} />,
              stakeLink: (
                <NavLink to={g.stake()}>
                  {$t(
                    defineMessage({
                      defaultMessage: "$ALEX Staking",
                      description: "/Launchpad/Wired a-power alert",
                    }),
                  )}
                </NavLink>
              ),
              farmLink: (
                <NavLink to={g.farmList()}>
                  {$t(
                    defineMessage({
                      defaultMessage: "Yield Farming",
                      description: "/Launchpad/Wired a-power alert",
                    }),
                  )}
                </NavLink>
              ),
            },
          )}
        </AlertLayoutAsBanner>
      )}
    </Spensor>
  )
}
