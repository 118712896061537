import clsx from "clsx"
import { noop } from "lodash"
import { ReactNode } from "react"
import { MessageDescriptor, defineMessage, useIntl } from "react-intl"
import { connectWallet$t } from "../../../../commonIntlMessages"
import { MaskedScopedLoadingBoundary } from "../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { SmartLoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../utils/SuspenseResource"
import { isEnumValue } from "../../../../utils/enumHelpers"
import { FCC } from "../../../../utils/reactHelpers/types"
import { orderDirection$t } from "../_/commonIntlMessages"
import { normal } from "../buttonPresets"
import {
  OrderDirection,
  StxDxOrderType,
  TradingFormError,
  TradingFormErrorType,
  TradingFormWarningType,
} from "../types"
import { CompactCardPlate } from "../wrappedCommonComponents/CompactCardPlate"
import { GreenFilledButton } from "../wrappedCommonComponents/GreenFilledButton"
import { PinkFilledButton } from "../wrappedCommonComponents/PinkFilledButton"
import { RectButton } from "../wrappedCommonComponents/RectButton"

export interface CreateOrderFormContainerProps {
  className?: string
  gapClassName?: string
  aboveForm?: ReactNode
  orderDirection: OrderDirection
  orderType: StxDxOrderType
  formError?: SuspenseResource<TradingFormError | undefined>
  onConnectWallet: () => void
  onDeposit: SuspenseResource<() => void | Promise<void>>
  onReplacePrice?: () => void
  onSubmit: () => void
}

export const CreateOrderFormContainer: FCC<
  CreateOrderFormContainerProps
> = props => {
  const intl = useIntl()
  const { $t } = intl

  const error = safeReadResource(props.formError)
  const formErrorContent = useFormErrorContent({
    formError: error,
    onDeposit: props.onDeposit,
    onReplacePrice: props.onReplacePrice,
  })

  return (
    <div
      className={clsx(
        "flex flex-col",
        props.gapClassName ?? "gap-y-4",
        props.className,
      )}
    >
      {props.aboveForm}

      <div className={"flex flex-col gap-2.5"}>{props.children}</div>

      {formErrorContent != null && (
        <CompactCardPlate>
          <NoteParagraph className={"text-red-500"} icon={<WarnIcon />}>
            {formErrorContent}
          </NoteParagraph>
        </CompactCardPlate>
      )}

      {error?.type === TradingFormErrorType.ConnectWalletRequired ? (
        <RectButton
          Variant={GradientFilledButton}
          {...normal}
          onClick={props.onConnectWallet}
        >
          {$t(connectWallet$t)}
        </RectButton>
      ) : (
        <RectButton
          Variant={
            props.orderDirection === "buy"
              ? GreenFilledButton
              : PinkFilledButton
          }
          {...normal}
          disabled={
            error != null && isEnumValue(TradingFormErrorType, error.type)
          }
          onClick={props.onSubmit}
        >
          {orderDirection$t.fromOrderDirection(intl, props.orderDirection)}
        </RectButton>
      )}
    </div>
  )
}

const useFormErrorContent = (props: {
  formError?: TradingFormError
  onDeposit: SuspenseResource<() => void | Promise<void>>
  onReplacePrice?: () => void
}): null | undefined | JSX.Element => {
  const { $t } = useIntl()

  if (props.formError?.type === TradingFormErrorType.TotalPriceTooSmall) {
    return (
      <>
        {$t(
          defineMessage({
            defaultMessage: "Total value cannot be less than {minTotalPrice}.",
            description: "Orderbook/Create Order Panel/error messages",
          }),
          {
            minTotalPrice: (
              <TextTokenCount
                token={props.formError.priceToken}
                count={props.formError.minimumPriceTokenCount}
              />
            ),
          },
        )}
      </>
    )
  }

  if (
    props.formError?.type ===
      TradingFormErrorType.InsufficientPriceTokenBalance ||
    props.formError?.type === TradingFormErrorType.InsufficientTradeTokenBalance
  ) {
    return (
      <>
        {$t(
          defineMessage({
            defaultMessage: "Insufficient Balance {depositButton}",
            description: "Orderbook/Create Order Panel/error messages",
          }),
          {
            depositButton: (
              <MaskedScopedLoadingBoundary
                inline={true}
                loadingIndicator={<LoadingIndicator />}
                read={{ onDeposit: props.onDeposit }}
                defaultRead={{ onDeposit: noop }}
              >
                {({ onDeposit }) => (
                  <SmartLoadableButton
                    className={"inline text-blue-600"}
                    Variant={HeadlessButton}
                    onClick={onDeposit}
                  >
                    {$t(
                      defineMessage({
                        defaultMessage: "Deposit >",
                        description:
                          "Orderbook/Create Order Panel/deposit button belongs error messages",
                      }),
                    )}
                  </SmartLoadableButton>
                )}
              </MaskedScopedLoadingBoundary>
            ),
          },
        )}
      </>
    )
  }

  let warningMessage: undefined | MessageDescriptor
  if (props.formError?.type === TradingFormWarningType.AbnormalBuyingPrice) {
    warningMessage = defineMessage({
      defaultMessage:
        "Limit Price is higher than the lowest selling order price. {replaceButton}",
      description: "Orderbook/Create Order Panel/warning messages",
    })
  } else if (
    props.formError?.type === TradingFormWarningType.AbnormalSellingPrice
  ) {
    warningMessage = defineMessage({
      defaultMessage:
        "Limit Price is lower than the highest buying order price. {replaceButton}",
      description: "Orderbook/Create Order Panel/warning messages",
    })
  }
  if (warningMessage != null) {
    return (
      <>
        {$t(warningMessage, {
          replaceButton: (
            <HeadlessButton
              className={"inline text-blue-600"}
              onClick={props.onReplacePrice}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Replace",
                  description:
                    "Orderbook/Create Order Panel/replace button belongs error messages",
                }),
              )}
            </HeadlessButton>
          ),
        })}
      </>
    )
  }

  return null
}
