import clsx from "clsx"
import { action } from "mobx"
import { FC, Suspense } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { RoundedButton } from "../../components/button/RoundedButton"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { Divider } from "../../components/Divider"
import { HorizontalScrollableLayoutContainerInMobile } from "../../components/LayoutContainer/LayoutContainer"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../components/Modal"
import { NavLink } from "../../components/NavLink"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { SecondaryPageTitle } from "../../components/PageStack/SecondaryPageTitle"
import { Spensor } from "../../components/Spensor"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { usePathGenerator } from "../../routes/routes"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../utils/alexjs/Currency"
import { liquidityTokenPairs } from "../../utils/alexjs/currencyHelpers"
import { ManualStakeStoreProvider } from "../Stake/store/manualStaking/useManualStakeStore"
import { CoFarmDisclaimer } from "./components/CoFarmDisclaimer"
import { CoFarmInfinityIcon } from "./components/CoFarmInfinityIcon"
import { CoFarmPipeDiagram } from "./components/CoFarmPipeDiagram/CoFarmPipeDiagram"
import { CoFarmWhy } from "./components/CoFarmWhy"
import { ConfirmCoFarmModalContent } from "./components/ConfirmCoFarmModalContent"
import { CoFarmStoreProvider, useCoFarmStore } from "./stores/useCoFarmStore"

const WiredCoFarmPipeDiagram: FC<{
  className?: string
}> = props => {
  const store = useCoFarmStore()
  const currency = useCurrencyStore()
  const [tokenA, tokenB] = liquidityTokenPairs(store.token)

  return (
    <CoFarmPipeDiagram
      className={props.className}
      lpToken={currency.getTokenInfo$(store.token)}
      lpTokenCount={store.pendingReturns$}
      lpLeftToken={currency.getTokenInfo$(tokenA)}
      lpLeftTokenCount={store.stxAmount$}
      lpRightToken={currency.getTokenInfo$(tokenB)}
      lpRightTokenCount={store.alexAmount$}
      lpLeftTokenTransformedLPToken={currency.getTokenInfo$(
        Currency.FWP_STX_ALEX_TRANCHED,
      )}
      lpLeftTokenTransformedLPTokenCount={store.stxAmount$}
      lpLeftTokenTransformedLPTokenAPR={store.farmRP$}
      lpRightTokenTransformedToken={currency.getTokenInfo$(Currency.ATALEX)}
      lpRightTokenTransformedTokenCount={store.atAlexAmount$}
      lpRightTokenTransformedTokenAPY={store.atAlexAPY$}
      lpRightTokenRewards={[
        {
          token: currency.getTokenInfo$(Currency.ATALEX),
          count: store.pendingRewards$ / store.atAlexIntrinsic.value$,
        },
        {
          token: currency.getTokenInfo$(Currency.APOWER),
          count: store.apowerAmount$,
        },
      ]}
      lpLeftTokenTransformedLPTokenEndCycle={store.getEndCycle$}
    />
  )
}

const WiredCoFarmButton: FC<{
  className?: string
}> = props => {
  const store = useCoFarmStore()
  const currency = useCurrencyStore()
  const [tokenA, tokenB] = liquidityTokenPairs(store.token)
  const navigate = useNavigate()
  const g = usePathGenerator()
  const { $t } = useIntl()

  return (
    <>
      <RoundedButton
        className={clsx(
          "p-[18px] flex items-center justify-center text-lg font-semibold",
          props.className,
        )}
        Variant={GradientFilledButton}
        disabled={store.stxAmount$ === 0}
        onClick={action(() => (store.confirming = true))}
      >
        {$t(
          defineMessage({
            defaultMessage: "{icon} Stake Co-Farm LP",
            description: "/Farm/CoFarm/button text",
          }),
          {
            icon: <CoFarmInfinityIcon />,
          },
        )}
      </RoundedButton>

      <Modal
        visible={store.confirming}
        onClose={action(() => (store.confirming = false))}
      >
        <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
          {() => (
            <ConfirmCoFarmModalContent
              lpLeftToken={currency.getTokenInfo$(tokenA)}
              lpRightToken={currency.getTokenInfo$(tokenB)}
              lpRightTokenTransformedToken={currency.getTokenInfo$(
                Currency.ATALEX,
              )}
              lpRightTokenTransformedTokenEstimateApy={store.atAlexAPY$}
              lpRightTokenTransformedTokenStakePrice={
                store.atAlexIntrinsic.value$
              }
              coFarmEstimateApr={store.farmRP$}
              fromCycle={store.fromCycle$}
              toCycle={store.toCycle$}
              alexFoundationOffer={{
                token: currency.getTokenInfo$(Currency.ALEX),
                count: store.alexAmount$,
              }}
              harvestAssets={[
                {
                  token: currency.getTokenInfo$(store.token),
                  count: store.pendingReturns$,
                },
                {
                  token: currency.getTokenInfo$(Currency.ALEX),
                  count: store.pendingRewards$,
                },
                {
                  token: currency.getTokenInfo$(Currency.APOWER),
                  count: store.apowerAmount$,
                },
              ]}
              coFarmAssets={[
                {
                  token: currency.getTokenInfo$(Currency.FWP_STX_ALEX_TRANCHED),
                  count: store.pendingReturns$,
                },
                {
                  token: currency.getTokenInfo$(Currency.ATALEX),
                  count:
                    store.atAlexAmount$ +
                    store.pendingRewards$ / store.atAlexIntrinsic.value$,
                },
                {
                  token: currency.getTokenInfo$(Currency.APOWER),
                  count: store.apowerAmount$,
                },
              ]}
              onClose={action(() => (store.confirming = false))}
              onConfirm={() => store.confirm()}
            />
          )}
        </Spensor>
      </Modal>

      <WiredTransactionStateDialog
        store={store.txStore}
        onClose={() => {
          navigate(g.farmDetail({ id: Currency.FWP_STX_ALEX_50_50_V1_01 }))
        }}
      />
    </>
  )
}

const WiredCoFarmDisclaimer: FC<{ className?: string }> = props => {
  const currency = useCurrencyStore()
  const store = useCoFarmStore()
  return (
    <CoFarmDisclaimer
      className={props.className}
      lpLeftToken={currency.getTokenInfo$(Currency.W_STX)}
      lpLeftTokenCount={store.stxAmount$}
      rewardToken={currency.getTokenInfo$(Currency.ALEX)}
    />
  )
}

const WiredCoFarmWhy: FC<{ className?: string }> = props => {
  const currency = useCurrencyStore()
  const store = useCoFarmStore()

  return (
    <CoFarmWhy
      className={props.className}
      lpLeftToken={currency.getTokenInfo$(Currency.W_STX)}
      rewardToken={currency.getTokenInfo$(Currency.ALEX)}
      endCycle={store.getEndCycle$}
    />
  )
}

const CoFarmDetailPageContent: FC = () => {
  const currency = useParams().token
  const { $t } = useIntl()
  if (currency !== Currency.FWP_STX_ALEX_50_50_V1_01) {
    throw new Error(`Invalid token ${currency}`)
  }

  const gen = usePathGenerator()

  return (
    <PageStackPage
      title={
        <SecondaryPageTitle
          returnFallbackURL={gen.farmList()}
          subtitle={
            <>
              <p>
                {$t(
                  defineMessage({
                    defaultMessage:
                      "Co-Farm with ALEX: Farming with protection against impermanent loss",
                    description: "/Farm/CoFarmDetailContent/subtitle",
                  }),
                )}
              </p>
              <NavLink
                className={"text-sm text-blue-600"}
                to={
                  "https://medium.com/alexgobtc/co-farming-with-alex-571000bc6d5a"
                }
              >
                {$t(
                  defineMessage({
                    defaultMessage: "How does Co-LP staking work?",
                    description: "/Farm/CoFarmDetailPageContent/Link text",
                  }),
                )}
              </NavLink>
            </>
          }
        >
          {$t(
            defineMessage({
              defaultMessage: "Co-Farm LP tokens",
              description: "/Farm/CoFarmDetailContent/title",
            }),
          )}
        </SecondaryPageTitle>
      }
    >
      <div className={"grid grid-cols-24 gap-y-8 lg:gap-8 mb-8"}>
        <Suspense
          fallback={
            <div
              className={
                "col-span-full lg:col-span-16 row-start-1 row-span-3 flex"
              }
            >
              <LoadingIndicator className="m-auto" />
            </div>
          }
        >
          <HorizontalScrollableLayoutContainerInMobile
            className={"col-span-full lg:col-span-16 row-start-1"}
          >
            <WiredCoFarmPipeDiagram />
          </HorizontalScrollableLayoutContainerInMobile>

          <WiredCoFarmButton
            className={"col-span-full lg:col-span-16 row-start-2"}
          />

          <Divider
            className={clsx(
              "col-span-full lg:col-span-16 row-start-3",
              "border-gray-500/30",
            )}
          />
        </Suspense>

        <Suspense>
          <WiredCoFarmDisclaimer
            className={"col-span-full lg:col-span-16 row-start-4"}
          />
        </Suspense>

        <Suspense>
          <WiredCoFarmWhy
            className={"col-span-full lg:col-span-8 row-start-5 lg:row-start-1"}
          />
        </Suspense>
      </div>
    </PageStackPage>
  )
}

export const CoFarmDetailPage: FC = () => {
  const currency = useParams().token
  if (currency !== Currency.FWP_STX_ALEX_50_50_V1_01) {
    throw new Error(`Invalid token ${currency}`)
  }
  return (
    <ManualStakeStoreProvider token={currency}>
      <CoFarmStoreProvider token={currency}>
        <Suspense>
          <CoFarmDetailPageContent />
        </Suspense>
      </CoFarmStoreProvider>
    </ManualStakeStoreProvider>
  )
}
