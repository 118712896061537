import { FC, Suspense } from "react"
import { defineMessage, useIntl } from "react-intl"
import { NavLink } from "../../components/NavLink"
import { PageStackPage } from "../../components/PageStack/PageStackPage"
import { PrimaryPageTitle } from "../../components/PageStack/PrimaryPageTitle"
import { Spensor } from "../../components/Spensor"
import { TokenName } from "../../components/TokenName"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../utils/alexjs/Currency"
import { WiredMixedStakePageContent } from "./components/WiredMixedStakePageContent"
import { AutoStakeProvider } from "./store/autoStaking/useAutoStakeStore"
import { ManualStakeStoreProvider } from "./store/manualStaking/useManualStakeStore"
import { MixedStakingStoreProvider } from "./store/mixedStaking/useMixedStakingStore"

const StakePageImpl: FC = () => {
  const currency = useCurrencyStore()
  const appEnvStore = useAppEnvStore()
  const alexToken = currency.getTokenInfo$(Currency.ALEX)
  const { $t } = useIntl()

  return (
    <PageStackPage
      title={
        <div
          className={
            "flex flex-col gap-2 order-1 flex-1 md:flex-row md:items-center md:justify-between"
          }
        >
          <PrimaryPageTitle
            className={"flex-1"}
            subtitleClassName={"flex flex-col gap-1"}
            subtitle={
              <>
                <p>
                  {$t(
                    defineMessage({
                      defaultMessage: "Stake {tokenName} to earn more rewards.",
                      description: "/Stake/Stake page subtitle",
                    }),
                    { tokenName: <TokenName token={alexToken} /> },
                  )}
                </p>
                <p>
                  <NavLink
                    className={"text-blue-600"}
                    to={
                      "https://medium.com/alexgobtc/how-to-use-autoalex-fefdce0ccbcb"
                    }
                  >
                    {$t(
                      defineMessage({
                        defaultMessage: "How to Use AutoALEX?",
                        description: "/Stake/Stake page nav link text",
                      }),
                    )}
                  </NavLink>
                </p>
              </>
            }
          >
            {$t(
              defineMessage({
                defaultMessage: "Stake",
                description: "/Stake/Stake page title",
              }),
            )}
          </PrimaryPageTitle>
        </div>
      }
    >
      <Suspense>
        <WiredMixedStakePageContent />
      </Suspense>
    </PageStackPage>
  )
}

export const StakePage: FC = () => {
  return (
    <Spensor>
      {() => (
        <ManualStakeStoreProvider token={Currency.ALEX}>
          <AutoStakeProvider>
            <MixedStakingStoreProvider>
              <Suspense>
                <StakePageImpl />
              </Suspense>
            </MixedStakingStoreProvider>
          </AutoStakeProvider>
        </ManualStakeStoreProvider>
      )}
    </Spensor>
  )
}
