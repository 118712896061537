import { unwrapResponse } from "clarity-codegen"
import { computed, makeObservable } from "mobx"
import { of } from "rxjs"
import { CONTRACT_DEPLOYER } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { AMMSwapPool } from "../../../../utils/alexjs/AMMSwapPool"
import {
  LiquidityPoolToken,
  SwappableCurrency,
  autoTokenForYTP,
  isYTPToken,
  liquidityTokenPairs,
} from "../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { props } from "../../../../utils/promiseHelpers"
import { AddLiquidityModule } from "./AddLiquidityModule"
import { MyLiquidityModule } from "./MyLiquidityModule"
import type { LiquidityInfo } from "./PoolDetailStore.services"
import {
  getAMMPoolLiquidityInfo,
  getAMMPoolV1_1LiquidityInfo,
  getPoolLiquidityInfo,
  getYTPPoolInfo,
} from "./PoolDetailStore.services"
import { RemoveLiquidityModule } from "./RemoveLiquidityModule"

class PoolDetailStore {
  constructor(
    readonly poolToken: LiquidityPoolToken,
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    readonly accountStore: Pick<AccountStore, "getBalance$">,
    readonly currencyStore: Pick<
      CurrencyStore,
      | "getPrice$"
      | "fetchPoolBreakdown$"
      | "getTokenInfo$"
      | "ammPoolIdFromCurrency$"
    >,
  ) {
    makeObservable(this)
  }

  get poolTokenInfo(): TokenInfo {
    return this.currencyStore.getTokenInfo$(this.poolToken)
  }

  private info = new LazyValue(
    () =>
      [
        this.poolToken,
        this.currencyStore.getPrice$(liquidityTokenPairs(this.poolToken)[0]),
        this.authStore.currentBlockHeight$,
      ] as const,
    ([token, tokenXPrice]) => {
      return getPoolLiquidityInfo(token, tokenXPrice)
    },
  )

  private ytpInfo = new LazyValue(
    () => this.poolToken,
    ytpToken => getYTPPoolInfo(ytpToken),
  )

  @computed get ammPoolId$(): number | null {
    if (!AMMSwapPool.isPoolToken(this.poolToken)) {
      return null
    }
    return this.currencyStore.ammPoolIdFromCurrency$(this.poolToken)
  }

  private ammInfo = new LazyValue(
    () =>
      [
        this.poolToken,
        this.ammPoolId$,
        this.currencyStore.getPrice$(liquidityTokenPairs(this.poolToken)[0]),
      ] as const,
    ([poolToken, poolId, tokenXPrice]) => {
      if (poolId == null) {
        return of(null)
      }
      if (AMMSwapPool.isV1_1PoolToken(poolToken)) {
        return getAMMPoolV1_1LiquidityInfo(poolId, tokenXPrice)
      }
      return getAMMPoolLiquidityInfo(poolId, tokenXPrice)
    },
  )

  @computed private get aamPoolBase$(): {
    factor: number
    tokenX: SwappableCurrency
    tokenY: SwappableCurrency
    ammVersion: "1" | "1_1"
  } {
    const token = this.poolToken
    if (!AMMSwapPool.isPoolToken(token)) {
      throw new Error("Not an AMM pool token")
    }
    const factor = AMMSwapPool.getFactor(token)
    const [tokenX, tokenY] = AMMSwapPool.breakDown(token)
    return {
      factor,
      tokenX,
      tokenY,
      ammVersion: AMMSwapPool.isV1_1PoolToken(token) ? "1_1" : "1",
    }
  }

  #ammPoolExtraInfo = new LazyValue(
    () => this.aamPoolBase$,
    ({ tokenY, tokenX, factor, ammVersion }) => {
      const balances = asSender(CONTRACT_DEPLOYER)
        .contract(ammVersion === "1" ? "amm-swap-pool" : "amm-swap-pool-v1-1")
        .func("get-balances")
        .call({
          factor,
          "token-x": tokenX,
          "token-y": tokenY,
        })
        .then(unwrapResponse)
        .then(a => ({
          x: a["balance-x"] / 1e8,
          y: a["balance-y"] / 1e8,
        }))
      const fees = asSender(CONTRACT_DEPLOYER)
        .contract(ammVersion === "1" ? "amm-swap-pool" : "amm-swap-pool-v1-1")
        .func("get-fee-rate-x")
        .call({
          factor,
          "token-x": tokenX,
          "token-y": tokenY,
        })
        .then(unwrapResponse)
        .then(value => value / 1e8)
      const feeRebate = asSender(CONTRACT_DEPLOYER)
        .contract(ammVersion === "1" ? "amm-swap-pool" : "amm-swap-pool-v1-1")
        .func("get-fee-rebate")
        .call({
          factor,
          "token-x": tokenX,
          "token-y": tokenY,
        })
        .then(unwrapResponse)
        .then(value => value / 1e8)
      return props({
        balances,
        fees,
        feeRebate,
      })
    },
  )

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @computed get ammPoolExtraInfo$() {
    const { tokenY, tokenX, factor } = this.aamPoolBase$
    const { fees, feeRebate, balances } = this.#ammPoolExtraInfo.value$
    const priceX = this.currencyStore.getPrice$(tokenX)
    const priceY = this.currencyStore.getPrice$(tokenY)
    const xValueInUSD = priceX * balances.x
    const yValueInUSD = priceY * balances.y
    const xPercentage = xValueInUSD / (xValueInUSD + yValueInUSD)
    const yPercentage = yValueInUSD / (xValueInUSD + yValueInUSD)
    const totalValueInUSD = xValueInUSD + yValueInUSD
    return {
      feeRebate,
      fees,
      xBalance: balances.x,
      yBalance: balances.y,
      xPercentage,
      yPercentage,
      totalValueInUSD,
      factor: factor / 1e8,
    }
  }

  @computed get info$(): LiquidityInfo {
    if (this.ytpInfo.value$ != null) {
      const { tokenXBalance, tokenYBalance } = this.ytpInfo.value$
      const [tokenX, tokenY] = liquidityTokenPairs(this.poolToken)
      const tokenXInUSD = tokenXBalance * this.currencyStore.getPrice$(tokenX)
      const tokenYInUSD = tokenYBalance * this.currencyStore.getPrice$(tokenY)
      return {
        ...this.info.value$,
        tokenXPercentage: tokenXInUSD / (tokenXInUSD + tokenYInUSD),
        tokenYPercentage: tokenYInUSD / (tokenXInUSD + tokenYInUSD),
      }
    }
    if (this.ammInfo.value$ != null) {
      return this.ammInfo.value$
    }
    return this.info.value$
  }

  myLiquidity = new MyLiquidityModule(this)
  addLiquidity = new AddLiquidityModule(this)
  removeLiquidity = new RemoveLiquidityModule(this)

  @computed get pooledAmount$(): number {
    if (isYTPToken(this.poolToken)) {
      return this.accountStore.getBalance$(autoTokenForYTP(this.poolToken))
    }
    return this.accountStore.getBalance$(this.poolToken)
  }
}

export default PoolDetailStore
