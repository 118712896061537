import { Ref, useMemo, useRef, useState } from "react"
import { useDebounceFn } from "./useDebounceFn"
import { useOnElementResize } from "./useOnElementResize"

export interface ElementSize {
  width: number
  height: number
}

export function useElementSize<T extends HTMLElement>(): [Ref<T>, ElementSize] {
  const containerRef = useRef<T>(null)

  const [containerSize, setContainerSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  })

  const onContainerResize = useDebounceFn(
    () => {
      if (containerRef.current) {
        setContainerSize(containerRef.current.getBoundingClientRect())
      } else {
        setContainerSize({ width: 0, height: 0 })
      }
    },
    [],
    100,
  )

  useOnElementResize(containerRef, onContainerResize)

  return useMemo(
    () => [containerRef, containerSize],
    [containerRef, containerSize],
  )
}
