import {
  ClaimTokenInfo,
  MixedStakeCycle,
  StakeCycle,
  StakeCycleStatus,
} from "../../types"
import { AutoStakeCycleViewModule } from "../autoStaking/AutoStakeCycleViewModule"
import MixedStakingStore from "./MixedStakingStore"

export class MixedStakeCycleViewModel implements MixedStakeCycle {
  constructor(
    readonly store: MixedStakingStore,
    readonly cycleNumber: number,
  ) {}

  get manual(): StakeCycle {
    return this.store.manualStore.myStaking.cellViewModule(this.cycleNumber)
  }

  get auto(): AutoStakeCycleViewModule {
    return this.store.autoStore.cycleViewModule(this.cycleNumber)
  }

  get cycleStatus(): StakeCycleStatus {
    return this.manual.cycleStatus
  }

  get currentBlock(): number {
    return this.manual.currentBlock
  }

  get fromBlock(): number {
    return this.manual.fromBlock
  }

  get manualStakingAlexTokenCount(): number {
    return this.manual.myStaked.count
  }

  get manualStakingApr(): number {
    return this.manual.apr
  }

  get manualStakingClaimable(): ClaimTokenInfo[] {
    return [...this.manual.rewardToClaim, ...this.manual.principalToClaim]
  }

  get targetBlock(): number {
    return this.manual.targetBlock
  }

  get targetDate(): Date {
    return this.manual.targetDate
  }

  get totalStakedAlexCount(): number {
    return (
      this.auto.poolVolume -
      this.auto.atAlexStakedVolume +
      this.manual.totalStaked.count
    )
  }

  get autoAlexIntrinsicAlexValue(): number {
    return this.auto.intrinsicAlexValue * this.autoAlexTokenCount
  }

  get autoAlexTokenCount(): number {
    return this.store.autoStore.atAlexBalance$
  }

  get autoStakingApy(): number {
    return this.manual.apr
    // return (this.manual.apr / PRODUCTION_ANNUAL_FACTOR + 1) ** 100 - 1
  }
}
