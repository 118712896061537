import { isEqual, uniqWith } from "lodash"
import { FC, useMemo } from "react"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { TitledColumnCell } from "../../../../../components/TitledColumnCell"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { Tooltip } from "../../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { assertNever } from "../../../../../utils/types"
import { ClaimTokenInfo, StakeCycleStatus } from "../../../types"

export interface Copies {
  toBeFarmed: string
  estimatedClaim: string
}

export const columnCount = 3

export const StakeCycleRowContent: FC<{
  className?: string
  status: StakeCycleStatus
  apr: number
  toBeFarmed: ClaimTokenInfo
  principalToClaim: ClaimTokenInfo[]
  rewardToClaim: ClaimTokenInfo[]
  upcomingStatusCopies: Copies
  currentStatusCopies: Copies
  finishedStatusCopies: Copies
}> = props => {
  // prettier-ignore
  const copies =
    props.status === "upcoming" ? props.upcomingStatusCopies :
    props.status === "current" ? props.currentStatusCopies :
    props.status === "finished" ? props.finishedStatusCopies :
    assertNever(props.status)

  const tokens = useMemo(
    () =>
      uniqWith(
        [
          ...props.rewardToClaim,
          /**
           * principal token should show in the last
           * if principal token is ALEX, it will merge with reward token, show
           * in the first
           * https://www.notion.so/alexgo-io/Dual-Yield-Farm-3916a51de85d4c36b9237d94bb3cb4fc#236b7965edc3429b9509c29d1fd8d81f
           */
          ...props.principalToClaim,
        ].map(info => info.token),
        isEqual,
      ),
    [props.principalToClaim, props.rewardToClaim],
  )

  return (
    <>
      <TitledColumnCell
        className={"gap-1 self-start"}
        title={copies.toBeFarmed}
        detail={
          <>
            <TokenIcon
              className={"mr-1.5"}
              token={props.toBeFarmed.token}
              size={16}
            />
            <TokenCount
              token={props.toBeFarmed.token}
              count={props.toBeFarmed.count}
            />
          </>
        }
      />

      <TitledColumnCell
        className={"gap-1 self-start"}
        title={"APR"}
        detail={<PercentNumber number={props.apr} />}
      />

      <TitledColumnCell
        className={"gap-1"}
        title={copies.estimatedClaim}
        detail={
          <ul>
            {tokens.map((token, idx) => {
              const principalCount = props.principalToClaim.find(
                info => info.token.id === token.id,
              )?.count
              const rewardCount = props.rewardToClaim.find(
                info => info.token.id === token.id,
              )?.count

              return (
                <li key={idx}>
                  <TokenList
                    token={token}
                    counts={[
                      ...(principalCount != null
                        ? [{ title: "Principal", count: principalCount }]
                        : []),
                      ...(rewardCount != null
                        ? [{ title: "Reward", count: rewardCount }]
                        : []),
                    ]}
                  />
                </li>
              )
            })}
          </ul>
        }
      />
    </>
  )
}

const TokenList: FC<{
  token: TokenInfo
  counts: {
    title: string
    count: number
  }[]
}> = props => {
  const content = (
    <>
      <TokenIcon className={"mr-1.5"} token={props.token} size={16} />{" "}
      {props.counts.map((c, idx) => (
        <>
          {idx !== 0 ? "+" : ""}
          <TokenCount token={props.token} count={c.count} />
        </>
      ))}
    </>
  )

  if (TokenInfoPresets.isAPower(props.token)) {
    return <div className={"inline-flex items-center"}>{content}</div>
  }

  return (
    <Tooltip
      className={"inline-flex items-center"}
      title={
        <ul>
          {props.counts.map((c, idx) => (
            <li key={idx}>
              {c.title}: <TokenCount token={props.token} count={c.count} />{" "}
              <TokenName token={props.token} />
            </li>
          ))}
        </ul>
      }
    >
      {content}
    </Tooltip>
  )
}
