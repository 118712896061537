import clsx from "clsx"
import { FC, SVGProps, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../components/Card"
import { CountNumber } from "../../../../components/CountNumber"
import { FiatCount } from "../../../../components/FiatCount"
import { InfoList } from "../../../../components/InfoList/InfoList"
import { InfoListItem } from "../../../../components/InfoList/InfoListItem"
import {
  DefaultInfoListItemDetail,
  InfoListItemDetail,
} from "../../../../components/InfoList/InfoListItemDetail"
import { InfoListItemTitle } from "../../../../components/InfoList/InfoListItemTitle"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { TabBar } from "../../../../components/TabBar"
import { PlainIconButton } from "../../../../components/button/variants/PlainIconButton"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import {
  CompactCard,
  CompactCardTitle,
} from "../wrappedCommonComponents/CompactCard"

export type B20StatsContentProps = {
  totalTradingVolume: SuspenseResource<number>
  weeklyTradingVolume: SuspenseResource<number>
  totalBuyOrderAmount: SuspenseResource<number>
  weeklyBuyOrderAmount: SuspenseResource<number>
  totalPoints: SuspenseResource<number>
  weeklyPoints: SuspenseResource<number>
  weeklyPointsPercentage: SuspenseResource<number>
}

enum B20TradePointsContentTab {
  LastRound = "Last Round",
  Total = "Total",
}

const B20TradePointsContent: FC<
  B20StatsContentProps & { className?: string }
> = props => {
  const { $t } = useIntl()

  const [tab, setTab] = useState<B20TradePointsContentTab>(
    B20TradePointsContentTab.LastRound,
  )

  const [contentVisible, setContentVisible] = useState(false)

  return (
    <CompactCard className={clsx("flex flex-col", props.className)}>
      <PlainIconButton
        className={"flex flex-row-reverse items-center gap-x-2.5"}
        iconClassName={clsx("ml-auto", !contentVisible && "rotate-90")}
        icon={DownArrowIcon}
        effectOnIconOnly={true}
        onClick={() => setContentVisible(!contentVisible)}
      >
        <CompactCardTitle className={"opacity-100"}>
          {$t(
            defineMessage({
              defaultMessage: "My Points: {points}",
              description: `Orderbook/B20 Stats`,
            }),
            { points: <CountNumber number={props.totalPoints} /> },
          )}
        </CompactCardTitle>
      </PlainIconButton>

      {contentVisible && (
        <>
          <TabBar
            className="mt-2"
            containerClassName="flex-1"
            tabClassName={
              "px-4 pb-3 text-[12px] leading-5 font-semibold flex-1 text-center"
            }
            tabs={[
              {
                label: "Last Round",
                value: B20TradePointsContentTab.LastRound,
              },
              { label: "Total", value: B20TradePointsContentTab.Total },
            ]}
            selectedTab={{
              tabValue: tab,
              onChange: tab => setTab(tab.value),
            }}
          />
          <InfoList
            direction={"column"}
            listItemDirection={"row"}
            className="gap-y-3.5 my-3.5"
            renderInfoListItemDetail={p => (
              <DefaultInfoListItemDetail {...p} textClassName="text-xs" />
            )}
          >
            {tab === B20TradePointsContentTab.Total ? (
              <>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "Trading volume",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <FiatCount count={props.totalTradingVolume} />
                  </InfoListItemDetail>
                </InfoListItem>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "Buy order amount",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <FiatCount count={props.totalBuyOrderAmount} />
                  </InfoListItemDetail>
                </InfoListItem>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "Points",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <Spensor>
                      {() => (
                        <CountNumber number={readResource(props.totalPoints)} />
                      )}
                    </Spensor>
                  </InfoListItemDetail>
                </InfoListItem>
              </>
            ) : (
              <>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "Trading volume",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <FiatCount count={props.weeklyTradingVolume} />
                  </InfoListItemDetail>
                </InfoListItem>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "My Percentage",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <PercentNumber number={props.weeklyPointsPercentage} />
                  </InfoListItemDetail>
                </InfoListItem>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "Buy order amount",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <FiatCount count={props.weeklyBuyOrderAmount} />
                  </InfoListItemDetail>
                </InfoListItem>
                <InfoListItem className="flex flex-row">
                  <InfoListItemTitle className="flex flew-row items-center gap-x-2 self-start">
                    {$t(
                      defineMessage({
                        defaultMessage: "Points",
                        description: `Orderbook/B20 Stats`,
                      }),
                    )}
                  </InfoListItemTitle>
                  <InfoListItemDetail className="flex flex-col items-end gap-y-0.5">
                    <Spensor>
                      {() => (
                        <CountNumber
                          number={readResource(props.weeklyPoints)}
                        />
                      )}
                    </Spensor>
                  </InfoListItemDetail>
                </InfoListItem>
              </>
            )}
          </InfoList>
          <CardInset boxClassName="px-2.5 py-2.5">
            <NoteParagraph textSizingClassName="text-xs">
              {$t(
                defineMessage({
                  defaultMessage:
                    "Data is refreshed after each points cycle, which is 1050 blocks. Airdrops will be delivered directly to your wallet.\n",
                  description: "Orderbook/B20 Stats",
                }),
              )}
            </NoteParagraph>
          </CardInset>
        </>
      )}
    </CompactCard>
  )
}

const DownArrowIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.307 12.75L2.764 6.256l1.06-1.06 5.483 5.482 5.482-5.482 1.06 1.06-6.542 6.494z"
        fill="#E5E7EB"
        opacity={0.5}
      />
    </svg>
  )
}

export default B20TradePointsContent
