import clsx from "clsx"
import { FC } from "react"
import { LoadableButton } from "../../../../components/button/LoadableButton"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { ReactComponent as LoadMoreIcon } from "./loadMore.svg"

export const LoadMoreButton: FC<{
  className?: string
  loading: boolean
  onLoadMore?: () => void
}> = props => (
  <LoadableButton
    Variant={OpacityButton}
    className={clsx("w-[160px]", props.className)}
    boxClassName={"px-3 py-1.5"}
    textClassName={"text-xs leading-4 font-medium text-gray-200"}
    contentContainerClassName={"flex items-center gap-1.5"}
    loading={props.loading}
    onClick={props.onLoadMore}
  >
    More <LoadMoreIcon fill={"#C4C4C4"} />
  </LoadableButton>
)
