import { TupleCV } from "@stacks/transactions"
import { STACK_NETWORK } from "../../../config"
import {
  numberT,
  stringAsciiT,
  tupleT,
} from "../../../generated/smartContractHelpers/codegenImport"
import { ResolutionString } from "../../../vendors/charting_library/charting_library.esm"

export const INTRADAY_RESOLUTION_MAP: { [key: string]: number } = {
  "1": 1,
  "5": 5,
  "15": 15,
  "30": 30,
  "60": 60,
  "240": 240,
  "360": 360,
  "480": 480,
  "720": 720,
}
export const RESOLUTION_MAP: { [key: string]: number } = {
  ...INTRADAY_RESOLUTION_MAP,
  "1D": 24 * 60,
  "1W": 7 * 24 * 60,
  "1M": 30 * 24 * 60,
}

export const INTRADAY_RESOLUTIONS: ResolutionString[] = Object.keys(
  INTRADAY_RESOLUTION_MAP,
) as any
export const DAILY_RESOLUTIONS: ResolutionString[] = ["1" as ResolutionString]
export const WEEKLY_RESOLUTIONS: ResolutionString[] = ["1" as ResolutionString]
export const MONTHLY_RESOLUTIONS: ResolutionString[] = ["1" as ResolutionString]
export const RESOLUTIONS: ResolutionString[] = Object.keys(
  RESOLUTION_MAP,
) as any

const makeDomainTuple = tupleT({
  name: stringAsciiT,
  version: stringAsciiT,
  "chain-id": numberT,
})

export const signatureDomain = makeDomainTuple.encode({
  name: "ALEX B20 Protocol",
  version: "0.0.1",
  "chain-id": STACK_NETWORK.chainId,
}) as TupleCV
