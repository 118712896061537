import clsx from "clsx"
import { ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../../../components/Card"
import { FCC } from "../../../../../utils/reactHelpers/types"

export const ActionSectionFrame: FCC<{
  className?: string
  titleRightSide?: ReactNode
}> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx(props.className, "flex flex-col gap-4")}>
      <div className={"flex items-center justify-between"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Schedule",
              description: "/Launchpad/Action section frame title",
            }),
          )}
        </CardTitle>
        {props.titleRightSide}
      </div>
      {props.children}
    </Card>
  )
}
