import { RefObject, useEffect } from "react"
import { isTouchDevice } from "../domHelpers/browserEnv"
import { usePersistFn } from "./usePersistFn"

export function useOnClickOutside(
  containerRef: RefObject<HTMLElement>,
  onClickOutside: () => void,
): void
export function useOnClickOutside(
  isClickOutside: (event: Event & { target: Node }) => boolean,
  onClickOutside: () => void,
): void
export function useOnClickOutside(
  predicate:
    | RefObject<HTMLElement>
    | ((event: Event & { target: Node }) => boolean),
  onClickOutside: () => void,
): void {
  return useOnClickOutsideWithCustomEventName(
    isTouchDevice() ? "touchstart" : "mousedown",
    event => {
      if (typeof predicate === "function") {
        return predicate(event as any)
      } else {
        const containerRef = predicate
        // Do nothing if clicking ref's element or descendent elements
        return (
          !containerRef.current || !containerRef.current.contains(event.target)
        )
      }
    },
    onClickOutside,
  )
}

export function useOnClickOutsideWithCustomEventName(
  eventName: "mousedown" | "click" | "touchstart",
  predicate: (event: Event & { target: Node }) => boolean,
  onClickOutside: () => void,
): void {
  const _onClickOutside = usePersistFn(onClickOutside)

  const isClickOutside = usePersistFn((event: Event): boolean => {
    if (!(event.target instanceof Node)) return false
    return predicate(event as any)
  })

  useEffect(() => {
    const listener = (event: Event): void => {
      if (isClickOutside(event)) {
        // wait all click outside checker executed, then perform operation
        setTimeout(_onClickOutside)
      }
    }
    document.addEventListener(eventName, listener, { passive: true })
    return () => {
      document.removeEventListener(eventName, listener, {})
    }
  }, [isClickOutside, _onClickOutside, eventName])
}

export function isClickOnAnyChildNode(
  event: Event,
  containerEl: Node,
): boolean {
  if (!(event.target instanceof Node)) return false

  const clickEl = event.target

  return Array.from(containerEl.childNodes).some(e => e.contains(clickEl))
}
