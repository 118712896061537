/**
 * This file defined all the ETH chains that this App supported in any environment.
 */

export enum ETHChain {
  Unknown = "Unknown",

  // Ethereum
  Ethereum = "Ethereum",
  Goerli = "Goerli",
  Sepolia = "Sepolia",
  SepoliaFork = "Sepolia Fork",

  // BSC
  BSC = "Binance Smart Chain",
  BSCTest = "Binance Smart Chain Testnet",
  BSCTestFork = "BSC Testnet Fork",

  // Others
  Polygon = "Polygon",
  AVAX = "Avalanche C-Chain",
}
