import clsx from "clsx"
import { format, isValid, parseISO } from "date-fns"
import { range } from "lodash"
import { CSSProperties, FC, useCallback, useMemo } from "react"
import {
  createContainer,
  LineSegment,
  VictoryAxis,
  VictoryChart,
  VictoryCursorContainerProps,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainerProps,
} from "victory"
import { CRPSimulatorDayData } from "../types"

export interface CRPSimulatorChartProps {
  className?: string
  data: CRPSimulatorDayData[]
  liquidations: Array<{
    platformName: string
    date: string
  }>
}

const colors = {
  ltv: "#2563eb",
  price: "#f97316",
  weight: "#10b981",
  label: "#ffffff",
}

const Container = createContainer<
  VictoryVoronoiContainerProps,
  VictoryCursorContainerProps
>("voronoi", "cursor")

interface ChartData {
  x: string
  date: string
  ltv: number
  weight: number
  price: number
  liquidationPoint: boolean
  liquidationPlatform?: string
}

export const CRPSimulatorChart: FC<CRPSimulatorChartProps> = props => {
  const liquidationDates = useMemo(() => {
    return props.liquidations.map(x => x.date)
  }, [props.liquidations])
  const chartData: ChartData[] = useMemo(
    () =>
      props.data.map(datum => ({
        x: datum.date,
        date: datum.date,
        ltv: datum.ltv,
        weight: datum.yWeight,
        price: datum.yPrice,
        liquidationPoint: liquidationDates.includes(datum.date),
        liquidationPlatform: props.liquidations
          .filter(x => x.date === datum.date)
          .map(x => x.platformName)
          .join(" & "),
      })),
    [liquidationDates, props.data, props.liquidations],
  )
  const maxPrice = useMemo(
    () => Math.max(0, ...chartData.map(datum => datum.price)),
    [chartData],
  )
  const maxPriceCeil = useMemo(
    () => Math.max(1, Math.ceil(maxPrice / 10000)) * 10000,
    [maxPrice],
  )
  const formatPrice = useCallback(
    (price: number) =>
      "$" +
      (price * maxPriceCeil).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
    [maxPriceCeil],
  )

  return (
    <div className={clsx("bg-[#13232e] rounded-lg", props.className)}>
      <VictoryChart
        width={640}
        height={400}
        padding={{
          top: 30,
          right: 70,
          bottom: 75,
          left: 55,
        }}
        domain={{
          y: [0, 1],
        }}
        containerComponent={
          <Container
            voronoiDimension="x"
            cursorDimension="x"
            cursorComponent={
              <LineSegment style={{ stroke: "#fff", strokeWidth: 1 }} />
            }
            // cursorLabel={
            //   // lib's type annotation is wrong
            //   ((args: { data?: any; datum?: any }, extra: any) => {
            //     return formatDate(args?.datum?.date || extra)
            //   }) as any
            // }
            cursorLabelComponent={
              <VictoryLabel
                style={{
                  fontSize: 10,
                  fontWeight: 700,
                  fill: "#fff",
                }}
              />
            }
          />
        }
      >
        {/* X-axis */}
        <VictoryAxis
          name="x-axis"
          tickFormat={formatDate}
          tickCount={6}
          style={{
            axis: { stroke: "none" },
            grid: { stroke: "none" },
            ticks: { stroke: "none", size: 0 },
            tickLabels: {
              fontSize: 10,
              fontWeight: 400,
              padding: 8,
              fill: "#4b5563",
            },
          }}
        />
        {/* Y-axis on the left for percentage */}
        <VictoryAxis
          name="y-axis-percentage"
          dependentAxis
          tickValues={range(0, 11).map(x => x / 10)}
          tickFormat={formatPercentage}
          style={{
            axis: { stroke: "none" },
            grid: { stroke: "#374151" },
            ticks: { stroke: "none", size: 0 },
            tickLabels: {
              fontSize: 12,
              fontWeight: 400,
              padding: 11,
              fill: "#9ca3af",
            },
          }}
        />
        {/* Y-axis on the right for btc price */}
        <VictoryAxis
          name="y-axis-price"
          dependentAxis
          tickValues={range(0, maxPriceCeil / 10000 + 1).map(
            y => (y * 10000) / maxPriceCeil,
          )}
          tickFormat={formatPrice}
          offsetX={590}
          style={{
            axis: { stroke: "none" },
            grid: { stroke: "none" },
            ticks: { stroke: "none", size: 0 },
            tickLabels: {
              fontSize: 12,
              fontWeight: 400,
              fill: "#9ca3af",
              textAnchor: "start",
            },
          }}
        />
        {/* legend */}
        <VictoryLegend
          x={190}
          y={360}
          orientation="horizontal"
          gutter={32}
          data={[
            { name: "LTV", symbol: { fill: colors.ltv } },
            { name: "xBTC Price", symbol: { fill: colors.price } },
            { name: "Weight to xBTC", symbol: { fill: colors.weight } },
          ]}
          style={{
            border: { stroke: "none" },
            labels: {
              fontSize: 10,
              fontWeight: 400,
              fill: "#ffffff",
            },
          }}
        />
        {/* LTV line */}
        <VictoryLine
          name="ltv-line"
          data={chartData}
          y="ltv"
          style={{
            data: lineDataStyle(colors.ltv),
          }}
        />
        <VictoryScatter
          name="ltv-point"
          data={chartData}
          y="ltv"
          size={pointSize}
          labels={({ active, datum }) =>
            active ? formatPercentage(datum.ltv) : ""
          }
          style={pointStyle(colors.ltv)}
        />

        {/* btc price line */}
        <VictoryLine
          name="price-line"
          data={chartData}
          y={datum => datum.price / maxPriceCeil}
          style={{
            data: lineDataStyle(colors.price),
          }}
        />
        <VictoryScatter
          name="price-point"
          data={chartData}
          y={datum => datum.price / maxPriceCeil}
          size={({ active, datum }: { active: boolean; datum: ChartData }) =>
            datum.liquidationPoint || active ? 4.5 : 0
          }
          labels={({ active, datum }: { active: boolean; datum: ChartData }) =>
            active
              ? formatPrice(datum.price / maxPriceCeil)
              : datum.liquidationPoint
              ? `[${datum.liquidationPlatform}] liquidation`
              : ""
          }
          style={{
            data: pointDataStyle(colors.price),
            labels: {
              ...pointLabelStyle(colors.price),
              fill: (args: any) => (args?.active ? colors.price : colors.label),
            },
          }}
        />
        {/* btc weight line */}
        <VictoryLine
          name="weight-line"
          data={chartData}
          y="weight"
          style={{
            data: lineDataStyle(colors.weight),
          }}
        />
        <VictoryScatter
          name="weight-point"
          data={chartData}
          y="weight"
          size={pointSize}
          labels={({ active, datum }) =>
            active ? formatPercentage(datum.weight) : ""
          }
          style={pointStyle(colors.weight)}
        />
      </VictoryChart>
    </div>
  )
}

const pointStyle = (
  color: string,
): { data: CSSProperties; labels: CSSProperties } => ({
  data: pointDataStyle(color),
  labels: pointLabelStyle(color),
})
const pointDataStyle = (color: string): CSSProperties => ({
  fill: "#fff",
  stroke: color,
  strokeWidth: 2,
})
const pointLabelStyle = (color: string): CSSProperties => ({
  fontSize: 10,
  fontWeight: 700,
  fill: color,
})
const pointSize = ({ active }: { active: boolean }): number =>
  active ? 4.5 : 0
const lineDataStyle = (color: string): CSSProperties => ({
  stroke: color,
  strokeWidth: 2,
})

const formatPercentage = (num: number): string => `${(num * 100).toFixed(0)}%`
const formatDate = (dateString: string): string => {
  if (!isValid(parseISO(dateString))) {
    console.warn(`invalid dateString: ${dateString}`)
    return ""
  }
  return format(parseISO(dateString), "LLL d")
}
