import {
  AddressTransactionsWithTransfersListResponse,
  AddressTransactionWithTransfers,
  MempoolTransaction,
} from "@stacks/stacks-blockchain-api-types"
import { range, reverse } from "lodash"
import { API_HOST } from "../../config"
import pLimit from "../../utils/pLimit"
import { defer } from "../../utils/promiseHelpers"

const PAGE_SIZE = 50

function getAddressTransactionsWithTransfers(
  apiHost: string,
  stxAddress: string,
  { limit, offset = 0 }: { limit: number; offset?: number },
): Promise<AddressTransactionsWithTransfersListResponse> {
  return fetch(
    `${apiHost}/extended/v1/address/${stxAddress}/transactions_with_transfers?limit=${limit}&offset=${offset}`,
  ).then(r =>
    r.ok
      ? (r.json() as Promise<AddressTransactionsWithTransfersListResponse>)
      : Promise.reject(new Error(r.statusText)),
  )
}

export async function* fetchNewContractCallTransactionsWithTransfers(
  stxAddress: string,
  existing = 0,
): AsyncGenerator<AddressTransactionWithTransfers[]> {
  const first = await getAddressTransactionsWithTransfers(
    API_HOST,
    stxAddress,
    { limit: 5 },
  )
  if (first.total === existing) {
    return
  }
  if (first.total - existing <= first.results.length) {
    yield first.results.slice(0, first.total - existing)
  }
  const remainingCount = first.total - first.results.length - existing
  const limit = pLimit(5)
  const requests = reverse(
    range(Math.ceil(remainingCount / PAGE_SIZE)).map(index => ({
      defer: defer<AddressTransactionWithTransfers[]>(),
      page: index,
    })),
  )
  requests.forEach(({ page, defer }) =>
    limit(() =>
      getAddressTransactionsWithTransfers(API_HOST, stxAddress, {
        limit: PAGE_SIZE,
        offset: first.results.length + PAGE_SIZE * page,
      }).then(r => defer.resolve(r.results)),
    ),
  )
  for (const data of requests) {
    yield await data.defer.promise
  }
  yield first.results
}

export async function fetchMemPoolTransactions(
  stxAddress: string,
): Promise<MempoolTransaction[]> {
  const response = await fetch(
    `${API_HOST}/extended/v1/address/${stxAddress}/mempool?limit=50`,
  ).then(r => (r.ok ? r.json() : Promise.reject(new Error(r.statusText))))
  return response.results
}
