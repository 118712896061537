import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["alex-lottery", "lottery-claim-helper"] as const
const functionName = "claim" as const

export interface LotteryWonRowData extends BaseRowData {
  type: NotifyTransactionType.LotteryWon
  lotteryId: number
  winningTickets?: number
  winningCurrencyAmount: CurrencyAmount
  prize?: number
}

export const lotteryWonTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  LotteryWonRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.LotteryWon,
  confirmedTransformer: (helpers, context) => {
    if (!context?.address) {
      throw new Error("context of address not found")
    }
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, context.address)

    const winningTicketsCount = args.winners.filter(
      x => x === context.address,
    ).length

    const tokenTrait = args["token-trait"] as Currency

    return {
      lotteryId: args["lottery-id"],
      winningTickets: winningTicketsCount,
      winningCurrencyAmount: {
        currency: tokenTrait,
        amount: transferAmounts[tokenTrait]?.in ?? undefined,
      },
      prize: getPrizeFromRoundId(args["round-id"]),
    }
  },
  pendingOrFailedTransformer: args => {
    // TODO: need context for pending tx
    const tokenTrait = args["token-trait"] as Currency

    return {
      lotteryId: args["lottery-id"],
      winningCurrencyAmount: {
        currency: tokenTrait,
      },
      prize: getPrizeFromRoundId(args["round-id"]),
    }
  },
}

function getPrizeFromRoundId(roundId: number): number {
  return 4 - roundId
}
