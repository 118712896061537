import { FC, ReactNode, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Column, useTable } from "react-table"
import { APowerTip } from "../../../../components/APowerTip/APowerTip"
import {
  CardModalContent,
  TitleBarWithLeftArea,
} from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { MayDollarSignTokenName } from "../../../../components/MayDollarSignTokenName"
import { PercentNumber } from "../../../../components/PercentNumber"
import { SegmentSwitch } from "../../../../components/SegmentSwitch"
import { Spensor } from "../../../../components/Spensor"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../../components/table/StyledTable"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { customReactTableRender } from "../../../../utils/customReactTableRender"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { FCC } from "../../../../utils/reactHelpers/types"
import {
  readResource,
  SuspenseResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { useAsyncFnFactory } from "../../../../utils/useAsync"
import { CycleStatusBadge } from "../../components/CycleStatusBadge"
import { LoadMoreButton } from "../../components/LoadMoreButton/LoadMoreButton"
import { StakeCycle, StakeCycleStatus } from "../../types"

export interface AllCyclesModalContentProps {
  stakingToken: TokenInfo
  cycles: SuspenseResource<StakeCycle[]>
  tab: "live" | "finished"
  isHasMore: boolean
  onSelectTab?: (tab: "live" | "finished") => void
  onLoadMore?: () => void | Promise<void>
  onClose?: () => void
}

export const AllCyclesModalContent: FC<AllCyclesModalContentProps> = props => {
  const { $t } = useIntl()

  return (
    <Frame
      title={
        <>
          {$t(
            defineMessage({
              defaultMessage: "All Cycles ({tokenName})",
              description:
                "/Stake/ManualStake/AllCyclesModalContent/Frame title",
            }),
            {
              tokenName: <TokenName token={props.stakingToken} />,
            },
          )}
        </>
      }
      queryStatus={props.tab}
      onChangeQueryStatus={props.onSelectTab}
      onClose={props.onClose}
    >
      <Spensor fallback={<LoadingIndicator className={"m-6"} />}>
        {() => (
          <DataTable
            tab={props.tab}
            cycles={readResource(props.cycles)}
            isHasMore={props.isHasMore}
            onLoadMore={props.onLoadMore}
          />
        )}
      </Spensor>
    </Frame>
  )
}

const DataTable: FC<{
  tab: "live" | "finished"
  cycles: StakeCycle[]
  isHasMore: boolean
  onLoadMore?: () => void | Promise<void>
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema({ tab: props.tab }),
    data: props.cycles,
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const propOnLoadMore = props.onLoadMore
  const [loadMoreReq, onLoadMore] = useAsyncFnFactory(
    () => async () => propOnLoadMore?.(),
    [propOnLoadMore],
  )

  return (
    <div className={"flex-1 w-full flex flex-col items-center mt-3.5"}>
      <div className={"flex-1 w-full overflow-scroll"}>
        <Table {...getTableProps()} className={"flex-1"}>
          <Thead>
            {headerGroups.map(g => (
              <TableHeadRow {...g.getHeaderGroupProps()}>
                {g.headers.map(cell =>
                  cell.id === tableActionColumnId ? null : (
                    <Th
                      {...cell.getHeaderProps()}
                      colSpan={cell.id === tablePrincipalColumnId ? 2 : 1}
                    >
                      {customReactTableRender(cell, "Header")}
                    </Th>
                  ),
                )}
              </TableHeadRow>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()} className={"h-full overflow-scroll"}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <TableBodyRow {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td {...cell.getCellProps()} className={"break-words"}>
                      {cell.render("Cell")}
                    </Td>
                  ))}
                </TableBodyRow>
              )
            })}
          </Tbody>
        </Table>
      </div>
      {props.isHasMore && (
        <LoadMoreButton
          className={"mt-4"}
          loading={loadMoreReq.loading}
          onLoadMore={onLoadMore}
        />
      )}
    </div>
  )
}

const Frame: FCC<{
  title: ReactNode
  queryStatus: "live" | "finished"
  onChangeQueryStatus?: (status: "live" | "finished") => void
  onClose?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      layoutClassName={"flex flex-col items-center justify-center"}
      gapClassName={""}
      width={"80vw"}
      titleBar={
        <TitleBarWithLeftArea
          title={props.title}
          onClose={props.onClose}
          leftArea={
            <div
              className={
                "flex items-center justify-center md:items-start md:justify-start"
              }
            >
              <SegmentSwitch
                availableValues={[
                  {
                    label: $t(
                      defineMessage({
                        defaultMessage: "Live",
                        description:
                          "/Stake/ManualStake/AllCyclesModalContent/Frame/Label",
                      }),
                    ),
                    value: "live",
                  },
                  {
                    label: $t(
                      defineMessage({
                        defaultMessage: "Finished",
                        description:
                          "/Stake/ManualStake/AllCyclesModalContent/Frame/Label",
                      }),
                    ),
                    value: "finished",
                  },
                ]}
                value={props.queryStatus}
                onChange={props.onChangeQueryStatus}
              />
            </div>
          }
        />
      }
    >
      {props.children}
    </CardModalContent>
  )
}

const tablePrincipalColumnId = "cyclePrincipalColumn"
const tableActionColumnId = "cycleActionColumn"

const useTableSchema = (context: {
  tab: "live" | "finished"
}): Column<StakeCycle>[] => {
  const { $t } = useIntl()
  return useMemo(
    () => [
      {
        id: "cycleNumber",
        Header: $t(
          defineMessage({
            defaultMessage: "Cycle",
            description:
              "/Stake/ManualStake/AllCyclesModalContent/Table header",
          }),
        ),
        accessor: "cycleNumber",
        width: 122,
        Cell: props => (
          <div className={"flex flex-row items-center gap-2.5"}>
            <Tooltip
              title={$t(
                defineMessage({
                  defaultMessage: "Block {fromBlock} - {toBlock}",
                  description:
                    "/Stake/ManualStake/AllCyclesModalContent/Table header tooltip",
                }),
                {
                  fromBlock: props.row.original.fromBlock,
                  toBlock: props.row.original.targetBlock - 1,
                },
              )}
            >
              <span className={"font-bold"}>
                #{props.row.original.cycleNumber}
              </span>
            </Tooltip>
            <CycleStatusBadge status={props.row.original.cycleStatus} />
          </div>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "APR",
            description:
              "/Stake/ManualStake/AllCyclesModalContent/Table header",
          }),
        ),
        accessor: "apr",
        Cell: props => <PercentNumber number={props.row.original.apr} />,
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Total Staked",
            description:
              "/Stake/ManualStake/AllCyclesModalContent/Table header",
          }),
        ),
        accessor: "totalStaked",
        Cell: props => (
          <Spensor>
            {() => {
              const value = readResource(props.row.original.totalStaked)
              return (
                <>
                  {value.count != null ? (
                    <TokenCount token={value.token} count={value.count} />
                  ) : (
                    "--"
                  )}
                  &nbsp;
                  <TokenName token={value.token} />
                </>
              )
            }}
          </Spensor>
        ),
      },
      {
        Header: props => (
          <>
            {$t(
              defineMessage({
                defaultMessage: "My {tokenName} Staking",
                description:
                  "/Stake/ManualStake/AllCyclesModalContent/Table header",
              }),
              {
                tokenName:
                  props.data[0] == null ? (
                    ""
                  ) : (
                    <MayDollarSignTokenName
                      token={props.data[0].myStaked.token}
                    />
                  ),
              },
            )}
          </>
        ),
        accessor: "myStaked",
        Cell: props =>
          props.row.original.myStaked == null ? null : (
            <>
              <TokenCount
                token={props.row.original.myStaked.token}
                count={props.row.original.myStaked.count}
              />
              &nbsp;
              <TokenName token={props.row.original.myStaked.token} />
            </>
          ),
      },
      {
        Header:
          context.tab === "live"
            ? $t(
                defineMessage({
                  defaultMessage: "Est. Rewards",
                  description:
                    "/Stake/ManualStake/AllCyclesModalContent/Table header",
                }),
              )
            : $t(
                defineMessage({
                  defaultMessage: "Rewards",
                  description:
                    "/Stake/ManualStake/AllCyclesModalContent/Table header",
                }),
              ),
        accessor: "rewardToClaim",
        Cell: props => (
          <ul>
            {props.row.original.rewardToClaim.map((i, idx) => (
              <li key={idx} className={"flex flex-row items-center"}>
                <TokenCount token={i.token} count={i.count} />
                &nbsp;
                <TokenName token={i.token} />
                {TokenInfoPresets.isAPower(i.token) && (
                  <>
                    &nbsp;
                    <APowerTip />
                  </>
                )}
              </li>
            ))}
          </ul>
        ),
      },
      {
        id: tablePrincipalColumnId,
        Header: $t(
          defineMessage({
            defaultMessage: "Principal to be claimed",
            description:
              "/Stake/ManualStake/AllCyclesModalContent/Table header",
          }),
        ),
        accessor: "principalToClaim",
        Cell: props => (
          <ul>
            {props.row.original.principalToClaim.map((i, idx) => (
              <li key={idx} className={"flex flex-row items-center"}>
                <TokenCount token={i.token} count={i.count} />
                &nbsp;
                <TokenName token={i.token} />
                {TokenInfoPresets.isAPower(i.token) && (
                  <>
                    &nbsp;
                    <APowerTip />
                  </>
                )}
              </li>
            ))}
          </ul>
        ),
      },
      {
        id: tableActionColumnId,
        accessor: "cycleStatus",
        Header: "",
        Cell: props => <SmartBlockTimeCountdown cell={props.row.original} />,
      },
    ],
    [context.tab, $t],
  )
}

const SmartBlockTimeCountdown: FC<{ cell: StakeCycle }> = props => {
  if (props.cell.cycleStatus !== StakeCycleStatus.InProgress) {
    return null
  }

  return (
    <BlockTimeCountdown
      className={"w-fit-content"}
      targetBlock={props.cell.targetBlock}
      currentBlock={suspenseResource(() => props.cell.currentBlock)}
      time={suspenseResource(() => props.cell.targetDate)}
    />
  )
}
