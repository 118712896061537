import { RefObject, useEffect, useState } from "react"

export const useIsFocusing = (ref: RefObject<HTMLElement>): boolean => {
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    const el = ref.current

    if (!el) return

    el.addEventListener("focus", onFocus)
    el.addEventListener("blur", onBlur)

    return () => {
      el.removeEventListener("focus", onFocus)
      el.removeEventListener("blur", onBlur)
    }

    function onFocus(): void {
      setIsFocus(true)
    }
    function onBlur(): void {
      setIsFocus(false)
    }
  }, [ref])

  return isFocus
}
