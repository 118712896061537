import { action, computed, makeObservable, observable } from "mobx"
import { assertNever } from "../../utils/types"

interface DefaultState {
  type: "default"
}
interface SavedState {
  type: "saved"
  slippage: number
}
interface UserEditing {
  type: "userEditing"
  prevState: SlippageStoreFinalState
  nextState?: SlippageStoreFinalState
}

type SlippageStoreFinalState = SavedState | DefaultState

type SlippageStoreState = DefaultState | SavedState | UserEditing

function isSlippageStoreFinalState(
  state: SlippageStoreState,
): state is SlippageStoreFinalState {
  switch (state.type) {
    case "default":
    case "saved":
      return true
    case "userEditing":
      return false
    default:
      assertNever(state)
  }
}

export class SlippageStore {
  constructor(readonly defaultPercent = 4) {
    makeObservable(this)
  }

  @observable.ref state: SlippageStoreState = {
    type: "default",
  }

  @observable showSlippagePop = false

  @observable private editingValue?: string

  @computed get slippagePercentage(): number {
    if (isSlippageStoreFinalState(this.state)) {
      return this.getSlippageFromFinalState(this.state) / 100
    } else if (this.state.type === "userEditing") {
      return this.getSlippageFromFinalState(this.state.prevState) / 100
    } else {
      assertNever(this.state)
    }
  }

  private getSlippageFromFinalState(state: SlippageStoreFinalState): number {
    switch (state.type) {
      case "default":
        return this.defaultPercent
      case "saved":
        return state.slippage
      default:
        assertNever(state)
    }
  }

  @action showSlippagePopModal(): void {
    if (this.state.type === "default" || this.state.type === "userEditing") {
      // do nothing
    } else if (this.state.type === "saved") {
      this.editingValue = String(this.state.slippage)
      this.state = {
        type: "userEditing",
        prevState: this.state,
        nextState: this.state,
      }
    } else {
      assertNever(this.state)
    }

    this.showSlippagePop = true
  }

  @action save(): void {
    if (isSlippageStoreFinalState(this.state)) {
      // do nothing
    } else if (this.state.type === "userEditing") {
      if (this.state.nextState) {
        this.state = this.state.nextState
      } else {
        this.state = this.state.prevState
      }
    } else {
      assertNever(this.state)
    }

    this.showSlippagePop = false
  }

  @action closeClicked(): void {
    if (isSlippageStoreFinalState(this.state)) {
      // do nothing
    } else if (this.state.type === "userEditing") {
      this.state = this.state.prevState
    } else {
      assertNever(this.state)
    }

    this.showSlippagePop = false
  }

  @action setInputToDefault(): void {
    this.changeNextState({ type: "default" })
  }

  get isDefaultInput(): boolean {
    if (this.state.type === "userEditing") {
      return this.state.nextState?.type === "default"
    } else {
      return this.state.type === "default"
    }
  }
  get inputValue(): string {
    return (
      (isSlippageStoreFinalState(this.state) ? undefined : this.editingValue) ??
      ""
    )
  }
  @action setInputValue(val: string): void {
    this.editingValue = val
    const value = getValidValue(val)
    this.changeNextState(
      value == null ? undefined : { type: "saved", slippage: value },
    )
  }

  @action changeNextState(
    nextState: undefined | SlippageStoreFinalState,
  ): void {
    this.state = {
      type: "userEditing",
      prevState: isSlippageStoreFinalState(this.state)
        ? this.state
        : this.state.prevState,
      nextState,
    }
  }

  @computed get isInputValid(): boolean {
    if (this.state.type !== "userEditing") return true
    return getValidValue(this.editingValue) != null
  }

  @action reset(): void {
    this.state = {
      type: "default",
    }
  }
}

function getValidValue(inputValue?: string): undefined | number {
  if (inputValue == null) return
  if (inputValue === "") return

  const value = Number(inputValue)
  if (Number.isNaN(value)) return

  if (value > 100) return
  if (value < 0) return

  return value
}
