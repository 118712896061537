import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { B20TokenListStore } from "./B20TokenListStore"

const { Provider, useStore, ContextBridgeSymbol } =
  createStore<B20TokenListStore>("B20TokenListStore")

export const useB20TokenListStore = useStore

export const B20TokenListContextBridgeSymbol = ContextBridgeSymbol

export const B20TokenListStoreProvider: FCC = props => {
  const currencyStore = useCurrencyStore()
  const authStore = useAuthStore()
  const chainStore = useChainStore()

  const store = useCreation(
    () => new B20TokenListStore(currencyStore, authStore, chainStore),
    [authStore, chainStore, currencyStore],
  )
  return <Provider store={store}>{props.children}</Provider>
}
