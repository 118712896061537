import { sum } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"
import { computedFn } from "mobx-utils"
import { IS_MAIN_NET } from "../../../../config"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import TimerValue from "../../../../stores/TimerValue"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { Currency } from "../../../../utils/alexjs/Currency"
import { waitUntil$, waitUntilExist$ } from "../../../../utils/waitFor"
import type { OrderbookAsset } from "../OrderbookStore.service/OrderbookStore.service"
import {
  UserBalance,
  getUserBalance,
} from "../OrderbookStore.service/OrderbookStore.service"
import { fetchPNLInfo, fetchingMyPointsInfo } from "./StxDxMyInfoModule.service"
import { StxDxStore } from "./StxDxStore"

export class StxDxMyInfoModule {
  constructor(readonly store: StxDxStore) {
    makeObservable(this)
  }

  @computed get hasRegistered$(): boolean {
    waitUntil$(() => this.store.authStore.supportSignTransaction$)
    return this.userId$ != null
  }

  userId = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([stxAddress]) =>
      asSender(stxAddress)
        .contract("stxdx-registry")
        .func("get-user-id")
        .call({ user: stxAddress }),
  )

  @computed get userId$(): number | undefined {
    return this.userId.value$
  }

  @computed get registeredUserId$(): number {
    if (this.userId$ == null) {
      throw waitUntilExist$(() => this.userId$)
    }
    return this.userId$
  }

  private authJWT = new SuspenseObservable<string>()
  @action saveAuthJWT(token: string): void {
    this.authJWT.set(token)
  }

  timerEveryMinute = new TimerValue(60 * 1000)

  @computed get jwtTokenExpired(): boolean {
    const token = this.authJWT.get()
    if (token == null) {
      return true
    }
    const exp = JSON.parse(atob(token.split(".")[1]!)).exp
    return exp < new Date(this.timerEveryMinute.value).getTime() / 1000 + 120 // 2 minutes buffer time
  }

  @computed get authJWT$(): string {
    if (this.jwtTokenExpired) {
      throw new Promise(() => null)
    }
    return this.authJWT.read$
  }

  #userBalances = new LazyValue(
    () => [this.authJWT$, this.store.info.allAssetIdMaps] as const,
    ([jwt, assetMap]) => getUserBalance(jwt, assetMap),
  )

  stxDxBalance$ = computedFn(
    (asset: OrderbookAsset): UserBalance | undefined => {
      if (!this.hasRegistered$) return undefined
      return this.#userBalances.value$.get(asset)
    },
  )

  @computed get isUsingRightNetwork$(): boolean {
    /**
     * Currently @stacks/connect is not able to provide current network info,
     * we need to keep following up on this feature
     *
     * https://github.com/hirosystems/connect/issues/263
     */
    return IS_MAIN_NET
  }

  txStore = new ConfirmTransactionStore()

  @observable showingSwitchNetworkGuide = false
  @action showSwitchNetworkGuide = (): void => {
    this.showingSwitchNetworkGuide = true
  }
  @action hideSwitchNetworkGuide = (): void => {
    this.showingSwitchNetworkGuide = false
  }

  currency = new SuspenseObservable<OrderbookAsset>(
    Currency.sUSDT as OrderbookAsset,
  )

  @computed get selectedTokenAvailableBalance$(): number {
    return this.stxDxBalance$(this.currency.read$)?.available ?? 0
  }

  @computed get selectedTokenLockedBalance$(): number {
    return this.stxDxBalance$(this.currency.read$)?.locked ?? 0
  }

  @computed get totalBalanceInUSD$(): number {
    return (
      sum(
        this.store.info.allAccessibleMarkets$.map(market => {
          const amount = this.stxDxBalance$(market.tradeToken)?.available ?? 0
          const price =
            this.store.info.marketSummary$(market.marketId).price ?? 0
          return amount * price
        }),
      ) + (this.stxDxBalance$(Currency.sUSDT as OrderbookAsset)?.available ?? 0)
    )
  }

  todayPNL = new LazyValue(
    () => [this.registeredUserId$, this.authJWT$] as const,
    ([uid, token]) => fetchPNLInfo(uid, token),
  )

  pointsStats = new LazyValue(
    () => ({ addr: this.store.authStore.stxAddress$ }),
    ({ addr }) => fetchingMyPointsInfo(addr),
  )
}
