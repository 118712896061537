import { ENV_NAME } from "../../../config"
import { assertExclude, checkNever } from "../../../utils/types"
import { ETHChain } from "../../appEnvStore/ETHChain"
import { AddEthereumChainParameter } from "../MetaMaskEthereumProvider/MetaMaskEthereumProviderRequests"

export namespace ETHChainInfo {
  const chainIdFromDecimals = (id: number): string => `0x${id.toString(16)}`

  export const EthereumChainId = chainIdFromDecimals(1)

  export const GoerliChainId = chainIdFromDecimals(5)

  export const SepoliaChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(11155111),
    chainName: "Sepolia",
    rpcUrls: ["https://rpc2.sepolia.org"],
    blockExplorerUrls: ["https://sepolia.etherscan.io"],
  }

  export const SepoliaForkChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(11155111),
    chainName: "Sepolia Fork",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://eth-rpc.alexgo.dev"],
  }

  export const BSCChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(56),
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  }

  export const BSCTestnetChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(97),
    chainName: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  }

  export const BSCTestnetForkChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(97),
    chainName: "BSC Testnet Fork",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bnb-rpc.alexgo.dev"],
  }

  export const PolygonChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(137),
    chainName: "Polygon Mainnet",
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  }

  export const AVAXChainInfo: AddEthereumChainParameter = {
    chainId: chainIdFromDecimals(43114),
    chainName: "Avalanche C-Chain",
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io"],
  }
}

export function ethChainToAddEthereumChainParameter(
  chain: ETHChain,
): undefined | string | AddEthereumChainParameter {
  switch (chain) {
    case ETHChain.Unknown:
      return undefined
    case ETHChain.Ethereum:
      return ETHChainInfo.EthereumChainId
    case ETHChain.Goerli:
      return ETHChainInfo.GoerliChainId
    case ETHChain.Sepolia:
      return ETHChainInfo.SepoliaChainInfo
    case ETHChain.SepoliaFork:
      return ETHChainInfo.SepoliaForkChainInfo
    case ETHChain.BSC:
      return ETHChainInfo.BSCChainInfo
    case ETHChain.BSCTest:
      return ETHChainInfo.BSCTestnetChainInfo
    case ETHChain.BSCTestFork:
      return ETHChainInfo.BSCTestnetForkChainInfo
    case ETHChain.Polygon:
      return ETHChainInfo.PolygonChainInfo
    case ETHChain.AVAX:
      return ETHChainInfo.AVAXChainInfo
    default:
      checkNever(chain)
  }
}

export function chainIdToETHChain(chainId: string): ETHChain {
  const restETHChain = assertExclude.i<Exclude<ETHChain, ETHChain.Unknown>>()

  if (chainId === ETHChainInfo.EthereumChainId) {
    return ETHChain.Ethereum
  }
  assertExclude(restETHChain, assertExclude.i<ETHChain.Ethereum>())

  if (chainId === ETHChainInfo.GoerliChainId) {
    return ETHChain.Goerli
  }
  assertExclude(restETHChain, assertExclude.i<ETHChain.Goerli>())

  if (
    ETHChainInfo.SepoliaChainInfo.chainId ===
    ETHChainInfo.SepoliaForkChainInfo.chainId
  ) {
    if (chainId === ETHChainInfo.SepoliaChainInfo.chainId) {
      if (ENV_NAME === "dev") {
        return ETHChain.SepoliaFork
      } else {
        return ETHChain.Sepolia
      }
    }
    assertExclude(restETHChain, assertExclude.i<ETHChain.Sepolia>())
    assertExclude(restETHChain, assertExclude.i<ETHChain.SepoliaFork>())
  } else {
    if (chainId === ETHChainInfo.SepoliaChainInfo.chainId) {
      return ETHChain.Sepolia
    }
    assertExclude(restETHChain, assertExclude.i<ETHChain.Sepolia>())

    if (chainId === ETHChainInfo.SepoliaForkChainInfo.chainId) {
      return ETHChain.SepoliaFork
    }
    assertExclude(restETHChain, assertExclude.i<ETHChain.SepoliaFork>())
  }

  if (chainId === ETHChainInfo.BSCChainInfo.chainId) {
    return ETHChain.BSC
  }
  assertExclude(restETHChain, assertExclude.i<ETHChain.BSC>())

  if (
    ETHChainInfo.BSCTestnetChainInfo.chainId ===
    ETHChainInfo.BSCTestnetForkChainInfo.chainId
  ) {
    if (chainId === ETHChainInfo.BSCTestnetChainInfo.chainId) {
      if (ENV_NAME === "dev") {
        return ETHChain.BSCTestFork
      } else {
        return ETHChain.BSCTest
      }
    }
    assertExclude(restETHChain, assertExclude.i<ETHChain.BSCTest>())
    assertExclude(restETHChain, assertExclude.i<ETHChain.BSCTestFork>())
  } else {
    if (chainId === ETHChainInfo.BSCTestnetChainInfo.chainId) {
      return ETHChain.BSCTest
    }
    assertExclude(restETHChain, assertExclude.i<ETHChain.BSCTest>())

    if (chainId === ETHChainInfo.BSCTestnetForkChainInfo.chainId) {
      return ETHChain.BSCTestFork
    }
    assertExclude(restETHChain, assertExclude.i<ETHChain.BSCTestFork>())
  }

  if (chainId === ETHChainInfo.PolygonChainInfo.chainId) {
    return ETHChain.Polygon
  }
  assertExclude(restETHChain, assertExclude.i<ETHChain.Polygon>())

  if (chainId === ETHChainInfo.AVAXChainInfo.chainId) {
    return ETHChain.AVAX
  }
  assertExclude(restETHChain, assertExclude.i<ETHChain.AVAX>())

  checkNever(restETHChain)
  console.error(`[chainIdToETHChain] Unknown chainId: ${chainId}`)
  return ETHChain.Unknown
}
