import clsx from "clsx"
import { noop } from "lodash"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../../../components/Card"
import { InfoList } from "../../../../../../components/InfoList/InfoList"
import {
  DefaultInfoListItem,
  InfoListItem,
} from "../../../../../../components/InfoList/InfoListItem"
import { InfoListItemDetail } from "../../../../../../components/InfoList/InfoListItemDetail"
import { InfoListItemTitle } from "../../../../../../components/InfoList/InfoListItemTitle"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../../../components/NoteParagraph/NoteParagraph"
import { useResponsiveValue } from "../../../../../../components/Themed/breakpoints"
import { TokenCount } from "../../../../../../components/TokenCount"
import { TokenName } from "../../../../../../components/TokenName"
import { trunc } from "../../../../../../utils/addressHelpers"
import { checkNever } from "../../../../../../utils/types"
import {
  PegInRequest,
  PegInRequestStatus,
} from "../DepositBrc20GuideModalContent"
import { AddressRow } from "./AddressRow"
import { ReactComponent as BtcIcon } from "./btc.svg"
import { ReactComponent as CompleteIcon } from "./complete.svg"
import { ReactComponent as FailedIcon } from "./failed.svg"
import { ReactComponent as PendingIcon } from "./pending.svg"
import { ReactComponent as RecievedIcon } from "./recieved.svg"

export const PegInRecord: FC<{
  className?: string
  record: PegInRequest
  onCopyBrc20ToBtcAddress?: () => void
}> = props => {
  const { $t } = useIntl()

  const { record } = props

  const truncate =
    useResponsiveValue({
      md: false,
    }) ?? true

  const bottomArea =
    record.status === PegInRequestStatus.Mempool ? (
      <AddressRow
        disabled={true}
        addressFontSizeClassName="text-base"
        address={$t(
          defineMessage({
            defaultMessage: "Waiting for the request to be approved....",
            description:
              "/OrderBook/BRC20 Peg Flow/DepositProgress/Description text",
          }),
        )}
        truncate={truncate}
        icon={<BtcIcon />}
        backgroundClassName={"bg-orange-700"}
        onCopy={noop}
      />
    ) : record.status === PegInRequestStatus.Pending ? (
      <div className="space-y-2.5">
        <p className="text-sm font-bold text-white">
          {$t(
            defineMessage({
              defaultMessage:
                "Send your BRC20 token the following Peg-in address：",
              description:
                "/OrderBook/BRC20 Peg Flow/DepositProgress/Description text",
            }),
          )}
        </p>
        <AddressRow
          addressFontSizeClassName="text-base"
          address={record.pegInAddress}
          truncate={truncate}
          icon={<BtcIcon />}
          backgroundClassName={"bg-orange-700"}
          onCopy={props.onCopyBrc20ToBtcAddress}
        />
      </div>
    ) : record.status === PegInRequestStatus.Rejected ? (
      <CardInset
        bgClassName={"bg-red-800"}
        boxClassName={"px-2.5 py-1"}
        roundClassName={"rounded"}
      >
        <NoteParagraph
          icon={<WarnIcon />}
          textColorClassName={"text-yellow-500"}
        >
          {$t(
            defineMessage({
              defaultMessage: `Don't transfer. Please re-submit a "transfer inscription ID" request`,
              description:
                "/OrderBook/BRC20 Peg Flow/DepositProgress/Description text",
            }),
          )}
        </NoteParagraph>
      </CardInset>
    ) : record.status === PegInRequestStatus.Processed ? null : (
      (checkNever(record.status), null)
    )

  return (
    <div className={clsx("bg-black/10 rounded-lg", props.className)}>
      <InfoList
        direction={"row"}
        listItemDirection={"column"}
        renderInfoListItem={p => (
          <DefaultInfoListItem {...p} className={clsx(p.className, "p-2.5")} />
        )}
      >
        <RecordRow
          title={$t(
            defineMessage({
              defaultMessage: "ID",
              description:
                "/OrderBook/BRC20 Peg Flow/Recent history table header",
            }),
          )}
          value={record.id}
        />
        <RecordRow
          title={$t(
            defineMessage({
              defaultMessage: "Token",
              description:
                "/OrderBook/BRC20 Peg Flow/Recent history table header",
            }),
          )}
          value={<TokenName token={record.token} />}
        />
        <RecordRow
          title={$t(
            defineMessage({
              defaultMessage: "Amount",
              description:
                "/OrderBook/BRC20 Peg Flow/Recent history table header",
            }),
          )}
          value={<TokenCount count={record.amount} token={record.token} />}
        />
        <RecordRow
          title={$t(
            defineMessage({
              defaultMessage: "Transfer Inscription ID",
              description:
                "/OrderBook/BRC20 Peg Flow/Recent history table header",
            }),
          )}
          value={
            <p title={record.pegInTransactionId}>
              {trunc(record.pegInTransactionId, {
                longerThan: 24,
                head: 10,
                tail: 10,
              })}
            </p>
          }
        />
        <RecordStatusRow status={record.status} />
      </InfoList>

      {bottomArea != null && (
        <div className="p-2.5 border-t border-white/[.15]">{bottomArea}</div>
      )}
    </div>
  )
}

const RecordRow: FC<{ title: string; value: ReactNode }> = props => (
  <InfoListItem className="flex flex-col space-y-1 justify-center">
    <InfoListItemTitle className="text-gray-400 text-xs">
      {props.title}
    </InfoListItemTitle>
    <InfoListItemDetail className="text-sm text-white">
      {props.value}
    </InfoListItemDetail>
  </InfoListItem>
)

const RecordStatusRow: FC<{ status: PegInRequestStatus }> = props => {
  const { $t } = useIntl()

  return (
    <RecordRow
      title={$t(
        defineMessage({
          defaultMessage: "Status",
          description: "/OrderBook/BRC20 Peg Flow/Recent history table header",
        }),
      )}
      value={
        <div
          className={clsx(
            "text-sm text-white flex items-center",
            props.status === PegInRequestStatus.Processed
              ? "text-lime-500"
              : props.status === PegInRequestStatus.Rejected
              ? "text-red-500"
              : "",
          )}
        >
          {props.status === PegInRequestStatus.Pending
            ? $t(
                defineMessage({
                  defaultMessage: `{icon} ID Received`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Recent history table status",
                }),
                {
                  icon: <RecievedIcon className="mr-2" />,
                },
              )
            : props.status === PegInRequestStatus.Processed
            ? $t(
                defineMessage({
                  defaultMessage: `{icon} Peg-in Complete`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Recent history table status",
                }),
                {
                  icon: <CompleteIcon className="mr-2" />,
                },
              )
            : props.status === PegInRequestStatus.Mempool
            ? $t(
                defineMessage({
                  defaultMessage: `{icon} Pending`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Recent history table status",
                }),
                {
                  icon: <PendingIcon className="mr-2" />,
                },
              )
            : $t(
                defineMessage({
                  defaultMessage: `{icon} Request Failed`,
                  description:
                    "/OrderBook/BRC20 Peg Flow/Recent history table status",
                }),
                {
                  icon: <FailedIcon className="mr-2" />,
                },
              )}
        </div>
      }
    />
  )
}
