import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { BlockTopTitleLine } from "../../../components/TokenInput/Block"
import {
  TokenBlock,
  TokenCountPair,
} from "../../../components/TokenInput/TokenBlock"

export interface RemoveLiquidityConfirmationProps {
  poolToken: TokenCountPair
  onConfirm?: () => void
  onClose?: () => void
}

const RemoveLiquidityConfirmation: FC<
  RemoveLiquidityConfirmationProps
> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      onClose={props.onClose}
      title={"Confirm Remove Liquidity"}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <TokenBlock
        topArea={
          <BlockTopTitleLine>
            {$t(
              defineMessage({
                defaultMessage: "LP Token",
                description: "/Pool/Remove liquidity confirmation",
              }),
            )}
          </BlockTopTitleLine>
        }
        tokens={[props.poolToken]}
      />
      <div className={"mt-3.5 flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Pool/Remove liquidity confirmation",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Pool/Remove liquidity confirmation",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}

export default RemoveLiquidityConfirmation
