import { isEqual, memoize, noop } from "lodash"
import {
  Observable,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from "rxjs"
import { components } from "../../../../generated/stxdx/types"
import { getUserBalances } from "../OrderbookStore.service/getUserBalances"
import { orderbookWebsocket } from "../websockets"
import { OrderbookMarketId } from "./StxDxMarket.service"

export type ResponseType = {
  type: "summary"
  market: OrderbookMarketId
  last_price: string
} & components["schemas"]["GetOrderBookResponse"] &
  components["schemas"]["GetMarketTradesResponse"]

export const getOrderbookSummaryFor = memoize(
  (market: OrderbookMarketId): Observable<ResponseType> =>
    orderbookWebsocket.pipe(
      switchMap(
        socket =>
          new Observable<ResponseType>(sink => {
            socket.emit("watch", { market })
            const handler = (name: string, data: ResponseType): void => {
              if (name === "summary" && data.market === market) {
                sink.next(data)
              }
            }
            socket.onAny(handler)
            return noop
          }),
      ),
      shareReplay(1),
      distinctUntilChanged(isEqual),
    ),
)

export const refreshOrderHistorySignal = memoize(
  (jwt: string): Observable<void> => {
    return getUserBalances(jwt).pipe(map(noop))
  },
)
