import {
  booleanT,
  defineContract,
  listT,
  noneT,
  numberT,
  optionalT,
  principalT,
  responseSimpleT,
  tupleT,
} from "../smartContractHelpers/codegenImport"

export const eventClaimHelper = defineContract({
  "event-claim-helper": {
    "claim-for-claimer": {
      input: [
        { name: "event-id", type: numberT },
        { name: "claimer", type: principalT },
        { name: "token-trait", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "create-event": {
      input: [
        { name: "token-trait", type: principalT },
        { name: "amount", type: numberT },
        { name: "start-timestamp", type: numberT },
        { name: "end-timestamp", type: numberT },
      ],
      output: responseSimpleT(numberT),
      mode: "public",
    },
    "send-excess-token": {
      input: [
        { name: "event-id", type: numberT },
        { name: "token-trait", type: principalT },
        { name: "receiver", type: principalT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-claim-many": {
      input: [
        { name: "event-id", type: numberT },
        {
          name: "claim-many",
          type: listT(tupleT({ amount: numberT, claimer: principalT })),
        },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "set-contract-owner": {
      input: [{ name: "owner", type: principalT }],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "update-event": {
      input: [
        { name: "event-id", type: numberT },
        { name: "token-trait", type: principalT },
        { name: "top-up-amount", type: numberT },
        { name: "start-timestamp", type: numberT },
        { name: "end-timestamp", type: numberT },
      ],
      output: responseSimpleT(booleanT),
      mode: "public",
    },
    "get-claim-or-default": {
      input: [
        { name: "event-id", type: numberT },
        { name: "claimer", type: principalT },
      ],
      output: numberT,
      mode: "readonly",
    },
    "get-claimed-or-default": {
      input: [
        { name: "event-id", type: numberT },
        { name: "claimer", type: principalT },
      ],
      output: booleanT,
      mode: "readonly",
    },
    "get-contract-owner": {
      input: [],
      output: responseSimpleT(principalT),
      mode: "readonly",
    },
    "get-event-details-or-fail": {
      input: [{ name: "event-id", type: numberT }],
      output: responseSimpleT(
        tupleT({
          allocated: numberT,
          claimed: numberT,
          deposited: numberT,
          "end-timestamp": numberT,
          "start-timestamp": numberT,
          token: principalT,
        }),
      ),
      mode: "readonly",
    },
    claimed: {
      input: tupleT({ claimer: principalT, event: numberT }),
      output: optionalT(booleanT),
      mode: "mapEntry",
    },
    claims: {
      input: tupleT({ claimer: principalT, event: numberT }),
      output: optionalT(numberT),
      mode: "mapEntry",
    },
    events: {
      input: numberT,
      output: optionalT(
        tupleT({
          allocated: numberT,
          claimed: numberT,
          deposited: numberT,
          "end-timestamp": numberT,
          "start-timestamp": numberT,
          token: principalT,
        }),
      ),
      mode: "mapEntry",
    },
    "contract-owner": { input: noneT, output: principalT, mode: "variable" },
    "event-nonce": { input: noneT, output: numberT, mode: "variable" },
    "temp-event-id": { input: noneT, output: numberT, mode: "variable" },
  },
} as const)
