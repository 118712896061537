import { gql } from "@urql/core"
import {
  FetchNewTokenomicsIntroQuery,
  FetchNewTokenomicsIntroQueryVariables,
} from "../../generated/graphql/graphql.generated"
import { fromGraphqlResult } from "../../generated/i18nHelpers/contentfulLongText"
import { gqlQuery } from "../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../utils/Observable/fromUrqlSource"
import { LazyValue } from "../LazyValue/LazyValue"

export class LongTextStore {
  newTokenomicsIntro = new LazyValue(
    () => [],
    () =>
      fromUrqlSource(
        gqlQuery<
          FetchNewTokenomicsIntroQuery,
          FetchNewTokenomicsIntroQueryVariables
        >(
          gql`
            query FetchNewTokenomicsIntro {
              longText(id: "1WHdQECzO3NdgKEA5Gxj4j") {
                cn: markdown(locale: "zh-CN")
                en: markdown(locale: "en-US")
              }
            }
          `,
        ),
        resp => fromGraphqlResult(resp.data.longText),
      ),
  )
}
