import { gql } from "@urql/core"
import {
  FetchOrderbookAcknowledgeTermQuery,
  FetchOrderbookAcknowledgeTermQueryVariables,
  FetchOrderbookTestnetSetupGuideQuery,
  FetchOrderbookTestnetSetupGuideQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { fromGraphqlResult } from "../../../../generated/i18nHelpers/contentfulLongText"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import { gqlQuery } from "../../../../utils/graphqlHelpers"

export class OrderbookLongTextI18nModule {
  acknowledgeTerm = new LazyValue(
    () => [],
    () =>
      fromUrqlSource(
        gqlQuery<
          FetchOrderbookAcknowledgeTermQuery,
          FetchOrderbookAcknowledgeTermQueryVariables
        >(
          gql`
            query FetchOrderbookAcknowledgeTerm {
              longText(id: "24g8C1UR2vGtg6dAanYE9") {
                cn: markdown(locale: "zh-CN")
                en: markdown(locale: "en-US")
              }
            }
          `,
        ),
        resp => fromGraphqlResult(resp.data.longText),
      ),
  )

  testnetSetupGuide = new LazyValue(
    () => [],
    () =>
      fromUrqlSource(
        gqlQuery<
          FetchOrderbookTestnetSetupGuideQuery,
          FetchOrderbookTestnetSetupGuideQueryVariables
        >(
          gql`
            query FetchOrderbookTestnetSetupGuide {
              longText(id: "1IFgzd8p2KdoHm98OVK87t") {
                cn: markdown(locale: "zh-CN")
                en: markdown(locale: "en-US")
              }
            }
          `,
        ),
        resp => fromGraphqlResult(resp.data.longText),
      ),
  )
}
