import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { MayDollarSignTokenName } from "../../../../components/MayDollarSignTokenName"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { Spensor, SuspenseProps } from "../../../../components/Spensor"
import { TokenBlock } from "../../../../components/TokenInput/TokenBlock"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { readResource } from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import {
  EstimateCycleDays,
  EstimateCycleDaysProps,
} from "../EstimateCycleDays/EstimateCycleDays"
import {
  StakeSubmissionSummary,
  StakeSubmissionSummaryProps,
} from "../StakeSubmissionSummary"

export interface ConfirmStakeModalContentProps
  extends SuspenseProps<StakeSubmissionSummaryProps>,
    EstimateCycleDaysProps {
  stakingToken: TokenInfo
  stakingTokenCount: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmStakeModalContent: FC<
  ConfirmStakeModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Staking",
          description: "/Stake/ManualStake/ConfirmStakeModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <TokenBlock
        tokens={[{ token: props.stakingToken, count: props.stakingTokenCount }]}
      />
      <EstimateCycleDays
        cycleCount={props.cycleCount}
        cycleTip={props.cycleTip}
        estimateDayCount={props.estimateDayCount}
      />
      <Spensor fallback={<LoadingIndicator size={40} />}>
        {() => (
          <StakeSubmissionSummary
            startedAtCycleNumber={readResource(props.startedAtCycleNumber)}
            endedAtCycleNumber={readResource(props.endedAtCycleNumber)}
            startedAtBlock={readResource(props.startedAtBlock)}
            endedAtBlock={readResource(props.endedAtBlock)}
          />
        )}
      </Spensor>
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "Once you have staked your {tokenName}, your tokens will be locked until the end of the staking cycle.",
            description: "/Stake/ManualStake/ConfirmStakeModalContent/Note",
          }),
          {
            tokenName: (
              <>
                <MayDollarSignTokenName token={props.stakingToken} />
                &nbsp;
                {TokenInfoPresets.isAlex(props.stakingToken)
                  ? ""
                  : $t(
                      defineMessage({
                        defaultMessage: "tokens",
                        description:
                          "/Stake/ManualStake/ConfirmStakeModalContent/Note/TokenText",
                      }),
                    )}
              </>
            ),
          },
        )}
      </NoteParagraph>
      <GradientFilledButton className={"mt-3.5"} onClick={props.onConfirm}>
        {$t(
          defineMessage({
            defaultMessage: "Confirm",
            description: "/Stake/ManualStake/ConfirmStakeModalContent/Button",
          }),
        )}
      </GradientFilledButton>
    </CardModalContent>
  )
}
