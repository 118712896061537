import { FC, ReactNode } from "react"
import { useAppEnvStore } from "../../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../../stores/chainStore/useChainStore"
import { createStore } from "../../../../utils/createStore"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import { useAutoStakeStore } from "../autoStaking/useAutoStakeStore"
import { useManualStakeStore } from "../manualStaking/useManualStakeStore"
import MixedStakingStore from "./MixedStakingStore"

const Store = createStore<MixedStakingStore>("MixedStakingStore")

export const MixedStakingStoreProvider: FC<{ children: ReactNode }> = props => {
  const chainStore = useChainStore()
  const authStore = useAuthStore()
  const appEnvStore = useAppEnvStore()
  const manual = useManualStakeStore()
  const auto = useAutoStakeStore()
  const store = useCreation(
    () =>
      new MixedStakingStore(manual, auto, chainStore, authStore, appEnvStore),
    [appEnvStore, authStore, auto, chainStore, manual],
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useMixedStakingStore = Store.useStore
