export type LotteryTicket =
  | LotteryTicket.Unknown
  | LotteryTicket.Won
  | LotteryTicket.Lose

export namespace LotteryTicket {
  export enum Type {
    Unknown = "Unknown",
    Won = "Won",
    Lose = "Lose",
  }

  export enum Status {
    WaitToClaim = "WaitToClaim",
    Sending = "Sending",
    Resending = "Resending",
    Sent = "Sent",
  }

  interface Common {
    number: number
  }

  export interface Unknown extends Common {
    type: Type.Unknown
  }

  export interface Won extends Common {
    type: Type.Won
    status: Status
    explorerLink?: string
    wonIDOTokenCount: number
  }

  export interface Lose extends Common {
    type: Type.Lose
    status: Status
    explorerLink?: string
    returnPriceTokenCount: number
  }
}
