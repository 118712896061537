import {
  AddressTransactionWithTransfers,
  ContractCallTransaction,
  MempoolContractCallTransaction,
  MempoolTransactionStatus,
  TransactionStatus,
} from "@stacks/stacks-blockchain-api-types"
import { action, computed, makeObservable, observable } from "mobx"
import { AlexContracts } from "../../../generated/smartContract/contracts_Alex"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import {
  getContractCallHelpers,
  isTransactionWithTransfers,
} from "../../../utils/contractHelpers"
import { getTransfers } from "../../../utils/transferHelpers"
import { isNotNull } from "../../../utils/utils"
import { PAGE_SIZE } from "../constants"
import * as types from "../types"
import {
  getContractCallTupleFromTx,
  getFilterFromGroup,
} from "./TransactionsModule.service"

export class CollectionModule<T extends types.BaseRowData = types.BaseRowData> {
  constructor(
    readonly name: string,
    readonly accountStore: Pick<AccountStore, "transactions$">,
    readonly authStore: Pick<AuthStore, "stxAddress$">,
    readonly currencyStore: Pick<CurrencyStore, "allTokenInfos$">,
    readonly collection: types.TransformerCollection,
  ) {
    makeObservable(this)
  }

  // pagination
  @observable pageIndex = 0

  @action setPageIndex(index: number): void {
    if (index < 0) {
      throw new Error(`Try to set negative index: ${index}`)
    }
    this.pageIndex = index
  }

  @computed get pagination$(): types.PaginationInfo {
    return {
      pageIndex: this.pageIndex,
      setPageIndex: this.setPageIndex.bind(this),
      pageSize: PAGE_SIZE,
      totalRows: this.txsCount.value$,
    }
  }

  // transactions
  /**
   * collection's filterFn composed from all transformerGroup's filterFn
   * transformerGroup's filterFn generate from contractCallsTuple if not provided
   */
  filterFn: types.NotifyTransactionFilter = tx =>
    this.transformerGroupNames.map(this.getFilterFn).some(filter => filter(tx))

  getTransformer$ = (
    value: AddressTransactionWithTransfers | MempoolContractCallTransaction,
  ): T | undefined => {
    if (isTransactionWithTransfers(value)) {
      return this.getConfirmedRowData$(value)
    }
    return this.getPendingOrFailedRowData$(value)
  }

  private getConfirmedRowData$ = (
    value: AddressTransactionWithTransfers,
  ): T | undefined => {
    return this.transformerGroupNames.reduce<T | undefined>((acc, curr) => {
      if (acc) {
        return acc
      }
      const filter = this.getFilterFn(curr)
      const tx = value.tx as ContractCallTransaction
      if (filter(tx)) {
        const tuple = getContractCallTupleFromTx(tx)
        const group = this.collection[curr]!
        return tx.tx_status === "success"
          ? getConfirmedRowData<any, any, any, { address: string }>(
              ...tuple,
              group,
              value,
              { address: this.authStore.stxAddress$ },
            )
          : getPendingOrFailedRowData<any, any, any>(...tuple, group, tx)
      }
      // eslint-disable-next-line array-callback-return
      return
    }, undefined)
  }

  private getPendingOrFailedRowData$ = (
    tx: MempoolContractCallTransaction,
  ): T | undefined => {
    return this.transformerGroupNames.reduce<T | undefined>((acc, curr) => {
      if (acc) {
        return acc
      }
      const filter = this.getFilterFn(curr)
      if (filter(tx)) {
        return getPendingOrFailedRowData<any, any, any>(
          ...getContractCallTupleFromTx(tx),
          this.collection[curr]!,
          tx,
        )
      }
      // eslint-disable-next-line array-callback-return
      return
    }, undefined)
  }

  @computed get rows$(): T[] {
    const txs = this.txs.value$.map(this.getTransformer$).filter(isNotNull)
    if (this.pageIndex === 0) {
      const txsIds = txs.map(t => t.id)
      return this.pendingTxs.value$
        .map(this.getTransformer$)
        .filter(isNotNull)
        .filter(t => !txsIds.includes(t.id))
        .concat(txs)
    }
    return txs
  }

  @computed get rowsToExport$(): T[] {
    return this.allTxsToExport.value$
      .map(this.getTransformer$)
      .filter(isNotNull)
  }

  // lazy values
  /**
   * Dexie has an opinionated way of paging (cursor base), but not suitable for our case.
   *
   * https://dexie.org/docs/Collection/Collection.offset()#a-better-paging-approach
   */
  private txs = new LazyValue(
    () =>
      [
        this.accountStore.transactions$,
        this.filterFn,
        this.pageIndex,
        PAGE_SIZE,
      ] as const,
    async ([transactions, filterFn, pageIndex, limit]) => {
      await transactions.sync()
      return transactions.table
        .orderBy("tx.burn_block_time")
        .reverse()
        .filter(value => filterFn(value.tx))
        .offset(pageIndex * limit)
        .limit(limit)
        .toArray()
    },
  )
  private txsCount = new LazyValue(
    () => [this.accountStore.transactions$, this.filterFn] as const,
    ([transactions, filterFn]) => {
      return transactions.table.filter(value => filterFn(value.tx)).count()
    },
  )
  private allTxsToExport = new LazyValue(
    () => [this.accountStore.transactions$, this.filterFn] as const,
    async ([transactions, filterFn]) => {
      await transactions.sync()
      return transactions.table
        .orderBy("tx.burn_block_time")
        .reverse()
        .filter(value => filterFn(value.tx))
        .toArray()
    },
  )
  private pendingTxs = new LazyValue(
    () => [this.accountStore.transactions$] as const,
    async ([transactions]) => {
      const txs = await transactions.fetchMemPoolTransactions()
      return txs.filter(this.filterFn) as MempoolContractCallTransaction[]
    },
  )

  // helpers
  @computed
  private get transformerGroupNames(): string[] {
    return Object.keys(this.collection)
  }

  // TODO: computedFn return error
  private getFilterFn = (
    transformerGroupName: string,
  ): types.NotifyTransactionFilter => {
    const group = this.collection[transformerGroupName]
    if (!group) {
      throw new Error("TransformerGroup not found: " + transformerGroupName)
    }
    return getFilterFromGroup(group)
  }
}

function getConfirmedRowData<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
  Row extends types.BaseRowData = types.BaseRowData,
  Context extends Record<string, unknown> = { address: string },
>(
  contractName: ContractName,
  functionName: FunctionName,
  group: types.TransformerGroup<[ContractName], FunctionName, Row, Context>,
  value: AddressTransactionWithTransfers,
  context?: Context,
): Row | undefined {
  const tx = value.tx as ContractCallTransaction
  const helpers = {
    ...getContractCallHelpers<ContractName, FunctionName>(
      contractName,
      functionName,
      tx,
    ),
    getTransfers: () => getTransfers(value),
    getTx: () => tx,
  }

  const transformed = group.confirmedTransformer(helpers, context)
  if (!transformed) {
    return
  }

  return {
    id: tx.tx_id,
    status: mapStatus(tx.tx_status),
    time: new Date(tx.burn_block_time * 1000),
    type: group.type,
    ...transformed,
  } as Row
}

function getPendingOrFailedRowData<
  ContractName extends keyof typeof AlexContracts,
  FunctionName extends keyof (typeof AlexContracts)[ContractName],
  Row extends types.BaseRowData = types.BaseRowData,
>(
  contractName: ContractName,
  functionName: FunctionName,
  group: types.TransformerGroup<[ContractName], FunctionName, Row>,
  tx: ContractCallTransaction | MempoolContractCallTransaction,
): Row | undefined {
  const { getArgs } = getContractCallHelpers(contractName, functionName, tx)

  const transformed = group.pendingOrFailedTransformer(getArgs(), tx)

  if (!transformed) {
    return
  }

  return {
    id: tx.tx_id,
    status: mapStatus(tx.tx_status),
    time: new Date(
      (tx as ContractCallTransaction).burn_block_time
        ? (tx as ContractCallTransaction).burn_block_time * 1000
        : (tx as MempoolContractCallTransaction).receipt_time * 1000,
    ),
    type: group.type,
    ...transformed,
  } as Row
}

function mapStatus(
  from: TransactionStatus | MempoolTransactionStatus,
): types.NotifyTransactionStatus {
  if (from === "success") {
    return types.NotifyTransactionStatus.Confirmed
  }
  if (from === "pending") {
    return types.NotifyTransactionStatus.Pending
  }
  return types.NotifyTransactionStatus.Failed
}
