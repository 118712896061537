import { FC } from "react"
import { Modal } from "../../../components/Modal"
import { TransactionStateDialogContentForGeneral } from "../../../components/TransactionStateDialog/TransactionStateDialog"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"

export const WiredMetaMaskTransactionStateModal: FC<{
  onClose?: () => void
}> = props => {
  const { wrapForm } = useWrapBridgeStore()

  const onClose = (): void => {
    props.onClose?.()
    wrapForm.metamaskTransactionStore.closeRunning()
  }

  if (!wrapForm.metamaskTransactionStore.running) return null

  return (
    <Modal visible={true} onClose={onClose}>
      <TransactionStateDialogContentForGeneral
        transactionState={wrapForm.metamaskTransactionStore.running}
        onClose={onClose}
      />
    </Modal>
  )
}
