import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { StxDxDepositModule } from "../modules/StxDxDepositModule"
import { StxDxWithdrawModule } from "../modules/StxDxWithdrawModule"
import { StxDxInfoModule } from "./StxDxInfoModule"
import { StxDxMyInfoModule } from "./StxDxMyInfoModule"

export class StxDxStore {
  constructor(
    readonly appEnv: AppEnvStore,
    readonly chainStore: ChainStore,
    readonly authStore: AuthStore,
    readonly currency: CurrencyStore,
    readonly account: AccountStore,
  ) {}

  myInfo = new StxDxMyInfoModule(this)
  info = new StxDxInfoModule(this, this.myInfo, this.currency)
  deposit = new StxDxDepositModule(this)
  withdraw = new StxDxWithdrawModule(
    this,
    this.account,
    this.chainStore,
    this.currency,
  )
}
