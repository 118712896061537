import { LaunchingStatus } from "../store/LaunchPadContractStore"

export enum IDOGovernanceVoteStatus {
  Upcoming = "upcoming",
  Ongoing = "ongoing",
  Approved = "approved",
  Rejected = "rejected",
}

export interface TokenMetaInfo {
  logoSrc: string
  tokenType: "stacksToken" | "brc20Token" | "ordinals"
  title: string
  subtitle?: string
  governanceVote?: {
    link: string
    status: IDOGovernanceVoteStatus
  }
  links: {
    website?: string
    twitter?: string
    medium?: string
    discord?: string
  }
}

export type TokenIDOStatus =
  | {
      type:
        | LaunchingStatus.Upcoming
        | LaunchingStatus.Registration
        | LaunchingStatus.Claim
    }
  | { type: LaunchingStatus.Finished; success: boolean }

export type TokenCountRange = number | [from: number, to: number]

export interface TokenIDOPrices {
  pricePerToken: number
  idoTokenCountPerTicket: number
  aPowerCountPerTicket: TokenCountRange
  pricePerTicket: number
}

export interface TokenIDORaiseNumbers {
  currentRaiseTicketCount: number
  minRaiseTicketCount: number
  maxRaiseTicketCount: number
  currentRaiseIdoTokenCount: number
  minRaiseIdoTokenCount: number
  maxRaiseIdoTokenCount: number
  currentRaisePriceTokenCount: number
  minRaisePriceTokenCount: number
  maxRaisePriceTokenCount: number
}

export interface TokenIDOSuccessInfo {
  fillRate: number
  totalAllocation: number
  currentActivation: number
}

export type APowerRuleRange = {
  start: number
  end: number
  amount: number
}

export interface TosItem {
  name: string
  link: string
  briefIntro: string
}
