import { useMemo, useSyncExternalStore } from "react"
import { isShallowEqual } from "../isShallowEqual"
import { useLatestValueRef } from "./useLatestValueRef"

export function useMediaQuery<Q extends string>(queries: Q[]): undefined | Q {
  const { current: queriesDep } = useLatestValueRef(queries, isShallowEqual)

  const [getSnapshot, subscribe] = useMemo(() => {
    const mediaQueryListAry = queriesDep.map(q => matchMedia(q))

    const getMatchedQuery = (): undefined | Q =>
      mediaQueryListAry.find(mql => mql.matches)?.media as Q

    return [
      getMatchedQuery,
      (notify: () => void) => {
        mediaQueryListAry.forEach(mql => mql.addEventListener("change", notify))
        return () => {
          mediaQueryListAry.forEach(mql =>
            mql.removeEventListener("change", notify),
          )
        }
      },
    ]
  }, [queriesDep])

  return useSyncExternalStore(subscribe, getSnapshot)
}
