import { createContext, useContext, useEffect, useId } from "react"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useRerender } from "../../utils/reactHelpers/useRerender"
import { DialogVisibleManager, DialogVisibleManagerFactory } from "./types"
import { createDialogVisibleStackManagerFactory } from "./_/DialogVisibleStackManager"

const DialogVisibleManagerContext = createContext<DialogVisibleManagerFactory>(
  createDialogVisibleStackManagerFactory(),
)

/**
 * Create a visibility manager, to ensure dialog is rendered one after another
 * by the order in which dialog is marked as visible
 */
export const useDialogVisibleManager = (
  managerReadableName?: string,
): DialogVisibleManager => {
  const factory = useContext(DialogVisibleManagerContext)

  const compId = useId() + (managerReadableName ?? "")

  const manager = useCreation(
    () => factory.create(compId),
    [compId, factory],
    manager => manager.destroy(),
  )

  const [, rerender] = useRerender()

  useEffect(() => manager.subscribe(rerender), [manager, rerender])

  return manager
}
