import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import NotifyStore from "../../../stores/notifyStore/NotifyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { YTPToken } from "../../../utils/alexjs/currencyHelpers"
import { isNotNull } from "../../../utils/utils"
import FarmStore from "../../Farm/stores/FarmStore"
import LendStore from "../../Lend/store/LendStore"
import ManualStakeStore from "../../Stake/store/manualStaking/ManualStakeStore"
import { StakableCurrency } from "../../Stake/store/manualStaking/ManualStakeStore.service"
import { SystemMessages } from "../types"

export const SYSTEM_MESSAGE_FILTER = "systemMessages"

export class SystemMessagesModule {
  constructor(readonly store: NotifyStore) {
    makeObservable(this)
  }

  @computed get stakeStore(): ManualStakeStore {
    return new ManualStakeStore(
      Currency.ALEX,
      this.store.appEnvStore,
      this.store.authStore,
      this.store.currencyStore,
      this.store.accountStore,
      this.store.chainStore,
    )
  }

  @computed get farmStore(): FarmStore {
    return new FarmStore(
      this.store.appEnvStore,
      this.store.authStore,
      this.store.currencyStore,
      this.store.accountStore,
      this.store.chainStore,
    )
  }

  lendPoolStore = memoize(
    (pool: YTPToken) =>
      new LendStore(
        pool,
        this.store.chainStore,
        this.store.authStore,
        this.store.accountStore,
        this.store.currencyStore,
      ),
  )
  allLendPools: YTPToken[] = [Currency.YTP_ALEX]
  @computed get allLendPoolStores(): LendStore[] {
    return this.allLendPools.map(pool => this.lendPoolStore(pool))
  }

  // system messages
  @computed get messages$(): SystemMessages.Data[] {
    const lendNotifications = this.store.appEnvStore.config$.enableLendFeature
      ? [
          ...this.claimableDeposit$
            .filter(record => record.amount > 0)
            .map(({ currency }) => ({
              type: SystemMessages.Types.DepositClaim,
              token: this.store.currencyStore.getTokenInfo$(currency),
            })),
          ...this.claimableBorrow$
            .filter(record => record.amount > 0)
            .map(({ currency }) => ({
              type: SystemMessages.Types.BorrowClaim,
              token: this.store.currencyStore.getTokenInfo$(currency),
            })),
        ]
      : []
    return [
      ...this.lpRewards$
        .filter(record => record.hasAnythingToClaim)
        .map(({ currency }) => ({
          type: SystemMessages.Types.FarmingHarvest,
          token: this.store.currencyStore.getTokenInfo$(currency),
        })),
      this.stakeRewards$.hasAnythingToClaim
        ? {
            type: SystemMessages.Types.StakingHarvest,
            token: this.store.currencyStore.getTokenInfo$(Currency.ALEX),
          }
        : null,
      ...lendNotifications,
    ].filter(isNotNull)
  }

  @computed get count$(): number {
    return this.messages$.length
  }
  @computed get lpRewards$(): {
    currency: StakableCurrency
    hasAnythingToClaim: boolean
  }[] {
    return this.farmStore.myFarms$.map(stakeStore => ({
      currency: stakeStore.stakableToken,
      hasAnythingToClaim: stakeStore.myStaking.hasAnythingToClaim$,
    }))
  }
  @computed get stakeRewards$(): {
    currency: Currency.ALEX
    hasAnythingToClaim: boolean
  } {
    return {
      currency: Currency.ALEX,
      hasAnythingToClaim: this.stakeStore.myStaking.hasAnythingToClaim$,
    }
  }
  @computed get claimableDeposit$(): { currency: YTPToken; amount: number }[] {
    return this.allLendPoolStores.map(s => ({
      currency: s.poolToken,
      amount: s.claimableYieldToken$,
    }))
  }
  @computed get claimableBorrow$(): { currency: YTPToken; amount: number }[] {
    return this.allLendPoolStores.map(s => ({
      currency: s.poolToken,
      amount:
        s.myBorrow.claimableBreakdown$.collateral +
        s.myBorrow.claimableBreakdown$.uToken,
    }))
  }
}
