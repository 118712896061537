import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  FailedBadge,
  FinishedBadge,
  ProgressingBadge,
  UpcomingBadge,
} from "../../../components/Badge"
import { assertNever } from "../../../utils/types"
import { LaunchingStatus } from "../store/LaunchPadContractStore"
import { TokenIDOStatus } from "./types"

export const IDOStatusBadge: FC<{ status: TokenIDOStatus }> = props => {
  const { $t } = useIntl()
  const { status } = props
  switch (status.type) {
    case LaunchingStatus.Upcoming:
      return (
        <UpcomingBadge>
          {$t(
            defineMessage({
              defaultMessage: "upcoming",
              description: "/Launchpad/IDO status badge",
            }),
          )}
        </UpcomingBadge>
      )
    case LaunchingStatus.Registration:
    case LaunchingStatus.Claim:
      return (
        <ProgressingBadge>
          {$t(
            defineMessage({
              defaultMessage: "live",
              description: "/Launchpad/IDO status badge",
            }),
          )}
        </ProgressingBadge>
      )
    case LaunchingStatus.Finished:
      return status.success ? (
        <FinishedBadge>
          {$t(
            defineMessage({
              defaultMessage: "finished",
              description: "/Launchpad/IDO status badge",
            }),
          )}
        </FinishedBadge>
      ) : (
        <FailedBadge>
          {$t(
            defineMessage({
              defaultMessage: "failed",
              description: "/Launchpad/IDO status badge",
            }),
          )}
        </FailedBadge>
      )
    default:
      assertNever(status)
  }
}
