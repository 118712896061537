import { FC, Ref, useEffect } from "react"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { isBRC20Token } from "../../../../utils/alexjs/Currency"
import { hasAny } from "../../../../utils/arrayHelpers"
import { safelyGet, waitFor } from "../../../../utils/waitFor"
import { TransferPanel } from "../components/TransferPanel"
import { useOrderbookPegInStore } from "../stores/useOrderbookPegInStore"
import { WiredOrdinalsWalletConnectionLine } from "./WiredOrdinalsWalletConnectionLine"

export const WiredTransferPanel: FC<{
  className?: string
  containerRef?: Ref<HTMLElement>
  inProgressPanel?: React.ReactNode
}> = props => {
  const pegInStore = useOrderbookPegInStore()

  const { recentTransferable } = pegInStore

  /**
   * Auto select the first BRC20 token if none is selected
   */
  useEffect(() => {
    const abortCtrl = new AbortController()
    const { viewingBRC20Currency } = pegInStore.recentTransferable

    if (viewingBRC20Currency.get() != null) return

    void waitFor(() => pegInStore.recentTransferable.brc20WalletBalance$).then(
      balances => {
        if (abortCtrl.signal.aborted) return
        if (!hasAny(balances)) return
        if (viewingBRC20Currency.get() != null) return
        viewingBRC20Currency.set(balances[0].brc20Currency)
      },
    )

    return () => {
      abortCtrl.abort()
    }
  }, [pegInStore.recentTransferable])

  return (
    <TransferPanel
      className={props.className}
      containerRef={props.containerRef}
      ordinalsAddressInfo={<WiredOrdinalsWalletConnectionLine />}
      prerequisites={suspenseResource(() =>
        safelyGet(() => pegInStore.bitcoinWalletAddresses$) == null
          ? "connectWallet"
          : undefined,
      )}
      availableTokens={suspenseResource(
        () => recentTransferable.brc20WalletBalance$,
      )}
      selectedToken={suspenseResource(
        () => recentTransferable.viewingBRC20Token$,
      )}
      onSelectedToken={async token => {
        if (token == null) {
          recentTransferable.viewingBRC20Currency.set(undefined)
          return
        }

        const currency = TokenInfoPresets.toCurrency(token)
        if (!isBRC20Token(currency)) {
          return
        }

        recentTransferable.viewingBRC20Currency.set(currency)
      }}
      inProgressPanel={props.inProgressPanel}
    />
  )
}
