import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LoadableButton } from "../../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../../components/button/variants/RedFilledButton"
import { Card, CardTitle } from "../../../../../components/Card"
import { InfoList } from "../../../../../components/InfoList"
import { SlippageSettingsButton } from "../../../../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../../../../components/Spensor"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { BalanceBottomArea } from "../../../../../components/TokenInput/BalanceBottomArea"
import {
  BlockTopLine,
  renderThinnerBlock,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
  suspenseResource,
} from "../../../../../utils/SuspenseResource"
import { DepositInfoListContent } from "../DepositInfoListContent"
import { FormError, FormErrorType } from "./types"

export interface AddDepositPanelProps {
  className?: string

  depositToken: TokenInfo
  yieldToken: TokenInfo

  depositTokenCount: null | number
  setDepositTokenCount?: (newCount: null | number) => void
  depositTokenBalance: SuspenseResource<number>

  yieldTokenCount: SuspenseResource<number>

  slippage: number
  expirationDate: SuspenseResource<Date>
  expirationBlockHeight: SuspenseResource<number>
  estimateAbsoluteInterest: SuspenseResource<number>
  estimateClaimable: SuspenseResource<number>
  liquidityProviderFee: SuspenseResource<number>

  error?: SuspenseResource<FormError | undefined>
  onSubmit?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
  onClickSlippage?: () => void
}

export const AddDepositPanel: FC<AddDepositPanelProps> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx(props.className, "flex flex-col gap-4")}>
      <div className={"flex flex-row items-center justify-between"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Deposit",
              description: "/Lend/DepositPage/AddDepositPanel/title",
            }),
          )}
        </CardTitle>
        <div className="flex items-center gap-2">
          {props.onClear && (
            <HeadlessButton className={"text-blue-600"} onClick={props.onClear}>
              {$t(
                defineMessage({
                  defaultMessage: "Clear",
                  description: "/Lend/DepositPage/AddDepositPanel/button text",
                }),
              )}
            </HeadlessButton>
          )}
          <SlippageSettingsButton onClick={props.onClickSlippage} />
        </div>
      </div>
      <BlockGroup
        firstBlock={
          <TokenInput
            renderBlock={renderThinnerBlock}
            token={props.depositToken}
            error={isTokenInputShouldBeErrorState(
              safeReadResource(props.error),
            )}
            value={props.depositTokenCount}
            onChange={props.setDepositTokenCount}
            bottomArea={
              <BalanceBottomArea
                token={props.depositToken}
                balance={props.depositTokenBalance}
                onPressMax={suspenseResource(() => {
                  const balance = readResource(props.depositTokenBalance)
                  if (props.depositTokenCount === balance) return undefined
                  if (!balance) return
                  return () => {
                    props.setDepositTokenCount?.(balance)
                  }
                })}
              />
            }
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            renderBlock={renderThinnerBlock}
            topArea={
              <BlockTopLine>
                {$t(
                  defineMessage({
                    defaultMessage: "Yield {tipIcon}",
                    description: "/Lend/DepositPage/AddDepositPanel/Block text",
                  }),
                  {
                    tipIcon: (
                      <TipIcon
                        inline
                        tip={
                          <>
                            {$t(
                              defineMessage({
                                defaultMessage:
                                  "{tokenName} functions like a certificate of deposit that pays a fixed interest to its holder at a pre-defined maturity date",
                                description:
                                  "/Lend/DepositPage/AddDepositPanel/Block tooltip",
                              }),
                              {
                                tokenName: (
                                  <TokenName token={props.yieldToken} />
                                ),
                              },
                            )}
                          </>
                        }
                      />
                    ),
                  },
                )}
              </BlockTopLine>
            }
            tokens={[{ token: props.yieldToken, count: props.yieldTokenCount }]}
          />
        }
      />

      <InfoList listItemDirection={"row-responsive"}>
        <DepositInfoListContent
          depositToken={props.depositToken}
          expirationBlockHeight={props.expirationBlockHeight}
          expirationDate={props.expirationDate}
          estimateAbsoluteInterest={props.estimateAbsoluteInterest}
          estimateClaimableValue={props.estimateClaimable}
          slippage={props.slippage}
          liquidityProviderFee={props.liquidityProviderFee}
        />
      </InfoList>
      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(
              defineMessage({
                defaultMessage: "Deposit",
                description:
                  "/Lend/DepositPage/AddDepositPanel/Loadable button text",
              }),
            )}
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.error)
          if (error?.type === FormErrorType.WalletNotConnected) {
            return (
              <GradientFilledButton onClick={props.onConnectWallet}>
                {$t(
                  defineMessage({
                    defaultMessage: "Connect Wallet",
                    description:
                      "/Lend/DepositPage/AddDepositPanel/button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error != null) {
            return (
              <Button
                Variant={
                  isErrorStateButtonShouldRenderAsGradientButton(error)
                    ? GradientFilledButton
                    : RedFilledButton
                }
                disabled={true}
              >
                {error.message}
              </Button>
            )
          }
          return (
            <GradientFilledButton onClick={props.onSubmit} disabled={true}>
              {$t(
                defineMessage({
                  defaultMessage: "Deposit",
                  description: "/Lend/DepositPage/AddDepositPanel/button text",
                }),
              )}
            </GradientFilledButton>
          )
        }}
      </Spensor>
    </Card>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: FormError,
): boolean => {
  if (!error) return false
  return error.type === FormErrorType.AmountIsEmpty
}

const isTokenInputShouldBeErrorState = (error?: FormError): boolean => {
  if (!error) return false
  return (
    error.type === FormErrorType.InsufficientTokenBalance ||
    error.type === FormErrorType.LessThanMinimizeAmount
  )
}
