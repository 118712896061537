import clsx from "clsx"
import { FC } from "react"
import styles from "./LoadingIndicator.module.scss"

export interface LoadingIndicatorProps {
  className?: string

  /**
   * @default 36px
   */
  size?: number | string

  /**
   * @default (size / 2 * 0.3)
   */
  fillWidth?: number
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = props => {
  const { size = 36 } = props

  return (
    <i
      className={clsx("animate-spin", styles.indicator, props.className)}
      style={{
        display: "block",
        width: size,
        height: size,
        ...{
          "--loading-indicator-border-width":
            props.fillWidth ??
            `calc(${typeof size === "number" ? size + "px" : size} / 2 * 0.3)`,
        },
      }}
    />
  )
}
