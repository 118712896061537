import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardDivider, CardInset } from "../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TokenName } from "../../../../components/TokenName"
import { TokenInfo } from "../../../../utils/models/TokenInfo"

export const MyLotteryPanel$Joined: FC<{
  rewardToken: TokenInfo
  lotteryTicketCount: number
}> = props => {
  const { $t } = useIntl()

  return (
    <>
      <CardInset className={"flex flex-col gap-6"}>
        <InfoList
          className={"m-auto"}
          direction={"row"}
          listItemDirection={"column"}
        >
          <InfoListItem className={"gap-2 items-center"}>
            <InfoListItemDetail className={"text-5xl"}>
              {props.lotteryTicketCount}
            </InfoListItemDetail>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "My Lottery Tickets",
                  description: "/Lottery/MyLotteryPanel$Joined/Info list title",
                }),
              )}
            </InfoListItemTitle>
          </InfoListItem>
        </InfoList>
        <CardDivider className={"w-full"} />
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "Winning tickets will have {tokenName} automatically sent to the wallet",
              description: "/Lottery/MyLotteryPanel$Joined/Note text",
            }),
            {
              tokenName: <TokenName token={props.rewardToken} />,
            },
          )}
        </NoteParagraph>
      </CardInset>
    </>
  )
}
