import { ComponentProps, ComponentType, FC } from "react"

export function withProps<
  T extends FC<any> | keyof JSX.IntrinsicElements,
  P extends Partial<ComponentProps<T>>,
  M extends { [K in keyof P]?: (o: P[K], p?: P[K]) => P[K] },
>(
  Comp: T,
  defaultProps: P,
  merger?: M,
): ComponentType<Omit<ComponentProps<T>, Exclude<keyof P, keyof M>>> {
  const fn: ComponentType<any> = props => {
    let tempP = { ...defaultProps }
    if (merger) {
      for (const key in merger) {
        if (merger.hasOwnProperty(key)) {
          // @ts-ignore
          tempP[key] = merger[key]!(tempP[key], props[key])
        }
      }
    } else {
      tempP = { ...defaultProps, ...props }
    }
    return <Comp {...props} {...tempP} />
  }

  fn.displayName = `withProps(${
    typeof Comp === "string"
      ? Comp
      : Comp.displayName ?? Comp.name ?? "Anonymous"
  })(${Object.keys(defaultProps).join(",")})`

  return fn
}
