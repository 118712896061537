import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardDescription } from "../../../../../components/Card"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { Spensor } from "../../../../../components/Spensor"
import {
  BalanceBottomArea,
  MaxButton,
} from "../../../../../components/TokenInput/BalanceBottomArea"
import {
  Block,
  BlockTokenLine,
  DefaultTokenNameArea,
} from "../../../../../components/TokenInput/Block"
import {
  TokenCountAreaReadonly,
  TokenInput,
} from "../../../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../../../components/TokenName"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../../components/button/variants/RedFilledButton"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ValidateAPowerRuleTable } from "../../ValidateAPowerRuleModalContent/ValidateAPowerRuleModalContent"
import { APowerRuleRange } from "../../types"
import { RegisterFormError, RegisterFormErrorType } from "../types"
import { MainButton } from "./MainButton"

export const ActionSectionContent$BeforeRegistered: FC<{
  formError?: SuspenseResource<undefined | RegisterFormError>

  lotteryTicketCount: number
  onLotteryTicketCountChange?: (ticketCount: null | number) => void
  onPressMaxLotteryTicketButton?: SuspenseResource<undefined | (() => void)>

  aPowerBalance: SuspenseResource<number>
  willLockAPowerCount: SuspenseResource<number>
  aPowerPerTicket: SuspenseResource<number | [number, number]>

  priceToken: SuspenseResource<TokenInfo>
  priceTokenBalance: SuspenseResource<number>
  willLockPriceTokenCount: SuspenseResource<number>
  pricePerTicket: SuspenseResource<number>

  onPressRegister?: SuspenseResource<undefined | (() => void)>
  aPowerRuleRanges: SuspenseResource<APowerRuleRange[] | undefined>
  onLearnMoreAPowerRule?: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <>
      <TokenInput
        token={TokenInfoPresets.IDOLotteryTicket}
        error={
          safeReadResource(props.formError)?.type ===
            RegisterFormErrorType.InsufficientAPowerBalance ||
          safeReadResource(props.formError)?.type ===
            RegisterFormErrorType.ExceedMaxAllowCount
        }
        value={props.lotteryTicketCount}
        onChange={props.onLotteryTicketCountChange}
        tokenNameArea={
          <DefaultTokenNameArea
            boxClassName={""}
            token={TokenInfoPresets.IDOLotteryTicket}
            afterTokenNameArea={
              <Spensor>
                {() => {
                  const onPressMaxLotteryTicketButton =
                    props.onPressMaxLotteryTicketButton &&
                    readResource(props.onPressMaxLotteryTicketButton)

                  return (
                    onPressMaxLotteryTicketButton != null && (
                      <MaxButton onClick={onPressMaxLotteryTicketButton} />
                    )
                  )
                }}
              </Spensor>
            }
          />
        }
      />

      <Spensor>
        {() => (
          <CardDescription>
            {$t(
              defineMessage({
                defaultMessage:
                  "Lottery ticket validation requires {priceToken} as well as {aPower}. The amount of APower required, is dependent on the number of tickets validated.",
                description:
                  "/Launchpad/ActionBeforeRegistered/Card description",
              }),
              {
                priceToken: (
                  <TokenName token={readResource(props.priceToken)} />
                ),
                aPower: <TokenName token={TokenInfoPresets.APower} />,
              },
            )}
            <HeadlessButton
              className={"inline text-blue-600"}
              onClick={props.onLearnMoreAPowerRule}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Learn more",
                  description:
                    "/Launchpad/ActionBeforeRegistered/Head less button",
                }),
              )}
            </HeadlessButton>
          </CardDescription>
        )}
      </Spensor>

      <Block boxClassName={"px-6 py-4 flex flex-col gap-4"}>
        <div>
          <BlockTokenLine
            tokenNameArea={
              <DefaultTokenNameArea
                boxClassName={""}
                token={TokenInfoPresets.APower}
              />
            }
            tokenCountArea={
              <TokenCountAreaReadonly
                token={TokenInfoPresets.APower}
                count={props.willLockAPowerCount}
              />
            }
          />
          <BalanceBottomArea
            boxClassName={""}
            token={TokenInfoPresets.APower}
            balance={props.aPowerBalance}
          />
        </div>

        <div>
          <BlockTokenLine
            tokenNameArea={
              <DefaultTokenNameArea
                boxClassName={""}
                token={props.priceToken}
              />
            }
            tokenCountArea={
              <TokenCountAreaReadonly
                token={props.priceToken}
                count={props.willLockPriceTokenCount}
              />
            }
          />
          <BalanceBottomArea
            boxClassName={""}
            token={props.priceToken}
            balance={props.priceTokenBalance}
          />
        </div>
      </Block>

      <Spensor
        fallback={
          <GradientFilledButton disabled={true}>
            {$t(
              defineMessage({
                defaultMessage: "Validate",
                description: "/Launchpad/ActionBeforeRegistered/Fallback text",
              }),
            )}
          </GradientFilledButton>
        }
      >
        {() => {
          const formError = props.formError && readResource(props.formError)

          if (formError) {
            if (formError?.type !== RegisterFormErrorType.EmptyTokenCount) {
              return (
                <MainButton Variant={RedFilledButton} disabled={true}>
                  {formError.message}
                </MainButton>
              )
            } else {
              return (
                <MainButton Variant={GradientFilledButton} disabled={true}>
                  {formError.message}
                </MainButton>
              )
            }
          }

          return (
            <MainButton
              Variant={GradientFilledButton}
              onClick={
                props.onPressRegister && readResource(props.onPressRegister)
              }
            >
              {$t(
                defineMessage({
                  defaultMessage: "Validate",
                  description: "/Launchpad/ActionBeforeRegistered/Main button",
                }),
              )}
            </MainButton>
          )
        }}
      </Spensor>

      <div className={"text-sm leading-5 flex flex-col gap-[5px]"}>
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "Please note: To receive your ordinals you need to peg out with service fees.",
              description: "/Launchpad/RedeemOrdinalsModalContent/Note text",
            }),
          )}
        </NoteParagraph>
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "The {aPower} submitted to validate the Launchpad tickets will be burnt. The {priceToken} submitted will be refunded to you wallet if a losing ticket is drawn.",
              description: "/Launchpad/ActionBeforeRegistered/Note paragraph",
            }),
            {
              aPower: <TokenName token={TokenInfoPresets.APower} />,
              priceToken: (
                <Spensor fallback={"..."}>
                  {() => <TokenName token={readResource(props.priceToken)} />}
                </Spensor>
              ),
            },
          )}
        </NoteParagraph>
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "Lottery validation can only be submitted ONCE per wallet per LaunchPad. Please be certain you are entering your final values.",
              description: "/Launchpad/ActionBeforeRegistered/Note paragraph",
            }),
          )}
        </NoteParagraph>
      </div>
      <Spensor>
        {() => {
          const ranges = readResource(props.aPowerRuleRanges)
          if (ranges == null) {
            return null
          }
          return (
            <ValidateAPowerRuleTable className={"w-full"} ranges={ranges} />
          )
        }}
      </Spensor>
    </>
  )
}
