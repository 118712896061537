import clsx from "clsx"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { Spensor } from "../../../../../components/Spensor"
import {
  readResource,
  SuspenseResource,
} from "../../../../../utils/SuspenseResource"
import { Order } from "../types"

export interface OrderListContainerProps<T extends Order> {
  className?: string
  orders: SuspenseResource<T[]>
  children: (orders: T[]) => JSX.Element | JSX.Element[]
}

export function OrderListContainer<T extends Order>(
  props: OrderListContainerProps<T>,
): JSX.Element {
  const clsName = clsx(props.className, "flex-1")

  return (
    <Spensor
      fallback={
        <div className={clsx("flex", clsName)}>
          <LoadingIndicator className={"m-auto"} />
        </div>
      }
    >
      {() => (
        <div className={clsName}>
          {props.children(readResource(props.orders))}
        </div>
      )}
    </Spensor>
  )
}
