import clsx from "clsx"
import { FC } from "react"

export interface NotificationCountProps {
  count?: number
  className?: string
}

const MAX_COUNT = 99

export const NotificationCount: FC<NotificationCountProps> = ({
  count = 0,
  className,
}) =>
  count > 0 ? (
    <div
      className={clsx(
        className,
        "rounded-full bg-red-500 w-[1.125rem] h-[1.125rem] flex justify-center items-center",
      )}
    >
      <span className="text-xs font-semibold text-white">
        {count > MAX_COUNT ? MAX_COUNT : count}
      </span>
    </div>
  ) : null
