import clsx from "clsx"
import { action } from "mobx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardPlate } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { Modal } from "../../../../components/Modal"
import { NavLink } from "../../../../components/NavLink"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../components/PercentNumber"
import { SegmentControl } from "../../../../components/SegmentControl"
import {
  SlippageSettingsButton,
  WiredSlippageModal,
} from "../../../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../../../components/Spensor"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../components/TokenInput/BalanceBottomArea"
import { DefaultTokenNameArea } from "../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupPlusIcon,
} from "../../../../components/TokenInput/BlockGroup"
import { TokenCountPair } from "../../../../components/TokenInput/TokenBlock"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { WiredTransactionStateDialog } from "../../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { Button } from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { usePathGenerator } from "../../../../routes/routes"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { Result } from "../../../../utils/Result"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import PoolTokenName from "../../../Farm/components/PoolTokenName"
import { usePoolDetailStore } from "../../store/detail/usePoolDetailStore"
import {
  AddLiquidityFormError,
  AddLiquidityFormErrorType,
  RemoveLiquidityFormError,
  RemoveLiquidityFormErrorType,
} from "../../store/types"
import AddLiquidityConfirmation from "../AddLiquidityConfirmation"
import RemoveLiquidityConfirmation from "../RemoveLiquidityConfirmation"
import { ReactComponent as WarningIcon } from "./warn.svg"

type SharedProps = {
  onSelectTab: (tab: "add" | "remove") => void
  className?: string
}

type AddLiquidityProps = SharedProps & {
  type: "add"
  tokenA: TokenInfo
  tokenABalance: SuspenseResource<number>
  tokenAUSD: SuspenseResource<number>
  tokenAAmount: number
  onChangeTokenA: (value: number) => void
  tokenB: TokenInfo
  tokenBBalance: SuspenseResource<number>
  tokenBUSD: SuspenseResource<number>
  tokenBAmount: SuspenseResource<number>
  onChangeTokenB: (value: number) => void
  onClear?: () => void
  formError: SuspenseResource<AddLiquidityFormError | undefined>
  isMaxStx: SuspenseResource<boolean>
  onConfirmClicked: () => void
  slippage: number
  onClickSlippage: () => void
  tokenLP: TokenInfo
  tokenLPAmount: SuspenseResource<number>
}

type RemoveLiquidityProps = SharedProps & {
  type: "remove"
  formError: SuspenseResource<RemoveLiquidityFormError | undefined>
  poolToken: TokenInfo
  amountToRemove: SuspenseResource<number>
  poolTokenBalance: SuspenseResource<number>
  poolTokenEstUSD: SuspenseResource<number>
  onChangeAmountToRemove: (value: number) => void
  onClear?: () => void
  onConfirmClicked: () => void
}

export const SlippageAndLPHint: FC<{
  slippage: number
  tokenLP: TokenCountPair
}> = ({ slippage, tokenLP }) => {
  const { $t } = useIntl()

  return (
    <div className="w-full rounded-lg bg-white bg-opacity-5 p-3 sm:p-4">
      <div className="flex justify-between items-center ">
        <div className="flex justify-center items-center">
          <span className="text-gray-400 mr-2.5 text-sm sm:text-base">
            {$t(
              defineMessage({
                defaultMessage: "Slippage Tolerance",
                description:
                  "/Pool/ChangeLiquiditySection/SlippageAndLPHint/Content",
              }),
            )}
          </span>
          <TipIcon
            tip={$t(
              defineMessage({
                defaultMessage:
                  "Your transaction will revert if the price changes unfavorably by more than this percentage.",
                description:
                  "/Pool/ChangeLiquiditySection/SlippageAndLPHint/Content tip",
              }),
            )}
          />
        </div>
        <PercentNumber number={slippage} />
      </div>
      <div className="flex justify-between items-center ">
        <div className="flex justify-center items-center">
          <span className="text-gray-400 mr-2.5 text-sm sm:text-base">
            {$t(
              defineMessage({
                defaultMessage: "Receive LP",
                description:
                  "/Pool/ChangeLiquiditySection/SlippageAndLPHint/Content",
              }),
            )}
          </span>
        </div>
        <span>
          {"~ "}
          <span className={clsx("inline-flex items-center")}>
            <TokenCount token={tokenLP.token} count={tokenLP.count} />
            &nbsp;
            <PoolTokenName token={tokenLP.token} />
          </span>
        </span>
      </div>
    </div>
  )
}

export const MaxSTXWarning: FC<{ className?: string }> = props => {
  const { $t } = useIntl()

  return (
    <div
      className={clsx(
        "w-full bg-white bg-opacity-5 rounded-lg p-4 flex items-center",
        props.className,
      )}
    >
      <span className="w-4 h-4">
        <WarningIcon />
      </span>
      <span className="ml-2.5 text-red-600 text-sm">
        {$t(
          defineMessage({
            defaultMessage:
              "Entering the Max STX amount will cause an insufficient balance to cover transaction fees. Please adjust the amount of STX entered accordingly.",
            description: "/Pool/ChangeLiquiditySection/MaxSTXWarning/Content",
          }),
        )}
      </span>
    </div>
  )
}

const AddLiquiditySection: FC<AddLiquidityProps> = props => {
  const { $t } = useIntl()
  const error = safeReadResource(props.formError)
  const aError =
    error?.type === AddLiquidityFormErrorType.InsufficientTokenBalance &&
    error.tokenA
  const bError =
    error?.type === AddLiquidityFormErrorType.InsufficientTokenBalance &&
    error.tokenB

  return (
    <div className="flex flex-col gap-5">
      <BlockGroup
        icon={<BlockGroupPlusIcon />}
        firstBlock={
          <TokenInput
            error={aError}
            className={"w-full"}
            token={props.tokenA}
            value={props.tokenAAmount}
            onChange={v => props.onChangeTokenA(v ?? 0)}
            bottomArea={
              <BalanceBottomArea
                token={props.tokenA}
                balance={props.tokenABalance}
                rightSide={
                  <EstimatedUSD error={aError} usdCount={props.tokenAUSD} />
                }
                onPressMax={suspenseResource(() => {
                  const balance = readResource(props.tokenABalance)
                  if (props.tokenAAmount === balance) return
                  if (!balance) return
                  return () => props.onChangeTokenA(balance)
                })}
              />
            }
          />
        }
        secondBlock={
          <TokenInput
            error={bError}
            className={"w-full"}
            token={props.tokenB}
            value={safeReadResource(props.tokenBAmount) ?? null}
            onChange={v => props.onChangeTokenB(v ?? 0)}
            bottomArea={
              <BalanceBottomArea
                token={props.tokenB}
                balance={props.tokenBBalance}
                rightSide={
                  <EstimatedUSD error={bError} usdCount={props.tokenBUSD} />
                }
                onPressMax={suspenseResource(() => {
                  const balance = readResource(props.tokenBBalance)
                  if (props.tokenBAmount === balance) return
                  if (!balance) return
                  return () => props.onChangeTokenB(balance)
                })}
              />
            }
          />
        }
      />
      <SlippageAndLPHint
        slippage={props.slippage}
        tokenLP={{ token: props.tokenLP, count: props.tokenLPAmount }}
      />
      <Spensor>
        {() => readResource(props.isMaxStx) && <MaxSTXWarning />}
      </Spensor>
      <Spensor
        fallback={
          <GradientFilledButton disabled={true}>
            {$t(
              defineMessage({
                defaultMessage: "Add",
                description:
                  "/Pool/ChangeLiquiditySection/AddLiquiditySection/Fallback text",
              }),
            )}
          </GradientFilledButton>
        }
      >
        {() => {
          const formError = props.formError && readResource(props.formError)

          if (formError) {
            if (formError?.type === AddLiquidityFormErrorType.EmptyTokenCount) {
              return (
                <Button Variant={GradientFilledButton} disabled={true}>
                  {$t(
                    defineMessage({
                      defaultMessage: "Enter an amount",
                      description:
                        "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
                    }),
                  )}
                </Button>
              )
            } else {
              return (
                <Button Variant={RedFilledButton} disabled={true}>
                  {$t(
                    defineMessage({
                      defaultMessage: "Insufficient balance",
                      description:
                        "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
                    }),
                  )}
                </Button>
              )
            }
          }
          return (
            <Button
              Variant={GradientFilledButton}
              onClick={props.onConfirmClicked}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Add",
                  description:
                    "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
                }),
              )}
            </Button>
          )
        }}
      </Spensor>
    </div>
  )
}

const RemoveLiquiditySection: FC<RemoveLiquidityProps> = props => {
  const { $t } = useIntl()
  const error = safeReadResource(props.formError)
  const insufficientError =
    error?.type === RemoveLiquidityFormErrorType.InsufficientTokenBalance
  return (
    <div className="flex flex-col gap-5">
      <TokenInput
        error={insufficientError}
        className={"w-full"}
        token={props.poolToken}
        value={safeReadResource(props.amountToRemove) ?? null}
        onChange={v => props.onChangeAmountToRemove(v ?? 0)}
        tokenNameArea={
          <DefaultTokenNameArea
            token={props.poolToken}
            TokenNameComponent={PoolTokenName}
          />
        }
        bottomArea={
          <BalanceBottomArea
            token={props.poolToken}
            balance={props.poolTokenBalance}
            rightSide={
              <EstimatedUSD
                error={insufficientError}
                usdCount={props.poolTokenEstUSD}
              />
            }
            onPressMax={suspenseResource(() => {
              const balance = readResource(props.poolTokenBalance)
              if (props.amountToRemove === balance) return
              if (!balance) return
              return () => props.onChangeAmountToRemove(balance)
            })}
          />
        }
      />
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "You will no longer earn reward after removed this liquidity.",
            description:
              "/Pool/ChangeLiquiditySection/AddLiquiditySection/Note text",
          }),
        )}
      </NoteParagraph>
      <Spensor
        fallback={
          <GradientFilledButton disabled={true}>Remove</GradientFilledButton>
        }
      >
        {() => {
          const formError = props.formError && readResource(props.formError)

          if (formError) {
            if (
              formError?.type === RemoveLiquidityFormErrorType.EmptyTokenCount
            ) {
              return (
                <Button Variant={GradientFilledButton} disabled={true}>
                  {$t(
                    defineMessage({
                      defaultMessage: "Enter an amount",
                      description:
                        "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
                    }),
                  )}
                </Button>
              )
            } else {
              return (
                <Button Variant={RedFilledButton} disabled={true}>
                  {$t(
                    defineMessage({
                      defaultMessage: "Insufficient balance",
                      description:
                        "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
                    }),
                  )}
                </Button>
              )
            }
          }
          return (
            <Button
              Variant={GradientFilledButton}
              onClick={props.onConfirmClicked}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Remove",
                  description:
                    "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
                }),
              )}
            </Button>
          )
        }}
      </Spensor>
    </div>
  )
}

const ChangeLiquiditySection: FC<
  AddLiquidityProps | RemoveLiquidityProps
> = props => {
  const { $t } = useIntl()

  return (
    <Card className={clsx("flex flex-col gap-4", props.className)}>
      <div className="flex flex-row gap-3 items-center">
        <SegmentControl
          className="mr-auto"
          controls={[
            {
              title: $t(
                defineMessage({
                  defaultMessage: "Add Liquidity",
                  description:
                    "/Pool/ChangeLiquiditySection/AddLiquiditySection/Title text",
                }),
              ),
              isActive: props.type === "add",
              onClick: () => props.onSelectTab("add"),
            },
            {
              title: $t(
                defineMessage({
                  defaultMessage: "Remove Liquidity",
                  description:
                    "/Pool/ChangeLiquiditySection/AddLiquiditySection/Title text",
                }),
              ),
              isActive: props.type === "remove",
              onClick: () => props.onSelectTab("remove"),
            },
          ]}
        />
        {props.onClear && (
          <button
            className="text-sm text-blue-600 cursor-pointer"
            onClick={props.onClear}
          >
            {$t(
              defineMessage({
                defaultMessage: "Clear",
                description:
                  "/Pool/ChangeLiquiditySection/AddLiquiditySection/Button text",
              }),
            )}
          </button>
        )}
        {props.type === "add" && (
          <SlippageSettingsButton onClick={props.onClickSlippage} />
        )}
      </div>
      {props.type === "add" ? (
        <AddLiquiditySection {...props} />
      ) : (
        <RemoveLiquiditySection {...props} />
      )}
    </Card>
  )
}

export default ChangeLiquiditySection

export const WiredChangeLiquiditySection: FC<{
  className?: string
}> = props => {
  const [tab, setTab] = useState<"add" | "remove">("add")
  const store = usePoolDetailStore()
  const currency = useCurrencyStore()
  if (tab === "add") {
    const { addLiquidity, poolTokenInfo } = store
    return (
      <>
        <ChangeLiquiditySection
          className={props.className}
          type={tab}
          tokenLP={store.poolTokenInfo}
          tokenLPAmount={suspenseResource(
            () => addLiquidity.estimatedLPTokenAmount$,
          )}
          onSelectTab={setTab}
          slippage={addLiquidity.slippageStore.slippagePercentage}
          onClickSlippage={() =>
            addLiquidity.slippageStore.showSlippagePopModal()
          }
          onClear={
            addLiquidity.amountAToAdd === 0
              ? undefined
              : () => addLiquidity.clear()
          }
          tokenA={currency.getTokenInfo$(addLiquidity.tokenA)}
          tokenAAmount={addLiquidity.amountAToAdd}
          tokenABalance={suspenseResource(() =>
            addLiquidity.balance$(addLiquidity.tokenA),
          )}
          onChangeTokenA={value => addLiquidity.setAmountAToAdd(value)}
          tokenAUSD={suspenseResource(() => addLiquidity.tokenAEstUSD$)}
          tokenB={currency.getTokenInfo$(addLiquidity.tokenB)}
          tokenBBalance={suspenseResource(() =>
            addLiquidity.balance$(addLiquidity.tokenB),
          )}
          tokenBAmount={suspenseResource(() => addLiquidity.amountBToAdd$)}
          onChangeTokenB={value => addLiquidity.setAmountBToAdd(value)}
          tokenBUSD={suspenseResource(() => addLiquidity.tokenBEstUSD$)}
          formError={suspenseResource(() =>
            Result.maybeError(addLiquidity.formData$),
          )}
          isMaxStx={suspenseResource(() => addLiquidity.isMaxSTX$)}
          onConfirmClicked={() => addLiquidity.onAddTransaction()}
        />
        <WiredPoolMigrationAlert />
        <WiredSlippageModal slippagePercent={addLiquidity.slippageStore} />
        <Modal
          visible={addLiquidity.confirming}
          onClose={action(() => (addLiquidity.confirming = false))}
        >
          <Spensor>
            {() => (
              <AddLiquidityConfirmation
                tokenLP={{
                  token: poolTokenInfo,
                  count: suspenseResource(
                    () => addLiquidity.estimatedLPTokenAmount$,
                  ),
                }}
                tokenA={{
                  token: currency.getTokenInfo$(addLiquidity.tokenA),
                  count: addLiquidity.amountAToAdd,
                }}
                tokenB={{
                  token: currency.getTokenInfo$(addLiquidity.tokenB),
                  count: addLiquidity.amountBToAdd$,
                }}
                tokenBInRiskOfSlippage={suspenseResource(
                  () => addLiquidity.tokenBInRiskOfSlippage$,
                )}
                suggestedBAmount={suspenseResource(
                  () => addLiquidity.maxAmountBWithInSlippage$,
                )}
                suggestedAAmount={suspenseResource(
                  () =>
                    addLiquidity.maxAmountBWithInSlippage$ * addLiquidity.dxDy$,
                )}
                slippage={addLiquidity.slippageStore.slippagePercentage}
                onConfirmAdjust={() =>
                  addLiquidity.adjustAmountToAvoidSlippage()
                }
                onClose={action(() => (addLiquidity.confirming = false))}
                onConfirm={() => {
                  const form = Result.maybeValue(addLiquidity.formData$)
                  if (form) {
                    void addLiquidity.addLiquidity(form)
                  }
                }}
              />
            )}
          </Spensor>
        </Modal>
        <WiredTransactionStateDialog store={addLiquidity.addTransaction} />
      </>
    )
  } else {
    const { removeLiquidity } = store
    return (
      <>
        <ChangeLiquiditySection
          className={props.className}
          type={tab}
          onSelectTab={setTab}
          onClear={
            removeLiquidity.percentageToRemove === 0
              ? undefined
              : () => removeLiquidity.setAmountToRemove(0)
          }
          formError={suspenseResource(() =>
            Result.maybeError(removeLiquidity.formData$),
          )}
          amountToRemove={suspenseResource(
            () => removeLiquidity.amountToRemove$,
          )}
          onChangeAmountToRemove={value =>
            removeLiquidity.setAmountToRemove(value)
          }
          poolTokenEstUSD={suspenseResource(
            () => removeLiquidity.poolTokenEstUSD$,
          )}
          poolToken={currency.getTokenInfo$(store.poolToken)}
          poolTokenBalance={suspenseResource(() => removeLiquidity.balance$)}
          onConfirmClicked={action(() => (removeLiquidity.confirming = true))}
        />
        <Modal
          visible={removeLiquidity.confirming}
          onClose={action(() => (removeLiquidity.confirming = false))}
        >
          <Spensor>
            {() => (
              <RemoveLiquidityConfirmation
                poolToken={{
                  token: currency.getTokenInfo$(store.poolToken),
                  count: removeLiquidity.amountToRemove$,
                }}
                onClose={action(() => (removeLiquidity.confirming = false))}
                onConfirm={() => {
                  const form = Result.maybeValue(removeLiquidity.formData$)
                  if (form) {
                    void removeLiquidity.removeLiquidity(form)
                  }
                }}
              />
            )}
          </Spensor>
        </Modal>
        <WiredTransactionStateDialog
          store={removeLiquidity.removeTransaction}
        />
      </>
    )
  }
}

const WiredPoolMigrationAlert: FC = () => {
  const currency = useCurrencyStore()
  const { addLiquidity } = usePoolDetailStore()
  const gen = usePathGenerator()
  if (addLiquidity.migrationConfirmation == null) {
    return null
  }
  return (
    <Modal
      visible={true}
      onClose={action(() => (addLiquidity.migrationConfirmation = undefined))}
    >
      <PoolMigrationAlert
        onClose={action(() => (addLiquidity.migrationConfirmation = undefined))}
        onContinue={action(() => {
          addLiquidity.migrationConfirmation = undefined
          addLiquidity.confirming = true
        })}
        switchLink={gen.poolDetail(
          currency.getTokenInfo$(addLiquidity.migrationConfirmation),
        )}
      />
    </Modal>
  )
}

const PoolMigrationAlert: FC<{
  switchLink: string
  onContinue: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent className={"flex flex-col gap-4"} width={480}>
      <CardPlate className="text-gray-200 text-center flex flex-col gap-y-2">
        <WarningIcon style={{ alignSelf: "center" }} width={72} height={72} />
        <span>
          {$t(
            defineMessage({
              defaultMessage:
                "This Pool is being migrated to a new Pool. Please refer to <link>discord link</link>.",
              description: "/Add LP/Migration",
            }),
            {
              link: dontWrapObserver(c => (
                <NavLink
                  className="underline"
                  to="https://discord.com/channels/856358412303990794/892817922014216263/1106595207639740507"
                >
                  {c}
                </NavLink>
              )),
            },
          )}
        </span>
      </CardPlate>
      <NavLink to={props.switchLink} className="flex-1">
        <GradientFilledButton>
          {$t(
            defineMessage({
              defaultMessage: "Go to the new Pool",
              description: "/Add LP/Nav link text",
            }),
          )}
        </GradientFilledButton>
      </NavLink>
      <WhiteFilledButton onClick={props.onContinue} className="flex-1">
        {$t(
          defineMessage({
            defaultMessage: "Stay on the old Pool",
            description: "/Add LP/Button text",
          }),
        )}
      </WhiteFilledButton>
    </CardModalContent>
  )
}
