import { FungibleConditionCode } from "@stacks/transactions"
import { unwrapResponse } from "clarity-codegen"
import { CONTRACT_DEPLOYER } from "../../../config"
import { asSender } from "../../../generated/smartContractHelpers/asSender"
import { AMMSwapPool } from "../../../utils/alexjs/AMMSwapPool"
import { Currency } from "../../../utils/alexjs/Currency"
import { SwappableCurrency } from "../../../utils/alexjs/currencyHelpers"
import {
  AlexVault,
  AlexVaultV1_1,
  transfer,
} from "../../../utils/alexjs/postConditions"
import { hasLength } from "../../../utils/arrayHelpers"

export const classicSwappableCurrency = [
  Currency.W_STX,
  Currency.ALEX,
  Currency.sUSDT,
  Currency.ATALEX,
  Currency.W_XBTC,
  Currency.W_BANANA,
  Currency.W_USDA,
  Currency.W_SLIME,
  Currency.W_XUSD,
  Currency.W_MIA,
  Currency.W_NYCC,
  Currency.W_DIKO,
  Currency.W_CORGI,
]

export async function getLiquidityProviderFee(
  tokenX: SwappableCurrency,
  tokenY: SwappableCurrency,
  ammPools: AMMSwapPool.PoolTokens[],
  ammV1_1Pools: AMMSwapPool.PoolV1_1Tokens[],
): Promise<number> {
  const ammV1_1Route = AMMSwapPool.getRoute(tokenX, tokenY, ammV1_1Pools)
  if (ammV1_1Route.length > 0) {
    if (hasLength(ammV1_1Route, 1)) {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("amm-swap-pool-v1-1")
        .func("fee-helper")
        .call({
          "token-x": tokenX,
          "token-y": tokenY,
          factor: AMMSwapPool.getFactor(ammV1_1Route[0].pool),
        })
        .then(unwrapResponse)
      return result / 1e8
    }
    if (hasLength(ammV1_1Route, 2)) {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("amm-swap-pool-v1-1")
        .func("fee-helper-a")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          "token-z": ammV1_1Route[1].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
        })
        .then(unwrapResponse)
      return result / 1e8
    }
    if (hasLength(ammV1_1Route, 3)) {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("amm-swap-pool-v1-1")
        .func("fee-helper-b")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          "token-z": ammV1_1Route[1].neighbour,
          "token-w": ammV1_1Route[2].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
          "factor-z": AMMSwapPool.getFactor(ammV1_1Route[2].pool),
        })
        .then(unwrapResponse)
      return result / 1e8
    }
    if (hasLength(ammV1_1Route, 4)) {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("amm-swap-pool-v1-1")
        .func("fee-helper-c")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          "token-z": ammV1_1Route[1].neighbour,
          "token-w": ammV1_1Route[2].neighbour,
          "token-v": ammV1_1Route[3].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
          "factor-z": AMMSwapPool.getFactor(ammV1_1Route[2].pool),
          "factor-w": AMMSwapPool.getFactor(ammV1_1Route[3].pool),
        })
        .then(unwrapResponse)
      return result / 1e8
    }
  }
  const ammRoute = AMMSwapPool.getRoute(tokenX, tokenY, ammPools)
  if (ammRoute.length === 0) {
    const reachableInAmm = AMMSwapPool.reachableInAMM(tokenX, tokenY, ammPools)
    if (reachableInAmm.type === "fromAmm") {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("swap-helper-bridged-v1-1")
        .func("fee-helper-from-amm")
        .call({
          "token-x": reachableInAmm.tokenX,
          "token-y": reachableInAmm.tokenY,
          "token-z": reachableInAmm.tokenZ,
          "factor-x": reachableInAmm.factorX,
        })
        .then(unwrapResponse)
      return result / 1e8
    }
    if (reachableInAmm.type === "toAmm") {
      const result = await asSender(CONTRACT_DEPLOYER)
        .contract("swap-helper-bridged-v1-1")
        .func("fee-helper-to-amm")
        .call({
          "token-x": reachableInAmm.tokenX,
          "token-y": reachableInAmm.tokenY,
          "token-z": reachableInAmm.tokenZ,
          "factor-y": reachableInAmm.factorY,
        })
        .then(unwrapResponse)
      return result / 1e8
    }
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("swap-helper-v1-03")
      .func("fee-helper")
      .call({
        "token-x": tokenX,
        "token-y": tokenY,
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (hasLength(ammRoute, 1)) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper")
      .call({
        "token-x": tokenX,
        "token-y": tokenY,
        factor: AMMSwapPool.getFactor(ammRoute[0].pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (hasLength(ammRoute, 2)) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper-a")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        "token-z": ammRoute[1].neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (hasLength(ammRoute, 3)) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper-b")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        "token-z": ammRoute[1].neighbour,
        "token-w": ammRoute[2].neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2].pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  if (hasLength(ammRoute, 4)) {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("amm-swap-pool")
      .func("fee-helper-c")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        "token-z": ammRoute[1].neighbour,
        "token-w": ammRoute[2].neighbour,
        "token-v": ammRoute[3].neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2].pool),
        "factor-w": AMMSwapPool.getFactor(ammRoute[3].pool),
      })
      .then(unwrapResponse)
    return result / 1e8
  }
  throw new Error("Too many AMM pools in route")
}

export const getYAmountFromOneX = async (
  stxAddress: string,
  tokenX: SwappableCurrency,
  tokenY: SwappableCurrency,
  fromAmount: number,
  ammPools: AMMSwapPool.PoolTokens[],
  ammV1_1Pools: AMMSwapPool.PoolV1_1Tokens[],
): Promise<number> => {
  const amount = Math.floor(fromAmount * 1e8)
  const ammV1_1Route = AMMSwapPool.getRoute(tokenX, tokenY, ammV1_1Pools)
  if (ammV1_1Route.length > 0) {
    if (hasLength(ammV1_1Route, 1)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("get-helper")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          dx: amount,
          factor: AMMSwapPool.getFactor(ammV1_1Route[0].pool),
        })
        .then(unwrapResponse)
        .then(a => a / amount)
    }
    if (hasLength(ammV1_1Route, 2)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("get-helper-a")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          "token-z": ammV1_1Route[1].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
          dx: amount,
        })
        .then(unwrapResponse)
        .then(a => a / amount)
    }
    if (hasLength(ammV1_1Route, 3)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("get-helper-b")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          "token-z": ammV1_1Route[1].neighbour,
          "token-w": ammV1_1Route[2].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
          "factor-z": AMMSwapPool.getFactor(ammV1_1Route[2].pool),
          dx: amount,
        })
        .then(unwrapResponse)
        .then(a => a / amount)
    }
    if (hasLength(ammV1_1Route, 4)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("get-helper-c")
        .call({
          "token-x": tokenX,
          "token-y": ammV1_1Route[0].neighbour,
          "token-z": ammV1_1Route[1].neighbour,
          "token-w": ammV1_1Route[2].neighbour,
          "token-v": ammV1_1Route[3].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
          "factor-z": AMMSwapPool.getFactor(ammV1_1Route[2].pool),
          "factor-w": AMMSwapPool.getFactor(ammV1_1Route[3].pool),
          dx: amount,
        })
        .then(unwrapResponse)
        .then(a => a / amount)
    }
  }
  const ammRoute = AMMSwapPool.getRoute(tokenX, tokenY, ammPools)
  if (ammRoute.length === 0) {
    const reachableInAMM = AMMSwapPool.reachableInAMM(tokenX, tokenY, ammPools)
    if (reachableInAMM.type === "fromAmm") {
      return await asSender(stxAddress)
        .contract("swap-helper-bridged-v1-1")
        .func("get-helper-from-amm")
        .call({
          dx: amount,
          "token-x": reachableInAMM.tokenX,
          "token-y": reachableInAMM.tokenY,
          "token-z": reachableInAMM.tokenZ,
          "factor-x": reachableInAMM.factorX,
        })
        .then(unwrapResponse)
        .then(a => a / amount)
    }
    if (reachableInAMM.type === "toAmm") {
      return await asSender(stxAddress)
        .contract("swap-helper-bridged-v1-1")
        .func("get-helper-to-amm")
        .call({
          dx: amount,
          "token-x": reachableInAMM.tokenX,
          "token-y": reachableInAMM.tokenY,
          "token-z": reachableInAMM.tokenZ,
          "factor-y": reachableInAMM.factorY,
        })
        .then(unwrapResponse)
        .then(a => a / amount)
    }
    return await asSender(stxAddress)
      .contract("swap-helper-v1-03")
      .func("get-helper")
      .call({
        "token-x": tokenX,
        "token-y": tokenY,
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (hasLength(ammRoute, 1)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        dx: amount,
        factor: AMMSwapPool.getFactor(ammRoute[0].pool),
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (hasLength(ammRoute, 2)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper-a")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        "token-z": ammRoute[1].neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (hasLength(ammRoute, 3)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper-b")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        "token-z": ammRoute[1].neighbour,
        "token-w": ammRoute[2].neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2].pool),
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  if (hasLength(ammRoute, 4)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("get-helper-c")
      .call({
        "token-x": tokenX,
        "token-y": ammRoute[0].neighbour,
        "token-z": ammRoute[1].neighbour,
        "token-w": ammRoute[2].neighbour,
        "token-v": ammRoute[3].neighbour,
        "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
        "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
        "factor-z": AMMSwapPool.getFactor(ammRoute[2].pool),
        "factor-w": AMMSwapPool.getFactor(ammRoute[3].pool),
        dx: amount,
      })
      .then(unwrapResponse)
      .then(a => a / amount)
  }
  throw new Error("Too many AMM pools in route")
}

export async function runSpot(
  stxAddress: string,
  currencyX: SwappableCurrency,
  currencyY: SwappableCurrency,
  fromAmount: number,
  minDy: number,
  middleSteps: SwappableCurrency[],
  ammPools: AMMSwapPool.PoolTokens[],
  ammV1_1Pools: AMMSwapPool.PoolTokens[],
): Promise<string> {
  const ammV1_1Route = AMMSwapPool.getRoute(currencyX, currencyY, ammV1_1Pools)
  if (ammV1_1Route.length > 0) {
    if (hasLength(ammV1_1Route, 1)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("swap-helper")
        .call(
          {
            "token-x-trait": currencyX,
            "token-y-trait": ammV1_1Route[0].neighbour,
            factor: AMMSwapPool.getFactor(ammV1_1Route[0].pool),
            dx: fromAmount * 1e8,
            "min-dy": minDy * 1e8,
          },
          [
            transfer(stxAddress, currencyX, fromAmount),
            transfer(
              AlexVaultV1_1,
              currencyY,
              minDy,
              FungibleConditionCode.GreaterEqual,
            ),
          ],
        )
        .then(a => a.txId)
    }
    if (hasLength(ammV1_1Route, 2)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("swap-helper-a")
        .call(
          {
            "token-x-trait": currencyX,
            "token-y-trait": ammV1_1Route[0].neighbour,
            "token-z-trait": ammV1_1Route[1].neighbour,
            "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
            "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
            dx: fromAmount * 1e8,
            "min-dz": minDy * 1e8,
          },
          [
            transfer(stxAddress, currencyX, fromAmount),
            transfer(
              AlexVaultV1_1,
              ammV1_1Route[0].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              stxAddress,
              ammV1_1Route[0].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              AlexVaultV1_1,
              currencyY,
              minDy,
              FungibleConditionCode.GreaterEqual,
            ),
          ],
        )
        .then(a => a.txId)
    }
    if (hasLength(ammV1_1Route, 3)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("swap-helper-b")
        .call(
          {
            "token-x-trait": currencyX,
            "token-y-trait": ammV1_1Route[0].neighbour,
            "token-z-trait": ammV1_1Route[1].neighbour,
            "token-w-trait": ammV1_1Route[2].neighbour,
            "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
            "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
            "factor-z": AMMSwapPool.getFactor(ammV1_1Route[2].pool),
            dx: fromAmount * 1e8,
            "min-dw": minDy * 1e8,
          },
          [
            transfer(stxAddress, currencyX, fromAmount),
            transfer(
              AlexVaultV1_1,
              ammV1_1Route[0].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              stxAddress,
              ammV1_1Route[0].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              AlexVaultV1_1,
              ammV1_1Route[1].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              stxAddress,
              ammV1_1Route[1].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              AlexVaultV1_1,
              currencyY,
              minDy,
              FungibleConditionCode.GreaterEqual,
            ),
          ],
        )
        .then(a => a.txId)
    }
    if (hasLength(ammV1_1Route, 4)) {
      return await asSender(stxAddress)
        .contract("amm-swap-pool-v1-1")
        .func("swap-helper-c")
        .call(
          {
            "token-x-trait": currencyX,
            "token-y-trait": ammV1_1Route[0].neighbour,
            "token-z-trait": ammV1_1Route[1].neighbour,
            "token-w-trait": ammV1_1Route[2].neighbour,
            "token-v-trait": ammV1_1Route[3].neighbour,
            "factor-x": AMMSwapPool.getFactor(ammV1_1Route[0].pool),
            "factor-y": AMMSwapPool.getFactor(ammV1_1Route[1].pool),
            "factor-z": AMMSwapPool.getFactor(ammV1_1Route[2].pool),
            "factor-w": AMMSwapPool.getFactor(ammV1_1Route[3].pool),
            dx: fromAmount * 1e8,
            "min-dv": minDy * 1e8,
          },
          [
            transfer(stxAddress, currencyX, fromAmount),
            transfer(
              AlexVaultV1_1,
              ammV1_1Route[0].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              stxAddress,
              ammV1_1Route[0].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              AlexVaultV1_1,
              ammV1_1Route[1].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              stxAddress,
              ammV1_1Route[1].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              AlexVaultV1_1,
              ammV1_1Route[2].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              stxAddress,
              ammV1_1Route[2].neighbour,
              0,
              FungibleConditionCode.GreaterEqual,
            ),
            transfer(
              AlexVaultV1_1,
              currencyY,
              minDy,
              FungibleConditionCode.GreaterEqual,
            ),
          ],
        )
        .then(a => a.txId)
    }
  }
  const ammRoute = AMMSwapPool.getRoute(currencyX, currencyY, ammPools)
  if (ammRoute.length === 0) {
    const reachableInAMM = await AMMSwapPool.reachableInAMM(
      currencyX,
      currencyY,
      ammPools,
    )
    if (reachableInAMM.type === "fromAmm") {
      return await asSender(stxAddress)
        .contract("swap-helper-bridged-v1-1")
        .func("swap-helper-from-amm")
        .call(
          {
            "token-x-trait": reachableInAMM.tokenX,
            "token-y-trait": reachableInAMM.tokenY,
            "token-z-trait": reachableInAMM.tokenZ,
            dx: fromAmount * 1e8,
            "min-dz": minDy * 1e8,
            "factor-x": reachableInAMM.factorX,
          },
          [
            transfer(stxAddress, currencyX, fromAmount),
            ...middleSteps.flatMap(middle => [
              transfer(
                AlexVaultV1_1,
                middle,
                0,
                FungibleConditionCode.GreaterEqual,
              ),
              transfer(
                stxAddress,
                middle,
                0,
                FungibleConditionCode.GreaterEqual,
              ),
            ]),
            transfer(
              AlexVault,
              currencyY,
              minDy,
              FungibleConditionCode.GreaterEqual,
            ),
          ],
        )
        .then(a => a.txId)
    }
    if (reachableInAMM.type === "toAmm") {
      return await asSender(stxAddress)
        .contract("swap-helper-bridged-v1-1")
        .func("swap-helper-to-amm")
        .call(
          {
            "token-x-trait": reachableInAMM.tokenX,
            "token-y-trait": reachableInAMM.tokenY,
            "token-z-trait": reachableInAMM.tokenZ,
            dx: fromAmount * 1e8,
            "min-dz": minDy * 1e8,
            "factor-y": reachableInAMM.factorY,
          },
          [
            transfer(stxAddress, currencyX, fromAmount),
            ...middleSteps.flatMap(middle => [
              transfer(
                AlexVault,
                middle,
                0,
                FungibleConditionCode.GreaterEqual,
              ),
              transfer(
                stxAddress,
                middle,
                0,
                FungibleConditionCode.GreaterEqual,
              ),
            ]),
            transfer(
              AlexVaultV1_1,
              currencyY,
              minDy,
              FungibleConditionCode.GreaterEqual,
            ),
          ],
        )
        .then(a => a.txId)
    }
    return await asSender(stxAddress)
      .contract("swap-helper-v1-03")
      .func("swap-helper")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": currencyY,
          dx: fromAmount * 1e8,
          "min-dy": minDy * 1e8,
        },
        [
          transfer(stxAddress, currencyX, fromAmount),
          ...middleSteps.flatMap(middle => [
            transfer(AlexVault, middle, 0, FungibleConditionCode.GreaterEqual),
            transfer(stxAddress, middle, 0, FungibleConditionCode.GreaterEqual),
          ]),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (hasLength(ammRoute, 1)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0].neighbour,
          factor: AMMSwapPool.getFactor(ammRoute[0].pool),
          dx: fromAmount * 1e8,
          "min-dy": minDy * 1e8,
        },
        [
          transfer(stxAddress, currencyX, fromAmount),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (hasLength(ammRoute, 2)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper-a")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0].neighbour,
          "token-z-trait": ammRoute[1].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
          dx: fromAmount * 1e8,
          "min-dz": minDy * 1e8,
        },
        [
          transfer(stxAddress, currencyX, fromAmount),
          transfer(
            AlexVault,
            ammRoute[0].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[0].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (hasLength(ammRoute, 3)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper-b")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0].neighbour,
          "token-z-trait": ammRoute[1].neighbour,
          "token-w-trait": ammRoute[2].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
          "factor-z": AMMSwapPool.getFactor(ammRoute[2].pool),
          dx: fromAmount * 1e8,
          "min-dw": minDy * 1e8,
        },
        [
          transfer(stxAddress, currencyX, fromAmount),
          transfer(
            AlexVault,
            ammRoute[0].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[0].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[1].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[1].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }
  if (hasLength(ammRoute, 4)) {
    return await asSender(stxAddress)
      .contract("amm-swap-pool")
      .func("swap-helper-c")
      .call(
        {
          "token-x-trait": currencyX,
          "token-y-trait": ammRoute[0].neighbour,
          "token-z-trait": ammRoute[1].neighbour,
          "token-w-trait": ammRoute[2].neighbour,
          "token-v-trait": ammRoute[3].neighbour,
          "factor-x": AMMSwapPool.getFactor(ammRoute[0].pool),
          "factor-y": AMMSwapPool.getFactor(ammRoute[1].pool),
          "factor-z": AMMSwapPool.getFactor(ammRoute[2].pool),
          "factor-w": AMMSwapPool.getFactor(ammRoute[3].pool),
          dx: fromAmount * 1e8,
          "min-dv": minDy * 1e8,
        },
        [
          transfer(stxAddress, currencyX, fromAmount),
          transfer(
            AlexVault,
            ammRoute[0].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[0].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[1].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[1].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            ammRoute[2].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            stxAddress,
            ammRoute[2].neighbour,
            0,
            FungibleConditionCode.GreaterEqual,
          ),
          transfer(
            AlexVault,
            currencyY,
            minDy,
            FungibleConditionCode.GreaterEqual,
          ),
        ],
      )
      .then(a => a.txId)
  }

  throw new Error("Too many AMM pools in route")
}

export async function getRoute(
  from: SwappableCurrency,
  to: SwappableCurrency,
  pools: AMMSwapPool.PoolTokens[],
  ammV1_1pools: AMMSwapPool.PoolV1_1Tokens[],
): Promise<SwappableCurrency[]> {
  const ammV1_1Route = AMMSwapPool.getRoute(from, to, ammV1_1pools)
  if (ammV1_1Route.length > 0) {
    return [from, ...ammV1_1Route.map(a => a.neighbour)]
  }
  const ammRoute = AMMSwapPool.getRoute(from, to, pools)
  if (ammRoute.length > 0) {
    return [from, ...ammRoute.map(a => a.neighbour)]
  }
  const reachableInAmm = AMMSwapPool.reachableInAMM(from, to, pools)
  if (reachableInAmm.type === "fromAmm") {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("swap-helper-bridged-v1-1")
      .func("route-helper-from-amm")
      .call({
        "token-x": reachableInAmm.tokenX,
        "token-y": reachableInAmm.tokenY,
        "token-z": reachableInAmm.tokenZ,
        "factor-x": reachableInAmm.factorX,
      })
      .then(unwrapResponse)
    return result.map(x => x as SwappableCurrency)
  }
  if (reachableInAmm.type === "toAmm") {
    const result = await asSender(CONTRACT_DEPLOYER)
      .contract("swap-helper-bridged-v1-1")
      .func("route-helper-to-amm")
      .call({
        "token-x": reachableInAmm.tokenX,
        "token-y": reachableInAmm.tokenY,
        "token-z": reachableInAmm.tokenZ,
        "factor-y": reachableInAmm.factorY,
      })
      .then(unwrapResponse)
    return result.map(x => x as SwappableCurrency)
  }
  const result = await asSender(CONTRACT_DEPLOYER)
    .contract("swap-helper-v1-03")
    .func("route-helper")
    .call({
      "token-x": from,
      "token-y": to,
    })
    .then(unwrapResponse)
  return result.map(x => x as SwappableCurrency)
}
