import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Button } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LoadableButton } from "../../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../../components/button/variants/RedFilledButton"
import { Card, CardPlate, CardTitle } from "../../../../../components/Card"
import { InfoList } from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { PercentNumber } from "../../../../../components/PercentNumber"
import { SlippageSettingsButton } from "../../../../../components/SlippageModalContent/WiredSlippageSettingsButton/WiredSlippageSettingsButton"
import { Spensor } from "../../../../../components/Spensor"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { BalanceBottomArea } from "../../../../../components/TokenInput/BalanceBottomArea"
import {
  BlockTopLine,
  renderThinnerBlock,
} from "../../../../../components/TokenInput/Block"
import {
  BlockGroup,
  BlockGroupDownArrowIcon,
} from "../../../../../components/TokenInput/BlockGroup"
import { TokenBlock } from "../../../../../components/TokenInput/TokenBlock"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
  suspenseResource,
} from "../../../../../utils/SuspenseResource"
import { DetailPlate } from "../../../../Stake/components/DetailPlate/DetailPlate"
import {
  BorrowInfoListContent,
  BorrowInfoListContentProps,
} from "../BorrowInfoListContent"
import { FormError, FormErrorType } from "./types"

export interface AddBorrowPanelProps extends BorrowInfoListContentProps {
  className?: string

  borrowToken: TokenInfo
  collateralToken: TokenInfo

  borrowTokenCount: null | number
  setBorrowTokenCount?: (newCount: null | number) => void
  availableBorrowTokenCount: SuspenseResource<number>
  collateralTokenCount: SuspenseResource<number>
  collateralTokenBalance: SuspenseResource<number>

  ltv: SuspenseResource<number>

  error?: SuspenseResource<FormError | undefined>
  onSubmit?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
  onClickSlippage?: () => void
}

export const AddBorrowPanel: FC<AddBorrowPanelProps> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx(props.className, "flex flex-col gap-4")}>
      <div className={"flex flex-row items-center justify-between"}>
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Borrow",
              description: "/Lend/AddBorrowPanel/Title",
            }),
          )}
        </CardTitle>
        <div className="flex items-center gap-2">
          {props.onClear && (
            <HeadlessButton className={"text-blue-600"} onClick={props.onClear}>
              {$t(
                defineMessage({
                  defaultMessage: "Clear",
                  description: "/Lend/AddBorrowPanel/Button text",
                }),
              )}
            </HeadlessButton>
          )}
          <SlippageSettingsButton onClick={props.onClickSlippage} />
        </div>
      </div>
      <BlockGroup
        firstBlock={
          <TokenInput
            renderBlock={renderThinnerBlock}
            token={props.borrowToken}
            error={isTokenInputShouldBeErrorState(
              safeReadResource(props.error),
            )}
            value={props.borrowTokenCount}
            onChange={props.setBorrowTokenCount}
            bottomArea={
              <BalanceBottomArea
                balanceLabel={$t(
                  defineMessage({
                    defaultMessage: "Available to borrow: ",
                    description: "/Lend/AddBorrowPanel/Balance label",
                  }),
                )}
                token={props.borrowToken}
                balance={props.availableBorrowTokenCount}
                onPressMax={suspenseResource(() => {
                  const balance = readResource(props.availableBorrowTokenCount)
                  if (props.borrowTokenCount === balance) return undefined
                  if (!balance) return
                  return () => {
                    props.setBorrowTokenCount?.(balance)
                  }
                })}
              />
            }
          />
        }
        icon={<BlockGroupDownArrowIcon />}
        secondBlock={
          <TokenBlock
            renderBlock={renderThinnerBlock}
            topArea={
              <BlockTopLine className={"flex items-center"}>
                {$t(
                  defineMessage({
                    defaultMessage: "Collateral {tipIcon}",
                    description: "/Lend/AddBorrowPanel/Token title ",
                  }),
                  {
                    tipIcon: (
                      <TipIcon
                        className={"ml-1"}
                        inline
                        tip={
                          <>
                            {$t(
                              defineMessage({
                                defaultMessage:
                                  "{tokenName} functions like a certificate of deposit that pays a fixed interest to its holder at a pre-defined maturity date",
                                description:
                                  "/Lend/AddBorrowPanel/Balance label tooltip",
                              }),
                              {
                                tokenName: (
                                  <TokenName token={props.collateralToken} />
                                ),
                              },
                            )}
                          </>
                        }
                      />
                    ),
                  },
                )}
                <span className={"ml-auto text-xs text-gray-600"}>
                  {" "}
                  {$t(
                    defineMessage({
                      defaultMessage: "LTV ≈ {per}",
                      description: "/Lend/AddBorrowPanel/Content",
                    }),
                    {
                      per: (
                        <Spensor fallback="--">
                          {() => (
                            <PercentNumber number={readResource(props.ltv)} />
                          )}
                        </Spensor>
                      ),
                    },
                  )}
                </span>
              </BlockTopLine>
            }
            tokens={[
              {
                token: props.collateralToken,
                count: props.collateralTokenCount,
              },
            ]}
            bottomArea={
              <BalanceBottomArea
                token={props.collateralToken}
                balance={props.collateralTokenBalance}
              />
            }
          />
        }
      />
      <DetailPlate gapClassName={"gap-4"}>
        <CardPlate className={"flex flex-col gap-4"}>
          <InfoList listItemDirection={"row-responsive"}>
            <BorrowInfoListContent
              interestToken={props.borrowToken}
              expirationDate={props.expirationDate}
              expirationBlockHeight={props.expirationBlockHeight}
              estimateAbsoluteInterest={props.estimateAbsoluteInterest}
              crpInfo={props.crpInfo}
              slippage={props.slippage}
              liquidityProviderFee={props.liquidityProviderFee}
            />
          </InfoList>
          <NoteParagraph>
            {$t(
              defineMessage({
                defaultMessage:
                  "You will receive your borrowed {tokenName} immediately once the transaction proceeds. The Borrow period is 1050 blocks (~7 days).",
                description: "/Lend/AddBorrowPanel/Detail Plate note",
              }),
              {
                tokenName: <TokenName token={props.borrowToken} />,
              },
            )}
          </NoteParagraph>
        </CardPlate>
      </DetailPlate>

      {safeReadResource(props.error)?.type === FormErrorType.PoolAtCapacity && (
        <NoteParagraph>
          {$t(
            defineMessage({
              defaultMessage:
                "Borrow has reached its capacity limit for the current term. You can borrow again when the next term begins",
              description: "/Lend/AddBorrowPanel/Detail Plate note",
            }),
          )}
        </NoteParagraph>
      )}
      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(
              defineMessage({
                defaultMessage: "Borrow",
                description: "/Lend/AddBorrowPanel/Fallback button text",
              }),
            )}
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.error)
          if (error?.type === FormErrorType.WalletNotConnected) {
            return (
              <GradientFilledButton onClick={props.onConnectWallet}>
                {$t(
                  defineMessage({
                    defaultMessage: "Connect Wallet",
                    description: "/Lend/AddBorrowPanel/Button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error != null) {
            return (
              <Button
                Variant={
                  isErrorStateButtonShouldRenderAsGradientButton(error)
                    ? GradientFilledButton
                    : RedFilledButton
                }
                disabled={true}
              >
                {error.message}
              </Button>
            )
          }
          return (
            <GradientFilledButton disabled={true} onClick={props.onSubmit}>
              {$t(
                defineMessage({
                  defaultMessage: "Borrow",
                  description: "/Lend/AddBorrowPanel/Button text",
                }),
              )}
            </GradientFilledButton>
          )
        }}
      </Spensor>
    </Card>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: FormError,
): boolean => {
  if (!error) return false
  return error.type === FormErrorType.AmountIsEmpty
}

const isTokenInputShouldBeErrorState = (error?: FormError): boolean => {
  if (!error) return false
  return (
    error.type === FormErrorType.InsufficientTokenBalance ||
    error.type === FormErrorType.LessThanMinimizeAmount
  )
}
