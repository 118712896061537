import { computed, makeObservable, observable } from "mobx"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import { SlippageStore } from "../../../stores/slippageStore/SlippageStore"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { Result } from "../../../utils/Result"
import { waitUntilExist$ } from "../../../utils/waitFor"
import {
  FormError,
  FormErrorType,
} from "../components/borrowPage/AddBorrowPanel/types"
import LendStore from "./LendStore"
import type { AddBorrowForm } from "./LendStore.service"
import {
  addBorrow,
  uTokenPerYToken,
  yTokenPerCollateral,
} from "./LendStore.service"

export class AddBorrowModule {
  constructor(readonly store: LendStore) {
    makeObservable(this)
  }

  uTokenAmount = new SuspenseObservable<number>()

  @computed get collateralAmount$(): number {
    return this.uTokenAmount.read$ / this.borrowableAmountPerCollateral$
  }

  slippageStore = new SlippageStore()

  @computed get collateralBalance$(): number {
    return this.store.accountStore.getBalance$(this.store.collateralToken)
  }

  @computed get borrowableBalance$(): number {
    return this.collateralBalance$ * this.borrowableAmountPerCollateral$
  }

  yTokenPerCollateral = new LazyValue(
    () =>
      [
        this.store.currentExpiry$,
        this.store.underlyingToken,
        this.store.collateralToken,
        this.store.currencyStore.getPrice$(this.store.collateralToken),
        this.store.chainStore.currentBlockHash$,
      ] as const,
    args => yTokenPerCollateral(...args),
    {
      decorator: pMemoizeDecorator({
        persistKey: "crp-get-token-given-position",
      }),
    },
  )

  @computed get isOutOfCapacity$(): boolean {
    return this.yTokenPerCollateral.value$ == null
  }

  @computed get yTokenPerCollateral$(): {
    yTokenAmount: number
    kTokenAmount: number
  } {
    if (
      !this.store.currentExpiryExist$ ||
      this.yTokenPerCollateral.value$ == null
    ) {
      return {
        kTokenAmount: 0,
        yTokenAmount: 0,
      }
    }
    return this.yTokenPerCollateral.value$
  }

  uTokenPerYToken = new LazyValue(
    () =>
      [
        this.store.currentExpiry$,
        this.store.underlyingToken,
        this.store.yieldToken,
        this.store.currencyStore.getPrice$(this.store.underlyingToken),
        this.store.chainStore.currentBlockHash$,
      ] as const,
    args => uTokenPerYToken(...args),
    {
      decorator: pMemoizeDecorator({
        persistKey: "crp-uTokenPerYToken",
      }),
    },
  )

  @computed get uTokenPerYToken$(): number {
    if (!this.store.currentExpiryExist$) {
      return 0
    }
    return this.uTokenPerYToken.value$
  }

  @computed get borrowableAmountPerCollateral$(): number {
    return this.yTokenPerCollateral$.yTokenAmount * this.uTokenPerYToken$
  }

  @computed get estimatedInterest$(): number {
    return this.yieldTokenAmount$ * (1 - this.uTokenPerYToken$)
  }

  @computed get yieldTokenAmount$(): number {
    return this.collateralAmount$ * this.yTokenPerCollateral$.yTokenAmount
  }

  @computed get liquidityProviderFee$(): number {
    return this.store.ytpLiquidityFeeCalc("yield", this.yieldTokenAmount$)
  }

  @computed get ltv$(): number {
    return this.store.ltv$
  }

  @computed get collateralBreakdown$(): {
    underlying: number
    collateral: number
  } {
    const detail = waitUntilExist$(() => this.store.currentExpiryDetail$)
    const { collateralPerShare, uTokenPerShare } = detail
    const cTotal =
      collateralPerShare *
      this.store.currencyStore.getPrice$(this.store.collateralToken)
    const uTotal =
      uTokenPerShare *
      this.store.currencyStore.getPrice$(this.store.underlyingToken)
    const total = cTotal + uTotal
    return {
      underlying: uTotal / total,
      collateral: cTotal / total,
    }
  }

  @observable showConfirmation = false
  txStore = new ConfirmTransactionStore()

  @computed get formData$(): Result<AddBorrowForm, FormError> {
    if (!this.store.authStore.isWalletConnected) {
      return Result.error({
        type: FormErrorType.WalletNotConnected,
        message: "Connect Wallet",
      })
    }
    if (!this.store.currentExpiryExist$) {
      return Result.error({
        type: FormErrorType.PoolNotCreatedYet,
        message: "Pool not created yet",
      })
    }
    if (this.isOutOfCapacity$) {
      return Result.error({
        type: FormErrorType.PoolAtCapacity,
        message: "Current term is at capacity",
      })
    }
    if (this.uTokenAmount.get() == null || this.uTokenAmount.get() === 0) {
      return Result.error({
        type: FormErrorType.AmountIsEmpty,
        message: "Enter Amount",
      })
    }
    if (this.uTokenAmount.read$ > this.borrowableBalance$) {
      return Result.error({
        type: FormErrorType.InsufficientTokenBalance,
        message: "Insufficient Balance",
      })
    }
    return Result.ok({
      stxAddress: this.store.authStore.stxAddress$,
      expiry: this.store.currentExpiry$,
      collateralAmount: this.collateralAmount$,
      yieldTokenAmount: this.yieldTokenAmount$,
      uTokenPerYieldToken: this.uTokenPerYToken$,
      slippage: this.slippageStore.slippagePercentage,
      ytpToken: this.store.poolToken,
    })
  }

  @asyncAction
  async addBorrow(
    formData: AddBorrowForm,
    run = runAsyncAction,
  ): Promise<void> {
    this.showConfirmation = false
    try {
      const { txId } = await run(addBorrow(formData))
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }
}
