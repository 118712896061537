import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenIcon, TokenIconProps } from "../../../../components/TokenIcon"
import { TokenName } from "../../../../components/TokenName"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { ReactComponent as WarningIcon } from "./_/warning.svg"

export const MigrateAutoStakeV2Section: FC<{
  className?: string
  autoAlexToken: TokenInfo
  autoAlexTokenCount: SuspenseResource<number>
  onMigrate: () => void
  onClaim: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <div className={props.className}>
      <div className="bg-black/30 rounded-2xl p-6">
        <p className="text-yellow-500 flex">
          <WarningIcon className="flex-none mr-2.5 mt-1" />
          {$t(
            defineMessage({
              defaultMessage:
                "You are recommended to migrate to Auto Staking (V2) as soon as possible, as Auto Staking (V1) is already suspended. Please note Claim to ALEX will be available for a limited time only (after which only Migrate option will be available).",
              description:
                "/Stake/MixedStake/MigrateAutoStakeV2Section/Note Text",
            }),
          )}
        </p>
        <div className="flex sm:items-center mt-4 justify-between flex-col sm:flex-row space-y-4">
          <InfoListItem className="space-y-2.5">
            <InfoListItemTitle className="flex flex-col mr-5">
              {$t(
                defineMessage({
                  defaultMessage: "Your Auto Staking (V1 )",
                  description:
                    "/Stake/MixedStake/MyStakingSection/Summary List item title",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail
              className={clsx("flex items-center font-semibold")}
            >
              <Spensor>
                {() => (
                  <div className={clsx("flex items-center gap-2 justify-end")}>
                    <TokenIcon
                      token={props.autoAlexToken}
                      size={TokenIconProps.defaultSize}
                    />
                    <span className="text-[24px] sm:text-[30px] leading-[24px] sm:leading-[36px]">
                      <TokenCount
                        token={props.autoAlexToken}
                        count={readResource(props.autoAlexTokenCount)}
                      />
                    </span>
                    <TokenName
                      className={"text-lg leading-6 font-medium"}
                      token={props.autoAlexToken}
                    />
                  </div>
                )}
              </Spensor>
            </InfoListItemDetail>
          </InfoListItem>
          <div className="flex items-center space-x-5">
            <GradientFilledButton
              className="py-2 px-10"
              onClick={props.onMigrate}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Migrate",
                  description:
                    "/Stake/MixedStake/MigrateAutoStakeV2Section/Button text",
                }),
              )}
            </GradientFilledButton>
            <WhiteFilledButton className="py-2 px-10" onClick={props.onClaim}>
              {$t(
                defineMessage({
                  defaultMessage: "Claim",
                  description:
                    "/Stake/MixedStake/MigrateAutoStakeV2Section/Button text",
                }),
              )}
            </WhiteFilledButton>
          </div>
        </div>
      </div>
    </div>
  )
}
