import { ComponentType, FC } from "react"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { ButtonVariantProps } from "../../button/ButtonVariant"
import { useMessage } from "../../message/MessageProvider"
import {
  TrivialButton$Connected,
  TrivialButton$Disconnected,
} from "./TrivialButton"

export const WiredTrivialButton$Connected: FC<{
  ButtonVariant?: ComponentType<ButtonVariantProps>
}> = props => {
  const authStore = useAuthStore()

  const accountStore = useAccountStore()

  const message = useMessage()

  const onCopyAddress = (): void => {
    void navigator.clipboard.writeText(authStore.stxAddress$).then(() =>
      message.show({
        key: "Account address copied!",
        message: `Account address copied!`,
      }),
    )
  }

  return (
    <TrivialButton$Connected
      stxAddress={authStore.stxAddress$}
      addressExplorerLink={authStore.addressExplorerLink$}
      ButtonVariant={props.ButtonVariant}
      onCopyAddress={onCopyAddress}
      onSignOut={() => accountStore.signOut()}
    />
  )
}

export const WiredTrivialButton$Disconnected: FC<{
  ButtonVariant?: ComponentType<ButtonVariantProps>
}> = props => {
  const auth = useAuthStore()

  const STORAGE_KEY = "WiredTopNavBar-hide-reminder"

  const hideReminder = localStorage.getItem(STORAGE_KEY)

  const onClick = (): void => {
    if (!hideReminder) {
      localStorage.setItem(STORAGE_KEY, "true")
    }
    auth.showWalletSelector()
  }

  return (
    <TrivialButton$Disconnected
      showReminder={!hideReminder}
      ButtonVariant={props.ButtonVariant}
      onConnect={onClick}
    />
  )
}
