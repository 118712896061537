import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import AccountStore from "../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../stores/chainStore/ChainStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { liquidityTokenPairs } from "../../../utils/alexjs/currencyHelpers"
import { optionally } from "../../../utils/utils"
import ManualStakeStore, {
  stakeStoreCache,
} from "../../Stake/store/manualStaking/ManualStakeStore"
import { StakableCurrency } from "../../Stake/store/manualStaking/ManualStakeStore.service"
import CoFarmStore from "./CoFarmStore"
import { getFarmsList } from "./FarmStore.service"

export interface FarmListItem {
  token: StakableCurrency
  liquidity?: number
  apr?: number
  fees?: number
}

class FarmStore {
  constructor(
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight$" | "isWalletConnected"
    >,
    readonly currencyStore: CurrencyStore,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly chainStore: Pick<
      ChainStore,
      "estimatedDateForBlock$" | "currentBlockHeight$" | "stakeChainModule"
    >,
  ) {
    makeObservable(this)
  }

  @computed get apowerMultiplier$(): number {
    return this.myFarms$[0]?.apowerMultiplier$ ?? 0.3
  }

  @computed get allPoolTokens$(): StakableCurrency[] {
    return this.appEnv.config$.farmingPool
  }

  private _poolStats = new LazyValue(
    () =>
      [
        this.allPoolTokens$.map(
          this.currencyStore.stakingCurrencyToStakingToken$,
        ),
        this.chainStore.stakeChainModule(Currency.ALEX).currentCycle$,
        this.chainStore.stakeChainModule(Currency.ALEX).v1StakeEndCycle$,
      ] as const,
    ([pools, currentCycle, v1StakeEndCycle]) =>
      getFarmsList(pools, currentCycle, v1StakeEndCycle),
  )

  poolStats$ = createTransformer((pool: StakableCurrency) => {
    const data = this._poolStats.value$.find(a => a.token === pool)
    if (data == null) {
      return undefined
    }
    const tokenX =
      pool === Currency.ALEX ? Currency.ALEX : liquidityTokenPairs(pool)[0]
    return {
      liquidity: optionally(
        data.liquidityInTokenX,
        a => a * this.currencyStore.getPrice$(tokenX),
      ),
      ...data,
    }
  })

  aprForPool$ = createTransformer(
    (pool: StakableCurrency): number | undefined => {
      const apr = this.poolStats$(pool)?.apr
      if (apr == null) {
        return undefined
      }
      const multiplier =
        this.stakingStore(pool).dualYield?.aprMultiplier$ ??
        this.stakingStore(pool).dualYieldV1_1?.aprMultiplier$ ??
        1
      return apr * multiplier
    },
  )

  getBalance$ = createTransformer((token: StakableCurrency) => {
    return this.accountStore.getBalance$(token)
  })

  @computed private get allStakeStores(): ManualStakeStore[] {
    return this.allPoolTokens$.map(this.stakingStore)
  }

  @computed get myFarms$(): ManualStakeStore[] {
    return this.allStakeStores.filter(
      a =>
        a.myStaking.activeStaking +
          (a.token$ === Currency.FWP_STX_ALEX_50_50_V1_01
            ? this.coFarmStore.nextCycleBalance$
            : 0) >
          0 || a.myStaking.hasAnythingToClaim$,
    )
  }

  stakingStore = memoize(
    (token: StakableCurrency) =>
      stakeStoreCache[token] ??
      new ManualStakeStore(
        token,
        this.appEnv,
        this.authStore,
        this.currencyStore,
        this.accountStore,
        this.chainStore,
      ),
  )

  @computed({ keepAlive: true }) get coFarmStore(): CoFarmStore {
    return new CoFarmStore(
      Currency.FWP_STX_ALEX_50_50_V1_01,
      this.stakingStore(Currency.FWP_STX_ALEX_50_50_V1_01),
      this.currencyStore,
    )
  }
}

export default FarmStore
