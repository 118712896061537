import { createContext, useContext } from "react"
import { useAccountStore } from "../../../../stores/accountStore/useAccountStore"
import { useAppEnvStore } from "../../../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../../../stores/currencyStore/useCurrencyStore"
import { FCC } from "../../../../utils/reactHelpers/types"
import { useCreation } from "../../../../utils/reactHelpers/useCreation"
import PoolListStore from "./PoolListStore"

const PoolListStoreContext = createContext<PoolListStore | null>(null)

export const PoolListStoreProvider: FCC = props => {
  const appEnvStore = useAppEnvStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(
    () =>
      new PoolListStore(appEnvStore, authStore, accountStore, currencyStore),
    [accountStore, appEnvStore, authStore, currencyStore],
  )

  return (
    <PoolListStoreContext.Provider value={store}>
      {props.children}
    </PoolListStoreContext.Provider>
  )
}

export const usePoolListStore = (): PoolListStore => {
  const store = useContext(PoolListStoreContext)
  if (store == null) {
    throw new Error(
      `usePoolListStore must be used within a PoolListStoreContext`,
    )
  }
  return store
}
