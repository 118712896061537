import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenIcon } from "../../../../../components/TokenIcon"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { ClaimTokenInfo, MixedStakeCycle } from "../../../types"
import { ClaimTokenInfoList } from "../../ClaimTokenInfoList"
import { HarvestIcon } from "../../HarvestIcon"
import { TokenCountDetailInfoList } from "../../TokenCountDetailInfoList"

export const columnCount = 2

export const StakeCycleRowContent: FC<{
  className?: string
  alexToken: TokenInfo
  autoAlexToken: TokenInfo
  cycle: MixedStakeCycle
}> = props => {
  const { $t } = useIntl()

  return (
    <>
      <TokenCountDetailInfoList
        primary={
          <>
            <TokenIcon
              className={"inline-block align-text-bottom"}
              token={props.autoAlexToken}
              size={18}
            />
            &nbsp;
            <TokenCount
              token={props.autoAlexToken}
              count={props.cycle.autoAlexTokenCount}
            />
          </>
        }
        secondary={
          props.cycle.autoAlexTokenCount > 0 ? (
            <>
              ≈&nbsp;
              <TokenCount
                token={props.alexToken}
                count={props.cycle.autoAlexIntrinsicAlexValue}
              />
              &nbsp;
              <TokenName token={props.alexToken} />
              &nbsp;
              <TipIcon
                className={"inline-block align-text-bottom"}
                tip={
                  <>
                    {$t(
                      defineMessage({
                        defaultMessage:
                          "The intrinsic value of {autoAlexToken} in {alexToken} value",
                        description:
                          "/Stake/MixedStake/StakeCycleRowContent/Tip",
                      }),
                      {
                        autoAlexToken: (
                          <TokenName token={props.autoAlexToken} />
                        ),
                        alexToken: <TokenName token={props.alexToken} />,
                      },
                    )}
                  </>
                }
              />
            </>
          ) : null
        }
      />
      <TokenCountDetailInfoList
        primary={
          <>
            <TokenIcon
              className={"inline-block align-text-bottom"}
              token={props.alexToken}
              size={18}
            />
            &nbsp;
            <TokenCount
              token={props.alexToken}
              count={props.cycle.manualStakingAlexTokenCount}
            />
          </>
        }
        secondary={
          props.cycle.manualStakingClaimable.some(a => a.count > 0) && (
            <>
              <HarvestIcon
                className={"inline-block align-text-bottom"}
                width={16}
                height={16}
              />
              &nbsp;
              <ClaimTokenInfoList
                claimTokenInfos={ClaimTokenInfo.mergeSameTokenInfo(
                  props.cycle.manualStakingClaimable,
                )}
              />
            </>
          )
        }
      />
    </>
  )
}
