import clsx from "clsx"
import { FC, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { cancel$t, confirm$t } from "../../../../commonIntlMessages"
import { CardInset } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { FiatCount } from "../../../../components/FiatCount"
import { InfoList } from "../../../../components/InfoList/InfoList"
import { InfoListItem } from "../../../../components/InfoList/InfoListItem"
import {
  DefaultInfoListItemDetail,
  InfoListItemDetail,
} from "../../../../components/InfoList/InfoListItemDetail"
import {
  DefaultInfoListItemTitle,
  InfoListItemTitle,
} from "../../../../components/InfoList/InfoListItemTitle"
import { Modal } from "../../../../components/Modal"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { useSpacing } from "../../../../components/Themed/spacing"
import { TokenCount } from "../../../../components/TokenCount"
import { Button } from "../../../../components/button/Button"
import { SmartLoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { OpacityButton } from "../../../../components/button/variants/OpacityButton"
import { BigNumber } from "../../../../utils/BigNumber"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { CardStrongText } from "./BRC20Card/BRC20Card"
import { BRC20InscriptionCard } from "./BRC20Card/BRC20InscriptionCard"
import {
  EditFeeModalContent,
  type EditFeeModalContentProps,
  type NetworkFeeRatePatch,
} from "./EditFeeModalContent"
import { ReactComponent as ModifyIcon } from "./_/edit.svg"

export interface InscribeConfirmModalContentProps {
  token: TokenInfo
  tokenCount: BigNumber
  btcNetwork: "mainnet" | "testnet"
  networkFeeRate: SuspenseResource<{
    type: "fast" | "avg" | "slow" | "custom"
    rate: BigNumber
  }>
  recommendedNetworkFeeRates: SuspenseResource<{
    fast: BigNumber
    avg: BigNumber
    slow: BigNumber
  }>
  inscribeServiceFeeToken: TokenInfo
  inscribeServiceFeeTokenCount: BigNumber
  inscribeServiceFeeTokenCountToUSD: BigNumber
  networkFeeRateToken: TokenInfo
  networkFeeRateTokenCount: BigNumber
  onChangeNetworkFeeRate: (ratePatch: NetworkFeeRatePatch) => Promise<void>
  onConfirm: () => void | Promise<void>
  onClose: () => void
}

export const InscribeConfirmModalContent: FC<
  InscribeConfirmModalContentProps
> = props => {
  const { $t } = useIntl()

  const spacing = useSpacing()

  const rowGap = spacing(4)

  const [editFeeModalOpen, setEditFeeModalOpen] = useState(false)

  const recommendedBitcoinNetworkFeeRates = readResource(
    props.recommendedNetworkFeeRates,
  )

  const [customRate, setCustomRate] = useState<BigNumber | undefined>()
  const [networkFeeRate, setNetworkFeeRate] = useState<
    EditFeeModalContentProps["networkFeeRate"]
  >({
    type: "fast",
    rate: BigNumber.from(20),
  })
  const onChangeNetworkFeeRate = (ratePatch: NetworkFeeRatePatch): void => {
    if (ratePatch.type === "custom") {
      setCustomRate(ratePatch.rate)
      setNetworkFeeRate(ratePatch)
    } else {
      setNetworkFeeRate({
        type: ratePatch.type,
        rate: recommendedBitcoinNetworkFeeRates[ratePatch.type],
      })
    }
  }

  return (
    <CardModalContent
      className={"flex flex-col"}
      gap={rowGap}
      padding={spacing(5)}
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Inscribe",
          description: "Orderbook Peg-In Page/Inscribe Confirm Modal/title",
        }),
      )}
      onClose={props.onClose}
    >
      <CardInset
        className={"flex items-center justify-center"}
        boxClassName={"px-2.5 py-5"}
      >
        <BRC20InscriptionCard
          className={"w-[200px]"}
          token={props.token}
          tokenCount={props.tokenCount}
          topArea={
            <CardStrongText>
              {$t(
                defineMessage({
                  defaultMessage: "Transfer",
                  description:
                    "Orderbook Peg-In Page/Inscribe Confirm Modal/inscription state text",
                }),
              )}
            </CardStrongText>
          }
        />
      </CardInset>

      <InfoList
        style={{ rowGap }}
        direction={"column"}
        listItemDirection={"row"}
        renderInfoListItemTitle={p => (
          <DefaultInfoListItemTitle
            {...p}
            className={"text-sm leading-5 font-normal text-gray-400"}
          />
        )}
        renderInfoListItemDetail={p => (
          <DefaultInfoListItemDetail
            {...p}
            className={"text-sm leading-5 font-normal text-white"}
          />
        )}
      >
        <StyledCardInset>
          <p className={"text-xs leading-4 font-normal"}>
            {$t(
              defineMessage({
                defaultMessage: "You will inscribe",
                description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
              }),
            )}
          </p>

          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Ordinal",
                  description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              {$t(
                defineMessage({
                  defaultMessage: "BRC-20 Transfer",
                  description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
                }),
              )}
            </InfoListItemDetail>
          </InfoListItem>

          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Transfer",
                  description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TextTokenCount token={props.token} count={props.tokenCount} />
            </InfoListItemDetail>
          </InfoListItem>
        </StyledCardInset>

        <StyledCardInset>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Network",
                  description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              {$t(
                props.btcNetwork === "mainnet"
                  ? defineMessage({
                      defaultMessage: "BTC Mainnet",
                      description:
                        "Orderbook Peg-In Page/Inscribe Confirm Modal",
                    })
                  : defineMessage({
                      defaultMessage: "BTC Testnet",
                      description:
                        "Orderbook Peg-In Page/Inscribe Confirm Modal",
                    }),
              )}
            </InfoListItemDetail>
          </InfoListItem>
        </StyledCardInset>

        <StyledCardInset>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Inscription Service Fee",
                  description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TextTokenCount
                token={props.inscribeServiceFeeToken}
                count={props.inscribeServiceFeeTokenCount}
              />
              &nbsp; (
              <FiatCount count={props.inscribeServiceFeeTokenCountToUSD} />)
            </InfoListItemDetail>
          </InfoListItem>
        </StyledCardInset>

        <StyledCardInset>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Network Fee Rate",
                  description: "Orderbook Peg-In Page/Inscribe Confirm Modal",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount
                token={props.networkFeeRateToken}
                count={props.networkFeeRateTokenCount}
              />
              &nbsp;sats/vB&nbsp;&nbsp;
              <OpacityButton
                className="inline-flex flex-row gap-x-1 items-center"
                boxClassName="px-4 py-3"
                onClick={() => setEditFeeModalOpen(true)}
              >
                <ModifyIcon />
                <span className="text-xs leading-4 font-medium">
                  {$t(
                    defineMessage({
                      defaultMessage: "Modify",
                      description:
                        "Orderbook Peg-In Page/Inscribe Confirm Modal",
                    }),
                  )}
                </span>
              </OpacityButton>
              <Modal
                visible={editFeeModalOpen}
                onClose={() => setEditFeeModalOpen(false)}
              >
                <EditFeeModalContent
                  className="max-w-[30rem]"
                  recommendedNetworkFeeRates={recommendedBitcoinNetworkFeeRates}
                  networkFeeRate={networkFeeRate}
                  feeTokenInfo={props.networkFeeRateToken}
                  customRate={customRate}
                  onChangeNetworkFeeRate={onChangeNetworkFeeRate}
                  onConfirm={async () => {
                    await props.onChangeNetworkFeeRate(networkFeeRate)
                    setEditFeeModalOpen(false)
                  }}
                  onClose={() => setEditFeeModalOpen(false)}
                />
              </Modal>
            </InfoListItemDetail>
          </InfoListItem>
        </StyledCardInset>
      </InfoList>

      <div className={"flex flex-row gap-x-2.5 mt-[14px]"}>
        <Button className={"flex-1"} onClick={props.onClose}>
          {$t(cancel$t)}
        </Button>

        <SmartLoadableButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          onClick={props.onConfirm}
        >
          {$t(confirm$t)}
        </SmartLoadableButton>
      </div>
    </CardModalContent>
  )
}

const StyledCardInset: typeof CardInset = props => {
  return (
    <CardInset
      {...props}
      className={clsx(props.className, "flex flex-col gap-3")}
      boxClassName={props.boxClassName ?? "p-4"}
    />
  )
}
