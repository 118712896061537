import { contractAddr } from "../../../../../generated/smartContractHelpers/asSender"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["auto-alex", "auto-alex-v2"] as const
const functionName = "add-to-position" as const

export interface AutoStakingRowData extends BaseRowData {
  type: NotifyTransactionType.AutoStaking
  alexCurrencyAmount: CurrencyAmount.Confirmed
  atAlexCurrencyAmount: CurrencyAmount.Unknown | CurrencyAmount.Confirmed
}

export const autoStakingTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  AutoStakingRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.AutoStaking,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()

    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const atAlex =
      tx.contract_call.contract_id === contractAddr("auto-alex-v2")
        ? Currency.ATALEXV2
        : Currency.ATALEX

    return {
      alexCurrencyAmount: {
        currency: Currency.ALEX,
        amount: getAmountFromContract(args.dx),
      },
      atAlexCurrencyAmount: {
        currency: atAlex,
        amount: transferAmounts[atAlex]?.in,
      },
    }
  },
  pendingOrFailedTransformer: (args, tx) => {
    const atAlex =
      tx.contract_call.contract_id === contractAddr("auto-alex-v2")
        ? Currency.ATALEXV2
        : Currency.ATALEX
    return {
      alexCurrencyAmount: {
        currency: Currency.ALEX,
        amount: getAmountFromContract(args.dx),
      },
      atAlexCurrencyAmount: {
        currency: atAlex,
      },
    }
  },
}
