import { noop } from "lodash"
import { FC } from "react"
import { useIntl } from "react-intl"
import { Spensor } from "../../../../components/Spensor"
import { MaxButton } from "../../../../components/TokenInput/BalanceBottomArea"
import { oneOf } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TradingFormError, TradingFormErrorType } from "../types"
import { AvailableOutgoingTokenCount } from "./_/AvailableOutgoingTokenCount"
import { formFields$t } from "./_/commonIntlMessages"
import { LabelTokenInput } from "./_/LabelTokenInput"
import { PercentSlider } from "./_/PercentSlider"

interface StopLimitOrderFormProps {
  formError: SuspenseResource<undefined | TradingFormError>
  outgoingToken: TokenInfo
  maxAvailableOutgoingTokenCount: SuspenseResource<number>
  priceToken: TokenInfo
  perTradeTokenStopPrice: SuspenseResource<number>
  perTradeTokenLimitPrice: SuspenseResource<number>
  totalTradeTokenPrice: SuspenseResource<number>
  tradeToken: TokenInfo
  tradeTokenCount: SuspenseResource<number | null>
  tradeTokenCountPercentage: SuspenseResource<number>
  onPerTradeTokenStopPriceChange: (newPrice: null | number) => void
  onPerTradeTokenLimitPriceChange: (newPrice: null | number) => void
  onTotalTradeTokenPriceChange: (newPrice: null | number) => void
  onTradeTokenCountChange: (count: null | number) => void
  onTradeTokenCountPercentageChange: (percentage: number) => void
  perTradeTokenPricePrecision: number
}

export const StopLimitOrderForm: FC<StopLimitOrderFormProps> = props => {
  const { $t } = useIntl()
  return (
    <>
      <AvailableOutgoingTokenCount
        token={props.outgoingToken}
        tokenCount={props.maxAvailableOutgoingTokenCount}
      />

      <LabelTokenInput
        label={$t(formFields$t.stopPrice)}
        token={{
          ...props.priceToken,
          precision: props.perTradeTokenPricePrecision,
        }}
        value={props.perTradeTokenStopPrice}
        onChange={props.onPerTradeTokenStopPriceChange}
      />

      <LabelTokenInput
        label={$t(formFields$t.limitPrice)}
        token={{
          ...props.priceToken,
          precision: props.perTradeTokenPricePrecision,
        }}
        value={props.perTradeTokenLimitPrice}
        onChange={props.onPerTradeTokenLimitPriceChange}
        error={oneOf(TradingFormErrorType.LimitPriceTooSmall)(
          safeReadResource(props.formError)?.type,
        )}
      />

      <LabelTokenInput
        label={
          <span className={"flex items-center"}>
            {$t(formFields$t.amount)}&nbsp;
            <MaxButton
              boxClassName={"px-2 py-0.5"}
              textClassName={"text-blue-600 text-xs font-medium"}
              onClick={() => props.onTradeTokenCountPercentageChange(1)}
            />
          </span>
        }
        token={props.tradeToken}
        disabled={safeReadResource(props.maxAvailableOutgoingTokenCount) === 0}
        value={props.tradeTokenCount}
        onChange={props.onTradeTokenCountChange}
        error={oneOf(
          TradingFormErrorType.InsufficientTradeTokenBalance,
          TradingFormErrorType.TradeTokenCountTooSmall,
        )(safeReadResource(props.formError)?.type)}
      />

      <Spensor
        fallback={<PercentSlider disabled={true} value={0} onChange={noop} />}
      >
        {() => (
          <PercentSlider
            value={readResource(props.tradeTokenCountPercentage)}
            disabled={
              safeReadResource(props.maxAvailableOutgoingTokenCount) === 0
            }
            onChange={props.onTradeTokenCountPercentageChange}
          />
        )}
      </Spensor>

      <LabelTokenInput
        label={$t(formFields$t.total)}
        token={props.priceToken}
        value={props.totalTradeTokenPrice}
        disabled={safeReadResource(props.maxAvailableOutgoingTokenCount) === 0}
        onChange={props.onTotalTradeTokenPriceChange}
        error={oneOf(
          TradingFormErrorType.InsufficientPriceTokenBalance,
          TradingFormErrorType.TotalPriceTooSmall,
        )(safeReadResource(props.formError)?.type)}
      />
    </>
  )
}
