import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"

export interface TokenIconProps {
  className?: string

  displayClassName?: string

  token: TokenInfo

  /**
   * @default TokenIconProps.sizes.normal
   */
  size?: number
}
export namespace TokenIconProps {
  export namespace sizes {
    export const small = 24
    export const normal = 32
    export const large = 64
  }

  export const defaultSize = sizes.normal
}

export const TokenIcon: FC<TokenIconProps> = props => {
  const { size = TokenIconProps.defaultSize } = props

  return (
    <span
      className={clsx(
        "shrink-0",
        props.className,
        props.displayClassName ?? "inline-block",
      )}
      style={{ height: size }}
    >
      {props.token.icons.length > 1 ? (
        <MultiTokenIcon
          size={size}
          icons={props.token.icons}
          name={props.token.displayName}
        />
      ) : (
        <SingleTokenIcon
          size={size}
          icon={props.token.icons[0]!}
          name={props.token.displayName}
        />
      )}
    </span>
  )
}

const SingleTokenIcon: FC<{
  className?: string
  name: string
  icon: string
  size: number
}> = props => (
  <img
    className={"block"}
    alt={`Token ${props.name} icon`}
    src={props.icon}
    style={{ minWidth: props.size, maxHeight: props.size }}
    height={props.size}
  />
)

const MultiTokenIcon: FC<{
  className?: string
  name: string
  icons: string[]
  size: number
}> = props => {
  const marginLeft = -(props.size / 4)

  return (
    <ul className={clsx("flex flex-row-reverse", props.className)}>
      {props.icons
        .slice()
        .reverse()
        .map((icon, idx, tokens) => (
          <li
            key={idx}
            className={"shrink-0"}
            style={idx === tokens.length - 1 ? {} : { marginLeft }}
          >
            <SingleTokenIcon icon={icon} name={props.name} size={props.size} />
          </li>
        ))}
    </ul>
  )
}
