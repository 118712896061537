import { CSSProperties, FC } from "react"
import { useTestnetRewardsClaimStore } from "../../stores/testnetRewardsClaimStore/useTestnetRewardsClaimStore"
import { safelyGet } from "../../utils/waitFor"
import { WiredTransactionStateDialog } from "../TransactionStateDialog/WiredTransactionStateDialog"
import { TestnetRewardsClaimBanner } from "./TestnetRewardsClaimBanner"

export const WiredTestnetRewardsClaimBanner: FC<{ style?: CSSProperties }> = ({
  style,
}) => {
  const store = useTestnetRewardsClaimStore()
  if (!safelyGet(() => store.enabled$)) {
    return <div style={style} />
  }
  return (
    <>
      <TestnetRewardsClaimBanner
        rewardToken={store.tokenInfo$}
        rewardTokenCount={store.claimable$}
        cutDownTime={new Date(store.endTime$ * 1000)}
        onSubmit={() => store.claim()}
        style={style}
      />
      <WiredTransactionStateDialog store={store.claimRunning} />
    </>
  )
}
