import { FC } from "react"
import { IS_MAIN_NET } from "../../config"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { useChainStore } from "../../stores/chainStore/useChainStore"
import { Currency } from "../../utils/alexjs/Currency"
import { suspenseResource } from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import BlockStatus from "./BlockStatus"

const WiredBlockStatus: FC = () => {
  const chain = useChainStore()
  const appEnv = useAppEnvStore()
  const stake = chain.stakeChainModule(Currency.ALEX)
  return (
    <Spensor fallback={null}>
      {() => {
        if (!appEnv.config$.enableBlockIndicator) {
          return null
        }
        return (
          <BlockStatus
            className="sm:fixed bottom-5 left-5"
            networkType={IS_MAIN_NET ? "mainnet" : "testnet"}
            status={suspenseResource(() => chain.speedStatus$)}
            block={chain.currentBlockHeight$}
            cycleBlockStart={stake.currentCycleBlock$}
            cycleBlockEnd={stake.nextCycleBlock$ - 1}
            estimatedCycleEndDate={chain.estimatedDateForBlock$(
              stake.nextCycleBlock$ - 1,
            )}
          />
        )
      }}
    </Spensor>
  )
}

export default WiredBlockStatus
