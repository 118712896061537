import { CSSProperties, FC } from "react"
import { useIntl } from "react-intl"
import { FixedSizeList as List } from "react-window"
import { CustomScrollBarContainer } from "../../../../components/CustomScrollBarContainer"
import { LayoutMeasurer } from "../../../../components/LayoutMeasurer"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { SuspenseResource } from "../../../../utils/SuspenseResource"
import { gapSpacing } from "./OrderbookListFrame"
import { Order } from "./types"
import { Column1Cell, Column2Cell, Column3Cell, columnHeight } from "./_/Cells"
import { orderlistHeaders$t } from "./_/commonIntlMessages"
import { OrderListContainer } from "./_/OrderListContainer"
import {
  AmountCell,
  CreateTimeCell,
  OrderListItemContainer,
  PriceCell,
} from "./_/OrderListItem"

export const MyTradesOrderListContent: FC<{
  priceToken: TokenInfo
  tradeToken: TokenInfo
  orders: SuspenseResource<Order.MyTradeOrder[]>
  onClickOrder: (order: Order.MyTradeOrder) => void
}> = props => {
  const intl = useIntl()

  return (
    <>
      <div className={"flex text-gray-500"} style={{ marginBlock: gapSpacing }}>
        <Column1Cell>
          {orderlistHeaders$t.price(intl, props.priceToken)}
        </Column1Cell>
        <Column2Cell>
          {orderlistHeaders$t.amount(intl, props.tradeToken)}
        </Column2Cell>
        <Column3Cell>{orderlistHeaders$t.time(intl)}</Column3Cell>
      </div>

      <LayoutMeasurer className={"flex flex-col flex-1"}>
        {layout => (
          <OrderListContainer orders={props.orders}>
            {orders => (
              <CustomScrollBarContainer
                className={"flex-1 overflow-y-scroll min-h-0"}
                style={{ height: layout.height }}
              >
                {({ scrollableNodeRef, contentNodeRef }) => (
                  <List
                    width={"100%"}
                    height={layout.height}
                    itemSize={columnHeight}
                    itemCount={orders.length}
                    outerRef={scrollableNodeRef}
                    innerRef={contentNodeRef}
                    innerElementType={"ul"}
                  >
                    {({ index, style }) => (
                      <OrderListItem
                        style={{ ...style, marginBottom: gapSpacing }}
                        priceToken={props.priceToken}
                        tradeToken={props.tradeToken}
                        order={orders[index]!}
                        onClick={() => props.onClickOrder(orders[index]!)}
                      />
                    )}
                  </List>
                )}
              </CustomScrollBarContainer>
            )}
          </OrderListContainer>
        )}
      </LayoutMeasurer>
    </>
  )
}

const OrderListItem: FC<{
  className?: string
  style?: CSSProperties
  priceToken: TokenInfo
  tradeToken: TokenInfo
  order: Order.MyTradeOrder
  onClick: () => void
}> = props => {
  return (
    <OrderListItemContainer
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      <PriceCell priceToken={props.priceToken} order={props.order} />
      <AmountCell tradeToken={props.tradeToken} order={props.order} />
      <CreateTimeCell order={props.order} />
    </OrderListItemContainer>
  )
}
