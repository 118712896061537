import { AddressTransactionWithTransfers } from "@stacks/stacks-blockchain-api-types"
import {
  Currency,
  DownstreamCurrency,
  NativeCurrency,
  WrappedCurrency,
} from "./alexjs/Currency"
import {
  currencyScale,
  getNativeAndDownstreamCurrencyByAsset,
} from "./alexjs/currencyHelpers"
import { isEnumValue } from "./enumHelpers"

export function getCurrencyAmountFromTransactionWithTransfers(
  transaction: AddressTransactionWithTransfers,
): { [key in Currency]?: number } {
  const stx = (transaction.stx_transfers ?? []).map(t => ({
    currency: Currency.W_STX,
    amount: scaleDownCurrency(
      DownstreamCurrency.STX,
      Number.parseInt(t.amount, 10),
    ),
  }))

  const others = (transaction.ft_transfers ?? []).map(t => {
    const currency = getNativeAndDownstreamCurrencyByAsset(t.asset_identifier)
    if (!currency) {
      throw new Error(
        `Unknown transfer assets identifier: ${t.asset_identifier}`,
      )
    }
    return {
      currency,
      amount: scaleDownCurrency(currency, Number.parseInt(t.amount, 10)),
    }
  })

  const result = [...stx, ...others].reduce<{
    [key in Currency]?: number
  }>((acc, curr) => {
    if (!curr.currency) return acc

    const currency = isEnumValue(DownstreamCurrency, curr.currency)
      ? WrappedCurrency.wrap(curr.currency)
      : curr.currency

    if (acc[currency] == null) acc[currency] = 0
    acc[currency] = acc[currency]! + curr.amount
    return acc
  }, {})
  return result
}

export function getCurrencyAmountWithDirectionFromTransactionWithTransfers(
  value: AddressTransactionWithTransfers,
  address?: string,
): { [key in Currency]?: { in: number; out: number } } {
  const senderAddress = address ?? value.tx.sender_address
  const result = getTransfers(value).reduce<{
    [key in Currency]?: {
      in: number
      out: number
    }
  }>((acc, curr) => {
    if (!curr.currency) return acc

    const currency = isEnumValue(DownstreamCurrency, curr.currency)
      ? WrappedCurrency.wrap(curr.currency)
      : curr.currency

    if (acc[currency] == null) acc[currency] = { in: 0, out: 0 }
    if (curr.recipient === senderAddress) {
      acc[currency]!.in = acc[currency]!.in + curr.amount
    }
    if (curr.sender === senderAddress) {
      acc[currency]!.out = acc[currency]!.out + curr.amount
    }

    return acc
  }, {})

  return result
}

export interface Transfer {
  currency: Currency | DownstreamCurrency
  recipient?: string
  sender?: string
  amount: number
}

export function getTransfers(
  value: AddressTransactionWithTransfers,
): Transfer[] {
  const stx = (value.stx_transfers ?? []).map(t => ({
    currency: Currency.W_STX,
    recipient: t.recipient,
    sender: t.sender,
    amount: scaleDownCurrency(
      DownstreamCurrency.STX,
      Number.parseInt(t.amount, 10),
    ),
  }))

  const others = (value.ft_transfers ?? []).map(t => {
    const currency = getNativeAndDownstreamCurrencyByAsset(t.asset_identifier)
    if (!currency) {
      throw new Error(
        `Unknown transfer assets identifier: ${t.asset_identifier}`,
      )
    }
    return {
      currency,
      recipient: t.recipient,
      sender: t.sender,
      amount: scaleDownCurrency(currency, Number.parseInt(t.amount, 10)),
    }
  })

  return [...stx, ...others]
}

export function getTransferAmounts(
  transfers: Transfer[],
  senderAddress: string,
): { [key in Currency]?: { in: number; out: number } } {
  return transfers.reduce<{
    [currency in Currency]?: { in: number; out: number }
  }>((acc, curr) => {
    if (!curr.currency) return acc

    const currency = isEnumValue(DownstreamCurrency, curr.currency)
      ? WrappedCurrency.wrap(curr.currency)
      : curr.currency

    if (acc[currency] == null) acc[currency] = { in: 0, out: 0 }
    if (curr.recipient === senderAddress) {
      acc[currency]!.in = acc[currency]!.in + curr.amount
    }
    if (curr.sender === senderAddress) {
      acc[currency]!.out = acc[currency]!.out + curr.amount
    }
    return acc
  }, {})
}

const scaleDownCurrency = (
  currency: NativeCurrency | DownstreamCurrency,
  amount: number,
): number => {
  return amount / currencyScale(currency)
}
