import { ComponentType, PropsWithChildren } from "react"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { FCC } from "../../../../utils/reactHelpers/types"

export interface ButtonVariantProps extends PropsWithChildren<unknown> {
  className?: string
  boxClassName?: string
  textClassName?: string
  disabled?: boolean
  onClick?: () => void
}

export interface ButtonProps extends ButtonVariantProps {
  Variant?: ComponentType<ButtonVariantProps>
}
export const RectButton: FCC<ButtonProps> = props => {
  const { Variant = WhiteFilledButton, ...restProps } = props
  return (
    <Variant {...restProps} roundedClassName={"rounded-lg"}>
      {props.children}
    </Variant>
  )
}
