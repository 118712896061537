import clsx from "clsx"
import { mergeWith } from "lodash"
import { FC } from "react"
import { DayPicker, DayPickerProps } from "react-day-picker"
import defaultStyles from "react-day-picker/dist/style.module.css"
import styles from "./DatePicker.module.scss"

export const DatePicker: FC<DayPickerProps> = props => {
  return (
    <DayPicker
      {...props}
      classNames={mergeWith(
        defaultStyles,
        styles,
        (a: string, b: string) => `${a} ${b}`,
      )}
      modifiersClassNames={styles}
      className={clsx(props.className, styles.root)}
    />
  )
}
