/* eslint-disable @typescript-eslint/no-var-requires */

import { WrapBridgeNetwork } from "../types"

export namespace WrapBridgeNetworkPresets {
  export const Unknown: WrapBridgeNetwork = {
    id: "unknown",
    name: "Unknown",
    icon: require("./_/eth.svg").default,
  }
  export const Stacks: WrapBridgeNetwork = {
    id: "stacks",
    name: "Stacks Chain",
    icon: require("./_/stacks.svg").default,
  }
  export const Ethereum: WrapBridgeNetwork = {
    id: "ethereum",
    name: "Ethereum (ERC 20)",
    icon: require("./_/eth.svg").default,
  }
  export const Goerli: WrapBridgeNetwork = {
    id: "goerli",
    name: "Goerli (ERC 20)",
    icon: require("./_/eth.svg").default,
  }
  export const Sepolia: WrapBridgeNetwork = {
    id: "sepolia",
    name: "Sepolia (ERC 20)",
    icon: require("./_/eth.svg").default,
  }
  export const SepoliaFork: WrapBridgeNetwork = {
    id: "sepolia-fork",
    name: "Sepolia Fork (ERC 20)",
    icon: require("./_/eth.svg").default,
  }

  export const BSC: WrapBridgeNetwork = {
    id: "bsc",
    name: "BSC Mainnet",
    icon: require("./_/bsc.svg").default,
  }

  export const BSCTest: WrapBridgeNetwork = {
    id: "bsc-testnet",
    name: "BSC Testnet",
    icon: require("./_/bsc.svg").default,
  }

  export const BSCTestFork: WrapBridgeNetwork = {
    id: "bsc-testnet-fork",
    name: "BSC Testnet Fork",
    icon: require("./_/bsc.svg").default,
  }
}
