import React, { FC } from "react"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { PrizePoolPanel } from "../component/PrizePoolPanel"
import { useLotteryPageStore } from "../store/useLotteryPageStore"

export const WiredPrizePoolPanel: FC<{
  className?: string
  onClickRules: () => void
}> = props => {
  const lottery = useLotteryPageStore()
  const currency = useCurrencyStore()
  return (
    <PrizePoolPanel
      className={props.className}
      lotteryStage={suspenseResource(() => lottery.info.status$)}
      roundNumber={suspenseResource(() => lottery.info.currentLotteryId$)}
      totalLotteryPrize={suspenseResource(() => ({
        count: lottery.info.totalPot$,
        token: currency.getTokenInfo$(Currency.ALEX),
      }))}
      alexOfferedLotteryPrize={suspenseResource(() => ({
        count: lottery.info.totalBonus$,
        token: currency.getTokenInfo$(Currency.ALEX),
      }))}
      onClickRules={props.onClickRules}
    />
  )
}
