import { ComponentType, FC } from "react"
import ReactMarkdown from "react-markdown"
import type { PluggableList } from "react-markdown/lib/react-markdown"
import { TransformOptions } from "react-markdown/lib/react-markdown"
import rehypeRaw from "rehype-raw"
import { NavLink } from "../NavLink"

export interface MarkdownContentProps {
  className?: string
  plugins?: PluggableList
  components?: TransformOptions["components"] & {
    [tagName: string]: ComponentType
  }
  markdown?: string
}

export const MarkdownContent: FC<MarkdownContentProps> = props => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw, ...(props.plugins ?? [])]}
      className={props.className}
      components={{
        a: props => (
          <NavLink
            to={props.href ?? ""}
            title={props.title}
            id={props.id}
            className={props.className}
            style={props.style}
          >
            {props.children}
          </NavLink>
        ),
        ...props.components,
      }}
    >
      {props.markdown ?? ""}
    </ReactMarkdown>
  )
}
