import { isNotNull } from "../../../../utils/utils"
import { BuyLotteryRowData } from "../../store/transformerGroups/lottery/buyLottery"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseCurrencyAmount } from "../parser"

export const buyLotteryExporter: Exporter<BuyLotteryRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = [
    parseCurrencyAmount(rowData.tokenCurrencyAmount, allTokenInfos),
    "→ Lottery",
    rowData.bonusTickets
      ? parseCurrencyAmount(rowData.bonusCurrencyAmount, allTokenInfos)
      : undefined,
    rowData.bonusTickets ? "→ Lottery" : undefined,
  ]
    .filter(isNotNull)
    .join("\n")
  const tradeInfo = `Round #${rowData.lotteryId}`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
