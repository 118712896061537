import { action, computed, makeObservable, observable } from "mobx"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { Result } from "../../../../utils/Result"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { roundNumber } from "../../../../utils/numberHelpers"
import { assertNever } from "../../../../utils/types"
import { StxDxOrderType } from "../../../Orderbook/components/types"
import * as OrderbookStoreService from "../../../Orderbook/store/OrderbookStore.service/OrderbookStore.service"
import type { Leverage } from "../../components/types"
import {
  OrderDirection,
  TradingFormError,
  TradingFormErrorType,
  TradingFormWarningType,
} from "../../components/types"
import { PerpetualStore } from "../PerpetualStore"
import type { PerpetualTradeFormData } from "../PerpetualStore.service/PerpetualStore.service"

export const leverages = [10, 20, 30, 50, 100] as Leverage[]

export class PerpetualTradeModule {
  constructor(readonly store: PerpetualStore) {
    makeObservable(this)
  }

  @observable orderType: StxDxOrderType = StxDxOrderType.Limit

  @observable side: OrderDirection = OrderDirection.Long

  @observable leverage: Leverage = 10

  private tradeAmount = new SuspenseObservable<number>()

  @computed get tradeToken(): OrderbookStoreService.OrderbookAsset {
    return this.store.market.read$.tradeToken
  }

  getTradeAmount(): number | undefined {
    return this.tradeAmount.get()
  }

  @computed get priceToken(): OrderbookStoreService.OrderbookAsset {
    return this.store.market.read$.priceToken
  }

  @computed get feeRates$(): { maker: number; taker: number } {
    return {
      maker: this.store.info.senderFeeRate$,
      taker: this.store.info.senderFeeRate$,
    }
  }

  @computed get perTradeTokenPrice$(): number {
    const custom = this.customPrice.get()
    if (
      (this.orderType === StxDxOrderType.Limit || StxDxOrderType.StopLimit) &&
      custom != null
    ) {
      return custom
    }
    const tick = this.store.currentMarketSummary$
    return (
      (this.side === OrderDirection.Long ? tick.ask : tick.bid) || tick.price
    )
  }

  @computed get priceTokenBalance$(): number {
    return this.store.myInfo.stxDxBalance$(this.priceToken)?.available ?? 0
  }

  @computed get tradeTokenBalance$(): number {
    return this.store.myInfo.stxDxBalance$(this.tradeToken)?.available ?? 0
  }

  @computed get priceTokenCount$(): number {
    return roundNumber(this.tradeAmount.read$ * this.perTradeTokenPrice$, {
      precision: this.store.market.read$.pricePrecision,
      rounder: this.side === OrderDirection.Long ? Math.ceil : Math.floor,
    })
  }

  @computed get incomingTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(
      this.side === OrderDirection.Long ? this.tradeToken : this.priceToken,
    )
  }

  @computed get outgoingTokenBalance(): number {
    try {
      return this.side === OrderDirection.Long
        ? this.priceTokenBalance$
        : this.tradeTokenBalance$
    } catch (err) {
      if (err instanceof Promise) {
        return 0
      } else {
        throw err
      }
    }
  }

  @computed get formData$(): Result<PerpetualTradeFormData, TradingFormError> {
    if (!this.store.authStore.isWalletConnected) {
      return Result.error({
        type: TradingFormErrorType.ConnectWalletRequired as const,
      })
    }

    if (this.tradeAmount.get() == null) {
      return Result.error({
        type: TradingFormErrorType.AmountIsEmpty as const,
      })
    }

    const MINIMUM_PRICE_TOKEN_COUNT = 0.1
    if (this.priceTokenCount$ < MINIMUM_PRICE_TOKEN_COUNT) {
      return Result.error({
        type: TradingFormErrorType.TotalPriceTooSmall as const,
        priceToken: this.store.currency.getTokenInfo$(this.priceToken),
        minimumPriceTokenCount: MINIMUM_PRICE_TOKEN_COUNT,
      })
    }

    if (
      this.side === OrderDirection.Long &&
      this.priceTokenCount$ > this.priceTokenBalance$
    ) {
      return Result.error({
        type: TradingFormErrorType.InsufficientPriceTokenBalance as const,
      })
    }

    if (
      this.side === OrderDirection.Long &&
      this.tradeAmount.read$ > this.tradeTokenBalance$
    ) {
      return Result.error({
        type: TradingFormErrorType.InsufficientTradeTokenBalance as const,
      })
    }

    let warning: undefined | { type: TradingFormWarningType }

    if (
      [StxDxOrderType.Limit, StxDxOrderType.StopLimit].includes(
        this.orderType,
      ) &&
      this.side === OrderDirection.Long &&
      this.perTradeTokenPrice$ &&
      this.store.orderbook.bestAskPrice$ &&
      this.perTradeTokenPrice$ > this.store.orderbook.bestAskPrice$
    ) {
      warning = { type: TradingFormWarningType.AbnormalBuyingPrice }
    }

    if (
      [StxDxOrderType.Limit, StxDxOrderType.StopLimit].includes(
        this.orderType,
      ) &&
      this.side === OrderDirection.Short &&
      this.perTradeTokenPrice$ &&
      this.store.orderbook.bestBidPrice$ &&
      this.perTradeTokenPrice$ < this.store.orderbook.bestBidPrice$
    ) {
      warning = { type: TradingFormWarningType.AbnormalSellingPrice }
    }

    const data: PerpetualTradeFormData = {
      warning: warning,
      outgoingToken: this.outgoingTokenInfo$,
      incomingToken: this.incomingTokenInfo$,
      stxAddress: this.store.authStore.stxAddress$,
      currentHeight: this.store.chainStore.currentBlockHeight$,
      currentUserId: this.store.myInfo.registeredUserId$,
      currentUserAuth: this.store.myInfo.authJWT$,
      size: this.tradeAmount.read$,
      price: this.perTradeTokenPrice$,
      side: this.side,
      currentPrice: this.store.currentPrice$,
      market: this.store.market.read$,
      orderType: this.orderType,
      stopPrice:
        this.orderType === StxDxOrderType.StopLimit
          ? this.stopPrice.read$
          : undefined,
      senderFeeRate: this.store.info.senderFeeRate$,
    }
    return Result.ok(data)
  }

  @computed get outgoingTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(
      this.side === OrderDirection.Long ? this.priceToken : this.tradeToken,
    )
  }

  @computed get tradeTokenPrecision$(): number {
    return TokenInfo.getPrecision(
      this.store.currency.getTokenInfo$(this.tradeToken),
    )
  }

  @computed get priceTokenPrecision$(): number {
    return this.store.market.read$.pricePrecision
  }

  @computed get tradePercentage$(): number {
    if (this.tradeAmount.get() === undefined) {
      return 0
    }
    switch (this.side) {
      case OrderDirection.Long:
        return (
          ((this.priceTokenCount$ ?? 0) / this.priceTokenBalance$) *
          this.store.info.amountPlusSenderFeeRate$
        )
      case OrderDirection.Short:
        return (
          (this.tradeAmount.read$ / this.tradeTokenBalance$) *
          this.store.info.amountPlusSenderFeeRate$
        )
      default:
        assertNever(this.side)
    }
  }

  customPrice = new SuspenseObservable<number>()
  stopPrice = new SuspenseObservable<number>()

  @action setOrderType(type: StxDxOrderType): void {
    this.orderType = type
  }

  @action setLeverage(leverage: Leverage): void {
    this.leverage = leverage
  }

  emptyTradeAmount(): void {
    this.tradeAmount.set(undefined)
  }

  setTradeAmount(amount: number): void {
    return this.tradeAmount.set(
      roundNumber(amount, {
        precision: this.tradeTokenPrecision$,
        rounder: this.side === OrderDirection.Long ? Math.floor : Math.ceil,
      }),
    )
  }

  @action setCustomPrice(price?: number): void {
    this.customPrice.set(price)
  }

  @action setTotal(total?: number): void {
    if (total == null) {
      this.emptyTradeAmount()
    } else {
      this.setTradeAmount(total / this.perTradeTokenPrice$)
    }
  }

  @action setPercentage(percentage: number): void {
    switch (this.side) {
      case OrderDirection.Long:
        this.setTradeAmount(
          ((percentage * this.priceTokenBalance$) / this.perTradeTokenPrice$) *
            (1 / this.store.info.amountPlusSenderFeeRate$),
        )
        break
      case OrderDirection.Short:
        this.setTradeAmount(
          percentage *
            this.tradeTokenBalance$ *
            (1 / this.store.info.amountPlusSenderFeeRate$),
        )
        break
      default:
        assertNever(this.side)
    }
  }

  @observable confirming = false

  // @asyncAction
  // trade(
  //   data: PerpetualTradeFormData,
  //   run = runAsyncAction,
  // ): Promise<void> {
  //   console.log(data, "🍎")
  //   this.confirming = false
  //   await run(tradeInStxDx(data))
  //   this.emptyTradeAmount()
  // }
  async trade(): Promise<void> {
    throw new Error("WIP")
    // const uid = await waitFor(
    //   () => this.store.stxDxStore.myInfo.registeredUserId$,
    // )
    // const jwt = await waitFor(() => this.store.stxDxStore.myInfo.authJWT$)
    // await sendRequest(jwt)("PerpetualController_previewPerpetualOrder", {
    //   body: {
    //     type: "vanilla",
    //     market: this.store.marketId$ as any,
    //     maker: String(uid),
    //     expiration_height: String(1e8),
    //     sender_fee: String(this.store.info.senderFeeRate$ * 1e8),
    //     side: "buy",
    //     size: String(900),
    //     risk: true,
    //     price: String(20000),
    //     leverage: String(10),
    //     timestamp: String(BigInt(Date.now())),
    //     stop_price: String(30000),
    //     salt: "2",
    //   },
    // })
    // await sendRequest(jwt)("PerpetualController_createOrder", {
    //   body: {
    //     order: "111",
    //     signature: "signature",
    //   },
    // })
  }
}
