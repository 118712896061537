import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BorrowRowData } from "../../../../store/transformerGroups/lend/borrow"
import { AllTokenInfos } from "../../../../types"
import { BaseCellContainer } from "../../cells/BaseCellContainer"
import { OneLineAmountCell } from "../../cells/OneLineAmountCell"
import { OneLineCurrencyCell } from "../../cells/OneLineCurrencyCell"
import { BaseRow } from "../BaseRow"

export const BorrowRow: FC<{
  rowData: BorrowRowData
  allTokenInfos: AllTokenInfos
}> = ({ rowData, allTokenInfos }) => {
  const { $t } = useIntl()
  return (
    <BaseRow
      rowData={rowData}
      currencyCell={
        <OneLineCurrencyCell
          fromCurrencyAmounts={[rowData.collateralCurrencyAmount]}
          toCurrencyAmounts={[rowData.tokenCurrencyAmount]}
          allTokenInfos={allTokenInfos}
        />
      }
      amountCell={
        <OneLineAmountCell
          fromCurrencyAmounts={[rowData.collateralCurrencyAmount]}
          toCurrencyAmounts={[rowData.tokenCurrencyAmount]}
          allTokenInfos={allTokenInfos}
          fromSuffix={$t(
            defineMessage({
              defaultMessage: "(Collateral)",
              description: "/Notify/Transaction/Table/Borrow row",
            }),
          )}
          toSuffix={$t(
            defineMessage({
              defaultMessage: "(Borrowed)",
              description: "/Notify/Transaction/Table/Borrow row",
            }),
          )}
        />
      }
      tradeInfoCell={
        <BaseCellContainer>
          {$t(
            defineMessage({
              defaultMessage: "Borrow",
              description: "/Notify/Transaction/Table/Borrow row",
            }),
          )}
        </BaseCellContainer>
      }
    />
  )
}
