import { bytesToHex, hexToBytes } from "@stacks/common"
import { openStructuredDataSignatureRequestPopup } from "@stacks/connect"
import type { SignatureData } from "@stacks/connect/dist/types/types/signature"
import { serializeCV } from "@stacks/transactions"
import { gql } from "@urql/core"
import { round, zipObject } from "lodash"
import { Observable, map } from "rxjs"
import { STACK_APP_DETAILS, STACK_NETWORK } from "../../../../config"
import {
  FetchBrc20MarketInfoQuery,
  FetchBrc20MarketInfoQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { AlexContracts } from "../../../../generated/smartContract/contracts_Alex"
import { asSender } from "../../../../generated/smartContractHelpers/asSender"
import { components } from "../../../../generated/stxdx/types"
import {
  sendPublicRequest,
  sendRequest,
} from "../../../../generated/stxdxHelpers/stxdxApi"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import { CurrencyAndBRC20s } from "../../../../utils/alexjs/Currency"
import { transfer } from "../../../../utils/alexjs/postConditions"
import { CancelError } from "../../../../utils/error"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { toAPIString } from "../../../../utils/numberHelpers"
import { assertNever } from "../../../../utils/types"
import { Bar } from "../../../../vendors/charting_library/charting_library.esm"
import { type Brc20MarketInfo } from "../../components/SummaryBarForBrc20"
import { StxDxOrderType, TradingFormWarningType } from "../../components/types"
import { signatureDomain } from "../constants"
import {
  OrderbookMarket,
  OrderbookMarketId,
} from "../stxdx_shared/StxDxMarket.service"
import {
  TemporarilyStoredSignInfoForInitialDeposit,
  getAuthSignatureFromWallet,
} from "../stxdx_shared/StxDxMyInfoModule.service"
import { AssetBalance, getUserBalances } from "./getUserBalances"

export type OrderbookAsset = CurrencyAndBRC20s & {
  readonly _brand: unique symbol
}

export type DepositFormData = {
  stxAddress: string
  userId?: number
  tokens: {
    amount: number
    assetId: number
    asset: CurrencyAndBRC20s
  }[]
}

export async function depositToStxDx(
  data: DepositFormData,
): Promise<{ txId: string }> {
  if (data.userId != null) {
    return await asSender(data.stxAddress)
      .contract("register-user-helper-v1-03")
      .func("transfer-in-many")
      .call(
        {
          amounts: data.tokens.map(t => t.amount * 1e8),
          "asset-ids": data.tokens.map(t => t.assetId),
          "asset-traits": data.tokens.map(t => t.asset),
        },
        data.tokens.map(t => transfer(data.stxAddress, t.asset, t.amount)),
      )
  }
  const { signature, publicKey, payload } = await getAuthSignatureFromWallet(
    data.stxAddress,
    Math.floor(60 * 60 * 5 + Date.now() / 1000),
  )
  TemporarilyStoredSignInfoForInitialDeposit.saveSig(payload, signature)
  return await asSender(data.stxAddress)
    .contract("register-user-helper-v1-03")
    .func("register-and-deposit")
    .call(
      {
        "pub-key": hexToBytes(publicKey),
        amounts: data.tokens.map(t => t.amount * 1e8),
        "asset-ids": data.tokens.map(t => t.assetId),
        "asset-traits": data.tokens.map(t => t.asset),
      },
      data.tokens.map(t => transfer(data.stxAddress, t.asset, t.amount)),
    )
}

export type WithdrawFormData = {
  stxAddress: string
  tokens: {
    amount: number
    assetId: number
    asset: CurrencyAndBRC20s
  }[]
  userId: number
}

export function withdrawFromStxDx(
  data: WithdrawFormData,
): Promise<{ txId: string }> {
  return asSender(data.stxAddress)
    .contract("stxdx-wallet-zero")
    .func("request-transfer-out-many")
    .call(
      {
        amounts: data.tokens.map(t => t.amount * 1e8),
        "asset-ids": data.tokens.map(t => t.assetId),
        assets: data.tokens.map(t => t.asset),
        "user-id": data.userId,
      },
      [],
    )
}

export type TradeFormData = {
  warning?: { type: TradingFormWarningType }
  side: "buy" | "sell"
  orderType: StxDxOrderType
  outgoingToken: TokenInfo
  incomingToken: TokenInfo
  market: OrderbookMarket
  stxAddress: string
  currentHeight: number
  currentUserId: number
  currentUserAuth: string
  currentPrice: number
  price: number
  stopPrice?: number
  size: number
  senderFeeRate: number
}

const OrderTranscoder =
  AlexContracts["stxdx-sender-proxy-v1-02"]["match-orders"].input[0].type

export const MARKET_PRICE_TOLERANCE_RADIO = 1.1

export async function tradeInStxDx(data: TradeFormData): Promise<void> {
  const size = round(data.size, 8 - data.market.pricePrecision)
  const shared: Pick<
    components["schemas"]["ConvertOrderRequest"],
    | "market"
    | "maker"
    | "expiration_height"
    | "sender_fee"
    | "side"
    | "size"
    | "risk"
  > = {
    market: data.market.marketId as any,
    maker: toAPIString(data.currentUserId),
    expiration_height: toAPIString(1e8),
    sender_fee: toAPIString(data.senderFeeRate * 1e8),
    side: data.side === "buy" ? "buy" : "sell",
    size: toAPIString(size),
    risk: false,
  }
  const request: components["schemas"]["ConvertOrderRequest"] =
    data.orderType === StxDxOrderType.Limit
      ? {
          ...shared,
          type: "vanilla",
          price: toAPIString(round(data.price, data.market.pricePrecision)),
        }
      : data.orderType === StxDxOrderType.Market
      ? {
          ...shared,
          type: "ioc",
          price: toAPIString(
            round(
              data.side === "buy"
                ? data.price * MARKET_PRICE_TOLERANCE_RADIO
                : data.price / MARKET_PRICE_TOLERANCE_RADIO,
              data.market.pricePrecision,
            ),
          ),
        }
      : data.orderType === StxDxOrderType.StopLimit
      ? {
          ...shared,
          type: "vanilla",
          risk:
            data.side === "sell"
              ? data.stopPrice! < data.currentPrice
              : data.stopPrice! > data.currentPrice,
          price: toAPIString(round(data.price, data.market.pricePrecision)),
          stop_price: toAPIString(
            round(data.stopPrice!, data.market.pricePrecision),
          ),
        }
      : assertNever(data.orderType)

  const { data: response } = await sendRequest(data.currentUserAuth)(
    "OrderController_convertOrder",
    {
      body: request,
    },
  )

  const clairty = OrderTranscoder.encode({
    "expiration-height": Number(response["expiration-height"]),
    maker: Number(response["maker"]),
    "maker-asset": Number(response["maker-asset"]),
    "maker-asset-data": Number(response["maker-asset-data"]),
    "maximum-fill": Number(response["maximum-fill"]),
    salt: Number(response["salt"]),
    sender: Number(response["sender"]),
    "sender-fee": Number(response["sender-fee"]),
    "taker-asset": Number(response["taker-asset"]),
    "taker-asset-data": Number(response["taker-asset-data"]),
    // type: 1,
    type: Number(response.type),
    risk: response.risk,
    timestamp: Number(response.timestamp),
    stop: Number(response.stop),
  })
  const signature = await new Promise<SignatureData>(
    async (resolve, reject) => {
      await openStructuredDataSignatureRequestPopup({
        stxAddress: data.stxAddress,
        domain: signatureDomain,
        message: clairty,
        appDetails: STACK_APP_DETAILS,
        network: STACK_NETWORK as any,
        onFinish: resolve,
        onCancel: () => reject(new CancelError("User cancelled")),
      })
    },
  )
  await sendRequest(data.currentUserAuth)("OrderController_createOrder", {
    body: {
      order: bytesToHex(serializeCV(clairty)),
      signature: signature.signature,
    },
  })
}

export type UserBalance = Record<keyof AssetBalance, number>

type UserBalances = Map<OrderbookAsset, UserBalance>

export function getUserBalance(
  jwt: string,
  assetIdMap: { [assetId: number]: OrderbookAsset },
): Observable<UserBalances> {
  return getUserBalances(jwt).pipe(
    map(data => {
      const balances: UserBalances = new Map()
      for (const assetId of Object.keys(data)) {
        const assetName = assetIdMap[Number(assetId)]
        const balance = data[assetId]
        if (assetName == null || balance == null) continue
        balances.set(assetName, {
          balance: Number(balance.balance) / 1e8,
          locked: Number(balance.locked) / 1e8,
          incoming: Number(balance.incoming) / 1e8,
          withdraw: Number(balance.withdraw) / 1e8,
          available: Number(balance.available) / 1e8,
        })
      }
      return balances
    }),
  )
}

export async function fetchChartDataFor({
  market,
  resolution,
  from,
  to,
  countBack,
}: {
  market: OrderbookMarketId
  resolution: number // int in minutes, e.g. 1 for 1 minute, 60 for 1 hour
  to: string // unix timestamp in seconds as string or 'now'
  countBack?: number
  from?: number // unix timestamp in seconds
}): Promise<{ bars: Bar[]; meta: { noData: boolean; nextTime?: number } }> {
  const { data: responseData } = await sendPublicRequest(
    "OrderController_getTradingView",
    {
      path: { market },
      query: { resolution, to, countback: countBack, from },
    },
  )
  const columns = ["time", ...responseData.metadata.columns.slice(1)]
  const bars: Bar[] = responseData.series.map<Bar>(s => {
    const [date, ...rest] = s
    const result = zipObject<number>(columns, [
      new Date(Number.parseInt(date!, 10)).valueOf() * 1000,
      ...rest.map(x => Number.parseFloat(x)),
    ])
    result.volume = result.volume! / 1e8
    return result as unknown as Bar
  })
  bars.reverse()
  const noData = bars.length === 0
  return {
    bars,
    meta: {
      noData,
      nextTime: noData ? undefined : responseData.metadata.nextTime,
    },
  }
}

export function getBrc20MarketInfo(
  marketId: string,
): Observable<undefined | Brc20MarketInfo> {
  return fromUrqlSource(
    gqlQuery<FetchBrc20MarketInfoQuery, FetchBrc20MarketInfoQueryVariables>(
      gql`
        query FetchBrc20MarketInfo($marketId: String!) {
          orderbookMarketsCollection(where: { marketId: $marketId }) {
            items {
              brc20MarketInfo {
                baseTokenSymbol
                inscriptionAddress
                inscriptionAddressLink
                totalSupply
                minted
                limitPerMint
                decimals
                deployerAddress
                deployerAddressLink
                deployedAt
                completedAt
                inscriptionNumberFrom
                inscriptionNumberTo
              }
            }
          }
        }
      `,
      { marketId },
    ),
    (result): undefined | Brc20MarketInfo => {
      const item =
        result.data.orderbookMarketsCollection?.items[0]?.brc20MarketInfo
      if (item == null) return undefined
      return {
        baseTokenSymbol: item.baseTokenSymbol!,
        inscriptionAddr: item.inscriptionAddress!,
        inscriptionAddrUrl: item.inscriptionAddressLink!,
        totalSupply: item.totalSupply!,
        minted: item.minted!,
        mintedPercentage: item.minted! / item.totalSupply!,
        limitPerMint: item.limitPerMint!,
        decimals: item.decimals!,
        deployerAddr: item.deployerAddress!,
        deployerAddrUrl: item.deployerAddressLink!,
        deployedAt: new Date(item.deployedAt!),
        completedAt: new Date(item.completedAt!),
        inscriptionNumber: [
          item.inscriptionNumberFrom!,
          item.inscriptionNumberTo!,
        ],
      }
    },
  )
}
