import { FC, ReactNode } from "react"
import PoolTokenName from "../../pages/Farm/components/PoolTokenName"
import { SuspenseResource, readResource } from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { APowerTip } from "../APowerTip/APowerTip"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import {
  BlockProps,
  BlockTokenLine,
  DefaultTokenNameArea,
  renderThinnerBlock,
} from "./Block"

export interface TokenCountPair {
  token: TokenInfo
  count: SuspenseResource<number>
}

export interface TokenCountPairPlain {
  token: TokenInfo
  count: number
}

export const TokenBlock: FC<{
  className?: string
  tokens: {
    token: SuspenseResource<TokenInfo>
    count: SuspenseResource<number>
  }[]
  topArea?: ReactNode
  bottomArea?: ReactNode
  renderBlock?: (props: BlockProps) => JSX.Element
}> = props => {
  const { renderBlock = renderThinnerBlock } = props

  const blockMidChild = (
    <div className={"flex flex-col gap-2.5"}>
      {props.tokens.map((p, idx) => (
        <BlockTokenLine
          key={idx}
          tokenNameArea={
            <DefaultTokenNameArea
              TokenNameComponent={PoolTokenName}
              token={p.token}
              boxClassName={""}
              afterTokenNameArea={
                <Spensor>
                  {() => (
                    <>
                      {TokenInfoPresets.isAPower(readResource(p.token)) && (
                        <APowerTip />
                      )}
                    </>
                  )}
                </Spensor>
              }
            />
          }
          tokenCountArea={
            <Spensor fallback={"-"}>
              {() => (
                <TokenCount token={p.token} count={readResource(p.count)} />
              )}
            </Spensor>
          }
        />
      ))}
    </div>
  )

  const blockProps: BlockProps = {
    className: props.className,
    children: (
      <>
        {props.topArea}
        {blockMidChild}
        {props.bottomArea}
      </>
    ),
  }

  return renderBlock(blockProps)
}
