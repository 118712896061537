import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BRC20InscriptionStatusType } from "../BRC20Card/BRC20InscriptionStatusCard"
import { PegInStepsBarItem } from "./PegInStepsBarItem"

const statusMapping: {
  [status in BRC20InscriptionStatusType]: [step: number, failed?: boolean]
} = {
  inscribing: [0],
  transferable: [1],
  "sending-peg-in-request": [1],
  "send-peg-in-request-failed": [1, true],
  "sent-peg-in-request": [2],
  "sending-to-alex": [2],
  "send-to-alex-failed": [2, true],
  "sent-to-alex": [2],
  "pegged-in": [3],
  "peg-in-failed": [2, true],
}

export const PegInStepsBar: FC<{
  className?: string
  currentStatus: BRC20InscriptionStatusType
}> = props => {
  const { $t } = useIntl()
  const steps = [
    $t(
      defineMessage({
        defaultMessage: "Inscribe Transfer",
        description: "BRC20 Peg-In Page/Steps Bar/steps",
      }),
    ),
    $t(
      defineMessage({
        defaultMessage: "Send Peg-in",
        description: "BRC20 Peg-In Page/Steps Bar/steps",
      }),
    ),
    $t(
      defineMessage({
        defaultMessage: "Send to ALEX",
        description: "BRC20 Peg-In Page/Steps Bar/steps",
      }),
    ),
  ]
  return (
    <ul className={clsx("py-2.5 flex flex-row gap-x-2", props.className)}>
      {steps.map((step, idx) => (
        <>
          <PegInStepsBarItem
            key={idx}
            currentStep={statusMapping[props.currentStatus][0]}
            failed={statusMapping[props.currentStatus][1]}
            index={idx}
            name={step}
          />
          {idx === steps.length - 1 ? null : (
            <div className="border-t border-gray-600 grow h-0 self-center" />
          )}
        </>
      ))}
    </ul>
  )
}
