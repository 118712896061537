import { createContext, useContext } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"

export interface ThemeColor {
  color(color: never): void
}

export type AnyColorsDefinition = Readonly<Record<string, string>>

const ColorsContext = createContext<null | AnyColorsDefinition>(null)

export const ColorsProvider: FCC<{
  colors: AnyColorsDefinition
}> = props => {
  return (
    <ColorsContext.Provider value={props.colors}>
      {props.children}
    </ColorsContext.Provider>
  )
}

export type ColorGetter = (color: Parameters<ThemeColor["color"]>[0]) => string

export const useColors = (): ColorGetter => {
  const palette = useContext(ColorsContext)

  if (palette == null) {
    throw new Error("ColorsProvider not found")
  }

  return usePersistFn(color => {
    if (palette[color] == null) {
      throw new Error(`Color "${color}" not defined`)
    }

    return palette[color]!
  })
}
