import * as React from "react"
import { FC, SVGProps, useMemo } from "react"
import { uuid } from "../../../../utils/stringHelpers"

export const SilverCrown: FC<SVGProps<SVGSVGElement>> = props => {
  const id1 = useMemo(() => uuid(), [])
  const id2 = useMemo(() => uuid(), [])

  return (
    <svg
      width={24}
      height={25}
      viewBox={"0 0 24 25"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id1})`}>
        <path
          d="M22 19.002v3H2v-3h20zm0-15c-1.5 0-2.662 1.684-1.598 3.193.535.76.406 1.216.045 1.75C19.682 10.071 18.575 11 17 11c-2.521 0-3.854-2.083-4.131-3.848-.096-.614-.15-1.074.436-1.644.402-.39.695-.904.695-1.508a2 2 0 00-4 0c0 .604.293 1.118.695 1.508.586.57.531 1.03.436 1.644-.277 1.765-1.61 3.849-4.131 3.849-1.575 0-2.682-.93-3.447-2.059-.362-.532-.491-.989.045-1.748C4.662 5.686 3.5 4.001 2 4.001a2 2 0 00-2 2c0 .797.464 1.495 1.144 1.808C1.969 8.19 2 9.126 2 9.98v7.021h20v-7.02c0-.855.031-1.793.856-2.172A1.987 1.987 0 0024 6.001a2 2 0 00-2-2z"
          fill={`url(#${id2})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`${id2}`}
          x1={24}
          y1={24.918}
          x2={12.228}
          y2={6.894}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D437A" />
          <stop offset={0.719} stopColor="#9CC4FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id={`${id1}`}>
          <path fill="#fff" transform="translate(0 .001)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
