import clsx from "clsx"
import { CSSProperties, ReactNode } from "react"
import { useColors } from "../../../../../components/Themed/color"
import { FCC } from "../../../../../utils/reactHelpers/types"

interface BasicCardTabProps {
  className?: string
  style?: CSSProperties
  tintColor?: string
  contentText?: ReactNode
}

export const BasicCardTab: FCC<BasicCardTabProps> = props => {
  const colors = useColors()

  return (
    <div
      className={clsx(
        "px-5 py-1 rounded-t-lg",
        "text-base leading-6 font-semibold text-white",
        props.className,
      )}
      style={{
        backgroundColor: props.tintColor ?? colors("gray-700"),
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

export const CardTabContainer: FCC<{ className?: string }> = props => {
  return (
    <div
      className={clsx(
        "flex flex-row items-end gap-x-2.5 pt-1.5",
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}
