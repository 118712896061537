import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { WhiteGradientAlert } from "../../../../../components/alert/WhiteGradientAlert"
import { btnPresets, Button } from "../../../../../components/button/Button"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { PlainIconButton } from "../../../../../components/button/variants/PlainIconButton"
import {
  CollapsableCard,
  IdentityContentContainer,
} from "../../../../../components/CollapsableCard/CollapsableCard"
import { EmptyState } from "../../../../../components/EmptyState/EmptyState"
import {
  createInfoListItemDetail,
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { IconTokenCount } from "../../../../../components/RichTokenCount"
import { Spensor } from "../../../../../components/Spensor"
import { TimeCountdown } from "../../../../../components/TimeCountdown/TimeCountdown"
import { TipIcon } from "../../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenCountPair } from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { readResource } from "../../../../../utils/SuspenseResource"
import { CollapsableCardScrollableRow } from "../../CollapsableCardScrollableRow"
import { ReactComponent as HourlyStatIcon } from "./hourlyStat.svg"

export type BorrowRecord = BorrowRecord.Active | BorrowRecord.Expired
export namespace BorrowRecord {
  export interface Common {
    onClickHourlyStat: () => void
    collateral: TokenCountPair[]
    collateralIntrinsicValue: TokenCountPair
    borrowed: TokenCountPair[]
  }

  export interface Active extends Common {
    status: "active"
    expiredAt: Date
  }

  export interface Expired extends Common {
    status: "expired"
    estimateClaimable: TokenCountPair[]
    estimateClaimableIntrinsicValue: TokenCountPair
    onClaim: () => void
    onRollover: () => void
  }
}

export const MyBorrowsPanel: FC<{
  className?: string
  defaultCollapsed?: boolean
  borrowRecords: BorrowRecord[]
}> = props => {
  const { $t } = useIntl()
  return (
    <CollapsableCard
      className={props.className}
      title={$t(
        defineMessage({
          defaultMessage: "Borrowed",
          description: "/Lend/BorrowPage/MyBorrowsPanel/Titlte",
        }),
      )}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={IdentityContentContainer}
    >
      {props.borrowRecords.map((r, idx) => (
        <CollapsableCardScrollableRow key={idx}>
          <WhiteGradientAlert withInteractionEffect={true}>
            {r.status === "active" ? (
              <ActiveBorrowRecord record={r} />
            ) : (
              <ExpiredBorrowRecord record={r} />
            )}
          </WhiteGradientAlert>
        </CollapsableCardScrollableRow>
      ))}
      {!props.borrowRecords.length && (
        <EmptyState className={"m-auto"}>
          {$t(
            defineMessage({
              defaultMessage: "No Borrow Data",
              description: "/Lend/BorrowPage/MyBorrowsPanel/Empty state",
            }),
          )}
        </EmptyState>
      )}
    </CollapsableCard>
  )
}

const ActiveBorrowRecord: FC<{ record: BorrowRecord.Active }> = props => {
  const { $t } = useIntl()
  return (
    <RecordRowInfoListContainer>
      <InfoListItem>
        <InfoListItemTitle className="flex items-center gap-2">
          {$t(
            defineMessage({
              defaultMessage: "Collateral",
              description:
                "/Lend/BorrowPage/ActiveBorrowRecord/Info List Item title",
            }),
          )}
          <PlainIconButton
            icon={HourlyStatIcon}
            onClick={props.record.onClickHourlyStat}
          />
        </InfoListItemTitle>
        <InfoListItemDetail>
          {props.record.collateral.map((c, idx) => (
            <IconTokenCount
              key={idx}
              className={"block"}
              token={c.token}
              count={c.count}
            />
          ))}
          <EstimateValue
            intrinsicValue={props.record.collateralIntrinsicValue}
          />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Borrowed",
              description:
                "/Lend/BorrowPage/ActiveBorrowRecord/Info List Item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          {props.record.borrowed.map((c, idx) => (
            <IconTokenCount key={idx} token={c.token} count={c.count} />
          ))}
        </InfoListItemDetail>
      </InfoListItem>
      <div className={"flex-1"} />
      <FinalSection>
        <GradientFilledButton
          {...btnPresets.smallMerge({
            className: "min-w-[150px]",
            disabled: true,
          })}
        >
          {$t(
            defineMessage({
              defaultMessage: "Claim",
              description: "/Lend/BorrowPage/ActiveBorrowRecord/Button text",
            }),
          )}
        </GradientFilledButton>
        <TimeCountdown time={props.record.expiredAt} />
      </FinalSection>
    </RecordRowInfoListContainer>
  )
}

const ExpiredBorrowRecord: FC<{ record: BorrowRecord.Expired }> = props => {
  const { $t } = useIntl()
  return (
    <RecordRowInfoListContainer>
      <InfoListItem>
        <InfoListItemTitle className="flex items-center gap-2">
          {$t(
            defineMessage({
              defaultMessage: "Collateral",
              description:
                "/Lend/BorrowPage/ExpiredBorrowRecord/Info List Item title",
            }),
          )}
          <HeadlessButton onClick={props.record.onClickHourlyStat}>
            <HourlyStatIcon />
          </HeadlessButton>
        </InfoListItemTitle>
        <InfoListItemDetail>
          {props.record.collateral.map((c, idx) => (
            <IconTokenCount key={idx} token={c.token} count={c.count} />
          ))}
          <EstimateValue
            intrinsicValue={props.record.collateralIntrinsicValue}
          />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Borrowed",
              description:
                "/Lend/BorrowPage/ExpiredBorrowRecord/Info List Item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          {props.record.borrowed.map((c, idx) => (
            <IconTokenCount key={idx} token={c.token} count={c.count} />
          ))}
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Claimable Amount {icon}",
              description:
                "/Lend/BorrowPage/ExpiredBorrowRecord/Info List Item title",
            }),
            {
              icon: (
                <TipIcon
                  inline
                  tip={$t(
                    defineMessage({
                      defaultMessage:
                        "The estimated value displays the token amount you will receive when you claim your collateral",
                      description:
                        "/Lend/BorrowPage/ExpiredBorrowRecord/Info List Item title icon tip",
                    }),
                  )}
                />
              ),
            },
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          {props.record.estimateClaimable.map((c, idx) => (
            <IconTokenCount key={idx} token={c.token} count={c.count} />
          ))}
          <EstimateValue
            intrinsicValue={props.record.estimateClaimableIntrinsicValue}
          />
        </InfoListItemDetail>
      </InfoListItem>
      <FinalSection className={"gap-5"}>
        <Button
          {...btnPresets.smallMerge({ className: "min-w-[150px]" })}
          onClick={props.record.onClaim}
        >
          {$t(
            defineMessage({
              defaultMessage: "Claim",
              description: "/Lend/BorrowPage/ExpiredBorrowRecord/Button text",
            }),
          )}
        </Button>
        <GradientFilledButton
          {...btnPresets.smallMerge({ className: "min-w-[150px]" })}
          onClick={props.record.onRollover}
          disabled={true}
        >
          {$t(
            defineMessage({
              defaultMessage: "Rollover {icon}",
              description: "/Lend/BorrowPage/ExpiredBorrowRecord/Button text",
            }),
            {
              icon: (
                <TipIcon
                  inline
                  tip={$t(
                    defineMessage({
                      defaultMessage:
                        "Rollover extends your borrow period through the next reward cycle.",
                      description:
                        "/Lend/BorrowPage/ExpiredBorrowRecord/Button text icon tip",
                    }),
                  )}
                />
              ),
            },
          )}
        </GradientFilledButton>
      </FinalSection>
    </RecordRowInfoListContainer>
  )
}

const RecordRowInfoListContainer: FCC = props => (
  <InfoList
    className={"flex-1"}
    direction={"row"}
    listItemDirection={"column"}
    listItemClassName={"flex-1 flex flex-col gap-1"}
    InfoListItemDetail={createInfoListItemDetail({
      className: "flex flex-col gap-1",
    })}
  >
    {props.children}
  </InfoList>
)

const EstimateValue: FC<{ intrinsicValue: TokenCountPair }> = props => (
  <span className={"text-xs text-gray-500"}>
    ≈{" "}
    <Spensor fallback={"-"}>
      {() => (
        <>
          <TokenCount
            token={props.intrinsicValue.token}
            count={readResource(props.intrinsicValue.count)}
          />{" "}
          <TokenName token={props.intrinsicValue.token} />
        </>
      )}
    </Spensor>
  </span>
)

const FinalSection: FCC<{ className?: string }> = props => (
  <div
    className={clsx(
      "flex-1 flex justify-end h-[fit-content] self-center",
      "mr-6",
      props.className,
    )}
  >
    <div className={"flex flex-col gap-2.5 items-center w-[fit-content]"}>
      {props.children}
    </div>
  </div>
)
