import {
  add,
  differenceInDays,
  Duration,
  formatDuration as _formatDuration,
  intervalToDuration,
} from "date-fns"
import { omit, pick } from "lodash"

export type FormatOptions = Parameters<typeof _formatDuration>[1]

export type FormatFunc = (
  duration: Duration,
  options?: FormatOptions & { includes?: (keyof Duration)[] },
) => string

/**
 * convert `duration` to `xxx days xxx hours xxx minutes xxx seconds`
 */
export const longFormatDuration: FormatFunc = (duration, options) => {
  if (options?.includes != null) {
    duration = pick(duration, options.includes)
  }

  if (
    duration.years != null ||
    duration.months != null ||
    duration.weeks != null
  ) {
    const nowDate = new Date()
    const futureDate = add(nowDate, duration)
    const durationDayCount = differenceInDays(futureDate, nowDate)

    duration = omit(duration, ["years", "months", "weeks"])
    duration.days = durationDayCount
  }

  if (duration.seconds != null) {
    duration = omit(duration, ["seconds"])
  }

  return _formatDuration(duration, options)
}

/**
 * convert `duration` to `xxx d xxx h xxx m xxx s`
 */
export const shortFormatDuration: FormatFunc = (duration, options) => {
  const res = longFormatDuration(duration, options)

  return res
    .replace("days", "d")
    .replace("day", "d")
    .replace("hours", "h")
    .replace("hour", "h")
    .replace("minutes", "m")
    .replace("minute", "m")
    .toUpperCase()
}

export const subDuration = (from: Date, to: Date): undefined | Duration => {
  if (from.getTime() >= to.getTime()) return
  return intervalToDuration({ start: from, end: to })
}
