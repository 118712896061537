import { action } from "mobx"
import React, { FC } from "react"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { WiredTransactionStateDialog } from "../../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { Currency } from "../../../utils/alexjs/Currency"
import { Result } from "../../../utils/Result"
import { suspenseResource } from "../../../utils/SuspenseResource"
import { BuyLotteryModalContent } from "../component/BuyLotteryModalContent/BuyLotteryModalContent"
import { ConfirmBuyLotteryModalContent } from "../component/ConfirmBuyLotteryModalContent/ConfirmBuyLotteryModalContent"
import { useLotteryPageStore } from "../store/useLotteryPageStore"

export const WiredBuyLotteryModal: FC = () => {
  const lottery = useLotteryPageStore()
  const currency = useCurrencyStore()
  const account = useAccountStore()
  const alex = currency.getTokenInfo$(Currency.ALEX)
  const buy = lottery.buy
  const apower = currency.getTokenInfo$(Currency.APOWER)
  return (
    <>
      <Modal
        visible={buy.showModal && !buy.showConfirmation}
        onClose={action(() => (buy.showModal = false))}
      >
        <BuyLotteryModalContent
          lotteryTicketCount={buy.ticketAmount.get()}
          onLotteryTicketCountChange={a => buy.ticketAmount.set(a ?? undefined)}
          priceToken={alex}
          priceTokenCount={suspenseResource(() => buy.amountInAlex$)}
          priceTokenBalance={suspenseResource(() =>
            account.getBalance$(Currency.ALEX),
          )}
          lotteryTicketUnitPrice={suspenseResource(() => buy.alexPerTicket$)}
          additionalLotteryTicketPriceToken={apower}
          additionalLotteryTicketRedeemableThreshold={suspenseResource(
            () => buy.apowerUnblockAt$,
          )}
          additionalLotteryTicket={
            buy.apowerUnlocked$
              ? suspenseResource(() => ({
                  lotteryTicketCount: buy.apowerTicketAmount.read$,
                  lotteryTicketUnitPrice: buy.apowerPerTicket$,
                  onLotteryTicketCountChange: a =>
                    buy.apowerTicketAmount.set(a ?? undefined),
                  maximumRedeemableLotteryTicketCount:
                    buy.maxEligibleApowerTicketCount$,
                  onPressMaxLotteryTicketButton: suspenseResource(() => {
                    const max = Math.min(
                      buy.maxTicketToPurchaseWithApower$,
                      buy.maxEligibleApowerTicketCount$,
                    )
                    return () => buy.apowerTicketAmount.set(max)
                  }),
                  priceTokenCount: buy.apowerAmount$,
                  priceTokenBalance: buy.apowerBalance$,
                }))
              : undefined
          }
          onClose={action(() => (buy.showModal = false))}
          formError={suspenseResource(() => Result.maybeError(buy.formData$))}
          onPressMaxLotteryTicketButton={suspenseResource(() => {
            const max = buy.maxTicketToPurchaseWithAlex$
            return () => buy.ticketAmount.set(max)
          })}
          onSubmit={action(() => (buy.showConfirmation = true))}
        />
      </Modal>
      <Spensor>
        {() => (
          <Modal
            visible={lottery.buy.showConfirmation}
            onClose={action(() => (buy.showConfirmation = false))}
          >
            <ConfirmBuyLotteryModalContent
              lotteryTicketCount={
                buy.ticketAmount.read$ +
                (buy.apowerUnlocked$ ? buy.apowerTicketAmount.read$ : 0)
              }
              prices={[
                {
                  token: alex,
                  count: buy.amountInAlex$,
                },
                {
                  count: buy.apowerUnlocked$ ? buy.apowerAmount$ : 0,
                  token: apower,
                },
              ]}
              onCancel={action(() => (buy.showConfirmation = false))}
              onConfirm={() => buy.register()}
            />
          </Modal>
        )}
      </Spensor>
      <WiredTransactionStateDialog store={buy.txStore} />
    </>
  )
}
