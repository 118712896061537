import clsx from "clsx"
import { FCC } from "../utils/reactHelpers/types"
import { withClassName } from "../utils/reactHelpers/withClassName"

export const Badge: FCC<{
  className?: string
  boxClassName?: string
}> = props => (
  <span
    className={clsx(
      "rounded-full text-xs leading-4 font-medium inline-flex items-center",
      props.className,
      props.boxClassName ?? "px-3 py-1",
    )}
  >
    {props.children}
  </span>
)

export const createBadge =
  (classNames: string): typeof Badge =>
  props =>
    <Badge {...props} className={clsx(classNames, props.className)} />

export const GrayBadge = createBadge("bg-gray-500 text-gray-200")
export const BlueBadge = createBadge("bg-blue-300 text-blue-700")
export const GreenBadge = createBadge("bg-green-300 text-green-700")
export const YellowBadge = createBadge("bg-amber-200 text-yellow-600")
export const RedBadge = createBadge("bg-red-300 text-red-700")
export const GoldenBadge = createBadge("bg-yellow-300 text-gray-900")

export const UpcomingBadge = withClassName("uppercase", GrayBadge)
export const ProgressingBadge = withClassName("uppercase", GreenBadge)
export const FinishedBadge = withClassName("uppercase", BlueBadge)
export const FailedBadge = withClassName("uppercase", RedBadge)
