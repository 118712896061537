import { computed, makeObservable, observable } from "mobx"
import { ConfirmTransactionStore } from "../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { SlippageStore } from "../../../stores/slippageStore/SlippageStore"
import { SuspenseObservable } from "../../../stores/SuspenseObservable"
import { ValidationStore } from "../../../stores/ValidationStore"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { Result } from "../../../utils/Result"
import {
  FormError,
  FormErrorType,
} from "../components/depositPage/AddDepositPanel/types"
import LendStore from "./LendStore"
import type { AddDepositFormData } from "./LendStore.service"
import {
  addDeposit,
  getYieldTokenPerUnderlyingToken,
} from "./LendStore.service"

export class AddDepositModule {
  constructor(readonly store: LendStore) {
    makeObservable(this)
  }

  amount = new SuspenseObservable<number>()
  slippageStore = new SlippageStore()

  @computed get balance$(): number {
    return this.store.accountStore.getBalance$(this.store.underlyingToken)
  }

  @computed get underlyingTokenPrice$(): number {
    return this.store.currencyStore.getPrice$(this.store.underlyingToken)
  }

  private _yieldTokenPerUnderlyingToken = new LazyValue(
    () => [this.store.poolToken, this.underlyingTokenPrice$] as const,
    async ([poolToken, uTokenPrice]) =>
      getYieldTokenPerUnderlyingToken(poolToken, 100 / uTokenPrice),
  )

  @computed get yieldTokenPerUnderlyingToken$(): number {
    if (this._yieldTokenPerUnderlyingToken.value$.type === "error") {
      throw new Promise(() => null)
    }
    return this._yieldTokenPerUnderlyingToken.value$.payload
  }

  @computed get estimateAPR$(): number {
    // const priceOfYieldToken = 1 / this.yieldTokenPerUnderlyingToken$
    // return (1 / priceOfYieldToken - 1) * PRODUCTION_ANNUAL_FACTOR
    return this.store.depositAPR$
  }

  @computed get estimateAbsoluteInterest$(): number {
    return this.amount.read$ * (this.yieldTokenPerUnderlyingToken$ - 1)
  }

  @computed get yieldTokenAmount$(): number {
    return this.amount.read$ * this.yieldTokenPerUnderlyingToken$
  }

  @computed get liquidityProviderFee$(): number {
    return this.store.ytpLiquidityFeeCalc("underlying", this.amount.read$)
  }

  @observable showConfirmation = false

  txStore = new ConfirmTransactionStore()

  validation = new ValidationStore(
    (
      input: AddDepositFormData,
    ): Promise<Result<number, "ERR-DY-BIGGER-THAN-AVAILABLE">> => {
      return getYieldTokenPerUnderlyingToken(input.poolToken, input.amount)
    },
  )

  @asyncAction async startValidating(run = runAsyncAction): Promise<void> {
    if (this.formData$.type === "error") {
      return
    }
    const result = await run(this.validation.validate(this.formData$.payload))
    if (result.type === "ok") {
      this.showConfirmation = true
    }
  }

  @computed get formData$(): Result<AddDepositFormData, FormError> {
    if (!this.store.authStore.isWalletConnected) {
      return Result.error({
        type: FormErrorType.WalletNotConnected,
        message: "Connect Wallet",
      })
    }
    if (this.amount.get() == null || this.amount.get() === 0) {
      return Result.error({
        type: FormErrorType.AmountIsEmpty,
        message: "Enter Amount",
      })
    }
    if (!this.store.currentExpiryExist$) {
      return Result.error({
        type: FormErrorType.PoolNotCreatedYet,
        message: "Pool not created yet",
      })
    }
    if (this.validation.read$?.type === "error") {
      return Result.error({
        type: FormErrorType.NotEnoughDeposit,
        message: "Exceeds max amount",
      })
    }
    if (this._yieldTokenPerUnderlyingToken.value$.type === "error") {
      return Result.error({
        type: FormErrorType.NotEnoughDeposit,
        message: "Deposit not Possible",
      })
    }
    if (this.amount.read$ > this.balance$) {
      return Result.error({
        type: FormErrorType.InsufficientTokenBalance,
        message: "Insufficient Balance",
      })
    }
    return Result.ok({
      stxAddress: this.store.authStore.stxAddress$,
      amount: this.amount.read$,
      yieldTokenPerUnderlyingToken: this.yieldTokenPerUnderlyingToken$,
      slippage: this.slippageStore.slippagePercentage,
      expiry: this.store.currentExpiry$,
      poolToken: this.store.poolToken,
    })
  }

  @asyncAction async addDeposit(
    data: AddDepositFormData,
    run = runAsyncAction,
  ): Promise<void> {
    try {
      this.showConfirmation = false
      const { txId } = await run(addDeposit(data))
      this.txStore.successRunning(txId)
    } catch (e) {
      this.txStore.errorRunning(e as Error)
    }
  }
}
