import { ReactNode } from "react"
import { RenderChildrenFn } from "../components/Spensor"

export function customReactTableRender(
  cell: { _cellBrandingTypeForMobx: true },
  type: "Header" | "Footer" | string,
  props?: object,
): ReactNode {
  return (
    <RenderChildrenFn>
      {() => (cell as any).render(type, props)}
    </RenderChildrenFn>
  )
}
