import {
  AddressTransactionWithTransfers,
  ContractCallTransaction,
} from "@stacks/stacks-blockchain-api-types/generated"
import { decodeSpecifiedContractCallTransaction } from "clarity-codegen"
import { Observable, liveQuery } from "dexie"
import { throttle } from "lodash"
import { CONTRACT_DEPLOYER } from "../../config"
import { AlexContracts } from "../../generated/smartContract/contracts_Alex"
import {
  ContractName,
  Contracts,
} from "../../generated/smartContractHelpers/asSender"
import pMemoize, { skipCaching } from "../../utils/pMemorize"
import { utilWindowFocus } from "../../utils/utilWindowFocus"
import { isNotNull } from "../../utils/utils"
import {
  fetchMemPoolTransactions,
  fetchNewContractCallTransactionsWithTransfers,
} from "./AccountTransactions.services"
import { TransactionDatabase } from "./TransactionDatabase"
import { CONTRACT_CALL_INDEX } from "./constants"
import { DecodedTransaction } from "./types"

class AccountTransactions {
  private readonly db: TransactionDatabase
  readonly table: TransactionDatabase["transactions"]

  constructor(readonly stxAddress: string) {
    this.db = new TransactionDatabase(stxAddress)
    this.table = this.db.transactions
    void this.sync()
  }

  sync = throttle(
    pMemoize(async () => {
      console.log(`start syncing transactions for ${this.stxAddress}`)
      const existing = await this.table.count()
      try {
        for await (const transactions of fetchNewContractCallTransactionsWithTransfers(
          this.stxAddress,
          existing,
        )) {
          await utilWindowFocus()
          await this.table.bulkPut(transactions)
          console.log(
            `synced ${transactions.length} transactions for ${this.stxAddress}`,
          )
        }
      } catch (e) {
        console.error(e)
      }
      // we want to prevent multiple syncs at the same time,
      // but we don't want to cache the result in persist
      return skipCaching
    }),
    5000,
  )

  liveQuery<T>(
    action: (table: TransactionDatabase["transactions"]) => T | Promise<T>,
  ): Observable<T> {
    return liveQuery(() => action(this.table))
  }

  async clear(): Promise<void> {
    await this.table.clear()
  }

  async fetchWithNonce(
    nonce: number,
  ): Promise<AddressTransactionWithTransfers | undefined> {
    return this.table.where("nonce").equals(nonce).first()
  }

  async contractCallTransactions<T extends ContractName>(
    contract: T,
    func: keyof Contracts[T],
  ): Promise<ContractCallTransaction[]> {
    return this.table
      .where(CONTRACT_CALL_INDEX)
      .equals([`${CONTRACT_DEPLOYER}.${contract}`, String(func)])
      .toArray()
      .then(txs =>
        (txs as { tx: ContractCallTransaction }[])
          .map(t => t.tx)
          .filter(t => t.tx_status === "success"),
      )
  }

  async decodedContractCallTransactions<
    T extends ContractName,
    F extends keyof Contracts[T],
  >(contract: T, func: F): Promise<DecodedTransaction<T, F>[]> {
    return this.contractCallTransactions(contract, func).then(txs =>
      txs
        .map(
          (tx): DecodedTransaction<T, F> =>
            decodeSpecifiedContractCallTransaction(
              AlexContracts,
              contract,
              func,
              tx,
            ),
        )
        .filter(isNotNull),
    )
  }

  fetchMemPoolTransactions = pMemoize(
    () => fetchMemPoolTransactions(this.stxAddress),
    { skipCaching: true },
  )
}

export default AccountTransactions
