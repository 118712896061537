import * as RadixTooltip from "@radix-ui/react-tooltip"
import clsx from "clsx"
import { ReactNode, useRef, useState } from "react"
import { isTouchDevice } from "../../utils/domHelpers/browserEnv"
import { FCC } from "../../utils/reactHelpers/types"
import { useOnClickOutside } from "../../utils/reactHelpers/useOnClickOutside"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"

export interface TooltipProps {
  className?: string

  titleClassName?: string
  titleMaxWidth?: number
  title: ReactNode

  visible?: boolean
  mouseEnterDelay?: number
}

export const Tooltip: FCC<TooltipProps> = props => {
  const [hoverVisible, setHoverVisible] = useState(false)
  const [clickVisible, setClickVisible] = useState(false)
  const visible =
    props.visible ?? (isTouchDevice() ? clickVisible : hoverVisible)

  const btnRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside(btnRef, () => {
    setClickVisible(false)
  })
  const onClickBtn = usePersistFn(() => {
    setClickVisible(true)
  })

  return (
    <RadixTooltip.Root
      open={visible}
      onOpenChange={setHoverVisible}
      delayDuration={props.mouseEnterDelay ?? 0}
    >
      <RadixTooltip.Trigger
        className={props.className}
        asChild={true}
        ref={btnRef}
        onClick={onClickBtn}
      >
        <div>{props.children}</div>
      </RadixTooltip.Trigger>

      {visible && (
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={clsx(
              "drop-shadow-2xl",
              /**
               * Add `z-1` here is for the `Tooltip.Portal`, we need it has
               * style `z-index: 1`.
               *
               * The Portal is using `@radix-ui/react-popper` to put the
               * `Tooltip.Content` in a right place, and the `z-index` of the
               * popper is read and decided from Content
               */
              "z-1",
            )}
            sideOffset={5}
          >
            <div
              className={clsx(
                "bg-black/75 px-2 py-1 rounded text-white whitespace-pre-line text-xs",
                props.titleClassName,
              )}
              style={{ maxWidth: props.titleMaxWidth ?? 300 }}
            >
              {props.title}
            </div>

            <RadixTooltip.Arrow className={clsx("text-black/75")} offset={5} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      )}
    </RadixTooltip.Root>
  )
}
