import { TokenInfo } from "../../../utils/models/TokenInfo"
import { SuspenseResource } from "../../../utils/SuspenseResource"

export type WrapBridgeNetwork = {
  id: string
  icon: string
  name: string
}

export type WrapBridgeSegment = {
  network: WrapBridgeNetwork
  estimatedAmount: number
  amount?: number
  token: TokenInfo
  address: string
  addressExploreLink?: string
}

export type WrapBridgeSegmentStatus =
  | {
      type: "warning"
      message: string
      explorerLink?: string
    }
  | {
      type: "inProgress"
      message: string
      explorerLink?: string
    }
  | {
      type: "success"
      explorerLink?: string
    }

export interface WrapBridgeSegmentStatusCollection {
  source: WrapBridgeSegmentStatus
  wrapping?: WrapBridgeSegmentStatus
  destination?: WrapBridgeSegmentStatus
}

export interface WrapBridgeHistoryRecord {
  orderNumber: string
  createdAt: Date
  source: SuspenseResource<WrapBridgeSegment & { waitBlocks?: number }>
  target: SuspenseResource<undefined | WrapBridgeSegment>
  fee: SuspenseResource<number>
  feeToken: SuspenseResource<TokenInfo>
  status: SuspenseResource<WrapBridgeSegmentStatusCollection>
}

export enum FormErrorType {
  SourceTokenAmountIsEmpty = "SourceTokenAmountIsEmpty",
  SourceTokenInsufficientBalance = "SourceTokenInsufficientBalance",
  SourceTokenInsufficientAllowance = "SourceTokenInsufficientAllowance",

  SourceWalletNotConnected = "SourceWalletNotConnected",
  TargetWalletNotConnected = "TargetWalletNotConnected",

  UnsupportedSourceToken = "UnsupportedSourceToken",
  UnsupportedTargetToken = "UnsupportedTargetToken",

  UnsupportedSourceChain = "UnsupportedSourceChain",
  UnsupportedTargetChain = "UnsupportedTargetChain",

  SourceTokenAmountTooSmall = "SourceTokenAmountTooSmall",
  SourceTokenAmountTooLarge = "SourceTokenAmountTooLarge",
}

export type FormError =
  | {
      type: Exclude<
        FormErrorType,
        | FormErrorType.SourceTokenAmountTooSmall
        | FormErrorType.SourceTokenAmountTooLarge
        | FormErrorType.SourceTokenInsufficientBalance
        | FormErrorType.SourceTokenInsufficientAllowance
      >
    }
  | {
      type: FormErrorType.SourceTokenInsufficientBalance
      token: TokenInfo
    }
  | {
      type: FormErrorType.SourceTokenInsufficientAllowance
      token: TokenInfo
      currAllowance: number
      minAllowance: number
    }
  | {
      type: FormErrorType.SourceTokenAmountTooSmall
      token: TokenInfo
      min: number
    }
  | {
      type: FormErrorType.SourceTokenAmountTooLarge
      token: TokenInfo
      max: number
    }
