import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardDivider } from "../../../../../components/Card"
import { TokenName } from "../../../../../components/TokenName"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LinkedButton } from "../../../../../components/button/LinkedButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { InCardIDOStepsContainer } from "../../IDOSteps"
import { BottomActionsSection } from "./utilComponents/BottomActionsSection"
import { IDODetailSidePanelFrame } from "./utilComponents/IDODetailSidePanelFrame"
import { IDOProgramDescribe } from "./utilComponents/IDOProgramDescribe"
import { TokenIconAndNumbersSection } from "./utilComponents/TokenIconAndNumbersSection"

export interface IDODetailSidePanel$UpcomingProps {
  className?: string
  idoToken: TokenInfo
  priceToken: TokenInfo
  maxRaiseIdoTokenCount: number
  maxRaiseIdoTokenPrice?: number
  idoSteps: ReactNode
  titleRightSide?: ReactNode
  onLearnMoreAPowerRule?: () => void
  dashboardLink?: null | string
  prepareAPowerLink?: null | string
}

export const IDODetailSidePanel$Upcoming: FC<
  IDODetailSidePanel$UpcomingProps
> = props => {
  const { $t } = useIntl()
  return (
    <IDODetailSidePanelFrame
      className={props.className}
      titleRightSide={props.titleRightSide}
    >
      <TokenIconAndNumbersSection
        idoToken={props.idoToken}
        priceToken={props.priceToken}
        maxRaiseIdoTokenCount={props.maxRaiseIdoTokenCount}
        maxRaiseIdoTokenPrice={props.maxRaiseIdoTokenPrice}
      />

      <IDOProgramDescribe />

      <InCardIDOStepsContainer>{props.idoSteps}</InCardIDOStepsContainer>

      <CardDivider />

      <p className={"text-sm leading-5 font-normal text-gray-400"}>
        {$t(
          defineMessage({
            defaultMessage:
              "Lottery ticket validation requires {priceToken} as well as {aPower}. The amount  of APower required, is dependent on the number of tickets validated. ",
            description: "/Launchpad/Ido detail side panel upcoming",
          }),
          {
            priceToken: <TokenName token={props.priceToken} />,
            aPower: <TokenName token={TokenInfoPresets.APower} />,
          },
        )}
        <HeadlessButton
          className={"inline text-blue-600"}
          onClick={props.onLearnMoreAPowerRule}
        >
          {$t(
            defineMessage({
              defaultMessage: "Learn more",
              description: "Launchpad/IDODetailSidePanel/Upcoming button",
            }),
          )}
        </HeadlessButton>
      </p>

      <BottomActionsSection>
        {props.dashboardLink !== null && (
          <LinkedButton
            Variant={GradientFilledButton}
            link={props.dashboardLink}
          >
            {$t(
              defineMessage({
                defaultMessage: "LaunchPad Dashboard",
                description:
                  "/Launchpad/IDODetailSidePanel/Upcoming link button",
              }),
            )}
          </LinkedButton>
        )}
        {props.prepareAPowerLink !== null && (
          <LinkedButton
            Variant={WhiteFilledButton}
            link={props.prepareAPowerLink}
          >
            {$t(
              defineMessage({
                defaultMessage: "Prepare {aPowerToken}",
                description:
                  "/Launchpad/IDODetailSidePanel/Upcoming link button",
              }),
              { aPowerToken: <TokenName token={TokenInfoPresets.APower} /> },
            )}
          </LinkedButton>
        )}
      </BottomActionsSection>
    </IDODetailSidePanelFrame>
  )
}
