import { FC } from "react"
import { TO_PREFIX } from "../../constants"
import { AllTokenInfos, CurrencyAmount } from "../../types"
import { OneLineTokenAmount } from "./OneLineTokenAmount"

export interface OneLineAmountProps {
  fromCurrencyAmounts?: CurrencyAmount[]
  toCurrencyAmounts?: CurrencyAmount[]
  fromPrefix?: string
  fromSuffix?: string
  toPrefix?: string
  toSuffix?: string
  allTokenInfos: AllTokenInfos
}

export const OneLineAmount: FC<OneLineAmountProps> = ({
  fromCurrencyAmounts = [],
  toCurrencyAmounts = [],
  allTokenInfos,
  fromPrefix,
  fromSuffix,
  toPrefix,
  toSuffix,
}) => (
  <p className="flex flex-col whitespace-nowrap">
    {fromCurrencyAmounts.map(
      currencyAmount =>
        currencyAmount && (
          <OneLineTokenAmount
            currencyAmount={currencyAmount}
            allTokenInfos={allTokenInfos}
            prefix={fromPrefix}
            suffix={fromSuffix}
          />
        ),
    )}
    {toCurrencyAmounts.map(
      currencyAmount =>
        currencyAmount && (
          <OneLineTokenAmount
            prefix={toPrefix ?? TO_PREFIX}
            suffix={toSuffix}
            currencyAmount={currencyAmount}
            allTokenInfos={allTokenInfos}
          />
        ),
    )}
  </p>
)
