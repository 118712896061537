import { FC, useState } from "react"
import { useConnect } from "../../../../../components/ConnectWallet/ConnectProvider"
import { Modal } from "../../../../../components/Modal"
import { Spensor } from "../../../../../components/Spensor"
import { useAuthStore } from "../../../../../stores/authStore/useAuthStore"
import { LaunchingStatus } from "../../../store/LaunchPadContractStore"
import { useLaunchPadContractStore } from "../../../store/useLaunchPadStores"
import { InCardIDOStepsContainer } from "../../IDOSteps"
import { StepEndCountdown } from "../../StepEndCountdown"
import { ValidateAPowerRuleModalContent } from "../../ValidateAPowerRuleModalContent/ValidateAPowerRuleModalContent"
import { WiredIDOSteps } from "../../WiredIDOSteps"
import { WiredRedeemOrdinalsModalContent } from "../../WiredRedeemOrdinalsModalContent"
import { ActionSectionContent$ConnectWallet } from "../_/ActionSectionContent$ConnectWallet"
import { ActionSectionContent$Loading } from "../_/ActionSectionContent$Loading"
import { ActionSectionFrame } from "../_/ActionSectionFrame"
import { WiredActionSectionContent } from "./_/WiredActionSectionContent"

export const WiredActionSection: FC<{ className?: string }> = props => {
  const authStore = useAuthStore()
  const store = useLaunchPadContractStore()

  const [connect] = useConnect()

  const [showValidateAPowerRuleModal, setShowValidateAPowerRuleModal] =
    useState(false)

  return (
    <ActionSectionFrame
      className={props.className}
      titleRightSide={
        <Spensor fallback={null}>
          {() =>
            store.steps.currentStatusEndedAt$ &&
            store.steps.currentStatusEndedAtBlock$ != null &&
            store.steps.nextStatus$ !== LaunchingStatus.Finished && (
              <StepEndCountdown
                currentStepNumber={store.steps.currentStepNumber$}
                endBlock={store.steps.currentStatusEndedAtBlock$}
                currentBlock={store.currentBlock$}
                currentStepEndedAt={store.steps.currentStatusEndedAt$}
              />
            )
          }
        </Spensor>
      }
    >
      <Spensor
        fallback={
          authStore.isWalletConnected ? (
            <ActionSectionContent$Loading />
          ) : (
            <Spensor fallback={<ActionSectionContent$Loading />}>
              {() => <ActionSectionContent$ConnectWallet onConnect={connect} />}
            </Spensor>
          )
        }
      >
        {() => (
          <>
            <InCardIDOStepsContainer>
              <WiredIDOSteps />
            </InCardIDOStepsContainer>
            <WiredActionSectionContent
              onLearnMoreAPowerRule={() => setShowValidateAPowerRuleModal(true)}
            />
          </>
        )}
      </Spensor>

      <Modal
        visible={showValidateAPowerRuleModal}
        onClose={() => setShowValidateAPowerRuleModal(false)}
      >
        <Spensor>
          {() => (
            <ValidateAPowerRuleModalContent
              ranges={store.apowerRanges$}
              maxTickets={store.register.maxAllowedTicket$}
              onConfirm={() => setShowValidateAPowerRuleModal(false)}
            />
          )}
        </Spensor>
      </Modal>
      <WiredRedeemOrdinalsModalContent />
    </ActionSectionFrame>
  )
}
