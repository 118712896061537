import { FC } from "react"
import { Card } from "../../../../components/Card"
import PlaceholderSrc from "./Tradeview_placeholder.png"

export const ComingSoonChartPlaceholder: FC<{
  className?: string
}> = props => {
  return (
    <Card
      className={props.className}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        backgroundImage: `url(${PlaceholderSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "748px 600px",
      }}
    />
  )
}
