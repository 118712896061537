import { FarmHarvestRowData } from "../../store/transformerGroups/farm/farmHarvest"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const farmHarvestExporter: Exporter<FarmHarvestRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.principalCurrencyAmount],
    toCurrencyAmounts: rowData.rewards,
    allTokenInfos,
  })
  const tradeInfo = `Reward Harvest`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
