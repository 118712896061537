import clsx from "clsx"
import { startCase } from "lodash"
import { FC, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { NavLink } from "../../../components/NavLink"
import { NotificationCount } from "../../../components/TopNavBar/NotificationButton/NotificationCount"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { TRANSACTION_FILTERS } from "../constants"
import { NotifyFilter } from "../types"
import { CanScrollMask } from "./CanScrollMask/CanScrollMask"

export interface NotifyTabbarProps {
  active: NotifyFilter
  className?: string
  systemMessagesCount?: number
}

export const NotifyTabbar: FC<NotifyTabbarProps> = props => (
  <CanScrollMask className={clsx(props.className, "flex gap-4")}>
    <TabContainer>
      {TRANSACTION_FILTERS.map(filterName => (
        <TabItem key={filterName} active={props.active} name={filterName} />
      ))}
    </TabContainer>
    <TabContainer>
      <TabItem
        active={props.active}
        name="systemMessages"
        append={<NotificationCount count={props.systemMessagesCount} />}
        className="inline-flex gap-x-2.5"
      />
    </TabContainer>
  </CanScrollMask>
)

const TabContainer = withClassName(
  "p-[5px] bg-black/[0.15] flex rounded-full",
  "div",
)

const TabItem: FC<{
  active: string
  name: string
  pageIndex?: number
  append?: React.ReactNode
  className?: string
}> = props => {
  const [searchParams] = useSearchParams()
  const nextSearchParams = useMemo(() => {
    return new URLSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      filter: props.name,
      page: ((props.pageIndex ?? 0) + 1).toString(),
    })
  }, [props.name, props.pageIndex, searchParams])
  return (
    <NavLink
      className={clsx(
        props.active === props.name && "bg-white/20",
        props.className,
        "py-1 px-8 rounded-full cursor-pointer",
        "text-xs leading-4 whitespace-nowrap",
      )}
      to={`?${nextSearchParams.toString()}`}
    >
      {startCase(props.name)}
      {props.append}
    </NavLink>
  )
}
