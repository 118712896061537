import clsx from "clsx"
import { ScopedLoadingBoundary } from "../../../../components/LoadingBoundary/ScopedLoadingBoundary"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { TabBar } from "../../../../components/TabBar"
import { FCC } from "../../../../utils/reactHelpers/types"
import { OrderListOrderType } from "./types"

export const gapSpacing = 2

export const OrderbookListFrameTabbar: FCC<{
  orderType: OrderListOrderType
  onChangeOrderType: (newType: OrderListOrderType) => void
}> = props => (
  <TabBar
    tabClassName={"px-4 pb-3 text-sm leading-5 font-semibold"}
    tabs={[
      { label: "Order Book", value: OrderListOrderType.OrderbookOrders },
      { label: "Trades", value: OrderListOrderType.TradeOrders },
      { label: "My Trades", value: OrderListOrderType.MyTradeOrders },
    ]}
    selectedTab={{
      tabValue: props.orderType,
      onChange: tab => props.onChangeOrderType?.(tab.value),
    }}
  />
)

export interface OrderbookListProps {
  className?: string
  orderType: OrderListOrderType
  onChangeOrderType: (newType: OrderListOrderType) => void
}

export const OrderbookListFrame: FCC<OrderbookListProps> = props => {
  const contentsLayoutClassName = "flex flex-col"

  return (
    <div
      className={clsx(
        "p-3 bg-black/30 rounded-lg text-xs",
        contentsLayoutClassName,
        props.className,
      )}
    >
      <OrderbookListFrameTabbar
        orderType={props.orderType}
        onChangeOrderType={props.onChangeOrderType}
      />

      <ScopedLoadingBoundary
        className={"flex-1"}
        contentContainerClassName={contentsLayoutClassName}
        loadingIndicator={<LoadingIndicator className={"m-auto"} />}
      >
        {props.children}
      </ScopedLoadingBoundary>
    </div>
  )
}
