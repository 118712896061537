import { noop } from "lodash"
import { action, computed, makeObservable, observable, when } from "mobx"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { Result } from "../../../../utils/Result"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { roundNumber } from "../../../../utils/numberHelpers"
import { assertNever } from "../../../../utils/types"
import type { OrderDirection } from "../../components/types"
import {
  StxDxOrderType,
  TradingFormError,
  TradingFormErrorType,
  TradingFormWarningType,
} from "../../components/types"
import { OrderbookStore } from "../OrderbookStore"
import type {
  OrderbookAsset,
  TradeFormData,
} from "../OrderbookStore.service/OrderbookStore.service"
import { tradeInStxDx } from "../OrderbookStore.service/OrderbookStore.service"

export class OrderbookTradeModule {
  constructor(readonly store: OrderbookStore, side: OrderDirection) {
    makeObservable(this)

    this.side = side

    when(
      // once current order tick available
      () => this.store.currentMarketSummary$ != null,
      // set customPrice to current tick price
      () => {
        const tick = this.store.currentMarketSummary$
        this.customPrice.set(this.side === "buy" ? tick.ask : tick.bid)
      },
      {
        onError: noop,
      },
    )
  }

  @observable side: OrderDirection = "buy"
  @observable orderType: StxDxOrderType = StxDxOrderType.Limit

  @action setOrderType(type: StxDxOrderType): void {
    this.orderType = type
  }

  @action setSide(side: OrderDirection): void {
    this.side = side
    this.emptyTradeAmount()
  }

  @computed get tradeToken$(): OrderbookAsset {
    return this.store.market.read$.tradeToken
  }

  @computed get priceToken$(): OrderbookAsset {
    return this.store.market.read$.priceToken
  }

  @computed get tradeTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(this.tradeToken$)
  }

  @computed get priceTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(this.priceToken$)
  }

  @computed get incomingTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(
      this.side === "buy" ? this.tradeToken$ : this.priceToken$,
    )
  }

  @computed get outgoingTokenInfo$(): TokenInfo {
    return this.store.currency.getTokenInfo$(
      this.side === "buy" ? this.priceToken$ : this.tradeToken$,
    )
  }

  @computed get outgoingTokenBalance(): number {
    try {
      return this.side === "buy"
        ? this.priceTokenBalance$
        : this.tradeTokenBalance$
    } catch (err) {
      if (err instanceof Promise) {
        return 0
      } else {
        throw err
      }
    }
  }

  @computed get priceTokenBalance$(): number {
    return this.store.myInfo.stxDxBalance$(this.priceToken$)?.available ?? 0
  }

  @computed get tradeTokenBalance$(): number {
    return this.store.myInfo.stxDxBalance$(this.tradeToken$)?.available ?? 0
  }

  customPrice = new SuspenseObservable<number>()
  stopPrice = new SuspenseObservable<number>()

  @computed get priceTokenPrecision$(): number {
    return this.store.market.read$.pricePrecision
  }

  @computed get tradeTokenPrecision$(): number {
    return TokenInfo.getPrecision(
      this.store.currency.getTokenInfo$(this.tradeToken$),
    )
  }

  @action setCustomPrice(price?: number): void {
    this.customPrice.set(price)
  }

  private tradeAmount = new SuspenseObservable<number>()

  get tradeAmount$(): number {
    return this.tradeAmount.read$
  }
  getTradeAmount(): number | undefined {
    return this.tradeAmount.get()
  }
  setTradeAmount(amount: number): void {
    return this.tradeAmount.set(
      roundNumber(amount, {
        precision: this.tradeTokenPrecision$,
        rounder: this.side === "sell" ? Math.floor : Math.ceil,
      }),
    )
  }

  emptyTradeAmount(): void {
    this.tradeAmount.set(undefined)
  }

  @computed get perTradeTokenPrice$(): number {
    const custom = this.customPrice.get()
    if (
      (this.orderType === StxDxOrderType.Limit || StxDxOrderType.StopLimit) &&
      custom != null
    ) {
      return custom
    }
    const tick = this.store.currentMarketSummary$
    return (this.side === "buy" ? tick.ask : tick.bid) || tick.price
  }

  @computed get priceTokenCount$(): number {
    return roundNumber(this.tradeAmount.read$ * this.perTradeTokenPrice$, {
      precision: this.store.market.read$.pricePrecision,
      rounder: this.side === "sell" ? Math.ceil : Math.floor,
    })
  }

  @computed get feeRates$(): { maker: number; taker: number } {
    return {
      maker: this.store.info.senderFeeRate$,
      taker: this.store.info.senderFeeRate$,
    }
  }

  @computed get tradePercentage$(): number {
    if (this.tradeAmount.get() === undefined) {
      return 0
    }
    if (this.side === "buy") {
      return (
        ((this.priceTokenCount$ ?? 0) / this.priceTokenBalance$) *
        this.store.info.amountPlusSenderFeeRate$
      )
    } else if (this.side === "sell") {
      return (
        (this.tradeAmount.read$ / this.tradeTokenBalance$) *
        this.store.info.amountPlusSenderFeeRate$
      )
    } else {
      assertNever(this.side)
    }
  }

  @action setPercentage(percentage: number): void {
    if (percentage === 0) {
      return this.emptyTradeAmount()
    }
    if (this.side === "buy") {
      this.setTradeAmount(
        ((percentage * this.priceTokenBalance$) / this.perTradeTokenPrice$) *
          (1 / this.store.info.amountPlusSenderFeeRate$),
      )
    } else if (this.side === "sell") {
      this.setTradeAmount(
        percentage *
          this.tradeTokenBalance$ *
          (1 / this.store.info.amountPlusSenderFeeRate$),
      )
    } else {
      assertNever(this.side)
    }
  }

  @action setTotal(total?: number): void {
    if (total == null) {
      this.emptyTradeAmount()
    } else {
      this.setTradeAmount(total / this.perTradeTokenPrice$)
    }
  }

  @computed get formData$(): Result<TradeFormData, TradingFormError> {
    if (!this.store.authStore.isWalletConnected) {
      return Result.error({
        type: TradingFormErrorType.ConnectWalletRequired as const,
      })
    }

    if (this.tradeAmount.get() == null) {
      return Result.error({
        type: TradingFormErrorType.AmountIsEmpty as const,
      })
    }

    const MINIMUM_PRICE_TOKEN_COUNT = 0.1
    if (this.priceTokenCount$ < MINIMUM_PRICE_TOKEN_COUNT) {
      return Result.error({
        type: TradingFormErrorType.TotalPriceTooSmall as const,
        priceToken: this.store.currency.getTokenInfo$(this.priceToken$),
        minimumPriceTokenCount: MINIMUM_PRICE_TOKEN_COUNT,
      })
    }

    if (
      this.side === "buy" &&
      this.priceTokenCount$ > this.priceTokenBalance$
    ) {
      return Result.error({
        type: TradingFormErrorType.InsufficientPriceTokenBalance as const,
      })
    }

    if (
      this.side === "sell" &&
      this.tradeAmount.read$ > this.tradeTokenBalance$
    ) {
      return Result.error({
        type: TradingFormErrorType.InsufficientTradeTokenBalance as const,
      })
    }

    let warning: undefined | { type: TradingFormWarningType }

    if (
      [StxDxOrderType.Limit, StxDxOrderType.StopLimit].includes(
        this.orderType,
      ) &&
      this.side === "buy" &&
      this.perTradeTokenPrice$ &&
      this.store.orderbook.bestAskPrice$ &&
      this.perTradeTokenPrice$ > this.store.orderbook.bestAskPrice$
    ) {
      warning = { type: TradingFormWarningType.AbnormalBuyingPrice }
    }

    if (
      [StxDxOrderType.Limit, StxDxOrderType.StopLimit].includes(
        this.orderType,
      ) &&
      this.side === "sell" &&
      this.perTradeTokenPrice$ &&
      this.store.orderbook.bestBidPrice$ &&
      this.perTradeTokenPrice$ < this.store.orderbook.bestBidPrice$
    ) {
      warning = { type: TradingFormWarningType.AbnormalSellingPrice }
    }

    const data: TradeFormData = {
      warning: warning,
      outgoingToken: this.outgoingTokenInfo$,
      incomingToken: this.incomingTokenInfo$,
      stxAddress: this.store.authStore.stxAddress$,
      currentHeight: this.store.chainStore.currentBlockHeight$,
      currentUserId: this.store.myInfo.registeredUserId$,
      currentUserAuth: this.store.myInfo.authJWT$,
      size: this.tradeAmount.read$,
      price: this.perTradeTokenPrice$,
      side: this.side,
      currentPrice: this.store.currentPrice$,
      market: this.store.market.read$,
      orderType: this.orderType,
      stopPrice:
        this.orderType === StxDxOrderType.StopLimit
          ? this.stopPrice.read$
          : undefined,
      senderFeeRate: this.store.info.senderFeeRate$,
    }
    return Result.ok(data)
  }

  @observable confirming = false

  @asyncAction async trade(
    data: TradeFormData,
    run = runAsyncAction,
  ): Promise<void> {
    this.confirming = false
    await run(tradeInStxDx(data))
    this.emptyTradeAmount()
  }

  @computed get onDeposit$(): () => void {
    const onStartFlow = this.store.depositFlow.onStartFlow$

    return () =>
      onStartFlow({
        depositCurrency:
          this.side === "buy" ? this.priceToken$ : this.tradeToken$,
      })
  }

  /**
   * Replace price to best ask/bid price if custom price is abnormal
   */
  @action onReplacePrice(): void {
    const { bestAskPrice$, bestBidPrice$ } = this.store.orderbook
    const targetPrice = this.side === "buy" ? bestAskPrice$ : bestBidPrice$
    targetPrice && this.customPrice.set(targetPrice)
  }
}
