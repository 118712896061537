import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Spensor } from "../../../components/Spensor"
import { Block } from "../../../components/TokenInput/Block"
import { BlockInputContainer } from "../../../components/TokenInput/BlockInputContainer"
import { TokenCountInput } from "../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { FCC } from "../../../utils/reactHelpers/types"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../utils/SuspenseResource"

export const LabelTokenInputReadonly: FCC<{
  className?: string
  label: ReactNode
  token: SuspenseResource<TokenInfo>
}> = props => {
  return (
    <Block
      className={clsx("flex flex-col", props.className)}
      boxClassName={"p-2.5"}
    >
      <div className={"text-xs leading-5 font-medium text-gray-500"}>
        {props.label}
      </div>

      <div className={"flex gap-1"}>
        {props.children}

        <span className={"ml-auto text-base font-medium text-gray-400"}>
          <Spensor fallback={"-"}>
            {() => <TokenName token={readResource(props.token)} />}
          </Spensor>
        </span>
      </div>
    </Block>
  )
}

export const LabelTokenInput: FC<{
  className?: string
  label: ReactNode
  disabled?: boolean
  error?: boolean
  token: SuspenseResource<TokenInfo>
  value: SuspenseResource<null | number>
  onChange?: (newValue: null | number) => void
}> = props => {
  const precision = TokenInfo.getPrecision(safeReadResource(props.token))

  return (
    <BlockInputContainer
      className={props.className}
      disabled={props.disabled}
      error={props.error}
      renderBlock={p => <Block {...p} boxClassName={"p-2.5"} />}
    >
      {p => (
        <div className={"flex flex-col"}>
          <div className={"text-xs leading-5 font-medium text-gray-500"}>
            {props.label}
          </div>

          <div className={"flex gap-1"}>
            <TokenCountInput
              inputRef={p.inputRef}
              className={clsx(
                "flex-1 bg-transparent text-base font-semibold placeholder-gray-200::placeholder outline-none",
                props.error && "text-red-500",
              )}
              disabled={props.disabled}
              placeholder={precision > 0 ? "0.0" : "0"}
              token={props.token}
              value={safeReadResource(props.value) ?? undefined}
              onChange={props.onChange}
            />

            <span className={"ml-auto text-base font-medium text-gray-400"}>
              <Spensor fallback={"-"}>
                {() => <TokenName token={readResource(props.token)} />}
              </Spensor>
            </span>
          </div>
        </div>
      )}
    </BlockInputContainer>
  )
}
