import { DepositRowData } from "../../store/transformerGroups/lend/deposit"
import { Exporter } from "../../types"
import { exporterBuilder } from "../builder"
import { parseAmount } from "../parser"

export const depositExporter: Exporter<DepositRowData> = (
  rowData,
  allTokenInfos,
) => {
  const amount = parseAmount({
    fromCurrencyAmounts: [rowData.fromCurrencyAmount],
    toCurrencyAmounts: [rowData.toCurrencyAmount],
    allTokenInfos,
  })
  const tradeInfo = `#${rowData.expiry}`
  return exporterBuilder(amount, tradeInfo)(rowData, allTokenInfos)
}
