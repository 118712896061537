import clsx from "clsx"
import { FC } from "react"
import { defineMessage, IntlShape, useIntl } from "react-intl"
import { Dropdown, DropdownItem } from "../../../components/Dropdown"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { PercentNumber } from "../../../components/PercentNumber"
import { TextTokenCount } from "../../../components/RichTokenCount"
import { TokenNameStack } from "../../../components/TokenNameStack"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { SuspenseResource } from "../../../utils/SuspenseResource"
import { OrderDirection } from "./types"
import {
  CompactCard,
  CompactCardTitleText,
} from "./wrappedCommonComponents/CompactCard"

export interface MarginPool {
  id: string
  tradeToken: TokenInfo
  priceToken: TokenInfo
  orderDirection: OrderDirection
}

export const MarginRatio: FC<{
  className?: string

  availableMarginPools: MarginPool[]
  selectedMarginPool: MarginPool
  onSelectedMarginPool: (pool: MarginPool) => void

  /**
   * 0 -> safe, 1 -> danger
   */
  healthPercentage: number
  maintenanceMarginPriceTokenCount: SuspenseResource<number>
  marginPriceTokenCount: SuspenseResource<number>
}> = props => {
  const intl = useIntl()
  const { $t } = intl
  return (
    <CompactCard className={clsx("flex flex-col gap-2.5", props.className)}>
      <CompactCardTitleText>
        {$t(
          defineMessage({
            defaultMessage: "Margin Ratio",
            description: `Perpetual/Margin Ratio Panel/title`,
          }),
        )}
      </CompactCardTitleText>

      <InfoList
        gapClassName={"gap-2.5"}
        direction={"column"}
        listItemDirection={"row"}
      >
        <InfoListItem>
          <InfoListItemTitle>
            <Dropdown
              trigger={
                <span
                  className={
                    "flex items-center text-sm leading-5 font-medium text-gray-200"
                  }
                >
                  {intlMarginPoolName(intl, props.selectedMarginPool)}&nbsp;
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="#C4C4C4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.20469 8.50136L1.84298 4.17196L2.55009 3.46484L6.20498 7.11972L9.85929 3.46541L10.5664 4.17251L6.20469 8.50136Z" />
                  </svg>
                </span>
              }
            >
              {props.availableMarginPools.map(pool => (
                <DropdownItem
                  key={pool.id}
                  selected={pool.id === props.selectedMarginPool.id}
                >
                  {intlMarginPoolName(intl, pool)}
                </DropdownItem>
              ))}
            </Dropdown>
          </InfoListItemTitle>
          <InfoListItemDetail className={"flex flex-row gap-2.5 items-center"}>
            <HealthWatcher percentage={props.healthPercentage} />

            <span
              className={
                props.healthPercentage < 0.34
                  ? "text-gray-600"
                  : props.healthPercentage < 0.67
                  ? "text-yellow-500"
                  : "text-red-500"
              }
            >
              <PercentNumber number={props.healthPercentage} />
            </span>
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Maintenance Margin",
                description: `Perpetual/Margin Ratio Panel/label text`,
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.selectedMarginPool.priceToken}
              count={props.maintenanceMarginPriceTokenCount}
            />
          </InfoListItemDetail>
        </InfoListItem>

        <InfoListItem>
          <InfoListItemTitle>
            {$t(
              defineMessage({
                defaultMessage: "Margin Balance",
                description: `Perpetual/Margin Ratio Panel/label text`,
              }),
            )}
          </InfoListItemTitle>
          <InfoListItemDetail>
            <TextTokenCount
              token={props.selectedMarginPool.priceToken}
              count={props.marginPriceTokenCount}
            />
          </InfoListItemDetail>
        </InfoListItem>
      </InfoList>
    </CompactCard>
  )
}

const HealthWatcher: FC<{ className?: string; percentage: number }> = props => {
  const size = 24
  return (
    <div
      className={clsx(
        props.className,
        "relative flex items-center justify-center",
      )}
      style={{ width: size, height: size }}
    >
      <svg
        className={"absolute"}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.02979 18.0299C2.73264 14.7327 2.73264 9.38699 6.02979 6.08984"
          stroke="#16A34A"
          strokeWidth="6"
        />
        <path
          d="M6.02998 6.08989C9.32713 2.79274 14.6729 2.79274 17.97 6.08989"
          stroke="#EAB308"
          strokeWidth="6"
        />
        <path
          d="M17.9702 18.0299C21.2674 14.7327 21.2674 9.38699 17.9702 6.08984"
          stroke="#EF4444"
          strokeWidth="6"
        />
      </svg>

      <svg
        className={"absolute"}
        style={{ transform: `rotate(${270 * props.percentage}deg)` }}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="9.98096"
          cy="9.98064"
          r="2.4"
          transform="rotate(-135 9.98096 9.98064)"
          fill="white"
        />
        <rect
          x="3.6167"
          y="17.1931"
          width="1.2"
          height="9.6"
          rx="0.6"
          transform="rotate(-135 3.6167 17.1931)"
          fill="white"
        />
      </svg>
    </div>
  )
}

function intlMarginPoolName({ $t }: IntlShape, pool: MarginPool): JSX.Element {
  return (
    <>
      {$t(
        pool.orderDirection === OrderDirection.Long
          ? defineMessage({
              defaultMessage: "{tokenPair} Isolated Long",
              description: `Perpetual/Margin Ratio Panel/title`,
            })
          : defineMessage({
              defaultMessage: "{tokenPair} Isolated Short",
              description: `Perpetual/Margin Ratio Panel/title`,
            }),
        {
          tokenPair: (
            <TokenNameStack
              tradeToken={pool.tradeToken}
              priceToken={pool.priceToken}
            />
          ),
        },
      )}
    </>
  )
}
