import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { AlertLayoutAsEntry } from "../../../../components/alert/AlertLayoutAsEntry"
import { WhiteGradientAlert } from "../../../../components/alert/WhiteGradientAlert"
import { CardDivider } from "../../../../components/Card"
import {
  CollapsableCard,
  DefaultContentContainer,
  IdentityContentContainer,
} from "../../../../components/CollapsableCard/CollapsableCard"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { IconTokenCount } from "../../../../components/RichTokenCount"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { hasAny } from "../../../../utils/arrayHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { FCC } from "../../../../utils/reactHelpers/types"
import { ClaimTokenInfo } from "../../types"
import { CollapsableCardScrollableRow } from "../CollapsableCardScrollableRow"

export interface DepositRecord {
  depositedToken: TokenInfo
  yieldToken: TokenInfo
  yieldTokenCount: number
  claimableUponMaturity: number
  claimableAmount: number
  depositLink: string
}

export interface BorrowRecord {
  borrowedToken: TokenInfo
  borrowedTokenCount: number
  collateralTokens: ClaimTokenInfo[]
  claimTokens: ClaimTokenInfo[]
  borrowLink: string
}

export const MyDashboardPanel: FC<{
  className?: string
  defaultCollapsed?: boolean
  depositRecords: DepositRecord[]
  borrowRecords: BorrowRecord[]
}> = props => {
  const { $t } = useIntl()
  return (
    <CollapsableCard
      className={props.className}
      title={$t(
        defineMessage({
          defaultMessage: "My Deposit & Borrow",
          description: "/Lend/MyDashboardPanel/Title",
        }),
      )}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={IdentityContentContainer}
    >
      <DefaultContentContainer>
        <CardDivider />
      </DefaultContentContainer>
      {hasAny(props.depositRecords) && (
        <PanelSectionFrame
          title={$t(
            defineMessage({
              defaultMessage: "Deposited",
              description: "/Lend/MyDashboardPanel/SectionFrame/Title",
            }),
          )}
        >
          {props.depositRecords.map((r, idx) => (
            <CollapsableCardScrollableRow key={idx}>
              <Link to={r.depositLink}>
                <AlertLayoutAsEntry key={idx} Alert={WhiteGradientAlert}>
                  <InfoList
                    direction={"row"}
                    listItemDirection={"column"}
                    listItemClassName={"flex-1 flex flex-col gap-2"}
                  >
                    <InfoListItem>
                      <InfoListItemTitle>
                        {$t(
                          defineMessage({
                            defaultMessage: "Deposited",
                            description:
                              "/Lend/DepositPage/SellDepositModalContent/Info list item title",
                          }),
                        )}
                      </InfoListItemTitle>
                      <InfoListItemDetail className={"my-auto"}>
                        <IconTokenCount
                          className={"text-2xl"}
                          iconSize={32}
                          tokenNameClassName={"text-base"}
                          token={r.yieldToken}
                          count={r.yieldTokenCount}
                        />
                      </InfoListItemDetail>
                    </InfoListItem>
                    <InfoListItem>
                      <InfoListItemTitle>
                        {$t(
                          defineMessage({
                            defaultMessage: "Claimable Upon Maturity",
                            description:
                              "/Lend/DepositPage/SellDepositModalContent/Info list item title",
                          }),
                        )}
                      </InfoListItemTitle>
                      <InfoListItemDetail>
                        <IconTokenCount
                          token={r.depositedToken}
                          count={r.claimableUponMaturity}
                        />
                      </InfoListItemDetail>
                    </InfoListItem>
                    <InfoListItem>
                      <InfoListItemTitle>
                        {$t(
                          defineMessage({
                            defaultMessage: "Claimable Amount",
                            description:
                              "/Lend/DepositPage/SellDepositModalContent/Info list item title",
                          }),
                        )}
                      </InfoListItemTitle>
                      <InfoListItemDetail>
                        <IconTokenCount
                          token={r.depositedToken}
                          count={r.claimableAmount}
                        />
                      </InfoListItemDetail>
                    </InfoListItem>
                  </InfoList>
                </AlertLayoutAsEntry>
              </Link>
            </CollapsableCardScrollableRow>
          ))}
        </PanelSectionFrame>
      )}

      {hasAny(props.borrowRecords) && (
        <PanelSectionFrame
          title={$t(
            defineMessage({
              defaultMessage: "Borrowed",
              description:
                "/Lend/DepositPage/SellDepositModalContent/SectionFrame/Title",
            }),
          )}
        >
          {props.borrowRecords.map((r, idx) => (
            <CollapsableCardScrollableRow key={idx}>
              <Link to={r.borrowLink}>
                <AlertLayoutAsEntry key={idx} Alert={WhiteGradientAlert}>
                  <InfoList
                    direction={"row"}
                    listItemDirection={"column"}
                    listItemClassName={"flex-1 flex flex-col gap-2"}
                  >
                    <InfoListItem>
                      <InfoListItemTitle>
                        {$t(
                          defineMessage({
                            defaultMessage: "Borrowed",
                            description:
                              "/Lend/DepositPage/SellDepositModalContent/Info list item title",
                          }),
                        )}
                      </InfoListItemTitle>
                      <InfoListItemDetail className={"my-auto"}>
                        <IconTokenCount
                          className={"text-2xl"}
                          iconSize={32}
                          tokenNameClassName={"text-base"}
                          token={r.borrowedToken}
                          count={r.borrowedTokenCount}
                        />
                      </InfoListItemDetail>
                    </InfoListItem>
                    <InfoListItem>
                      <InfoListItemTitle>
                        {$t(
                          defineMessage({
                            defaultMessage: "Collateral",
                            description:
                              "/Lend/DepositPage/SellDepositModalContent/Info list item title",
                          }),
                        )}
                      </InfoListItemTitle>
                      <InfoListItemDetail>
                        <ul>
                          {r.collateralTokens.map((c, idx) => (
                            <div key={idx}>
                              <IconTokenCount token={c.token} count={c.count} />
                            </div>
                          ))}
                        </ul>
                      </InfoListItemDetail>
                    </InfoListItem>
                    <InfoListItem>
                      <InfoListItemTitle>
                        {$t(
                          defineMessage({
                            defaultMessage: "Claimable Amount {icon}",
                            description:
                              "/Lend/DepositPage/SellDepositModalContent/Info list item title",
                          }),
                          {
                            icon: (
                              <TipIcon
                                inline
                                tip={$t(
                                  defineMessage({
                                    defaultMessage:
                                      "The redemption certificate token is used when you redeem your collateral.",
                                    description:
                                      "/Lend/DepositPage/SellDepositModalContent/Info list item title tooltip",
                                  }),
                                )}
                              />
                            ),
                          },
                        )}
                      </InfoListItemTitle>
                      <InfoListItemDetail>
                        <ul>
                          {r.claimTokens.map((c, idx) => (
                            <div key={idx}>
                              <IconTokenCount token={c.token} count={c.count} />
                            </div>
                          ))}
                        </ul>
                      </InfoListItemDetail>
                    </InfoListItem>
                  </InfoList>
                </AlertLayoutAsEntry>
              </Link>
            </CollapsableCardScrollableRow>
          ))}
        </PanelSectionFrame>
      )}
    </CollapsableCard>
  )
}

const PanelSectionFrame: FCC<{
  title: ReactNode
}> = props => (
  <div className={"flex flex-col gap-2"}>
    <DefaultContentContainer className={"font-medium text-base text-gray-400"}>
      {props.title}
    </DefaultContentContainer>
    <div className={"flex flex-col gap-4"}>{props.children}</div>
  </div>
)
