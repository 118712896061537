import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"

import { TokenListStatus } from "../../../types"

export const ListStatus: FC<{
  status: TokenListStatus
}> = props => {
  const { $t } = useIntl()
  return (
    <div
      className={clsx(
        props.status === TokenListStatus.Listed
          ? " bg-green-300 text-green-700"
          : "bg-gray-500 text-white",
        "px-2 rounded-full text-xs leading-4",
      )}
    >
      {props.status === TokenListStatus.Listed
        ? $t(
            defineMessage({
              defaultMessage: "List",
              description:
                "/Orderbook/B20TokenListPage/TokenListFrame/List status",
            }),
          )
        : $t(
            defineMessage({
              defaultMessage: "Candidate",
              description:
                "/Orderbook/B20TokenListPage/TokenListFrame/Candidate status",
            }),
          )}
    </div>
  )
}
