import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { WhiteGradientAlert } from "../../../../components/alert/WhiteGradientAlert"
import {
  btnPresets,
  Button,
  ButtonProps,
} from "../../../../components/button/Button"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import {
  CollapsableCard,
  IdentityContentContainer,
} from "../../../../components/CollapsableCard/CollapsableCard"
import { EmptyState } from "../../../../components/EmptyState/EmptyState"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { IconTokenCount } from "../../../../components/RichTokenCount"
import { TimeCountdown } from "../../../../components/TimeCountdown/TimeCountdown"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { FCC } from "../../../../utils/reactHelpers/types"
import { CollapsableCardScrollableRow } from "../CollapsableCardScrollableRow"
import { DepositRecord } from "../types"

export const MyDepositsPanel: FC<{
  className?: string
  defaultCollapsed?: boolean
  depositRecords: DepositRecord[]
}> = props => {
  const { $t } = useIntl()
  return (
    <CollapsableCard
      className={props.className}
      title={$t(
        defineMessage({
          defaultMessage: "Deposited",
          description: "/Lend/DedpositPage/MyDepositsPanel/Title",
        }),
      )}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={IdentityContentContainer}
    >
      {props.depositRecords.map((r, idx) => (
        <CollapsableCardScrollableRow key={idx}>
          <WhiteGradientAlert withInteractionEffect={true}>
            {r.status === "active" ? (
              <ActiveDepositRecord record={r} />
            ) : (
              <ExpiredDepositRecord record={r} />
            )}
          </WhiteGradientAlert>
        </CollapsableCardScrollableRow>
      ))}
      {!props.depositRecords.length && (
        <EmptyState className={"m-auto"}>
          {$t(
            defineMessage({
              defaultMessage: "No Deposit Data",
              description: "/Lend/DedpositPage/MyDepositsPanel/Empty State",
            }),
          )}
        </EmptyState>
      )}
    </CollapsableCard>
  )
}

const ActiveDepositRecord: FC<{ record: DepositRecord.Active }> = props => {
  const { $t } = useIntl()
  return (
    <InfoList
      className={"flex-1"}
      direction={"row"}
      listItemDirection={"column"}
      listItemClassName={"flex-1 flex flex-col gap-2"}
    >
      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Active deposits",
              description:
                "/Lend/DedpositPage/ActiveDepositRecord/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail className={"my-auto"}>
          <IconTokenCount
            className={"text-2xl"}
            iconSize={32}
            tokenNameClassName={"text-base"}
            token={props.record.yieldToken}
            count={props.record.yieldTokenCount}
          />
        </InfoListItemDetail>
      </InfoListItem>
      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Maturity Amount",
              description:
                "/Lend/DedpositPage/ActiveDepositRecord/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          <IconTokenCount
            token={props.record.intrinsicToken}
            count={props.record.intrinsicTokenCount}
          />
        </InfoListItemDetail>
      </InfoListItem>
      <SimpleSection flex={0.5}>
        <Tooltip
          title={$t(
            defineMessage({
              defaultMessage: "Maturity Block：{block}",
              description:
                "/Lend/DedpositPage/ActiveDepositRecord/Time countdown tooltip",
            }),
            {
              block: props.record.expiredAtBlock,
            },
          )}
        >
          <TimeCountdown time={props.record.expiredAt} />
        </Tooltip>
      </SimpleSection>
      <SimpleSection className={"gap-5"}>
        <StyledButton
          {...btnPresets.small}
          Variant={WhiteFilledButton}
          onClick={props.record.onSell}
        >
          {$t(
            defineMessage({
              defaultMessage: "Sell",
              description:
                "/Lend/DedpositPage/ActiveDepositRecord/Section Button",
            }),
          )}
        </StyledButton>
      </SimpleSection>
    </InfoList>
  )
}

const ExpiredDepositRecord: FC<{ record: DepositRecord.Expired }> = props => {
  const { $t } = useIntl()
  return (
    <InfoList
      className={"flex-1"}
      direction={"row"}
      listItemDirection={"column"}
      listItemClassName={"flex-1 flex flex-col gap-2"}
    >
      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Expired deposits",
              description:
                "/Lend/DedpositPage/ExpiredDepositRecord/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail className={"my-auto"}>
          <IconTokenCount
            className={"text-2xl"}
            iconSize={32}
            tokenNameClassName={"text-base"}
            token={props.record.yieldToken}
            count={props.record.yieldTokenCount}
          />
        </InfoListItemDetail>
      </InfoListItem>

      <InfoListItem>
        <InfoListItemTitle>
          {$t(
            defineMessage({
              defaultMessage: "Claimable Amount",
              description:
                "/Lend/DedpositPage/ExpiredDepositRecord/Info list item title",
            }),
          )}
        </InfoListItemTitle>
        <InfoListItemDetail>
          <IconTokenCount
            token={props.record.intrinsicToken}
            count={props.record.intrinsicTokenCount}
          />
        </InfoListItemDetail>
      </InfoListItem>
      <SimpleSection flex={0.5} />
      <SimpleSection className={"gap-5"}>
        <StyledButton
          {...btnPresets.small}
          Variant={WhiteFilledButton}
          onClick={props.record.onClaim}
        >
          {$t(
            defineMessage({
              defaultMessage: "Claim",
              description:
                "/Lend/DedpositPage/ExpiredDepositRecord/Section Button",
            }),
          )}
        </StyledButton>
        <StyledButton
          {...btnPresets.small}
          Variant={GradientFilledButton}
          onClick={props.record.onRollover}
          disabled={true}
        >
          {$t(
            defineMessage({
              defaultMessage: "Rollover {icon}",
              description:
                "/Lend/DedpositPage/ExpiredDepositRecord/Section Button",
            }),
            {
              icon: (
                <TipIcon
                  inline
                  tip={$t(
                    defineMessage({
                      defaultMessage:
                        "Rollover extends your deposit period through the next reward cycle.",
                      description:
                        "/Lend/DedpositPage/ExpiredDepositRecord/tooltips",
                    }),
                  )}
                />
              ),
            },
          )}
        </StyledButton>
      </SimpleSection>
    </InfoList>
  )
}

const StyledButton: FC<ButtonProps> = props => (
  <Button
    {...props}
    className={clsx(btnPresets.small.className, "min-w-[100px]")}
  />
)

const SimpleSection: FCC<{ className?: string; flex?: number }> = props => (
  <div
    className={clsx(
      "flex-1 flex justify-end h-[fit-content] self-center",
      props.className,
    )}
    style={{
      flex: props.flex ?? 1,
    }}
  >
    {props.children}
  </div>
)
