export enum FormErrorType {
  InsufficientTokenBalance = "InsufficientTokenBalance",
  LessThanMinimizeAmount = "LessThanMinimizeAmount",
  AmountIsEmpty = "AmountIsEmpty",
  WalletNotConnected = "WalletNotConnected",
  PoolNotCreatedYet = "PoolNotCreatedYet",
  PoolAtCapacity = "PoolAtCapacity",
}

export type FormError = {
  type: FormErrorType
  message: string
}
