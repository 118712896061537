import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  ChartPanelFrame,
  ChartPlaceholder,
  ChartTitle,
} from "../../../../components/ChartPanel"
import { MayDollarSignTokenName } from "../../../../components/MayDollarSignTokenName"
import { Spensor } from "../../../../components/Spensor"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import {
  CurvePoint,
  EarningsPreviewChart,
  lineStyles,
} from "./_/EarningsPreviewChart"

export type { CurvePoint } from "./_/EarningsPreviewChart"

export interface EarningsPreviewSectionProps {
  className?: string

  token: TokenInfo

  userSelectedCycleCount: number
  missedCycleCount: number
  maxSelectableCycleCount: number

  chartCurves?: {
    gapCurve: SuspenseResource<CurvePoint[]>
    idealEarningsCurve: SuspenseResource<CurvePoint[]>
    estimatedEarningsCurve: SuspenseResource<CurvePoint[]>
  }
}

export const EarningsPreviewSection: FC<
  EarningsPreviewSectionProps
> = props => {
  const { $t } = useIntl()
  return (
    <ChartPanelFrame
      className={props.className}
      header={
        <>
          <ChartTitle.Primary>
            {$t(
              defineMessage({
                defaultMessage: "Earnings Preview",
                description: "/Stake/ManualStake/EarningsPreviewSection title",
              }),
            )}
          </ChartTitle.Primary>
          <ChartTitle.Secondary>
            {$t(
              defineMessage({
                defaultMessage: "Longer cycles will yield a higher APR",
                description: "/Stake/ManualStake/EarningsPreviewSection title",
              }),
            )}
          </ChartTitle.Secondary>
        </>
      }
    >
      <Spensor fallback={<ChartPlaceholder.Loading />}>
        {() =>
          props.chartCurves == null ? (
            <ChartPlaceholder.Unavailable />
          ) : (
            <EarningsPreviewChart
              maxSelectableCycleCount={props.maxSelectableCycleCount}
              userSelectCycleCount={props.userSelectedCycleCount}
              idealEarningsCurve={readResource(
                props.chartCurves.idealEarningsCurve,
              )}
              estimatedEarningsCurve={readResource(
                props.chartCurves.estimatedEarningsCurve,
              )}
              gapCurve={readResource(props.chartCurves.gapCurve)}
            />
          )
        }
      </Spensor>

      <div className="flex flex-col gap-4 text-gray-200">
        <div className="flex items-center">
          <div
            className="w-12 mr-3.5"
            style={{
              borderWidth: 2,
              borderColor: lineStyles.userSelectedEarningCurve.color,
            }}
          />
          <div>
            <p>
              {$t(
                defineMessage({
                  defaultMessage:
                    "Selected {tokenName} staking cycle APR curve ({cycleCount} Cycles)",
                  description:
                    "/Stake/ManualStake/EarningsPreviewSection content",
                }),
                {
                  tokenName: <MayDollarSignTokenName token={props.token} />,
                  cycleCount: props.userSelectedCycleCount,
                },
              )}
            </p>
            <p className="text-gray-500">
              {$t(
                defineMessage({
                  defaultMessage:
                    "* There will be 1 cycle missed after every {cycleCount} cycles",
                  description:
                    "/Stake/ManualStake/EarningsPreviewSection content",
                }),
                {
                  cycleCount: props.userSelectedCycleCount,
                },
              )}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <div
            className="w-12 mr-3.5"
            style={{
              borderWidth: 2,
              borderColor: lineStyles.estimatedEarningsCurve.color,
              borderStyle: "dashed",
              opacity: lineStyles.estimatedEarningsCurve.opacity,
            }}
          />
          <p>
            {$t(
              defineMessage({
                defaultMessage:
                  "Estimated APR curve based on your cycle selection",
                description:
                  "/Stake/ManualStake/EarningsPreviewSection content",
              }),
            )}
          </p>
        </div>

        <div className="flex items-center">
          <div
            className="w-12 mr-3.5"
            style={{
              borderWidth: 2,
              borderColor: lineStyles.idealEarningsCurve.color,
            }}
          />
          <p>
            {$t(
              defineMessage({
                defaultMessage:
                  "Maximum {tokenName} staking cycle APR curve ({cycleCount} Cycles)",
                description:
                  "/Stake/ManualStake/EarningsPreviewSection content",
              }),
              {
                tokenName: <MayDollarSignTokenName token={props.token} />,
                cycleCount: props.maxSelectableCycleCount,
              },
            )}
          </p>
        </div>
      </div>
    </ChartPanelFrame>
  )
}
