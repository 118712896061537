import { Observable, Subscribable } from "rxjs"

export const fromSubscribable = <T>(
  subscribable: Subscribable<T>,
): Observable<T> => {
  return new Observable(subscriber => {
    const subscription = subscribable.subscribe(subscriber)
    return () => subscription.unsubscribe()
  })
}
