import { gql } from "@urql/core"
import { Observable, of } from "rxjs"
import { IS_MAIN_NET } from "../../../../config"
import {
  FetchAlexIdoAnalysisDataQuery,
  FetchAlexIdoAnalysisDataQueryVariables,
  FetchBananaIdoAnalysisDataQuery,
  FetchBananaIdoAnalysisDataQueryVariables,
  FetchLaunchpadsQuery,
  FetchLaunchpadsQueryVariables,
} from "../../../../generated/graphql/graphql.generated"
import { Currency } from "../../../../utils/alexjs/Currency"
import { hasAny } from "../../../../utils/arrayHelpers"
import { gqlQuery } from "../../../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../../../utils/Observable/fromUrqlSource"
import {
  LaunchPadCMSDetailFragment,
  LaunchPadContentfulDetail,
  mapLaunchPadCmsDetailFragment,
} from "../LaunchPadContentfulStore.services"

export function getIDOFromContentful(): Observable<
  LaunchPadContentfulDetail[]
> {
  return fromUrqlSource(
    gqlQuery<FetchLaunchpadsQuery, FetchLaunchpadsQueryVariables>(
      gql`
        ${LaunchPadCMSDetailFragment}
        query FetchLaunchpads($env: String!) {
          idoCollection(
            where: { environment_contains_all: [$env] }
            order: [sys_publishedAt_DESC]
            limit: 20
          ) {
            items {
              ...LaunchPadCMSDetailFragment
            }
          }
        }
      `,
      {
        env: IS_MAIN_NET ? "Production" : "Development",
      },
    ),
    res =>
      res.data.idoCollection!.items.map(a => mapLaunchPadCmsDetailFragment(a!)),
  )
}

export function getIDOAnalysisData(
  idoTokenId: string,
): Observable<undefined | { participantsCount: number; allTimeHigh: number }> {
  if (idoTokenId === Currency.W_BANANA) {
    return fromUrqlSource(
      gqlQuery<
        FetchBananaIdoAnalysisDataQuery,
        FetchBananaIdoAnalysisDataQueryVariables
      >(
        gql`
          query FetchBananaIdoAnalysisData {
            public_dbt_dim_idos_info {
              ido_banana_participants_count
              max_alex_wban_ratio
            }
          }
        `,
        {},
      ),
      res =>
        !hasAny(res.data.public_dbt_dim_idos_info) ||
        res.data.public_dbt_dim_idos_info[0].ido_banana_participants_count ==
          null ||
        res.data.public_dbt_dim_idos_info[0].max_alex_wban_ratio == null
          ? undefined
          : {
              participantsCount: Number(
                res.data.public_dbt_dim_idos_info[0]
                  .ido_banana_participants_count,
              ),
              allTimeHigh: Number(
                res.data.public_dbt_dim_idos_info[0].max_alex_wban_ratio,
              ),
            },
    )
  }

  if (idoTokenId === Currency.ALEX) {
    return fromUrqlSource(
      gqlQuery<
        FetchAlexIdoAnalysisDataQuery,
        FetchAlexIdoAnalysisDataQueryVariables
      >(
        gql`
          query FetchAlexIdoAnalysisData {
            public_dbt_dim_idos_info {
              ido_alex_participants_count
              max_wstx_alex_ratio
            }
          }
        `,
        {},
      ),
      res =>
        !hasAny(res.data.public_dbt_dim_idos_info) ||
        res.data.public_dbt_dim_idos_info[0].ido_alex_participants_count ==
          null ||
        res.data.public_dbt_dim_idos_info[0].max_wstx_alex_ratio == null
          ? undefined
          : {
              participantsCount: Number(
                res.data.public_dbt_dim_idos_info[0]
                  .ido_alex_participants_count,
              ),
              allTimeHigh: Number(
                res.data.public_dbt_dim_idos_info[0].max_wstx_alex_ratio!,
              ),
            },
    )
  }

  return of(undefined)
}
