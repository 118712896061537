import { createElement } from "react"
import { Workbox } from "workbox-window"
import { MessageController } from "../components/message/MessageProvider"
import { SwitchToLatestAppVersionMessageItem } from "../components/message/SwitchToLatestAppVersionMessageItem"

export type GetMessageControllerFn = (
  callbackOnce: (ctrl: MessageController) => void,
) => void

export const enableWorkboxServiceWorker = (
  getMessageController: GetMessageControllerFn,
): void => {
  if (process.env.NODE_ENV !== "production") return
  if (!("serviceWorker" in navigator)) return

  const wb = new Workbox("/service-worker.js")
  void wb.register()

  setInterval(() => {
    void wb.update()
  }, 1000 * 60 * 60 * 0.5)

  wb.addEventListener("controlling", () => {
    window.location.reload()
  })

  wb.addEventListener("waiting", () => {
    getMessageController(ctrl =>
      ctrl.show(ctx => ({
        key: "App updated notification",
        message: createElement(SwitchToLatestAppVersionMessageItem, {
          onSwitch: () => wb.messageSkipWaiting(),
          onDismiss: ctx.close,
        }),
        autoDismiss: null,
      })),
    )
  })
}
