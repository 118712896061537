import { Currency } from "../../../../../utils/alexjs/Currency"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["alex-reserve-pool", "alex-reserve-pool-sft"] as const
const functionName = "stake-tokens" as const

export interface FarmRowData extends BaseRowData {
  type: NotifyTransactionType.Farm
  principalCurrencyAmount: CurrencyAmount.Confirmed
  period: number
}

export const farmTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  FarmRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.Farm,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()

    const tokenTrait = args["token-trait"] as Currency
    // staking and farming are the same transaction type, return undefined if is not farming.
    // undefined result will be filtered out
    if (tokenTrait === Currency.ALEX) {
      return undefined
    }

    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
        amount: getAmountFromContract(args["amount-token"]),
      },
      period: args["lock-period"],
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    if (tokenTrait === Currency.ALEX) {
      return undefined
    }
    return {
      principalCurrencyAmount: {
        currency: tokenTrait,
        amount: getAmountFromContract(args["amount-token"]),
      },
      period: args["lock-period"],
    }
  },
}
