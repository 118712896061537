import { ComponentType, FC, Ref } from "react"
import { defineMessage, useIntl } from "react-intl"
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../../components/Modal"
import { Spensor } from "../../../components/Spensor"
import { ButtonVariantProps } from "../../../components/button/ButtonVariant"
import { useAppEnvStore } from "../../../stores/appEnvStore/useAppEnvStore"
import { Result } from "../../../utils/Result"
import { readResource, suspenseResource } from "../../../utils/SuspenseResource"
import { waitFor } from "../../../utils/waitFor"
import { useWrapBridgeStore } from "../store/useWrapBridgeStore"
import { ResetThenApproveNoticeModalContent } from "./ResetThenApproveNoticeModalContent"
import { WiredFromForm } from "./WiredFromForm"
import { WiredToForm } from "./WiredToForm"
import {
  WrapBridgeConfirmation,
  WrapBridgeConfirmationFrame,
} from "./WrapBridgePanel/WrapBridgeConfirmation/WrapBridgeConfirmation"
import {
  ConnectorBg,
  WrapBridgePanelFrame,
} from "./WrapBridgePanel/WrapBridgePanel/WrapBridgePanel"
import { WrapBridgePanelDetailsSuspensible } from "./WrapBridgePanel/WrapBridgePanelDetails/WrapBridgePanelDetails"
import { WrapBridgePanelDetailsErrorBoundary } from "./WrapBridgePanel/WrapBridgePanelDetails/WrapBridgePanelDetailsErrorBoundary"
import { WrapBridgeSubmitArea } from "./WrapBridgePanel/WrapBridgeSubmitArea"

const WiredWrapBridgeConfirmation: FC = () => {
  const { wrapForm, wrapFormViewModule } = useWrapBridgeStore()
  const { confirmingFormData } = wrapFormViewModule

  if (confirmingFormData == null) return null

  return (
    <Spensor
      fallback={
        <WrapBridgeConfirmationFrame
          className={"min-h-[400px]"}
          onClose={() => wrapFormViewModule.closeConfirmModal()}
        >
          <LoadingIndicator className={"m-auto"} />
        </WrapBridgeConfirmationFrame>
      }
    >
      {() => (
        <WrapBridgeConfirmation
          {...readResource(confirmingFormData.confirmationData)}
          onConfirm={async () => {
            await wrapForm.execute(confirmingFormData.executionFormData)
            wrapFormViewModule.closeConfirmModal()
          }}
          onClose={() => wrapFormViewModule.closeConfirmModal()}
        />
      )}
    </Spensor>
  )
}

const WiredWrapBridgePanelDetails: FC<{
  containerRef?: Ref<HTMLElement>
  widthClassName?: string
}> = props => {
  const { wrapForm, wrapFormViewModule } = useWrapBridgeStore()
  return (
    <Spensor>
      {() => (
        <WrapBridgePanelDetailsSuspensible
          containerRef={props.containerRef}
          className={props.widthClassName}
          direction={wrapForm.direction}
          fromNetwork={wrapFormViewModule.fromNetwork}
          toNetwork={wrapFormViewModule.toNetwork}
          waitingBlocks={suspenseResource(
            () => wrapForm.transferProphet.value$.waitBlocks,
          )}
          feeRate={suspenseResource(
            () => wrapForm.transferProphet.value$.feeRate,
          )}
          minFeeAmount={suspenseResource(
            () => wrapForm.transferProphet.value$.minFeeAmount,
          )}
          fee={suspenseResource(() => wrapForm.wrapFeeTokenCount$)}
          feeToken={suspenseResource(() => wrapFormViewModule.wrapFeeToken$)}
          feeUsdCount={suspenseResource(
            () => wrapFormViewModule.feeCountToUSD$,
          )}
          fromToken={suspenseResource(() => wrapFormViewModule.fromToken$)}
          fromAmount={suspenseResource(() => wrapForm.fromTokenCount.get())}
          fromUsdCount={suspenseResource(
            () => wrapFormViewModule.fromTokenCountToUSD$,
          )}
          toAmount={suspenseResource(() => wrapForm.toTokenCount$)}
          toUsdCount={suspenseResource(
            () => wrapFormViewModule.toTokenCountToUSD$,
          )}
          toToken={suspenseResource(() => wrapFormViewModule.toToken$)}
          bridgingStartedAt={suspenseResource(
            () => wrapFormViewModule.bridgingStartedAt$,
          )}
          bridgingEndedAt={suspenseResource(
            () => wrapFormViewModule.bridgingEndedAt$,
          )}
        />
      )}
    </Spensor>
  )
}

export const useFormTitle = (): {
  fromTitle: string
  toTitle: string
} => {
  const { wrapForm } = useWrapBridgeStore()
  const { $t } = useIntl()
  const stacksText = $t(
    defineMessage({
      defaultMessage: "Stacks",
      description: "/Bridge/chain name",
    }),
  )
  const ETHText = $t(
    defineMessage({
      defaultMessage: "ETH",
      description: "/Bridge/chain name",
    }),
  )
  return {
    fromTitle: wrapForm.direction === "unwrap" ? stacksText : ETHText,
    toTitle: wrapForm.direction === "unwrap" ? ETHText : stacksText,
  }
}

export const WiredBridgePanel: FC<{
  ConnectorBg?: ConnectorBg
  SubmitButtonVariant?: ComponentType<ButtonVariantProps>
  backgroundImage?: string
}> = props => {
  const appEnv = useAppEnvStore()
  const { wrapForm, wrapFormViewModule } = useWrapBridgeStore()
  const { fromTitle, toTitle } = useFormTitle()

  return (
    <WrapBridgePanelFrame
      onSwitchClicked={() => wrapForm.swapWrapDirection()}
      fromTitle={fromTitle}
      ToTitle={toTitle}
      fromForm={<WiredFromForm />}
      toForm={<WiredToForm />}
      ConnectorBg={props.ConnectorBg}
      backgroundImage={props.backgroundImage}
    >
      <WrapBridgePanelDetailsErrorBoundary>
        {({ containerDomRef }) => (
          <WiredWrapBridgePanelDetails containerRef={containerDomRef} />
        )}
      </WrapBridgePanelDetailsErrorBoundary>

      <WrapBridgeSubmitArea
        formError={suspenseResource(() =>
          Result.maybeError(wrapFormViewModule.formData$),
        )}
        className={"mx-auto w-full max-w-lg"}
        wrapDirection={wrapForm.direction}
        SubmitButtonVariant={props.SubmitButtonVariant}
        onApprove={async () => {
          await wrapFormViewModule.tryToApproveFromCurrency()
          if (!appEnv.config$.enableBridgeSubmit) {
            return
          }
          const openConfirm = await waitFor(
            () => wrapFormViewModule.openConfirmModal$,
          )
          openConfirm?.()
        }}
        onSubmit={suspenseResource(() =>
          wrapFormViewModule.openConfirmModal$ == null ||
          !appEnv.config$.enableBridgeSubmit
            ? undefined
            : () => wrapFormViewModule.openConfirmModal$?.(),
        )}
      />

      <Modal
        visible={wrapFormViewModule.showingResetThenApproveModal}
        onClose={() => wrapFormViewModule.closeResetThenApproveModal()}
      >
        <WiredResetThenApproveNoticeModalContent />
      </Modal>

      <Modal
        visible={!!wrapFormViewModule.confirmingFormData}
        onClose={() => wrapFormViewModule.closeConfirmModal()}
      >
        <WiredWrapBridgeConfirmation />
      </Modal>
    </WrapBridgePanelFrame>
  )
}

const WiredResetThenApproveNoticeModalContent: FC = () => {
  const { wrapFormViewModule } = useWrapBridgeStore()

  return (
    <ResetThenApproveNoticeModalContent
      onStart={() => wrapFormViewModule.resetThenApproveFromCurrency()}
      onDismiss={() => {
        wrapFormViewModule.closeResetThenApproveModal()
      }}
    />
  )
}
