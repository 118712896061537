import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import { CardPlate } from "../../../../../components/Card"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { NoteParagraph } from "../../../../../components/NoteParagraph/NoteParagraph"
import { TokenCount } from "../../../../../components/TokenCount"
import { BlockTopLine } from "../../../../../components/TokenInput/Block"
import {
  TokenBlock,
  TokenCountPairPlain,
} from "../../../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../../../components/TokenName"

export interface ConfirmClaimModalContentProps {
  claimAssets: TokenCountPairPlain[]
  borrowedAssets: TokenCountPairPlain
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmClaimModalContent: FC<
  ConfirmClaimModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Claim",
          description: "/Lend/ConfirmClaimModalContent/Title",
        }),
      )}
      className={"flex flex-col gap-4"}
      width={480}
    >
      <TokenBlock
        topArea={
          <BlockTopLine>
            {$t(
              defineMessage({
                defaultMessage: "You will claim",
                description: "/Lend/ConfirmClaimModalContent/token block",
              }),
            )}
          </BlockTopLine>
        }
        tokens={props.claimAssets}
      />

      <CardPlate>
        <InfoList direction={"column"} listItemDirection={"row-responsive"}>
          <InfoListItem>
            <InfoListItemTitle>
              {$t(
                defineMessage({
                  defaultMessage: "Your Borrowed Amount",
                  description: "/Lend/ConfirmClaimModalContent/Info list item",
                }),
              )}
            </InfoListItemTitle>
            <InfoListItemDetail>
              <TokenCount
                token={props.borrowedAssets.token}
                count={props.borrowedAssets.count}
              />
              &nbsp;
              <TokenName token={props.borrowedAssets.token} />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardPlate>
      <NoteParagraph>
        {$t(
          defineMessage({
            defaultMessage:
              "Your claim amount reflects the dynamic CRP balance.",
            description: "/Lend/ConfirmClaimModalContent/Note",
          }),
        )}
      </NoteParagraph>
      <div className={"flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Lend/ConfirmClaimModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Lend/ConfirmClaimModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
