import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  AddStakeSectionInfoList,
  AddStakeSectionInfoListItem,
} from "../components/AddStakeSectionInfoList"

export interface StakeSubmissionSummaryProps {
  startedAtCycleNumber: number
  endedAtCycleNumber: number
  startedAtBlock: number
  endedAtBlock: number
}

export const StakeSubmissionSummary: FC<
  StakeSubmissionSummaryProps
> = props => {
  const { $t } = useIntl()

  return (
    <AddStakeSectionInfoList>
      <AddStakeSectionInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "Cycle numbers",
            description: "/Stake/ManualStake/StakeSubmissionSummary title",
          }),
        )}
        detail={`#${props.startedAtCycleNumber}-#${props.endedAtCycleNumber}`}
      />
      <AddStakeSectionInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "Start block",
            description:
              "/Stake/ManualStake/StakeSubmissionSummary/Info list item title",
          }),
        )}
        detail={props.startedAtBlock}
      />
      <AddStakeSectionInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "End block",
            description:
              "/Stake/ManualStake/StakeSubmissionSummary/Info list item title",
          }),
        )}
        detail={props.endedAtBlock}
      />
    </AddStakeSectionInfoList>
  )
}
