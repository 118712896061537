import { ComponentType, FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { loading$t } from "../../../../commonIntlMessages"
import { CardPlate } from "../../../../components/Card"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { ButtonVariantProps } from "../../../../components/button/ButtonVariant"
import {
  LoadableButton,
  SmartLoadableButton,
} from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../../utils/SuspenseResource"
import { FormError, FormErrorType } from "../../types/types"

export const WrapBridgeSubmitArea: FC<{
  className?: string
  formError: SuspenseResource<undefined | FormError>
  wrapDirection: "wrap" | "unwrap"
  SubmitButtonVariant?: ComponentType<ButtonVariantProps>
  onApprove: SuspenseResource<undefined | (() => void | Promise<void>)>
  onSubmit: SuspenseResource<undefined | (() => void | Promise<void>)>
}> = props => {
  const { SubmitButtonVariant = GradientFilledButton } = props
  const { $t } = useIntl()

  const formError = safeReadResource(props.formError)

  const errorMessage =
    formError?.type === FormErrorType.SourceTokenAmountTooSmall
      ? $t(
          defineMessage({
            defaultMessage:
              "{direction, select, wrap {Wrap} other {Unwrap}}  amount must exceed {amount} {fromTokenName}",
            description: "/Bridge/Form Panel/validate error message",
          }),
          {
            amount: (
              <TokenCount token={formError.token} count={formError.min} />
            ),
            fromTokenName: <TokenName token={formError.token} />,
            direction: props.wrapDirection,
          },
        )
      : formError?.type === FormErrorType.SourceTokenAmountTooLarge
      ? $t(
          defineMessage({
            defaultMessage: "Amount should smaller than {amount}",
            description: "/Bridge/Form Panel/validate error message",
          }),
          {
            amount: (
              <TokenCount token={formError.token} count={formError.max} />
            ),
          },
        )
      : formError?.type === FormErrorType.SourceTokenInsufficientBalance
      ? $t(
          defineMessage({
            defaultMessage: "Insufficient {token} balance",
            description: "/Bridge/Form Panel/validate error message",
          }),
          {
            token: <TokenName token={formError.token} />,
          },
        )
      : formError?.type === FormErrorType.SourceTokenInsufficientAllowance &&
        formError.currAllowance !== 0
      ? $t(
          defineMessage({
            defaultMessage:
              "To proceed with the transaction, you need to allow spending at least {amount} {token}",
            description: "/Bridge/Form Panel/validate error message",
          }),
          {
            amount: (
              <TokenCount
                token={formError.token}
                count={formError.minAllowance}
              />
            ),
            token: <TokenName token={formError.token} />,
          },
        )
      : null

  return (
    <>
      {errorMessage != null && (
        <CardPlate className={props.className}>
          <NoteParagraph
            icon={<WarnIcon />}
            textColorClassName={"text-red-500"}
          >
            {errorMessage}
          </NoteParagraph>
        </CardPlate>
      )}

      <Spensor
        fallback={
          <LoadableButton
            className={props.className}
            Variant={SubmitButtonVariant}
            loading={true}
          >
            {$t(loading$t)}
          </LoadableButton>
        }
      >
        {() =>
          formError?.type === FormErrorType.SourceTokenInsufficientAllowance ? (
            <SmartLoadableButton
              className={props.className}
              Variant={SubmitButtonVariant}
              disabled={readResource(props.onApprove) == null}
              onClick={readResource(props.onApprove)}
            >
              {$t(
                defineMessage({
                  defaultMessage:
                    "Give permission to {direction, select, wrap {wrap} other {unwrap}} {token}",
                  description:
                    "/Bridge/Form Panel/approve allowance button text",
                }),
                {
                  direction: props.wrapDirection,
                  token: <TokenName token={formError.token} />,
                },
              )}
            </SmartLoadableButton>
          ) : (
            <SmartLoadableButton
              className={props.className}
              Variant={SubmitButtonVariant}
              disabled={readResource(props.onSubmit) == null}
              onClick={readResource(props.onSubmit)}
            >
              {props.wrapDirection === "wrap"
                ? $t(
                    defineMessage({
                      defaultMessage: "Wrap",
                      description: "/Bridge/Form Panel/submit button text",
                    }),
                  )
                : $t(
                    defineMessage({
                      defaultMessage: "Unwrap",
                      description: "/Bridge/Form Panel/submit button text",
                    }),
                  )}
            </SmartLoadableButton>
          )
        }
      </Spensor>
    </>
  )
}
