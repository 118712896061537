import { createContext, useContext } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import { OneOrMore } from "../../utils/types"

export interface LocaleInfo {
  languageTag: string
  displayName: string
}

export interface LocaleSwitchContextValue {
  currentLocale: LocaleInfo

  availableLocales: Readonly<OneOrMore<LocaleInfo>>

  onLocaleSwitch: (locale: LocaleInfo) => void
}

const LocaleSwitchContext = createContext<null | LocaleSwitchContextValue>(null)

export interface LocaleSwitchProviderProps extends LocaleSwitchContextValue {}

export const LocaleSwitchProvider: FCC<LocaleSwitchProviderProps> = props => {
  return (
    <LocaleSwitchContext.Provider value={props}>
      {props.children}
    </LocaleSwitchContext.Provider>
  )
}

export const useLocaleSwitch = (): LocaleSwitchContextValue => {
  const ctxValue = useContext(LocaleSwitchContext)

  if (ctxValue == null) {
    throw new Error("useLocaleSwitch called outside of LocaleSwitchProvider")
  }

  return ctxValue
}
