import { CSSProperties, FC } from "react"

export const DownOutlinedIcon: FC<{
  clasName?: string
  style?: CSSProperties
}> = props => {
  return (
    <svg
      className={props.clasName}
      style={props.style}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.06798 8.50014L2.70626 4.17073L3.41337 3.46362L7.06826 7.1185L10.7226 3.46419L11.4297 4.17129L7.06798 8.50014Z" />
    </svg>
  )
}
