import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../../components/button/variants/WhiteFilledButton"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { normal } from "../../../../Perpetual/components/buttonPresets"
import { RectButton } from "../../../../Perpetual/components/wrappedCommonComponents/RectButton"
import { ReactComponent as WarningIcon } from "../_/warning.svg"
import { DetailSection } from "./_/DetailSection"
import { WarningSection } from "./_/WarningSection"

export const WithdrawDuplicateWarningModalContent: FC<{
  token: SuspenseResource<TokenInfo>
  userBalance: SuspenseResource<number>
  withdrawPendingAmount: SuspenseResource<number>
  withdrawRequests: SuspenseResource<{ txId: string; txExplorerLink: string }[]>
  onStillWithdraw: () => void
  onOk: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      className="flex flex-col gap-4"
      bgClassName="bg-gray-800"
      width={640}
      title={
        <h2 className="flex items-center justify-center space-x-2.5">
          <WarningIcon />
          <p>
            {$t(
              defineMessage({
                defaultMessage: "Warning",
                description:
                  "/OrderBook/BRC20/WithdrawDuplicateWarningModalContent/Title text",
              }),
            )}
          </p>
        </h2>
      }
      onClose={props.onClose}
    >
      <DetailSection
        token={props.token}
        userBalance={props.userBalance}
        withdrawPendingAmount={props.withdrawPendingAmount}
        withdrawRequests={props.withdrawRequests}
      />
      <WarningSection />
      <div className="flex space-x-2.5">
        <RectButton
          className={"flex-1"}
          Variant={WhiteFilledButton}
          {...normal}
          onClick={safeReadResource(props.onStillWithdraw)}
        >
          {$t(
            defineMessage({
              defaultMessage: "Still Withdraw",
              description:
                "/OrderBook/BRC20/WithdrawDuplicateWarningModalContent/Button text",
            }),
          )}
        </RectButton>
        <RectButton
          className={"flex-1"}
          Variant={GradientFilledButton}
          {...normal}
          onClick={props.onOk}
        >
          {$t(
            defineMessage({
              defaultMessage: "OK",
              description:
                "/OrderBook/BRC20/WithdrawDuplicateWarningModalContent/Button text",
            }),
          )}
        </RectButton>
      </div>
    </CardModalContent>
  )
}
