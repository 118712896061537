import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TimeCountdownText } from "./TimeCountdownText"

export const BlockTimeCountdownText: FC<{
  targetBlock: number
  currentBlock: SuspenseResource<number>
  time: SuspenseResource<Date>
  estimateSuffix?: ReactNode
  format?: (duration: Duration) => string
}> = props => {
  const { $t } = useIntl()
  return (
    <Spensor>
      {() => {
        const targetBlock = props.targetBlock
        const currentBlock = readResource(props.currentBlock)

        if (targetBlock - currentBlock > 1) {
          const time = readResource(props.time)
          return (
            <>
              <TimeCountdownText time={time} format={props.format} />
              {props.estimateSuffix}
            </>
          )
        }
        return (
          <>
            {$t(
              defineMessage({
                defaultMessage: "1 Block Remaining",
                description: "/Components/BlockTimeCountdown/Content text",
              }),
            )}
          </>
        )
      }}
    </Spensor>
  )
}
