import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BlueGradientAlert } from "../../../../components/alert/BlueGradientAlert"
import { Button } from "../../../../components/button/Button"
import { LoadableButton } from "../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import { CardDivider } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { Spensor } from "../../../../components/Spensor"
import { TipIcon } from "../../../../components/TipIcon/TipIcon"
import { TokenCount } from "../../../../components/TokenCount"
import {
  BalanceBottomArea,
  MaxButton,
} from "../../../../components/TokenInput/BalanceBottomArea"
import {
  Block,
  BlockTokenLine,
  DefaultTokenNameArea,
} from "../../../../components/TokenInput/Block"
import { BlockGroup } from "../../../../components/TokenInput/BlockGroup"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { TokenName } from "../../../../components/TokenName"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { BuyLotteryFormError, BuyLotteryFormErrorType } from "../types"
import { ReactComponent as GiftIcon } from "./_/giftIcon.svg"

export interface BuyLotteryModalContentProps {
  formError?: SuspenseResource<undefined | BuyLotteryFormError>

  lotteryTicketCount?: number
  onLotteryTicketCountChange?: (ticketCount: null | number) => void
  onPressMaxLotteryTicketButton?: SuspenseResource<undefined | (() => void)>

  priceToken: TokenInfo
  priceTokenCount: SuspenseResource<number>
  priceTokenBalance: SuspenseResource<number>
  lotteryTicketUnitPrice: SuspenseResource<number>

  additionalLotteryTicketPriceToken: TokenInfo
  additionalLotteryTicketRedeemableThreshold: SuspenseResource<number>
  additionalLotteryTicket?: SuspenseResource<{
    maximumRedeemableLotteryTicketCount: AdditionalLotteryTicketFormProps["maximumRedeemableLotteryTicketCount"]

    lotteryTicketCount: AdditionalLotteryTicketFormProps["lotteryTicketCount"]
    onLotteryTicketCountChange?: AdditionalLotteryTicketFormProps["onLotteryTicketCountChange"]
    onPressMaxLotteryTicketButton?: AdditionalLotteryTicketFormProps["onPressMaxLotteryTicketButton"]

    priceTokenCount: AdditionalLotteryTicketFormProps["priceTokenCount"]
    priceTokenBalance: AdditionalLotteryTicketFormProps["priceTokenBalance"]
    lotteryTicketUnitPrice: AdditionalLotteryTicketFormProps["lotteryTicketUnitPrice"]
  }>
  onConnectWallet?: () => void
  onSubmit?: () => void
  onClose?: () => void
}

export const BuyLotteryModalContent: FC<
  BuyLotteryModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Buy Lottery",
          description: "/Lottery/BuyLotteryModalContent/Card title",
        }),
      )}
      onClose={props.onClose}
    >
      <BlockGroup
        firstBlock={
          <TokenInput
            token={TokenInfoPresets.LotteryTicket}
            error={
              safeReadResource(props.formError)?.type ===
                BuyLotteryFormErrorType.InsufficientNormalLotteryTicketPriceTokenBalance ||
              safeReadResource(props.formError)?.type ===
                BuyLotteryFormErrorType.ExceedMaxAllowNormalLotteryTicketCount
            }
            value={props.lotteryTicketCount ?? null}
            onChange={props.onLotteryTicketCountChange}
            tokenNameArea={
              <DefaultTokenNameArea
                boxClassName={""}
                token={TokenInfoPresets.IDOLotteryTicket}
                afterTokenNameArea={
                  <Spensor>
                    {() => {
                      const onPressMaxLotteryTicketButton =
                        props.onPressMaxLotteryTicketButton &&
                        readResource(props.onPressMaxLotteryTicketButton)

                      return (
                        onPressMaxLotteryTicketButton != null && (
                          <MaxButton onClick={onPressMaxLotteryTicketButton} />
                        )
                      )
                    }}
                  </Spensor>
                }
              />
            }
          />
        }
        secondBlock={
          <Block>
            <BlockTokenLine
              tokenNameArea={<DefaultTokenNameArea token={props.priceToken} />}
              tokenCountArea={
                <Spensor fallback={"-"}>
                  {() => (
                    <TokenCount
                      token={props.priceToken}
                      count={readResource(props.priceTokenCount)}
                    />
                  )}
                </Spensor>
              }
            />
            <BalanceBottomArea
              token={props.priceToken}
              balance={props.priceTokenBalance}
              rightSide={
                <Spensor>
                  {() => (
                    <>
                      {$t(
                        defineMessage({
                          defaultMessage: "1 Ticket = {tokenCount}",
                          description:
                            "/Lottery/BuyLotteryModalContent/Blance text",
                        }),
                        {
                          tokenCount: (
                            <TextTokenCount
                              token={props.priceToken}
                              count={readResource(props.lotteryTicketUnitPrice)}
                            />
                          ),
                        },
                      )}
                    </>
                  )}
                </Spensor>
              }
            />
          </Block>
        }
      />
      <BlueGradientAlert className={"flex flex-col gap-4"}>
        <div className={clsx("flex items-center gap-2.5")}>
          <GiftIcon width={24} height={24} />
          <div className={"flex-1"}>
            {$t<ReactNode>(
              defineMessage({
                defaultMessage: `When the number of purchases is ≥ {tokenCount}, {tokenName} can be used to exchange for Lottery, and the cost will be subsidized by <span>ALEX Lab Foundation</span>`,
                description: "/Lottery/BuyLotteryModalContent/Alert text",
              }),
              {
                tokenCount: (
                  <Spensor fallback="--">
                    {() => (
                      <TokenCount
                        token={TokenInfoPresets.LotteryTicket}
                        count={readResource(
                          props.additionalLotteryTicketRedeemableThreshold,
                        )}
                      />
                    )}
                  </Spensor>
                ),
                tokenName: (
                  <TokenName token={props.additionalLotteryTicketPriceToken} />
                ),
                span: dontWrapObserver(children => (
                  <span className="text-yellow-200">{children}</span>
                )),
              },
            )}
          </div>
        </div>
        {props.additionalLotteryTicket != null && (
          <>
            <CardDivider className={"w-full"} />
            <Spensor fallback={<LoadingIndicator className="mx-auto" />}>
              {() => (
                <AdditionalLotteryTicketForm
                  formError={props.formError}
                  originPriceToken={props.priceToken}
                  priceToken={props.additionalLotteryTicketPriceToken}
                  {...readResource(props.additionalLotteryTicket!)}
                />
              )}
            </Spensor>
          </>
        )}
      </BlueGradientAlert>
      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(
              defineMessage({
                defaultMessage: "Deposit",
                description: "/Lottery/BuyLotteryModalContent/Button text",
              }),
            )}
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.formError)
          if (error?.type === BuyLotteryFormErrorType.WalletNotConnected) {
            return (
              <GradientFilledButton onClick={props.onConnectWallet}>
                {$t(
                  defineMessage({
                    defaultMessage: "Connect Wallet",
                    description: "/Lottery/BuyLotteryModalContent/Button text",
                  }),
                )}
              </GradientFilledButton>
            )
          }
          if (error != null) {
            return (
              <Button
                Variant={
                  isErrorStateButtonShouldRenderAsGradientButton(error)
                    ? GradientFilledButton
                    : RedFilledButton
                }
                disabled={true}
              >
                {error.message}
              </Button>
            )
          }
          return (
            <GradientFilledButton onClick={props.onSubmit}>
              {$t(
                defineMessage({
                  defaultMessage: "Buy Ticket",
                  description: "/Lottery/BuyLotteryModalContent/Button text",
                }),
              )}
            </GradientFilledButton>
          )
        }}
      </Spensor>
      <NoteParagraph>
        {$t<ReactNode>(
          defineMessage({
            defaultMessage: `<line>Lottery purchase can only be submitted ONCE per wallet per Lottery round.</line>
               <line>Please be certain you are entering your final values.</line>`,
            description: "/Lottery/BuyLotteryModalContent/Note text",
          }),
          {
            line: dontWrapObserver(children => <p>{children}</p>),
          },
        )}
      </NoteParagraph>
    </CardModalContent>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: BuyLotteryFormError,
): boolean => {
  if (!error) return false
  return error.type === BuyLotteryFormErrorType.EmptyNormalLotteryTicketCount
}

interface AdditionalLotteryTicketFormProps {
  formError?: SuspenseResource<undefined | BuyLotteryFormError>

  maximumRedeemableLotteryTicketCount: number

  lotteryTicketCount: number
  onLotteryTicketCountChange?: (ticketCount: null | number) => void
  onPressMaxLotteryTicketButton?: SuspenseResource<undefined | (() => void)>

  originPriceToken: TokenInfo
  priceToken: TokenInfo
  priceTokenCount: SuspenseResource<number>
  priceTokenBalance: SuspenseResource<number>
  lotteryTicketUnitPrice: SuspenseResource<number>
}

const AdditionalLotteryTicketForm: FC<
  AdditionalLotteryTicketFormProps
> = props => {
  const { $t } = useIntl()
  return (
    <>
      <div className={"flex items-center"}>
        <span>
          {$t(
            defineMessage({
              defaultMessage:
                "You can redeem additional <span>{tokenCount} Lottery</span>",
              description: "/Lottery/BuyLotteryModalContent/Ticket form",
            }),
            {
              tokenCount: (
                <TokenCount
                  token={TokenInfoPresets.LotteryTicket}
                  count={props.maximumRedeemableLotteryTicketCount}
                />
              ),
              span: dontWrapObserver(children => (
                <span className="text-yellow-200">{children}</span>
              )),
            },
          )}
        </span>

        <TipIcon
          className={"ml-auto"}
          tip={
            <RuleTipContent
              priceToken={props.priceToken}
              originPriceToken={props.originPriceToken}
            />
          }
        />
      </div>
      <BlockGroup
        firstBlock={
          <TokenInput
            token={TokenInfoPresets.LotteryTicket}
            error={
              safeReadResource(props.formError)?.type ===
                BuyLotteryFormErrorType.InsufficientAdditionalLotteryTicketPriceTokenBalance ||
              safeReadResource(props.formError)?.type ===
                BuyLotteryFormErrorType.ExceedMaxAllowAdditionalLotteryTicketCount
            }
            value={props.lotteryTicketCount}
            onChange={props.onLotteryTicketCountChange}
            tokenNameArea={
              <DefaultTokenNameArea
                boxClassName={""}
                token={TokenInfoPresets.IDOLotteryTicket}
                afterTokenNameArea={
                  <Spensor>
                    {() => {
                      const onPressMaxLotteryTicketButton =
                        props.onPressMaxLotteryTicketButton &&
                        readResource(props.onPressMaxLotteryTicketButton)

                      return (
                        onPressMaxLotteryTicketButton != null && (
                          <MaxButton onClick={onPressMaxLotteryTicketButton} />
                        )
                      )
                    }}
                  </Spensor>
                }
              />
            }
          />
        }
        secondBlock={
          <Block>
            <BlockTokenLine
              tokenNameArea={<DefaultTokenNameArea token={props.priceToken} />}
              tokenCountArea={
                <Spensor fallback={"-"}>
                  {() => (
                    <TokenCount
                      token={props.priceToken}
                      count={readResource(props.priceTokenCount)}
                    />
                  )}
                </Spensor>
              }
            />
            <BalanceBottomArea
              token={props.priceToken}
              balance={props.priceTokenBalance}
              rightSide={
                <Spensor>
                  {() => (
                    <>
                      {$t(
                        defineMessage({
                          defaultMessage: "1 Ticket = {tokenCount}",
                          description:
                            "/Lottery/BuyLotteryModalContent/Ticket form text",
                        }),
                        {
                          tokenCount: (
                            <TextTokenCount
                              token={props.priceToken}
                              count={readResource(props.lotteryTicketUnitPrice)}
                            />
                          ),
                        },
                      )}
                    </>
                  )}
                </Spensor>
              }
            />
          </Block>
        }
      />
    </>
  )
}

const RuleTipContent: FC<{
  priceToken: TokenInfo
  originPriceToken: TokenInfo
}> = props => {
  const { $t } = useIntl()

  return (
    <>
      {$t<ReactNode>(
        defineMessage({
          defaultMessage: `
            <content>
              <line>Swap 50 {priceToken} for each of these additional tickets.</line>
              <line>The ALEX Lab Foundation will cover these costs with {originPriceToken}.</line>
            </content>
            <content>
              <listItem>5-14 tickets: swap up to 1 additional ticket with {priceToken}</listItem>
              <listItem>15-24 tickets: swap up to 3 additional tickets with {priceToken}</listItem>
              <listItem>25-34 tickets: swap up to 6 additional tickets with {priceToken}</listItem>
              <listItem>35-44 tickets: swap up to 10 additional tickets with {priceToken}</listItem>
              <listItem>≥45 tickets: swap up to 15 additional tickets with {priceToken}</listItem>
            </content>`,
          description: "/Lottery/BuyLotteryModalContent/RuleTipContent text",
        }),
        {
          priceToken: <TokenName token={props.priceToken} />,
          originPriceToken: <TokenName token={props.originPriceToken} />,
          line: dontWrapObserver(children => <p>{children}</p>),
          content: dontWrapObserver(children => (
            <ul className="mb-2">{children}</ul>
          )),
          listItem: dontWrapObserver(children => <li>{children}</li>),
        },
      )}
    </>
  )
}
