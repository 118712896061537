import { ExternalProvider, Web3Provider } from "@ethersproject/providers"
import { Signer, utils } from "ethers"
import { noop } from "lodash"
import { computed, makeObservable } from "mobx"
import { Observable } from "rxjs"
import { ALLOW_CONTRACT_ARGUMENTATION } from "../../config"
import { ERC20__factory } from "../../generated/evmContract"
import { CancelError } from "../../utils/error"
import { isPromiseLike } from "../../utils/promiseHelpers"
import { assertNever } from "../../utils/types"
import { safelyGet, waitFor, waitForNever$ } from "../../utils/waitFor"
import { LazyValue } from "../LazyValue/LazyValue"
import { SuspenseObservable } from "../SuspenseObservable"
import { AppEnvStore } from "../appEnvStore/AppEnvStore"
import { ETHChain } from "../appEnvStore/ETHChain"
import { ETHCurrency } from "../appEnvStore/ETHCurrency"
import {
  getMetaMaskEthereumProvider,
  type MetaMaskEthereumProvider,
} from "./MetaMaskEthereumProvider/MetaMaskEthereumProvider"
import { isMayBeProviderRpcError } from "./MetaMaskEthereumProvider/MetaMaskEthereumProviderBasic"
import {
  getIsPreviouslyConnected,
  setIsPreviouslyConnected,
} from "./MetaMaskEthereumProvider/connectedProvider"
import {
  TransferResponse,
  connectedChain,
  connectedWalletAddress,
  currentBlockNumber,
  getERC20TokenBalance,
  getSignTypedDataFn,
  switchToChain,
  switchToChainSimply,
  transferERC20Token,
} from "./MetaMaskModule.service"
import { ethChainToAddEthereumChainParameter } from "./_/ETHChainInfo"

export class MetaMaskModule {
  constructor(private appEnv: AppEnvStore) {
    makeObservable(this)
    void this.autoReconnect()
  }

  metaMaskEthereumProvider = new SuspenseObservable<MetaMaskEthereumProvider>()

  connectedChain = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    ([p]) => connectedChain(p),
  )

  connectedWalletAddress = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    ([p]) =>
      new Observable<string>(ob => {
        const sub = connectedWalletAddress(p).subscribe({
          ...ob,
          next(v) {
            if (v == null) return
            ob.next(v)
          },
        })
        return () => {
          sub.unsubscribe()
        }
      }),
  )
  @computed get connectedWalletAddress$(): string {
    const asAddress = ALLOW_CONTRACT_ARGUMENTATION
      ? new URLSearchParams(window.location.search).get("asEthAddress")
      : undefined
    return asAddress || this.connectedWalletAddress.value$
  }
  @computed get isWalletConnected(): boolean {
    return safelyGet(() => this.connectedWalletAddress$) != null
  }

  currentBlockNumber = new LazyValue(
    () => [this.metaMaskEthereumProvider.read$] as const,
    ([p]) => currentBlockNumber(p),
  )

  getErc20ContractAddr$(chain: ETHChain, currency: ETHCurrency): string {
    const errMsg = `${currency} is not supported on chain: ${chain}`

    const addrs = this.appEnv.ethContractAddress$

    let addr: undefined | string
    switch (currency) {
      case ETHCurrency.USDT:
        addr = addrs.usdt[chain]
        break
      case ETHCurrency.LUNR:
        addr = addrs.lunr[chain]
        break
      case ETHCurrency.USDC:
        addr = addrs.usdc[chain]
        break
      case ETHCurrency.WBTC:
        addr = addrs.wbtc[chain]
        break
      default:
        assertNever(currency, new Error(errMsg))
    }

    if (addr == null) {
      console.error(errMsg)
      return waitForNever$()
    }

    return addr
  }

  getEndpointContractAddr$(chain: ETHChain): string {
    const addr = this.appEnv.ethContractAddress$.bridgeEndpoint[chain]
    const errMsg = `Bridge endpoint is not supported on chain: ${chain}`

    if (addr == null) {
      console.error(errMsg)
      return waitForNever$()
    }

    return addr
  }

  usdcBalance = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.getErc20ContractAddr$(
          this.connectedChain.value$.chain,
          ETHCurrency.USDC,
        ),
        this.connectedWalletAddress$,
      ] as const,
    ([p, contract, addr]) =>
      getERC20TokenBalance(
        p,
        ERC20__factory.connect(contract, this.provider$),
        addr,
      ),
  )

  usdtBalance = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.getErc20ContractAddr$(
          this.connectedChain.value$.chain,
          ETHCurrency.USDT,
        ),
        this.connectedWalletAddress$,
      ] as const,
    ([p, contract, addr]) =>
      getERC20TokenBalance(
        p,
        ERC20__factory.connect(contract, this.provider$),
        addr,
      ),
  )

  lunrBalance = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.getErc20ContractAddr$(
          this.connectedChain.value$.chain,
          ETHCurrency.LUNR,
        ),
        this.connectedWalletAddress$,
      ] as const,
    ([p, contract, addr]) =>
      getERC20TokenBalance(
        p,
        ERC20__factory.connect(contract, this.provider$),
        addr,
      ),
  )

  wbtcBalance = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.getErc20ContractAddr$(
          this.connectedChain.value$.chain,
          ETHCurrency.WBTC,
        ),
        this.connectedWalletAddress$,
      ] as const,
    ([p, contract, addr]) =>
      getERC20TokenBalance(
        p,
        ERC20__factory.connect(contract, this.provider$),
        addr,
      ),
  )

  @computed get isConnected(): boolean {
    return this.metaMaskEthereumProvider.get() != null
  }

  async isAuthorized(): Promise<boolean> {
    const provider = await getMetaMaskEthereumProvider()
    if (provider == null) return false
    const accounts = await provider.request({ method: "eth_accounts" })
    return accounts.length > 0
  }

  private async autoReconnect(): Promise<void> {
    // only reconnect if:
    if (
      // user has previously connected on our website, and
      getIsPreviouslyConnected() &&
      // wallet indeed has authorized address(es) (user can disconnect manually/changed wallet seed)
      (await this.isAuthorized())
    ) {
      await this.connectImpl()
    }
  }

  private async connectImpl(): Promise<void> {
    try {
      const provider = await getMetaMaskEthereumProvider()
      if (provider == null) return
      await provider.request({ method: "eth_requestAccounts" })
      this.metaMaskEthereumProvider.set(provider)
    } catch (e) {
      if (isPromiseLike(e)) {
        return
      }

      if (isMayBeProviderRpcError(e) && e.code === 4001) {
        throw new CancelError()
      }

      throw e
    }
  }

  @computed get provider$(): Web3Provider {
    if (this.connectedChain.value$) {
      // do nothing
      // we need to recreate provider every time chain is changed
      // otherwise the provider will throw an "network changed" error
    }
    return new Web3Provider(
      this.metaMaskEthereumProvider.read$ as ExternalProvider,
    )
  }

  @computed get signer$(): Signer {
    return this.provider$.getSigner()
  }

  connect = async (): Promise<void> => {
    await this.connectImpl()
    setIsPreviouslyConnected(true)
  }

  disconnect = async (): Promise<void> => {
    this.metaMaskEthereumProvider.set(undefined)
    setIsPreviouslyConnected(false)
  }

  async switchToChain$(chain: ETHChain): Promise<void> {
    const provider = await waitFor(() => this.metaMaskEthereumProvider.read$)
    return this.switchToChainFactory(provider)(chain)
  }

  private switchToChainFactory(provider: MetaMaskEthereumProvider) {
    return async (chain: ETHChain) => {
      const parameter = ethChainToAddEthereumChainParameter(chain)
      if (parameter == null) {
        throw new Error(`[switchToChain] Unsupported chain: ${chain}`)
      }

      if (typeof parameter === "string") {
        await switchToChainSimply(provider, parameter)
      } else {
        await switchToChain(provider, parameter)
      }
    }
  }

  async transferEth(
    toWalletAddress: string,
    amount: number,
  ): Promise<TransferResponse> {
    const res = await this.signer$.sendTransaction({
      to: toWalletAddress,
      value: utils.parseEther(amount.toString()),
    })
    return {
      hash: res.hash,
      waitConfirmations: () => res.wait().then(noop),
    }
  }

  transfer = new LazyValue(
    () => [this.connectedChain.value$, this.connectedWalletAddress$] as const,
    async ([chain, addr]) => this.transferFactory(chain.chain, addr),
  )

  private transferFactory(
    chain: ETHChain,
    fromWalletAddress: string,
  ): TransferFn {
    return async (tokenContractAddr, toWalletAddress, amount) => {
      try {
        return await transferERC20Token(
          this.signer$,
          tokenContractAddr,
          chain,
          fromWalletAddress,
          toWalletAddress,
          amount,
        )
      } catch (e) {
        if (isMayBeProviderRpcError(e) && e.code === 4001) {
          throw new CancelError()
        }

        throw e
      }
    }
  }

  signTypedData = new LazyValue(
    () =>
      [
        this.metaMaskEthereumProvider.read$,
        this.connectedChain.value$,
      ] as const,
    async ([p, c]) => getSignTypedDataFn(p, c.chainId),
  )
}

export type TransferFn = (
  tokenContractAddr: string,
  toWalletAddress: string,
  amount: number,
) => Promise<TransferResponse>
