import { action, computed, makeObservable, observable } from "mobx"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { asyncAction, runAsyncAction } from "../../../../utils/asyncAction"
import { Result } from "../../../../utils/Result"
import {
  RemoveLiquidityFormError,
  RemoveLiquidityFormErrorType,
} from "../types"
import PoolDetailStore from "./PoolDetailStore"
import { removeLiquidity } from "./RemoveLiquidityModule.services"

export type RemoveLiquidityFormData = {
  amount: number
  percentage: number
}

export class RemoveLiquidityModule {
  constructor(readonly store: PoolDetailStore) {
    makeObservable(this)
  }

  @observable percentageToRemove = 0

  @computed get balance$(): number {
    return this.store.myLiquidity.pooledAmount$
  }

  @computed get amountToRemove$(): number {
    return (
      Math.floor(
        (this.balance$ === 0 ? 0.1 : this.balance$) *
          this.percentageToRemove *
          1e8,
      ) / 1e8
    )
  }

  @computed get poolTokenEstUSD$(): number {
    return (
      this.store.currencyStore.getPrice$(this.store.poolToken) *
      this.amountToRemove$
    )
  }

  @action setAmountToRemove(amount: number): void {
    this.percentageToRemove =
      amount / (this.balance$ === 0 ? 0.1 : this.balance$)
  }

  @computed get formData$(): Result<
    RemoveLiquidityFormData,
    RemoveLiquidityFormError
  > {
    if (this.percentageToRemove === 0) {
      return Result.error<RemoveLiquidityFormError>({
        type: RemoveLiquidityFormErrorType.EmptyTokenCount,
      })
    }
    if (this.percentageToRemove > 1) {
      return Result.error<RemoveLiquidityFormError>({
        type: RemoveLiquidityFormErrorType.InsufficientTokenBalance,
      })
    }
    return Result.ok({
      amount: this.amountToRemove$,
      percentage: this.percentageToRemove,
    })
  }

  @observable confirming = false
  removeTransaction = new ConfirmTransactionStore()

  @asyncAction
  async removeLiquidity(
    form: RemoveLiquidityFormData,
    run = runAsyncAction,
  ): Promise<void> {
    this.confirming = false
    try {
      const txId = await run(
        removeLiquidity(
          this.store.authStore.stxAddress$,
          this.store.poolToken,
          form.amount,
          form.percentage * 1e8,
        ),
      )
      this.removeTransaction.successRunning(txId)
    } catch (e) {
      this.removeTransaction.errorRunning(e as Error)
    }
  }
}
