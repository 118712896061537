import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  Card,
  CardDivider,
  CardInset,
  CardTitle,
} from "../../../../../components/Card"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import { IconTokenCount } from "../../../../../components/RichTokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"

export const MaturedTokensCard: FC<{
  className?: string
  lpToken: TokenInfo
  lpTokenCount: number
  lpLeftToken: TokenInfo
  lpLeftTokenCount: number
  lpRightToken: TokenInfo
  lpRightTokenCount: number
}> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx("flex flex-col gap-2.5", props.className)}>
      <CardTitle>
        {$t(
          defineMessage({
            defaultMessage: "Expiring LP tokens",
            description: "/Farm/Matured tokens card title",
          }),
        )}
      </CardTitle>
      <IconTokenCount
        iconSize={40}
        className={"self-center text-3xl font-medium"}
        tokenNameClassName={"text-xl"}
        token={props.lpToken}
        count={props.lpTokenCount}
      />

      <CardInset boxClassName={"p-[10px] mt-[20px]"}>
        <div className={"relative w-full flex justify-center"}>
          <svg
            className={"absolute -top-[30px]"}
            width="40"
            height="20"
            viewBox="0 0 40 20"
            fill="black"
            fillOpacity="0.2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 0L40 20H0L20 0Z" />
          </svg>
        </div>

        <InfoList
          className={"flex gap-[48px]"}
          direction={"row"}
          listItemDirection={"column"}
          listItemClassName={"flex-1 items-center gap-1"}
        >
          <InfoListItem>
            <InfoListItemTitle>
              <TokenName token={props.lpLeftToken} />
            </InfoListItemTitle>
            <InfoListItemDetail>
              <IconTokenCount
                inline={false}
                iconSize={32}
                className={"text-2xl font-medium"}
                tokenNameClassName={"sr-only"}
                token={props.lpLeftToken}
                count={props.lpLeftTokenCount}
              />
            </InfoListItemDetail>
          </InfoListItem>

          <CardDivider direction={"vertical"} />

          <InfoListItem>
            <InfoListItemTitle>
              <TokenName token={props.lpRightToken} />
            </InfoListItemTitle>
            <InfoListItemDetail>
              <IconTokenCount
                inline={false}
                iconSize={32}
                className={"text-2xl font-medium"}
                tokenNameClassName={"sr-only"}
                token={props.lpRightToken}
                count={props.lpRightTokenCount}
              />
            </InfoListItemDetail>
          </InfoListItem>
        </InfoList>
      </CardInset>
    </Card>
  )
}
