import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { BlockTimeCountdown } from "../../../components/TimeCountdown/BlockTimeCountdown"
import { Tooltip } from "../../../components/Tooltip/Tooltip"

export const StepEndCountdown: FC<{
  className?: string
  currentStepNumber: number
  currentBlock: number
  currentStepEndedAt: Date
  endBlock: number
}> = props => {
  const { $t } = useIntl()
  return (
    <Tooltip
      title={$t(
        defineMessage({
          defaultMessage:
            "Current Block: {currentBlock}{breakLine}Step {currentStepNumber} Ended At: Block {endBlock}",
          description: "/Launchpad/Step end countdown tooltip",
        }),
        {
          breakLine: <br />,
          currentBlock: props.currentBlock,
          currentStepNumber: props.currentStepNumber,
          endBlock: props.endBlock,
        },
      )}
    >
      <BlockTimeCountdown
        prefix={$t(
          defineMessage({
            defaultMessage: "Step Countdown",
            description: "/Launchpad/Step end countdown",
          }),
        )}
        currentBlock={props.currentBlock}
        targetBlock={props.endBlock}
        time={props.currentStepEndedAt}
      />
    </Tooltip>
  )
}
