import { isStacksWalletInstalled } from "@stacks/connect"
import { action } from "mobx"
import { FC, Suspense, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useMatch } from "react-router-dom"
import { connectWallet$t } from "../../commonIntlMessages"
import { PathGenerator, RoutePath, usePathGenerator } from "../../routes/routes"
import { useAccountStore } from "../../stores/accountStore/useAccountStore"
import { AppConfigs } from "../../stores/appEnvStore/appEnv.services"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { OKXWalletAuthSession } from "../../stores/authStore/OKXWalletAuthSession"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../stores/currencyStore/useCurrencyStore"
import { useNotifyStore } from "../../stores/notifyStore/useNotifyStore"
import { suspenseResource } from "../../utils/SuspenseResource"
import { Currency } from "../../utils/alexjs/Currency"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { OneOrMore } from "../../utils/types"
import { isNotNull } from "../../utils/utils"
import { CardModalContent } from "../CardModal/CardModal"
import { useConnect } from "../ConnectWallet/ConnectProvider"
import { DropdownContentInnerContainer, DropdownItem } from "../Dropdown"
import { useLocaleSwitch } from "../IntlProvider/LocaleSwitchProvider"
import { Modal } from "../Modal"
import { NavLink } from "../NavLink"
import { Spensor } from "../Spensor"
import { NavBarButtonItemData, NavBarItemData } from "./NavBarItem"
import { NotificationButton } from "./NotificationButton/NotificationButton"
import { TopNavBar, _BalanceButton } from "./TopNavBar"
import {
  WiredTrivialButton$Connected,
  WiredTrivialButton$Disconnected,
} from "./TrivialButton/WiredTrivialButton"
import { BalanceButtonProps } from "./_/BalanceButton"
import { ReactComponent as OKXIcon } from "./_/OKX_Logo.svg"
import { ReactComponent as HiroIcon } from "./_/hiro.svg"
import { ReactComponent as XverseIcon } from "./_/xverse.svg"

const WiredBalanceButton: FC<{
  onClick?: BalanceButtonProps["onClick"]
}> = props => {
  const currencyStore = useCurrencyStore()
  const [token, setToken] = useState<TokenInfo>(
    currencyStore.getTokenInfo$(Currency.W_STX),
  )
  const accStore = useAccountStore()

  const isInOrderBook = useMatch(RoutePath.OrderbookDetail)

  if (isInOrderBook) {
    return null
  }

  const balance = accStore.allTokensWithBalanceForBalanceBar$.find(
    t => t.token.id === token.id,
  )?.balance

  return (
    <_BalanceButton
      availableTokens={accStore.allTokensWithBalanceForBalanceBar$.map(
        t => t.token,
      )}
      balance={balance ?? 0}
      selectedToken={token}
      onSelectToken={setToken}
      onClick={props.onClick}
    />
  )
}

const WiredNotificationBelt: FC = () => {
  const gen = usePathGenerator()
  const notifyStore = useNotifyStore()
  const isInOrderBook = useMatch(RoutePath.OrderbookDetail)

  if (isInOrderBook) {
    return null
  }

  return (
    <NavLink to={gen.notify()}>
      <Spensor fallback={<NotificationButton className="align-bottom" />}>
        {() => (
          <NotificationButton
            className="align-bottom"
            count={notifyStore.systemMessages.count$}
          />
        )}
      </Spensor>
    </NavLink>
  )
}

export const WiredTopNavBar: FC<{
  onHeightChanged?: (newHeight: number) => void
}> = props => {
  const env = useAppEnvStore()
  const [languageSelectorVisibility, setLanguageSelectorVisibility] =
    useState(false)

  return (
    <>
      <TopNavBar
        className={"relative z-1"}
        buttons={suspenseResource(() =>
          getAvailableNavItemFromConfig(env.config$),
        )}
        rightSide={
          <Suspense fallback={<WiredTrivialButton$Disconnected />}>
            <Suspense fallback={null}>
              <WiredBalanceButton />
            </Suspense>
            <WiredTrivialButton$Connected />
            <WiredNotificationBelt />
          </Suspense>
        }
        onShowLanguageModal={
          !env.config$.enableLanguageSelector
            ? undefined
            : () => {
                setLanguageSelectorVisibility(true)
              }
        }
        onHeightChanged={props.onHeightChanged}
      />
      <Modal
        visible={languageSelectorVisibility}
        onClose={() => setLanguageSelectorVisibility(false)}
      >
        <LanguageSelector
          onSelected={() => setLanguageSelectorVisibility(false)}
        />
      </Modal>
      <WiredWalletSelector />
    </>
  )
}

const WiredWalletSelector: FC = () => {
  const [connect] = useConnect()
  const auth = useAuthStore()
  const { $t } = useIntl()
  const onClose = action(() => (auth.isConnectingWallet = false))
  return (
    <Modal visible={auth.isConnectingWallet} onClose={onClose}>
      <CardModalContent
        className={"w-[480px] text-white font-semibold"}
        title={$t(connectWallet$t)}
        onClose={onClose}
      >
        <button
          disabled={!isStacksWalletInstalled()}
          className="flex items-center justify-start gap-x-4 px-6 py-4 rounded-lg border border-gray-700 hover:bg-white/10"
          onClick={() => connect("browser")}
        >
          <HiroIcon />
          <div className="flex flex-col items-start">
            {$t(
              defineMessage({
                defaultMessage: "Hiro Wallet",
                description: "WalletSelector",
              }),
            )}
            {!isStacksWalletInstalled() && (
              <span className="text-xs text-gray-500">
                {$t(
                  defineMessage({
                    defaultMessage: "Not installed",
                    description: "WalletSelector",
                  }),
                )}
              </span>
            )}
          </div>
        </button>
        <button
          disabled={!isStacksWalletInstalled()}
          className="flex items-center justify-start gap-x-4 px-6 py-4 rounded-lg border border-gray-700 hover:bg-white/10"
          onClick={() => connect("browser")}
        >
          <XverseIcon />
          <div className="flex flex-col items-start">
            {$t(
              defineMessage({
                defaultMessage: "Xverse Wallet",
                description: "WalletSelector",
              }),
            )}
            {!isStacksWalletInstalled() && (
              <span className="text-xs text-gray-500">
                {$t(
                  defineMessage({
                    defaultMessage: "Not installed",
                    description: "WalletSelector",
                  }),
                )}
              </span>
            )}
          </div>
        </button>
        <button
          className="flex items-center justify-start gap-x-4 px-6 py-4 rounded-lg border border-gray-700 hover:bg-white/10"
          onClick={() => connect("wallet-connect")}
        >
          <XverseIcon />
          <div className="flex flex-col items-start">
            {$t(
              defineMessage({
                defaultMessage: "Xverse Wallet Mobile",
                description: "WalletSelector",
              }),
            )}
            <span className="text-xs text-gray-500">
              {$t(
                defineMessage({
                  defaultMessage: "via Wallet Connect",
                  description: "WalletSelector",
                }),
              )}
            </span>
          </div>
        </button>
        <button
          className="flex items-center justify-start gap-x-4 px-6 py-4 rounded-lg border border-gray-700 hover:bg-white/10"
          onClick={() => {
            if (OKXWalletAuthSession.isAvailable()) {
              connect("okx-wallet")
            } else {
              window.open("https://okx.com/web3", "_blank")
            }
          }}
        >
          <OKXIcon />
          <div className="flex flex-col items-start">
            {$t(
              defineMessage({
                defaultMessage: "OKX Wallet",
                description: "WalletSelector",
              }),
            )}
            {!OKXWalletAuthSession.isAvailable() && (
              <span className="text-xs text-gray-500">
                {$t(
                  defineMessage({
                    defaultMessage: "Not installed",
                    description: "WalletSelector",
                  }),
                )}
              </span>
            )}
          </div>
        </button>
      </CardModalContent>
    </Modal>
  )
}

const LanguageSelector: FC<{
  onSelected: () => void
}> = props => {
  const { currentLocale, availableLocales, onLocaleSwitch } = useLocaleSwitch()

  return (
    <DropdownContentInnerContainer>
      {availableLocales.map((l, idx) => (
        <DropdownItem
          key={idx}
          selected={l.languageTag === currentLocale.languageTag}
          onClick={() => {
            onLocaleSwitch(l)
            props.onSelected()
          }}
        >
          {l.displayName}
        </DropdownItem>
      ))}
    </DropdownContentInnerContainer>
  )
}

function getAvailableNavItemFromConfig(config: AppConfigs): NavBarItemData[] {
  const buttons: (NavBarItemData | null)[] = [
    {
      type: "dropdown",
      name: "Swap",
      buttons: [
        config.enableSwapFeature
          ? { link: (g: PathGenerator) => g.swap(), name: "Swap" }
          : null,
        config.enablePoolFeature
          ? { link: (g: PathGenerator) => g.poolList(), name: "Pool" }
          : null,
      ].filter(isNotNull) as OneOrMore<NavBarButtonItemData>,
    },
    config.enableBrc20Feature
      ? { link: g => g.orderbook(), name: "B20 (Orderbook)", highlighted: true }
      : null,
    {
      type: "dropdown",
      name: "Earn",
      buttons: [
        config.enableFarmFeature
          ? { link: (g: PathGenerator) => g.farmList(), name: "Farm" }
          : null,
        config.enableStakeFeature
          ? { link: (g: PathGenerator) => g.stake(), name: "Stake" }
          : null,
        config.enableLotteryFeature
          ? { link: (g: PathGenerator) => g.lottery(), name: "Lottery" }
          : null,
        config.enableLendFeature
          ? { link: (g: PathGenerator) => g.lend(), name: "Lend" }
          : null,
      ].filter(isNotNull) as OneOrMore<NavBarButtonItemData>,
    },
    config.enableWrapBridgeFeature
      ? { link: g => g.wrapBridge(), name: "Bridge" }
      : null,
    config.enablePerpetualFeature
      ? { link: g => g.perpetual(), name: "Perpetual (Testnet)" }
      : null,
    config.enableLaunchPadFeature
      ? { link: (g: PathGenerator) => g.launchpad(), name: "Launchpad" }
      : null,
  ]
  return buttons.filter(isNotNull)
}
