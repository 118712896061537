import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useChainStore } from "../../../stores/chainStore/useChainStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { createStore } from "../../../utils/createStore"
import { FCC } from "../../../utils/reactHelpers/types"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import { useStxDxStore } from "../../Orderbook/store/stxdx_shared/useStxDxStore"
import { PerpetualStore } from "./PerpetualStore"

const Store = createStore<PerpetualStore>("PerpetualStore")

export const PerpetualStoreProvider: FCC = props => {
  const chain = useChainStore()
  const auth = useAuthStore()
  const currency = useCurrencyStore()
  const account = useAccountStore()
  const stxDx = useStxDxStore()
  const store = useCreation(
    () => new PerpetualStore(chain, auth, currency, account, stxDx),
    [account, auth, chain, currency, stxDx],
    store => store.destroy(),
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const usePerpetualStore = Store.useStore.bind(null)
