import { FC, ReactNode } from "react"
import { createStore } from "../../utils/createStore"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useAppEnvStore } from "../appEnvStore/useAppEnvStore"
import { ChainStore } from "./ChainStore"

const { Provider, useStore } = createStore<ChainStore>("ChainStore")

export const ChainStoreProvider: FC<{
  children: ReactNode
}> = props => {
  const appEnv = useAppEnvStore()
  const store = useCreation(() => new ChainStore(appEnv), [appEnv])
  return <Provider store={store}>{props.children}</Provider>
}

export const useChainStore = useStore
