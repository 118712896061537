import clsx from "clsx"
import { last } from "lodash"
import { FC } from "react"
import { useContextInComponent } from "./PageStackContext"

export const PageStackNavBar: FC<{ className?: string }> = props => {
  const { pageStack } = useContextInComponent("PageStackNavBar")

  const currPage = last(pageStack)

  return (
    <div
      className={clsx(
        "flex flex-wrap gap-2 items-center justify-between",
        props.className,
      )}
    >
      {currPage?.title}
    </div>
  )
}
