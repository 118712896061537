import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardModalContent } from "../../../../../components/CardModal/CardModal"
import { NavLink } from "../../../../../components/NavLink"
import {
  SuspenseResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { SelectedListItem } from "../_/SelectedListItem"
import { SelectedListTitle } from "../_/SelectedListTitle"
import { ReactComponent as Brc20Icon } from "../_/brc20.svg"
import { ReactComponent as BridgeIcon } from "../_/bridge.svg"
import { ReactComponent as SUSDTIcon } from "./_/depositSUSDT.svg"

export const DepositEntryModalContent: FC<{
  bridgeInEthereumTokensLink: SuspenseResource<string>
  pegInAction:
    | {
        pegInBrc20TokensLink: SuspenseResource<string>
        onDepositBrc20Tokens?: undefined
      }
    | {
        pegInBrc20TokensLink?: undefined
        onDepositBrc20Tokens: () => void
      }
  onGeneratePegInBtcAddress: () => Promise<void>
  onDepositStxTokens: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      className="flex flex-col gap-4"
      width={480}
      title={$t(
        defineMessage({
          defaultMessage: "Deposit",
          description: "/OrderBook/DepositEntryModalContent/Title text",
        }),
      )}
      onClose={props.onClose}
    >
      <SelectedListTitle>
        <p>
          {$t(
            defineMessage({
              defaultMessage: "Deposit Assets into your Trading Account",
              description:
                "/OrderBook/DepositEntryModalContent/Description text",
            }),
          )}
        </p>
        {props.pegInAction.onDepositBrc20Tokens != null && (
          <p>
            <NavLink
              className={"text-blue-600"}
              to={$t(
                defineMessage({
                  defaultMessage:
                    "https://perfect-bulb-261.notion.site/Peg-in-Tutorial-9578442e99664f22a9efe7c26051e4d4",
                  description:
                    "/OrderBook/DepositEntryModalContent/Link to peg-in tutorial",
                }),
              )}
            >
              {$t(
                defineMessage({
                  defaultMessage: "Peg-in tutorial >",
                  description:
                    "/OrderBook/DepositEntryModalContent/Title of Link to peg-in tutorial",
                }),
              )}
            </NavLink>
          </p>
        )}
      </SelectedListTitle>
      <div className="space-y-2.5">
        {props.pegInAction.pegInBrc20TokensLink != null ? (
          <NavLink
            to={safeReadResource(props.pegInAction.pegInBrc20TokensLink) ?? ""}
          >
            <SelectedListItem
              icon={<Brc20Icon />}
              arrowDirection="topRight"
              title={$t(
                defineMessage({
                  defaultMessage: "Peg-in BRC-20 from BTC Chain",
                  description:
                    "/OrderBook/DepositEntryModalContent/Selected title text",
                }),
              )}
              description={$t(
                defineMessage({
                  defaultMessage: "Deposit your BRC-20 to your Trading Account",
                  description:
                    "/OrderBook/DepositEntryModalContent/Selected description text",
                }),
              )}
            />
          </NavLink>
        ) : (
          <SelectedListItem
            icon={<Brc20Icon />}
            arrowDirection="right"
            title={$t(
              defineMessage({
                defaultMessage: "Peg-in BRC-20 from BTC Chain",
                description:
                  "/OrderBook/DepositEntryModalContent/Selected title text",
              }),
            )}
            description={$t(
              defineMessage({
                defaultMessage: "Deposit your BRC-20 to your Trading Account",
                description:
                  "/OrderBook/DepositEntryModalContent/Selected description text",
              }),
            )}
            onClick={props.pegInAction.onDepositBrc20Tokens}
          />
        )}

        <SelectedListItem
          arrowDirection="right"
          icon={<SUSDTIcon />}
          title={$t(
            defineMessage({
              defaultMessage: "Deposit SIP-010 & BRC-20",
              description:
                "/OrderBook/DepositEntryModalContent/Selected title text",
            }),
          )}
          description={$t(
            defineMessage({
              defaultMessage:
                "Deposit your SIP-010 & BRC-20 to your Trading Account",
              description:
                "/OrderBook/DepositEntryModalContent/Selected description text",
            }),
          )}
          onClick={props.onDepositStxTokens}
        />
      </div>
      <div className="space-y-2.5 mt-3.5">
        <SelectedListTitle className="mb-2.5">
          {$t(
            defineMessage({
              defaultMessage: "Bridge Assets to Stacks Chain",
              description:
                "/OrderBook/DepositEntryModalContent/Description text",
            }),
          )}
        </SelectedListTitle>
        <div className="space-y-2.5">
          <NavLink
            to={safeReadResource(props.bridgeInEthereumTokensLink) ?? ""}
            target="_blank"
          >
            <SelectedListItem
              icon={<BridgeIcon />}
              arrowDirection="topRight"
              title={$t(
                defineMessage({
                  defaultMessage: "Bridge USDT to sUSDT",
                  description:
                    "/OrderBook/DepositEntryModalContent/Selected title text",
                }),
              )}
              description={$t(
                defineMessage({
                  defaultMessage:
                    "Bridge your USDT to Stacks from BNB/Ethereum.",
                  description:
                    "/OrderBook/DepositEntryModalContent/Selected description text",
                }),
              )}
            />
          </NavLink>
        </div>
      </div>
    </CardModalContent>
  )
}
