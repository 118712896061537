import { mapValues, noop } from "lodash"
import { FC, useEffect, useMemo, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Modal } from "../../../../components/Modal"
import {
  TokenNameBRC20Badge,
  TokenNameBRC20Decoration,
} from "../../../../components/TokenName"
import { suspenseResource } from "../../../../utils/SuspenseResource"
import { waitFor, waitUntilExist$ } from "../../../../utils/waitFor"
import {
  AvailableWalletType,
  SelectOridinalsWalletModalContent,
} from "../components/SelectOridinalsWalletModalContent/SelectOridinalsWalletModalContent"
import { WalletConnectionLine } from "../components/WalletConnectionLine"
import { useOrderbookPegInStore } from "../stores/useOrderbookPegInStore"

export const WiredOrdinalsWalletConnectionLine: FC<{
  className?: string
}> = props => {
  const pegInStore = useOrderbookPegInStore()
  const { $t } = useIntl()

  const [walletSelectModalVisible, setWalletSelectModalVisible] =
    useState(false)

  const availableWallets = useMemo(
    () =>
      suspenseResource(() =>
        mapValues(
          pegInStore.availableBtcWalletAdapters.value$,
          (adapter, type: AvailableWalletType) =>
            adapter == null
              ? noop
              : async () => {
                  await pegInStore.connectBtcWallet(type, adapter)
                  setWalletSelectModalVisible(false)
                },
        ),
      ),
    [pegInStore],
  )

  useEffect(() => {
    const abortCtrl = new AbortController()

    const previousType = pegInStore.previousConnectedWallet
    if (previousType != null) {
      void waitFor(() =>
        waitUntilExist$(
          () => pegInStore.availableBtcWalletAdapters.value$[previousType],
        ),
      ).then(adapter => {
        if (pegInStore.isConnected) return
        if (abortCtrl.signal.aborted) return
        return pegInStore.connectBtcWallet(previousType, adapter)
      })
    }

    return () => {
      abortCtrl.abort()
    }
  }, [pegInStore])

  return (
    <>
      <WalletConnectionLine
        className={props.className}
        labelText={$t(
          defineMessage({
            defaultMessage: "Your ordinals address:",
            description:
              "Orderbook Peg-In Page/Ordinals Wallet Connection Info/label",
          }),
        )}
        wallet={suspenseResource(() => ({
          walletAddress: (
            <>
              <TokenNameBRC20Decoration>
                {pegInStore.bitcoinWalletAddresses$.ordinals[0].address}
              </TokenNameBRC20Decoration>
              &nbsp;
              <TokenNameBRC20Badge />
            </>
          ),
          onDisconnect: () => pegInStore.disconnectBtcWallet(),
        }))}
        onConnectWallet={() => setWalletSelectModalVisible(true)}
      />

      <Modal
        visible={walletSelectModalVisible}
        onClose={() => setWalletSelectModalVisible(false)}
      >
        <SelectOridinalsWalletModalContent
          availableWallets={availableWallets}
          onClose={() => setWalletSelectModalVisible(false)}
        />
      </Modal>
    </>
  )
}
