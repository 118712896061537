import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { NavLink } from "../../../components/NavLink"
import { NoteParagraph } from "../../../components/NoteParagraph/NoteParagraph"
import { BlockTopLine } from "../../../components/TokenInput/Block"
import { TokenBlock } from "../../../components/TokenInput/TokenBlock"
import { dontWrapObserver } from "../../../utils/mobxHelpers"
import { TokenInfo } from "../../../utils/models/TokenInfo"

export interface ConfirmClaimCoFarmModalContentProps {
  claimPrincipalToken: TokenInfo
  claimPrincipalAmount: number
  onConfirm?: () => void
  onClose?: () => void
}

export const ConfirmClaimCoFarmModalContent: FC<
  ConfirmClaimCoFarmModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent
      onClose={props.onClose}
      title={$t(
        defineMessage({
          defaultMessage: "Confirm Co-Farm LP",
          description: "/Farm/Confirm co farm modal content title",
        }),
      )}
      className="flex flex-col gap-4"
      width={480}
    >
      <TokenBlock
        topArea={
          <BlockTopLine className="flex flex-row gap-x-2.5">
            <span>
              {$t(
                defineMessage({
                  defaultMessage: "Eligible STX to claim",
                  description: "/Farm/Confirm co farm modal content",
                }),
              )}
            </span>
          </BlockTopLine>
        }
        tokens={[
          {
            token: props.claimPrincipalToken,
            count: props.claimPrincipalAmount,
          },
        ]}
      />
      <div className="flex flex-col gap-1">
        {$t(
          defineMessage({
            defaultMessage: `<line>You will receive the amount of STX you committed to Co-Farm</line>
              <line>ALEX rewards will automatically be sent to your wallet address after each cycle. <span>more rules ></span></line>`,
            description: "/Farm/Confirm co farm modal content",
          }),
          {
            line: dontWrapObserver(children => (
              <NoteParagraph textSizingClassName={"text-sm"}>
                {children}
              </NoteParagraph>
            )),
            span: dontWrapObserver(children => (
              <NavLink
                className="text-blue-600"
                to="https://medium.com/alexgobtc/co-farming-with-alex-571000bc6d5a"
              >
                {children}
              </NavLink>
            )),
          },
        )}
      </div>
      <div className={"flex flex-row gap-2.5 pt-4"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onClose}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Farm/Confirm co farm modal content",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Farm/Confirm co farm modal content",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
