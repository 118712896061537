import clsx from "clsx"
import { ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../../../../components/Card"
import { FCC } from "../../../../../../utils/reactHelpers/types"

export const IDODetailSidePanelFrame: FCC<{
  className?: string
  titleRightSide?: ReactNode
}> = props => {
  const { $t } = useIntl()
  return (
    <Card className={clsx("flex flex-col gap-6", props.className)}>
      <div className="flex flex-row items-center justify-between">
        <CardTitle>
          {$t(
            defineMessage({
              defaultMessage: "Total Raise",
              description: "/Launchpad/IDO detail side panel frame title",
            }),
          )}
        </CardTitle>
        {props.titleRightSide}
      </div>
      {props.children}
    </Card>
  )
}
