export enum RegisterFormErrorType {
  EmptyTokenCount = "EmptyTokenCount",
  ExceedMaxAllowCount = "ExceedMaxAllowCount",
  InsufficientAPowerBalance = "InsufficientAPowerBalance",
  InsufficientPriceTokenBalance = "InsufficientPriceTokenBalance",
}

export type RegisterFormError = {
  type: RegisterFormErrorType
  message: string
}

export { LotteryTicket } from "./_/LotteryTicketCard/types"
