import { FC } from "react"
import { TokenCount } from "../../../components/TokenCount"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { TokenCountRange as TokenCountRangeType } from "./types"

export interface TokenCountRangeProps {
  token: TokenInfo
  range: TokenCountRangeType
}

export const TokenCountRange: FC<TokenCountRangeProps> = props => {
  return typeof props.range === "number" ? (
    <TokenCount token={props.token} count={props.range} />
  ) : (
    <>
      <TokenCount token={props.token} count={props.range[0]} />
      -
      <TokenCount token={props.token} count={props.range[1]} />
    </>
  )
}
