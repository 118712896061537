import { FC } from "react"
import { TableBodyRow } from "../../../../../components/table/StyledTable"
import { EXPLORER_TX_URL } from "../../../../../config"

export const BaseRowContainer: FC<{
  txId: string
  children?: React.ReactNode
}> = ({ txId, children }) => (
  <TableBodyRow
    className="rounded-lg cursor-pointer hover:bg-black/10 active:bg-black/20"
    onClick={() => {
      // TODO: you can't wrap NavLink inside tr
      window.open(EXPLORER_TX_URL(txId), "_blank")
    }}
  >
    {children}
  </TableBodyRow>
)
