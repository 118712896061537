import clsx from "clsx"
import { FC } from "react"
import { RenderFn } from "../../../utils/reactHelpers/childrenHelpers"

// eslint-disable-next-line @typescript-eslint/ban-types
export type PageFragmentRenderer<P = {}> = RenderFn<{ className: string } & P>

export interface OrderbookPageFrameProps {
  className?: string
  gapClassName?: string
  orderbookList: PageFragmentRenderer
  tradingView: PageFragmentRenderer
  marketSelector: PageFragmentRenderer
  accountInfoPanel: PageFragmentRenderer
  createOrderPanel: PageFragmentRenderer
  tradePointsPanel: PageFragmentRenderer
}

export const OrderbookPageFrame: FC<OrderbookPageFrameProps> = props => {
  const colMaxWidth = 320
  const gapClassName = props.gapClassName ?? "gap-2.5"

  return (
    <div
      className={clsx("grid", gapClassName, props.className)}
      style={{
        gridTemplateColumns: `minmax(auto, ${colMaxWidth}px) minmax(600px, 1fr) minmax(auto, ${colMaxWidth}px)`,
        gridTemplateRows: "500px 1fr",
      }}
    >
      <div className={"col-start-1 col-span-1 row-start-1 row-span-full"}>
        {props.orderbookList({ className: "" })}
      </div>

      <div
        className={clsx(
          "col-start-2 col-span-1 row-start-1 row-span-full flex flex-col",
          gapClassName,
        )}
      >
        {props.tradingView({ className: "h-[373px]" })}

        {props.createOrderPanel({ className: "" })}
      </div>

      <div
        className={clsx(
          "col-start-3 col-span-1 row-start-1 row-span-full flex flex-col",
          gapClassName,
        )}
      >
        {props.marketSelector({ className: "h-[350px]" })}

        {props.accountInfoPanel({ className: "" })}

        {props.tradePointsPanel({ className: "" })}
      </div>
    </div>
  )
}
