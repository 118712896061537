import clsx from "clsx"
import { FC, ReactNode, useState } from "react"
import { CardInset, CardInsetDivider } from "../../../../components/Card"
import { SegmentSwitch } from "../../../../components/SegmentSwitch"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { TokenIDORaiseNumbers } from "../types"
import { OneTrackMode } from "./_/OneTrackMode"
import { TwoTrackMode } from "./_/TwoTrackMode"
import { TrackModeProps } from "./_/types"

export interface IDOProgressProps {
  className?: string
  priceToken: TokenInfo
  idoNumbers: TokenIDORaiseNumbers
  isFinished: boolean
}

export const IDOProgress: FC<IDOProgressProps> = props => {
  const [unitMode, setUnitMode] = useState<"token" | "ticket">("ticket")

  let trackModeProps: TrackModeProps
  if (unitMode === "token") {
    trackModeProps = {
      unitToken: props.priceToken,
      currentRaiseTokenCount: props.idoNumbers.currentRaisePriceTokenCount,
      minRaiseTokenCount: props.idoNumbers.minRaisePriceTokenCount,
      maxRaiseTokenCount: props.idoNumbers.maxRaisePriceTokenCount,
      isFinished: props.isFinished,
    }
  } else {
    trackModeProps = {
      unitToken: {
        ...TokenInfoPresets.IDOLotteryTicket,
        displayName: "Ticket",
      },
      currentRaiseTokenCount: props.idoNumbers.currentRaiseTicketCount,
      minRaiseTokenCount: props.idoNumbers.minRaiseTicketCount,
      maxRaiseTokenCount: props.idoNumbers.maxRaiseTicketCount,
      isFinished: props.isFinished,
    }
  }

  let content: ReactNode
  if (
    props.idoNumbers.minRaiseTicketCount ===
    props.idoNumbers.maxRaiseTicketCount
  ) {
    content = <OneTrackMode {...trackModeProps} />
  } else {
    content = <TwoTrackMode {...trackModeProps} />
  }

  return (
    <CardInset
      className={clsx("flex flex-col items-center gap-2.5", props.className)}
      boxClassName={"p-4"}
    >
      <div className={"w-full my-4"}>{content}</div>

      <CardInsetDivider className={"w-full"} />

      <SegmentSwitch
        availableValues={[
          { label: "Ticket", value: "ticket" },
          { label: "Fund", value: "token" },
        ]}
        value={unitMode}
        onChange={setUnitMode}
      />
    </CardInset>
  )
}
