import clsx from "clsx"
import { FC, ReactNode } from "react"
import { HeadlessButton } from "../button/HeadlessButton"

const itemPadding = 4 * 4

export interface MessageItemProps {
  className?: string
  icon?: ReactNode
  title: ReactNode
  content?: ReactNode
  onClose: () => void
}

export const MessageItem: FC<MessageItemProps> = props => {
  return (
    <div
      className={clsx(
        `flex flex-col gap-2 bg-black/30 rounded-2xl`,
        "max-w-[80vw] min-w-[300px]",
        props.className,
      )}
      style={{
        padding: itemPadding,
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
        backdropFilter: "blur(40px)",
      }}
    >
      <div
        className={clsx(
          "flex items-center gap-2.5",
          "text-base leading-6 font-bold text-gray-200",
        )}
      >
        {props.icon != null && <span className={"shrink-0"}>{props.icon}</span>}

        <div className={"flex-1 whitespace-nowrap truncate"}>{props.title}</div>

        <HeadlessButton
          className={"ml-auto shrink-0"}
          style={{ right: itemPadding }}
          onClick={props.onClose}
        >
          <CloseIcon />
        </HeadlessButton>
      </div>

      {props.content != null && (
        <div
          className={"text-sm leading-5 font-normal text-gray-400 break-words"}
        >
          {props.content}
        </div>
      )}
    </div>
  )
}

export const SuccessIcon: FC = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="#16A34A"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 0a9 9 0 100 18A9 9 0 009 0zm-.938 12.389l-3.374-3.24 1.046-1.076 2.309 2.203 4.579-4.664 1.066 1.057-5.626 5.72z" />
  </svg>
)

export const ErrorIcon: FC = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="#DC2626"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 0a9 9 0 100 18A9 9 0 009 0zm3.448 13.466l-3.444-3.413L5.588 13.5l-1.053-1.054 3.41-3.444L4.5 5.588l1.054-1.053 3.44 3.407 3.41-3.442 1.062 1.052-3.41 3.44 3.444 3.411-1.052 1.063z" />
  </svg>
)

export const WarnIcon: FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#FBBF24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 3.9C18 3.4032 17.5968 3 17.1 3H5.7348C5.3415 3 4.9671 3.1719 4.7106 3.4707C3.7404 4.6011 1.2807 7.4658 0.2169 8.7051C0.0728999 8.8734 0 9.0813 0 9.2901C0 9.4989 0.072 9.7077 0.216 9.876C1.2789 11.118 3.7395 13.9935 4.7106 15.1284C4.9671 15.4281 5.3415 15.6 5.7366 15.6H17.1C17.5968 15.6 18 15.1977 18 14.7C18 12.3807 18 6.2193 18 3.9V3.9ZM10.8081 8.3352L12.3471 6.7953C12.4785 6.6639 12.6522 6.5982 12.825 6.5982C13.1886 6.5982 13.5 6.8898 13.5 7.2723C13.5 7.4469 13.4343 7.6188 13.3029 7.7511L11.763 9.2901L13.3182 10.8453C13.4505 10.9776 13.5162 11.1504 13.5162 11.3232C13.5162 11.7075 13.2021 11.9982 12.8412 11.9982C12.6684 11.9982 12.4956 11.9325 12.3633 11.8011L10.8081 10.2459L9.2529 11.8011C9.1215 11.9325 8.9478 11.9982 8.775 11.9982C8.4141 11.9982 8.1 11.7075 8.1 11.3232C8.1 11.1504 8.1657 10.9776 8.298 10.8453L9.8532 9.2901L8.244 7.6818C8.1126 7.5495 8.0469 7.3776 8.0469 7.2039C8.0469 6.8205 8.3583 6.5289 8.7228 6.5289C8.8956 6.5289 9.0684 6.5946 9.1998 6.726L10.8081 8.3352Z" />
  </svg>
)

export const CloseIcon: FC = () => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.873 7.754l4.919-4.957a.549.549 0 000-.794l-.757-.795a.549.549 0 00-.794 0L8.284 6.165a.366.366 0 01-.53 0L2.797 1.17a.549.549 0 00-.794 0l-.795.795a.549.549 0 000 .794l4.957 4.957a.366.366 0 010 .53L1.17 13.24a.549.549 0 000 .794l.795.795a.549.549 0 00.795 0l4.956-4.957a.366.366 0 01.53 0l4.957 4.957a.549.549 0 00.794 0l.795-.795a.549.549 0 000-.794L9.873 8.284a.366.366 0 010-.53z"
      fill="#C4C4C4"
      opacity={0.44}
    />
  </svg>
)
