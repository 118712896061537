import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../components/Card"
import { PercentNumber } from "../../../components/PercentNumber"
import { TipIcon } from "../../../components/TipIcon/TipIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { LiquidityNumber } from "../../Farm/components/LiquidityNumber"
import { LiquidityInfo } from "../store/detail/PoolDetailStore.services"

const Container = withClassName(
  "border border-gray-700 rounded-lg p-4 flex flex-col gap-2",
  "div",
)
const Title = withClassName("text-sm text-gray-200 text-opacity-50", "p")
const Detail = withClassName("text-2xl font-bold break-all", "p")

const PoolStatsInfo: FC<
  LiquidityInfo & { className?: string; tokenX: TokenInfo; tokenY: TokenInfo }
> = props => {
  const { $t } = useIntl()

  return (
    <Card className={clsx("flex flex-col gap-5", props.className)}>
      <CardTitle>
        {$t(
          defineMessage({
            defaultMessage: "Pool Info",
            description: "/Pool/PoolStatsInfo/Card title",
          }),
        )}
      </CardTitle>
      <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 grid-rows-1 sm:grid-rows-2 gap-3">
        <Container>
          <Title>
            {$t(
              defineMessage({
                defaultMessage: "Liquidity",
                description: "/Pool/PoolStatsInfo/Card title",
              }),
            )}
          </Title>
          <Detail>
            $ <LiquidityNumber count={props.liquidity} />
            {props.tokenXPercentage != null && (
              <div className="text-sm font-normal">
                <TokenName token={props.tokenX} />
                {": "}
                <PercentNumber number={props.tokenXPercentage} />
              </div>
            )}
            {props.tokenYPercentage != null && (
              <div className="text-sm font-normal">
                <TokenName token={props.tokenY} />
                {": "}
                <PercentNumber number={props.tokenYPercentage} />
              </div>
            )}
          </Detail>
        </Container>
        <Container>
          <Title>
            {$t(
              defineMessage({
                defaultMessage: "Volume 24h",
                description: "/Pool/PoolStatsInfo/Card title",
              }),
            )}
          </Title>
          <Detail>
            $ <LiquidityNumber count={props.volume24h} />
          </Detail>
        </Container>
        <Container>
          <div className="flex flex-row items-center gap-2">
            <Title>
              {$t(
                defineMessage({
                  defaultMessage: "APR",
                  description: "/Pool/PoolStatsInfo/Card title",
                }),
              )}
            </Title>
            <TipIcon
              tip={$t(
                defineMessage({
                  defaultMessage:
                    "By adding liquidity you'll earn fees from all trades on this pair proportional to your share of the pool.{breakLine}Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.",
                  description: "/Pool/PoolStatsInfo/Card title tip",
                }),
                { breakLine: <br /> },
              )}
            />
          </div>
          <Detail>
            {props.apr == null ? (
              $t(
                defineMessage({
                  defaultMessage: "N/A",
                  description: "/Pool/PoolStatsInfo/Card detail",
                }),
              )
            ) : (
              <PercentNumber number={props.apr} />
            )}
          </Detail>
        </Container>
        <Container>
          <Title>
            {$t(
              defineMessage({
                defaultMessage: "Volume 7d",
                description: "/Pool/PoolStatsInfo/Card title",
              }),
            )}
          </Title>
          <Detail>
            $ <LiquidityNumber count={props.volume7d} />
          </Detail>
        </Container>
      </div>
    </Card>
  )
}

export default PoolStatsInfo
