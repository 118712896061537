import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { CardInset } from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { ordinal } from "../../../../utils/stringHelpers"
import { APowerRuleRange } from "../types"
import styles from "./ValidateAPowerRuleModalContent.module.scss"

export const ValidateAPowerRuleModalContent: FC<{
  ranges?: APowerRuleRange[]
  maxTickets?: number
  onClose?: () => void
  onConfirm?: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      className={"text-gray-200"}
      title={$t(
        defineMessage({
          defaultMessage: "APower LaunchPad Table",
          description: "/Launchpad/TokenProfileSection/CardModalContent title",
        }),
      )}
      width={640}
      onClose={props.onClose}
    >
      <CardInset>
        {props.ranges == null ? (
          <p>
            {$t(
              defineMessage({
                defaultMessage: "TBD",
                description:
                  "/Launchpad/ValidateAPowerRuleModalContent/content",
              }),
              { maxTickets: props.maxTickets },
            )}
          </p>
        ) : (
          <ValidateAPowerRuleTable ranges={props.ranges} />
        )}
        {props.maxTickets != null && (
          <p>
            {$t(
              defineMessage({
                defaultMessage:
                  "Note: Each wallet address will only be able to validate {maxTickets} tickets at maximum",
                description: "/Launchpad/ValidateAPowerRuleModalContent/Note",
              }),
              { maxTickets: props.maxTickets },
            )}
          </p>
        )}
      </CardInset>
      <GradientFilledButton className={"w-full"} onClick={props.onConfirm}>
        {$t(
          defineMessage({
            defaultMessage: "OK",
            description: "/Launchpad/ValidateAPowerRuleModalContent/Buton text",
          }),
        )}
      </GradientFilledButton>
    </CardModalContent>
  )
}

export const ValidateAPowerRuleTable: FC<{
  className?: string
  ranges: APowerRuleRange[]
}> = props => {
  const { $t } = useIntl()

  return (
    <div className={clsx(styles.table, props.className)}>
      <table>
        <thead>
          <tr>
            <th align="left">
              {$t(
                defineMessage({
                  defaultMessage: "Tickets",
                  description: "/Launchpad/Validate a power rule table",
                }),
              )}
            </th>
            <th align="left">
              {$t(
                defineMessage({
                  defaultMessage: "{aPowerToken} Per Ticket",
                  description: "/Launchpad/Validate a power rule table",
                }),
                { aPowerToken: <TokenName token={TokenInfoPresets.APower} /> },
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.ranges.map(range => (
            <tr key={range.start}>
              <td align="left">
                {ordinal(range.start)} - {ordinal(range.end)}
              </td>
              <td align="left">
                <TokenCount
                  token={TokenInfoPresets.APower}
                  count={range.amount}
                />{" "}
                <TokenName token={TokenInfoPresets.APower} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
