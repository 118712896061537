import { optionalResponse } from "clarity-codegen"
import { Currency } from "../../../../../utils/alexjs/Currency"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"
import { getAmountFromContract } from "../../TransactionsModule.service"

const contracts = ["yield-token-pool"] as const
const functionName = "swap-y-for-x" as const

export interface DepositSellRowData extends BaseRowData {
  type: NotifyTransactionType.DepositSell
  fromCurrencyAmount: CurrencyAmount
  toCurrencyAmount: CurrencyAmount
  expiry: number
}

export const depositSellTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  DepositSellRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.DepositSell,
  confirmedTransformer: helpers => {
    const args = helpers.getArgs()
    const result = helpers.getResult()
    const resultAmount = optionalResponse(result)

    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    return {
      fromCurrencyAmount: {
        currency: yieldTokenTrait,
        amount: getAmountFromContract(resultAmount?.dy),
      },
      toCurrencyAmount: {
        currency: tokenTrait,
        amount: getAmountFromContract(resultAmount?.dx),
      },
      expiry: args.expiry,
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    const yieldTokenTrait = args["yield-token-trait"] as Currency

    return {
      fromCurrencyAmount: {
        currency: yieldTokenTrait,
        amount: getAmountFromContract(args.dy),
      },
      toCurrencyAmount: {
        currency: tokenTrait,
        minAmount: getAmountFromContract(args["min-dx"]),
      },
      expiry: args.expiry,
    }
  },
}
