import { createContext, useContext } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import {
  Dimensions,
  useDimensions,
} from "../../utils/reactHelpers/useDimensions"

export interface ThemeBreakpoints {
  breakpoint(breakpoint: never): void
}
type BreakpointKeys = Parameters<ThemeBreakpoints["breakpoint"]>[0]

export type AnyBreakpointsDefinition = Readonly<
  Readonly<
    [
      name: string,
      value:
        | number
        | {
            width: number
            height: number
          },
    ]
  >[]
>
export type BreakpointsKeysFromDefinition<T extends AnyBreakpointsDefinition> =
  {
    [K in keyof T]: T[K][0]
  }[number]

export type AtLeastOneResponsiveValue<Value> = Partial<{
  [K in BreakpointKeys]: Value
}> &
  {
    [Key in BreakpointKeys]: Record<Key, Value>
  }[BreakpointKeys]
export type ResponsiveValue<Value> = Value | AtLeastOneResponsiveValue<Value>
export type ResponsiveFinalValue<T> = T extends AtLeastOneResponsiveValue<
  infer V
>
  ? V
  : never

const BreakpointsContext = createContext<null | AnyBreakpointsDefinition>(null)

export const BreakpointsProvider: FCC<{
  breakpoints: AnyBreakpointsDefinition
}> = props => {
  return (
    <BreakpointsContext.Provider value={props.breakpoints}>
      {props.children}
    </BreakpointsContext.Provider>
  )
}

export function useResponsiveValue<TVal>(
  propValue: ResponsiveValue<TVal>,
): undefined | TVal {
  const breakpointsDefs = useContext(BreakpointsContext)

  if (breakpointsDefs == null) {
    throw new Error("BreakpointsProvider is not found")
  }

  const dimensions = useDimensions()

  return getResponsiveValue(propValue, breakpointsDefs, dimensions)
}

export function useResponsiveValues<P extends Record<string, any>>(
  props: P,
): {
  [K in keyof P]: P[K] extends infer R | AtLeastOneResponsiveValue<infer J>
    ? R | J
    : P[K]
} {
  const dimensions = useDimensions()

  const breakpointsDefs = useContext(BreakpointsContext)
  if (breakpointsDefs == null) {
    throw new Error(
      "useResponsiveValues must be used within a BreakpointsProvider",
    )
  }

  return Object.fromEntries(
    Object.entries(props).map(([key, value]) => [
      key,
      getResponsiveValue(value, breakpointsDefs, dimensions),
    ]),
  ) as any
}

export function getResponsiveValue<TVal>(
  value: ResponsiveValue<TVal>,
  breakpointsDefinition: AnyBreakpointsDefinition,
  dimensions: Dimensions,
): undefined | TVal {
  if (!isResponsiveObjectValue(value, breakpointsDefinition)) {
    return value
  }

  return getValueForScreenSize({
    responsiveValue: value,
    breakpoints: breakpointsDefinition,
    dimensions,
  })
}

export function isResponsiveObjectValue<TVal>(
  val: ResponsiveValue<TVal>,
  breakpointsDefinition: AnyBreakpointsDefinition,
): val is AtLeastOneResponsiveValue<TVal> {
  if (!val) return false
  if (typeof val !== "object") return false
  return (Object.keys(val) as string[]).reduce(
    (acc, key) => acc && !!breakpointsDefinition.find(def => def[0] === key),
    true,
  )
}

export function getValueForScreenSize<TVal>({
  responsiveValue,
  breakpoints,
  dimensions,
}: {
  responsiveValue: AtLeastOneResponsiveValue<TVal>
  breakpoints: AnyBreakpointsDefinition
  dimensions: Dimensions
}): TVal | undefined {
  const { width, height } = dimensions

  return breakpoints.reduce<TVal | undefined>((acc, [name, value]) => {
    const pickedResponsiveValue = (responsiveValue as any)[name] as
      | undefined
      | TVal

    if (typeof value === "object") {
      if (
        width >= value.width &&
        height >= value.height &&
        pickedResponsiveValue !== undefined
      ) {
        return pickedResponsiveValue
      }
    } else if (width >= value && pickedResponsiveValue !== undefined) {
      return pickedResponsiveValue as TVal
    }

    return acc
  }, undefined)
}
