import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import {
  CardDivider,
  CardInset,
  CardInsetDivider,
  CardInsetTitle,
} from "../../../../components/Card"
import {
  BasicContentContainer,
  CollapsableCard,
} from "../../../../components/CollapsableCard/CollapsableCard"
import { NoteParagraph } from "../../../../components/NoteParagraph/NoteParagraph"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { formatTimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import {
  StakeCycleTable,
  StakeCycleTableHeaderRow,
  StakeCycleTableRow,
} from "../../components/StakeCycleTable"
import { MixedStakeCycle } from "../../types"
import { HarvestButton } from "./_/HarvestButton"
import { MyStakingSummary } from "./_/MyStakingSummary"
import { StakeCycleRowContent, columnCount } from "./_/StakeCycleRowContent"
import { ReactComponent as CyclesFullscreenIcon } from "./cyclesFullscreen.svg"

export interface MyStakingSectionProps {
  className?: string
  defaultCollapsed?: boolean

  alexToken: TokenInfo
  autoAlexToken: TokenInfo

  autoAlexTokenCount: number
  autoStakingAPY: number
  autoAlexApowerToBeDistributed?: number
  manualStakingAlexTokenCount: number
  manualStakingAPR: number
  harvestableAlexTokenCount: number
  harvestableAPowerCount: number

  currentBlock: number
  currentCycleEndedAt: Date
  currentCycleStartedAtBlock: number
  currentCycleEndedAtBlock: number

  stakingCycles: MixedStakeCycle[]
  onHarvestAll?: (autoMint: boolean) => void
  onShowAllCycles?: () => void
  onShowApowerDistribution?: () => void
}

export const MyStakingSection: FC<MyStakingSectionProps> = props => {
  const { $t } = useIntl()

  return (
    <CollapsableCard
      className={props.className}
      title={$t(
        defineMessage({
          defaultMessage: "My Staking",
          description: "/Staking/MixedStake/MyStakingSection/Title",
        }),
      )}
      defaultCollapsed={props.defaultCollapsed}
      ContentContainer={props => (
        <BasicContentContainer
          {...props}
          gapClassName={"gap-6"}
          className={clsx(
            "flex flex-wrap",
            props.gapClassName,
            props.className,
          )}
        />
      )}
    >
      <div
        className={clsx(
          "flex-1 min-w-full lg:min-w-0 py-6",
          "flex flex-col gap-6",
        )}
      >
        <MyStakingSummary
          gapClassName={"gap-6"}
          alexToken={props.alexToken}
          autoAlexToken={props.autoAlexToken}
          autoAlexTokenCount={props.autoAlexTokenCount}
          autoAlexApowerToBeDistributed={props.autoAlexApowerToBeDistributed}
          autoStakingAPY={props.autoStakingAPY}
          manualStakingAlexTokenCount={props.manualStakingAlexTokenCount}
          manualStakingAPR={props.manualStakingAPR}
          onShowApowerDistribution={props.onShowApowerDistribution}
        />

        {props.manualStakingAlexTokenCount > 0 && (
          <HarvestButton
            className={"block"}
            alexToken={props.alexToken}
            autoAlexToken={props.autoAlexToken}
            alexTokenCount={props.harvestableAlexTokenCount}
            apowerTokenCount={props.harvestableAPowerCount}
            onClick={props.onHarvestAll}
          />
        )}
        <CardDivider className={"w-full"} />
        <NoteParagraph className={"w-full"}>
          {$t(
            defineMessage({
              defaultMessage:
                "To unstake {autoAlexToken}, go to SWAP and exchange {autoAlexToken} for {alexToken}.",
              description: "/Staking/MixedStake/MyStakingSection/Note",
            }),
            {
              autoAlexToken: <TokenName token={props.autoAlexToken} />,
              alexToken: <TokenName token={props.alexToken} />,
            },
          )}
        </NoteParagraph>
      </div>
      <CardInset className={"flex-1 min-w-full lg:min-w-0 flex flex-col gap-4"}>
        <div className={"flex flex-row items-center"}>
          <CardInsetTitle>
            {$t(
              defineMessage({
                defaultMessage: "Cycles",
                description: "/Staking/MixedStake/MyStakingSection/Card title",
              }),
            )}
          </CardInsetTitle>
          <Tooltip
            className={"mr-auto ml-2.5"}
            title={$t(
              defineMessage({
                defaultMessage:
                  "Current Cycle Countdown: {time} {breakLine} Start Block: {startBlock} / End Block: {endBlock}",
                description: "/Staking/MixedStake/MyStakingSection/Card title",
              }),
              {
                breakLine: <br />,
                time: formatTimeCountdownText(props.currentCycleEndedAt),
                startBlock: props.currentCycleStartedAtBlock,
                endBlock: props.currentCycleEndedAtBlock,
              },
            )}
          >
            <BlockTimeCountdown
              currentBlock={props.currentBlock}
              targetBlock={props.currentCycleEndedAtBlock}
              time={props.currentCycleEndedAt}
            />
          </Tooltip>
          <CyclesFullscreenIcon
            className={"cursor-pointer"}
            onClick={props.onShowAllCycles}
          />
        </div>
        <StakeCycleTable
          divider={<CardInsetDivider />}
          columnCount={columnCount}
        >
          <StakeCycleTableHeaderRow
            titles={[
              $t(
                defineMessage({
                  defaultMessage: "Cycles",
                  description:
                    "/Staking/MixedStake/MyStakingSection/table header",
                }),
              ),
              $t(
                defineMessage({
                  defaultMessage: "Auto Staking(V2)",
                  description:
                    "/Staking/MixedStake/MyStakingSection/table header",
                }),
              ),
              $t(
                defineMessage({
                  defaultMessage: "Manual Staking",
                  description:
                    "/Staking/MixedStake/MyStakingSection/table header",
                }),
              ),
            ]}
          />
          {props.stakingCycles.map(cycle => (
            <StakeCycleTableRow
              key={cycle.cycleNumber}
              cycleNumber={cycle.cycleNumber}
              status={cycle.cycleStatus}
              fromBlock={cycle.fromBlock}
              targetBlock={cycle.targetBlock}
            >
              <StakeCycleRowContent
                alexToken={props.alexToken}
                autoAlexToken={props.autoAlexToken}
                cycle={cycle}
              />
            </StakeCycleTableRow>
          ))}
        </StakeCycleTable>
      </CardInset>
    </CollapsableCard>
  )
}
