import clsx from "clsx"
import { noop } from "lodash"
import { FC, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Column, useTable } from "react-table"
import { GrayBadge, GreenBadge } from "../../../components/Badge"
import { PlainIconButton } from "../../../components/button/variants/PlainIconButton"
import { Card } from "../../../components/Card"
import { NavLink } from "../../../components/NavLink"
import {
  Table,
  TableBodyRow,
  TableHeadRow,
  Tbody,
  Td,
  Th,
  Thead,
} from "../../../components/table/StyledTable"
import { TokenIcon } from "../../../components/TokenIcon"
import { customReactTableRender } from "../../../utils/customReactTableRender"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { TruncateTokenName } from "../../Farm/components/TruncateTokenName"
import { ReactComponent as DDReportIcon } from "./assets/icon/ddReport.svg"
import { ReactComponent as DiscordIcon } from "./assets/icon/discord.svg"
import { ReactComponent as MediumIcon } from "./assets/icon/medium.svg"
import { ReactComponent as TelegramIcon } from "./assets/icon/telegram.svg"
import { ReactComponent as TwitterIcon } from "./assets/icon/twitter.svg"
import { ReactComponent as WhitePaperIcon } from "./assets/icon/whitepaper.svg"
import { ReactComponent as WebSiteIcon } from "./assets/icon/www.svg"
import { TokenInfoListItem } from "./types"

const TokenListTable: FC<{
  className?: string
  tokenListItems: SuspenseResource<TokenInfoListItem[]>
}> = props => {
  const tableInstance = useTable({
    columns: useTableSchema(),
    data: readResource(props.tokenListItems),
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <Card className={clsx("min-w-[900px]", props.className)}>
      <div className={"flex-1 w-full flex flex-col items-center"}>
        <div className={"flex-1 w-full overflow-auto"}>
          <Table {...getTableProps()} className={"flex-1"}>
            <Thead>
              {headerGroups.map(g => (
                <TableHeadRow {...g.getHeaderGroupProps()}>
                  {g.headers.map(cell => (
                    <Th {...cell.getHeaderProps()}>
                      {customReactTableRender(cell, "Header")}
                    </Th>
                  ))}
                </TableHeadRow>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()} className={"h-full overflow-auto"}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <TableBodyRow
                    {...row.getRowProps({
                      className: "hover:bg-black/10 active:bg-black/20",
                    })}
                  >
                    {row.cells.map(cell => (
                      <Td
                        {...cell.getCellProps({
                          className: "break-words",
                          style: { width: cell.column.width },
                        })}
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </TableBodyRow>
                )
              })}
            </Tbody>
          </Table>
        </div>
      </div>
    </Card>
  )
}

const useTableSchema = (): Column<TokenInfoListItem>[] => {
  const { $t } = useIntl()
  return useMemo(
    () => [
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Token",
            description: "/TokenList/Table/header",
          }),
        ),
        accessor: "token",
        width: 220,
        Cell: props => (
          <div className={"flex flex-row items-center gap-2.5"}>
            <TokenIcon token={props.value} />
            <span className="leading-tight flex flex-col">
              <TruncateTokenName
                className={"text-sm font-medium truncate"}
                token={props.value}
              />
              <span className="text-gray-500 text-xs">
                {props.value.description}
              </span>
            </span>
          </div>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Status",
            description: "/TokenList/Table/header",
          }),
        ),
        accessor: "status",
        Cell: props => (
          <div>
            {props.value === "Launched" ? (
              <GreenBadge>{props.value}</GreenBadge>
            ) : (
              <GrayBadge>{props.value}</GrayBadge>
            )}
          </div>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Launch",
            description: "/TokenList/Table/header",
          }),
        ),
        accessor: "launchTime",
        Cell: props => (
          <div className="text-sm leading-5 font-normal whitespace-pre-line">
            {props.value.replace(/\s\\n\s/, "\n")}
          </div>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Information",
            description: "/TokenList/Table/header",
          }),
        ),
        accessor: "website",
        Cell: props => (
          <div className="flex flex-row gap-4">
            {props.row.original.website && (
              <NavLink to={props.row.original.website}>
                <PlainIconButton icon={WebSiteIcon} onClick={noop} />
              </NavLink>
            )}
            {props.row.original.whitePaper && (
              <NavLink to={props.row.original.whitePaper}>
                <PlainIconButton icon={WhitePaperIcon} onClick={noop} />
              </NavLink>
            )}
            {props.row.original.ddReportLink && (
              <NavLink to={props.row.original.ddReportLink}>
                <PlainIconButton icon={DDReportIcon} onClick={noop} />
              </NavLink>
            )}
          </div>
        ),
      },
      {
        Header: $t(
          defineMessage({
            defaultMessage: "Social",
            description: "/TokenList/Table/header",
          }),
        ),
        accessor: "discordLink",
        Cell: props => (
          <ul className={"flex flex-row gap-4"}>
            {props.row.original.mediumLink && (
              <li>
                <NavLink to={props.row.original.mediumLink}>
                  <PlainIconButton icon={MediumIcon} onClick={noop} />
                </NavLink>
              </li>
            )}
            {props.row.original.twitterLink && (
              <li>
                <NavLink to={props.row.original.twitterLink}>
                  <PlainIconButton icon={TwitterIcon} onClick={noop} />
                </NavLink>
              </li>
            )}
            {props.row.original.discordLink && (
              <li>
                <NavLink to={props.row.original.discordLink}>
                  <PlainIconButton icon={DiscordIcon} onClick={noop} />
                </NavLink>
              </li>
            )}
            {props.row.original.telegramLink && (
              <li>
                <NavLink to={props.row.original.telegramLink}>
                  <PlainIconButton icon={TelegramIcon} onClick={noop} />
                </NavLink>
              </li>
            )}
          </ul>
        ),
      },
    ],
    [$t],
  )
}

export default TokenListTable
