import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["fwp-wstx-alex-tranched-64"] as const
const functionName = "reduce-position" as const

export interface CoFarmClaimRowData extends BaseRowData {
  type: NotifyTransactionType.CoFarmClaim
  claimedPrincipalCurrencyAmount: CurrencyAmount
}

export const coFarmClaimTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  CoFarmClaimRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.CoFarmClaim,
  confirmedTransformer: (helpers, context) => {
    if (!context?.address) {
      throw new Error("context of address not found")
    }
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, context.address)

    return {
      claimedPrincipalCurrencyAmount: {
        currency: Currency.W_STX,
        amount: transferAmounts[Currency.W_STX]?.in ?? undefined,
      },
    }
  },
  pendingOrFailedTransformer: () => {
    return {
      claimedPrincipalCurrencyAmount: {
        currency: Currency.W_STX,
      },
    }
  },
}
