import { unwrapResponse } from "clarity-codegen"
import { sum } from "lodash"
import { computed, makeObservable } from "mobx"
import { CONTRACT_DEPLOYER } from "../../../../../config"
import { asSender } from "../../../../../generated/smartContractHelpers/asSender"
import { LazyValue } from "../../../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../../../stores/LazyValue/pMemoizeDecorator"
import { Currency } from "../../../../../utils/alexjs/Currency"
import { DualYieldV1_1Token } from "../../../../../utils/alexjs/currencyHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import ManualStakeStore from "../ManualStakeStore"

export class DualYieldModuleV1_1 {
  constructor(
    readonly poolToken: DualYieldV1_1Token,
    readonly store: ManualStakeStore,
  ) {
    makeObservable(this)
  }

  private _multiplier = new LazyValue(
    () => this.poolToken,
    token =>
      asSender(CONTRACT_DEPLOYER)
        .contract("dual-farming-pool-v1-03")
        .func("get-multiplier-in-fixed-or-default")
        .call({ token })
        .then(a => a / 1e8),
    {
      decorator: pMemoizeDecorator({
        persistKey: "dual-token-v1-1-multiplier-update",
      }),
    },
  )

  @computed get multiplier$(): number {
    return this._multiplier.value$
  }

  @computed get pendingReward$(): number {
    const myStaking = this.store.myStaking
    return (
      sum(
        myStaking.pastCircles
          .filter(
            c => !myStaking.claimedCycles.includes(c) && c >= this.startCycle$,
          )
          .map(myStaking.rewardAt),
      ) * this.multiplier$
    )
  }

  @computed get startCycle$(): number {
    return this.startCycle.value$
  }

  private startCycle = new LazyValue(
    () => this.poolToken,
    token =>
      asSender(CONTRACT_DEPLOYER)
        .contract("dual-farming-pool-v1-03")
        .func("get-start-cycle-or-default")
        .call({
          token,
        }),
    {
      decorator: pMemoizeDecorator({
        persistKey: "dual-token-v1-1-start-cycle",
      }),
    },
  )

  private underlyingToken = new LazyValue(
    () => this.poolToken,
    token =>
      asSender(CONTRACT_DEPLOYER)
        .contract("dual-farming-pool-v1-03")
        .func("get-dual-token-or-fail")
        .call({ token })
        .then(unwrapResponse),
    {
      decorator: pMemoizeDecorator({
        persistKey: "dual-token-v1-1-underlying",
      }),
    },
  )

  @computed get underlyingToken$(): Currency {
    return this.underlyingToken.value$ as Currency
  }

  @computed get tokenInfo$(): TokenInfo {
    return this.store.currencyStore.getTokenInfo$(this.underlyingToken$)
  }

  @computed get priceInUSD$(): number {
    return this.store.currencyStore.getPrice$(this.underlyingToken$)
  }

  @computed get aprMultiplier$(): number {
    const alexPrice = this.store.currencyStore.getPrice$(Currency.ALEX)
    return 1 + (this.priceInUSD$ / alexPrice) * this.multiplier$
  }
}
