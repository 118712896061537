import clsx from "clsx"
import { FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { PercentNumber } from "../../../components/PercentNumber"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { plural } from "../../../utils/stringHelpers"
import { VerticalInfoListContainer, VerticalInfoListItem } from "./InfoList"
import { TokenIDOSuccessInfo } from "./types"

export interface IDOSucceedNumbersListProps {
  className?: string
  idoToken: TokenInfo
  tokenIDOSuccessSummary: TokenIDOSuccessInfo
}

export const IDOSucceedNumbersList: FC<IDOSucceedNumbersListProps> = props => {
  const { $t } = useIntl()
  return (
    <VerticalInfoListContainer className={clsx(props.className)}>
      <VerticalInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "Filled",
            description: "/Launchpad/IDO succeed numbers list",
          }),
        )}
        detail={
          <PercentNumber number={props.tokenIDOSuccessSummary.fillRate} />
        }
      />
      <VerticalInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "Total Allocation",
            description: "/Launchpad/IDO succeed numbers list",
          }),
        )}
        detail={
          <>
            <TokenIcon className={"mr-2"} token={props.idoToken} size={24} />
            <TokenCount
              token={props.idoToken}
              count={props.tokenIDOSuccessSummary.totalAllocation}
            />
            &nbsp;
            {props.idoToken.propertyTags.includes("ordinals-voucher") ? (
              plural(props.tokenIDOSuccessSummary.totalAllocation, {
                one: "Ordinal",
                many: "Ordinals",
              })
            ) : (
              <TokenName token={props.idoToken} />
            )}
          </>
        }
      />
      <VerticalInfoListItem
        title={$t(
          defineMessage({
            defaultMessage: "Total Lottery",
            description: "/Launchpad/IDO succeed numbers list",
          }),
        )}
        detail={
          <>
            {$t(
              defineMessage({
                defaultMessage: "{ticketCount} Tickets",
                description: "/Launchpad/IDO succeed numbers list",
              }),
              {
                ticketCount: (
                  <TokenCount
                    token={TokenInfoPresets.IDOLotteryTicket}
                    count={props.tokenIDOSuccessSummary.currentActivation}
                  />
                ),
              },
            )}
          </>
        }
      />
    </VerticalInfoListContainer>
  )
}
