import { createContext, useContext, useMemo } from "react"
import { FCC } from "../utils/reactHelpers/types"

interface LabelContextValue {
  controlId?: string
}

const LabelForContext = createContext<LabelContextValue>({})

export const LabelForProvider: FCC<{ controlId: string }> = props => {
  const value: LabelContextValue = useMemo(
    () => ({
      controlId: props.controlId,
    }),
    [props.controlId],
  )

  return (
    <LabelForContext.Provider value={value}>
      {props.children}
    </LabelForContext.Provider>
  )
}

export const useLabelFor = (): undefined | string =>
  useContext(LabelForContext).controlId
