import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { clear$t } from "../../../../../commonIntlMessages"
import { CardInset } from "../../../../../components/Card"
import {
  CardModalContent,
  TitleBarWithLeftSideTitle,
} from "../../../../../components/CardModal/CardModal"
import { WarnIcon } from "../../../../../components/NoteParagraph/NoteParagraph"
import { Spensor } from "../../../../../components/Spensor"
import { BalanceBottomArea } from "../../../../../components/TokenInput/BalanceBottomArea"
import { TokenInput } from "../../../../../components/TokenInput/TokenInput"
import { TokenNameSelectTrigger } from "../../../../../components/TokenInput/TokenNameSelect"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { LoadableButton } from "../../../../../components/button/LoadableButton"
import { GradientFilledButton } from "../../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { OpacityButton } from "../../../../../components/button/variants/OpacityButton"
import { RedFilledButton } from "../../../../../components/button/variants/RedFilledButton"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
} from "../../../../../utils/SuspenseResource"
import { dontWrapObserver } from "../../../../../utils/mobxHelpers"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { WithdrawFormError, WithdrawFormErrorType } from "../../types"
import { RectButton } from "../../wrappedCommonComponents/RectButton"
import { ReactComponent as DeleteButtonIcon } from "./deleteButton.svg"

export const TokensWithdrawModalContent: FC<{
  tokens: {
    token: TokenInfo
    tokenCount: SuspenseResource<null | number>
    tokenBalanceCount: SuspenseResource<number>
    isError?: SuspenseResource<boolean>
    onPressMax: () => void
    onTokenCountChange: (newCount: null | number) => void
    onDelete?: () => void
  }[]
  onAdd?: () => void
  onSelectCurrency: (fromCurrency: TokenInfo) => void
  error?: SuspenseResource<WithdrawFormError | undefined>
  onSubmit: () => void
  onClear: () => void
  onClose: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <CardModalContent
      width={640}
      titleBar={
        <TitleBarWithLeftSideTitle
          title={"Withdraw"}
          rightSide={
            <HeadlessButton
              className={"ml-auto text-blue-600"}
              onClick={props.onClear}
            >
              {$t(clear$t)}
            </HeadlessButton>
          }
          onClose={props.onClose}
        />
      }
    >
      {props.tokens.map((token, idx) => (
        <TokenInput
          key={idx}
          token={token.token}
          value={token.tokenCount}
          tokenNameArea={
            <>
              <TokenNameSelectTrigger
                token={token.token}
                onClick={() => props.onSelectCurrency(token.token)}
              />
              {token.onDelete != null && (
                <HeadlessButton onClick={token.onDelete}>
                  <DeleteButtonIcon />
                </HeadlessButton>
              )}
            </>
          }
          bottomArea={
            <BalanceBottomArea
              token={token.token}
              balance={token.tokenBalanceCount}
              onPressMax={token.onPressMax}
            />
          }
          error={safeReadResource(token.isError)}
          onChange={token.onTokenCountChange}
        />
      ))}

      {props.onAdd != null && (
        <RectButton Variant={OpacityButton} onClick={props.onAdd}>
          {$t(
            defineMessage({
              defaultMessage: "+ Add a token",
              description: "Orderbook/withdraw modal",
            }),
          )}
        </RectButton>
      )}

      <CardInset
        className={"flex flex-col gap-y-2.5"}
        boxClassName={"px-4 py-2.5"}
        bgClassName={"bg-red-900"}
      >
        <div
          className={
            "flex flex-row items-center justify-center text-base font-medium"
          }
        >
          <WarnIcon className={"w-4.5 h-4.5"} />
          &nbsp;
          {$t(
            defineMessage({
              defaultMessage: "Must read before Withdraw",
              description: `Orderbook/Withdraw Modal/must read block/title`,
            }),
          )}
        </div>

        {$t<ReactNode>(
          defineMessage({
            defaultMessage: `
<paragraph>Warning: <strong>DO NOT SUBMIT WITHDRAWL REQUEST MORE THAN ONCE</strong>. Withdrawal is a two-step process where a request is raised, which needs to be approved. If you do not see the requested funds in your wallet 24 hours after you submited a withdrawal request, please contact community managers.</paragraph>
<paragraph>If you already submitted multiple times of withdraw requests, please open ticket in discord for help.</paragraph>
          `,
            description: `Orderbook/Withdraw Modal/must read block/content`,
          }),
          {
            paragraph: dontWrapObserver(contents => (
              <p className={"text-xs leading-4 font-normal text-yellow-400"}>
                {contents}
              </p>
            )),
            strong: dontWrapObserver(contents => (
              <span className={"font-bold text-yellow-200"}>{contents}</span>
            )),
          },
        )}
      </CardInset>

      <Spensor
        fallback={
          <LoadableButton Variant={GradientFilledButton} loading={true}>
            {$t(withdraw$t)}
          </LoadableButton>
        }
      >
        {() => {
          const error = readResource(props.error)
          if (error != null) {
            return (
              <RectButton
                Variant={
                  isErrorStateButtonShouldRenderAsGradientButton(error)
                    ? GradientFilledButton
                    : RedFilledButton
                }
                disabled={true}
              >
                {error.message}
              </RectButton>
            )
          }
          return (
            <RectButton Variant={GradientFilledButton} onClick={props.onSubmit}>
              {$t(withdraw$t)}
            </RectButton>
          )
        }}
      </Spensor>
    </CardModalContent>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: WithdrawFormError,
): boolean => {
  if (!error) return false
  return error.type === WithdrawFormErrorType.AmountIsEmpty
}

const withdraw$t = defineMessage({
  defaultMessage: "Withdraw",
  description: "Orderbook/withdraw modal",
})
