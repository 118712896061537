import { FC } from "react"
import { Slider } from "../../../../../components/Slider"
import { ComboboxSelector } from "../../../components/ComboboxSelector/ComboboxSelector"
import { FormModeSwitchInfo } from "../types"

export interface CycleCountSliderProps {
  value: FormModeSwitchInfo
  onChange?: (value: FormModeSwitchInfo) => void
}

export const CycleCountSlider: FC<CycleCountSliderProps> = props => {
  return (
    <div>
      <ComboboxSelector<number | "auto">
        availableValues={[
          { label: "Auto", value: "auto", isRecommend: true },
          { label: "4", value: 4 },
          { label: "8", value: 8 },
          { label: "16", value: 16 },
          { label: "32", value: 32 },
        ]}
        value={[props.value.mode === "auto" ? "auto" : props.value.cycleCount]}
        onSelect={value => {
          if (value === "auto") {
            props.onChange?.({ mode: "auto" })
          } else {
            props.onChange?.({ mode: "manual", cycleCount: value })
          }
        }}
      />

      <Slider
        disabled={props.value.mode === "auto"}
        min={1}
        max={32}
        value={props.value.mode === "auto" ? 1 : props.value.cycleCount}
        onChange={v => props.onChange?.({ mode: "manual", cycleCount: v })}
      />
    </div>
  )
}
