import { Currency } from "../../../../../utils/alexjs/Currency"
import { getTransferAmounts } from "../../../../../utils/transferHelpers"
import {
  BaseRowData,
  CurrencyAmount,
  NotifyTransactionType,
  TransformerGroup,
} from "../../../types"

const contracts = ["collateral-rebalancing-pool-v1"] as const
const functionName = "reduce-position-key" as const

export interface BorrowClaimRowData extends BaseRowData {
  type: NotifyTransactionType.BorrowClaim
  collateralCurrencyAmount: CurrencyAmount
  tokenCurrencyAmount: CurrencyAmount
  keyTokenCurrencyAmount: CurrencyAmount
  expiry: number
}

export const borrowClaimTransformerGroup: TransformerGroup<
  typeof contracts,
  typeof functionName,
  BorrowClaimRowData
> = {
  contracts,
  functionName,
  type: NotifyTransactionType.BorrowClaim,
  confirmedTransformer: helpers => {
    const tx = helpers.getTx()
    const args = helpers.getArgs()
    const transfers = helpers.getTransfers()
    const transferAmounts = getTransferAmounts(transfers, tx.sender_address)

    const tokenTrait = args["token-trait"] as Currency
    const collateralTrait = args["collateral-trait"] as Currency
    const keyTokenTrait = args["key-token-trait"] as Currency

    return {
      tokenCurrencyAmount: {
        currency: tokenTrait,
        amount: transferAmounts[tokenTrait]?.in,
      },
      collateralCurrencyAmount: {
        currency: collateralTrait,
        amount: transferAmounts[collateralTrait]?.in,
      },
      keyTokenCurrencyAmount: {
        currency: keyTokenTrait,
        amount: transferAmounts[keyTokenTrait]?.out,
      },
      expiry: args.expiry,
    }
  },
  pendingOrFailedTransformer: args => {
    const tokenTrait = args["token-trait"] as Currency
    const collateralTrait = args["collateral-trait"] as Currency
    const keyTokenTrait = args["key-token-trait"] as Currency

    return {
      tokenCurrencyAmount: {
        currency: tokenTrait,
      },
      collateralCurrencyAmount: {
        currency: collateralTrait,
      },
      keyTokenCurrencyAmount: {
        currency: keyTokenTrait,
      },
      expiry: args.expiry,
    }
  },
}
