import clsx from "clsx"
import copy from "copy-to-clipboard"
import { format } from "date-fns"
import { FC, ReactNode } from "react"
import { useIntl } from "react-intl"
import { copied$t } from "../../../../../commonIntlMessages"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../../components/InfoList"
import {
  Transformer,
  createSizeTuner,
} from "../../../../../components/SizeTuner"
import { TokenNameStack } from "../../../../../components/TokenNameStack"
import { Tooltip } from "../../../../../components/Tooltip/Tooltip"
import { HeadlessButton } from "../../../../../components/button/HeadlessButton"
import { useMessage } from "../../../../../components/message/MessageProvider"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { FCC } from "../../../../../utils/reactHelpers/types"
import { usePersistFn } from "../../../../../utils/reactHelpers/usePersistFn"
import { orderDirection$t, orderType$t } from "../../_/commonIntlMessages"
import { Colors } from "../../designTokens"
import { OrderDirection, StxDxOrderType } from "../../types"
import { fields$t } from "./commonIntlMessages"
import { ReactComponent as CopyIcon } from "./copyIcon.svg"

interface SizeTunerTransformerData {
  columnId: string
}
const { SizeTuner, SizeConsumer } = createSizeTuner<SizeTunerTransformerData>()

export const RecordTableContainer: FCC = props => {
  const transformer: Transformer<SizeTunerTransformerData> = usePersistFn(
    allSize => {
      return {
        width: Math.max(...allSize.map(s => s.width), 0),
        height: Math.max(...allSize.map(s => s.height), 0),
      }
    },
  )

  return <SizeTuner transformer={transformer}>{props.children}</SizeTuner>
}

export const RecordRowContainer: FCC<{ className?: string }> = props => (
  <InfoList
    className={clsx(
      "gap-1 justify-between bg-black/20 px-4 py-3 rounded-lg",
      props.className,
    )}
    direction={"row"}
    listItemDirection={"column"}
  >
    {props.children}
  </InfoList>
)

export const FieldCell: FCC<{
  className?: string
  containerClassName?: string
  columnId: string
}> = props => (
  <SizeConsumer
    className={props.className}
    containerClassName={props.containerClassName}
    data={{ columnId: props.columnId }}
  >
    <InfoListItem className={clsx("justify-center")}>
      {props.children}
    </InfoListItem>
  </SizeConsumer>
)

export const TokenPairCell: FC<{
  clickToCopyRecordIdentifier: ReactNode
  recordIdentifier: string
  tradeToken: TokenInfo
  priceToken: TokenInfo
  orderType: StxDxOrderType
  orderDirection: OrderDirection
  className?: string
}> = props => {
  const intl = useIntl()

  const message = useMessage()

  return (
    <FieldCell columnId={"tokenPair"} className={props.className}>
      <InfoListItemTitle className={"sr-only"}>Trading Type</InfoListItemTitle>
      <InfoListItemDetail>
        <div className={"text-base font-semibold text-gray-200"}>
          <TokenNameStack
            tradeToken={props.tradeToken}
            priceToken={props.priceToken}
          />
        </div>
        <div className="flex items-end gap-2">
          <div
            className={clsx(
              "text-xs font-semibold",
              props.orderDirection === "buy"
                ? Colors.buyTextClassName
                : Colors.sellTextClassName,
            )}
          >
            <span className={"capitalize"}>
              {orderType$t.fromStxDxOrderType(intl, props.orderType)}
            </span>{" "}
            /{" "}
            <span className={"capitalize"}>
              {orderDirection$t.fromOrderDirection(intl, props.orderDirection)}
            </span>
          </div>
          <Tooltip title={props.clickToCopyRecordIdentifier}>
            <HeadlessButton
              onClick={() => {
                if (copy(props.recordIdentifier)) {
                  message.show({ message: intl.$t(copied$t) })
                }
              }}
            >
              <CopyIcon />
            </HeadlessButton>
          </Tooltip>
        </div>
      </InfoListItemDetail>
    </FieldCell>
  )
}

export const ActionsCell: FCC = props => {
  const { $t } = useIntl()
  return (
    <FieldCell
      className={"flex items-center justify-end"}
      containerClassName={"w-[fit-content]"}
      columnId={"actions"}
    >
      <InfoListItemTitle className={"sr-only"}>
        {$t(fields$t.actions)}
      </InfoListItemTitle>
      <InfoListItemDetail className={"self-center"}>
        {props.children}
      </InfoListItemDetail>
    </FieldCell>
  )
}

export const CreateDateCell: FC<{
  className?: string
  titleText?: ReactNode
  createdAt: Date
}> = props => {
  const { $t } = useIntl()
  return (
    <FieldCell columnId={"createDate"} className={props.className}>
      <InfoListItemTitle>
        {props.titleText ?? $t(fields$t.createDate)}
      </InfoListItemTitle>
      <InfoListItemDetail textClassName={"text-gray-500"}>
        {format(props.createdAt, "yyyy-MM-dd HH:mm:ss")}
      </InfoListItemDetail>
    </FieldCell>
  )
}
