import { FC } from "react"
import { ConfirmTransactionStoreForGeneral } from "../../stores/confirmTransactionDialogStore/ConfirmTransactionStoreForGeneral"
import { Modal } from "../Modal"
import { TransactionStateDialogContentForGeneral } from "./TransactionStateDialog"

export const WiredTransactionStateDialogForGeneral: FC<{
  store: ConfirmTransactionStoreForGeneral
  onClose?: () => void
}> = props => {
  const state = props.store.running

  const onClose = (): void => {
    props.store.closeRunning()
    props.onClose?.()
  }

  if (!state) return null

  return (
    <Modal visible={true} onClose={onClose}>
      <TransactionStateDialogContentForGeneral
        transactionState={state}
        onClose={onClose}
      />
    </Modal>
  )
}
