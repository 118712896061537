import { defineMessage, useIntl } from "react-intl"
import { Card, CardInset, CardTitle } from "../../../../components/Card"
import { FCC } from "../../../../utils/reactHelpers/types"

export const MyIDOFrame: FCC<{ className?: string }> = props => {
  const { $t } = useIntl()
  return (
    <Card className={props.className}>
      <CardTitle className={"block mb-4"}>
        {$t(
          defineMessage({
            defaultMessage: "My Launch",
            description: "/Launchpad/My Launch Frame title",
          }),
        )}
      </CardTitle>
      <CardInset
        className={"flex flex-col gap-6 items-center px-4 py-6 text-center"}
      >
        {props.children}
      </CardInset>
    </Card>
  )
}
