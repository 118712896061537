import clsx from "clsx"
import { action } from "mobx"
import { FC, Suspense, useEffect, useMemo, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { useParams, useSearchParams } from "react-router-dom"
import { cancel$t } from "../../commonIntlMessages"
import { CardModalContent } from "../../components/CardModal/CardModal"
import { ChartContainer } from "../../components/ChartContainer/ChartContainer"
import { useConnect } from "../../components/ConnectWallet/ConnectProvider"
import { usePreventGlobalWiredDisclaimerModal } from "../../components/DisclaimerModalContent/WiredDisclaimerModal"
import { RootLayoutContainer } from "../../components/LayoutContainer/RootLayoutContainer"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Modal } from "../../components/Modal"
import { WarnIcon } from "../../components/NoteParagraph/NoteParagraph"
import { useSetTopNavBarLogoExtra } from "../../components/TopNavBar/TopNavBarLogoExtraProvider"
import { WiredTransactionStateDialog } from "../../components/TransactionStateDialog/WiredTransactionStateDialog"
import { GradientFilledButton } from "../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteBorderButton } from "../../components/button/variants/WhiteBorderButton"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { suspenseResource } from "../../utils/SuspenseResource"
import { BRC20Currency } from "../../utils/alexjs/Currency"
import { oneOf } from "../../utils/arrayHelpers"
import { assertNever } from "../../utils/types"
import { safelyGet, waitFor } from "../../utils/waitFor"
import { ResolutionString } from "../../vendors/charting_library/charting_library.esm"
import { ComingSoonPage } from "./ComingSoonPage/ComingSoonPage"
import { ScreenType, getScreensFromParams } from "./OrderbookPage.types"
import { ReactComponent as AlexBRC20Logo } from "./assets/ALEX_B20_LOGO_Full_Color.svg"
import { ComingSoonChartPlaceholder } from "./components/ComingSoonChartPlaceholder/ComingSoonChartPlaceholder"
import OrderbookDisclaimer from "./components/OrderbookDisclaimer/OrderbookDisclaimer"
import { OrderListOrderType } from "./components/OrderbookList/types"
import { OrderbookPageFrame } from "./components/OrderbookPageFrame"
import { TradingAccountFrame } from "./components/TradingAccount/TradingAccountFrame"
import { useOrderbookStore } from "./store/useOrderbookStore"
import { WiredAccountSettingDialog } from "./wiredComponents/WiredAccountSettingDialog"
import { WiredB20TradePointsContent } from "./wiredComponents/WiredB20TradePointsContent"
import { WiredCreateOrder } from "./wiredComponents/WiredCreateOrder"
import WiredLoginModal from "./wiredComponents/WiredLoginModal"
import { WiredMarketSelector } from "./wiredComponents/WiredMarketSelector"
import { WiredOrderHistory } from "./wiredComponents/WiredOrderHistory"
import {
  WiredMyTradesOrderListContent,
  WiredOrderbookListContent,
  WiredOrderbookListFrame,
  WiredTradesOrderListContent,
} from "./wiredComponents/WiredOrderbookList"
import { WiredSummaryBar } from "./wiredComponents/WiredSummaryBar"
import {
  WiredTestNetworkSetupGuideModalContent,
  WiredTradingAccountContent,
} from "./wiredComponents/WiredTradingAccountContent"

const OrderbookPageContent: FC = () => {
  const store = useOrderbookStore()
  const appEnv = useAppEnvStore()

  const [connect] = useConnect()

  return (
    <div className={"flex flex-col gap-y-2.5 m-2.5"}>
      <WiredSummaryBar />
      <OrderbookPageFrame
        orderbookList={p => (
          <WiredOrderbookListFrame
            className={clsx(p.className, "min-h-[600px]")}
          >
            {(() => {
              if (
                store.orderbook.showingOrderType ===
                OrderListOrderType.OrderbookOrders
              ) {
                return <WiredOrderbookListContent />
              } else if (
                store.orderbook.showingOrderType ===
                OrderListOrderType.TradeOrders
              ) {
                return <WiredTradesOrderListContent />
              } else if (
                store.orderbook.showingOrderType ===
                OrderListOrderType.MyTradeOrders
              ) {
                return <WiredMyTradesOrderListContent />
              } else {
                assertNever(store.orderbook.showingOrderType)
              }
            })()}
          </WiredOrderbookListFrame>
        )}
        tradingView={p =>
          safelyGet(() => appEnv.config$.enableOrderbookTradingView) ? (
            <ChartContainer
              key={store.marketId$}
              className={p.className}
              datafeed={store.chartDatafeed}
              interval={"15" as ResolutionString}
              symbol={store.marketId$}
            />
          ) : (
            <ComingSoonChartPlaceholder className={p.className} />
          )
        }
        marketSelector={p => <WiredMarketSelector className={p.className} />}
        accountInfoPanel={p => (
          <TradingAccountFrame
            className={clsx(p.className)}
            gapClassName={"gap-4"}
            onSettingClick={action(
              () => (store.accountSetting.showSettingModal = true),
            )}
            hasRegistered={suspenseResource(() => store.myInfo.hasRegistered$)}
          >
            <Suspense fallback={<LoadingIndicator className="m-auto" />}>
              <WiredTradingAccountContent gapClassName={"gap-4"} />
              <WiredTestNetworkSetupGuideModalContent />
            </Suspense>
            <WiredAccountSettingDialog />
          </TradingAccountFrame>
        )}
        createOrderPanel={p => <WiredCreateOrder className={p.className} />}
        tradePointsPanel={p => (
          <WiredB20TradePointsContent className={p.className} />
        )}
      />
      <WiredOrderHistory />
      <OrderbookDisclaimer />
      <Suspense>
        <WiredLoginModal />
      </Suspense>
      <WiredTransactionStateDialog store={store.txStore} />
      <WalletNotSupportedAlert />
    </div>
  )
}

const WalletNotSupportedAlert: FC = () => {
  const [dismissed, setDismissed] = useState(false)
  const authStore = useAuthStore()
  const showAlert = safelyGet(() => authStore.supportSignTransaction$) === false
  const { $t } = useIntl()
  return (
    <Modal visible={showAlert && !dismissed} onClose={() => setDismissed(true)}>
      <CardModalContent
        className={"w-[480px] flex flex-col"}
        title={$t(
          defineMessage({
            defaultMessage: "Switch Wallet",
            description: "Wallet not supporting b20",
          }),
        )}
        onClose={() => setDismissed(true)}
      >
        <WarnIcon width={60} height={60} style={{ alignSelf: "center" }} />
        <div className={"text-center text-base font-semibold mt-4"}>
          {$t(
            defineMessage({
              defaultMessage:
                "B20 is not yet compatible with {wallet}. Please switch to another wallet.",
              description: "Wallet not supporting b20",
            }),
            {
              wallet: authStore.connectedProvider.get() ?? "your wallet",
            },
          )}
        </div>
        <div className="flex gap-x-2">
          <WhiteBorderButton
            onClick={() => setDismissed(true)}
            className="flex-1"
          >
            {$t(cancel$t)}
          </WhiteBorderButton>
          <GradientFilledButton
            onClick={() => authStore.showWalletSelector()}
            className="flex-1"
          >
            {$t(
              defineMessage({
                defaultMessage: "Switch Wallet",
                description: "Wallet not supporting b20",
              }),
            )}
          </GradientFilledButton>
        </div>
      </CardModalContent>
    </Modal>
  )
}

export const OrderbookPage: FC = () => {
  usePreventGlobalWiredDisclaimerModal()
  const logo = useMemo(() => <AlexBRC20Logo />, [])
  useSetTopNavBarLogoExtra(logo)

  const { $t } = useIntl()
  const appEnv = useAppEnvStore()
  const store = useOrderbookStore()

  const marketId = useParams().market
  const [params] = useSearchParams()

  const market = useMemo(
    () => store.info.allAccessibleMarkets$.find(a => a.marketId === marketId),
    [marketId, store.info.allAccessibleMarkets$],
  )

  if (market == null) {
    throw new Error(
      $t(
        defineMessage({
          description: "Orderbook/Market not found",
          defaultMessage: "Invalid market: {market}",
        }),
        { market: marketId },
      ),
    )
  }

  useEffect(() => {
    store.setMarket(market)

    const screen = getScreensFromParams(params)
    if (screen?.type === ScreenType.PegIn) {
      void Promise.all([
        waitFor(() => store.depositFlow.onStartFlow$),
        waitFor(() => store.brc20Pegging.pegInForm.availablePegInCurrencies$),
      ]).then(([onStartFlow, availablePegInCurrencies]) => {
        onStartFlow({
          startFrom: "depositBrc20Guide",
          pegInCurrency: oneOf(...availablePegInCurrencies)(screen.token)
            ? (screen.token as BRC20Currency)
            : undefined,
        })
      })
    }
  }, [market, params, store])

  if (appEnv.config$.enableOrderBook) {
    return (
      <RootLayoutContainer fullWidth={true} topExtraPadding={10}>
        <Suspense>
          <OrderbookPageContent />
        </Suspense>
      </RootLayoutContainer>
    )
  } else {
    return <ComingSoonPage />
  }
}
