import { FC, Suspense } from "react"
import { EXPLORER_TX_URL } from "../../../../../../config"
import { useAuthStore } from "../../../../../../stores/authStore/useAuthStore"
import { assertNever } from "../../../../../../utils/types"
import { LaunchingStatus } from "../../../../store/LaunchPadContractStore"
import { useLaunchPadContractStore } from "../../../../store/useLaunchPadStores"
import { ActionSectionContent$AfterRegistered } from "../../_/ActionSectionContent$AfterRegistered"
import { ActionSectionContent$ClaimForNotRegistered } from "../../_/ActionSectionContent$ClaimForNotRegistered"
import { ActionSectionContent$FinishedFailed } from "../../_/ActionSectionContent$FinishedFailed"
import { ActionSectionContent$FinishedSucceed } from "../../_/ActionSectionContent$FinishedSucceed"
import { ActionSectionContent$Upcoming } from "../../_/ActionSectionContent$Upcoming/ActionSectionContent$Upcoming"
import { WiredActionSectionContent$BeforeRegistered } from "./WiredActionSectionContent$BeforeRegistered"
import { WiredActionSectionContent$ClaimForRegistered } from "./WiredActionSectionContent$ClaimForRegistered"

export const WiredActionSectionContent: FC<{
  onLearnMoreAPowerRule?: () => void
}> = props => {
  const store = useLaunchPadContractStore()
  const authStore = useAuthStore()

  if (store.tokenProfileViewModule.status.type === LaunchingStatus.Finished) {
    return store.tokenProfileViewModule.status.success ? (
      <ActionSectionContent$FinishedSucceed
        idoToken={store.tokenInfo$}
        priceToken={store.priceTokenInfo$}
        tokenIDOSuccessSummary={store.tokenProfileViewModule}
        lotteryTickets={store.claimViewModule.tickets}
      />
    ) : (
      <ActionSectionContent$FinishedFailed
        priceToken={store.priceTokenInfo$}
        userAssets={
          authStore.isWalletConnected &&
          store.userIDOViewModule.hasValidated &&
          store.userIDOViewModule.lockedPriceToken$ > 0
            ? {
                registeredTicketCount:
                  store.userIDOViewModule.remainingRegisteredTicketCount,
                lockedAPowerCount: store.userIDOViewModule.lockedAPower$,
                lockedPriceTokenCount:
                  store.userIDOViewModule.lockedPriceToken$,
                returnPriceTokenExplorerLink:
                  store.claimViewModule.refundTxs.value$[0] != null
                    ? EXPLORER_TX_URL(store.claimViewModule.refundTxs.value$[0])
                    : undefined,
              }
            : undefined
        }
      />
    )
  }

  if (store.tokenProfileViewModule.status.type === LaunchingStatus.Claim) {
    return store.userIDOViewModule.hasValidated ? (
      <WiredActionSectionContent$ClaimForRegistered />
    ) : (
      <ActionSectionContent$ClaimForNotRegistered
        token={store.tokenInfo$}
        currentAllocationTokenCount={
          store.tokenProfileViewModule.totalAllocation
        }
      />
    )
  }

  if (
    store.tokenProfileViewModule.status.type === LaunchingStatus.Registration
  ) {
    return !store.userIDOViewModule.hasValidated ? (
      <Suspense fallback={null}>
        <WiredActionSectionContent$BeforeRegistered
          onLearnMoreAPowerRule={props.onLearnMoreAPowerRule}
        />
      </Suspense>
    ) : (
      <ActionSectionContent$AfterRegistered
        currentBlock={store.currentBlock$}
        startedAt={store.steps.claimStartedAt$}
        startedAtBlock={store.steps.claimStartedAtBlock$}
        idoToken={store.tokenInfo$}
        priceToken={store.priceTokenInfo$}
        lotteryTickets={store.claimViewModule.tickets}
      />
    )
  }

  if (store.tokenProfileViewModule.status.type === LaunchingStatus.Upcoming) {
    return (
      <ActionSectionContent$Upcoming
        priceToken={store.priceTokenInfo$}
        idoPrices={store.tokenProfileViewModule}
        currentBlock={store.currentBlock$}
        validateStepStartedAt={store.steps.registrationStartedAt$}
        validateStepStartedAtBlock={store.steps.registrationStartedAtBlock$}
        onLearnMoreAPowerRule={props.onLearnMoreAPowerRule}
      />
    )
  }

  assertNever(store.tokenProfileViewModule.status.type)
}
