import { groupBy, sumBy } from "lodash"
import { TokenInfo } from "../../utils/models/TokenInfo"
import {
  readResource,
  suspenseResource,
  SuspenseResource,
} from "../../utils/SuspenseResource"

export enum StakeCycleStatus {
  Finished = "finished",
  InProgress = "current",
  Upcoming = "upcoming",
}

export interface ClaimTokenInfo {
  token: TokenInfo
  count: number
  countToUSD?: SuspenseResource<number>
}

export type RewardInfos = [ClaimTokenInfo, ...ClaimTokenInfo[]]

export interface StakeCycle {
  cycleNumber: number
  cycleStatus: StakeCycleStatus
  apr: number

  totalStaked: ClaimTokenInfo
  myStaked: ClaimTokenInfo
  principalToClaim: ClaimTokenInfo[]
  rewardToClaim: ClaimTokenInfo[]

  currentBlock: number
  fromBlock: number
  targetBlock: number
  targetDate: Date
}

export interface AutoStakeCycle {
  cycleNumber: number
  cycleStatus: StakeCycleStatus
  apy: number

  poolVolume: number
  myStaked: number
  intrinsicAlexValue: number

  currentBlock: number
  fromBlock: number
  targetBlock: number
  targetDate: Date
}

export interface MixedStakeCycle {
  cycleNumber: number
  cycleStatus: StakeCycleStatus

  totalStakedAlexCount: number

  autoAlexTokenCount: number
  autoAlexIntrinsicAlexValue: number
  autoStakingApy: number

  manualStakingAlexTokenCount: number
  manualStakingClaimable: ClaimTokenInfo[]
  manualStakingApr: number

  currentBlock: number
  fromBlock: number
  targetBlock: number
  targetDate: Date
}

export interface ClaimTokenInfo {
  token: TokenInfo
  count: number
  countToUSD?: SuspenseResource<number>
}
export namespace ClaimTokenInfo {
  export function groupSameTokenInfo(
    infos: ClaimTokenInfo[],
  ): [ClaimTokenInfo, ...ClaimTokenInfo[]][] {
    const grouped = groupBy(infos, info => info.token.id)
    return Object.values(grouped)
  }

  export function mergeSameTokenInfo(
    infos: ClaimTokenInfo[],
  ): ClaimTokenInfo[] {
    const grouped = groupSameTokenInfo(infos)
    return grouped.map(group => ({
      token: group[0].token,
      count: sumBy(group, info => info.count),
      countToUSD: group.some(g => g.countToUSD != null)
        ? suspenseResource(() =>
            sumBy(group, info => readResource(info.countToUSD ?? 0)),
          )
        : undefined,
    }))
  }
}

export interface AutoAlexCyclePrice {
  cycleNumber: number
  intrinsicValue: SuspenseResource<number>
}
