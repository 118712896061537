import { CSSProperties, FC } from "react"
import { defineMessage, useIntl } from "react-intl"
import { RectButton } from "../../pages/Orderbook/components/wrappedCommonComponents/RectButton"
import { SuspenseResource, readResource } from "../../utils/SuspenseResource"
import { dontWrapObserver } from "../../utils/mobxHelpers"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { Card } from "../Card"
import { LayoutContainer } from "../LayoutContainer/LayoutContainer"
import { Spensor } from "../Spensor"
import { TimeCountdownText } from "../TimeCountdown/TimeCountdownText"
import { shortFormatDuration } from "../TimeCountdown/helpers"
import { TokenCount } from "../TokenCount"
import { TokenName } from "../TokenName"
import { WhiteFilledButton } from "../button/variants/WhiteFilledButton"
import { ReactComponent as ClockIcon } from "./_/clock.svg"

export const TestnetRewardsClaimBanner: FC<{
  rewardToken: SuspenseResource<TokenInfo>
  rewardTokenCount: SuspenseResource<number>
  cutDownTime: SuspenseResource<Date>
  onSubmit: () => void
  style?: CSSProperties
}> = props => {
  const { $t } = useIntl()
  return (
    <Card
      className="rounded-none "
      boxClassName="py-2"
      backgroundImage="var(--orangetoblue, linear-gradient(90deg, #EA580C 0%, #0B54FF 100%))"
      style={props.style}
    >
      <LayoutContainer className={"flex justify-between items-center gap-4"}>
        <div className="flex justify-between gap-8 items-center overflow-y-auto">
          <div className="flex flex-col gap-1 flex-none">
            <h3 className="text-yellow-200 font-semibold text-lg">
              {$t(
                defineMessage({
                  defaultMessage: "🎉 Testnet Rewards Claim",
                  description: "UI/Testnet rewards claim banner title",
                }),
              )}
            </h3>
            <p className="text-white text-xs">
              {$t(
                defineMessage({
                  defaultMessage:
                    "Thank you for your contribution during the testnet. Please claim your <strong>rewards by the claim period end.</strong>",
                  description: "UI/Testnet rewards claim banner description",
                }),
                {
                  strong: dontWrapObserver(contents => (
                    <strong>{contents}</strong>
                  )),
                },
              )}
            </p>
          </div>
          <div className="flex text-white gap-5 items-center pr-4">
            <div className="flex flex-col items-end gap-1 flex-none">
              <p className="text-base font-semibold">
                {$t(
                  defineMessage({
                    defaultMessage: "Your rewards: {tokenCount} {tokenName}",
                    description: "UI/Testnet rewards claim reward text",
                  }),
                  {
                    tokenName: (
                      <TokenName token={readResource(props.rewardToken)} />
                    ),
                    tokenCount: (
                      <TokenCount
                        token={props.rewardToken}
                        count={props.rewardTokenCount}
                      />
                    ),
                  },
                )}
              </p>
              <p className="text-xs flex items-center gap-1.5">
                <ClockIcon />
                <span>
                  {$t(
                    defineMessage({
                      defaultMessage: " Claim countdown: {cutDownText}",
                      description:
                        "UI/Testnet rewards claim banner countdown text",
                    }),
                    {
                      cutDownText: (
                        <Spensor fallback="--">
                          {() => (
                            <TimeCountdownText
                              time={readResource(props.cutDownTime)}
                              format={d =>
                                shortFormatDuration(d, {
                                  includes: ["days", "hours", "minutes"],
                                })
                              }
                            />
                          )}
                        </Spensor>
                      ),
                    },
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <RectButton
          className={"flex flex-row justify-center items-center gap-x-1"}
          boxClassName={"h-10 sm:px-14 px-6"}
          Variant={WhiteFilledButton}
          onClick={props.onSubmit}
        >
          {$t(
            defineMessage({
              defaultMessage: "Claim",
              description: "UI/Testnet rewards claim banner claim button",
            }),
          )}
        </RectButton>
      </LayoutContainer>
    </Card>
  )
}
