import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { WhiteFilledButton } from "../../../../components/button/variants/WhiteFilledButton"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import {
  NoteParagraph,
  WarnIcon,
} from "../../../../components/NoteParagraph/NoteParagraph"
import { TokenCount } from "../../../../components/TokenCount"
import {
  BlockTokenLine,
  DefaultTokenNameArea,
  renderThinnerBlock,
} from "../../../../components/TokenInput/Block"
import { BlockGroup } from "../../../../components/TokenInput/BlockGroup"
import { TokenCountPairPlain } from "../../../../components/TokenInput/TokenBlock"
import { dontWrapObserver } from "../../../../utils/mobxHelpers"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"

export interface BuyLotteryModalContentProps {
  lotteryTicketCount: number
  prices: TokenCountPairPlain[]

  onConfirm?: () => void
  onCancel?: () => void
}

export const ConfirmBuyLotteryModalContent: FC<
  BuyLotteryModalContentProps
> = props => {
  const { $t } = useIntl()
  return (
    <CardModalContent title={"Buy Lottery"} onClose={props.onCancel}>
      <BlockGroup
        firstBlock={renderThinnerBlock({
          children: (
            <BlockTokenLine
              tokenNameArea={
                <DefaultTokenNameArea token={TokenInfoPresets.LotteryTicket} />
              }
              tokenCountArea={
                <TokenCount
                  token={TokenInfoPresets.LotteryTicket}
                  count={props.lotteryTicketCount}
                />
              }
            />
          ),
        })}
        secondBlock={renderThinnerBlock({
          children: (
            <ul className={"flex flex-col gap-2.5"}>
              {props.prices.map((p, idx) => (
                <BlockTokenLine
                  key={idx}
                  tokenNameArea={<DefaultTokenNameArea token={p.token} />}
                  tokenCountArea={
                    <TokenCount token={p.token} count={p.count} />
                  }
                />
              ))}
            </ul>
          ),
        })}
      />
      <NoteParagraph textColorClassName={"text-yellow-500"} icon={<WarnIcon />}>
        {$t<ReactNode>(
          defineMessage({
            defaultMessage: `<line>Lottery purchase can only be submitted ONCE per wallet per Lottery round.</line>
            <line>Please be certain you are entering your final values.</line>`,
            description: "/Lottery/ConfirmBuyLotteryModalContent/Note",
          }),
          {
            line: dontWrapObserver(children => <p>{children}</p>),
          },
        )}
      </NoteParagraph>
      <div className={"flex flex-row gap-2.5"}>
        <WhiteFilledButton className={"flex-1"} onClick={props.onCancel}>
          {$t(
            defineMessage({
              defaultMessage: "Cancel",
              description: "/Lottery/ConfirmBuyLotteryModalContent/Button text",
            }),
          )}
        </WhiteFilledButton>
        <GradientFilledButton className={"flex-1"} onClick={props.onConfirm}>
          {$t(
            defineMessage({
              defaultMessage: "Confirm",
              description: "/Lottery/ConfirmBuyLotteryModalContent/Button text",
            }),
          )}
        </GradientFilledButton>
      </div>
    </CardModalContent>
  )
}
