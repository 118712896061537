import { useContext, useMemo } from "react"
import { FCC } from "../../utils/reactHelpers/types"
import {
  InfoListRenderersContext,
  InfoListRenderersContextValue,
} from "./_/InfoListRenderersContext"

export const InfoListProvider: FCC<
  Partial<InfoListRenderersContextValue> & {
    inheritRenderers?: boolean
  }
> = props => {
  const parentCtxValue = useContext(InfoListRenderersContext)

  const getValue = <K extends keyof InfoListRenderersContextValue>(
    key: K,
  ): InfoListRenderersContextValue[K] => {
    return (
      (props.inheritRenderers ? parentCtxValue[key] : undefined) ??
      (props[key] as any)
    )
  }
  const renderInfoListItem = getValue("renderInfoListItem")
  const renderInfoListItemTitle = getValue("renderInfoListItemTitle")
  const renderInfoListItemDetail = getValue("renderInfoListItemDetail")
  const ctxValue: InfoListRenderersContextValue = useMemo(
    () => ({
      renderInfoListItem,
      renderInfoListItemTitle,
      renderInfoListItemDetail,
    }),
    [renderInfoListItem, renderInfoListItemDetail, renderInfoListItemTitle],
  )

  return (
    <InfoListRenderersContext.Provider value={ctxValue}>
      {props.children}
    </InfoListRenderersContext.Provider>
  )
}
