import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { GrayBadge, GreenBadge, YellowBadge } from "../../../components/Badge"
import { OpacityButton } from "../../../components/button/variants/OpacityButton"
import {
  Card,
  CardDivider,
  CardInset,
  CardInsetDescription,
  CardTitle,
} from "../../../components/Card"
import { PercentNumber } from "../../../components/PercentNumber"
import { IconTokenCount } from "../../../components/RichTokenCount"
import { Spensor } from "../../../components/Spensor"
import { TokenCount } from "../../../components/TokenCount"
import { TokenCountPairPlain } from "../../../components/TokenInput/TokenBlock"
import { TokenName } from "../../../components/TokenName"
import { readResource, SuspenseResource } from "../../../utils/SuspenseResource"
import { LotteryStage, LotteryStageType } from "./types"
import { Burn } from "./_/Burn"
import { CopperCrown } from "./_/CopperCrown"
import { GoldenCrown } from "./_/GoldenCrown"
import { SilverCrown } from "./_/SilverCrown"

export const PrizePoolPanel: FC<{
  className?: string
  roundNumber: SuspenseResource<number>
  lotteryStage: SuspenseResource<LotteryStage>
  totalLotteryPrize: SuspenseResource<TokenCountPairPlain>
  alexOfferedLotteryPrize: SuspenseResource<TokenCountPairPlain>
  onClickRules: () => void
}> = props => {
  const { $t } = useIntl()

  return (
    <Card className={clsx("flex flex-col gap-6", props.className)}>
      <div className={"flex flex-row items-center"}>
        <CardTitle className={"flex items-center"}>
          {$t(
            defineMessage({
              defaultMessage: "Prize Pool",
              description: "/Lottery/PrizePoolPanel/Card Title",
            }),
          )}
          &nbsp;
          <Spensor>
            {() => (
              <StatusBadge stageType={readResource(props.lotteryStage).type} />
            )}
          </Spensor>
        </CardTitle>
        <span className={"ml-auto text-lg text-gray-400"}>
          {$t(
            defineMessage({
              defaultMessage: "Round #",
              description: "/Lottery/PrizePoolPanel/Content",
            }),
          )}
          <Spensor fallback="--">
            {() => readResource(props.roundNumber)}
          </Spensor>
        </span>
      </div>

      <div className={"flex flex-col gap-2.5"}>
        <Spensor>
          {() => (
            <IconTokenCount
              iconSize={32}
              className={"text-2xl font-medium text-yellow-200"}
              token={readResource(props.totalLotteryPrize).token}
              count={readResource(props.totalLotteryPrize).count}
            />
          )}
        </Spensor>

        <Spensor>
          {() => (
            <CardInsetDescription>
              {$t(
                defineMessage({
                  defaultMessage:
                    "( {tokenCount} {tokenName} from ALEX Lab Foundation )",
                  description: "/Lottery/PrizePoolPanel/Description",
                }),
                {
                  tokenCount: (
                    <TokenCount
                      token={readResource(props.alexOfferedLotteryPrize).token}
                      count={readResource(props.alexOfferedLotteryPrize).count}
                    />
                  ),
                  tokenName: (
                    <TokenName
                      token={readResource(props.alexOfferedLotteryPrize).token}
                    />
                  ),
                },
              )}
            </CardInsetDescription>
          )}
        </Spensor>
        <CardDivider className={"w-full"} />
      </div>
      <CardInset className={"flex flex-col gap-4"} boxClassName={"p-4"}>
        <ul className={"flex flex-col gap-2.5"}>
          <li>
            <PoolRow
              Icon={GoldenCrown}
              title={$t(
                defineMessage({
                  defaultMessage: "1st Prize Pool",
                  description: "/Lottery/PrizePoolPanel/Pool row title",
                }),
              )}
              detail={<PercentNumber number={0.5} />}
            />
          </li>
          <li>
            <PoolRow
              Icon={SilverCrown}
              title={$t(
                defineMessage({
                  defaultMessage: "2nd Prize Pool",
                  description: "/Lottery/PrizePoolPanel/Pool row title",
                }),
              )}
              detail={<PercentNumber number={0.3} />}
            />
          </li>
          <li>
            <PoolRow
              Icon={CopperCrown}
              title={$t(
                defineMessage({
                  defaultMessage: "3rd Prize Pool",
                  description: "/Lottery/PrizePoolPanel/Pool row title",
                }),
              )}
              detail={<PercentNumber number={0.2} />}
            />
          </li>
          <li>
            <PoolRow
              Icon={Burn}
              title={$t(
                defineMessage({
                  defaultMessage: "Burn",
                  description: "/Lottery/PrizePoolPanel/Pool row title",
                }),
              )}
              detail={
                <>
                  {$t(
                    defineMessage({
                      defaultMessage: "Winning Amount {percentNumber}",
                      description: "/Lottery/PrizePoolPanel/Pool row detail",
                    }),
                    {
                      percentNumber: (
                        <>
                          &times; <PercentNumber number={0.2} />
                        </>
                      ),
                    },
                  )}
                </>
              }
            />
          </li>
        </ul>
      </CardInset>
      <div>
        <OpacityButton
          className={"text-base font-medium min-w-[240px]"}
          onClick={props.onClickRules}
        >
          {$t(
            defineMessage({
              defaultMessage: "Lottery Rules",
              description: "/Lottery/PrizePoolPanel/Button text",
            }),
          )}
        </OpacityButton>
      </div>
    </Card>
  )
}

const StatusBadge: FC<{ stageType: LotteryStage["type"] }> = props => {
  const { $t } = useIntl()
  return (
    <>
      {props.stageType === LotteryStageType.Finished ? (
        <GrayBadge className={"uppercase"}>
          {$t(
            defineMessage({
              defaultMessage: "Finished",
              description: "/Lottery/PrizePoolPanel/Status Badge",
            }),
          )}
        </GrayBadge>
      ) : props.stageType === LotteryStageType.Drawing ? (
        <YellowBadge className={"uppercase"}>
          {$t(
            defineMessage({
              defaultMessage: "Drawing",
              description: "/Lottery/PrizePoolPanel/Status Badge",
            }),
          )}
        </YellowBadge>
      ) : (
        <GreenBadge className={"uppercase"}>
          {$t(
            defineMessage({
              defaultMessage: "Live",
              description: "/Lottery/PrizePoolPanel/Status Badge",
            }),
          )}
        </GreenBadge>
      )}
    </>
  )
}

const PoolRow: FC<{
  Icon: (props: { width: number; height: number }) => null | JSX.Element
  title: ReactNode
  detail: ReactNode
}> = props => {
  return (
    <div className={"flex gap-2.5 items-center"}>
      <props.Icon width={24} height={24} />
      <div className={"text-sm text-white"}>{props.title}</div>
      <div className={"text-base font-bold text-gray-200 ml-auto"}>
        {props.detail}
      </div>
    </div>
  )
}
