import { FC, ReactNode } from "react"

export const NoteParagraphWrap: FC<{
  className?: string
  children: ReactNode
}> = props => {
  return (
    <div className={props.className}>
      <div className="flex bg-white/5 rounded-lg px-4">{props.children}</div>
    </div>
  )
}
