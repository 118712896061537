import { createContext, FC, useCallback, useContext, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { FCC } from "../../utils/reactHelpers/types"
import {
  readResource,
  suspenseResource,
  SuspenseResource,
} from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { MayDollarSignTokenName } from "../MayDollarSignTokenName"
import { Spensor } from "../Spensor"
import { TipIcon } from "../TipIcon/TipIcon"
import { TokenName } from "../TokenName"

const APowerRatioContext = createContext<SuspenseResource<number>>(0.3)

export const APowerRatioProvider: FCC<{
  ratio: SuspenseResource<number>
}> = props => {
  return (
    <APowerRatioContext.Provider value={props.ratio}>
      {props.children}
    </APowerRatioContext.Provider>
  )
}

export const useAPowerRatio = (): {
  ratio: SuspenseResource<number>
  displayableRatio: SuspenseResource<string>
} => {
  const ratio = useContext(APowerRatioContext)

  return useMemo(
    () => ({
      ratio,
      displayableRatio: suspenseResource(() => readResource(ratio).toString()),
    }),
    [ratio],
  )
}

export const APowerTip: FC<{
  className?: string
}> = props => {
  const { displayableRatio } = useAPowerRatio()
  const { $t } = useIntl()
  const getCopy = useCallback(
    (ratio: string) =>
      $t(
        defineMessage({
          defaultMessage:
            "Gain {aPower} to increase your access to IDOs on the ALEX Launchpad. \nThe amount of {aPower} received will be ${ratio}x the amount of {alexToken}.",
          description: "/Components/APowerTip/Tip text",
        }),
        {
          aPower: <TokenName token={TokenInfoPresets.APower} />,
          alexToken: (
            <MayDollarSignTokenName token={TokenInfoPresets.MockAlex} />
          ),
          ratio,
        },
      ),
    [$t],
  )

  return (
    <Spensor
      fallback={<TipIcon className={props.className} tip={getCopy("...")} />}
    >
      {() => (
        <TipIcon
          className={props.className}
          tip={getCopy(readResource(displayableRatio))}
        />
      )}
    </Spensor>
  )
}
