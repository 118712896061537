import * as Sentry from "@sentry/react"
import { FC, ReactNode, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { ReactComponent as AlexLogo } from "../components/TopNavBar/alexLogo.svg"
import { ReactComponent as AlexLogoText } from "../components/TopNavBar/alexLogoText.svg"
import { FCC } from "../utils/reactHelpers/types"
import { ErrorBoundary } from "./ErrorBoundary"
import { GradientText } from "./GradientText"
import { WhiteFilledButton } from "./button/variants/WhiteFilledButton"

export const GlobalErrorBoundaryPage: FCC = props => {
  const { $t } = useIntl()

  const [eventId, setEventId] = useState<string>()

  return (
    <ErrorBoundary
      onDidCatchError={(error, errorInfo) => {
        Sentry.withScope(scope => {
          scope.setExtras({ errorInfo })
          setEventId(Sentry.captureException(error))
        })
      }}
      fallback={({ error, errorInfo }) => (
        <GlobalErrorBoundaryPageContent
          title={$t(
            defineMessage({
              defaultMessage: "Oops! Something went wrong.",
              description: "/Compoents/GlobalErrorBoundaryPage/Title",
            }),
          )}
          subTitle={String(
            error != null && error instanceof Error ? error.message : error,
          )}
          stacks={`${
            error != null && error instanceof Error ? error.stack : ""
          }\n\n\n${errorInfo?.componentStack}`}
          actions={
            <>
              <GoToHomePageButton />
              <WhiteFilledButton
                onClick={() => {
                  Sentry.showReportDialog({ eventId })
                }}
              >
                {$t(
                  defineMessage({
                    defaultMessage: "Report Issue",
                    description:
                      "/Compoents/GlobalErrorBoundaryPage/Action button text",
                  }),
                )}
              </WhiteFilledButton>
            </>
          }
        />
      )}
    >
      {props.children}
    </ErrorBoundary>
  )
}

export const GoToHomePageButton: FC = () => {
  const { $t } = useIntl()
  return (
    <WhiteFilledButton
      className="min-w-[240px]"
      onClick={() => (window.location.href = "/")}
    >
      {$t(
        defineMessage({
          defaultMessage: "Go to Home",
          description:
            "/Compoents/GlobalErrorBoundaryPage/GoToHomePageButton/Action button text",
        }),
      )}
    </WhiteFilledButton>
  )
}

export const GlobalErrorBoundaryPageContent: FC<{
  code?: string
  title?: ReactNode
  subTitle?: ReactNode
  stacks?: ReactNode
  actions?: ReactNode
}> = props => (
  <div className="h-screen flex flex-col justify-between px-4 py-12 lg:px-24 lg:py-24">
    <div className="flex justify-between items-center">
      <div className={"flex items-center cursor-pointer gap-1.5"}>
        <AlexLogo width={32} height={32} />
        <AlexLogoText width={106} height={28} />
      </div>
      {props.code && (
        <GradientText
          className={"text-2xl sm:text-3xl leading-9 font-medium uppercase"}
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {props.code}
        </GradientText>
      )}
    </div>
    <div>
      <h1>
        <GradientText
          className={"text-5xl leading-9 font-medium"}
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {props.title}
        </GradientText>
      </h1>
      {props.subTitle && (
        <GradientText
          className={"text-2xl leading-9 font-medium"}
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {props.subTitle}
        </GradientText>
      )}
      {!!props.stacks && (
        <pre className="text-xs max-h-56 overflow-y-scroll text-gray-200">
          {props.stacks}
        </pre>
      )}
    </div>
    <div className="flex gap-2">{props.actions}</div>
  </div>
)

export const NotFoundPage: FC = () => {
  const { $t } = useIntl()
  return (
    <GlobalErrorBoundaryPageContent
      title={$t(
        defineMessage({
          defaultMessage: "Not Found",
          description: "/Compoents/GlobalErrorBoundaryPage/NotFoundPage/Title",
        }),
      )}
      code="404"
      subTitle={$t(
        defineMessage({
          defaultMessage:
            "ALEX provide complete defi on bitcoin, but apparently you have entered a incomplete page.",
          description:
            "/Compoents/GlobalErrorBoundaryPage/NotFoundPage/SubTitle",
        }),
      )}
      actions={<GoToHomePageButton />}
    />
  )
}
