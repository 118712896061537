import { FC, useCallback } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardDescription, CardTitle } from "../../../components/Card"
import { NavLink } from "../../../components/NavLink"
import { SystemMessages } from "../types"

interface Message {
  title: string
  description: string
  link: string
  linkText: string
}

export interface SystemMessageListProps {
  data: Array<SystemMessages.Data & { link: string }>
}

export const SystemMessageList: FC<SystemMessageListProps> = props => {
  const { parseData } = useParseData()
  return (
    <div className="flex flex-col gap-y-2.5">
      {props.data
        .map(datum => ({ ...parseData(datum), link: datum.link }))
        .map(message => (
          <NavLink to={message.link}>
            <Card key={message.title} className="flex flex-col gap-y-2">
              <div className="flex flex-wrap">
                <CardTitle className="grow">{message.title}</CardTitle>
              </div>
              <CardDescription>
                <span>{message.description}</span>
                &nbsp;
                <span className="text-blue-600">{message.linkText}</span>
              </CardDescription>
            </Card>
          </NavLink>
        ))}
    </div>
  )
}

function useParseData(): {
  parseData: (data: SystemMessages.Data) => Omit<Message, "link">
} {
  const { $t } = useIntl()
  const parseFarmingData = useCallback(
    (_data: SystemMessages.Data): Omit<Message, "link"> => {
      return {
        title: $t(
          defineMessage({
            defaultMessage: "New Farming rewards to harvest",
            description: "/Notify/System Message List Title",
          }),
        ),
        description: $t(
          defineMessage({
            defaultMessage: "You have received rewards from LP farming.",
            description: "/Notify/System Message List desc",
          }),
        ),
        linkText: $t(
          defineMessage({
            defaultMessage: "Harvest now.",
            description: "/Notify/System Message List link",
          }),
        ),
      }
    },
    [$t],
  )

  const parseStakingData = useCallback(
    (_data: SystemMessages.Data): Omit<Message, "link"> => {
      return {
        title: $t(
          defineMessage({
            defaultMessage: "New Staking rewards to harvest",
            description: "/Notify/System Message List Title",
          }),
        ),
        description: $t(
          defineMessage({
            defaultMessage: "You have received rewards from ALEX staking.",
            description: "/Notify/System Message List desc",
          }),
        ),
        linkText: $t(
          defineMessage({
            defaultMessage: "Harvest now.",
            description: "/Notify/System Message List link",
          }),
        ),
      }
    },
    [$t],
  )

  const parseDepositClaimData = useCallback(
    (_data: SystemMessages.Data): Omit<Message, "link"> => {
      return {
        title: $t(
          defineMessage({
            defaultMessage: "Maturity Deposit to roll over",
            description: "/Notify/System Message List Title",
          }),
        ),
        description: "",
        linkText: $t(
          defineMessage({
            defaultMessage: "Click for details.",
            description: "/Notify/System Message List link",
          }),
        ),
      }
    },
    [$t],
  )
  const parseBorrowClaimData = useCallback(
    (_data: SystemMessages.Data): Omit<Message, "link"> => {
      return {
        title: $t(
          defineMessage({
            defaultMessage: "Matured Borrow Redemption to claim/rollover",
            description: "/Notify/System Message List Title",
          }),
        ),
        description: "",
        linkText: $t(
          defineMessage({
            defaultMessage: "Click for details.",
            description: "/Notify/System Message List link",
          }),
        ),
      }
    },
    [$t],
  )

  const parseData = useCallback(
    (data: SystemMessages.Data): Omit<Message, "link"> => {
      if (data.type === SystemMessages.Types.FarmingHarvest) {
        return parseFarmingData(data)
      }
      if (data.type === SystemMessages.Types.StakingHarvest) {
        return parseStakingData(data)
      }
      if (data.type === SystemMessages.Types.DepositClaim) {
        return parseDepositClaimData(data)
      }
      if (data.type === SystemMessages.Types.BorrowClaim) {
        return parseBorrowClaimData(data)
      }
      throw new Error(
        $t(
          defineMessage({
            defaultMessage: "Invalid data",
            description: "/Notify/System Message List",
          }),
        ),
      )
    },
    [
      parseBorrowClaimData,
      parseDepositClaimData,
      parseFarmingData,
      parseStakingData,
      $t,
    ],
  )
  return {
    parseData,
  } as const
}
