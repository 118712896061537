import { gql } from "@urql/core"
import { Observable } from "rxjs"
import {
  GetFarmsListQuery,
  GetFarmsListQueryVariables,
} from "../../../generated/graphql/graphql.generated"
import { fromUrqlSource } from "../../../utils/Observable/fromUrqlSource"
import { Currency } from "../../../utils/alexjs/Currency"
import { first } from "../../../utils/arrayHelpers"
import { gqlQuery } from "../../../utils/graphqlHelpers"
import { isNotNull, optionally } from "../../../utils/utils"
import { StakingToken } from "../../Stake/store/manualStaking/ManualStakeStore.service"
import type { FarmListItem } from "./FarmStore"

export function getFarmsList(
  pools: StakingToken[],
  currentCycle: number,
  v1StakeEndCycle: number | undefined,
): Observable<
  (Omit<FarmListItem, "liquidity"> & { liquidityInTokenX?: number })[]
> {
  return fromUrqlSource(
    gqlQuery<GetFarmsListQuery, GetFarmsListQueryVariables>(
      gql`
        query GetFarmsList($tokenNames: [String!]!, $poolIds: [numeric!]!) {
          laplace_latest_pool_stats(
            where: { pool_token: { _in: $tokenNames } }
          ) {
            pool_token
            liquidity
            apr_7d
          }
          laplace_latest_amm_pool_stats_v1_01(
            where: { pool_id: { _in: $poolIds } }
          ) {
            pool_id
            liquidity
            apr_7d
          }
          laplace_latest_alex_reserve_pools(
            where: { token_name: { _in: $tokenNames } }
          ) {
            token_name
            earning_preview
          }
          laplace_latest_alex_reserve_pool_sft(
            where: { token_id: { _in: $poolIds } }
          ) {
            token_id
            token_name
            earning_preview
          }
        }
      `,
      {
        tokenNames: pools
          .map(a => (typeof a === "string" ? a : null))
          .filter(isNotNull),
        poolIds: pools
          .map(a => (typeof a === "string" ? null : a.tokenId))
          .filter(isNotNull),
      },
    ),
    ({ data }) =>
      pools.map(pool => {
        if (typeof pool !== "string") {
          if (pool.currency !== Currency.AMM_SWAP_POOL_V1_1) {
            throw new Error("Only farming AMM V1.1 pools are supported")
          }
          const f = data.laplace_latest_alex_reserve_pool_sft?.find(
            d => d.token_id === pool.tokenId,
          )
          const l = data.laplace_latest_amm_pool_stats_v1_01.find(
            d => d.pool_id === pool.tokenId,
          )
          return {
            token: pool.representingCurrency,
            liquidityInTokenX: optionally(l?.liquidity, e => e / 1e8),
            apr: f?.earning_preview
              ? first(
                  f.earning_preview.slice(
                    0,
                    v1StakeEndCycle != null
                      ? v1StakeEndCycle - currentCycle
                      : undefined,
                  ),
                )
              : undefined,
            fees: optionally(l?.apr_7d, a => a / 1e8),
          }
        }
        const f = data.laplace_latest_alex_reserve_pools?.find(
          d => d.token_name === pool,
        )
        const l = data.laplace_latest_pool_stats.find(
          d => d.pool_token === pool,
        )
        return {
          token: pool,
          liquidityInTokenX: optionally(l?.liquidity, e => e / 1e8),
          apr: f?.earning_preview
            ? first(
                f.earning_preview.slice(
                  0,
                  v1StakeEndCycle != null
                    ? v1StakeEndCycle - currentCycle
                    : undefined,
                ),
              )
            : undefined,
          fees: optionally(l?.apr_7d, a => a / 1e8),
        }
      }),
  )
}
