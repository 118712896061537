import clsx from "clsx"
import { FC, ReactNode } from "react"
import {
  InfoList,
  InfoListItem,
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../components/InfoList"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { FCC } from "../../../utils/reactHelpers/types"
import { withClassName } from "../../../utils/reactHelpers/withClassName"
import { ClaimTokenInfo } from "../types"

export const StakingSummaryList: FCC<{
  className?: string
  gapClassName?: string
}> = props => (
  <InfoList
    className={props.className}
    direction={"column"}
    listItemDirection={"row"}
    listItemClassName={props.gapClassName}
  >
    {props.children}
  </InfoList>
)

export const StakingSummaryListItem: FC<{
  className?: string
  titleClassName?: string
  title: ReactNode
  detailClassName?: string
  detail: ReactNode
}> = props => (
  <InfoListItem className={clsx(props.className)}>
    <InfoListItemTitle className={clsx(props.titleClassName)}>
      {props.title}
    </InfoListItemTitle>
    <InfoListItemDetail
      className={clsx("flex items-center font-semibold", props.detailClassName)}
    >
      {props.detail}
    </InfoListItemDetail>
  </InfoListItem>
)

export const TitleText = withClassName("text-sm leading-5 font-normal", "span")

export const ClaimTokenInfoVerticalList: FC<{
  className?: string
  tokenIconSize?: number
  countTextClassName?: string
  infos: ClaimTokenInfo[]
}> = props => (
  <ul
    className={clsx(
      "flex flex-col gap-2.5 items-start sm:items-end",
      props.className,
    )}
  >
    {props.infos.map(({ token, count }, idx) => (
      <li key={idx} className={clsx("flex items-center gap-2 justify-end")}>
        <TokenIcon token={token} size={props.tokenIconSize ?? 24} />
        <span className={clsx(props.countTextClassName)}>
          <TokenCount token={token} count={count} />
        </span>
        <TokenName
          className={"text-base leading-6 font-medium"}
          token={token}
        />
      </li>
    ))}
  </ul>
)
