import { action, computed, makeObservable, observable } from "mobx"
import { EXPLORER_TX_URL } from "../../../../config"
import { sendRequest } from "../../../../generated/stxdxHelpers/stxdxApi"
import { LazyValue } from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import { isNotNull } from "../../../../utils/utils"
import {
  FundHistoryRecord,
  FundHistoryRecordDirection,
  FundHistoryRecordStatus,
  OpenOrderRecord,
  OrdersHistoryRecord,
  OrdersHistoryRecordStatus,
  StxDxOrderStatus,
  TradeHistoryRecord,
} from "../../components/types"
import { OrderbookStore } from "../OrderbookStore"
import {
  ServerOrderData,
  getMyFundsHistory,
  getOrderHistory,
  getTradeHistory,
} from "./OrderbookMyHistoryModule.service"

export class OrderbookMyHistoryModule {
  constructor(readonly store: OrderbookStore) {
    makeObservable(this)
  }

  refresh = new SuspenseObservable<number>()

  @observable filterByCurrentMarket = false
  @action changeFilterByCurrentMarket = (newValue: boolean): void => {
    this.filterByCurrentMarket = newValue
  }

  #myPendingOrders = new LazyValue(
    () => ({
      auth: this.store.myInfo.authJWT$,
      selectedMarketId: this.filterByCurrentMarket
        ? this.store.marketId$
        : undefined,
      allMarkets: this.store.info.allAccessibleMarkets$,
    }),
    ({ auth, selectedMarketId, allMarkets }) =>
      getOrderHistory({
        auth,
        selectedMarketId,
        allMarkets,
        status: StxDxOrderStatus.Matching,
      }),
  )

  #myOrders = new LazyValue(
    () => ({
      auth: this.store.myInfo.authJWT$,
      selectedMarketId: this.filterByCurrentMarket
        ? this.store.marketId$
        : undefined,
      allMarkets: this.store.info.allAccessibleMarkets$,
    }),
    ({ auth, selectedMarketId, allMarkets }) =>
      getOrderHistory({ auth, selectedMarketId, allMarkets, limit: 100 }),
  )

  #myTrades = new LazyValue(
    () => ({
      auth: this.store.myInfo.authJWT$,
      selectedMarketId: this.filterByCurrentMarket
        ? this.store.marketId$
        : undefined,
      _block: this.store.chainStore.currentBlockHash$,
      allMarkets: this.store.info.allAccessibleMarkets$,
    }),
    ({ auth, selectedMarketId, allMarkets }) =>
      getTradeHistory({ auth, allMarkets, selectedMarketId: selectedMarketId }),
  )

  #myFunds = new LazyValue(
    () =>
      [
        this.store.myInfo.authJWT$,
        this.store.myInfo.registeredUserId$,
        this.store.chainStore.currentBlockHash$,
      ] as const,
    ([auth, uid]) => getMyFundsHistory({ auth, uid }),
  )

  @computed get myPendingOrders$(): OpenOrderRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myPendingOrders.value$
      .filter(o => o.status === StxDxOrderStatus.Matching)
      .map(
        order =>
          ({
            ...order,
            tradeToken: this.store.currency.getTokenInfo$(
              order.market.tradeToken,
            ),
            priceToken: {
              ...this.store.currency.getTokenInfo$(order.market.priceToken),
              precision: order.market.pricePrecision,
            },
            onCancel: async () => {
              await sendRequest(this.store.myInfo.authJWT$)(
                "OrderController_cancelOrder",
                {
                  path: {
                    order_hash: order.orderHash,
                  },
                },
              )
            },
          } as OpenOrderRecord),
      )
  }

  @computed get myNonPendingOrders$(): OrdersHistoryRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myOrders.value$
      .filter(o => o.status !== StxDxOrderStatus.Matching)
      .map(
        (order: ServerOrderData) =>
          ({
            ...order,
            executedTradeTokenCount:
              order.filledPercentage * order.expectedTradeTokenCount,
            status:
              //prettier-ignore
              order.status === StxDxOrderStatus.Expired ? OrdersHistoryRecordStatus.Expired :
                order.status === StxDxOrderStatus.Canceled ? OrdersHistoryRecordStatus.Cancelled :
                  OrdersHistoryRecordStatus.Normal,
            tradeToken: this.store.currency.getTokenInfo$(
              order.market.tradeToken,
            ),
            priceToken: {
              ...this.store.currency.getTokenInfo$(order.market.priceToken),
              precision: order.market.pricePrecision,
            },
          } as OrdersHistoryRecord),
      )
  }

  @computed get myTrades$(): TradeHistoryRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myTrades.value$.map(trade => ({
      ...trade,
      tradeToken: this.store.currency.getTokenInfo$(trade.market.tradeToken),
      priceToken: {
        ...this.store.currency.getTokenInfo$(trade.market.priceToken),
        precision: trade.market.pricePrecision,
      },
    }))
  }

  @computed get myFunds$(): FundHistoryRecord[] {
    if (!this.store.myInfo.hasRegistered$) {
      return []
    }
    return this.#myFunds.value$
      .map(fund => {
        if (!(fund.assetId in this.store.info.allAssetIdMaps)) {
          return null
        }
        return {
          status: fund.status as FundHistoryRecordStatus,
          createdAt: fund.createdAt,
          direction: fund.type as FundHistoryRecordDirection,
          explorerLink: EXPLORER_TX_URL(fund.txId),
          transactionId: fund.txId,
          tokenCount: fund.amount,
          token: this.store.currency.getTokenInfo$(
            this.store.info.allAssetIdMaps[fund.assetId]!,
          ),
        }
      })
      .filter(isNotNull)
  }

  @action notifyMayOrderCreated(): void {
    // We re switching to user balance ws channel
    // We no longer need to keep track of new orders manually
    // this.refreshNewOrder.set(this.refreshNewOrder.read$ + 1)
  }
}
