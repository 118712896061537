import { useMemo, useState } from "react"
import { CancelError } from "../../utils/error"
import { defer } from "../../utils/promiseHelpers"
import { FCC } from "../../utils/reactHelpers/types"
import { usePersistFn } from "../../utils/reactHelpers/usePersistFn"
import { Modal } from "../Modal"
import {
  DialogControllerShowOptions,
  DialogControllerShowOptionsFactory,
  DialogControllerShowOptionsFactoryInfo,
  DialogInstance,
} from "./types"
import { DialogContext } from "./_/DialogContext"
import { DialogBaseController } from "./_/types"

export const DialogProvider: FCC = props => {
  const [dialogs, setDialogs] = useState<
    {
      dialogInstance: DialogInstance<unknown>
      options: DialogControllerShowOptions
    }[]
  >([])

  const addDialog = usePersistFn(
    (
      _opts:
        | DialogControllerShowOptions
        | DialogControllerShowOptionsFactory<unknown>,
    ): DialogInstance<unknown> => {
      const valueDefer = defer<unknown>()

      const closeDialog = (): void => {
        setDialogs(dialogs =>
          dialogs.filter(m => m.dialogInstance !== instance),
        )
      }

      const dismissThisDialog = (): void => {
        valueDefer.reject(new CancelError())
        closeDialog()
      }

      const resolveThisDialog = (value: unknown): void => {
        valueDefer.resolve(value)
        closeDialog()
      }

      const instance: DialogInstance<unknown> = {
        value: valueDefer.promise,
        resolve: resolveThisDialog,
        dismiss: dismissThisDialog,
      }

      const factoryInfo: DialogControllerShowOptionsFactoryInfo<unknown> = {
        resolve: resolveThisDialog,
        dismiss: dismissThisDialog,
      }

      const opts = typeof _opts === "function" ? _opts(factoryInfo) : _opts

      setDialogs(dialog =>
        dialog.concat({ dialogInstance: instance, options: opts }),
      )

      return instance
    },
  )

  const ctrl: DialogBaseController = useMemo(
    () => ({
      show<T>(
        opts:
          | DialogControllerShowOptions
          | DialogControllerShowOptionsFactory<T>,
      ) {
        return addDialog(opts) as DialogInstance<T>
      },
    }),
    [addDialog],
  )

  return (
    <>
      <DialogContext.Provider value={ctrl}>
        {props.children}
      </DialogContext.Provider>
      {dialogs.map((d, idx) => (
        <Modal key={idx} visible={true} onClose={d.dialogInstance.dismiss}>
          {d.options.dialogContent}
        </Modal>
      ))}
    </>
  )
}
