import { keys, values, zip } from "lodash"
import tailwindBreakpoints from "./tailwindBreakpoints.json"

export const defaultThemeBreakpoints: [
  keyof typeof tailwindBreakpoints,
  number,
][] = zip(
  keys(tailwindBreakpoints),
  values(tailwindBreakpoints).map(n => parseInt(n, 10)),
) as any
