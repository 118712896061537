const isConnectedStorageKey =
  "src/stores/authStore/MetaMaskEthereumProvider/connectedProvider.ts-isConnectedStorageKey"

export const getIsPreviouslyConnected = (): boolean => {
  return localStorage.getItem(isConnectedStorageKey) != null
}

export const setIsPreviouslyConnected = (isConnected: boolean): void => {
  if (isConnected) {
    localStorage.setItem(isConnectedStorageKey, "1")
  } else {
    localStorage.removeItem(isConnectedStorageKey)
  }
}
