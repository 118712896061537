import { createStore } from "../../utils/createStore"
import { FCC } from "../../utils/reactHelpers/types"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useAccountStore } from "../accountStore/useAccountStore"
import { useAppEnvStore } from "../appEnvStore/useAppEnvStore"
import { useAuthStore } from "../authStore/useAuthStore"
import { useChainStore } from "../chainStore/useChainStore"
import { useCurrencyStore } from "../currencyStore/useCurrencyStore"
import NotifyStore from "./NotifyStore"

const Store = createStore<NotifyStore>("NotifyStore")

export const NotifyStoreProvider: FCC = props => {
  const account = useAccountStore()
  const currency = useCurrencyStore()
  const auth = useAuthStore()
  const appEnv = useAppEnvStore()
  const chain = useChainStore()
  const store = useCreation(
    () => new NotifyStore(account, currency, auth, appEnv, chain),
    [account, appEnv, auth, chain, currency],
  )
  return <Store.Provider store={store}>{props.children}</Store.Provider>
}

export const useNotifyStore = Store.useStore.bind(null)
