import { memoize } from "lodash"
import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import { AppEnvStore } from "../../../../stores/appEnvStore/AppEnvStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { poolMigration } from "../../../../utils/alexjs/PoolMigrations"
import { LiquidityPoolToken } from "../../../../utils/alexjs/currencyHelpers"
import { isNotNull } from "../../../../utils/utils"
import PoolDetailStore from "../detail/PoolDetailStore"

class PoolListStore {
  constructor(
    readonly appEnv: Pick<AppEnvStore, "config$">,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight$">,
    readonly accountStore: Pick<AccountStore, "getBalance$">,
    readonly currencyStore: CurrencyStore,
  ) {
    makeObservable(this)
  }

  poolStore = memoize(
    (token: LiquidityPoolToken) =>
      new PoolDetailStore(
        token,
        this.appEnv,
        this.authStore,
        this.accountStore,
        this.currencyStore,
      ),
  )

  @computed get allPools$(): LiquidityPoolToken[] {
    return this.appEnv.config$.pools
  }

  @computed get allPoolStores$(): PoolDetailStore[] {
    return this.allPools$.filter(x => !(x in poolMigration)).map(this.poolStore)
  }

  oldPoolStores$ = createTransformer((token: LiquidityPoolToken) => {
    return Object.entries(poolMigration)
      .map(([key, value]) =>
        value === token ? (key as LiquidityPoolToken) : null,
      )
      .filter(isNotNull)
      .filter(x => this.allPools$.includes(x))
      .map(this.poolStore)
  })

  @computed get myPools$(): PoolDetailStore[] {
    return this.allPools$
      .map(this.poolStore)
      .sort((a, b) => b.pooledAmount$ - a.pooledAmount$)
      .filter(a => a.pooledAmount$ > 0)
  }
}

export default PoolListStore
