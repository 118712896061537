import { FC, Suspense, useState } from "react"
import { EnvironmentUnsupportedReason } from "../../stores/appEnvStore/AppEnvStore"
import { useAppEnvStore } from "../../stores/appEnvStore/useAppEnvStore"
import { isMobileDevice } from "../../utils/domHelpers/browserEnv"
import { Modal } from "../Modal"
import {
  UnSupportAlertModalContent,
  UnSupportAlertType,
} from "./UnSupportAlertModalContent"

const UnsupportedReasonMapping: {
  [reason in EnvironmentUnsupportedReason]: UnSupportAlertType
} = {
  [EnvironmentUnsupportedReason.WalletNotInstalled]: "wallet",
}

const WiredUnSupportAlertModalImpl: FC = () => {
  const appEnvStore = useAppEnvStore()

  const reason = appEnvStore.environmentUnsupportedReason.value$
  const unsupportedAlertType: undefined | UnSupportAlertType =
    reason && UnsupportedReasonMapping[reason]

  const [isClosed, setIsClosed] = useState<null | boolean>(null)

  // we ignore mobile wallet reason here
  return unsupportedAlertType == null ||
    (unsupportedAlertType === "wallet" && isMobileDevice()) ? null : (
    <Modal visible={!isClosed}>
      <UnSupportAlertModalContent
        type={unsupportedAlertType}
        onClose={
          unsupportedAlertType === "wallet"
            ? () => setIsClosed(true)
            : undefined
        }
      />
    </Modal>
  )
}

export const WiredUnSupportAlertModal: FC = () => (
  <Suspense fallback={null}>
    <WiredUnSupportAlertModalImpl />
  </Suspense>
)
