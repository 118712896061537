import clsx from "clsx"
import { FC } from "react"
import {
  BaseRoundedFilledButton,
  RoundedButtonVariantProps,
} from "../../../../components/button/variants/BaseRoundedFilledButton"

export const GreenFilledButton: FC<RoundedButtonVariantProps> = props => (
  <BaseRoundedFilledButton
    {...props}
    className={clsx("text-gray-100", props.className)}
    bgClassName={
      "bg-green-600 hover:bg-green-700 active:bg-green-800 disabled:bg-green-800"
    }
  >
    {props.children}
  </BaseRoundedFilledButton>
)
