import clsx from "clsx"
import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { EmptyState } from "../../../../components/EmptyState/EmptyState"
import {
  InfoListItemDetail,
  InfoListItemTitle,
} from "../../../../components/InfoList"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Pagination, PaginationInfo } from "../../../../components/Pagination"
import { TextTokenCount } from "../../../../components/RichTokenCount"
import { Spensor } from "../../../../components/Spensor"
import { TokenCount } from "../../../../components/TokenCount"
import {
  SuspenseResource,
  readResource,
} from "../../../../utils/SuspenseResource"
import { simplifyIdentifier } from "../../../../utils/stringHelpers"
import { assertNever } from "../../../../utils/types"
import {
  OrdersHistoryRecord,
  OrdersHistoryRecordStatus,
  StxDxOrderType,
} from "../types"
import { DateRange, DateRangeSelector } from "./DateRangeSelector"
import {
  CreateDateCell,
  FieldCell,
  RecordRowContainer,
  TokenPairCell,
} from "./_/RecordRow"
import { TriggerConditionText } from "./_/TriggerConditionText"
import { fields$t } from "./_/commonIntlMessages"

export const OrdersHistoryTabContent: FC<{
  records: SuspenseResource<OrdersHistoryRecord[]>
  pagination?: SuspenseResource<PaginationInfo>
  dateRange?: DateRange
  onChangeDateRange?: (range: DateRange) => void
  onChangePage?: (page: number) => void
}> = props => {
  return (
    <>
      {props.dateRange != null && props.onChangeDateRange != null && (
        <DateRangeSelector
          value={props.dateRange}
          onChange={props.onChangeDateRange}
        />
      )}

      <Spensor fallback={<LoadingIndicator className={"m-auto"} />}>
        {() =>
          readResource(props.records).length <= 0 ? (
            <EmptyState className={"m-auto"} />
          ) : (
            <ul className={"flex flex-col gap-1"}>
              {readResource(props.records).map((r, idx) => (
                <li key={idx}>
                  {
                    // prettier-ignore
                    r.orderType === StxDxOrderType.Limit ? (<LimitRow record={r} />) :
                    r.orderType === StxDxOrderType.Market ? (<MarketRow record={r} />) :
                    r.orderType === StxDxOrderType.StopLimit ? (<StopLimitRow record={r} />) :
                    assertNever(r)
                  }
                </li>
              ))}
            </ul>
          )
        }
      </Spensor>

      <Spensor>
        {() =>
          props.pagination != null ? (
            <Pagination
              {...readResource(props.pagination)}
              onChange={i => props.onChangePage?.(i.page)}
            />
          ) : null
        }
      </Spensor>
    </>
  )
}

const LimitRow: FC<{ record: OrdersHistoryRecord.Limit }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TextTokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountPerTradeToken}
      />
    }
  />
)

const MarketRow: FC<{ record: OrdersHistoryRecord.Market }> = props => {
  const { $t } = useIntl()
  return (
    <RowTemplate
      record={props.record}
      average={
        <TextTokenCount
          token={props.record.priceToken}
          count={props.record.priceTokenCountAverage}
        />
      }
      price={$t(
        defineMessage({
          defaultMessage: "Market",
          description:
            'Orderbook/OrderHistory/market order "Price" field value',
        }),
      )}
    />
  )
}

const StopLimitRow: FC<{ record: OrdersHistoryRecord.StopLimit }> = props => (
  <RowTemplate
    record={props.record}
    price={
      <TokenCount
        token={props.record.priceToken}
        count={props.record.priceTokenCountPerTradeToken}
      />
    }
    triggerCondition={
      <TriggerConditionText
        priceToken={props.record.priceToken}
        triggerCondition={props.record.triggerCondition}
      />
    }
  />
)

const RowTemplate: FC<{
  record: OrdersHistoryRecord
  average?: ReactNode
  price: ReactNode
  triggerCondition?: ReactNode
}> = props => {
  const { record } = props

  const { $t } = useIntl()

  const grayOutReason =
    props.record.status === OrdersHistoryRecordStatus.Cancelled
      ? defineMessage({
          defaultMessage: "Cancelled",
          description: "Orderbook/OrderHistory/grayed out reason",
        })
      : props.record.status === OrdersHistoryRecordStatus.Expired &&
        props.record.executedTradeTokenCount === 0
      ? defineMessage({
          defaultMessage: "Expired",
          description: "Orderbook/OrderHistory/grayed out reason",
        })
      : null

  const opacityClass = grayOutReason ? "opacity-30" : ""

  return (
    <RecordRowContainer>
      <TokenPairCell
        className={opacityClass}
        clickToCopyRecordIdentifier={$t(
          defineMessage({
            defaultMessage: "Order Hash: {orderHash} (click to copy)",
            description: "Orderbook/OrderHistory/Order History",
          }),
          {
            orderHash: simplifyIdentifier(record.orderHash),
          },
        )}
        recordIdentifier={record.orderHash}
        tradeToken={record.tradeToken}
        priceToken={record.priceToken}
        orderDirection={record.orderDirection}
        orderType={record.orderType}
      />

      {props.average != null ? (
        <FieldCell columnId={"price"} className={opacityClass}>
          <InfoListItemTitle>{$t(fields$t.averagePrice)}</InfoListItemTitle>
          <InfoListItemDetail>{props.average}</InfoListItemDetail>
        </FieldCell>
      ) : (
        <FieldCell columnId={"price"} className={opacityClass}>
          <InfoListItemTitle>{$t(fields$t.price)}</InfoListItemTitle>
          <InfoListItemDetail>{props.price}</InfoListItemDetail>
        </FieldCell>
      )}

      <FieldCell columnId={"amount"} className={opacityClass}>
        <InfoListItemTitle>{$t(fields$t.amount)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.tradeToken}
            count={props.record.expectedTradeTokenCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"executed"}>
        <InfoListItemTitle className={opacityClass}>
          {$t(fields$t.executed)}
        </InfoListItemTitle>
        <InfoListItemDetail
          className={clsx(
            props.record.executedTradeTokenCount > 0 ? "" : opacityClass,
            "flex items-center",
          )}
        >
          <TextTokenCount
            token={props.record.tradeToken}
            count={props.record.executedTradeTokenCount}
          />
          &nbsp;
          {grayOutReason != null &&
            (props.record.executedTradeTokenCount > 0 ? (
              <span className="text-xs lead-4 text-white px-2 py-0.5 bg-gray-500 rounded">
                {$t(
                  defineMessage({
                    defaultMessage: "Partial",
                    description: "Orderbook/OrderHistory/grayed out reason",
                  }),
                )}
              </span>
            ) : (
              <span>({$t(grayOutReason)})</span>
            ))}
        </InfoListItemDetail>
      </FieldCell>

      <FieldCell columnId={"total"} className={opacityClass}>
        <InfoListItemTitle>{$t(fields$t.total)}</InfoListItemTitle>
        <InfoListItemDetail>
          <TextTokenCount
            token={props.record.priceToken}
            count={props.record.priceTokenTotalCount}
          />
        </InfoListItemDetail>
      </FieldCell>

      {props.triggerCondition != null && (
        <FieldCell columnId={"triggerCondition"} className={opacityClass}>
          <InfoListItemTitle>{$t(fields$t.triggerCondition)}</InfoListItemTitle>
          <InfoListItemDetail>{props.triggerCondition}</InfoListItemDetail>
        </FieldCell>
      )}

      <CreateDateCell
        className={opacityClass}
        createdAt={props.record.createdAt}
      />
    </RecordRowContainer>
  )
}
