import { FC, ReactNode } from "react"
import { defineMessage, useIntl } from "react-intl"
import { Card, CardTitle } from "../../../../components/Card"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { Slider } from "../../../../components/Slider"
import { Spensor, SuspenseProps } from "../../../../components/Spensor"
import {
  BalanceBottomArea,
  EstimatedUSD,
} from "../../../../components/TokenInput/BalanceBottomArea"
import {
  DefaultTokenNameArea,
  renderThinnerBlock,
} from "../../../../components/TokenInput/Block"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { Button } from "../../../../components/button/Button"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import { GradientFilledButton } from "../../../../components/button/variants/GradientFilledButton/GradientFilledButton"
import { RedFilledButton } from "../../../../components/button/variants/RedFilledButton"
import {
  SuspenseResource,
  readResource,
  safeReadResource,
  suspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import PoolTokenName from "../../../Farm/components/PoolTokenName"
import { AddStakeSectionInfoList } from "../../components/AddStakeSectionInfoList"
import { DetailPlate } from "../../components/DetailPlate/DetailPlate"
import { CycleCountSlider } from "../CycleCountSlider/CycleCountSlider"
import {
  EstimateCycleDays,
  EstimateCycleDaysProps,
} from "../EstimateCycleDays/EstimateCycleDays"
import {
  StakeSubmissionSummary,
  StakeSubmissionSummaryProps,
} from "../StakeSubmissionSummary"
import { FormError, FormErrorType } from "./types"

export interface AddStakeSectionProps
  extends SuspenseProps<StakeSubmissionSummaryProps>,
    EstimateCycleDaysProps {
  className?: string

  title: ReactNode
  token: TokenInfo
  tokenCount: null | number
  tokenCountToUSD: SuspenseResource<number>
  setTokenCount?: (newCount: null | number) => void
  balance: SuspenseResource<number>

  cycleCount: number
  setCycleCount?: (newCount: number) => void
  maxCycleCount?: number

  error?: SuspenseResource<FormError | undefined>
  onSubmit?: () => void
  onClear?: () => void
  onConnectWallet?: () => void
}

export const AddStakeSection: FC<AddStakeSectionProps> = props => {
  const { $t } = useIntl()
  return (
    <Card className={props.className}>
      <div className={"flex flex-row items-center justify-between"}>
        <CardTitle>{props.title}</CardTitle>
        {props.onClear && (
          <HeadlessButton className={"text-blue-600"} onClick={props.onClear}>
            {$t(
              defineMessage({
                defaultMessage: "Clear",
                description: "/Stake/ManualStake/AddStakeSection/Button text",
              }),
            )}
          </HeadlessButton>
        )}
      </div>

      <div className={"mt-4 mb-[30px] flex flex-col gap-4"}>
        <TokenInput
          token={props.token}
          tokenNameArea={
            <DefaultTokenNameArea
              token={props.token}
              TokenNameComponent={PoolTokenName}
            />
          }
          error={isTokenInputShouldBeErrorState(safeReadResource(props.error))}
          value={props.tokenCount}
          onChange={props.setTokenCount}
          bottomArea={
            <BalanceBottomArea
              token={props.token}
              balance={props.balance}
              rightSide={
                props.tokenCount != null && (
                  <EstimatedUSD
                    error={isTokenInputShouldBeErrorState(
                      safeReadResource(props.error),
                    )}
                    usdCount={props.tokenCountToUSD}
                  />
                )
              }
              onPressMax={suspenseResource(() => {
                const balance = readResource(props.balance)
                if (props.tokenCount === balance) return undefined
                if (!balance) return
                return () => {
                  props.setTokenCount?.(balance)
                }
              })}
            />
          }
          renderBlock={renderThinnerBlock}
        />
        <div className={"flex flex-col gap-2.5"}>
          <EstimateCycleDays
            cycleCount={props.cycleCount}
            cycleTip={props.cycleTip}
            estimateDayCount={props.estimateDayCount}
          />

          {props.maxCycleCount == null ? (
            <CycleCountSlider
              shortcuts={[
                { count: 4 },
                { count: 8 },
                { count: 12 },
                { count: 24 },
                { count: 32, isRecommend: true },
              ]}
              value={props.cycleCount}
              onChange={props.setCycleCount}
            />
          ) : (
            <Slider
              min={1}
              max={props.maxCycleCount}
              value={props.cycleCount}
              onChange={props.setCycleCount}
            />
          )}
        </div>

        <DetailPlate
          title={$t(
            defineMessage({
              defaultMessage: "Staking Details",
              description:
                "/Stake/ManualStake/AddStakeSection/DetailPlate title",
            }),
          )}
        >
          <Spensor
            fallback={
              <AddStakeSectionInfoList
                className={"flex items-center justify-center p-6"}
              >
                <LoadingIndicator />
              </AddStakeSectionInfoList>
            }
          >
            {() => (
              <StakeSubmissionSummary
                startedAtBlock={readResource(props.startedAtBlock)}
                endedAtBlock={readResource(props.endedAtBlock)}
                startedAtCycleNumber={readResource(props.startedAtCycleNumber)}
                endedAtCycleNumber={readResource(props.endedAtCycleNumber)}
              />
            )}
          </Spensor>
        </DetailPlate>
      </div>

      {safeReadResource(props.error)?.type ===
      FormErrorType.WalletNotConnected ? (
        <GradientFilledButton onClick={props.onConnectWallet}>
          {$t(
            defineMessage({
              defaultMessage: "Connect Wallet",
              description: "/Stake/ManualStake/AddStakeSection/Button text",
            }),
          )}
        </GradientFilledButton>
      ) : safeReadResource(props.error) ? (
        <Button
          Variant={
            isErrorStateButtonShouldRenderAsGradientButton(
              safeReadResource(props.error),
            )
              ? GradientFilledButton
              : RedFilledButton
          }
          disabled={true}
        >
          {safeReadResource(props.error)?.message}
        </Button>
      ) : (
        <GradientFilledButton onClick={props.onSubmit}>
          {$t(
            defineMessage({
              defaultMessage: "Stake",
              description: "/Stake/ManualStake/AddStakeSection/Button text",
            }),
          )}
        </GradientFilledButton>
      )}
    </Card>
  )
}

const isErrorStateButtonShouldRenderAsGradientButton = (
  error?: FormError,
): boolean => {
  if (!error) return false
  return error.type === FormErrorType.AmountIsEmpty
}

const isTokenInputShouldBeErrorState = (error?: FormError): boolean => {
  if (!error) return false
  return (
    error.type === FormErrorType.InsufficientTokenBalance ||
    error.type === FormErrorType.LessThanMinimizeAmount
  )
}
