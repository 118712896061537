import { computed, makeObservable, observable } from "mobx"
import { combineLatestWith, map } from "rxjs"
import {
  LazyValue,
  SharedLazyValue,
} from "../../../../stores/LazyValue/LazyValue"
import { SuspenseObservable } from "../../../../stores/SuspenseObservable"
import AccountStore from "../../../../stores/accountStore/AccountStore"
import AuthStore from "../../../../stores/authStore/AuthStore"
import { ChainStore } from "../../../../stores/chainStore/ChainStore"
import { ConfirmTransactionStore } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStore"
import { ConfirmTransactionStoreForGeneral } from "../../../../stores/confirmTransactionDialogStore/ConfirmTransactionStoreForGeneral"
import CurrencyStore from "../../../../stores/currencyStore/CurrencyStore"
import { assertExclude, checkNever } from "../../../../utils/types"
import { BitcoinClient } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClient"
import {
  BRC20WalletRecentTransferableInscription,
  WalletOrdinalInscription,
} from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClient.types"
import { BitcoinClientBRC20Wallet } from "../../../Orderbook/OrderbookPegInPage/stores/BitcoinClient/BitcoinClientBRC20Wallet"
import {} from "../../../Orderbook/store/OrderbookStore.service/OrderbookStore.service"
import type { BRC20InscriptionStatus } from "../components/BRC20Card/BRC20InscriptionStatusCard"
import type { TransferableInscriptionRecord } from "../components/TransferablePanel"
import { getTokenInfoFromBRC20Symbol$ } from "./BulkTransferInscriptionPageStore.services"

export class RecentTransferableModule {
  constructor(
    private authStore: AuthStore,
    private chainStore: ChainStore,
    private accountStore: AccountStore,
    private currencyStore: CurrencyStore,
    private btcClient: SharedLazyValue<BitcoinClient>,
    private brc20Wallet: SharedLazyValue<BitcoinClientBRC20Wallet>,
    private pegInBtcAddress: SharedLazyValue<undefined | string>,
    private stacksTxStore: ConfirmTransactionStore,
    private generalTxStore: ConfirmTransactionStoreForGeneral,
  ) {
    makeObservable(this)
  }

  private btcNetworkInfo = new LazyValue(
    () => this.btcClient.value$,
    btcClient => btcClient.networkInfo,
  )

  private recentTransferInscriptions = new LazyValue(
    () => ({ wallet: this.brc20Wallet.value$ }),
    ({ wallet }) => wallet.recentTransferBrc20Inscriptions,
  )

  private transferableOrdinalInscriptions = new LazyValue(
    () => ({ wallet: this.brc20Wallet.value$ }),
    ({ wallet }) =>
      wallet.transferableOrdinalInscriptions.pipe(
        combineLatestWith(wallet.recentTransferBrc20Inscriptions),
        map(([ordinals, brc20]) =>
          ordinals.filter(
            ord =>
              !brc20.transferableInscriptions.some(
                brc => brc.inscriptionId === ord.inscriptionId,
              ),
          ),
        ),
      ),
  )

  private refreshSignalForMempool = new SuspenseObservable(Math.random())
  private inscriptionTransferMempool = new LazyValue(
    () => ({
      _refresh: this.refreshSignalForMempool.read$,
      _block: this.btcNetworkInfo.value$.block.height,
      brc20Wallet: this.brc20Wallet.value$,
    }),
    ({ brc20Wallet }) => brc20Wallet.getInscriptionTransferMempool(),
  )

  @observable private selectedInscriptionIds = new Set<string>()

  private brc20InscriptionStatus$(
    inscription: BRC20WalletRecentTransferableInscription,
  ): undefined | BRC20InscriptionStatus {
    /**
     *   | { type: "inscribing"; explorerUrl: string }
     *   | { type: "transferable"; onSendPegInRequest: () => void | Promise<void> }
     *   | { type: "sending"; explorerUrl: string }
     *   | { type: "sent"; explorerUrl: string }
     *   | { type: "send-failed"; explorerUrl: string }
     */

    /**
     * TODO:
     *
     * * inscription creation
     *   * service fee transferring
     *   * order in progress
     *   * inscription transferring
     */

    const restStatusType = assertExclude.i<BRC20InscriptionStatus["type"]>()

    // TODO
    assertExclude(restStatusType, "inscribing")

    if (inscription.transferResult === "failed") {
      return {
        type: "send-failed",
        explorerUrl: this.brc20Wallet.value$.inscriptionExplorerLink(
          inscription.inscriptionId,
        ),
      }
    }
    assertExclude(restStatusType, "send-failed")

    if (inscription.transferResult === "success") {
      return {
        type: "sent",
        explorerUrl: this.brc20Wallet.value$.inscriptionExplorerLink(
          inscription.inscriptionId,
        ),
      }
    }
    assertExclude(restStatusType, "sent")

    if (inscription.transferResult == null) {
      const inscriptionSending = this.inscriptionTransferMempool.value$.find({
        txId: inscription.txId,
        index: inscription.index,
      })

      if (inscriptionSending != null) {
        return {
          type: "sending",
          explorerUrl: this.btcClient.value$.explorerLink(
            inscriptionSending.txId,
          ),
        }
      } else {
        return {
          type: "transferable",
          isSelected: this.selectedInscriptionIds.has(
            inscription.inscriptionId,
          ),
          onToggleSelection: () => {
            if (this.selectedInscriptionIds.has(inscription.inscriptionId)) {
              this.selectedInscriptionIds.delete(inscription.inscriptionId)
            } else {
              this.selectedInscriptionIds.add(inscription.inscriptionId)
            }
          },
        }
      }
    }
    assertExclude(restStatusType, "transferable")
    assertExclude(restStatusType, "sending")

    checkNever(restStatusType)
    checkNever(inscription.transferResult)
    return
  }

  private ordinalsInscriptionStatus$(
    inscription: WalletOrdinalInscription,
  ): BRC20InscriptionStatus {
    /**
     *   | { type: "inscribing"; explorerUrl: string }
     *   | { type: "transferable"; onSendPegInRequest: () => void | Promise<void> }
     *   | { type: "sending"; explorerUrl: string }
     *   | { type: "sent"; explorerUrl: string }
     *   | { type: "send-failed"; explorerUrl: string }
     */

    /**
     * TODO:
     *
     * * inscription creation
     *   * service fee transferring
     *   * order in progress
     *   * inscription transferring
     */

    const restStatusType = assertExclude.i<BRC20InscriptionStatus["type"]>()
    void restStatusType

    // TODO: currently not available to detect
    assertExclude(restStatusType, "inscribing")
    assertExclude(restStatusType, "send-failed")
    assertExclude(restStatusType, "sent")

    const inscriptionSending = this.inscriptionTransferMempool.value$.find({
      txId: inscription.txId,
      index: inscription.index,
    })

    if (inscriptionSending != null) {
      return {
        type: "sending",
        explorerUrl: this.btcClient.value$.explorerLink(
          inscriptionSending.txId,
        ),
      }
    } else {
      return {
        type: "transferable",
        isSelected: this.selectedInscriptionIds.has(inscription.inscriptionId),
        onToggleSelection: () => {
          if (this.selectedInscriptionIds.has(inscription.inscriptionId)) {
            this.selectedInscriptionIds.delete(inscription.inscriptionId)
          } else {
            this.selectedInscriptionIds.add(inscription.inscriptionId)
          }
        },
      }
    }
    // assertExclude(restStatusType, "transferable")
    // assertExclude(restStatusType, "sending")
    //
    // checkNever(restStatusType)
    // return
  }

  @computed
  get recentTransferInscriptions$(): TransferableInscriptionRecord[] {
    return this.transferableOrdinalInscriptions.value$
      .map((record): TransferableInscriptionRecord => {
        const status = this.ordinalsInscriptionStatus$(record)

        return {
          type: "ordinals" as const,
          mimeType: record.mimeType,
          previewUrl: record.previewUrl,
          status,
          inscriptionNumber: Number(record.inscriptionNumber),
        }
      })
      .concat(
        this.recentTransferInscriptions.value$.transferableInscriptions.flatMap(
          (record): TransferableInscriptionRecord[] => {
            const token = getTokenInfoFromBRC20Symbol$(
              this.currencyStore,
              record.symbol,
            )
            if (token == null) return []

            const status = this.brc20InscriptionStatus$(record)
            if (status == null) return []

            return [
              {
                type: "brc20" as const,
                status,
                token,
                tokenAmount: record.amount,
                inscriptionNumber: Number(record.inscriptionNumber),
              },
            ]
          },
        ),
      )
  }

  @computed get sendable(): boolean {
    return this.selectedInscriptionIds.size > 0
  }

  async sendInscriptions(): Promise<void> {
    const receiverAddress = prompt("Please input receiver Bitcoin Address:")

    if (!receiverAddress) return

    await this.generalTxStore.run(async () => {
      const resp =
        await this.brc20Wallet.value$.unsafelySendInscriptionTransactionHex(
          Array.from(this.selectedInscriptionIds).map(id => ({
            inscriptionId: id,
            receiverAddress,
          })),
          { useUnsafelySendingApproach: true, ordinalsSupport: true },
        )
      return {
        explorerLink: await this.btcClient.value$.explorerLink(resp.txId),
      }
    })
  }
}
