import { asSender } from "../../../../../generated/smartContractHelpers/asSender"
import { transfer } from "../../../../../utils/alexjs/postConditions"
import { StakingToken } from "../ManualStakeStore.service"

export type AddStakeFormData = {
  stxAddress: string
  amount: number
  cycles: number
  token: StakingToken
}
export async function addStakeToken(
  data: AddStakeFormData,
): Promise<{ txId: string }> {
  if (typeof data.token === "string") {
    return asSender(data.stxAddress)
      .contract("alex-reserve-pool")
      .func("stake-tokens")
      .call(
        {
          "amount-token": data.amount * 1e8,
          "lock-period": data.cycles,
          "token-trait": data.token,
        },
        [transfer(data.stxAddress, data.token, data.amount)],
      )
  }
  return asSender(data.stxAddress)
    .contract("alex-reserve-pool-sft")
    .func("stake-tokens")
    .call(
      {
        "amount-token": data.amount * 1e8,
        "lock-period": data.cycles,
        "token-id": data.token.tokenId,
        "token-trait": data.token.currency,
      },
      [transfer(data.stxAddress, data.token.currency, data.amount)],
    )
}
